import {GraphQLError} from 'graphql'

export enum ErrorCodeList {
  AddressVerificationFailed = 'ADDRESS_VERIFICATION_FAILED',
}

export type ErrorMessageLookup = (errorCode: string) => string | null

export const isValidGraphQLError = (error: unknown): error is GraphQLError =>
  typeof error === 'object' && error !== null && 'extensions' in error && error.extensions !== null
