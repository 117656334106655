import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {StyleSheet, View} from 'react-native'
import Webcam from 'react-webcam'

import {
  UniversalCameraProps,
  UniversalCameraRef,
} from 'src/products/general/UniversalCamera/UniversalCamera.types'
import {jpegContentType} from 'src/lib/utils/formData'
import {useMeasurement} from 'src/lib/utils/useMeasurement'
import {openImageUriAsFile} from 'src/products/general/UniversalCamera/UniversalCamera.utils'
import AspectView from 'src/products/general/components/atoms/PFCamera/AspectView'
import Log from 'src/lib/loggingUtil'

const UniversalCamera: React.FC<UniversalCameraProps> = forwardRef(
  (props: UniversalCameraProps, ref: React.Ref<UniversalCameraRef>) => {
    const {
      disabled: isDisabled,
      aspectRatio,
      position = 'front',
      style,
      onCameraReady,
      width: captureWidth = 960,
      height: captureHeight = 540,
    } = props

    const {width, height, handleLayout: handleOnCameraLayout} = useMeasurement()

    const webcamRef = useRef<Webcam>(null)

    useImperativeHandle(
      ref,
      () => ({
        takeImageCapture: async (): ReturnType<UniversalCameraRef['takeImageCapture']> => {
          if (!webcamRef.current) {
            return null
          }

          const dataUri = webcamRef.current.getScreenshot({
            width: captureWidth,
            height: captureHeight,
          })
          if (!dataUri) {
            return null
          }

          const file = await openImageUriAsFile(dataUri)

          return {
            source: 'web',
            file,
            dataUri,
            width: captureWidth,
            height: captureHeight,
            pictureOrientation: 1,
            uri: dataUri,
          }
        },
      }),
      [captureHeight, captureWidth],
    )

    const handleOnUserMedia = (): void => {
      onCameraReady?.()
    }

    const videoConstraints: MediaTrackConstraints = {
      facingMode: position === 'front' ? 'user' : 'environment',
      width: width,
      height: height,
    }

    const cam =
      !isDisabled && width && height ? (
        <View style={styles.webcam} testID="UniversalCamera-Webcam">
          <Webcam
            style={styles.webcam}
            screenshotQuality={0.98}
            onUserMedia={handleOnUserMedia}
            screenshotFormat={jpegContentType}
            ref={webcamRef}
            audio={false}
            mirrored={false}
            videoConstraints={videoConstraints}
            onUserMediaError={(error: string | DOMException) => {
              const errorMessage = typeof error === 'string' ? error : error.message
              Log.error(`${errorMessage} - Camera Error`, 'webcam user media error')
            }}
          />
        </View>
      ) : null

    return (
      <AspectView aspectRatio={aspectRatio ?? 1} style={style}>
        <View testID="UniversalCamera" onLayout={handleOnCameraLayout} style={styles.container}>
          {cam}
        </View>
      </AspectView>
    )
  },
)

UniversalCamera.displayName = 'Camera'

export default UniversalCamera
export type {Webcam as CameraRefType}

const styles = StyleSheet.create({
  container: {height: '100%', width: '100%'},
  webcam: {height: '100%', objectFit: 'cover', width: '100%'},
})
