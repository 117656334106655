import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Alert, {AlertProps} from 'src/designSystem/components/molecules/Alert/Alert'
import {
  ActiveLoanAggregateStatus,
  LoanActiveStatusActions,
  LoanActiveStatusPaymentAlertItem,
  LoanPayment,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import converter from 'number-to-words'

export type LoanAlertTileProps = {
  alert: LoanActiveStatusPaymentAlertItem
  actions: Pick<ActiveLoanAggregateStatus, 'actions'>['actions']
  loanPayments: LoanAlertTileLoanPaymentFields[]
  onRelinkBankAccount: () => void
  onContactUs: () => void
  onUpdateLoanPaymentDates: () => void
  onUpdatePaymentMethod: () => void
  onMakeAPayment: () => void
  testID?: string
}

export type LoanAlertTileLoanPaymentFields = Pick<
  LoanPayment,
  'amount' | 'statusCode' | 'originalDate' | 'rescheduledDate' | 'lastFailedTransaction'
>

/**
 * Tile to display alerts for a loan that correspond to one of LoanActiveStatusPaymentAlerts.
 * @example <LoanAlertTile alert={alert} daysUntilChargedOff={18} loanPayments={[]} onPress={() => {})} />
 */
// eslint-disable-next-line complexity
const LoanAlertTile: React.FC<LoanAlertTileProps> = (props: LoanAlertTileProps) => {
  const {t} = useTranslation(['DashboardLoanAlertTile', 'Common'])
  const {
    actions,
    alert,
    loanPayments,
    onContactUs,
    onRelinkBankAccount,
    onUpdateLoanPaymentDates,
    onMakeAPayment,
    testID,
  } = props
  let alertProps:
    | Pick<AlertProps, 'level' | 'title' | 'description' | 'onPress' | 'testID'>
    | undefined = undefined
  const failedPayments: LoanAlertTileLoanPaymentFields[] = loanPayments.filter(
    (thisPayment: LoanAlertTileLoanPaymentFields) => {
      return thisPayment.statusCode === LoanPaymentStatusCode.Failed
    },
  )
  // first failed payment for LATE_PAYMENT
  const firstFailedPayment = failedPayments[0]
  const firstFailedPaymentDate = firstFailedPayment
    ? new Date(firstFailedPayment.rescheduledDate || firstFailedPayment.originalDate)
    : undefined
  const fullYearSliceStartIndex = 2
  const firstFailedPaymentDateToDisplay = firstFailedPaymentDate
    ? `${
        firstFailedPaymentDate.getMonth() + 1
      }/${firstFailedPaymentDate.getDate()}/${firstFailedPaymentDate
        .getFullYear()
        .toString()
        .slice(fullYearSliceStartIndex)}`
    : undefined

  switch (alert.__typename) {
    case undefined:
      return null
    // "FailedPayment" replaced the deprecated "LatePayment"
    case 'LoanActiveAlertLatePayment':
    case 'LoanActiveAlertFailedPayment':
      alertProps = {
        level: 'warning',
        title: t('LatePaymentTitle'),
        description: t('LatePaymentDescription', {
          accountMask: firstFailedPayment?.lastFailedTransaction?.accountMask,
          amount: firstFailedPayment?.amount,
          date: firstFailedPaymentDateToDisplay,
        }),
        onPress: onUpdateLoanPaymentDates,
      }
      break
    case 'LoanActiveAlertLatePaymentNotFailed':
      alertProps = {
        level: 'warning',
        title: t('PaymentLateNotFailedTitle'),
        description: (
          <PFText variant="p_sm">
            <Trans
              t={t}
              i18nKey="PaymentLateNotFailedDescription"
              components={{bold: <PFText variant="p_sm_semibold" />}}
              values={{
                // convert dates to format "March 12th"
                rescheduleOnOrBeforeDate:
                  t(`Month_${new Date(alert.rescheduleOnOrBeforeDate).getMonth() + 1}`, {
                    ns: 'Common',
                  }) +
                  ` ${converter.toOrdinal(new Date(alert.rescheduleOnOrBeforeDate).getDate())}`,
                paymentDate:
                  t(`Month_${new Date(alert.paymentDate).getMonth() + 1}`, {
                    ns: 'Common',
                  }) + ` ${converter.toOrdinal(new Date(alert.paymentDate).getDate())}`,
              }}
            />
          </PFText>
        ),
        onPress: onUpdateLoanPaymentDates,
      }
      break
    case 'LoanActiveAlertLoanSuspended':
      alertProps = {
        level: 'warning',
        title: t('LoanSuspendedTitle'),
        onPress: onRelinkBankAccount,
      }
      break
    case 'LoanActiveAlertInDefault':
      alertProps = {
        level: 'error',
        title: t('LoanInDefaultTitle'),
        description: (
          <PFText variant="p_sm">
            <Trans
              t={t}
              i18nKey="LoanInDefaultDescription"
              components={{bold: <PFText variant="p_sm_semibold" />}}
              values={{
                daysUntilChargeOff: alert.daysUntilChargeOff,
              }}
            />
          </PFText>
        ),
        onPress: actions.actions?.includes(LoanActiveStatusActions.MakePayment)
          ? onMakeAPayment
          : undefined,
      }
      break
    case 'LoanActiveAlertChargedOff':
      alertProps = {
        level: 'error',
        title: t('LoanChargedOffTitle'),
        description: (
          <PFText variant="p_sm">
            <Trans
              t={t}
              i18nKey="LoanChargedOffDescription"
              components={{
                bold: <PFText variant="p_sm_semibold" />,
              }}
              values={{
                daysUntilChargedOff: 20, // this will be updated in ENG-13574
              }}
            />
          </PFText>
        ),
        onPress: onContactUs,
      }
      break
    case 'LoanActiveAlertPaymentMethodIssue':
      alertProps = {
        level: 'error',
        title: t('PaymentMethodIssueTitle'),
        description: t('PaymentMethodIssueDescription'),
        onPress: onRelinkBankAccount,
      }
      break
    case 'LoanActiveAlertDisbursementCompleted':
      alertProps = {
        level: 'success',
        title: t('DisbursementCompletedTitle'),
      }
      break
    case 'LoanActiveAlertDisbursementDelayed':
      alertProps = {
        level: 'info',
        title: t('DisbursementDelayedTitle'),
      }
      break
    case 'LoanActiveAlertPaymentCompleted':
      alertProps = {
        level: 'success',
        title: t('PaymentCompletedTitle'),
      }
      break
    case 'LoanActiveAlertUpcomingPayment':
      alertProps = {
        level: 'info',
        title: t('UpcomingPaymentTitle', {daysUntilDue: alert.daysUntilDue}),
      }
      break
    case 'LoanActiveAlertFinalPaymentProcessing':
      alertProps = {
        level: 'info',
        title: t('LoanFinalPaymentProcessingTitle'),
      }
      break
  }
  if (alertProps) {
    const finalAlertProps: AlertProps = {
      level: alertProps.level,
      title: alertProps.title,
      description: alertProps.description,
      onPress: alertProps.onPress ?? undefined,
      testID,
    }
    return <Alert {...finalAlertProps} />
  } else {
    return null
  }
}

export {LoanAlertTile}
