import {
  AutomaticPaymentSchedule,
  PaymentFrequency,
  PotentialAutomaticPaymentCollection,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {DeepNonNullable} from 'utility-types'

type RequiredScheduleData = DeepNonNullable<
  Pick<AutomaticPaymentSchedule, '__typename' | 'id' | 'paymentDate'>
>
type ValidPaymentSchedule = RequiredScheduleData &
  Partial<Omit<AutomaticPaymentSchedule, keyof RequiredScheduleData>>

const hasValidPaymentSchedule = (
  maybePaymentSchedule: DeepPartial<PotentialAutomaticPaymentCollection['payments']>,
): maybePaymentSchedule is ValidPaymentSchedule[] =>
  !!maybePaymentSchedule?.every<ValidPaymentSchedule>((datum): datum is ValidPaymentSchedule => {
    return datum?.__typename === 'AutomaticPaymentSchedule' && typeof datum?.id === 'string'
  })

const getValidPaymentSchedule = (
  scheduleData: DeepPartial<PotentialAutomaticPaymentCollection['payments']>,
): ValidPaymentSchedule[] | undefined =>
  hasValidPaymentSchedule(scheduleData) ? scheduleData : undefined

const hasValidPaymentFrequency = (
  maybePaymentFrequency: PotentialAutomaticPaymentCollection['frequency'],
): maybePaymentFrequency is PaymentFrequency =>
  maybePaymentFrequency !== null && maybePaymentFrequency !== undefined

const getValidPaymentFrequency = (
  frequencyData: PotentialAutomaticPaymentCollection['frequency'],
): PaymentFrequency =>
  hasValidPaymentFrequency(frequencyData) ? frequencyData : PaymentFrequency.Unknown

export {getValidPaymentSchedule, getValidPaymentFrequency}
export type {ValidPaymentSchedule}
