import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseDefaultPaymentAccountQueryVariables = Types.Exact<{[key: string]: never}>

export type UseDefaultPaymentAccountQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        paymentMethods: {
          __typename?: 'CardPaymentMethodCollection'
          default?:
            | {
                __typename?: 'AchPaymentMethod'
                id: string
                code: Types.PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: Types.LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            | {__typename?: 'CheckPaymentMethod'}
            | {
                __typename?: 'DebitCardPaymentMethod'
                id: string
                code: Types.PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                card: {
                  __typename?: 'LinkedDebitCard'
                  mask?: string | null
                  account?: {
                    __typename?: 'LinkedAccount'
                    id: string
                    mask: string
                    name?: string | null
                    preferredFundingSource?: boolean | null
                    type: Types.LinkedAccountType
                    institution?: {__typename?: 'Institution'; name: string} | null
                  } | null
                }
              }
            | null
          all?: Array<
            | {
                __typename?: 'AchPaymentMethod'
                id: string
                code: Types.PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: Types.LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            | {__typename?: 'CheckPaymentMethod'}
            | {
                __typename?: 'DebitCardPaymentMethod'
                id: string
                code: Types.PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: Types.PaymentMethodStatusCode
                      reason?: string | null
                    }
                card: {
                  __typename?: 'LinkedDebitCard'
                  mask?: string | null
                  account?: {
                    __typename?: 'LinkedAccount'
                    id: string
                    mask: string
                    name?: string | null
                    preferredFundingSource?: boolean | null
                    type: Types.LinkedAccountType
                    institution?: {__typename?: 'Institution'; name: string} | null
                  } | null
                }
              }
          > | null
        }
      } | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      cardEligible?: Array<
        | {
            __typename?: 'AchPaymentMethod'
            id: string
            code: Types.PaymentMethodTypeCode
            bankingPaymentInstrumentId: string
            status:
              | {
                  __typename?: 'ExpiredPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'InvalidPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'PendingPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'RemovedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SuspendedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SystemPurgedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'VerifiedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
            account?: {
              __typename?: 'LinkedAccount'
              id: string
              mask: string
              name?: string | null
              preferredFundingSource?: boolean | null
              type: Types.LinkedAccountType
              institution?: {__typename?: 'Institution'; name: string} | null
            } | null
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {
            __typename?: 'DebitCardPaymentMethod'
            id: string
            code: Types.PaymentMethodTypeCode
            bankingPaymentInstrumentId: string
            status:
              | {
                  __typename?: 'ExpiredPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'InvalidPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'PendingPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'RemovedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SuspendedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SystemPurgedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'VerifiedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
            card: {
              __typename?: 'LinkedDebitCard'
              mask?: string | null
              account?: {
                __typename?: 'LinkedAccount'
                id: string
                mask: string
                name?: string | null
                preferredFundingSource?: boolean | null
                type: Types.LinkedAccountType
                institution?: {__typename?: 'Institution'; name: string} | null
              } | null
            }
          }
      > | null
    }
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        name?: string | null
        mask: string
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        institution?: {__typename?: 'Institution'; name: string} | null
      }> | null
    }
  }
}

export const UseDefaultPaymentAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseDefaultPaymentAccount'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'paymentMethods'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'default'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'AchPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'bankingPaymentInstrumentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'code'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'reason'},
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'account'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'mask'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'name'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'preferredFundingSource',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'type'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'institution'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'name'},
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'code'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'reason'},
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'bankingPaymentInstrumentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'card'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'mask'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'account'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'id'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'mask'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'name'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'preferredFundingSource',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'institution',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'name',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'all'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'AchPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'bankingPaymentInstrumentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'code'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'reason'},
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'account'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'mask'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'name'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'preferredFundingSource',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'type'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'institution'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'name'},
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'code'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'reason'},
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'bankingPaymentInstrumentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'card'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'mask'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'account'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'id'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'mask'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'name'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'preferredFundingSource',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'institution',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'name',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cardEligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'status'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'account'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'preferredFundingSource'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'status'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'account'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'preferredFundingSource',
                                                },
                                              },
                                              {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'institution'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'name'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'accountFilter'},
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {kind: 'Name', value: 'onlyAchEligible'},
                                  value: {kind: 'BooleanValue', value: true},
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseDefaultPaymentAccountQuery, UseDefaultPaymentAccountQueryVariables>
