import {DeviceDetailsInput} from '@possible/cassandra/src/types/types.public.generated'
import {GetClientInfo} from 'src/api/actions/client'

const getDeviceDetails = async (): Promise<DeviceDetailsInput> => {
  const {deviceCarrier, deviceId, deviceMfg, deviceOsName, clientType} = await GetClientInfo()

  return {
    deviceId,
    deviceManufacturer: deviceMfg,
    carrier: deviceCarrier,
    operatingSystem: deviceOsName,
    clientType,
  }
}

export {getDeviceDetails}
