import React, {FC, useEffect} from 'react'

import {ApplicationProcessingTemplate} from 'src/products/general/ApplicationProcessing/ApplicationProcessingTemplate'
import {ApplicationProcessingProps} from 'src/products/general/ApplicationProcessing/types'
import {useLoanApplicationProcessingStatus} from 'src/products/general/ApplicationProcessing/useLoanApplicationProcessingStatus'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'

export const LoanApplicationProcessing: FC<ApplicationProcessingProps> = ({
  onUnderwritingComplete: onProcessingComplete,
}) => {
  const {isApplicationUWComplete} = useLoanApplicationProcessingStatus(true)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_application_processing_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  useEffect(() => {
    if (isApplicationUWComplete) {
      void onProcessingComplete()
    }
  }, [isApplicationUWComplete, onProcessingComplete])

  return <ApplicationProcessingTemplate />
}
