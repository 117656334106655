import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AccountManagementAccountsQueryVariables = Types.Exact<{[key: string]: never}>

export type AccountManagementAccountsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
        paymentMethods: {
          __typename?: 'LoanPaymentMethodCollection'
          paymentDefault?:
            | {
                __typename: 'AchPaymentMethod'
                id: string
                account?: {__typename?: 'LinkedAccount'; id: string} | null
              }
            | {__typename: 'CheckPaymentMethod'; id: string}
            | {
                __typename: 'DebitCardPaymentMethod'
                id: string
                card: {
                  __typename?: 'LinkedDebitCard'
                  account?: {__typename?: 'LinkedAccount'; id: string} | null
                }
              }
            | null
        }
      } | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      all?: Array<
        | {
            __typename?: 'AchPaymentMethod'
            id: string
            bankAccount: {__typename?: 'BankAccount'; friendlyName: string; mask: string}
            account?: {
              __typename?: 'LinkedAccount'
              id: string
              name?: string | null
              mask: string
              achNumbersAvailable?: boolean | null
              isLoginRequired: boolean
              preferredFundingSource?: boolean | null
              status: Types.LinkedAccountStatusCode
              type: Types.LinkedAccountType
              institution?: {__typename?: 'Institution'; name: string} | null
              ownershipSummary?: {
                __typename?: 'LinkedAccountOwnershipSummary'
                ownerType: Types.LinkedAccountOwnerType
                ownershipStatus: Types.LinkedAccountOwnershipStatus
                validationErrors?: Array<{
                  __typename?: 'LinkedAccountOwnershipValidationError'
                  validationStatus: Types.LinkedAccountOwnershipValidationStatus
                  validationType: Types.LinkedAccountOwnershipValidationType
                }> | null
              } | null
            } | null
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {
            __typename?: 'DebitCardPaymentMethod'
            bankingPaymentInstrumentId: string
            code: Types.PaymentMethodTypeCode
            fundingSourceId?: string | null
            id: string
            card: {
              __typename?: 'LinkedDebitCard'
              mask?: string | null
              friendlyName: string
              account?: {
                __typename?: 'LinkedAccount'
                id: string
                isDeletable: boolean
                isLoginRequired: boolean
                type: Types.LinkedAccountType
              } | null
              institution?: {__typename?: 'Institution'; name: string} | null
            }
            status:
              | {
                  __typename?: 'ExpiredPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'InvalidPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'PendingPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'RemovedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SuspendedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'SystemPurgedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
              | {
                  __typename?: 'VerifiedPaymentMethodStatus'
                  code: Types.PaymentMethodStatusCode
                  reason?: string | null
                }
          }
        | null
      > | null
    }
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        mask: string
        name?: string | null
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        institution?: {__typename?: 'Institution'; name: string} | null
        ownershipSummary?: {
          __typename?: 'LinkedAccountOwnershipSummary'
          ownerType: Types.LinkedAccountOwnerType
          ownershipStatus: Types.LinkedAccountOwnershipStatus
          validationErrors?: Array<{
            __typename?: 'LinkedAccountOwnershipValidationError'
            validationStatus: Types.LinkedAccountOwnershipValidationStatus
            validationType: Types.LinkedAccountOwnershipValidationType
          }> | null
        } | null
      }> | null
    }
  }
}

export const AccountManagementAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AccountManagementAccounts'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'paymentMethods'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'paymentDefault'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'CheckPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'AchPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'account'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'card'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'account'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'id'},
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'typeCodeFilter'},
                            value: {
                              kind: 'ListValue',
                              values: [
                                {kind: 'EnumValue', value: 'DEBIT_CARD'},
                                {kind: 'EnumValue', value: 'ACH'},
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'account'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'isDeletable'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'isLoginRequired'},
                                              },
                                              {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'fundingSourceId'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'status'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'account'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'achNumbersAvailable'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'ownershipSummary'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'ownerType'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'ownershipStatus'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'validationErrors'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'validationStatus',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'validationType'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'isLoginRequired'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'preferredFundingSource'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'accountFilter'},
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {kind: 'Name', value: 'onlyAchEligible'},
                                  value: {kind: 'BooleanValue', value: true},
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'ownershipSummary'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'ownerType'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'ownershipStatus'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'validationErrors'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'validationType'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'validationStatus'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountManagementAccountsQuery,
  AccountManagementAccountsQueryVariables
>
