import {init as logRocketInit, identify} from 'logrocket'

import {EnvironmentType} from '@possible/cassandra'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import Log from 'src/lib/loggingUtil'

type IdentifyUser = (userId: string, params?: Parameters<typeof identify>[0]) => void

/* Sadly there isn't a better way to get this type 😢 LogRocket Web types are safe to use in mobile
 * code but not the other way around. */
type LogRocketNetworkParams = Required<
  NonNullable<NonNullable<Parameters<typeof logRocketInit>[1]>['network']>
>
type RequestSanitizer = LogRocketNetworkParams['requestSanitizer']
type ResponseSanitizer = LogRocketNetworkParams['responseSanitizer']

const getLogRocketAppId = async (): Promise<string> =>
  (await getEnvironment()) === EnvironmentType.Prod
    ? 'dokrpq/possible-production'
    : 'dokrpq/possible-dev'

const sessionReplayLogger = (error: unknown, message: string): void => {
  Log.error(error, `[SessionReplay]: ${message}`)
}

const requestSanitizer: RequestSanitizer = (request) => {
  request.body = undefined

  return request
}

const responseSanitizer: ResponseSanitizer = (response) => {
  response.body = undefined

  return response
}

const redactionTag = 'private'

export {getLogRocketAppId, sessionReplayLogger, redactionTag, requestSanitizer, responseSanitizer}
export type {IdentifyUser}
