import React from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {NamedColors} from 'src/designSystem/colors'
import type {ValidPaymentSchedule} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate.utils'
import {t} from 'i18next'

import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'

type PaymentScheduleProps = {
  disclaimer: string | JSX.Element
  header: string
  schedule: ValidPaymentSchedule[]
  routedFromDashboard?: boolean
}

const PaymentSchedule = ({
  disclaimer,
  header,
  schedule,
  routedFromDashboard,
}: PaymentScheduleProps): JSX.Element => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  return (
    <>
      <PFText variant={'p_semibold'}>{header}</PFText>

      <Box paddingBottom={'small'} paddingTop={'little'} gap={'little'}>
        {schedule.slice(0, 3).map((item, index) => {
          if (index === 0) {
            return (
              <Box
                direction="row"
                justify="between"
                key={`${item.__typename}:${item.id}`}
                align="center"
              >
                <PFText variant={'p'} color={NamedColors.SILVER}>
                  {formatDate(item.paymentDate, friendlyDateWithDOW, utcDate)}
                </PFText>
                {routedFromDashboard ? (
                  <SvgLink
                    onPress={(): void => {
                      navigation.navigate('CardRescheduleUpcomingPayment')
                    }}
                    linkText={t('Reschedule')}
                    linkType={'inline'}
                    linkIcon={'internal'}
                  />
                ) : null}
              </Box>
            )
          }
          return (
            <PFText key={`${item.__typename}:${item.id}`} variant={'p'} color={NamedColors.SILVER}>
              {formatDate(item.paymentDate, friendlyDateWithDOW, utcDate)}
            </PFText>
          )
        })}
      </Box>
      <PFText variant={'p_sm'} color={NamedColors.SILVER}>
        {disclaimer}
      </PFText>
    </>
  )
}

export {PaymentSchedule}
export type {PaymentScheduleProps}
