import React, {FC} from 'react'
import {Pressable} from 'react-native'

import withDoubleClickPrevention from 'src/designSystem/components/atoms/WithDoubleClickPrevention/WithDoubleClickPrevention'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {
  GetSvgLinkIconProps,
  SvgLinkProps,
  getSvgLinkIcon,
  getSvgLinkTextColor,
} from 'src/designSystem/components/atoms/SvgLink/SvgLink.utils'
import {Color} from 'src/designSystem/types'
import {isDeviceAndroid, isDeviceWeb} from 'src/lib/utils/platform'

// the "single" variant is very similar to ButtonLink, it will replace ButtonLink as part of the work in ENG-17576
// alignment between the text and the icon is more reliable in the "single" variant when changing between textVariants and iconSizes
// because of the use of PFText in the "inline" variant (necessary for using it inline correctly)
const SvgLink: FC<SvgLinkProps> = (props) => {
  const {
    accessibilityLabel,
    disabled: isDisabled,
    linkColor = 'info',
    linkIcon,
    linkIconFilled: isLinkIconFilled,
    linkIconSize = 'small',
    linkText,
    linkType,
    onPress,
    testID = 'SvgLink',
    textTransform,
    textVariant = 'p',
    linkIconPlacement = 'after',
  } = props

  const handleOnPress = isDisabled ? undefined : onPress

  const isWebUser = isDeviceWeb()
  const isAndroidUser = isDeviceAndroid()

  const getSvgLinkIconProps: GetSvgLinkIconProps = {
    disabled: isDisabled,
    isDeviceAndroid: isAndroidUser,
    isDeviceWeb: isWebUser,
    linkColor,
    linkIcon,
    linkIconFilled: isLinkIconFilled,
    linkIconSize,
    svgLinkType: linkType,
    textVariant,
  }

  const textColor: Color = getSvgLinkTextColor(linkColor)

  if (linkType === 'inline') {
    return (
      <PFText
        onPress={handleOnPress}
        testID={testID}
        textProps={{
          accessibilityRole: 'link',
          accessibilityLabel,
        }}
        variant={textVariant}
      >
        {linkIconPlacement === 'before' ? (
          <PFText variant={textVariant} color={textColor}>
            {getSvgLinkIcon(getSvgLinkIconProps)}
          </PFText>
        ) : null}
        <PFText
          variant={textVariant}
          color={textColor}
          textProps={{
            style: {
              textTransform,
            },
          }}
        >
          {linkText}
        </PFText>
        {linkIconPlacement === 'after' ? (
          <PFText variant={textVariant} color={textColor}>
            {getSvgLinkIcon(getSvgLinkIconProps)}
          </PFText>
        ) : null}
      </PFText>
    )
  } else {
    return (
      <Pressable
        onPress={handleOnPress}
        testID={testID}
        accessibilityRole="link"
        disabled={isDisabled}
        accessibilityLabel={accessibilityLabel}
      >
        <Box direction={'row'} align={'center'} wrap={'wrap'}>
          {linkIconPlacement === 'before' ? getSvgLinkIcon(getSvgLinkIconProps) : null}

          <PFText
            color={isDisabled ? 'textDisabled' : textColor}
            variant={textVariant}
            textProps={{style: {textTransform}}}
          >
            {linkText}
          </PFText>
          {linkIconPlacement === 'after' ? getSvgLinkIcon(getSvgLinkIconProps) : null}
        </Box>
      </Pressable>
    )
  }
}

const SvgLinkComponent = withDoubleClickPrevention(SvgLink)

export {SvgLinkComponent as SvgLink}
