import {getExperimentVariant} from 'src/lib/experimentation/amplitude/amplitude'

import {ExperimentNamesType} from 'src/lib/experimentation/amplitude/experimentTypes'

export const getIsFeatureFlagEnabled = (key: ExperimentNamesType): boolean => {
  return getExperimentVariant(key).value === 'treatment' || getExperimentVariant(key).value === 'on'
}

export const useIsFeatureFlagEnabled = (key: ExperimentNamesType): boolean => {
  return getIsFeatureFlagEnabled(key)
}
