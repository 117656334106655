import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {UseApprovedApplicationWithOfferIdResultType} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.types'
import {ApprovedApplicationsQueryApplicationSelector} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.utils'
import {ApprovedApplicationsDocument} from 'src/products/general/ApplicationActivationWorkflow/ApprovedApplications.gqls'

const ApplicationNotFoundErrorMessage = 'Application not found'
const UnknownProductTypeErrorMessage = 'Unknown product type'

/**
 * Get an approved application to activate; this function
 * makes a big assumption which holds true today: that there
 * will only be one approved application at a time. If this
 * assumption changes, this function will need to be updated.
 * @returns {UseApprovedApplicationWithOfferIdResultType}
 */
const useApprovedApplication = (): UseApprovedApplicationWithOfferIdResultType => {
  const queryResult = useCassandraQuery(
    ApprovedApplicationsDocument,
    {
      // we want to have full control over when this is re-fetched so that it doesn't
      // run at unexpected times and cause the ApplicationActivationWorkflow to re-render unnecessarily
      fetchPolicy: 'no-cache',
    },
    ApprovedApplicationsQueryApplicationSelector,
  )

  const {selectedData, loading: isLoading, error} = queryResult
  if (error) {
    return {
      status: 'ERROR',
      error,
      refetch: queryResult.refetch,
    }
  }

  if (isLoading) {
    return {
      status: 'LOADING',
    }
  }

  const approvedApplication = selectedData?.find(
    (app) =>
      app.product.status.__typename === 'ApprovedCardAccountStatus' ||
      app.product.status.__typename === 'ApprovedLoanStatus',
  )
  const activatedApplication = selectedData?.find(
    (app) =>
      app.product.status.__typename === 'ActiveCardAccountStatus' ||
      app.product.status.__typename === 'ActiveLoanStatus',
  )

  if (!approvedApplication) {
    // if there is an activated application they probably just activated it in the corresponding
    if (activatedApplication && activatedApplication.product.__typename === 'Loan') {
      return {
        status: 'ACTIVATED',
        productTypename: 'Loan',
        application: activatedApplication,
        loan: activatedApplication?.product,
        refetch: queryResult.refetch,
      }
    } else if (activatedApplication && activatedApplication.product.__typename === 'CardAccount') {
      return {
        status: 'ACTIVATED',
        productTypename: 'CardAccount',
        application: activatedApplication,
        cardAccount: activatedApplication?.product,
        refetch: queryResult.refetch,
      }
    }
    return {
      status: 'ERROR',
      error: new Error(ApplicationNotFoundErrorMessage),
      refetch: queryResult.refetch,
    }
  }

  if (approvedApplication.product.__typename === 'Loan') {
    return {
      status: 'READY',
      productTypename: 'Loan',
      application: approvedApplication,
      loan: approvedApplication.product,
      refetch: queryResult.refetch,
    }
  } else if (approvedApplication.product.__typename === 'CardAccount') {
    return {
      status: 'READY',
      productTypename: 'CardAccount',
      application: approvedApplication,
      cardAccount: approvedApplication.product,
      refetch: queryResult.refetch,
    }
  }

  return {
    status: 'ERROR',
    error: new Error(UnknownProductTypeErrorMessage),
    refetch: queryResult.refetch,
  }
}

export {useApprovedApplication}
