import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  PaymentNotification,
  PaymentNotificationLevel,
} from 'src/products/card/components/molecules/PaymentNotification/PaymentNotification'
import {useCards} from 'src/products/card/hooks/useCards'
import {CardAccountDashboardStatus} from 'src/products/card/types'
import {formatDate, humanReadableDateShortOrdinal} from 'src/lib/utils/date'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {Maybe, OverDuePaymentInfo} from 'src/cassandra'

const getVariant = (
  accountStatus: CardAccountDashboardStatus | undefined,
): PaymentNotificationLevel => {
  switch (accountStatus) {
    case CardAccountDashboardStatus.Overdue:
    case CardAccountDashboardStatus.OverdueNSF:
    case CardAccountDashboardStatus.AtRiskDelinquency:
    case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
      return PaymentNotificationLevel.Warn
    case CardAccountDashboardStatus.Delinquent:
    case CardAccountDashboardStatus.DelinquentNSF:
    case CardAccountDashboardStatus.DelinquencyReported:
    case CardAccountDashboardStatus.DelinquencyReportedNSF:
    case CardAccountDashboardStatus.DeactivatedDelinquent:
    case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
      return PaymentNotificationLevel.Error
    default:
      return PaymentNotificationLevel.Warn
  }
}

const MissedPaymentTile: FC = () => {
  const {activeCardStatus, accountStatus} = useCards()
  const {t} = useTranslation(['MissedPaymentsTile'])

  let overduePaymentInfo: Maybe<OverDuePaymentInfo> | undefined
  if (activeCardStatus.__typename === 'DeactivatedCardAccountStatus') {
    overduePaymentInfo = activeCardStatus?.overdueStatementInfo
  } else if (activeCardStatus.__typename === 'ActiveCardAccountStatus') {
    overduePaymentInfo = activeCardStatus?.overduePaymentInfo
  }

  if (!overduePaymentInfo) {
    return null
  }

  const {amount, paymentDueAt} = overduePaymentInfo

  return (
    <PaymentNotification
      variant={getVariant(accountStatus)}
      title={t('MissedPayment')}
      content={t('WasDue', {
        amountOverdue: convertToDollarAmt(amount),
        overdueDate: formatDate(paymentDueAt, humanReadableDateShortOrdinal),
      })}
    />
  )
}

export default MissedPaymentTile
