import React, {FC} from 'react'

import {useGetRejectionText} from 'src/products/card/LoanDash/useGetRejectionText'
import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {TilesToDisplayOnce} from 'src/products/card/LoanDash/utils'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'

type CardRejectedTileProps = BaseCardProps

export const CardRejectedTile: FC<CardRejectedTileProps> = ({
  loading,
  tileMarginHorizontal,
  tileRadius,
}) => {
  const {title, body} = useGetRejectionText()
  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText={title}
      content={body}
      loading={loading}
      displayOnceKey={TilesToDisplayOnce.CardRejectedTile}
      testID="CardApplicationRejectedBase"
      eventName={CardEvents.card_reject_dashboard_card_viewed}
      eventCategory={AppEvents.Category.Card}
    />
  )
}
