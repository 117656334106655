import React, {useState} from 'react'

import {CardAgreementType} from '@possible/cassandra/src/types/types.mobile.generated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {CardAcceptAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptAgreement'
import {CardActivationAutopayScheduleTemplateProps} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleTemplate/CardActivationAutopayScheduleTemplate'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {CardPaymentScheduleTemplate} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate'
import {useTranslation} from 'react-i18next'

type CardReviewAutopayTemplateProps = Omit<
  CardActivationAutopayScheduleTemplateProps,
  'onSelectManualPay'
> & {
  /** Because of the shared container, `CardReviewAutopayTemplate` accepts but does not use the
   * `onSelectManualPay` prop.
   */
  onSelectManualPay?: CardActivationAutopayScheduleTemplateProps['onSelectManualPay']
}

/**
 * The `CardReviewAutopayTemplate` is based on `CardPaymentScheduleTemplate` but has the API of
 * `CardActivationAutopayScheduleTemplate` because they share the same container
 * (`CardAutopayScheduleReduxContainer`).
 *
 * Note: Because of the shared container, `CardReviewAutopayTemplate` accepts but does not use the
 * `onSelectManualPay` prop.
 */
const CardReviewAutopayTemplate = ({
  isLoading,
  onPressPrimary,
  primaryButtonText,
  onSelectManualPay, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...props
}: CardReviewAutopayTemplateProps): JSX.Element => {
  const {t} = useTranslation(['CardReviewAutopay', 'Common', 'CardPaymentAgreement'])
  const [isDebitAuthorizationAccepted, setIsDebitAuthorizationAccepted] = useState(false)

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: primaryButtonText ?? t('Common:Continue'),
      onPress: onPressPrimary,
      disabled: isLoading || !isDebitAuthorizationAccepted,
      loading: isLoading,
    },
  }
  const isUserMinPayProgram = props.minPayData?.supportsMinPay

  return (
    <CardPaymentScheduleTemplate
      buttonProps={buttonProps}
      subtitle={isUserMinPayProgram ? t('MinPaySubtitle') : t('Subtitle')}
      title={isUserMinPayProgram ? t('MinPayTitle') : t('Title')}
      testID={'CardReviewAutopayTemplate'}
      {...props}
    >
      <Box paddingTop={'enormous'} justify={'end'}>
        <CardAcceptAgreement
          checked={isDebitAuthorizationAccepted}
          setChecked={setIsDebitAuthorizationAccepted}
          cardAgreementType={CardAgreementType.DebitAuthorizationAgreement}
          agreementName={t('CardPaymentAgreement:DebitAuthorizationAgreement')}
        />
      </Box>
    </CardPaymentScheduleTemplate>
  )
}

export {CardReviewAutopayTemplate}
