import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import PlaidLogo from 'src/assets/illustrations/Plaid_Logo.svg'
import PossibleLogo from 'src/assets/illustrations/PossibleLogo.svg'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type ApplyInOneClickTemplateProps = {
  handleStartOneClickApplication: () => void
  isContinueButtonLoading?: boolean
}

const ApplyInOneClickTemplate: React.FC<ApplyInOneClickTemplateProps> = (props) => {
  const {handleStartOneClickApplication, isContinueButtonLoading} = props
  const {t} = useTranslation(['ApplyInOneClick', 'Common'])

  const buttonProps: ButtonLockupPropsPrimary = {
    text: t('Common:Continue'),
    onPress: handleStartOneClickApplication,
    testID: 'Apply-In-One-Click-Start-Button',
    mode: 'primary',
    loading: isContinueButtonLoading,
    disabled: isContinueButtonLoading,
  }

  const title = (
    <Box paddingHorizontal={'large'} marginHorizontal={'large'}>
      <PFText variant={'h2'} textAlign="center" color={NamedColors.BLACK}>
        <Trans
          t={t}
          i18nKey={'ApplyInOneClickTitle'}
          components={{
            blue: <PFText variant={'h2'} color={NamedColors.PRODUCT_BLUE} />,
          }}
        />
      </PFText>
    </Box>
  )

  const applySteps = [
    {
      number: 1,
      text: (
        <Trans
          t={t}
          i18nKey={'FollowPlaidPrompts'}
          components={{
            bold: <PFText variant={'p_semibold'} color={NamedColors.BLACK} />,
          }}
        />
      ),
    },
    {
      number: 2,
      text: (
        <Trans
          t={t}
          i18nKey={'ConfirmYourInfoIsCorrect'}
          components={{
            bold: <PFText variant={'p_semibold'} color={NamedColors.BLACK} />,
          }}
        />
      ),
    },
    {
      number: 3,
      text: (
        <Trans
          t={t}
          i18nKey={'WeWillUseInfoLikeCashFlowToQualify'}
          components={{
            bold: <PFText variant={'p_semibold'} color={NamedColors.BLACK} />,
          }}
        />
      ),
    },
  ]

  const renderApplySteps = (
    <Box direction="column" gap="medium" paddingRight={'large'} fill="horizontal">
      {applySteps.map((step) => (
        <Box direction="row" align="center" key={step.number}>
          <Box
            width={32}
            height={32}
            background={NamedColors.WHITESMOKE}
            justify="center"
            align="center"
            radius={'enormous'}
            marginRight={'small'}
          >
            <PFText variant={'p_semibold'} color={NamedColors.PRODUCT_BLUE} textAlign="center">
              {step.number}
            </PFText>
          </Box>
          <Box direction="row" width={'100%'}>
            <PFText variant={'p'} color={NamedColors.BLACK} textAlign="left">
              {step.text}
            </PFText>
          </Box>
        </Box>
      ))}
    </Box>
  )

  const renderDataSecurityNote = (
    <Box background={NamedColors.WHITESMOKE} radius={10} padding={14} paddingHorizontal={38}>
      <PFText variant={'p'} color={NamedColors.SILVER} textAlign="center">
        <Trans
          t={t}
          i18nKey={'YourDataSecurityIsOurPriority'}
          components={{
            bold: <PFText variant={'p_semibold'} color={NamedColors.SILVER} />,
          }}
        />
      </PFText>
    </Box>
  )

  const content = (
    <Box paddingHorizontal={'large'} marginTop={'medium'} background="white" gap={'large'}>
      <Box marginTop={'tiny'} direction={'row'} align={'center'} justify="center" gap={'small'}>
        <PFSvgContain svg={PossibleLogo} height={20} />
        <SvgIcon name={'plus'} colorVariant={'inactive'} />
        <PFSvgContain svg={PlaidLogo} height={26} />
      </Box>
      <Box>
        <PFText variant={'p'} color={NamedColors.BLACK}>
          {t('YouAreEligible')}
        </PFText>
      </Box>
      <Box direction="column" gap="small">
        <Box>{renderApplySteps}</Box>
      </Box>
      <Box>{renderDataSecurityNote}</Box>
    </Box>
  )

  return (
    <Page
      title={title}
      variant={'generic'}
      buttonProps={buttonLockupProperties(buttonProps)}
      backgroundColor={NamedColors.SLATE}
      safeAreaBackground={NamedColors.SLATE}
      noHorizontalPadding
      smallTopGap
    >
      {content}
    </Page>
  )
}

export {ApplyInOneClickTemplate}
