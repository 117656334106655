/**
 * LaunchArgs.web.tsx
 * Launch arguments are not supported on web
 */

const ProcessAppLaunchArgs = (): void => {
  return
}

const isKeyALaunchArg = (): void => {
  return
}

export {ProcessAppLaunchArgs, isKeyALaunchArg}
