import {DestroyClient} from '@possible/cassandra'

/**
 * Clear the Cassandra GQL client on logout.
 */
export const clearCassandraOnLogout = (): void => {
  // wait one moment before destroying the client to ensure that any in-flight requests have a chance to complete
  // and avoid an error w/apollo when it doesn't have an accessible client
  setTimeout(() => {
    DestroyClient()
  }, 1000)
}
