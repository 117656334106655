import React from 'react'
import {StyleSheet, View} from 'react-native'

import {Color} from 'src/designSystem/types'
import {pfHeaderHeight, pfHeaderHeightWeb} from 'src/nav/Header/HeaderConstants'
import {isDeviceWeb} from 'src/lib/utils/platform'

export type HeaderSpacerProps = {
  backgroundColor?: Color
  height?: number
}

const HeaderSpacer = (props: HeaderSpacerProps): JSX.Element => {
  const {backgroundColor, height} = props

  const spacerStyles = [styles.spacer, {backgroundColor}]
  if (height !== undefined) {
    spacerStyles.push({height})
  }

  return <View style={spacerStyles} />
}
export default HeaderSpacer

const styles = StyleSheet.create({
  spacer: {
    height: isDeviceWeb() ? pfHeaderHeightWeb : pfHeaderHeight,
  },
})
