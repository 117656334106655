import {ApolloError} from '@apollo/client'
import {useNavigation} from '@react-navigation/native'
import React, {ReactElement} from 'react'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {
  AcceptAgreementsContinueResult,
  StateSpecificAgreement,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {AcceptLoanAndAutoPayAgreementsBaseTemplateProps} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'
import {AcceptLoanAndStateAgreementsBaseTemplateProps} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsBaseTemplate'
import {useGetAgreementDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument'
import {useSaveAcceptAgreementsResult} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useSaveAcceptAgreementsResult/useSaveAcceptAgreementsResult'
import {AcceptLoanAndStateAgreementsDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreements.gqls'
import {usePromise} from 'src/lib/usePromise/usePromise'

export type AcceptLoanAndStateAgreementsGQLContainerProps = Pick<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'onAcceptAndContinue'
> & {
  testID: string
  /**
   * Allow the GQL container to fetch the necessary data and pass it to a child using a render() prop.
   * That way we can use one data container for multiple child templates since there are a number of variations of
   * this screen that will all need the same data.
   */
  render: (
    props: Omit<
      AcceptLoanAndStateAgreementsBaseTemplateProps,
      // these two will be provided by the child screen template that uses AcceptLoanAndStateAgreementsBaseTemplate
      'stateSpecificAgreements' | 'loanAgreementTestID'
    >,
  ) => ReactElement
}

/**
 * Data container responsible for fetching and storying any data necessary for accepting agreements.
 * Accepts a render prop so any child template using the base template AcceptLoanAndStateAgreementsBaseTemplate
 * can be used with this data.
 */
export const AcceptLoanAndStateAgreementsGQLContainer: React.FC<
  AcceptLoanAndStateAgreementsGQLContainerProps
> = (props) => {
  const {render, onAcceptAndContinue} = props
  const {
    selectedData,
    error: queryError,
    loading: isLoading,
  } = useCassandraQuery(
    AcceptLoanAndStateAgreementsDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationErrorAndShowException(err, 'AcceptLoanAndAutoPayAgreements query failed')
      },
    },
    (data) => {
      return {
        loanId: data.me.loans.latestActionableLoan?.id,
        loanAmountBorrowed:
          data.me.loans.latestActionableLoan?.aggregateStatus?.__typename ===
          'ApprovedLoanAggregateStatus'
            ? data.me.loans.latestActionableLoan?.aggregateStatus.amountApproved
            : '',
      }
    },
  )
  const navigation = useNavigation()
  const {onSaveAcceptAgreementsResult} = useSaveAcceptAgreementsResult()
  const {getAgreementDocument} = useGetAgreementDocument()

  const loanId = selectedData?.loanId
  const loanAmountBorrowed = selectedData?.loanAmountBorrowed

  /**
   * Open an agreement document PDF when a user selects it.
   */
  const handleOnOpenAgreement = async (
    stateSpecificAgreement: StateSpecificAgreement,
  ): Promise<void> => {
    if (!loanId) {
      logOfferActivationErrorAndShowException(
        new Error('AcceptLoanAndStateAgreementsGQLContainer failed to open document, no loanId'),
      )
      return
    }
    try {
      const {documentUrl} = await getAgreementDocument({
        loanId,
        type: stateSpecificAgreement.documentTypeToDownload,
      })

      displayPdf(documentUrl, navigation)
    } catch (e) {
      logOfferActivationErrorAndShowException(e, 'Failed get and open agreement document url')
      return
    }
  }

  /**
   * Record this user's choices when they continue.
   */
  const [handleOnAcceptAndContinue, {isLoading: isOnAcceptAndContinueExecuting}] = usePromise(
    async (agreementsResult: AcceptAgreementsContinueResult): Promise<void> => {
      if (!loanId) {
        logOfferActivationErrorAndShowException(
          new Error(
            'AcceptLoanAndStateAgreementsGQLContainer no loanId found when accepting agreements and continuing',
          ),
        )
        return
      }
      try {
        await onSaveAcceptAgreementsResult({loanId, agreementsResult})
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptLoanAndStateAgreementsGQLContainer handleOnAcceptAndContinue() failed to submit',
        )
        return
      }

      await onAcceptAndContinue(agreementsResult)
    },
  )

  return render({
    ...props,
    isLoading: isLoading,
    isError: !!queryError,
    isSubmitting: isOnAcceptAndContinueExecuting,
    loanAmountBorrowed: loanAmountBorrowed ?? '',
    onOpenAgreementDocument: handleOnOpenAgreement,
    onAcceptAndContinue: (agreementsResult): void => {
      handleOnAcceptAndContinue(agreementsResult).catch((e) => {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptLoanAndStateAgreementsGQLContainer failed to accept and continue',
        )
      })
    },
  })
}
