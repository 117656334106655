import {StackScreenProps} from '@react-navigation/stack'
import React, {useState} from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {ApplyInOneClickTemplate} from 'src/products/general/ApplyInOneClick/ApplyInOneClickTemplate'
import {usePlaidLayersContext} from 'src/products/MCU/PlaidLayers/PlaidLayersContext'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'

type ApplyInOneClickContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'ApplyInOneClick'>

const ApplyInOneClickContainer: React.FC<ApplyInOneClickContainerProps> = (props): JSX.Element => {
  const {navigation, onPreReqFulfilled} = props
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [hasPushPermissions] = usePushPermissions()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.plaid_layer_view_layer_prompt,
    eventCategory: AppEvents.Category.PlaidSDK,
  })

  navigation.setOptions({
    headerShown: false,
  })

  const onExit = (): void => {
    navigation.navigate('PhoneConfirmation', {
      optedOutOfPlaidLayer: true,
    })
  }

  const onSuccess = async (): Promise<void> => {
    setIsBusy(true)
    if (!hasPushPermissions) {
      navigation.navigate('NotificationsPermissions')
    } else {
      await onPreReqFulfilled()
    }
    setIsBusy(false)
  }

  const onFailProcessProfile = async (): Promise<void> => {
    await onPreReqFulfilled()
  }

  const {isLoadingPlaidData, openPlaidLayers} = usePlaidLayersContext()

  const onHandleStartOneClickApplication = (): void => {
    TrackAppEvent(AppEvents.Name.plaid_layer_layer_prompt_complete, AppEvents.Category.PlaidSDK)
    setIsBusy(true)
    void openPlaidLayers(onSuccess, onFailProcessProfile, onExit)
  }

  return (
    <ApplyInOneClickTemplate
      handleStartOneClickApplication={onHandleStartOneClickApplication}
      isContinueButtonLoading={isLoadingPlaidData || isBusy}
    />
  )
}

export {ApplyInOneClickContainer}
