import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {AccountsWithPossibleTemplate} from 'src/products/MCU/AccountManagementV2/AccountsWithPossible/AccountsWithPossibleTemplate'

type AccountsWithPossibleProps = StackScreenProps<MainStackParamList, 'AccountsWithPossible'>

/**
 * Page that displays information about how payment accounts work with Possible (primary vs additional).
 */
export const AccountsWithPossible: React.FC<AccountsWithPossibleProps> = ({navigation}) => {
  const handleOnContinue = (): void => {
    navigation.goBack()
  }
  return <AccountsWithPossibleTemplate onContinue={handleOnContinue} />
}
