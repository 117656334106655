import React, {useState, useEffect} from 'react'
import {StackHeaderProps} from '@react-navigation/stack'
import {StyleSheet, View} from 'react-native'

import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import {HeaderCloseXButton} from 'src/nav/Header/HeaderCloseXButton/HeaderCloseXButton'
import {
  LeftButtonStateTypes,
  iconSize,
  headerIconHeight,
  headerIconLeftPadding,
  pfHeaderHeight,
} from 'src/nav/Header/HeaderConstants'
import {SafeAreaView} from 'react-native-safe-area-context'

export const noLeftButtonProp = 'noLeftButton'

type Props = StackHeaderProps
const ModalStackHeader = (props: Props) => {
  const [leftButton, setLeftButton] = useState(LeftButtonStateTypes.NONE)
  const {route, navigation} = props

  const processState = (currentPageName: string, pageParams: object | undefined) => {
    if (currentPageName === 'SideMenu') {
      setLeftButton(LeftButtonStateTypes.CLOSE)
      return
    }
    if (pageParams?.[noLeftButtonProp]) {
      setLeftButton(LeftButtonStateTypes.NONE)
      return
    }
    if (!navigation.canGoBack()) {
      setLeftButton(LeftButtonStateTypes.CLOSE)
    } else {
      setLeftButton(LeftButtonStateTypes.BACK)
    }
  }

  const leftButtonComp = () => {
    switch (leftButton) {
      case LeftButtonStateTypes.BACK:
        return (
          <View style={styles.imageView}>
            <HeaderBackButton navigation={navigation} size={iconSize} />
          </View>
        )
      case LeftButtonStateTypes.CLOSE:
        return (
          <View style={styles.imageView}>
            <HeaderCloseXButton navigation={navigation} size={iconSize} />
          </View>
        )
      case LeftButtonStateTypes.MENU:
      case LeftButtonStateTypes.NONE:
        return null
    }
  }

  useEffect(() => processState(route.name, route.params))

  return (
    <SafeAreaView edges={['top']}>
      <View style={[{flexDirection: 'row', alignItems: 'center'}, styles.container]}>
        <View style={{flex: 1, justifyContent: 'flex-start'}}>{leftButtonComp()}</View>
        <View style={{flex: 1}} />
        <View style={{flex: 1}} />
      </View>
    </SafeAreaView>
  )
}
export default ModalStackHeader

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: pfHeaderHeight,
    width: '100%',
  },
  imageView: {
    height: headerIconHeight,
    marginLeft: headerIconLeftPadding,
    width: headerIconHeight,
  },
})
