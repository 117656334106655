/* eslint-disable complexity */
import React, {useCallback} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'
import {Trans, useTranslation} from 'react-i18next'

import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {littleGap} from 'src/designSystem/layout'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCardsType} from 'src/products/card/hooks/useCards'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {CardAccountDashboardStatus} from 'src/products/card/types'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {CardDashboardPaymentBodyDeactivated} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentBodyDeactivated'
import {formatDate, graphqlDateFormat, humanReadableDateDayMonth} from 'src/lib/utils/date'
import {useHasPendingPaymentForStatementBalance} from 'src/products/card/hooks/useHasPendingPaymentForStatementBalance'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {
  isOver150DaysDelinquent,
  isOver30DaysDelinquent,
  isOver60DaysDelinquent,
  isOver90DaysDelinquent,
} from 'src/products/card/Dashboard/util'

type CardDashboardPaymentBodyProps = Pick<
  useCardsType,
  | 'daysUntilDelinquent'
  | 'daysUntilReportedDelinquent'
  | 'accountStatus'
  | 'autopayEnabled'
  | 'activeStatement'
  | 'delinquentNumberOfDays'
> &
  Pick<ReturnType<typeof useTranslation>, 't'>

export const CardDashboardPaymentBody: React.FC<CardDashboardPaymentBodyProps> = ({
  accountStatus,
  daysUntilDelinquent,
  daysUntilReportedDelinquent,
  autopayEnabled,
  activeStatement,
  t,
  delinquentNumberOfDays,
}) => {
  const hasCardCreditReportingFeatureFlag = useIsFeatureFlagEnabled(
    'card-credit-reporting-ui-updates',
  )
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const isStatementBalancePending = useHasPendingPaymentForStatementBalance()
  const handleNavigateToHowDoesDelinquencyWork = useCallback(() => {
    navigation.navigate('CardHowDelinquencyAffectMe')
  }, [navigation])

  // move these to util when we refactor late state into separate components

  const BulletPoint = (): JSX.Element => (
    <Box paddingHorizontal="tiny">
      <PFText variant="p_semibold">{`\u2022`}</PFText>
    </Box>
  )
  const getDelinquentReportedText = ():
    | {
        creditImpact: number
        daysDelinquent: string
        reportedAt: string
      }
    | undefined => {
    if (isOver30DaysDelinquent(delinquentNumberOfDays)) {
      return {
        creditImpact: 60 - (delinquentNumberOfDays ?? 0),
        daysDelinquent: '30+',
        reportedAt: '60',
      }
    }
    if (isOver60DaysDelinquent(delinquentNumberOfDays)) {
      return {
        creditImpact: 90 - (delinquentNumberOfDays ?? 0),
        daysDelinquent: '60+',
        reportedAt: '90',
      }
    }
    if (isOver90DaysDelinquent(delinquentNumberOfDays)) {
      return {
        creditImpact: 150 - (delinquentNumberOfDays ?? 0),
        daysDelinquent: '90+',
        reportedAt: '180',
      }
    }
    if (isOver150DaysDelinquent(delinquentNumberOfDays)) {
      return {
        creditImpact: 180 - (delinquentNumberOfDays ?? 0),
        daysDelinquent: '90+',
        reportedAt: '180',
      }
    }
  }
  const getBodyText = useCallback(() => {
    switch (accountStatus) {
      case CardAccountDashboardStatus.OverdueNSF:
        return (
          <PFText
            variant="p"
            textAlign="center"
            testID="Overdue-NSF-Status-Id"
            textProps={{style: {paddingBottom: littleGap}}}
          >
            {t('OverdueNSF')}
          </PFText>
        )
      case CardAccountDashboardStatus.AtRiskDelinquency:
        return (
          <PFText variant="p" textAlign="center" textProps={{style: {paddingBottom: littleGap}}}>
            <Trans
              t={t}
              i18nKey="AtRiskDelinquency"
              components={{bold: <PFText variant="p_semibold" />}}
              values={{daysUntilDelinquent: daysUntilDelinquent}}
            />
          </PFText>
        )
      case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
        return (
          <PFText variant="p" textAlign="center" textProps={{style: {paddingBottom: littleGap}}}>
            <Trans
              t={t}
              i18nKey="AtRiskDelinquencyNSF"
              components={{bold: <PFText variant="p_semibold" />}}
              values={{daysUntilDelinquent: daysUntilDelinquent}}
            />
          </PFText>
        )
      case CardAccountDashboardStatus.Delinquent:
        return (
          <Box paddingHorizontal={'large'} justify={'center'} align={'center'}>
            <PFText variant="p" textAlign="center" textProps={{style: {paddingBottom: littleGap}}}>
              <Trans
                t={t}
                i18nKey={hasCardCreditReportingFeatureFlag ? 'DelinquentDynamic' : 'Delinquent'}
                components={{
                  bold: (
                    <PFText
                      variant="p_semibold"
                      textAlign="center"
                      textProps={{style: {paddingBottom: littleGap}}}
                    />
                  ),
                }}
                values={{daysUntilReportedDelinquent: daysUntilReportedDelinquent}}
              />
            </PFText>
            <Box align={'center'}>
              <SvgLink
                onPress={handleNavigateToHowDoesDelinquencyWork}
                linkIcon={'internal'}
                linkText={t('TapForDetails')}
                linkType={'single'}
              />
            </Box>
          </Box>
        )
      case CardAccountDashboardStatus.DelinquentNSF:
        return (
          <Box paddingHorizontal={'large'} justify={'center'} align={'center'}>
            <PFText variant="p" textAlign="center" textProps={{style: {paddingBottom: littleGap}}}>
              <Trans
                t={t}
                i18nKey={hasCardCreditReportingFeatureFlag ? 'DelinquentDynamic' : 'DelinquentNSF'}
                components={{
                  bold: (
                    <PFText
                      variant="p_semibold"
                      textAlign="center"
                      textProps={{style: {paddingBottom: littleGap}}}
                    />
                  ),
                }}
                values={{daysUntilReportedDelinquent: daysUntilReportedDelinquent}}
              />
            </PFText>
            <SvgLink
              onPress={handleNavigateToHowDoesDelinquencyWork}
              linkText={t('TapForDetails')}
              linkType={'single'}
            />
          </Box>
        )

      case CardAccountDashboardStatus.DelinquencyReported:
        if (hasCardCreditReportingFeatureFlag) {
          if (
            isOver30DaysDelinquent(delinquentNumberOfDays) ||
            isOver60DaysDelinquent(delinquentNumberOfDays) ||
            isOver90DaysDelinquent(delinquentNumberOfDays) ||
            isOver150DaysDelinquent(delinquentNumberOfDays)
          ) {
            return (
              <Box>
                <Box>
                  <Box background="black" radius={4} justify="center" align="center" padding="tiny">
                    <PFText variant="label_sm" color="white">
                      {t(
                        !isOver150DaysDelinquent
                          ? 'nextNegativeCreditImpact'
                          : 'accountWillBeChargedOff',
                        {
                          daysUntilNextCreditImpact: getDelinquentReportedText()?.creditImpact,
                        },
                      )}
                    </PFText>
                  </Box>
                  <Box paddingTop="small">
                    <PFText variant="p">
                      {t(
                        isOver30DaysDelinquent(delinquentNumberOfDays)
                          ? 'accountDelinquent'
                          : 'accountDelinquentPost30Days',
                        {
                          daysDelinquent: getDelinquentReportedText()?.daysDelinquent,
                        },
                      )}
                    </PFText>
                  </Box>
                  <Box paddingVertical="small">
                    <PFText variant="p_semibold">{t('payYourFullBalanceTo')}</PFText>
                  </Box>
                  <Box direction="row" align="start">
                    <BulletPoint />
                    <PFText variant="p">{t('exitDelinquency')}</PFText>
                  </Box>
                  <Box direction="row" align="start">
                    <BulletPoint />
                    <PFText variant="p">{t('unlockYourCard')}</PFText>
                  </Box>
                  <Box direction="row" align="start">
                    <BulletPoint />
                    <PFText variant="p">
                      {t('protectYourCredit', {
                        reportedAtDays: getDelinquentReportedText()?.reportedAt,
                      })}
                    </PFText>
                  </Box>
                </Box>
                <Box paddingVertical="small">
                  <SvgLink
                    onPress={handleNavigateToHowDoesDelinquencyWork}
                    linkText={t('learnMore')}
                    linkType={'single'}
                    linkIcon={'internal'}
                    linkIconSize={'little'}
                  />
                </Box>
              </Box>
            )
          } else {
            return (
              <CardDashboardPaymentBodyDeactivated
                description={
                  <Trans
                    t={t}
                    i18nKey="DelinquencyReported"
                    components={{
                      bold: (
                        <PFText
                          variant="p_semibold"
                          textAlign="center"
                          textProps={{style: {paddingBottom: littleGap}}}
                        />
                      ),
                    }}
                  />
                }
                t={t}
                onPress={handleNavigateToHowDoesDelinquencyWork}
              />
            )
          }
        } else {
          return (
            <Box paddingHorizontal={'large'} justify={'center'} align={'center'}>
              <PFText
                variant="p"
                textAlign="center"
                textProps={{style: {paddingBottom: littleGap}}}
              >
                <Trans
                  t={t}
                  i18nKey={'Delinquent'}
                  components={{
                    bold: (
                      <PFText
                        variant="p_semibold"
                        textAlign="center"
                        textProps={{style: {paddingBottom: littleGap}}}
                      />
                    ),
                  }}
                  values={{daysUntilReportedDelinquent: daysUntilReportedDelinquent}}
                />
              </PFText>
              <SvgLink
                onPress={handleNavigateToHowDoesDelinquencyWork}
                linkText={t('TapForDetails')}
                linkType={'single'}
                linkIcon={'internal'}
                linkIconSize={'little'}
              />
            </Box>
          )
        }
      case CardAccountDashboardStatus.DelinquencyReportedNSF:
        return (
          <Box paddingHorizontal={'large'} justify={'center'} align={'center'}>
            <PFText variant="p" textAlign="center" textProps={{style: {paddingBottom: littleGap}}}>
              <Trans
                t={t}
                i18nKey="DelinquencyReportedNSF"
                components={{
                  bold: <PFText variant="p_semibold" textAlign="center" />,
                }}
              />
            </PFText>
            <SvgLink
              onPress={handleNavigateToHowDoesDelinquencyWork}
              linkType="single"
              linkText={t('TapForDetails')}
              linkIcon={'internal'}
              linkIconSize={'little'}
            />
          </Box>
        )
      case CardAccountDashboardStatus.Deactivated:
        if (isStatementBalancePending) {
          return (
            <CardDashboardPaymentBodyDeactivated
              description={
                <Trans
                  t={t}
                  i18nKey="DeactivatedPendingPaymentDescription"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              t={t}
              onPress={handleNavigateToHowDoesDelinquencyWork}
            />
          )
        }

        return (
          <CardDashboardPaymentBodyDeactivated
            title={
              <Trans
                t={t}
                i18nKey={
                  activeStatement?.paymentDueDate
                    ? 'DeactivatedTitle'
                    : 'DeactivatedTitleNoStatementDate'
                }
                components={{bold: <PFText variant="p_semibold" />}}
                values={{
                  statementDate: formatDate(
                    activeStatement?.paymentDueDate as string,
                    humanReadableDateDayMonth,
                    graphqlDateFormat,
                  ),
                }}
              />
            }
            description={t(
              autopayEnabled ? 'DeactivatedAutopayDescription' : 'DeactivatedManualPayDescription',
            )}
            t={t}
            onPress={handleNavigateToHowDoesDelinquencyWork}
          />
        )
      case CardAccountDashboardStatus.DeactivatedDelinquent:
        if (isStatementBalancePending) {
          return (
            <CardDashboardPaymentBodyDeactivated
              description={
                <Trans
                  t={t}
                  i18nKey="DeactivatedDelinquentPendingPaymentDescription"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              t={t}
              onPress={handleNavigateToHowDoesDelinquencyWork}
            />
          )
        }

        return (
          <CardDashboardPaymentBodyDeactivated
            titleVariant="p"
            title={
              <Trans
                t={t}
                i18nKey={hasCardCreditReportingFeatureFlag ? 'DelinquentDynamic' : 'Delinquent'}
                components={{
                  bold: (
                    <PFText
                      variant="p_semibold"
                      textAlign="center"
                      textProps={{style: {paddingBottom: littleGap}}}
                    />
                  ),
                }}
                values={{daysUntilReportedDelinquent: daysUntilReportedDelinquent}}
              />
            }
            description={
              <Trans
                t={t}
                i18nKey="DeactivatedDelinquentDescription"
                components={{bold: <PFText variant="p_semibold" />}}
              />
            }
            t={t}
            onPress={handleNavigateToHowDoesDelinquencyWork}
          />
        )
      case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
        if (hasCardCreditReportingFeatureFlag) {
          if (isStatementBalancePending) {
            return (
              <CardDashboardPaymentBodyDeactivated
                description={
                  <Trans
                    t={t}
                    i18nKey="DeactivatedDelinquentPendingPaymentDescription"
                    components={{bold: <PFText variant="p_semibold" />}}
                  />
                }
                t={t}
                onPress={handleNavigateToHowDoesDelinquencyWork}
              />
            )
          }

          return (
            <CardDashboardPaymentBodyDeactivated
              title={
                <Trans
                  t={t}
                  i18nKey="DelinquencyReportedThirtyDays"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              description={
                <Trans
                  t={t}
                  i18nKey="DeactivatedDelinquentDescription"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              t={t}
              onPress={handleNavigateToHowDoesDelinquencyWork}
            />
          )
        } else {
          return null
        }
      case CardAccountDashboardStatus.ChargedOff:
        if (hasCardCreditReportingFeatureFlag) {
          return (
            <CardDashboardPaymentBodyDeactivated
              title={<Trans t={t} i18nKey="ChargedOffTitle" />}
              description={<Trans t={t} i18nKey="ChargedOffDescription" />}
              textAlign="left"
              t={t}
            />
          )
        } else {
          return (
            <CardDashboardPaymentBodyDeactivated
              title={
                <Trans
                  t={t}
                  i18nKey="DelinquencyReportedThirtyDays"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              description={
                <Trans
                  t={t}
                  i18nKey="DeactivatedDelinquentDescription"
                  components={{bold: <PFText variant="p_semibold" />}}
                />
              }
              t={t}
              onPress={handleNavigateToHowDoesDelinquencyWork}
            />
          )
        }
      default:
        return null
    }
  }, [
    accountStatus,
    t,
    daysUntilDelinquent,
    hasCardCreditReportingFeatureFlag,
    daysUntilReportedDelinquent,
    handleNavigateToHowDoesDelinquencyWork,
    isStatementBalancePending,
    activeStatement?.paymentDueDate,
    autopayEnabled,
    delinquentNumberOfDays,
    getDelinquentReportedText,
  ])
  return (
    <Box
      align="center"
      justify="center"
      marginTop={
        (
          [
            CardAccountDashboardStatus.Overdue,
            CardAccountDashboardStatus.Deactivated,
            CardAccountDashboardStatus.Deactivated,
          ] as (CardAccountDashboardStatus | undefined)[]
        ).includes(accountStatus)
          ? undefined
          : 'tiny'
      }
    >
      {getBodyText()}
    </Box>
  )
}
