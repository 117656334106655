import {isDeviceAndroid} from 'src/lib/utils/platform'
import {SizeVariants} from 'src/designSystem/types'

export const horizontalScale = function (x) {
  return x
}

export const verticalScale = function (y) {
  return y
}

export const scale = function (z) {
  return z
}

// Gap Management
export const tinyGap = 4
export const littleGap = 8
export const smallGap = 16
export const mediumGap = 24
export const largeGap = 32
export const enormousGap = 40

export const gapSizeMap: {[key in SizeVariants]: number} = {
  tiny: tinyGap,
  little: littleGap,
  small: smallGap,
  medium: mediumGap,
  large: largeGap,
  enormous: enormousGap,
}

export const headerHeight: number = 154 as const
export const smallHeaderHeight: number = 46 as const
export const menuHeaderHeight = 142
export const contentMaxWidth: number = 450 as const

export const paginationHeaderHeight: number = 132 as const
export const paginationStepRadius = 5 as number
export const paginationStepHeight = 5 as number

export const pageMarginHorizontal = 24
export const pageMarginVertical = 24
export const pageMarginBottom = 24

export const osPaddingTop = isDeviceAndroid() ? 10 : 0

export const gutterSize = 10
