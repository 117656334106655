import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {BottomSheetOrOverlay} from 'src/designSystem/components/organisms/BottomSheetOrOverlay/BottomSheetOrOverlay'

export type HowAutoPayWorksOverlay = {
  isVisible: boolean
  onDismiss: () => void
}

export const HowAutoPayWorksOverlay: React.FC<HowAutoPayWorksOverlay> = (props) => {
  const {isVisible, onDismiss: handleOnDismiss} = props
  const {t} = useTranslation('HowAutoPayWorksBottomSheet')
  return (
    <BottomSheetOrOverlay
      visible={isVisible}
      title={t('HowAutoPayWorksTitle')}
      titleVariant="h3"
      showDots={false}
      onOkay={handleOnDismiss}
      onDismiss={handleOnDismiss}
      okayButtonSize="large"
      okayButtonText={t('IUnderstand')}
      showCloseButton={true}
    >
      <PFText variant="p">{t('HowAutoPayWorksDescription')}</PFText>
    </BottomSheetOrOverlay>
  )
}
