import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {logErrorAndShowException} from 'src/lib/errors'
import AppNav from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivationAutopayScheduleReduxContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleReduxContainer'
import {CardActivationAutopayScheduleTemplate} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleTemplate/CardActivationAutopayScheduleTemplate'
import {CardReviewAutopayTemplate} from 'src/products/card/Autopay/CardReviewAutopay/CardReviewAutopayTemplate/CardReviewAutopayTemplate'

type Props = {
  navigation: StackScreenProps<MainStackParamList, 'CardReviewAutopay'>['navigation']
}

const CardReviewAutopay: FC<Props> = ({navigation}) => {
  const {t} = useTranslation('CardReviewAutopay')
  useEffect(() => {
    navigation.setParams({showOptOutOfAutoPay: false})
  }, [])
  const handleOnComplete = async (): Promise<void> => {
    TrackAppEvent(CardEvents.enable_auto_pay_action, AppEvents.Category.Card, {
      source: 'AutopayEnrollFlow',
    })
    try {
      AppNav.push(navigation, 'CardSignedUpAutopay')
    } catch (e) {
      await logErrorAndShowException(e, t('CardEnableAutopaymentsError'))
    }
  }
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )

  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay
  return (
    <CardActivationAutopayScheduleReduxContainer
      onComplete={handleOnComplete}
      navigation={navigation}
      Template={
        isUserMinPayProgram ? CardActivationAutopayScheduleTemplate : CardReviewAutopayTemplate
      }
    />
  )
}

export {CardReviewAutopay}
