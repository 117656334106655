import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import React from 'react'

import {MainStackScreenProps} from 'src/nav/MainStackParamsList'
import {SetLoanAutoPayAccountQueryDocument} from 'src/products/MCU/AccountManagementV2/SetLoanAutoPayAccount/SetLoanAutoPayAccount.gqls'
import {SetLoanAutoPayAccountTemplate} from 'src/products/MCU/AccountManagementV2/SetLoanAutoPayAccount/SetLoanAutoPayAccountTemplate'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

export type SetLoanAutoPayAccountProps = MainStackScreenProps<'SetLoanAutoPayAccount'>

const SetLoanAutoPayAccount: React.FC<SetLoanAutoPayAccountProps> = (props) => {
  const {navigation, route} = props
  const {paymentMethodId, loanId, onSuccessRoute} = route.params
  const {
    selectedData,
    loading: isQueryLoading,
    error: queryError,
  } = useCassandraQuery(
    SetLoanAutoPayAccountQueryDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (e) => {
        logAddPaymentMethodErrorAndShowException(e, 'SetLoanAutoPayAccountQuery failed')
      },
    },
    (from) => {
      return {
        paymentMethods: from.me.paymentMethods.all,
      }
    },
  )
  const selectedPaymentMethod = selectedData?.paymentMethods?.find(
    (pm) => pm?.__typename === 'AchPaymentMethod' && pm.id === paymentMethodId,
  )

  const handleOnConfirmSetAutoPayAccount = (): void => {
    if (selectedPaymentMethod?.__typename !== 'AchPaymentMethod') {
      void logAddPaymentMethodErrorAndShowException(
        new Error('SetLoanAutoPayAccount cant confirm because its not an AchPaymentMethod'),
      )
      return
    }
    // users must accept the AutoPay agreement before changing their AutoPay account
    navigation.push('AcceptLoanAutoPayAgreement', {
      loanId,
      newDefaultPaymentMethodId: paymentMethodId,
      onSuccessRoute,
    })
  }
  const handleOnNevermind = (): void => {
    navigation.goBack()
  }
  const shouldDisplayError =
    // query failed
    !!queryError ||
    // query succeeded but we couldnt find the selected payment method
    (!isQueryLoading && !selectedPaymentMethod) ||
    // query succeeded but the selected payment method is not an ACH account
    (!isQueryLoading && selectedPaymentMethod?.__typename !== 'AchPaymentMethod') ||
    // query succeeded but the selected ACH account doesnt have achNumbersAvailable so we cant use it
    (!isQueryLoading &&
      selectedPaymentMethod?.__typename === 'AchPaymentMethod' &&
      !selectedPaymentMethod.account?.achNumbersAvailable)
  return (
    <SetLoanAutoPayAccountTemplate
      isLoading={isQueryLoading && !selectedPaymentMethod}
      isError={shouldDisplayError}
      onPressConfirm={handleOnConfirmSetAutoPayAccount}
      onPressNevermind={handleOnNevermind}
    />
  )
}

export {SetLoanAutoPayAccount}
