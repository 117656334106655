import React from 'react'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {useTranslation} from 'react-i18next'

type Props = {
  isVisible: boolean
  onContinue: () => void
}
const LoginFromRegistrationModal = (props: Props): JSX.Element => {
  const {t} = useTranslation(['RegistrationOrLogin'])
  const {isVisible, onContinue: handleOnContinue} = props
  return (
    <Overlay
      visible={isVisible}
      testID={'login-from-registration-modal'}
      onDismiss={() => {}}
      okayButtonText={t('LogIn')}
      onOkay={handleOnContinue}
    >
      <Box background="white" gap="little">
        <SvgIcon name="check" size={'medium'} colorVariant="success" />
        <PFText variant="h3">{t('LoginFromRegistrationTitle')}</PFText>
        <PFText variant="p">{t('LoginFromRegistrationBody')}</PFText>
      </Box>
    </Overlay>
  )
}

export {LoginFromRegistrationModal}
