import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {primaryText} from 'src/designSystem/semanticColors'
import {DividerWithTextOrIcon} from 'src/designSystem/components/atoms/DividerWithTextOrIcon/DividerWithTextOrIcon'
import {smallGap} from 'src/designSystem/layout'

export const Divider: FC<{text?: string}> = ({text}) => {
  const {t} = useTranslation(['Common'])
  const displayText = text ?? t('Common:OR') ?? ''
  return (
    <View style={styles.divider}>
      <DividerWithTextOrIcon text={displayText} color={primaryText} />
    </View>
  )
}

const styles = StyleSheet.create({
  divider: {
    marginBottom: smallGap / 4,
    marginTop: smallGap * 2,
  },
})
