import {enormousGap, smallGap} from 'src/designSystem/layout'
import {isDeviceIOS} from 'src/lib/utils/platform'
import {SvgIconSize, SvgIconSizeMap} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

export enum LeftButtonStateTypes {
  NONE = 'none',
  BACK = 'back',
  MENU = 'menu',
  LOGOUT = 'logout',
  CLOSE = 'close',
}

export enum RightButtonStateTypes {
  NONE = 'none',
  CLOSE = 'close',
  LOGOUT = 'logout',
}

export const iconSize: SvgIconSize = 'medium'
export const headerIconHeight = SvgIconSizeMap[iconSize]
export const headerIconTopPadding = isDeviceIOS() ? smallGap : smallGap / 2
export const headerIconBottomPadding = smallGap
export const headerIconLeftPadding = smallGap
export const headerIconRightPadding = smallGap
export const pfHeaderHeight = headerIconHeight + headerIconTopPadding + headerIconBottomPadding
export const pfHeaderHeightWeb = 2 * enormousGap
