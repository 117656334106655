import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {PrimaryAccountContent} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/PrimaryAccountContent'

type Props = StackScreenProps<MainStackParamList, 'AddPrimaryAccount'>

export const AddPrimaryAccount: FC<Props> = ({route, navigation}) => {
  const {shouldLinkNewAccount, onChooseNewAccountComplete} = route.params
  const {t} = useTranslation(['AccountManagement', 'Common'])

  const {addOrRelinkAccountForPrimary} = useAccountManagementBankLinking()

  usePageViewedAnalytics({
    eventName: BankAccountManagementEvents.add_primary_account_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
  })

  /**
   * Sends the user to PrimaryAccountSelection to select a primary account.
   */
  function navigateToPrimaryAccountSelection(): void {
    navigation.push('PrimaryAccountSelection', {
      onContinue: (): void => {
        navigation.pop() // PrimaryAccountSelection does not close itself
        TrackAppEvent(
          BankAccountManagementEvents.account_management_primary_account_set,
          AppEvents.Category.BankAccountManagement,
        )
        void onChooseNewAccountComplete?.()
        navigation.goBack()
      },
    })
  }

  /**
   * Starts the UX to add a new account with an aggregator and then
   * select a primary account
   */
  function addNewPrimaryAccount(): void {
    addOrRelinkAccountForPrimary({
      onChoosePrimaryAccountComplete: ({linkedAccounts}): void => {
        void onChooseNewAccountComplete?.()
        navigation.navigate({
          name: 'AccountManagementV2',
          params: {
            newLinkedAccountIds: linkedAccounts?.map((account) => account.id),
          },
          merge: true,
        })
      },
    })
  }

  return (
    <Page
      title={
        <PFText textAlign="left" variant="h2">
          {t('WhatIsPrimaryAccount')}
        </PFText>
      }
      variant="generic"
      smallTopGap
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: t('AddPrimaryAccount'),
          onPress: shouldLinkNewAccount ? addNewPrimaryAccount : navigateToPrimaryAccountSelection,
          testID: 'Add-Primary-Account-Button',
        },
        secondary: {
          buttonText: t('MaybeLater'),
          onPress: () => navigation.goBack(),
        },
      }}
    >
      <PrimaryAccountContent />
    </Page>
  )
}
