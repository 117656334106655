import {OperationVariables, TypedDocumentNode} from '@apollo/client'

type QueryDataSelector<
  TData = unknown,
  RData = unknown,
  _TVariables extends OperationVariables = OperationVariables,
> = (from: TData) => RData

const CreateCassandraSelector = <
  TData,
  TVariables extends OperationVariables = OperationVariables,
  RData = unknown,
>(
  _document: TypedDocumentNode<TData, TVariables>,
  selector: QueryDataSelector<TData, RData, TVariables>,
): QueryDataSelector<TData, RData, TVariables> => selector

export {CreateCassandraSelector}
export type {QueryDataSelector}
