import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useEffect} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import OfferApplicationWorkflowReady from 'src/products/general/OfferApplicationWorkflow/OfferApplicationWorkflowReady'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'
import {useWFLogging} from 'src/workflows/logging'
import {WorkflowsErrorType, WorkflowsLoadingType} from 'src/workflows/types'

const OfferApplicationWorkflowLoading: React.FC<WorkflowsLoadingType> = () => {
  return (
    <Box fill align="center" justify="center" testID="OfferApplicationWorkflow-Loading">
      <Loading size="large" type="loader0" />
    </Box>
  )
}

const OfferApplicationWorkflowError: React.FC<
  WorkflowsErrorType & StackScreenProps<MainStackParamList, 'OfferApplicationWorkflow'>
> = (props) => {
  const {error, refetch, navigation} = props

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.offer_application_workflow_error, AppEvents.Category.Workflows, {
      error: error.message,
    })
  }, [error.message])

  const handleOnRefetch = useCallback(async () => {
    await refetch()
  }, [refetch])

  const handleOnContactUs = useCallback((): void => {
    TrackAppEvent(
      AppEvents.Name.offer_application_workflow_error_contact_us_pressed,
      AppEvents.Category.Workflows,
    )
    openContactUsForm(navigation)
  }, [navigation])

  return <ErrorTemplate onRetry={handleOnRefetch} onContactUs={handleOnContactUs} />
}

export const OfferApplicationWorkflow: React.FC<
  StackScreenProps<MainStackParamList, 'OfferApplicationWorkflow'>
> = (props) => {
  const workflows = useWorkflows()
  const {log} = useWFLogging('OfferApplicationWorkflow')

  log('Workflows Data = ', workflows)
  switch (workflows.status) {
    case 'READY':
      return <OfferApplicationWorkflowReady {...workflows} {...props} />
    case 'LOADING':
      return <OfferApplicationWorkflowLoading {...workflows} />
    case 'ERROR':
      return <OfferApplicationWorkflowError {...workflows} {...props} />
  }
}
