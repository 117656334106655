import React, {useState, useEffect} from 'react'
import {View} from 'react-native'

import Analytics, {_initSingular} from 'src/lib/Analytics'
import {ContextualizedLogException} from 'src/lib/errors'

const DeeplinkContainer: React.FC<React.PropsWithChildren> = ({children}) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  useEffect(() => {
    const init = async (): Promise<void> => {
      await Analytics._initAmplitudeClient()
      await _initSingular()
      setLoaded(true)
    }
    init().catch(ContextualizedLogException('Error while initializing deeplink container'))
  }, [])

  return <>{loaded ? children : <View />}</>
}

export default DeeplinkContainer
