import React, {ReactNode} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {RouteProp, useRoute} from '@react-navigation/native'
import {ParamListBase} from '@react-navigation/routers'
import {Subtract} from 'utility-types'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export type InjectedNavProps = {
  navigation: StackNavigationProp<MainStackParamList>
  route: RouteProp<ParamListBase, string>
}

type IChildrenProps = {
  children?: ReactNode
}

const withNav =
  <P extends InjectedNavProps>(Component: React.ComponentType<P>) =>
  (props: Subtract<P, InjectedNavProps> & IChildrenProps) => {
    const navigation = useMainNavigator()
    const route = useRoute()

    return <Component {...(props as P & IChildrenProps)} navigation={navigation} route={route} />
  }

export default withNav
