import {TextStyle} from 'react-native'

import {semiBoldStyle, regularStyle} from 'src/designSystem/fonts'
import {NamedColors} from 'src/designSystem/colors'

// TextInput
export const inputFontSizeActive = 16
export const inputFontSizeInactive = 16
export const inputLineHeightActive = 23
export const inputLineHeightInactive = 23

export type TextVariants =
  | 'd1'
  | 'd2'
  | 'd3'
  | 'h1'
  | 'h2'
  | 'h2_regular'
  | 'h3'
  | 'h3_regular'
  | 'p_lg'
  | 'p_lg_semibold'
  | 'p'
  | 'p_semibold'
  | 'p_sm'
  | 'p_sm_semibold'
  | 'p_tiny'
  | 'p_tiny_semibold'
  | 'label_sm'
  | 'label_md'
  | 'label_lg'

type MapOfTextStyles = {[key in TextVariants]: (color: NamedColors) => TextStyle}

export const VariantsStyle: MapOfTextStyles = {
  d1: (color: NamedColors) => ({
    fontSize: 88,
    lineHeight: 96,
    color: color,
    letterSpacing: -0.01,
    ...regularStyle,
  }),
  d2: (color: NamedColors) => ({
    fontSize: 72,
    lineHeight: 80,
    color: color,
    letterSpacing: -0.01,
    ...regularStyle,
  }),
  d3: (color: NamedColors) => ({
    fontSize: 48,
    lineHeight: 56,
    color: color,
    ...regularStyle,
  }),
  h1: (color: NamedColors) => ({
    fontSize: 40,
    lineHeight: 48,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  h2: (color: NamedColors) => ({
    fontSize: 32,
    lineHeight: 40,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  h2_regular: (color: NamedColors) => ({
    fontSize: 32,
    lineHeight: 40,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  h3: (color: NamedColors) => getBaseStyle(color),
  h3_regular: (color: NamedColors) => ({
    fontSize: 24,
    lineHeight: 32,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  p_lg: (color: NamedColors) => ({
    fontSize: 20,
    lineHeight: 28,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  p_lg_semibold: (color: NamedColors) => ({
    fontSize: 20,
    lineHeight: 28,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  p: (color: NamedColors) => ({
    fontSize: 16,
    lineHeight: 24,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  p_semibold: (color: NamedColors) => ({
    fontSize: 16,
    lineHeight: 24,
    color: color,
    letterSpacing: 0.02,
    ...semiBoldStyle,
  }),
  p_sm: (color: NamedColors) => ({
    fontSize: 14,
    lineHeight: 20,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  p_sm_semibold: (color: NamedColors) => ({
    fontSize: 14,
    lineHeight: 20,
    color: color,
    letterSpacing: 0.02,
    ...semiBoldStyle,
  }),
  p_tiny: (color: NamedColors) => ({
    fontSize: 12,
    lineHeight: 15,
    color: color,
    letterSpacing: 0.01,
    ...regularStyle,
  }),
  p_tiny_semibold: (color: NamedColors) => ({
    fontSize: 12,
    lineHeight: 15,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  label_sm: (color: NamedColors) => ({
    fontSize: 14,
    lineHeight: 20,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  label_md: (color: NamedColors) => ({
    fontSize: 16,
    lineHeight: 24,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }),
  label_lg: (color: NamedColors) => getBaseStyle(color),
}

function getBaseStyle(color: NamedColors) {
  return {
    fontSize: 24,
    lineHeight: 32,
    color: color,
    letterSpacing: 0.01,
    ...semiBoldStyle,
  }
}
