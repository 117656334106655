import {TypedDocumentNode, VariablesOf} from '@graphql-typed-document-node/core'
import {ApolloQueryResult, OperationVariables} from '@apollo/client'

import {GetClient, GetPublicClient} from '../client'
import {QueryStatus} from '../types'

/**
 * NextQueryStatus will return QueryStatus.Refreshing under certain circumstances.
 * This will allow developers to determine if we're refreshing state (polling, forceRefresh)
 * or loading state for the first time.
 * @param currentStatus The current QueryStatus of the state slice.
 * @param incomingStatus The proposed next QueryStatus of the state slice.
 * @returns incomingStatus or QueryStatus.Refreshing
 */
export const NextQueryStatus = (
  currentStatus: QueryStatus,
  incomingStatus: QueryStatus,
): QueryStatus => {
  if (currentStatus === QueryStatus.Fulfilled && incomingStatus === QueryStatus.Pending) {
    return QueryStatus.Refreshing
  }

  return incomingStatus
}

/**
 * Invoke a query on the GraphQL service. This is essentially a typing-utility function.
 * @param query A TypedDocumentNode that defines the query.
 * @param variables The variables in the same typing as defined by the query TypedDocumentNode.
 * @returns The query response. This should be typed.
 */
export const ApplyQuery = async <
  TData,
  TVariables extends OperationVariables,
  T extends TypedDocumentNode<TData, TVariables>,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables?: VariablesOf<T>,
  isPublic?: boolean,
): Promise<ApolloQueryResult<TData>> => {
  let client = isPublic ? GetPublicClient(query) : GetClient(query)

  return client.query({
    query,
    variables,
  })
}

/**
 * Invoke a mutation on the GraphQL service. This is essentially a typing-utility function.
 * @param mutation A TypedDocumentNode that defines the mutation.
 * @param variables The variables in the same typing as defined by the mutation TypedDocumentNode.
 * @returns The mutation response 'data'. This should be typed.
 */
export const ApplyMutation = async <
  TData,
  TVariables extends OperationVariables,
  T extends TypedDocumentNode<TData, TVariables>,
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  variables?: VariablesOf<T>,
  isPublic?: boolean,
) => {
  let client = isPublic ? GetPublicClient(mutation) : GetClient(mutation)

  const response = await client.mutate({
    mutation,
    variables,
  })

  if (response.errors?.length) {
    throw new Error(response.errors?.[0].message)
  }

  return response.data
}
