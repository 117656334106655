import {useEffect, useState} from 'react'

import {TilesToDisplayOnce} from 'src/products/card/LoanDash/utils'
import {getUserProperty, setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'

export const useDisplayOneTimeMessage = (displayOnceKey?: TilesToDisplayOnce): boolean => {
  const [show, setShow] = useState(!displayOnceKey)

  useEffect(() => {
    if (!displayOnceKey) {
      return
    }

    const checkKey = async (): Promise<void> => {
      // Check if this key has been set yet
      // If not, save and set show to true
      const {value: cardApplicationGroupValue} = await getUserProperty('cardApplicationGroup')
      const isSet = cardApplicationGroupValue?.[displayOnceKey]
      const hasPossibleCard = cardApplicationGroupValue?.possibleCard ?? false

      if (!isSet) {
        setShow(true)

        await setUserProperty('cardApplicationGroup', {
          possibleCard: hasPossibleCard,
          [displayOnceKey]: true,
        })
      }
    }

    void checkKey()
  }, [displayOnceKey])

  return show
}
