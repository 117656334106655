import React, {FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {RadioButton} from 'src/designSystem/components/atoms/RadioButton/RadioButton'
import PaymentMethodAccount from 'src/products/card/Common/PaymentMethodAccount/PaymentMethodAccount'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import {isPaymentMethodFromPaymentInstrument} from 'src/products/card/PaymentMethods/PaymentMethodUtils'

export type PaymentMethodFieldProps = {
  selectedPaymentAccount: PaymentAccount
  onPaymentAccountSelect: (paymentAccount: PaymentAccount) => void
  showRadio?: boolean
}

type Props = PaymentMethodFieldProps & {
  paymentAccount: PaymentAccount
  title: string
  subTitle: string
}

export const PaymentMethodFieldBase: FC<Props> = (props) => {
  const {
    title,
    subTitle,
    paymentAccount,
    selectedPaymentAccount,
    onPaymentAccountSelect,
    showRadio = true,
  } = props

  const onPress = (): void => {
    onPaymentAccountSelect(paymentAccount)
  }

  let fieldJsx = (
    <RadioButton
      id="paymentMethod"
      text={title}
      subText={subTitle}
      isChecked={isPaymentMethodFromPaymentInstrument(selectedPaymentAccount, paymentAccount)}
      toggleChecked={onPress}
      testID={`PaymentMethodFieldBase-RadioButton-${title}`}
    />
  )

  if (!showRadio) {
    fieldJsx = (
      <PaymentMethodAccount
        bankName={title}
        accountType={subTitle}
        accountMask=""
        hideBorder={true}
      />
    )
  }

  return <Box flex>{fieldJsx}</Box>
}
