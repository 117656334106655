import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Icon from 'src/assets/illustrations/CardNeedMoreTime.svg'
import {useCards} from 'src/products/card/hooks/useCards'
import {usePayOverTimeNavigation} from 'src/products/card/hooks/usePayOverTimeNavigation'
import {EntryTile} from 'src/designSystem/components/molecules/EntryTile/EntryTile'

export const CardDashboardPayOverTime: FC = () => {
  const {t} = useTranslation('CardDashboardPayOverTime')
  const {installmentsNumberOfPayments} = useCards()

  const handleOnPressPayOverTime = usePayOverTimeNavigation()

  return (
    <EntryTile
      svg={Icon}
      header={t('NeedALittleMoreTime')}
      body={t('LockYourCard', {numberOfPayments: installmentsNumberOfPayments})}
      linkText={t('PayOverTime')}
      onLinkPress={handleOnPressPayOverTime}
    />
  )
}
