import {ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {GetBankPlaidLinkTokenDocument} from '@possible/cassandra/src/types/types.mobile.generated'
import {getPlaidLinkTokenInput} from 'src/products/loans/BankOAuth/BankOAuth.utils'
import Log from 'src/lib/loggingUtil'

export async function queryPlaidLinkToken(
  linkedAccountId: string | undefined,
): Promise<string | undefined> {
  try {
    const input = {...getPlaidLinkTokenInput(), linkedAccountId}
    const response = await ApplyQuery(GetBankPlaidLinkTokenDocument, {input})
    const linkToken = response.data?.getBankPlaidLinkToken?.linkToken
    if (!linkToken) {
      throw Error(JSON.stringify(response))
    }
    return linkToken
  } catch (e) {
    Log.error(e, `Failed to query plaid link token, linkedAccountId: ${linkedAccountId}`)
  }
}
