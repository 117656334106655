import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type PrimaryAccountSelectionGqlContainerMutationMutationVariables = Types.Exact<{
  linkedAccountId: Types.Scalars['ID']['input']
  paymentMethod: Types.LoanPaymentMethod
}>

export type PrimaryAccountSelectionGqlContainerMutationMutation = {
  __typename?: 'Mutation'
  loanSetBankAccountAsPrimary: {
    __typename?: 'SetBankAccountAsPrimaryResponse'
    primaryAccount?: {
      __typename?: 'LinkedAccount'
      id: string
      preferredFundingSource?: boolean | null
    } | null
  }
}

export type LinkedAccountsQueryVariables = Types.Exact<{[key: string]: never}>

export type LinkedAccountsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename: 'LinkedAccount'
        id: string
        mask: string
        maskCorrected: string
        name?: string | null
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        isDeletable: boolean
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        lastSyncedAt?: string | null
        bankingAggregatorId: Types.AggregatorType
        institution?: {__typename?: 'Institution'; name: string} | null
      }> | null
    }
  }
}

export const PrimaryAccountSelectionGqlContainerMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'PrimaryAccountSelectionGQLContainerMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethod'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanPaymentMethod'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetBankAccountAsPrimary'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkedAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentMethod'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethod'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'primaryAccount'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrimaryAccountSelectionGqlContainerMutationMutation,
  PrimaryAccountSelectionGqlContainerMutationMutationVariables
>
export const LinkedAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LinkedAccounts'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'accountFilter'},
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {kind: 'Name', value: 'onlyAchEligible'},
                                  value: {kind: 'BooleanValue', value: true},
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'maskCorrected'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isDeletable'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastSyncedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'bankingAggregatorId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkedAccountsQuery, LinkedAccountsQueryVariables>
