import React, {useCallback} from 'react'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'

import {useUniversalPlaidLink} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.hooks'
import {
  AggregatorPlaidLinkContainerProps,
  LinkStableEvent,
  onEventType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'
import AggregatorPlaidTemplate from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidTemplate'

const AggregatorPlaidLinkContainer: React.FC<AggregatorPlaidLinkContainerProps> = (props) => {
  const {token, onExit, onSuccess, onInstitutionSelected} = props

  const handleEvent = useCallback<onEventType>(
    (eventName, metadata) => {
      if (eventName === LinkStableEvent.SELECT_INSTITUTION) {
        const {institutionId, institutionName} = metadata

        // I think this metadata is typed such that certain properties
        // are only available for certain events. In the case of 'SELECT_INSTITUTION'
        // institutionId and institutionName should be available.
        if (institutionId && institutionName) {
          onInstitutionSelected(institutionId, institutionName)
        }
      }
    },
    [onInstitutionSelected],
  )

  const {ready, open} = useUniversalPlaidLink({
    publicToken: token,
    onSuccess,
    onExit,
    onEvent: handleEvent,
  })

  if (!ready) {
    return <Loading size="large" type="loader0" />
  }

  return <AggregatorPlaidTemplate onOpenLink={open} />
}

export default AggregatorPlaidLinkContainer
