import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {primaryLink, success, error, warning, NamedColors} from 'src/designSystem/colors'
import {formatDate, utcDate} from 'src/lib/utils/date'

export const VariantValues = ['error', 'info', 'success', 'warning'] as const
export type VariantType = (typeof VariantValues)[number]

type MapOfTextStyles = {[key in VariantType]: NamedColors}

const VariantColorMap: MapOfTextStyles = {
  info: primaryLink,
  success: success,
  error: error,
  warning: warning,
}

export type CalendarDateCircleProps = {
  date: Date | string
  active?: boolean
  variant?: VariantType
}
const CalendarDateCircle: FC<CalendarDateCircleProps> = (props) => {
  const {active, date, variant} = props
  const selectedColor = VariantColorMap[variant ?? 'info']
  const iconBorderColor = active ? selectedColor : `${selectedColor}33`

  return (
    <View style={[styles.circle, {borderColor: iconBorderColor}]}>
      <PFText variant={'p_lg'} textAlign={'center'}>
        {formatDate(date, 'D', utcDate)}
      </PFText>
      <PFText variant={'p_sm'} textAlign={'center'}>
        {formatDate(date, 'MMM', utcDate)}
      </PFText>
    </View>
  )
}

export default CalendarDateCircle

const styles = StyleSheet.create({
  circle: {
    alignItems: 'center',
    borderRadius: 33,
    borderWidth: 1.5,
    height: 66,
    justifyContent: 'center',
    width: 66,
  },
})
