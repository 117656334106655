import React, {ReactNode} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {littleGap, smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type AccountNotUsableInfoTileProps = {
  accountManagementScreen?: boolean
  choosePrimaryAccountScreen?: boolean
  paymentMethodScreen?: boolean
}

const AccountNotUsableInfoTile = ({
  accountManagementScreen,
  choosePrimaryAccountScreen,
  paymentMethodScreen,
}: AccountNotUsableInfoTileProps): JSX.Element => {
  const {t} = useTranslation('AccountManagement')

  const chooseInfoContent = (): ReactNode => {
    if (accountManagementScreen) {
      return accountManagementInfoContent
    }
    if (choosePrimaryAccountScreen) {
      return choosePrimaryAccountInfoContent
    }
    if (paymentMethodScreen) {
      return paymentMethodInfoContent
    }
  }

  const accountManagementInfoContent = (
    <PFText variant="p" color="textDisabled">
      <Trans
        t={t}
        i18nKey="NoteYourAccountWithWarningMayNotBeUseable"
        components={{
          iconBtn: <SvgIcon name={'warning'} colorVariant={'warning'} isFilled />,
        }}
      />
    </PFText>
  )

  const choosePrimaryAccountInfoContent = (
    <PFText variant="p_sm" color={NamedColors.SILVER}>
      {t('AccountsMayBeDisabledToSetAsPrimary')}
    </PFText>
  )

  const paymentMethodInfoContent = (
    <PFText variant="p_sm" color={NamedColors.SILVER}>
      {t('AccountsMayNotBeUsedForPayments')}
    </PFText>
  )

  const contentWrapper = (
    <Box paddingHorizontal={smallGap} paddingVertical={littleGap} marginVertical={smallGap}>
      {chooseInfoContent()}
    </Box>
  )

  if (accountManagementScreen || choosePrimaryAccountScreen || paymentMethodScreen) {
    return contentWrapper
  }

  return <></>
}

export {AccountNotUsableInfoTile}
