/* eslint-disable */
/* This is an autogenerated file from client_mono/packages/generated/proto_ts_gen.sh */

/* NOTE : 
  If you see odd typescript errors due to types prefixed with '.com.possiblefinance.service.'
  It is likely due to a proto file missing from client_mono/packages/generated/proto_ts_gen.sh
*/

export namespace protobuf {
  export namespace DescriptorProto {
    export interface IExtensionRange {
      start?: number
      end?: number
      options?: protobuf.IExtensionRangeOptions
    }
    export interface IReservedRange {
      start?: number
      end?: number
    }
  }
  export namespace ExtensionRangeOptions {
    export interface IDeclaration {
      number?: number
      fullName?: string
      type?: string
      reserved?: true | false
      repeated?: true | false
    }
    export enum VerificationState {
      DECLARATION = 'DECLARATION',
      UNVERIFIED = 'UNVERIFIED',
    }
  }
  export namespace FieldDescriptorProto {
    export enum Type {
      TYPE_DOUBLE = 'TYPE_DOUBLE',
      TYPE_FLOAT = 'TYPE_FLOAT',
      TYPE_INT64 = 'TYPE_INT64',
      TYPE_UINT64 = 'TYPE_UINT64',
      TYPE_INT32 = 'TYPE_INT32',
      TYPE_FIXED64 = 'TYPE_FIXED64',
      TYPE_FIXED32 = 'TYPE_FIXED32',
      TYPE_BOOL = 'TYPE_BOOL',
      TYPE_STRING = 'TYPE_STRING',
      TYPE_GROUP = 'TYPE_GROUP',
      TYPE_MESSAGE = 'TYPE_MESSAGE',
      TYPE_BYTES = 'TYPE_BYTES',
      TYPE_UINT32 = 'TYPE_UINT32',
      TYPE_ENUM = 'TYPE_ENUM',
      TYPE_SFIXED32 = 'TYPE_SFIXED32',
      TYPE_SFIXED64 = 'TYPE_SFIXED64',
      TYPE_SINT32 = 'TYPE_SINT32',
      TYPE_SINT64 = 'TYPE_SINT64',
    }
    export enum Label {
      LABEL_OPTIONAL = 'LABEL_OPTIONAL',
      LABEL_REPEATED = 'LABEL_REPEATED',
      LABEL_REQUIRED = 'LABEL_REQUIRED',
    }
  }
  export namespace EnumDescriptorProto {
    export interface IEnumReservedRange {
      start?: number
      end?: number
    }
  }
  export namespace FileOptions {
    export enum OptimizeMode {
      SPEED = 'SPEED',
      CODE_SIZE = 'CODE_SIZE',
      LITE_RUNTIME = 'LITE_RUNTIME',
    }
  }
  export namespace FieldOptions {
    export interface IEditionDefault {
      edition?: protobuf.Edition
      value?: string
    }
    export enum CType {
      STRING = 'STRING',
      CORD = 'CORD',
      STRING_PIECE = 'STRING_PIECE',
    }
    export enum JSType {
      JS_NORMAL = 'JS_NORMAL',
      JS_STRING = 'JS_STRING',
      JS_NUMBER = 'JS_NUMBER',
    }
    export enum OptionRetention {
      RETENTION_UNKNOWN = 'RETENTION_UNKNOWN',
      RETENTION_RUNTIME = 'RETENTION_RUNTIME',
      RETENTION_SOURCE = 'RETENTION_SOURCE',
    }
    export enum OptionTargetType {
      TARGET_TYPE_UNKNOWN = 'TARGET_TYPE_UNKNOWN',
      TARGET_TYPE_FILE = 'TARGET_TYPE_FILE',
      TARGET_TYPE_EXTENSION_RANGE = 'TARGET_TYPE_EXTENSION_RANGE',
      TARGET_TYPE_MESSAGE = 'TARGET_TYPE_MESSAGE',
      TARGET_TYPE_FIELD = 'TARGET_TYPE_FIELD',
      TARGET_TYPE_ONEOF = 'TARGET_TYPE_ONEOF',
      TARGET_TYPE_ENUM = 'TARGET_TYPE_ENUM',
      TARGET_TYPE_ENUM_ENTRY = 'TARGET_TYPE_ENUM_ENTRY',
      TARGET_TYPE_SERVICE = 'TARGET_TYPE_SERVICE',
      TARGET_TYPE_METHOD = 'TARGET_TYPE_METHOD',
    }
  }
  export namespace MethodOptions {
    export enum IdempotencyLevel {
      IDEMPOTENCY_UNKNOWN = 'IDEMPOTENCY_UNKNOWN',
      NO_SIDE_EFFECTS = 'NO_SIDE_EFFECTS',
      IDEMPOTENT = 'IDEMPOTENT',
    }
  }
  export namespace UninterpretedOption {
    export interface INamePart {
      namePart?: string
      isExtension?: true | false
    }
  }
  export namespace FeatureSet {
    export enum FieldPresence {
      FIELD_PRESENCE_UNKNOWN = 'FIELD_PRESENCE_UNKNOWN',
      EXPLICIT = 'EXPLICIT',
      IMPLICIT = 'IMPLICIT',
      LEGACY_REQUIRED = 'LEGACY_REQUIRED',
    }
    export enum EnumType {
      ENUM_TYPE_UNKNOWN = 'ENUM_TYPE_UNKNOWN',
      OPEN = 'OPEN',
      CLOSED = 'CLOSED',
    }
    export enum RepeatedFieldEncoding {
      REPEATED_FIELD_ENCODING_UNKNOWN = 'REPEATED_FIELD_ENCODING_UNKNOWN',
      PACKED = 'PACKED',
      EXPANDED = 'EXPANDED',
    }
    export enum Utf8Validation {
      UTF8_VALIDATION_UNKNOWN = 'UTF8_VALIDATION_UNKNOWN',
      NONE = 'NONE',
      VERIFY = 'VERIFY',
    }
    export enum MessageEncoding {
      MESSAGE_ENCODING_UNKNOWN = 'MESSAGE_ENCODING_UNKNOWN',
      LENGTH_PREFIXED = 'LENGTH_PREFIXED',
      DELIMITED = 'DELIMITED',
    }
    export enum JsonFormat {
      JSON_FORMAT_UNKNOWN = 'JSON_FORMAT_UNKNOWN',
      ALLOW = 'ALLOW',
      LEGACY_BEST_EFFORT = 'LEGACY_BEST_EFFORT',
    }
  }
  export namespace FeatureSetDefaults {
    export interface IFeatureSetEditionDefault {
      edition?: protobuf.Edition
      features?: protobuf.IFeatureSet
    }
  }
  export namespace SourceCodeInfo {
    export interface ILocation {
      path?: number[]
      span?: number[]
      leadingComments?: string
      trailingComments?: string
      leadingDetachedComments?: string[]
    }
  }
  export namespace GeneratedCodeInfo {
    export namespace Annotation {
      export enum Semantic {
        NONE = 'NONE',
        SET = 'SET',
        ALIAS = 'ALIAS',
      }
    }
    export interface IAnnotation {
      path?: number[]
      sourceFile?: string
      begin?: number
      end?: number
      semantic?: protobuf.GeneratedCodeInfo.Annotation.Semantic
    }
  }
  export interface IFileDescriptorSet {
    file?: protobuf.IFileDescriptorProto[]
  }
  export interface IFileDescriptorProto {
    name?: string
    package?: string
    dependency?: string[]
    publicDependency?: number[]
    weakDependency?: number[]
    messageType?: protobuf.IDescriptorProto[]
    enumType?: protobuf.IEnumDescriptorProto[]
    service?: protobuf.IServiceDescriptorProto[]
    extension?: protobuf.IFieldDescriptorProto[]
    options?: protobuf.IFileOptions
    sourceCodeInfo?: protobuf.ISourceCodeInfo
    syntax?: string
    edition?: protobuf.Edition
  }
  export interface IDescriptorProto {
    name?: string
    field?: protobuf.IFieldDescriptorProto[]
    extension?: protobuf.IFieldDescriptorProto[]
    nestedType?: protobuf.IDescriptorProto[]
    enumType?: protobuf.IEnumDescriptorProto[]
    extensionRange?: protobuf.DescriptorProto.IExtensionRange[]
    oneofDecl?: protobuf.IOneofDescriptorProto[]
    options?: protobuf.IMessageOptions
    reservedRange?: protobuf.DescriptorProto.IReservedRange[]
    reservedName?: string[]
  }
  export interface IExtensionRangeOptions {
    uninterpretedOption?: protobuf.IUninterpretedOption[]
    declaration?: protobuf.ExtensionRangeOptions.IDeclaration[]
    features?: protobuf.IFeatureSet
    verification?: protobuf.ExtensionRangeOptions.VerificationState
  }
  export interface IFieldDescriptorProto {
    name?: string
    number?: number
    label?: protobuf.FieldDescriptorProto.Label
    type?: protobuf.FieldDescriptorProto.Type
    typeName?: string
    extendee?: string
    defaultValue?: string
    oneofIndex?: number
    jsonName?: string
    options?: protobuf.IFieldOptions
    proto3Optional?: true | false
  }
  export interface IOneofDescriptorProto {
    name?: string
    options?: protobuf.IOneofOptions
  }
  export interface IEnumDescriptorProto {
    name?: string
    value?: protobuf.IEnumValueDescriptorProto[]
    options?: protobuf.IEnumOptions
    reservedRange?: protobuf.EnumDescriptorProto.IEnumReservedRange[]
    reservedName?: string[]
  }
  export interface IEnumValueDescriptorProto {
    name?: string
    number?: number
    options?: protobuf.IEnumValueOptions
  }
  export interface IServiceDescriptorProto {
    name?: string
    method?: protobuf.IMethodDescriptorProto[]
    options?: protobuf.IServiceOptions
  }
  export interface IMethodDescriptorProto {
    name?: string
    inputType?: string
    outputType?: string
    options?: protobuf.IMethodOptions
    clientStreaming?: true | false
    serverStreaming?: true | false
  }
  export interface IFileOptions {
    javaPackage?: string
    javaOuterClassname?: string
    javaMultipleFiles?: true | false
    javaGenerateEqualsAndHash?: true | false
    javaStringCheckUtf8?: true | false
    optimizeFor?: protobuf.FileOptions.OptimizeMode
    goPackage?: string
    ccGenericServices?: true | false
    javaGenericServices?: true | false
    pyGenericServices?: true | false
    phpGenericServices?: true | false
    deprecated?: true | false
    ccEnableArenas?: true | false
    objcClassPrefix?: string
    csharpNamespace?: string
    swiftPrefix?: string
    phpClassPrefix?: string
    phpNamespace?: string
    phpMetadataNamespace?: string
    rubyPackage?: string
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IMessageOptions {
    messageSetWireFormat?: true | false
    noStandardDescriptorAccessor?: true | false
    deprecated?: true | false
    mapEntry?: true | false
    deprecatedLegacyJsonFieldConflicts?: true | false
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IFieldOptions {
    ctype?: protobuf.FieldOptions.CType
    packed?: true | false
    jstype?: protobuf.FieldOptions.JSType
    lazy?: true | false
    unverifiedLazy?: true | false
    deprecated?: true | false
    weak?: true | false
    debugRedact?: true | false
    retention?: protobuf.FieldOptions.OptionRetention
    targets?: protobuf.FieldOptions.OptionTargetType[]
    editionDefaults?: protobuf.FieldOptions.IEditionDefault[]
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IOneofOptions {
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IEnumOptions {
    allowAlias?: true | false
    deprecated?: true | false
    deprecatedLegacyJsonFieldConflicts?: true | false
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IEnumValueOptions {
    deprecated?: true | false
    features?: protobuf.IFeatureSet
    debugRedact?: true | false
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IServiceOptions {
    features?: protobuf.IFeatureSet
    deprecated?: true | false
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IMethodOptions {
    deprecated?: true | false
    idempotencyLevel?: protobuf.MethodOptions.IdempotencyLevel
    features?: protobuf.IFeatureSet
    uninterpretedOption?: protobuf.IUninterpretedOption[]
  }
  export interface IUninterpretedOption {
    name?: protobuf.UninterpretedOption.INamePart[]
    identifierValue?: string
    positiveIntValue?: number
    negativeIntValue?: number
    doubleValue?: number
    stringValue?: string
    aggregateValue?: string
  }
  export interface IFeatureSet {
    fieldPresence?: protobuf.FeatureSet.FieldPresence
    enumType?: protobuf.FeatureSet.EnumType
    repeatedFieldEncoding?: protobuf.FeatureSet.RepeatedFieldEncoding
    utf8Validation?: protobuf.FeatureSet.Utf8Validation
    messageEncoding?: protobuf.FeatureSet.MessageEncoding
    jsonFormat?: protobuf.FeatureSet.JsonFormat
  }
  export interface IFeatureSetDefaults {
    defaults?: protobuf.FeatureSetDefaults.IFeatureSetEditionDefault[]
    minimumEdition?: protobuf.Edition
    maximumEdition?: protobuf.Edition
  }
  export interface ISourceCodeInfo {
    location?: protobuf.SourceCodeInfo.ILocation[]
  }
  export interface IGeneratedCodeInfo {
    annotation?: protobuf.GeneratedCodeInfo.IAnnotation[]
  }
  export interface ITimestamp {
    seconds?: number
    nanos?: number
  }
  export interface IDoubleValue {
    value?: number
  }
  export interface IFloatValue {
    value?: number
  }
  export interface IInt64Value {
    value?: number
  }
  export interface IUInt64Value {
    value?: number
  }
  export interface IInt32Value {
    value?: number
  }
  export interface IUInt32Value {
    value?: number
  }
  export interface IBoolValue {
    value?: true | false
  }
  export interface IStringValue {
    value?: string
  }
  export interface IBytesValue {
    value?: string
  }
  export enum Edition {
    EDITION_UNKNOWN = 'EDITION_UNKNOWN',
    EDITION_PROTO2 = 'EDITION_PROTO2',
    EDITION_PROTO3 = 'EDITION_PROTO3',
    EDITION_2023 = 'EDITION_2023',
    EDITION_1_TEST_ONLY = 'EDITION_1_TEST_ONLY',
    EDITION_2_TEST_ONLY = 'EDITION_2_TEST_ONLY',
    EDITION_99997_TEST_ONLY = 'EDITION_99997_TEST_ONLY',
    EDITION_99998_TEST_ONLY = 'EDITION_99998_TEST_ONLY',
    EDITION_99999_TEST_ONLY = 'EDITION_99999_TEST_ONLY',
  }
}
export namespace common {
  export namespace ServiceError {
    export enum ErrorCode {
      Ok = 'Ok',
      BadRequest = 'BadRequest',
      NotFound = 'NotFound',
      InternalServerError = 'InternalServerError',
      Unauthorized = 'Unauthorized',
      SendFailure = 'SendFailure',
      Duplicate = 'Duplicate',
      InvalidAuth = 'InvalidAuth',
      InsufficientDataError = 'InsufficientDataError',
      RateLimitReached = 'RateLimitReached',
      TemporarilyUnavailable = 'TemporarilyUnavailable',
      ProductNotReady = 'ProductNotReady',
      UnsupportedStateError = 'UnsupportedStateError',
      ReAuthorizationRequired = 'ReAuthorizationRequired',
      ExternalServiceFailure = 'ExternalServiceFailure',
    }
    export enum ErrorSubCode {
      None = 'None',
      StreetAddressVerificationFailed = 'StreetAddressVerificationFailed',
      PaymentInstrumentNumberExpiredOrInvalid = 'PaymentInstrumentNumberExpiredOrInvalid',
      PreferredLinkedAccountNotFound = 'PreferredLinkedAccountNotFound',
      ConsumerReportCreditFileFrozen = 'ConsumerReportCreditFileFrozen',
    }
  }
  export interface IClientInfo {
    clientInfoVer?: number
    clientDevMode?: string
    clientBinaryVersion?: string
    clientUiVersion?: string
    clientType?: string
    deviceOsName?: string
    deviceOsVersion?: string
    deviceId?: string
    deviceLocale?: string
    deviceTimezone?: string
    deviceCarrier?: string
    deviceMfg?: string
    deviceBranch?: string
    appFirebaseIid?: string
    appInstallTime?: string
    appInstanceId?: string
    appUpdatedLastTime?: string
    appUniqueId?: string
    buildId?: string
    deviceUserAgent?: string
    deviceIsTablet?: string
    devicePhoneNumber?: string
    deviceToken?: string
    screenSize?: string
    windowSize?: string
  }
  export interface IAuthorization {
    authKeyId?: string
    authKeySecret?: string
    requestorUserId?: string
    isAdmin?: true | false
    sessionId?: string
    requestedVia?: common.IEntityDescriptor
  }
  export interface IServiceError {
    errorCode?: common.ServiceError.ErrorCode
    errorSubCode?: common.ServiceError.ErrorSubCode
    errorMsg?: string
  }
  export interface IEntityDescriptor {
    type?: string
    id?: string
  }
  export interface IStartSessionRequest {
    authorization?: common.IAuthorization
    clientInfo?: common.IClientInfo
    resume?: true | false
  }
  export interface IStartSessionResponse {
    error?: common.IServiceError
    sessionId?: string
  }
  export enum CacheMode {
    CACHE_MODE_UNSPECIFIED = 'CACHE_MODE_UNSPECIFIED',
    CACHE_MODE_FORCE_REFRESH = 'CACHE_MODE_FORCE_REFRESH',
    CACHE_MODE_CACHED_ONLY = 'CACHE_MODE_CACHED_ONLY',
    CACHE_MODE_REFRESH_IF_STALE = 'CACHE_MODE_REFRESH_IF_STALE',
  }
  export enum AccessLevel {
    AL_Not_Set = 'AL_Not_Set',
    AL_User = 'AL_User',
    AL_Admin = 'AL_Admin',
    AL_Any = 'AL_Any',
    AL_Server = 'AL_Server',
  }
  export enum Locale {
    LOCALE_UNSPECIFIED = 'LOCALE_UNSPECIFIED',
    LOCALE_EN_US = 'LOCALE_EN_US',
    LOCALE_ES_US = 'LOCALE_ES_US',
  }
}
export namespace awards {
  export namespace UserAward {
    export enum Type {
      TypeNULL = 'TypeNULL',
      TypeDiscount = 'TypeDiscount',
      TypeCredit = 'TypeCredit',
      TypeGiftCard = 'TypeGiftCard',
      TypeDirect = 'TypeDirect',
    }
    export enum Status {
      StatusNULL = 'StatusNULL',
      StatusEnabled = 'StatusEnabled',
      StatusDisabled = 'StatusDisabled',
    }
    export enum EventTrigger {
      EventTriggerNA = 'EventTriggerNA',
      SignUpFirstLoan = 'SignUpFirstLoan',
    }
    export enum AwardReferenceType {
      AwardReferenceNA = 'AwardReferenceNA',
      UserGenerated = 'UserGenerated',
      Code = 'Code',
    }
    export enum CategoryReuseType {
      CategoryReuseRepeatNULL = 'CategoryReuseRepeatNULL',
      CategoryReuseRepeatOnce = 'CategoryReuseRepeatOnce',
      CategoryReuseRepeatOncePerUser = 'CategoryReuseRepeatOncePerUser',
      CategoryReuseRepeatOncePerLoan = 'CategoryReuseRepeatOncePerLoan',
    }
  }
  export namespace UserAwardDiscount {
    export enum UserDiscountState {
      StateNA = 'StateNA',
      StateAvailable = 'StateAvailable',
      StateApplied = 'StateApplied',
      StateCancelled = 'StateCancelled',
    }
  }
  export namespace UserAwardFirstLoanReferral {
    export enum ClaimedStatus {
      NULL = 'NULL',
      Claimed = 'Claimed',
      NotClaimed = 'NotClaimed',
      Pending = 'Pending',
      Expired = 'Expired',
    }
  }
  export namespace UserAwardRedeem {
    export enum DisburseStatus {
      NULL = 'NULL',
      Pending = 'Pending',
      Error = 'Error',
      Complete = 'Complete',
      Retry = 'Retry',
      Queued = 'Queued',
    }
  }
  export namespace UserAwardClaimResponse {
    export enum Result {
      ResultNA = 'ResultNA',
      ResultInvalidCode = 'ResultInvalidCode',
      ResultAlreadyUsed = 'ResultAlreadyUsed',
      ResultAwarded = 'ResultAwarded',
    }
  }
  export namespace UserAwardRedeemRequest {
    export enum RedeemType {
      NA = 'NA',
      GIFT = 'GIFT',
    }
  }
  export interface IUserAwardLinkInfo {
    id?: string
    title?: string
    description?: string
    channel?: string
    feature?: string
    campaign?: string
  }
  export interface IUserAward {
    id?: string
    type?: awards.UserAward.Type
    referenceType?: awards.UserAward.AwardReferenceType
    code?: string
    eventTrigger?: awards.UserAward.EventTrigger
    defaultForEventTrigger?: boolean
    category?: string
    categoryReuse?: awards.UserAward.CategoryReuseType
    value?: number
    createdAt?: string
    updatedAt?: string
    status?: awards.UserAward.Status
    userText?: string
    awardLinkInfo?: awards.IUserAwardLinkInfo
  }
  export interface IUserAwardDiscount {
    userId?: string
    loanId?: string
    state?: awards.UserAwardDiscount.UserDiscountState
    awardId?: string
    createdAt?: string
    updatedAt?: string
    awardedBy?: common.IEntityDescriptor
    id?: string
    updatedBy?: common.IEntityDescriptor
  }
  export interface IUserAwardCredit {
    userId?: string
    awardId?: string
    awardedBy?: common.IEntityDescriptor
    createdAt?: string
    updatedAt?: string
    id?: string
  }
  export interface IUserAwardFirstLoanReferral {
    loanId?: string
    referrerId?: string
    refereeId?: string
    claimedStatus?: awards.UserAwardFirstLoanReferral.ClaimedStatus
    amount?: protobuf.IDoubleValue
    redeemId?: string
    ack?: protobuf.IInt32Value
    referralId?: string
    ownerId?: string
  }
  export interface IUserAwardRedeem {
    redeemId?: string
    userId?: string
    awards?: string
    amount?: protobuf.IDoubleValue
    disburseType?: string
    disburseId?: string
    disburseStatus?: awards.UserAwardRedeem.DisburseStatus
    disburseMsg?: string
    providerId?: string
    providerType?: string
  }
  export interface IUserAwardCreateRequest {
    authorization?: common.IAuthorization
    data?: awards.IUserAward
  }
  export interface IUserAwardCreateResponse {
    error?: common.IServiceError
    data?: awards.IUserAward
  }
  export interface IUserAwardReadRequest {
    authorization?: common.IAuthorization
  }
  export interface IUserAwardReadResponse {
    error?: common.IServiceError
    data?: awards.IUserAward[]
  }
  export interface IUserAwardReadDefaultRequest {
    authorization?: common.IAuthorization
    eventTrigger?: awards.UserAward.EventTrigger
  }
  export interface IUserAwardReadDefaultResponse {
    error?: common.IServiceError
    data?: awards.IUserAward
  }
  export interface IUserAwardUpdateRequest {
    authorization?: common.IAuthorization
    data?: awards.IUserAward
  }
  export interface IUserAwardUpdateResponse {
    error?: common.IServiceError
    data?: awards.IUserAward
  }
  export interface IUserAwardClaimCreateRequest {
    authorization?: common.IAuthorization
  }
  export interface IUserAwardClaimRequest {
    authorization?: common.IAuthorization
    awardId?: string
    code?: string
    creditAsGiftCard?: true | false
  }
  export interface IUserAwardClaimResponse {
    error?: common.IServiceError
    award?: awards.IUserAward
    discount?: awards.IUserAwardDiscount
    credit?: awards.IUserAwardCredit
    result?: awards.UserAwardClaimResponse.Result
  }
  export interface IUserAwardDiscountReadRequest {
    authorization?: common.IAuthorization
  }
  export interface IUserAwardDiscountReadResponse {
    error?: common.IServiceError
    discount?: awards.IUserAwardDiscount[]
  }
  export interface IUserAwardRedeemRequest {
    authorization?: common.IAuthorization
    type?: awards.UserAwardRedeemRequest.RedeemType
    userId?: string
  }
  export interface IUserAwardRedeemResponse {
    error?: common.IServiceError
    referrals?: awards.IUserAwardFirstLoanReferral[]
    redeem?: awards.IUserAwardRedeem
  }
  export interface ILastReferralInfoRequest {
    authorization?: common.IAuthorization
    sourceType?: string
    sourceId?: string
  }
  export interface ILastReferralInfoResponse {
    error?: common.IServiceError
  }
  export interface IReferralInfoSummary {
    id?: string
    createdAt?: string
    sourceId?: string
    sourceType?: string
    usersAwardsId?: string
    refereeId?: string
    refereeEmail?: string
    refereeFirstName?: string
    refereeLastName?: string
    referrerId?: string
    referrerEmail?: string
    referrerFirstName?: string
    referrerLastName?: string
    awardsFirstLoanReferrals?: awards.IUserAwardFirstLoanReferral
    userAward?: awards.IUserAward
  }
  export interface IGetReferralsByUserRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetReferralsByUserResponse {
    error?: common.IServiceError
    referrals?: awards.IReferralInfoSummary[]
  }
  export interface IReferralUpdate {
    isFirst?: true | false
    type?: string
    blob?: string
  }
  export interface IUpdateReferralInfoRequest {
    authorization?: common.IAuthorization
    referrals?: awards.IReferralUpdate[]
    userId?: string
  }
  export interface IUpdateReferralInfoResponse {
    error?: common.IServiceError
  }
  export interface IProcessReferralLinkRequest {
    referringUserId?: string
    referralCode?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IProcessReferralLinkResponse {}
  export interface IGetReferralLinkForUserRequest {
    authorization?: common.IAuthorization
    userId?: string
    eventTrigger?: awards.UserAward.EventTrigger
    identityId?: string
  }
  export interface IGetReferralLinkForUserResponse {
    error?: common.IServiceError
    award?: awards.IUserAward
    linkUrl?: string
    linkTitle?: string
    linkMessage?: string
  }
}
export namespace banking {
  export namespace LinkedProcessorAccount {
    export namespace LinkedProcessorFundingSource {
      export enum ProcessorFundingSourceStatus {
        NA = 'NA',
        pending = 'pending',
        verified = 'verified',
        failed = 'failed',
        removed = 'removed',
      }
      export enum VerificationMethod {
        iav = 'iav',
        microdeposits = 'microdeposits',
        iav_or_microdeposits = 'iav_or_microdeposits',
      }
    }
    export interface ILinkedProcessorFundingSource {
      fundingSourceId?: string
      processorAccountId?: string
      externalFundingSourceId?: string
      status?: string
      createdAt?: string
      updatedAt?: string
      updatedBy?: common.IEntityDescriptor
      processorNetwork?: banking.LinkedAccount.ProcessorNetwork
      processorId?: banking.LinkedProcessorAccount.Processor
      verificationMethod?: banking.LinkedProcessorAccount.LinkedProcessorFundingSource.VerificationMethod
      verificationStatus?: banking.LinkedProcessorAccount.LinkedProcessorFundingSource.ProcessorFundingSourceStatus
      microDepositsAt?: string
      defaultCurrency?: string
      paymentInstrumentId?: string
    }
    export enum Processor {
      none = 'none',
      stripe = 'stripe',
      dwolla = 'dwolla',
      synapsefi = 'synapsefi',
      lpp = 'lpp',
      check = 'check',
      leadbank = 'leadbank',
      seattlebank = 'seattlebank',
      republicbankofchicago = 'republicbankofchicago',
      coastal = 'coastal',
      pnc = 'pnc',
      fiserv = 'fiserv',
      donkey = 'donkey',
    }
    export enum ProcessorAccountStatus {
      NA = 'NA',
      document = 'document',
      pending = 'pending',
      verified = 'verified',
      suspended = 'suspended',
      retry = 'retry',
      deactivated = 'deactivated',
      failed = 'failed',
      unknown = 'unknown',
      ssn = 'ssn',
    }
    export enum ProcessorAccountSubStatus {
      substatus_na = 'substatus_na',
      send_and_receive = 'send_and_receive',
      send = 'send',
      receive = 'receive',
    }
  }
  export namespace LinkedAccount {
    export namespace Number {
      export namespace Insights {
        export interface ICCIssuer {
          name?: string
          phone?: string
          matchesProvidedName?: true | false
          matchesProvidedPhoneNumber?: true | false
        }
      }
      export interface IEncryption {
        encryptionProvider?: string
        processorId?: string
      }
      export interface IDecryption {
        decryptNumbers?: true | false
        decryptionKeys?: string[]
      }
      export interface IInsights {
        type?: string
        issuer?: banking.LinkedAccount.Number.Insights.ICCIssuer
        brand?: string
        country?: string
        isIssuedInBillingAddressCountry?: true | false
        isPrepaid?: true | false
        isVirtual?: true | false
      }
      export enum NumberStatus {
        pending = 'pending',
        verified = 'verified',
        invalid = 'invalid',
        expired = 'expired',
        removed = 'removed',
        suspended = 'suspended',
        system_purged = 'system_purged',
      }
    }
    export interface IBalance {
      limit?: number
      current?: number
      available?: number
      error?: true | false
      currencyCodeIso?: string
      currencyCodeUnofficial?: string
    }
    export interface IAccount {
      externalAccountId?: string
      externalProviderAccountId?: string
      mask?: string
      maskCorrected?: string
      name?: string
      officialName?: string
      type?: string
      subType?: string
      status?: string
      institutionId?: string
      institutionName?: string
    }
    export interface IAccountBalancePair {
      account?: banking.LinkedAccount.IAccount
      balance?: banking.LinkedAccount.IBalance
      access?: banking.LinkedAccount.IAccess
      cachedDatetime?: string
    }
    export interface ILink {
      itemId?: string
      accessToken?: string
    }
    export interface IAccess {
      link?: banking.LinkedAccount.ILink
    }
    export interface INumber {
      id?: string
      prefix?: string
      mask?: string
      account?: string
      routing?: string
      wireRouting?: string
      expDate?: string
      cvv?: string
      network?: banking.LinkedAccount.ProcessorNetwork
      encryption?: banking.LinkedAccount.Number.IEncryption
      branch?: string
      institution?: string
      iban?: string
      sortCode?: string
      bic?: string
      isUserInput?: true | false
      status?: banking.LinkedAccount.Number.NumberStatus
      insights?: banking.LinkedAccount.Number.IInsights
      linkedAccountId?: string
      variant?: string
      createdAt?: string
      updatedAt?: string
      replacedId?: string
      friendlyName?: string
    }
    export interface IInstitution {
      id?: string
      name?: string
      products?: string[]
      logo?: string
      url?: string
      primaryColor?: string
      isSupportedByPf?: true | false
      isWithOptionalMetadata?: true | false
    }
    export interface IPreferences {
      fundingSourcePref?: number
    }
    export enum Aggregator {
      none0 = 'none0',
      plaid = 'plaid',
      yodlee = 'yodlee',
      finicity = 'finicity',
      possible = 'possible',
      mocked = 'mocked',
    }
    export enum ProcessorNetwork {
      none = 'none',
      ach = 'ach',
      interchange = 'interchange',
      check = 'check',
      eft = 'eft',
      bacs = 'bacs',
      international = 'international',
    }
    export enum AccountOwnerType {
      ACCOUNT_TYPE_UNSPECIFIED = 'ACCOUNT_TYPE_UNSPECIFIED',
      ACCOUNT_TYPE_INDIVIDUAL = 'ACCOUNT_TYPE_INDIVIDUAL',
      ACCOUNT_TYPE_JOINT = 'ACCOUNT_TYPE_JOINT',
      ACCOUNT_TYPE_TRUST = 'ACCOUNT_TYPE_TRUST',
      ACCOUNT_TYPE_ASSOCIATION = 'ACCOUNT_TYPE_ASSOCIATION',
    }
  }
  export namespace TransactionHistoryItem {
    export interface ICategory {
      id?: string
      group?: string
      hierarchy?: string[]
      type?: string
      direction?: string
    }
    export interface ILocation {
      lat?: number
      lon?: number
      address?: string
      city?: string
      state?: string
      zip?: string
      country?: string
      region?: string
      storeNumber?: string
    }
    export interface IPaymentMetaData {
      byOrderOf?: string
      payee?: string
      payer?: string
      paymentMethod?: string
      paymentProcessor?: string
      ppdId?: string
      reason?: string
      referenceNumber?: string
    }
    export interface ITransactionMetaData {
      moneyFlow?: string
      isRentPayment?: true | false
      isUtilsPayment?: true | false
      isLoanPayment?: true | false
      isDepositTransfer?: true | false
      isPayrollPayment?: true | false
    }
    export interface ITransaction {
      aggregatorRef?: string
      transactionId?: string
      transactionType?: string
      transactionSubtype?: string
      pending?: true | false
      pendingTransactionId?: string
      removed?: true | false
      createdDatetime?: string
      updatedDatetime?: string
    }
  }
  export namespace FundingAuthorization {
    export namespace Triggers {
      export interface IOnFailure {
        action?: banking.FundingAuthorization.Triggers.Action
        retryOverNetwork?: banking.LinkedAccount.ProcessorNetwork
        retryFundingSourceId?: string
      }
      export interface IOnSuccess {}
      export enum Action {
        none = 'none',
        retry = 'retry',
      }
    }
    export interface ITriggers {
      onFailure?: banking.FundingAuthorization.Triggers.IOnFailure
    }
    export interface IAnalytics {
      purposeCode?: string
      via?: common.IEntityDescriptor
    }
    export interface IACHData {
      fileId?: string
      traceNumber?: string
    }
    export interface IProcessorStatusReason {
      processorId?: string
      status?: string
      code?: string
      originalCode?: string
      description?: string
      dateSettled?: string
      dateFailed?: string
      achOutboundFile?: banking.FundingAuthorization.IACHData
      achReturnFile?: banking.FundingAuthorization.IACHData
    }
    export interface IFees {
      amount?: number
      note?: string
    }
    export enum Direction {
      to = 'to',
      from = 'from',
    }
    export enum ProcessorFundingStatus {
      none = 'none',
      pending = 'pending',
      completed = 'completed',
      failed = 'failed',
      cancelled = 'cancelled',
    }
    export enum ProcessorFundingSubStatus {
      na = 'na',
      queued_for_review = 'queued_for_review',
    }
    export enum ComplianceCodes {
      DEFAULT = 'DEFAULT',
      US_TX = 'US_TX',
      US_WA = 'US_WA',
      COASTAL = 'COASTAL',
      COASTAL_CC = 'COASTAL_CC',
      POSSIBLE_SPV = 'POSSIBLE_SPV',
      COASTAL_BUYBACK_SPV = 'COASTAL_BUYBACK_SPV',
    }
    export enum SettlementDateTimePolicy {
      STRICT = 'STRICT',
      ANY = 'ANY',
      TRUNCATE_TO_HOUR = 'TRUNCATE_TO_HOUR',
      TRUNCATE_TO_DAY = 'TRUNCATE_TO_DAY',
    }
  }
  export namespace BankingEvent {
    export type DataEntry = {[key: string]: string}
  }
  export namespace GetFundingSourcesRequest {
    export interface IFundingSourcesRequestCriteria {
      network?: banking.LinkedAccount.ProcessorNetwork
      direction?: banking.FundingAuthorization.Direction
      processorId?: banking.LinkedProcessorAccount.Processor
      linkedAccountId?: string[]
      paymentInstrumentId?: string[]
      processorStatus?: banking.LinkedProcessorAccount.ProcessorAccountStatus
      fundingSourceStatus?: banking.LinkedProcessorAccount.LinkedProcessorFundingSource.ProcessorFundingSourceStatus
      complianceCodes?: banking.FundingAuthorization.ComplianceCodes[]
      limit?: number
    }
  }
  export namespace GetFundingSourcesResponse {
    export interface IMatchingFundingSource {
      matchedFilter?: banking.GetFundingSourcesRequest.IFundingSourcesRequestCriteria
      fundingSource?: banking.LinkedProcessorAccount.ILinkedProcessorFundingSource[]
      number?: banking.LinkedAccount.INumber[]
    }
  }
  export namespace GetFundingSourcesLinkStatusResponse2 {
    export interface IFundingSourcesLinkStatus {
      processorAccountId?: string
      linkable?: true | false
      linked?: true | false
      processorId?: banking.LinkedProcessorAccount.Processor
      processorAccountStatus?: banking.LinkedProcessorAccount.ProcessorAccountStatus
      processorAccountSubStatus?: banking.LinkedProcessorAccount.ProcessorAccountSubStatus
      tieringUpComplete?: true | false
      supportedComplianceCodes?: banking.FundingAuthorization.ComplianceCodes[]
    }
  }
  export namespace CreateLinkedAccountRequest {
    export interface IPlaidRequest {
      accessToken?: string
    }
    export interface IYodleeRequest {
      username?: string
      password?: string
    }
    export interface IMockedRequest {
      accessToken?: string
      accounts?: banking.LinkedAccount.IAccountBalancePair[]
    }
  }
  export namespace AddPaymentInstrumentRequest {
    export enum AddPolicy {
      replace_existing_by_network_by_linked_account_id = 'replace_existing_by_network_by_linked_account_id',
      replace_existing_by_network = 'replace_existing_by_network',
      add_to_list = 'add_to_list',
      maintain_status_quo = 'maintain_status_quo',
    }
    export enum LinkPolicy {
      link_immediately_with_processor = 'link_immediately_with_processor',
      do_nothing = 'do_nothing',
    }
  }

  export interface ILinkedProcessorAccount {
    processorAccountId?: string
    processorId?: string
    userId?: string
    externalAccountId?: string
    status?: banking.LinkedProcessorAccount.ProcessorAccountStatus
    subStatus?: banking.LinkedProcessorAccount.ProcessorAccountSubStatus
    isTieringUp?: true | false
    createdAt?: string
    updatedAt?: string
  }
  export interface ILinkedAccount {
    id?: string
    userId?: string
    aggregatorId?: string
    aggregatorRef?: string
    accountType?: string
    accountKey?: string
    account?: banking.LinkedAccount.IAccount
    access?: banking.LinkedAccount.IAccess
    accessPublic?: banking.LinkedAccount.IAccess
    accountNumber?: banking.LinkedAccount.INumber[]
    status?: string
    createdAt?: string
    updatedAt?: string
    lastSyncedAt?: protobuf.ITimestamp
    institution?: banking.LinkedAccount.IInstitution
    prefs?: banking.LinkedAccount.IPreferences
    processorAccount?: banking.ILinkedProcessorAccount[]
    isLoginRequired?: true | false
    isFullNumbersAvailable?: true | false
    isInterchangeNumbersAvailable?: true | false
    isFundableAccount?: true | false
    isDepositoryChecking?: true | false
    isDepositorySavings?: true | false
    isDepositoryPrepaid?: true | false
    isSupportedByPf?: true | false
    isPreferredFundingSource?: true | false
    aggregatorRefOriginal?: string
    ownerType?: banking.LinkedAccount.AccountOwnerType
  }
  export interface ITransactionHistoryItem {
    accountId?: string
    internalAccountId?: string
    amount?: number
    amountDirectional?: number
    categoryId?: string
    category?: string[]
    date?: string
    name?: string
    merchantName?: string
    feeMatch?: string
    originalDesc?: string
    accountOwner?: string
    transactionMetadata?: banking.TransactionHistoryItem.ITransactionMetaData
    paymentMetadata?: banking.TransactionHistoryItem.IPaymentMetaData
    location?: banking.TransactionHistoryItem.ILocation
    transaction?: banking.TransactionHistoryItem.ITransaction
    currencyCodeIso?: string
    currencyCodeUnofficial?: string
  }
  export interface IAmount {
    amount?: number
    currency?: string
  }
  export interface ITransactionsProcessor {
    processorId?: string
    processorAccountId?: string
    processorFundingSourceId?: string
    processorNetwork?: string
    fastSettlementsIneligible?: true | false
  }
  export interface IFundingAuthorization {
    id?: string
    userId?: string
    forEntity?: common.IEntityDescriptor
    authorizedByEntity?: common.IEntityDescriptor
    updatedBy?: common.IEntityDescriptor
    amount?: banking.IAmount
    fees?: banking.FundingAuthorization.IFees
    principal?: banking.FundingAuthorization.IFees
    interest?: banking.FundingAuthorization.IFees
    direction?: banking.FundingAuthorization.Direction
    notBeforeDatetimeEffective?: string
    settlementDatetimePolicy?: banking.FundingAuthorization.SettlementDateTimePolicy
    reason?: string
    status?: string
    processorStatus?: string
    processorTransactionId?: string
    isResyncDisabled?: true | false
    processor?: banking.ITransactionsProcessor
    idempotenceKey?: string
    transferId?: string
    processorStatusReason?: banking.FundingAuthorization.IProcessorStatusReason
    fundingSourceId?: string
    complianceCode?: banking.FundingAuthorization.ComplianceCodes
    triggers?: banking.FundingAuthorization.ITriggers
    analytics?: banking.FundingAuthorization.IAnalytics
    createdAt?: string
    updatedAt?: string
  }
  export interface IBankingProcessorSetAccountStatusRequest {
    authorization?: common.IAuthorization
    processorAccount?: banking.ILinkedProcessorAccount
    requestedBy?: common.IEntityDescriptor
  }
  export interface IBankingProcessorGetAccountStatusResponse {
    error?: common.IServiceError
    status?: banking.LinkedProcessorAccount.ProcessorAccountStatus
    subStatus?: banking.LinkedProcessorAccount.ProcessorAccountSubStatus
    tieringUpComplete?: true | false
    supportedComplianceCodes?: banking.FundingAuthorization.ComplianceCodes[]
  }
  export interface IBankingEvent {
    data?: banking.BankingEvent.DataEntry
  }
  export interface ICreateFundingAuthorizationRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization[]
    createdBy?: common.IEntityDescriptor
  }
  export interface ICreateFundingAuthorizationResponse {
    error?: common.IServiceError
    fundingAuthorization?: banking.IFundingAuthorization[]
  }
  export interface IRetryFundingAuthorizationRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization
    createdBy?: common.IEntityDescriptor
  }
  export interface IRetryFundingAuthorizationResponse {
    error?: common.IServiceError
    fundingAuthorization?: banking.IFundingAuthorization
  }
  export interface IGetFundingAuthorizationsRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization
  }
  export interface IGetFundingAuthorizationsResponse {
    error?: common.IServiceError
    fundingAuthorization?: banking.IFundingAuthorization[]
  }
  export interface IGetFundingAuthorizationsByIdsRequest {
    authorization?: common.IAuthorization
    fundingAuthorizationIds?: string[]
  }
  export interface IGetFundingAuthorizationsByIdsResponse {
    fundingAuthorization?: banking.IFundingAuthorization[]
  }
  export interface IFundingSource {
    id?: string
  }
  export interface IGetFundingSourcesRequest {
    authorization?: common.IAuthorization
    userId?: string
    filterBy?: banking.GetFundingSourcesRequest.IFundingSourcesRequestCriteria[]
    includeRemoved?: true | false
  }
  export interface IGetFundingSourcesResponse {
    error?: common.IServiceError
    source?: banking.IFundingSource[]
    fundingSource?: banking.LinkedProcessorAccount.ILinkedProcessorFundingSource[]
    fundingSourcesEx?: banking.GetFundingSourcesResponse.IMatchingFundingSource[]
    processorAccounts?: banking.ILinkedProcessorAccount[]
    result?: banking.GetFundingSourcesResult
  }
  export interface IInitializeAllFundingSourcesRequest {
    authorization?: common.IAuthorization
    userId?: string
    processorId?: string
    processorIds?: string[]
    updatedBy?: common.IEntityDescriptor
  }
  export interface IInitializeAllFundingSourcesResponse {
    error?: common.IServiceError
    source?: banking.IFundingSource[]
    fundingSource?: banking.LinkedProcessorAccount.ILinkedProcessorFundingSource[]
  }
  export interface IGetLinkedAccountRequest {
    authorization?: common.IAuthorization
    linkedAccount?: banking.ILinkedAccount
  }
  export interface IGetLinkedAccountResponse {
    error?: common.IServiceError
    linkedAccount?: banking.ILinkedAccount
  }
  export interface IGetLinkedAccountsRequest {
    authorization?: common.IAuthorization
    userId?: string
    withOptionalMetadata?: true | false
    withPublicTokenIfRequired?: true | false
  }
  export interface IGetLinkedAccountsRelatedRequest {
    authorization?: common.IAuthorization
    accountId?: string[]
  }
  export interface IGetLinkedAccountsResponse {
    error?: common.IServiceError
    accounts?: banking.ILinkedAccount[]
    institutions?: banking.LinkedAccount.IInstitution[]
  }
  export interface ISetPrimaryFundingSourceRequest {
    authorization?: common.IAuthorization
    linkedAccount?: banking.ILinkedAccount
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetPrimaryFundingSourceResponse {
    error?: common.IServiceError
    accounts?: banking.ILinkedAccount[]
  }
  export interface IGetBankingAccountNumbersRequest {
    authorization?: common.IAuthorization
    linkedAccount?: banking.ILinkedAccount
    forceRefresh?: true | false
    requestedBy?: common.IEntityDescriptor
    decryption?: banking.LinkedAccount.Number.IDecryption
  }
  export interface IGetBankingAccountNumbersResponse {
    error?: common.IServiceError
    numbers?: banking.LinkedAccount.INumber[]
  }
  export interface IGetFundingSourcesLinkStatusResponse {
    error?: common.IServiceError
    processorAccountId?: string
    linkable?: true | false
    linked?: true | false
    processorId?: string
    processorAccountStatus?: banking.LinkedProcessorAccount.ProcessorAccountStatus
    processorAccountSubStatus?: banking.LinkedProcessorAccount.ProcessorAccountSubStatus
    tieringUpComplete?: true | false
  }
  export interface IGetFundingSourcesLinkStatusRequest2 {
    authorization?: common.IAuthorization
    userId?: string
    direction?: banking.FundingAuthorization.Direction
    forceRefresh?: true | false
  }
  export interface IGetFundingSourcesLinkStatusResponse2 {
    error?: common.IServiceError
    processorsEnabled?: banking.LinkedProcessorAccount.Processor[]
    status?: banking.GetFundingSourcesLinkStatusResponse2.IFundingSourcesLinkStatus[]
  }
  export interface ISetFundingAuthorizationProcessorStatusByIdRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetFundingAuthorizationProcessorStatusByIdResponse {
    error?: common.IServiceError
  }
  export interface ICancelFundingAuthorizationByIdRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization
    updatedBy?: common.IEntityDescriptor
    ignoreChangesSafetyMargin?: true | false
  }
  export interface ICancelFundingAuthorizationByIdResponse {
    error?: common.IServiceError
    fundingAuthorizations?: banking.IFundingAuthorization[]
  }
  export interface IGetRealTimeAccountBalanceRequest {
    authorization?: common.IAuthorization
    userId?: string
    forceRefresh?: true | false
    cachedOnly?: true | false
    accountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetRealTimeAccountBalanceResponse {
    error?: common.IServiceError
    balances?: banking.LinkedAccount.IAccountBalancePair[]
  }
  export interface IGetNextAvailablePaymentDateRequest {
    authorization?: common.IAuthorization
    userId?: string
    network?: banking.LinkedAccount.ProcessorNetwork
    direction?: banking.FundingAuthorization.Direction
    desiredSettlementDatetime?: string
    timeNow?: string
  }
  export interface IGetNextAvailablePaymentDateResponse {
    error?: common.IServiceError
    settlementDelayDays?: number
    settlesOnHolidaysAndWeekends?: true | false
    desiredSettlementDatetime?: string
    adjustedSettlementDatetime?: string
    submitForProcessingBy?: string
    timeNow?: string
  }
  export interface ISetFundingAuthorizationsStatusRequest {
    authorization?: common.IAuthorization
    fundingAuthorizations?: banking.IFundingAuthorization[]
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetFundingAuthorizationsStatusResponse {
    error?: common.IServiceError
    fundingAuthorizations?: banking.IFundingAuthorization[]
  }
  export interface ISwitchFinInstAggregatorRequest {
    authorization?: common.IAuthorization
    userId?: string
    aggregatorId?: string
    unlinkExisting?: true | false
    issueRelinkUra?: true | false
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISwitchFinInstAggregatorResponse {
    error?: common.IServiceError
  }
  export interface ILinkedAccountNumberVerifyACHRequest {
    authorization?: common.IAuthorization
    linkedAccountId?: string
    routingNumber?: string
    accountNumber?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface ILinkedAccountNumberVerifyACHResponse {
    error?: common.IServiceError
    accountNumberMatchLastFour?: true | false
    accountNumberMatch?: true | false
    routingNumberMatch?: true | false
  }
  export interface ICreateLinkedAccountRequest {
    authorization?: common.IAuthorization
    userId?: string
    aggregatorId?: banking.LinkedAccount.Aggregator
    createdBy?: common.IEntityDescriptor
    plaid?: banking.CreateLinkedAccountRequest.IPlaidRequest
    yodlee?: banking.CreateLinkedAccountRequest.IYodleeRequest
    mocked?: banking.CreateLinkedAccountRequest.IMockedRequest
  }
  export interface ICreateLinkedAccountResponse {
    error?: common.IServiceError
    linkedAccounts?: banking.ILinkedAccount[]
  }
  export interface IAddPaymentInstrumentRequest {
    authorization?: common.IAuthorization
    userId?: string
    number?: banking.LinkedAccount.INumber
    friendlyName?: string
    linkedAccountId?: string
    createdBy?: common.IEntityDescriptor
    addPolicy?: banking.AddPaymentInstrumentRequest.AddPolicy
    linkPolicy?: banking.AddPaymentInstrumentRequest.LinkPolicy
    replacedId?: string
  }
  export interface IAddPaymentInstrumentResponse {
    error?: common.IServiceError
    number?: banking.LinkedAccount.INumber
    result?: banking.AddPaymentInstrumentResult
  }
  export interface IRemovePaymentInstrumentRequest {
    authorization?: common.IAuthorization
    number?: banking.LinkedAccount.INumber
    requestedBy?: common.IEntityDescriptor
  }
  export interface IRemovePaymentInstrumentResponse {
    error?: common.IServiceError
    number?: banking.LinkedAccount.INumber
  }

  export interface ISetPaymentInstrumentStatusRequest {
    authorization?: common.IAuthorization
    userId?: string
    paymentInstrumentId?: string
    status?: banking.LinkedAccount.Number.NumberStatus
    statusReason?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetPaymentInstrumentStatusResponse {
    number?: banking.LinkedAccount.INumber
  }
  export enum LinkedAccountStatus {
    LINKED_ACCOUNT_STATUS_UNSPECIFIED = 'LINKED_ACCOUNT_STATUS_UNSPECIFIED',
    LINKED_ACCOUNT_STATUS_LINKED_IN_USE = 'LINKED_ACCOUNT_STATUS_LINKED_IN_USE',
    LINKED_ACCOUNT_STATUS_UNLINKED = 'LINKED_ACCOUNT_STATUS_UNLINKED',
  }
  export enum GetFundingSourcesResult {
    GET_FUNDING_SOURCES_RESULT_UNSPECIFIED = 'GET_FUNDING_SOURCES_RESULT_UNSPECIFIED',
    GET_FUNDING_SOURCES_RESULT_FOUND_SOURCES = 'GET_FUNDING_SOURCES_RESULT_FOUND_SOURCES',
    GET_FUNDING_SOURCES_RESULT_NO_PROCESSOR_ACCOUNTS = 'GET_FUNDING_SOURCES_RESULT_NO_PROCESSOR_ACCOUNTS',
    GET_FUNDING_SOURCES_RESULT_NO_SOURCES = 'GET_FUNDING_SOURCES_RESULT_NO_SOURCES',
    GET_FUNDING_SOURCES_RESULT_NO_CAPABLE_SOURCES = 'GET_FUNDING_SOURCES_RESULT_NO_CAPABLE_SOURCES',
    GET_FUNDING_SOURCES_RESULT_OTHER_FAILURE = 'GET_FUNDING_SOURCES_RESULT_OTHER_FAILURE',
    GET_FUNDING_SOURCES_RESULT_NO_SOURCES_FOR_PROCESSOR = 'GET_FUNDING_SOURCES_RESULT_NO_SOURCES_FOR_PROCESSOR',
    GET_FUNDING_SOURCES_RESULT_NO_SOURCES_FOR_NETWORK = 'GET_FUNDING_SOURCES_RESULT_NO_SOURCES_FOR_NETWORK',
    GET_FUNDING_SOURCES_RESULT_NO_VERIFIED_SOURCES = 'GET_FUNDING_SOURCES_RESULT_NO_VERIFIED_SOURCES',
    GET_FUNDING_SOURCES_RESULT_NO_LINKED_SOURCES = 'GET_FUNDING_SOURCES_RESULT_NO_LINKED_SOURCES',
    GET_FUNDING_SOURCES_RESULT_NO_USABLE_LINKED_SOURCES = 'GET_FUNDING_SOURCES_RESULT_NO_USABLE_LINKED_SOURCES',
  }
  export enum AddPaymentInstrumentResult {
    ADD_PAYMENT_INSTRUMENT_RESULT_UNSPECIFIED = 'ADD_PAYMENT_INSTRUMENT_RESULT_UNSPECIFIED',
    ADD_PAYMENT_INSTRUMENT_RESULT_SUCCESSFUL = 'ADD_PAYMENT_INSTRUMENT_RESULT_SUCCESSFUL',
    ADD_PAYMENT_INSTRUMENT_RESULT_INVALID_MASK = 'ADD_PAYMENT_INSTRUMENT_RESULT_INVALID_MASK',
    ADD_PAYMENT_INSTRUMENT_RESULT_INVALID_CARD_TYPE = 'ADD_PAYMENT_INSTRUMENT_RESULT_INVALID_CARD_TYPE',
  }
}
export namespace legal {
  export enum NoaaCode {
    NOAA_CODE_UNSPECIFIED = 'NOAA_CODE_UNSPECIFIED',
    NOAA_CODE_URA_EXPIRED = 'NOAA_CODE_URA_EXPIRED',
    NOAA_CODE_LOAN_NO_STATE_LICENSE = 'NOAA_CODE_LOAN_NO_STATE_LICENSE',
    NOAA_CODE_LOAN_STATE_TEMP_UNAVAILABLE = 'NOAA_CODE_LOAN_STATE_TEMP_UNAVAILABLE',
    NOAA_CODE_LOAN_USER_SUSPENDED_BY_PROCESSOR = 'NOAA_CODE_LOAN_USER_SUSPENDED_BY_PROCESSOR',
    NOAA_CODE_LOAN_CANCELLED_BY_USER = 'NOAA_CODE_LOAN_CANCELLED_BY_USER',
    NOAA_CODE_LOAN_CANCELLED_BANKRUPTCY = 'NOAA_CODE_LOAN_CANCELLED_BANKRUPTCY',
    NOAA_CODE_LOAN_EXPIRED_PENDING = 'NOAA_CODE_LOAN_EXPIRED_PENDING',
    NOAA_CODE_LOAN_EXPIRED_APPROVED = 'NOAA_CODE_LOAN_EXPIRED_APPROVED',
    NOAA_CODE_LOAN_RECOVERY_HUMAN_INPUT = 'NOAA_CODE_LOAN_RECOVERY_HUMAN_INPUT',
    NOAA_CODE_LOAN_URA_EXPIRED = 'NOAA_CODE_LOAN_URA_EXPIRED',
    NOAA_CODE_BANKING_ERROR_NO_FUNDABLE_ACCOUNTS = 'NOAA_CODE_BANKING_ERROR_NO_FUNDABLE_ACCOUNTS',
    NOAA_CODE_BANKING_ERROR_ACCOUNT_RELINKING_REQUIRED = 'NOAA_CODE_BANKING_ERROR_ACCOUNT_RELINKING_REQUIRED',
    NOAA_CODE_BANKING_ERROR_ACCOUNT_MIN_HISTORY_NOT_MET = 'NOAA_CODE_BANKING_ERROR_ACCOUNT_MIN_HISTORY_NOT_MET',
    NOAA_CODE_USER_ACCOUNT_DISABLED = 'NOAA_CODE_USER_ACCOUNT_DISABLED',
    NOAA_CODE_USER_ACCOUNT_DUPLICATE = 'NOAA_CODE_USER_ACCOUNT_DUPLICATE',
    NOAA_CODE_USER_ADDRESS_IS_INVALID = 'NOAA_CODE_USER_ADDRESS_IS_INVALID',
    NOAA_CODE_USER_STATE_NOT_SUPPORTED = 'NOAA_CODE_USER_STATE_NOT_SUPPORTED',
    NOAA_CODE_USER_REJECT_QUOTA_REACHED = 'NOAA_CODE_USER_REJECT_QUOTA_REACHED',
    NOAA_CODE_USER_BANKRUPT = 'NOAA_CODE_USER_BANKRUPT',
    NOAA_CODE_USER_ACCOUNT_FRAUDULENT = 'NOAA_CODE_USER_ACCOUNT_FRAUDULENT',
    NOAA_CODE_USER_LEGAL_MLA_CHECK_FAILED = 'NOAA_CODE_USER_LEGAL_MLA_CHECK_FAILED',
    NOAA_CODE_USER_LEGAL_OFAC_CHECK_FAILED = 'NOAA_CODE_USER_LEGAL_OFAC_CHECK_FAILED',
    NOAA_CODE_USER_PROOF_OF_RESIDENCE_MISSING = 'NOAA_CODE_USER_PROOF_OF_RESIDENCE_MISSING',
    NOAA_CODE_USER_IDDOC_CHECK_FAILED = 'NOAA_CODE_USER_IDDOC_CHECK_FAILED',
    NOAA_CODE_USER_SSN_INVALID = 'NOAA_CODE_USER_SSN_INVALID',
    NOAA_CODE_USER_INVALID_INCOME_DOCS = 'NOAA_CODE_USER_INVALID_INCOME_DOCS',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_IN_DEFAULT = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_IN_DEFAULT',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_OVER_COUNT = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_OVER_COUNT',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_INSTALLMENT = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_INSTALLMENT',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_OVER_AMOUNT = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_FAILED_OVER_AMOUNT',
    NOAA_CODE_USER_LEGAL_SDB_CHECK_UNKNOWN = 'NOAA_CODE_USER_LEGAL_SDB_CHECK_UNKNOWN',
    NOAA_CODE_UW_REJECT_LIMITED_PF_HISTORY = 'NOAA_CODE_UW_REJECT_LIMITED_PF_HISTORY',
    NOAA_CODE_UW_AUTO_APPROVED = 'NOAA_CODE_UW_AUTO_APPROVED',
    NOAA_CODE_UW_AUTO_REJECTED = 'NOAA_CODE_UW_AUTO_REJECTED',
    NOAA_CODE_UW_FLAGGED_FOR_REVIEW = 'NOAA_CODE_UW_FLAGGED_FOR_REVIEW',
    NOAA_CODE_UW_FLAGGED_FOR_REVIEW_SFN_FAILURE = 'NOAA_CODE_UW_FLAGGED_FOR_REVIEW_SFN_FAILURE',
    NOAA_CODE_UW_NO_PAYROLL_DETECTED = 'NOAA_CODE_UW_NO_PAYROLL_DETECTED',
    NOAA_CODE_UW_PAYMENT_PROCESSOR_STATUS = 'NOAA_CODE_UW_PAYMENT_PROCESSOR_STATUS',
    NOAA_CODE_UW_NO_INCOME_ESTIMATES = 'NOAA_CODE_UW_NO_INCOME_ESTIMATES',
    NOAA_CODE_UW_NO_BALANCE_DATE_ERROR = 'NOAA_CODE_UW_NO_BALANCE_DATE_ERROR',
    NOAA_CODE_UW_BALANCE_RECENCY = 'NOAA_CODE_UW_BALANCE_RECENCY',
    NOAA_CODE_UW_ID_DOC_VERIFICATION_REQ = 'NOAA_CODE_UW_ID_DOC_VERIFICATION_REQ',
    NOAA_CODE_UW_ID_DOC_NUMBER_VERIFICATION_REQ = 'NOAA_CODE_UW_ID_DOC_NUMBER_VERIFICATION_REQ',
    NOAA_CODE_UW_INCOME_VERIFICATION_REQ = 'NOAA_CODE_UW_INCOME_VERIFICATION_REQ',
    NOAA_CODE_UW_NO_AMOUNT_PROPOSED = 'NOAA_CODE_UW_NO_AMOUNT_PROPOSED',
    NOAA_CODE_UW_NO_PAYMENT_DATES_PROPOSED = 'NOAA_CODE_UW_NO_PAYMENT_DATES_PROPOSED',
  }
}
export namespace uhura {
  export namespace MessageRequest {
    export type VariablesEntry = {[key: string]: string}
  }
  export namespace MessageResponse {
    export type ResultsEntry = {[key: string]: string}
  }
  export namespace LocalizedTemplate {
    export type TitleOverrideEntry = {[key: string]: string}
  }
  export namespace SubmitContactUsFormRequest {
    export interface IFieldValues {
      fieldId?: string
      values?: string[]
      type?: string
    }
  }
  export interface IMessageAttachment {
    URL?: string
    name?: string
    description?: string
    disposition?: uhura.AttachmentDisposition
  }
  export interface IMessageRequest {
    templateId?: string
    userId?: string
    address?: string
    type?: uhura.MessageType[]
    variables?: uhura.MessageRequest.VariablesEntry
    istest?: true | false
    idempotenceKey?: string
    emailAddresses?: string[]
    attachments?: uhura.IMessageAttachment[]
    authorization?: common.IAuthorization
    dedupeOverrideSeconds?: protobuf.IInt64Value
  }
  export interface IMessageResponse {
    results?: uhura.MessageResponse.ResultsEntry
  }
  export interface IGetTemplateListRequest {
    authorization?: common.IAuthorization
  }
  export interface ITemplate {
    templateId?: string
    title?: string
    description?: string
    config?: string
    smsTemplate?: string
    pushTemplate?: string
    emailTextTemplate?: string
    emailHtmlTemplate?: string
    zendeskTemplate?: string
    uraTemplate?: string
    voiceTemplate?: string
    localizedTemplates?: uhura.ILocalizedTemplate[]
  }
  export interface ILocalizedTemplate {
    templateId?: string
    language?: uhura.TemplateLanguage
    title?: string
    smsTemplate?: string
    pushTemplate?: string
    emailTextTemplate?: string
    emailHtmlTemplate?: string
    zendeskTemplate?: string
    uraTemplate?: string
    voiceTemplate?: string
    titleOverride?: uhura.LocalizedTemplate.TitleOverrideEntry
  }
  export interface IGetTemplateListResponse {
    templates?: uhura.ITemplate[]
  }
  export interface IGetTemplateRequest {
    templateId?: string
    authorization?: common.IAuthorization
  }
  export interface IGetTemplateResponse {
    template?: uhura.ITemplate
  }
  export interface ISetTemplateRequest {
    template?: uhura.ITemplate
    authorization?: common.IAuthorization
  }
  export interface IDeleteTemplateRequest {
    templateId?: string
    authorization?: common.IAuthorization
  }
  export interface IRenderTemplateResponse {
    title?: string
    content?: string
  }
  export interface ICommunicationAuditTrail {
    templateId?: string
    type?: string
    title?: string
    createdAt?: string
    updatedAt?: string
    userId?: string
    text?: string
    html?: string
  }
  export interface IGetCommunicationsForUserRequest {
    authorization?: common.IAuthorization
    userId?: string
    pageNumber?: protobuf.IInt32Value
    pageSize?: protobuf.IInt32Value
    includeText?: boolean
  }
  export interface IGetCommunicationsForUserResponse {
    communications?: uhura.ICommunicationAuditTrail[]
  }
  export interface IContactUsFormFieldOption {
    name?: string
    value?: string
  }
  export interface IContactUsFormField {
    title?: string
    id?: string
    type?: string
    description?: string
    required?: true | false
    options?: uhura.IContactUsFormFieldOption[]
    editableInPortal?: true | false
    regexpForValidation?: true | false
  }
  export interface IContactUsConditionsChildField {
    id?: string
    isRequired?: true | false
  }
  export interface IContactUsConditions {
    parentField?: string
    value?: string
    childFields?: uhura.IContactUsConditionsChildField[]
  }
  export interface IContactUsForm {
    name?: string
    id?: string
    fields?: uhura.IContactUsFormField[]
    conditions?: uhura.IContactUsConditions[]
  }
  export interface IGetContactUsFormRequest {
    authorization?: common.IAuthorization
  }
  export interface IGetContactUsFormResponse {
    form?: uhura.IContactUsForm
  }
  export interface ISubmitContactUsFormRequest {
    authorization?: common.IAuthorization
    fields?: uhura.SubmitContactUsFormRequest.IFieldValues[]
    name?: string
    email?: string
    attachments?: string[]
  }
  export interface ISubmitContactUsFormResponse {}
  export interface IUsersCommunicationPreferences {
    userId?: string
    doNotContact?: true | false
    blacklisted?: boolean
    loginContact?: true | false
    escalate?: boolean
    complaint?: boolean
    updatedById?: string
  }
  export interface IUsersCommunicationPreferencesRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IUsersCommunicationPreferencesResponse {
    usersCommunicationPreferences?: uhura.IUsersCommunicationPreferences
  }
  export interface IUpdateUsersCommunicationPreferencesRequest {
    authorization?: common.IAuthorization
    usersCommunicationPreferences?: uhura.IUsersCommunicationPreferences
  }
  export interface IHasNewCommunicationsRequest {
    authorization?: common.IAuthorization
    userId?: string
    cutoffAgeMillis?: number
    ignoreType?: uhura.MessageType[]
  }
  export enum MessageType {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    PUSH = 'PUSH',
    SUBSPACE = 'SUBSPACE',
    ZENDESK = 'ZENDESK',
    URA = 'URA',
    BRAZE = 'BRAZE',
    VOICE = 'VOICE',
    SMS_VERIFY = 'SMS_VERIFY',
    AMPLITUDE = 'AMPLITUDE',
    SMS_MFA = 'SMS_MFA',
  }
  export enum AttachmentDisposition {
    ATTACHMENT = 'ATTACHMENT',
    INLINE = 'INLINE',
  }
  export enum TemplateLanguage {
    ES_US = 'ES_US',
  }
  export enum URAContext {
    URA_CONTEXT_UNSPECIFIED = 'URA_CONTEXT_UNSPECIFIED',
    URA_CONTEXT_FAILED_PAYMENT = 'URA_CONTEXT_FAILED_PAYMENT',
  }
  export enum URATypes {
    URA_TYPES_NOT_APPLICABLE = 'URA_TYPES_NOT_APPLICABLE',
    URA_TYPES_LINK_ACCOUNT_WITH_MORE_DATA = 'URA_TYPES_LINK_ACCOUNT_WITH_MORE_DATA',
    URA_TYPES_RELINK_ACCOUNT = 'URA_TYPES_RELINK_ACCOUNT',
    URA_TYPES_LINK_SUPPORTED_INSTITUTION = 'URA_TYPES_LINK_SUPPORTED_INSTITUTION',
    URA_TYPES_LINK_SUPPORTED_ACCOUNTS = 'URA_TYPES_LINK_SUPPORTED_ACCOUNTS',
    URA_TYPES_UPDATE_PII_ADDRESS = 'URA_TYPES_UPDATE_PII_ADDRESS',
    URA_TYPES_UPDATE_PII_SSN = 'URA_TYPES_UPDATE_PII_SSN',
    URA_TYPES_SSN_CARD = 'URA_TYPES_SSN_CARD',
    URA_TYPES_RETAKE_ID_FRONT = 'URA_TYPES_RETAKE_ID_FRONT',
    URA_TYPES_RETAKE_LIVENESS = 'URA_TYPES_RETAKE_LIVENESS',
    URA_TYPES_RETAKE_ID_LIGHTING = 'URA_TYPES_RETAKE_ID_LIGHTING',
    URA_TYPES_VERIFY_NAME_MATCHES_ID = 'URA_TYPES_VERIFY_NAME_MATCHES_ID',
    URA_TYPES_OUT_OF_STATE = 'URA_TYPES_OUT_OF_STATE',
    URA_TYPES_DOCS_PROOF_OF_INCOME = 'URA_TYPES_DOCS_PROOF_OF_INCOME',
    URA_TYPES_ACCOUNT_NUMBER_REQUIRED_ACH = 'URA_TYPES_ACCOUNT_NUMBER_REQUIRED_ACH',
    URA_TYPES_ACCOUNT_NUMBER_REQUIRED_INTERCHANGE = 'URA_TYPES_ACCOUNT_NUMBER_REQUIRED_INTERCHANGE',
    URA_TYPES_RETAKE_ID_FORM = 'URA_TYPES_RETAKE_ID_FORM',
    URA_TYPES_RETAKE_ID_FINGERS = 'URA_TYPES_RETAKE_ID_FINGERS',
    URA_TYPES_RETAKE_ID_CORNERS = 'URA_TYPES_RETAKE_ID_CORNERS',
    URA_TYPES_RETAKE_ID_EXPIRED = 'URA_TYPES_RETAKE_ID_EXPIRED',
    URA_TYPES_POR_DATE = 'URA_TYPES_POR_DATE',
    URA_TYPES_CONTACT_US = 'URA_TYPES_CONTACT_US',
    URA_TYPES_PROVIDE_POR_ADDRESS = 'URA_TYPES_PROVIDE_POR_ADDRESS',
    URA_TYPES_POR_LN_MISMATCH = 'URA_TYPES_POR_LN_MISMATCH',
    URA_TYPES_IDENTITY_DOCS_REQUIRED = 'URA_TYPES_IDENTITY_DOCS_REQUIRED',
    URA_TYPES_PAYMENTS_OVERDUE = 'URA_TYPES_PAYMENTS_OVERDUE',
    URA_TYPES_PAYMENT_RESCHEDULED = 'URA_TYPES_PAYMENT_RESCHEDULED',
    URA_TYPES_PAYMENT_RESCHEDULE_REQUEST = 'URA_TYPES_PAYMENT_RESCHEDULE_REQUEST',
    URA_TYPES_PAYMENTS_REAUTH_REQUIRED = 'URA_TYPES_PAYMENTS_REAUTH_REQUIRED',
    URA_TYPES_PAYMENT_INSTRUMENT_UPDATE_REQUIRED = 'URA_TYPES_PAYMENT_INSTRUMENT_UPDATE_REQUIRED',
    URA_TYPES_OTHER = 'URA_TYPES_OTHER',
    URA_TYPES_LOAN_REJECTED = 'URA_TYPES_LOAN_REJECTED',
    URA_TYPES_LINK_ACCOUNT_WITH_NEW_NAME = 'URA_TYPES_LINK_ACCOUNT_WITH_NEW_NAME',
    URA_TYPES_LINK_PERSONAL_ACCOUNT = 'URA_TYPES_LINK_PERSONAL_ACCOUNT',
    URA_TYPES_POR_BANK_ADDRESS_MISMATCH = 'URA_TYPES_POR_BANK_ADDRESS_MISMATCH',
    URA_TYPES_UNFREEZE_BUREAU_FROZEN_FILE = 'URA_TYPES_UNFREEZE_BUREAU_FROZEN_FILE',
  }
}
export namespace cards {
  export namespace CardServiceGetStatementsRequest {
    export interface IDateRange {
      startDate?: protobuf.ITimestamp
      endDate?: protobuf.ITimestamp
    }
  }
  export namespace CardsServiceGetIssuerProcessorsRequest {
    export interface IFilterBy {
      status?: cards.IssuerProcessorStatus
    }
  }
  export namespace CardAccountApplicationProperties {
    export type ExtraFlagsEntry = {[key: string]: true | false}
  }
  export namespace GetCardPanDecodedDataByIdResponse {
    export interface ICardData {
      number?: string
      expiry?: string
      cvv?: string
    }
  }
  export namespace GetCardPanDecodingDataByIdResponse {
    export interface II2CCardData {
      authToken?: string
      cardReferenceNumber?: string
    }
  }
  export namespace ApprovalManualReviewActionRequest {
    export interface IRejectionData {
      rejectionCodes?: legal.NoaaCode[]
    }
    export interface ISnoozeData {
      hours?: protobuf.IInt32Value
    }
    export interface IUraData {
      uraList?: cards.ICardUraData[]
      expiresInHours?: protobuf.IInt32Value
    }
  }
  export namespace ExecuteCardsToolsGenericCommandRequest {
    export type ParamsEntry = {[key: string]: string}
  }
  export interface ICardsIssuerProcessor {
    processorId?: cards.IssuerProcessorId
  }
  export interface ICardAccountType {
    id?: string
    issuerProcessorId?: cards.IssuerProcessorId
    cardNetwork?: cards.CardNetwork
    ledgerTimezoneId?: string
    programDetails?: cards.ICardProgramDetails
  }
  export interface ICardProgramDetails {
    name?: string
    minBalanceLimit?: protobuf.IDoubleValue
    maxBalanceLimit?: protobuf.IDoubleValue
    monthlyMembershipFees?: protobuf.IDoubleValue
    isMembershipSupported?: boolean
  }
  export interface ICardAccount {
    id?: string
    typeId?: string
    userId?: string
    issuerProcessorId?: cards.IssuerProcessorId
    cardNetwork?: cards.CardNetwork
    cards?: cards.ICard[]
    balanceLimit?: protobuf.IDoubleValue
    status?: cards.CardAccountStatus
    subStatus?: cards.CardAccountSubStatus
    cardSuspensionReason?: cards.CardAccountSuspensionStatusReason
    cardDeactivationReason?: cards.CardAccountDeactivationStatusReason
    rejectionReasonCodes?: string
    overdueStatus?: cards.CardAccountOverDueStatus
    approvedAt?: protobuf.ITimestamp
    rejectedAt?: protobuf.ITimestamp
    activeAt?: protobuf.ITimestamp
    suspendedAt?: protobuf.ITimestamp
    deactivatedAt?: protobuf.ITimestamp
    expiredAt?: protobuf.ITimestamp
    cancelledAt?: protobuf.ITimestamp
    lockedAt?: protobuf.ITimestamp
    lockedExpiresAt?: protobuf.ITimestamp
    eligibleToReapplyAfter?: protobuf.ITimestamp
    autopayEnabled?: boolean
    autopayEnabledAt?: protobuf.ITimestamp
    installmentsEnabled?: boolean
    installmentsEnabledAt?: protobuf.ITimestamp
    miscData?: cards.ICardAccountMiscData
    updatedBy?: common.IEntityDescriptor
    updatedAt?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
    creditReportingId?: string
  }
  export interface ICardAccountSimplifiedVersion {
    id?: string
    userId?: string
    typeId?: string
    status?: cards.CardAccountStatus
    subStatus?: cards.CardAccountSubStatus
    createdAt?: protobuf.ITimestamp
  }
  export interface ICard {
    id?: string
    issuerProcessorAccountId?: string
    cardAccountId?: string
    type?: cards.CardType
    status?: cards.CardStatus
    externalId?: string
    bin?: string
    numberEncrypted?: protobuf.IBytesValue
    mask?: string
    expiryEncrypted?: protobuf.IBytesValue
    cvvEncrypted?: protobuf.IBytesValue
    isPanAvailable?: boolean
    activeAt?: protobuf.ITimestamp
    suspendedAt?: protobuf.ITimestamp
    deactivatedAt?: protobuf.ITimestamp
    updatedBy?: common.IEntityDescriptor
    updatedAt?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
    specialCommentCode?: string
    complianceConditionCode?: string
    delinquentNumberOfDays?: protobuf.IInt32Value
    highestBalanceSpent?: protobuf.IDoubleValue
  }
  export interface ICardAccountBalance {
    cardAccountId?: string
    availableBalance?: protobuf.IDoubleValue
    ledgerBalance?: protobuf.IDoubleValue
    ledgerBalanceAsOfDate?: protobuf.ITimestamp
    availableSpent?: protobuf.IDoubleValue
    ledgerSpent?: protobuf.IDoubleValue
    ledgerSpentAfterPayments?: protobuf.IDoubleValue
    minPaymentDue?: protobuf.IDoubleValue
  }
  export interface IStatement {
    id?: string
    cardAccountId?: string
    issuerProcessorCardId?: string
    statementDate?: protobuf.ITimestamp
    paymentDueDate?: protobuf.ITimestamp
    presignedUrl?: string
    balance?: protobuf.IDoubleValue
    updatedBy?: common.IEntityDescriptor
    updatedAt?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
  }
  export interface IMerchantInfo {
    merchantId?: string
    merchantName?: string
    merchantLogoUrl?: string
    merchantLogoIsBase64Encoded?: boolean
  }
  export interface ICardServiceGetStatementsRequest {
    cardAccountId?: string
    byStatementId?: string
    byDateRange?: cards.CardServiceGetStatementsRequest.IDateRange
  }
  export interface ICardServiceGetStatementsResponse {
    statements?: cards.IStatement[]
  }
  export interface ICardServiceDownloadStatementRequest {
    id?: string
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface ICardServiceDownloadStatementResponse {
    id?: string
    cardAccountId?: string
    presignedUrl?: string
    expiresAt?: protobuf.ITimestamp
  }
  export interface ICardsServiceGetIssuerProcessorsRequest {
    filterBy?: cards.CardsServiceGetIssuerProcessorsRequest.IFilterBy
  }
  export interface ICardsServiceGetIssuerProcessorsResponse {
    processors?: cards.ICardsIssuerProcessor[]
  }
  export interface IGetCardAccountOffersRequest {
    userId?: string
  }
  export interface IGetCardAccountOffersResponse {
    cardAccountTypes?: cards.ICardAccountType[]
    cardOfferAvailabilityStatus?: cards.CardOfferAvailabilityStatus
  }
  export interface IGetApplicationPreRequisitesRequest {
    userId?: string
  }
  export interface IApplicationPrerequisite {
    preqName?: cards.ApplicationPrerequisiteName
    preqClass?: cards.ApplicationPrerequisitesClass
    preqStatus?: cards.ApplicationPrerequisitesStatus
  }
  export interface IGetApplicationPreRequisitesResponse {
    prerequisites?: cards.IApplicationPrerequisite[]
  }
  export interface ICardAccountMiscData {
    cohortPhase?: cards.CohortPhase
    cohortUserType?: cards.CohortUserType
  }
  export interface ICardAccountApplicationProperties {
    enabled?: true | false
    miscData?: cards.ICardAccountMiscData
    extraFlags?: cards.CardAccountApplicationProperties.ExtraFlagsEntry
  }
  export interface IToggleCardAccountApplicationPropertiesRequest {
    userId?: string
    properties?: cards.ICardAccountApplicationProperties
  }
  export interface IMultiUserToggleCardAccountApplicationPropertiesRequest {
    userIds?: string[]
    properties?: cards.ICardAccountApplicationProperties
  }
  export interface IApplyForCardAccountRequest {
    userId?: string
    cardTypeId?: string
    userIpAddress?: string
    miscData?: cards.ICardAccountMiscData
    requestedBy?: common.IEntityDescriptor
  }
  export interface IApplyForCardAccountResponse {
    cardAccount?: cards.ICardAccount
  }
  export interface IAcceptApprovedCardAccountRequest {
    cardAccountId?: string
    userIpAddress?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IAcceptApprovedCardAccountResponse {}
  export interface IDeclineApprovedCardAccountRequest {
    cardAccountId?: string
    userIpAddress?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IDeclineApprovedCardAccountResponse {}
  export interface IActivateCardRequest {
    cardAccountId?: string
    cardId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IActivateCardResponse {}
  export interface ILockActiveCardAccountRequest {
    cardAccountId?: string
    reason?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface ILockActiveCardAccountResponse {}
  export interface IGetCardAccountBalanceRequest {
    cardAccountId?: string
  }
  export interface IGetCardAccountBalanceResponse {
    balance?: cards.ICardAccountBalance
  }
  export interface ICardBalanceEntry {
    balanceAmount?: number
    balanceType?: cards.CardBalanceType
    balanceDateTime?: protobuf.ITimestamp
    associatedTransactionId?: string
  }
  export interface IGetCardAccountBalanceEntriesOfTypeRequest {
    externalId?: string
    type?: cards.CardBalanceType
    offset?: number
    count?: number
  }
  export interface IGetCardAccountBalanceEntriesOfTypeResponse {
    balances?: cards.ICardBalanceEntry[]
    offset?: number
    continues?: true | false
  }
  export interface IGetBalanceAtChargedOffRequest {
    cardAccountId?: string
    issuerProcessorCardId?: string
  }
  export interface IGetBalanceAtChargedOffResponse {
    ledgerBalance?: protobuf.IDoubleValue
  }
  export interface IGetCardAccountByIdRequest {
    cardAccountId?: string
  }
  export interface IGetCardAccountByIdResponse {
    cardAccount?: cards.ICardAccount
    cardAccountType?: cards.ICardAccountType
  }
  export interface IGetCardPanDecodedDataByIdRequest {
    cardAccountId?: string
    cardId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetCardPanDecodedDataByIdResponse {
    card?: cards.ICard
    cardData?: cards.GetCardPanDecodedDataByIdResponse.ICardData
  }
  export interface IGetCardPanDecodingDataByIdRequest {
    cardAccountId?: string
    cardId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetCardPanDecodingDataByIdResponse {
    card?: cards.ICard
    i2cCardData?: cards.GetCardPanDecodingDataByIdResponse.II2CCardData
  }
  export interface IGetCardAccountsByUserIdRequest {
    userId?: string
  }
  export interface IGetCardAccountsByUserIdResponse {
    cardAccountId?: string
  }
  export interface IGetCardAccountsSimplifiedVersionByUserIdRequest {
    userId?: string
  }
  export interface IGetCardAccountsSimplifiedVersionByUserIdResponse {
    cards?: cards.ICardAccountSimplifiedVersion[]
  }
  export interface ICardBillPaymentMethod {
    id?: string
    cardAccountId?: string
    paymentInstrumentId?: string
    primary?: true | false
    processorNetwork?: banking.LinkedAccount.ProcessorNetwork
    accountPrefix?: string
    accountMask?: string
    friendlyName?: string
    updatedBy?: common.IEntityDescriptor
    updatedAt?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
  }
  export interface IGetCardBillPaymentMethodsRequest {
    cardAccountId?: string
  }
  export interface IGetCardBillPaymentMethodsResponse {
    billPaymentMethod?: cards.ICardBillPaymentMethod[]
  }
  export interface IAddCardBillPaymentMethodRequest {
    cardAccountId?: string
    paymentInstrumentId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IAddCardBillPaymentMethodResponse {
    billPaymentMethod?: cards.ICardBillPaymentMethod
  }
  export interface IMarkCardBillPaymentMethodPrimaryRequest {
    cardBillPaymentMethodId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IMarkCardBillPaymentMethodPrimaryResponse {
    billPaymentMethod?: cards.ICardBillPaymentMethod
  }
  export interface IScheduleCardAccountPaymentRequest {
    cardAccountId?: string
    cardBillPaymentMethodId?: string
    amount?: protobuf.IDoubleValue
    executeAt?: protobuf.ITimestamp
    requestedBy?: common.IEntityDescriptor
  }
  export interface IScheduleCardAccountPaymentResponse {
    payment?: cards.ICardPayment
  }
  export interface IAutoPaymentSchedule {
    startingAt?: protobuf.ITimestamp
    recurrence?: cards.ScheduleRecurrence
  }
  export interface IFeeSchedule {
    date?: protobuf.ITimestamp
    amount?: protobuf.IDoubleValue
  }
  export interface IAutomaticPayment {
    id?: string
    cardAccountId?: string
    userId?: string
    paymentId?: string
    paymentDate?: protobuf.ITimestamp
    amount?: protobuf.IDoubleValue
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
  }
  export interface ISetupAutoPaymentScheduleRequest {
    cardAccountId?: string
    schedule?: cards.IAutoPaymentSchedule
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetupAutoPaymentScheduleResponse {
    schedule?: cards.IAutoPaymentSchedule
    upcoming?: cards.IAutomaticPayment[]
  }
  export interface IGetAutoPaymentScheduleRequest {
    cardAccountId?: string
    returnExamplesForTheNextNDays?: protobuf.IInt32Value
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetAutoPaymentScheduleResponse {
    schedule?: cards.IAutoPaymentSchedule
    upcoming?: cards.IAutomaticPayment[]
  }
  export interface ICardPayment {
    paymentId?: string
    cardAccountId?: string
    fundingAuthorizationId?: string
    amount?: protobuf.IDoubleValue
    status?: cards.CardPaymentStatus
    statusCode?: string
    statusReason?: string
    executeAt?: protobuf.ITimestamp
    groupId?: string
    ordinality?: protobuf.IInt32Value
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    paymentType?: cards.CardPaymentType
    scheduledAt?: protobuf.ITimestamp
    initiatedAt?: protobuf.ITimestamp
    postedAt?: protobuf.ITimestamp
  }
  export interface ICardAccountAuditEntry {
    id?: string
    cardAccountId?: string
    cardId?: string
    entryType?: cards.AuditEntryType
    entryNote?: string
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    updatedBy?: common.IEntityDescriptor
    balanceLimit?: protobuf.IDoubleValue
    acceptedAt?: protobuf.ITimestamp
    suspendedAt?: protobuf.ITimestamp
    deactivatedAt?: protobuf.ITimestamp
    approvedAt?: protobuf.ITimestamp
    cancelledAt?: protobuf.ITimestamp
    expiredAt?: protobuf.ITimestamp
    lockedAt?: protobuf.ITimestamp
    lockExpiresAt?: protobuf.ITimestamp
    status?: cards.CardAccountStatus
    previousStatus?: cards.CardAccountStatus
    subStatus?: cards.CardAccountSubStatus
    previousSubStatus?: cards.CardAccountSubStatus
    overdueStatus?: cards.CardAccountOverDueStatus
    previousOverdueStatus?: cards.CardAccountOverDueStatus
    suspensionReason?: cards.CardAccountSuspensionStatusReason
    deactivationReason?: cards.CardAccountDeactivationStatusReason
    billingCycleStartDayOfMonth?: protobuf.IInt32Value
    billingCycleTimezoneId?: string
    userIpAddress?: string
    userDeviceId?: string
    rejectedAt?: protobuf.ITimestamp
    rejectionReasonCodes?: string
    underwritingResponseId?: string
    paymentMethodId?: string
    bankingPaymentInstrumentId?: string
    bankingPaymentInstrumentIsPrimary?: true | false
    paymentId?: string
    paymentAmount?: protobuf.IDoubleValue
    paymentStatus?: cards.CardPaymentStatus
    craInquiryReportId?: string
    craInquiryProviderId?: cards.ConsumerInquiryProviderId
    isBadSsn?: true | false
    isMla?: true | false
    isOfac?: true | false
    isPorFailure?: true | false
    cardAccountStatementId?: string
    cardAccountStatementPresignedUrl?: string
  }
  export interface IGetPaymentHistoryRequest {
    cardAccountId?: string
    from?: protobuf.ITimestamp
    to?: protobuf.ITimestamp
  }
  export interface IGetPaymentHistoryResponse {
    payments?: cards.ICardPayment[]
  }
  export interface IGetUpcomingScheduledPaymentsRequest {
    cardAccountId?: string
    from?: protobuf.ITimestamp
    to?: protobuf.ITimestamp
  }
  export interface IScheduledFuturePayment {
    estimatedPayment?: protobuf.IDoubleValue
    paymentAt?: protobuf.ITimestamp
  }
  export interface IGetUpcomingScheduledPaymentsResponse {
    payments?: cards.IScheduledFuturePayment[]
  }
  export interface ICardAccountAgreement {
    agreementId?: string
    agreementType?: cards.CardAccountAgreementType
    agreementStatus?: cards.CardAccountAgreementStatus
    userIpAddress?: string
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
  }
  export interface IGetCardAccountAgreementsRequest {
    cardAccountId?: string
    agreementId?: string
  }
  export interface IGetCardAccountAgreementsResponse {
    agreements?: cards.ICardAccountAgreement[]
  }
  export interface ICardAccountAgreementDownloadRequest {
    cardAccountId?: string
    agreementId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IPresignedUrl {
    url?: string
    expiresAt?: protobuf.ITimestamp
  }
  export interface ICardAccountAgreementDownload {
    agreement?: cards.ICardAccountAgreement
    presignedUrl?: cards.IPresignedUrl
  }
  export interface ICardAccountAgreementDownloadResponse {
    cardAccountId?: string
    agreementDownload?: cards.ICardAccountAgreementDownload[]
  }
  export interface IAcceptCardAccountAgreementAction {
    agreementId?: string
    action?: cards.CardAccountAgreementAction
  }
  export interface IAcceptCardAccountAgreementsRequest {
    agreementActions?: cards.IAcceptCardAccountAgreementAction[]
    userIpAddress?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IAcceptCardAccountAgreementsResponse {}
  export interface IManualReviewTask {
    cardAccountId?: string
    assigneeId?: string
    taskId?: string
    reviewReasonCode?: cards.ManualReviewReasonCode
    reviewReasonMessage?: string
  }
  export interface IManualReviewTaskHistory {
    reviewId?: string
    cardAccountId?: string
    assigneeId?: string
    taskId?: string
    reviewReasonCode?: cards.ManualReviewReasonCode
    reviewReasonMessage?: string
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    assignedAt?: protobuf.ITimestamp
  }
  export interface IAssignApprovalManualReviewToAssigneeRequest {
    cardAccountId?: string
    assigneeId?: string
    taskId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IAssignApprovalManualReviewToAssigneeResponse {
    manualReviewTask?: cards.IManualReviewTask
  }
  export interface IGetApprovalManualReviewsByCardAccountIdRequest {
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetApprovalManualReviewsByCardAccountIdResponse {
    approvalManualReview?: cards.IApprovalManualReview
  }
  export interface IGetNextApprovalManualReviewByAssigneeRequest {
    assigneeId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetNextApprovalManualReviewByAssigneeResponse {
    approvalManualReview?: cards.IApprovalManualReview
  }
  export interface IApprovalManualReview {
    manualReviewTask?: cards.IManualReviewTask
    cardAccount?: cards.ICardAccount
    cardAccountType?: cards.ICardAccountType
    userFullName?: string
    userId?: string
    history?: cards.IManualReviewTaskHistory[]
  }
  export interface ICardUraData {
    id?: string
    userId?: string
    cardAccountId?: string
    actionType?: uhura.URATypes
    linkedAccountId?: string
    context?: uhura.URAContext
  }
  export interface IApprovalManualReviewActionRequest {
    action?: cards.ApprovalManualReviewAction
    taskId?: string
    approvalMaxCreditLimit?: protobuf.IDoubleValue
    rejectionData?: cards.ApprovalManualReviewActionRequest.IRejectionData
    snoozeData?: cards.ApprovalManualReviewActionRequest.ISnoozeData
    uraData?: cards.ApprovalManualReviewActionRequest.IUraData
    requestedBy?: common.IEntityDescriptor
  }
  export interface IApprovalManualReviewActionResponse {}
  export interface IMerchantDevice {
    id?: string
    type?: cards.MerchantDeviceType
  }
  export interface IMerchant {
    id?: string
    categoryCode?: string
    name?: string
    city?: string
    state?: string
    country?: string
    device?: cards.IMerchantDevice
  }
  export interface ITransactionHistoryBalance {
    availableBalance?: protobuf.IDoubleValue
    ledgerBalance?: protobuf.IDoubleValue
  }
  export interface ITransactionAmount {
    amount?: protobuf.IDoubleValue
  }
  export interface ITransactionDispute {
    type?: cards.TransactionDisputeType
    status?: cards.TransactionDisputeStatus
    amount?: protobuf.IDoubleValue
    datetime?: protobuf.ITimestamp
  }
  export interface ITransactionHistoryItem {
    cardAccountId?: string
    externalId?: string
    transactionId?: string
    authorizationCode?: string
    transactionDatetime?: protobuf.ITimestamp
    transactionPostedDatetime?: protobuf.ITimestamp
    transactionExpiryDatetime?: protobuf.ITimestamp
    amount?: cards.ITransactionAmount
    amountAdditional?: cards.ITransactionAmount
    description?: string
    merchant?: cards.IMerchant
    acceptor?: cards.IMerchant
    balance?: cards.ITransactionHistoryBalance
    dispute?: cards.ITransactionDispute
  }
  export interface IGetTransactionHistoryRequest {
    cardAccountId?: string
    cardId?: string
    fromDatetime?: protobuf.ITimestamp
    toDatetime?: protobuf.ITimestamp
    offset?: protobuf.IInt32Value
    count?: protobuf.IInt32Value
    includeEverything?: boolean
  }
  export interface IGetTransactionHistoryResponse {
    items?: cards.ITransactionHistoryItem[]
    offset?: protobuf.IInt32Value
    continues?: boolean
  }
  export interface ICardAccountDelinquencyHistory {
    id?: string
    cardAccountId?: string
    daysDelinquent?: number
    delinquencyDate?: string
    dayOfMonth?: number
  }
  export interface IGetCardAccountDelinquencySnapshotRequest {
    cardAccountId?: string
  }
  export interface IGetCardAccountDelinquencySnapshotResponse {
    isDelinquent?: boolean
    delinquencyNumberOfDays?: protobuf.IInt32Value
    category?: cards.CardAccountDelinquencyCategory
  }
  export interface IGetCardAccountCreditReportingDelinquencyHistoryRequest {
    cardAccountId?: string
    dayOfMonth?: number
  }
  export interface IGetCardAccountCreditReportingDelinquencyHistoryResponse {
    cardDelinquencyHistories?: cards.ICardAccountDelinquencyHistory[]
  }
  export interface ISuspendCardAccountRequest {
    cardAccountId?: string
    suspensionStatusReason?: cards.CardAccountSuspensionStatusReason
    requestedBy?: common.IEntityDescriptor
  }
  export interface IDeactivateCardAccountRequest {
    cardAccountId?: string
    deactivationStatusReason?: cards.CardAccountDeactivationStatusReason
    requestedBy?: common.IEntityDescriptor
  }
  export interface IReactivateCardAccountRequest {
    cardAccountId?: string
    reactivationReason?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetCardAccountPaymentsRequest {
    cardAccountId?: string
    fromDatetime?: protobuf.ITimestamp
    toDatetime?: protobuf.ITimestamp
    offset?: protobuf.IInt32Value
    count?: protobuf.IInt32Value
  }
  export interface IGetCardAccountPaymentsResponse {
    payments?: cards.ICardPayment[]
    offset?: protobuf.IInt32Value
    continues?: boolean
  }
  export interface IGetCardAccountPaymentByPaymentIdRequest {
    cardAccountId?: string
    paymentId?: string
  }
  export interface IGetCardAccountPaymentByPaymentIdResponse {
    payment?: cards.ICardPayment
  }
  export interface ISetCardAccountPaymentStatusByPaymentIdRequest {
    cardAccountId?: string
    paymentId?: string
    status?: cards.CardPaymentStatus
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetCardAccountPaymentStatusByPaymentIdResponse {
    payment?: cards.ICardPayment
  }
  export interface IUnlockCardAccountRequest {
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IReplaceCardRequest {
    cardAccountId?: string
    cardId?: string
    replacementReason?: cards.CardReplacementReason
    shippingMethod?: cards.CardShippingMethod
    requestedBy?: common.IEntityDescriptor
  }
  export interface ICalculateInstallmentScheduleRequest {
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface ICalculateInstallmentScheduleResponse {
    frequency?: cards.PaymentFrequency
    payments?: cards.ICardPayment[]
  }
  export interface IActivateInstallmentPaymentsRequest {
    cardAccountId?: string
    groupId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IDeactivateInstallmentPaymentsRequest {
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetCurrentInstallmentPaymentsRequest {
    cardAccountId?: string
  }
  export interface IGetCurrentInstallmentPaymentsResponse {
    installmentsRemaining?: protobuf.IInt32Value
    frequency?: cards.PaymentFrequency
    payments?: cards.ICardPayment[]
  }
  export interface IModifyInstallmentPaymentDateRequest {
    cardAccountId?: string
    paymentId?: string
    newPaymentDate?: protobuf.ITimestamp
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetInstallmentsEligibilityRequest {
    cardAccountId?: string
  }
  export interface IGetInstallmentsEligibilityResponse {
    isEligibleForInstallments?: boolean
  }
  export interface ICardAccountInstallment {
    id?: string
    dueAt?: protobuf.ITimestamp
    amountWithoutFees?: number
    fees?: number
    totalDue?: number
    remainingDue?: number
    pendingAmount?: number
    paidAmount?: number
    paidOff?: true | false
    overdue?: true | false
    status?: cards.CardPaymentStatus
  }
  export interface IGetPayOverTimeDetailsRequest {
    cardAccountId?: string
  }
  export interface IGetPayOverTimeDetailsResponse {
    cardAccountId?: string
    frequency?: cards.PaymentFrequency
    totalPaid?: number
    pendingAmount?: number
    remainingAmount?: number
    installments?: cards.ICardAccountInstallment[]
    totalOverdueAmount?: number
    numberOfOverdueInstallments?: number
  }
  export interface IActivatePhysicalCardRequest {
    cardAccountId?: string
    cardId?: string
    cvv?: string
    ssnMask?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IActivatePhysicalCardResponse {
    cardActivated?: boolean
  }
  export interface IGetPotentialAutoPaymentScheduleRequest {
    cardAccountId?: string
  }
  export interface IGetPotentialAutoPaymentScheduleResponse {
    frequency?: cards.PaymentFrequency
    payments?: cards.IAutomaticPayment[]
  }
  export interface IGetPotentialFeeScheduleRequest {
    cardAccountId?: string
  }
  export interface IGetPotentialFeeScheduleResponse {
    feeSchedule?: cards.IFeeSchedule[]
  }
  export interface ICardBuybackUploadReportRequest {
    filename?: string
  }
  export interface ICardBuybackUploadReportResponse {
    presignedUrl?: string
  }
  export interface IGetPresignedAgreementUrlRequest {
    agreementTypes?: cards.AgreementType[]
  }
  export interface IPresignedAgreement {
    agreementType?: cards.AgreementType
    presignedUrl?: string
  }
  export interface IGetPresignedAgreementUrlResponse {
    presignedAgreements?: cards.IPresignedAgreement[]
  }
  export interface ICardPaymentPeriod {
    startDateTime?: protobuf.ITimestamp
    endDateTime?: protobuf.ITimestamp
    paymentDueAt?: protobuf.ITimestamp
  }
  export interface IGetActiveCardPaymentPeriodsRequest {
    cardAccountId?: string
  }
  export interface IGetActiveCardPaymentPeriodsResponse {
    periods?: cards.ICardPaymentPeriod[]
  }
  export interface IGetPreviousCardPaymentPeriodsRequest {
    cardAccountId?: string
    offset?: protobuf.IInt32Value
    count?: protobuf.IInt32Value
  }
  export interface IGetPreviousCardPaymentPeriodsResponse {
    periods?: cards.ICardPaymentPeriod[]
    offset?: protobuf.IInt32Value
    continues?: boolean
  }
  export interface IGetCurrentCardPaymentPeriodRequest {
    cardAccountId?: string
  }
  export interface IGetCurrentCardPaymentPeriodResponse {
    period?: cards.ICardPaymentPeriod
  }
  export interface IGetAllCardAccountIdsPaginatedRequest {
    pageNumber?: protobuf.IInt32Value
    cardStatus?: cards.CardAccountStatus[]
  }
  export interface IGetAllCardAccountIdsPaginatedResponse {
    cardAccountId?: string
    nextPageNumber?: protobuf.IInt32Value
  }
  export interface IGetOverduePaymentInfoRequest {
    cardAccountId?: string
  }
  export interface IOverdueInfo {
    amount?: protobuf.IDoubleValue
    paymentDueAt?: protobuf.ITimestamp
  }
  export interface IGetOverduePaymentInfoResponse {
    overdueInfo?: cards.IOverdueInfo[]
  }
  export interface IGetOverdueStatementInfoRequest {
    cardAccountId?: string
  }
  export interface IGetOverdueStatementInfoResponse {
    overdueInfo?: cards.IOverdueInfo
  }
  export interface IGetCardAccountAuditEntriesRequest {
    cardAccountId?: string
    entryTypes?: cards.AuditEntryType[]
  }
  export interface IGetCardAccountAuditEntriesResponse {
    cardAccountAuditEntries?: cards.ICardAccountAuditEntry[]
  }
  export interface ICardAccountPayrollDate {
    payrollDate?: protobuf.ITimestamp
    unadjustedPayrollDate?: protobuf.ITimestamp
  }
  export interface IGetPayrollDatesForResetRequest {
    cardAccountId?: string
    assessmentDate?: protobuf.ITimestamp
  }
  export interface IGetPayrollDatesForResetResponse {
    cardAccountId?: string
    frequency?: cards.PaymentFrequency
    payrollDates?: cards.ICardAccountPayrollDate[]
  }
  export interface IResetPayrollDatesRequest {
    cardAccountId?: string
    frequency?: cards.PaymentFrequency
    payrollDates?: cards.ICardAccountPayrollDate[]
    requestedBy?: common.IEntityDescriptor
  }
  export interface IRunCardAccountAuditRequest {
    cardAccountId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IIssueTestCardRequest {
    cardProgramId?: string
    fullName?: string
    mailingAddress?: string
    startingCardNumbers?: string
    limit?: number
    shippingMethodValue?: string
    memberId?: string
    designId?: string
  }
  export interface ICreditOrDebitFundsWithIssuerProcessorRequest {
    cardAccountId?: string
    externalId?: string
    amount?: number
    transactionId?: string
    transactionExecuteAt?: protobuf.ITimestamp
    requestedBy?: common.IEntityDescriptor
    operation?: cards.CreditOrDebitFundsOperation
  }
  export interface ICardAccountRefund {
    id?: string
    cardAccountId?: string
    type?: cards.CardAccountRefundType
    reason?: cards.CardAccountRefundReason
    status?: cards.CardAccountRefundStatus
    notes?: string
    bankingAmount?: number
    issuerProcessorAmount?: number
    bankingInstrumentId?: string
    bankingFundingAuthId?: string
    transactionId?: string
    rejectReason?: string
    createdBy?: common.IEntityDescriptor
    createdAt?: protobuf.ITimestamp
    updatedBy?: common.IEntityDescriptor
    updatedAt?: protobuf.ITimestamp
  }
  export interface IGetCardAccountRefundsRequest {
    cardAccountId?: string
  }
  export interface IGetCardAccountRefundsResponse {
    refunds?: cards.ICardAccountRefund[]
  }
  export interface ISubmitCardAccountRefundRequest {
    cardAccountId?: string
    type?: cards.CardAccountRefundType
    reason?: cards.CardAccountRefundReason
    notes?: string
    bankingAmount?: number
    issuerProcessorAmount?: number
    bankingInstrumentId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IApproveCardAccountRefundRequest {
    refundId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IRejectCardAccountRefundRequest {
    refundId?: string
    rejectReason?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IUpdateCardAccountLimitRequest {
    cardAccountId?: string
    newLimit?: number
    requestedBy?: common.IEntityDescriptor
  }
  export interface IIsPaymentInstrumentInUseRequest {
    paymentInstrumentId?: string
  }
  export interface IIsPaymentInstrumentInUseResponse {
    inUse?: true | false
  }
  export interface IExecuteCardsToolsGenericCommandRequest {
    command?: string
    params?: cards.ExecuteCardsToolsGenericCommandRequest.ParamsEntry
    requestedBy?: common.IEntityDescriptor
  }
  export enum IssuerProcessorId {
    ISSUE_PROCESSOR_ID_UNSPECIFIED = 'ISSUE_PROCESSOR_ID_UNSPECIFIED',
    ISSUE_PROCESSOR_ID_I2C = 'ISSUE_PROCESSOR_ID_I2C',
    ISSUE_PROCESSOR_ID_I2C_EMULATION = 'ISSUE_PROCESSOR_ID_I2C_EMULATION',
  }
  export enum IssuerProcessorStatus {
    ISSUE_PROCESSOR_STATUS_UNSPECIFIED = 'ISSUE_PROCESSOR_STATUS_UNSPECIFIED',
    ISSUE_PROCESSOR_STATUS_ACTIVE = 'ISSUE_PROCESSOR_STATUS_ACTIVE',
    ISSUE_PROCESSOR_STATUS_INACTIVE = 'ISSUE_PROCESSOR_STATUS_INACTIVE',
  }
  export enum CardNetwork {
    CARD_NETWORK_UNSPECIFIED = 'CARD_NETWORK_UNSPECIFIED',
    CARD_NETWORK_VISA = 'CARD_NETWORK_VISA',
    CARD_NETWORK_MASTERCARD = 'CARD_NETWORK_MASTERCARD',
  }
  export enum CardAccountTypeStatus {
    CARD_ACCOUNT_TYPE_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_TYPE_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_TYPE_STATUS_ACTIVE = 'CARD_ACCOUNT_TYPE_STATUS_ACTIVE',
    CARD_ACCOUNT_TYPE_STATUS_INACTIVE = 'CARD_ACCOUNT_TYPE_STATUS_INACTIVE',
  }
  export enum CardAccountStatus {
    CARD_ACCOUNT_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_STATUS_PENDING = 'CARD_ACCOUNT_STATUS_PENDING',
    CARD_ACCOUNT_STATUS_REJECTED = 'CARD_ACCOUNT_STATUS_REJECTED',
    CARD_ACCOUNT_STATUS_APPROVED = 'CARD_ACCOUNT_STATUS_APPROVED',
    CARD_ACCOUNT_STATUS_CANCELLED = 'CARD_ACCOUNT_STATUS_CANCELLED',
    CARD_ACCOUNT_STATUS_EXPIRED = 'CARD_ACCOUNT_STATUS_EXPIRED',
    CARD_ACCOUNT_STATUS_ACTIVE = 'CARD_ACCOUNT_STATUS_ACTIVE',
    CARD_ACCOUNT_STATUS_DEACTIVATED = 'CARD_ACCOUNT_STATUS_DEACTIVATED',
  }
  export enum CardAccountSubStatus {
    CARD_ACCOUNT_SUB_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_SUB_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_SUB_STATUS_PENDING = 'CARD_ACCOUNT_SUB_STATUS_PENDING',
    CARD_ACCOUNT_SUB_STATUS_REJECTED = 'CARD_ACCOUNT_SUB_STATUS_REJECTED',
    CARD_ACCOUNT_SUB_STATUS_APPROVED = 'CARD_ACCOUNT_SUB_STATUS_APPROVED',
    CARD_ACCOUNT_SUB_STATUS_CANCELLED_PENDING = 'CARD_ACCOUNT_SUB_STATUS_CANCELLED_PENDING',
    CARD_ACCOUNT_SUB_STATUS_CANCELLED_APPROVED = 'CARD_ACCOUNT_SUB_STATUS_CANCELLED_APPROVED',
    CARD_ACCOUNT_SUB_STATUS_EXPIRED_PENDING = 'CARD_ACCOUNT_SUB_STATUS_EXPIRED_PENDING',
    CARD_ACCOUNT_SUB_STATUS_EXPIRED_APPROVED = 'CARD_ACCOUNT_SUB_STATUS_EXPIRED_APPROVED',
    CARD_ACCOUNT_SUB_STATUS_ACTIVE = 'CARD_ACCOUNT_SUB_STATUS_ACTIVE',
    CARD_ACCOUNT_SUB_STATUS_ACTIVE_LOCKED = 'CARD_ACCOUNT_SUB_STATUS_ACTIVE_LOCKED',
    CARD_ACCOUNT_SUB_STATUS_ACTIVE_SUSPENDED = 'CARD_ACCOUNT_SUB_STATUS_ACTIVE_SUSPENDED',
    CARD_ACCOUNT_SUB_STATUS_DEACTIVATED = 'CARD_ACCOUNT_SUB_STATUS_DEACTIVATED',
    CARD_ACCOUNT_SUB_STATUS_DEACTIVATED_WITH_BALANCE = 'CARD_ACCOUNT_SUB_STATUS_DEACTIVATED_WITH_BALANCE',
  }
  export enum CardAccountSuspensionStatusReason {
    CARD_ACCOUNT_SUSPENSION_REASON_NOT_APPLICABLE = 'CARD_ACCOUNT_SUSPENSION_REASON_NOT_APPLICABLE',
    CARD_ACCOUNT_SUSPENSION_REASON_PAY_LATER_AGREEMENT = 'CARD_ACCOUNT_SUSPENSION_REASON_PAY_LATER_AGREEMENT',
    CARD_ACCOUNT_SUSPENSION_REASON_PAYMENT_DEFAULTS = 'CARD_ACCOUNT_SUSPENSION_REASON_PAYMENT_DEFAULTS',
    CARD_ACCOUNT_SUSPENSION_REASON_FRAUD = 'CARD_ACCOUNT_SUSPENSION_REASON_FRAUD',
    CARD_ACCOUNT_SUSPENSION_REASON_NSF = 'CARD_ACCOUNT_SUSPENSION_REASON_NSF',
  }
  export enum CardAccountDeactivationStatusReason {
    CARD_ACCOUNT_DEACTIVATION_REASON_UNSPECIFIED = 'CARD_ACCOUNT_DEACTIVATION_REASON_UNSPECIFIED',
    CARD_ACCOUNT_DEACTIVATION_REASON_NOT_APPLICABLE = 'CARD_ACCOUNT_DEACTIVATION_REASON_NOT_APPLICABLE',
    CARD_ACCOUNT_DEACTIVATION_REASON_LOST = 'CARD_ACCOUNT_DEACTIVATION_REASON_LOST',
    CARD_ACCOUNT_DEACTIVATION_REASON_STOLEN = 'CARD_ACCOUNT_DEACTIVATION_REASON_STOLEN',
    CARD_ACCOUNT_DEACTIVATION_REASON_REPLACED = 'CARD_ACCOUNT_DEACTIVATION_REASON_REPLACED',
    CARD_ACCOUNT_DEACTIVATION_REASON_CHARGED_OFF = 'CARD_ACCOUNT_DEACTIVATION_REASON_CHARGED_OFF',
    CARD_ACCOUNT_DEACTIVATION_REASON_FRAUD = 'CARD_ACCOUNT_DEACTIVATION_REASON_FRAUD',
    CARD_ACCOUNT_DEACTIVATION_REASON_BANKRUPT = 'CARD_ACCOUNT_DEACTIVATION_REASON_BANKRUPT',
    CARD_ACCOUNT_DEACTIVATION_REASON_DECEASED = 'CARD_ACCOUNT_DEACTIVATION_REASON_DECEASED',
    CARD_ACCOUNT_DEACTIVATION_REASON_DEBT_SOLD = 'CARD_ACCOUNT_DEACTIVATION_REASON_DEBT_SOLD',
  }
  export enum ApplicationPrerequisitesClass {
    APPLICATION_PREREQUISITE_CLASS_UNSPECIFIED = 'APPLICATION_PREREQUISITE_CLASS_UNSPECIFIED',
    APPLICATION_PREREQUISITE_CLASS_OPTIONAL = 'APPLICATION_PREREQUISITE_CLASS_OPTIONAL',
    APPLICATION_PREREQUISITE_CLASS_REQUIRED = 'APPLICATION_PREREQUISITE_CLASS_REQUIRED',
  }
  export enum ApplicationPrerequisitesStatus {
    APPLICATION_PREREQUISITE_STATUS_UNSPECIFIED = 'APPLICATION_PREREQUISITE_STATUS_UNSPECIFIED',
    APPLICATION_PREREQUISITE_STATUS_MISSING = 'APPLICATION_PREREQUISITE_STATUS_MISSING',
    APPLICATION_PREREQUISITE_STATUS_SUBMITTED = 'APPLICATION_PREREQUISITE_STATUS_SUBMITTED',
    APPLICATION_PREREQUISITE_STATUS_SUBMITTED_IN_REVIEW = 'APPLICATION_PREREQUISITE_STATUS_SUBMITTED_IN_REVIEW',
    APPLICATION_PREREQUISITE_STATUS_INVALID = 'APPLICATION_PREREQUISITE_STATUS_INVALID',
    APPLICATION_PREREQUISITE_STATUS_VALID = 'APPLICATION_PREREQUISITE_STATUS_VALID',
  }
  export enum ApplicationPrerequisiteName {
    APPLICATION_PREREQUISITE_NAME_BANK_DATA_UNSPECIFIED = 'APPLICATION_PREREQUISITE_NAME_BANK_DATA_UNSPECIFIED',
    APPLICATION_PREREQUISITE_NAME_BANK_DATA_LINKS = 'APPLICATION_PREREQUISITE_NAME_BANK_DATA_LINKS',
    APPLICATION_PREREQUISITE_NAME_BANK_DATA_LINKS_SHOWING_PAYROLL = 'APPLICATION_PREREQUISITE_NAME_BANK_DATA_LINKS_SHOWING_PAYROLL',
    APPLICATION_PREREQUISITE_NAME_BASIC_PII_FULL_NAME = 'APPLICATION_PREREQUISITE_NAME_BASIC_PII_FULL_NAME',
    APPLICATION_PREREQUISITE_NAME_BASIC_PII_PRIMARY_RESIDENCE_ADDRESS = 'APPLICATION_PREREQUISITE_NAME_BASIC_PII_PRIMARY_RESIDENCE_ADDRESS',
    APPLICATION_PREREQUISITE_NAME_BASIC_PII_MAILING_ADDRESS = 'APPLICATION_PREREQUISITE_NAME_BASIC_PII_MAILING_ADDRESS',
    APPLICATION_PREREQUISITE_NAME_BASIC_PII_SSN = 'APPLICATION_PREREQUISITE_NAME_BASIC_PII_SSN',
    APPLICATION_PREREQUISITE_NAME_BASIC_PII_DOB = 'APPLICATION_PREREQUISITE_NAME_BASIC_PII_DOB',
  }
  export enum CardOfferAvailabilityStatus {
    CARD_OFFER_AVAILABILITY_STATUS_OFFER_UNSPECIFIED = 'CARD_OFFER_AVAILABILITY_STATUS_OFFER_UNSPECIFIED',
    CARD_OFFER_AVAILABILITY_STATUS_OFFER_AVAILABLE = 'CARD_OFFER_AVAILABILITY_STATUS_OFFER_AVAILABLE',
    CARD_OFFER_AVAILABILITY_STATUS_USER_NOT_ELIGIBLE = 'CARD_OFFER_AVAILABILITY_STATUS_USER_NOT_ELIGIBLE',
    CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_CARDS = 'CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_CARDS',
    CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOANS = 'CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOANS',
    CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOCATION = 'CARD_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOCATION',
    CARD_OFFER_AVAILABILITY_STATUS_NO_OFFER_AVAILABLE = 'CARD_OFFER_AVAILABILITY_STATUS_NO_OFFER_AVAILABLE',
  }
  export enum CardAccountOverDueStatus {
    CARD_ACCOUNT_OVERDUE_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_OVERDUE_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_OVERDUE_STATUS_NONE = 'CARD_ACCOUNT_OVERDUE_STATUS_NONE',
    CARD_ACCOUNT_OVERDUE_STATUS_OVERDUE = 'CARD_ACCOUNT_OVERDUE_STATUS_OVERDUE',
    CARD_ACCOUNT_OVERDUE_STATUS_DELINQUENT = 'CARD_ACCOUNT_OVERDUE_STATUS_DELINQUENT',
    CARD_ACCOUNT_OVERDUE_STATUS_DELINQUENT_THIRTY_DAYS_OR_GREATER = 'CARD_ACCOUNT_OVERDUE_STATUS_DELINQUENT_THIRTY_DAYS_OR_GREATER',
  }
  export enum CardType {
    CARD_TYPE_PHYSICAL_CARD = 'CARD_TYPE_PHYSICAL_CARD',
    CARD_TYPE_VIRTUAL_CARD = 'CARD_TYPE_VIRTUAL_CARD',
  }
  export enum CardStatus {
    CARD_STATUS_PENDING = 'CARD_STATUS_PENDING',
    CARD_STATUS_ACTIVE = 'CARD_STATUS_ACTIVE',
    CARD_STATUS_SUSPENDED = 'CARD_STATUS_SUSPENDED',
    CARD_STATUS_DEACTIVATED = 'CARD_STATUS_DEACTIVATED',
  }
  export enum CohortPhase {
    COHORT_PHASE_UNSPECIFIED = 'COHORT_PHASE_UNSPECIFIED',
    COHORT_PHASE_NONE = 'COHORT_PHASE_NONE',
    COHORT_PHASE_PHASE1 = 'COHORT_PHASE_PHASE1',
    COHORT_PHASE_PHASE2 = 'COHORT_PHASE_PHASE2',
    COHORT_PHASE_PHASE3 = 'COHORT_PHASE_PHASE3',
    COHORT_PHASE_CRUT = 'COHORT_PHASE_CRUT',
    COHORT_PHASE_INTERNAL = 'COHORT_PHASE_INTERNAL',
  }
  export enum CohortUserType {
    COHORT_USER_TYPE_UNSPECIFIED = 'COHORT_USER_TYPE_UNSPECIFIED',
    COHORT_USER_TYPE_NONE = 'COHORT_USER_TYPE_NONE',
    COHORT_USER_TYPE_LOAN_GRAD = 'COHORT_USER_TYPE_LOAN_GRAD',
    COHORT_USER_TYPE_LOAN_STATE = 'COHORT_USER_TYPE_LOAN_STATE',
    COHORT_USER_TYPE_NON_LOAN_STATE = 'COHORT_USER_TYPE_NON_LOAN_STATE',
  }
  export enum CardBalanceType {
    CARD_BALANCE_TYPE_UNSPECIFIED = 'CARD_BALANCE_TYPE_UNSPECIFIED',
    CARD_BALANCE_TYPE_LEDGER = 'CARD_BALANCE_TYPE_LEDGER',
    CARD_BALANCE_TYPE_AVAILABLE = 'CARD_BALANCE_TYPE_AVAILABLE',
    CARD_BALANCE_TYPE_MINIMUM_DUE = 'CARD_BALANCE_TYPE_MINIMUM_DUE',
  }
  export enum ScheduleRecurrence {
    SCHEDULE_RECURRENCE_DISABLED = 'SCHEDULE_RECURRENCE_DISABLED',
    SCHEDULE_RECURRENCE_DYNAMIC = 'SCHEDULE_RECURRENCE_DYNAMIC',
  }
  export enum CardPaymentStatus {
    CARD_PAYMENT_STATUS_UNSPECIFIED = 'CARD_PAYMENT_STATUS_UNSPECIFIED',
    CARD_PAYMENT_STATUS_STAGED = 'CARD_PAYMENT_STATUS_STAGED',
    CARD_PAYMENT_STATUS_PENDING = 'CARD_PAYMENT_STATUS_PENDING',
    CARD_PAYMENT_STATUS_IN_PROGRESS = 'CARD_PAYMENT_STATUS_IN_PROGRESS',
    CARD_PAYMENT_STATUS_COMPLETED = 'CARD_PAYMENT_STATUS_COMPLETED',
    CARD_PAYMENT_STATUS_CANCELLED = 'CARD_PAYMENT_STATUS_CANCELLED',
    CARD_PAYMENT_STATUS_FAILED = 'CARD_PAYMENT_STATUS_FAILED',
  }
  export enum CardPaymentType {
    CARD_PAYMENT_TYPE_UNSPECIFIED = 'CARD_PAYMENT_TYPE_UNSPECIFIED',
    CARD_PAYMENT_TYPE_AUTO_PAY = 'CARD_PAYMENT_TYPE_AUTO_PAY',
    CARD_PAYMENT_TYPE_CUSTOM = 'CARD_PAYMENT_TYPE_CUSTOM',
    CARD_PAYMENT_TYPE_PAY_OVER_TIME = 'CARD_PAYMENT_TYPE_PAY_OVER_TIME',
  }
  export enum ConsumerInquiryProviderId {
    CONSUMER_INQUIRY_PROVIDER_ID_UNSPECIFIED = 'CONSUMER_INQUIRY_PROVIDER_ID_UNSPECIFIED',
    CONSUMER_INQUIRY_PROVIDER_ID_CLARITY = 'CONSUMER_INQUIRY_PROVIDER_ID_CLARITY',
    CONSUMER_INQUIRY_PROVIDER_ID_FACTORTRUST = 'CONSUMER_INQUIRY_PROVIDER_ID_FACTORTRUST',
    CONSUMER_INQUIRY_PROVIDER_ID_LEXISNEXIS = 'CONSUMER_INQUIRY_PROVIDER_ID_LEXISNEXIS',
    CONSUMER_INQUIRY_PROVIDER_ID_GIACT = 'CONSUMER_INQUIRY_PROVIDER_ID_GIACT',
    CONSUMER_INQUIRY_PROVIDER_ID_SENTILINK = 'CONSUMER_INQUIRY_PROVIDER_ID_SENTILINK',
    CONSUMER_INQUIRY_PROVIDER_ID_CREDEGRAPH = 'CONSUMER_INQUIRY_PROVIDER_ID_CREDEGRAPH',
    CONSUMER_INQUIRY_PROVIDER_ID_UNRECOGNIZED = 'CONSUMER_INQUIRY_PROVIDER_ID_UNRECOGNIZED',
  }
  export enum AuditEntryType {
    AUDIT_ENTRY_TYPE_UNSPECIFIED = 'AUDIT_ENTRY_TYPE_UNSPECIFIED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_DEACTIVATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_DEACTIVATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_DEACTIVATED_WITH_BALANCE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_DEACTIVATED_WITH_BALANCE',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REACTIVATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REACTIVATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REJECTED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REJECTED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_ACCEPTED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_ACCEPTED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_REJECTED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_REJECTED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING_CANCELLED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING_CANCELLED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_EXPIRED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_APPROVED_EXPIRED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING_EXPIRED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PENDING_EXPIRED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_SUSPENDED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_SUSPENDED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_UNSUSPENDED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_UNSUSPENDED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_PAY_LATER_COMPLETE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_ACTIVE_PAY_LATER_COMPLETE',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BILL_PAYMENT_METHOD_CREATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BILL_PAYMENT_METHOD_CREATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BILL_PAYMENT_METHOD_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BILL_PAYMENT_METHOD_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BORROWING_BASE_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_BORROWING_BASE_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_STAGED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_STAGED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_DATE_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_DATE_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_EXECUTION_DATE_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_EXECUTION_DATE_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_INIT = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_INIT',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_STATUS_CHANGE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_STATUS_CHANGE',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_UPCOMING_NOTIFY = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_UPCOMING_NOTIFY',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_AMOUNT_CHANGE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAYMENT_AMOUNT_CHANGE',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PII_VERIFY = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PII_VERIFY',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_MLA_OFAC_VERIFY = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_MLA_OFAC_VERIFY',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_STATEMENT_GENERATED_DOWNLOAD_URL = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_STATEMENT_GENERATED_DOWNLOAD_URL',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAN_DECODE_REQUEST = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_PAN_DECODE_REQUEST',
    AUDIT_ENTRY_TYPE_CARD_BILL_AUTOPAY_ENABLED = 'AUDIT_ENTRY_TYPE_CARD_BILL_AUTOPAY_ENABLED',
    AUDIT_ENTRY_TYPE_CARD_BILL_AUTOPAY_DISABLED = 'AUDIT_ENTRY_TYPE_CARD_BILL_AUTOPAY_DISABLED',
    AUDIT_ENTRY_TYPE_CARD_BILL_INSTALLMENTS_ENABLED = 'AUDIT_ENTRY_TYPE_CARD_BILL_INSTALLMENTS_ENABLED',
    AUDIT_ENTRY_TYPE_CARD_BILL_INSTALLMENTS_DISABLED = 'AUDIT_ENTRY_TYPE_CARD_BILL_INSTALLMENTS_DISABLED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_LEDGER_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_LEDGER_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_CREDIT_LIMIT_ADJUSTED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_CREDIT_LIMIT_ADJUSTED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REJECTED_COOLOFF_PERIOD_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_REJECTED_COOLOFF_PERIOD_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_CREDIT_REPORTING_ID_BACKFILL = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_CREDIT_REPORTING_ID_BACKFILL',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_OVERDUE_STATUS_CHANGE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_OVERDUE_STATUS_CHANGE',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_OVERDUE_AMOUNT_CHANGE = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_OVERDUE_AMOUNT_CHANGE',
    AUDIT_ENTRY_TYPE_NOTE = 'AUDIT_ENTRY_TYPE_NOTE',
    AUDIT_ENTRY_TYPE_UNKNOWN = 'AUDIT_ENTRY_TYPE_UNKNOWN',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_COHORT_DATA_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_COHORT_DATA_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_ACCOUNT_EXPIRED_COOLOFF_PERIOD_UPDATED = 'AUDIT_ENTRY_TYPE_CARD_ACCOUNT_EXPIRED_COOLOFF_PERIOD_UPDATED',
    AUDIT_ENTRY_TYPE_CARD_RESET_PAYMENT_SCHEDULE = 'AUDIT_ENTRY_TYPE_CARD_RESET_PAYMENT_SCHEDULE',
  }
  export enum CardAccountAgreementType {
    CARD_ACCOUNT_AGREEMENT_TYPE_UNSPECIFIED = 'CARD_ACCOUNT_AGREEMENT_TYPE_UNSPECIFIED',
    CARD_ACCOUNT_AGREEMENT_TYPE_TERMS_AND_CONDITIONS = 'CARD_ACCOUNT_AGREEMENT_TYPE_TERMS_AND_CONDITIONS',
    CARD_ACCOUNT_AGREEMENT_TYPE_ELECTRONIC_REPAYMENTS = 'CARD_ACCOUNT_AGREEMENT_TYPE_ELECTRONIC_REPAYMENTS',
    CARD_ACCOUNT_AGREEMENT_TYPE_INSTALLMENT_AGREEMENT = 'CARD_ACCOUNT_AGREEMENT_TYPE_INSTALLMENT_AGREEMENT',
    CARD_ACCOUNT_AGREEMENT_TYPE_DEBIT_AUTHORIZATION_AGREEMENT = 'CARD_ACCOUNT_AGREEMENT_TYPE_DEBIT_AUTHORIZATION_AGREEMENT',
    CARD_ACCOUNT_AGREEMENT_TYPE_BETA_TESTING_AGREEMENT = 'CARD_ACCOUNT_AGREEMENT_TYPE_BETA_TESTING_AGREEMENT',
  }
  export enum CardAccountAgreementStatus {
    CARD_ACCOUNT_AGREEMENT_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_AGREEMENT_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_AGREEMENT_STATUS_PENDING = 'CARD_ACCOUNT_AGREEMENT_STATUS_PENDING',
    CARD_ACCOUNT_AGREEMENT_STATUS_AGREED = 'CARD_ACCOUNT_AGREEMENT_STATUS_AGREED',
    CARD_ACCOUNT_AGREEMENT_STATUS_REJECTED = 'CARD_ACCOUNT_AGREEMENT_STATUS_REJECTED',
  }
  export enum CardAccountAgreementAction {
    CARD_ACCOUNT_AGREEMENT_ACTION_UNSPECIFIED = 'CARD_ACCOUNT_AGREEMENT_ACTION_UNSPECIFIED',
    CARD_ACCOUNT_AGREEMENT_ACTION_AGREED = 'CARD_ACCOUNT_AGREEMENT_ACTION_AGREED',
    CARD_ACCOUNT_AGREEMENT_ACTION_REJECTED = 'CARD_ACCOUNT_AGREEMENT_ACTION_REJECTED',
  }
  export enum ManualReviewReasonCode {
    MANUAL_REVIEW_REASON_CODE_OTHER = 'MANUAL_REVIEW_REASON_CODE_OTHER',
    MANUAL_REVIEW_REASON_CODE_ENABLER_UNDERWRITING_EXCEPTION = 'MANUAL_REVIEW_REASON_CODE_ENABLER_UNDERWRITING_EXCEPTION',
    MANUAL_REVIEW_REASON_CODE_FRAUD_CHECKS_EXCEPTION = 'MANUAL_REVIEW_REASON_CODE_FRAUD_CHECKS_EXCEPTION',
    MANUAL_REVIEW_REASON_CODE_KYC_CHECKS_EXCEPTION = 'MANUAL_REVIEW_REASON_CODE_KYC_CHECKS_EXCEPTION',
    MANUAL_REVIEW_REASON_CODE_BANKING_DATA_PREP_EXCEPTION = 'MANUAL_REVIEW_REASON_CODE_BANKING_DATA_PREP_EXCEPTION',
  }
  export enum ApprovalManualReviewAction {
    APPROVAL_MANUAL_REVIEW_ACTION_UNSPECIFIED = 'APPROVAL_MANUAL_REVIEW_ACTION_UNSPECIFIED',
    APPROVAL_MANUAL_REVIEW_ACTION_SNOOZE = 'APPROVAL_MANUAL_REVIEW_ACTION_SNOOZE',
    APPROVAL_MANUAL_REVIEW_ACTION_URA = 'APPROVAL_MANUAL_REVIEW_ACTION_URA',
    APPROVAL_MANUAL_REVIEW_ACTION_RESUME = 'APPROVAL_MANUAL_REVIEW_ACTION_RESUME',
    APPROVAL_MANUAL_REVIEW_ACTION_REJECT = 'APPROVAL_MANUAL_REVIEW_ACTION_REJECT',
    APPROVAL_MANUAL_REVIEW_ACTION_APPROVE = 'APPROVAL_MANUAL_REVIEW_ACTION_APPROVE',
    APPROVAL_MANUAL_REVIEW_ACTION_EXPIRE = 'APPROVAL_MANUAL_REVIEW_ACTION_EXPIRE',
    APPROVAL_MANUAL_REVIEW_ACTION_CANCEL = 'APPROVAL_MANUAL_REVIEW_ACTION_CANCEL',
  }
  export enum MerchantDeviceType {
    MERCHANT_DEVICE_TYPE_API = 'MERCHANT_DEVICE_TYPE_API',
    MERCHANT_DEVICE_TYPE_ATM = 'MERCHANT_DEVICE_TYPE_ATM',
    MERCHANT_DEVICE_TYPE_IRV = 'MERCHANT_DEVICE_TYPE_IRV',
    MERCHANT_DEVICE_TYPE_POS = 'MERCHANT_DEVICE_TYPE_POS',
    MERCHANT_DEVICE_TYPE_WEB = 'MERCHANT_DEVICE_TYPE_WEB',
  }
  export enum TransactionDisputeType {
    TRANSACTION_DISPUTE_TYPE_PAYMENT = 'TRANSACTION_DISPUTE_TYPE_PAYMENT',
    TRANSACTION_DISPUTE_TYPE_TRANSACTION = 'TRANSACTION_DISPUTE_TYPE_TRANSACTION',
  }
  export enum TransactionDisputeStatus {
    TRANSACTION_DISPUTE_STATUS_IN_PROGRESS = 'TRANSACTION_DISPUTE_STATUS_IN_PROGRESS',
    TRANSACTION_DISPUTE_STATUS_MERCHANT_WIN = 'TRANSACTION_DISPUTE_STATUS_MERCHANT_WIN',
    TRANSACTION_DISPUTE_STATUS_CARD_HOLDER_WIN = 'TRANSACTION_DISPUTE_STATUS_CARD_HOLDER_WIN',
    TRANSACTION_DISPUTE_STATUS_APPROVED = 'TRANSACTION_DISPUTE_STATUS_APPROVED',
    TRANSACTION_DISPUTE_STATUS_REJECTED = 'TRANSACTION_DISPUTE_STATUS_REJECTED',
  }
  export enum CardAccountDelinquencyCategory {
    CARD_ACCOUNT_DELINQUENCY_CATEGORY_NOT_APPLICABLE = 'CARD_ACCOUNT_DELINQUENCY_CATEGORY_NOT_APPLICABLE',
    CARD_ACCOUNT_DELINQUENCY_CATEGORY_STATEMENT_DUE_DATE = 'CARD_ACCOUNT_DELINQUENCY_CATEGORY_STATEMENT_DUE_DATE',
    CARD_ACCOUNT_DELINQUENCY_CATEGORY_PAY_LATER_AGREEMENT = 'CARD_ACCOUNT_DELINQUENCY_CATEGORY_PAY_LATER_AGREEMENT',
  }
  export enum CardReplacementReason {
    CARD_REPLACEMENT_REASON_DAMAGED = 'CARD_REPLACEMENT_REASON_DAMAGED',
    CARD_REPLACEMENT_REASON_EXPIRED = 'CARD_REPLACEMENT_REASON_EXPIRED',
    CARD_REPLACEMENT_REASON_LOST_OR_STOLEN = 'CARD_REPLACEMENT_REASON_LOST_OR_STOLEN',
    CARD_REPLACEMENT_REASON_FRAUD_OR_COMPROMISED = 'CARD_REPLACEMENT_REASON_FRAUD_OR_COMPROMISED',
    CARD_REPLACEMENT_REASON_RETURN = 'CARD_REPLACEMENT_REASON_RETURN',
    CARD_REPLACEMENT_REASON_NEW_ENROLLMENT = 'CARD_REPLACEMENT_REASON_NEW_ENROLLMENT',
  }
  export enum CardShippingMethod {
    CARD_SHIPPING_METHOD_NORMAL_SHIPMENT_6_DAYS = 'CARD_SHIPPING_METHOD_NORMAL_SHIPMENT_6_DAYS',
    CARD_SHIPPING_METHOD_URGENT_SHIPMENT_3_DAYS = 'CARD_SHIPPING_METHOD_URGENT_SHIPMENT_3_DAYS',
    CARD_SHIPPING_METHOD_VIP_SHIPMENT_24_HRS = 'CARD_SHIPPING_METHOD_VIP_SHIPMENT_24_HRS',
    CARD_SHIPPING_METHOD_BULK_SHIPMENT = 'CARD_SHIPPING_METHOD_BULK_SHIPMENT',
    CARD_SHIPPING_METHOD_BULK_SHIPMENT_URGENT = 'CARD_SHIPPING_METHOD_BULK_SHIPMENT_URGENT',
    CARD_SHIPPING_METHOD_BULK_SHIPMENT_VIP = 'CARD_SHIPPING_METHOD_BULK_SHIPMENT_VIP',
  }
  export enum PaymentFrequency {
    PAYMENT_FREQUENCY_UNSPECIFIED = 'PAYMENT_FREQUENCY_UNSPECIFIED',
    PAYMENT_FREQUENCY_WEEKLY = 'PAYMENT_FREQUENCY_WEEKLY',
    PAYMENT_FREQUENCY_BIWEEKLY = 'PAYMENT_FREQUENCY_BIWEEKLY',
    PAYMENT_FREQUENCY_MONTHLY = 'PAYMENT_FREQUENCY_MONTHLY',
    PAYMENT_FREQUENCY_UNKNOWN = 'PAYMENT_FREQUENCY_UNKNOWN',
  }
  export enum AgreementType {
    AGREEMENT_TYPE_TERMS_OF_SERVICE = 'AGREEMENT_TYPE_TERMS_OF_SERVICE',
    AGREEMENT_TYPE_CARD_AGREEMENT = 'AGREEMENT_TYPE_CARD_AGREEMENT',
    AGREEMENT_TYPE_BETA_TESTING_AGREEMENT = 'AGREEMENT_TYPE_BETA_TESTING_AGREEMENT',
  }
  export enum CardPaymentDateStatus {
    CARD_PAYMENT_DATE_STATUS_ACTIVE = 'CARD_PAYMENT_DATE_STATUS_ACTIVE',
    CARD_PAYMENT_DATE_STATUS_PENDING = 'CARD_PAYMENT_DATE_STATUS_PENDING',
    CARD_PAYMENT_DATE_STATUS_COMPLETED = 'CARD_PAYMENT_DATE_STATUS_COMPLETED',
    CARD_PAYMENT_DATE_STATUS_CANCELLED = 'CARD_PAYMENT_DATE_STATUS_CANCELLED',
  }
  export enum CreditOrDebitFundsOperation {
    CREDIT_OR_DEBIT_FUNDS_OPERATION_UNSPECIFIED = 'CREDIT_OR_DEBIT_FUNDS_OPERATION_UNSPECIFIED',
    CREDIT_OR_DEBIT_FUNDS_OPERATION_CREDIT = 'CREDIT_OR_DEBIT_FUNDS_OPERATION_CREDIT',
    CREDIT_OR_DEBIT_FUNDS_OPERATION_DEBIT = 'CREDIT_OR_DEBIT_FUNDS_OPERATION_DEBIT',
  }
  export enum CardAccountRefundType {
    CARD_ACCOUNT_REFUND_TYPE_UNSPECIFIED = 'CARD_ACCOUNT_REFUND_TYPE_UNSPECIFIED',
    CARD_ACCOUNT_REFUND_TYPE_BANKING_ONLY = 'CARD_ACCOUNT_REFUND_TYPE_BANKING_ONLY',
    CARD_ACCOUNT_REFUND_TYPE_ISSUER_PROCESSOR_ONLY = 'CARD_ACCOUNT_REFUND_TYPE_ISSUER_PROCESSOR_ONLY',
    CARD_ACCOUNT_REFUND_TYPE_BANKING_AND_ISSUER_PROCESSOR = 'CARD_ACCOUNT_REFUND_TYPE_BANKING_AND_ISSUER_PROCESSOR',
  }
  export enum CardAccountRefundReason {
    CARD_ACCOUNT_REFUND_REASON_UNSPECIFIED = 'CARD_ACCOUNT_REFUND_REASON_UNSPECIFIED',
    CARD_ACCOUNT_REFUND_REASON_MONTHLY_FEE = 'CARD_ACCOUNT_REFUND_REASON_MONTHLY_FEE',
    CARD_ACCOUNT_REFUND_REASON_PARTIAL_PAYMENT = 'CARD_ACCOUNT_REFUND_REASON_PARTIAL_PAYMENT',
    CARD_ACCOUNT_REFUND_REASON_FULL_PAYMENT = 'CARD_ACCOUNT_REFUND_REASON_FULL_PAYMENT',
    CARD_ACCOUNT_REFUND_REASON_OVERDRAFT_FEE = 'CARD_ACCOUNT_REFUND_REASON_OVERDRAFT_FEE',
  }
  export enum CardAccountRefundStatus {
    CARD_ACCOUNT_REFUND_STATUS_UNSPECIFIED = 'CARD_ACCOUNT_REFUND_STATUS_UNSPECIFIED',
    CARD_ACCOUNT_REFUND_STATUS_PENDING = 'CARD_ACCOUNT_REFUND_STATUS_PENDING',
    CARD_ACCOUNT_REFUND_STATUS_APPROVED = 'CARD_ACCOUNT_REFUND_STATUS_APPROVED',
    CARD_ACCOUNT_REFUND_STATUS_REJECTED = 'CARD_ACCOUNT_REFUND_STATUS_REJECTED',
  }
}
export namespace lag {
  export namespace LoanTerms {
    export namespace Payments {
      export interface IByEntity {
        enabled?: true | false
        maxDays?: number
      }
    }
    export interface IPayments {
      reschedulingByUser?: lag.LoanTerms.Payments.IByEntity
      reschedulingByCustomerService?: lag.LoanTerms.Payments.IByEntity
    }
    export interface IConversionOption {
      variant?: lag.ILoanTerms
      offerId?: string
      projectedPayments?: lag.ILoanPaymentRecord[]
      paymentsToRefund?: lag.ILoanPaymentRecord[]
    }
    export interface ICreditReporting {
      enabled?: true | false
    }
    export interface IConstraints {
      payments?: lag.LoanTerms.IPayments
      conversions?: lag.LoanTerms.IConversionOption[]
      creditReporting?: lag.LoanTerms.ICreditReporting
      earliestFirstPaymentDays?: protobuf.IInt32Value
      latestFirstPaymentDays?: protobuf.IInt32Value
      earliestLastPaymentDays?: protobuf.IInt32Value
      latestLastPaymentDays?: protobuf.IInt32Value
      paymentFrequencyEqualSubstantially?: boolean
      totalNumberOfPayments?: protobuf.IInt32Value
    }
    export interface IComplexRate {
      amountMin?: number
      amountMax?: number
      rate?: number
    }
  }
  export interface IPayment {
    Amount?: number
    date?: string
    ordinal?: number
  }
  export interface IPaymentDateChange {
    id?: string
    ordinal?: number
    date?: string
  }
  export interface ILoanTerms {
    rate?: number
    step?: number
    title?: string
    defaultAmount?: number
    maximumAmount?: number
    minimumAmount?: number
    loanDurationWeeks?: number
    collectionWeekInterval?: number
    estimatedAPR?: number
    lenderApr?: number
    complexRate?: lag.LoanTerms.IComplexRate[]
    stateName?: string
    maximumAmountRepeatLoan?: number
    variantId?: string
    constraints?: lag.LoanTerms.IConstraints
  }
  export interface ILoanAgreementTerms {
    Payments?: lag.IPayment[]
    OriginationDate?: string
    LoanAmount?: number
    FullName?: string
    FullAddress?: string
    Telephone?: string
    Email?: string
    LoanNum?: string
    VeritecTransNum?: string
    APR?: string
    TotalFee?: number
    TotalOwed?: number
    DaysOfLoan?: number
    LoanTerminationDate?: string
  }
  export interface ILoanPaymentRecord {
    id?: string
    uid?: string
    loanId?: string
    status?: string
    currency?: string
    amount?: number
    rescheduledDate?: string
    fundingId?: string
    method?: string
    ordinal?: number
    originalDate?: string
    payingOffNow?: true | false
    userNoticeCleared?: true | false
    userNotice?: string
    userNoticeDate?: string
    failureCount?: number
    retriesExhausted?: true | false
    fees?: number
    interest?: number
    principal?: number
    executeEffectiveDatetime?: string
    paidoffDatetime?: string
  }
  export interface IChangePaymentDatesResponse {
    error?: common.IServiceError
    LoanAgreementTerms?: lag.ILoanAgreementTerms
    paymentRecords?: lag.ILoanPaymentRecord[]
    amortizationJson?: string
  }
}
export namespace partners {
  export interface IRequestOfferRequest {
    authorization?: common.IAuthorization
    partnerId?: partners.PartnerId
    leadTrackingId?: string
    email?: string
    phone?: string
    address?: string
    city?: string
    state?: string
    zipCode?: string
    firstName?: string
    lastName?: string
    birthDate?: protobuf.ITimestamp
    ssnEncrypted?: string
    selfCreditRating?: partners.SelfCreditRating
    annualIncome?: number
  }
  export interface IRequestOfferResponse {
    partnerId?: partners.PartnerId
    leadTrackingId?: string
    offers?: partners.IOffer[]
    approved?: true | false
  }
  export interface IOffer {
    id?: string
    ordinal?: number
    approvedAmount?: number
    interestRate?: number
    apr?: number
    annualFee?: number
    originationFee?: number
    monthlyPayment?: number
    title?: string
    url?: string
    months?: number
    status?: partners.OfferStatus
  }
  export interface ISelectOfferRequest {
    authorization?: common.IAuthorization
    offerId?: string
  }
  export interface ISelectOfferResponse {
    partnerId?: partners.PartnerId
    offer?: partners.IOffer
  }
  export interface ILinkUserWithOfferRequest {
    authorization?: common.IAuthorization
    offerId?: string
    userId?: string
  }
  export interface IGetCurrentOfferRequest {
    authorization?: common.IAuthorization
  }
  export interface IGetCurrentOfferResponse {
    partnerId?: partners.PartnerId
    offer?: partners.IOffer
  }
  export interface IStoreProductAttributionRequest {
    authorization?: common.IAuthorization
    offerId?: string
    entityId?: string
  }
  export enum PartnerId {
    PARTNER_ID_UNSPECIFIED = 'PARTNER_ID_UNSPECIFIED',
    PARTNER_ID_LENDING_TREE = 'PARTNER_ID_LENDING_TREE',
  }
  export enum OfferStatus {
    OFFER_STATUS_UNSPECIFIED = 'OFFER_STATUS_UNSPECIFIED',
    OFFER_STATUS_PENDING = 'OFFER_STATUS_PENDING',
    OFFER_STATUS_SELECTED = 'OFFER_STATUS_SELECTED',
    OFFER_STATUS_ACCOUNT_CREATION = 'OFFER_STATUS_ACCOUNT_CREATION',
    OFFER_STATUS_PRODUCT_REQUEST = 'OFFER_STATUS_PRODUCT_REQUEST',
  }
  export enum SelfCreditRating {
    SELF_CREDIT_RATING_UNSPECIFIED = 'SELF_CREDIT_RATING_UNSPECIFIED',
    SELF_CREDIT_RATING_EXCELLENT = 'SELF_CREDIT_RATING_EXCELLENT',
    SELF_CREDIT_RATING_GOOD = 'SELF_CREDIT_RATING_GOOD',
    SELF_CREDIT_RATING_FAIR = 'SELF_CREDIT_RATING_FAIR',
    SELF_CREDIT_RATING_POOR = 'SELF_CREDIT_RATING_POOR',
    SELF_CREDIT_RATING_UNAVAILABLE = 'SELF_CREDIT_RATING_UNAVAILABLE',
  }
}
export namespace users {
  export namespace UserEvent {
    export type DataEntry = {[key: string]: string}
  }
  export namespace UserEnvEx {
    export interface IFinInstAggregator {
      aggregatorId?: string[]
    }
    export interface IFinInstProcessor {
      processorId?: string[]
      enableInterchange?: true | false
      enableInterchange2?: boolean
    }
    export interface IMlaOfacProvider {
      providerId?: string[]
    }
    export interface IFraudFilters {
      level?: number
      level2?: protobuf.IInt32Value
    }
  }
  export namespace UserNote {
    export interface IVersion {
      createdAt?: protobuf.ITimestamp
      text?: string
      updatedBy?: common.IEntityDescriptor
    }
    export interface ITargetLoanMetadata {
      loanId?: string
    }
    export interface ITargetCardMetadata {
      cardId?: string
    }
    export interface ITargetUserMetadata {
      userId?: string
    }
  }
  export namespace GetUserNotesRequest {
    export interface IByEntityType {
      entityType?: string
    }
    export interface IByEntity {
      entity?: common.IEntityDescriptor
    }
    export interface IByNote {
      entity?: common.IEntityDescriptor
      noteId?: string
    }
  }
  export namespace CreateAuthorizationTokenRequest {
    export type DataEntry = {[key: string]: string}
  }
  export namespace ConsumerInquiryRequest {
    export enum Providers {
      clarity = 'clarity',
      factortrust = 'factortrust',
      lexisnexis = 'lexisnexis',
      giact = 'giact',
      sentilink = 'sentilink',
      credegraph = 'credegraph',
    }
  }
  export namespace GetMostRecentUserSessionRespone {
    export type UserSessionEntry = {[key: string]: string}
  }
  export namespace UserMiscPropertyQueryRequest {
    export enum QueryType {
      NA = 'NA',
      ByUserAndProperty = 'ByUserAndProperty',
      ByProperty = 'ByProperty',
      byUser = 'byUser',
    }
  }
  export namespace GetUserVerificationsRequest {
    export interface IFilterBy {
      status?: string
    }
  }
  export namespace AuthenticateUserRequest {
    export type UserAttributesEntry = {[key: string]: string}
    export enum IamUserGroup {
      NA = 'NA',
      SuperAdmin = 'SuperAdmin',
      Admin = 'Admin',
      BPO = 'BPO',
    }
  }
  export namespace SendPhoneVerificationCodeRequest {
    export enum PhoneVerificationMethod {
      PHONE_VERIFICATION_METHOD_UNSPECIFIED = 'PHONE_VERIFICATION_METHOD_UNSPECIFIED',
      PHONE_VERIFICATION_METHOD_VOICE = 'PHONE_VERIFICATION_METHOD_VOICE',
      PHONE_VERIFICATION_METHOD_SMS = 'PHONE_VERIFICATION_METHOD_SMS',
    }
  }
  export interface IUserEvent {
    data?: users.UserEvent.DataEntry
  }
  export interface IStreetAddress {
    id?: string
    recordType?: string
    street1?: string
    street2?: string
    city?: string
    state?: string
    zipcode?: string
    zipplusfour?: string
    country?: string
    county?: string
    text?: string
    verified?: true | false
    isPoBox?: true | false
    lat?: number
    lon?: number
    utcOffset?: number
    timezoneId?: string
    createdAt?: string
    updatedAt?: string
    addressType?: users.StreetAddressType
  }
  export interface IIdProviderUserInfo {
    type?: string
    provider?: string
    id?: string
    name?: string
    givenName?: string
    familyName?: string
    nickname?: string
    gender?: string
    pictureUrl?: string
    email?: string
    emailVerified?: true | false
  }
  export interface IEligibility {
    loans?: true | false
  }
  export interface IUser {
    id?: string
    firstName?: string
    middleName?: string
    lastName?: string
    nameSuffix?: string
    email?: string
    emailVerified?: boolean
    phone?: string
    phoneVerified?: true | false
    ssn?: string
    ssnVerified?: true | false
    ssnType?: string
    gender?: string
    address?: users.IStreetAddress
    dob?: string
    ssnMask?: string
    dek?: string
    idNumber?: string
    ssnEncrypted?: string
    ssnHash?: string
    identityProviders?: users.IIdProviderUserInfo[]
    status?: string
    subStatus?: string
    dogfood?: true | false
    isAdmin?: true | false
    isBankrupt?: true | false
    createdAt?: string
    updatedAt?: string
    passwordExpiresAt?: string
    eligibility?: users.IEligibility
    analytics?: string
    phoneVerifiedAt?: string
    creditReportingDisabled?: boolean
    preferredLanguage?: common.Locale
    idExpirationDate?: string
  }
  export interface IIDPhoto {
    id?: string
    type?: string
    key?: string
    setKey?: string
    bucket?: string
    contentTypeMime?: string
    status?: string
    uploadedAt?: string
    presignedUrl?: string
  }
  export interface IUpdateUserRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    updatedBy?: common.IEntityDescriptor
  }
  export interface IUpdateUserResponse {
    error?: common.IServiceError
    updatedBy?: common.IEntityDescriptor
    user?: users.IUser
  }
  export interface ICreateUserRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    createdBy?: common.IEntityDescriptor
    sensitive?: true | false
    identityProviders?: users.IIdProviderUserInfo[]
  }
  export interface ICreateUserResponse {
    error?: common.IServiceError
    user?: users.IUser
  }
  export interface IGetUserRequest {
    authorization?: common.IAuthorization
    sensitive?: true | false
    withAnalytics?: true | false
    user?: users.IUser
  }
  export interface ILinkIdentityProviderToUserRequest {
    authorization?: common.IAuthorization
    userId?: string
    identityProvider?: users.IIdProviderUserInfo
  }
  export interface ILinkIdentityProviderToUserResponse {
    error?: common.IServiceError
  }
  export interface IGetUserByIdentityProviderIdRequest {
    authorization?: common.IAuthorization
    id?: string
    provider?: string
  }
  export interface IGetUserResponse {
    error?: common.IServiceError
    user?: users.IUser
  }
  export interface IGetUsersRequest {
    authorization?: common.IAuthorization
    sensitive?: true | false
    user?: users.IUser[]
  }
  export interface IGetUserByPhoneRequest {
    authorization?: common.IAuthorization
    phone?: string
    verified?: boolean
  }
  export interface IGetUsersResponse {
    error?: common.IServiceError
    user?: users.IUser[]
  }
  export interface IUpdateUserEligibilityRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    eligibility?: users.IEligibility
    updatedBy?: common.IEntityDescriptor
  }
  export interface IUpdateUserEligibilityResponse {
    error?: common.IServiceError
    eligibility?: users.IEligibility
  }
  export interface ISetUserStatusRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetUserStatusResponse {
    error?: common.IServiceError
    user?: users.IUser
  }
  export interface ISetUserSubStatusRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetUserSubStatusResponse {
    error?: common.IServiceError
    user?: users.IUser
  }
  export interface IUserEnvEx {
    userId?: string
    fininstAggregator?: users.UserEnvEx.IFinInstAggregator
    fininstProcessor?: users.UserEnvEx.IFinInstProcessor
    mlaOfaProvider?: users.UserEnvEx.IMlaOfacProvider
    fraudFilters?: users.UserEnvEx.IFraudFilters
  }
  export interface IUserNote {
    id?: string
    parentUserId?: string
    loan?: users.UserNote.ITargetLoanMetadata
    card?: users.UserNote.ITargetCardMetadata
    user?: users.UserNote.ITargetUserMetadata
    version?: users.UserNote.IVersion[]
  }
  export interface IGetUserNotesRequest {
    authorization?: common.IAuthorization
    parentUserId?: string
    entityTypeRollup?: users.GetUserNotesRequest.IByEntityType
    entityRollup?: users.GetUserNotesRequest.IByEntity
    specificNote?: users.GetUserNotesRequest.IByNote
  }
  export interface IGetUserNotesResponse {
    notes?: users.IUserNote[]
  }
  export interface ICreateUserNoteRequest {
    authorization?: common.IAuthorization
    note?: users.IUserNote
  }
  export interface ICreateUserNoteResponse {
    note?: users.IUserNote
    alreadyExists?: boolean
  }
  export interface IUpdateUserNoteRequest {
    authorization?: common.IAuthorization
    id?: string
    parentUserId?: string
    entity?: common.IEntityDescriptor
    updatedVersion?: users.UserNote.IVersion
  }
  export interface IUpdateUserNoteResponse {
    note?: users.IUserNote
  }
  export interface IGetUserEnvExRequest {
    authorization?: common.IAuthorization
    users?: users.IUser[]
    updatedBy?: common.IEntityDescriptor
  }
  export interface IGetUserEnvExResponse {
    error?: common.IServiceError
    envs?: users.IUserEnvEx[]
  }
  export interface ISetUserEnvExRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    env?: users.IUserEnvEx
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetUserEnvExResponse {
    error?: common.IServiceError
    env?: users.IUserEnvEx
  }
  export interface IGetLastKnownIpAddressByUserIdRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
  }
  export interface IGetLastKnownIpAddressResponse {
    error?: common.IServiceError
    ipRoute?: string[]
  }
  export interface IMilitaryActiveDutyCheckByUserIdRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    forEntity?: common.IEntityDescriptor
    cacheMode?: common.CacheMode
    requestedBy?: common.IEntityDescriptor
  }
  export interface IMilitaryActiveDutyCheckByUserIdResponse {
    error?: common.IServiceError
    requestId?: string
    isActiveDuty?: true | false
    isOfac?: true | false
    cachedDatetime?: string
    providerId?: users.ConsumerInquiryRequest.Providers
  }
  export interface IGetMatchingUsersRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    limit?: number
    matchOnFullname?: true | false
    matchOnDob?: true | false
    matchOnSsn?: true | false
  }
  export interface IGetMatchingUsersResponse {
    error?: common.IServiceError
    users?: users.IUser[]
  }
  export interface IAuthorizationToken {
    id?: string
    accessToken?: string
    tokenType?: string
    expiresIn?: string
    refreshToken?: string
    scope?: string
    admin?: true | false
    uid?: string
    createdAt?: string
    ipRoute?: string[]
  }
  export interface IUserIdentity {
    key?: string
    bucket?: string
    photos?: users.IIDPhoto
    provider?: string
  }
  export interface ICreateAuthorizationTokenRequest {
    authorization?: common.IAuthorization
    token?: users.IAuthorizationToken
    createdBy?: common.IEntityDescriptor
    data?: users.CreateAuthorizationTokenRequest.DataEntry
  }
  export interface ICreateAuthorizationTokenResponse {
    error?: common.IServiceError
    token?: users.IAuthorizationToken
  }
  export interface IGetAuthorizationTokenRequest {
    authorization?: common.IAuthorization
    token?: users.IAuthorizationToken
    includeExpired?: true | false
  }
  export interface IGetAuthorizationTokenByUserIdResponse {
    error?: common.IServiceError
    token?: users.IAuthorizationToken[]
  }
  export interface IAccountRecoveryRequest {
    authorization?: common.IAuthorization
    phone?: string
  }
  export interface IAccountRecoveryResponse {
    error?: common.IServiceError
  }
  export interface IDeleteUserRequest {
    authorization?: common.IAuthorization
    userId?: string
    currentTime?: string
  }
  export interface IDeleteUserResponse {
    error?: common.IServiceError
  }
  export interface IConsumerInquiryRequest {
    authorization?: common.IAuthorization
    userId?: string
    providerId?: users.ConsumerInquiryRequest.Providers
    type?: string
    forceRefresh?: true | false
    cachedOnly?: true | false
    forEntity?: common.IEntityDescriptor
    requestedBy?: common.IEntityDescriptor
    includeRawResponse?: true | false
  }
  export interface IConsumerInquiryResponse {
    error?: common.IServiceError
    requestId?: string
    isActiveDuty?: true | false
    isOfac?: true | false
    rawResponse?: string
    cachedDatetime?: string
    providerId?: users.ConsumerInquiryRequest.Providers
    validations?: users.PiiValidatedType[]
  }
  export interface IVerifyPiiForUserRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    forEntity?: common.IEntityDescriptor
    forceRefresh?: true | false
    cachedOnly?: true | false
    requestedBy?: common.IEntityDescriptor
  }
  export interface IVerifyPiiForUserResponse {
    error?: common.IServiceError
    requestId?: string
    isPiiSsnMatch?: true | false
    addressMatch?: boolean
    validations?: users.PiiValidatedType[]
    cachedDatetime?: string
    providerId?: users.ConsumerInquiryRequest.Providers
  }
  export interface ISetUsersUrchinParamsRequest {
    authorization?: common.IAuthorization
    userId?: string
    utmCampaign?: string
    utmContent?: string
    utmMedium?: string
    utmSource?: string
    utmTerm?: string
  }
  export interface IGetDuplicateUserIdsForUserByDeviceRequest {
    authorization?: common.IAuthorization
    userId?: string
    limit?: number
  }
  export interface IGetDuplicateUserIdsForUserByDeviceResponse {
    userIds?: string[]
  }
  export interface IGetUsersWithMatchingIdNumbersRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetUsersWithMatchingIdNumbersResponse {
    users?: users.IUser[]
  }
  export interface IUpdateUserFraudOrDuplicateStatusRequest {
    authorization?: common.IAuthorization
    userId?: string
    reason?: string
    type?: users.ReasonType
  }
  export interface IUserFraud {
    userId?: string
    status?: users.ReasonType
    reason?: string
    matchingUserId?: string
    createdAt?: string
    updatedAt?: string
  }
  export interface IUpdateUserFraudRequest {
    authorization?: common.IAuthorization
    userId?: string
    userFraudList?: users.IUserFraud[]
  }
  export interface IGetUserFraudRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetUserFraudResponse {
    userFraud?: users.IUserFraud[]
  }
  export interface IGetMostRecentUserSessionRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetMostRecentUserSessionRespone {
    userSession?: users.GetMostRecentUserSessionRespone.UserSessionEntry
  }
  export interface IResourceWithSignedUrl {
    resourceEntityPath?: string
    signedUrl?: string
  }
  export interface IGetSignedResourceKeyRequest {
    authorization?: common.IAuthorization
    assetsEntityPath?: string[]
  }
  export interface IGetSignedResourceKeyResponse {
    resourceWithSignedUrl?: users.IResourceWithSignedUrl[]
  }
  export interface IUserMiscProperty {
    property?: string
    value?: string
    userId?: string
    updatedBy?: common.IEntityDescriptor
    createAt?: string
    updateAt?: string
    id?: string
    version?: number
  }
  export interface IUserProperty {
    userId?: string
    property?: string
    value?: string
  }
  export interface IUserMiscPropertyNewRequest {
    authorization?: common.IAuthorization
    property?: string
    value?: string
    userId?: string
    version?: number
  }
  export interface IUserMiscPropertyNewResponse {
    error?: common.IServiceError
    property?: users.IUserMiscProperty
  }
  export interface IUserMiscPropertyQueryRequest {
    authorization?: common.IAuthorization
    type?: users.UserMiscPropertyQueryRequest.QueryType
    userId?: string
    property?: string
    getLatest?: true | false
  }
  export interface IUserMiscPropertyQueryResponse {
    error?: common.IServiceError
    properties?: users.IUserMiscProperty[]
  }
  export interface IUserMiscPropertyUpdateRequest {
    authorization?: common.IAuthorization
    id?: string
    value?: string
  }
  export interface IUserMiscPropertyUpdateResponse {
    error?: common.IServiceError
    property?: users.IUserMiscProperty
  }
  export interface IGetUserPropertyRequest {
    authorization?: common.IAuthorization
    userId?: string
    property?: string
  }
  export interface IGetUserPropertyResponse {
    property?: users.IUserProperty
  }
  export interface IGetUserPropertiesRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetUserPropertiesResponse {
    properties?: users.IUserProperty[]
  }
  export interface ISetUserPropertyRequest {
    authorization?: common.IAuthorization
    userId?: string
    property?: string
    value?: string
  }
  export interface IUserSummary {
    preLoanInfo?: users.IUserMiscProperty
  }
  export interface ISetUserAddressBookEntryRequest {
    authorization?: common.IAuthorization
    userId?: string
    requestedBy?: common.IEntityDescriptor
    addressId?: string
    street1?: string
    street2?: string
    city?: string
    state?: string
    zip?: string
    addressType?: users.StreetAddressType
    addressTypeLabel?: string
  }
  export interface ISetUserAddressBookEntryResponse {
    error?: common.IServiceError
    address?: users.IStreetAddress
  }
  export interface IGetUserAddressRequest {
    authorization?: common.IAuthorization
    addressId?: string
  }
  export interface IGetUserAddressResponse {
    error?: common.IServiceError
    address?: users.IStreetAddress
  }
  export interface IGetUserAddressBookRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetUserAddressBookResponse {
    error?: common.IServiceError
    address?: users.IStreetAddress[]
  }
  export interface IGetUserVerificationsRequest {
    authorization?: common.IAuthorization
    userId?: string
    filterBy?: users.GetUserVerificationsRequest.IFilterBy
  }
  export interface IUserVerificationRecord {
    type?: string
    source?: string
    status?: string
    verifiedAt?: string
  }
  export interface IGetUserVerificationsResponse {
    error?: common.IServiceError
    verificationRecords?: users.IUserVerificationRecord[]
  }
  export interface ISetUsersNotificationTokenRequest {
    authorization?: common.IAuthorization
    token?: string
    userId?: string
    bindingType?: users.BindingType
  }
  export interface IGetUserNotificationTokenRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetUserNotificationTokenResponse {
    token?: string[]
  }
  export interface IAuthenticateUserRequest {
    email?: string
    userGroup?: users.AuthenticateUserRequest.IamUserGroup
    userAttributes?: users.AuthenticateUserRequest.UserAttributesEntry
    userAgent?: string
    xForwardedFor?: string
  }
  export interface IAuthenticateUserResponse {
    userId?: string
    role?: users.AuthenticateUserRequest.IamUserGroup
    authenticationToken?: string
  }
  export interface ISetUserCreditReportingDisabledRequest {
    authorization?: common.IAuthorization
    userId?: string
    creditReportingDisabled?: boolean
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetUserCreditReportingDisabledResponse {
    error?: common.IServiceError
  }
  export interface IUserSetPreferredLanguageRequest {
    authorization?: common.IAuthorization
    userId?: string
    preferredLanguage?: common.Locale
    updatedBy?: common.IEntityDescriptor
  }
  export interface IUserSetPreferredLanguageResponse {
    error?: common.IServiceError
  }
  export interface ISendPhoneVerificationCodeRequest {
    authorization?: common.IAuthorization
    userId?: string
    phoneNumber?: string
    method?: users.SendPhoneVerificationCodeRequest.PhoneVerificationMethod
  }
  export interface IVerifyPhoneNumberRequest {
    authorization?: common.IAuthorization
    userId?: string
    phoneNumber?: string
    code?: string
  }
  export interface IRequestMfaRequest {
    authorization?: common.IAuthorization
    userId?: string
    source?: string
    verificationType?: users.UserVerificationType
    messageType?: users.UserVerificationMessageType[]
  }
  export interface IRequestMfaResponse {
    messageType?: users.UserVerificationMessageType
  }
  export interface IValidateMfaRequest {
    authorization?: common.IAuthorization
    userId?: string
    source?: string
    verificationType?: users.UserVerificationType
    verificationCode?: string
    consumeVerificationCode?: boolean
  }
  export interface IValidateMfaResponse {
    isValidVerificationCode?: boolean
  }
  export interface IShouldRefreshMfaRequest {
    authorization?: common.IAuthorization
    userId?: string
    verificationType?: users.UserVerificationType
  }
  export interface IShouldRefreshMfaResponse {
    shouldRefreshMfa?: boolean
  }
  export interface IGetUserLockoutRequest {
    authorization?: common.IAuthorization
    username?: string
    type?: users.UserLockoutType
  }
  export interface IUserLockout {
    username?: string
    type?: users.UserLockoutType
    consecutiveFailures?: number
    totalFailures?: number
    lockedTimeout?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    updatedBy?: string
  }
  export interface IGetUserLockoutResponse {
    userLockout?: users.IUserLockout
  }
  export interface IIncrementFailureCountAndLockoutRequest {
    authorization?: common.IAuthorization
    username?: string
    lockedTimeout?: protobuf.ITimestamp
    type?: users.UserLockoutType
  }
  export interface IIncrementFailureCountRequest {
    authorization?: common.IAuthorization
    username?: string
    type?: users.UserLockoutType
  }
  export interface IClearFailureCountRequest {
    authorization?: common.IAuthorization
    username?: string
    type?: users.UserLockoutType
  }
  export interface ILogoutRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IUsersBankruptcy {
    userId?: string
    bankruptcyCode?: users.BankruptcyCode
    s3Key?: string
    filedDate?: protobuf.ITimestamp
  }
  export interface IAddUserBankruptcyRecordRequest {
    authorization?: common.IAuthorization
    userBankruptcy?: users.IUsersBankruptcy
    createdBy?: common.IEntityDescriptor
  }
  export interface IGetCurrentUserBankruptcyRecordRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetCurrentUserBankruptcyRecordResponse {
    userBankruptcy?: users.IUsersBankruptcy
  }
  export enum PiiValidatedType {
    PII_VALIDATED_TYPE_UNSPECIFIED = 'PII_VALIDATED_TYPE_UNSPECIFIED',
    PII_VALIDATED_TYPE_NONE = 'PII_VALIDATED_TYPE_NONE',
    PII_VALIDATED_TYPE_SSN = 'PII_VALIDATED_TYPE_SSN',
    PII_VALIDATED_TYPE_ADDRESS = 'PII_VALIDATED_TYPE_ADDRESS',
    PII_VALIDATED_TYPE_IDENTITY = 'PII_VALIDATED_TYPE_IDENTITY',
    PII_VALIDATED_TYPE_FRAUD = 'PII_VALIDATED_TYPE_FRAUD',
  }
  export enum ReasonType {
    fraudulent = 'fraudulent',
    duplicate = 'duplicate',
    ignored = 'ignored',
  }
  export enum StreetAddressType {
    STREET_ADDRESS_TYPE_HOME = 'STREET_ADDRESS_TYPE_HOME',
    STREET_ADDRESS_TYPE_MAILING = 'STREET_ADDRESS_TYPE_MAILING',
    STREET_ADDRESS_TYPE_WORK = 'STREET_ADDRESS_TYPE_WORK',
    STREET_ADDRESS_TYPE_OTHER = 'STREET_ADDRESS_TYPE_OTHER',
  }
  export enum BindingType {
    FCM = 'FCM',
  }
  export enum UserVerificationMessageType {
    USER_VERIFICATION_MESSAGE_UNSPECIFIED = 'USER_VERIFICATION_MESSAGE_UNSPECIFIED',
    USER_VERIFICATION_MESSAGE_EMAIL_LINK = 'USER_VERIFICATION_MESSAGE_EMAIL_LINK',
    USER_VERIFICATION_MESSAGE_EMAIL_CODE = 'USER_VERIFICATION_MESSAGE_EMAIL_CODE',
    USER_VERIFICATION_MESSAGE_PHONE_VOICE = 'USER_VERIFICATION_MESSAGE_PHONE_VOICE',
    USER_VERIFICATION_MESSAGE_PHONE_SMS = 'USER_VERIFICATION_MESSAGE_PHONE_SMS',
  }
  export enum UserVerificationType {
    USER_VERIFICATION_UNSPECIFIED = 'USER_VERIFICATION_UNSPECIFIED',
    USER_VERIFICATION_VERIFY_EMAIL = 'USER_VERIFICATION_VERIFY_EMAIL',
    USER_VERIFICATION_VERIFY_PHONE = 'USER_VERIFICATION_VERIFY_PHONE',
    USER_VERIFICATION_MFA_LOGIN = 'USER_VERIFICATION_MFA_LOGIN',
    USER_VERIFICATION_MFA_CARDS = 'USER_VERIFICATION_MFA_CARDS',
    USER_VERIFICATION_MFA_STATEMENTS = 'USER_VERIFICATION_MFA_STATEMENTS',
  }
  export enum UserLockoutType {
    password = 'password',
    verification_code = 'verification_code',
  }
  export enum BankruptcyCode {
    BANKRUPTCY_CODE_UNSPECIFIED = 'BANKRUPTCY_CODE_UNSPECIFIED',
    BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_7 = 'BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_7',
    BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_CHAPTER_7 = 'BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_CHAPTER_7',
    BANKRUPTCY_CODE_BANKRUPTCY_DISCHARGED_CHAPTER_7 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISCHARGED_CHAPTER_7',
    BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_RESCINDED_CHAPTER_7 = 'BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_RESCINDED_CHAPTER_7',
    BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_7 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_7',
    BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_7 = 'BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_7',
    BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_11 = 'BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_11',
    BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_CHAPTER_11 = 'BANKRUPTCY_CODE_REAFFIRMATION_OF_DEBT_CHAPTER_11',
    BANKRUPTCY_CODE_BANKRUPTCY_DISCHARGED_CHAPTER_11 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISCHARGED_CHAPTER_11',
    BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_11 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_11',
    BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_11 = 'BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_11',
    BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_12 = 'BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_12',
    BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_12 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_12',
    BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_12 = 'BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_12',
    BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_13 = 'BANKRUPTCY_CODE_BANKRUPTCY_FILED_CHAPTER_13',
    BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_13 = 'BANKRUPTCY_CODE_BANKRUPTCY_DISMISSED_CHAPTER_13',
    BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_13 = 'BANKRUPTCY_CODE_BANKRUPTCY_WITHDRAWN_CHAPTER_13',
    BANKRUPTCY_CODE_BANKRUPTCY_CHAPTER_12_13_CONVERTED_TO_CHAPTER_7 = 'BANKRUPTCY_CODE_BANKRUPTCY_CHAPTER_12_13_CONVERTED_TO_CHAPTER_7',
  }
}
export namespace underwriting {
  export namespace Underwriting {
    export namespace Income {
      export interface IPayrollGroup {
        payrollTransactions?: banking.ITransactionHistoryItem[]
        name?: string
        reasonForInclusion?: string
        payrollFrequency?: string
        averageIncomePerPayroll?: protobuf.IDoubleValue
        lengthOfPayrollInWeeks?: protobuf.IUInt32Value
        nextEstimatedPayrollDate?: string
        nextEstimatedPayrollDates?: string
      }
    }
    export namespace Loans {
      export interface ILoanGroup {
        loanTransactions?: banking.ITransactionHistoryItem[]
        name?: string
        status?: string
        estimatedPayment?: protobuf.IDoubleValue
      }
    }
    export namespace DecisionParams {
      export interface IShadowModelRes {
        modelName?: string
        shadowScore?: protobuf.IDoubleValue
        shadowBucket?: string
        shadowApproved?: string
      }
      export interface IModelBucket {
        championBucket?: protobuf.IInt32Value
        challengerBucket?: protobuf.IInt32Value
      }
      export interface IDecisioningModelMatrix {
        name?: string
        modelMatrix?: underwriting.Underwriting.DecisionParams.IModelObject[]
        modelBucket?: string
        modelApproved?: string
      }
      export interface IModelObject {
        modelName?: string
        modelLogicType?: string
        label?: string
        score?: protobuf.IDoubleValue
        bucket?: string
        approved?: string
      }
      export interface ILoanAmountAdjustment {
        name?: string
        initialAmount?: protobuf.IInt32Value
        adjustedAmount?: protobuf.IInt32Value
        order?: protobuf.IInt32Value
      }
      export interface IFeature {
        name?: string
        value?: string
      }
    }
    export interface IIncome {
      estimatedWeeklyIncome?: protobuf.IDoubleValue
      lenghtOfIncomeInWeeks?: protobuf.IUInt32Value
      payrollsPerMonth?: protobuf.IDoubleValue
      consistentIncomeSources?: protobuf.IUInt32Value
      residualIncome?: protobuf.IDoubleValue
      incomeGraph?: protobuf.IBytesValue
      incomeGraphS3Location?: string
      incomeTransactions?: banking.ITransactionHistoryItem[]
      payrollGroups?: underwriting.Underwriting.Income.IPayrollGroup[]
    }
    export interface IBankAccount {
      availableBankBalance?: protobuf.IDoubleValue
      instancesOfBankFees?: protobuf.IUInt32Value
      totalBankFees?: protobuf.IDoubleValue
      feeTransactions?: banking.ITransactionHistoryItem[]
      payrollAccountId?: string
      eligibleAccountIds?: string
      latestBalanceDate?: string
      overdrafts?: underwriting.IFeeGroup
      nsf?: underwriting.IFeeGroup
      returns?: underwriting.IFeeGroup
      reversals?: underwriting.IFeeGroup
      atm?: underwriting.IFeeGroup
      maintenance?: underwriting.IFeeGroup
      refund?: underwriting.IFeeGroup
    }
    export interface ILoans {
      dueNextPayroll?: protobuf.IDoubleValue
      loanTransactions?: banking.ITransactionHistoryItem[]
      loanGroups?: underwriting.Underwriting.Loans.ILoanGroup[]
    }
    export interface IDecisionParams {
      codeVersion?: string
      modelVersion?: string
      isSwapIn?: boolean
      isRepeatLoan?: boolean
      userState?: string
      isChimeAccount?: boolean
      isPrepaidAccount?: boolean
      isOnlineAccount?: boolean
      approvedRoundTo50?: boolean
      isAutoApprove?: boolean
      payrollScheduleType?: string
      clarityBucket?: string
      lexisNexisBucket?: string
      shadowResults?: underwriting.Underwriting.DecisionParams.IShadowModelRes[]
      modelBucket?: underwriting.Underwriting.DecisionParams.IModelBucket
      dominantPayrollCategory?: string
      ltiRatio?: protobuf.IDoubleValue
      activeTests?: string
      hitLineAssignmentMax?: boolean
      proposedLoanAmount?: protobuf.IInt32Value
      modelMatrices?: underwriting.Underwriting.DecisionParams.IDecisioningModelMatrix[]
      loanAmountAdjustments?: underwriting.Underwriting.DecisionParams.ILoanAmountAdjustment[]
      loanAmountFeatures?: underwriting.Underwriting.DecisionParams.IFeature[]
    }
    export interface IScore {
      reject?: boolean
      score?: protobuf.IDoubleValue
      approvalScore?: protobuf.IDoubleValue
      rejectionScore?: protobuf.IDoubleValue
      scorePercentile?: protobuf.IUInt32Value
      rejectReason?: string
      proposedLoanAmount?: protobuf.IDoubleValue
      proposedPaymentDates?: string
      rejectReasonsList?: string
      forceManualReview?: boolean
      forceManualReviewReasons?: string
      decisionSummaryPlot?: protobuf.IBytesValue
      decisionForcePlot?: protobuf.IBytesValue
      lowestScoringFeatures?: string
      proposedCreditLimit?: protobuf.IDoubleValue
    }
  }
  export interface IUnderwriting {
    model?: string
    income?: underwriting.Underwriting.IIncome
    bankAccount?: underwriting.Underwriting.IBankAccount
    loans?: underwriting.Underwriting.ILoans
    score?: underwriting.Underwriting.IScore
    decisionParams?: underwriting.Underwriting.IDecisionParams
  }
  export interface IUnderwritingRequest {
    authorization?: common.IAuthorization
    user?: users.IUser
    assessmentDate?: string
    loanTerms?: lag.ILoanTerms
    cardAccountType?: cards.ICardAccountType
  }
  export interface IPaymentScheduleRequest {
    authorization?: common.IAuthorization
    userId?: string
    assessmentDate?: string
    numberOfPayments?: protobuf.IUInt32Value
    paymentScheduleType?: underwriting.PaymentScheduleType
  }
  export interface IUnderwritingResponse {
    error?: common.IServiceError
    underwriting?: underwriting.IUnderwriting
  }
  export interface IPaymentScheduleResponse {
    error?: common.IServiceError
    schedule?: underwriting.IPaymentScheduleDateCollection
    unadjustedSchedule?: underwriting.IPaymentScheduleDateCollection
    weekendHolidayAdjustedSchedule?: underwriting.IPaymentScheduleDateCollection
    frequency?: underwriting.PaymentScheduleFrequency
  }
  export interface IPaymentScheduleDateCollection {
    dates?: string
  }
  export interface IFeeGroup {
    feeTransactions?: banking.ITransactionHistoryItem[]
    name?: string
    instancesInLastTwoWeeks?: protobuf.IUInt32Value
    totalFeesInLastTwoWeeks?: protobuf.IDoubleValue
    instancesInLastThreeMonths?: protobuf.IUInt32Value
    totalFeesInLastThreeMonths?: protobuf.IDoubleValue
  }
  export interface IPartnerUnderwritingRequest {
    authorization?: common.IAuthorization
    partnerId?: partners.PartnerId
    leadTrackingId?: string
    email?: string
    phone?: string
    address?: string
    city?: string
    state?: string
    zipCode?: string
    firstName?: string
    lastName?: string
    birthDate?: protobuf.ITimestamp
    selfCreditRating?: underwriting.UnderwritingSelfCreditRating
    annualIncome?: number
    ssnEncrypted?: string
    leadId?: string
  }
  export interface IPartnerUnderwritingResponse {
    error?: common.IServiceError
    rejected?: boolean
    rejectionReasonsList?: string
    proposedLoanAmount?: protobuf.IDoubleValue
  }
  export enum PaymentScheduleFrequency {
    PAYMENT_SCHEDULE_FREQUENCY_UNSPECIFIED = 'PAYMENT_SCHEDULE_FREQUENCY_UNSPECIFIED',
    PAYMENT_SCHEDULE_FREQUENCY_WEEKLY = 'PAYMENT_SCHEDULE_FREQUENCY_WEEKLY',
    PAYMENT_SCHEDULE_FREQUENCY_BIWEEKLY = 'PAYMENT_SCHEDULE_FREQUENCY_BIWEEKLY',
    PAYMENT_SCHEDULE_FREQUENCY_MONTHLY = 'PAYMENT_SCHEDULE_FREQUENCY_MONTHLY',
    PAYMENT_SCHEDULE_FREQUENCY_UNKNOWN = 'PAYMENT_SCHEDULE_FREQUENCY_UNKNOWN',
  }
  export enum PaymentScheduleType {
    PAYMENT_SCHEDULE_TYPE_UNSPECIFIED = 'PAYMENT_SCHEDULE_TYPE_UNSPECIFIED',
    PAYMENT_SCHEDULE_TYPE_CARDS_INITIAL = 'PAYMENT_SCHEDULE_TYPE_CARDS_INITIAL',
    PAYMENT_SCHEDULE_TYPE_CARDS_REFRESH = 'PAYMENT_SCHEDULE_TYPE_CARDS_REFRESH',
  }
  export enum UnderwritingSelfCreditRating {
    UNDERWRITING_SELF_CREDIT_RATING_UNSPECIFIED = 'UNDERWRITING_SELF_CREDIT_RATING_UNSPECIFIED',
    UNDERWRITING_SELF_CREDIT_RATING_EXCELLENT = 'UNDERWRITING_SELF_CREDIT_RATING_EXCELLENT',
    UNDERWRITING_SELF_CREDIT_RATING_GOOD = 'UNDERWRITING_SELF_CREDIT_RATING_GOOD',
    UNDERWRITING_SELF_CREDIT_RATING_FAIR = 'UNDERWRITING_SELF_CREDIT_RATING_FAIR',
    UNDERWRITING_SELF_CREDIT_RATING_POOR = 'UNDERWRITING_SELF_CREDIT_RATING_POOR',
    UNDERWRITING_SELF_CREDIT_RATING_UNAVAILABLE = 'UNDERWRITING_SELF_CREDIT_RATING_UNAVAILABLE',
  }
}
export namespace loans {
  export namespace Loan {
    export namespace LoanStatus {
      export type StatusDataEntry = {[key: string]: string}
    }
    export namespace Underwriting {
      export type DataEntry = {[key: string]: string}
    }
    export interface IPaymentInstrument {
      number?: banking.LinkedAccount.INumber
      linkedAccount?: banking.ILinkedAccount
      fundingSource?: banking.LinkedProcessorAccount.ILinkedProcessorFundingSource
      isValid?: true | false
      preference?: number
      direction?: banking.FundingAuthorization.Direction
    }
    export interface ILoanStatus {
      status?: string
      statusReason?: string
      statusData?: loans.Loan.LoanStatus.StatusDataEntry
      statusCodes?: string[]
      subStatus?: string
      autoStatesExemptTill?: string
      counterOfferCodes?: string[]
      approvalDelayed?: true | false
      aggregateStatus?: loans.LoanAggregateStatus
    }
    export interface IUnderwriting {
      data?: loans.Loan.Underwriting.DataEntry
      version?: string
      overallScore?: number
      loansInGoodstanding?: true | false
      incomeFreq?: number
      directDepositPaychecks?: true | false
      averageMonthlyIncome3mo?: number
      estimatedMonthlyIncome3mo?: number
      accountBalance?: number
    }
    export interface IType {
      id?: string
      state?: string
      title?: string
      status?: string
      terms?: lag.ILoanTerms
      regionCode?: string
      vintage?: string
      createdAtDatetime?: string
      updatedAtDatetime?: string
      updatedBy?: common.IEntityDescriptor
      isPrimary?: true | false
    }
    export interface IPayment {
      date?: string
      amount?: number
      currency?: string
      fee?: number
      cabFee?: number
      ordinal?: number
      interestAndPrincipal?: number
    }
    export interface IAgreement {
      apr?: number
      email?: string
      loanNum?: string
      payments?: loans.Loan.IPayment[]
      fullName?: string
      telephone?: string
      totalFee?: number
      totalOwed?: number
      loanAmount?: number
      cabFee?: number
      feePercent?: number
      interestFee?: number
      totalInterestAndPrincipal?: number
      daysOfLoan?: number
      unitPeriod?: number
      fullAddress?: string
      originationDate?: string
      veritecTransNum?: string
      loanTerminationDate?: string
      now?: string
      borrowSigned?: true | false
      userUtcOffset?: number
      cancelDate?: string
      stateName?: string
    }
    export interface INote {
      id?: string
      loanId?: string
      body?: string
      createdAt?: string
      updatedAt?: string
      updatedBy?: common.IEntityDescriptor
      createdBy?: common.IEntityDescriptor
      loanStatus?: string
      loanSubstatus?: string
      paymentId?: string
      paymentStatus?: string
      originalDate?: string
      rescheduledDate?: string
      fundingAuthId?: string
      fundingAuthStatus?: string
      fundingAuthProcessorStatus?: string
      type?: string
    }
  }
  export namespace LoanEvent {
    export type DataEntry = {[key: string]: string}
  }
  export namespace SetLoanPaymentDueDatesRequest {
    export type PaymentsEntry = {[key: string]: string}
  }
  export namespace GetLoansByStatusResponse {
    export type LoanTypesEntry = {[key: string]: loans.Loan.IType}
  }
  export namespace GetLoanStatusHistoryByLoanIdResponse {
    export interface ILoanStatusChange {
      previousStatus?: string
      status?: string
      previousSubstatus?: string
      substatus?: string
      createdAt?: string
    }
  }
  export namespace GetLoanPaymentMethodsResponse {
    export interface ILoanPaymentMethods {
      network?: banking.LinkedAccount.ProcessorNetwork
      direction?: banking.FundingAuthorization.Direction
    }
  }
  export namespace UnderwritingSteps {
    export interface IStep {
      updatedAt?: string
      status?: loans.UnderwritingSteps.StepStatus
      statusReason?: string
      statusData?: string
      uraId?: string
    }
    export enum StepStatus {
      Incomplete = 'Incomplete',
      Success = 'Success',
      Failed = 'Failed',
      URA = 'URA',
    }
  }
  export namespace SetPrimaryFundingSourceRequest {
    export interface ILoanPaymentMethods {
      network?: banking.LinkedAccount.ProcessorNetwork
      direction?: banking.FundingAuthorization.Direction
    }
  }
  export interface ITradeLine {
    identificationNumber?: string
    cycleIdentifier?: string
    portfolioType?: string
    accountType?: string
    creditLimit?: number
    termsDuration?: string
    termsFrequency?: string
    complianceConditionCode?: string
    interestTypeIndicator?: string
    ecoaCode?: string
    consumerInformationIndicator?: string
    addressIndicator?: string
    residenceCode?: string
    timeStamp?: protobuf.ITimestamp
    consumerAccountNumber?: string
    dateOpened?: protobuf.ITimestamp
    highestCreditOrOriginalLoanAmount?: number
    scheduledMonthlyPayment?: number
    actualPayment?: number
    accountStatus?: string
    paymentRating?: string
    paymentHistoryProfile?: string
    specialComment?: string
    currentBalance?: number
    amountPastDue?: number
    originalChargeoffAmount?: number
    dateOfAccountInformation?: protobuf.ITimestamp
    dateOfFirstDelinquency?: protobuf.ITimestamp
    dateClosed?: protobuf.ITimestamp
    dateOfLastPayment?: protobuf.ITimestamp
    paymentHistoryStartDate?: protobuf.ITimestamp
    surname?: string
    firstName?: string
    middleName?: string
    generationCode?: string
    dateOfBirth?: protobuf.ITimestamp
    phone?: string
    countryCode?: string
    firstLineOfAddress?: string
    secondLineOfAddress?: string
    city?: string
    state?: string
    postalZipCode?: string
  }
  export interface ILoan {
    id?: string
    typeId?: string
    status?: loans.Loan.ILoanStatus
    createdAtDatetime?: string
    approvedAtDatetime?: string
    activeAtDatetime?: string
    amountRequested?: number
    amountBorrowed?: number
    amountOutstanding?: number
    rejectedAtDatetime?: string
    userId?: string
    updatedAtDatetime?: string
    dueAtDatetime?: string
    currency?: string
    paymentMethod?: string
    originationDatetime?: string
    disbursementMethod?: string
    reapplyDatetime?: string
    grossMonthlyIncome?: protobuf.IDoubleValue
    monthlyIncomePeriodStartDate?: string
    monthlyIncomePeriodEndDate?: string
    underwriting?: loans.Loan.IUnderwriting
    agreement?: loans.Loan.IAgreement
    amountBorrowedInc?: number
    amountOutstandingInc?: number
    amountPaidOffInc?: number
    fundingSettledAt?: string
    chargedOffDatetime?: string
    paidOffDatetime?: string
    sentToCollectionsDatetime?: string
    borrowingBaseChangedDatetime?: string
    amortizationInfoJson?: string
    stateRecordId?: string
    complianceCode?: banking.FundingAuthorization.ComplianceCodes
    borrowingBaseId?: string
    suspendedStatus?: string
    suspendedData?: string
    updatedBy?: common.IEntityDescriptor
    activeCancelRequestedBy?: common.IEntityDescriptor
    externalId?: string
    originalLoanId?: string
    paymentInstruments?: loans.Loan.IPaymentInstrument[]
    fundRecoveryPartner?: loans.LoanFundRecoveryPartner
    sentToFundRecoveryAt?: protobuf.ITimestamp
    role?: loans.LoanRole
    expiresAt?: protobuf.ITimestamp
  }
  export interface ILoanEvent {
    data?: loans.LoanEvent.DataEntry
  }
  export interface ILoanSimplifiedVersion {
    id?: string
    userId?: string
    typeId?: string
    status?: loans.LoanStatus
    substatus?: loans.LoanSubstatus
    createdAt?: protobuf.ITimestamp
  }
  export interface IPaymentTransaction {
    paymentId?: string
    fundingId?: string
    accountMask?: string
    accountFriendlyName?: string
    failedAt?: protobuf.ITimestamp
    settledAt?: protobuf.ITimestamp
  }
  export interface ISetLoanStatusRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    updatedBy?: common.IEntityDescriptor
    updatedVia?: common.IEntityDescriptor
  }
  export interface ISetLoanStatusResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface IGetLoanRolesRequest {
    loanIds?: string[]
  }
  export interface ILoanRoleResponse {
    loanId?: string
    role?: loans.LoanRole
  }
  export interface IGetLoanRolesResponse {
    roles?: loans.ILoanRoleResponse[]
  }
  export interface ICreateLoanRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    createdBy?: common.IEntityDescriptor
    createdVia?: common.IEntityDescriptor
  }
  export interface ICreateLoanResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface ISetLoanPaymentDueDatesRequest {
    authorization?: common.IAuthorization
    loanId?: string
    payments?: loans.SetLoanPaymentDueDatesRequest.PaymentsEntry
    reason?: loans.DueDatesChangeReason
  }
  export interface IScheduledPayment {
    ordinal?: number
    currentDate?: protobuf.ITimestamp
    amount?: number
  }
  export interface IPaymentDateUpdate {
    paymentId?: string
    ordinal?: number
    newDate?: protobuf.ITimestamp
  }
  export interface ILoanAgreementTerms {
    payments?: loans.IScheduledPayment[]
    originationDate?: protobuf.ITimestamp
    terminationDate?: protobuf.ITimestamp
    durationDays?: number
    principal?: number
    fees?: number
    interest?: number
    totalOwed?: number
    apr?: number
    customerName?: string
    customerAddress?: string
    customerPhone?: string
    customerEmail?: string
    loanNumber?: string
    sdbId?: string
  }
  export interface ISetPaymentDatesRequest {
    authorization?: common.IAuthorization
    loanId?: string
    payments?: loans.IPaymentDateUpdate[]
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetPaymentDatesResult {
    code?: loans.SetPaymentDatesResultCode
    message?: string
  }
  export interface ISetPaymentDatesResponse {
    loanAgreementTerms?: loans.ILoanAgreementTerms
    payments?: loans.IPayment[]
    result?: loans.ISetPaymentDatesResult
  }
  export interface IPayNowRequest {
    authorization?: common.IAuthorization
    loanId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IPayNowResponse {
    loanAgreementTerms?: loans.ILoanAgreementTerms
    payments?: loans.IPayment[]
  }
  export interface IGetCustomPaymentBalancesRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface ICustomPaymentOption {
    intention?: loans.CustomPaymentIntention
    amount?: number
    installmentCount?: number
  }
  export interface IGetCustomPaymentBalancesResponse {
    options?: loans.ICustomPaymentOption[]
  }
  export interface ISubmitCustomPaymentRequest {
    authorization?: common.IAuthorization
    loanId?: string
    intention?: loans.CustomPaymentIntention
    instrumentId?: string
  }
  export interface ISubmitCustomPaymentResponse {
    result?: loans.SubmitCustomPaymentResult
    amount?: number
    initiatedAt?: protobuf.ITimestamp
  }
  export interface IChangePaymentDatesRequest {
    authorization?: common.IAuthorization
    loanId?: string
    payments?: lag.IPaymentDateChange[]
    overrideChecks?: true | false
    doNotAdjustDates?: true | false
    payNow?: true | false
    updatedBy?: common.IEntityDescriptor
    updatedVia?: common.IEntityDescriptor
  }
  export interface IGetLoanRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    ignorePaymentInstruments?: boolean
  }
  export interface IGetLoansByStatusRequest {
    authorization?: common.IAuthorization
    status?: string[]
    subStatus?: string[]
    userId?: string
  }
  export interface IGetLoanResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface IGetLoansByUserIdRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    loans?: loans.ILoan[]
    withPaymentInstruments?: true | false
  }
  export interface IGetLoansByUserIdResponse {
    error?: common.IServiceError
    loans?: loans.ILoan[]
  }
  export interface IGetLoansSimplifiedVersionByUserIdRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetLoansSimplifiedVersionByUserIdResponse {
    loans?: loans.ILoanSimplifiedVersion[]
  }
  export interface IGetLoansByStatusResponse {
    error?: common.IServiceError
    loans?: loans.ILoan[]
    loanTypes?: loans.GetLoansByStatusResponse.LoanTypesEntry
  }
  export interface IGetLoanCountRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetLoanCountResponse {
    count?: number
  }
  export interface IGetPrimaryLoanTypeByStateRequest {
    authorization?: common.IAuthorization
    state?: string
  }
  export interface IGetPrimaryLoanTypeByStateResponse {
    loanType?: loans.Loan.IType
  }
  export interface IGetPrimaryLoanTypeByRegionRequest {
    authorization?: common.IAuthorization
    regionCode?: string
  }
  export interface IGetPrimaryLoanTypeByRegionResponse {
    error?: common.IServiceError
    loanType?: loans.Loan.IType
  }
  export interface IGetLoanTypesRequest {
    authorization?: common.IAuthorization
    status?: string
  }
  export interface IGetLoanTypesResponse {
    error?: common.IServiceError
    loanType?: loans.Loan.IType[]
  }
  export interface IGetLoanTypeByIdRequest {
    authorization?: common.IAuthorization
    id?: string
    status?: string
  }
  export interface IGetLoanTypeByIdResponse {
    error?: common.IServiceError
    loanType?: loans.Loan.IType
  }
  export interface IGetActiveLoanTypesByRegionRequest {
    authorization?: common.IAuthorization
    regionCode?: string
  }
  export interface IGetActiveLoanTypesByRegionResponse {
    error?: common.IServiceError
    loanTypes?: loans.Loan.IType[]
  }
  export interface IGetLoanNotesRequest {
    authorization?: common.IAuthorization
    note?: loans.Loan.INote
  }
  export interface IGetLoanNotesResponse {
    error?: common.IServiceError
    notes?: loans.Loan.INote[]
  }
  export interface ICreateLoanNoteRequest {
    authorization?: common.IAuthorization
    note?: loans.Loan.INote
    requestedBy?: common.IEntityDescriptor
  }
  export interface IUpdateLoanNoteRequest {
    authorization?: common.IAuthorization
    note?: loans.Loan.INote
    requestedBy?: common.IEntityDescriptor
  }
  export interface IDeleteLoanNoteRequest {
    authorization?: common.IAuthorization
    note?: loans.Loan.INote
  }
  export interface IGetLoanNoteResponse {
    error?: common.IServiceError
    notes?: loans.Loan.INote
  }
  export interface IGenerateUnderwritingDataRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGenerateUnderwritingDataResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface ISetLoanUnderwritingByIdRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    underwriting?: loans.Loan.IUnderwriting
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetLoanUnderwritingByIdResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface IGetLoanStatusHistoryByLoanIdRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
  }
  export interface IGetLoanStatusHistoryByLoanIdResponse {
    error?: common.IServiceError
    history?: loans.GetLoanStatusHistoryByLoanIdResponse.ILoanStatusChange[]
  }
  export interface IGetNextAvailablePaymentDateRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    localize?: true | false
    timeNow?: string
    forDisbursement?: true | false
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetNextAvailablePaymentDateResponse {
    error?: common.IServiceError
    settlementDelayDays?: number
    settlesOnHolidaysAndWeekends?: true | false
    desiredSettlementDatetime?: string
    adjustedSettlementDatetime?: string
    submitForProcessingBy?: string
    timeNow?: string
  }
  export interface IGetLoanFundingStatusRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
  }
  export interface IGetLoanFundingStatusResponse {
    error?: common.IServiceError
    status?: string
    processorStatus?: string
    processorStatusReason?: banking.FundingAuthorization.IProcessorStatusReason
    displayStatus?: string
    displayProcessorStatus?: string
  }
  export interface IGetLoanPaymentMethodsRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
  }
  export interface IGetLoanPaymentMethodsResponse {
    error?: common.IServiceError
    methods?: loans.GetLoanPaymentMethodsResponse.ILoanPaymentMethods[]
  }
  export interface ICancelLoanByLoanIdRequest {
    authorization?: common.IAuthorization
    forceCancel?: true | false
    loan?: loans.ILoan
    updatedBy?: common.IEntityDescriptor
  }
  export interface IConvertLoanRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    offerId?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IConvertLoanResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
    payments?: lag.ILoanPaymentRecord[]
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetConversionOptionsRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    includeOffers?: true | false
    effectiveDate?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IGetConversionOptionsResponse {
    error?: common.IServiceError
    options?: lag.LoanTerms.IConversionOption[]
  }
  export interface ISuspendLoanRequest {
    authorization?: common.IAuthorization
    loanId?: string
    suspendedStatus?: string
    suspendedData?: string
    updatedBy?: common.IEntityDescriptor
    updatedVia?: common.IEntityDescriptor
  }
  export interface ISuspendLoanResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
  }
  export interface IUpdateLoanPaymentStatusRequest {
    authorization?: common.IAuthorization
    id?: string
    status?: string
    date?: string
    updatedBy?: common.IEntityDescriptor
    updatedVia?: common.IEntityDescriptor
  }
  export interface IUpdateLoanPaymentStatusResponse {
    error?: common.IServiceError
  }
  export interface ICancelPaymentsRequest {
    authorization?: common.IAuthorization
    loanId?: string
    paymentIds?: string[]
    updatedBy?: common.IEntityDescriptor
  }
  export interface ICancelPaymentResult {
    resultCode?: loans.CancelPaymentResultCode
    currentPayment?: loans.IPayment
    cancelledFundingAuthorizationId?: string
  }
  export interface ICancelPaymentsResponse {
    resultCode?: loans.CancelPaymentsResultCode
    results?: loans.ICancelPaymentResult[]
  }
  export interface IFailPaymentsRequest {
    authorization?: common.IAuthorization
    loanId?: string
    paymentIds?: string[]
    failureCode?: loans.PaymentFailureCode
    failureDescription?: string
    updatedBy?: common.IEntityDescriptor
  }
  export interface IFailPaymentResult {
    resultCode?: loans.FailPaymentResultCode
    currentPayment?: loans.IPayment
  }
  export interface IFailPaymentsResponse {
    resultCode?: loans.FailPaymentsResultCode
    results?: loans.IFailPaymentResult[]
  }
  export interface IUpdateLoanPaymentMethodsRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    paymentInstrumentId?: string
    direction?: banking.FundingAuthorization.Direction
    paymentMethod?: string
    requestedBy?: common.IEntityDescriptor
  }
  export interface IUpdateLoanPaymentMethodsResponse {
    error?: common.IServiceError
  }
  export interface IGetLoansForWorkflowSummaryRequest {
    authorization?: common.IAuthorization
    workflow?: string
  }
  export interface IGetLoansForWorkflowSummaryResponse {
    error?: common.IServiceError
    loans?: loans.ILoan[]
  }
  export interface IUnderwritingSteps {
    loanId?: string
    uraClearedTime?: string
    pi?: loans.UnderwritingSteps.IStep
    selfie?: loans.UnderwritingSteps.IStep
    uwBankSummary?: underwriting.IUnderwriting
    sdb?: loans.UnderwritingSteps.IStep
    duplicateAccounts?: loans.UnderwritingSteps.IStep
    bank?: loans.UnderwritingSteps.IStep
    accountSelection?: loans.UnderwritingSteps.IStep
    clarity?: loans.UnderwritingSteps.IStep
    paymentDates?: loans.UnderwritingSteps.IStep
    pfHistory?: loans.UnderwritingSteps.IStep
    lastReviewedBy?: common.IEntityDescriptor
    paymentProcessor?: loans.UnderwritingSteps.IStep
  }
  export interface IUnderwritingStepsRequest {
    authorization?: common.IAuthorization
    underwritingSteps?: loans.IUnderwritingSteps
    underwritingStepsBulk?: loans.IUnderwritingSteps[]
    ignoreUnderwritingData?: true | false
    refreshUwBankSummary?: true | false
    requestedBy?: common.IEntityDescriptor
  }
  export interface IUnderwritingStepsResponse {
    error?: common.IServiceError
    underwritingSteps?: loans.IUnderwritingSteps
    underwritingStepsBulk?: loans.IUnderwritingSteps[]
  }
  export interface ISetPrimaryFundingSourceRequest {
    authorization?: common.IAuthorization
    linkedAccount?: banking.ILinkedAccount
    updateLoan?: true | false
    loan?: loans.ILoan
    loanPaymentMethod?: loans.SetPrimaryFundingSourceRequest.ILoanPaymentMethods
    loanDisbursementMethod?: loans.SetPrimaryFundingSourceRequest.ILoanPaymentMethods
    requestedBy?: common.IEntityDescriptor
  }
  export interface ISetPrimaryFundingSourceResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
    accounts?: banking.ILinkedAccount[]
  }
  export interface IUpdateLoanStatusRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    requestedBy?: common.IEntityDescriptor
    requestedVia?: common.IEntityDescriptor
  }
  export interface ISetLoanGrossMonthlyIncomeRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    grossMonthlyIncome?: number
    monthlyIncomePeriodStartDate?: string
    monthlyIncomePeriodEndDate?: string
    updatedBy?: common.IEntityDescriptor
    updatedVia?: common.IEntityDescriptor
  }
  export interface ISetLoanGrossMonthlyIncomeResponse {
    error?: common.IServiceError
  }
  export interface ICancelActiveLoansByUserIdRequest {
    authorization?: common.IAuthorization
    userId?: string
    statusReason?: string
    statusCodes?: string[]
    requestedBy?: common.IEntityDescriptor
    forceCancel?: boolean
  }
  export interface ICancelActiveLoansByUserIdResponse {
    error?: common.IServiceError
  }
  export interface IAcceptLoanRequest {
    authorization?: common.IAuthorization
    loanId?: string
    statusReason?: string
    paymentMethod?: loans.LoanPaymentMethod
    disbursementMethod?: loans.LoanPaymentMethod
    updatedBy?: common.IEntityDescriptor
    accountNumber?: string
    routingNumber?: string
  }
  export interface IAcceptLoanResponse {
    error?: common.IServiceError
    loan?: loans.ILoan
    result?: loans.AcceptLoanResult
    disbursementAccountName?: string
  }
  export interface IValidatePaymentInstrumentsRequest {
    loanId?: string
  }
  export interface IValidatePaymentInstrumentsResponse {
    disbursementValid?: true | false
    paymentValid?: true | false
  }
  export interface INotifyOfPrequalificationRequest {
    authorization?: common.IAuthorization
    userId?: string
    amount?: protobuf.IDoubleValue
  }
  export interface IGetPrequalificationRequest {
    authorization?: common.IAuthorization
    userId?: string
    assessmentDate?: protobuf.ITimestamp
  }
  export interface IGetPrequalificationResponse {
    amount?: number
    status?: loans.PrequalificationStatus
  }
  export interface ISettlement {
    id?: string
    loanId?: string
    status?: loans.SettlementStatus
    expirationDate?: string
    dueDate?: string
    paymentIds?: string
    agreementDoc?: string
    agreementDocSigned?: string
    createdAt?: string
    updatedAt?: string
  }
  export interface ICreateSettlementOfferRequest {
    authorization?: common.IAuthorization
    loanId?: string
    expirationDate?: string
    dueDate?: string
    paymentIds?: string
  }
  export interface IGetCurrentSettlementOfferRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetCurrentSettlementOfferResponse {
    settlement?: loans.ISettlement
  }
  export interface IAcceptSettlementOfferRequest {
    authorization?: common.IAuthorization
    settlementId?: string
  }
  export interface IGetLoanDocumentForDownloadRequest {
    authorization?: common.IAuthorization
    loanId?: string
    documentType?: loans.LoanDocumentType
    requestedBy?: common.IEntityDescriptor
  }
  export interface ILoanDocumentDownload {
    type?: loans.LoanDocumentType
    presignedUrl?: string
    expiresAt?: protobuf.ITimestamp
  }
  export interface IGetLoanDocumentForDownloadResponse {
    download?: loans.ILoanDocumentDownload
  }
  export interface IGetLoanDocumentsForDownloadRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetLoanDocumentsForDownloadResponse {
    download?: loans.ILoanDocumentDownload[]
  }
  export interface ILoanDocument {
    documentKey?: string
    type?: loans.LoanDocumentType
    loanStatusAtCreation?: loans.LoanStatus
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    updatedBy?: common.IEntityDescriptor
  }
  export interface IGetLoanDocumentsRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetLoanDocumentsResponse {
    documents?: loans.ILoanDocument[]
  }
  export interface IGetLoanDocumentsHistoryRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetLoanDocumentsHistoryResponse {
    history?: loans.ILoanDocument[]
  }
  export interface ITransformLoanDocumentKeyForDownloadRequest {
    authorization?: common.IAuthorization
    documentKey?: string
    documentType?: loans.LoanDocumentType
  }
  export interface ITransformLoanDocumentKeyForDownloadResponse {
    download?: loans.ILoanDocumentDownload
  }
  export interface ICalculateAmortizationRequest {
    authorization?: common.IAuthorization
    state?: string
    amount?: number
    paymentDates?: protobuf.ITimestamp[]
  }
  export interface IAmortizationFee {
    type?: loans.FeeType
    amount?: number
  }
  export interface ICalculateAmortizationResponse {
    effectiveApr?: number
    totalCost?: number
    fees?: loans.IAmortizationFee[]
    lenderApr?: number
    rate?: number
    loanDurationWeeks?: number
  }
  export interface ILoanBuyBackData {
    loanId?: string
    owner?: string
    buybackDate?: protobuf.ITimestamp
    buybackAmount?: number
    buybackComplete?: string
    match?: string
  }
  export interface IDispatchPaymentProcessEventsRequest {
    authorization?: common.IAuthorization
    loanId?: string
    paymentsIds?: string[]
  }
  export interface IGetLoanOffersRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
  export interface IGetLoanOffersResponse {
    loanTypes?: loans.Loan.IType[]
    loanOfferAvailabilityStatus?: loans.LoanOfferAvailabilityStatus
  }
  export interface IFailPaymentRequest {
    authorization?: common.IAuthorization
    paymentId?: string
    failureCode?: loans.PaymentFailureCode
    failureDescription?: string
    updatedBy?: common.IEntityDescriptor
  }
  export interface IFailPaymentResponse {
    paymentId?: string
    updatedStatus?: loans.PaymentStatus
  }
  export interface ILoanActiveAlert {
    type?: loans.LoanActiveAlertType
    triggeredAt?: protobuf.ITimestamp
    paymentId?: string
    daysUntilChargeOff?: protobuf.IInt32Value
    daysUntilDue?: protobuf.IInt32Value
    priority?: number
    category?: loans.LoanActiveAlertCategory
    rescheduleOnOrBeforeDate?: protobuf.ITimestamp
  }
  export interface IPayment {
    id?: string
    userId?: string
    loanId?: string
    ordinal?: number
    fundingId?: string
    status?: loans.PaymentStatus
    amount?: number
    fees?: number
    interest?: number
    principal?: number
    originalDate?: protobuf.ITimestamp
    rescheduledDate?: protobuf.ITimestamp
    executeEffectiveAt?: protobuf.ITimestamp
    paidoffAt?: protobuf.ITimestamp
    createdAt?: protobuf.ITimestamp
    updatedAt?: protobuf.ITimestamp
    method?: banking.LinkedAccount.ProcessorNetwork
    payingOffNow?: true | false
    failureCount?: number
    retriesExhausted?: true | false
    updatedBy?: common.IEntityDescriptor
    reschedulable?: true | false
    pastDue?: true | false
    alerts?: loans.PaymentAlert[]
  }
  export interface IListPaymentsRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IListPaymentsResponse {
    payments?: loans.IPayment[]
    originalLoanPayments?: loans.IPayment[]
  }
  export interface IGetLastTransactionRequest {
    authorization?: common.IAuthorization
    paymentId?: string
    loanId?: string
    state?: loans.PaymentTransactionState
  }
  export interface IGetLastTransactionResponse {
    transaction?: loans.IPaymentTransaction
  }
  export interface IGetChargeOffInformationRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetChargeOffInformationResponse {
    chargeOffAt?: protobuf.ITimestamp
    daysUntilChargeOff?: number
  }
  export interface IListPaymentDateAvailabilityRequest {
    loanId?: string
  }
  export interface IPaymentDateAvailability {
    date?: protobuf.ITimestamp
    available?: true | false
  }
  export interface IListPaymentDateAvailabilityResponse {
    dates?: loans.IPaymentDateAvailability[]
  }
  export interface IGenerateUploadUrlRequest {
    authorization?: common.IAuthorization
    filename?: string
  }
  export interface IGenerateUploadUrlResponse {
    presignedUrl?: string
  }
  export interface IGetDisbursementRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IDisbursement {
    status?: banking.FundingAuthorization.ProcessorFundingStatus
    method?: loans.LoanPaymentMethod
    updatedAt?: protobuf.ITimestamp
    completedAt?: protobuf.ITimestamp
    accountMask?: string
    accountFriendlyName?: string
    amount?: number
    alerts?: loans.DisbursementAlert[]
  }
  export interface IGetDisbursementResponse {
    disbursement?: loans.IDisbursement
  }
  export interface ICheckPayNowEligibilityRequest {
    loanId?: string
  }
  export interface ICheckPayNowEligibilityResponse {
    result?: loans.PayNowEligibilityResult
  }
  export interface IGetLoanTradeLineRequest {
    authorization?: common.IAuthorization
    loanId?: string
    runPeriodAt?: protobuf.ITimestamp
  }
  export interface IGetLoanTradeLineResponse {
    tradeLine?: loans.ITradeLine
    enabled?: true | false
    validationMessages?: string[]
  }
  export interface IGetActiveLoanAlertsRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetActiveLoanAlertsResponse {
    alerts?: loans.ILoanActiveAlert[]
  }
  export enum LoanPaymentMethod {
    LOAN_PAYMENT_METHOD_UNSPECIFIED = 'LOAN_PAYMENT_METHOD_UNSPECIFIED',
    LOAN_PAYMENT_METHOD_NONE = 'LOAN_PAYMENT_METHOD_NONE',
    LOAN_PAYMENT_METHOD_ACH = 'LOAN_PAYMENT_METHOD_ACH',
    LOAN_PAYMENT_METHOD_INTERCHANGE = 'LOAN_PAYMENT_METHOD_INTERCHANGE',
    LOAN_PAYMENT_METHOD_CHECK = 'LOAN_PAYMENT_METHOD_CHECK',
  }
  export enum LoanStatus {
    LOAN_STATUS_UNSPECIFIED = 'LOAN_STATUS_UNSPECIFIED',
    LOAN_STATUS_PENDING = 'LOAN_STATUS_PENDING',
    LOAN_STATUS_REJECTED = 'LOAN_STATUS_REJECTED',
    LOAN_STATUS_APPROVED = 'LOAN_STATUS_APPROVED',
    LOAN_STATUS_ACTIVE = 'LOAN_STATUS_ACTIVE',
    LOAN_STATUS_PAIDOFF = 'LOAN_STATUS_PAIDOFF',
    LOAN_STATUS_CHARGEDOFF = 'LOAN_STATUS_CHARGEDOFF',
    LOAN_STATUS_EXPIRED = 'LOAN_STATUS_EXPIRED',
    LOAN_STATUS_CANCELLED = 'LOAN_STATUS_CANCELLED',
    LOAN_STATUS_REPLACED = 'LOAN_STATUS_REPLACED',
  }
  export enum LoanSubstatus {
    LOAN_SUBSTATUS_UNSPECIFIED = 'LOAN_SUBSTATUS_UNSPECIFIED',
    LOAN_SUBSTATUS_NONE = 'LOAN_SUBSTATUS_NONE',
    LOAN_SUBSTATUS_PENDING = 'LOAN_SUBSTATUS_PENDING',
    LOAN_SUBSTATUS_PENDING_REVIEW = 'LOAN_SUBSTATUS_PENDING_REVIEW',
    LOAN_SUBSTATUS_PENDING_INFO = 'LOAN_SUBSTATUS_PENDING_INFO',
    LOAN_SUBSTATUS_PENDING_IGNORE = 'LOAN_SUBSTATUS_PENDING_IGNORE',
    LOAN_SUBSTATUS_APPROVED = 'LOAN_SUBSTATUS_APPROVED',
    LOAN_SUBSTATUS_APPROVED_AUTO = 'LOAN_SUBSTATUS_APPROVED_AUTO',
    LOAN_SUBSTATUS_APPROVED_MANUAL = 'LOAN_SUBSTATUS_APPROVED_MANUAL',
    LOAN_SUBSTATUS_REJECTED = 'LOAN_SUBSTATUS_REJECTED',
    LOAN_SUBSTATUS_REJECTED_AUTO = 'LOAN_SUBSTATUS_REJECTED_AUTO',
    LOAN_SUBSTATUS_REJECTED_MANUAL = 'LOAN_SUBSTATUS_REJECTED_MANUAL',
    LOAN_SUBSTATUS_ACTIVE = 'LOAN_SUBSTATUS_ACTIVE',
    LOAN_SUBSTATUS_ACTIVE_LATE = 'LOAN_SUBSTATUS_ACTIVE_LATE',
    LOAN_SUBSTATUS_ACTIVE_DEFAULT = 'LOAN_SUBSTATUS_ACTIVE_DEFAULT',
    LOAN_SUBSTATUS_ACTIVE_CANCEL_REQUESTED = 'LOAN_SUBSTATUS_ACTIVE_CANCEL_REQUESTED',
    LOAN_SUBSTATUS_ACTIVE_CANCEL_ISSUED = 'LOAN_SUBSTATUS_ACTIVE_CANCEL_ISSUED',
    LOAN_SUBSTATUS_ACTIVE_CANCEL_FAILED = 'LOAN_SUBSTATUS_ACTIVE_CANCEL_FAILED',
    LOAN_SUBSTATUS_ACTIVE_CANCELLED = 'LOAN_SUBSTATUS_ACTIVE_CANCELLED',
    LOAN_SUBSTATUS_PAIDOFF = 'LOAN_SUBSTATUS_PAIDOFF',
    LOAN_SUBSTATUS_PAIDOFF_EARLY = 'LOAN_SUBSTATUS_PAIDOFF_EARLY',
    LOAN_SUBSTATUS_PAIDOFF_LATE = 'LOAN_SUBSTATUS_PAIDOFF_LATE',
    LOAN_SUBSTATUS_PAIDOFF_DEFAULT = 'LOAN_SUBSTATUS_PAIDOFF_DEFAULT',
    LOAN_SUBSTATUS_CHARGEDOFF = 'LOAN_SUBSTATUS_CHARGEDOFF',
    LOAN_SUBSTATUS_CHARGEDOFF_COLLECTED_FULL = 'LOAN_SUBSTATUS_CHARGEDOFF_COLLECTED_FULL',
    LOAN_SUBSTATUS_CHARGEDOFF_BANKRUPTCY = 'LOAN_SUBSTATUS_CHARGEDOFF_BANKRUPTCY',
    LOAN_SUBSTATUS_CHARGEDOFF_COLLECTIONS = 'LOAN_SUBSTATUS_CHARGEDOFF_COLLECTIONS',
    LOAN_SUBSTATUS_CHARGEDOFF_SETTLED = 'LOAN_SUBSTATUS_CHARGEDOFF_SETTLED',
    LOAN_SUBSTATUS_EXPIRED = 'LOAN_SUBSTATUS_EXPIRED',
    LOAN_SUBSTATUS_EXPIRED_PENDING = 'LOAN_SUBSTATUS_EXPIRED_PENDING',
    LOAN_SUBSTATUS_EXPIRED_APPROVED = 'LOAN_SUBSTATUS_EXPIRED_APPROVED',
    LOAN_SUBSTATUS_CANCELLED = 'LOAN_SUBSTATUS_CANCELLED',
    LOAN_SUBSTATUS_CANCELLED_ACTIVE = 'LOAN_SUBSTATUS_CANCELLED_ACTIVE',
    LOAN_SUBSTATUS_CANCELLED_APPROVED = 'LOAN_SUBSTATUS_CANCELLED_APPROVED',
  }
  export enum DueDatesChangeReason {
    DUE_DATES_CHANGE_REASON_UNSPECIFIED = 'DUE_DATES_CHANGE_REASON_UNSPECIFIED',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_DEATH_IN_FAMILY = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_DEATH_IN_FAMILY',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_SERIOUS_ILLNESS = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_SERIOUS_ILLNESS',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_NATURAL_DISASTER = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_NATURAL_DISASTER',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_MEDICAL_CARE = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_MEDICAL_CARE',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_MASS_CASUALTY = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_MASS_CASUALTY',
    DUE_DATES_CHANGE_REASON_RELIEF_PLAN_HOME_EMERGENCY = 'DUE_DATES_CHANGE_REASON_RELIEF_PLAN_HOME_EMERGENCY',
    DUE_DATES_CHANGE_REASON_CS_PROCESSING_ERROR = 'DUE_DATES_CHANGE_REASON_CS_PROCESSING_ERROR',
    DUE_DATES_CHANGE_REASON_OTHER = 'DUE_DATES_CHANGE_REASON_OTHER',
  }
  export enum LoanFundRecoveryPartner {
    LOAN_FUND_RECOVERY_PARTNER_UNSPECIFIED = 'LOAN_FUND_RECOVERY_PARTNER_UNSPECIFIED',
    LOAN_FUND_RECOVERY_PARTNER_TRUE_ACCORD = 'LOAN_FUND_RECOVERY_PARTNER_TRUE_ACCORD',
    LOAN_FUND_RECOVERY_PARTNER_INDEBTED = 'LOAN_FUND_RECOVERY_PARTNER_INDEBTED',
  }
  export enum LoanRole {
    LOAN_ROLE_UNSPECIFIED = 'LOAN_ROLE_UNSPECIFIED',
    LOAN_ROLE_STANDARD = 'LOAN_ROLE_STANDARD',
    LOAN_ROLE_CONVERSION_REPLACEMENT = 'LOAN_ROLE_CONVERSION_REPLACEMENT',
    LOAN_ROLE_INITIAL_SINGLE_PAYMENT = 'LOAN_ROLE_INITIAL_SINGLE_PAYMENT',
  }
  export enum LoanAggregateStatus {
    LOAN_AGGREGATE_STATUS_UNSPECIFIED = 'LOAN_AGGREGATE_STATUS_UNSPECIFIED',
    LOAN_AGGREGATE_STATUS_DECISIONING = 'LOAN_AGGREGATE_STATUS_DECISIONING',
    LOAN_AGGREGATE_STATUS_APPROVED = 'LOAN_AGGREGATE_STATUS_APPROVED',
    LOAN_AGGREGATE_STATUS_ACTIVE = 'LOAN_AGGREGATE_STATUS_ACTIVE',
    LOAN_AGGREGATE_STATUS_CLOSED = 'LOAN_AGGREGATE_STATUS_CLOSED',
  }
  export enum PaymentTransactionState {
    PAYMENT_TRANSACTION_STATE_UNSPECIFIED = 'PAYMENT_TRANSACTION_STATE_UNSPECIFIED',
    PAYMENT_TRANSACTION_STATE_FAILED = 'PAYMENT_TRANSACTION_STATE_FAILED',
  }
  export enum SetPaymentDatesResultCode {
    SET_PAYMENT_DATES_RESULT_CODE_UNSPECIFIED = 'SET_PAYMENT_DATES_RESULT_CODE_UNSPECIFIED',
    SET_PAYMENT_DATES_RESULT_CODE_SUCCESSFUL = 'SET_PAYMENT_DATES_RESULT_CODE_SUCCESSFUL',
    SET_PAYMENT_DATES_RESULT_CODE_INFEASIBLE_DATE = 'SET_PAYMENT_DATES_RESULT_CODE_INFEASIBLE_DATE',
  }
  export enum CustomPaymentIntention {
    CUSTOM_PAYMENT_INTENTION_UNSPECIFIED = 'CUSTOM_PAYMENT_INTENTION_UNSPECIFIED',
    CUSTOM_PAYMENT_INTENTION_NEXT_INSTALLMENT = 'CUSTOM_PAYMENT_INTENTION_NEXT_INSTALLMENT',
    CUSTOM_PAYMENT_INTENTION_MISSED_INSTALLMENT = 'CUSTOM_PAYMENT_INTENTION_MISSED_INSTALLMENT',
    CUSTOM_PAYMENT_INTENTION_OVERDUE_BALANCE = 'CUSTOM_PAYMENT_INTENTION_OVERDUE_BALANCE',
    CUSTOM_PAYMENT_INTENTION_FULL_BALANCE = 'CUSTOM_PAYMENT_INTENTION_FULL_BALANCE',
    CUSTOM_PAYMENT_INTENTION_PAYMENT_PROCESSING = 'CUSTOM_PAYMENT_INTENTION_PAYMENT_PROCESSING',
  }
  export enum SubmitCustomPaymentResult {
    MAKE_CUSTOM_PAYMENT_RESULT_UNSPECIFIED = 'MAKE_CUSTOM_PAYMENT_RESULT_UNSPECIFIED',
    MAKE_CUSTOM_PAYMENT_RESULT_CREATED = 'MAKE_CUSTOM_PAYMENT_RESULT_CREATED',
    MAKE_CUSTOM_PAYMENT_RESULT_COULD_NOT_CREATE = 'MAKE_CUSTOM_PAYMENT_RESULT_COULD_NOT_CREATE',
  }
  export enum CancelPaymentResultCode {
    CANCEL_PAYMENT_RESULT_CODE_UNSPECIFIED = 'CANCEL_PAYMENT_RESULT_CODE_UNSPECIFIED',
    CANCEL_PAYMENT_RESULT_CODE_CANCELLED = 'CANCEL_PAYMENT_RESULT_CODE_CANCELLED',
    CANCEL_PAYMENT_RESULT_CODE_SKIPPED_CANCEL = 'CANCEL_PAYMENT_RESULT_CODE_SKIPPED_CANCEL',
    CANCEL_PAYMENT_RESULT_CODE_EXECUTION_DATE_MISSING = 'CANCEL_PAYMENT_RESULT_CODE_EXECUTION_DATE_MISSING',
    CANCEL_PAYMENT_RESULT_CODE_EXECUTION_DATE_TOO_CLOSE = 'CANCEL_PAYMENT_RESULT_CODE_EXECUTION_DATE_TOO_CLOSE',
    CANCEL_PAYMENT_RESULT_CODE_INVALID_PAYMENT_STATUS = 'CANCEL_PAYMENT_RESULT_CODE_INVALID_PAYMENT_STATUS',
    CANCEL_PAYMENT_RESULT_CODE_INVALID_OTHER = 'CANCEL_PAYMENT_RESULT_CODE_INVALID_OTHER',
  }
  export enum CancelPaymentsResultCode {
    CANCEL_PAYMENTS_RESULT_CODE_UNSPECIFIED = 'CANCEL_PAYMENTS_RESULT_CODE_UNSPECIFIED',
    CANCEL_PAYMENTS_RESULT_CODE_COMPLETED = 'CANCEL_PAYMENTS_RESULT_CODE_COMPLETED',
    CANCEL_PAYMENTS_RESULT_CODE_UNCHANGED = 'CANCEL_PAYMENTS_RESULT_CODE_UNCHANGED',
  }
  export enum FailPaymentResultCode {
    FAIL_PAYMENT_RESULT_CODE_UNSPECIFIED = 'FAIL_PAYMENT_RESULT_CODE_UNSPECIFIED',
    FAIL_PAYMENT_RESULT_CODE_FAILED = 'FAIL_PAYMENT_RESULT_CODE_FAILED',
    FAIL_PAYMENT_RESULT_CODE_SKIPPED_FAIL = 'FAIL_PAYMENT_RESULT_CODE_SKIPPED_FAIL',
    FAIL_PAYMENT_RESULT_CODE_INVALID_PAYMENT_STATUS = 'FAIL_PAYMENT_RESULT_CODE_INVALID_PAYMENT_STATUS',
    FAIL_PAYMENT_RESULT_CODE_AUTHORIZATION_UPDATE_FAILURE = 'FAIL_PAYMENT_RESULT_CODE_AUTHORIZATION_UPDATE_FAILURE',
    FAIL_PAYMENT_RESULT_CODE_INVALID_OTHER = 'FAIL_PAYMENT_RESULT_CODE_INVALID_OTHER',
  }
  export enum FailPaymentsResultCode {
    FAIL_PAYMENTS_RESULT_CODE_UNSPECIFIED = 'FAIL_PAYMENTS_RESULT_CODE_UNSPECIFIED',
    FAIL_PAYMENTS_RESULT_CODE_COMPLETED = 'FAIL_PAYMENTS_RESULT_CODE_COMPLETED',
    FAIL_PAYMENTS_RESULT_CODE_PARTIAL = 'FAIL_PAYMENTS_RESULT_CODE_PARTIAL',
    FAIL_PAYMENTS_RESULT_CODE_UNCHANGED = 'FAIL_PAYMENTS_RESULT_CODE_UNCHANGED',
  }
  export enum AcceptLoanResult {
    ACCEPT_LOAN_RESULT_UNSPECIFIED = 'ACCEPT_LOAN_RESULT_UNSPECIFIED',
    ACCEPT_LOAN_RESULT_ACTIVATED = 'ACCEPT_LOAN_RESULT_ACTIVATED',
    ACCEPT_LOAN_RESULT_NO_LINKED_ACCOUNT = 'ACCEPT_LOAN_RESULT_NO_LINKED_ACCOUNT',
    ACCEPT_LOAN_RESULT_NO_PREFERRED_ACCOUNT = 'ACCEPT_LOAN_RESULT_NO_PREFERRED_ACCOUNT',
    ACCEPT_LOAN_RESULT_NO_ACH_INSTRUMENT_CREATED = 'ACCEPT_LOAN_RESULT_NO_ACH_INSTRUMENT_CREATED',
    ACCEPT_LOAN_RESULT_BLOCKED_BY_SDB = 'ACCEPT_LOAN_RESULT_BLOCKED_BY_SDB',
    ACCEPT_LOAN_RESULT_INVALID_ACH_ACCOUNT_NUMBER = 'ACCEPT_LOAN_RESULT_INVALID_ACH_ACCOUNT_NUMBER',
    ACCEPT_LOAN_RESULT_DEBIT_DISBURSEMENT_UNSUPPORTED = 'ACCEPT_LOAN_RESULT_DEBIT_DISBURSEMENT_UNSUPPORTED',
    ACCEPT_LOAN_RESULT_ACH_DISBURSEMENT_UNSUPPORTED = 'ACCEPT_LOAN_RESULT_ACH_DISBURSEMENT_UNSUPPORTED',
    ACCEPT_LOAN_RESULT_OTHER_DISBURSEMENT_UNSUPPORTED = 'ACCEPT_LOAN_RESULT_OTHER_DISBURSEMENT_UNSUPPORTED',
  }
  export enum PrequalificationStatus {
    PREQUALIFICATION_STATUS_UNSPECIFIED = 'PREQUALIFICATION_STATUS_UNSPECIFIED',
    PREQUALIFICATION_STATUS_IN_PROGRESS = 'PREQUALIFICATION_STATUS_IN_PROGRESS',
    PREQUALIFICATION_STATUS_MORE_INFO_NEEDED = 'PREQUALIFICATION_STATUS_MORE_INFO_NEEDED',
    PREQUALIFICATION_STATUS_APPROVED = 'PREQUALIFICATION_STATUS_APPROVED',
    PREQUALIFICATION_STATUS_REJECTED = 'PREQUALIFICATION_STATUS_REJECTED',
  }
  export enum SettlementStatus {
    SETTLEMENT_STATUS_UNSPECIFIED = 'SETTLEMENT_STATUS_UNSPECIFIED',
    SETTLEMENT_STATUS_PENDING = 'SETTLEMENT_STATUS_PENDING',
    SETTLEMENT_STATUS_EXPIRED = 'SETTLEMENT_STATUS_EXPIRED',
    SETTLEMENT_STATUS_IN_PROGRESS = 'SETTLEMENT_STATUS_IN_PROGRESS',
    SETTLEMENT_STATUS_FAILED = 'SETTLEMENT_STATUS_FAILED',
    SETTLEMENT_STATUS_COMPLETED = 'SETTLEMENT_STATUS_COMPLETED',
  }
  export enum LoanDocumentType {
    LOAN_DOCUMENT_TYPE_UNSPECIFIED = 'LOAN_DOCUMENT_TYPE_UNSPECIFIED',
    LOAN_DOCUMENT_TYPE_LOAN_AGREEMENT = 'LOAN_DOCUMENT_TYPE_LOAN_AGREEMENT',
    LOAN_DOCUMENT_TYPE_ARBITRATION = 'LOAN_DOCUMENT_TYPE_ARBITRATION',
    LOAN_DOCUMENT_TYPE_ACH_AGREEMENT = 'LOAN_DOCUMENT_TYPE_ACH_AGREEMENT',
    LOAN_DOCUMENT_TYPE_CREDIT_SERVICE = 'LOAN_DOCUMENT_TYPE_CREDIT_SERVICE',
    LOAN_DOCUMENT_TYPE_CREDIT_SERVICE_DISCLOSURE = 'LOAN_DOCUMENT_TYPE_CREDIT_SERVICE_DISCLOSURE',
    LOAN_DOCUMENT_TYPE_PAY_OFF_CONFIRMATION = 'LOAN_DOCUMENT_TYPE_PAY_OFF_CONFIRMATION',
    LOAN_DOCUMENT_TYPE_INSTALLMENT_LOAN_AGREEMENT = 'LOAN_DOCUMENT_TYPE_INSTALLMENT_LOAN_AGREEMENT',
    LOAN_DOCUMENT_TYPE_STATE_DISCLOSURE = 'LOAN_DOCUMENT_TYPE_STATE_DISCLOSURE',
    LOAN_DOCUMENT_TYPE_COVERED_BORROWER_AGREEMENT = 'LOAN_DOCUMENT_TYPE_COVERED_BORROWER_AGREEMENT',
    LOAN_DOCUMENT_TYPE_EXTENDED_PAYMENT_PLAN = 'LOAN_DOCUMENT_TYPE_EXTENDED_PAYMENT_PLAN',
    LOAN_DOCUMENT_TYPE_SINGLE_PAYMENT_LOAN_AGREEMENT = 'LOAN_DOCUMENT_TYPE_SINGLE_PAYMENT_LOAN_AGREEMENT',
  }
  export enum FeeType {
    FEE_TYPE_UNSPECIFIED = 'FEE_TYPE_UNSPECIFIED',
    FEE_TYPE_ORIGINATION = 'FEE_TYPE_ORIGINATION',
  }
  export enum LoanOfferAvailabilityStatus {
    LOAN_OFFER_AVAILABILITY_STATUS_OFFER_UNSPECIFIED = 'LOAN_OFFER_AVAILABILITY_STATUS_OFFER_UNSPECIFIED',
    LOAN_OFFER_AVAILABILITY_STATUS_OFFER_AVAILABLE = 'LOAN_OFFER_AVAILABILITY_STATUS_OFFER_AVAILABLE',
    LOAN_OFFER_AVAILABILITY_STATUS_USER_NOT_ELIGIBLE = 'LOAN_OFFER_AVAILABILITY_STATUS_USER_NOT_ELIGIBLE',
    LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOANS = 'LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOANS',
    LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_CARDS = 'LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_CARDS',
    LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOCATION = 'LOAN_OFFER_AVAILABILITY_STATUS_INELIGIBLE_LOCATION',
    LOAN_OFFER_AVAILABILITY_STATUS_NO_OFFER_AVAILABLE = 'LOAN_OFFER_AVAILABILITY_STATUS_NO_OFFER_AVAILABLE',
  }
  export enum PaymentStatus {
    PAYMENT_STATUS_UNSPECIFIED = 'PAYMENT_STATUS_UNSPECIFIED',
    PAYMENT_STATUS_PENDING = 'PAYMENT_STATUS_PENDING',
    PAYMENT_STATUS_IN_PROGRESS = 'PAYMENT_STATUS_IN_PROGRESS',
    PAYMENT_STATUS_COMPLETED = 'PAYMENT_STATUS_COMPLETED',
    PAYMENT_STATUS_CANCELLED = 'PAYMENT_STATUS_CANCELLED',
    PAYMENT_STATUS_FAILED = 'PAYMENT_STATUS_FAILED',
    PAYMENT_STATUS_SUSPENDED = 'PAYMENT_STATUS_SUSPENDED',
    PAYMENT_STATUS_REPLACED = 'PAYMENT_STATUS_REPLACED',
  }
  export enum PaymentFailureCode {
    PAYMENT_FAILURE_CODE_UNSPECIFIED = 'PAYMENT_FAILURE_CODE_UNSPECIFIED',
    PAYMENT_FAILURE_CODE_CHARGEBACK = 'PAYMENT_FAILURE_CODE_CHARGEBACK',
    PAYMENT_FAILURE_CODE_OTHER = 'PAYMENT_FAILURE_CODE_OTHER',
  }
  export enum PaymentAlert {
    PAYMENT_ALERT_UNSPECIFIED = 'PAYMENT_ALERT_UNSPECIFIED',
    PAYMENT_ALERT_COMPLETED = 'PAYMENT_ALERT_COMPLETED',
    PAYMENT_ALERT_UPCOMING = 'PAYMENT_ALERT_UPCOMING',
  }
  export enum LoanActiveAlertCategory {
    LOAN_ACTIVE_ALERT_CATEGORY_UNSPECIFIED = 'LOAN_ACTIVE_ALERT_CATEGORY_UNSPECIFIED',
    LOAN_ACTIVE_ALERT_CATEGORY_CRITICAL = 'LOAN_ACTIVE_ALERT_CATEGORY_CRITICAL',
    LOAN_ACTIVE_ALERT_CATEGORY_WARNING = 'LOAN_ACTIVE_ALERT_CATEGORY_WARNING',
    LOAN_ACTIVE_ALERT_CATEGORY_INFO = 'LOAN_ACTIVE_ALERT_CATEGORY_INFO',
    LOAN_ACTIVE_ALERT_CATEGORY_INFO_LIGHT = 'LOAN_ACTIVE_ALERT_CATEGORY_INFO_LIGHT',
  }
  export enum LoanActiveAlertType {
    LOAN_ACTIVE_ALERT_TYPE_UNSPECIFIED = 'LOAN_ACTIVE_ALERT_TYPE_UNSPECIFIED',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_COMPLETED = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_COMPLETED',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_UPCOMING = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_UPCOMING',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_LATE = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_LATE',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_LATE_NOT_FAILED = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_LATE_NOT_FAILED',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_METHOD_ISSUE = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_METHOD_ISSUE',
    LOAN_ACTIVE_ALERT_TYPE_LOAN_SUSPENDED = 'LOAN_ACTIVE_ALERT_TYPE_LOAN_SUSPENDED',
    LOAN_ACTIVE_ALERT_TYPE_LOAN_IN_DEFAULT = 'LOAN_ACTIVE_ALERT_TYPE_LOAN_IN_DEFAULT',
    LOAN_ACTIVE_ALERT_TYPE_LOAN_CHARGEDOFF = 'LOAN_ACTIVE_ALERT_TYPE_LOAN_CHARGEDOFF',
    LOAN_ACTIVE_ALERT_TYPE_DISBURSEMENT_COMPLETED = 'LOAN_ACTIVE_ALERT_TYPE_DISBURSEMENT_COMPLETED',
    LOAN_ACTIVE_ALERT_TYPE_DISBURSEMENT_DELAYED = 'LOAN_ACTIVE_ALERT_TYPE_DISBURSEMENT_DELAYED',
    LOAN_ACTIVE_ALERT_TYPE_PAYMENT_FAILED = 'LOAN_ACTIVE_ALERT_TYPE_PAYMENT_FAILED',
    LOAN_ACTIVE_ALERT_TYPE_FINAL_PAYMENT_PROCESSING = 'LOAN_ACTIVE_ALERT_TYPE_FINAL_PAYMENT_PROCESSING',
  }
  export enum DisbursementAlert {
    DISBURSEMENT_ALERT_UNSPECIFIED = 'DISBURSEMENT_ALERT_UNSPECIFIED',
    DISBURSEMENT_ALERT_COMPLETED = 'DISBURSEMENT_ALERT_COMPLETED',
    DISBURSEMENT_ALERT_DELAYED = 'DISBURSEMENT_ALERT_DELAYED',
  }
  export enum PayNowEligibilityResult {
    PAY_NOW_ELIGIBILITY_RESULT_UNSPECIFIED = 'PAY_NOW_ELIGIBILITY_RESULT_UNSPECIFIED',
    PAY_NOW_ELIGIBILITY_RESULT_ELIGIBLE = 'PAY_NOW_ELIGIBILITY_RESULT_ELIGIBLE',
    PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_LOAN_STATUS = 'PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_LOAN_STATUS',
    PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_IN_PROGRESS = 'PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_IN_PROGRESS',
    PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_NO_DISBURSEMENT = 'PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_NO_DISBURSEMENT',
    PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_FUND_RECOVERY = 'PAY_NOW_ELIGIBILITY_RESULT_INELIGIBLE_FUND_RECOVERY',
  }
}
export namespace donkey {
  export namespace UserInfo {
    export type FlagsEntry = {[key: string]: string}
  }
  export namespace UsersCreateResponse {
    export type UsersEntry = {[key: string]: donkey.IUserInfo}
  }
  export namespace UsersUpdateFlagsRequest {
    export interface IFlagUpdate {
      key?: string
      value?: string
    }
  }
  export namespace GetUserFlagsResponse {
    export type FlagsEntry = {[key: string]: string}
  }
  export interface IApplyStepRequest {
    authorization?: common.IAuthorization
    yamlStepData?: string
    userId?: string
  }
  export interface IApplyStepResponse {
    error?: common.IServiceError
  }
  export interface IUsersDeleteRequest {
    authorization?: common.IAuthorization
    userIds?: string[]
    type?: string
    phoneNumbers?: string[]
  }
  export interface IUsersDeleteResponse {
    error?: common.IServiceError
    userIds?: string[]
  }
  export interface IUsersReadRequest {
    authorization?: common.IAuthorization
    userId?: string[]
  }
  export interface IUsersReadResponse {
    error?: common.IServiceError
    users?: donkey.IUserInfo[]
  }
  export interface IUserInfo {
    userId?: string
    templateId?: string
    email?: string
    phone?: string
    donkeyLogs?: donkey.ILog[]
    createdAt?: string
    flags?: donkey.UserInfo.FlagsEntry
    creatorUserId?: string
    type?: string
  }
  export interface ILog {
    data?: string
    id?: string
    createdAt?: string
    action?: string
    templateId?: string
    step?: number
  }
  export interface IUsersCreateRequest {
    authorization?: common.IAuthorization
    templateId?: string
    name?: string
    config?: string
    email?: string
    phone?: string
    type?: string
  }
  export interface IUsersCreateResponse {
    error?: common.IServiceError
    users?: donkey.UsersCreateResponse.UsersEntry
    user?: donkey.IUserInfo
    nukedUserId?: string
  }
  export interface IUsersUpdateFlagsRequest {
    authorization?: common.IAuthorization
    users?: donkey.IUserInfo[]
    userId?: string
    templateId?: string
    flagUpdates?: donkey.UsersUpdateFlagsRequest.IFlagUpdate[]
  }
  export interface IUsersUpdateFlagsResponse {
    error?: common.IServiceError
    log?: donkey.ILog
    user?: donkey.IUserInfo
  }
  export interface ITemplate {
    name?: string
    config?: string
    id?: string
    createdAt?: string
    updatedAt?: string
    type?: string
    category?: string
  }
  export interface ITemplatesCreateRequest {
    authorization?: common.IAuthorization
    templates?: donkey.ITemplate[]
  }
  export interface ITemplatesCreateResponse {
    error?: common.IServiceError
    templates?: donkey.ITemplate[]
  }
  export interface ITemplatesReadRequest {
    authorization?: common.IAuthorization
    name?: string
    templateId?: string
  }
  export interface ITemplatesReadResponse {
    error?: common.IServiceError
    templates?: donkey.ITemplate[]
  }
  export interface ITemplatesUpdateRequest {
    authorization?: common.IAuthorization
    templates?: donkey.ITemplate[]
  }
  export interface ITemplatesUpdateResponse {
    error?: common.IServiceError
    templates?: donkey.ITemplate[]
  }
  export interface ITemplatesDeleteRequest {
    authorization?: common.IAuthorization
    templateId?: string[]
  }
  export interface ITemplatesDeleteResponse {
    error?: common.IServiceError
    templates?: donkey.ITemplate[]
  }
  export interface ITransactionData {
    id?: string
    title?: string
    description?: string
    updatedBy?: common.IEntityDescriptor
    updateAt?: string
    createdAt?: string
    assetPath?: string
  }
  export interface IBankBalance {
    available?: number
    current?: number
    limit?: number
  }
  export interface ITransactionDataRetrieveRequest {
    authorization?: common.IAuthorization
    id?: string
  }
  export interface ITransactionDataRetrieveResponse {
    error?: common.IServiceError
    record?: donkey.ITransactionData
    dataSet?: string
  }
  export interface ITransactionDataReadRequest {
    authorization?: common.IAuthorization
  }
  export interface ITransactionDataReadResponse {
    error?: common.IServiceError
    records?: donkey.ITransactionData[]
  }
  export interface ITransactionDataCreateRequest {
    authorization?: common.IAuthorization
    record?: donkey.ITransactionData
    dataSet?: string
  }
  export interface ITransactionDataCreateResponse {
    error?: common.IServiceError
    record?: donkey.ITransactionData
  }
  export interface ITransactionDataDeleteRequest {
    authorization?: common.IAuthorization
    id?: string
  }
  export interface ITransactionDataDeleteResponse {
    error?: common.IServiceError
  }
  export interface IGetBankBalanceRequest {
    authorization?: common.IAuthorization
  }
  export interface IGetBankBalanceResponse {
    balance?: donkey.IBankBalance
  }
  export interface IUiBranch {
    id?: string
    name?: string
    platform?: string
    deploymentKey?: string
  }
  export interface IUiBranchesGetRequest {
    authorization?: common.IAuthorization
    count?: number
    platform?: string
  }
  export interface IUiBranchesGetResponse {
    branches?: donkey.IUiBranch[]
  }
  export interface IUserGetFieldRequest {
    authorization?: common.IAuthorization
    userId?: string
    field?: string
    data?: string
  }
  export interface IUserGetFieldResponse {
    error?: common.IServiceError
    value?: string
  }
  export interface IGetUserFlagsRequest {
    authorization?: common.IAuthorization
  }
  export interface IGetUserFlagsResponse {
    flags?: donkey.GetUserFlagsResponse.FlagsEntry
  }
  export interface ICheckUserFlagRequest {
    authorization?: common.IAuthorization
    flag?: donkey.DonkeyFlag
    value?: string
  }
  export interface ICheckUserFlagResponse {
    hasFlag?: true | false
  }
  export interface IIsLoanSetToBeAutoAcceptedRequest {
    authorization?: common.IAuthorization
  }
  export interface IIsLoanSetToBeAutoAcceptedResponse {
    isLoanSetToBeAutoAccepted?: true | false
  }
  export interface IGetLoanOriginationDateRequest {
    authorization?: common.IAuthorization
    loanStatus?: loans.LoanStatus
  }
  export interface IGetLoanOriginationDateResponse {
    originationDate?: protobuf.ITimestamp
  }
  export interface IGetLoanDecisionDateRequest {
    authorization?: common.IAuthorization
    loanId?: string
  }
  export interface IGetLoanDecisionDateResponse {
    decisionDate?: protobuf.ITimestamp
  }
  export interface IGetLoanPaymentStatusRequest {
    authorization?: common.IAuthorization
    loanId?: string
    paymentId?: string
  }
  export interface IGetLoanPaymentStatusResponse {
    status?: donkey.DonkeyLoanPaymentStatus
  }
  export interface IShouldExecuteFundingAuthorizationRequest {
    authorization?: common.IAuthorization
    fundingAuthorization?: banking.IFundingAuthorization
  }
  export interface IShouldExecuteFundingAuthorizationResponse {
    shouldExecuteFundingAuthorization?: boolean
  }
  export enum DonkeyFlag {
    DONKEY_FLAG_UNSPECIFIED = 'DONKEY_FLAG_UNSPECIFIED',
    DONKEY_FLAG_GENERATE_CHECKING_ACCOUNT = 'DONKEY_FLAG_GENERATE_CHECKING_ACCOUNT',
    DONKEY_FLAG_GENERATE_SAVINGS_ACCOUNT = 'DONKEY_FLAG_GENERATE_SAVINGS_ACCOUNT',
    DONKEY_FLAG_PERFORM_MFA = 'DONKEY_FLAG_PERFORM_MFA',
    DONKEY_FLAG_SAME_MASK_EACH_ACCOUNT = 'DONKEY_FLAG_SAME_MASK_EACH_ACCOUNT',
    DONKEY_FLAG_SDB_REGION = 'DONKEY_FLAG_SDB_REGION',
    DONKEY_FLAG_TRANSACTION_DATA = 'DONKEY_FLAG_TRANSACTION_DATA',
    DONKEY_FLAG_TRANSACTION_DATA_FORCE_TO_DB = 'DONKEY_FLAG_TRANSACTION_DATA_FORCE_TO_DB',
    DONKEY_FLAG_UHURA_ENABLED = 'DONKEY_FLAG_UHURA_ENABLED',
    DONKEY_FLAG_UW_VALIDATE_ELIGIBILITY_RESPONSE = 'DONKEY_FLAG_UW_VALIDATE_ELIGIBILITY_RESPONSE',
    DONKEY_FLAG_PII_SIMULATED_VALIDATIONS = 'DONKEY_FLAG_PII_SIMULATED_VALIDATIONS',
    DONKEY_FLAG_SENTILINK_RESULT = 'DONKEY_FLAG_SENTILINK_RESULT',
    DONKEY_FLAG_LOAN_ACCEPTANCE = 'DONKEY_FLAG_LOAN_ACCEPTANCE',
    DONKEY_FLAG_LOAN_PREQUAL = 'DONKEY_FLAG_LOAN_PREQUAL',
    DONKEY_FLAG_FORCE_INVALIDATE_PAYMENT_INSTRUMENTS_OF_NETWORK = 'DONKEY_FLAG_FORCE_INVALIDATE_PAYMENT_INSTRUMENTS_OF_NETWORK',
    DONKEY_FLAG_PAID_OFF_EARLY_PAY = 'DONKEY_FLAG_PAID_OFF_EARLY_PAY',
    DONKEY_FLAG_FORCE_UNLINK_ACCOUNT = 'DONKEY_FLAG_FORCE_UNLINK_ACCOUNT',
    DONKEY_FLAG_DISBURSEMENT_FUNDING_AUTH_STATUS = 'DONKEY_FLAG_DISBURSEMENT_FUNDING_AUTH_STATUS',
    DONKEY_FLAG_PAYMENTS_DATA = 'DONKEY_FLAG_PAYMENTS_DATA',
    DONKEY_FLAG_CARD_PAY_OVER_TIME_DATES = 'DONKEY_FLAG_CARD_PAY_OVER_TIME_DATES',
    DONKEY_FLAG_CARD_PAY_OVER_TIME_PAYMENT_OVERRIDES = 'DONKEY_FLAG_CARD_PAY_OVER_TIME_PAYMENT_OVERRIDES',
    DONKEY_FLAG_BY_PASS_FRAUD_CHECK = 'DONKEY_FLAG_BY_PASS_FRAUD_CHECK',
    DONKEY_FLAG_BY_PASS_DOCS_VERIFICATION = 'DONKEY_FLAG_BY_PASS_DOCS_VERIFICATION',
    DONKEY_FLAG_SDB_NEW_LOAN_REJECTED = 'DONKEY_FLAG_SDB_NEW_LOAN_REJECTED',
    DONKEY_FLAG_FORCE_PROCESSOR_STATUS_AFTER_LOAN_ACTIVE = 'DONKEY_FLAG_FORCE_PROCESSOR_STATUS_AFTER_LOAN_ACTIVE',
    DONKEY_FLAG_BANK_BALANCE_DATA = 'DONKEY_FLAG_BANK_BALANCE_DATA',
    DONKEY_FLAG_LOAN_PAYMENT_METHOD = 'DONKEY_FLAG_LOAN_PAYMENT_METHOD',
  }
  export enum DonkeyLoanPaymentStatus {
    DONKEY_LOAN_PAYMENT_STATUS_UNSPECIFIED = 'DONKEY_LOAN_PAYMENT_STATUS_UNSPECIFIED',
    DONKEY_LOAN_PAYMENT_STATUS_PENDING = 'DONKEY_LOAN_PAYMENT_STATUS_PENDING',
    DONKEY_LOAN_PAYMENT_STATUS_IN_PROGRESS = 'DONKEY_LOAN_PAYMENT_STATUS_IN_PROGRESS',
    DONKEY_LOAN_PAYMENT_STATUS_COMPLETED = 'DONKEY_LOAN_PAYMENT_STATUS_COMPLETED',
    DONKEY_LOAN_PAYMENT_STATUS_COMPLETED_LATE = 'DONKEY_LOAN_PAYMENT_STATUS_COMPLETED_LATE',
    DONKEY_LOAN_PAYMENT_STATUS_FAILED = 'DONKEY_LOAN_PAYMENT_STATUS_FAILED',
    DONKEY_LOAN_PAYMENT_STATUS_FAILED_ONLY_ON_FIRST_EXECUTION = 'DONKEY_LOAN_PAYMENT_STATUS_FAILED_ONLY_ON_FIRST_EXECUTION',
  }
}
export namespace loanWorkflow {
  export namespace LoanWorkflowResumeRequest {
    export interface IURAData {
      id?: string
      userId?: string
      loanId?: string
      actionType?: string
      actionTitle?: string
      actionBody?: string
      actionData?: string
      timeoutBehavior?: loanWorkflow.LoanWorkflowResumeRequest.URATimeoutBehavior
    }
    export enum URATimeoutBehavior {
      NONE = 'NONE',
      ON_URA_TIMEOUT_REVIEW = 'ON_URA_TIMEOUT_REVIEW',
      ON_URA_TIMEOUT_EXPIRE = 'ON_URA_TIMEOUT_EXPIRE',
      ON_URA_TIMEOUT_REJECT = 'ON_URA_TIMEOUT_REJECT',
      ON_URA_TIMEOUT_CONTINUE = 'ON_URA_TIMEOUT_CONTINUE',
    }
  }
  export namespace WorkflowLoanSummary {
    export enum URAStatus {
      NA = 'NA',
      Completed = 'Completed',
      Waiting = 'Waiting',
    }
  }
  export interface ILoanWorkflowTerminateRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    updatedBy?: common.IEntityDescriptor
  }
  export interface ILoanWorkflowTerminateResponse {
    error?: common.IServiceError
  }
  export interface ILoanWorkflowResumeRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    reject?: boolean
    ura?: boolean
    urasToIssue?: loanWorkflow.LoanWorkflowResumeRequest.IURAData[]
    override?: boolean
    snooze?: boolean
    resumedBy?: common.IEntityDescriptor
    loanAmount?: protobuf.IDoubleValue
    manualReviewPaymentDates?: string
  }
  export interface ILoanWorkflowResumeResponse {
    error?: common.IServiceError
  }
  export interface ILoanWorkflowCurrentReviewAssignmentsRequest {
    authorization?: common.IAuthorization
  }
  export interface ILoanWorkflowReviewerAssignment {
    loanId?: string
    userId?: string
    userFullName?: string
    createdAt?: string
    expiresAt?: string
    updatedBy?: common.IEntityDescriptor
  }
  export interface ILoanWorkflowCurrentReviewAssignmentsResponse {
    error?: common.IServiceError
    assignments?: loanWorkflow.ILoanWorkflowReviewerAssignment[]
  }
  export interface ILoanWorkflowStatusRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
    loans?: loans.ILoan[]
  }
  export interface ILoanWorkflowCurrentState {
    loanId?: string
    sfnArn?: string
    sfnTaskToken?: string
    state?: string
    previousState?: string
    createdAt?: string
    updatedAt?: string
    updatedBy?: common.IEntityDescriptor
    inputData?: string
  }
  export interface ILoanWorkflowStatusResponse {
    error?: common.IServiceError
    state?: loanWorkflow.ILoanWorkflowCurrentState[]
  }
  export interface ILoanWorkflowManualRequest {
    authorization?: common.IAuthorization
    statusCode?: string
    userId?: string
  }
  export interface ILoanWorkflowManualResponse {
    error?: common.IServiceError
    state?: loanWorkflow.ILoanWorkflowCurrentState
  }
  export interface IGetWorkflowLoanSummaryRequest {
    authorization?: common.IAuthorization
  }
  export interface IProcessorLinkState {
    processorAccountStatus?: banking.LinkedProcessorAccount.ProcessorAccountStatus
  }
  export interface IWorkflowLoanSummary {
    loanId?: string
    userId?: string
    fullName?: string
    loanStatus?: string
    loanSubStatus?: string
    uraTitle?: string
    uraStatus?: loanWorkflow.WorkflowLoanSummary.URAStatus
    uraType?: string
    uwSteps?: loans.IUnderwritingSteps
    processorLinkState?: loanWorkflow.IProcessorLinkState
    submittedOn?: string
    lastActivity?: loanWorkflow.ILoanWorkflowCurrentState
  }
  export interface IGetWorkflowLoanSummaryResponse {
    error?: common.IServiceError
    summaries?: loanWorkflow.IWorkflowLoanSummary[]
  }
  export interface IUnderwritingDataRequest {
    authorization?: common.IAuthorization
    refresh?: boolean
    userId?: string
    loanId?: string
    noGraphImage?: boolean
    loanTypeId?: string
  }
  export interface IUnderwritingDataResponse {
    error?: common.IServiceError
    underwritingData?: underwriting.IUnderwriting
  }
  export interface IAuditPendingLoansRequest {
    authorization?: common.IAuthorization
    loan?: loans.ILoan
  }
  export interface IAuditPendingLoansResponse {
    error?: common.IServiceError
  }
}
export namespace sdb {
  export interface ISDBAction {
    id?: string
    uid?: string
    amount?: number
    status?: string
    statusDescription?: string
    recordId?: string
    action?: string
    loanAmount?: protobuf.IDoubleValue
  }
  export interface INewLoanRequest {
    loanId?: string
    authorization?: common.IAuthorization
  }
  export interface INewLoanResponse {
    result?: sdb.SDBResult
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBActions?: sdb.ISDBAction[]
  }
  export interface ICloseLoanRequest {
    loanId?: string
    eraseRecord?: true | false
    authorization?: common.IAuthorization
  }
  export interface ICloseLoanResponse {
    result?: sdb.SDBResult
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBActions?: sdb.ISDBAction[]
  }
  export interface IDefaultLoanRequest {
    loanId?: string
    isDefault?: true | false
    authorization?: common.IAuthorization
  }
  export interface IDefaultLoanResponse {
    result?: sdb.SDBResult
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBActions?: sdb.ISDBAction[]
  }
  export interface IReopenLoanRequest {
    loanId?: string
    isReopen?: true | false
    isReturnedCheck?: true | false
    reopenFee?: number
    reopenReason?: string
    reopenDescription?: string
    reopenNotes?: string
    authorization?: common.IAuthorization
  }
  export interface IReopenLoanResponse {
    result?: sdb.SDBResult
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBActions?: sdb.ISDBAction[]
  }
  export interface IQueryRecordsRequest {
    loanId?: string
    authorization?: common.IAuthorization
  }
  export interface IQueryRecordsResponse {
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBActions?: sdb.ISDBAction[]
  }
  export interface IGetEligibilityRequest {
    loanId?: string
    bypassCache?: true | false
    userId?: string
    authorization?: common.IAuthorization
  }
  export interface IGetEligibilityResponse {
    result?: sdb.SDBResult
    error?: common.IServiceError
    code?: sdb.SDBResponseCode
    SDBAction?: sdb.ISDBAction
  }
  export enum SDBResponseCode {
    Ok = 'Ok',
    NoProvider = 'NoProvider',
    NoUserOrLoanRecord = 'NoUserOrLoanRecord',
    Ok2 = 'Ok2',
  }
  export enum SDBResult {
    SDBCheckEligible = 'SDBCheckEligible',
    SDBCheckOverAmount = 'SDBCheckOverAmount',
    SDBCheckInstallment = 'SDBCheckInstallment',
    SDBCheckOverCount = 'SDBCheckOverCount',
    SDBCheckInDefault = 'SDBCheckInDefault',
    SDBError = 'SDBError',
    SDBSuccess = 'SDBSuccess',
    SDBCheckEligible2 = 'SDBCheckEligible2',
    SDBCheckOfacHit = 'SDBCheckOfacHit',
  }
}
export namespace sherlock {
  export interface IUserSearchRequest {
    query?: string
    authorization?: common.IAuthorization
  }
  export interface IUserSearchResponse {
    userId?: string[]
  }
  export interface ILoanSearchRequest {
    query?: string
    authorization?: common.IAuthorization
  }
  export interface ILoanSearchResponse {
    loanId?: string[]
  }
  export interface IReindexEntitiesRequest {
    authorization?: common.IAuthorization
    entityType?: sherlock.EntityType
  }
  export enum EntityType {
    ENTITY_TYPE_UNSPECIFIED = 'ENTITY_TYPE_UNSPECIFIED',
    ENTITY_TYPE_USER = 'ENTITY_TYPE_USER',
    ENTITY_TYPE_LOAN = 'ENTITY_TYPE_LOAN',
    ENTITY_TYPE_CARD = 'ENTITY_TYPE_CARD',
  }
}
export namespace ura {
  export namespace QueryRequest {
    export enum QueryType {
      NA = 'NA',
      ByUserInPending = 'ByUserInPending',
      ByLoanInPending = 'ByLoanInPending',
      ByUserAll = 'ByUserAll',
      ByLoanAll = 'ByLoanAll',
      CountByUraType = 'CountByUraType',
      ByIdList = 'ByIdList',
    }
  }
  export interface IUserRequestedAction {
    loanId?: string
    userId?: string
    status?: string
    noticeCleared?: true | false
    noticeDate?: string
    id?: string
    actionType?: string
    actionTitle?: string
    actionBody?: string
    actionData?: string
    userNoteId?: string
    resourceId?: string
    updatedAt?: string
    createdAt?: string
    ableToCompleteAt?: protobuf.ITimestamp
    forEntity?: common.IEntityDescriptor
    createdBy?: common.IEntityDescriptor
    updatedBy?: common.IEntityDescriptor
  }
  export interface IQueryRequest {
    userId?: string
    type?: ura.QueryRequest.QueryType
    loanId?: string
    idList?: string[]
    authorization?: common.IAuthorization
    updatedVia?: common.IEntityDescriptor
    uraActionType?: string
    uraCreatedAtMin?: string
  }
  export interface IQueryResponse {
    actions?: ura.IUserRequestedAction[]
    count?: number
    error?: common.IServiceError
  }
  export interface ISetStatusRequest {
    id?: string
    newStatus?: string
    resourceId?: string
    authorization?: common.IAuthorization
    updatedBy?: common.IEntityDescriptor
  }
  export interface ISetStatusResponse {
    error?: common.IServiceError
    action?: ura.IUserRequestedAction
  }
  export interface IExpireUsersRequestedActionsForUserRequest {
    authorization?: common.IAuthorization
    userId?: string
  }
}
