import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'

const NoPreferredBankAccountIdErrorPage: FC = () => {
  const {t} = useTranslation('RelinkPreferredBankAccount')

  return (
    <ErrorTemplate
      errorTitle={t('PleaseContactUs')}
      body={t('WeCouldNotVerifyYourBankInformation')}
      testID="NoPreferredBankAccountId-Error-Page"
      getContactUsComponent={({onContactUs: handleOnContactUs}): JSX.Element => {
        return (
          <PFText
            variant="p"
            color="link"
            onPress={handleOnContactUs}
            testID={'NoPreferredBankAccountId-Error-ContactUsLink'}
          >
            {t('ContactUsHere')}
          </PFText>
        )
      }}
    />
  )
}

export {NoPreferredBankAccountIdErrorPage}
