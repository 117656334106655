import {Consumer} from 'src/cassandra'
import {getCardOfferInfo} from 'src/products/general/ProductHub/ProductHub.utils'

type UseGetCardOffersType = {
  isLoading: boolean
  cardOffers: Consumer.types.CardAccountOffer[]
  defaultCardOffer: Consumer.types.CardAccountOffer | undefined
}

export const useGetCardOffers = (): UseGetCardOffersType => {
  const {selectedData, loading: isLoading} = Consumer.hooks.useProductsQuery({
    fetchPolicy: 'cache-first',
  })

  const cardOfferInfo = getCardOfferInfo(selectedData?.eligible?.all ?? [])
  return {
    isLoading,
    cardOffers: cardOfferInfo?.offers ?? [],
    defaultCardOffer: (cardOfferInfo?.offers ?? []).length ? cardOfferInfo?.offers[0] : undefined,
  }
}
