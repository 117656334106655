import {GraphQLError} from 'graphql'

export enum ErrorCodes {
  AccessDenied = 'MGE0001',
  AuthenticationFailed = 'MGE0002',
  MissingAuthorizationHeader = 'MGE0003',
  PreferredLinkedAccountNotFound = 'MG0004',
  UserNotFound = 'MGE0005',
  BadRequest = 'MGE0006',
  RateLimit = 'MGE0007',
  NotFound = 'MGE0008',
  FieldValidation = 'MGE0009',
  PaymentDuplicateDetected = 'MGE0010',
  PaymentAmountInsufficient = 'MGE0011',
  AchMaskMatchFail = 'MGE0012',

  OnboardingModuleIncomplete = 'MGE1001',

  ServerError = 'MGE6714',
}

export const isGraphQLError = (err: any): boolean => {
  if (Array.isArray(err)) {
    return err.some((e) => e instanceof GraphQLError)
  }

  return err instanceof GraphQLError
}

export const asGraphQLErrors = (err: any): GraphQLError[] => {
  if (isGraphQLError(err)) {
    if (Array.isArray(err)) {
      return err as GraphQLError[]
    }

    return [err as GraphQLError]
  }

  return []
}

export const containsError = (err: any, code: ErrorCodes) => {
  if (!err) {
    return false
  }

  if (Array.isArray(err)) {
    return (err as GraphQLError[]).some((e) => e.extensions?.errorCode === code)
  }

  return err.extensions?.errorCode === code
}
