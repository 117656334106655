import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AppState, AppStateStatus, StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'

const AppInactiveContentHider: React.FC<React.PropsWithChildren> = (props) => {
  const {children} = props

  const [hideContent, setHideContent] = useState(AppState.currentState !== 'active')

  const handleAppStateChange = useCallback((nextAppState: AppStateStatus) => {
    setHideContent(nextAppState !== 'active')
  }, [])

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppStateChange)
    return () => subscription.remove()
  }, [handleAppStateChange])

  const contentOverlay = useMemo(() => {
    return <Box fill position="absolute" background="white" boxStyle={style.contentOverlay} />
  }, [])

  return (
    <>
      {hideContent ? contentOverlay : null}
      {children}
    </>
  )
}

export default AppInactiveContentHider

const style = StyleSheet.create({
  contentOverlay: {
    zIndex: 10000,
  },
})
