import React from 'react'

import {formatMask} from 'src/lib/user/utils'
import {
  getPaymentMethodAccountName,
  getPaymentMethodMask,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {
  CardActionListItem,
  CardActionListItemProps,
} from 'src/products/card/components/molecules/CardActionListItem'
import {LinkedAccount, PaymentMethodType} from 'src/cassandra'

type PaymentMethodLinkBase<T> = Partial<CardActionListItemProps> & {
  paymentMethod: T
}

const PaymentMethodLinkBase = <T extends PaymentMethodType | LinkedAccount>({
  paymentMethod,
  ...props
}: PaymentMethodLinkBase<T>): JSX.Element => {
  const accountName = getPaymentMethodAccountName(paymentMethod) ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''

  return (
    <CardActionListItem
      isFirstChild
      subTitle={formatMask(accountMask, false)}
      title={accountName}
      {...props}
    />
  )
}

export {PaymentMethodLinkBase}
