import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

import {
  AcceptLoanAndAutoPayAgreementsBaseTemplate,
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'

export type AcceptLoanAndAutoPayAgreementsExtendedPlanTemplateProps = Omit<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'title' | 'description' | 'showLoanAgreementCheckbox'
>

/**
 * Page template for a screen for LA and WA users to accept the loan agreement and
 * the autopay agreement after converting their loan to an extended installment plan loan.
 */
export const AcceptLoanAndAutoPayAgreementsExtendedPlanTemplate: FC<
  AcceptLoanAndAutoPayAgreementsExtendedPlanTemplateProps
> = (props) => {
  const {onOpenAutoPayAgreement: handleOnOpenAutoPayAgreement} = props
  const {t} = useTranslation(['LoanApprovedExtendedPlan', 'LoanApproved', 'Common'])

  return (
    <AcceptLoanAndAutoPayAgreementsBaseTemplate
      {...props}
      testID="LoanAndAutoPayAgreements-ExtendedPlan"
      showLoanAgreementCheckbox={true}
      isExtendedInstallmentPlan={true}
      // states the support the extended installment plan (WA and LA) have a specific description copy
      description={
        <PFText variant="p">{t('LoanApprovedExtendedPlan:WeWillUpdateScheduledPayments')}</PFText>
      }
      autoPayAgreementOptions={{
        acceptOption: (
          <PFText>
            <Trans i18nKey={'Common:AcceptEPA'} t={t}>
              I accept the
              <SvgLink
                onPress={handleOnOpenAutoPayAgreement}
                linkText={'Electronic Payment Authorization'}
                linkType={'inline'}
              />
            </Trans>
          </PFText>
        ),
        doNotAcceptOption: t('LoanApproved:IDoNotAccept'),
      }}
    />
  )
}
