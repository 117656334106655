import React, {FC, ReactNode} from 'react'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {darkGrayText, userInfoFieldEditColor} from 'src/designSystem/semanticColors'
import {StackedButtonsAction} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIconButton} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

export type UserInfoFieldProps = {
  label: string
  value: string
  isEditable?: boolean
  showInfoIcon?: boolean
  onPress?: () => void
  valueExtra?: ReactNode
  action?: StackedButtonsAction
}

const UserInfoField: FC<UserInfoFieldProps> = (props) => {
  const {
    label,
    value,
    isEditable = false,
    showInfoIcon = false,
    onPress: handleOnPress,
    valueExtra,
    action,
  } = props
  const showIcon = showInfoIcon || isEditable

  const isEditableIcon = (
    <Box alignSelf={'end'}>
      {handleOnPress ? (
        <SvgIconButton
          name={isEditable ? 'edit' : 'info'}
          colorVariant={'info'}
          size={'small'}
          onPress={handleOnPress}
          accessibilityLabel={label + '_editor'}
        />
      ) : (
        <SvgIcon name={'edit'} colorVariant={'custom'} customColor={userInfoFieldEditColor} />
      )}
    </Box>
  )

  return (
    <Box width={'100%'}>
      <Box marginBottom={'little'}>
        <PFText variant={'label_sm'} color={darkGrayText}>
          {label}
        </PFText>
      </Box>
      <Box direction={'row'} width={'100%'} align={'center'}>
        <Box flex={9} direction={'row'}>
          <PFText variant={'p'}>{value}</PFText>
          {valueExtra}
        </Box>
        <Box align={'center'}>{showIcon ? isEditableIcon : undefined}</Box>
      </Box>

      {action ? (
        <Box marginTop={'small'}>
          <Button
            mode="secondary"
            disabled={action.disabled}
            loading={action.loading}
            size="medium"
            onPress={action.onPress}
            testID={action.testID}
            width="100%"
          >
            {action.text}
          </Button>
        </Box>
      ) : undefined}
    </Box>
  )
}

export default UserInfoField
