import {chaosReduxStoreCache} from './src/root-redux-store-cache'

// redux reducer and state type
export * from './src/reducer'

// graphql chaos
export * from './src/graphql-chaos/state/selectors'
export * as graphqlChaosActions from './src/graphql-chaos/state/actions'
export * from './src/graphql-chaos/ChaosModeApolloLink'
export * from './src/graphql-chaos/chaos-mode-apollo-http-link-intercept'

// react component chaos
export * from './src/component-chaos/hooks/useChaosModeRenderFailure'
export * from './src/component-chaos/state/selectors'
export * as componentChaosActions from './src/component-chaos/state/actions'
export {ChaosEnabled} from './src/component-chaos/components/ChaosEnabled'
export type {GraphqlChaosResponseMock} from './src/graphql-chaos/state/slice'

/**
 * Initialize @possible/chaos to enable simulating failure scenarios. This should not be initialized in production.
 * @param params
 */
export const initializeChaos = (
  params: Pick<Parameters<typeof chaosReduxStoreCache.setStore>['0'], 'store' | 'chaosSliceKey'>,
) => {
  const {store, chaosSliceKey} = params
  chaosReduxStoreCache.setStore({
    store,
    chaosSliceKey,
  })
}
