import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack'
import React from 'react'

import {genericPageBackground} from 'src/designSystem/semanticColors'
import {Color} from 'src/designSystem/types'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import MainStackHeader from 'src/nav/MainStackHeader'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import ModalStackHeader from 'src/nav/ModalStackHeader'

export const globalCardStyle = {
  flex: 1,
  backgroundColor: 'transparent',
}

export const headerButtonsWhiteColor = {
  headerStyle: {
    headerButtonsColor: 'white',
  },
}

export const MainStackNav = createStackNavigator<MainStackParamList>()
export const ModalStackNav = createStackNavigator<MainStackParamList>()

const mainStackHeader = (props) => {
  const headerButtonsColor: Color | undefined =
    props?.options?.headerStyle?.headerButtonsColor ?? undefined
  const closeButton: boolean = props?.options?.headerStyle?.closeButton ?? false
  return (
    <MainStackHeader {...props} headerButtonsColor={headerButtonsColor} closeButton={closeButton} />
  )
}

export const MainStackBaseOptions: StackNavigationOptions = {
  title: 'A Payday Alternative Loan | Possible Finance',
  headerShown: true,
  header: mainStackHeader,
  headerStyle: {height: pfHeaderHeight, backgroundColor: 'transparent'},
  headerMode: 'screen',
  headerTransparent: true,
  cardStyle: {...globalCardStyle, backgroundColor: genericPageBackground},
}

const modalStackHeader = (props) => <ModalStackHeader {...props} />

export const ModalStackScreenOptions = ({route}): StackNavigationOptions => {
  return {
    title: 'A Payday Alternative Loan | Possible Finance',
    headerStyle: {height: pfHeaderHeight, backgroundColor: 'transparent'},
    headerTransparent: true,
    header: modalStackHeader,
    cardStyle: {
      backgroundColor: 'transparent',
    },
    detachPreviousScreen: false,
    presentation: 'transparentModal',
  }
}
