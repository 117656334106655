import React, {useState} from 'react'
import {ApolloError} from '@apollo/client'
import moment from 'moment'

import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'
import {getLoanDisbursementAvailableDate} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {MoneyOnTheWayTemplate} from 'src/products/loans/MoneyOnTheWay/MoneyOnTheWayTemplate'
import {MoneyOnTheWayDocument} from 'src/products/loans/MoneyOnTheWay/MoneyOnTheWay.gqls'

export type MoneyOnTheWayGQLContainerProps = {
  onContinue: () => void
}

const MoneyOnTheWayGQLContainer: React.FC<MoneyOnTheWayGQLContainerProps> = (props) => {
  const {onContinue: handleOnContinue} = props
  const [disbursementSettlementDate, setDisbursementSettlementDate] =
    useState<DisbursementSettlementDateType>()

  const {
    selectedData,
    error: queryError,
    loading: isQueryLoading,
  } = useCassandraQuery(
    MoneyOnTheWayDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'MoneyOnTheWay query error',
        )
      },
      onCompleted: (data) => {
        const loanId = data.me.loans.latestActionableLoan?.id
        if (!loanId) {
          return
        }
        try {
          void getLoanDisbursementAvailableDate({
            loanId: loanId,
            activeDate: moment().utc(),
          })
            .then((response) => {
              setDisbursementSettlementDate(response)
            })
            .catch((e) => {
              logOfferActivationErrorAndShowException(e)
            })
        } catch (e) {
          logOfferActivationErrorAndShowException(e)
        }
      },
    },
    (data) => {
      let amountBorrowed: string | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ActiveLoanAggregateStatus'
      ) {
        amountBorrowed = data.me.loans.latestActionableLoan.aggregateStatus.amountBorrowed
      }
      return {
        amountBorrowed: amountBorrowed,
        loanId: data.me.loans.latestActionableLoan?.id,
        paymentMethod:
          data.me.loans.latestActionableLoan?.paymentMethods.paymentDefault?.__typename,
      }
    },
  )

  const isAch = selectedData?.paymentMethod === 'AchPaymentMethod'
  const disbursementSettlementDateStr = moment
    .utc(disbursementSettlementDate?.adjustedSettlementDatetime)
    .local()
    .format('dddd, MMMM Do')

  // these default values are what was requested for the LoanAcceptedPixel component
  const loanId = selectedData?.loanId ?? '00000000-0000-0000-0000-000000000000'
  const amountBorrowed = selectedData?.amountBorrowed ?? '0'

  return (
    <BaseTemplate
      isError={!!queryError}
      isLoading={isQueryLoading}
      testID="MoneyOnTheWay"
      onErrorBoundary={(e): void => {
        logOfferActivationError(e, 'MoneyOnTheWay render failed')
      }}
    >
      <MoneyOnTheWayTemplate
        amountBorrowed={amountBorrowed}
        disbursementSettlementDate={disbursementSettlementDateStr}
        loanId={loanId}
        isAch={isAch}
        onContinue={handleOnContinue}
      />
    </BaseTemplate>
  )
}

export {MoneyOnTheWayGQLContainer}
