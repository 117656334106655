import React, {FC} from 'react'
import {
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  KeyboardAvoidingViewProps,
  ScrollViewProps,
  ViewStyle,
} from 'react-native'

import {genericPageBackground} from 'src/designSystem/semanticColors'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {isDeviceIOS} from 'src/lib/utils/platform'
import {Color} from 'src/designSystem/types'

export type ScrollViewBaseType = KeyboardAvoidingViewProps & ScrollViewProps

const getKeyboardViewStyle = (backgroundColor: Color): ViewStyle => {
  return {
    flex: 1,
    backgroundColor: backgroundColor,
  }
}

const ScrollViewBase: FC<ScrollViewBaseType> = (props) => {
  const {keyboardShouldPersistTaps, onContentSizeChange, onScroll, children, style} = props

  return (
    <KeyboardAvoidingView
      style={getKeyboardViewStyle(genericPageBackground)}
      behavior={isDeviceIOS() ? 'padding' : 'height'}
      keyboardVerticalOffset={pfHeaderHeight}
    >
      <ScrollView
        centerContent={true}
        onScroll={(data): void => onScroll?.(data)}
        onContentSizeChange={(width, height): void | undefined =>
          onContentSizeChange?.(width, height)
        }
        contentContainerStyle={[styles.view, style]}
        scrollEventThrottle={100}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      >
        {children}
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

export default ScrollViewBase

const styles = StyleSheet.create({
  view: {
    flexGrow: 1,
  },
})
