import React, {FC} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {possibleWhyRejected} from 'src/navigation/WebLinks'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {LoanProcessingRejectedGQLContainer} from 'src/products/loans/LoanProcessing/LoanProcessingRejected/LoanProcessingRejectedGQLContainer'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, 'ProductHub'>
}

export const LoanProcessingRejectedWorkflowContainer: FC<Props> = ({navigation}) => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_not_approved_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const handleOnReadMore = (): void => {
    PushWebPageOnStack(navigation, {uri: possibleWhyRejected})
  }

  const handleOnComplete = (): void => navigation.reset({index: 0, routes: [{name: 'Dashboard'}]})

  return (
    <LoanProcessingRejectedGQLContainer
      onReadMore={handleOnReadMore}
      onComplete={handleOnComplete}
    />
  )
}
