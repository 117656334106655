import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {formatMask} from 'src/lib/user/utils'
import {
  getPaymentMethodAccount,
  getPaymentMethodMask,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {DebitCardPaymentMethod} from 'src/cassandra'
import {
  PaymentMethodFieldBase,
  PaymentMethodFieldProps,
} from 'src/products/card/PaymentMethods/PaymentMethodField/PaymentMethodFieldBase'

type Props = PaymentMethodFieldProps & {
  paymentMethod: DebitCardPaymentMethod
}

export const DebitCardPaymentMethodField: FC<Props> = (props) => {
  const {t} = useTranslation('PayNow')
  const {paymentMethod, ...otherProps} = props
  const accountName = getPaymentMethodAccount(paymentMethod)?.name ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''
  const mask = formatMask(accountMask, false)

  let title = t('DebitCard')
  const subTitle = formatMask(mask, false)

  // Debit cards may not be associated with an account
  if (accountName) {
    title = accountName
  }

  return (
    <PaymentMethodFieldBase
      paymentAccount={paymentMethod}
      title={title}
      subTitle={subTitle}
      {...otherProps}
    />
  )
}
