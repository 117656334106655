import React from 'react'
import {StyleSheet, View} from 'react-native'

import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {headerIconLeftPadding, pfHeaderHeight} from 'src/nav/Header/HeaderConstants'

const HeaderBackImage = (): JSX.Element => (
  <View style={styles.iconPosition}>
    <SvgIcon name={'arrowLeft'} colorVariant={'default'} size={'large'} />
  </View>
)

const styles = StyleSheet.create({
  iconPosition: {
    height: pfHeaderHeight,
    justifyContent: 'flex-end',
    // Remove `paddingBotton` in a future navigation refactor
    paddingBottom: 3, // Aligns icon with current `MainStackHeader` positioning
    paddingHorizontal: headerIconLeftPadding, // Horizontal padding increase tappable area
  },
})

export {HeaderBackImage}
