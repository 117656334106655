import React, {useCallback} from 'react'
import {StackScreenProps} from '@react-navigation/stack/lib/typescript/src/types'
import {Trans, useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LandingScreenTemplate} from 'src/products/MCU/Landing/LandingScreenTemplate'
import {isTinyScreen} from 'src/products/MCU/Landing/LandingScreen.utils'
import {LandingScreenContent} from 'src/products/MCU/Landing/LandingScreen.types'
import {FixedSizeArray} from 'src/lib/utils/typesUtil'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'

type Props = StackScreenProps<MainStackParamList, 'Landing'>

const LoanLandingScreenContainer: React.FC<Props> = (props): JSX.Element => {
  const {navigation} = props
  const {t} = useTranslation('OpeningLandingPage')

  useFocusEffect(
    useCallback(() => {
      TrackAppEvent(AppEvents.Name.loan_landing_screen_viewed, AppEvents.Category.Login)
    }, []),
  )

  const onHandleNavigateToCreateAccount = (): void => {
    TrackAppEvent(AppEvents.Name.loan_landing_check_eligibility_CTA, AppEvents.Category.Login)
    navigation.navigate('Registration')
  }
  const onHandleNavigateToLogin = (): void => {
    TrackAppEvent(AppEvents.Name.loan_landing_click_log_in, AppEvents.Category.Login)
    navigation.navigate('Login')
  }

  const content: FixedSizeArray<LandingScreenContent, 4> = [
    {
      title: t('BorrowUpTo'),
      icon: {name: 'money', colorVariant: 'active'},
      cta: t('TapToLearnMore'),
      bottomSheetTitle: t('BorrowUpToBottomSheetTitle'),
      bottomSheetDescription: [
        t('WhenApprovedGetCashInMinutes'),
        t('MaximumLoanAmountsVaryByState'),
      ],
      bottomSheetCTA: t('GetStarted'),
      tileTestID: 'Borrow-Up-To-Tile',
      bottomSheetCTATestID: 'Borrow-Up-To-Bottom-Sheet-CTA',
      handleBottomSheetCTA: onHandleNavigateToCreateAccount,
      handleAnalyticBottomSheetViewed: () =>
        TrackAppEvent(AppEvents.Name.loan_landing_learn_more_borrow, AppEvents.Category.Login),
    },
    {
      title: t('NoLateFeesEver'),
      icon: {name: 'scale', colorVariant: 'active'},
      cta: null,
      bottomSheetTitle: t('NoLateFeesEverBottomSheetTitle'),
      bottomSheetDescription: [t('NoLateFeesEverDescription')],
      bottomSheetCTA: t('GetStarted'),
      tileTestID: 'No-Late-Fees-Ever-Tile',
      bottomSheetCTATestID: 'No-Late-Fees-Ever-Bottom-Sheet-CTA',
      handleBottomSheetCTA: onHandleNavigateToCreateAccount,
      handleAnalyticBottomSheetViewed: () =>
        TrackAppEvent(AppEvents.Name.loan_landing_learn_more_fees, AppEvents.Category.Login),
    },
    {
      title: t('PayOverTimeInInstallments'),
      icon: {name: 'clock', colorVariant: 'active'},
      cta: null,
      bottomSheetTitle: t('PayOverTimeInInstallmentsBottomSheetTitle'),
      bottomSheetDescription: [t('MakePaymentsEasilyInInstallments'), t('RescheduleUpTo29Days')],
      bottomSheetCTA: t('GetStarted'),
      tileTestID: 'Pay-Over-Time-In-Installments-Tile',
      bottomSheetCTATestID: 'Pay-Over-Time-In-Installments-Bottom-Sheet-CTA',
      handleBottomSheetCTA: onHandleNavigateToCreateAccount,
      handleAnalyticBottomSheetViewed: () =>
        TrackAppEvent(AppEvents.Name.loan_landing_learn_more_POT, AppEvents.Category.Login),
    },
    {
      title: t('MinimizeDebtAndBuildCreditHistory'),
      icon: {name: 'checkWithCircle', colorVariant: 'active'},
      cta: null,
      bottomSheetTitle: t('MinimizeDebtAndBuildCreditHistoryBottomSheetTitle'),
      bottomSheetDescription: [t('MinimizeDebtAndBuildCreditHistoryDescription')],
      bottomSheetCTA: t('GetStarted'),
      tileTestID: 'Minimize-Debt-And-Build-Credit-History-Tile',
      bottomSheetCTATestID: 'Minimize-Debt-And-Build-Credit-History-Bottom-Sheet-CTA',
      handleBottomSheetCTA: onHandleNavigateToCreateAccount,
      handleAnalyticBottomSheetViewed: () =>
        TrackAppEvent(AppEvents.Name.loan_landing_learn_more_debt, AppEvents.Category.Login),
    },
  ]

  const title = (
    <Box marginBottom={'medium'} justify="center" align="center" width={250}>
      <PFText
        variant={!isTinyScreen ? 'h2_regular' : 'h3_regular'}
        textAlign="left"
        color={NamedColors.WHITE}
        testID="Get-The-Funds-You-Need-Fast"
      >
        <Trans
          t={t}
          i18nKey={'GetTheFundsYouNeedFast'}
          components={{
            lime: (
              <PFText
                variant={!isTinyScreen ? 'h2_regular' : 'h3_regular'}
                color={NamedColors.LIME}
              />
            ),
          }}
        />
      </PFText>
    </Box>
  )

  return (
    <LandingScreenTemplate
      title={title}
      tilesContent={content}
      handleNavigateToCreateAccount={onHandleNavigateToCreateAccount}
      handleNavigateToLogin={onHandleNavigateToLogin}
    />
  )
}

export {LoanLandingScreenContainer}
