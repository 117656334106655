export {default as ArrowDown} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowDown.svg'
export {default as ArrowDownFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowDownFilled.svg'
export {default as ArrowLeft} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowLeft.svg'
export {default as ArrowLeftFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowLeftFilled.svg'
export {default as ArrowRight} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowRight.svg'
export {default as ArrowRightFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowRightFilled.svg'
export {default as ArrowUp} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowUp.svg'
export {default as ArrowUpFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ArrowUpFilled.svg'
export {default as Bank} from 'src/designSystem/components/atoms/SvgIcon/assets/Bank.svg'
export {default as BankFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/BankFilled.svg'
export {default as Calendar} from 'src/designSystem/components/atoms/SvgIcon/assets/Calendar.svg'
export {default as CalendarFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CalendarFilled.svg'
export {default as Card} from 'src/designSystem/components/atoms/SvgIcon/assets/Card.svg'
export {default as CardFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CardFilled.svg'
export {default as Chart} from 'src/designSystem/components/atoms/SvgIcon/assets/Chart.svg'
export {default as ChartFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChartFilled.svg'
export {default as Chat} from 'src/designSystem/components/atoms/SvgIcon/assets/Chat.svg'
export {default as ChatFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChatFilled.svg'
export {default as Check} from 'src/designSystem/components/atoms/SvgIcon/assets/Check.svg'
export {default as CheckFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CheckFilled.svg'
export {default as CheckWithCircle} from 'src/designSystem/components/atoms/SvgIcon/assets/CheckWithCircle.svg'
export {default as CheckWithCircleFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CheckWithCircleFilled.svg'
export {default as ChevronDown} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronDown.svg'
export {default as ChevronDownFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronDownFilled.svg'
export {default as ChevronLeft} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronLeft.svg'
export {default as ChevronLeftFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronLeftFilled.svg'
export {default as ChevronRight} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronRight.svg'
export {default as ChevronRightFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronRightFilled.svg'
export {default as ChevronUp} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronUp.svg'
export {default as ChevronUpFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ChevronUpFilled.svg'
export {default as Circle} from 'src/designSystem/components/atoms/SvgIcon/assets/Circle.svg'
export {default as CircleFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CircleFilled.svg'
export {default as Clock} from 'src/designSystem/components/atoms/SvgIcon/assets/Clock.svg'
export {default as ClockFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ClockFilled.svg'
export {default as Close} from 'src/designSystem/components/atoms/SvgIcon/assets/Close.svg'
export {default as CloseFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CloseFilled.svg'
export {default as Copy} from 'src/designSystem/components/atoms/SvgIcon/assets/Copy.svg'
export {default as CopyFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/CopyFilled.svg'
export {default as Delete} from 'src/designSystem/components/atoms/SvgIcon/assets/Delete.svg'
export {default as DeleteFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/DeleteFilled.svg'
export {default as Download} from 'src/designSystem/components/atoms/SvgIcon/assets/Download.svg'
export {default as DownloadFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/DownloadFilled.svg'
export {default as Edit} from 'src/designSystem/components/atoms/SvgIcon/assets/Edit.svg'
export {default as EditFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/EditFilled.svg'
export {default as Error} from 'src/designSystem/components/atoms/SvgIcon/assets/Error.svg'
export {default as ErrorFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ErrorFilled.svg'
export {default as ExternalLink} from 'src/designSystem/components/atoms/SvgIcon/assets/ExternalLink.svg'
export {default as ExternalLinkFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ExternalLinkFilled.svg'
export {default as Filter} from 'src/designSystem/components/atoms/SvgIcon/assets/Filter.svg'
export {default as FilterFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/FilterFilled.svg'
export {default as Google} from 'src/designSystem/components/atoms/SvgIcon/assets/Google.svg'
export {default as GoogleFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/GoogleFilled.svg'
export {default as Gift} from 'src/designSystem/components/atoms/SvgIcon/assets/Gift.svg'
export {default as GiftFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/GiftFilled.svg'
export {default as Graph} from 'src/designSystem/components/atoms/SvgIcon/assets/Graph.svg'
export {default as GraphFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/GraphFilled.svg'
export {default as Hide} from 'src/designSystem/components/atoms/SvgIcon/assets/Hide.svg'
export {default as HideFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/HideFilled.svg'
export {default as Home} from 'src/designSystem/components/atoms/SvgIcon/assets/Home.svg'
export {default as HomeFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/HomeFilled.svg'
export {default as Info} from 'src/designSystem/components/atoms/SvgIcon/assets/Info.svg'
export {default as InfoFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/InfoFilled.svg'
export {default as LargeArrowRight} from 'src/designSystem/components/atoms/SvgIcon/assets/LargeArrowRight.svg'
export {default as LargeArrowRightFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LargeArrowRightFilled.svg'
export {default as LargeArrowUpRight} from 'src/designSystem/components/atoms/SvgIcon/assets/LargeArrowUpRight.svg'
export {default as LargeArrowUpRightFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LargeArrowUpRightFilled.svg'
export {default as Like} from 'src/designSystem/components/atoms/SvgIcon/assets/Like.svg'
export {default as LikeFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LikeFilled.svg'
export {default as Location} from 'src/designSystem/components/atoms/SvgIcon/assets/Location.svg'
export {default as LocationFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LocationFilled.svg'
export {default as Lock} from 'src/designSystem/components/atoms/SvgIcon/assets/Lock.svg'
export {default as LockFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LockFilled.svg'
export {default as Login} from 'src/designSystem/components/atoms/SvgIcon/assets/Login.svg'
export {default as LoginFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LoginFilled.svg'
export {default as Logout} from 'src/designSystem/components/atoms/SvgIcon/assets/Logout.svg'
export {default as LogoutFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/LogoutFilled.svg'
export {default as Menu} from 'src/designSystem/components/atoms/SvgIcon/assets/Menu.svg'
export {default as MenuFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MenuFilled.svg'
export {default as Message} from 'src/designSystem/components/atoms/SvgIcon/assets/Message.svg'
export {default as MessageFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MessageFilled.svg'
export {default as Minus} from 'src/designSystem/components/atoms/SvgIcon/assets/Minus.svg'
export {default as MinusFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MinusFilled.svg'
export {default as Money} from 'src/designSystem/components/atoms/SvgIcon/assets/Money.svg'
export {default as MoneyFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MoneyFilled.svg'
export {default as MoneyRotating} from 'src/designSystem/components/atoms/SvgIcon/assets/MoneyRotating.svg'
export {default as MoneyRotatingFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MoneyRotatingFilled.svg'
export {default as MoreHorizontal} from 'src/designSystem/components/atoms/SvgIcon/assets/MoreHorizontal.svg'
export {default as MoreHorizontalFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MoreHorizontalFilled.svg'
export {default as MoreVertical} from 'src/designSystem/components/atoms/SvgIcon/assets/MoreVertical.svg'
export {default as MoreVerticalFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/MoreVerticalFilled.svg'
export {default as Notification} from 'src/designSystem/components/atoms/SvgIcon/assets/Notification.svg'
export {default as NotificationFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/NotificationFilled.svg'
export {default as Play} from 'src/designSystem/components/atoms/SvgIcon/assets/Play.svg'
export {default as PlayFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/PlayFilled.svg'
export {default as Plus} from 'src/designSystem/components/atoms/SvgIcon/assets/Plus.svg'
export {default as PlusFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/PlusFilled.svg'
export {default as Profile} from 'src/designSystem/components/atoms/SvgIcon/assets/Profile.svg'
export {default as ProfileFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ProfileFilled.svg'
export {default as Reply} from 'src/designSystem/components/atoms/SvgIcon/assets/Reply.svg'
export {default as ReplyFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ReplyFilled.svg'
export {default as Reveal} from 'src/designSystem/components/atoms/SvgIcon/assets/Reveal.svg'
export {default as RevealFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/RevealFilled.svg'
export {default as RotatePhone} from 'src/designSystem/components/atoms/SvgIcon/assets/RotatePhone.svg'
export {default as RotatePhoneFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/RotatePhoneFilled.svg'
export {default as Scale} from 'src/designSystem/components/atoms/SvgIcon/assets/Scale.svg'
export {default as ScaleFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ScaleFilled.svg'
export {default as Search} from 'src/designSystem/components/atoms/SvgIcon/assets/Search.svg'
export {default as SearchFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/SearchFilled.svg'
export {default as Settings} from 'src/designSystem/components/atoms/SvgIcon/assets/Settings.svg'
export {default as SettingsFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/SettingsFilled.svg'
export {default as Shield} from 'src/designSystem/components/atoms/SvgIcon/assets/Shield.svg'
export {default as ShieldFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/ShieldFilled.svg'
export {default as Sun} from 'src/designSystem/components/atoms/SvgIcon/assets/Sun.svg'
export {default as SunFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/SunFilled.svg'
export {default as Target} from 'src/designSystem/components/atoms/SvgIcon/assets/Target.svg'
export {default as TargetFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/TargetFilled.svg'
export {default as Transfer} from 'src/designSystem/components/atoms/SvgIcon/assets/Transfer.svg'
export {default as TransferFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/TransferFilled.svg'
export {default as Upload} from 'src/designSystem/components/atoms/SvgIcon/assets/Upload.svg'
export {default as UploadFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/UploadFilled.svg'
export {default as Wallet} from 'src/designSystem/components/atoms/SvgIcon/assets/Wallet.svg'
export {default as WalletFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/WalletFilled.svg'
export {default as Warning} from 'src/designSystem/components/atoms/SvgIcon/assets/Warning.svg'
export {default as WarningFilled} from 'src/designSystem/components/atoms/SvgIcon/assets/WarningFilled.svg'
