import {Applications, CreateApolloClient, LogCallback, UpdateEnvironment} from '@possible/cassandra'
import {AppState} from 'react-native'

import {APIStateChange} from 'src/api/actions'
import {ConnectionError, ConnectionSuccess} from 'src/api/actions/client/connectionActions'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {UserLoginStates} from 'src/api/reducers/types'
import Log from 'src/lib/loggingUtil'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {getPfStore} from 'src/store'
import {usePfDispatch} from 'src/store/utils'

export const initializeCassandra = async (
  dispatch: ReturnType<typeof usePfDispatch>,
  token?: string,
): Promise<void> => {
  const cassandraLogger: LogCallback = {
    log: Log.log,
    warn: Log.warn,
    error: Log.error,
  }

  try {
    const env = await getEnvironment()
    dispatch(UpdateEnvironment({environment: env, app: Applications.MOBILE, token}))
    CreateApolloClient({
      app: Applications.MOBILE,
      env,
      token,
      logger: cassandraLogger,
      onSuccess: () => {
        if (getPfStore().getState().api.badConnection) {
          void dispatch(ConnectionSuccess())
        }
      },
      onError: (errorResponse) => {
        if (
          errorResponse.networkError?.message &&
          errorResponse.networkError.message === 'Network request failed' &&
          // if we background the app then it seems that react-native may cancel any requests in flight.
          // that doesn't mean the network is down so we only check if the app is in the foreground
          AppState.currentState === 'active' &&
          !getPfStore().getState().api.badConnection
        ) {
          void dispatch(ConnectionError())
        }
        if (
          errorResponse.networkError &&
          'statusCode' in errorResponse.networkError &&
          errorResponse.networkError.statusCode === 403
        ) {
          // we're not longer logged in since our token expired. update store so that LogOut()
          // doesn't try to submit the logout mutation and fail since it's not necessary
          dispatch(
            APIStateChange({
              user_logged_state: UserLoginStates.not_logged_in,
            }),
          )
          // if we get a 403 from GQL we log the user out
          void dispatch(Logout())
        }
      },
    })
  } catch (e) {
    Log.error(e, 'Failed to initialize Cassandra')
  }
}
