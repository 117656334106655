import {setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {TilesToDisplayOnce} from 'src/products/card/LoanDash/utils'

type ParamsType = {
  enableCards: boolean
}
type UseResetOneTimeMessagesType = () => (paramsType) => Promise<void>

export const useResetOneTimeMessages: UseResetOneTimeMessagesType = () => {
  return async (params: ParamsType) => {
    const {enableCards: shouldEnableCards} = params
    await setUserProperty('cardApplicationGroup', {
      possibleCard: shouldEnableCards,
      [TilesToDisplayOnce.CardRejectedTile]: false,
      [TilesToDisplayOnce.CardExpiredTile]: false,
    })
  }
}
