import React from 'react'
import {useTranslation} from 'react-i18next'

import {PaymentItem} from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToRescheduleTemplate/PaymentItem/PaymentItem'
import {RescheduleLoanPaymentItemType} from 'src/products/loans/Reschedule/Reschedule.types'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type SelectPaymentToRescheduleTemplateProps = {
  nextAvailSettlementDateText: string // dddd, MMMM Do
  payments: RescheduleLoanPaymentItemType[]
  onContinue: () => void
  willRescheduleChangePaymentAmount: boolean
  onEdit: (payment: RescheduleLoanPaymentItemType) => void
}

const SelectPaymentToRescheduleTemplate = (
  props: SelectPaymentToRescheduleTemplateProps,
): JSX.Element => {
  const {
    nextAvailSettlementDateText,
    payments,
    onContinue,
    willRescheduleChangePaymentAmount,
    onEdit: handleOnEdit,
  } = props

  const {t} = useTranslation('Reschedule')

  return (
    <Page
      testID="SelectPaymentToRescheduleTemplate"
      variant={'generic'}
      smallTopGap
      title={t('NeedMoreTime')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          testID: 'Continue-CTA-Button',
          text: t('AllDone'),
          onPress: onContinue,
        },
      }}
    >
      <Box gap={'small'}>
        <Box>
          <PFText variant={'p'}>{t('WeKnowLifeHappens')}</PFText>
        </Box>
        <Box>
          <PFText variant={'p'}>{t('FirstAvailablePaymentDate')}</PFText>
          <PFText variant={'p_semibold'}>{nextAvailSettlementDateText}</PFText>
        </Box>
        <Box>
          {payments?.map((payment) => {
            return (
              <PaymentItem
                key={payment.id}
                hasBeenRescheduled={
                  payment.rescheduledDate !== undefined &&
                  payment.rescheduledDate !== payment.originalDate
                }
                payment={payment}
                onEdit={handleOnEdit}
                testID={`payment-item-${payment.ordinal}`}
              />
            )
          })}
        </Box>
        {willRescheduleChangePaymentAmount ? (
          <Box paddingVertical={'small'}>
            <PFText variant={'p'}>{t('YouMightSeeYourFinalPaymentAmountChange')}</PFText>
          </Box>
        ) : null}
      </Box>
    </Page>
  )
}

export {SelectPaymentToRescheduleTemplate}
