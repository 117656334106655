import {
  INTRO_LOGIN_BUTTON,
  UNSUPPORTED_INSTITUTIONS_PLAID,
  UNSUPPORTED_INSTITUTIONS_POSSIBLE,
  UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS,
} from 'src/lib/RemoteConfig/parameterkeys'

export const loginButton = 'secondary'
export const loginLink = 'text_link'

const unsupportedInstitutionsPlaidFallback = []

export const unsupportedInstitutionsOnlineBanksFallback = [
  {
    id: 'ins_24',
    name: 'Simple',
  },
  {
    id: 'ins_132083',
    name: 'Venmo - Personal',
  },
  {
    id: 'ins_35',
    name: 'Chime',
  },
]

export const unsupportedInstitutionsPossibleFallback = [
  {
    id: 'ins_22',
    name: 'Paypal',
  },
  {
    id: 'ins_117190',
    name: 'Bluebird Amex',
  },
  {
    id: 'ins_117179',
    name: 'Dave',
  },
  {
    id: 'ins_118408',
    name: 'Serve',
  },
  {
    id: 'ins_132401',
    name: 'Current',
  },
  {
    id: 'ins_127892',
    name: 'GO2Bank',
  },
  {
    id: 'ins_129417',
    name: 'ONE',
  },
  {
    id: 'ins_129291',
    name: 'Albert',
  },
  {
    id: 'ins_126340',
    name: 'Lili',
  },
  {
    id: 'ins_125234',
    name: 'Branch',
  },
  {
    id: 'ins_133030',
    name: 'Uber Pro Card',
  },
  {
    id: 'ins_118390',
    name: 'Oxygen',
  },
  {
    id: 'ins_133040',
    name: 'Kikoff',
  },
  {
    id: 'ins_130955',
    name: 'MoneyLion - RoarMoney',
  },
  {
    id: 'ins_111998',
    name: 'Walmart MoneyCard by Green Dot',
  },
  {
    id: 'ins_132277',
    name: 'Turbo Card by Green Dot',
  },
  {
    id: 'ins_117248',
    name: 'ACE Flare Account by Metabank',
  },
  {
    id: 'ins_114860',
    name: 'Wisely',
  },
  {
    id: 'ins_129555',
    name: 'Providers Card',
  },
]

const secondsInMinute = 60
const minutesInhours = 60
const hourUnit = secondsInMinute * minutesInhours
export const refreshInterval = 5 * hourUnit

export const fetchError = 'Unable to fetch Firebase remote configs'

export const defaultConfig = {
  [INTRO_LOGIN_BUTTON]: loginButton,
  [UNSUPPORTED_INSTITUTIONS_PLAID]: JSON.stringify(unsupportedInstitutionsPlaidFallback),
  [UNSUPPORTED_INSTITUTIONS_POSSIBLE]: JSON.stringify(unsupportedInstitutionsPossibleFallback),
  [UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS]: JSON.stringify(
    unsupportedInstitutionsOnlineBanksFallback,
  ),
}
