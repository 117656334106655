import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {RequiredKeys} from 'utility-types'

type RequiredFields<T extends keyof MainStackParamList> = Pick<
  MainStackParamList[T],
  RequiredKeys<MainStackParamList[T]>
>

export const screenToParamsMap: Partial<{[key in keyof MainStackParamList]: RequiredFields<key>}> =
  {
    CollectDebitCardNumbersForLoansProduct: {accountId: '123'},
  }
