import React, {useRef, useState} from 'react'
import {
  Animated,
  DimensionValue,
  Easing,
  LayoutAnimation,
  LayoutChangeEvent,
  Platform,
  Pressable,
  StyleSheet,
  UIManager,
  View,
} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {gutterSize, tinyGap} from 'src/designSystem/layout'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import Log from 'src/lib/loggingUtil'

type ExpandableSectionProps = {
  children: React.ReactNode
  showLoading?: boolean
  testID?: string
  title: string
}

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = (props) => {
  const {children, showLoading, title, testID = 'ExpandableSection'} = props

  const [isExpanded, setIsExpanded] = useState(false)
  const [buttonHeight, setButtonHeight] = useState<DimensionValue>('auto')
  const animatedOpacity = useRef(new Animated.Value(0)).current
  const animatedRotation = useRef(new Animated.Value(0)).current
  const animatedDeg = animatedRotation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  })

  const logAnimationError = (): void => Log.error('Error animating with LayoutAnimation')

  const handleOnPress = (): void => {
    LayoutAnimation.configureNext(
      LayoutAnimation.Presets.easeInEaseOut,
      undefined,
      logAnimationError,
    )

    setIsExpanded((isExpanded) => !isExpanded)

    Animated.timing(animatedOpacity, {
      duration: 300,
      toValue: isExpanded ? 0 : 1,
      useNativeDriver: true,
    }).start()

    Animated.timing(animatedRotation, {
      duration: 300,
      easing: Easing.inOut(Easing.ease),
      toValue: isExpanded ? 0 : 1,
      useNativeDriver: true,
    }).start()
  }

  const handleOnLayout = (event: LayoutChangeEvent): void => {
    setButtonHeight(event.nativeEvent.layout.height)
  }

  return (
    <View style={[styles.expandableBox, !isExpanded ? {height: buttonHeight} : null]}>
      <Pressable
        aria-busy={showLoading}
        aria-expanded={isExpanded}
        aria-label={title}
        disabled={showLoading}
        onPress={handleOnPress}
        onLayout={handleOnLayout}
        role={'button'}
        style={styles.button}
        testID={testID}
      >
        {showLoading ? (
          <Loading type="loader0" size="large" />
        ) : (
          <>
            <PFText variant="p_semibold">{title}</PFText>
            <Animated.View aria-hidden style={{transform: [{rotate: animatedDeg}]}}>
              <SvgIcon name={'chevronDown'} colorVariant="default" />
            </Animated.View>
          </>
        )}
      </Pressable>

      <Animated.View style={[styles.content, {opacity: animatedOpacity}]}>{children}</Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: gutterSize * 2,
    paddingRight: gutterSize,
    paddingVertical: gutterSize,
  },
  content: {
    marginTop: (gutterSize - tinyGap) * -1,
    paddingHorizontal: gutterSize * 2,
    paddingBottom: gutterSize,
  },
  expandableBox: {
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: 10,
    overflow: 'hidden',
  },
  loading: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
