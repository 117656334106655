import {
  LinkedAccount,
  LinkedAccountStatusCode,
  UserProfile,
} from '@possible/cassandra/src/types/types.mobile.generated'

import {getUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {Address} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'

export const maskPrefix = '\u0020\u0020\u2022\u2006\u2022\u2006\u2022\u2006\u2022\u2006'
export const maskPrefixSmall = '\u2E31\u2E31\u2E31\u2E31'
// the dots •••• with a non-breaking space at end so it doesn't wrap
export const maskPrefixNonBreaking = '\u2022\u2022\u2022\u2022\u00A0'
// the dots spaced out • • • • with a non-breaking space so it doesn't wrap
export const maskPrefixNonBreakingSpacedOut = '\u2022\u00A0\u2022\u00A0\u2022\u00A0\u2022\u00A0'

// Confirmed new UI for formatted mask with Design
// formatMask function is being depreciated in favor of formatMaskNonBreaking
// ticket: https://possible.atlassian.net/browse/ENG-16516
export const formatMask = (mask: string | undefined, small: boolean): string => {
  if (mask) {
    return `${small ? maskPrefixSmall : maskPrefix}${mask}`
  } else {
    return ''
  }
}

export const formatMaskNonBreaking = (
  mask: string | null,
  withSpaceBetweenDots: boolean,
): string => {
  if (!mask) {
    return ''
  }

  if (withSpaceBetweenDots) {
    return `${maskPrefixNonBreakingSpacedOut}${mask}`
  }

  return `${maskPrefixNonBreaking}${mask}`
}

export function hasValidFundableAccountGql<
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(accounts: LinkedAccountSubset[] | undefined | null): boolean {
  if (!accounts || accounts.length === 0) {
    return false
  }
  const filterredAccounts = accounts.filter(
    (account) => account.status === LinkedAccountStatusCode.LinkedInUse && !account.isLoginRequired,
  )
  return filterredAccounts.length > 0
}

export const isAccountLinkedGql = <
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(
  account: LinkedAccountSubset,
): boolean => {
  return account.status === LinkedAccountStatusCode.LinkedInUse && account.isLoginRequired === false
}

export async function userHasOnlineBankEnabled(): Promise<boolean> {
  try {
    const result = await getUserProperty('onlineBank')
    return result?.value?.enabled ?? false
  } catch (e) {
    Log.info('onlineBank get error:', e)
  }
  return false
}

function addAddressLine(oldAddStr: string, newAddrLine: string | null | undefined): string {
  if (!newAddrLine) {
    return oldAddStr
  }
  if (oldAddStr) {
    oldAddStr = `${oldAddStr}\n`
  }
  oldAddStr = oldAddStr + newAddrLine
  return oldAddStr
}

export const getPreferredAccountFormatted = (
  preferredAccount?: Pick<LinkedAccount, 'name' | 'mask' | 'preferredFundingSource'>,
): string => {
  if (!preferredAccount) {
    return ''
  }
  return `${preferredAccount.name}\n${formatMaskNonBreaking(preferredAccount.mask, false)}`
}

export function getFormattedAddress(address: Address | null | undefined): string {
  if (!address) return ''
  let adddressStr = ''
  adddressStr = addAddressLine(adddressStr, address?.street1)
  adddressStr = addAddressLine(adddressStr, address?.street2)
  adddressStr = addAddressLine(
    adddressStr,
    `${address?.city}, ${address?.state} ${address?.postalCode}`,
  )
  return adddressStr
}

export function getFormattedAccountType(subType: string | undefined): string {
  if (!subType) {
    return ''
  }
  return subType
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function capitalizeFirstLetter(string: string | undefined): string {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getUserFullName = (data: NonNullable<UserProfile['name']>): string =>
  // Object order is not guaranteed
  [data.firstName, data?.middleName, data?.lastName, data?.suffix]
    .filter((nameDatum) => !!nameDatum)
    .join(' ')
