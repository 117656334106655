import {useEffect, useState} from 'react'

import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {getShouldEnrollInCards} from 'src/lib/card/selectors'
import {clearShouldEnrollInCards} from 'src/lib/card/actions'
import {useInitializeCards} from 'src/products/loans/Landing/useInitializeCards'
import Log from 'src/lib/loggingUtil'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'

/**
 * If this user came from the cards deep link, enroll them in cards
 */
type UseEnrollInCardsIfNecessaryType = () => {
  isComplete: boolean
}
export const useEnrollInCardsIfNecessary: UseEnrollInCardsIfNecessaryType = () => {
  const shouldEnrollInCards = usePfSelector(getShouldEnrollInCards)
  const {initializeCards, isLoading: isLoadingUseInitializeCards} = useInitializeCards()
  const {canAccessCards, isLoading: isLoadingCanAccessCards} = useCanAccessCards()
  const dispatch = usePfDispatch()
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    const run = async (): Promise<void> => {
      if (!shouldEnrollInCards) {
        setIsComplete(true)
        return
      }

      if (!isLoadingUseInitializeCards && !isLoadingCanAccessCards) {
        if (!canAccessCards) {
          Log.info('Adding user to card group')
          await initializeCards()
        }
        dispatch(clearShouldEnrollInCards())
        setIsComplete(true)
      }
    }

    void run()
  }, [
    shouldEnrollInCards,
    dispatch,
    initializeCards,
    isLoadingUseInitializeCards,
    canAccessCards,
    isLoadingCanAccessCards,
  ])

  return {
    isComplete,
  }
}
