import React, {FC, useEffect, useState} from 'react'
import {View, StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {paginationStepHeight, paginationStepRadius, tinyGap} from 'src/designSystem/layout'
import {paginationInactiveStepColor} from 'src/designSystem/semanticColors'
import Log from 'src/lib/loggingUtil'

type PaginationProps = {
  steps: number
  currentStep: number
  color?: 'primary' | 'success'
}

const Pagination: FC<PaginationProps> = (props) => {
  const {steps, currentStep, color} = props
  const [availableWidth, setAvailableWidth] = useState(0)

  useEffect(() => {
    if (currentStep > steps) {
      Log.warn('Pagination currentStep > steps.') //track if wrong data is passed
    }
  }, [steps, currentStep])

  const getPaginationSteps = () => {
    return Array.from({length: steps}, (item, index) => {
      const isLastStep: boolean = steps === index
      const isPrevStep: boolean = currentStep > index
      const completedStepColor = color || 'primary'

      const stepWidth: number =
        steps > 0 ? (availableWidth - tinyGap * (steps - 1)) / steps : availableWidth

      return (
        <Box
          key={`step-${index}`}
          radius={paginationStepRadius}
          marginRight={!isLastStep ? 'tiny' : 0}
          background={isPrevStep ? completedStepColor : paginationInactiveStepColor}
          height={paginationStepHeight}
          width={stepWidth}
        />
      )
    })
  }

  return (
    <View
      style={styles.wrapperStyle}
      onLayout={(event) => {
        setAvailableWidth(event.nativeEvent.layout.width)
      }}
    >
      {getPaginationSteps()}
    </View>
  )
}

export default Pagination

const styles = StyleSheet.create({
  wrapperStyle: {
    flexDirection: 'row',
  },
})
