import React from 'react'

import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {WorkflowScreenProps} from 'src/workflows/types'
import {CardManualPayScheduleGQLContainer} from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleGQLContainer/CardManualPayScheduleGQLContainer'

const CardManualPayScheduleWorkflowContainer = ({
  onScreenComplete,
}: WorkflowScreenProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_manual_pay_schedule_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnPress = (): void => {
    TrackAppEvent(CardEvents.card_manual_pay_schedule_page_cta, AppEvents.Category.Card)

    onScreenComplete()
  }

  return <CardManualPayScheduleGQLContainer onComplete={handleOnPress} />
}

export {CardManualPayScheduleWorkflowContainer}
