import {TFunction} from 'i18next'
import {capitalize} from 'lodash'
import moment from 'moment'

import {
  CardAccountTransaction,
  CardPaymentStatus,
  CardTransactionStatus,
  Payment,
} from 'src/cassandra'
import {
  failedTransactionPillColor,
  pendingTransactionPillColor,
  pendingTransactionPillTextColor,
} from 'src/designSystem/semanticColors'
import {ColorVariants} from 'src/designSystem/colors'
import {
  PFStatusPillProps,
  PFStatusPillPropsSolid,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type ItemVariantType = 'payment' | 'transaction'

export type ItemType = {
  title: string
  titleColor?: ColorVariants
  description: string
  descriptionColor?: ColorVariants
  date: Date
  amount: number
  amountColor?: ColorVariants
  amountPrefix?: '-' | '+'
  status?: string
  variant: ItemVariantType
  pillColor?: PFStatusPillProps['color']
  pillTextColor?: PFStatusPillPropsSolid['fontColor']
  isPending: boolean
  icon: SvgIconProps
}

export function cardPaymentStatusToString(status: CardPaymentStatus, t: TFunction): string {
  switch (status) {
    case CardPaymentStatus.Cancelled:
      return t('Cancelled')
    case CardPaymentStatus.Failed:
      return t('Failed')
    case CardPaymentStatus.InProgress:
    case CardPaymentStatus.InProgressAchTransit:
    case CardPaymentStatus.Pending:
      return t('Pending')
    default:
      return ''
  }
}

export function cardTransactionStatusToString(
  status: CardTransactionStatus | undefined,
  t: TFunction,
): string {
  if (status === CardTransactionStatus.Pending) {
    return t('Pending')
  } else {
    return ''
  }
}

/**
 * Convert all payments to an unsorted list of ItemType[]
 */
export const getAllPaymentItems = (allPostedPayments: Payment[], t: TFunction): ItemType[] => {
  return allPostedPayments.map((payment: Payment) => {
    const isPending =
      payment?.status === CardPaymentStatus.Pending ||
      payment?.status === CardPaymentStatus.InProgress ||
      payment?.status === CardPaymentStatus.InProgressAchTransit
    return {
      title: 'Payment',
      titleColor: isPending ? 'textDisabled' : 'textPrimary',
      description: capitalize(
        payment.status === CardPaymentStatus.Completed ? '' : payment?.statusCode || '',
      ),
      descriptionColor: isPending ? 'textDisabled' : 'textPrimary',
      date: moment(payment?.executeAt).toDate(),
      amount: parseFloat(payment?.amount || '0'),
      amountColor:
        isPending || payment?.status === CardPaymentStatus.Failed ? 'textDisabled' : 'textPrimary',
      status: cardPaymentStatusToString(payment?.status, t),
      pillColor:
        payment?.status === CardPaymentStatus.Failed
          ? failedTransactionPillColor
          : pendingTransactionPillColor,
      pillTextColor:
        payment?.status === CardPaymentStatus.Failed ? 'white' : pendingTransactionPillTextColor,
      amountPrefix: '-',
      variant: 'payment',
      isPending,
      icon: {
        name: 'largeArrowUpRight',
        colorVariant: isPending ? 'inactive' : 'default',
      },
    }
  })
}

/**
 * Convert all transactions to an unsorted list of ItemType[]
 */
export const getAllTransactionItems = (
  transactions: CardAccountTransaction[],
  t: TFunction,
): ItemType[] => {
  return transactions.map((transaction: CardAccountTransaction) => {
    const {description = '', transactionDatetime = '', status} = transaction
    let amount: string = transaction?.amount ?? ''
    let amountPrefix: '-' | '+' | undefined
    // The positive and negative of transaction amount are revered
    if (amount?.startsWith('-')) {
      amount = amount?.replace('-', '')
    } else {
      amountPrefix = '-'
    }

    const isPending = status === CardTransactionStatus.Pending

    return {
      title: transaction?.merchant?.name ?? '',
      titleColor: isPending ? 'textDisabled' : 'textPrimary',
      description: description ?? '',
      descriptionColor: isPending ? 'textDisabled' : 'textPrimary',
      date: moment(transactionDatetime).toDate(),
      amount: parseFloat(amount),
      amountColor: isPending ? 'textDisabled' : 'textPrimary',
      amountPrefix: amountPrefix,
      status: cardTransactionStatusToString(status ?? undefined, t),
      variant: 'transaction',
      pillColor: pendingTransactionPillColor,
      pillTextColor: pendingTransactionPillTextColor,
      isPending,
      icon: {
        name: 'card',
        colorVariant: isPending ? 'inactive' : 'default',
      },
    }
  })
}

/**
 * Sort a list of ItemType. The sort rules are:
 * 1) Pending transactions should always appear first
 * 2) All other transactions are sorted newest first
 */
export const sortTransactionItems = (transactionItems: ItemType[]): ItemType[] => {
  transactionItems.sort((a: ItemType, b: ItemType) => {
    if (a.isPending && !b.isPending) {
      return -1
    } else if (!a.isPending && b.isPending) {
      return 1
    } else {
      return b.date.getTime() - a.date.getTime()
    }
  })

  return transactionItems
}
