import {LoanClosedReasonCode} from '@possible/cassandra/src/types/types.mobile.generated'

export const isLoanClosedReasonCodePaidOff = (closeReasonCode: LoanClosedReasonCode): boolean => {
  return (
    closeReasonCode === LoanClosedReasonCode.Paidoff ||
    closeReasonCode === LoanClosedReasonCode.ChargedoffPaidoff
  )
}

export const isLoanClosedReasonCodeChargedOff = (
  closeReasonCode: LoanClosedReasonCode,
): boolean => {
  return (
    closeReasonCode === LoanClosedReasonCode.ChargedoffPaidoff ||
    closeReasonCode === LoanClosedReasonCode.ChargedoffSettled
  )
}
