import {useCallback} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {Consumer} from 'src/cassandra'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {FetchResult} from '@apollo/client'

type UseIdentityVerificationType<MutationType> = {
  makeAuthenticatedNetworkCall: (
    makeNetworkCall: () => Promise<FetchResult<MutationType>>,
  ) => Promise<FetchResult<MutationType>>
  verifyIdentity: () => Promise<boolean>
}
type PerformNetworkCallWithMFA<MutationType> = () => Promise<FetchResult<MutationType>>
type RetryNetworkCallType<MutationType> = (
  makeNetworkCall: PerformNetworkCallWithMFA<MutationType>,
) => ReturnType<PerformNetworkCallWithMFA<MutationType>>
type MakeAuthenticatedNetworkCallType<MutationType> = (
  makeNetworkCall: PerformNetworkCallWithMFA<MutationType>,
) => ReturnType<PerformNetworkCallWithMFA<MutationType>>
type VerifyIdentityType = () => Promise<boolean>

export const useIdentityVerification = <MutationType,>(
  verificationType: Consumer.types.UserVerificationType,
  onCancel?: () => void,
): UseIdentityVerificationType<MutationType> => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const verifyIdentity: VerifyIdentityType = useCallback(() => {
    return new Promise((resolve) => {
      navigation.navigate('OTPIdentityVerification', {resolve, verificationType, onCancel})
    })
  }, [verificationType, onCancel, navigation])

  // if network call fails, verify identity and try again
  const retryNetworkCall: RetryNetworkCallType<MutationType> = useCallback(
    async (makeNetworkCall) => {
      await verifyIdentity()
      return makeNetworkCall()
    },
    [verifyIdentity],
  )

  const makeAuthenticatedNetworkCall: MakeAuthenticatedNetworkCallType<MutationType> = useCallback(
    async (makeNetworkCall) => {
      try {
        // Attempt to make the network call which requires MFA
        const r = await makeNetworkCall()
        if (r?.errors?.[0]) {
          return retryNetworkCall(makeNetworkCall)
        }

        return r
      } catch (e) {
        return retryNetworkCall(makeNetworkCall)
      }
    },
    [retryNetworkCall],
  )

  return {makeAuthenticatedNetworkCall, verifyIdentity}
}
