import {TFunction} from 'i18next'
import moment from 'moment'
import React from 'react'

import {Consumer} from '@possible/cassandra'
import {
  ActiveCardAccountRestrictionCode,
  ActiveCardAccountStatus,
  Card,
  CardAccount,
  CardAccountInstallmentPlan,
  CardAccountOverdueStatus,
  CardAccountStatusCode,
  CardAccountStatuses,
  CardAccountSuspendedRestriction,
  CardAccountSuspensionReasonCode,
  CardAccountTransaction,
  CardAdhocPaymentQuery,
  CardInstallmentPlanCollection,
  CardsQueryPolledQuery,
  CardsQueryQuery,
  CardStatusCode,
  CardType,
  ChargedOffCardStatus,
  InstallmentPayment,
  LinkedAccount,
  Maybe,
  OverDuePaymentInfo,
  Payment,
  PaymentStatusCode,
} from 'src/cassandra'
import {NamedColors} from 'src/designSystem/colors'
import CalendarDateCircle from 'src/designSystem/components/atoms/CalendarDateCircle/CalendarDateCircle'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {formatDate, graphqlDateFormat, utcDate} from 'src/lib/utils/date'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {CardDashboardHeaderHeading} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardHeaderHeading'
import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'

export const formatExpiry = (expiry?: string): string => {
  if (!expiry) {
    return ''
  }
  return formatDate(expiry, 'MM/YYYY', 'MMYYYY')
}

export const getAvailableBalance = (status: Partial<CardAccountStatuses> | undefined): string => {
  if (status && 'balance' in status) {
    return convertToDollarAmt(status?.balance?.availableBalance)
  }
  return '0'
}
export const getAvailableSpent = (status: Partial<CardAccountStatuses> | undefined): string => {
  if (status && 'balance' in status) {
    return status?.balance?.availableSpent || '0'
  }
  return '0'
}
export const getLedgerSpent = (status: Partial<CardAccountStatuses> | undefined): string => {
  if (status && 'balance' in status) {
    return status?.balance?.ledgerSpent || '0'
  }
  return '0'
}
export const getTotalPaymentAmount = (payments: Payment[]): number => {
  return payments.reduce((prev, val) => Number(val.amount) + prev, 0)
}
export const getTotalTransactionAmount = (transactions: CardAccountTransaction[]): number => {
  return transactions.reduce((prev, val) => Number(val.amount) + prev, 0)
}
export const isAccountLocked = (accountStatus: ActiveCardAccountStatus): boolean => {
  if (accountStatus.__typename === 'ActiveCardAccountStatus') {
    return accountStatus.restriction?.code === ActiveCardAccountRestrictionCode.Locked
  } else {
    return false
  }
}
export const getAccountOverdueOrDelinquentStatus = (
  accountStatus: ActiveCardAccountStatus | undefined,
  nsf: boolean,
): CardAccountDashboardStatus | undefined => {
  if (!accountStatus) {
    return undefined
  }
  const userIsAtRiskForDelinquency =
    accountStatus.cardAccountOverdueStatus === CardAccountOverdueStatus.Overdue &&
    accountStatus.daysUntilDelinquent &&
    accountStatus.daysUntilDelinquent > 0
  if (!nsf) {
    if (userIsAtRiskForDelinquency) {
      return CardAccountDashboardStatus.AtRiskDelinquency
    }
    if (accountStatus.cardAccountOverdueStatus !== CardAccountOverdueStatus.None) {
      switch (accountStatus.cardAccountOverdueStatus) {
        case 'OVERDUE':
          return CardAccountDashboardStatus.Overdue
        case 'DELINQUENT':
          return CardAccountDashboardStatus.Delinquent
        case 'DELINQUENT_THIRTY_DAYS_OR_GREATER':
          return CardAccountDashboardStatus.DelinquencyReported
        default:
          return undefined
      }
    }
  } else {
    if (userIsAtRiskForDelinquency) {
      return CardAccountDashboardStatus.AtRiskDelinquencyNSF
    }
    switch (accountStatus.cardAccountOverdueStatus) {
      case 'OVERDUE':
        return CardAccountDashboardStatus.OverdueNSF
      case 'DELINQUENT':
        return CardAccountDashboardStatus.DelinquentNSF
      case 'DELINQUENT_THIRTY_DAYS_OR_GREATER':
        return CardAccountDashboardStatus.DelinquencyReportedNSF
      default:
        return CardAccountDashboardStatus.Active
    }
  }
}

export const hasOverdueAmount = (
  accountStatus?: Pick<ActiveCardAccountStatus, 'overduePaymentInfo'> & {
    overdueInstallmentInfo?: Maybe<Array<Maybe<Pick<OverDuePaymentInfo, 'amount'>>>>
  },
): boolean => {
  const amount = accountStatus?.overduePaymentInfo?.amount
  return !!(amount && parseFloat(amount) > 0)
}

export const getOverdueInstallmentInfo = (
  accountStatus?: Pick<ActiveCardAccountStatus, '__typename' | 'overdueInstallmentInfo'>,
): OverDuePaymentInfo[] =>
  (accountStatus?.__typename === 'ActiveCardAccountStatus' && accountStatus?.overdueInstallmentInfo
    ? accountStatus.overdueInstallmentInfo
    : []
  ).flatMap((info) => (info ? [info] : []))

export const getCardAccountPOTStatus = (
  activeAccount?:
    | CardsQueryPolledQuery['me']['cardAccounts']['active']
    | CardAdhocPaymentQuery['me']['cardAccounts']['active'],
): CardAccountPOTStatus => {
  if (activeAccount?.status?.__typename === 'ActiveCardAccountStatus') {
    const statusReason = activeAccount.status?.restriction as CardAccountSuspendedRestriction
    const overdueInstallmentInfo = getOverdueInstallmentInfo(activeAccount.status)

    if (statusReason?.reason === CardAccountSuspensionReasonCode.PayLaterAgreement) {
      if (hasOverdueAmount(activeAccount.status)) {
        if (overdueInstallmentInfo.length > 1) {
          return CardAccountPOTStatus.OverdueMultipleMissedPayments
        } else {
          return CardAccountPOTStatus.OverdueOneMissedPayment
        }
      } else {
        return CardAccountPOTStatus.UpToDate
      }
    }
  }
  return CardAccountPOTStatus.NotPOT
}

export const isDelinquent = (accountStatus?: CardAccountStatuses): boolean => {
  if (
    accountStatus?.__typename === 'DeactivatedCardAccountStatus' ||
    accountStatus?.__typename === 'ActiveCardAccountStatus'
  ) {
    const overdueStatus = (accountStatus?.cardAccountOverdueStatus ??
      CardAccountOverdueStatus.None) as CardAccountOverdueStatus
    return [CardAccountOverdueStatus.Delinquent].includes(overdueStatus)
  }

  return false
}

export const isDelinquentThirtyDays = (accountStatus?: CardAccountStatuses): boolean => {
  if (
    accountStatus?.__typename === 'DeactivatedCardAccountStatus' ||
    accountStatus?.__typename === 'ActiveCardAccountStatus'
  ) {
    const overdueStatus = (accountStatus?.cardAccountOverdueStatus ??
      CardAccountOverdueStatus.None) as CardAccountOverdueStatus
    return [CardAccountOverdueStatus.DelinquentThirtyDaysOrGreater].includes(overdueStatus)
  }

  return false
}
export const getCardAccountDashboardStatus = (
  accountStatus?: CardAccountStatuses,
): CardAccountDashboardStatus | undefined => {
  if (!accountStatus) {
    return undefined
  }

  if (accountStatus.__typename === 'DeactivatedCardAccountStatus') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (accountStatus.chargedOffStatus !== ChargedOffCardStatus.None) {
      return CardAccountDashboardStatus.ChargedOff
    }
    if (isDelinquent(accountStatus)) {
      return CardAccountDashboardStatus.DeactivatedDelinquent
    } else if (isDelinquentThirtyDays(accountStatus)) {
      return CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays
    } else {
      return CardAccountDashboardStatus.Deactivated
    }
  }

  if (accountStatus.__typename === 'ActiveCardAccountStatus') {
    const statusReason = accountStatus?.restriction as CardAccountSuspendedRestriction
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (accountStatus.chargedOffStatus !== ChargedOffCardStatus.None) {
      return CardAccountDashboardStatus.ChargedOff
    }
    switch (statusReason?.reason) {
      case CardAccountSuspensionReasonCode.PayLaterAgreement:
        return CardAccountDashboardStatus.SuspendedPayOverTime
      case CardAccountSuspensionReasonCode.Fraud:
        return CardAccountDashboardStatus.SuspendedFraud
      case CardAccountSuspensionReasonCode.Nsf:
        return getAccountOverdueOrDelinquentStatus(accountStatus, true)
      default:
    }

    if (
      accountStatus.cardAccountOverdueStatus !== CardAccountOverdueStatus.None &&
      accountStatus.cardAccountOverdueStatus
    ) {
      return getAccountOverdueOrDelinquentStatus(accountStatus, false)
    }

    return CardAccountDashboardStatus.Active
  }

  return undefined
}

export const getFutureInstallment = (
  activeAccountCurrentInstallmentPaymentsCards: InstallmentPayment[],
): string | undefined => {
  const futureInstallment = activeAccountCurrentInstallmentPaymentsCards?.find(
    (payment) => payment?.executeAt && moment(payment?.executeAt, utcDate).isAfter(moment()),
  )

  if (!futureInstallment?.executeAt) {
    return undefined
  }

  return formatDate(futureInstallment?.executeAt, graphqlDateFormat, utcDate) ?? undefined
}

export const getSuspendedDefaultDate = (
  accountStatus: ActiveCardAccountStatus,
): string | undefined => {
  return accountStatus?.restriction?.occurredAt
}

const getActiveAccountCurrentInstallmentPaymentsCards = (
  cardAccount:
    | CardsQueryQuery['me']['cardAccounts']['active']
    | CardAdhocPaymentQuery['me']['cardAccounts']['active'],
  filterForZero: boolean,
): InstallmentPayment[] => {
  let activeAccountCurrentInstallmentPaymentsCards: InstallmentPayment[]
  if (filterForZero && cardAccount?.installmentPlans.current?.installments) {
    activeAccountCurrentInstallmentPaymentsCards =
      cardAccount?.installmentPlans.current?.installments.filter(
        ({remainingDue}) => remainingDue && Number(remainingDue) > 0,
      ) ?? []
  } else {
    activeAccountCurrentInstallmentPaymentsCards =
      cardAccount?.installmentPlans.current?.installments ?? []
  }
  return activeAccountCurrentInstallmentPaymentsCards
}

//Refactor components currently using getNextPayment to use smaller functions above rather than megafunction that takes in megaparameter
export const getNextPaymentDate = (
  cardAccount: CardsQueryQuery['me']['cardAccounts']['active'],
  cardAccountPolled: CardsQueryPolledQuery['me']['cardAccounts']['active'],
  filterForZero: boolean,
): string | undefined => {
  const activeAccountCurrentInstallmentPaymentsCards =
    getActiveAccountCurrentInstallmentPaymentsCards(cardAccount, filterForZero)
  if (cardAccount && cardAccountPolled) {
    const accountStatus = cardAccountPolled.status as ActiveCardAccountStatus
    const paymentDate = cardAccountPolled.payments?.schedule?.[0]?.paymentDate
    const cardAccountDashboardStatus = getCardAccountDashboardStatus(accountStatus)
    switch (cardAccountDashboardStatus) {
      case CardAccountDashboardStatus.SuspendedPayOverTime:
        return getFutureInstallment(activeAccountCurrentInstallmentPaymentsCards)
      default:
        return paymentDate ? formatDate(paymentDate, graphqlDateFormat, utcDate) : undefined
    }
  }
}
export const getNextPaymentAmount = (
  cardAccount:
    | CardsQueryQuery['me']['cardAccounts']['active']
    | CardAdhocPaymentQuery['me']['cardAccounts']['active'],
  filterForZero: boolean,
): string | undefined => {
  const activeAccountCurrentInstallmentPaymentsCards =
    getActiveAccountCurrentInstallmentPaymentsCards(cardAccount, filterForZero)
  const futureInstallment = activeAccountCurrentInstallmentPaymentsCards?.find(
    (payment) => payment?.dueAt && moment(payment?.dueAt, utcDate).isAfter(moment()),
  )

  if (!futureInstallment?.remainingDue) {
    return undefined
  }

  return futureInstallment.remainingDue
}

export const getPaymentDates = (
  cardAccount: CardsQueryQuery['me']['cardAccounts']['active'],
  cardAccountPolled: CardsQueryPolledQuery['me']['cardAccounts']['active'],
): string[] => {
  if (cardAccount && cardAccountPolled) {
    const paymentDates = cardAccountPolled.payments.schedule
    return (paymentDates ?? []).map((paymentDate) => paymentDate?.paymentDate)
  }
  return []
}

export const getIssuedCard = (
  issuedCards: Card[] | undefined,
  cardType: CardType,
  cardStatus?: CardStatusCode,
): Maybe<Consumer.types.Card> => {
  return (
    issuedCards?.find(
      (card) => (!cardStatus || card.status?.code === cardStatus) && card?.type === cardType,
    ) || null
  )
}

export const getPendingPhysicalCard = (
  activeAccount: Maybe<CardAccount>,
): Maybe<Consumer.types.Card> =>
  getIssuedCard(activeAccount?.cards?.issued ?? [], CardType.PhysicalCard, CardStatusCode.Pending)

export const getCurrentInstallment = (
  activeAccountCurrentInstallmentPaymentsCards: InstallmentPayment[],
): number =>
  activeAccountCurrentInstallmentPaymentsCards.filter(
    (payment) => payment?.statusCode === PaymentStatusCode.Succeeded,
  ).length

export const isPayOverTime = (
  spentAmount: string,
  eligibleForInstallments: boolean | undefined,
): boolean => {
  return !!(parseFloat(spentAmount ?? '0') > 0 && eligibleForInstallments)
}

export const getCardDashboardHeading = (
  cardStatus: CardAccountDashboardStatus | undefined,
  t: TFunction,
  nsf?: boolean,
): JSX.Element | null => {
  switch (cardStatus) {
    case CardAccountDashboardStatus.Active:
      if (nsf) {
        return (
          <CardDashboardHeaderHeading
            text={t('CardLocked')}
            textColor={'error'}
            iconColorVariant={'error'}
          />
        )
      } else {
        return null
      }
    case CardAccountDashboardStatus.SuspendedPayOverTime:
      return (
        <CardDashboardHeaderHeading
          text={t('CardLockedPayOverTime')}
          textColor={'error'}
          iconColorVariant={'error'}
        />
      )
    case CardAccountDashboardStatus.SuspendedFraud:
      return (
        <CardDashboardHeaderHeading
          text={t('CardLockedFraud')}
          textColor={'error'}
          iconColorVariant={'error'}
        />
      )
    case CardAccountDashboardStatus.Delinquent:
    case CardAccountDashboardStatus.DelinquentNSF:
    case CardAccountDashboardStatus.DelinquencyReported:
    case CardAccountDashboardStatus.DelinquencyReportedNSF:
    case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
    case CardAccountDashboardStatus.OverdueNSF:
      return (
        <CardDashboardHeaderHeading
          text={t('CardLocked')}
          textColor={'error'}
          iconColorVariant={'error'}
        />
      )
    case CardAccountDashboardStatus.Deactivated:
    case CardAccountDashboardStatus.DeactivatedDelinquent:
    case CardAccountDashboardStatus.ChargedOff:
    case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
      return (
        <PFStatusPill text={t('CardClosed')} color={NamedColors.SAND} fontColor="textPrimary" />
      )
    default:
      return null
  }
}

export const getCardDashboardHeaderBalanceLabel = (
  cardStatus: CardAccountDashboardStatus | undefined,
  t: TFunction,
): string => {
  switch (cardStatus) {
    case CardAccountDashboardStatus.SuspendedPayOverTime:
      return t('PlanBalance')
    case CardAccountDashboardStatus.Deactivated:
    case CardAccountDashboardStatus.DeactivatedDelinquent:
    case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
    case CardAccountDashboardStatus.ChargedOff:
      return t('OutstandingBalance')
    case CardAccountDashboardStatus.Active:
    case CardAccountDashboardStatus.SuspendedFraud:
    default:
      return t('CurrentBalance')
  }
}

export const calculateSpendProgress = (
  amount: number | string | null | undefined,
  total: number | string | null | undefined,
): number => {
  if (!amount || !total) {
    return 0
  }
  const amountNumber = Number(amount)
  const totalNumber = Number(total)
  return Math.min(1, totalNumber > 0 ? amountNumber / totalNumber : 0)
}

export const getLedgerSpentAfterPayments = (
  status: Partial<CardAccountStatuses> | undefined,
): number => {
  switch (status?.__typename) {
    case 'ActiveCardAccountStatus':
    case 'DeactivatedCardAccountStatus':
    case 'ExpiredCardAccountStatus':
      return Number(status?.balance?.ledgerSpentAfterPayments ?? '0')
    default:
      return 0
  }
}

export const getAccountPotentialInstallmentPlan = (
  installmentPlans: CardInstallmentPlanCollection | undefined | null,
): Maybe<CardAccountInstallmentPlan> => {
  if (!installmentPlans?.eligibleForInstallments) {
    return null
  }
  return installmentPlans?.potential || null
}

export const shouldShowVirtualCardButton = (
  activePhysicalCard: Card | null,
  autopayEnabled: boolean | undefined,
  externalPhaseThreeUser: boolean,
  accountStatus: CardAccountStatusCode,
): boolean => {
  return (
    accountStatus === CardAccountStatusCode.Active &&
    (!!activePhysicalCard || autopayEnabled || externalPhaseThreeUser)
  )
}

export const getIsDue = (date: string | undefined): boolean => {
  if (!date) {
    return false
  }
  const now = moment().utc()
  return moment(date, graphqlDateFormat).isBefore(now, 'day')
}

export const getDatesToDisplay = (
  dates: string[],
  size: number,
  onlyDisplayFutureDates?: boolean,
): string[] => {
  if (!size) {
    return []
  }

  // Filter out past dates
  if (onlyDisplayFutureDates) {
    dates = dates.filter((date) => moment().isBefore(date))
  }
  return dates?.slice(0, size)
}

export const renderCircularDateComponents = (nextPaymentDates: string[]): JSX.Element[] => {
  return getDatesToDisplay(nextPaymentDates, 3, true).map((date, index) => {
    return <CalendarDateCircle key={index} date={date} active={index === 0} />
  })
}

export const isAccountRoutingInputRequired = (
  selectedBankAccount: LinkedAccount | undefined,
): boolean => {
  if (!selectedBankAccount) {
    return true
  }
  return (
    !selectedBankAccount?.achNumbersAvailable && !selectedBankAccount?.debitCardNumbersAvailable
  )
}

export const displayUpcomingPaymentsTile = (
  accountStatus: CardAccountDashboardStatus | undefined,
  autopayEnabled?: boolean,
  supportsMinPay?: boolean,
): boolean => {
  if (accountStatus === undefined) {
    return false
  }

  // ENG-14511 don't display Upcoming Payments tile to deactivated manual pay users
  const isDeactivated = [
    CardAccountDashboardStatus.Deactivated,
    CardAccountDashboardStatus.DeactivatedDelinquent,
  ].includes(accountStatus)
  const isDeactivatedManualPayUser = isDeactivated && !autopayEnabled
  const isDeactivatedMinPayUser = (isDeactivated && supportsMinPay) ?? false
  // Active and SuspendedPayOverTime include UpcomingPaymentsCard from CardDashboardPayment
  const userIsActiveOrPOT = [
    CardAccountDashboardStatus.Active,
    CardAccountDashboardStatus.SuspendedPayOverTime,
  ].includes(accountStatus)

  return (
    (!userIsActiveOrPOT && !isDeactivatedManualPayUser) ||
    (accountStatus === CardAccountDashboardStatus.Active && supportsMinPay) ||
    isDeactivatedMinPayUser
  )
}

type CardStatus =
  | {
      __typename: 'ActiveCardAccountStatus'
      restriction?:
        | {
            __typename?: 'CardAccountSuspendedRestriction'
            reason?: Consumer.types.CardAccountSuspensionReasonCode | null | undefined
          }
        | null
        | undefined
    }
  | {
      __typename?: string
      restriction?:
        | {
            __typename?: string
            reason?: Consumer.types.CardAccountSuspensionReasonCode | null | undefined
          }
        | null
        | undefined
    }
export const isNSF = (status: CardStatus | undefined): boolean =>
  status?.__typename === 'ActiveCardAccountStatus' &&
  status?.restriction?.__typename === 'CardAccountSuspendedRestriction' &&
  status?.restriction?.reason === Consumer.types.CardAccountSuspensionReasonCode.Nsf
