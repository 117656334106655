import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import PageImage from 'src/designSystem/components/molecules/PageImage/PageImage'
import Maintenance from 'src/assets/illustrations/Maintenance.png'

export const MaintenanceInProgressPage = 'MaintenanceInProgress'

export const MaintenanceInProgress: FC = () => {
  const {t} = useTranslation(['MaintenanceInProgress'])

  return (
    <GenericNonModalTemplate
      title={t('GiveUsAMoment')}
      description={t('SystemsDownForMaintenance')}
    >
      <PageImage image={Maintenance} />
    </GenericNonModalTemplate>
  )
}

export default MaintenanceInProgress
