import APIClient from '@possible/generated/APIClient'
import {PfDispatch, PfGetState} from 'src/store/types'

class APIClientLoan {
  dispatch?: PfDispatch
  getState?: PfGetState

  init(dispatch, getState) {
    this.dispatch = dispatch
    this.getState = getState
  }

  async getConversionOptions(id) {
    const params = {
      method: 'GetConversionOptions',
      uri: '/api/v2/loans2/GetConversionOptions',
      request: {
        include_offers: true,
        loan: {
          id,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async convertLoan(id, offer_id) {
    const params = {
      method: 'ConvertLoan',
      uri: '/api/v2/loans2/ConvertLoan',
      request: {
        loan: {
          id,
        },
        offer_id,
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoansByUserId(userId) {
    const params = {
      method: 'GetLoansByUserId',
      uri: '/api/v2/loans2/GetLoansByUserId',
      request: {
        loan: {
          userId,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoan(id) {
    const params = {
      method: 'GetLoan',
      uri: '/api/v2/loans2/GetLoan',
      request: {
        loan: {
          id,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }
}

export default new APIClientLoan()
