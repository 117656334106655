import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFElevation from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {NamedColors} from 'src/designSystem/colors'

type Props = {
  dismiss: () => void
}

export const LessThan50DollarsModal: React.FC<Props> = (props) => {
  const {t} = useTranslation(['CardDashboardPayment', 'Common'])
  return (
    <Modal transparent>
      <Box
        align="center"
        justify="center"
        height="100%"
        boxStyle={{backgroundColor: NamedColors.SEMITRANSPARENT}}
      >
        <PFElevation level={3} backgroundColor="white" borderRadius={4}>
          <Box margin="large" gap="medium" width={300}>
            <PFText variant="p_lg">{t('SorryYourBalanceMustBe')}</PFText>
            <Button size="large" mode="primary" width={300} onPress={props.dismiss}>
              {t('Common:OK')}
            </Button>
          </Box>
        </PFElevation>
      </Box>
    </Modal>
  )
}
