import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import CardPaymentAdded from 'src/assets/illustrations/CardPaymentAdded.svg'

type DebitCardAddedModalPropsTypes = {
  visible: boolean
  onDismiss: () => void
}

const DebitCardAddedModal: FC<DebitCardAddedModalPropsTypes> = (props) => {
  const {t} = useTranslation(['AddDebitCard', 'Common'])

  const {visible, onDismiss} = props

  return (
    <Overlay
      visible={visible}
      title={t('PaymentMethodAdded')}
      image={<PFSvgContain svg={CardPaymentAdded} width={'50%'} />}
      text={''}
      dismissButtonText={t('Common:OK')}
      dismissButtonSize={'medium'}
      onDismiss={(): void => {
        onDismiss()
      }}
      testID="DebitCardAddedModal"
    />
  )
}

export default DebitCardAddedModal
