import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'

/**
 * Open the UnsupportedBanks screen to allow user to check if their bank
 * is supported.
 */
export const onCheckBankSupport = (args: {
  navigation: StackScreenProps<MainStackParamList>['navigation']
}): void => {
  const {navigation} = args
  navigation.navigate('UnsupportedBanks')
}
