import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanDisbursementMethodSelectionQueryVariables = Types.Exact<{[key: string]: never}>

export type LoanDisbursementMethodSelectionQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        loanTypeInfo?: {__typename: 'LoanTypeInfo'; variant: Types.LoanTypeVariant} | null
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
  }
}

export type LoanDisbursementMethodSelectionSaveMutationVariables = Types.Exact<{
  input: Types.SetLoanDisbursementMethodInput
}>

export type LoanDisbursementMethodSelectionSaveMutation = {
  __typename?: 'Mutation'
  loanSetDisbursementMethod: {__typename?: 'SetLoanDisbursementMethodResponse'; success: boolean}
}

export type LoanDisbursementMethodSelectionPaymentMethodsForRtpQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type LoanDisbursementMethodSelectionPaymentMethodsForRtpQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    paymentMethods: {
      __typename: 'PaymentMethodCollection'
      all?: Array<
        | {__typename: 'AchPaymentMethod'; availableNetworks: Array<Types.ProcessorNetwork>}
        | {__typename: 'CheckPaymentMethod'}
        | {__typename: 'DebitCardPaymentMethod'}
        | null
      > | null
    }
  }
}

export const LoanDisbursementMethodSelectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanDisbursementMethodSelection'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'loanTypeInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'variant'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanDisbursementMethodSelectionQuery,
  LoanDisbursementMethodSelectionQueryVariables
>
export const LoanDisbursementMethodSelectionSaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'LoanDisbursementMethodSelectionSave'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'SetLoanDisbursementMethodInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDisbursementMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanDisbursementMethodSelectionSaveMutation,
  LoanDisbursementMethodSelectionSaveMutationVariables
>
export const LoanDisbursementMethodSelectionPaymentMethodsForRtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanDisbursementMethodSelectionPaymentMethodsForRTP'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'availableNetworks'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanDisbursementMethodSelectionPaymentMethodsForRtpQuery,
  LoanDisbursementMethodSelectionPaymentMethodsForRtpQueryVariables
>
