import * as Types from '../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type BankCreateLinkedAccountsMutationVariables = Types.Exact<{
  input: Types.ExchangePubTokenInput
}>

export type BankCreateLinkedAccountsMutation = {
  __typename?: 'Mutation'
  bankCreateLinkedAccounts: {
    __typename?: 'CreateLinkedAccountsResponse'
    linkedAccounts: Array<{
      __typename?: 'LinkedAccount'
      achNumbersAvailable?: boolean | null
      debitCardNumbersAvailable?: boolean | null
      id: string
      name?: string | null
      isDeletable: boolean
      isLoginRequired: boolean
      lastSyncedAt?: string | null
      mask: string
      officialName?: string | null
      preferredFundingSource?: boolean | null
      status: Types.LinkedAccountStatusCode
      type: Types.LinkedAccountType
      institution?: {__typename?: 'Institution'; name: string} | null
      ownershipSummary?: {
        __typename?: 'LinkedAccountOwnershipSummary'
        ownerType: Types.LinkedAccountOwnerType
        ownershipStatus: Types.LinkedAccountOwnershipStatus
        validationErrors?: Array<{
          __typename?: 'LinkedAccountOwnershipValidationError'
          matchScore: number
          validationType: Types.LinkedAccountOwnershipValidationType
          validationStatus: Types.LinkedAccountOwnershipValidationStatus
        }> | null
      } | null
    }>
  }
}

export const BankCreateLinkedAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'bankCreateLinkedAccounts'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ExchangePubTokenInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankCreateLinkedAccounts'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'linkedAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'debitCardNumbersAvailable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'isDeletable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastSyncedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'ownershipSummary'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'ownerType'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'ownershipStatus'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'validationErrors'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'matchScore'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'validationType'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'validationStatus'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BankCreateLinkedAccountsMutation,
  BankCreateLinkedAccountsMutationVariables
>
