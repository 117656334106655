import React from 'react'
import {createStackNavigator} from '@react-navigation/stack'

import {subStackScreenOptions} from 'src/nav/Stacks/subStackScreenOptions'
import CardViewPaymentMethods from 'src/products/card/PaymentMethods/CardViewPaymentMethods/CardViewPaymentMethods'
import {CardAdhocPaymentAmountEntry} from 'src/products/card/AdhocPayment/CardAdhocPaymentAmountEntry'
import {CardAdhocPaymentReview} from 'src/products/card/AdhocPayment/CardAdhocPaymentReview'
import {CardAdhocPaymentSuccess} from 'src/products/card/AdhocPayment/CardAdhocPaymentSuccess'
import {CardAdhocPaymentFailure} from 'src/products/card/AdhocPayment/CardAdhocPaymentFailure'
import type {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {CardAdhocPaymentProvider} from 'src/products/card/AdhocPayment/useCardAdhocPayment/CardAdhocPaymentProvider'

const {Navigator, Screen} = createStackNavigator<CardAdhocPaymentStack>()

/* Consider using `Group` after future navigation refactor. Using a nested `Navigator` is the
 * safest way to reset our current `screenOptions` without affecting the rest of the app nav. */
const CardAdhocPaymentNavigator = (): JSX.Element => {
  return (
    <CardAdhocPaymentProvider>
      <Navigator
        initialRouteName={'CardAdhocPaymentAmountEntry'}
        screenOptions={subStackScreenOptions}
      >
        <Screen component={CardAdhocPaymentReview} name={'CardAdhocPaymentReview'} />
        <Screen component={CardAdhocPaymentAmountEntry} name={'CardAdhocPaymentAmountEntry'} />
        <Screen component={CardViewPaymentMethods} name={'CardViewPaymentMethods'} />
        <Screen
          component={CardAdhocPaymentFailure}
          name={'CardAdhocPaymentFailure'}
          options={{
            cardStyle: {backgroundColor: 'transparent'},
            presentation: 'transparentModal',
          }}
        />
        <Screen
          component={CardAdhocPaymentSuccess}
          name={'CardAdhocPaymentSuccess'}
          options={{headerShown: false}}
        />
      </Navigator>
    </CardAdhocPaymentProvider>
  )
}

export {CardAdhocPaymentNavigator as CardAdhocPayment}
