import React, {FC, ReactElement} from 'react'
import {TouchableOpacity} from 'react-native'

import {
  infoCapsuleBackground,
  infoCapsuleText,
  infoCapsuleBorderColor,
} from 'src/designSystem/semanticColors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {Color} from 'src/designSystem/types'

export enum PFInfoCapsuleVariant {
  Text,
  List,
  Element,
}

export type PFInfoCapsuleTextVariantProps = {
  text: string | ReactElement
}

export type PFInfoCapsuleElementVariantProps = {
  variant: PFInfoCapsuleVariant.Element
  element: ReactElement
}

export type PFInfoCapsuleListVariantProps = {
  variant: PFInfoCapsuleVariant.List
  description: string
  messages: string[]
}

export type PFInfoCapsuleProps = {
  onPress?: () => void
  variant?: PFInfoCapsuleVariant
  testID?: string
  svgIcon?: SvgIconProps
  color?: Color
  noBorder?: boolean
} & (
  | PFInfoCapsuleTextVariantProps
  | PFInfoCapsuleListVariantProps
  | PFInfoCapsuleElementVariantProps
)

const PFInfoCapsule: FC<PFInfoCapsuleProps> = (props) => {
  const {
    onPress: handleOnPress,
    variant,
    testID,
    svgIcon,
    color,
    noBorder: shouldHaveNoBorder,
  } = props
  let content: JSX.Element | null = null

  if (variant === PFInfoCapsuleVariant.List) {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const {description, messages} = props as PFInfoCapsuleListVariantProps
    content = (
      <Box paddingLeft={'little'} gap={'tiny'}>
        <PFText color={infoCapsuleText} variant={'p_sm'}>
          {description}
        </PFText>
        <Box>
          {messages.map((message, index) => (
            <Box direction={'row'} key={index}>
              <PFText color={infoCapsuleText} variant={'p_sm'}>
                {message}
              </PFText>
            </Box>
          ))}
        </Box>
      </Box>
    )
  } else if (variant === PFInfoCapsuleVariant.Element) {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const {element} = props as PFInfoCapsuleElementVariantProps
    content = element
  } else {
    // we want to allow for 4 lines of text but on smaller screens this could be 5 lines so we set the max at 5
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const {text} = props as PFInfoCapsuleTextVariantProps
    content = (
      <Box flex>
        <PFText
          testID={testID}
          variant={'p_sm'}
          color={color ?? infoCapsuleText}
          textProps={{numberOfLines: 5}}
        >
          {text}
        </PFText>
      </Box>
    )
  }

  return (
    <TouchableOpacity onPress={handleOnPress} disabled={!handleOnPress}>
      <Box
        flex
        background={infoCapsuleBackground}
        radius={'little'}
        border={shouldHaveNoBorder ? undefined : {width: 1, color: infoCapsuleBorderColor}}
        direction={'row'}
        align={'center'}
        paddingVertical={'little'}
        paddingRight={'little'}
        paddingLeft={'small'}
        gap={'small'}
      >
        {svgIcon ? <SvgIcon {...svgIcon} /> : null}
        {content}
      </Box>
    </TouchableOpacity>
  )
}

export {PFInfoCapsule}
