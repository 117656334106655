import React from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLockupPropSingleButton} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {LoanTilaContent} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'

export type LoanTilaDisclosureTemplateProps = {
  isLoading?: boolean
  loanTilaContentData?: LoanTilaContentData
  onPressContinue: () => Promise<void>
}

const LoanTilaDisclosureTemplate = (props: LoanTilaDisclosureTemplateProps): JSX.Element => {
  const {onPressContinue: handleOnPressContinue, loanTilaContentData, isLoading} = props
  const {t} = useTranslation(['LoanApproved', 'Common'])

  const buttonProps: ButtonLockupPropSingleButton = {
    type: 'singleButton',
    primary: {
      text: t('Common:AcknowledgeAndContinue'),
      onPress: handleOnPressContinue,
      disabled: isLoading,
      loading: isLoading,
    },
  }

  return (
    <Page
      variant="generic"
      smallTopGap
      buttonProps={buttonProps}
      title={t('TilaDisclosure')}
      testID="LoanTilaDisclosureTemplate"
    >
      <LoanTilaContent {...loanTilaContentData} />
    </Page>
  )
}

export {LoanTilaDisclosureTemplate}
