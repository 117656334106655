import {useCallback} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'

import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {isAccountDelinquent} from 'src/lib/card/accountStatus'
import {useWarningModals, WarningModalKind} from 'src/products/card/hooks/useWarningModals'
import {useCards} from 'src/products/card/hooks/useCards'

/* TODO: ENG-13012
 *
 * `CardProps` is a placeholder while we refactor `useCards` to be more modular and to use Apollo Client's
 * cache at which point we will be able to fetch the data we need directly and the prop can be
 * deleted.
 */

type NavigationAction = () => void

const usePayOverTimeNavigation = (): NavigationAction => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const {accountStatus, ledgerSpentAfterPayments, cardAccount} = useCards()
  const {setModalVisible, isModalVisible} = useWarningModals()
  const autopayEnabled = cardAccount?.autopayEnabled

  const handleOnPress = useCallback(
    // return a handler function that we can call elsewhere.
    function (navigationAction: NavigationAction): NavigationAction {
      return () => {
        TrackAppEvent(CardEvents.card_dashboard_pay_over_time_cta, AppEvents.Category.Card)
        navigationAction()
      }
    },
    [],
  )

  // Note: Order matters!
  if (ledgerSpentAfterPayments < 50) {
    return handleOnPress(() => setModalVisible(WarningModalKind.LedgerSpentMinimum))
  }

  if (isAccountDelinquent(accountStatus) && !isModalVisible(WarningModalKind.POTDelinquency)) {
    return handleOnPress(() => setModalVisible(WarningModalKind.POTDelinquency))
  }

  if (autopayEnabled) {
    return handleOnPress(() => PushPage(navigation, 'CardHowPayOverTimeWorks'))
  }

  return handleOnPress(() => PushPage(navigation, 'CardHowPayOverTimeWorksManual'))
}

export {usePayOverTimeNavigation}
