import React from 'react'
import {useTranslation, Trans} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {GenericVerification} from 'src/products/loans/PhoneConfirmation/GenericVerification'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'

export type EmailVerificationTemplateProps = {
  onSendCode: () => Promise<boolean>
  onEditEmail: () => void
  onResendCode: () => Promise<boolean>
  onSubmitCode: (code: string) => Promise<void>
  email: string
  showSendCodeOverlay: boolean
}

export const EmailVerificationTemplate = ({
  onResendCode,
  onSubmitCode,
  onSendCode: handleOnSendCode,
  onEditEmail: handleOnEditEmail,
  email,
  showSendCodeOverlay,
}: EmailVerificationTemplateProps): JSX.Element => {
  const {t} = useTranslation(['Common', 'EmailConfirmation'])

  return (
    <>
      <GenericVerification
        resendAction={onResendCode}
        submitAction={onSubmitCode}
        email={email}
        verificationMethod={'EMAIL'}
      />
      <Overlay
        visible={showSendCodeOverlay}
        title={t('EmailConfirmation:VerifyEmail')}
        content={
          <PFText variant="p">
            <Trans
              t={t}
              i18nKey="EmailConfirmation:Description"
              values={{email}}
              components={{bold: <PFText variant="p_semibold" />}}
            />
          </PFText>
        }
        okayButtonSize="large"
        okayButtonText={t('EmailConfirmation:SendCodeToEmail')}
        onOkay={handleOnSendCode}
        dismissButtonSize="large"
        dismissButtonText={t('EmailConfirmation:EditEmail')}
        onDismiss={handleOnEditEmail}
        testID="EmailSendVerificationCodeModal"
      />
    </>
  )
}
