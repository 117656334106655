import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  LoanPaymentReviewContent,
  LoanPaymentReviewContentProps,
} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewTemplate/LoanPaymentReviewContent/LoanPaymentReviewContent'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'

export type LoanPaymentReviewTemplateProps = LoanPaymentReviewContentProps & {
  onPressContinue: () => void | Promise<void>
  isCounterOffer: boolean
  isSubmitting: boolean
}

const LoanPaymentReviewTemplate: FC<LoanPaymentReviewTemplateProps> = (props) => {
  const {
    accountMask,
    amountApproved,
    apr,
    isCounterOffer,
    onPressContinue,
    payments,
    preferredAccountInstitutionName: accountName,
    preferredAccountType: accountType,
    totalCost,
    totalOwed,
    isSubmitting,
    loanTypeVariant,
  } = props
  const {t} = useTranslation('LoanPaymentReview')

  const isAdvance = isAdvanceVariant(loanTypeVariant)
  const approvedTitle = isAdvance ? t('YourAdvanceIsApproved') : t('YourApplicationIsApproved')
  const title = isCounterOffer ? t('ReviewYourOffer') : approvedTitle

  return (
    <Page
      variant={'generic'}
      buttonProps={buttonLockupProperties({
        text: t(isAdvance ? 'AgreeAndContinueAdvance' : 'AgreeAndContinue'),
        testID: 'LoanPaymentReviewContinueButton',
        onPress: onPressContinue,
        disabled: isSubmitting,
        loading: isSubmitting,
      })}
      title={title}
      smallTopGap
      description={isAdvance ? t('AdvanceApprovedDescription') : undefined}
    >
      <LoanPaymentReviewContent
        accountMask={accountMask}
        amountApproved={amountApproved}
        apr={apr}
        payments={payments}
        preferredAccountInstitutionName={accountName}
        preferredAccountType={accountType}
        totalCost={totalCost}
        totalOwed={totalOwed}
        loanTypeVariant={loanTypeVariant}
      />
    </Page>
  )
}

export {LoanPaymentReviewTemplate}
