import React from 'react'
import {StyleSheet, View} from 'react-native'

import {isDeviceWeb} from 'src/lib/utils/platform'
import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import {dividerColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import DashedLine from 'src/assets/illustrations/DashedLine.svg'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'

type SeparatorVariant = 'dashed' | 'solid'
export type DividerWithTextProps = {
  text?: string
  color?: Color
  variant?: SeparatorVariant
}

// TODO: ENG-1314 Add stroke color for dashed variant of Separator component
const DashedTextLine = (lineStyles, svgProps) => (
  <View style={lineStyles}>
    <PFSvg SvgUri={DashedLine} svgProps={svgProps} />
  </View>
)

const Separator = (props: DividerWithTextProps) => {
  const {text, variant} = props
  let color = props.color
  if (!color) {
    color = dividerColor
  }
  color = DefaultVariantsColor[color] ?? color

  const dashed = variant === 'dashed'
  const lineStyles = [styles.lineView, {backgroundColor: color}]

  const svgProps = {
    width: '100%',
    height: isDeviceWeb() ? '100%' : '5',
    fill: color,
  }

  const lineComponent = dashed ? (
    <DashedTextLine lineStyles={lineStyles} svgProps={svgProps} />
  ) : (
    <View style={lineStyles} />
  )

  const textComp = text && (
    <>
      <View style={styles.textView}>
        <PFText variant="p_semibold" textAlign="center" textProps={{style: styles.textStyle}}>
          {text}
        </PFText>
      </View>
      {lineComponent}
    </>
  )
  return (
    <View style={styles.containerView}>
      {lineComponent}
      {textComp}
    </View>
  )
}
export default Separator

const styles = StyleSheet.create({
  containerView: {
    flexDirection: 'row',
  },
  lineView: {
    alignSelf: 'center',
    flex: 1,
    height: 1,
    justifyContent: 'center',
  },
  textStyle: {
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
  textView: {
    paddingHorizontal: smallGap,
  },
})
