import {title} from 'process'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {Image, ScrollView, StyleSheet} from 'react-native'

import {
  ErrorResponseCode,
  MobileGatewayApiResponse,
} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'
import {EmailPasswordLogin, EmailPasswordSignup} from 'src/api/MobileGatewayAPI/actions'
import CardLandingSplash from 'src/assets/images/card-landing-splash.png'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {smallGap} from 'src/designSystem/layout'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {EmailPasswordForm} from 'src/products/MCU/components/organisms/EmailPasswordForm/EmailPasswordForm'
import {LoginRegistrationLegal} from 'src/products/MCU/RegistrationOrLogin/LoginRegistrationLegal'
import {LoginResponseHandler} from 'src/products/MCU/RegistrationOrLogin/LoginResponseHandler'
import {usePfDispatch} from 'src/store/utils'
import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'

type Props = StackScreenProps<MainStackParamList, 'CardLandingWeb'>

export const CardLandingWeb: React.FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation('OpeningLandingPage')
  const [isBusy, setIsBusy] = useState(false)
  const [response, setResponse] = useState<MobileGatewayApiResponse | undefined>(undefined)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_registration_page_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  const navigateToLogin = (signUp: boolean): void => {
    PushPage(navigation, 'Login', {signUp})
  }

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<UserRegisterInput>({
    mode: 'all',
  })
  const dispatch = usePfDispatch()

  const conflictingAccountResponse = (response: MobileGatewayApiResponse): boolean =>
    response.getErrorCode() === ErrorResponseCode.ConflictingAccount

  const onSignUp = async (data: {
    email: string
    password: string
    stateSelected?: string | null
  }): Promise<void> => {
    setIsBusy(true)

    try {
      const result = await dispatch(
        EmailPasswordSignup(data?.email, data?.password, data.stateSelected ?? undefined),
      )
      setResponse(result)
      if (conflictingAccountResponse(result)) {
        await dispatch(EmailPasswordLogin(data.email, data.password))
      }
    } catch (e) {
      ShowException(e)
    } finally {
      setIsBusy(false)
    }
  }
  const getFormSubmitAction = (): {text: string; onSubmit: () => Promise<void>} => {
    return {
      text: t('CreateAccount'),
      onSubmit: handleSubmit(onSignUp),
    }
  }
  const capsuleErrorCodes = useMemo(
    () => ({
      [ErrorResponseCode.WrongPassword]: undefined,
      [ErrorResponseCode.PasswordAttemptsExceeded]: undefined,
      [ErrorResponseCode.InvalidAuthorizationToken]: undefined,
      [ErrorResponseCode.InvalidAccountState]: undefined,
      [ErrorResponseCode.BlacklistedPassword]: undefined,
      [ErrorResponseCode.ConflictingAccount]: undefined,
      [ErrorResponseCode.PasswordResetRequired]: undefined,
      [ErrorResponseCode.InvalidPasswordCriteria]: undefined,
    }),
    [],
  )

  return (
    <ScrollView style={styles.scrollViewContainer}>
      <Box boxStyle={styles.containerView}>
        <Box
          position="absolute"
          height={250}
          background={NamedColors.BLACK}
          paddingHorizontal={'medium'}
        >
          <Box boxStyle={styles.contentView}>
            <Box paddingTop={'enormous'} width={'100%'} align="center" justify="center">
              <Box>
                <PFText variant="h2" color="white" textAlign="center">
                  {t('GetTheCardTitle')}
                </PFText>

                <Image source={CardLandingSplash} style={styles.imageStyle} />
              </Box>
              <Box>
                <Box paddingBottom={'small'}>
                  <PFText variant="p" textAlign="center">
                    <Trans
                      t={t}
                      i18nKey="CreditLimit1"
                      components={{bold: <PFText variant="p_semibold" />}}
                    />
                  </PFText>

                  <PFText variant="p" textAlign="center">
                    <Trans
                      t={t}
                      i18nKey="CreditLimit2"
                      components={{bold: <PFText variant="p_semibold" />}}
                    />
                  </PFText>
                </Box>
                <Box direction="row" justify="around" wrap="wrap" width={314}>
                  {[t('InterestRate'), t('LateFees'), t('NoHiddenFees'), t('NoDeposit')].map(
                    (pillText) => (
                      <Box key={pillText} marginBottom={8}>
                        <PFStatusPill text={pillText} color={NamedColors.SAND} fontColor="black" />
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box paddingTop={'small'} align="center">
            <Box boxStyle={styles.emailPassword}>
              {LoginResponseHandler({
                capsuleErrorCodes,
                response,
                style: styles.errorMargin,
              })}
              <EmailPasswordForm
                showAppRevampRegistrationExperiment
                isSubmittingForm={isBusy}
                showForgotLinks={false}
                action={getFormSubmitAction()}
                navigation={navigation}
                key={title}
                showRequirements={true}
                control={control}
                errors={errors}
                watch={watch}
              />
            </Box>
            <Box paddingVertical={'small'}>
              <SvgLink
                linkType={'single'}
                onPress={(): void => navigateToLogin(false)}
                linkIcon={'internal'}
                textVariant={'p_semibold'}
                linkText={t('LoginLinkText')}
              />
            </Box>
            <Box justify="center" align="center" paddingVertical="medium">
              <PFText textAlign="center" variant="p_sm" color={NamedColors.SILVER}>
                {t('IssuerInfo')}
              </PFText>
            </Box>
            <LoginRegistrationLegal disabled={isBusy} />
          </Box>
        </Box>
      </Box>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  containerView: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  contentView: {
    alignSelf: 'center',
    paddingHorizontal: 24,
    width: '100%',
  },
  emailPassword: {width: 340},
  errorMargin: {
    marginVertical: smallGap,
  },
  imageStyle: {alignSelf: 'center', height: 250, width: 250},
  scrollViewContainer: {
    flex: 1,
  },
})
