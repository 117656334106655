import React, {FC, useCallback, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  DebitCardSubmitValues,
  failedMutationErrorText,
  throwSubmitAddDebitCardError,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'
import {formatDate, monthDate, yearMonth} from 'src/lib/utils/date'
import {
  AddPaymentMethodResultType,
  DebitPaymentMethodInput,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {useBankAddDebitPaymentMethod} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/mutations/BankAddDebitPaymentMethod/useBankAddDebitPaymentMethod'
import {DebitCardTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCardTemplate'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'

import {PaymentMethodsAlertModals} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'

type CollectDebitCardNumberForAdhocPaymentProps = StackScreenProps<
  MainStackParamList & LoanPayNavigatorStack,
  'CollectDebitCardNumberForAdhocPayment'
>

/**
 * Screen to add a new Debit account for an adhoc / one time payment.
 */
const CollectDebitCardNumberForAdhocPayment: FC<CollectDebitCardNumberForAdhocPaymentProps> = (
  props,
) => {
  const {navigation, route} = props

  const [submitAddDebitCard, {loading: isSubmitting}] = useBankAddDebitPaymentMethod()
  const [errorReason, setErrorReason] = useState<PaymentMethodsAlertModalErrorReason | undefined>()

  const onSuccessRouteDestination: keyof MainStackParamList | keyof LoanPayNavigatorStack =
    route.params.onSuccessRouteDestination

  const handleOnOkayError = useCallback(() => {
    setErrorReason(undefined)
  }, [])

  const handleOnSubmit = async (data: DebitCardSubmitValues): Promise<void> => {
    const {number, expiration, cvv} = data

    const unmaskedNumber = number.replace(/\s/g, '')
    const formattedExpiration = formatDate(expiration, yearMonth, monthDate)

    const input: DebitPaymentMethodInput = {
      cardCvv: cvv,
      cardExp: formattedExpiration,
      cardNumber: unmaskedNumber,
    }

    try {
      const {data: response, errors} = await submitAddDebitCard({
        variables: {
          input,
        },
      })
      const errorType: AddPaymentMethodResultType | undefined =
        response?.bankAddDebitPaymentMethod?.result.type
      const hasError = (errors || errorType) && errorType !== AddPaymentMethodResultType.Successful

      if (hasError) {
        setErrorReason(errorType ?? 'GENERIC_ERROR')
        TrackAppEvent(
          BankAccountManagementEvents.add_new_payment_account_failed,
          AppEvents.Category.BankAccountManagement,
          {
            type: 'debit_card',
          },
        )

        throwSubmitAddDebitCardError(errorType)
      } else {
        TrackAppEvent(
          BankAccountManagementEvents.bank_account_management_add_debit_card_success,
          AppEvents.Category.BankAccountManagement,
        )
        TrackAppEvent(
          BankAccountManagementEvents.add_new_payment_account_completed,
          AppEvents.Category.BankAccountManagement,
          {
            type: 'debit_card',
          },
        )
        // @ts-expect-error UPDATE NOTE: we need to reduce the number of potential routes this
        // type is capable of representing
        navigation.navigate(onSuccessRouteDestination ?? 'AccountManagementV2', {
          newSelectedPaymentInstrumentId:
            response?.bankAddDebitPaymentMethod?.paymentMethod?.bankingPaymentInstrumentId,
        })
      }
    } catch (e) {
      logAddPaymentMethodError(e, failedMutationErrorText)
    }
  }

  return (
    <>
      <PaymentMethodsAlertModals
        paymentType={'CARD'}
        reason={errorReason}
        onOkay={handleOnOkayError}
      />
      <DebitCardTemplate onSubmit={handleOnSubmit} loading={isSubmitting} hiddenFields={['name']} />
    </>
  )
}

export {CollectDebitCardNumberForAdhocPayment}
