import React, {useCallback, useMemo, useState} from 'react'
import {View} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {AppEvents, AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'
import {ErrorNoticeRetryCountdown} from 'src/designSystem/components/organisms/ErrorNotice/ErrorNoticeRetryCountdown'

/**
 * The max number of times to retry a failed operation.
 */
const MaxRetryCount = 5

type ErrorNoticeProps = {
  testID?: string
  title?: string
  body?: string // defaults to generic message if not provided
  onContactUs: () => void

  /**
   * If provided we will attempt a few retries and render a retry button.
   * It should be possible to just patch `refetch` from `useQuery` to this prop.
   */
  onRetry?: () => Promise<unknown> | void

  /**
   * If provided we will emit an error event to analytics.
   */
  error?: Error
  analyticCategory?: AppEventCategory
  analyticName?: AppEventName
  getContactUsComponent?: (args: {onContactUs: () => void}) => JSX.Element
}

/**
 * Generic error notice to inform the user that something went wrong. Works well with <ErrorBoundary> component
 * to detect component failures and handle gracefully.
 *
 * @example <ErrorNotice title='Some title' onContactUs={() => {}} />
 */
const ErrorNotice = (props: ErrorNoticeProps): JSX.Element => {
  const {t} = useTranslation('ErrorNotice')

  const {
    testID = 'ErrorNotice',
    title = t('GenericTitle'),
    body,
    onContactUs: handleContactUs,
    onRetry,
    error,
    analyticCategory = AppEvents.Category.GeneralError,
    analyticName = AppEvents.Name.error_tile_viewed, // Keeping this reference to "tile" so as to not fork analytics data
    getContactUsComponent = ({onContactUs: handleOnContactUs}): JSX.Element => {
      return (
        <Trans t={t} i18nKey="BodyFooter">
          If this error persist please
          <PFText
            variant="p"
            color="link"
            onPress={handleOnContactUs}
            testID={'ErrorNotice-ContactUsLink'}
          >
            {' '}
            contact us here
          </PFText>
        </Trans>
      )
    },
  } = props

  const [retryCount, setRetryCount] = useState(0)
  const [isBusy, setIsBusy] = useState(false)

  const bodyToUse = body ?? t('BodyMain')

  useFocusEffect(
    useCallback(() => {
      if (error) {
        TrackAppEvent(analyticName, analyticCategory, {error: error.message})
        Log.error(error, 'ErrorNotice')
      }
    }, [analyticCategory, analyticName, error]),
  )

  const handleRetry = useCallback(async (): Promise<void> => {
    // in reality this won't ever get called if onRetry is not provided
    // but to make TS happy we need to check
    if (!onRetry) {
      return
    }

    try {
      setIsBusy(true)
      await onRetry()
    } catch (e) {
      // do nothing, this is an error display after all
    } finally {
      setRetryCount((current) => current + 1)
      setIsBusy(false)
    }
  }, [onRetry])

  const renderedBody = useMemo(() => {
    if (onRetry && retryCount < MaxRetryCount) {
      return <ErrorNoticeRetryCountdown retryCount={retryCount} onAttemptRetry={handleRetry} />
    }

    return (
      <>
        <Box marginTop="medium">
          <PFText variant="p">{bodyToUse}</PFText>
        </Box>

        <Box marginTop="large">
          <PFText variant="p">{getContactUsComponent({onContactUs: handleContactUs})}</PFText>
        </Box>
      </>
    )
  }, [bodyToUse, handleRetry, handleContactUs, onRetry, retryCount, getContactUsComponent])

  const renderedButtons = useMemo(() => {
    if (onRetry) {
      return (
        <Box marginTop="large">
          <Button
            width="100%"
            mode="primary"
            size="large"
            onPress={handleRetry}
            disabled={isBusy}
            loading={isBusy}
            testID={'ErrorNotice-RetryButton'}
          >
            {t('RetryButton')}
          </Button>
        </Box>
      )
    }

    return null
  }, [onRetry, handleRetry, isBusy, t])

  return (
    <View testID={testID}>
      <SvgIcon name={'warning'} colorVariant={'warning'} size={'large'} isFilled />

      <Box paddingRight={70} marginTop={12}>
        <PFText variant="h3">{title}</PFText>
      </Box>

      {renderedBody}

      {renderedButtons}
    </View>
  )
}

export {ErrorNotice}
export type {ErrorNoticeProps}
