import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {QueryStatus, StateBase} from '../types'
import {User} from '../types/types.mobile.generated'
import {NextQueryStatus} from '../utils'
import {GetMe} from './methods'

export type UserState = StateBase & {
  token?: string
  me?: User
}

const initialState: UserState = {
  queryStatus: QueryStatus.Initial,
  applyForCardQueryStatus: QueryStatus.Initial,
  getUserNextAvailablePaymentDateQueryStatus: QueryStatus.Initial,
  getUserNextAvailablePaymentDate: undefined,
}

/**
 * Do not use! Make a co-located query instead.
 */
export const GetMeAction = createAsyncThunk('User/GetMe', async () => {
  return GetMe()
})

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase('CLEAR_USER_ACTION', () => initialState)
      .addCase(GetMeAction.pending, (state) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Pending)
        state.error = undefined
      })
      .addCase(GetMeAction.fulfilled, (state, action) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Fulfilled)
        state.me = action.payload
      })
      .addCase(GetMeAction.rejected, (state, action) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Rejected)
        state.error = action.error
      })
  },
})

export const {actions} = UserSlice

export default UserSlice.reducer
