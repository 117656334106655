import {useEffect} from 'react'

import {userIdSelector, userEmailSelector} from 'src/lib/user/selector'
import {usePfSelector} from 'src/store/utils'
import {initializeAmplitudeExperimentation} from 'src/lib/experimentation/amplitude/amplitude'

export const useExperimentationWrapper = (authenticated: boolean): void => {
  const userId = usePfSelector(userIdSelector)
  const email = usePfSelector(userEmailSelector)

  useEffect(() => {
    void initializeAmplitudeExperimentation(userId, email)
  }, [userId, authenticated, email])
}
