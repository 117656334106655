import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export const LearnMoreAboutPaymentAccountsInline: FC = () => {
  const {t} = useTranslation(['AccountManagement', 'Common'])
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const handleClickLearnMore = (): void => {
    navigation.navigate('AccountsWithPossible')
  }

  return (
    <View style={styles.view}>
      <PFText variant="p">
        <SvgLink
          onPress={handleClickLearnMore}
          linkType={'inline'}
          testID="Primary-Account-Learn-More-Link"
          linkText={t('Common:LearnMore')}
          linkIcon="info"
          linkIconPlacement="before"
          textVariant="p"
        />
        <PFText variant="p">{t('LearnMoreAboutAccounts')} </PFText>
      </PFText>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // center vertically
    alignContent: 'center',
  },
})
