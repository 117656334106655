import React, {FC, ReactElement, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {
  ButtonLockupProps,
  ButtonLockupPropsPrimary,
  CheckBoxProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {RadioButtonList} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {NoAutoPayOverlay} from 'src/products/loans/LoanApprovedActivation/components/NoAutoPayOverlay/NoAutoPayOverlay'
import {
  AcceptAgreementsContinueResult,
  AcceptAgreementsPaymentMethodTypeName,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'

enum AutoPayAgreementOption {
  AcceptAutoPay = 'AcceptAutoPay',
  NoAutoPay = 'NoAutoPay',
}

export type AcceptLoanAndAutoPayAgreementsBaseTemplateProps = {
  testID: string
  title?: string
  description?: string | ReactElement
  isLoading: boolean
  isSubmitting: boolean
  isError: boolean
  isExtendedInstallmentPlan?: boolean
  defaultPaymentMethod: {
    __typename: AcceptAgreementsPaymentMethodTypeName
    mask?: string
  }
  /* optionally override the AutoPay agreement accept/deny options content */
  autoPayAgreementOptions?: {
    acceptOption?: string | ReactElement
    doNotAcceptOption?: string | ReactElement
  }
  /* if true a checkbox for "I accept the Loan Agreement" will be shown */
  showLoanAgreementCheckbox?: boolean
  onOpenAutoPayAgreement: () => void
  onOpenLoanAgreement: (args?: {isExtendedInstallmentPlan?: boolean}) => void
  onAcceptAndContinue: (args: AcceptAgreementsContinueResult) => void | Promise<void>
}

/**
 * Base template for a screen that asks uses to accept the AutoPay Agreement, the Loan Agreement, or both.
 * This should not be used directly by screens, you probably want AcceptLoanAndAutoPayAgreementsTemplate
 * or AcceptAutoPayAgreementTemplate.
 */
export const AcceptLoanAndAutoPayAgreementsBaseTemplate: FC<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps
> = (props) => {
  const {t} = useTranslation(['LoanApproved', 'Common'])
  const {
    title = t('AcceptAgreements'),
    description = t('AcceptACHDescription'),
    onAcceptAndContinue,
    testID,
    defaultPaymentMethod,
    autoPayAgreementOptions,
    isExtendedInstallmentPlan,
    isLoading,
    isSubmitting,
    isError,
    showLoanAgreementCheckbox,
    onOpenAutoPayAgreement: handleOnOpenAutoPayAgreement,
    onOpenLoanAgreement,
  } = props

  const [typeOfPaymentMethod, setTypeOfPaymentMethod] =
    useState<AcceptAgreementsPaymentMethodTypeName>(defaultPaymentMethod.__typename)
  const [selectedAutoPayOption, setSelectedAutoPayOption] = useState<
    AutoPayAgreementOption | undefined
  >(undefined)
  const [didAcceptLoanAgreement, setDidAcceptLoanAgreement] = useState<boolean>(false)
  const [isNoAutoPayOverlayVisible, setIsNoAutoPayOverlayVisible] = useState<boolean>(false)

  const onAgreeAndContinue = (): void => {
    void onAcceptAndContinue({
      didAcceptAutoPayAgreement: selectedAutoPayOption === AutoPayAgreementOption.AcceptAutoPay,
      didAcceptLoanAgreement: showLoanAgreementCheckbox ? didAcceptLoanAgreement : undefined,
      setDisbursementMethodToCheck: typeOfPaymentMethod === 'CheckPaymentMethod',
    })
  }
  const handleOnOpenLoanAgreement = (): void => {
    onOpenLoanAgreement({
      isExtendedInstallmentPlan,
    })
  }

  const autoPayRadioOptions = [
    {
      id: AutoPayAgreementOption[AutoPayAgreementOption.AcceptAutoPay],
      // use the default text unless an override was provided
      text: autoPayAgreementOptions?.acceptOption ?? (
        <PFText>
          <Trans
            i18nKey={'AutoPayAuthorization'}
            values={{mask: defaultPaymentMethod.mask}}
            t={t}
            components={{
              PaymentAuthLink: (
                <SvgLink
                  onPress={handleOnOpenAutoPayAgreement}
                  linkText={'Electronic Repayment Authorization'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      ),
      testID: 'Accept-Ach-Agreement-Id',
    },
    {
      id: AutoPayAgreementOption[AutoPayAgreementOption.NoAutoPay],
      text:
        // use the default text unless an override was provided
        autoPayAgreementOptions?.doNotAcceptOption ??
        t('DeclineAgreement', {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          paymentMethodText:
            typeOfPaymentMethod === 'DebitCardPaymentMethod'
              ? t('InstantDebit')
              : t('DirectDeposit'),
        }),
      testID: 'Accept-Agreement-No-Auto-Pay-Id',
    },
  ]

  const handleOnAutoPayRadioButtonPress = (option: string): void => {
    if (option === AutoPayAgreementOption.AcceptAutoPay.valueOf()) {
      setSelectedAutoPayOption(AutoPayAgreementOption.AcceptAutoPay)
      // we use the default disbursement method if they accept the autopay agreement
      setTypeOfPaymentMethod(defaultPaymentMethod.__typename)
    } else if (
      option === AutoPayAgreementOption.NoAutoPay.valueOf() &&
      selectedAutoPayOption !== AutoPayAgreementOption.NoAutoPay
    ) {
      // if they chose to not accept auto pay, we confirm with the NoAutoPayOverlay
      setIsNoAutoPayOverlayVisible(true)
    }
  }

  const handleOnConfirmNoAutoPay = (): void => {
    setTypeOfPaymentMethod('CheckPaymentMethod')
    setSelectedAutoPayOption(AutoPayAgreementOption.NoAutoPay)
    setIsNoAutoPayOverlayVisible(false)
  }
  const handleOnCancelNoAutoPayOverlay = (): void => {
    setIsNoAutoPayOverlayVisible(false)
  }

  const onAcceptLoanAgreement = (): void => {
    setDidAcceptLoanAgreement(!didAcceptLoanAgreement)
  }
  const primaryAgreeAndContinueAction: ButtonLockupPropsPrimary = {
    text: t('Common:AgreeAndContinue'),
    onPress: onAgreeAndContinue,
    disabled:
      isLoading ||
      isSubmitting ||
      (showLoanAgreementCheckbox
        ? // if showing the loan agreement checkbox they must check it first
          selectedAutoPayOption === undefined || !didAcceptLoanAgreement
        : // if only showing autopay agreement loan checkbox is ignored
          selectedAutoPayOption === undefined),
    testID: 'Agree-Continue-Button',
    loading: isSubmitting,
  }
  const secondaryAcceptLoanAgreementCheckbox: CheckBoxProps = {
    text: (
      <PFText variant={'p'}>
        <Trans i18nKey={'AcceptLoanAgreementText'} t={t}>
          I accept the
          <SvgLink
            onPress={handleOnOpenLoanAgreement}
            linkText={'Loan Agreement'}
            linkType={'inline'}
          />
        </Trans>
      </PFText>
    ),
    onCheck: onAcceptLoanAgreement,
    checked: didAcceptLoanAgreement,
    testID: 'AcceptLoanAgreement-checkbox',
  }

  const buttonLockupProps: ButtonLockupProps = showLoanAgreementCheckbox
    ? {
        type: 'interstitial',
        primary: primaryAgreeAndContinueAction,
        checkBox: secondaryAcceptLoanAgreementCheckbox,
      }
    : {
        type: 'singleButton',
        primary: primaryAgreeAndContinueAction,
      }

  return (
    <BaseTemplate
      isLoading={isLoading}
      isError={isError}
      pageTitle={title}
      showTileBorder={false}
      testID={testID}
    >
      <Page
        title={title}
        titleTextAlignment="left"
        description={description}
        buttonProps={buttonLockupProps}
        variant={'generic'}
        smallTopGap
      >
        <Box paddingBottom={'medium'}>
          <RadioButtonList
            options={autoPayRadioOptions}
            onPress={(option): void => handleOnAutoPayRadioButtonPress(option)}
            selectedOption={selectedAutoPayOption?.valueOf() ?? ''}
          />
        </Box>
        <NoAutoPayOverlay
          isVisible={isNoAutoPayOverlayVisible}
          isExtendedInstallmentPlan={isExtendedInstallmentPlan}
          onConfirmNoAutoPay={handleOnConfirmNoAutoPay}
          onCancel={handleOnCancelNoAutoPayOverlay}
        />
      </Page>
    </BaseTemplate>
  )
}
