import React, {FunctionComponent, ReactText, ReactElement, ReactNode} from 'react'
import {View, TextStyle} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type DualLineTextProps = {
  textAlign?: TextStyle['textAlign']
  disabled?: boolean
  text: ReactText | ReactElement | ReactNode
  subText?: ReactText
}

const DualLineText: FunctionComponent<DualLineTextProps> = ({
  textAlign,
  disabled,
  text,
  subText,
}) => {
  const textVariant = subText ? 'p_semibold' : 'p'

  return (
    <View>
      {React.isValidElement(text) ? (
        React.cloneElement<any>(text, {disabled, variant: textVariant})
      ) : (
        <PFText textAlign={textAlign} variant={textVariant} disabled={disabled}>
          {text}
        </PFText>
      )}
      {subText ? (
        <PFText textAlign={textAlign} variant={textVariant} disabled={disabled}>
          {subText}
        </PFText>
      ) : null}
    </View>
  )
}

export default DualLineText
