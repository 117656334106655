import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {TextInputProps, View} from 'react-native'

import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import styles from 'src/designSystem/components/atoms/PFFieldBase/styles'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {Color} from 'src/designSystem/types'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type AssistiveContentProps = TextInputProps & {
  label: string
  error?: string
  infoMessage?: string
  fieldState: PFFieldBaseStateTypes
}

const AssistiveContent: FC<AssistiveContentProps> = (props) => {
  const {label, fieldState, error, infoMessage} = props
  const {t} = useTranslation('Common')

  if (fieldState !== PFFieldBaseStates.ERROR && !infoMessage) {
    return null
  }

  const errorIcon = <SvgIcon name={'error'} colorVariant={'error'} size={'small'} isFilled />
  const infoIcon = <SvgIcon name={'info'} colorVariant={'info'} size={'small'} isFilled />
  const textColor: Color = fieldState === PFFieldBaseStates.ERROR ? 'error' : 'textPrimary'
  const icon = fieldState === PFFieldBaseStates.ERROR ? errorIcon : infoIcon
  const message = error ?? infoMessage ?? t('LabelIsRequired', {label})

  return (
    <View style={styles.assistiveTextViewWrapper}>
      <View style={styles.assistiveIconWrapper}>{icon}</View>
      <View style={styles.assistiveTextWrapper}>
        <PFText variant={'p_sm'} color={textColor}>
          {message}
        </PFText>
      </View>
    </View>
  )
}

export default AssistiveContent
