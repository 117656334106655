// Alert
export const alertBorderWidth = 2
export const alertIconWidth = 16
export const alertIconHeight = 12

// Progress Bar
export const fineProgressBarThickness = 4
export const mediumProgressBarThickness = 8
export const thickProgressBarThickness = 16

// Activity Indicator
export const defaultActivityIndicatorSize: number = 20 as number

// Attempts List Header
export const separatorWidth = 1

//Button Focused Border
export const buttonFocusBorderWidth = 2
export const buttonFocusBorderDistance = 4

// Page defaults
export const defaultExtraHeightOffset: number = 75 as const //default from react-native-keyboard-aware-scroll-view lib

// Callout
export const calloutBorderRadius = 7

// Thubmnail
export const thumbnailSize = 30

// Icon
export const defaultIconSize = 50

// Icon Button
export const iconButtonSizeSmall = 45
export const iconButtonPadding = 3.5
export const iconButtonPaddingSmall = 3

// Generic old button
export const maxButtonWidth = 400
export const primaryButtonHeight = 56
export const secondaryButtonHeight = 40
export const tertiaryButtonHeight = 48
export const buttonBorderRadius = 4
export const buttonBorderWidth = 1
export const tertiaryButtonBorderWidth = 2
export const stackedButtonSpacing = 8
export const buttonHorizontalMargin = 16
export const buttonVerticalMargin = 8
// Login Page Layout
export const loginPageTitleWidth = 283
export const loginPageLogoWidth = 66

// Basic Card
export const basicTileBorderRadius = 8
export const basicTileElevation = 2

// Tab List
export const tabListActiveBorderWidth = 4
export const tabListBorderWidth = 1
