import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  BankRoutingAndAccountFormPageContainer,
  BankRoutingAndAccountFormPageContainerProps,
} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageContainer'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'

export type BankVerifyRoutingAndAccountPropsFromRouter = Pick<
  StackScreenProps<MainStackParamList & LoanPayNavigatorStack, 'BankVerifyRoutingAndAccount'>,
  'route'
>
/**
 * Screen to verify an existing bank account's routing + account numbers.
 */
export const BankVerifyRoutingAndAccount: React.FC<BankVerifyRoutingAndAccountPropsFromRouter> = (
  props: BankVerifyRoutingAndAccountPropsFromRouter,
) => {
  const {route} = props
  const {linkedAccountIdToAssociateWith, onSuccessRouteDestination, accountNumberMask, onSuccess} =
    route.params

  const {t} = useTranslation(['Bank', 'Common'])
  const title = (
    <Trans t={t} i18nKey="VerifyBankDetailsEndingIn" values={{mask: accountNumberMask}}>
      <PFText variant="h1">Verify your bank details for the</PFText>
      <PFText variant="h1" color={NamedColors.PRODUCT_BLUE}>
        account ending in 1234
      </PFText>
    </Trans>
  )
  usePageViewedAnalytics({
    eventName: BankAccountManagementEvents.bank_verify_routing_and_account_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
  })

  const handleOnSuccess: BankRoutingAndAccountFormPageContainerProps['onSuccess'] = ({
    newAchPaymentMethod,
  }) => {
    onSuccess?.({newAchPaymentMethod})
    TrackAppEvent(
      BankAccountManagementEvents.bank_verify_routing_and_account_submit_completed,
      AppEvents.Category.BankAccountManagement,
    )
  }

  const handleOnFailure: BankRoutingAndAccountFormPageContainerProps['onFailure'] = ({
    errorReason,
  }) => {
    TrackAppEvent(
      BankAccountManagementEvents.bank_verify_routing_and_account_submit_failed,
      AppEvents.Category.BankAccountManagement,
      {
        reason: errorReason,
      },
    )
  }

  const handleOnErrorBoundary: BankRoutingAndAccountFormPageContainerProps['onErrorBoundary'] = (
    error,
  ) => {
    TrackAppEvent(
      BankAccountManagementEvents.bank_verify_routing_and_account_error_viewed,
      AppEvents.Category.BankAccountManagement,
      {
        error: error.message,
      },
    )
    logAddPaymentMethodError(error, 'BankVerifyRoutingAndAccount render failed and threw exception')
  }
  return (
    <BankRoutingAndAccountFormPageContainer
      title={title}
      description={t('YouCanFindThisInfoWithBank')}
      linkedAccountIdToAssociateWith={linkedAccountIdToAssociateWith}
      successRouteDestination={onSuccessRouteDestination}
      onSuccess={handleOnSuccess}
      onFailure={handleOnFailure}
      onErrorBoundary={handleOnErrorBoundary}
      accountNumberMask={accountNumberMask}
    />
  )
}
