import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'

export type OnPressContinueProps = {
  eventCategory: AppEventCategory
  eventName: AppEventName
  eventArgs?: {[key: string]: unknown}
  goToPaymentConfirm: () => void
  handleOnChangeSelectedButton?: (value: string) => void
  paymentValue: string
}

export const onPressContinue = (props: OnPressContinueProps): void => {
  const {
    eventCategory,
    eventName,
    eventArgs,
    goToPaymentConfirm,
    handleOnChangeSelectedButton,
    paymentValue,
  } = props
  handleOnChangeSelectedButton?.(paymentValue)

  TrackAppEvent(eventName, eventCategory, eventArgs)

  goToPaymentConfirm()
}

export type ButtonActionEventProps = {
  eventCategory: AppEventCategory
  eventName: AppEventName
  eventArgs?: {[key: string]: unknown}
}
