import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AcceptAgreementsQueryVariables = Types.Exact<{[key: string]: never}>

export type AcceptAgreementsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            mask?: string | null
          }
        | {
            __typename: 'CheckPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            mask?: string | null
          }
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            mask?: string | null
          }
      > | null
    }
  }
}

export type AcceptAgreementsGetAgreementDocUrlMutationVariables = Types.Exact<{
  loanId: Types.Scalars['ID']['input']
  type: Types.LoanDocumentType
}>

export type AcceptAgreementsGetAgreementDocUrlMutation = {
  __typename?: 'Mutation'
  loanGetDocument?: {__typename?: 'PresignedUrl'; url: string} | null
}

export type AcceptAgreementsUpdateLoanActivationAgreementsMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
  acceptAutoPayAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptLoanAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type AcceptAgreementsUpdateLoanActivationAgreementsMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export type AcceptAgreementsSetLoanDisbursementMethodMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
  disbursementMethod: Types.LoanPaymentMethod
}>

export type AcceptAgreementsSetLoanDisbursementMethodMutation = {
  __typename?: 'Mutation'
  loanSetDisbursementMethod: {__typename?: 'SetLoanDisbursementMethodResponse'; success: boolean}
}

export const AcceptAgreementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AcceptAgreements'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'CheckPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptAgreementsQuery, AcceptAgreementsQueryVariables>
export const AcceptAgreementsGetAgreementDocUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsGetAgreementDocUrl'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanDocumentType'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGetDocument'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'url'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsGetAgreementDocUrlMutation,
  AcceptAgreementsGetAgreementDocUrlMutationVariables
>
export const AcceptAgreementsUpdateLoanActivationAgreementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsUpdateLoanActivationAgreements'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptAutoPayAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptAutoPayAgreement'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptAutoPayAgreement'},
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptLoanAgreement'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsUpdateLoanActivationAgreementsMutation,
  AcceptAgreementsUpdateLoanActivationAgreementsMutationVariables
>
export const AcceptAgreementsSetLoanDisbursementMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsSetLoanDisbursementMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'disbursementMethod'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanPaymentMethod'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDisbursementMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'disbursementMethod'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'disbursementMethod'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsSetLoanDisbursementMethodMutation,
  AcceptAgreementsSetLoanDisbursementMethodMutationVariables
>
