import React, {useState, useEffect, useCallback} from 'react'
import {View, ViewStyle} from 'react-native'

import {snackbarIfError} from 'src/lib/Snackbar'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import {MobileGatewayApiResponse} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'

type Props = {
  capsuleErrorCodes: string[] | {[key: string]: string | undefined}
  response: MobileGatewayApiResponse | undefined
  style?: ViewStyle
}

// capsuleErrorCodes can be either an array or an object of {errorCode: errorMessage}
// For arrays, use the error message provided in response
// For objects, if the object has a key for the error code, return that, otherwise use the error message in response
export const getCapsuleErrorMessage = (
  response: MobileGatewayApiResponse,
  capsuleErrorCodes: Props['capsuleErrorCodes'],
): string | undefined => {
  const errorCode = response.getErrorCode() ?? ''
  let errorMessage: string | undefined

  // If capsuleErrorCodes is array, convert it to an object
  if (Array.isArray(capsuleErrorCodes)) {
    capsuleErrorCodes = capsuleErrorCodes.reduce((acc, i) => {
      acc[i] = undefined
      return acc
    }, {})
  }

  if (capsuleErrorCodes[errorCode]) {
    errorMessage = capsuleErrorCodes[errorCode]
  } else if (Object.keys(capsuleErrorCodes).includes(errorCode)) {
    errorMessage = response?.getErrorStr()
  }

  return errorMessage
}

/** Intended to be used once we allow multiple languages. But we never implemented this
 * work on the server. Currently useful to show snackbar error only
 * for `MobileGatewayApiResponse` errors.
 */
const LoginResponseHandler = (props: Props): React.JSX.Element | undefined => {
  const {response, capsuleErrorCodes, style} = props
  const [errorMessage, setErrorMessage] = useState('')

  const handleResponse = useCallback(
    (result: MobileGatewayApiResponse) => {
      const errorCode = result?.getErrorCode()
      if (errorCode) {
        const capsuleErrorMsg = getCapsuleErrorMessage(result, capsuleErrorCodes)
        if (capsuleErrorMsg) {
          setErrorMessage(capsuleErrorMsg)
        } else {
          snackbarIfError(result)
        }
      }
    },
    [capsuleErrorCodes],
  )

  useEffect(() => {
    if (response) {
      void handleResponse(response)
    } else {
      setErrorMessage('')
    }
  }, [response, handleResponse])

  return errorMessage === '' ? undefined : (
    <View style={style ?? undefined}>
      <Alert description={errorMessage} level={'error'} />
    </View>
  )
}

export {LoginResponseHandler}
