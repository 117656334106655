import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CardActionListItemProps,
  CardActionListItem,
} from 'src/products/card/components/molecules/CardActionListItem'
import {DefaultVariantsColor} from 'src/designSystem/colors'

export const UnlinkedBankPaymentMethodLink: FC<Partial<CardActionListItemProps>> = ({
  onPress,
  ...props
}) => {
  const {t} = useTranslation('CardAdhocPayment')

  return (
    <CardActionListItem
      isFirstChild
      onPress={onPress}
      subTitle={t('RelinkAccount')}
      title={t('AccountUnlinked')}
      titleColor={DefaultVariantsColor.error}
      {...props}
    />
  )
}
