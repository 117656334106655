/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull react_native_typed_events'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/implementation/react_native_typed_events)
 */

import * as amplitude from '@amplitude/analytics-react-native'

export type ReactNativeClient = amplitude.Types.ReactNativeClient
export type BaseEvent = amplitude.Types.BaseEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions

export type Environment = 'staging' | 'production'

export const ApiKey: Record<Environment, string> = {
  staging: '8f47b924543db006ac7b59f113902605',
  production: '47cf9c642eeb2c284d0d38b544d11dc5',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'react_native_typed_events',
    versionId: '9a37a45c-96e5-4ba0-913e-a60f19d5e054',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: {configuration?: ReactNativeOptions}
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: {apiKey: string; configuration?: ReactNativeOptions}
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: {instance: ReactNativeClient}
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  '[Branch] campaign'?: any
  '[Branch] channel'?: any
  '[Branch] feature'?: any
  '[Branch] tags'?: any
  '[Possible Finance] [AB] account_eligibility'?: any
  '[Possible Finance] [AB] account_summary'?: any
  '[Possible Finance] [AB] alabama_enabled'?: any
  '[Possible Finance] [AB] alaska_enabled'?: any
  '[Possible Finance] [AB] auto_email_verification'?: any
  '[Possible Finance] [AB] automated_recovery'?: any
  '[Possible Finance] [AB] bank_link_position'?: any
  '[Possible Finance] [AB] chat_as_contact_us'?: any
  '[Possible Finance] [AB] chat_bot_in_mobile'?: any
  '[Possible Finance] [AB] chime_support'?: any
  '[Possible Finance] [AB] cont_uw'?: any
  '[Possible Finance] [AB] contact_us_in_app'?: any
  '[Possible Finance] [AB] credit_pull_messaging'?: any
  '[Possible Finance] [AB] debit_card_skip_account'?: any
  '[Possible Finance] [AB] delaware_enabled'?: any
  '[Possible Finance] [AB] enable_processor_lpp'?: any
  '[Possible Finance] [AB] enable_processor_repeatusers_lpp'?: any
  '[Possible Finance] [AB] fee_fighter'?: any
  '[Possible Finance] [AB] graphQlEnabled'?: any
  '[Possible Finance] [AB] graphQLEnabled'?: any
  '[Possible Finance] [AB] id_instructions_popup'?: any
  '[Possible Finance] [AB] id_scan_first'?: any
  '[Possible Finance] [AB] illinois_enabled'?: any
  '[Possible Finance] [AB] indiana_enabled'?: any
  '[Possible Finance] [AB] intro_login_button'?: any
  '[Possible Finance] [AB] loginMfa'?: any
  '[Possible Finance] [AB] louisiana_enabled'?: any
  '[Possible Finance] [AB] lwf_enable_leadbank'?: any
  '[Possible Finance] [AB] lwf_enable_leadbank_4newusers'?: any
  '[Possible Finance] [AB] lwfBalanceCheck'?: any
  '[Possible Finance] [AB] master_card_support'?: any
  '[Possible Finance] [AB] michigan_enabled'?: any
  '[Possible Finance] [AB] mississippi_enabled'?: any
  '[Possible Finance] [AB] missouri_enabled'?: any
  '[Possible Finance] [AB] newDebitCardValidation'?: any
  '[Possible Finance] [AB] newIssuerNameValidation'?: any
  '[Possible Finance] [AB] no_cool_off_charged_off'?: any
  '[Possible Finance] [AB] oklahoma_enabled'?: any
  '[Possible Finance] [AB] onboarding'?: any
  '[Possible Finance] [AB] one_min_text_1'?: any
  '[Possible Finance] [AB] online_bank_support'?: any
  '[Possible Finance] [AB] pay_now'?: any
  '[Possible Finance] [AB] phone_entry_first'?: any
  '[Possible Finance] [AB] plaid_assets_enabled'?: any
  '[Possible Finance] [AB] plaid_rux'?: any
  '[Possible Finance] [AB] possible_card_iam'?: any
  '[Possible Finance] [AB] prequal_enabled'?: any
  '[Possible Finance] [AB] reclaim_auth_optimization'?: any
  '[Possible Finance] [AB] reclaim_phasing_out'?: any
  '[Possible Finance] [AB] redesign_pay_now'?: any
  '[Possible Finance] [AB] refund_me'?: any
  '[Possible Finance] [AB] rhodeIslandEnabled'?: any
  '[Possible Finance] [AB] south_carolina_enabled'?: any
  '[Possible Finance] [AB] stateSelectionCta'?: any
  '[Possible Finance] [AB] storybook'?: any
  '[Possible Finance] [AB] uw_paystub_test'?: any
  '[Possible Finance] [AB] zendesk_messenger'?: any
  '[Possible Finance] adId'?: any
  '[Possible Finance] adName'?: any
  '[Possible Finance] adSetId'?: any
  '[Possible Finance] adSetName'?: any
  '[Possible Finance] advertisingPartnerName'?: any
  '[Possible Finance] app_ui_version'?: any
  '[Possible Finance] apple_signin'?: any
  '[Possible Finance] application_count'?: any
  '[Possible Finance] campaign'?: any
  '[Possible Finance] campaignId'?: any
  '[Possible Finance] channel'?: any
  '[Possible Finance] city'?: any
  '[Possible Finance] country'?: any
  '[Possible Finance] creativeId'?: any
  '[Possible Finance] creativeName'?: any
  '[Possible Finance] email'?: any
  '[Possible Finance] feature'?: any
  '[Possible Finance] first_name'?: any
  '[Possible Finance] google_oauth'?: any
  '[Possible Finance] intro_login_button'?: any
  '[Possible Finance] last_name'?: any
  '[Possible Finance] loan_active_date'?: any
  '[Possible Finance] loan_chargedoff_date'?: any
  '[Possible Finance] loan_count'?: any
  '[Possible Finance] loan_created_date'?: any
  '[Possible Finance] loan_decision_date'?: any
  '[Possible Finance] loan_original_amount'?: any
  '[Possible Finance] loan_paidoff_date'?: any
  '[Possible Finance] loan_remaining_amount'?: any
  '[Possible Finance] loan_status'?: any
  '[Possible Finance] loan_substatus'?: any
  '[Possible Finance] loans_failed_payments'?: any
  '[Possible Finance] phone'?: any
  '[Possible Finance] secondaryPublisher'?: any
  '[Possible Finance] state'?: any
  '[Possible Finance] state_dropdown'?: any
  '[Possible Finance] time_zone'?: any
  '[Possible Finance] ura_id'?: any
  '[Possible Finance] ura_status'?: any
  '[Possible Finance] ura_type'?: any
  '[Possible Finance] user_created_date'?: any
  '[Possible Finance] user_status'?: any
  '[Possible Finance] user_substatus'?: any
  '[Possible Finance] zip_code'?: any
  '[Singular] campaign'?: any
  '[Singular] media source'?: any
  '555555 [Possible Finance] [AB] intro_login_button'?: any
  '555555 [Possible Finance] apple_signin'?: any
  'Account Management V2'?: string
  'Alabama Enabled'?: any
  'Auto-Navigate to Loan Application'?: any
  'Backend Test 2'?: any
  'BackendFlag'?: any
  'BFF'?: any
  'Card Friends & Family'?: any
  'Cohort'?: any
  'google_oauth'?: any
  'GraphQL Enabled'?: any
  'IAM Possible Cards'?: any
  'ignore_user'?: any
  'initial_referrer'?: any
  'initial_referring_domain'?: any
  'initial_utm_campaign'?: any
  'initial_utm_content'?: any
  'initial_utm_medium'?: any
  'initial_utm_source'?: any
  'initial_utm_term'?: any
  'Iowa Enabled'?: any
  'is_card_user'?: string
  'Kansas Enabled'?: any
  'Kentucky Enabled'?: any
  'keyword'?: any
  'loans_failed_payments'?: any
  'Login MFA'?: any
  'Login on Sign-up Failure'?: any
  'Login/Sign up Redesign'?: any
  'LPP issuer name pilot validation'?: any
  'LWF Balance Check'?: any
  'New debit card validation'?: any
  'New Experiement'?: any
  'New Feature Flag'?: any
  'Payment Schedule Survey Enabled'?: any
  'Possible Card'?: any
  'Possible Card WIP'?: any
  'PossibleCard Experiment'?: any
  'Pre-Qualified Enabled'?: any
  'Reclaim Phasing Out'?: any
  'referrer'?: any
  'referring_domain'?: any
  'registered_for_out_of_state_wait_list'?: any
  'registered_for_wait_list'?: any
  'registeredForWaitList'?: any
  'Rhode Island'?: any
  'Spanish Language'?: any
  'State Selection CTA'?: any
  'Storybook'?: any
  'TAP_AutoNav_To_Application'?: any
  'TAP_Cancel_Too_Low'?: any
  'TAP_LoginOnSignupFailure'?: any
  'TAP_LoginSignupRedesign'?: any
  'TAP_Prequal_Interstitial'?: any
  'TAP_Prequal_Interstitial_Skip'?: any
  'TAP_Prequal_Link_Account'?: any
  'TAP_Prequal_No_Data'?: any
  'TAP_Prequalification'?: any
  'TAP_Prequalification_Enabled'?: any
  'TAP_PrequalLoanSlider'?: any
  'TAP_TJC_TEST_1'?: any
  'TAP_Tutorial_Screen'?: any
  'TAP_Update_Payment_Schedule_Screen'?: any
  'TAP_Update_State_Screen_Copy'?: any
  'TAP_Update_State_Screen_Text'?: any
  'Tennessee Enabled'?: any
  'TL_Experiments'?: any
  'Tutorial Screen'?: any
  'utm_campaign'?: any
  'utm_content'?: any
  'utm_medium'?: any
  'utm_source'?: any
  'utm_term'?: any
}

export interface LoansAdhocAmountEntryCtaProperties {
  args?: {
    paymentAmount?: {
      [k: string]: any
    }
    paymentIntention?: {
      [k: string]: any
    }
  }
  category?: any
}

export interface LoansAdhocAmountEntryErrorViewedProperties {
  category?: any
}

export interface LoansAdhocAmountEntryScreenViewedProperties {
  category?: any
}

export interface LoansAdhocDashboardMakeAPaymentCtaProperties {
  category?: any
}

export interface LoansAdhocFailureModalCtaCancelProperties {
  category?: any
}

export interface LoansAdhocFailureModalCtaTryAgainProperties {
  category?: any
}

export interface LoansAdhocFailureModalViewedProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodLinkCtaProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodsAddBankCtaProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodsBankSelectCtaProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodsErrorViewedProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodsSavePaymentCtaProperties {
  category?: any
}

export interface LoansAdhocPaymentMethodsScreenViewedProperties {
  category?: any
}

export interface LoansAdhocPaymentReviewEditAmountSelectedProperties {
  category?: any
}

export interface LoansAdhocPaymentReviewRelinkAccountProperties {
  category?: any
}

export interface LoansAdhocPaymentReviewScreenViewedProperties {
  category?: any
}

export interface LoansAdhocPaymentReviewViewPaymentMethodsProperties {
  category?: any
}

export interface LoansAdhocSuccessScreenViewedProperties {
  category?: any
}

export interface PlaidTransitionViewProperties {
  args?: {
    institution_name?: string
    view_name?: string
    viewName?: string
  }
  category?: any
}

export interface RegistrationEmailEnteredProperties {
  email: string
}

export interface RegistrationWaitlistedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | us_state_ineligible |
   */
  waitlist_reason: 'us_state_ineligible'
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class AccountManagementAccountRouting implements BaseEvent {
  event_type = 'Account_management_account_routing'
}

export class AccountManagementAccountRoutingFail implements BaseEvent {
  event_type = 'Account_management_account_routing_fail'
}

export class AccountManagementAccountRoutingSuccess implements BaseEvent {
  event_type = 'Account_management_account_routing_success'
}

export class AccountManagementAddDebitCard implements BaseEvent {
  event_type = 'Account_management_add_debit_card'
}

export class AccountManagementAddDebitCardDuplicate implements BaseEvent {
  event_type = 'Account_management_add_debit_card_duplicate'
}

export class AccountManagementAddDebitCardFail implements BaseEvent {
  event_type = 'Account_management_add_debit_card_fail'
}

export class AccountManagementAddDebitCardFailCredit implements BaseEvent {
  event_type = 'Account_management_add_debit_card_fail_credit'
}

export class AccountManagementAddDebitCardSuccess implements BaseEvent {
  event_type = 'Account_management_add_debit_card_success'
}

export class AccountManagementAddNewAccount implements BaseEvent {
  event_type = 'Account_management_add_new_account'
}

export class AccountManagementLinkWithPlaid implements BaseEvent {
  event_type = 'Account_management_link_with_plaid'
}

export class AccountManagementLinkWithPlaidFail implements BaseEvent {
  event_type = 'Account_management_link_with_plaid_fail'
}

export class AccountManagementLinkWithPlaidSuccess implements BaseEvent {
  event_type = 'Account_management_link_with_plaid_success'
}

export class BankLinkCompleted implements BaseEvent {
  event_type = 'Bank Link Completed'
}

export class BankLinkError implements BaseEvent {
  event_type = 'Bank Link Error'
}

export class BankLinkStarted implements BaseEvent {
  event_type = 'Bank Link Started'
}

export class BankOwnershipAddressFail implements BaseEvent {
  event_type = 'Bank Ownership Address Fail'
}

export class BankOwnershipContactUsClicked implements BaseEvent {
  event_type = 'Bank Ownership Contact Us Clicked'
}

export class BankOwnershipContactUsSubmitted implements BaseEvent {
  event_type = 'Bank Ownership Contact Us Submitted'
}

export class BankOwnershipContactUsViewed implements BaseEvent {
  event_type = 'Bank Ownership Contact Us Viewed'
}

export class BankOwnershipFail implements BaseEvent {
  event_type = 'Bank Ownership Fail'
}

export class BankOwnershipJointAccount implements BaseEvent {
  event_type = 'Bank Ownership Joint Account'
}

export class BankOwnershipNameAndAddressFail implements BaseEvent {
  event_type = 'Bank Ownership Name and Address Fail'
}

export class BankOwnershipNameChallenge implements BaseEvent {
  event_type = 'Bank Ownership Name Challenge'
}

export class BankOwnershipNameFail implements BaseEvent {
  event_type = 'Bank Ownership Name Fail'
}

export class BankOwnershipNonPersonalAccount implements BaseEvent {
  event_type = 'Bank Ownership Non Personal Account'
}

export class CardApplicationLanded implements BaseEvent {
  event_type = 'Card Application Landed'
}

export class CardApplicationStarted implements BaseEvent {
  event_type = 'Card Application Started'
}

export class ContactVerificationEmailEdited implements BaseEvent {
  event_type = 'Contact Verification Email Edited'
}

export class ContactVerificationEmailError implements BaseEvent {
  event_type = 'Contact Verification Email Error'
}

export class ContactVerificationEmailVerificationCompleted implements BaseEvent {
  event_type = 'Contact Verification Email Verification Completed'
}

export class ContactVerificationEmailVerificationStarted implements BaseEvent {
  event_type = 'Contact Verification Email Verification Started'
}

export class ContactVerificationPhoneCompleted implements BaseEvent {
  event_type = 'Contact Verification Phone Completed'
}

export class CsEmailVerifyAlreadyConfirmed implements BaseEvent {
  event_type = 'cs_email_verify_already_confirmed'
}

export class CsEmailVerifyClickLogIn implements BaseEvent {
  event_type = 'cs_email_verify_click_log_in'
}

export class CsEmailVerifyConfirmed implements BaseEvent {
  event_type = 'cs_email_verify_confirmed'
}

export class CsEmailVerifyFailed implements BaseEvent {
  event_type = 'cs_email_verify_failed'
}

export class CsEmailVerifyTryAgain implements BaseEvent {
  event_type = 'cs_email_verify_try_again'
}

export class EmailVerificationFailed implements BaseEvent {
  event_type = 'email_verification_failed'
}

export class FinalPaymentProcessingAlertTapped implements BaseEvent {
  event_type = 'final_payment_processing_alert_tapped'
}

export class HelpCenterRedirectSelected implements BaseEvent {
  event_type = 'help_center_redirect_selected'
}

export class HelpCenterRedirectViewed implements BaseEvent {
  event_type = 'help_center_redirect_viewed'
}

export class HelpCenterSelected implements BaseEvent {
  event_type = 'help_center_selected'
}

export class IdentityVerificationPhotoIdCompleted implements BaseEvent {
  event_type = 'Identity Verification Photo ID Completed'
}

export class IdentityVerificationPhotoIdStarted implements BaseEvent {
  event_type = 'Identity Verification Photo ID Started'
}

export class IdentityVerificationSelfieCaptured implements BaseEvent {
  event_type = 'Identity Verification Selfie Captured'
}

export class IdentityVerificationSelfieCompleted implements BaseEvent {
  event_type = 'Identity Verification Selfie Completed'
}

export class IdentityVerificationStarted implements BaseEvent {
  event_type = 'Identity Verification Started'
}

export class IntroTutorialCompleted implements BaseEvent {
  event_type = 'Intro Tutorial Completed'
}

export class IntroTutorialStarted implements BaseEvent {
  event_type = 'Intro Tutorial Started'
}

export class LoanApplicationAmountSelected implements BaseEvent {
  event_type = 'Loan Application Amount Selected'
}

export class LoanApplicationCompleted implements BaseEvent {
  event_type = 'Loan Application Completed'
}

export class LoanApplicationMarketingSurveyCompleted implements BaseEvent {
  event_type = 'Loan Application Marketing Survey Completed'
}

export class LoanApplicationPersonalInfoVerified implements BaseEvent {
  event_type = 'Loan Application Personal Info Verified'
}

export class LoanApplicationStarted implements BaseEvent {
  event_type = 'Loan Application Started'
}

export class LoanLandingCheckEligibilityCta implements BaseEvent {
  event_type = 'Loan_Landing_check_eligibility_CTA'
}

export class LoanLandingClickLogIn implements BaseEvent {
  event_type = 'Loan_Landing_click_log_in'
}

export class LoanLandingLearnMoreBorrow implements BaseEvent {
  event_type = 'Loan_Landing_learn_more_borrow'
}

export class LoanLandingLearnMoreDebt implements BaseEvent {
  event_type = 'Loan_Landing_learn_more_debt'
}

export class LoanLandingLearnMoreFees implements BaseEvent {
  event_type = 'Loan_Landing_learn_more_fees'
}

export class LoanLandingLearnMorePot implements BaseEvent {
  event_type = 'Loan_Landing_learn_more_POT'
}

export class LoanLandingScreenViewed implements BaseEvent {
  event_type = 'Loan_Landing_screen_viewed'
}

export class LoansAdhocAmountEntryCta implements BaseEvent {
  event_type = 'loans_adhoc_amount_entry_cta'

  constructor(public event_properties?: LoansAdhocAmountEntryCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocAmountEntryErrorViewed implements BaseEvent {
  event_type = 'loans_adhoc_amount_entry_error_viewed'

  constructor(public event_properties?: LoansAdhocAmountEntryErrorViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocAmountEntryScreenViewed implements BaseEvent {
  event_type = 'loans_adhoc_amount_entry_screen_viewed'

  constructor(public event_properties?: LoansAdhocAmountEntryScreenViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocClickMakeAPayment implements BaseEvent {
  event_type = 'loans_adhoc_click_make_a_payment'
}

export class LoansAdhocDashboardMakeAPaymentCta implements BaseEvent {
  event_type = 'loans_adhoc_dashboard_make_a_payment_cta'

  constructor(public event_properties?: LoansAdhocDashboardMakeAPaymentCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocDebitCard implements BaseEvent {
  event_type = 'loans_adhoc_debit_card'
}

export class LoansAdhocDebitCardDuplicate implements BaseEvent {
  event_type = 'loans_adhoc_debit_card_duplicate'
}

export class LoansAdhocDebitCardIsCredit implements BaseEvent {
  event_type = 'loans_adhoc_debit_card_is_credit'
}

export class LoansAdhocDebitCardSuccess implements BaseEvent {
  event_type = 'loans_adhoc_debit_card_success'
}

export class LoansAdhocDebitCardValidationFail implements BaseEvent {
  event_type = 'loans_adhoc_debit_card_validation_fail'
}

export class LoansAdhocEditLoanAmount implements BaseEvent {
  event_type = 'loans_adhoc_edit_loan_amount'
}

export class LoansAdhocEditPaymentAccount implements BaseEvent {
  event_type = 'loans_adhoc_edit_payment_account'
}

export class LoansAdhocFailureModalCtaCancel implements BaseEvent {
  event_type = 'loans_adhoc_failure_modal_cta_cancel'

  constructor(public event_properties?: LoansAdhocFailureModalCtaCancelProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocFailureModalCtaTryAgain implements BaseEvent {
  event_type = 'loans_adhoc_failure_modal_cta_try_again'

  constructor(public event_properties?: LoansAdhocFailureModalCtaTryAgainProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocFailureModalViewed implements BaseEvent {
  event_type = 'loans_adhoc_failure_modal_viewed'

  constructor(public event_properties?: LoansAdhocFailureModalViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocLinkWithPlaid implements BaseEvent {
  event_type = 'loans_adhoc_link_with_plaid'
}

export class LoansAdhocLinkWithPlaidFail implements BaseEvent {
  event_type = 'loans_adhoc_link_with_plaid_fail'
}

export class LoansAdhocLinkWithPlaidSuccess implements BaseEvent {
  event_type = 'loans_adhoc_link_with_plaid_success'
}

export class LoansAdhocPaymentMethodLinkCta implements BaseEvent {
  event_type = 'loans_adhoc_payment_method_link_cta'

  constructor(public event_properties?: LoansAdhocPaymentMethodLinkCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentMethodsAddBankCta implements BaseEvent {
  event_type = 'loans_adhoc_payment_methods_add_bank_cta'

  constructor(public event_properties?: LoansAdhocPaymentMethodsAddBankCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentMethodsBankSelectCta implements BaseEvent {
  event_type = 'loans_adhoc_payment_methods_bank_select_cta'

  constructor(public event_properties?: LoansAdhocPaymentMethodsBankSelectCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentMethodsErrorViewed implements BaseEvent {
  event_type = 'loans_adhoc_payment_methods_error_viewed'

  constructor(public event_properties?: LoansAdhocPaymentMethodsErrorViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentMethodsSavePaymentCta implements BaseEvent {
  event_type = 'loans_adhoc_payment_methods_save_payment_cta'

  constructor(public event_properties?: LoansAdhocPaymentMethodsSavePaymentCtaProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentMethodsScreenViewed implements BaseEvent {
  event_type = 'loans_adhoc_payment_methods_screen_viewed'

  constructor(public event_properties?: LoansAdhocPaymentMethodsScreenViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentReviewCta implements BaseEvent {
  event_type = 'loans_adhoc_payment_review_cta'
}

export class LoansAdhocPaymentReviewEditAmountSelected implements BaseEvent {
  event_type = 'loans_adhoc_payment_review_edit_amount_selected'

  constructor(public event_properties?: LoansAdhocPaymentReviewEditAmountSelectedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentReviewRelinkAccount implements BaseEvent {
  event_type = 'loans_adhoc_payment_review_relink_account'

  constructor(public event_properties?: LoansAdhocPaymentReviewRelinkAccountProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentReviewScreenViewed implements BaseEvent {
  event_type = 'loans_adhoc_payment_review_screen_viewed'

  constructor(public event_properties?: LoansAdhocPaymentReviewScreenViewedProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocPaymentReviewViewPaymentMethods implements BaseEvent {
  event_type = 'loans_adhoc_payment_review_view_payment_methods'

  constructor(public event_properties?: LoansAdhocPaymentReviewViewPaymentMethodsProperties) {
    this.event_properties = event_properties
  }
}

export class LoansAdhocRoutingAccount implements BaseEvent {
  event_type = 'loans_adhoc_routing_account'
}

export class LoansAdhocRoutingAccountFail implements BaseEvent {
  event_type = 'loans_adhoc_routing_account_fail'
}

export class LoansAdhocRoutingAccountSuccess implements BaseEvent {
  event_type = 'loans_adhoc_routing_account_success'
}

export class LoansAdhocSuccessScreenViewed implements BaseEvent {
  event_type = 'loans_adhoc_success_screen_viewed'

  constructor(public event_properties?: LoansAdhocSuccessScreenViewedProperties) {
    this.event_properties = event_properties
  }
}

export class NotificationPermissionGrantedCta implements BaseEvent {
  event_type = 'notification_permission_granted_cta'
}

export class NotificationPermissionNotNowCta implements BaseEvent {
  event_type = 'notification_permission_not_now_cta'
}

export class NotificationPermissionScreenViewed implements BaseEvent {
  event_type = 'notification_permission_screen_viewed'
}

export class PlaidLayerDobSsnComplete implements BaseEvent {
  event_type = 'plaid_layer_DOB_SSN_complete'
}

export class PlaidLayerError implements BaseEvent {
  event_type = 'plaid_layer_error'
}

export class PlaidLayerExit implements BaseEvent {
  event_type = 'plaid_layer_exit'
}

export class PlaidLayerFailOauth implements BaseEvent {
  event_type = 'plaid_layer_fail_oauth'
}

export class PlaidLayerIneligible implements BaseEvent {
  event_type = 'plaid_layer_ineligible'
}

export class PlaidLayerLayerPromptComplete implements BaseEvent {
  event_type = 'plaid_layer_layer_prompt_complete'
}

export class PlaidLayerNameDobSsnComplete implements BaseEvent {
  event_type = 'plaid_layer_name_DOB_SSN_complete'
}

export class PlaidLayerOptBackIn implements BaseEvent {
  event_type = 'plaid_layer_opt_back_in'
}

export class PlaidLayerOptOut implements BaseEvent {
  event_type = 'plaid_layer_opt_out'
}

export class PlaidLayerProcessComplete implements BaseEvent {
  event_type = 'plaid_layer_process_complete'
}

export class PlaidLayerSkipSubmitPhone implements BaseEvent {
  event_type = 'plaid_layer_skip_submit_phone'
}

export class PlaidLayerStart implements BaseEvent {
  event_type = 'plaid_layer_start'
}

export class PlaidLayerSubmitCredentials implements BaseEvent {
  event_type = 'plaid_layer_submit_credentials'
}

export class PlaidLayerSubmitMfa implements BaseEvent {
  event_type = 'plaid_layer_submit_MFA'
}

export class PlaidLayerSubmitPhone implements BaseEvent {
  event_type = 'plaid_layer_submit_phone'
}

export class PlaidLayerThreeEasyStepsComplete implements BaseEvent {
  event_type = 'plaid_layer_three_easy_steps_complete'
}

export class PlaidLayerViewDobSsn implements BaseEvent {
  event_type = 'plaid_layer_view_DOB_SSN'
}

export class PlaidLayerViewLayerPrompt implements BaseEvent {
  event_type = 'plaid_layer_view_layer_prompt'
}

export class PlaidLayerViewNameDobSsn implements BaseEvent {
  event_type = 'plaid_layer_view_name_DOB_SSN'
}

export class PlaidLayerViewThreeEasySteps implements BaseEvent {
  event_type = 'plaid_layer_view_three_easy_steps'
}

export class PlaidTransitionView implements BaseEvent {
  event_type = 'plaid_transition_view'

  constructor(public event_properties?: PlaidTransitionViewProperties) {
    this.event_properties = event_properties
  }
}

export class RegistrationAccountCreated implements BaseEvent {
  event_type = 'Registration Account Created'
}

export class RegistrationAddressCompleted implements BaseEvent {
  event_type = 'Registration Address Completed'
}

export class RegistrationAddressVerificationFailed implements BaseEvent {
  event_type = 'Registration Address Verification Failed'
}

export class RegistrationEmailEntered implements BaseEvent {
  event_type = 'Registration Email Entered'

  constructor(public event_properties: RegistrationEmailEnteredProperties) {
    this.event_properties = event_properties
  }
}

export class RegistrationPersonalDetailsCompleted implements BaseEvent {
  event_type = 'Registration Personal Details Completed'
}

export class RegistrationSsnCompleted implements BaseEvent {
  event_type = 'Registration SSN Completed'
}

export class RegistrationStarted implements BaseEvent {
  event_type = 'Registration Started'
}

export class RegistrationSuccessfullyCompleted implements BaseEvent {
  event_type = 'Registration Successfully Completed'
}

export class RegistrationVerificationCompleted implements BaseEvent {
  event_type = 'Registration Verification Completed'
}

export class RegistrationVerificationStarted implements BaseEvent {
  event_type = 'Registration Verification Started'
}

export class RegistrationWaitlisted implements BaseEvent {
  event_type = 'Registration Waitlisted'

  constructor(public event_properties: RegistrationWaitlistedProperties) {
    this.event_properties = event_properties
  }
}

export class RegistrationExistingLogIn implements BaseEvent {
  event_type = 'registration_existing_log_in'
}

export class RegistrationGetStarted implements BaseEvent {
  event_type = 'registration_get_started'
}

export class SelfReportedFinancesCompleted implements BaseEvent {
  event_type = 'Self-Reported Finances Completed'
}

export type PromiseResult<T> = {promise: Promise<T | void>}

const getVoidPromiseResult = () => ({promise: Promise.resolve()})

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Account_management_account_routing
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_account_routing)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects routing and account
   *
   * @param options Amplitude event options.
   */
  accountManagementAccountRouting(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAccountRouting(), options);
  }

  /**
   * Account_management_account_routing_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_account_routing_fail)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects account and routing and fails verification
   *
   * @param options Amplitude event options.
   */
  accountManagementAccountRoutingFail(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAccountRoutingFail(), options);
  }

  /**
   * Account_management_account_routing_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_account_routing_success)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects account and routing and succeeds
   *
   * @param options Amplitude event options.
   */
  accountManagementAccountRoutingSuccess(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAccountRoutingSuccess(), options);
  }

  /**
   * Account_management_add_debit_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_debit_card)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects debit card
   *
   * @param options Amplitude event options.
   */
  accountManagementAddDebitCard(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddDebitCard(), options);
  }

  /**
   * Account_management_add_debit_card_duplicate
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_debit_card_duplicate)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects debit card and attempts to link an already linked card
   *
   * @param options Amplitude event options.
   */
  accountManagementAddDebitCardDuplicate(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddDebitCardDuplicate(), options);
  }

  /**
   * Account_management_add_debit_card_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_debit_card_fail)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects debit card and verification fails
   *
   * @param options Amplitude event options.
   */
  accountManagementAddDebitCardFail(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddDebitCardFail(), options);
  }

  /**
   * Account_management_add_debit_card_fail_credit
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_debit_card_fail_credit)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects debit card and fails because it's a credit card
   *
   * @param options Amplitude event options.
   */
  accountManagementAddDebitCardFailCredit(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddDebitCardFailCredit(), options);
  }

  /**
   * Account_management_add_debit_card_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_debit_card_success)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and selects debit card and succeeds
   *
   * @param options Amplitude event options.
   */
  accountManagementAddDebitCardSuccess(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddDebitCardSuccess(), options);
  }

  /**
   * Account_management_add_new_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_add_new_account)
   *
   * Fires when customer selects "add new account" from acct mgmt screen
   *
   * @param options Amplitude event options.
   */
  accountManagementAddNewAccount(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementAddNewAccount(), options);
  }

  /**
   * Account_management_link_with_plaid
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_link_with_plaid)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and chooses link with plaid
   *
   * @param options Amplitude event options.
   */
  accountManagementLinkWithPlaid(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementLinkWithPlaid(), options);
  }

  /**
   * Account_management_link_with_plaid_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_link_with_plaid_fail)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and chooses link with plaid and fails
   *
   * @param options Amplitude event options.
   */
  accountManagementLinkWithPlaidFail(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementLinkWithPlaidFail(), options);
  }

  /**
   * Account_management_link_with_plaid_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Account_management_link_with_plaid_success)
   *
   * Fires when customer selects "add new account" from acct mgmt screen and chooses link with plaid and succeeds
   *
   * @param options Amplitude event options.
   */
  accountManagementLinkWithPlaidSuccess(
    options?: EventOptions,
  ) {
    return this.track(new AccountManagementLinkWithPlaidSuccess(), options);
  }

  /**
   * Bank Link Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Link%20Completed)
   *
   * Event fires when applicant successfully links their bank
   *
   * Bank Link Completed means that customer has successfully linked their bank. 
   *  It fires from the BE (or should) but a proxy is view plaid success page 
   *  As of today, it takes place when ​the customer exits the plaid flow successfully
   *
   * @param options Amplitude event options.
   */
  bankLinkCompleted(
    options?: EventOptions,
  ) {
    return this.track(new BankLinkCompleted(), options);
  }

  /**
   * Bank Link Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Link%20Error)
   *
   * Event fires when there's an error linking a plaid account
   *
   * @param options Amplitude event options.
   */
  bankLinkError(
    options?: EventOptions,
  ) {
    return this.track(new BankLinkError(), options);
  }

  /**
   * Bank Link Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Link%20Started)
   *
   * Event fires when applicant starts the Plaid flow
   *
   * currently by clicking "Ok got it" on link bank account page
   *
   * @param options Amplitude event options.
   */
  bankLinkStarted(
    options?: EventOptions,
  ) {
    return this.track(new BankLinkStarted(), options);
  }

  /**
   * Bank Ownership Address Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Address%20Fail)
   *
   * Returns when the address (state) associated with the bank account the customer links in account management does not match the one provided in their application
   *
   * @param options Amplitude event options.
   */
  bankOwnershipAddressFail(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipAddressFail(), options);
  }

  /**
   * Bank Ownership Contact Us Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Contact%20Us%20Clicked)
   *
   * Returns when the customer clicks "Contact Us" from the modal that opens post plaid assets challenge
   *
   * @param options Amplitude event options.
   */
  bankOwnershipContactUsClicked(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipContactUsClicked(), options);
  }

  /**
   * Bank Ownership Contact Us Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Contact%20Us%20Submitted)
   *
   * Returns when the customer submits the "contact us" form after a plaid assets ownership challenge
   *
   * @param options Amplitude event options.
   */
  bankOwnershipContactUsSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipContactUsSubmitted(), options);
  }

  /**
   * Bank Ownership Contact Us Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Contact%20Us%20Viewed)
   *
   * Returns when the customer views the "contact us" form after a plaid assets ownership challenge
   *
   * @param options Amplitude event options.
   */
  bankOwnershipContactUsViewed(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipContactUsViewed(), options);
  }

  /**
   * Bank Ownership Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Fail)
   *
   * Returns when the customer attempts to link an account via account management and returns ANY plaid assets ownership failure or challenge
   *
   * @param options Amplitude event options.
   */
  bankOwnershipFail(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipFail(), options);
  }

  /**
   * Bank Ownership Joint Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Joint%20Account)
   *
   * Returns when the customer attempts to link a joint account in account management
   *
   * @param options Amplitude event options.
   */
  bankOwnershipJointAccount(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipJointAccount(), options);
  }

  /**
   * Bank Ownership Name and Address Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Name%20and%20Address%20Fail)
   *
   * Returns when both the name and the address (state) associated with the bank account the customer links in account management does not match the ones provided in their application
   *
   * @param options Amplitude event options.
   */
  bankOwnershipNameAndAddressFail(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipNameAndAddressFail(), options);
  }

  /**
   * Bank Ownership Name Challenge
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Name%20Challenge)
   *
   * Returns when the name on the bank account the customer links in account management matches between 40% and 70% to the application name
   *
   * @param options Amplitude event options.
   */
  bankOwnershipNameChallenge(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipNameChallenge(), options);
  }

  /**
   * Bank Ownership Name Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Name%20Fail)
   *
   * Returns when the name on the bank account the customer links in account management matches at less than 40% to the application name
   *
   * @param options Amplitude event options.
   */
  bankOwnershipNameFail(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipNameFail(), options);
  }

  /**
   * Bank Ownership Non Personal Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Bank%20Ownership%20Non%20Personal%20Account)
   *
   * Returns when the customer attempts to link a non personal account in account management
   *
   * @param options Amplitude event options.
   */
  bankOwnershipNonPersonalAccount(
    options?: EventOptions,
  ) {
    return this.track(new BankOwnershipNonPersonalAccount(), options);
  }

  /**
   * Card Application Landed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Card%20Application%20Landed)
   *
   * `Card Application Landed` means that the customer has landed on the card selection page but may not have taken action yet. Once customer actually continues to start application, we generate another event called `Card Application Started`. 
   *
   *  It fires on the front-end when the customer views the card selection page. 
   *
   *  It replaces the (current as of 8/7/2023) `card_selection_page_viewed` event.
   *
   * @param options Amplitude event options.
   */
  cardApplicationLanded(
    options?: EventOptions,
  ) {
    return this.track(new CardApplicationLanded(), options);
  }

  /**
   * Card Application Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Card%20Application%20Started)
   *
   * `Card Application Started` means that the customer has started the application from the card selection page by clicking the CTA.  
   *
   *
   * It fires on the back-end when the customer starts the card application.  
   *
   *
   * It replaces the (current as of 8/7/2023) `apply_for_card_selected` event.
   *
   * @param options Amplitude event options.
   */
  cardApplicationStarted(
    options?: EventOptions,
  ) {
    return this.track(new CardApplicationStarted(), options);
  }

  /**
   * Contact Verification Email Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Contact%20Verification%20Email%20Edited)
   *
   * Event fires when applicant changes the email address to be used for email verification
   *
   * @param options Amplitude event options.
   */
  contactVerificationEmailEdited(
    options?: EventOptions,
  ) {
    return this.track(new ContactVerificationEmailEdited(), options);
  }

  /**
   * Contact Verification Email Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Contact%20Verification%20Email%20Error)
   *
   * Event fires when applicant enters the incorrect code for email verification
   *
   * @param options Amplitude event options.
   */
  contactVerificationEmailError(
    options?: EventOptions,
  ) {
    return this.track(new ContactVerificationEmailError(), options);
  }

  /**
   * Contact Verification Email Verification Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Contact%20Verification%20Email%20Verification%20Completed)
   *
   * Event fires when applicant successfully enters the correct code used for email verification
   *
   * @param options Amplitude event options.
   */
  contactVerificationEmailVerificationCompleted(
    options?: EventOptions,
  ) {
    return this.track(new ContactVerificationEmailVerificationCompleted(), options);
  }

  /**
   * Contact Verification Email Verification Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Contact%20Verification%20Email%20Verification%20Started)
   *
   * Event fires when applicant confirms their email for verification
   *
   * @param options Amplitude event options.
   */
  contactVerificationEmailVerificationStarted(
    options?: EventOptions,
  ) {
    return this.track(new ContactVerificationEmailVerificationStarted(), options);
  }

  /**
   * Contact Verification Phone Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Contact%20Verification%20Phone%20Completed)
   *
   * Fires when the customer enters their phone number during verification 
   *
   *  Maps to  older event: `enter_phone_number_completed`
   *
   * @param options Amplitude event options.
   */
  contactVerificationPhoneCompleted(
    options?: EventOptions,
  ) {
    return this.track(new ContactVerificationPhoneCompleted(), options);
  }

  /**
   * cs_email_verify_already_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/cs_email_verify_already_confirmed)
   *
   * This event fires when the customer engages with a "verify email" email triggered by Customer support, then views the "email already verified" page
   *
   * @param options Amplitude event options.
   */
  csEmailVerifyAlreadyConfirmed(
    options?: EventOptions,
  ) {
    return this.track(new CsEmailVerifyAlreadyConfirmed(), options);
  }

  /**
   * cs_email_verify_click_log_in
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/cs_email_verify_click_log_in)
   *
   * This event fires when the customer engages with a "verify email" email triggered by Customer support, then clicks "tap to log in" on the "email confirmed" page
   *
   * @param options Amplitude event options.
   */
  csEmailVerifyClickLogIn(
    options?: EventOptions,
  ) {
    return this.track(new CsEmailVerifyClickLogIn(), options);
  }

  /**
   * cs_email_verify_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/cs_email_verify_confirmed)
   *
   * This event fires when the customer engages with a "verify email" email triggered by Customer support, then views the "email confirmed" page
   *
   * @param options Amplitude event options.
   */
  csEmailVerifyConfirmed(
    options?: EventOptions,
  ) {
    return this.track(new CsEmailVerifyConfirmed(), options);
  }

  /**
   * cs_email_verify_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/cs_email_verify_failed)
   *
   * This event fires when the customer engages with a "verify email" email triggered by Customer support, then views the "email veriication failed" page
   *
   * @param options Amplitude event options.
   */
  csEmailVerifyFailed(
    options?: EventOptions,
  ) {
    return this.track(new CsEmailVerifyFailed(), options);
  }

  /**
   * cs_email_verify_try_again
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/cs_email_verify_try_again)
   *
   * This event fires when the customer engages with a "verify email" email triggered by Customer support, then clicks "try again" on the "email verification failed" or "email already verified"
   *
   *  page
   *
   * @param options Amplitude event options.
   */
  csEmailVerifyTryAgain(
    options?: EventOptions,
  ) {
    return this.track(new CsEmailVerifyTryAgain(), options);
  }

  /**
   * email_verification_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/email_verification_failed)
   *
   * email verification code sent to the customer is invalid or fails when they put it in
   *
   * @param options Amplitude event options.
   */
  emailVerificationFailed(
    options?: EventOptions,
  ) {
    return this.track(new EmailVerificationFailed(), options);
  }

  /**
   * final_payment_processing_alert_tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/final_payment_processing_alert_tapped)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  finalPaymentProcessingAlertTapped(
    options?: EventOptions,
  ) {
    return this.track(new FinalPaymentProcessingAlertTapped(), options);
  }

  /**
   * help_center_redirect_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/help_center_redirect_selected)
   *
   * When a user opens the left hand hamburger menu and selects "help center" option, then lands on the interim redirect page AND clicks the CTA to enter the secured help center
   *
   * @param options Amplitude event options.
   */
  helpCenterRedirectSelected(
    options?: EventOptions,
  ) {
    return this.track(new HelpCenterRedirectSelected(), options);
  }

  /**
   * help_center_redirect_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/help_center_redirect_viewed)
   *
   * When a user opens the left hand hamburger menu and selects "help center" option, then lands on the interim redirect page
   *
   * @param options Amplitude event options.
   */
  helpCenterRedirectViewed(
    options?: EventOptions,
  ) {
    return this.track(new HelpCenterRedirectViewed(), options);
  }

  /**
   * help_center_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/help_center_selected)
   *
   * When a user opens the left hand hamburger menu and selects "help center" option
   *
   * @param options Amplitude event options.
   */
  helpCenterSelected(
    options?: EventOptions,
  ) {
    return this.track(new HelpCenterSelected(), options);
  }

  /**
   * Identity Verification Photo ID Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Identity%20Verification%20Photo%20ID%20Completed)
   *
   * **When:** the customer completes photo ID verification
   *
   * **Where:** back-end, when customer successfully submits the photo IDs.  
   *  **Replaces:** `front_id_photo_submitted`
   *
   * @param options Amplitude event options.
   */
  identityVerificationPhotoIdCompleted(
    options?: EventOptions,
  ) {
    return this.track(new IdentityVerificationPhotoIdCompleted(), options);
  }

  /**
   * Identity Verification Photo ID Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Identity%20Verification%20Photo%20ID%20Started)
   *
   * **When:** the customer kicks off photo ID verification
   *
   * **Where:** front-end, when lands on the 'take a photo of your ID' page
   *
   * @param options Amplitude event options.
   */
  identityVerificationPhotoIdStarted(
    options?: EventOptions,
  ) {
    return this.track(new IdentityVerificationPhotoIdStarted(), options);
  }

  /**
   * Identity Verification Selfie Captured
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Identity%20Verification%20Selfie%20Captured)
   *
   * `Identity Verification Selfie Captured` means that the customer has taken their selfie but not submitted it yet.
   *
   *
   *  It fires on the front-end when the customer takes their selfie on their device's camera but haven't submitted it yet.
   *
   *
   *  It does not replace any existing event.
   *
   * @param options Amplitude event options.
   */
  identityVerificationSelfieCaptured(
    options?: EventOptions,
  ) {
    return this.track(new IdentityVerificationSelfieCaptured(), options);
  }

  /**
   * Identity Verification Selfie Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Identity%20Verification%20Selfie%20Completed)
   *
   * **When:** the customer has taken their selfie & submitted it.
   *
   * **Where:** on the back-end, upon selfie submission
   *
   * **Replacing:** `take_a_selfie_completed`
   *
   * @param options Amplitude event options.
   */
  identityVerificationSelfieCompleted(
    options?: EventOptions,
  ) {
    return this.track(new IdentityVerificationSelfieCompleted(), options);
  }

  /**
   * Identity Verification Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Identity%20Verification%20Started)
   *
   * `Identity Verification Started` means that the customer has viewed the card selfie & id info page. 
   *
   *
   * It fires on the front-end when the customer views the card selfie & id info page. 
   *
   *
   * It replaces the (current as of 8/7/2023) `card_selfie_id_info_pag_viewed` event.
   *
   * @param options Amplitude event options.
   */
  identityVerificationStarted(
    options?: EventOptions,
  ) {
    return this.track(new IdentityVerificationStarted(), options);
  }

  /**
   * Intro Tutorial Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Intro%20Tutorial%20Completed)
   *
   * When the customer finishes the Intro tutorial and is ready to proceed
   *
   * @param options Amplitude event options.
   */
  introTutorialCompleted(
    options?: EventOptions,
  ) {
    return this.track(new IntroTutorialCompleted(), options);
  }

  /**
   * Intro Tutorial Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Intro%20Tutorial%20Started)
   *
   * When the customer first opens the app and sees our initial tutorial.
   *
   * @param options Amplitude event options.
   */
  introTutorialStarted(
    options?: EventOptions,
  ) {
    return this.track(new IntroTutorialStarted(), options);
  }

  /**
   * Loan Application Amount Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan%20Application%20Amount%20Selected)
   *
   * Event fires when applicant proceeds after selecting an amount for their loan
   *
   * @param options Amplitude event options.
   */
  loanApplicationAmountSelected(
    options?: EventOptions,
  ) {
    return this.track(new LoanApplicationAmountSelected(), options);
  }

  /**
   * Loan Application Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan%20Application%20Completed)
   *
   * Event fires when applicant submits their loan application.
   *
   * Currently triggered by clicking "Submit Application"
   *
   * @param options Amplitude event options.
   */
  loanApplicationCompleted(
    options?: EventOptions,
  ) {
    return this.track(new LoanApplicationCompleted(), options);
  }

  /**
   * Loan Application Marketing Survey Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan%20Application%20Marketing%20Survey%20Completed)
   *
   * Event fires when applicant selects their attribution source in the survey
   *
   * @param options Amplitude event options.
   */
  loanApplicationMarketingSurveyCompleted(
    options?: EventOptions,
  ) {
    return this.track(new LoanApplicationMarketingSurveyCompleted(), options);
  }

  /**
   * Loan Application Personal Info Verified
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan%20Application%20Personal%20Info%20Verified)
   *
   * **Registration Personal Info Verified**
   *
   * This event fires when the user confirms their personal information is correct
   *
   * @param options Amplitude event options.
   */
  loanApplicationPersonalInfoVerified(
    options?: EventOptions,
  ) {
    return this.track(new LoanApplicationPersonalInfoVerified(), options);
  }

  /**
   * Loan Application Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan%20Application%20Started)
   *
   * Event: Loan Application Started  Description: This event is triggered when a user begins the process of applying for a loan.  It fires when applicant views the "Get a possible loan" page (should change)
   *
   * @param options Amplitude event options.
   */
  loanApplicationStarted(
    options?: EventOptions,
  ) {
    return this.track(new LoanApplicationStarted(), options);
  }

  /**
   * Loan_Landing_check_eligibility_CTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_check_eligibility_CTA)
   *
   * This event fires when the customer clicks the "check my eligibility" CTA on the new loans landing page
   *
   * @param options Amplitude event options.
   */
  loanLandingCheckEligibilityCta(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingCheckEligibilityCta(), options);
  }

  /**
   * Loan_Landing_click_log_in
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_click_log_in)
   *
   * This event fires when the customer clicks the "already a member, log in" CTA on the new loans landing page
   *
   * @param options Amplitude event options.
   */
  loanLandingClickLogIn(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingClickLogIn(), options);
  }

  /**
   * Loan_Landing_learn_more_borrow
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_learn_more_borrow)
   *
   * This event fires when the customer clicks the "borrow up to $500 instantly" bubble
   *
   * @param options Amplitude event options.
   */
  loanLandingLearnMoreBorrow(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingLearnMoreBorrow(), options);
  }

  /**
   * Loan_Landing_learn_more_debt
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_learn_more_debt)
   *
   * This event fires when the customer clicks the "minimize mounting debt" bubble
   *
   * @param options Amplitude event options.
   */
  loanLandingLearnMoreDebt(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingLearnMoreDebt(), options);
  }

  /**
   * Loan_Landing_learn_more_fees
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_learn_more_fees)
   *
   * This event fires when the customer clicks the "no late fees" bubble
   *
   * @param options Amplitude event options.
   */
  loanLandingLearnMoreFees(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingLearnMoreFees(), options);
  }

  /**
   * Loan_Landing_learn_more_POT
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_learn_more_POT)
   *
   * This event fires when the customer clicks the "POT" bubble
   *
   * @param options Amplitude event options.
   */
  loanLandingLearnMorePot(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingLearnMorePot(), options);
  }

  /**
   * Loan_Landing_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Loan_Landing_screen_viewed)
   *
   * This event fires when the customer views the new loans landing page (as of feb 2024)
   *
   * @param options Amplitude event options.
   */
  loanLandingScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new LoanLandingScreenViewed(), options);
  }

  /**
   * loans_adhoc_amount_entry_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_amount_entry_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. args)
   * @param options Amplitude event options.
   */
  loansAdhocAmountEntryCta(
    properties?: LoansAdhocAmountEntryCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocAmountEntryCta(properties), options);
  }

  /**
   * loans_adhoc_amount_entry_error_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_amount_entry_error_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocAmountEntryErrorViewed(
    properties?: LoansAdhocAmountEntryErrorViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocAmountEntryErrorViewed(properties), options);
  }

  /**
   * loans_adhoc_amount_entry_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_amount_entry_screen_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocAmountEntryScreenViewed(
    properties?: LoansAdhocAmountEntryScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocAmountEntryScreenViewed(properties), options);
  }

  /**
   * loans_adhoc_click_make_a_payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_click_make_a_payment)
   *
   * Fires when customer selects "Make a payment" from loans dashboard
   *
   * @param options Amplitude event options.
   */
  loansAdhocClickMakeAPayment(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocClickMakeAPayment(), options);
  }

  /**
   * loans_adhoc_dashboard_make_a_payment_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_dashboard_make_a_payment_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocDashboardMakeAPaymentCta(
    properties?: LoansAdhocDashboardMakeAPaymentCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDashboardMakeAPaymentCta(properties), options);
  }

  /**
   * loans_adhoc_debit_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_debit_card)
   *
   * Fires when customer selects "debit card" for their new payment account within the make a payment flow
   *
   * @param options Amplitude event options.
   */
  loansAdhocDebitCard(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDebitCard(), options);
  }

  /**
   * loans_adhoc_debit_card_duplicate
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_debit_card_duplicate)
   *
   * Fires when customer selects "debit card" for their new payment account within the make a payment flow and does not successfully add the card because it is a duplicate
   *
   * @param options Amplitude event options.
   */
  loansAdhocDebitCardDuplicate(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDebitCardDuplicate(), options);
  }

  /**
   * loans_adhoc_debit_card_is_credit
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_debit_card_is_credit)
   *
   * Fires when customer selects "debit card" for their new payment account within the make a payment flow and does not successfully add the card because it is a credit card
   *
   * @param options Amplitude event options.
   */
  loansAdhocDebitCardIsCredit(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDebitCardIsCredit(), options);
  }

  /**
   * loans_adhoc_debit_card_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_debit_card_success)
   *
   * Fires when customer selects "debit card" for their new payment account within the make a payment flow and successfully adds the card
   *
   * @param options Amplitude event options.
   */
  loansAdhocDebitCardSuccess(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDebitCardSuccess(), options);
  }

  /**
   * loans_adhoc_debit_card_validation_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_debit_card_validation_fail)
   *
   * Fires when customer selects "debit card" for their new payment account within the make a payment flow and does not successfully add the card because it failed validation
   *
   * @param options Amplitude event options.
   */
  loansAdhocDebitCardValidationFail(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocDebitCardValidationFail(), options);
  }

  /**
   * loans_adhoc_edit_loan_amount
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_edit_loan_amount)
   *
   * Fires when customer selects "edit" for the loan amount within the make a payment flow
   *
   * @param options Amplitude event options.
   */
  loansAdhocEditLoanAmount(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocEditLoanAmount(), options);
  }

  /**
   * loans_adhoc_edit_payment_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_edit_payment_account)
   *
   * Fires when customer selects "edit" for the payment account within the make a payment flow
   *
   * @param options Amplitude event options.
   */
  loansAdhocEditPaymentAccount(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocEditPaymentAccount(), options);
  }

  /**
   * loans_adhoc_failure_modal_cta_cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_failure_modal_cta_cancel)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocFailureModalCtaCancel(
    properties?: LoansAdhocFailureModalCtaCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocFailureModalCtaCancel(properties), options);
  }

  /**
   * loans_adhoc_failure_modal_cta_try_again
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_failure_modal_cta_try_again)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocFailureModalCtaTryAgain(
    properties?: LoansAdhocFailureModalCtaTryAgainProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocFailureModalCtaTryAgain(properties), options);
  }

  /**
   * loans_adhoc_failure_modal_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_failure_modal_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocFailureModalViewed(
    properties?: LoansAdhocFailureModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocFailureModalViewed(properties), options);
  }

  /**
   * loans_adhoc_link_with_plaid
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_link_with_plaid)
   *
   * Fires when customer selects "link with plaid" for their new payment account within the make a payment flow
   *
   * @param options Amplitude event options.
   */
  loansAdhocLinkWithPlaid(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocLinkWithPlaid(), options);
  }

  /**
   * loans_adhoc_link_with_plaid_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_link_with_plaid_fail)
   *
   * Fires when customer selects "link with plaid" for their new payment account within the make a payment flow and does not successfully add the account
   *
   * @param options Amplitude event options.
   */
  loansAdhocLinkWithPlaidFail(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocLinkWithPlaidFail(), options);
  }

  /**
   * loans_adhoc_link_with_plaid_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_link_with_plaid_success)
   *
   * Fires when customer selects "link with plaid" for their new payment account within the make a payment flow and successfully adds the account
   *
   * @param options Amplitude event options.
   */
  loansAdhocLinkWithPlaidSuccess(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocLinkWithPlaidSuccess(), options);
  }

  /**
   * loans_adhoc_payment_method_link_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_method_link_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodLinkCta(
    properties?: LoansAdhocPaymentMethodLinkCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodLinkCta(properties), options);
  }

  /**
   * loans_adhoc_payment_methods_add_bank_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_methods_add_bank_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodsAddBankCta(
    properties?: LoansAdhocPaymentMethodsAddBankCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodsAddBankCta(properties), options);
  }

  /**
   * loans_adhoc_payment_methods_bank_select_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_methods_bank_select_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodsBankSelectCta(
    properties?: LoansAdhocPaymentMethodsBankSelectCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodsBankSelectCta(properties), options);
  }

  /**
   * loans_adhoc_payment_methods_error_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_methods_error_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodsErrorViewed(
    properties?: LoansAdhocPaymentMethodsErrorViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodsErrorViewed(properties), options);
  }

  /**
   * loans_adhoc_payment_methods_save_payment_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_methods_save_payment_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodsSavePaymentCta(
    properties?: LoansAdhocPaymentMethodsSavePaymentCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodsSavePaymentCta(properties), options);
  }

  /**
   * loans_adhoc_payment_methods_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_methods_screen_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentMethodsScreenViewed(
    properties?: LoansAdhocPaymentMethodsScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentMethodsScreenViewed(properties), options);
  }

  /**
   * loans_adhoc_payment_review_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_review_cta)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loansAdhocPaymentReviewCta(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentReviewCta(), options);
  }

  /**
   * loans_adhoc_payment_review_edit_amount_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_review_edit_amount_selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentReviewEditAmountSelected(
    properties?: LoansAdhocPaymentReviewEditAmountSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentReviewEditAmountSelected(properties), options);
  }

  /**
   * loans_adhoc_payment_review_relink_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_review_relink_account)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentReviewRelinkAccount(
    properties?: LoansAdhocPaymentReviewRelinkAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentReviewRelinkAccount(properties), options);
  }

  /**
   * loans_adhoc_payment_review_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_review_screen_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentReviewScreenViewed(
    properties?: LoansAdhocPaymentReviewScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentReviewScreenViewed(properties), options);
  }

  /**
   * loans_adhoc_payment_review_view_payment_methods
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_payment_review_view_payment_methods)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocPaymentReviewViewPaymentMethods(
    properties?: LoansAdhocPaymentReviewViewPaymentMethodsProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocPaymentReviewViewPaymentMethods(properties), options);
  }

  /**
   * loans_adhoc_routing_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_routing_account)
   *
   * Fires when customer selects "routing and account" for their new payment account within the make a payment flow
   *
   * @param options Amplitude event options.
   */
  loansAdhocRoutingAccount(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocRoutingAccount(), options);
  }

  /**
   * loans_adhoc_routing_account_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_routing_account_fail)
   *
   * Fires when customer selects "routing and account number" for their new payment account within the make a payment flow and does not successfully add the account
   *
   * @param options Amplitude event options.
   */
  loansAdhocRoutingAccountFail(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocRoutingAccountFail(), options);
  }

  /**
   * loans_adhoc_routing_account_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_routing_account_success)
   *
   * Fires when customer selects "routing and account number" for their new payment account within the make a payment flow and successfully adds the account
   *
   * @param options Amplitude event options.
   */
  loansAdhocRoutingAccountSuccess(
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocRoutingAccountSuccess(), options);
  }

  /**
   * loans_adhoc_success_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/loans_adhoc_success_screen_viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  loansAdhocSuccessScreenViewed(
    properties?: LoansAdhocSuccessScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoansAdhocSuccessScreenViewed(properties), options);
  }

  /**
   * notification_permission_granted_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/notification_permission_granted_cta)
   *
   * Owner: Willian Roberg de Melo
   *
   * @param options Amplitude event options.
   */
  notificationPermissionGrantedCta(
    options?: EventOptions,
  ) {
    return this.track(new NotificationPermissionGrantedCta(), options);
  }

  /**
   * notification_permission_not_now_cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/notification_permission_not_now_cta)
   *
   * Owner: Willian Roberg de Melo
   *
   * @param options Amplitude event options.
   */
  notificationPermissionNotNowCta(
    options?: EventOptions,
  ) {
    return this.track(new NotificationPermissionNotNowCta(), options);
  }

  /**
   * notification_permission_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/notification_permission_screen_viewed)
   *
   * Owner: Willian Roberg de Melo
   *
   * @param options Amplitude event options.
   */
  notificationPermissionScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new NotificationPermissionScreenViewed(), options);
  }

  /**
   * plaid_layer_DOB_SSN_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_DOB_SSN_complete)
   *
   * This event should trigger in layer flow when customer is ineligible for layer and completes new DOB/SSN combined screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerDobSsnComplete(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerDobSsnComplete(), options);
  }

  /**
   * plaid_layer_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_error)
   *
   * This event should trigger/map to plaid's "error" event sent through layer integration
   *
   * @param options Amplitude event options.
   */
  plaidLayerError(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerError(), options);
  }

  /**
   * plaid_layer_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_exit)
   *
   * This event should trigger/map to plaid's "exit" event sent through layer integration, when a customer exits the flow
   *
   * @param options Amplitude event options.
   */
  plaidLayerExit(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerExit(), options);
  }

  /**
   * plaid_layer_fail_oauth
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_fail_oauth)
   *
   * This event should trigger/map to plaid's "fail_oauth" event sent through layer integration, when a customer fails oauth
   *
   * @param options Amplitude event options.
   */
  plaidLayerFailOauth(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerFailOauth(), options);
  }

  /**
   * plaid_layer_ineligible
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_ineligible)
   *
   * in plaid layer flow when customer opts in to begin the layer experience and plaid determines they're not eligible
   *
   * @param options Amplitude event options.
   */
  plaidLayerIneligible(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerIneligible(), options);
  }

  /**
   * plaid_layer_layer_prompt_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_layer_prompt_complete)
   *
   * This event should trigger in layer flow when customer completes new fast and easy layer prompt screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerLayerPromptComplete(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerLayerPromptComplete(), options);
  }

  /**
   * plaid_layer_name_DOB_SSN_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_name_DOB_SSN_complete)
   *
   * This event should trigger in layer flow when customer opts out of layer and completes new combined name/dob/ssn screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerNameDobSsnComplete(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerNameDobSsnComplete(), options);
  }

  /**
   * plaid_layer_opt_back_in
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_opt_back_in)
   *
   * in plaid layer flow when customer opts out of the plaid layer flow at any time, then clicks the "tap here to try again" opt in
   *
   * @param options Amplitude event options.
   */
  plaidLayerOptBackIn(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerOptBackIn(), options);
  }

  /**
   * plaid_layer_opt_out
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_opt_out)
   *
   * in plaid layer flow when customer opts out of the plaid layer flow at any time
   *
   * @param options Amplitude event options.
   */
  plaidLayerOptOut(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerOptOut(), options);
  }

  /**
   * plaid_layer_process_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_process_complete)
   *
   * in plaid layer flow when customer completes the layer flow and their profile is processed
   *
   * @param options Amplitude event options.
   */
  plaidLayerProcessComplete(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerProcessComplete(), options);
  }

  /**
   * plaid_layer_skip_submit_phone
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_skip_submit_phone)
   *
   * This event should trigger/map to plaid's "skip submit phone" event sent through layer integration, when a customer chooses to skip submitting their phone number to start the flow
   *
   * @param options Amplitude event options.
   */
  plaidLayerSkipSubmitPhone(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerSkipSubmitPhone(), options);
  }

  /**
   * plaid_layer_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_start)
   *
   * in plaid layer flow when customer opts in to begin the layer experience (click CTA on plaid + possible screen)
   *
   * @param options Amplitude event options.
   */
  plaidLayerStart(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerStart(), options);
  }

  /**
   * plaid_layer_submit_credentials
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_submit_credentials)
   *
   * This event should trigger/map to plaid's "submit credentials" event sent through layer integration, when a customer has to submit their institution creds in the flow
   *
   * @param options Amplitude event options.
   */
  plaidLayerSubmitCredentials(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerSubmitCredentials(), options);
  }

  /**
   * plaid_layer_submit_MFA
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_submit_MFA)
   *
   * This event should trigger/map to plaid's "submit MFA" event sent through layer integration, when a customer has to submit MFA to verify their ID
   *
   * @param options Amplitude event options.
   */
  plaidLayerSubmitMfa(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerSubmitMfa(), options);
  }

  /**
   * plaid_layer_submit_phone
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_submit_phone)
   *
   * This event should trigger/map to plaid's "submit" event sent through layer integration, when a customer submits their phone number to start the flow
   *
   * @param options Amplitude event options.
   */
  plaidLayerSubmitPhone(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerSubmitPhone(), options);
  }

  /**
   * plaid_layer_three_easy_steps_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_three_easy_steps_complete)
   *
   * This event should trigger in layer flow when customer is ineligible for layer and completes new 3 easy steps screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerThreeEasyStepsComplete(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerThreeEasyStepsComplete(), options);
  }

  /**
   * plaid_layer_view_DOB_SSN
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_view_DOB_SSN)
   *
   * This event should trigger in layer flow when customer is ineligible for layer and views new DOB/SSN combined screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerViewDobSsn(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerViewDobSsn(), options);
  }

  /**
   * plaid_layer_view_layer_prompt
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_view_layer_prompt)
   *
   * This event should trigger in layer flow when customer views new fast and easy layer prompt screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerViewLayerPrompt(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerViewLayerPrompt(), options);
  }

  /**
   * plaid_layer_view_name_DOB_SSN
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_view_name_DOB_SSN)
   *
   * This event should trigger in layer flow when customer opts out of layer and views new combined name/dob/ssn screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerViewNameDobSsn(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerViewNameDobSsn(), options);
  }

  /**
   * plaid_layer_view_three_easy_steps
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_layer_view_three_easy_steps)
   *
   * This event should trigger in layer flow when customer is ineligible for layer and views new 3 easy steps screen
   *
   * @param options Amplitude event options.
   */
  plaidLayerViewThreeEasySteps(
    options?: EventOptions,
  ) {
    return this.track(new PlaidLayerViewThreeEasySteps(), options);
  }

  /**
   * plaid_transition_view
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/plaid_transition_view)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. args)
   * @param options Amplitude event options.
   */
  plaidTransitionView(
    properties?: PlaidTransitionViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaidTransitionView(properties), options);
  }

  /**
   * Registration Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Account%20Created)
   *
   * Creating an account is "I have a user and password and can now long in."
   *
   * We should fire this event only **after** validation is actually successful - so either from the back-end and/or on the front-end, after the callback for successful account creation.
   *
   * **7/14/23** -- Thacher confirmed with Jonny that this should map to `enter_phone_number_screen_viewed`
   *
   * @param options Amplitude event options.
   */
  registrationAccountCreated(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationAccountCreated(), options);
  }

  /**
   * Registration Address Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Address%20Completed)
   *
   * Fires when the customer successfully enters their address (currently name) part of registration. 
   *
   *  Should fire on back-end after address verification
   *
   * @param options Amplitude event options.
   */
  registrationAddressCompleted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationAddressCompleted(), options);
  }

  /**
   * Registration Address Verification Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Address%20Verification%20Failed)
   *
   * Fires when the customer attempts to enter an address as part of registration, but the address cannot be verified and the customer gets back an error.  Should fire on back-end after address verification fails.
   *
   * @param options Amplitude event options.
   */
  registrationAddressVerificationFailed(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationAddressVerificationFailed(), options);
  }

  /**
   * Registration Email Entered
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Email%20Entered)
   *
   * Fires on the client whenever the customer enters their email (likely during registration) for the first time. 
   *
   *  Does *not* fire for a customer attempting to login, only for those attempting to register.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  registrationEmailEntered(
    properties: RegistrationEmailEnteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationEmailEntered(properties), options);
  }

  /**
   * Registration Personal Details Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Personal%20Details%20Completed)
   *
   * Fires when the customer successfully enters the personal details (currently name) part of registration.
   *
   * @param options Amplitude event options.
   */
  registrationPersonalDetailsCompleted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationPersonalDetailsCompleted(), options);
  }

  /**
   * Registration SSN Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20SSN%20Completed)
   *
   * Fires when the customer successfully enters their social security number into our system. Should fire on back-end.
   *
   * @param options Amplitude event options.
   */
  registrationSsnCompleted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationSsnCompleted(), options);
  }

  /**
   * Registration Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Started)
   *
   * Occurs when the customer *first* visits the registration page. As of today, should fire basically 1:1 with `registration_screen_viewed` .  But we're keeping it a separate event so that we can change the name of the first screen without breaking our analytics dependencies.
   *
   * @param options Amplitude event options.
   */
  registrationStarted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationStarted(), options);
  }

  /**
   * Registration Successfully Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Successfully%20Completed)
   *
   * The user has successfully gotten completely through Registration and now has no more obstacles to apply for either a Loan or a Credit Card.
   *
   * We should fire this event only after validation is actually successful - so either from the back-end and/or on the front-end, after the callback for successful registration.
   *
   * 7/14/23 -- Thacher confirmed with Jonny that this should map to `personal_details_screen_viewed`
   *
   * @param options Amplitude event options.
   */
  registrationSuccessfullyCompleted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationSuccessfullyCompleted(), options);
  }

  /**
   * Registration Verification Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Verification%20Completed)
   *
   * The user has successfully gotten through whatever the current state of Registration verification is (as of July 2023, this is verifying your phone #)
   *
   * We should fire this event only **after** validation is actually successful - so either from the back-end and/or on the front-end, after the callback for successful verification.
   *
   * **7/14/23** -- Thacher confirmed with Jonny that this should map to `enter_verification_code_submitted`
   *
   * @param options Amplitude event options.
   */
  registrationVerificationCompleted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationVerificationCompleted(), options);
  }

  /**
   * Registration Verification Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Verification%20Started)
   *
   * Fires when the customer begins verification; currently should map 1:1 with the verification screen viewed
   *
   * @param options Amplitude event options.
   */
  registrationVerificationStarted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationVerificationStarted(), options);
  }

  /**
   * Registration Waitlisted
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Registration%20Waitlisted)
   *
   * The user has gotten through verification, and we've decided to waitlist them, for some reason, based on the current availability of products.
   *
   * We should fire this event only **after** validation is actually successful - so either from the back-end and/or on the front-end, after the callback for successful registration.
   *
   * **Note**: as more waitlisting reasons emerge over time, please keep adding them to the waitlist_reason enum in the properties.
   *
   * **7/14/23** -- Thacher confirmed with Jonny that this should map to `out_of_state_wait_list_registered` and `card_waiting_list_dashboard_registered`
   *
   * @param properties The event's properties (e.g. waitlist_reason)
   * @param options Amplitude event options.
   */
  registrationWaitlisted(
    properties: RegistrationWaitlistedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationWaitlisted(properties), options);
  }

  /**
   * registration_existing_log_in
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/registration_existing_log_in)
   *
   * This event fires when a customer selects "already a customer, log in here" on the new registration page created Q124Event to track existing users logging in during the loan application process
   *
   * @param options Amplitude event options.
   */
  registrationExistingLogIn(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationExistingLogIn(), options);
  }

  /**
   * registration_get_started
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/registration_get_started)
   *
   * This event fires when a customer clicks "get started" on the new registration page Q124
   *
   * @param options Amplitude event options.
   */
  registrationGetStarted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationGetStarted(), options);
  }

  /**
   * Self-Reported Finances Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/possible-finance/Possible%20Finance%20Mobile/events/main/latest/Self-Reported%20Finances%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  selfReportedFinancesCompleted(
    options?: EventOptions,
  ) {
    return this.track(new SelfReportedFinancesCompleted(), options);
  }
}

export const ampli = new Ampli()
