import * as Types from '../../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type BankAddDebitPaymentMethodMutationVariables = Types.Exact<{
  input: Types.DebitPaymentMethodInput
}>

export type BankAddDebitPaymentMethodMutation = {
  __typename?: 'Mutation'
  bankAddDebitPaymentMethod: {
    __typename?: 'AddPaymentMethodResponse'
    paymentMethod?:
      | {
          __typename?: 'AchPaymentMethod'
          bankingPaymentInstrumentId: string
          code: Types.PaymentMethodTypeCode
          id: string
          status:
            | {__typename?: 'ExpiredPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'InvalidPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'PendingPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'RemovedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SuspendedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SystemPurgedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'VerifiedPaymentMethodStatus'; reason?: string | null}
        }
      | {
          __typename?: 'CheckPaymentMethod'
          bankingPaymentInstrumentId: string
          code: Types.PaymentMethodTypeCode
          id: string
          status:
            | {__typename?: 'ExpiredPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'InvalidPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'PendingPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'RemovedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SuspendedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SystemPurgedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'VerifiedPaymentMethodStatus'; reason?: string | null}
        }
      | {
          __typename?: 'DebitCardPaymentMethod'
          bankingPaymentInstrumentId: string
          code: Types.PaymentMethodTypeCode
          id: string
          status:
            | {__typename?: 'ExpiredPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'InvalidPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'PendingPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'RemovedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SuspendedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'SystemPurgedPaymentMethodStatus'; reason?: string | null}
            | {__typename?: 'VerifiedPaymentMethodStatus'; reason?: string | null}
        }
      | null
    result: {__typename?: 'AddPaymentMethodResult'; type: Types.AddPaymentMethodResultType}
  }
}

export const BankAddDebitPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'BankAddDebitPaymentMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitPaymentMethodInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAddDebitPaymentMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethod'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'status'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'reason'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'result'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'type'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BankAddDebitPaymentMethodMutation,
  BankAddDebitPaymentMethodMutationVariables
>
