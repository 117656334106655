import {lag, loans, ura, users} from '@possible/generated/proto'
import {
  DeleteMapActionType,
  entityActionTypes,
  isDeleteMapAction,
  isUpsertMapAction,
  UpsertMapActionType,
} from 'src/lib/actions/PfEntityAction'

export type EntityReduxState = {
  users: Map<string, users.IUser>
  loans: Map<string, loans.ILoan>
  uras: Map<string, ura.IUserRequestedAction>
  loanPayments: Map<string, lag.ILoanPaymentRecord>
}

const EntityStateDefault = {
  abFlags: new Map(),
  users: new Map(),
  loans: new Map(),
  uras: new Map(),
  loanPayments: new Map(),
}

function deleteEntity(state: any, action: DeleteMapActionType): EntityReduxState {
  const entityMapClone: Map<string, any> = new Map(state[action.entityType])
  action.keys.forEach((key) => entityMapClone.delete(key))

  state[action.entityType] = entityMapClone
  return state
}

function upsertEntity(state: any, action: UpsertMapActionType<any>): EntityReduxState {
  const entityMapClone: Map<string, any> = new Map(state[action.entityType])
  for (const id in action.values) {
    entityMapClone.set(id, action.values[id])
  }

  state[action.entityType] = entityMapClone
  return state
}

export default function reducer(
  state: EntityReduxState = EntityStateDefault,
  action: entityActionTypes,
): EntityReduxState {
  if (isDeleteMapAction(action)) {
    return deleteEntity(state, action)
  } else if (isUpsertMapAction(action)) {
    return upsertEntity(state, action)
  } else {
    return state
  }
}
