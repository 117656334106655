import React from 'react'
import {TouchableOpacity} from 'react-native'
import {StackHeaderProps} from '@react-navigation/stack'

import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type HeaderCloseXButtonPropTypes = Pick<StackHeaderProps, 'navigation'> & Pick<SvgIconProps, 'size'>

const HeaderCloseXButton = (props: HeaderCloseXButtonPropTypes): JSX.Element => {
  const {navigation, size} = props
  return (
    <TouchableOpacity onPress={(): void => navigation.pop?.()}>
      <SvgIcon name={'close'} colorVariant={'info'} size={size} />
    </TouchableOpacity>
  )
}

export {HeaderCloseXButton}
