import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import {getPaymentMethodInstitution} from 'src/products/card/PaymentMethods/PaymentMethodUtils'

/**
 * Organize payment methods by institution.
 */
export const getPaymentMethodsOrganizedByInstitution = (
  paymentMethods: PaymentAccount[],
): Record<string, PaymentAccount[]> => {
  if (!paymentMethods) return {}
  const organizedPaymentMethods: Record<string, PaymentAccount[]> = {}

  // bucket paymentMethods by institution name
  paymentMethods.forEach((paymentMethod) => {
    const instName = getPaymentMethodInstitution(paymentMethod)?.name ?? ''
    if (!organizedPaymentMethods[instName]) {
      organizedPaymentMethods[instName] = []
    }
    organizedPaymentMethods[instName].push(paymentMethod)
  })

  return organizedPaymentMethods
}
