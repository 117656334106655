import React, {PropsWithChildren, useContext, useEffect} from 'react'

import {usePrevious} from 'src/lib/utils/hooks'

import {
  OverlayBaseProps,
  OverlayConfig,
} from 'src/designSystem/components/organisms/Overlay/Overlay.types'
import {OverlayContext} from 'src/designSystem/components/organisms/Overlay/OverlayContext'

/**
 * Base component for overlays. Any overlay variant should use this to display an overlay.
 */
export const OverlayBase: React.FC<PropsWithChildren<OverlayBaseProps>> = (props) => {
  const {showOverlay, updateOverlay, hideOverlay, removeOverlay, overlays} =
    useContext(OverlayContext)
  const thisOverlayConfig: OverlayConfig | undefined = overlays[props.testID]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {visible, parentProps} = props
  const previousvisible = usePrevious<boolean>(visible)
  useEffect(() => {
    if (visible && !previousvisible) {
      showOverlay({
        overlayProps: props,
        source: 'Overlay visible useEffect()',
      })
    } else if (!visible && previousvisible && !thisOverlayConfig?.hasInitiatedAnimatedClose) {
      hideOverlay({
        overlayProps: props,
      })
    }
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  // if the parent overlay component's props change we want to re-render it within OverlayProvider
  // by updating its props (including any newly updated children) in OverlayContext state
  useEffect(() => {
    if (!thisOverlayConfig?.hasInitiatedAnimatedClose) {
      updateOverlay({
        overlayProps: props,
      })
    }
  }, [parentProps]) // eslint-disable-line react-hooks/exhaustive-deps

  // when this modal is umounted we remove its config from the global overlay context to avoid memory leak
  useEffect(() => {
    return () => {
      removeOverlay({
        overlayProps: props,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default OverlayBase
