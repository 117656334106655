import React, {FC} from 'react'

import {ReasonSurveyRESTContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyRESTContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {AppEvents} from 'src/lib/Analytics/app_events'

type Props = WorkflowPreReqFulfillScreenProps

export const ReasonSurveyWorkflowContainer: FC<Props> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.purpose_for_loan_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }

  return <ReasonSurveyRESTContainer onContinue={handleOnContinue} />
}
