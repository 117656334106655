import {LedgerItemProps} from 'src/products/card/components/molecules/LedgerItem/LedgerItem'
import {CardAccountDashboardStatus} from 'src/products/card/types'

export enum CardDashboardBalanceLedgerKind {
  PendingPayment = 'PendingPayment',
  PendingTransactions = 'PendingTransactions',
  AvailableToSpend = 'AvailableToSpend',
}

export type CardDashboardBalanceLedgerItem = Pick<
  LedgerItemProps,
  'title' | 'amount' | 'onPress' | 'separator'
> & {
  hideIfZero?: boolean // Hide/show `amount` if $0.00
  kind: CardDashboardBalanceLedgerKind
  overlay?: JSX.Element
}

const filterLedgerItems = (
  kindsToKeep: CardDashboardBalanceLedgerKind[],
  unfilteredItems: CardDashboardBalanceLedgerItem[],
): CardDashboardBalanceLedgerItem[] =>
  unfilteredItems.filter(
    ({amount, hideIfZero = false, kind}) =>
      kindsToKeep.includes(kind) && !(hideIfZero && amount === '$0.00'),
  )

export const getCardDashboardBalanceLedger = (
  cardStatus: CardAccountDashboardStatus | undefined,
  ledgerItems: CardDashboardBalanceLedgerItem[],
): CardDashboardBalanceLedgerItem[] => {
  switch (cardStatus) {
    case CardAccountDashboardStatus.SuspendedPayOverTime:
    case CardAccountDashboardStatus.SuspendedFraud:
    case CardAccountDashboardStatus.Delinquent:
    case CardAccountDashboardStatus.DelinquentNSF:
    case CardAccountDashboardStatus.DelinquencyReported:
    case CardAccountDashboardStatus.DelinquencyReportedNSF:
    case CardAccountDashboardStatus.Deactivated:
    case CardAccountDashboardStatus.DeactivatedDelinquent:
    case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
      return filterLedgerItems([CardDashboardBalanceLedgerKind.PendingPayment], ledgerItems)
    case CardAccountDashboardStatus.Active:
      return filterLedgerItems(
        [
          CardDashboardBalanceLedgerKind.PendingPayment,
          CardDashboardBalanceLedgerKind.PendingTransactions,
          CardDashboardBalanceLedgerKind.AvailableToSpend,
        ],
        ledgerItems,
      )
    case CardAccountDashboardStatus.Overdue:
    case CardAccountDashboardStatus.OverdueNSF:
    case CardAccountDashboardStatus.AtRiskDelinquency:
    case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
      return filterLedgerItems(
        [
          CardDashboardBalanceLedgerKind.PendingPayment,
          CardDashboardBalanceLedgerKind.AvailableToSpend,
        ],
        ledgerItems,
      )
    default:
      return []
  }
}
