import {
  ApolloError,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  SubscriptionOptions,
  useMutation,
  useSubscription,
} from '@apollo/client'
import {TypedDocumentNode} from '@graphql-typed-document-node/core'

import {CassandraClientOptions, GetApplication, useCassandraClient} from '../client'
import {Applications} from '../types'
import {useCassandraLazyQuery, useCassandraQuery} from './hooks'
import {QueryDataSelector} from './selectors'

const isApplicationIAM = () => GetApplication() === Applications.IAM

/**
 * Create a new hook built upon Apollo's useQuery.
 * @param query The TypedDocumentNode query to use
 * @optional @param selector Optionally provide a way to resolve just to the relevant data
 * @returns A hook to call the query
 */
export const useQueryFactory = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
  RData = unknown,
>(
  query: TypedDocumentNode<TData, TVariables>,
  selector?: QueryDataSelector<TData, RData>,
) => {
  return function useHook(
    options?: QueryHookOptions<TData, TVariables>,
    clientOptions?: CassandraClientOptions,
  ) {
    return useCassandraQuery<TData, TVariables, RData>(query, options, selector, clientOptions)
  }
}

/**
 * Create a new hook built upon Apollo's useLazyQuery.
 * @param query The TypedDocumentNode query to use
 * @optional @param selector Optionally provide a way to resolve just to the relevant data
 * @returns A hook to call the query
 */
export const useLazyQueryFactory = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
  RData = unknown,
>(
  query: TypedDocumentNode<TData, TVariables>,
  selector?: QueryDataSelector<TData, RData>,
) => {
  return function useHook(
    options?: LazyQueryHookOptions<TData, TVariables>,
    clientOptions?: CassandraClientOptions,
  ) {
    return useCassandraLazyQuery<TData, TVariables, RData>(query, options, selector, clientOptions)
  }
}

/**
 * Create a new hook built upon Apollo's useMutation.
 * @param mutation The TypedDocumentNode mutation to use
 * @optional @param followUpQuery A TypedDocumentNode query to call as a follow-up to the mutation (used to refresh the cache)
 * @returns A hook to call the mutation
 */
export const useMutationFactory = <TData = any, TVariables = OperationVariables>(
  mutation: TypedDocumentNode<TData, TVariables>,
) => {
  return function useHook(
    options?: MutationHookOptions<TData, TVariables>,
    clientOptions?: CassandraClientOptions,
  ): MutationTuple<TData, TVariables> {
    const [client] = useCassandraClient(clientOptions)

    return useMutation(mutation, {
      ...options,
      client,
      variables: {
        ...options?.variables,
        iam: isApplicationIAM(),
      } as unknown as TVariables,
    })
  }
}

export const useSubscriptionFactory = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: TypedDocumentNode<TData, TVariables>,
) => {
  type SubscriptionResult = {
    variables?: TVariables
    loading: boolean
    data?: TData
    error?: ApolloError
  }

  return function useHook(options?: SubscriptionOptions<TData, TVariables>): SubscriptionResult {
    const [client] = useCassandraClient()

    return useSubscription(subscription, {
      ...options,
      client,
      variables: {
        ...options?.variables,
        iam: isApplicationIAM(),
      } as unknown as TVariables,
    })
  }
}
