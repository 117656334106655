import React from 'react'

import OverlayBase from 'src/designSystem/components/organisms/Overlay/OverlayBase'
import {OverlayStackedButtonsProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtons.types'
import {OverlayStackedButtonsTemplate} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtonsTemplate'

/**
 * An overlay with a title, content, and stacked buttons at the bottom for a primary and secondary button.
 */
const OverlayStackedButtons: React.FC<OverlayStackedButtonsProps> = (
  props: OverlayStackedButtonsProps,
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {visible, testID} = props

  return (
    <OverlayBase testID={testID} visible={visible} parentProps={props}>
      <OverlayStackedButtonsTemplate {...props} />
    </OverlayBase>
  )
}

export default OverlayStackedButtons
