import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {formatDate, humanReadableDateShort} from 'src/lib/utils/date'
import {settlementPaymentBackground, inactivePaymentText} from 'src/designSystem/semanticColors'
import {capitalizeFirstLetter} from 'src/lib/user/utils'

type Props = StackScreenProps<MainStackParamList, 'SettlementReview'>

const SettlementReview: FC<Props> = () => {
  const {t} = useTranslation(['Settlements', 'Common'])

  // TODO FRONT-68 Get data from selectors
  const date = formatDate(new Date(), humanReadableDateShort)
  const amount = 150

  const singleButtonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary: {
      text: t('ReviewPlanAction'),
      // TODO FRONT-9
      onPress: () => {
        /*
          no-op
        */
      },
    },
    secondary: {
      buttonText: t('ReviewPlanSecondaryAction'),
      onPress: () => {
        /*
          no-op
        */
      },
    },
  }

  const renderPayments = () => {
    const transfers = [
      {
        status: 'completed',
        amount: 50,
        date: new Date(),
      },
      {
        status: 'pending',
        amount: 50,
        date: new Date(),
      },
      {
        amount: 50,
        status: 'forgiven',
        date: new Date(),
      },
      {
        amount: 50,
        status: 'forgiven',
        date: new Date(),
      },
    ]
    return transfers.map((payment, index) => {
      const textVariant = payment.status === 'pending' ? 'p_semibold' : 'p'
      const colorVariant = payment.status === 'pending' ? 'primary' : inactivePaymentText
      return (
        <Box direction="row" justify="between" paddingBottom={'small'} key={index}>
          <PFText color={colorVariant} variant={textVariant}>
            {formatDate(payment.date, humanReadableDateShort)}
          </PFText>
          <PFText color={colorVariant} variant={textVariant}>
            {capitalizeFirstLetter(payment.status === 'pending' ? 'due' : payment.status)}
          </PFText>
          <PFText color={colorVariant} variant={textVariant}>
            ${payment.amount.toFixed(2)}
          </PFText>
        </Box>
      )
    })
  }

  return (
    <Page
      buttonProps={singleButtonProps}
      variant={'generic'}
      title={t('ReviewPlanTitle')}
      description={t('ReviewPlanDescription', {amount: amount.toFixed(2), date})}
      smallTopGap
    >
      <Box justify="between" height="100%">
        <Box
          elevation={3}
          background={settlementPaymentBackground}
          paddingTop={'medium'}
          paddingBottom={'little'}
          paddingHorizontal={'small'}
        >
          <Box paddingBottom="medium">
            <PFText variant="p_semibold" textAlign="center">
              {t('PaymentSchedule')}
            </PFText>
          </Box>
          {renderPayments()}
        </Box>
        <PFText variant="p" textAlign="center">
          {t('ReviewPlanFooter')}
        </PFText>
      </Box>
    </Page>
  )
}

export default SettlementReview
