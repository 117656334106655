import React, {useState, FC, useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import PFPasswordInput from 'src/designSystem/components/molecules/PFPasswordInput/PFPasswordInput'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  validEmail,
  startsOrEndsWithWhitespace,
  getHasErrorsOrMissingValues,
} from 'src/lib/utils/formValidationUtil'
import PFEmailInput from 'src/designSystem/components/molecules/PFEmailInput/PFEmailInput'
import {ShowException} from 'src/lib/errors'
import {usePfDispatch} from 'src/store/utils'
import {ResetPasswordLogin} from 'src/api/MobileGatewayAPI/actions'
import {LoginResponseHandler} from 'src/products/MCU/RegistrationOrLogin/LoginResponseHandler'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {MobileGatewayApiResponse} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'

type FormData = {
  email: string
  temporaryPassword: string
  newPassword: string
}

const capsuleErrorCodes = ['invalid_account_state', 'invalid_password_criteria']

type Props = StackScreenProps<MainStackParamList, 'TemporaryPassword'>

const TemporaryPassword: FC<Props> = () => {
  const [requirementsMet, setRequirementsMet] = useState(false)
  const [busy, setBusy] = useState(false)
  const [response, setResponse] = useState<MobileGatewayApiResponse | undefined>(undefined)
  const dispatch = usePfDispatch()
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>({mode: 'all'})
  const {t} = useTranslation(['TemporaryPassword', 'Common'])

  const focusEffect = useCallback(() => {
    async function asyncFocusEffect() {
      const accountRecovery = getDeeplink('account_recovery')
      if (accountRecovery) {
        await clearDeeplink(accountRecovery)
      }
    }
    asyncFocusEffect()
  }, [])

  useFocusEffect(focusEffect)

  const onSubmit = async (data) => {
    setBusy(true)
    setResponse(undefined)
    try {
      const result = await dispatch(
        ResetPasswordLogin(data?.email, data?.temporaryPassword, data?.newPassword),
      )
      setResponse(result)
    } catch (e) {
      ShowException(e)
    }
    setBusy(false)
  }

  const getDisabledAction = () => {
    return !requirementsMet || busy || getHasErrorsOrMissingValues(errors, watch)
  }

  const action = {
    text: t('Common:Submit'),
    onPress: handleSubmit(onSubmit),
    disabled: getDisabledAction(),
  }

  const formProps = {
    email: {
      name: 'email',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:EmailRequiredError'),
        validate: (value) => (validEmail(value) ? undefined : t('Common:InvalidEmailError')),
      },
    },
    temporaryPassword: {
      name: 'temporaryPassword',
      field: FieldVariants.TextField,
      rules: {
        required: t('PasswordRequiredError'),
        minLength: {value: 8, message: t('MinCharactersError')},
      },
    },
    newPassword: {
      name: 'newPassword',
      field: FieldVariants.TextField,
      rules: {
        validate: (value) => {
          if (value === watch('temporaryPassword')) {
            return t('PasswordsMatchError')
          } else if (startsOrEndsWithWhitespace(value)) {
            return t('PasswordWhitespaceError')
          }
          return undefined
        },
      },
    },
  }

  const title = t('Title')
  const description = t('Description')

  const getContent = () => {
    return (
      <HookForm control={control} errors={errors}>
        <PFEmailInput label={t('Common:Email')} formProps={formProps.email} />
        <PFPasswordInput label={t('TemporaryPassword')} formProps={formProps.temporaryPassword} />
        <PFPasswordInput
          label={t('Common:NewPassword')}
          showRequirements={true}
          onAllRequirementsMet={setRequirementsMet}
          formProps={formProps.newPassword}
        />
      </HookForm>
    )
  }

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(action)}
      title={title}
      description={description}
    >
      {LoginResponseHandler({capsuleErrorCodes, response})}
      {getContent()}
    </GenericNonModalTemplate>
  )
}

export default TemporaryPassword
