import React, {ReactElement, useEffect} from 'react'

import LoanPaidOffTile from 'src/products/loans/Dashboard/LoanPaidOffTile/LoanPaidOffTile'
import {LoanProgressTile} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import LoanRejectedTile from 'src/products/loans/Dashboard/LoanRejectedTile/LoanRejectedTile'
import LoanExpiredTile from 'src/products/loans/Dashboard/LoanExpiredTile/LoanExpiredTile'
import LoanUnmetStateRequirementsTile from 'src/products/loans/Dashboard/LoanUnmetStateRequirementsTile/LoanUnmetStateRequirementsTile'
import {LoanCancelledTile} from 'src/products/loans/Dashboard/LoanCancelledTile/LoanCancelledTile'
import {LoanEligibleToApplyTile} from 'src/products/loans/Dashboard/LoanEligibleToApplyTile/LoanEligibleToApplyTile'
import LoanChargedOffSettledTile from 'src/products/loans/Dashboard/LoanChargedOffSettledTile/LoanChargedOffSettledTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {LoanClosedReasonCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {isLoanClosedReasonCodePaidOff} from 'src/lib/loans/loansUtilsV2'
import {LoanPaidOff, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {usePrevious} from 'src/lib/utils/hooks'
import {
  checkIfEligibleToApplyAgain,
  shouldShowLoanPaidOffTileForPaidOffLoan,
} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.utils'
import {DashboardLoanClosedProps} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.types'
import {usePromise} from 'src/lib/usePromise/usePromise'

/**
 * Dashboard displayed when a loan is closed and paid off.
 */

const DashboardLoanClosed: React.FC<DashboardLoanClosedProps> = (props) => {
  const {
    reason,
    reapplyOn,
    prequalAmount,
    payments,
    userEmailAddress,
    hasPreviousLoan,
    disbursement,
    onApplyForNewLoan,
    onShowDefaultExplanation: handleOnShowDefaultExplanation,
    onShowChargedOffExplanation: handleOnShowChargedOffExplanation,
    onContactUs: handleOnContactUs,
    onLoanRejectedLearnMore: handleOnLoanRejectedLearnMore,
    onViewStateDatabasePolicy: handleOnViewStateDatabasePolicy,
    onViewLoanHistory: handleOnViewLoanHistory,
  } = props

  const reapplyOnDateTime = new Date(reapplyOn)
  const previousCloseReason = usePrevious(reason)
  useEffect(() => {
    if (previousCloseReason !== reason) {
      if (isLoanClosedReasonCodePaidOff(reason)) {
        // track when a loan is paid off
        LoanPaidOff()
      }
      // the first time a close reason is viewed we track an analytics event for that particular close reason
      TrackAppEvent(
        ManageActiveLoanEvents.closed_dashboard_viewed,
        AppEvents.Category.ManageActiveLoan,
        {
          reason,
        },
      )
    }
  }, [previousCloseReason, reason])

  const {isAfterReapplyOnDate, shouldShowEligibleToApplyTile} = checkIfEligibleToApplyAgain({
    reason,
    reapplyOn,
    prequalAmount,
  })

  const tiles: ReactElement[] = []

  const [handleOnApply, {isLoading: isApplyLoading}] = usePromise(
    async (sourceForAnalytics: string | undefined): Promise<void> => {
      await onApplyForNewLoan({sourceForAnalytics})
    },
  )

  if (shouldShowEligibleToApplyTile) {
    tiles.push(
      <>
        <LoanEligibleToApplyTile
          testID="DashboardLoanClosed-LoanEligibleToApplyTile"
          prequalifiedAmount={prequalAmount ? parseFloat(prequalAmount) : undefined}
          onApplyForNewLoan={async (): Promise<void> => {
            await handleOnApply('DashboardLoanClosed-LoanEligibleToApplyTile')
          }}
          isLoading={isApplyLoading}
        />
      </>,
    )
  }
  switch (reason) {
    case LoanClosedReasonCode.Paidoff:
    case LoanClosedReasonCode.ChargedoffPaidoff:
      // we won't show paid off loan users the LoanPaidOffTile if they had a prequal
      // since we want them to see the prequal offer in LoanEligibleToApplyTile instead
      if (
        shouldShowLoanPaidOffTileForPaidOffLoan({
          isAfterReapplyOnDate,
          prequalAmount,
        })
      ) {
        tiles.push(
          <>
            <LoanPaidOffTile
              testID="DashboardLoanClosed-LoanPaidOffTile"
              onApplyForNewLoan={async (): Promise<void> => {
                await handleOnApply('DashboardLoanClosed-LoanPaidOffTile')
              }}
              reapplyOnDate={reapplyOnDateTime}
              isBusy={isApplyLoading}
            />
          </>,
        )
      }
      tiles.push(
        <>
          <LoanProgressTile
            testID="DashboardLoanClosed-LoanProgressTile"
            payments={payments}
            fundingFailed={
              false /* not used when loan is closed since we only show this if its fully paid off */
            }
            amountProcessing="0.00" /* a closed loan will never have any amount processing / in progress */
            noAccountLinked={false /* same as fundingFailed */}
            onShowDefaultExplanation={handleOnShowDefaultExplanation}
            onShowChargedOffExplanation={handleOnShowChargedOffExplanation}
            onReschedule={
              /* when a loan is closed it cant be rescheduled so this isn't necessary */

              (): void => {}
            }
            onContactUs={handleOnContactUs}
            onRelinkBankAccount={
              /* when a loan is closed there is no UX to relink a bank account so this isn't necessary */

              (): void => {}
            }
          />
        </>,
      )
      // this should always exist on a paid off loan but per the type it may be undefined so we need a safety check to please TypeScript
      if (disbursement) {
        tiles.push(
          <>
            <LoanHistoryTile
              loanPayments={payments}
              hasPreviousLoan={hasPreviousLoan}
              onViewLoanHistory={handleOnViewLoanHistory}
              loanDisbursement={disbursement}
              testID="DashboardLoanClosed-LoanHistoryTile"
            />
          </>,
        )
      }
      break
    case LoanClosedReasonCode.Rejected:
      if (isAfterReapplyOnDate) {
        // if it's after the reapplyOn date they should see the LoanEligibleToApplyTile
        // to let them apply for a new loan
        break
      }
      tiles.push(
        <>
          <LoanRejectedTile
            testID="DashboardLoanClosed-LoanRejectedTile"
            onLoanRejectedLearnMore={handleOnLoanRejectedLearnMore}
            userEmailAddress={userEmailAddress}
            reapplyOnDate={reapplyOnDateTime}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.LoanExpired:
    case LoanClosedReasonCode.DecisioningExpired:
      tiles.push(
        <>
          <LoanExpiredTile
            wasApproved={reason === LoanClosedReasonCode.LoanExpired}
            testID="DashboardLoanClosed-LoanExpiredTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanExpiredTile')
            }}
            isBusy={isApplyLoading}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.UnmetStateRequirement:
      if (isAfterReapplyOnDate) {
        break
      }
      tiles.push(
        <>
          <LoanUnmetStateRequirementsTile
            testID="DashboardLoanClosed-LoanUnmetStateRequirementsTile"
            onApplyAgain={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanUnmetStateRequirementsTile')
            }}
            onViewStateDatabasePolicy={handleOnViewStateDatabasePolicy}
            isBusy={isApplyLoading}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.Cancelled:
      tiles.push(
        <>
          <LoanCancelledTile
            testID="DashboardLoanClosed-LoanCancelledTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanCancelledTile')
            }}
            isBusy={isApplyLoading}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.ChargedoffSettled:
      tiles.push(
        <LoanChargedOffSettledTile
          onApplyForNewLoan={async (): Promise<void> => {
            await handleOnApply('DashboardLoanClosed-LoanChargedOffSettledTile')
          }}
          testID="DashboardLoanClosed-LoanChargedOffSettledTile"
          reapplyOnDate={reapplyOnDateTime}
          isBusy={isApplyLoading}
        />,
      )
      break
    case LoanClosedReasonCode.Other:
      throw new Error('"Other" close reason not implemented')
  }
  return <TileListView testID="DashboardLoanClosed" list={tiles} />
}

export {DashboardLoanClosed}
