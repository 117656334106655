import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {formatDate, humanReadableDateAtTime} from 'src/lib/utils/date'

type LastUpdatedAtProps = {
  time: Date
  onRefresh?: () => Promise<void>
}
const LastUpdatedAt: React.FC<LastUpdatedAtProps> = (props: LastUpdatedAtProps) => {
  const {t} = useTranslation('LastUpdatedAt')
  const {time, onRefresh: handleOnRefresh} = props
  let timeFormatted = formatDate(time, humanReadableDateAtTime)
  if (time.getMonth() === 4) {
    // our format abbreviates the month and adds a '.' but the month of May is already short
    // and doesn't need a period so we remove it
    timeFormatted = timeFormatted.replace('.', '')
  }
  return (
    <>
      <PFText variant="p" color={NamedColors.SILVER} textAlign="center">
        {t('LastUpdatedTime', {
          time: timeFormatted,
        })}
      </PFText>

      {handleOnRefresh ? (
        <Box align="center">
          <SvgLink
            onPress={handleOnRefresh}
            linkText={t('TapToRefresh')}
            linkType="single"
            textVariant={'p_sm'}
            linkIcon={'internal'}
          />
        </Box>
      ) : null}
    </>
  )
}

export {LastUpdatedAt}
