import moment from 'moment'

import {formatDate, humanReadableDateNumbers} from 'src/lib/utils/date'
import {LoanHistoryQuery} from 'src/products/loans/LoanHistory/LoanHistory.gqls'

export const getLoanExpirationDateStr = (
  approvedAtDatetime?: string,
  dateFormat: string = humanReadableDateNumbers,
): string | undefined => {
  if (!approvedAtDatetime || !moment(approvedAtDatetime).isValid()) return
  const expiresAt = moment(approvedAtDatetime).add(3, 'd')
  return formatDate(expiresAt, dateFormat)
}

/**
 * Installment loans should have an originalLoanId set
 */
export const isInstallmentLoan = (
  loan: NonNullable<LoanHistoryQuery['me']['loans']['all']>['0'],
): boolean => {
  return !!loan?.originalLoan?.id
}

export const isCounterOffer = (amountBorrowed, amountRequested) => {
  return amountBorrowed !== amountRequested
}
