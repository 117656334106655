import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {NamedColors} from 'src/designSystem/colors'
import {useCountdownTimer} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.utils'
import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import {ModifyEmailSendCodeMedium} from '@possible/cassandra/src/types/types.mobile.generated'
import {UserVerifyEmailSendCodeMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {userVerifyEmailConfirm} from '@possible/cassandra/src/user/authPublicMethods'
import Snackbar from 'src/lib/Snackbar'

export type EmailVerificationOverlayProps = {
  email?: string
  isEmailVerified: boolean
  isForEmailChange?: boolean
}

const EmailVerificationOverlay = (props: EmailVerificationOverlayProps): JSX.Element => {
  const {t} = useTranslation('EmailVerification')
  const {email, isEmailVerified, isForEmailChange} = props

  const [isVisible, setIsVisible] = useState(!isEmailVerified)
  const {isCounting, localizedText, resetTimer, startTimer} = useCountdownTimer()
  const [isLoading, setIsLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const sendCode = async (): Promise<void> => {
    startTimer()
    try {
      const isSuccess: boolean | undefined = await UserVerifyEmailSendCodeMutation({
        verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
      })

      if (!isSuccess) {
        throw Error('Could not send email code verification')
      }
    } catch (e) {
      Log.error(e, 'EmailVerificationOverlay send email code error')
      ShowException(e)
    }
  }

  useEffect(() => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_submitted,
      AppEvents.Category.Activation,
    )
    if (!isForEmailChange) {
      void sendCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- needs to run only once
  }, [])

  const handleOnVerify = async (): Promise<void> => {
    resetTimer()
    try {
      setIsLoading(true)

      if (!email) {
        throw new Error('Email undefined for email verification')
      }

      const isSuccess: boolean | undefined = await userVerifyEmailConfirm({
        email,
        verificationCode,
      })

      if (!isSuccess) {
        throw Error('Failed to verify email')
      }

      setIsVisible(false)

      Snackbar.success({
        title: 'Your email has been confirmed',
        duration: Snackbar.LENGTH_LONG,
      })
    } catch (e) {
      Log.error(e, 'EmailVerificationOverlay verify Email Code error')
      ShowException(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnResendMyCode = (): void => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_resend_selected,
      AppEvents.Category.Activation,
    )
    void sendCode()
  }

  return (
    <Overlay
      visible={isVisible}
      testID="Email-Verification-Overlay"
      onOkay={handleOnVerify}
      hideOnOkay={false}
      okayButtonDisabled={isLoading}
      okayButtonSize="medium"
      okayButtonText={t('OnConfirmCTA')}
      renderKey={localizedText}
      title={
        <>
          <SvgIcon name="check" size={'medium'} colorVariant="success" />
          {isForEmailChange ? (
            <PFText variant="h3">{t('verifyYourEmailTitle')}</PFText>
          ) : (
            <PFText variant="h3">{t('title')}</PFText>
          )}
        </>
      }
      shouldContentScroll
    >
      <Box background="white" gap="little">
        <PFText variant="p">
          <Trans
            t={t}
            i18nKey="description"
            values={{email}}
            components={{bold: <PFText key="email" variant="p_semibold" />}}
          />
        </PFText>

        <PFText variant="p_semibold">{t('verificationCodeTitle')}</PFText>
        <PFTextInput
          maxLength={4}
          testID="Verification-Code-Input"
          label={t('verificationCodeLabel')}
          onChangeText={(value) => setVerificationCode(value)}
        />
        <Box align={'center'} marginTop={'small'} gap={'tiny'}>
          <PFText variant={'p'} color={NamedColors.SILVER}>
            {t('DidntReceiveYourCode')}
          </PFText>
          <ButtonLink
            size="medium"
            onPress={handleOnResendMyCode}
            testID={'Resend-My-Code-Link'}
            disabled={isCounting}
          >
            {isCounting ? localizedText : t('Common:ResendMyCode')}
          </ButtonLink>
        </Box>
      </Box>
    </Overlay>
  )
}

export {EmailVerificationOverlay}
