import React, {FC, ReactElement} from 'react'

import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'
import {OHStateDisclosureTemplate} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/OHStateDisclosure/OHStateDisclosureTemplate'
import {StateDisclosureWorkflowContainerBase} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosureWorkflowContainerBase'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

type Props = WorkflowPreReqFulfillScreenProps

export const OHStateDisclosureWorkflowContainer: FC<Props> = (props) => {
  const {onPreReqFulfilled: handleOnPreReqFulfilled} = props
  return (
    <StateDisclosureWorkflowContainerBase
      onPreReqFulfilled={handleOnPreReqFulfilled}
      stateCode={StateCodes.Oh}
      render={(props): ReactElement => <OHStateDisclosureTemplate {...props} />}
    />
  )
}
