import {Consumer, GetMeAction, AchPaymentMethod} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'
import {setSelectedPayNowPaymentMethod} from 'src/lib/card/slice'
import {usePfDispatch} from 'src/store/utils'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UseLinkAchPaymentMethodMutationDocument} from 'src/products/general/GeneralPaymentMethods/useLinkAchPaymentMethod/useLinkAchPaymentMethodMutation.gqls'

type UseLinkAchPaymentMethodErrorMessages = {
  missingAccountId: string
  unableToCreateAccount: string
  unableToRetrievePaymentInstrument: string
  unableToLinkAccount: string
}

type UseLinkAchPaymentMethodReturnValue = ({
  routingNumber,
  accountNumber,
  linkedAccountId,
  cardAccountId,
  shouldSetSelectedPayNowPaymentMethod,
  shouldSetPrimaryPaymentMethod,
}: {
  routingNumber: string
  accountNumber: string
  linkedAccountId?: string
  cardAccountId?: string
  shouldSetSelectedPayNowPaymentMethod?: boolean
  shouldSetPrimaryPaymentMethod?: boolean
}) => Promise<AchPaymentMethod>

/**
 * Given a LinkedAccount, make this a Payment Method.
 * This requires:
 * 1) Creating an ACH Payment Instrument from the LinkedAccount
 * 2) Creating a Payment Method from the ACH Payment Instrument
 * 3) If shouldSetPrimaryPaymentMethod is true, then set the new payment method to be the user primary payment method
 * If shouldSetSelectedPayNowPaymentMethod is true, then save the new payment method to selectedPayNowPaymentMethod
 */
export const useLinkAchPaymentMethod = (args: {
  errorMessages: UseLinkAchPaymentMethodErrorMessages
}): UseLinkAchPaymentMethodReturnValue => {
  const {errorMessages} = args
  const [bankAddAchPaymentMethod] = useCassandraMutation(UseLinkAchPaymentMethodMutationDocument)
  const [applyLinkPaymentMethod] = Consumer.hooks.useCardAccountLinkPaymentMethodMutation()
  const dispatch = usePfDispatch()

  return async ({
    routingNumber,
    accountNumber,
    linkedAccountId,
    cardAccountId,
    shouldSetSelectedPayNowPaymentMethod = false,
    shouldSetPrimaryPaymentMethod = false,
  }) => {
    if (!linkedAccountId || !cardAccountId) {
      throw new Error(errorMessages.missingAccountId)
    }

    // 1) Creating an ACH Payment Instrument from the LinkedAccount
    const r = await bankAddAchPaymentMethod({
      variables: {
        routingNumber,
        accountNumber,
        linkedAccountId,
      },
    })

    if (r.errors?.length) {
      // Rely on the caller for error handling
      Log.log(r.errors?.[0].message)
      throw new Error(errorMessages.unableToCreateAccount)
    }

    const paymentInstrumentId = r?.data?.bankAddAchPaymentMethod?.paymentMethod?.id

    if (!paymentInstrumentId) {
      throw new Error(errorMessages.unableToRetrievePaymentInstrument)
    }

    // 2) Creating a Payment Method from the ACH Payment Instrument, and setting makePrimary if necessary
    const r2 = await applyLinkPaymentMethod({
      variables: {
        cardAccountId,
        userPaymentMethodID: paymentInstrumentId,
        makePrimary: shouldSetPrimaryPaymentMethod,
      },
    })

    const paymentMethod = r2.data?.cardAccountLinkPaymentMethod

    const isValidPaymentMethod = (
      maybePaymentMethod: typeof paymentMethod,
    ): maybePaymentMethod is AchPaymentMethod =>
      maybePaymentMethod?.__typename === 'AchPaymentMethod'

    if (r2.errors?.length || !isValidPaymentMethod(paymentMethod)) {
      // Rely on the caller for error handling
      Log.log(r.errors?.[0].message)
      throw new Error(errorMessages.unableToLinkAccount)
    }

    // save the new CardPaymentMethodType
    if (shouldSetSelectedPayNowPaymentMethod) {
      dispatch(setSelectedPayNowPaymentMethod(paymentMethod))
    }

    await dispatch(GetMeAction())

    return paymentMethod
  }
}
