import React, {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap, smallGap} from 'src/designSystem/layout'
import {TextVariants} from 'src/designSystem/typography'
import Box from 'src/designSystem/components/atoms/Box/Box'

/**
 * This component can optionally take a title
 * if there is a title it will display title, link, description
 * if there is no title it will display description, link
 */

type CardDashboardPaymentBodyDeactivatedProps = Pick<ReturnType<typeof useTranslation>, 't'> & {
  title?: ReactNode
  description: ReactNode
  onPress?: () => void
  titleVariant?: TextVariants
  descriptionVariant?: TextVariants
  textAlign?: 'center' | 'auto' | 'left' | 'right' | 'justify'
}

export const CardDashboardPaymentBodyDeactivated: FC<CardDashboardPaymentBodyDeactivatedProps> = (
  props,
) => {
  const {
    t,
    title,
    description,
    onPress: handleOnPress,
    titleVariant = 'p_semibold',
    descriptionVariant = 'p',
    textAlign = 'center',
  } = props

  return (
    <Box>
      {title ? (
        <Box boxStyle={styles.titleStyle}>
          <PFText variant={titleVariant} textAlign={textAlign}>
            {title}
          </PFText>
        </Box>
      ) : null}
      {!title ? (
        <Box boxStyle={styles.descriptionWithoutTitleStyle}>
          <PFText variant={descriptionVariant} textAlign={textAlign}>
            {description}
          </PFText>
        </Box>
      ) : null}
      {handleOnPress ? (
        <Box align="center">
          <SvgLink
            onPress={handleOnPress}
            linkIcon={'internal'}
            linkText={t('TapForDetails')}
            linkType={'single'}
          />
        </Box>
      ) : null}
      {title ? (
        <Box boxStyle={styles.descriptionWithTitleStyle}>
          <PFText variant="p" textAlign={textAlign}>
            {description}
          </PFText>
        </Box>
      ) : null}
    </Box>
  )
}

const styles = StyleSheet.create({
  descriptionWithTitleStyle: {
    paddingTop: littleGap,
  },
  descriptionWithoutTitleStyle: {
    paddingBottom: smallGap,
  },
  titleStyle: {
    paddingBottom: littleGap,
  },
})
