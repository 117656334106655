import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushMainPage} from 'src/navigation/NavHelper'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {uraIds} from 'src/lib/ura/selectors'
import {PfReduxState} from 'src/reducers/types'
import {completeUra} from 'src/cassandra'
import {ShowException} from 'src/lib/errors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'

type Props = {
  uraId: string
  navigation?: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const OverduePayment: FC<Props> = (props) => {
  const {uraId, navigation} = props
  const dispatch = usePfDispatch()
  const {t} = useTranslation('OverduePayment')
  // URA redux state needs proper typing before this can work
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  const ura: any = usePfSelector((state: PfReduxState) => uraIds(state)?.get(uraId))

  useEffect(() => {
    dispatch(URAUIUpdateAction([{id: uraId}], 'MergeRecord'))
  }, [dispatch, uraId])

  const close = (): void => {
    navigation?.goBack()
  }

  const onDismiss = async (routeName: keyof MainStackParamList): Promise<void> => {
    try {
      await completeUra(uraId)
      close()
      PushMainPage(routeName)
    } catch (e) {
      ShowException(e)
    }
  }
  const primaryCta: LightboxNoticeAction = {
    text: t('PrimaryCta'),
    size: 'large',
    onPress: () => onDismiss('CardAdhocPayment'),
  }

  const secondaryCta: LightboxNoticeAction = {
    text: t('SecondaryCta'),
    size: 'large',
    onPress: () => onDismiss('CardHowPayOverTimeWorks'),
  }

  return (
    <URAModalContent
      uraId={uraId}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ura={ura}
      primary={primaryCta}
      secondary={secondaryCta}
      close={close}
      extra={
        <Box gap={'little'}>
          <PFText variant={'p'}>{t('Description')}</PFText>
        </Box>
      }
    />
  )
}

export default OverduePayment
