import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AcceptAgreementsGetAgreementDocUrlMutationVariables = Types.Exact<{
  loanId: Types.Scalars['ID']['input']
  type: Types.LoanDocumentType
}>

export type AcceptAgreementsGetAgreementDocUrlMutation = {
  __typename?: 'Mutation'
  loanGetDocument?: {__typename?: 'PresignedUrl'; url: string} | null
}

export const AcceptAgreementsGetAgreementDocUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsGetAgreementDocUrl'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanDocumentType'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGetDocument'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'url'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsGetAgreementDocUrlMutation,
  AcceptAgreementsGetAgreementDocUrlMutationVariables
>
