import React, {ReactNode} from 'react'
import {StyleSheet} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {
  ProgressTrackerStepLayout,
  ProgressTrackerStepLayoutProps,
} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTrackerStepLayout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {tinyGap} from 'src/designSystem/layout'

type ProgressTrackerStepProps = Omit<ProgressTrackerStepLayoutProps, 'children'> & {
  title: ReactNode
  subtitle?: ReactNode
}

const ProgressTrackerStep = ({
  subtitle,
  title,
  ...props
}: ProgressTrackerStepProps): JSX.Element => (
  <ProgressTrackerStepLayout {...props}>
    <PFText variant={'p_semibold'}>{title}</PFText>
    {subtitle ? (
      <PFText color={NamedColors.SILVER} textProps={textProps} variant={'p_sm'}>
        {subtitle}
      </PFText>
    ) : null}
  </ProgressTrackerStepLayout>
)

const styles = StyleSheet.create({
  subtitle: {
    paddingTop: tinyGap / 2,
  },
})

const textProps = {style: styles.subtitle} // The `PFText` API forces this somewhat awkward pattern.

export {ProgressTrackerStep}
export type {ProgressTrackerStepProps}
