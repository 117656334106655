import {PaymentAction} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput.types'

type GetIsButtonSelectedProps = {
  buttonActions: PaymentAction[]
  currentAction: PaymentAction
  trackingId: string
}

const getIsButtonSelected = ({
  buttonActions,
  currentAction,
  trackingId,
}: GetIsButtonSelectedProps): boolean => {
  const buttonTrackingIds = buttonActions.map((action) => action.trackingId)

  return (
    currentAction.trackingId === trackingId ||
    ((currentAction.isFallback && !buttonTrackingIds.includes(trackingId)) as boolean)
  )
}

export {getIsButtonSelected}
export type {GetIsButtonSelectedProps}
