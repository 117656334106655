import React, {RefObject, useCallback, useEffect, useRef, forwardRef} from 'react'
import {Animated, View, TouchableOpacity, ViewStyle} from 'react-native'
import ReactDOM from 'react-dom'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import PFElevation from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import styles, {
  ActiveOptionStyle,
  baseBorderRadius,
  OuterWrapperAbsoluteViewStyle,
  AbsoluteViewDimensionTypes,
  MAX_DROPDOWN_HEIGHT,
} from 'src/designSystem/components/atoms/PFDropdown/Dropdown/styles'
import {DropdownPropTypes} from 'src/designSystem/components/atoms/PFDropdown/Dropdown/types'

interface DropdownPropTypesWeb extends DropdownPropTypes {
  animationControl: Animated.Value
}

const Dropdown = forwardRef((props: DropdownPropTypesWeb, _ref: React.Ref<any>) => {
  const {
    editable,
    hasFocus,
    placeholderObj,
    value,
    options,
    setHasFocus,
    testID,
    onValueChange,
    children,
  } = props

  const dropdownInnerRef: RefObject<any> = useRef(null)

  const portalTarget: HTMLElement | null = document.getElementById('dropdown-root')

  const removeDropdownNode = useCallback(() => {
    if (portalTarget) {
      ReactDOM.unmountComponentAtNode(portalTarget)
    }
  }, [portalTarget])

  useEffect(() => {
    if (!hasFocus) {
      removeDropdownNode()
    }
  }, [hasFocus, removeDropdownNode])

  const getDropdownMenu = () => {
    const optionsComponent = [placeholderObj, ...options].map((item, index) => {
      const isActiveValue = value === item.value
      const isPlaceholder = index === 0
      const activeOptionsStyle: Animated.WithAnimatedValue<ViewStyle> = ActiveOptionStyle(
        isActiveValue,
        isPlaceholder,
      )
      const getOptionColor = () => {
        if (isActiveValue && !isPlaceholder) {
          return DefaultVariantsColor['white']
        } else if (isPlaceholder) {
          return DefaultVariantsColor['textDisabled']
        } else {
          return DefaultVariantsColor['black']
        }
      }

      return (
        <TouchableOpacity
          key={index}
          activeOpacity={1}
          onPress={() => {
            onValueChange(item.value)
            setHasFocus(false)
          }}
        >
          <Animated.View style={activeOptionsStyle}>
            <PFText variant={'p'} color={getOptionColor()}>
              {item.label}
            </PFText>
          </Animated.View>
        </TouchableOpacity>
      )
    })

    const getDimensions = (): AbsoluteViewDimensionTypes | undefined => {
      if (dropdownInnerRef?.current) {
        return dropdownInnerRef.current.getBoundingClientRect()
      }
    }

    const outerWrapperAbsoluteViewStyle: Animated.WithAnimatedValue<ViewStyle> =
      OuterWrapperAbsoluteViewStyle(getDimensions())

    const getModalContent = () => {
      if (hasFocus) {
        return (
          <Animated.View style={outerWrapperAbsoluteViewStyle}>
            <PFElevation
              level={4}
              backgroundColor={DefaultVariantsColor['white']}
              borderRadius={baseBorderRadius}
            >
              <Animated.View style={styles.innerWrapper}>
                <Animated.View style={{overflow: 'scroll', maxHeight: MAX_DROPDOWN_HEIGHT}}>
                  {optionsComponent}
                </Animated.View>
              </Animated.View>
            </PFElevation>
          </Animated.View>
        )
      } else {
        return null
      }
    }
    return portalTarget ? ReactDOM.createPortal(getModalContent(), portalTarget) : null
  }

  return (
    <View ref={dropdownInnerRef}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          if (editable) {
            setHasFocus(!hasFocus)
          }
        }}
        testID={testID}
      >
        {children}
      </TouchableOpacity>
      {getDropdownMenu()}
    </View>
  )
})

Dropdown.displayName = 'Dropdown'

export default Dropdown
