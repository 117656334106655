import Cookies from 'universal-cookie'

type UserInfo = {
  [key: string]: string
}

const cookies = new Cookies(null, {path: '/'})

export async function setGenericPassword(username: string, password: string): Promise<void> {
  return Promise.resolve(cookies.set('userInfo', {username, password}))
}

export async function resetGenericPassword(): Promise<void> {
  return Promise.resolve(cookies.remove('userInfo'))
}

export async function getGenericPassword(): Promise<UserInfo> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Promise.resolve(cookies.get('userInfo'))
}

export async function setServicePassword(username: string, password: string): Promise<void> {
  return Promise.resolve(cookies.set('userInfo', {username, password}))
}

export async function resetServicePassword(): Promise<void> {
  return Promise.resolve(cookies.remove('userInfo'))
}

export async function getServicePassword(): Promise<UserInfo> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Promise.resolve(cookies.get('userInfo'))
}
