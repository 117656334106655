import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  StateSpecificAgreement,
  AgreementType,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {
  AcceptLoanAndStateAgreementsBaseTemplate,
  AcceptLoanAndStateAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsBaseTemplate'

export type AcceptLoanAndStateAgreementsHITemplateProps = Omit<
  AcceptLoanAndStateAgreementsBaseTemplateProps,
  // these are specified by this HI template so they don't propagate to the parent
  'stateSpecificAgreements' | 'loanAgreementTestID'
>
export const AcceptLoanAndStateAgreementsHITestIds = {
  firstAcceptInstallmentPlanDisclosure: 'HAWAII_ACCEPT_INSTALLMENT_PLAN_DISCLOSURE',
  secondLoanAgreement: 'HAWAII_LOAN_AGREEMENT',
} as const

const AcceptLoanAndStateAgreementsHITemplate: React.FC<
  AcceptLoanAndStateAgreementsHITemplateProps
> = (props) => {
  const {
    isLoading,
    isError,
    isSubmitting,
    loanAmountBorrowed,
    onOpenAgreementDocument: handleOnOpenAgreementDocument,
    onAcceptAndContinue: handleOnAcceptAndContinue,
  } = props
  const {t} = useTranslation('LoanApproved')
  const stateSpecificAgreements: StateSpecificAgreement[] = [
    {
      title: t('InstallmentPlanDisclosure'),
      documentTypeToDownload: LoanDocumentType.StateDisclosure,
      agreementType: AgreementType.AcceptInstallmentPlanDisclosure,
      id: AcceptLoanAndStateAgreementsHITestIds.firstAcceptInstallmentPlanDisclosure,
    },
  ]
  const loanAgreementTestID = AcceptLoanAndStateAgreementsHITestIds.secondLoanAgreement

  return (
    <AcceptLoanAndStateAgreementsBaseTemplate
      testID="AcceptLoanAndStateAgreementsHI"
      stateSpecificAgreements={stateSpecificAgreements}
      loanAgreementTestID={loanAgreementTestID}
      loanAmountBorrowed={loanAmountBorrowed}
      isLoading={isLoading}
      isError={isError}
      isSubmitting={isSubmitting}
      onOpenAgreementDocument={handleOnOpenAgreementDocument}
      onAcceptAndContinue={handleOnAcceptAndContinue}
    />
  )
}

export {AcceptLoanAndStateAgreementsHITemplate}
