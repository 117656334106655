import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useMemo, useRef} from 'react'

import {isAdvanceState} from 'src/lib/advance/CashAdvanceUtils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {GetWorkflowInitInfoDocument} from 'src/workflows/hooks/workflowInit.gqls'
import {wfError, wfLog} from 'src/workflows/logging'
import {WorkflowsInitialRouteType, WorkflowsReadyType, WorkflowsType} from 'src/workflows/types'
import {
  GetNextRouteFromPreReqs,
  isInActivationStage,
  isInOfferStage,
} from 'src/workflows/workflow.utils'

const NoCodePathsMatchedError = new Error('No code paths matched')

const getWorkflowsInitialRoute = (
  workflowsData: WorkflowsReadyType,
  initSplashScreenOverride?: keyof MainStackParamList,
): WorkflowsInitialRouteType => {
  const {applications, offers, selectedOffer, unmetMinPreReqs} = workflowsData

  const nextMinPreReqRoute = GetNextRouteFromPreReqs(unmetMinPreReqs)

  // Workflows
  if (isInActivationStage(applications)) {
    // Activation Flow
    return {loading: false, name: 'ProductHub', error: undefined}
  } else if (isInOfferStage(selectedOffer, applications)) {
    // Offer Flow
    wfLog('Going to OfferApplicationWorkflow')
    return {loading: false, name: 'OfferApplicationWorkflow', error: undefined}
  } else if (offers.length > 0 || applications?.length > 0) {
    // Product Hub
    wfLog('Going to ProductHub')
    return {loading: false, name: 'ProductHub', error: undefined}
  } else if (nextMinPreReqRoute) {
    // the user has minimum pre-reqs to fulfill, send 'em to sign up
    return {loading: false, name: initSplashScreenOverride ?? 'SignupWorkflow', error: undefined}
  } else if (offers.length === 0 && applications?.length === 0) {
    // if we have no offers and no applications there is nothing the user can do;
    // let's send 'em to the waitlist
    wfLog('Going to Waitlist')
    return {loading: false, name: 'UnsupportedStateWaitList', error: undefined}
  } else {
    // this should not be reached;
    // but let's log and event it
    TrackAppEvent(
      AppEvents.Name.workflow_initial_route_error,
      AppEvents.Category.Workflows,
      NoCodePathsMatchedError,
    )
    wfError(NoCodePathsMatchedError, 'Error while determining initial route.')
    return {
      loading: false,
      error: NoCodePathsMatchedError,
      refetch: workflowsData.refetch,
    }
  }
}

/**
 * A hook to get the initialRoute for AuthenticatedRoutes based on the
 * status of the workflows data.
 * @param workflowsData The workflows data to use.
 * @param fallbackRouteName The route to use if we should not be using workflows.
 * @returns An initial route to use or undefined if the data is still loading.
 */
export const useWorkflowsInitialRoute = (
  workflowsData: WorkflowsType,
): WorkflowsInitialRouteType => {
  const resolvedInitialRoute = useRef<WorkflowsInitialRouteType | undefined>(undefined)

  const {data, loading: isLoading, error} = useCassandraQuery(GetWorkflowInitInfoDocument)
  const {canAccessCards, isLoading: isLoadingCanAccessCards} = useCanAccessCards()

  return useMemo(() => {
    if (resolvedInitialRoute.current) {
      return resolvedInitialRoute.current
    }

    if (isLoading || isLoadingCanAccessCards) {
      return {loading: true}
    }

    switch (workflowsData.status) {
      case 'LOADING':
        return {loading: true}
      case 'ERROR':
        return {loading: false, error: workflowsData.error, refetch: workflowsData.refetch}
      case 'READY': {
        const initSplashScreenOverride =
          !error && isAdvanceState(data?.me?.onboarding?.loan?.stateSelected, canAccessCards)
            ? 'AdvanceSplashScreen'
            : undefined

        resolvedInitialRoute.current = getWorkflowsInitialRoute(
          workflowsData,
          initSplashScreenOverride,
        )
        return resolvedInitialRoute.current
      }
    }
  }, [workflowsData, data, isLoading, error, canAccessCards, isLoadingCanAccessCards])
}
