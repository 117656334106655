import React, {FC, useEffect, useRef} from 'react'
import Collapsible from 'react-native-collapsible'
import {ScrollView} from 'react-native-gesture-handler'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {InputRef} from 'src/designSystem/components/atoms/PFTextInput/Input'

export type RadioButtonExtraInputType = {
  label: string
  onChange: (string: string) => void
  onSubmitEditing: () => void
  testID?: string
}

type RadioButtonExtraInputProps = RadioButtonExtraInputType & {
  isSelected: boolean
  value: string
  isDisabled?: boolean
}

const RadioButtonExtraInput: FC<RadioButtonExtraInputProps> = (props) => {
  const {
    label,
    onChange,
    onSubmitEditing: handleSubmitEditing,
    isSelected,
    value,
    isDisabled,
    testID,
  } = props
  const inputRef: InputRef = useRef(null)

  useEffect(() => {
    if (isSelected) {
      inputRef?.current?.focus()
    } else {
      inputRef?.current?.blur()
    }
  }, [isSelected])

  return (
    <Collapsible collapsed={!isSelected}>
      <ScrollView>
        <Box marginTop={'small'}>
          <PFTextInput
            testID={testID}
            ref={inputRef}
            label={label}
            value={value}
            onChangeText={(text: string): void => onChange(text)}
            onSubmitEditing={handleSubmitEditing}
            editable={!isDisabled}
          />
        </Box>
      </ScrollView>
    </Collapsible>
  )
}

export {RadioButtonExtraInput}
