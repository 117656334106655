import React from 'react'
import {ViewStyle, TouchableOpacity} from 'react-native'

import {DefaultVariantsColor} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconSize} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {getColorValue} from 'src/designSystem/lib/colorUtil'
import {ButtonAction, Color, SizeVariants} from 'src/designSystem/types'
import {TextVariants} from 'src/designSystem/typography'
import {Extends} from 'src/lib/utils/typesUtil'

export type ButtonSizeType = Extends<SizeVariants, 'little' | 'small' | 'medium' | 'large'>
export const iconSideValues = ['left', 'right'] as const
export type IconSide = (typeof iconSideValues)[number]

const buttonSizeTextVariantMap: {[key in ButtonSizeType]: TextVariants} = {
  little: 'p_sm',
  small: 'p_sm_semibold',
  medium: 'label_md',
  large: 'label_lg',
}
const buttonSizeIconSizeMap: {[key in ButtonSizeType]: SvgIconSize} = {
  little: 'little',
  small: 'little',
  medium: 'small',
  large: 'medium',
}

export type ButtonLinkProps = React.PropsWithChildren & {
  buttonStyle?: ViewStyle
  color?: Color
  containerStyle?: ViewStyle
  disabled?: boolean
  icon?: SvgIconProps['name']
  iconSide?: IconSide
  onPress: ButtonAction
  size?: ButtonSizeType
  testID?: string
  textVariantOverride?: TextVariants
}

const ButtonLink = ({
  buttonStyle = {},
  children,
  color = undefined,
  containerStyle,
  disabled,
  icon,
  iconSide = 'right',
  onPress,
  size = 'medium',
  testID = 'ButtonLink',
  textVariantOverride,
}: ButtonLinkProps): JSX.Element => {
  const variant = textVariantOverride ?? buttonSizeTextVariantMap[size]

  const computedStyle: ViewStyle = {
    alignItems: 'center',
    ...containerStyle,
  }

  const computedButtonStyle: ViewStyle = {
    ...computedStyle,
    ...buttonStyle,
  }

  const getColor = (): Color => {
    let computedColor = getColorValue(color) ?? DefaultVariantsColor.link

    if (size === 'little') {
      computedColor = DefaultVariantsColor.black
    }

    if (disabled) {
      computedColor = DefaultVariantsColor.textDisabled
    }

    return computedColor
  }

  let computedGapSize: SizeVariants = 'little'
  if (size === 'little' ?? size === 'small') {
    computedGapSize = 'tiny'
  }

  return (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={disabled ? 1.0 : 0.8}
      style={computedButtonStyle}
      onPress={disabled ? undefined : onPress} // eslint-disable-line react/jsx-handler-names
      testID={`${testID}-TouchableOpacity`}
    >
      <Box
        gap={computedGapSize}
        direction={iconSide === 'left' ? 'row-reverse' : 'row'}
        boxStyle={computedStyle}
      >
        <PFText variant={variant} color={getColor()} testID={testID}>
          {children}
        </PFText>

        {icon ? (
          <SvgIcon
            name={icon}
            colorVariant={'custom'}
            customColor={getColor()}
            size={buttonSizeIconSizeMap[size]}
          />
        ) : null}
      </Box>
    </TouchableOpacity>
  )
}

export {ButtonLink}
