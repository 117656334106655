import React from 'react'
import {StyleSheet, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {contentMaxWidth, largeGap, mediumGap, tinyGap} from 'src/designSystem/layout'

import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {ReviewCarousel} from 'src/products/MCU/RegistrationOrLogin/Registration/ReviewCarousel/ReviewCarousel'

export const RegistrationHeader: React.FC = (): JSX.Element => {
  const {t} = useTranslation('RegistrationOrLogin')
  return (
    <View style={styles.container}>
      <SafeAreaView edges={['top', 'right', 'left']} style={styles.safeAreaViewTop}>
        <Box paddingTop={mediumGap}>
          <NameLogo isInverted align="center" />
        </Box>
        <Box
          alignSelf="start"
          paddingHorizontal={mediumGap}
          width={'100%'}
          paddingTop={largeGap}
          paddingBottom={tinyGap}
        >
          <Box gap={'tiny'} marginBottom={'small'}>
            <PFText
              testID="Registration-Page-Title"
              variant={'d3'}
              color={NamedColors.WHITE}
              textAlign="left"
            >
              {t('LetsGetStarted')}
            </PFText>
            <PFText variant={'p'} color={NamedColors.SKY}>
              {t('RegistrationV2Subtitle')}
            </PFText>
          </Box>
        </Box>
        <ReviewCarousel />
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  safeAreaViewTop: {
    backgroundColor: NamedColors.BLACK,
    width: '100%',
    maxWidth: contentMaxWidth,
  },
})
