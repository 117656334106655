import {GetMeAction} from '@possible/cassandra'
import {
  UserModifyProfileDocument,
  UserModifyProfileMutationVariables,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyMutation} from '@possible/cassandra/src/utils/operations'
import APIClient from '@possible/generated/APIClient'

import {userIdSelector} from 'src/api/selectors/selectors'
import Log from 'src/lib/loggingUtil'
import {URA_UPDATE} from 'src/lib/ura/actions/uraActions'
import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'
import {PfDispatch, PfGetState} from 'src/store/types'

//////////// User Actions

const checkNoError = (response) => {
  return response && !response.getErrorStr()
}

interface UserInfoUpdate {
  firstName: string
  lastName: string
  middleName: string
  nameSuffix: string
  birthDate: string
  ssn: string
}

const _transformUserInfo = (
  userInfo: Partial<UserInfoUpdate>,
): UserModifyProfileMutationVariables => {
  const info: UserModifyProfileMutationVariables = {}

  if (userInfo.firstName && userInfo.lastName) {
    info.name = {
      first: userInfo.firstName,
      last: userInfo.lastName,
      middle: userInfo.middleName,
      suffix: userInfo.nameSuffix,
    }
  }

  if (userInfo.birthDate) {
    info.dob = {dob: formatDate(userInfo.birthDate, graphqlDateFormat)}
  }

  if (userInfo.ssn) {
    info.ssn = {ssn: userInfo.ssn}
  }

  return info
}

export function userInfoUpdate(userInfo: Partial<UserInfoUpdate>) {
  return async (dispatch) => {
    try {
      const response = await ApplyMutation(UserModifyProfileDocument, _transformUserInfo(userInfo))

      if (!response) {
        throw new Error('Failed to update user info')
      }
      await dispatch(GetMeAction())

      return response['userModifyProfile']
    } catch (e) {
      Log.error(e, 'userInfoUpdate error:')
      throw e
    }
  }
}

export function URASet(req) {
  return async (dispatch) => {
    const response = await APIClient.ura_set_status(req)
    if (checkNoError(response)) {
      await dispatch({type: URA_UPDATE, ura: [response.action]})
    }

    return response
  }
}
