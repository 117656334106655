import React, {useContext, useEffect, useRef, useState} from 'react'
import {Animated, StyleSheet} from 'react-native'

import {isDeviceAndroid} from 'src/lib/utils/platform'
import {animationDuration} from 'src/designSystem/animations'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {modalBackgroundColor} from 'src/designSystem/semanticColors'
import {usePrevious} from 'src/lib/utils/hooks'
import {OverlayContext} from 'src/designSystem/components/organisms/Overlay/OverlayContext'
import {OverlayConfig} from 'src/designSystem/components/organisms/Overlay/Overlay.types'

const overlayMaxWidth = 400

/**
 * OverlayModal component is just provided the overlayId. This is used to access this particular
 * overlay's configuration/props from the OverlayContext, which contains config for all overlays.
 */
export type OverlayModalProps = {
  overlayId: string
}

/**
 * Base modal component that renders an overlay. Animates open/close behavior and displays children
 * within a modal.
 */
const OverlayModal: React.FC<OverlayModalProps> = (props: OverlayModalProps) => {
  const {overlayId} = props
  const {overlays, completedHideOverlay} = useContext(OverlayContext)

  const thisOverlayConfig: OverlayConfig | undefined = overlays[overlayId]
  const {overlayProps, onCloseAnimationComplete, hasInitiatedAnimatedClose} = thisOverlayConfig

  const opacity = useRef(new Animated.Value(0)).current

  // this is a ridiculous hack to fix shadow showing up
  // in android too soon/too late in animations
  const [showElevation, setShowElevation] = useState(!isDeviceAndroid())

  const animateOpen = Animated.timing(opacity, {
    useNativeDriver: true,
    toValue: 1,
    duration: animationDuration,
  })

  const animateClosed = Animated.timing(opacity, {
    useNativeDriver: true,
    toValue: 0,
    duration: animationDuration,
  })
  const debugOverlays = {}
  for (const thisId in overlays) {
    debugOverlays[thisId] = {
      index: overlays[thisId].index,
      overlayProps: {
        visible: overlays[thisId].overlayProps.visible,
        testID: overlays[thisId].overlayProps.testID,
      },
    }
  }
  const previousvisible = usePrevious<boolean>(overlayProps.visible)
  const previousHasInitiatedAnimatedClose = usePrevious<boolean>(hasInitiatedAnimatedClose)
  useEffect(() => {
    if (overlayProps.visible && !previousvisible) {
      animateOpen.start(() => {
        setShowElevation(true)
      })
    } else if (hasInitiatedAnimatedClose && !previousHasInitiatedAnimatedClose) {
      // more hax
      if (isDeviceAndroid()) {
        setShowElevation(false)
      }
      animateClosed.start(() => {
        onCloseAnimationComplete?.()
        completedHideOverlay({
          overlayProps,
        })
      })
    }
  }, [
    animateOpen,
    animateClosed,
    overlayProps.testID,
    overlayProps.visible,
    onCloseAnimationComplete,
    previousvisible,
    hasInitiatedAnimatedClose,
    previousHasInitiatedAnimatedClose,
    completedHideOverlay,
    overlayProps,
  ])

  const overlayTileElevation = 2

  if (overlayProps.visible) {
    return (
      <Animated.View style={[StyleSheet.absoluteFill, {opacity}]}>
        <Box
          background={modalBackgroundColor}
          fill
          align="center"
          padding="medium"
          testID={overlayProps.testID}
        >
          <Box fill justify="center" boxStyle={{maxWidth: overlayMaxWidth}}>
            <Box
              background={'white'}
              radius={'small'}
              elevation={showElevation ? overlayTileElevation : 0}
              fill={'horizontal'}
            >
              {overlayProps.children}
            </Box>
          </Box>
        </Box>
      </Animated.View>
    )
  }
  return null
}

export default OverlayModal
