import {difference} from 'lodash'
import {useCallback, useMemo} from 'react'

import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {
  AllSupportedFrontEndPrereqs,
  OneTimeOnlyFrontEndPreReqs,
  WorkflowDescriptor,
} from 'src/workflows/constants'
import {wfWarn} from 'src/workflows/logging'
import {
  SetWorkflowStateAction,
  workflowsMetOneTimeFrontEndPreReqsSelector,
  workflowsSelectedOfferSelector,
} from 'src/workflows/slice'
import {FrontEndPreReqType, WorkflowsStackParams} from 'src/workflows/types'

type UseFrontEndPreReqResultType = {
  /**
   * All pre-requisites associated with the current route.
   */
  preReqs: FrontEndPreReqType[]

  /**
   * All pre-requisites that have been met on the current route.
   */
  metPreReqs: FrontEndPreReqType[]

  /**
   * All pre-requisites that have not been met on the current route.
   */
  unmetPreReqs: FrontEndPreReqType[]

  /**
   * A function to be called when pre-requisites are fulfilled.
   * @returns The pre-requisites that were fulfilled.
   */
  onPreReqsFulfilled: () => Promise<FrontEndPreReqType[]>
}

/**
 * A hook to retrieve and interact with front-end pre-requisites.
 * This only gets the met and unmet preReqs for the current route
 * @param route The route name of which to retrieve pre-reqs.
 * @param routeMap Should only be used for testing.
 * @returns
 */
export const useFrontEndPreReqs = (
  route: keyof WorkflowsStackParams | undefined,
): UseFrontEndPreReqResultType => {
  const metOneTimeFrontEndPreReqs = usePfSelector(workflowsMetOneTimeFrontEndPreReqsSelector)
  const selectedOfferMiscProp = usePfSelector(workflowsSelectedOfferSelector)
  const dispatch = usePfDispatch()

  const associatedPreReqs: FrontEndPreReqType[] = useMemo(() => {
    const preReqs: FrontEndPreReqType[] = []
    for (const preReq of AllSupportedFrontEndPrereqs) {
      const descriptor = WorkflowDescriptor[preReq]
      if (
        (descriptor?.isFrontEndPreReq && descriptor.screen === route) ||
        (route && descriptor?.fulfilledByScreens?.includes(route))
      ) {
        preReqs.push(preReq)
      }
    }
    return preReqs
  }, [route])

  const metPreReqs = useMemo(() => {
    const preReqs: FrontEndPreReqType[] = []

    if (selectedOfferMiscProp?.metFrontEndPreReqs) {
      for (const preReq of selectedOfferMiscProp.metFrontEndPreReqs) {
        if (associatedPreReqs.includes(preReq)) {
          preReqs.push(preReq)
        }
      }
    }

    for (const preReq of metOneTimeFrontEndPreReqs) {
      if (associatedPreReqs.includes(preReq)) {
        preReqs.push(preReq)
      }
    }

    return preReqs
  }, [associatedPreReqs, metOneTimeFrontEndPreReqs, selectedOfferMiscProp])

  const unmetPreReqs = useMemo(() => {
    return difference(associatedPreReqs, metPreReqs)
  }, [associatedPreReqs, metPreReqs])

  const handlePreReqsFulfilled = useCallback(async (): Promise<FrontEndPreReqType[]> => {
    const oneTimePreReqs: FrontEndPreReqType[] = []
    const nonOneTimePreReqs: FrontEndPreReqType[] = []

    for (const preReq of associatedPreReqs) {
      if (OneTimeOnlyFrontEndPreReqs.includes(preReq)) {
        oneTimePreReqs.push(preReq)
      } else {
        nonOneTimePreReqs.push(preReq)
      }
    }

    if (oneTimePreReqs.length === 0 && nonOneTimePreReqs.length === 0) {
      return []
    }

    try {
      await dispatch(
        SetWorkflowStateAction({
          metOneTimeFrontEndPreReqs: [...metOneTimeFrontEndPreReqs, ...oneTimePreReqs],
          selectedOffer: selectedOfferMiscProp
            ? {
                ...selectedOfferMiscProp,
                metFrontEndPreReqs: [
                  ...(selectedOfferMiscProp.metFrontEndPreReqs ?? []),
                  ...nonOneTimePreReqs,
                ],
              }
            : undefined,
        }),
      )

      return [...oneTimePreReqs, ...nonOneTimePreReqs]
    } catch (e) {
      wfWarn('SetWorkflowStateAction failed!', e)
      throw e
    }
  }, [associatedPreReqs, dispatch, metOneTimeFrontEndPreReqs, selectedOfferMiscProp])

  return {
    preReqs: associatedPreReqs,
    metPreReqs,
    unmetPreReqs,
    onPreReqsFulfilled: handlePreReqsFulfilled,
  }
}
