import React from 'react'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

type Fn = () => void

type Props = {
  showModal: boolean
  hideModal: Fn
  text: string
}

const CancelMembershipModal = (props: Props): JSX.Element => {
  const {hideModal, showModal, text} = props

  const handlePrimaryAction: Fn = () => hideModal()
  const handleSecondaryAction: Fn = () => hideModal()
  const okayButtonText = 'Continue to Cancel'
  const dismissButtonText = 'Keep Possible Membership'

  return (
    <OverlaySimple
      title={'Are you Sure?'}
      testID="Cancel-Membership-Modal"
      visible={showModal}
      onOkay={handlePrimaryAction}
      onDismiss={handleSecondaryAction}
      okayButtonText={okayButtonText}
      dismissButtonText={dismissButtonText}
      text={text}
    />
  )
}

export {CancelMembershipModal}
