import React, {FC, ReactNode, useRef} from 'react'
import {View, TouchableOpacity, ViewStyle, StyleSheet, Animated, Easing} from 'react-native'
import Collapsible from 'react-native-collapsible'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type CollapsibleCardProps = React.PropsWithChildren & {
  openTitle: string
  hideTitle: string
  open: boolean
  onChangeOpen?: (value) => void
  content: ReactNode
  contentStyle?: ViewStyle
}

const CollapsibleCard: FC<CollapsibleCardProps> = (props) => {
  const {open: isOpen, onChangeOpen, children, openTitle, hideTitle, content, contentStyle} = props
  const animatedRotation = useRef(new Animated.Value(0)).current
  const animatedDeg = animatedRotation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  })

  const handleOnPress = (): void => {
    Animated.timing(animatedRotation, {
      duration: 300,
      easing: Easing.inOut(Easing.ease),
      toValue: isOpen ? 0 : 1,
      useNativeDriver: true,
    }).start()

    onChangeOpen?.(!isOpen)
  }

  return (
    <BasicTile>
      {children}

      <TouchableOpacity onPress={handleOnPress}>
        <Box direction={'row'} align={'center'} padding={'small'}>
          <View style={styles.titleContainer}>
            <PFText variant={'p_semibold'}>{isOpen ? hideTitle : openTitle}</PFText>
          </View>
          <Animated.View style={{transform: [{rotate: animatedDeg}]}}>
            <SvgIcon name={'chevronDown'} colorVariant={'active'} />
          </Animated.View>
        </Box>
      </TouchableOpacity>

      <Collapsible collapsed={!isOpen} easing={'easeInOut'}>
        <Box padding={'small'} boxStyle={contentStyle}>
          {content}
        </Box>
      </Collapsible>
    </BasicTile>
  )
}

export default CollapsibleCard

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
  },
})
