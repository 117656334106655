import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type ConfirmDebitCardQueryVariables = Types.Exact<{[key: string]: never}>

export type ConfirmDebitCardQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        preferredFundingSource?: boolean | null
      }> | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {__typename: 'AchPaymentMethod'}
        | {__typename: 'CheckPaymentMethod'}
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            expDate?: string | null
            card: {
              __typename?: 'LinkedDebitCard'
              mask?: string | null
              institution?: {__typename?: 'Institution'; name: string} | null
              account?: {__typename?: 'LinkedAccount'; id: string} | null
            }
          }
      > | null
    }
  }
}

export type ConfirmDebitCardSaveMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
}>

export type ConfirmDebitCardSaveMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export type LoanSetDisbursementMethodConfirmDebitCardMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
  paymentInstrumentId: Types.Scalars['UUID']['input']
}>

export type LoanSetDisbursementMethodConfirmDebitCardMutation = {
  __typename?: 'Mutation'
  loanSetDisbursementMethod: {__typename?: 'SetLoanDisbursementMethodResponse'; success: boolean}
}

export const ConfirmDebitCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ConfirmDebitCard'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'account'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'expDate'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmDebitCardQuery, ConfirmDebitCardQueryVariables>
export const ConfirmDebitCardSaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'ConfirmDebitCardSave'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'confirmDebitCard'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmDebitCardSaveMutation, ConfirmDebitCardSaveMutationVariables>
export const LoanSetDisbursementMethodConfirmDebitCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'loanSetDisbursementMethodConfirmDebitCard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentInstrumentId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDisbursementMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'disbursementMethod'},
                      value: {kind: 'EnumValue', value: 'DEBIT_CARD'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'paymentInstrumentId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanSetDisbursementMethodConfirmDebitCardMutation,
  LoanSetDisbursementMethodConfirmDebitCardMutationVariables
>
