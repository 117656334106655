import React, {useMemo, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import {shuffleArray} from 'src/products/general/components/templates/SurveyTemplate/SurveyTemplate.utils'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ButtonLockupPropsSecondary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {
  RadioButtonList,
  RadioButtonListOption,
} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import Page from 'src/designSystem/components/organisms/Page/Page'

type Props = {
  title: string
  text?: string
  subText?: string
  radioButtonExtraInputLabel?: string
  showDontRecall?: boolean
  /**
   * This must be a stable/memoized prop, or it will reshuffle each render
   */
  options: string[]
  secondaryAction?: ButtonLockupPropsSecondary
  onContinue: (value: string, other?: string) => Promise<void> | void
  onSelectedOption?: (value: string) => Promise<void> | void
  isContinueLoading: boolean
  testID?: string
  radioButtonExtraInputTestID?: string
}

export const SurveyTemplate: React.FC<Props> = (props) => {
  const {
    title,
    text,
    subText,
    radioButtonExtraInputLabel,
    showDontRecall,
    options,
    secondaryAction,
    onContinue,
    isContinueLoading,
    onSelectedOption,
    testID,
    radioButtonExtraInputTestID,
  } = props

  const {t} = useTranslation(['SurveyTemplate', 'Common'])
  const [otherValue, setOtherValue] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const [selectedValue, setSelectedValue] = useState('')

  const inputLabel = t('Other')

  const list = useMemo(() => {
    const shuffledOptions = options.slice()
    shuffleArray(shuffledOptions)
    const list: ({text: string} & RadioButtonListOption)[] = shuffledOptions.map(
      (option, index) => {
        return {
          id: `${option}-${index}`,
          testID: `${testID ? testID : ''}_${option.replace(/\s/g, '_')}`,
          text: option,
        }
      },
    )

    return list
  }, [options, testID])

  const modifiedList = [...list]
  if (showDontRecall) {
    modifiedList.push({
      id: 'option-dont-recall',
      testID: `dontRecall`,
      text: t('DontRecall'),
    })
  }

  if (radioButtonExtraInputLabel) {
    modifiedList.push({
      id: `${inputLabel}-${options.length}`,
      testID: `Survey_${inputLabel.replace(/\s/g, '_')}`,
      text: inputLabel,
      radioButtonExtraInput: {
        label: radioButtonExtraInputLabel ?? '',
        onChange: setOtherValue,
        onSubmitEditing: (): void => {
          void handleOnPressContinue()
        },
      },
    })
  }

  const onSelect = (id: string): void => {
    const selectedValue = modifiedList.find((option) => option.id === id)?.text ?? ''
    void onSelectedOption?.(selectedValue)

    setSelectedId(id)
    setSelectedValue(selectedValue)
    setOtherValue('')
  }

  const isDisabled =
    selectedId === '' ||
    isContinueLoading ||
    (selectedValue === inputLabel && otherValue.length === 0)

  const handleOnPressContinue = async (): Promise<void> => {
    if (isDisabled) return
    await onContinue(selectedValue, otherValue)
  }

  const primaryAction = {
    text: t('Common:Continue'),
    disabled: isDisabled,
    onPress: handleOnPressContinue,
    testID: 'SurveyConfirmationButton',
    loading: isContinueLoading,
  }

  return (
    <Page
      buttonProps={buttonLockupProperties(primaryAction, secondaryAction)}
      variant={'generic'}
      title={title}
      smallTopGap={true}
    >
      {text ? (
        <View style={styles.titleView}>
          <PFText variant={'p'}>{text}</PFText>
        </View>
      ) : null}
      {subText ? (
        <View style={styles.titleView}>
          <PFText variant={'p_semibold'}>{subText}</PFText>
        </View>
      ) : null}
      <View style={styles.listView}>
        <RadioButtonList
          options={modifiedList}
          onPress={(value): void => onSelect(value)}
          selectedOption={selectedId}
          extraInputValue={otherValue}
          isBusy={isContinueLoading}
          radioButtonExtraInputTestID={radioButtonExtraInputTestID}
        />
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  listView: {
    paddingBottom: smallGap,
  },
  titleView: {
    marginBottom: smallGap,
  },
})
