import {useMutationFactory} from '@possible/cassandra/src/utils'

import {BankAddAchPaymentMethodDocument} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/BankAddAchPaymentMethod.gqls'

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useBankAddAchPaymentMethod = useMutationFactory(BankAddAchPaymentMethodDocument)

/**
 * The AchPaymentMethod from the result of useBankAddAchPaymentMethod()
 */
export type UseBankAddAchPaymentMethodResultPaymentMethod = NonNullable<
  NonNullable<
    Awaited<ReturnType<ReturnType<typeof useBankAddAchPaymentMethod>[0]>>['data']
  >['bankAddAchPaymentMethod']['paymentMethod']
> & {
  __typename: 'AchPaymentMethod'
}
