import {useCallback, useState} from 'react'

import {RealtimeDbSubscriptionManager} from 'src/lib/RealtimeDatabase/SubscriptionManager'
import {RealtimeDbProperties} from 'src/lib/RealtimeDatabase/RealtimeDb.types'
import Log from 'src/lib/loggingUtil'

const subscriptionManager = new RealtimeDbSubscriptionManager()

export const init = subscriptionManager.init

export const unsubscribeWhenAppIsInBackground = subscriptionManager.unsubscribeWhenAppIsInBackground

/**
 * Subscribe to the maintenance mode realtime db property.
 */
export const useMaintenanceMode = (): boolean => {
  const [isMaintenanceMode, setMaintenanceMode] = useState(false)
  const handleOnValueChange = useCallback((value: unknown) => {
    setMaintenanceMode(value === 'yes')
  }, [])
  subscriptionManager.useSubscribeToRealtimeDbProperty(
    RealtimeDbProperties.MaintenanceMode,
    handleOnValueChange,
  )
  return isMaintenanceMode
}

/**
 * Minimum binary version for android and ios that the app should be updated to.
 * Each value is a semver string ex: "2.7.4"
 */
export type MinimumBinaryVersion = {
  android: string
  ios: string
}

/**
 * Subscribe to the minimum binary version realtime db property. Contains min
 * version for android and ios.
 */
export const useMinimumBinaryVersion = (): MinimumBinaryVersion | undefined => {
  const [minBinaryVersion, setMinBinaryVersion] = useState<MinimumBinaryVersion | undefined>(
    undefined,
  )
  const handleOnValueChange = useCallback((value: unknown) => {
    if (
      value &&
      typeof value === 'object' &&
      'ios' in value &&
      typeof value.ios === 'string' &&
      'android' in value &&
      typeof value.android === 'string'
    ) {
      setMinBinaryVersion({
        ios: value.ios,
        android: value.android,
      })
    } else {
      Log.error(
        `useMinimumBinaryVersion minimum binary version value is not a string, typeof=${typeof value}`,
      )
    }
  }, [])
  subscriptionManager.useSubscribeToRealtimeDbProperty(
    RealtimeDbProperties.MinimumBinaryVersion,
    handleOnValueChange,
  )
  return minBinaryVersion
}
