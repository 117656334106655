import {
  CardAccountApplicationRejectDocument,
  CardAccountApplicationResumeDocument,
  CardAccountApplicationSnoozeDocument,
  UserQueryDocument,
} from '../types/iam'
import {useLazyQueryFactory, useMutationFactory} from '../utils'

// User
export const useUserLazyQuery = useLazyQueryFactory(UserQueryDocument)

// Cards
export const useCardAccountApplicationResumeMutation = useMutationFactory(
  CardAccountApplicationResumeDocument,
)
export const useCardAccountApplicationSnoozeMutation = useMutationFactory(
  CardAccountApplicationSnoozeDocument,
)
export const useCardAccountApplicationRejectMutation = useMutationFactory(
  CardAccountApplicationRejectDocument,
)
