import React from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type PropTypes = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  bodyText?: string
  linkText?: string
  center?: boolean
}

const goToPage = (navigation, page): void => {
  navigation?.navigate(page)
}

const BankSupportDisclaimerInline = (props: PropTypes): JSX.Element => {
  const {navigation, bodyText, linkText, center: shouldBeCentered} = props
  const {t} = useTranslation(['AddBankLink', 'Common'])

  const selectedBodyText = bodyText ?? t('IsYourBankSupported?')
  const selectedLinkText = linkText ?? t('Common:CheckNowCamelCase')
  const viewStyles = [styles.box, shouldBeCentered ? styles.justifyCenter : {}]

  return (
    <View style={viewStyles} testID="BankSupportDisclaimerInline">
      <PFText variant={'p'} textAlign={'center'}>
        {selectedBodyText}
        {'  '}
      </PFText>
      <SvgLink
        linkType={'inline'}
        onPress={(): void => goToPage(navigation, 'UnsupportedBanks')}
        linkText={selectedLinkText}
        linkIcon={'internal'}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    alignItems: 'center',
    flex: 0,
    flexDirection: 'row',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
})

export {BankSupportDisclaimerInline}
