import React, {FC, useMemo, useCallback, ReactNode} from 'react'
import {View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {separatorColor as defaultSeparatorColor} from 'src/designSystem/semanticColors'
import {Color, SizeVariants} from 'src/designSystem/types'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'

export type SeparatorModes = 'none' | 'after-all' | 'between'

export type GenericListProps = {
  items: ReactNode[]
  separatorMode?: SeparatorModes
  separatorColor?: Color
  boxGap?: SizeVariants
}

const GenericList: FC<GenericListProps> = (props) => {
  const {
    items,
    separatorMode = 'none',
    separatorColor = defaultSeparatorColor,
    boxGap = 'little',
  } = props

  const separator: (key: string) => ReactNode = useCallback(
    (key: string) => <Separator variant={'solid'} color={separatorColor} key={key} />,
    [separatorColor],
  )

  const listItems = useMemo(() => {
    const wrapItemWithKey = (childrenElements: ReactNode[]): ReactNode[] => {
      return childrenElements.map((item, index) => {
        return <View key={index}>{item}</View>
      })
    }

    const content: ReactNode[] = []
    if (separatorMode === 'none') {
      return wrapItemWithKey(items)
    }
    const lastItemIndex = items.length - 1
    items.forEach((item, index) => {
      content.push(item)
      if (index !== lastItemIndex || separatorMode === 'after-all') {
        content.push(separator(`separator${index}`))
      }
    })
    return wrapItemWithKey(content)
  }, [items, separatorMode, separator])

  return <Box gap={boxGap}>{listItems}</Box>
}

export default GenericList
