import React, {FC} from 'react'
import {View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {formatMask} from 'src/lib/user/utils'
import {cardActionRowBorderColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import {Color} from 'src/designSystem/types'

type PaymentMethodAccountTypes = {
  bankName: string
  accountType: string
  accountMask: string
  onPress?: () => void
  hideBorder?: boolean
  variant?: 'regular' | 'small'
  color?: Color
}

const PaymentMethodAccount: FC<PaymentMethodAccountTypes> = (props) => {
  const {bankName, accountType, accountMask, hideBorder, variant = 'regular', color} = props

  const itemBorder = hideBorder ? 0 : 1
  const titleVariant = variant === 'regular' ? 'p_lg_semibold' : 'p'
  const descVariant = variant === 'regular' ? 'p' : 'p_sm'

  return (
    <View
      style={{
        borderTopWidth: itemBorder,
        borderBottomWidth: itemBorder,
        borderColor: cardActionRowBorderColor,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: hideBorder ? 0 : smallGap,
      }}
    >
      <Box boxStyle={{flex: 1}}>
        <Box gap={'tiny'}>
          <PFText variant={titleVariant} color={color}>
            {bankName}
          </PFText>
          <PFText variant={descVariant} color={color} testID={'PaymentMethodAccount-mask'}>
            {accountType} {formatMask(accountMask, false)}
          </PFText>
        </Box>
      </Box>
    </View>
  )
}
export default PaymentMethodAccount
