import React, {PropsWithChildren, createContext, useCallback, useMemo, useRef} from 'react'

export type ProductHubTileContextState = {
  isFirstTile: (key: string) => boolean
  unsetTile: (key: string) => void
}

export const ProductHubTileContext = createContext<ProductHubTileContextState>({
  isFirstTile: (): boolean => true,
  unsetTile: () => undefined,
})

export const ProductHubTileProvider: React.FC<PropsWithChildren> = ({children}) => {
  const tileKeys = useRef({})

  const isFirstTile = useCallback(
    (key: string): boolean => {
      tileKeys.current[key] = 1
      return Object.keys(tileKeys.current)?.length > 0 && Object.keys(tileKeys.current)[0] === key
    },
    [tileKeys],
  )

  const unsetTile = useCallback(
    (key: string): void => {
      delete tileKeys.current[key]
    },
    [tileKeys],
  )

  const productHubTileContext = useMemo(
    () => ({
      isFirstTile,
      unsetTile,
    }),
    [isFirstTile, unsetTile],
  )

  return (
    <ProductHubTileContext.Provider value={productHubTileContext}>
      {children}
    </ProductHubTileContext.Provider>
  )
}
