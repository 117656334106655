import React, {FC, useMemo} from 'react'
import {TouchableOpacity} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIconNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {NamedColors} from 'src/designSystem/colors'

export type TapTileProps = {
  title: string
  description: string
  icon?: SvgIconNames
  onPress: () => void
  isDisabled?: boolean
  isSelected?: boolean
  isNotSelected?: boolean
  showForwardArrow?: boolean
  testId: string
}

const TapTile: FC<TapTileProps> = (props) => {
  const {
    title,
    description,
    icon,
    onPress: handleOnPress,
    isDisabled,
    isSelected,
    isNotSelected,
    showForwardArrow,
    testId,
  } = props

  const tapTileBorderTypeAndColor = useMemo(() => {
    if (isDisabled) {
      return {
        width: 0,
        color: NamedColors.BLACK,
      }
    }

    if (isSelected) {
      return {
        width: 2,
        color: NamedColors.SKY,
      }
    }

    if (isNotSelected) {
      return {
        width: 1,
        color: NamedColors.SILVER,
      }
    }

    return {
      width: 1,
      color: NamedColors.BLACK,
    }
  }, [isDisabled, isNotSelected, isSelected])

  const tapTileBackgroundColor = useMemo(() => {
    if (isDisabled) {
      return NamedColors.ASH
    }

    if (isSelected) {
      return NamedColors.PRODUCT_BLUE
    }

    return undefined
  }, [isDisabled, isSelected])

  const tapTileIconColor = useMemo(() => {
    if (isDisabled || isNotSelected) {
      return 'inactive'
    }

    if (isSelected) {
      return 'white'
    }

    return 'active'
  }, [isDisabled, isNotSelected, isSelected])

  const tapTileTitleColor = useMemo(() => {
    if (isDisabled || isNotSelected) {
      return NamedColors.SILVER
    }

    if (isSelected) {
      return NamedColors.MINT
    }

    return NamedColors.BLACK
  }, [isDisabled, isNotSelected, isSelected])

  const tapTileForwardArrowColor = useMemo(() => {
    if (isDisabled) {
      return 'inactive'
    }

    if (isSelected) {
      return 'white'
    }

    return 'default'
  }, [isDisabled, isSelected])

  return (
    <TouchableOpacity disabled={isDisabled} onPress={handleOnPress} testID={testId}>
      <Box
        flex
        direction={'row'}
        align={'center'}
        padding={'small'}
        border={tapTileBorderTypeAndColor}
        radius={'small'}
        background={tapTileBackgroundColor}
        justify="start"
        testID="Tap-Tile-Body"
      >
        {icon ? (
          <Box marginRight={'small'} testID="Tap-Tile-Icon">
            <SvgIcon name={icon} colorVariant={tapTileIconColor} size={'medium'} />
          </Box>
        ) : null}
        <Box width={'75%'}>
          <PFText variant={'p_semibold'} color={tapTileTitleColor} testID="Tap-Tile-Title">
            {title}
          </PFText>
          <PFText
            variant={'p_sm'}
            color={isSelected ? NamedColors.WHITE : NamedColors.SILVER}
            testID="Tap-Tile-Description"
          >
            {description}
          </PFText>
        </Box>
        {showForwardArrow ? (
          <Box align="end" padding={16} testID="Tap-Tile-Forward-Arrow">
            <SvgIcon
              name={'chevronRight'}
              colorVariant={tapTileForwardArrowColor}
              size={'medium'}
            />
          </Box>
        ) : null}
      </Box>
    </TouchableOpacity>
  )
}
export {TapTile}
