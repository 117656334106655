import {CardApplicationGroupMiscPropPayload} from 'src/api/lib/UserProperties/UserProperties.types'
import {SetAmplitudeUserProperties} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'

/**
 * If the given user is a cards user,
 * get the card cohort and upload this data to Amplitude
 */
export const updateAnalyticsWithCohortData = (
  cardUserProperties: CardApplicationGroupMiscPropPayload,
): void => {
  try {
    const hasPossibleCard = cardUserProperties?.possibleCard
    const cohortPhase = cardUserProperties?.cohortPhase ?? ''
    const cohortUserType = cardUserProperties?.cohortUserType ?? ''

    // only send cohort data for cards users
    if (hasPossibleCard) {
      // Update Amplitude
      SetAmplitudeUserProperties('cohortPhase', cohortPhase)
      SetAmplitudeUserProperties('cohortUserType', cohortUserType)
    }
  } catch (e) {
    Log.error(e, 'Error sending user cohort properties to analytics')
  }
}
