import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {contentMaxWidth, largeGap, mediumGap, tinyGap} from 'src/designSystem/layout'
import {ProgressTracker} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTracker'
import {useMainNavigator} from 'src/nav/AppNavHelper'

const Header: React.FC = () => {
  const {t} = useTranslation('Advance')
  return (
    <View style={styles.headerContainer}>
      <SafeAreaView edges={['top', 'right', 'left']} style={styles.headerSafeAreaViewTop}>
        <Box paddingTop={mediumGap}></Box>
        <Box
          alignSelf="start"
          paddingHorizontal={mediumGap}
          width={'100%'}
          paddingTop={largeGap}
          paddingBottom={tinyGap}
        >
          <Box gap={'tiny'} marginBottom={'small'}>
            <PFText
              testID="Registration-Page-Title"
              variant={'d3'}
              color={NamedColors.WHITE}
              textAlign="left"
            >
              {t('AdvanceIsHere')}
            </PFText>
            <PFText variant={'p'} color={NamedColors.WHITE}>
              <Trans
                t={t}
                i18nKey={'AdvanceIsHereSubtitle'}
                components={{
                  lime: <PFText variant={'p'} color={NamedColors.MINT} />,
                }}
              />
            </PFText>
          </Box>
        </Box>
      </SafeAreaView>
    </View>
  )
}

export const AdvanceSplashScreen: React.FC = () => {
  const navigation = useMainNavigator()
  const {t} = useTranslation(['Advance', 'Common'])

  const steps = [
    {
      key: 'SelectAmount',
      isComplete: true,
      label: '1',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'SelectAmount'}>
            Verify the phone number
            <PFText variant={'p_sm'}>{` where we can reach you`}</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'LinkAccount',
      isComplete: true,
      label: '2',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'SelectAmount'}>
            Link the bank account
            <PFText variant={'p_sm'}>{` where you get paid`}</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'GetApproved',
      isComplete: true,
      label: '3',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'SelectAmount'}>
            Get approved and get access to paycheck advances for a
            <PFText
              variant={'p_sm'}
              color={NamedColors.PRODUCT_BLUE}
            >{` flat $15 monthly membership`}</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'FreeTransfers',
      isComplete: true,
      label: '💵',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'SelectAmount'}>
            Get approved and get access to paycheck advances for a
            <PFText
              variant={'p_sm'}
              color={NamedColors.PRODUCT_BLUE}
            >{` flat $15 monthly membership`}</PFText>
          </Trans>
        </PFText>
      ),
    },
  ]

  return (
    <View style={{width: '100%', height: '100%'}}>
      <Header />
      <View style={styles.bodyView}>
        <View style={{flex: 1, margin: 32}}>
          <ProgressTracker steps={steps} />
        </View>
        <View style={styles.button}>
          <Button
            mode="primary"
            size="large"
            width="100%"
            onPress={() => {
              navigation.navigate('SignupWorkflow', {screen: 'Loading'})
            }}
          >
            Continue
          </Button>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
  },
  headerSafeAreaViewTop: {
    backgroundColor: NamedColors.BLACK,
    width: '100%',
    maxWidth: contentMaxWidth,
  },
  button: {
    width: '100%',
    alignItems: 'center',
    marginVertical: 28,
    paddingHorizontal: 16,
    maxWidth: 450,
  },
  bodyView: {
    width: '100%',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
})

//
