import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PaymentStatusCode} from 'src/cassandra'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export const PaymentStatus: React.FC<{status?: PaymentStatusCode | null}> = ({status}) => {
  const {t} = useTranslation('CardDashboardPayment')

  // For each payment status, get the translated, user-facing text
  const userTextTranslations = useMemo(
    () => ({
      [PaymentStatusCode.Scheduled]: '',
      [PaymentStatusCode.Posted]: t('processing'),
      [PaymentStatusCode.Succeeded]: t('paid'),
      [PaymentStatusCode.Failed]: t('failed'),
      [PaymentStatusCode.Cancelled]: t('cancelled'),
      [PaymentStatusCode.Suspended]: t('processing'),
      [PaymentStatusCode.Replaced]: t('cancelled'),
      [PaymentStatusCode.Reversed]: t('cancelled'),
    }),
    [t],
  )

  if (!status) {
    return null
  }

  const userText = userTextTranslations[status]
  const textColor = status == PaymentStatusCode.Failed ? 'error' : 'textDisabled'

  return userText ? (
    <PFText variant="p" textProps={{style: {textTransform: 'uppercase'}}} color={textColor}>
      {userText}
    </PFText>
  ) : null
}
