export const maskPrefix = `\u2022\u2006\u2022\u2006\u2022\u2006 - \u2022\u2006\u2022\u2006 - `
export const maskSsn = (ssn: string): string => {
  if (ssn?.length > 4) {
    return `${maskPrefix}${ssn.slice(-4)}`
  } else {
    return ''
  }
}

export const prefixSsn = (ssnMask: string): string => {
  return ssnMask ? `${maskPrefix}${ssnMask}` : ''
}
