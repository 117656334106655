import {StyleSheet, Platform} from 'react-native'

import {black} from 'src/designSystem/colors'
import {inputFontSizeActive, inputLineHeightActive} from 'src/designSystem/typography'
import {convertHexToRgbA} from 'src/designSystem/lib/colorUtil'
import {textFieldActiveColor} from 'src/designSystem/semanticColors'
import {regularStyle} from 'src/designSystem/fonts'
import {smallGap} from 'src/designSystem/layout'
import {isDeviceAndroid, isDeviceWeb} from 'src/lib/utils/platform'

const MIN_INPUT_HEIGHT = 60
const inputInternalGap = 4
export const inputGap = 8

export default StyleSheet.create({
  overrideView: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
})

export const InputFieldStyle = (hasIcon: boolean, multiline: boolean) => ({
  color: black,
  ...regularStyle,
  fontSize: inputFontSizeActive,
  lineHeight: inputLineHeightActive,
  borderWidth: 0,
  margin: 0,
  minHeight: MIN_INPUT_HEIGHT,
  paddingLeft: smallGap,
  paddingRight: hasIcon ? 0 : smallGap,
  paddingTop:
    !isDeviceWeb() && !multiline ? inputLineHeightActive : inputLineHeightActive + inputInternalGap,
  paddingBottom: inputGap,
  //marginBottom: inputInternalGap, //we are using padding and margin at bottom to avoid the text cutoff on iOS when we specify lineHeight (https://github.com/facebook/react-native/issues/7687)
  textAlignVertical: 'center',
  ...Platform.select({
    web: {
      outline: 'none',
      resize: 'vertical',
    },
  }),
})

export const selectionColor = isDeviceAndroid()
  ? convertHexToRgbA(textFieldActiveColor, 0.2)
  : textFieldActiveColor
