import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardPayOverTimeDetailsMutationMutationVariables = Types.Exact<{
  cardAccountId: Types.Scalars['ID']['input']
  cardPaymentMethodId: Types.Scalars['ID']['input']
}>

export type CardPayOverTimeDetailsMutationMutation = {
  __typename?: 'Mutation'
  cardAccountEnableAutomaticPayments?: Array<{
    __typename?: 'AutomaticPaymentSchedule'
    id?: string | null
    paymentDate: string
  }> | null
}

export const CardPayOverTimeDetailsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CardPayOverTimeDetailsMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardPaymentMethodId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountEnableAutomaticPayments'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardPaymentMethodId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardPaymentMethodId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardPayOverTimeDetailsMutationMutation,
  CardPayOverTimeDetailsMutationMutationVariables
>
