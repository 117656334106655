import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  PaymentInfoBox,
  PaymentInfoBoxProps,
} from 'src/products/loans/LoanApprovedActivation/components/PaymentInfoBox/PaymentInfoBox'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {LoanTilaContent} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type PaymentReviewTilaAndLoanAgreementCATemplateProps = {
  isDisabled: boolean
  isLoanAgreementChecked: boolean
  isSubmitting: boolean
  loanTilaContentData: LoanTilaContentData
  onPressAcceptLoan: () => Promise<void>
  onPressLoanAgreement: () => Promise<void>
  onPressLoanAgreementCheckbox: () => void
  paymentInfoBoxProps: PaymentInfoBoxProps
}

const PaymentReviewTilaAndLoanAgreementCATemplate: React.FC<
  PaymentReviewTilaAndLoanAgreementCATemplateProps
> = (props) => {
  const {
    isSubmitting,
    isDisabled,
    isLoanAgreementChecked,
    loanTilaContentData,
    onPressAcceptLoan,
    onPressLoanAgreement: handleOnPressLoanAgreement,
    onPressLoanAgreementCheckbox: handleOnPressLoanAgreementCheckbox,
    paymentInfoBoxProps,
  } = props

  const {t} = useTranslation('LoanApproved')

  const ctaButtonProps: ButtonLockupPropsPrimary = {
    text: t('AcceptSinglePayment'),
    onPress: onPressAcceptLoan,
    disabled: isDisabled,
    loading: isSubmitting,
    testID: 'Accept-Single-Payment-Button',
  }

  return (
    <Page
      variant={'generic'}
      title={t('ReviewPayments')}
      buttonProps={buttonLockupProperties(ctaButtonProps)}
      smallTopGap
    >
      <Box gap={'medium'} paddingBottom={'medium'}>
        <PFInfoCapsule
          svgIcon={{name: 'copy', colorVariant: 'active', isFilled: true}}
          text={t('SinglePaymentInfoCapsule')}
        />
        <PaymentInfoBox {...paymentInfoBoxProps} />
        <Box>
          <PFText variant={'h1'}>{t('TilaDisclosure')}</PFText>
          <LoanTilaContent {...loanTilaContentData} />
        </Box>
        <PFCheckbox
          testID="Tila-Checkbox"
          onPress={handleOnPressLoanAgreementCheckbox}
          checked={isLoanAgreementChecked}
          disabled={isSubmitting}
        >
          <PFText variant={'p'}>
            <Trans i18nKey={'AcceptLoanAgreementText'} t={t}>
              I accept the
              <SvgLink
                onPress={handleOnPressLoanAgreement}
                linkText={'Loan Agreement'}
                linkType={'inline'}
                testID="Loan-Agreement-Link"
              />
            </Trans>
          </PFText>
        </PFCheckbox>
      </Box>
    </Page>
  )
}

export {PaymentReviewTilaAndLoanAgreementCATemplate}
