import React, {useEffect, useState} from 'react'
import {TouchableWithoutFeedback} from 'react-native-gesture-handler'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {isTinyScreen} from 'src/products/MCU/Landing/LandingScreen.utils'
import {LandingScreenContent} from 'src/products/MCU/Landing/LandingScreen.types'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Button from 'src/designSystem/components/atoms/Button/Button'

type LandingScreenTileProps = LandingScreenContent

const LandingScreenTile: React.FC<LandingScreenTileProps> = (props): JSX.Element => {
  const {
    title,
    icon,
    cta,
    bottomSheetTitle,
    bottomSheetDescription,
    bottomSheetCTA,
    tileTestID,
    bottomSheetCTATestID,
    handleBottomSheetCTA,
    handleAnalyticBottomSheetViewed,
  } = props

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState<boolean>(false)

  useEffect(() => {
    if (isBottomSheetVisible) {
      handleAnalyticBottomSheetViewed()
    }
  }, [isBottomSheetVisible, handleAnalyticBottomSheetViewed])

  return (
    <>
      <Box
        background={NamedColors.SLATE}
        direction="column"
        radius={'medium'}
        paddingVertical={!isTinyScreen ? 'small' : 'tiny'}
        paddingHorizontal={'small'}
        marginBottom={'small'}
        shrink={1}
      >
        <TouchableWithoutFeedback
          onPress={(): void => setIsBottomSheetVisible(true)}
          testID={tileTestID}
        >
          <Box>
            <Box marginBottom={!isTinyScreen ? 'small' : 'tiny'} paddingRight={'small'}>
              <PFText variant="p_lg">{title}</PFText>
            </Box>
            <Box direction="row" justify="between" align="center">
              <Box>
                <SvgIcon
                  name={icon.name}
                  colorVariant={icon.colorVariant}
                  size={!isTinyScreen ? 'large' : 'medium'}
                />
              </Box>
              <Box direction="row" align="center" paddingTop={'small'}>
                <Box marginRight={'tiny'}>
                  <PFText variant="p_sm_semibold" color={NamedColors.PRODUCT_BLUE}>
                    {cta}
                  </PFText>
                </Box>
                <Box>
                  <SvgIcon name={'arrowRight'} colorVariant={'active'} />
                </Box>
              </Box>
            </Box>
          </Box>
        </TouchableWithoutFeedback>
      </Box>
      <BottomSheet
        visible={isBottomSheetVisible}
        title={bottomSheetTitle}
        titleAlign="left"
        titleVariant="h3"
        onDismiss={(): void => setIsBottomSheetVisible(false)}
        showDots={false}
        showCloseButton
        fullWidthTitle={false}
        testID={`${tileTestID}-Bottom-Sheet`}
      >
        <Box fill="horizontal">
          {bottomSheetDescription.map((description, index) => (
            <Box key={`description-${index + 1}`} marginBottom={'medium'} marginRight={'small'}>
              <PFText variant="p_lg" textAlign="left">
                {description}
              </PFText>
            </Box>
          ))}
          <Box marginTop="medium">
            <Button
              mode="primary"
              size="large"
              width="100%"
              onPress={(): void => {
                setIsBottomSheetVisible(false)
                handleBottomSheetCTA()
              }}
              testID={bottomSheetCTATestID}
            >
              {bottomSheetCTA}
            </Button>
          </Box>
        </Box>
      </BottomSheet>
    </>
  )
}

export {LandingScreenTile}
