import {
  LinkedAccount,
  BankAccountsDocument,
  LinkedAccountStatusCode,
  LinkedAccountType,
  LinkedAccountOwnershipSummary,
  BankAccountsQuery,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {getFormattedAccountType} from 'src/lib/user/utils'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {
  AccountManagementLinkedAccountBank,
  AccountManagementPaymentMethodAch,
} from 'src/products/MCU/AccountManagementV2/queries/AccountManagementAccounts/useAccountManagementAccountsQuery'

/**
 * A subset of fields on the LinkedAccountOwnershipSummaryValidationErrors type used by AccountManagement.
 */
export type LinkedAccountOwnershipValidationErrorSubset = Pick<
  NonNullable<LinkedAccountOwnershipSummary['validationErrors']>[0],
  'validationType' | 'validationStatus'
>
/**
 * A subset of fields on the LinkedAccountOwnershipSummary type used by AccountManagement.
 * Not all fields are required so this subset lets us only query for what we need.
 */
export type LinkedAccountOwnershipSummarySubset = Pick<
  LinkedAccountOwnershipSummary,
  'ownerType' | 'ownershipStatus'
> & {
  validationErrors?: LinkedAccountOwnershipValidationErrorSubset[] | null
}

export type BankAccountInfo = {
  /**
   * The id of the LinkedAccount for this bank account / payment method. Unless wasAddedWithoutAggregator is false, then it is
   * the PaymentMethod id since it doesn't have a LinkedAccount.
   */
  id: string
  paymentMethodId: string | null
  name: string
  mask: string
  primary: boolean
  isDefaultLoanPaymentMethod: boolean
  type: LinkedAccountType
  isRelinkRequired?: boolean
  isRoutingAndAccountNumbersAvailable?: boolean
  hasDebitCard?: boolean
  debitCardMask?: string
  ownershipSummary?: LinkedAccountOwnershipSummarySubset
  /**
   * If true this is an additional payment method that is not eligible to be a primary account.
   * This is because it was added manually without an aggregator like plaid, so we don't have access to transation history.
   */
  wasAddedWithoutAggregator?: boolean
  isAccountJustAdded?: boolean
}

export const filterLinkedAccountsInUse = <
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'type'>,
>(
  linkedAccounts: LinkedAccountSubset[],
): LinkedAccountSubset[] | undefined | null =>
  linkedAccounts?.filter(
    (account) =>
      account.status === LinkedAccountStatusCode.LinkedInUse &&
      (account.type === LinkedAccountType.Checking || account.type === LinkedAccountType.Savings),
  )

export async function getFilteredLinkedBankAccountsGql(): Promise<
  BankAccountsQuery['me']['bankAccounts']['all']
> {
  let filteredLinkedAccounts: BankAccountsQuery['me']['bankAccounts']['all'] = []
  try {
    const response = await ApplyQuery(BankAccountsDocument, {})
    const linkedAccounts = response.data.me.bankAccounts.all
    if (!linkedAccounts) {
      throw Error('Failed to query bank accounts')
    }
    filteredLinkedAccounts = filterLinkedAccountsInUse(linkedAccounts) ?? []
  } catch (e) {
    // pass
  }
  return filteredLinkedAccounts
}

/**
 * Converts the AccountManagementPaymentMethodAch type to the BankAccountInfo type (used by AccountManagement).
 * @param achPaymentMethod
 * @returns
 */
export const convertAchPaymentMethodToBankAccountInfo = ({
  achPaymentMethod,
  defaultLoanPaymentMethodId,
}: {
  achPaymentMethod: AccountManagementPaymentMethodAch
  defaultLoanPaymentMethodId: string | undefined
}): BankAccountInfo | undefined => {
  if (!achPaymentMethod?.account) {
    // if an AchPaymentMethod does not have a .account LinkedAccount that means it was added
    // manually without an aggregator like Plaid
    return {
      // note: this is not LinkedAccount id since it doesn't have a LinkedAccount
      id: achPaymentMethod.id,
      paymentMethodId: achPaymentMethod.id,
      name: achPaymentMethod.bankAccount.friendlyName,
      mask: achPaymentMethod.bankAccount.mask,
      // if this is a bank added with manually entering routing + account it doesn't have a LinkedAccount and
      // can't be set as primary so this is false
      primary: false,
      isDefaultLoanPaymentMethod: defaultLoanPaymentMethodId === achPaymentMethod.id,
      type: LinkedAccountType.Other,
      isRelinkRequired: false,
      isRoutingAndAccountNumbersAvailable: true,
      hasDebitCard: false,
      ownershipSummary: undefined,
      // this has no LinkedAccount so it was added manually without an aggregator like Plaid
      wasAddedWithoutAggregator: true,
    }
  }

  // this AchPaymentMethod has an .account LinkedAccount so it was added with an aggregator like Plaid
  const bankAccountInfo: BankAccountInfo = {
    id: achPaymentMethod?.account.id,
    paymentMethodId: achPaymentMethod.id,
    name: `${achPaymentMethod?.account.institution?.name ?? ''} ${getFormattedAccountType(
      achPaymentMethod?.account.type,
    )}`,
    mask: achPaymentMethod.bankAccount.mask,
    primary: achPaymentMethod?.account.preferredFundingSource ?? false,
    isDefaultLoanPaymentMethod: defaultLoanPaymentMethodId === achPaymentMethod.id,
    type: achPaymentMethod.account.type,
    isRelinkRequired: achPaymentMethod.account.isLoginRequired,
    isRoutingAndAccountNumbersAvailable: !!achPaymentMethod.account.achNumbersAvailable,
    hasDebitCard: false,
    ownershipSummary: achPaymentMethod.account.ownershipSummary ?? undefined,
    // since this has a LinkedAccount (which means it was synced with an aggregator like Plaid)
    // it is eligible to be a primary account
    wasAddedWithoutAggregator: false,
  }
  return bankAccountInfo
}

/**
 * Converts the AccManLinkedAccount type to the BankAccountInfo type (used by AccountManagement).
 * @param achPaymentMethod
 * @returns
 */
export const convertLinkedAccountToBankAccountInfo = (
  account: AccountManagementLinkedAccountBank,
): BankAccountInfo | undefined => {
  const bankAccountInfo: BankAccountInfo = {
    id: account.id,
    // this is a LinkedAccount not a PaymentMethod so it has no paymentMethodId
    paymentMethodId: null,
    name: `${account.institution?.name ?? ''} ${getFormattedAccountType(account.type)}`,
    mask: account.mask,
    primary: account.preferredFundingSource ?? false,
    // we don't know if this is default or not since that info is on AchPaymentMethod not LinkedAccount
    isDefaultLoanPaymentMethod: false,
    type: account.type,
    isRelinkRequired: account.isLoginRequired,
    isRoutingAndAccountNumbersAvailable: !!account.achNumbersAvailable,
    hasDebitCard: false,
    ownershipSummary: account.ownershipSummary ?? undefined,
    wasAddedWithoutAggregator: false,
  }
  return bankAccountInfo
}
