import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {BankRoutingAccountFormData} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountForm.types'
import {BankRoutingAndAccountFormPageTemplate} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageTemplate'

type ConfirmBankAccountTemplateProps = {
  onSubmit: (data: BankRoutingAccountFormData) => Promise<void> | void
  accountMask: string
  accountDisplayName: string
  isSubmitting: boolean
  onDismissErrorModal: () => void
  errorModalReason: PaymentMethodsAlertModalErrorReason
  isEditable?: boolean
}

const ConfirmBankAccountTitle = ({
  accountMask,
}: {
  accountMask: ConfirmBankAccountTemplateProps['accountMask']
}): JSX.Element => {
  const {t} = useTranslation('ConfirmBankAccount')

  return (
    <PFText>
      <Trans
        i18nKey="VerifyYourBankDetailsEndingIn"
        t={t}
        components={{
          black: <PFText variant="h1" />,
          blue: <PFText variant="h1" color={NamedColors.PRODUCT_BLUE} />,
        }}
        values={{
          accountMask,
        }}
      ></Trans>
    </PFText>
  )
}

/**
 * Template for the screen to ask a user to confirm their bank account routing & account
 * numbers while accepting a loan offer.
 */
const ConfirmBankAccountTemplate: FC<ConfirmBankAccountTemplateProps> = (props) => {
  const {
    onSubmit: handleOnSubmit,
    accountDisplayName,
    accountMask,
    isSubmitting,
    onDismissErrorModal: handleOnDismissErrorModal,
    errorModalReason,
    isEditable,
  } = props
  const {t} = useTranslation('ConfirmBankAccount')
  return (
    <BankRoutingAndAccountFormPageTemplate
      title={<ConfirmBankAccountTitle accountMask={accountMask} />}
      description={
        <PFText variant="p">
          <Trans
            t={t}
            i18nKey="YouCanFindThisInformation"
            values={{accountDisplayName}}
            components={{
              bold: <PFText variant="p_semibold" />,
            }}
          />
        </PFText>
      }
      onSubmit={handleOnSubmit}
      onDismissErrorModal={handleOnDismissErrorModal}
      isSubmitting={isSubmitting}
      accountNumberMask={accountMask}
      errorModalReason={errorModalReason}
      isEditable={isEditable}
    />
  )
}

export {ConfirmBankAccountTemplate, ConfirmBankAccountTitle}
export type {ConfirmBankAccountTemplateProps}
