import * as Types from '../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AccountSelectionAddAchPaymentMethodForLinkedAccountMutationVariables = Types.Exact<{
  input: Types.AddAchPaymentMethodForLinkedAccountInput
}>

export type AccountSelectionAddAchPaymentMethodForLinkedAccountMutation = {
  __typename?: 'Mutation'
  bankAddAchPaymentMethodForLinkedAccount: {
    __typename?: 'AddAchPaymentMethodForLinkedAccountResponse'
    result: Types.AddAchPaymentMethodForLinkedAccountResult
    achPaymentMethod?: {
      __typename?: 'AchPaymentMethod'
      id: string
      status:
        | {__typename?: 'ExpiredPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'InvalidPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'PendingPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'RemovedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'SuspendedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'SystemPurgedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
        | {__typename?: 'VerifiedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
      account?: {__typename?: 'LinkedAccount'; id: string} | null
      bankAccount: {
        __typename?: 'BankAccount'
        friendlyName: string
        mask: string
        institution?: {__typename?: 'Institution'; name: string} | null
      }
    } | null
  }
}

export const AccountSelectionAddAchPaymentMethodForLinkedAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AccountSelectionAddAchPaymentMethodForLinkedAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'AddAchPaymentMethodForLinkedAccountInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAddAchPaymentMethodForLinkedAccount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'achPaymentMethod'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'status'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'account'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'bankAccount'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'friendlyName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'result'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountSelectionAddAchPaymentMethodForLinkedAccountMutation,
  AccountSelectionAddAchPaymentMethodForLinkedAccountMutationVariables
>
