import React from 'react'

import {Color} from 'src/designSystem/types'
import {TextVariants, VariantsStyle} from 'src/designSystem/typography'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  SvgIconColorVariantNames,
  SvgIconSize,
  iconColorOptions,
} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {NamedColors} from 'src/designSystem/colors'

export type SvgLinkIconType = 'internal' | 'external' | 'info'
export type SvgLinkType = 'inline' | 'single'
export type SvgLinkProps = {
  accessibilityLabel?: string
  disabled?: boolean
  linkColor?: SvgIconColorVariantNames
  linkIcon?: SvgLinkIconType
  linkIconFilled?: boolean
  linkIconSize?: SvgIconSize
  linkText: string
  linkType: SvgLinkType
  onPress: () => void | Promise<void>
  testID?: string
  textTransform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize'
  textVariant?: TextVariants
  linkIconPlacement?: 'before' | 'after'
}

export const getSvgLinkTextColor = (linkColor: SvgIconColorVariantNames): Color => {
  return iconColorOptions[linkColor].color
}

export type GetSvgLinkIconProps = Pick<
  SvgLinkProps,
  'disabled' | 'linkColor' | 'linkIcon' | 'linkIconSize' | 'textVariant' | 'linkIconFilled'
> & {
  isDeviceAndroid: boolean
  isDeviceWeb: boolean
  svgLinkType: SvgLinkType
}

export const getSvgLinkIcon = (props: GetSvgLinkIconProps): JSX.Element | undefined => {
  const {
    disabled: isDisabled,
    isDeviceAndroid,
    isDeviceWeb,
    linkColor = 'info',
    linkIcon,
    linkIconFilled: isLinkIconFilled = false,
    linkIconSize,
    svgLinkType,
    textVariant = 'p',
  } = props
  if (!linkIcon) return undefined
  let icon: JSX.Element | undefined = undefined
  const iconVariant = isDisabled ? 'inactive' : linkColor
  if (linkIcon === 'external') {
    icon = (
      <SvgIcon
        name={'externalLink'}
        colorVariant={iconVariant}
        size={linkIconSize}
        isFilled={isLinkIconFilled}
      />
    )
  } else if (linkIcon === 'internal') {
    icon = (
      <SvgIcon
        name={'arrowRight'}
        colorVariant={iconVariant}
        size={linkIconSize}
        isFilled={isLinkIconFilled}
      />
    )
  } else if (linkIcon === 'info') {
    icon = (
      <SvgIcon
        name={'info'}
        colorVariant={iconVariant}
        size={linkIconSize}
        isFilled={isLinkIconFilled}
      />
    )
  } else {
    return undefined
  }

  // A color is needed for this function to return the data for the textVariant lineHeight
  // 24 is the lineHeight for the default textVariant and is needed here to address "if undefined" errors
  const textVariantLineHeight: number =
    VariantsStyle[textVariant](NamedColors.AMBER).lineHeight ?? 24

  // this is a very specific number that works best for the Android platform
  const inlineAndroidHeight = textVariantLineHeight * 0.56

  if (isDeviceWeb) {
    return (
      <div
        // necessary to use inline styles for web
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          display: 'inline-block',
          paddingLeft: 4,
          paddingRight: 4,
          verticalAlign: 'middle',
          paddingTop: svgLinkType === 'inline' ? 6 : 0,
        }}
      >
        {icon}
      </div>
    )
  } else if (isDeviceAndroid) {
    return (
      <Box
        paddingHorizontal={'tiny'}
        paddingTop={svgLinkType === 'single' ? 'tiny' : undefined}
        height={svgLinkType === 'inline' ? inlineAndroidHeight : undefined}
      >
        {icon}
      </Box>
    )
  } else {
    return (
      <Box paddingHorizontal={'tiny'} paddingTop={'tiny'}>
        {icon}
      </Box>
    )
  }
}
