import React, {FC, useContext, useEffect} from 'react'
import {StyleSheet, View, ViewProps} from 'react-native'

import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'

import {headerHeight, pageMarginHorizontal} from 'src/designSystem/layout'
import {isDeviceIOS} from 'src/lib/utils/platform'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {ProductHubTileContext} from 'src/products/general/ProductHub/ProductHubTileProvider'

type ProductHubTileWrapperProps = ViewProps & {
  tileKey: string
}

export const ProductHubTileWrapper: FC<ProductHubTileWrapperProps> = ({
  children,
  tileKey,
  ...viewProps
}) => {
  const {isFirstTile: isFirstTileOnScreen, unsetTile} = useContext(ProductHubTileContext)

  const headerComponentHeight = isDeviceIOS() ? headerHeight - pfHeaderHeight : undefined

  const header = isFirstTileOnScreen(tileKey) ? (
    <HeaderSpacer height={headerComponentHeight} />
  ) : undefined

  useEffect(() => {
    return () => unsetTile(tileKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tileKey])

  return (
    <>
      {header}
      <View style={styles.containerView} {...viewProps}>
        {children}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  containerView: {
    paddingHorizontal: pageMarginHorizontal,
  },
})
