import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCardAdhocPaymentAnalytics} from 'src/products/card/AdhocPayment/useCardAdhocPaymentAnalytics'
import {CardPayOverTimeScheduleTemplate} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleTemplate/CardPayOverTimeScheduleTemplate'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardPayOverTimeScheduleQueryDocument} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleQuery.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type CardPayOverTimeScheduleContainerProps = StackScreenProps<
  MainStackParamList,
  'CardPayOverTimeSchedule'
>

const CardPayOverTimeScheduleContainer = ({
  navigation,
}: CardPayOverTimeScheduleContainerProps): JSX.Element => {
  const {
    data,
    error,
    loading: isLoading,
  } = useCassandraQuery(CardPayOverTimeScheduleQueryDocument, {fetchPolicy: 'cache-first'})
  const {eventArgs} = useCardAdhocPaymentAnalytics()

  usePageViewedAnalytics({
    eventName: CardEvents.card_pot_detail_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnPressPrimary = (): void => {
    TrackAppEvent(CardEvents.card_pot_detail_page_cta, AppEvents.Category.Card, eventArgs)

    navigation.push('CardAdhocPayment')
  }

  return (
    <BaseTemplate isLoading={isLoading} isError={!!error}>
      <CardPayOverTimeScheduleTemplate
        installments={data?.me.cardAccounts.active?.installmentPlans.current?.installments}
        onPressPrimary={handleOnPressPrimary}
        pendingAmount={data?.me.cardAccounts.active?.installmentPlans.current?.pendingAmount || ''}
        remainingAmount={
          data?.me.cardAccounts.active?.installmentPlans.current?.remainingAmount || ''
        }
        totalDue={data?.me.cardAccounts.active?.installmentPlans.current?.totalDue || ''}
        totalOverdueAmount={
          data?.me.cardAccounts.active?.installmentPlans.current?.totalOverdueAmount || ''
        }
        totalPaid={data?.me.cardAccounts.active?.installmentPlans.current?.totalPaid || ''}
      />
    </BaseTemplate>
  )
}

export {CardPayOverTimeScheduleContainer}
