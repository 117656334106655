import {
  Experiment,
  ExperimentClient,
  Variants,
  Variant,
} from '@amplitude/experiment-react-native-client'

import {isDev} from 'src/lib/utils/environmentUtil'
import {
  ExperimentNames,
  ExperimentNamesType,
  DefaultVariant,
  ExperimentDefaults,
} from 'src/lib/experimentation/amplitude/experimentTypes'
import {SetAmplitudeUserProperties} from 'src/lib/Analytics/analytics_compat'
import {GetExperimentOverride} from 'src/lib/LaunchArgs/Experiments'

let experimentClient: ExperimentClient | undefined

const amplitudeExperimentationProductionKey = 'client-X65dt6kgcHs1qmz2y4SfRK1mF5XQafeJ'
const amplitudeExperimentationStagingKey = 'client-8OGEMRWd7wC09zkq1onWbGeaBKGxaucu'

const experimentDefault = (experimentName: ExperimentNamesType): Variant => {
  return ExperimentDefaults[experimentName]
    ? {
        value: ExperimentDefaults[experimentName],
      }
    : DefaultVariant
}

const getExperimentDefaults = (): Variants => {
  const initialVariants: Variants = {}
  ExperimentNames.forEach((experimentName) => {
    initialVariants[experimentName] = experimentDefault(experimentName)
  })

  return initialVariants
}

export const initializeAmplitudeExperimentation = async (
  userId: string | undefined,
  email?: string,
): Promise<void> => {
  const isDevEnv = await isDev()

  if (!experimentClient) {
    const amplitudeKey = isDevEnv
      ? amplitudeExperimentationStagingKey
      : amplitudeExperimentationProductionKey

    experimentClient = Experiment.initializeWithAmplitudeAnalytics(amplitudeKey, {
      automaticExposureTracking: false,
      automaticFetchOnAmplitudeIdentityChange: true,
      fallbackVariant: DefaultVariant,
      initialVariants: getExperimentDefaults(),
    })
  }

  // Fetches variants for the user only once on initialization
  await experimentClient.fetch({user_id: userId})

  // Reset cache on user change / initialization
  previousExperimentValue = {}

  // In dev, set user email
  if (email && isDevEnv) {
    SetAmplitudeUserProperties('[Possible Finance] email', email)
  }
}

let previousExperimentValue: Partial<Record<ExperimentNamesType, string>> = {}
export const getExperimentVariant = (experimentName: ExperimentNamesType): Variant => {
  const override = GetExperimentOverride(experimentName)
  if (override) {
    return {
      value: override,
    }
  }

  if (!experimentClient) {
    return experimentDefault(experimentName)
  }

  const variant = experimentClient.variant(experimentName, experimentDefault(experimentName))

  // Track exposure only on initial call or when variant value changes
  if (previousExperimentValue[experimentName] !== variant.value) {
    previousExperimentValue[experimentName] = variant.value
    experimentClient.exposure(experimentName)
  }

  return variant
}
