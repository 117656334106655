import React, {FC} from 'react'

import {
  AcceptLoanAndAutoPayAgreementsBaseTemplate,
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'

export type AcceptLoanAndAutoPayAgreementTemplateProps = Omit<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'showLoanAgreementCheckbox'
>

/**
 * Page template for a screen that asks users to accept the autopay agreement. Does not
 * include the loan agreement checkbox.
 */
export const AcceptLoanAndAutoPayAgreementsTemplate: FC<
  AcceptLoanAndAutoPayAgreementTemplateProps
> = (props) => {
  return (
    <AcceptLoanAndAutoPayAgreementsBaseTemplate
      {...props}
      testID="LoanAndAutoPayAgreements"
      showLoanAgreementCheckbox={true}
    />
  )
}
