import React, {memo, ReactNode, useContext} from 'react'
import {ScrollView, StyleSheet, TextStyle} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button, {ButtonSize} from 'src/designSystem/components/atoms/Button/Button'
import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {TextVariants} from 'src/designSystem/typography'
import {SizeVariants} from 'src/designSystem/types'
import {OverlayContext} from 'src/designSystem/components/organisms/Overlay/OverlayContext'
import {OverlayVariantBaseProps} from 'src/designSystem/components/organisms/Overlay/Overlay.types'
import OverlayBase from 'src/designSystem/components/organisms/Overlay/OverlayBase'

export type OverlaySimpleProps = OverlayVariantBaseProps & {
  title?: string | ReactNode
  titleAlign?: TextStyle['textAlign']
  titleSize?: TextVariants
  image?: ReactNode
  text?: string | ReactNode
  legalText?: string | ReactNode
  okayButtonText?: string
  onOkay?: (() => void) | (() => Promise<void>)
  // defaults to true. if false, tapping the okay button will not hide the modal
  hideOnOkay?: boolean
  okayButtonSize?: ButtonSize
  okayButtonDisabled?: boolean
  dismissButtonText?: string
  dismissButtonSize?: ButtonSize
  dismissButtonDisabled?: boolean
  onDismiss?: () => void
  dismissAsLink?: boolean
  /** note: prefer to use children instead <Overlay><Element/></Overlay>*/
  content?: ReactNode
  shouldContentScroll?: boolean
  textAlign?: TextStyle['textAlign']
  padding?: SizeVariants
  children?: ReactNode
  /** if renderKey is provided and changes the component will re-render, allowing you to force re-render when desired*/
  renderKey?: string
}

const getLegalText = (legalText: string | ReactNode): ReactNode | undefined => {
  if (!legalText) {
    return
  }

  if (typeof legalText === 'string') {
    return <PFText variant="p_sm">{legalText}</PFText>
  } else {
    return legalText
  }
}

const getButtonSize = (size?: ButtonSize): ButtonSize => {
  return size ?? 'large'
}

/**
 * A simple overlay that has an "okay" button, a "dismiss" button, a title, and text.
 * @example <OverlaySimple title="Title" text="Some text content" okayButtonText="Okay" onOkay={() => void} />
 */
const OverlaySimple: React.FC<OverlaySimpleProps> = (props: OverlaySimpleProps) => {
  const {hideOverlay} = useContext(OverlayContext)
  const {
    visible,
    title,
    titleAlign = 'left',
    titleSize = 'h2',
    image,
    text,
    legalText,
    okayButtonText,
    onOkay,
    hideOnOkay = true,
    okayButtonSize,
    okayButtonDisabled = false,
    dismissButtonText,
    dismissButtonSize,
    dismissButtonDisabled = false,
    onDismiss,
    dismissAsLink,
    content,
    shouldContentScroll = false,
    textAlign = image ? 'center' : 'left',
    testID,
    padding = 'medium',
    children,
  } = props
  const handleOkay = (): void => {
    // by default we hide the modal when tapping the okay button
    // if configured not to, call onOkay() without hiding
    if (!hideOnOkay) {
      void onOkay?.()
      return
    }

    hideOverlay({
      overlayProps: props,
      onCloseAnimationComplete: () => {
        void onOkay?.()
      },
    })
  }

  const handleDismiss = (): void => {
    hideOverlay({
      overlayProps: props,
      onCloseAnimationComplete: (): void => {
        onDismiss?.()
      },
    })
  }

  const dismissButtonType = dismissAsLink ? (
    <ButtonLink size={'large'} onPress={handleDismiss} disabled={dismissButtonDisabled}>
      {dismissButtonText}
    </ButtonLink>
  ) : (
    <Button
      mode="secondary"
      size={getButtonSize(dismissButtonSize)}
      width="100%"
      testID={`${testID}-DismissBtn`}
      onPress={handleDismiss}
      disabled={dismissButtonDisabled}
    >
      {dismissButtonText}
    </Button>
  )

  const renderButtons = (): React.ReactNode => {
    if (okayButtonText || dismissButtonText) {
      return (
        <Box gap={dismissAsLink ? 'small' : 'little'} fill="horizontal">
          {okayButtonText ? (
            <Button
              mode="primary"
              size={getButtonSize(okayButtonSize)}
              width="100%"
              onPress={handleOkay}
              testID={`${testID}-OkayBtn`}
              disabled={okayButtonDisabled}
            >
              {okayButtonText}
            </Button>
          ) : null}
          {dismissButtonText && dismissButtonType}
        </Box>
      )
    }
  }

  // content can be provided as children <OverlaySimple><Child /></OverlaySimple>
  // or as "content" prop <OverlaySimple content={<Child />} />
  const contentToShow = children ?? content
  let titleToShow: ReactNode = null
  if (typeof title === 'string' && title !== '') {
    titleToShow = (
      <PFText variant={titleSize} textAlign={titleAlign}>
        {title}
      </PFText>
    )
  } else if (title && typeof title !== 'string') {
    // if title isn't a string it's a ReactNode
    titleToShow = title
  }

  return (
    <OverlayBase testID={testID} visible={visible} parentProps={props}>
      <Box gap={'medium'} padding={padding} align={image ? 'center' : undefined}>
        {titleToShow}

        {image}

        {text && text !== '' ? (
          <PFText variant="p" textAlign={textAlign}>
            {text}
          </PFText>
        ) : undefined}

        {shouldContentScroll ? (
          <ScrollView style={style.contentContainerStyle}>{contentToShow}</ScrollView>
        ) : (
          contentToShow
        )}

        {renderButtons()}

        {getLegalText(legalText)}
      </Box>
    </OverlayBase>
  )
}

export default memo(OverlaySimple, (prevProps, newProps) => {
  const changed =
    prevProps.visible !== newProps.visible ||
    prevProps.text !== newProps.text ||
    prevProps.title !== newProps.title ||
    prevProps.okayButtonText !== newProps.okayButtonText ||
    prevProps.okayButtonDisabled !== newProps.okayButtonDisabled ||
    prevProps.dismissButtonText !== newProps.dismissButtonText ||
    prevProps.dismissButtonDisabled !== newProps.dismissButtonDisabled ||
    prevProps.renderKey !== newProps.renderKey
  return !changed
})

const style = StyleSheet.create({
  contentContainerStyle: {
    maxHeight: 300,
  },
})
