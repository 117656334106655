import {LinkTokenInput} from '@possible/cassandra/src/types/types.mobile.generated'

import {isDeviceAndroid, isDeviceIOS} from 'src/lib/utils/platform'

const plaidRedirectionSingularLink = 'https://possible.sng.link/Cyhrm/gt4n/e3dt'

export function getPlaidLinkTokenInput(): LinkTokenInput {
  if (isDeviceIOS()) {
    // Apple universal link
    return {redirectUri: plaidRedirectionSingularLink}
  } else if (isDeviceAndroid()) {
    // Android package name
    return {androidPackageName: 'com.possible_mobile'}
  }
  // Web redirect uri
  return {}
}
