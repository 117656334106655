import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type NetworkFailureErrorQueryVariables = Types.Exact<{[key: string]: never}>

export type NetworkFailureErrorQuery = {__typename?: 'Query'; me: {__typename?: 'User'; id: string}}

export const NetworkFailureErrorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'NetworkFailureError'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkFailureErrorQuery, NetworkFailureErrorQueryVariables>
