import React from 'react'

import {ProgressTrackerStep} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTrackerStep'
import type {ProgressTrackerStepProps} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTrackerStep'

type ProgressTrackerProps = {
  children?: React.ReactNode
  steps?: ProgressTrackerStepProps[]
}

const ProgressTracker = (props: ProgressTrackerProps): JSX.Element => {
  const {children, steps} = props

  return (
    <>
      {steps
        ? steps.map(({title, subtitle, ...stepProps}) => (
            <ProgressTrackerStep
              key={stepProps.key ?? (typeof title === 'string' ? JSON.stringify(title) : '')}
              title={title}
              subtitle={subtitle}
              {...stepProps}
            />
          ))
        : null}
      {children}
    </>
  )
}

export {ProgressTracker}
export type {ProgressTrackerProps}
