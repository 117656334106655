import React, {FC, ReactElement} from 'react'
import {FlatList, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'

type PrimaryAccountDescriptionProps = {
  isChooseYourAccount?: boolean
}

const PrimaryAccountDescription: FC<PrimaryAccountDescriptionProps> = ({
  isChooseYourAccount,
}): ReactElement => {
  const {t} = useTranslation(['AccountManagement', 'Common'])

  const listItems = [
    {id: 1, text: t('AccountManagement:AutoPay')},
    {id: 2, text: t('AccountManagement:OneTimePayments')},
    {id: 4, text: t('AccountManagement:PreQualifiedOffers')},
    {id: 5, text: t('AccountManagement:FutureApplications')},
  ]

  const renderList = ({item}: {item: {id: number; text: string}}): ReactElement => (
    <Box boxStyle={styles.listStyle}>
      <PFText variant="p_semibold">{`\u2022 ${item.text}`}</PFText>
    </Box>
  )

  return (
    <>
      <Box boxStyle={styles.row}>
        <PFText variant="p">
          {t('Your')}
          <PFStatusPill
            text="Primary"
            color={NamedColors.PRODUCT_BLUE}
            fontColor={NamedColors.WHITE}
          />
          {t('AccountIsUsedFor')}
        </PFText>
      </Box>
      <Box boxStyle={styles.listWrapper}>
        <FlatList
          data={listItems}
          renderItem={renderList}
          keyExtractor={(item): string => item.id.toString()}
        />
        <Box boxStyle={styles.row}>
          {isChooseYourAccount ? <PFText variant="p">{t('UpdatingPrimaryAccount')}</PFText> : null}
        </Box>
      </Box>
    </>
  )
}

export default PrimaryAccountDescription

const styles = StyleSheet.create({
  listStyle: {
    marginBottom: 5,
    marginLeft: 5,
  },
  listWrapper: {
    marginBottom: 20,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    marginTop: 10,
  },
})
