import React, {FC, useCallback} from 'react'
import WebView from 'react-native-webview'
import {StyleSheet} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect} from '@react-navigation/native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, AdminEvents} from 'src/lib/Analytics/app_events'

type Props = StackScreenProps<MainStackParamList, 'ZendeskSSOForm'> & {
  token: string
  zendeskUrl: string
}

const ZendeskSSOFormFC: FC<Props> = (props) => {
  const {token, zendeskUrl} = props

  useFocusEffect(
    useCallback(() => {
      TrackAppEvent(AdminEvents.help_center_redirect_selected, AppEvents.Category.Admin)
    }, []),
  )

  return (
    <SafeAreaView style={styles.webView}>
      <WebView
        testID="Help-Center-Form"
        originWhitelist={['*']}
        clearCache={true}
        source={{
          html: `
                <html>
                  <body  onload="document.forms[0].submit();">
                    <form method="post" action="${zendeskUrl}">
                      <input type="hidden" name="jwt" value="${token}" />
                    </form>
                  </body>
                </html>
              `,
        }}
      />
    </SafeAreaView>
  )
}
const ZendeskSSOForm = withForwardedNavigationParams<Props>()(ZendeskSSOFormFC)

export {ZendeskSSOForm}

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    width: '100%',
  },
})
