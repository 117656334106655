import React, {Fragment, FC} from 'react'
import {View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {
  RadioButton,
  RadioButtonProps,
} from 'src/designSystem/components/atoms/RadioButton/RadioButton'
import Separator, {
  DividerWithTextProps,
} from 'src/designSystem/components/atoms/Separator/Separator'
import {
  RadioButtonExtraInput,
  RadioButtonExtraInputType,
} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonExtraInput'
import {littleGap} from 'src/designSystem/layout'

export type RadioButtonListOption = Omit<RadioButtonProps, 'toggleChecked'> & {
  separatorProps?: DividerWithTextProps
  radioButtonExtraInput?: RadioButtonExtraInputType
}

type RadioButtonListProps = {
  options: RadioButtonListOption[]
  onPress: (selectedId: string) => void
  selectedOption: string
  extraInputValue?: string
  isBusy?: boolean
  testID?: string
  radioButtonExtraInputTestID?: string
}

const RadioButtonList: FC<RadioButtonListProps> = (props) => {
  const {options, onPress, selectedOption, extraInputValue, isBusy, radioButtonExtraInputTestID} =
    props

  return (
    <Box gap={'tiny'}>
      {options.map((option, index) => {
        const {
          id,
          testID,
          text,
          subText,
          isDisabled,
          separatorProps,
          radioButtonExtraInput,
          statusPillProps,
        } = option
        const isChecked = selectedOption === id
        return (
          <Fragment key={id}>
            <View style={{padding: littleGap}}>
              <RadioButton
                id={`radio-button-${index}`}
                testID={testID}
                text={text}
                subText={subText}
                isChecked={isChecked}
                toggleChecked={(): void => onPress(id)}
                isDisabled={isBusy || isDisabled}
                statusPillProps={statusPillProps}
              />
              {radioButtonExtraInput ? (
                <RadioButtonExtraInput
                  testID={radioButtonExtraInputTestID}
                  {...radioButtonExtraInput}
                  isSelected={isChecked}
                  value={extraInputValue ?? ''}
                  isDisabled={isBusy}
                />
              ) : null}
            </View>
            {separatorProps ? (
              <Box paddingVertical={'little'}>
                <Separator
                  text={separatorProps?.text}
                  color={separatorProps?.color}
                  variant={separatorProps?.variant}
                />
              </Box>
            ) : undefined}
          </Fragment>
        )
      })}
    </Box>
  )
}

export {RadioButtonList}
