import React from 'react'

import {ColorVariants, DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'

import {
  BallIndicator,
  DotIndicator,
  MaterialIndicator,
  UIActivityIndicator,
} from 'react-native-indicators'

export type LoadingType = 'loader0' | 'loader1' | 'loader2' | 'loader3'
export type LoaderSize = 'small' | 'medium' | 'large'
export type LoadingProps = {
  type: LoadingType
  color?: ColorVariants | NamedColors
  size: LoaderSize
}

const sizeToPoints = {
  small: 10,
  medium: 18,
  large: 24,
}

const Loading: React.FC<LoadingProps> = (props) => {
  const color = DefaultVariantsColor[props.color ?? 'primary'] ?? props.color
  switch (props.type) {
    case 'loader0':
      return <MaterialIndicator size={sizeToPoints[props.size]} color={color} />
    case 'loader1':
      return <UIActivityIndicator size={sizeToPoints[props.size]} color={color} />
    case 'loader2':
      return <BallIndicator size={sizeToPoints[props.size]} color={color} />
    case 'loader3':
      return <DotIndicator size={sizeToPoints[props.size] / 2} count={3} color={color} />
  }
}
export default Loading
