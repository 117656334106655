import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {PFDescriptionLabel} from 'src/designSystem/components/molecules/PFDescriptionLabel/PFDescriptionLabel'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {borderColor} from 'src/designSystem/semanticColors'
import {ColorVariants} from 'src/designSystem/colors'

const PhotoInstructionsCard: FC = () => {
  const {t} = useTranslation('PhotoInstructionsCard')

  const commonProps = {
    topImage: true,
    color: 'black' as ColorVariants,
  }

  const boxProps: BoxProps = {
    direction: 'row',
    paddingVertical: 'little',
    paddingHorizontal: 'large',
    gap: 'large',
    justify: 'around',
  }

  return (
    <BasicTile>
      <Box {...boxProps}>
        <PFDescriptionLabel label={t('WellLit')} descriptor="well-lit" {...commonProps} />
        <View style={styles.border} />
        <PFDescriptionLabel label={t('NoHands')} descriptor="no-hands" {...commonProps} />
        <View style={styles.border} />
        <PFDescriptionLabel label={t('InFocus')} descriptor="in-focus" {...commonProps} />
      </Box>
    </BasicTile>
  )
}

export default PhotoInstructionsCard

const styles = StyleSheet.create({
  border: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
})
