import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {LoanTypeVariant} from 'src/cassandra'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import {TapTile} from 'src/designSystem/components/molecules/TapTile/TapTile'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'
import {transferMethodsType} from 'src/lib/loans/consts'

export type LoanDisbursementMethodSelectionTemplateProps = {
  isInstantDebitDisabled?: boolean
  onSelectDebitCard: () => void
  onSelectAch: () => void
  onContinue: () => void | Promise<void>
  isSubmitting: boolean
  isContinueBtnDisabled: boolean
  selectedMethod: Extract<transferMethodsType, 'ach' | 'interchange'> | undefined
  loanTypeVariant?: LoanTypeVariant
  showIsEligibleForRTP: boolean
  closeIsEligibleForRTP: () => void
  continueWithRTP: () => void | Promise<void>
}

const LoanDisbursementMethodSelectionTemplate: FC<LoanDisbursementMethodSelectionTemplateProps> = (
  props,
) => {
  const {
    isInstantDebitDisabled,
    isContinueBtnDisabled,
    isSubmitting,
    onSelectDebitCard,
    onSelectAch,
    onContinue,
    selectedMethod,
    loanTypeVariant,
    showIsEligibleForRTP,
    closeIsEligibleForRTP,
    continueWithRTP,
  } = props

  const {t} = useTranslation(['DisbursementMethodSelection', 'Common'])

  const isAdvance = isAdvanceVariant(loanTypeVariant)
  const title = t(isAdvance ? 'TitleAdvance' : 'Title')

  const handleOnPressDebitCard = (): void => {
    if (isInstantDebitDisabled) {
      return
    }
    onSelectDebitCard()
  }

  const handleOnPressACH = (): void => {
    onSelectAch()
  }

  /**
   * Determine the lower info block that is displayed below the disbursement method CTA options.
   */
  let lowerBlockInfo: JSX.Element | null = null
  const whatHappensWhyRepaymentDue = (
    <Box direction="row" gap="little">
      <Box marginTop={'tiny'}>
        <SvgIcon name={'calendar'} colorVariant={'inactive'} size={'medium'} />
      </Box>

      <Box marginHorizontal="little" boxStyle={styles.whatHappensWhenRepaymentDueContainer}>
        <PFText variant="p_sm_semibold" color={'textDisabled'}>
          {t('WhatHappens')}{' '}
          <PFText variant="p_sm" color={'textDisabled'}>
            {t('AllOfYourPayments')}
          </PFText>
        </PFText>
      </Box>
    </Box>
  )

  if (selectedMethod === 'interchange') {
    lowerBlockInfo = (
      <Box gap={'small'}>
        <PFText variant={'p_sm_semibold'} color={'primary'}>
          {t('AccessToMoney')}
        </PFText>
        {whatHappensWhyRepaymentDue}
      </Box>
    )
  } else if (selectedMethod === 'ach') {
    lowerBlockInfo = (
      <Box gap={'small'}>
        <PFText variant={'p_sm_semibold'} color={'primary'}>
          {t('MoneyWithinOneToTwoBusinessDays')}
        </PFText>
        {whatHappensWhyRepaymentDue}
      </Box>
    )
  }

  return (
    <Page
      variant={'generic'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: () => onContinue(),
          disabled: isContinueBtnDisabled || isSubmitting,
          loading: isSubmitting,
        },
      }}
      title={title}
      smallTopGap
    >
      <Box gap="small">
        <Box direction={'column'} gap={'small'}>
          <TapTile
            title={t('FreeInstantTransfer')}
            description={t('LinkDebitCardConnectedToYourPrimaryBankAccount')}
            icon={'card'}
            onPress={handleOnPressDebitCard}
            isSelected={selectedMethod === 'interchange'}
            isNotSelected={selectedMethod === 'ach'}
            testId={'disbursement-method-tap-tile-debit-card'}
          />
          <TapTile
            title={t('UpTo12BusinessDays')}
            description={t('GetADirectDeposit')}
            icon={'bank'}
            onPress={handleOnPressACH}
            isSelected={selectedMethod === 'ach'}
            isNotSelected={selectedMethod === 'interchange'}
            testId={'disbursement-method-tap-tile-ach'}
          />
        </Box>
        {isInstantDebitDisabled ? (
          <Alert
            level="warning"
            title={t('InstantDebitUnavailableTitle')}
            description={t('InstantDebitUnavailableDescription')}
          />
        ) : null}
        {lowerBlockInfo}
      </Box>
      <BottomSheet
        visible={showIsEligibleForRTP}
        onDismiss={async () => {
          closeIsEligibleForRTP()
          await continueWithRTP()
        }}
        showDots={false}
        dismissButton={{text: t('continue')}}
        fullWidthTitle={false}
        testID="Rtp-Eligible-Bottom-Sheet"
      >
        <Box gap={'small'}>
          <SvgIcon name={'checkWithCircle'} colorVariant={'success'} size={'enormous'} />

          <PFText variant="h3">{t('EligibleForRTP')}</PFText>
          <PFText variant="p">{t('FundsWithinFiveMinutes')}</PFText>
        </Box>
      </BottomSheet>
    </Page>
  )
}

export {LoanDisbursementMethodSelectionTemplate}

const styles = StyleSheet.create({
  whatHappensWhenRepaymentDueContainer: {
    flex: 1,
  },
})
