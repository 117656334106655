import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ApolloError} from '@apollo/client'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {NoPreferredBankAccountIdErrorPage} from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/NoPreferredBankAccountIdErrorPage/NoPreferredBankAccountIdErrorPage'
import {RelinkPreferredBankAccountDocument} from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/RelinkPreferredBankAccount.gqls'
import {RelinkPreferredBankAccountTemplate} from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/RelinkPreferredBankAccountTemplate'
import {usePreferredAccount} from 'src/lib/bank/usePreferredAccount'

export type RelinkPreferredBankAccountGQLContainerProps = {
  onOpenBankAggregator: (preferredAccountId: string) => void
  onContactUs: () => void
  isParentLoading?: boolean
}

const RelinkPreferredBankAccountGQLContainer: FC<RelinkPreferredBankAccountGQLContainerProps> = (
  props,
) => {
  const {onOpenBankAggregator, onContactUs: handleOnContactUs, isParentLoading} = props
  const {t} = useTranslation('RelinkPreferredBankAccount')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const {
    value: preferredAccount,
    isLoading: isPreferredAccountLoading,
    preferredAccountError,
  } = usePreferredAccount()

  const {
    selectedData,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(
    RelinkPreferredBankAccountDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'RelinkPreferredBankAccount query error',
        )
      },
    },
    (data) => {
      return data
    },
  )

  const onRelinkAccount = (): void => {
    if (preferredAccount?.id) {
      onOpenBankAggregator(preferredAccount.id)
    } else {
      logOfferActivationError(
        'RelinkPreferredBankAccountGQLContainer preferredAccount.id is undefined',
      )
    }
  }

  const handleOnVerifyMyAccountInfo = (): void => {
    setIsSubmitting(true)
    if (!selectedData?.me.loans.latestActionableLoan?.id) {
      setIsSubmitting(false)
      return
    }

    try {
      onRelinkAccount()
    } catch (error) {
      logOfferActivationErrorAndShowException(
        error,
        'RelinkPreferredBankAccount failed to update loan activation requirements',
      )
      setIsSubmitting(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <BaseTemplate
      testID={'RelinkPreferredBankAccountGQLContainer'}
      pageTitle={t('YourBankInformationNeedsToBeVerified')}
      showTileBorder={false}
      isError={!!queryError || !!preferredAccountError}
      isLoading={isLoadingQuery || isPreferredAccountLoading || isParentLoading}
    >
      {!isPreferredAccountLoading && preferredAccount?.id ? (
        <RelinkPreferredBankAccountTemplate
          institutionName={preferredAccount?.institution?.name ?? ''}
          accountMask={preferredAccount?.mask ?? ''}
          onContactUs={handleOnContactUs}
          onVerifyMyAccountInfo={handleOnVerifyMyAccountInfo}
          isSubmitting={isSubmitting}
        />
      ) : (
        <NoPreferredBankAccountIdErrorPage />
      )}
    </BaseTemplate>
  )
}

export {RelinkPreferredBankAccountGQLContainer}
