import {CardAccountStatuses} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {isUserEligibleToReapply} from 'src/products/card/LoanDash/utils'
import {AccountStatusQueryDocument} from 'src/products/general/ApplicationProcessing/AccountStatusQuery.gqls'

type IsUserEligibleToReapplyType = {
  isLoading: boolean
  isUserEligibleToReapply: boolean
}

export const useIsUserEligibleToReapply = (): IsUserEligibleToReapplyType => {
  const {data, loading: isLoading} = useCassandraQuery(AccountStatusQueryDocument, {
    fetchPolicy: 'cache-first',
  })

  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const activeCardStatus = data?.me.cardAccounts.active?.status as CardAccountStatuses | undefined

  return {
    isLoading,
    isUserEligibleToReapply: isUserEligibleToReapply(activeCardStatus),
  }
}
