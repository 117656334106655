import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleSsnURI} from 'src/navigation/WebLinks'
import SSNCollectionGQLContainer from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionGQLContainer'

type Props = StackScreenProps<MainStackParamList, 'SsnConfirm'> & {
  onComplete: () => void
  clearDefaultSsnOnInit?: boolean
  navigation: StackNavigationProp<MainStackParamList, 'SsnConfirm'>
}

/**
 * SSNCollectionRouteContainer is used by SSN URA
 */
export const SSNCollectionRouteContainer: React.FC<Props> = (props) => {
  const {onComplete, clearDefaultSsnOnInit, navigation, route} = props
  const isFromUra = route.params.isFromURA
  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_ssn_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const handleMoreInfo = (): void => {
    TrackAppEvent(
      AppEvents.Name.personal_details_ssn_helper_selected,
      AppEvents.Category.Application,
    )
    PushWebPageOnStack(navigation, {
      uri: possibleSsnURI,
    })
  }

  const handleContactUs = (): void => {
    openContactUsForm(navigation.getParent())
  }

  const handleComplete = (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.personal_details_ssn_completed, AppEvents.Category.Application)
    onComplete()
    return Promise.resolve()
  }

  return (
    <SSNCollectionGQLContainer
      onComplete={handleComplete}
      onMoreInfoPressed={handleMoreInfo}
      onContactUs={handleContactUs}
      clearDefaultSsnOnInit={clearDefaultSsnOnInit}
      isFromUra={isFromUra}
    />
  )
}

export default withForwardedNavigationParams<Props>()(SSNCollectionRouteContainer)
