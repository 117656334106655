import React from 'react'

import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'

type AdhocAmountEntryInfoCapsuleProps = {
  text: string
}

const AdhocAmountEntryInfoCapsule: React.FC<AdhocAmountEntryInfoCapsuleProps> = (
  props,
): JSX.Element => {
  const {text} = props

  return (
    <PFInfoCapsule svgIcon={{name: 'largeArrowUpRight', colorVariant: 'success'}} text={text} />
  )
}

export {AdhocAmountEntryInfoCapsule}
