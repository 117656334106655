import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {LoanPaymentStatusCode} from 'src/cassandra'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

const PaymentItemStatusIndicator = (props: {
  paymentStatusCode: LoanPaymentStatusCode
  hasBeenRescheduled: boolean
}): JSX.Element => {
  const {paymentStatusCode, hasBeenRescheduled} = props
  const {t} = useTranslation('Reschedule')

  const showPaid = paymentStatusCode === LoanPaymentStatusCode.Completed

  const showEditIcon =
    paymentStatusCode === LoanPaymentStatusCode.Pending ||
    paymentStatusCode === LoanPaymentStatusCode.Suspended ||
    paymentStatusCode === LoanPaymentStatusCode.Failed ||
    paymentStatusCode === LoanPaymentStatusCode.InProgress

  const editIconColor = (): SvgIconProps['colorVariant'] => {
    if (paymentStatusCode === LoanPaymentStatusCode.InProgress) {
      return 'warning'
    } else if (
      paymentStatusCode === LoanPaymentStatusCode.Suspended ||
      paymentStatusCode === LoanPaymentStatusCode.Failed
    ) {
      return 'error'
    } else {
      return 'default'
    }
  }

  const paidText = (
    <Box testID={'payment-status-paid-text'}>
      <PFText variant={'p'} color={hasBeenRescheduled ? 'textPrimary' : 'link'}>
        {t('Paid')}
      </PFText>
    </Box>
  )

  return (
    <Box flex direction={'row'} justify={'end'} marginRight={'little'}>
      {showPaid && paidText}
      {!showPaid && showEditIcon ? (
        <SvgIcon
          testID={'payment-status-edit-icon'}
          name={'edit'}
          colorVariant={editIconColor()}
          size={'medium'}
        />
      ) : null}
    </Box>
  )
}

export {PaymentItemStatusIndicator}
