import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardActivationAutopayScheduleMutationMutationVariables = Types.Exact<{
  cardAccountId: Types.Scalars['ID']['input']
  cardPaymentMethodId: Types.Scalars['ID']['input']
}>

export type CardActivationAutopayScheduleMutationMutation = {
  __typename?: 'Mutation'
  cardAccountEnableAutomaticPayments?: Array<{
    __typename?: 'AutomaticPaymentSchedule'
    id?: string | null
    paymentDate: string
  }> | null
}

export type CardSetAutopayModelMutationMutationVariables = Types.Exact<{
  cardAccountId: Types.Scalars['ID']['input']
  autopayModel: Types.AutopayModel
}>

export type CardSetAutopayModelMutationMutation = {
  __typename?: 'Mutation'
  cardAccountSetAutopayModel: {
    __typename?: 'CardAccount'
    id: string
    autopayEnabled: boolean
    supportsMinPay: boolean
    payments: {
      __typename?: 'CardPaymentCollection'
      schedule?: Array<{
        __typename?: 'AutomaticPaymentSchedule'
        paymentDate: string
        id?: string | null
        currentExpectedAmount?: string | null
      }> | null
    }
    balance?: {
      __typename?: 'CardAccountBalance'
      minimumPaymentDue?: string | null
      minimumPaymentDueAfterPayments?: string | null
    } | null
  }
}

export const CardActivationAutopayScheduleMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CardActivationAutopayScheduleMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardPaymentMethodId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountEnableAutomaticPayments'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardPaymentMethodId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardPaymentMethodId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardActivationAutopayScheduleMutationMutation,
  CardActivationAutopayScheduleMutationMutationVariables
>
export const CardSetAutopayModelMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CardSetAutopayModelMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'autopayModel'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'AutopayModel'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountSetAutopayModel'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'autopayModel'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'autopayModel'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                {kind: 'Field', name: {kind: 'Name', value: 'supportsMinPay'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'schedule'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'numberOfPayments'},
                            value: {kind: 'IntValue', value: '100'},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'currentExpectedAmount'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'minimumPaymentDueAfterPayments'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardSetAutopayModelMutationMutation,
  CardSetAutopayModelMutationMutationVariables
>
