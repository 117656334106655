import React, {FC} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

const Notice: FC = () => {
  const {t} = useTranslation('Bank')
  const unsupportedAccountTypes = ['PrepaidCards', 'CreditCards']

  const primaryAction = {
    text: t('TryDifferentAccount'),
    onPress: (): void => {
      TrackAppEvent(
        AppEvents.Name.link_bank_account_unsupported_account_try_again,
        AppEvents.Category.Application,
      )
    },
    closeOnPress: true,
  }

  const unsupportedAccounts = unsupportedAccountTypes.map((unsupported) => (
    <Box key={unsupported} direction={'row'} gap={'small'} align={'center'} marginVertical={2}>
      <SvgIcon name={'warning'} colorVariant={'error'} size={'large'} />
      <PFText variant={'p'}>{t(unsupported)}</PFText>
    </Box>
  ))

  return (
    <LightboxNotice title={t('UnsupportedAccount')} primary_action={primaryAction}>
      <Box gap={'medium'}>
        <PFText variant={'p'}>{t('DidNotFindCheckingAccountMessage')}</PFText>
        <View>{unsupportedAccounts}</View>
        <PFText variant={'p'}>{t('LinkBankAccountToContinue')}</PFText>
      </Box>
    </LightboxNotice>
  )
}

export function showUnsupportedAccountsPopup(): void {
  ShowLightbox(<Notice />, true)

  TrackAppEvent(
    AppEvents.Name.link_bank_account_unsupported_account_viewed,
    AppEvents.Category.Application,
  )
}
