import React, {ReactElement} from 'react'
import {StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'
import {SvgProps} from 'react-native-svg'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import GenericList from 'src/designSystem/components/molecules/GenericList/GenericList'
import {NamedColors} from 'src/designSystem/colors'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import NoTransactions from 'src/assets/illustrations/NoTransactions.svg'
import {
  TransactionTileItem,
  getTransactionItemElement,
} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile.utils'

export type TransactionsTileProps = {
  title?: string
  transactions: TransactionTileItem[]
  maxTransactionsDisplayed?: number // defaults to no maximum
  noTransactionsText?: string
  noTransactionsImg?: React.FC<SvgProps>
  onViewMoreBtnText?: string
  showSeeMoreBtn: boolean
  onViewMore: () => void
  testID?: string
}

/**
 * A tile to display a list of transactions (i.e. payments or deposits).
 * @example <
 */
const TransactionsTile: React.FC<TransactionsTileProps> = (props: TransactionsTileProps) => {
  const {
    title,
    transactions,
    maxTransactionsDisplayed,
    noTransactionsText,
    showSeeMoreBtn,
    onViewMoreBtnText,
    onViewMore: handleOnViewMore,
    testID,
  } = props
  const {t} = useTranslation('TransactionsTile')
  const isEmpty = transactions.length === 0
  const itemsElements: ReactElement[] = transactions
    .slice(0, maxTransactionsDisplayed)
    .map((thisTransaction: TransactionTileItem) => {
      return getTransactionItemElement({
        t,
        item: thisTransaction,
      })
    })
  return (
    <Box boxStyle={styles.tileContainer} testID={testID}>
      {/* title */}
      <Box marginTop="small" marginBottom="little">
        <PFText variant="h3" textAlign="center">
          {title ?? t('Transactions')}
        </PFText>
      </Box>

      {/* transcations list */}
      <GenericList items={itemsElements} boxGap="little" />

      {/* see more btn */}
      {isEmpty ? (
        <Box direction="column" align="center" marginVertical="small">
          <Box marginBottom="small" boxStyle={styles.emptyStateImageContainer}>
            <PFSvgContain width={'100%'} svg={NoTransactions} />
          </Box>
          {noTransactionsText ? (
            <Box>
              <PFText variant="p_lg_semibold" color={NamedColors.SILVER} textAlign="center">
                {noTransactionsText}
              </PFText>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box marginVertical="small" align="center">
          {showSeeMoreBtn ? (
            <Button size="medium" mode="secondary" width="auto" onPress={handleOnViewMore}>
              {onViewMoreBtnText ?? t('SeeAllTransactions')}
            </Button>
          ) : null}
        </Box>
      )}
    </Box>
  )
}

export {TransactionsTile}

const styles = StyleSheet.create({
  emptyStateImageContainer: {
    width: '40%',
  },
  tileContainer: {
    backgroundColor: NamedColors.WHITE,
    borderRadius: 8,
  },
})
