import {WebAppConfigType} from 'src/WebAppConfigType'
import {EnvironmentType} from '@possible/cassandra'
import {DEV_HOST} from '@possible/generated/src/api/lib/ApiUris'

function getPathFromUrl(url) {
  return url.split(/[?#]/)[0]
}

const local_dev: Partial<WebAppConfigType> = {
  REACT_APP_PFENV: EnvironmentType.Dev,
}

const dev: Partial<WebAppConfigType> = {
  REACT_APP_PFENV: EnvironmentType.Dev,
}

const prod: Partial<WebAppConfigType> = {
  REACT_APP_PFENV: EnvironmentType.Prod,
}

function init(): WebAppConfigType {
  const hostname = location.hostname.toLowerCase()
  const REACT_APP_URI = getPathFromUrl(window.location.href)
  const REACT_APP_ORIGIN = window.location.origin
  let envSpecificConfig

  if (hostname === '0.0.0.0' || hostname === 'localhost') {
    //running from a local repo pointed to development servers
    envSpecificConfig = local_dev
  } else if (
    hostname === 'test.' + DEV_HOST ||
    hostname === 'app.' + DEV_HOST ||
    hostname === 'appstaging.' + DEV_HOST
  ) {
    //test.possiblesasquatch.com are the Merge Request builds
    //app.possiblesasquatch.com is the dev branch
    //both point to the development servers.
    envSpecificConfig = dev
  } else {
    //app.possiblefinance.com is the master branch pointed to production servers
    envSpecificConfig = prod
  }

  return {
    ...envSpecificConfig,
    REACT_APP_URI,
    REACT_APP_ORIGIN,
  }
}

const WebAppConfig = init()
if (WebAppConfig.REACT_APP_PFENV === EnvironmentType.Dev) {
  //The below is to support __DEV__ for react native libraries.
  //__DEV__ is initially set to undefined in index.html
  /* eslint-disable no-global-assign */
  // @ts-expect-error
  __DEV__ = true
  /* eslint-enable no-global-assign */
}

export default WebAppConfig
