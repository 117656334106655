import {StackScreenProps} from '@react-navigation/stack'
import moment from 'moment'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {getNextAvailableDisbursementDate} from 'src/api/actions/loans/loanActions'

export type LoanFinalAcceptNavProps = StackScreenProps<MainStackParamList, keyof MainStackParamList>

export const handleOnConfirmBankDetails = (navigation: LoanFinalAcceptNavProps): void => {
  navigation.navigation.push('ConfirmBankAccount', {shouldNavBackAfterSubmit: true})
}

export type LoanDisbursementAvailableDateProps = {
  activeDate: moment.Moment
  loanId: string
}

export const getLoanDisbursementAvailableDate = async (
  props: LoanDisbursementAvailableDateProps,
): ReturnType<typeof getNextAvailableDisbursementDate> => {
  const {activeDate, loanId} = props
  const response = await getNextAvailableDisbursementDate(loanId, activeDate.format())
  if (!response) {
    throw new Error('Failed to retrieve next available disbursement date')
  }
  return response
}
