import {PreReqType} from 'src/workflows/types'
import {BaselineMinPreReqsOrder} from 'src/workflows/order/baselinePreReqOrder'
import {PreReqSortFunctionFactory} from 'src/workflows/order/utils'

/**
 * This array and the OnboardRevampPreReqSortFunction are used to
 * determine the order in which pre-reqs should be fulfilled for
 * users in the app-revamp-onboarding experiment.
 *
 * This experiment was completed and fully rolled out to all users.
 */
export const OnboardRevampPreReqOrder: PreReqType[] = [
  'AMOUNT_SELECTION',

  // user minimum pre-reqs (minimum to get any eligible or ineligible offers)
  ...BaselineMinPreReqsOrder,

  'SSN',
  'PII_CONFIRM',

  'CARD_APPLICATION_FINANCES', // Cards
  'DEPOSITORY_ACCOUNTS', // Both
  'CONFIRM_BANK_REAPPLICATION_NO_ACCOUNTS',
  'CONFIRM_BANK_REAPPLICATION_RELINK_ACCOUNT',
  'CONFIRM_BANK_REAPPLICATION_VALID_ACCOUNT',
  'PRIMARY_ACCOUNT',

  // Loans
  'MARKETING_SURVEY',

  // Both
  'EMAIL_VERIFICATION',

  // Loans
  'LOAN_APPLICATION_SUBMISSION',

  // Cards
  'CARD_APPLICATION_SUBMISSION',
]

/**
 * This function and the OnboardRevampPreReqOrder are used to
 * determine the order in which pre-reqs should be fulfilled for
 * users in the app-revamp-onboarding experiment.
 *
 * * This experiment was completed and fully rolled out to all users.
 */
export const OnboardRevampPreReqSortFunction = PreReqSortFunctionFactory(OnboardRevampPreReqOrder)
