import {
  getChaosFailedMutations,
  getChaosFailedQueries,
  getChaosResponseMockMutations,
} from './state/selectors'
import {GraphqlChaosResponseMock} from './state/slice'
import {chaosReduxStoreCache} from '../root-redux-store-cache'

type ApolloHttpLinkFetch = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>

const getMockFetchResponse = (mockResponse: any): Response => {
  const blob = new Blob([JSON.stringify(mockResponse, null, 2)], {type: 'application/json'})
  return new Response(blob)
}

export const integrateChaosModeForApolloHttpLink = async (
  fetch: ApolloHttpLinkFetch,
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  let operationName = ''
  // parse the operation name from the request body and simulate chaos / failure if it's
  // enabled for this mutation
  try {
    operationName = init?.body ? JSON.parse(init?.body.toString()).operationName : ''
  } catch (e) {}
  const chaosState = chaosReduxStoreCache.getChaosStateFromStore()
  if (!chaosState) {
    // if chaos state isn't set or configured we will use the regular fetch() method with no changes
    return fetch(input, init)
  }
  const chaosFailedMutations: {
    [mutationName: string]: boolean
  } = getChaosFailedMutations(chaosState)
  const chaosFailedQueries: {
    [queryName: string]: boolean
  } = getChaosFailedQueries(chaosState)
  const chaosResponseMockMutations: {
    [mutationName: string]: GraphqlChaosResponseMock
  } = getChaosResponseMockMutations(chaosState)

  const shouldSimulateChaosFailureForThisMutationCall =
    operationName && chaosFailedMutations[operationName] === true
  const shouldSimulateChaosResponseMockForThisMutationCall =
    operationName && chaosResponseMockMutations[operationName] !== undefined
  const shouldSimulateChaosFailureForThisQueryCall =
    operationName && chaosFailedQueries[operationName] === true

  if (
    !shouldSimulateChaosFailureForThisMutationCall &&
    !shouldSimulateChaosResponseMockForThisMutationCall &&
    !shouldSimulateChaosFailureForThisQueryCall
  ) {
    // if chaos state isn't set or configured we will use the regular fetch() method with no changes
    return fetch(input, init)
  }
  // simluate query failure
  if (shouldSimulateChaosFailureForThisQueryCall) {
    console.log('@possible/chaos simulating query failure', operationName)
    // short wait to simulate API call
    return getMockFetchResponse({
      data: null,
      errors: [
        {
          message: `Chaos Mode simulated query failure for: ${operationName}`,
        },
      ],
    })
  }
  // simulate mutation failure
  if (shouldSimulateChaosFailureForThisMutationCall) {
    console.log('@possible/chaos simulating mutation failure', operationName)
    // short wait to simulate API call
    return getMockFetchResponse({
      data: null,
      errors: [
        {
          message: `Chaos Mode simulated mutation failure for: ${operationName}`,
        },
      ],
    })
  }
  // simulate mutation mock response
  if (shouldSimulateChaosResponseMockForThisMutationCall) {
    console.log(
      `@possible/chaos simulating mutation using mock response "${chaosResponseMockMutations[operationName]?.mockName}"`,
      operationName,
    )
    // short wait to simulate API call
    return getMockFetchResponse({
      data: chaosResponseMockMutations[operationName]?.mockResponse,
      errors: [],
    })
  }
  // if chaos state isn't set or configured we will use the regular fetch() method with no changes
  return fetch(input, init)
}
