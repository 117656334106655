import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import {littleGap, smallGap} from 'src/designSystem/layout'

type Props = {
  errorsCount: number
}

export const ErrorCapsule: FC<Props> = (props) => {
  const {errorsCount} = props
  const {t} = useTranslation('AccountManagement')

  const title =
    errorsCount > 1 ? t('YouHaveAccountsThatNeedsYourAttention') : t('AnAccountNeedsYourAtention')

  const description =
    errorsCount > 1 ? t('TapTheAccountBelowForMoreInfo') : t('TapBelowForMoreInfo')

  return (
    <View style={styles.outerView}>
      <View style={styles.itemView}>
        <Alert title={title} description={description} level="warning" />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemView: {
    paddingBottom: smallGap,
  },
  outerView: {
    paddingTop: littleGap,
  },
})
