import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CardActivatedTemplate,
  CardActivatedTemplateProps,
} from 'src/products/card/Activation/CardActivated/CardActivatedTemplate/CardActivatedTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {logErrorAndShowException} from 'src/lib/errors'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {CardActivatedDocument} from 'src/products/card/Activation/CardActivated/CardActivatedGQLContainer/CardActivatedQuery.gqls'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {getUserFullName} from 'src/lib/user/utils'
import {isValidAddress} from 'src/lib/utils/formValidationUtil'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import ProdegeCardActivatedPixel from 'src/products/card/Activation/ProdegeCardActivatedPixel'

const CardActivatedGQLContainer: FC<Pick<CardActivatedTemplateProps, 'onComplete'>> = ({
  onComplete,
}) => {
  const {t} = useTranslation(['CardActivatedTemplate'])
  usePageViewedAnalytics({
    eventName: CardEvents.card_activated_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })

  const {
    data,
    error: isError,
    loading: isLoading,
  } = useCassandraQuery(CardActivatedDocument, {
    fetchPolicy: 'cache-first',
  })

  const mailingAddress = data?.me.profile?.mailing?.address || data?.me.profile?.home?.address

  const handleOnPress = (): void => {
    try {
      TrackAppEvent(CardEvents.card_activated_page_cta, AppEvents.Category.Card)
      void onComplete?.()
    } catch (e) {
      void logErrorAndShowException(e, 'card CardActivated error')
    }
  }

  return (
    <BaseTemplate
      isError={!!isError}
      isLoading={isLoading}
      pageTitle={t('Title')}
      showTileBorder={false}
    >
      <CardActivatedTemplate
        userFullName={data?.me.profile?.name ? getUserFullName(data.me.profile.name) : undefined}
        userAddress={isValidAddress(mailingAddress) ? mailingAddress : undefined}
        isAutopayEnabled={data?.me.cardAccounts.active?.autopayEnabled ?? false}
        onComplete={handleOnPress}
      />
      <ProdegeCardActivatedPixel />
    </BaseTemplate>
  )
}

export {CardActivatedGQLContainer}
