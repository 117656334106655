import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {AccountListDocument} from 'src/lib/bank/queries/AccountList.gqls'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {
  LinkedAccountsSelector,
  UseGetLinkedAccountsResult,
} from 'src/lib/bank/useGetLinkedAccounts.utils'

/**
 * Hook to get a user's linked accounts and their primary account. Note that you may be looking for
 * PaymentMethods instead of LinkedAccounts since payment methods added without Plaid/Yodlee will
 * not be included in the LinkedAccounts list.
 */
export const useGetLinkedAccounts = (): UseGetLinkedAccountsResult => {
  const {
    selectedData: linkedAccountsData,
    loading: isLoadingLinkedAccounts,
    error: linkedAccountsError,
    refetch: linkedAccountsRefetch,
  } = useCassandraQuery(
    AccountListDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (error) => {
        logAddPaymentMethodErrorAndShowException(error, 'useGetLinkedAccounts failed')
      },
    },
    LinkedAccountsSelector,
  )

  return {linkedAccountsData, linkedAccountsError, isLoadingLinkedAccounts, linkedAccountsRefetch}
}
