import {useRef, useEffect} from 'react'

/**
 * The given function, fn, will be executed the first time it
 * is called after the component is mounted. All subsequent calls
 * will do nothing.
 */
export const useExecuteOnce = <T>(fn: (...args: T[]) => void) => {
  const fnRef = useRef(fn)

  useEffect(() => {
    fnRef.current = (() => {
      let hasRun = false
      return (...args) => {
        if (!hasRun) {
          fn(...args)
          hasRun = true
        }
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return fnRef.current
}
