import {
  remoteConfigLib,
  initializeRemoteConfig,
  setDefaults,
  verifyLastFetchStatusSucceeded,
  getRemoteValueString,
} from 'src/lib/RemoteConfig/methods'
import {fetchError, loginLink, refreshInterval} from 'src/lib/RemoteConfig/properties'
import Log from 'src/lib/loggingUtil'
import {INTRO_LOGIN_BUTTON} from 'src/lib/RemoteConfig/parameterkeys'
import {SetAmplitudeUserProperties} from 'src/lib/Analytics/analytics_compat'
import {useEffect, useState} from 'react'

const handleError = (e) => {
  Log.log(fetchError, e)
}

export const init = (isRefreshing: boolean = false as const) => {
  const selectedInterval = isRefreshing ? 0 : refreshInterval // Set expirationDurationSeconds to 0 to force a fetch now
  const setDefaultsIfNotRefreshing = isRefreshing ? async () => null : setDefaults
  setDefaultsIfNotRefreshing()
    .then(() => initializeRemoteConfig(selectedInterval))
    .then(handleActivation)
    .catch(handleError)
}

export const forceRefresh = () => {
  Log.log('RemoteConfig last fetch status: ', remoteConfigLib.lastFetchStatus)
  init(true)
}

const handleActivation = (fetchedRemotely) => {
  /* activate resolve to true only if new values were grabbed,
     so false doesn't mean that the value weren't fetch there is just nothing new
   */
  const lastFetchStatus = remoteConfigLib.lastFetchStatus
  Log.log(
    `Firebase remote configs fetched status ${lastFetchStatus} and activated ${fetchedRemotely}.`,
  )
  const loginButtonRemoteConfig = getRemoteValueString(INTRO_LOGIN_BUTTON)
  Log.log('Login link enabled: ', loginButtonRemoteConfig === loginLink)

  if (verifyLastFetchStatusSucceeded(lastFetchStatus)) {
    /*We are setting the value of the A/B variant for Google Signin as a user property, this will let Product know which
     *  variant the user is in. This is a A/B test for logged out users so that value isn't saved in the DB and sent by
     *  the server, this means that analytics needs to be initialized before RemoteConfig*/
    SetAmplitudeUserProperties(
      `[Possible Finance] [AB] ${INTRO_LOGIN_BUTTON}`,
      loginButtonRemoteConfig,
    )
  } else {
    Log.log(fetchError, lastFetchStatus)
  }
}

// we should refactor this to use stronger typing
// https://possible.atlassian.net/browse/ENG-18320
export const getRemoteValueJson = <ReturnedType = unknown>(
  key: string,
): ReturnedType | undefined => {
  try {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    return JSON.parse(getRemoteValueString(key)) as ReturnedType
  } catch (e) {
    handleError(e)
    return undefined
  }
}

/**
 * Provides access to a firebase remote config json value in a component.
 */
export const useRemoteValueJson = (key: string): unknown => {
  const [value, setValue] = useState<unknown>(undefined)
  useEffect(() => {
    setValue(getRemoteValueJson(key))
  }, [key])
  return value
}
