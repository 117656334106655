export enum NamedColors {
  BLACK = '#1C1C1C',
  PRODUCT_BLUE = '#006EF5',
  PRODUCT_BLUE_ALPHA_20 = '#0577FF20',
  WHITE = '#FFFFFF',
  SKY = '#6BB6FB',
  OCEAN = '#091F83',
  MINT = '#5AFFE2',
  HONEYDEW = '#B6F9C1',
  GREEN = '#008A16',
  LIME = '#00EC25',
  PRODUCT_LIME = '#00D421',
  BLUSH = '#FFCEC2',
  PEACH = '#C17C87',
  CREAM = '#FFB257',
  AMBER = '#862910',
  SAND = '#FFEFB2',
  GOLD = '#FFD31F',
  PRODUCT_GOLD = '#E5BD1C',
  SLATE = '#F0F8FF',
  ASH = '#DADADA',
  SILVER = '#767676',
  VERMILLION = '#C82C19',
  PRODUCT_VERMILLION = '#B32816',
  WHITESMOKE = '#F5F5F5',
  TRANSPARENT = 'transparent',
  SEMITRANSPARENT = 'rgba(0, 0, 0, 0.5)',
  TRANSLUCENT = 'rgba(0, 0, 0, 0.25)',
}

//These are the main colors defined by design as primary colors
const ColorVariantValues = [
  'disabled',
  'textDisabled',
  'textPrimary',
  'success',
  'warning',
  'error',
  'link',
  'white',
  'black',
  'inverse',
  'primary',
  'info',
] as const

export type ColorVariants = (typeof ColorVariantValues)[number]

/**
 * Type guard to check if a string is a valid ColorVariant and get type safety.
 */
export const isColorVariant = (variant: string): variant is ColorVariants => {
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return ColorVariantValues.includes(variant as ColorVariants)
}
export const primaryLink = NamedColors.PRODUCT_BLUE
export const error = NamedColors.VERMILLION
export const success = NamedColors.LIME
export const alert = NamedColors.OCEAN
export const background = NamedColors.SLATE
export const backgroundAndroid = NamedColors.SLATE
export const disabled = NamedColors.ASH
export const textDisabled = NamedColors.SILVER
export const warning = NamedColors.PRODUCT_GOLD
export const inverse = NamedColors.WHITE
export const white = NamedColors.WHITE
export const black = NamedColors.BLACK
export const info = NamedColors.PRODUCT_BLUE

export type MapOfColorVariants = {[key in ColorVariants]: NamedColors}

export const DefaultVariantsColor: MapOfColorVariants = {
  disabled,
  textDisabled,
  textPrimary: black,
  success,
  warning,
  error,
  link: primaryLink,
  white,
  black,
  inverse,
  primary: NamedColors.PRODUCT_BLUE,
  info,
}
