import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {CardMinPaymentsQuery} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {getTestID} from 'src/lib/utils/tests.utils'
import {useCardsType} from 'src/products/card/hooks/useCards'

export type MinPayTablePropsFromUseCards = Pick<
  useCardsType,
  'nextPaymentDueDateFiltered' | 'autopayEnabled'
>

type MinPayTableFieldsProps = MinPayTablePropsFromUseCards & {
  cardMinPayResponse: CardMinPaymentsQuery['me']['cardAccounts']['active']
  autopayStatus: string
}

const manualPayRowOrder: string[] = [
  'paymentDueDate',
  'autopayModel',
  'minimumDue',
  'statementBalance',
]
const autoPayStatementBalanceRowOrder: string[] = [
  'autoPayDueDate',
  'autopayModel',
  'minimumDue',
  'statementBalance',
]
const autoPayMinPayRowOrder: string[] = [
  'autoPayDueDate',
  'autopayModel',
  'minimumDue',
  'statementBalance',
]

export const MinPayTableFields: React.FC<MinPayTableFieldsProps> = ({
  cardMinPayResponse,
  autopayStatus,
  autopayEnabled,
  nextPaymentDueDateFiltered,
}) => {
  const {t} = useTranslation(['UpcomingPaymentsCard', 'Common'])

  const rows = [
    {
      id: 'autopayModel',
      label: t('AutoPaySetting'),
      value: autopayStatus,
    },
    {
      id: 'paymentDueDate',
      label: t('PaymentDueDate'),
      value: formatDate(
        cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
        humanReadableDateDayMonth2,
      ),
    },
    {
      id: 'autoPayDueDate',
      label: t('AutoPayDate'),
      value: formatDate(nextPaymentDueDateFiltered, humanReadableDateDayMonth2),
      isHidden: cardMinPayResponse?.balance?.statement === '0.00' || !nextPaymentDueDateFiltered,
    },
    {
      id: 'minimumDue',
      label: t('MinimumDue'),
      value: `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`,
      isHidden: autopayStatus !== 'Minimum Due',
    },
    {
      id: 'statementBalance',
      label: t('StatementBalance'),
      value: `$${cardMinPayResponse?.balance?.statementAfterPayments}`,
    },
  ]

  const renderRow = (label: string, value: string | number): JSX.Element => (
    <Box
      direction="row"
      testID={getTestID(label, '-Id')}
      justify="between"
      paddingVertical="tiny"
      key={label}
    >
      <PFText variant="p" color={NamedColors.SILVER}>
        {t(label)}:{' '}
      </PFText>
      <PFText variant="p">{value}</PFText>
    </Box>
  )

  let order: string[] = []

  if (!autopayEnabled) {
    order = manualPayRowOrder
  } else {
    if (autopayStatus === 'Statement Balance') {
      order = autoPayStatementBalanceRowOrder
    } else {
      order = autoPayMinPayRowOrder
    }
  }

  return order.map((id) => {
    const row = rows.find((row) => row.id === id)
    if (row && !row.isHidden) {
      return renderRow(row.label, row.value)
    }
  })
}
