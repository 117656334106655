import {CreateCassandraSelector} from '@possible/cassandra/src/utils/selectors'

import {ApprovedApplicationsDocument} from 'src/products/general/ApplicationActivationWorkflow/ApprovedApplications.gqls'

const ApprovedApplicationsQueryApplicationSelector = CreateCassandraSelector(
  ApprovedApplicationsDocument,
  (data) => data.me.products.applications.all,
)

export {ApprovedApplicationsQueryApplicationSelector}
