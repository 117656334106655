import {createDrawerNavigator} from '@react-navigation/drawer'
import {createStackNavigator} from '@react-navigation/stack'
import React from 'react'

import LeftDrawer from 'src/products/general/LeftDrawer/LeftDrawer'
import {AppStackParamList} from 'src/nav/MainStackParamsList'
import RedirectionListener from 'src/nav/RedirectionListener'
import {MainStack} from 'src/nav/Stacks/MainStack'
import {ModalStack} from 'src/nav/Stacks/ModalStack'

const AppStackNav = createStackNavigator<AppStackParamList>()

const AppStack = () => {
  return (
    <AppStackNav.Navigator initialRouteName={'MainStack'}>
      <AppStackNav.Screen name="MainStack" component={MainStack} options={{headerShown: false}} />
      <AppStackNav.Screen
        name="ModalStack"
        component={ModalStack}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
        }}
      />
    </AppStackNav.Navigator>
  )
}

const Drawer = createDrawerNavigator()
const drawerContent = (props) => <LeftDrawer {...props} />

// TECH DEBT NOTE
// This should probably return the Unauthenticated routes without
// them being wrapped in a Drawer Navigator as the Drawer should
// never be presented to unauthenticated users.
// This will complicate route typing dramatically, however.
const DrawerStack = (): React.ReactNode => {
  return (
    <Drawer.Navigator
      initialRouteName="AppStack"
      screenOptions={{
        drawerHideStatusBarOnOpen: true,
        drawerType: 'front',
        headerShown: false,
      }}
      drawerContent={drawerContent}
      backBehavior={'initialRoute'}
    >
      <Drawer.Screen name="AppStack" component={AppStack} />
    </Drawer.Navigator>
  )
}

const RootStack = () => {
  return (
    <>
      <DrawerStack />
      <RedirectionListener />
    </>
  )
}

/*
 The names of the route configs matter. The cloud flare needs to redirect these to the root, otherwise a 400 error
 will be returned. This is a problem because the nav path is placed on the browser location bar, during a refresh.
 the browser will try to reach out. Any base route name should not conflict with any resources generated into the
 build folder.
 */

export default RootStack
