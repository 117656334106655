import {StyleSheet, Animated, TextStyle, ViewStyle, StyleProp} from 'react-native'

import {isDeviceAndroid} from 'src/lib/utils/platform'
import {SvgIconSizeMap} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

import {DefaultVariantsColor} from 'src/designSystem/colors'
import {
  inputFontSizeActive,
  inputFontSizeInactive,
  inputLineHeightActive,
  inputLineHeightInactive,
} from 'src/designSystem/typography'
import {convertHexToRgbA} from 'src/designSystem/lib/colorUtil'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import {
  textFieldActiveColor,
  textInputBorderColor,
  textInputErrorOutlineColor,
  textInputActiveOutlineColor,
  transparent,
  textInputActiveOuterOutlineColor,
  textInputErrorOuterOutlineColor,
} from 'src/designSystem/semanticColors'
import {regularStyle, semiBoldStyle} from 'src/designSystem/fonts'
import {smallGap} from 'src/designSystem/layout'

const MIN_INPUT_HEIGHT = 60
const MIN_INPUT_OUTLINE_HEIGHT = 64
const baseBorderRadius = 8
const outlineBorderRadius = 10
const outlineBorderWidth = 2
const inputInternalGap = 4
export const inputGap = 8

export default StyleSheet.create({
  assistiveIconWrapper: {
    minWidth: SvgIconSizeMap['small'],
    paddingHorizontal: inputInternalGap,
  },
  assistiveTextViewWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: inputInternalGap,
    paddingHorizontal: smallGap,
  },
  assistiveTextWrapper: {
    flex: 1,
  },
  fieldWrapper: {
    flexDirection: 'row',
  },
  leftContentWrapper: {
    flex: 1,
  },
  outerWrapper: {
    flex: 1,
  },
  overrideView: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
})

export const LabelWrapperStyle = (
  animationControl: Animated.Value,
  placeholder?: string,
): Animated.WithAnimatedValue<ViewStyle> => {
  const viewStyle: ViewStyle = {
    position: 'absolute',
    left: smallGap,
  }

  return {
    ...viewStyle,
    top: animationControl.interpolate({
      inputRange: [0, 1],
      outputRange: [placeholder ? inputGap : smallGap, inputGap],
    }),
    zIndex: animationControl.interpolate({
      inputRange: [0, 1],
      outputRange: [-1, 0],
    }),
  }
}

export const LabelStyle: (
  animationControl: Animated.Value,
  state: PFFieldBaseStateTypes,
  placeholder?: string,
) => Animated.WithAnimatedValue<TextStyle> = (
  animationControl: Animated.Value,
  state: PFFieldBaseStateTypes,
  placeholder?: string,
) => {
  const isActive = state === PFFieldBaseStates.ACTIVE || state === PFFieldBaseStates.DEFAULT
  const fontStyle = isActive || placeholder ? semiBoldStyle : regularStyle

  return {
    ...fontStyle,
    fontSize: animationControl.interpolate({
      inputRange: [0, 1],
      outputRange: [inputFontSizeActive, inputFontSizeInactive],
    }),
    lineHeight: animationControl.interpolate({
      inputRange: [0, 1],
      outputRange: [inputLineHeightActive, inputLineHeightInactive],
    }),
  }
}

export const BoxStyle = (state: PFFieldBaseStateTypes): ViewStyle => {
  const activeColor =
    state === PFFieldBaseStates.ACTIVE ? textInputActiveOutlineColor : textInputBorderColor

  return {
    backgroundColor: DefaultVariantsColor['white'],
    borderRadius: baseBorderRadius,
    borderWidth: 1,
    borderColor: state === PFFieldBaseStates.ERROR ? textInputErrorOutlineColor : activeColor,
    minHeight: MIN_INPUT_HEIGHT,
    overflow: 'hidden',
  }
}

export const OutlineStyle = (state: PFFieldBaseStateTypes): StyleProp<ViewStyle> => {
  const activeColor =
    state === PFFieldBaseStates.ACTIVE ? textInputActiveOuterOutlineColor : transparent

  return {
    minHeight: MIN_INPUT_OUTLINE_HEIGHT,
    borderRadius: outlineBorderRadius,
    borderWidth: outlineBorderWidth,
    borderColor: state === PFFieldBaseStates.ERROR ? textInputErrorOuterOutlineColor : activeColor,
  }
}

export const RightContentWrapperStyle: (boolean) => ViewStyle = (hasIcon: boolean) => ({
  alignItems: 'center',
  flex: 0,
  flexBasis: 'auto',
  justifyContent: hasIcon ? 'center' : 'flex-start',
  minWidth: SvgIconSizeMap['small'],
  paddingHorizontal: smallGap,
  paddingTop: hasIcon ? 0 : inputInternalGap,
  ...(hasIcon ? {} : AbsoluteTopStyle()),
})

export const AbsoluteTopStyle: () => ViewStyle = () => ({
  position: 'absolute',
  top: 0,
  right: 0,
})

export const selectionColor = isDeviceAndroid()
  ? convertHexToRgbA(textFieldActiveColor, 0.2)
  : textFieldActiveColor
