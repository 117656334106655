import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type SetLoanAutoPayAccountQueryQueryVariables = Types.Exact<{[key: string]: never}>

export type SetLoanAutoPayAccountQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      all?: Array<
        | {
            __typename?: 'AchPaymentMethod'
            id: string
            bankAccount: {__typename?: 'BankAccount'; mask: string}
            account?: {__typename?: 'LinkedAccount'; achNumbersAvailable?: boolean | null} | null
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {__typename?: 'DebitCardPaymentMethod'}
        | null
      > | null
    }
  }
}

export const SetLoanAutoPayAccountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'SetLoanAutoPayAccountQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'typeCodeFilter'},
                            value: {
                              kind: 'ListValue',
                              values: [
                                {kind: 'EnumValue', value: 'DEBIT_CARD'},
                                {kind: 'EnumValue', value: 'ACH'},
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'account'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'achNumbersAvailable'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetLoanAutoPayAccountQueryQuery,
  SetLoanAutoPayAccountQueryQueryVariables
>
