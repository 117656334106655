import {
  LazyQueryHookExecOptions,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  SubscriptionOptions,
  SubscriptionResult,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

import {CassandraClientOptions, useCassandraClient} from '../client'
import {isApplicationIAM} from './application'
import {QueryDataSelector} from './selectors'
import {useCallback} from 'react'

function useCassandraQuery<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
  RData = unknown,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  selector?: QueryDataSelector<TData, RData, TVariables>,
  clientOptions?: CassandraClientOptions,
) {
  const [client] = useCassandraClient(clientOptions)

  const {refetch, ...qlResult} = useQuery<TData, TVariables>(query, {
    ...options,
    client,
    variables: {
      ...options?.variables,
      iam: isApplicationIAM(),
    } as unknown as TVariables,
  })

  const refetchWithSelectedData = useCallback(
    async (variables?: Partial<TVariables>) => {
      const rResult = await refetch(variables)
      return {
        ...rResult,
        selectedData: selector?.(rResult.data),
      }
    },
    [refetch],
  )

  return {
    ...qlResult,
    refetch: refetchWithSelectedData,
    selectedData: qlResult.data ? selector?.(qlResult.data) : undefined,
  }
}

function useCassandraLazyQuery<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
  RData = unknown,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
  selector?: QueryDataSelector<TData, RData, TVariables>,
  clientOptions?: CassandraClientOptions,
): [
  (
    options?: Partial<LazyQueryHookExecOptions<TData, TVariables>>,
  ) => Promise<QueryResult<TData, TVariables> & {selectedData?: RData}>,
  LazyQueryResultTuple<TData, TVariables>[1] & {selectedData?: RData},
] {
  const [client] = useCassandraClient(clientOptions)

  const [fetch, qlResult] = useLazyQuery<TData, TVariables>(query, {
    ...options,
    client,
    variables: {
      ...options?.variables,
      iam: isApplicationIAM(),
    } as unknown as TVariables,
  })

  const fetchWithSelectedData = useCallback(
    async (fOptions?: Partial<LazyQueryHookExecOptions<TData, TVariables>>) => {
      const fResult = await fetch(fOptions)
      return {
        ...fResult,
        selectedData: fResult?.data ? selector?.(fResult.data) : undefined,
      }
    },
    [fetch],
  )

  return [
    fetchWithSelectedData,
    {
      ...qlResult,
      selectedData: qlResult.data ? selector?.(qlResult.data) : undefined,
    },
  ]
}

function useCassandraMutation<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
  clientOptions?: CassandraClientOptions,
): MutationTuple<TData, TVariables> {
  const [client] = useCassandraClient(clientOptions)

  return useMutation(mutation, {
    ...options,
    client,
    variables: {
      ...options?.variables,
      iam: isApplicationIAM(),
    } as unknown as TVariables,
  })
}

function useCassandraSubscription<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionOptions<TData, TVariables>,
  clientOptions?: CassandraClientOptions,
): SubscriptionResult<TData> {
  const [client] = useCassandraClient(clientOptions)

  return useSubscription(subscription, {
    ...options,
    client,
    variables: {
      ...options?.variables,
      iam: isApplicationIAM(),
    } as unknown as TVariables,
  })
}

export {useCassandraQuery, useCassandraLazyQuery, useCassandraMutation, useCassandraSubscription}
export type {
  UseCassandraQueryReturnType,
  UseCassandraQuerySelectedDataType,
  UseCassandraLazyQueryReturnType,
  UseCassandraLazyQueryMethodType,
  UseCassandraLazyQueryResultType,
  UseCassandraLazyQuerySelectedDataType,
}

type ExtractData<T> =
  T extends QueryDataSelector<infer RData, unknown>
    ? RData
    : T extends TypedDocumentNode<infer TData, unknown>
      ? TData
      : never
type ExtractVariables<T> =
  T extends QueryDataSelector<unknown, unknown, infer TVariables>
    ? TVariables
    : T extends TypedDocumentNode<unknown, infer TVariables>
      ? TVariables extends OperationVariables
        ? TVariables
        : never
      : never

/**
 * Get the full return type of the useCassandraQuery hook
 * @example (with a selector):
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MyReturnType = UseCassandraQueryReturnType<typeof selector>
 * @example (without a selector):
 * type MyReturnType = UseCassandraQueryReturnType<typeof MyQueryDocument>
 */
type UseCassandraQueryReturnType<
  SelectorOrTypedDocumentNode,
  TData = ExtractData<SelectorOrTypedDocumentNode>,
  RData = SelectorOrTypedDocumentNode extends QueryDataSelector<TData, infer RData> ? RData : never,
  TVariables extends OperationVariables = ExtractVariables<SelectorOrTypedDocumentNode>,
> = ReturnType<typeof useCassandraQuery<TData, TVariables, RData>>

/**
 * Extract the selected data type of the useCassandraLazyQuery hook given a selector type
 * @example
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MySelectedDataType = UseCassandraLazyQuerySelectedDataType<typeof selector>
 */
type UseCassandraQuerySelectedDataType<Selector> = NonNullable<
  UseCassandraQueryReturnType<Selector>['selectedData']
>

/**
 * Get the full return type of the useCassandraQuery hook
 * @example (with a selector):
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MyReturnType = UseCassandraQueryReturnType<typeof selector>
 * @example (without a selector):
 * type MyReturnType = UseCassandraQueryReturnType<typeof MyQueryDocument>
 */
type UseCassandraLazyQueryReturnType<
  SelectorOrTypedDocumentNode,
  TData = ExtractData<SelectorOrTypedDocumentNode>,
  RData = SelectorOrTypedDocumentNode extends QueryDataSelector<TData, infer RData> ? RData : never,
  TVariables extends OperationVariables = ExtractVariables<SelectorOrTypedDocumentNode>,
> = ReturnType<typeof useCassandraLazyQuery<TData, TVariables, RData>>

/**
 * Extract the function type (index 0) of the useCassandraLazyQuery hook's returned tuple
 * @example (with a selector):
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MyMethodType = UseCassandraLazyQueryMethodType<typeof selector>
 * @example (without a selector):
 * type MyMethodType = UseCassandraLazyQueryMethodType<typeof MyQueryDocument>
 */
type UseCassandraLazyQueryMethodType<SelectorOrTypedDocumentNode> =
  UseCassandraLazyQueryReturnType<SelectorOrTypedDocumentNode>[0]

/**
 * Extract the selected data type of the useCassandraLazyQuery hook given a selector type
 * @example
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MySelectedDataType = UseCassandraLazyQuerySelectedDataType<typeof selector>
 */
type UseCassandraLazyQuerySelectedDataType<Selector> = NonNullable<
  UseCassandraLazyQueryReturnType<Selector>[1]['selectedData']
>

/**
 * Extract the result type (index 1) of the useCassandraLazyQuery hook's returned tuple
 * @example (with a selector):
 * const selector = CreateCassandraSelector(MyQueryDocument, (fromData) => fromData.myQuery.myField)
 * type MyResultType = UseCassandraLazyQueryResultType<typeof selector>
 * @example (without a selector):
 * type MyResultType = UseCassandraLazyQueryResultType<typeof MyQueryDocument>
 */
type UseCassandraLazyQueryResultType<SelectorOrTypedDocumentNode> =
  UseCassandraLazyQueryReturnType<SelectorOrTypedDocumentNode>[1]
