import React, {FC} from 'react'
import {
  getPaymentMethodAccount,
  getPaymentMethodMask,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {LinkedAccount} from 'src/cassandra'
import {
  PaymentMethodFieldBase,
  PaymentMethodFieldProps,
} from 'src/products/card/PaymentMethods/PaymentMethodField/PaymentMethodFieldBase'
import {formatMask} from 'src/lib/user/utils'

type Props = PaymentMethodFieldProps & {
  paymentMethod: LinkedAccount
}

/**
 * This component displays a field for LinkedAccount.
 * These are bank accounts which have been returned by Plaid,
 * but have not yet been linked with an account or routing number.
 */
export const LinkedAccountPaymentMethodField: FC<Props> = (props) => {
  const {paymentMethod, ...otherProps} = props
  const accountName = getPaymentMethodAccount(paymentMethod)?.name ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''

  return (
    <PaymentMethodFieldBase
      paymentAccount={paymentMethod}
      title={accountName}
      subTitle={formatMask(accountMask, false)}
      {...otherProps}
    />
  )
}
