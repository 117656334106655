import React, {FC, ReactNode} from 'react'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {Trans, useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Coin from 'src/assets/icons/CoinGreen.svg'
import Calendar from 'src/assets/icons/Calendar1.svg'
import Upload from 'src/assets/icons/Upload.svg'
import Clock from 'src/assets/icons/Clock.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import AppNavActions from 'src/nav/AppNavActions'
import {SvgProps} from 'react-native-svg'

type Props = StackScreenProps<MainStackParamList, 'CardPaymentDetails'>
type ParaType = [FC<SvgProps>, string, ReactNode]

export const CardPaymentDetails: FC<Props> = ({navigation}) => {
  const {t} = useTranslation(['CardHowPaymentsWork', 'Common'])
  const paras: ParaType[] = [
    [
      Coin,
      'Title0',
      <Trans key="1" i18nKey={'Desc0'} t={t}>
        Your payment must be <PFText variant={'p_semibold'}>made in full</PFText> towards your card
        balance every pay period based on your payday schedule (biweekly or monthly). If you have a
        weekly payday or if we can’t determine your schedule, we’ll default to every two weeks.
      </Trans>,
    ],
    [
      Calendar,
      'Title1',
      <Trans key="2" i18nKey={'Desc1'} t={t}>
        Never miss a payment with Autopay, which pulls payments
        <PFText variant={'p_semibold'}>on your payday</PFText>. With Autopay, full payments will be
        deducted from your linked bank account. Alternatively, you may choose to make manual
        payments toward your balance. However, it’ll be up to you to make sure your card is paid in
        full each pay period.
      </Trans>,
    ],
    [
      Clock,
      'Title2',
      <Trans key="3" i18nKey={'Desc2'} t={t}>
        Don’t worry if you’re not able to make a full balance payment on a scheduled date - if
        you’re enrolled in autopay, you’ll have the option to
        <PFText variant={'p_semibold'}>Pay Over Time</PFText>.Please note that you won’t be able to
        access remaining credit until the balance is fully paid.
      </Trans>,
    ],
    [
      Upload,
      'Title3',
      <Trans key="4" i18nKey={'Desc3'} t={t}>
        You don’t have to worry about interest or late fees with a Possible Card. You’ll only pay a
        monthly fee to access credit. This monthly fee is
        <PFText variant={'p_semibold'}>automatically charged</PFText> to your card on the first of
        every month (whether you’ve used the card that month or not).
      </Trans>,
    ],
  ]

  return (
    <Page
      variant="generic"
      smallTopGap
      title={t('CardPaymentsWithPossible')}
      testID={'card_payments_with_possible'}
      titleTextAlignment="center"
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Okay'),
          onPress: () => AppNavActions.pop(navigation),
        },
      }}
    >
      <Box marginHorizontal="medium" marginBottom="large">
        {paras.map((para, i) => (
          <React.Fragment key={i}>
            <Box align="center" marginTop="medium" marginBottom="small">
              <PFSvgContain svg={para[0]} width={49} />
            </Box>

            <PFText variant="h3" textAlign="center">
              {t(para[1])}
            </PFText>

            <Box marginTop="small">
              <PFText variant="p">{para[2]}</PFText>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Page>
  )
}
