import {useMemo} from 'react'

import {LoanPaymentStatusCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {LoanProgressTileLoanPaymentFields} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile.types'

export const useLoanPaymentsPaidAmount = (
  payments: LoanProgressTileLoanPaymentFields[],
): number => {
  return useMemo((): number => {
    const paidAmount: number = payments
      .filter((thisPaymentDate: LoanProgressTileLoanPaymentFields) => {
        return thisPaymentDate.statusCode === LoanPaymentStatusCode.Completed
      })
      .reduce((accumulator, thisPaymentDate) => {
        return accumulator + parseFloat(thisPaymentDate.amount)
      }, 0)
    return paidAmount
  }, [payments])
}

export const useLoanPaymentsTotalAmount = (
  payments: LoanProgressTileLoanPaymentFields[],
): number => {
  return useMemo((): number => {
    const totalAmount: number = payments.reduce(
      (accumulator: number, thisPaymentDate: LoanProgressTileLoanPaymentFields) => {
        return accumulator + parseFloat(thisPaymentDate.amount)
      },
      0,
    )
    return totalAmount
  }, [payments])
}

export const useLoanPaymentsRemainingAmount = (
  payments: LoanProgressTileLoanPaymentFields[],
): number => {
  return useMemo((): number => {
    const remainingAmount: number = payments
      .filter((thisPaymentDate: LoanProgressTileLoanPaymentFields) => {
        return (
          thisPaymentDate.statusCode !== LoanPaymentStatusCode.Completed &&
          thisPaymentDate.statusCode !== LoanPaymentStatusCode.InProgress
        )
      })
      .reduce((accumulator: number, thisPaymentDate: LoanProgressTileLoanPaymentFields) => {
        return accumulator + parseFloat(thisPaymentDate.amount)
      }, 0)
    return remainingAmount
  }, [payments])
}

export const useLoanPaymentsFirstMissedPayment = (
  payments: LoanProgressTileLoanPaymentFields[],
): LoanProgressTileLoanPaymentFields => {
  return useMemo((): LoanProgressTileLoanPaymentFields => {
    const firstMissedPayment: LoanProgressTileLoanPaymentFields = payments
      .filter(
        (thisPaymentDate: LoanProgressTileLoanPaymentFields) =>
          thisPaymentDate.statusCode === LoanPaymentStatusCode.Failed ||
          thisPaymentDate.statusCode === LoanPaymentStatusCode.Suspended,
      )
      .sort((a: LoanProgressTileLoanPaymentFields, b: LoanProgressTileLoanPaymentFields) => {
        return (
          new Date(a.rescheduledDate ?? a.originalDate).getTime() -
          new Date(b.rescheduledDate ?? b.originalDate).getTime()
        )
      })[0]
    return firstMissedPayment
  }, [payments])
}
