import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'

import {ButtonAction} from 'src/designSystem/types'
import {onboardingSetCardPaymentMethod} from 'src/lib/onboarding/slice'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivationAutopayScheduleGQLContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleGQLContainer'
import {CardActivationAutopayScheduleTemplateProps} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleTemplate/CardActivationAutopayScheduleTemplate'
import {useDefaultPaymentAccount} from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/useDefaultPaymentAccount'
import {usePfDispatch} from 'src/store/utils'

type CardAutopayScheduleReduxContainerProps = {
  navigation: StackScreenProps<MainStackParamList>['navigation']
  onComplete?: ButtonAction
  onPressSecondary?: () => void | Promise<void>
  Template?: React.ComponentType<CardActivationAutopayScheduleTemplateProps>
} & Pick<CardActivationAutopayScheduleTemplateProps, 'isRoutedFromDashboard'>

export const CardActivationAutopayScheduleReduxContainer: FC<
  CardAutopayScheduleReduxContainerProps
> = ({navigation, onComplete, onPressSecondary, Template, isRoutedFromDashboard}) => {
  const {defaultPaymentAccount, hasPaymentInstrument} = useDefaultPaymentAccount()
  const dispatch = usePfDispatch()

  const handleOnComplete = (): void => {
    dispatch(onboardingSetCardPaymentMethod('autopay'))
    void onComplete?.()
  }

  const handleOnPressSecondary = (): void => {
    dispatch(onboardingSetCardPaymentMethod('manual'))
    void onPressSecondary?.()
  }

  return (
    <CardActivationAutopayScheduleGQLContainer
      defaultPaymentMethod={defaultPaymentAccount}
      doesPrimaryPaymentMethodHavePaymentInstrument={hasPaymentInstrument}
      navigation={navigation}
      onComplete={handleOnComplete}
      onPressSecondary={handleOnPressSecondary}
      Template={Template}
      isRoutedFromDashboard={isRoutedFromDashboard}
    />
  )
}
