import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseSetPrimaryAccountMutationVariables = Types.Exact<{
  linkedAccountId: Types.Scalars['ID']['input']
  updateActiveLoanPaymentMethod?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type UseSetPrimaryAccountMutation = {
  __typename?: 'Mutation'
  loanSetBankAccountAsPrimary: {
    __typename?: 'SetBankAccountAsPrimaryResponse'
    primaryAccount?: {
      __typename?: 'LinkedAccount'
      id: string
      preferredFundingSource?: boolean | null
    } | null
  }
}

export type UseSetPrimaryAccountLatestLoanAutoPayMethodQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type UseSetPrimaryAccountLatestLoanAutoPayMethodQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        preferredFundingSource?: boolean | null
        mask: string
      }> | null
    }
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
        paymentMethods: {
          __typename?: 'LoanPaymentMethodCollection'
          paymentDefault?:
            | {
                __typename?: 'AchPaymentMethod'
                id: string
                account?: {__typename?: 'LinkedAccount'; mask: string; id: string} | null
              }
            | {__typename?: 'CheckPaymentMethod'}
            | {
                __typename?: 'DebitCardPaymentMethod'
                id: string
                card: {
                  __typename?: 'LinkedDebitCard'
                  account?: {__typename?: 'LinkedAccount'; mask: string; id: string} | null
                }
              }
            | null
        }
      } | null
    }
  }
}

export const UseSetPrimaryAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useSetPrimaryAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'updateActiveLoanPaymentMethod'},
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
          defaultValue: {kind: 'BooleanValue', value: true},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetBankAccountAsPrimary'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkedAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentMethod'},
                value: {kind: 'EnumValue', value: 'ACH'},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'updateLoanPaymentMethod'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'updateActiveLoanPaymentMethod'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'primaryAccount'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseSetPrimaryAccountMutation, UseSetPrimaryAccountMutationVariables>
export const UseSetPrimaryAccountLatestLoanAutoPayMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseSetPrimaryAccountLatestLoanAutoPayMethod'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'paymentMethods'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'paymentDefault'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'AchPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'account'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'mask'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'card'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'account'},
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'mask'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'id'},
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseSetPrimaryAccountLatestLoanAutoPayMethodQuery,
  UseSetPrimaryAccountLatestLoanAutoPayMethodQueryVariables
>
