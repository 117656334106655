/* eslint-disable no-type-assertion/no-type-assertion */
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  LoanProcessingRejectedProps,
  LoanProcessingRejectedTemplate,
} from 'src/products/loans/LoanProcessing/LoanProcessingRejected/LoanProcessingRejectedTemplate'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {LoanProcessingQueryDocument} from 'src/products/loans/LoanProcessing/LoanProcessingQuery.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type LoanProcessingRejectedContainerProps = Pick<
  LoanProcessingRejectedProps,
  'onReadMore' | 'onComplete'
>

export const LoanProcessingRejectedGQLContainer: FC<LoanProcessingRejectedContainerProps> = (
  props,
) => {
  const {t} = useTranslation(['Onboarding'])
  const {
    selectedData: me,
    loading: isLoading,
    error,
  } = useCassandraQuery(
    LoanProcessingQueryDocument,
    {fetchPolicy: 'cache-first'},
    (data) => data.me,
  )

  const email = me?.profile?.email?.address
  const loanAggregateStatus = me?.loans.latestActionableLoan?.aggregateStatus
  let reapplyDatetime: string | undefined
  if (loanAggregateStatus?.__typename === 'ClosedLoanAggregateStatus') {
    reapplyDatetime = loanAggregateStatus.reapplyOn
  }

  return (
    <BaseTemplate
      isLoading={isLoading}
      isError={!!error || !email || !reapplyDatetime}
      pageTitle={t('OnboardingLoanProcessingRejectedTitle')}
      showTileBorder={false}
    >
      <LoanProcessingRejectedTemplate
        {...props}
        email={email ?? ''}
        reapplyDatetime={reapplyDatetime!}
      />
    </BaseTemplate>
  )
}
