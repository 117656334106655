import React from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import Bullseye from 'src/assets/illustrations/Bullseye.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import type {ValidAddress} from 'src/lib/utils/formValidationUtil'

type CardActivatedTemplateProps = {
  isAutopayEnabled?: boolean
  userAddress?: ValidAddress
  onComplete: () => void | Promise<void>
  userFullName?: string
}

const CardActivatedTemplate = ({
  isAutopayEnabled,
  userAddress,
  onComplete,
  userFullName,
}: CardActivatedTemplateProps): JSX.Element => {
  const {t} = useTranslation(['CardActivatedTemplate', 'Common'])

  const mapMailingAddressToFormattedArray = (): string[] => {
    let formattedAddress: string[] = []

    if (userFullName && userAddress) {
      formattedAddress = [userFullName, userAddress.street1]

      if (userAddress.street2) {
        formattedAddress.push(userAddress.street2)
      }

      formattedAddress.push(`${userAddress.city}, ${userAddress.state} ${userAddress.postalCode}`)
    }

    return formattedAddress
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('ButtonText'),
          onPress: onComplete,
          testID: 'CardActivatedTemplate-primaryButton',
        },
      }}
      variant={'generic'}
      title={t('Title')}
      testID={'CardActivatedTemplate'}
      smallTopGap
    >
      <Box align={'center'} paddingBottom={'large'}>
        <PFSvg
          SvgUri={Bullseye}
          svgProps={{
            height: 183,
            width: 175,
          }}
        />
      </Box>

      <Box gap={'small'}>
        {isAutopayEnabled ? <PFText variant={'p_semibold'}>{t('Access')}</PFText> : null}

        <PFText variant={'p'}>{t('Desc')}</PFText>

        {mapMailingAddressToFormattedArray().length ? (
          <Box>
            {mapMailingAddressToFormattedArray().map((addressLine) => (
              <PFText
                variant={'p'}
                key={addressLine.replace(/ /g, '-')} // sanitize possible spaces in keys
              >
                {addressLine}
              </PFText>
            ))}
          </Box>
        ) : null}
      </Box>
    </Page>
  )
}

export {CardActivatedTemplate}
export type {CardActivatedTemplateProps}
