import {useEffect, useState} from 'react'

import {Consumer} from '@possible/cassandra'

import {usePfDispatch, usePfSelector} from 'src/store/utils'

import {wfDebug, wfError, wfLog} from 'src/workflows/logging'
import {
  SetWorkflowStateAction,
  workflowsSelectedOfferIdSelector,
  workflowsStateFetchedSelector,
} from 'src/workflows/slice'

import {GetOfferForId, canAccessOffer} from 'src/workflows/workflow.utils'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'

type UseSelectDefaultOfferReturnType = {
  loading: boolean
  selectedOfferId: string | null
}

/**
 * Automatically select an eligible offer id once available
 * as determined by the status of the user's card eligibility.
 * This will be removed once product selection is implemented.
 */
export const useSelectDefaultOffer = (): UseSelectDefaultOfferReturnType => {
  const {selectedData} = Consumer.hooks.useProductsQuery({fetchPolicy: 'cache-first'})

  const hasFetchedWorkflowsState = usePfSelector(workflowsStateFetchedSelector)
  const selectedOfferId = usePfSelector(workflowsSelectedOfferIdSelector)
  const dispatch = usePfDispatch()
  const {canAccessCards, isLoading: isLoadingCardsAccess} = useCanAccessCards()
  const [result, setResult] = useState<UseSelectDefaultOfferReturnType>({
    loading: true,
    selectedOfferId: null,
  })

  // TEMPORARY
  // if we have no remaining minimum requirements,
  // we should select an offer based on user origin
  useEffect(() => {
    wfDebug('useSelectDefaultOffer effect running')

    if (isLoadingCardsAccess || !selectedData || !hasFetchedWorkflowsState) {
      wfLog('useSelectDefaultOffer waiting for data')
      setResult({loading: true, selectedOfferId: null})
      return
    }

    // if we have a selected offer already and it is still eligible,
    // we don't need to do anything
    if (selectedOfferId && GetOfferForId(selectedOfferId, selectedData.eligible)) {
      wfLog('useSelectDefaultOffer already has selected offer')
      setResult({loading: false, selectedOfferId})
      return
    }

    // Don't select a default offer if the user has an application
    const applications = selectedData?.applications.all ?? []
    if (applications.length > 0) {
      wfLog(
        'useSelectDefaultOffer not selecting any offer because user has existing application(s)',
      )
      setResult({loading: false, selectedOfferId: null})
      return
    }

    // Cards is no longer using useSelectDefaultOffer
    if (canAccessCards) {
      wfLog('useSelectDefaultOffer not selecting any offer for cards user')
      setResult({loading: false, selectedOfferId: null})
      return
    }

    const asyncEffect = async (): Promise<void> => {
      const {eligible} = selectedData

      // users in unsupported US states will not have any eligible offers
      // and should be taken to the waitlist screen
      if (eligible.all.length === 0) {
        wfLog('useSelectDefaultOffer found no eligible offers')
        setResult({loading: false, selectedOfferId: null})
        return
      }

      try {
        for (const e of eligible.all) {
          for (const offer of e.offers) {
            // there's probably a bug here in that it will select
            // the first offer it finds that the user can access
            // regardless of whether or not the user is truly "eligible"
            // for it -- a user intending to get a card might instead
            // select a loan offer, for example
            if (canAccessOffer(offer, canAccessCards)) {
              wfLog('useSelectDefaultOffer found default offer', {offerId: offer.id})
              await dispatch(
                SetWorkflowStateAction({
                  selectedOffer: {
                    offerId: offer.id,
                    metFrontEndPreReqs: [],
                  },
                }),
              )
              setResult({loading: false, selectedOfferId: offer.id})
              return
            }
          }
        }

        // if we get here, we didn't find any offers the user can access
        setResult({loading: false, selectedOfferId: null})
      } catch (e) {
        if (e instanceof Error) {
          wfError(e, 'Error selecting default offer')
        }

        // TODO: For now this will trigger the navigation to ProductHub
        // in the future we will likely want to retry or show an error screen
        setResult({loading: false, selectedOfferId: null})
      }
    }

    void asyncEffect()
  }, [
    dispatch,
    hasFetchedWorkflowsState,
    selectedData,
    selectedOfferId,
    canAccessCards,
    isLoadingCardsAccess,
  ])

  return result
}
