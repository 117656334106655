import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import LinkBankAccount from 'src/assets/illustrations/LinkBankAccount.svg'

type BankAccountAddedModalPropsTypes = {
  visible: boolean
  onDismiss: () => void
}

const BankAccountAddedModal: FC<BankAccountAddedModalPropsTypes> = (props) => {
  const {t} = useTranslation(['CardDashboard', 'Common'])
  const {visible, onDismiss} = props

  return (
    <Overlay
      visible={visible}
      title={t('YourBankAccountHasBeenVerified')}
      image={<PFSvgContain svg={LinkBankAccount} width={167} height={145} />}
      text={''}
      okayButtonText={t('Common:Continue')}
      okayButtonSize={'medium'}
      onOkay={(): void => {
        onDismiss()
      }}
      testID="BankAccountAddedModal"
    />
  )
}

export default BankAccountAddedModal
