import {useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {UseFormReturn} from 'react-hook-form'

import {usePfDispatch} from 'src/store/utils'
import {
  EmailPasswordLogin,
  SocialProvidersType,
  socialSignin,
} from 'src/api/MobileGatewayAPI/actions'
import {PushPage} from 'src/navigation/NavHelper'
import {ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'

import {snackbarIfError} from 'src/lib/Snackbar'
import {
  UserLoginResponse,
  UserSocialLoginFunction,
} from 'src/products/MCU/RegistrationOrLogin/Login/Login.types'
import {LoginOrRegisterResult} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'

export type UserLoginReturn =
  | (Pick<LoginOrRegisterResult, 'mfaDeliveryMedium'> &
      Pick<UserRegisterInput, 'email' | 'password'>)
  | null

export type UseUserLoginMutationReturn = {
  userLogin: (args: {data: UserRegisterInput; loginFromRegistration?: boolean}) => Promise<boolean>
  isSubmittingForm: boolean
  isSocialBusy: boolean
  handleSocialLogin: UserSocialLoginFunction
}

export const useUserLoginMutation = (
  formContext: UseFormReturn<UserRegisterInput>,
): UseUserLoginMutationReturn => {
  const {setValue} = formContext

  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'Login'>>()
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [isSocialBusy, setIsSocialBusy] = useState(false)

  const dispatch = usePfDispatch()

  const handleClearPassword = (result: UserLoginResponse): void => {
    const errorCode = result?.getErrorCode()
    if (errorCode === 'wrong_password') {
      setValue('password', '')
    }
  }

  const handleSocialLogin = async (provider: SocialProvidersType): Promise<void> => {
    setIsSocialBusy(true)
    try {
      const result = await dispatch(socialSignin(false, provider))
      snackbarIfError(result)
      handleClearPassword(result)
      setIsSocialBusy(false)
    } catch (e) {
      setIsSocialBusy(false)
      ShowException(e)
    }
  }

  const userLogin: UseUserLoginMutationReturn['userLogin'] = async ({
    data,
    loginFromRegistration = false,
  }): Promise<boolean> => {
    setIsSubmittingForm(true)
    try {
      const result = await dispatch(
        EmailPasswordLogin(data?.email, data?.password, loginFromRegistration),
      )
      if (result.mfaDeliveryMedium) {
        PushPage(navigation, 'OTPVerification', {
          email: data?.email,
          password: data?.password,
          mfaDeliveryMedium: result.mfaDeliveryMedium,
        })
      } else {
        const hasError = snackbarIfError(result)
        handleClearPassword(result)
        setIsSubmittingForm(false)

        if (hasError) {
          return false
        }
      }
      return true
    } catch (e) {
      setIsSubmittingForm(false)
      ShowException(e)
    }
    return false
  }

  return {
    userLogin,
    isSubmittingForm,
    isSocialBusy,
    handleSocialLogin,
  }
}
