import React, {ReactNode, FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'

export type ActionRowProps = {
  children: ReactNode
  isFirstItem?: boolean
}

const ActionRow: FC<ActionRowProps> = (props) => {
  const {children} = props

  return (
    <Box direction={'row'} align={'center'} paddingVertical={'little'}>
      {children}
    </Box>
  )
}

export default ActionRow
