import React from 'react'
import {AccessibilityProps, ImageBackground, StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {ProgressBarIndicator} from 'src/designSystem/components/molecules/ProgressBar/ProgressBarIndicator'
import ProgressBarSegmentProcessing from 'src/assets/images/ProgressBarSegmentProcessing.png'
import {
  ProgressBarSegment,
  ProgressBarSegmentStatus,
} from 'src/designSystem/components/molecules/ProgressBar/ProgressBarSegment'
import {ProgressBarDelimiters} from 'src/designSystem/components/molecules/ProgressBar/ProgressBarDelimiters'
import {useTranslation} from 'react-i18next'

type ProgressBarProps = AccessibilityProps & {
  accessibilityLabelNamespace?: string
  amountError?: number
  amountInProgress?: number
  amountSuccess?: number
  amountWarning?: number
  indicatorStatus?: ProgressBarSegmentStatus
  numberOfDelimiters?: number
}

/**
 * A progress bar to show progress of loan and card segments. It's recommended to use the
 * `accessibilityLabel` and `accessibilityHint`
 *
 * @param accessibilityLabelNamespace - The namespace for the translations to provide accessibility
 *                                      labels. Use this to provide relevant accessibility labels
 *                                      for the progress bar.
 * @param accessibilityLabel - It's recommended to provide a label to explain the purpose of the
 *                            progress bar. This will be read aloud by screen readers.
 * @param accessibilityHint - If you pass an `indicatorStatus` other than `None`, you may want to
 *                           provide a hint to explain the meaning of the indicator.
 * @param amountError - The amount of the error segment as a percentage between 0.0 - 1.0.
 * @param amountInProgress - The amount of the in progress segment as a percentage between 0.0 - 1.0.
 * @param amountSuccess - The amount of the success segment as a percentage between 0.0 - 1.0.
 * @param amountWarning - The amount of the warning segment as a percentage between 0.0 - 1.0.
 * @param indicatorStatus - The status of the indicator at the end of the progress bar. Defaults to
 *                          `ProgressBarSegmentStatus.None` and hides the indicator.
 * @example <ProgressBar
 *            amountError={0}
 *            amountInProgress={0}
 *            amountSuccess={0}
 *            amountWarning={0}
 *          />
 */
const ProgressBar = ({
  accessibilityLabelNamespace = 'ProgressBar',
  amountError = 0,
  amountInProgress = 0,
  amountSuccess = 0,
  amountWarning = 0,
  indicatorStatus = ProgressBarSegmentStatus.None,
  numberOfDelimiters = 0,
  ...accessibilityProps
}: ProgressBarProps): JSX.Element => {
  const {t} = useTranslation(accessibilityLabelNamespace)

  // Render order matters for layering
  return (
    <View
      accessible={true}
      accessibilityLabel={t('mainAccessibilityLabel')}
      role={'none'}
      style={styles.progressBar}
      {...accessibilityProps}
    >
      {amountInProgress > 0 ? (
        <ImageBackground
          /* To show the striped "in progress" background for segments we render
           * `ProgressBarSegment` with a transparent background to reveal the striped background
           * image layered behind the entire progress bar. This ensures the background image
           * repeats smoothly and correctly. */
          aria-hidden
          resizeMode="cover"
          resizeMethod="auto"
          source={ProgressBarSegmentProcessing}
          style={[styles.inProgress, StyleSheet.absoluteFill]}
        />
      ) : null}

      <View
        style={styles.segments}
        // Rendered segments that indicate progress. Order matters as these are sorted to spec by Product
      >
        {amountError > 0 ? (
          <ProgressBarSegment
            accessibilityLabelNamespace={accessibilityLabelNamespace}
            amount={amountError}
            status={ProgressBarSegmentStatus.Error}
          />
        ) : null}

        {amountWarning > 0 ? (
          <ProgressBarSegment
            accessibilityLabelNamespace={accessibilityLabelNamespace}
            amount={amountWarning}
            status={ProgressBarSegmentStatus.Warning}
          />
        ) : null}

        {amountSuccess > 0 ? (
          <ProgressBarSegment
            accessibilityLabelNamespace={accessibilityLabelNamespace}
            amount={amountSuccess}
            status={ProgressBarSegmentStatus.Success}
          />
        ) : null}

        {amountInProgress > 0 ? (
          <ProgressBarSegment
            accessibilityLabelNamespace={accessibilityLabelNamespace}
            amount={amountInProgress}
            status={ProgressBarSegmentStatus.InProgress}
          />
        ) : null}

        {amountError + amountWarning + amountSuccess + amountInProgress < 1 ? (
          <ProgressBarSegment
            accessibilityLabelNamespace={accessibilityLabelNamespace}
            accessibilityValue={{
              min: 0,
              max: 100,
              now: 100 - (amountError + amountWarning + amountSuccess + amountInProgress) * 100, // Expose value as amount remaining
            }}
            status={ProgressBarSegmentStatus.None}
            amount={1}
          />
        ) : null}
      </View>

      <ProgressBarDelimiters
        amount={amountError + amountWarning + amountSuccess}
        numberOfDelimiters={numberOfDelimiters}
      />

      <ProgressBarIndicator
        accessibilityLabelNamespace={accessibilityLabelNamespace}
        position={amountError + amountWarning + amountSuccess}
        type={indicatorStatus}
      />
    </View>
  )
}

export {ProgressBar}
export type {ProgressBarProps}

const styles = StyleSheet.create({
  progressBar: {
    backgroundColor: NamedColors.WHITE,
    borderColor: NamedColors.BLACK,
    borderRadius: 16,
    borderWidth: 1,
    flexDirection: 'row',
    height: 16,
    marginVertical: 4, // Android overflow fix for `ProgressBarIndicator`
    position: 'relative',
  },
  segments: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 16,
    overflow: 'hidden',
  },
  inProgress: {
    backgroundColor: NamedColors.HONEYDEW,
    borderRadius: 16,
    overflow: 'hidden', // Web overflow fix
  },
})
