import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'

const addOfferActivationIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - [Offer Activation]`)
  modifiedError.stack = e.stack
  return modifiedError
}

const formatError = (e: unknown): Error => {
  let finalError: Error
  if (e instanceof Error) {
    finalError = e
  } else {
    try {
      finalError = new Error(JSON.stringify(e))
    } catch (e) {
      finalError = new Error(String(e))
    }
  }
  return addOfferActivationIdentifierToError(finalError)
}

/**
 * Log errors related to offer activation / acceptance (card or loans). Includes standardized log suffix.
 */
export const logOfferActivationError = (e: unknown, msg?: string): void => {
  const finalError = formatError(e)
  Log.error(finalError, msg)
}

/**
 * Log errors related to offer application (card or loans) and show an exception. Includes standardized log suffix.
 */
export const logOfferActivationErrorAndShowException = (e: unknown, msg?: string): void => {
  const finalError = formatError(e)
  void logErrorAndShowException(finalError, msg)
}
