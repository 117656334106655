import React from 'react'
import {StyleSheet, View} from 'react-native'

import {smallGap} from 'src/designSystem/layout'
import {openContactUsForm} from 'src/lib/contactUs'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import Page, {PageProps} from 'src/designSystem/components/organisms/Page/Page'
import ErrorTile, {ErrorTileProps} from 'src/designSystem/components/organisms/ErrorTile/ErrorTile'
import {
  ErrorNotice,
  ErrorNoticeProps,
} from 'src/designSystem/components/organisms/ErrorNotice/ErrorNotice'

/**
 * The `ErrorPageDefaultProps` generic creates a type that is same as `ErrorNoticeProps`, except that
 * it has a default`onContactUs` so that prop can be optional.
 */
type ErrorTemplateDefaultProps<T extends ErrorNoticeProps> = Omit<T, 'onContactUs' | 'title'> &
  Partial<Pick<T, 'onContactUs'>> & {
    pageTitle?: PageProps['title']
    errorTitle?: ErrorNoticeProps['title']
  }

type ErrorNoticeTemplateProps = ErrorTemplateDefaultProps<ErrorNoticeProps> & {
  showTileBorder?: false
}
type ErrorTileTemplateProps = ErrorTemplateDefaultProps<ErrorTileProps> & {showTileBorder: true}

type ErrorTemplateProps = ErrorNoticeTemplateProps | ErrorTileTemplateProps
/**
 * Page that displays an error tile.
 */
const ErrorTemplate = (props: ErrorTemplateProps): JSX.Element => {
  const {errorTitle, onContactUs, pageTitle, showTileBorder = true, ...remainingProps} = props

  const mainStackNav = useMainNavigator()
  const defaultHandleOnContactUs = (): void => openContactUsForm(mainStackNav)
  const handleOnContactUs = onContactUs ?? defaultHandleOnContactUs
  return (
    <Page smallTopGap title={pageTitle} variant="generic">
      <View style={styles.tileShadowFix}>
        {showTileBorder ? (
          <ErrorTile {...remainingProps} title={errorTitle} onContactUs={handleOnContactUs} />
        ) : (
          <ErrorNotice {...remainingProps} title={errorTitle} onContactUs={handleOnContactUs} />
        )}
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  tileShadowFix: {
    /* Page is rendered with a collection of props that result in tile shadows getting clipped.
     * This padding attempts to make this more predictable. */
    paddingVertical: smallGap,
  },
})

export {ErrorTemplate}
export type {ErrorTemplateProps}
