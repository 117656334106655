import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MarketingSurveyGQLContainer} from 'src/products/general/MarketingSurvey/MarketingSurveyGQLContainer'
import {OnContinueAfterMarketingSurveyOptionSelected} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'

export type MarketingSurveyWorkflowsContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'MarketingSurvey'>

/**
 * Container to integrate the marketing survey in a Workflows flow.
 */
const MarketingSurveyWorkflowsContainer: React.FC<MarketingSurveyWorkflowsContainerProps> = (
  props: MarketingSurveyWorkflowsContainerProps,
) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.marketing_source_survey_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const handleContinue: OnContinueAfterMarketingSurveyOptionSelected = async ({
    selectedSourceOption,
    otherValue,
  }) => {
    TrackAppEvent(AppEvents.Name.marketing_source_survey_selected, AppEvents.Category.Application, {
      value: selectedSourceOption,
      other: otherValue,
    })
    await onPreReqFulfilled()
  }
  return <MarketingSurveyGQLContainer onContinue={handleContinue} />
}

export {MarketingSurveyWorkflowsContainer}
