import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ReschedulePaymentGQLContainer} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentGQLContainer'

type ReschedulePaymentProps = StackScreenProps<MainStackParamList, 'ReschedulePayment'>

const ReschedulePayment: FC<ReschedulePaymentProps> = (props) => {
  const {navigation, route} = props
  const {payment, loanId} = route.params

  function handleOnContinue(): void {
    navigation.navigate('SelectPaymentToReschedule', {
      loanId: loanId,
    })
  }

  return <ReschedulePaymentGQLContainer payment={payment} onContinue={handleOnContinue} />
}

export {ReschedulePayment}
