import {useCallback, useEffect, useState} from 'react'

import {checkPushPermissions} from 'src/lib/usePushPermissions/checkPushPermissions/checkPushPermissions'
import {requestPushPermissions as requestNativePermissions} from 'src/lib/usePushPermissions/requestPushPermissions/requestPushPermissions'
import Log from 'src/lib/loggingUtil'
/**
 * A stateful hook that returns the current push notification permissions
 * and a function to request them
 * @returns A tuple of (do we have push permissions already?, request them from the user)
 * [boolean, async () => boolean]
 */

export type UsePushPermissions = [boolean, () => Promise<boolean>]

export const usePushPermissions = (): UsePushPermissions => {
  const [hasPushPermissions, setHasPushPermissions] = useState(false)

  useEffect(() => {
    const fetchCurrentPermissions = async (): Promise<void> => {
      setHasPushPermissions(await checkPushPermissions())
    }

    fetchCurrentPermissions().catch((error) => {
      Log.log('Error fetching push permissions on mount', error)
    })
  }, [])

  const requestPushPermissions = useCallback(async () => {
    await requestNativePermissions()

    const authorizationStatus = await checkPushPermissions()
    setHasPushPermissions(authorizationStatus)

    return authorizationStatus
  }, [])

  return [hasPushPermissions, requestPushPermissions]
}
