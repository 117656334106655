import React, {ReactNode} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {useEnrollInCardsIfNecessary} from 'src/products/card/hooks/useEnrollInCardsIfNecessary'

/**
 * Ensure the correct product is set before loading the routes.
 * Otherwise workflows may load preReqs for the wrong product.
 */
export const ProductSelectionWrapper: React.FC<{children: ReactNode}> = ({children}) => {
  const {isComplete} = useEnrollInCardsIfNecessary()
  if (!isComplete) {
    return (
      <Box fill>
        <Loading type="loader0" size="large" />
      </Box>
    )
  }
  return children
}
