import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {SvgProps} from 'react-native-svg'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {darkGrayText} from 'src/designSystem/semanticColors'

export type EmptyStateProps = {
  text?: string
  image?: React.FC<SvgProps>
}

const emptyStateImageWidth = '40%'

const EmptyState: FC<EmptyStateProps> = (props) => {
  const {text, image} = props

  return (
    <Box direction={'column'} justify={'center'} gap={'none'} align={'center'}>
      {image ? (
        <View style={styles.imageView}>
          <PFSvgContain width={'100%'} svg={image} />
        </View>
      ) : undefined}
      {text ? (
        <PFText variant={'p_sm'} color={darkGrayText} textAlign={'center'}>
          {text}
        </PFText>
      ) : undefined}
    </Box>
  )
}

export default EmptyState

const styles = StyleSheet.create({
  imageView: {
    width: emptyStateImageWidth,
  },
})
