export const CardEventProperties = {
  card_pending_payment_help_locations: {
    // Event properties for card_pending_payment_help_clicked
    card_dashboard_header: 'card_dashboard_header',
    card_pot_detail_header: 'card_pot_detail_header',
    card_pot_detail_page_installment: 'card_pot_detail_page_installment',
  },
  // Event properties for card_pot_detail_page_installments_filter
  card_pot_detail_page_installments_filters: {
    installments_remaining: 'installments_remaining',
    installments_paid: 'installments_paid',
  },
} as const
