import React, {FC, ReactText, ReactElement, ReactNode} from 'react'
import {StyleSheet, View, TouchableOpacity} from 'react-native'
import {NumberProp} from 'react-native-svg'

import {smallGap, tinyGap} from 'src/designSystem/layout'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import SelectedRadioIcon from 'src/assets/icons/radio-selected.svg'
import UnSelectedRadioIcon from 'src/assets/icons/radio-unselected.svg'
import DisabledUnSelectedRadioIcon from 'src/assets/icons/radio-disabled-unselected.svg'
import DisabledSelectedRadioIcon from 'src/assets/icons/radio-disabled-selected.svg'
import {TextVariants} from 'src/designSystem/typography'
import {ColorVariants, NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  PFStatusPill,
  PFStatusPillPropsSolid,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'

const handlePress = (
  toggleChecked: (checked: boolean) => void,
  checked: boolean,
  disabled: boolean,
): void => {
  if (!disabled) {
    toggleChecked?.(!checked)
  }
}

export type RadioButtonProps = {
  id: string
  testID?: string
  text: ReactText | ReactElement
  subText?: ReactText
  subTextColorVariant?: 'primary' | 'disabled'
  toggleChecked: (checked: boolean) => void
  isChecked?: boolean
  isDisabled?: boolean
  size?: RadioButtonSizes
  statusPillProps?: PFStatusPillPropsSolid
}

export type RadioButtonSizes = 'medium'

const radioButtonSizesMap: {[key in RadioButtonSizes]: number} = {
  medium: 24,
}

type RadioButtonIconProps = {
  id: string
  isChecked: boolean
  isDisabled: boolean
  size: NumberProp
  testID?: string
}

const getRadioIcon = (props: RadioButtonIconProps): ReactNode => {
  const {id, isChecked, isDisabled, size, testID} = props
  const radioButtonProps = {
    height: size,
    width: size,
  }

  if (!isDisabled) {
    return isChecked ? (
      <PFSvg id={id} SvgUri={SelectedRadioIcon} svgProps={radioButtonProps} testID={testID} />
    ) : (
      <PFSvg id={id} SvgUri={UnSelectedRadioIcon} svgProps={radioButtonProps} testID={testID} />
    )
  }
  return isChecked ? (
    <PFSvg id={id} SvgUri={DisabledSelectedRadioIcon} svgProps={radioButtonProps} testID={testID} />
  ) : (
    <PFSvg
      id={id}
      SvgUri={DisabledUnSelectedRadioIcon}
      svgProps={radioButtonProps}
      testID={testID}
    />
  )
}

const RadioButton: FC<RadioButtonProps> = (props) => {
  const {
    id,
    testID,
    text,
    subText,
    subTextColorVariant,
    toggleChecked,
    statusPillProps,
    isChecked = false,
    isDisabled = false,
    size = 'medium',
  } = props
  const sizeValue = radioButtonSizesMap[size]

  const textVariant: TextVariants = !isChecked ? 'p' : 'p_semibold'
  const textColor: ColorVariants = !isDisabled ? 'textPrimary' : 'textDisabled'

  let subTextColor = textColor
  if (subTextColorVariant === 'primary' && !isDisabled) {
    subTextColor = 'textPrimary'
  } else if (subTextColorVariant === 'disabled') {
    subTextColor = 'textDisabled'
  }

  const radioButtonIconProps: RadioButtonIconProps = {
    id,
    isChecked,
    isDisabled,
    size: sizeValue,
    testID,
  }

  return (
    <TouchableOpacity
      disabled={isDisabled}
      onPress={(): void => handlePress(toggleChecked, isChecked, isDisabled)}
    >
      <Box direction="row">
        <Box direction="column">
          {statusPillProps ? (
            // Pushes the radio button down to align with the text exact height of status pill
            <Box marginBottom="tiny" boxStyle={{opacity: 0}}>
              <PFStatusPill text=" " />
            </Box>
          ) : null}
          {getRadioIcon(radioButtonIconProps)}
        </Box>

        <View style={styles.textView}>
          <View style={styles.text}>
            {statusPillProps ? (
              <Box marginBottom="tiny" testID="RadioButton-StatusPill">
                <PFStatusPill
                  text={statusPillProps.text}
                  color={statusPillProps.color ?? NamedColors.PRODUCT_BLUE}
                  fontColor={statusPillProps.fontColor ?? NamedColors.WHITE}
                />
              </Box>
            ) : null}
            {React.isValidElement(text) ? (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              React.cloneElement<any>(text, {isDisabled, variant: textVariant, color: textColor})
            ) : (
              <PFText variant={textVariant} color={textColor} disabled={isDisabled}>
                {text}
              </PFText>
            )}
          </View>
          {subText ? (
            <PFText variant={'p_sm'} color={subTextColor} disabled={isDisabled}>
              {subText}
            </PFText>
          ) : null}
        </View>
      </Box>
    </TouchableOpacity>
  )
}

export {RadioButton}

const styles = StyleSheet.create({
  text: {
    marginBottom: tinyGap,
    minHeight: 24, // The text height should be at least the icon's size.
  },
  textView: {
    flex: 1,
    marginLeft: smallGap,
  },
})
