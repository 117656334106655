import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {merge} from 'lodash'
import moment from 'moment'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Calendar} from 'react-native-calendars'
import {MarkingProps} from 'react-native-calendars/src/calendar/day/marking'
import {MarkedDates} from 'react-native-calendars/src/types'

import {
  CardPaymentRescheduleDate,
  CardPaymentRescheduleDateRestriction,
  CardPaymentRescheduleReason,
} from '@possible/cassandra/src/types/consumer'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import CalendarCash from 'src/assets/illustrations/CalendarCash.svg'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {disabledText, selectedDateColor} from 'src/designSystem/semanticColors'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {apiParseDateFormat} from 'src/lib/time_util'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  CardAccountReschedulePaymentDocument,
  CardActiveWithAutopayPaymentsDocument,
} from 'src/products/card/Dashboard/CardRescheduleUpcomingPayment/CardRescheduleUpcomingPayment.gqls'
import {useCards} from 'src/products/card/hooks/useCards'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

export const CardRescheduleUpcomingPayment = (): JSX.Element => {
  const {nextPaymentDates, autopayEnabled: isAutopayEnabled, refetchPollingCardsQuery} = useCards()
  const {t} = useTranslation('CardRescheduleUpcomingPayment')

  usePageViewedAnalytics({
    eventName: CardEvents.card_reschedule_payments_screen_viewed,
    eventCategory: AppEvents.Category.Card,
    eventArgs: {
      paymentMode: isAutopayEnabled ? 'autopay_full_balance' : 'manual_pay',
    },
  })
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const today = moment().format(apiParseDateFormat)
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>(undefined)
  const [markedDates, setMarkedDates] = React.useState<MarkedDates>({})

  const [isOverlayVisible, setShowOverlay] = React.useState<boolean>(false)
  const [isErrorOverlayVisible, setShowErrorOverlay] = React.useState<boolean>(false)

  const {data: upcomingPayments, loading: isLoading} = useCassandraQuery(
    CardActiveWithAutopayPaymentsDocument,
  )
  const schedule = upcomingPayments?.me.cardAccounts.active?.payments?.schedule?.[0]
  const allowedDates = schedule?.paymentAllowedRescheduleDates?.allowed || []
  const [firstAllowedRescheduleDate, lastAllowedRescheduleDate] = [
    allowedDates?.[0]?.date,
    allowedDates?.[allowedDates.length - 1]?.date,
  ]
  const [reschedulePayment] = useCassandraMutation(CardAccountReschedulePaymentDocument)

  const getRestrictedDates = (date: CardPaymentRescheduleDate): boolean =>
    date.restriction !== CardPaymentRescheduleDateRestriction.NoRestriction

  const formatAndMarkDates = (
    dates: Array<CardPaymentRescheduleDate | string>,
    formatFn: (date: CardPaymentRescheduleDate | string) => MarkingProps,
  ): MarkedDates =>
    dates.reduce<MarkedDates>((acc, date) => {
      const isStringDate = typeof date === 'string'
      const formattedDate = moment.utc(isStringDate ? date : date.date).format(apiParseDateFormat)
      acc[formattedDate] = formatFn(date)
      return acc
    }, {})

  const allowedDatesMarked = formatAndMarkDates(allowedDates, () => ({
    disabled: false,
    selectedTextColor: 'black',
  }))

  const restrictedAllowedDatesMarked = formatAndMarkDates(
    allowedDates?.filter(getRestrictedDates),
    () => ({
      customStyles: {
        container: {
          backgroundColor: NamedColors.BLUSH,
        },
      },
    }),
  )

  const nextPaymentDatesMarked = formatAndMarkDates(nextPaymentDates, () => ({
    customStyles: {
      container: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: selectedDateColor,
      },
    },
  }))

  const mergeMarkedDatesStyles = (...markedDatesObjects: MarkedDates[]): MarkedDates =>
    markedDatesObjects.reduce<MarkedDates>((acc, obj) => {
      if (obj) {
        Object.keys(obj).forEach((date) => {
          if (!acc[date]) {
            acc[date] = obj[date]
          } else {
            // using lodash merge to deep merge instead of shallow merge
            acc[date] = merge({}, acc[date], obj[date])
          }
        })
      }
      return acc
    }, {})

  const getMarkedDates: MarkedDates = mergeMarkedDatesStyles(
    nextPaymentDatesMarked,
    allowedDatesMarked,
    restrictedAllowedDatesMarked,
  )
  useEffect(() => {
    setMarkedDates(getMarkedDates)
  }, [upcomingPayments])

  return !isLoading ? (
    <Page
      variant="generic"
      title={t('needMoreTimeTitle')}
      description={t('initiallyScheduledFor', {
        date: moment.utc(nextPaymentDates[0]).format('MMMM Do'),
      })}
      smallTopGap
      buttonProps={{
        primary: {
          testID: 'ReschedulePaymentButton',
          disabled: !selectedDate,
          text: t('saveRescheduledPayment'),
          onPress: async (): Promise<void> => {
            TrackAppEvent(
              CardEvents.card_reschedule_payments_screen_reschedule_cta_clicked,
              AppEvents.Category.Card,
            )
            try {
              const res = await reschedulePayment({
                variables: {
                  input: {
                    cardAccountId: upcomingPayments?.me.cardAccounts.active?.id ?? '',
                    paymentScheduleId: schedule?.id ?? '',
                    newPaymentDate: selectedDate ?? '',
                    reason: CardPaymentRescheduleReason.NotSpecified,
                    requestedMultipleReschedules: false,
                  },
                },
              })
              if (res.errors) {
                setShowErrorOverlay(true)
              } else {
                setShowOverlay(true)
              }
            } catch (e) {
              setShowErrorOverlay(true)
              Log.error(e, 'Error rescheduling payment')
            }
          },
        },
        type: 'singleButton',
      }}
    >
      <Calendar
        testID="ReschedulePaymentCalendar"
        markingType="custom"
        current={selectedDate}
        theme={{
          selectedDayBackgroundColor: 'white',
          textDisabledColor: disabledText,
          textMonthFontWeight: 'bold',
          todayTextColor: allowedDates.find((date) => date.date === today) ? 'black' : disabledText,
        }}
        calendarWidth={'100%'}
        renderArrow={(direction): React.ReactNode => {
          return (
            <SvgIcon
              name={direction === 'left' ? 'chevronLeft' : 'chevronRight'}
              colorVariant={'info'}
            />
          )
        }}
        minDate={firstAllowedRescheduleDate}
        maxDate={lastAllowedRescheduleDate}
        markedDates={mergeMarkedDatesStyles(markedDates, {
          [selectedDate ?? '']: {
            selected: true,
            customStyles: {container: {borderWidth: 1, borderColor: selectedDateColor}},
          },
        })}
        onDayPress={(data): void => {
          TrackAppEvent(
            CardEvents.card_reschedule_payments_calendar_date_selected,
            AppEvents.Category.Card,
          )
          setSelectedDate(data?.dateString)
          setMarkedDates(
            mergeMarkedDatesStyles(markedDates, {
              [moment.utc(nextPaymentDates[0]).format(apiParseDateFormat)]: {
                customStyles: {container: {borderWidth: 0}},
              },
            }),
          )
        }}
        disabledByDefault
        hideExtraDays
        disableAllTouchEventsForDisabledDays
        disableAllTouchEventsForInactiveDays
      />
      <Box align="center" paddingVertical={'small'}>
        <Box direction="row" align="center">
          <Box height={14} width={14} radius={7} border={{width: 1, color: selectedDateColor}} />
          <Box paddingHorizontal={'tiny'}>
            <PFText variant="p">Scheduled Autopay Dates</PFText>
          </Box>
        </Box>
        <Box direction="row" align="center" paddingVertical={'small'}>
          <Box height={14} width={14} radius={7} background={NamedColors.BLUSH} />
          <Box paddingHorizontal={'tiny'}>
            <PFText variant="p">Date May Result in Delinquent Accounts</PFText>
          </Box>
        </Box>
      </Box>
      <OverlaySimple
        testID="ReschedulePaymentOverlay"
        visible={isOverlayVisible}
        title={t('yourNextAutopayHasBeenRescheduled')}
        text={t('yourNextAutopayWillBeInitiated')}
        titleSize="h2"
        titleAlign="left"
        okayButtonText={t('done')}
        okayButtonSize="medium"
        hideOnOkay={true}
        onOkay={(): void => {
          setShowOverlay(false)
          refetchPollingCardsQuery()
          navigation.navigate('CardDashboard')
        }}
        padding="medium"
        image={<PFSvgContain height={141} svg={CalendarCash} />}
        content={<PFText variant="h2">{moment(selectedDate).format('MMMM Do')}</PFText>}
      />
      <OverlaySimple
        testID="ReschedulePaymentOverlayError"
        visible={isErrorOverlayVisible}
        title={t('error')}
        text={t('thereWasAnErrorReschedulingYourPayment')}
        okayButtonText={t('close')}
        titleSize="h2"
        titleAlign="left"
        okayButtonSize="medium"
        hideOnOkay={true}
        onOkay={(): void => {
          setShowErrorOverlay(false)
        }}
        padding="medium"
      />
    </Page>
  ) : (
    <Spinner />
  )
}
