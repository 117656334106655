import React from 'react'
import {StackHeaderProps} from '@react-navigation/stack'

import AppNavActions from 'src/nav/AppNavActions'
import {
  SvgIconButton,
  SvgIconButtonProps,
} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

const HeaderMenuButton = (
  props: Pick<StackHeaderProps, 'navigation'> & Pick<SvgIconButtonProps, 'size'>,
): JSX.Element => {
  const {size} = props
  return (
    <SvgIconButton
      name={'menu'}
      colorVariant={'default'}
      size={size}
      onPress={(): void => AppNavActions.openDrawer()}
    />
  )
}

export {HeaderMenuButton}
