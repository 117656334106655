import React, {FC} from 'react'
import {View, FlexStyle, ViewStyle} from 'react-native'

import {transparent} from 'src/designSystem/semanticColors'
import {BasicSizeVariants} from 'src/designSystem/types'
import Logo from 'src/assets/illustrations/PossibleLogoSmall.svg'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'

const imageSizeMap: {[key in BasicSizeVariants]: number} = {
  small: 22,
  medium: 24,
  large: 30,
}

type LogoProps = {
  size?: BasicSizeVariants
  align?: FlexStyle['alignItems']
}

const PossibleLogoComponent: FC<LogoProps> = (props) => {
  const {size, align} = props

  const imageSize = imageSizeMap[size ?? 'medium']

  const viewStyle: ViewStyle = {
    alignItems: align ?? 'center',
    backgroundColor: transparent,
    height: imageSize,
    width: imageSize,
  }

  return (
    <View style={viewStyle}>
      <PFSvg SvgUri={Logo} svgProps={{height: imageSize, width: '100%'}} />
    </View>
  )
}

export const PossibleLogo = React.memo(PossibleLogoComponent)
