import {LoanPaymentStatusCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  DateBubblePaymentDate,
  DateBubblePaymentDateStatus,
} from 'src/designSystem/components/atoms/DateBubble/DateBubble'
import {LoanCalendarTileLoanPaymentFields} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile.types'

export const convertLoanPaymentFieldsToDateBubbleDates = (props: {
  loanPaymentDates: LoanCalendarTileLoanPaymentFields[]
  isInDefault?: boolean
  isChargedOff?: boolean
  hasPaymentMethodIssue?: boolean
}): DateBubblePaymentDate[] => {
  const {loanPaymentDates, isInDefault, isChargedOff, hasPaymentMethodIssue} = props
  const paymentDateBubbles: DateBubblePaymentDate[] = loanPaymentDates
    .filter((thisPayment: LoanCalendarTileLoanPaymentFields) => {
      // we only care about failed or upcoming payments
      const includedStatusCodes = [
        LoanPaymentStatusCode.Pending,
        LoanPaymentStatusCode.Failed,
        LoanPaymentStatusCode.Suspended,
      ]
      return includedStatusCodes.indexOf(thisPayment.statusCode) !== -1
    })
    .map(
      (
        thisPayment: LoanCalendarTileLoanPaymentFields,
        index: number,
        allPayments: LoanCalendarTileLoanPaymentFields[],
      ) => {
        const previousPayment = allPayments[index - 1]
        const isNextPayment =
          (thisPayment.statusCode === LoanPaymentStatusCode.Pending ||
            thisPayment.statusCode === LoanPaymentStatusCode.InProgress) &&
          (!previousPayment ||
            (previousPayment.statusCode !== LoanPaymentStatusCode.Pending &&
              previousPayment.statusCode !== LoanPaymentStatusCode.InProgress))

        let thisDateBubbleStatus: DateBubblePaymentDateStatus
        if (isNextPayment && !hasPaymentMethodIssue) {
          // the next payment has NEXT style unless they have a funding issue, in which case it will have UPCOMING style
          // since the payment can't happen yet
          thisDateBubbleStatus = DateBubblePaymentDateStatus.NEXT
        } else {
          switch (thisPayment.statusCode) {
            case LoanPaymentStatusCode.Failed:
            case LoanPaymentStatusCode.Suspended:
              // if loan is default or charged off failed payments have FAILED bubble
              // otherwise, failed payments have WARNING bubble
              if (isInDefault || isChargedOff) {
                thisDateBubbleStatus = DateBubblePaymentDateStatus.FAILED
              } else {
                thisDateBubbleStatus = DateBubblePaymentDateStatus.WARNING
              }
              break
            case LoanPaymentStatusCode.InProgress:
            case LoanPaymentStatusCode.Pending:
              thisDateBubbleStatus = DateBubblePaymentDateStatus.UPCOMING
              break
            default:
              thisDateBubbleStatus = DateBubblePaymentDateStatus.UPCOMING
              break
          }
        }
        const thisDateBubbleDate: DateBubblePaymentDate = {
          amount: parseFloat(thisPayment.amount),
          date: new Date(thisPayment.rescheduledDate || thisPayment.originalDate),
          status: thisDateBubbleStatus,
          key: thisPayment.id,
        }
        return thisDateBubbleDate
      },
    )
  return paymentDateBubbles
}
