const fbsdk = {
  accessToken: {},
  LoginManager: {
    logOut: () => {
      /* noop */
    },
  },
  LoginButton: {},
  AppEventsLogger: {
    flush: () => {
      /* noop */
    },
    logEvent: (eventName: string, params?: any) => {
      fbq('track', eventName, params)
    },
    logPurchase: (currencyCode: string, _parameters?: any) => {
      fbq('track', 'Purchase', {
        currency: currencyCode,
      })
    },
  },
}

export default fbsdk
