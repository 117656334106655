import React from 'react'

import {
  UsePageViewedAnalyticsProps,
  usePageViewedAnalytics,
} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AdhocPaymentFailureTemplate} from 'src/products/general/AdhocPayment/AdhocPaymentFailure/AdhocPaymentFailureTemplate'
import {
  BaseTemplate,
  BaseTemplateProps,
} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type AdhocPaymentFailureContainerProps = {
  pageViewedAnalytics: UsePageViewedAnalyticsProps
  onTryAgain: () => void
  onCancel: () => void
  overrideTitle?: string
  overrideText?: string
} & Pick<BaseTemplateProps, 'onErrorBoundary'>

export const AdhocPaymentFailureContainer: React.FC<AdhocPaymentFailureContainerProps> = (
  props,
): JSX.Element => {
  const {
    pageViewedAnalytics,
    onTryAgain: handleOnTryAgain,
    onCancel: handleOnCancel,
    overrideTitle,
    overrideText,
    onErrorBoundary: handleOnErrorBoundary,
  } = props

  usePageViewedAnalytics(pageViewedAnalytics)

  return (
    <BaseTemplate onErrorBoundary={handleOnErrorBoundary}>
      <AdhocPaymentFailureTemplate
        onTryAgain={handleOnTryAgain}
        onCancel={handleOnCancel}
        overrideTitle={overrideTitle}
        overrideText={overrideText}
      />
    </BaseTemplate>
  )
}
