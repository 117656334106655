import {Store} from 'redux'
import {ChaosState} from './reducer'

/**
 * Caches the root redux store of the application using this package.
 * This is necessary so that @possible/chaos hooks can internally access store state.
 *
 * For example, using this cached store the graphql chaos hooks can just be used in
 * the @possibe/cassandra hook factory without requiring each component using an apollo
 * query to pass in chaos state.
 */
class StoreCache {
  store: Store | undefined = undefined
  chaosSliceKey: string | undefined

  setStore(params: {store: Store; chaosSliceKey: string}) {
    const {store, chaosSliceKey} = params
    this.store = store
    this.chaosSliceKey = chaosSliceKey
    console.warn('@possible/chaos Chaos Mode enabled!! This should not happen in production.')
  }
  getStore(): Store | undefined {
    return this.store
  }
  getChaosStateFromStore(): ChaosState | undefined {
    if (this.store && this.chaosSliceKey) {
      return this.store.getState()[this.chaosSliceKey] as ChaosState
    }
    return undefined
  }
}

// export a singleton instance of the cache
export const chaosReduxStoreCache = new StoreCache()
