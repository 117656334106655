import React from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'

import DateBubble, {
  DateBubblePaymentDate,
} from 'src/designSystem/components/atoms/DateBubble/DateBubble'

type DateBubbleListProps = {
  dates: DateBubblePaymentDate[]
}

/**
 * Renders a list of circles containing a month and day of that month in different states
 * defined by DateBubbleStates.
 */
const DateBubbleList: React.FC<DateBubbleListProps> = ({dates}) => {
  return (
    <View style={styles.list}>
      {dates.map((thisPaymentDate: DateBubblePaymentDate, index) => {
        let listItemStyle: StyleProp<ViewStyle>
        if (index === 0) {
          listItemStyle = styles.listItemFirst
        } else if (index === dates.length - 1) {
          listItemStyle = styles.listItemLast
        } else {
          listItemStyle = styles.listItem
        }
        return (
          <View key={thisPaymentDate.key} style={listItemStyle} testID="DateBubbleList-item">
            <DateBubble paymentDate={thisPaymentDate} />
          </View>
        )
      })}
    </View>
  )
}

export default DateBubbleList

const dateBubbleListRowGap = 28

const styles = StyleSheet.create({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginVertical: dateBubbleListRowGap / -2,
    width: '100%',
  },
  // our version of react-native doesn't support css flex gap property so I
  // set margin accordingly. react-native 0.71 supports gap so we can use that after upgrading
  listItem: {
    marginLeft: 4,
    marginRight: 4,
    marginVertical: dateBubbleListRowGap / 2,
  },
  listItemFirst: {
    marginRight: 4,
    marginVertical: dateBubbleListRowGap / 2,
  },
  listItemLast: {
    marginLeft: 4,
    marginVertical: dateBubbleListRowGap / 2,
  },
})
