import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'

/**
 * A tile to inform the user that their loan approval is delayed.
 * @example <LoanDecisioningDelayedTile />
 */
export const LoanDecisioningDelayedTile: React.FC = () => {
  const {t} = useTranslation('DashboardLoanDecisioningDelayedTile')

  return (
    <BasicTile padding="medium" align="center">
      <Box width="auto" gap="medium">
        <PFText variant="p_lg_semibold">{t('ApplicationSubmitted')}</PFText>
        <Box>
          <PFText variant="p">{t('YourApplicationIsBeingReviewed')}</PFText>
          <Box marginBottom={'small'} />
          <PFText variant="p">{t('WeWillSendYouAText')}</PFText>
        </Box>
      </Box>
    </BasicTile>
  )
}
