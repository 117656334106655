import {Image, ImageResolvedAssetSource, ImageURISource} from 'react-native'

import {isDeviceWeb} from 'src/lib/utils/platform'

export const resolveAssetSource = (image: ImageURISource): ImageResolvedAssetSource | undefined => {
  if (isDeviceWeb()) {
    return
  } else {
    return Image.resolveAssetSource(image)
  }
}
