import React, {FC, useMemo} from 'react'
import {Animated, View, ViewStyle} from 'react-native'

import PFElevation from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import AssistiveContent from 'src/designSystem/components/atoms/PFFieldBase/AssistiveContent'
import Label from 'src/designSystem/components/atoms/PFFieldBase/Label'
import RightContent from 'src/designSystem/components/atoms/PFFieldBase/RightContent'
import styles, {BoxStyle, OutlineStyle} from 'src/designSystem/components/atoms/PFFieldBase/styles'
import {PFFieldBaseProps} from 'src/designSystem/components/atoms/PFFieldBase/types'
import {textInputBackgroundColor} from 'src/designSystem/semanticColors'

const PFFieldBase: FC<PFFieldBaseProps> = (props: PFFieldBaseProps) => {
  const {
    children,
    label,
    fieldState,
    icon,
    onPressIcon,
    textButton,
    error,
    infoMessage,
    editable = true,
    animationControl,
    placeholder,
    testID,
  } = props

  const boxStyles: Animated.WithAnimatedValue<ViewStyle> = useMemo(
    () => BoxStyle(fieldState),
    [fieldState],
  )
  const outlineStyles = useMemo(() => OutlineStyle(fieldState), [fieldState])
  return (
    <View style={styles.outerWrapper} accessible={false}>
      <Animated.View style={outlineStyles}>
        <Animated.View style={boxStyles}>
          <PFElevation level={editable ? 0 : -1} backgroundColor={textInputBackgroundColor}>
            <View style={styles.fieldWrapper}>
              <View style={styles.leftContentWrapper}>
                <Label
                  label={label}
                  fieldState={fieldState}
                  animationControl={animationControl}
                  placeholder={placeholder}
                />
                {children}
              </View>
              <RightContent
                icon={icon}
                onPressIcon={onPressIcon}
                testID={testID}
                textButton={textButton}
              />
            </View>
          </PFElevation>
        </Animated.View>
      </Animated.View>
      <AssistiveContent
        label={label}
        fieldState={fieldState}
        error={error}
        infoMessage={infoMessage}
      />
    </View>
  )
}

PFFieldBase.displayName = 'PFFieldBase'

export default PFFieldBase
