import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {InterstitialList} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import GreenSilhouette from 'src/assets/illustrations/GreenSilhouette.svg'
import GreenGraph from 'src/assets/illustrations/GreenGraph.svg'
import ButtonLockup, {
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export type LoanCounterOfferOnCompleteProps = {
  onContinue: () => void | Promise<void>
  onCancel: () => void | Promise<void>
}
export type LoanCounterOfferNavigationProps = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

export type LoanCounterOfferLoanProps = {
  reason: string
  firstName?: string
  amountBorrowed?: string
  amountRequested?: string
  isAmountApprovedOverOrEqualFiftyPercent: boolean
  isOnContinueLoading: boolean
}

type Props = LoanCounterOfferLoanProps & LoanCounterOfferOnCompleteProps

/**
 * Normally we would not split templates like this, but for the time being
 * we need `LoanProcessingCounterOfferTemplateInner` to function both in a standalone
 * page for MPO, and in a tile for DashboardLoan. The tile can use this component
 * and so can `LoanProcessingCounterOfferTemplate`
 */
export const LoanProcessingCounterOfferTemplateInner = (props: Props): JSX.Element => {
  const {
    reason,
    firstName,
    isAmountApprovedOverOrEqualFiftyPercent,
    amountBorrowed,
    amountRequested,
    isOnContinueLoading,
    onContinue,
    onCancel,
  } = props
  const {t} = useTranslation('LoanCounterOffer')

  const defaultGraphicalItems = [
    {
      id: 1,
      subtext: t('LoanCounterOfferReason'),
      illustration: GreenSilhouette,
    },
    {
      id: 2,
      subtext: t('LoanCounterOfferReason2'),
      illustration: GreenGraph,
    },
  ]

  const BodyText = useMemo(() => {
    const bodyText = t(
      isAmountApprovedOverOrEqualFiftyPercent
        ? 'LoanCounterOfferOverFiftyBody'
        : 'LoanCounterOfferBelowFiftyBody',
      {
        firstName,
        amountBorrowed: convertToDollarAmt(amountBorrowed, false),
        amountRequested: convertToDollarAmt(amountRequested, false),
        reason,
      },
    )

    const testIdType = isAmountApprovedOverOrEqualFiftyPercent
      ? 'loan_counter_offer_over_fifty_body'
      : 'loan_counter_offer_below_fifty_body'

    return (
      <>
        <PFText variant="p" testID={testIdType}>
          {bodyText}
          <PFText variant="p_semibold">{t('LoanCounterOfferBodyEnd')}</PFText>
        </PFText>
        {isAmountApprovedOverOrEqualFiftyPercent ? (
          <PFText variant="p" testID="Loan-Counter-Offer-Below-Fifty-Body-End">
            {t('LoanCounterOfferBelowFiftyBodyEnd')}
          </PFText>
        ) : null}
      </>
    )
  }, [
    amountBorrowed,
    amountRequested,
    firstName,
    isAmountApprovedOverOrEqualFiftyPercent,
    reason,
    t,
  ])

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary: {
      text: t('Common:Continue'),
      onPress: async () => {
        await onContinue()
      },
      loading: isOnContinueLoading,
      disabled: isOnContinueLoading,
      testID: 'loan_counter_offer_continue_button',
      width: '100%',
    },
    secondary: {
      buttonText: t('Common:NotNow'),
      onPress: async () => {
        await onCancel()
      },
      testID: 'loan_counter_offer_not_now_button',
    },
  }

  return (
    <Box fill="horizontal">
      <Box marginBottom="medium">
        <PFText variant="h2" testID="Loan-Counter-Offer-Title">
          {t(
            isAmountApprovedOverOrEqualFiftyPercent
              ? 'LoanCounterOfferOverFiftyTitle'
              : 'LoanCounterOfferBelowFiftyTitle',
            {
              firstName,
              amountBorrowed: convertToDollarAmt(amountBorrowed, false),
            },
          )}
        </PFText>
      </Box>
      <Box marginBottom="medium">
        <PFStatusPill text={t('LimitedTimeOffer')} color="disabled" fontColor="black" />
      </Box>
      <Box marginBottom="small">{BodyText}</Box>
      <Box marginBottom="small">
        <InterstitialList items={defaultGraphicalItems} illustrationJustify="flex-start" />
      </Box>

      <Box fill="horizontal" width="100%">
        <ButtonLockup {...buttonProps} />
      </Box>
    </Box>
  )
}

export const LoanProcessingCounterOfferTemplate = (props: Props): JSX.Element => {
  return (
    <Page variant="generic" smallTopGap testID="Loan-Counter-Offer-Page">
      <LoanProcessingCounterOfferTemplateInner {...props} />
    </Page>
  )
}
