import React from 'react'
import {useTranslation, Trans} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'

export type AccountsWithPossibleTemplateProps = {
  onContinue: () => void
}
export const AccountsWithPossibleTemplate: React.FC<AccountsWithPossibleTemplateProps> = (
  props,
) => {
  const {onContinue} = props

  const {t} = useTranslation('AccountsWithPossible')
  return (
    <Page
      title={t('PrimaryAccountAndPaymentMethodsTitle')}
      variant={'generic'}
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Understand'),
          onPress: onContinue,
        },
      }}
    >
      <Box>
        <PFText variant="h3" color={NamedColors.PRODUCT_BLUE}>
          {t('YourPrimaryAccount')}
        </PFText>
        <PFText variant="p">{t('PrimaryAccountInfo')}</PFText>
      </Box>
      <Box marginTop={'large'}>
        <PFText variant="h3" color={NamedColors.PRODUCT_BLUE}>
          {t('AdditionalPaymentMethods')}
        </PFText>
        <PFText variant="p">{t('AdditionalPaymentMethodsInfo')}</PFText>
      </Box>
      <Box marginVertical={'large'}>
        <PFText variant="p">
          <Trans
            t={t}
            i18nKey="RememberLinkedAccountNote"
            components={{
              bold: <PFText variant="p_semibold" />,
            }}
          />
        </PFText>
      </Box>
    </Page>
  )
}
