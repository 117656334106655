import {
  LinkedAccountStatusCode,
  LinkedAccountType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {useQueryFactory} from '@possible/cassandra/src/utils'
import {
  AggregatorAccountSelectionForPrimaryAllAccountsDocument,
  AggregatorAccountSelectionForPrimaryAllAccountsQuery,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForPrimaryAccount/queries/AggregatorAccountSelectionForPrimaryAllAccounts/AggregatorAccountSelectionForPrimaryAllAccounts.gqls'

/**
 * Query hook to fetch all bank account LinkedAccounts for primary account selection.
 */
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useAggregatorAccountSelectionForPrimaryAccountQuery = useQueryFactory(
  AggregatorAccountSelectionForPrimaryAllAccountsDocument,
  (
    from: AggregatorAccountSelectionForPrimaryAllAccountsQuery,
  ): {
    linkedCheckingAccounts: UseAggregatorAccountSelectionForPrimaryAccountResultLinkedAccount[]
  } => {
    const linkedCheckingAccounts: UseAggregatorAccountSelectionForPrimaryAccountResultLinkedAccount[] =
      from.me.bankAccounts.all
        ? from.me.bankAccounts.all.filter((account) => {
            return (
              account.status === LinkedAccountStatusCode.LinkedInUse &&
              account.type === LinkedAccountType.Checking
            )
          })
        : []

    return {
      linkedCheckingAccounts,
    }
  },
)

export type UseAggregatorAccountSelectionForPrimaryAccountResultLinkedAccount = NonNullable<
  AggregatorAccountSelectionForPrimaryAllAccountsQuery['me']['bankAccounts']['all']
>[0]
