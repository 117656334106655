import React, {ComponentType} from 'react'
import {useDebouncedCallback} from 'use-debounce'

import {doubleClickPreventionDebounceTime} from 'src/designSystem/timings'

type WithDoubleClickPreventionProps = {
  onPress?: () => void
}

const withDoubleClickPrevention = <P extends object>(
  WrappedComponent: ComponentType<P>,
): React.FC<P & WithDoubleClickPreventionProps> => {
  return (props) => {
    const {onPress, ...rest} = props

    // useDebouncedCallback will not accept undefined
    const handlePress = () => {
      onPress?.()
    }

    const debouncedPress = useDebouncedCallback(handlePress, doubleClickPreventionDebounceTime, {
      leading: true,
      trailing: false,
    })

    return <WrappedComponent {...(rest as P)} onPress={debouncedPress} />
  }
}

export default withDoubleClickPrevention
