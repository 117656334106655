import React from 'react'
import {useTranslation} from 'react-i18next'

import CalendarCash from 'src/assets/illustrations/CalendarCash.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {NamedColors} from 'src/designSystem/colors'

export type AdhocPaymentSuccessTemplateProps = {
  onOkay: () => void
  paymentAmount: string
  executeDate: string
  executeDateFormat?: string
  overrideTitle?: string
}

const AdhocPaymentSuccessTemplate: React.FC<AdhocPaymentSuccessTemplateProps> = (
  props,
): JSX.Element => {
  const {onOkay, paymentAmount, executeDate, executeDateFormat, overrideTitle} = props

  const {t} = useTranslation(['AdhocPayment', 'Common'])

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:OK'),
          onPress: () => onOkay(),
          testID: 'adhoc-payment-OK-button',
        },
      }}
      variant={'generic'}
      title={overrideTitle ?? t('YourPaymentIsProcessing')}
      smallTopGap
    >
      <>
        <Box align={'center'} paddingVertical={'enormous'} paddingLeft={'small'}>
          <PFSvgContain height={141} svg={CalendarCash} />
        </Box>
        <PFText textAlign={'center'} variant={'d2'}>{`${convertToDollarAmt(
          paymentAmount,
        )}`}</PFText>
        <Box align={'center'} gap={'tiny'} paddingTop={'small'}>
          <PFText variant={'p_lg'} color={NamedColors.SILVER}>
            {t('WillBePaidOn')}
          </PFText>
          <PFText variant={'h3'}>
            {formatDate(executeDate, humanReadableDateDayMonth2, executeDateFormat)}
          </PFText>
        </Box>
      </>
    </Page>
  )
}

export {AdhocPaymentSuccessTemplate}
