import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivationAutopayScheduleReduxContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleReduxContainer'
import {WorkflowScreenProps} from 'src/workflows/types'

type CardActivationAutopayScheduleWorkflowContainerProps = WorkflowScreenProps &
  StackScreenProps<MainStackParamList> & {
    onPressSecondary: () => void
  }

export const CardActivationAutopayScheduleWorkflowContainer: FC<
  CardActivationAutopayScheduleWorkflowContainerProps
> = ({onPressSecondary: handleOnPressSecondary, onScreenComplete, navigation}) => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_autopay_schedule_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnComplete = (): void => {
    TrackAppEvent(CardEvents.enable_auto_pay_action, AppEvents.Category.Card, {
      source: 'ActivationFlow',
    })

    onScreenComplete()
  }

  return (
    <CardActivationAutopayScheduleReduxContainer
      /* This is likely be removed in ENG-16749
       *
       * https://possible.atlassian.net/browse/ENG-16749
       */
      navigation={navigation}
      onComplete={handleOnComplete}
      onPressSecondary={handleOnPressSecondary}
    />
  )
}
