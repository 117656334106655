import React from 'react'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {NamedColors} from 'src/designSystem/colors'
import {
  ErrorNotice,
  ErrorNoticeProps,
} from 'src/designSystem/components/organisms/ErrorNotice/ErrorNotice'
import {BoxProps} from 'src/designSystem/components/atoms/Box/Box'

export type ErrorTileProps = ErrorNoticeProps & {
  transparentBackground?: boolean
}

/**
 * Generic error tile to inform the user that something went wrong. Works well with <ErrorBoundary> component
 * to detect component failures and handle gracefully.
 *
 * @example <ErrorTile title='Some title' onContactUs={() => {}} />
 */
const ErrorTile = ({
  transparentBackground: transparentBackground,
  testID = 'ErrorTile',
  ...props
}: ErrorTileProps): JSX.Element => {
  const tileStyles: BoxProps = {}

  if (transparentBackground) {
    tileStyles.background = NamedColors.TRANSPARENT
  }

  return (
    <BasicTile padding="medium" styles={tileStyles}>
      <ErrorNotice testID={testID} {...props} />
    </BasicTile>
  )
}

export default ErrorTile
