/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useState, useEffect} from 'react'
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber'
import {useTranslation} from 'react-i18next'

const phoneUtil = PhoneNumberUtil.getInstance()

type CountdownTimer = {
  seconds: number
  isCounting: boolean
  startTimer: () => void
  resetTimer: () => void
  handleResetCodeTimer: () => void
  localizedText: string
}

const useCountdownTimer = (): CountdownTimer => {
  const [seconds, setSeconds] = useState(60)
  const [isCounting, setIsCounting] = useState(false)
  const {t} = useTranslation('PhoneNumberEntry')

  const resetTimer = (): void => {
    setSeconds(60)
    setIsCounting(false)
  }

  const startTimer = (): void => {
    setIsCounting(true)
  }

  const handleResetCodeTimer = (): void => {
    resetTimer()
    startTimer()
  }

  useEffect(() => {
    let timer

    if (isCounting && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)
    }

    return (): void => {
      clearInterval(timer)
    }
  }, [isCounting, seconds])

  const localizedText = t('ResendCode', {seconds: seconds.toString().padStart(2, '0')})

  useEffect(() => {
    if (seconds === 0) {
      resetTimer()
    }
  }, [seconds])

  return {
    seconds,
    isCounting,
    startTimer,
    resetTimer,
    handleResetCodeTimer,
    localizedText,
  }
}

const formatPhoneNumber = (phoneNumber: string): string => {
  const phoneNumberParsed = phoneUtil.parse(phoneNumber, 'US')

  const phoneNumberDisplayFormatted: string = phoneUtil.format(
    phoneNumberParsed,
    PhoneNumberFormat.INTERNATIONAL,
  )

  return phoneNumberDisplayFormatted
}

export {useCountdownTimer, formatPhoneNumber}
