import React, {FC, useCallback} from 'react'

import {LoanAmountSelectionGQLContainer} from 'src/products/loans/LoanAmountSelection/LoanAmountSelectionGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type LoanAmountSelectionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the LoanAmountSelection screen within Workflows.
 * @param props
 * @returns
 */

const LoanAmountSelectionWorkflowContainer: FC<LoanAmountSelectionWorkflowContainerProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props
  /**
   * When user finishes selecting their loan amount we inform Workflows
   * that the prereq has been fulfilled.
   */
  const handleOnPreReqFulfilled = useCallback(async (): Promise<void> => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return <LoanAmountSelectionGQLContainer onPreReqFulfilled={handleOnPreReqFulfilled} />
}

export {LoanAmountSelectionWorkflowContainer}
