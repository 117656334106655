import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CohortUserTypeQueryQueryVariables = Types.Exact<{[key: string]: never}>

export type CohortUserTypeQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        address?: {__typename?: 'Address'; state?: string | null} | null
      } | null
    } | null
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
    onboarding?: {
      __typename?: 'OnboardingInfo'
      loan?: {__typename?: 'LoanOnboardingInfo'; stateSelected?: string | null} | null
    } | null
  }
}

export const CohortUserTypeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CohortUserTypeQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'state'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'onboarding'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'stateSelected'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CohortUserTypeQueryQuery, CohortUserTypeQueryQueryVariables>
