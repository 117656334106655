import {FlexAlignType, FlexStyle} from 'react-native'

import {Color, SizeVariants} from 'src/designSystem/types'
import {Extends} from 'src/lib/utils/typesUtil'

export type PositionType = Extends<FlexStyle['position'], 'absolute' | 'relative'>
export type DirectionType = Extends<
  FlexStyle['flexDirection'],
  'row' | 'row-reverse' | 'column' | 'column-reverse'
>
export type BoxOverflowType = Extends<FlexStyle['overflow'], 'hidden' | 'visible'>
export type BoxAlignType = 'start' | 'center' | 'end'
export type BoxJustifyType = 'start' | 'center' | 'end' | 'around' | 'between'
export type BoxRadiusType = 'none' | SizeVariants | 'round'

export type BoxFillStyleType = 'horizontal' | 'vertical'
export type BoxBorderType = {
  width: number
  color: Color
}

export type BoxSpecificRadiusType = {
  topLeft?: BoxRadiusType | number
  topRight?: BoxRadiusType | number
  bottomLeft?: BoxRadiusType | number
  bottomRight?: BoxRadiusType | number
}

export type ComputedAlignType = Extends<FlexAlignType, 'center' | 'flex-start' | 'flex-end'>
export type ComputedJustifyType = Extends<
  FlexStyle['justifyContent'],
  'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between'
>

export const boxAlignToFlexMap: {[key in BoxAlignType]: ComputedAlignType} = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
}

export const boxJustifyToFlexMap: {[key in BoxJustifyType]: ComputedJustifyType} = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
  around: 'space-around',
  between: 'space-between',
}

export const boxRadiusMap: {[key in BoxRadiusType]: number} = {
  none: 0,
  tiny: 2,
  little: 4,
  small: 8,
  medium: 16,
  large: 32,
  enormous: 64,
  round: 2000,
}
