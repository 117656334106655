import React, {FC} from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {useTranslation} from 'react-i18next'

import {drawerHeaderBackground, menuCloseIconColor} from 'src/designSystem/semanticColors'
import AppNavActions from 'src/nav/AppNavActions'
import {menuHeaderHeight} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type Props = {
  name?: string
}

const MenuHeader: FC<Props> = (props) => {
  const {name} = props
  const {t} = useTranslation('LeftDrawer')

  const containerBoxProps: BoxProps = {
    height: menuHeaderHeight,
    background: drawerHeaderBackground,
    justify: 'end',
    paddingBottom: 'small',
    paddingTop: 'medium',
    paddingLeft: 'small',
    fill: 'horizontal',
  }

  const logoBoxProps: BoxProps = {
    direction: 'row',
    align: 'center',
    paddingBottom: 'medium',
  }

  return (
    <SafeAreaView edges={['top']} style={{backgroundColor: drawerHeaderBackground}}>
      <Box {...containerBoxProps}>
        <Box {...logoBoxProps}>
          <NameLogo size="medium" />
          <TouchableWithoutFeedback onPress={(): void => AppNavActions.closeDrawer()}>
            <View style={styles.close}>
              <SvgIcon
                name={'close'}
                colorVariant={'custom'}
                customColor={menuCloseIconColor}
                size={'small'}
              />
            </View>
          </TouchableWithoutFeedback>
        </Box>
        <PFText variant={'p_lg_semibold'}>{t('Hi')}</PFText>
        {name ? <PFText variant={'p_lg_semibold'}>{name}</PFText> : null}
      </Box>
    </SafeAreaView>
  )
}

export default MenuHeader

const styles = StyleSheet.create({
  close: {
    left: 0,
    position: 'absolute',
    top: 0,
  },
})
