import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'
import {View} from 'react-native-animatable'

import Button, {ButtonMode} from 'src/designSystem/components/atoms/Button/Button'

type SocialButtonVariants = 'google'

type SocialButtonProps = {
  variant: SocialButtonVariants
  mode?: ButtonMode
  busy: boolean
  onLogin: (variant: SocialButtonVariants) => Promise<void>
  testID?: string
  disabled: boolean
}

const SocialButton: FC<SocialButtonProps> = (props) => {
  const {variant, mode = 'primary', busy, onLogin, testID, disabled} = props

  const {t} = useTranslation('GoogleLogin')

  const getText = () => {
    return t('LoginWithGoogle')
  }

  return (
    <View style={styles.container}>
      <Button
        mode={mode}
        disabled={disabled || busy}
        loading={busy}
        size="large"
        icon={{
          name: variant,
          colorVariant: 'default',
        }}
        onPress={() => onLogin(variant)}
        width="100%"
        testID={testID}
      >
        {getText()}
      </Button>
    </View>
  )
}
export default SocialButton

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
