import {CardAccount, Loan} from '@possible/cassandra/src/types/types.mobile.generated'
import {RouteProp, useRoute} from '@react-navigation/native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'
import {ProductsQueryApplicationsType} from 'src/workflows/types'

const InProgressStatusTypeNames: (
  | Loan['status']['__typename']
  | CardAccount['status']['__typename']
)[] = ['PendingCardAccountStatus', 'ApprovedCardAccountStatus']

const findInProgressApplications = (
  applications: ProductsQueryApplicationsType[] = [],
  statuses = InProgressStatusTypeNames,
): ProductsQueryApplicationsType[] => {
  return applications.filter((app) => statuses.includes(app.product.status.__typename))
}

export const isNewUser = (applications: ProductsQueryApplicationsType[]): boolean => {
  const inProgressApplications = findInProgressApplications(applications)
  const numberOfActiveAndClosedApplications = applications.length - inProgressApplications.length
  return numberOfActiveAndClosedApplications === 0
}

type UseIsInCardsOnboardingType = {
  isInCardsOnboarding: boolean
  isLoading: boolean
}

export const useIsInCardsOnboarding = (): UseIsInCardsOnboardingType => {
  const route = useRoute<RouteProp<MainStackParamList, 'ProductHub'>>()
  const {canAccessCards, isLoading: isLoadingCanAccessCards} = useCanAccessCards()
  const workflowsData = useWorkflows()

  if (workflowsData.status !== 'READY' || isLoadingCanAccessCards) {
    return {
      isInCardsOnboarding: false,
      isLoading: true,
    }
  }

  // Is in loan grad onboarding (doesn't have selectedOffer yet)?
  const isInLoanGradFlow = route?.params?.isInNonInitialOnboardingFlow === true

  // If the user has offersMinPreReqs, they are in the SignupFlow
  const isInSignupFlow = workflowsData.unmetMinPreReqs && workflowsData.unmetMinPreReqs.length > 0

  // If the user is new and has a card offer they are in the OfferFlow
  // (Loan grads can have a card offer without being in the onboarding flow)
  const isInCardOfferFlow =
    isNewUser(workflowsData.applications) &&
    workflowsData.offers.some(
      (offer) => offer.__typename === 'CardAccountOfferInfo' && offer.offers?.length > 0,
    )

  // If the user has a card application with status of ‘Pending’ or ‘Approved’ they are in the Activation flow
  const isInCardActivationFlow = findInProgressApplications(workflowsData.applications).length > 0

  const isInCardsOnboarding =
    canAccessCards &&
    (isInLoanGradFlow || isInSignupFlow || isInCardOfferFlow || isInCardActivationFlow)

  return {
    isInCardsOnboarding,
    isLoading: false,
  }
}
