import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserEnvQueryVariables = Types.Exact<{[key: string]: never}>

export type UserEnvQuery = {
  __typename?: 'Query'
  getUserEnv: {__typename: 'UserEnv'; bankingAggregatorId?: string | null; userId: string}
}

export const UserEnvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UserEnv'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getUserEnv'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'bankingAggregatorId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserEnvQuery, UserEnvQueryVariables>
