import React, {FC, ReactNode} from 'react'
import {StyleSheet, View, ScrollView} from 'react-native'

import StorybookToggle from 'src/products/general/VersionFooter/StorybookToggle'
import EnvSelector from 'src/products/general/VersionFooter/EnvSelector'
import {isDeviceNotWeb} from 'src/lib/utils/platform'
import MrBranchSelector from 'src/products/general/VersionFooter/MrBranchSelector'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {AppScreenSelector} from 'src/products/general/VersionFooter/AppScreenSelector/AppScreenSelector'
import {ChaosModeSettings} from 'src/products/general/VersionFooter/ChaosModeSettings/ChaosModeSettings'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'

type Props = {
  isDevMode?: boolean
}

const styles = StyleSheet.create({
  storybook: {
    alignSelf: 'center',
    marginBottom: 2,
    marginTop: 5,
  },
})

/**
 * Development tools
 * There are two different kinds of dev:
 * * dev the environment (dev/staging/prod)
 * * devMode when the user taps the screen 10 times
 * Show the dev tools if in devMode or on dev environment
 */
export const VersionFooterDevTools: FC<Props> = ({isDevMode = false}) => {
  const showDevTools = __DEV__ || isDevMode
  if (!showDevTools) {
    return null
  }

  let mobileOnly: ReactNode = null
  if (isDeviceNotWeb()) {
    mobileOnly = (
      <Box testID="Branch_Selector_Wrapper" border={{color: NamedColors.SILVER, width: 1}}>
        <Box direction="row" gap={'small'} justify="center" align="center">
          <PFText variant="p_sm" textAlign="center" testID="label_dev_mode">
            Dev Mode On
          </PFText>
          <Box border={{color: NamedColors.SILVER, width: 1}}>
            <MrBranchSelector key={'branchselector'} />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <View testID="Version_Footer_Dev_tools">
      <ScrollView>
        {mobileOnly}
        <AppScreenSelector />
        <EnvSelector />
        <View style={styles.storybook}>
          <StorybookToggle />
        </View>
        <ChaosModeSettings />
      </ScrollView>
    </View>
  )
}
