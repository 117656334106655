import {ReactNativeClient} from '@amplitude/analytics-types'
import DeviceInfo from 'react-native-device-info'

import {EnvironmentType} from '@possible/cassandra'

import {EVENT_SERVICE_LOGGING} from 'src/config'
import {ampli} from 'src/lib/Analytics/ampli'
import {newAmplitude, setIdentityObject} from 'src/lib/Analytics/amplitude'
import EventStreamSingleton, {EventStream} from 'src/lib/EventStream/EventStream'
import {EventStreamAnalyticEvent, EventStreamMetadata} from 'src/lib/EventStream/EventStream.types'
import Log from 'src/lib/loggingUtil'
import {EventStreamServiceBase} from 'src/lib/Services/EventStreamServiceBase'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {isDeviceIOS} from 'src/lib/utils/platform'

const shouldLogToConsole = EVENT_SERVICE_LOGGING

const amplitudeApiKeyProd = '47cf9c642eeb2c284d0d38b544d11dc5'
const amplitudeApiKeyDev = '8f47b924543db006ac7b59f113902605'

/**
 * AmplitudeService is a wrapper around the Amplitude SDK.
 * It receives `analytic` events from the EventStream and
 * forwards them to Amplitude.
 */
export class AmplitudeService extends EventStreamServiceBase {
  _amplitudeClient: ReactNativeClient | undefined

  _currentMetadata: EventStreamMetadata = {}

  init = async (): Promise<void> => {
    if (this._amplitudeClient) {
      return
    }

    const key =
      (await getEnvironment()) !== EnvironmentType.Prod ? amplitudeApiKeyDev : amplitudeApiKeyProd

    this._amplitudeClient = newAmplitude(key)
    ampli.load({client: {instance: this._amplitudeClient}})
  }

  get amplitudeClient(): ReactNativeClient | undefined {
    return this._amplitudeClient
  }

  metadataUpdated = (metadata: Partial<EventStreamMetadata>): void => {
    for (const [key, value] of Object.entries(metadata)) {
      this._currentMetadata[key] = value
      if (!this._amplitudeClient) {
        return
      }

      if (key === 'userId') {
        this._amplitudeClient.setUserId(value)
        ampli.identify(value)
      } else {
        setIdentityObject(key, value, this._amplitudeClient)
      }
    }
  }

  analyticListener = (event: EventStreamAnalyticEvent): void => {
    if (!event.destinations?.includes('amplitude')) {
      return
    }

    if (shouldLogToConsole) {
      Log.log(`AmplitudeService: ${event.name}`)
    }

    const advertisingId = isDeviceIOS()
      ? {
          idfv: this._currentMetadata.deviceId,
        }
      : {}

    this._amplitudeClient?.logEvent(
      event.name,
      {
        category: event.category,
        args: event.data,
      },
      {
        user_id: this._currentMetadata.userId,
        ...advertisingId,
        app_version: DeviceInfo.getVersion(),
      },
    )
  }

  startListening(eventStream: EventStream = EventStreamSingleton): void {
    super.startListening(eventStream)
    eventStream.addListener('analytic', this.analyticListener)
  }

  stopListening(eventStream: EventStream = EventStreamSingleton): void {
    super.stopListening(eventStream)
    eventStream.removeListener('analytic', this.analyticListener)
  }
}
