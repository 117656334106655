import React, {FC, isValidElement, ReactElement, ReactNode} from 'react'
import {StyleSheet, TouchableOpacity} from 'react-native'

import {
  alertErrorBackground,
  alertErrorBorder,
  alertErrorDescription,
  alertErrorIconColor,
  alertErrorTitle,
  alertInfoBackground,
  alertInfoBorder,
  alertInfoDescription,
  alertInfoIconColor,
  alertInfoTitle,
  alertSuccessBackground,
  alertSuccessBorder,
  alertSuccessDescription,
  alertSuccessIconColor,
  alertSuccessTitle,
  alertWarningBackground,
  alertWarningBorder,
  alertWarningDescription,
  alertWarningIconColor,
  alertWarningTitle,
} from 'src/designSystem/semanticColors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {alertBorderWidth, alertIconHeight, alertIconWidth} from 'src/designSystem/guide'
import {Color} from 'src/designSystem/types'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

export type AlertLevel = 'info' | 'success' | 'warning' | 'error'

export type AlertProps = {
  level: AlertLevel
  title?: string
  description?: string | ReactNode
  onPress?: () => void | Promise<void>
  testID?: string
}

type AlertConfig = {
  border: Color
  background: Color
  titleColor: Color
  descriptionColor: Color
  iconColor: Color
  iconName: SvgIconNames
}

const getConfig = (level: AlertLevel): AlertConfig => {
  switch (level) {
    case 'info': {
      return {
        border: alertInfoBorder,
        background: alertInfoBackground,
        titleColor: alertInfoTitle,
        descriptionColor: alertInfoDescription,
        iconColor: alertInfoIconColor,
        iconName: 'info',
      }
    }
    case 'success': {
      return {
        border: alertSuccessBorder,
        background: alertSuccessBackground,
        titleColor: alertSuccessTitle,
        descriptionColor: alertSuccessDescription,
        iconColor: alertSuccessIconColor,
        iconName: 'checkWithCircle',
      }
    }
    case 'warning': {
      return {
        border: alertWarningBorder,
        background: alertWarningBackground,
        titleColor: alertWarningTitle,
        descriptionColor: alertWarningDescription,
        iconColor: alertWarningIconColor,
        iconName: 'warning',
      }
    }
    case 'error': {
      return {
        border: alertErrorBorder,
        background: alertErrorBackground,
        titleColor: alertErrorTitle,
        descriptionColor: alertErrorDescription,
        iconColor: alertErrorIconColor,
        iconName: 'error',
      }
    }
  }
}

const Alert: FC<AlertProps> = (props) => {
  const {title, description, onPress, level, testID} = props
  const config = getConfig(level)
  const borderStyles = {
    width: alertBorderWidth,
    color: config.border,
  }
  const iconPadding = 12

  const getIcon = (): ReactElement => {
    return (
      <Box
        paddingVertical={iconPadding}
        paddingHorizontal={iconPadding}
        align={'center'}
        justify={'center'}
      >
        <SvgIcon
          name={config.iconName}
          colorVariant="custom"
          customColor={config.iconColor}
          size={'small'}
          isFilled
        />
      </Box>
    )
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={!onPress} testID="alert-touchable">
      <Box
        background={config.background}
        radius={'little'}
        border={borderStyles}
        elevation={4}
        testID={testID}
      >
        <Box direction={'row'} justify={'center'} align={'start'}>
          {getIcon()}
          <Box
            paddingLeft={0}
            paddingRight="little"
            paddingVertical="little"
            gap={'tiny'}
            boxStyle={styles.contentContainer}
          >
            {!isStringValueEmpty(title) ? (
              <PFText variant={'label_md'} color={config.titleColor}>
                {title}
              </PFText>
            ) : null}
            {typeof description === 'string' && !isStringValueEmpty(description) ? (
              <PFText variant={'p_sm'} color={config.descriptionColor}>
                {description}
              </PFText>
            ) : (
              isValidElement(description) && description
            )}
          </Box>
          {onPress ? (
            <Box
              width={alertIconWidth}
              height={alertIconHeight}
              alignSelf={'start'}
              paddingRight={'medium'}
              marginTop={12}
            >
              <SvgIcon name={'arrowRight'} colorVariant={'default'} />
            </Box>
          ) : undefined}
        </Box>
      </Box>
    </TouchableOpacity>
  )
}

export default Alert

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
})
