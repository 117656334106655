import React, {ReactElement, ReactNode, useState} from 'react'
import {TouchableWithoutFeedback, View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import DualLineText from 'src/designSystem/components/molecules/DualLineText/DualLineText'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {smallGap} from 'src/designSystem/layout'
import {HowAutoPayWorksOverlay} from 'src/products/MCU/AccountManagementV2/PaymentMethods/HowAutoPayWorksOverlay/HowAutoPayWorksOverlay'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'

type BankAccountTileTextProps = {
  account: BankAccountInfo
  accountNumber: string
}

export const BankAccountTileText: React.FC<BankAccountTileTextProps> = ({
  account,
  accountNumber,
}: BankAccountTileTextProps): ReactElement => {
  const {t} = useTranslation(['Bank'])
  const [isHowAutoPayWorksVisible, setIsHowAutoPayWorksVisible] = useState<boolean>(false)

  const handleOnPressHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(true)
  }
  const handleOnDismissHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(false)
  }
  let statusPill: ReactNode = null
  if (account.isDefaultLoanPaymentMethod) {
    statusPill = (
      <Touchable onPress={handleOnPressHowAutoPayWorks}>
        <PFStatusPill
          icon={{
            colorVariant: 'info',
            name: 'moneyRotating',
          }}
          iconPlacement="before"
          text={t('AccountTagAutoPay')}
          variant="outline"
        />
      </Touchable>
    )
  } else if (account.isAccountJustAdded) {
    statusPill = <PFStatusPill variant="outline" text={t('AccountTagNew')} />
  }
  return (
    <>
      <View
        style={styles.textView}
        testID={account.primary ? 'BankAccountTile-PrimaryAccount' : undefined}
      >
        {statusPill}
        <DualLineText text={account.name} subText={accountNumber} />
      </View>
      <HowAutoPayWorksOverlay
        isVisible={isHowAutoPayWorksVisible}
        onDismiss={handleOnDismissHowAutoPayWorks}
      />
    </>
  )
}

type BankAccountTileInfoIconProps = {
  primary: boolean
  onPress: () => void
}

export const BankAccountTileInfoIcon: React.FC<BankAccountTileInfoIconProps> = ({
  primary,
  onPress: handleOnPress,
}: BankAccountTileInfoIconProps): ReactElement | null => {
  if (!primary) {
    return null
  }
  return (
    <TouchableWithoutFeedback onPress={handleOnPress} testID={'Info-Icon'}>
      <View style={styles.rightIconView}>
        <SvgIcon name={'info'} colorVariant={'info'} />
      </View>
    </TouchableWithoutFeedback>
  )
}

type BankAccountTileAlertIconProps = {
  onPress?: () => void
}

export const BankAccountTileAlertIcon: React.FC<BankAccountTileAlertIconProps> = ({
  onPress: handleOnPress,
}: BankAccountTileAlertIconProps): ReactElement => {
  return (
    <TouchableWithoutFeedback onPress={handleOnPress} testID="Alert-Icon">
      <View style={styles.rightIconView}>
        <SvgIcon name={'warning'} colorVariant={'warning'} isFilled />
      </View>
    </TouchableWithoutFeedback>
  )
}

type BankAccountTileEditIconProps = {
  onPress: () => void
}

export const BankAccountTileEditIcon: React.FC<BankAccountTileEditIconProps> = ({
  onPress: handleOnPress,
}: BankAccountTileEditIconProps): ReactElement => {
  return (
    <TouchableWithoutFeedback onPress={handleOnPress} testID="Edit-Icon">
      <View style={styles.rightIconView} testID="Edit-Icon">
        <SvgIcon name={'edit'} colorVariant={'info'} />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  rightIconView: {
    marginLeft: smallGap,
  },
  textView: {
    flex: 1,
  },
})
