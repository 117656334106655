import {useSelector} from 'react-redux'

import {getSelectedPayNowPaymentMethod} from 'src/products/card/PaymentMethods/selectors'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import {useDefaultPaymentAccount} from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/useDefaultPaymentAccount'

export type UseCardAdhocPaymentAccountType = {
  isLoading: boolean
  adhocPaymentAccount: PaymentAccount
  hasPaymentInstrument: boolean
}

export const useCardAdhocPaymentAccount = (): UseCardAdhocPaymentAccountType => {
  // User selected payment methods are stored in redux
  const selectedPaymentMethod = useSelector(getSelectedPayNowPaymentMethod)
  const {isLoading, defaultPaymentAccount} = useDefaultPaymentAccount()
  let adhocPaymentAccount: PaymentAccount = null
  let hasPaymentInstrument = false

  if (selectedPaymentMethod) {
    adhocPaymentAccount = selectedPaymentMethod
  } else if (defaultPaymentAccount) {
    adhocPaymentAccount = defaultPaymentAccount
  }

  if (adhocPaymentAccount?.id) {
    hasPaymentInstrument = adhocPaymentAccount.__typename !== 'LinkedAccount'
  }

  return {isLoading, adhocPaymentAccount, hasPaymentInstrument}
}
