import {gql} from '@apollo/client'

import {
  CardAccountsFragment,
  LocationFragment,
  UraFragment,
  UserFragment,
  UserLoansFragment,
  UserProfileFragment,
} from '../fragments'
import {BankAccountsFragment} from '../fragments/bankAccounts'
import {CardsPaymentMethodsFragment} from '../fragments/paymentMethods'

export const MeIdQuery = gql`
  query MeId {
    me {
      id
    }
  }
`

export const MeQuery = gql`
  ${UserFragment}
  ${UserProfileFragment}
  ${UserLoansFragment}
  ${CardAccountsFragment}
  ${UraFragment}
  ${CardsPaymentMethodsFragment}
  ${BankAccountsFragment}
  query Me {
    me {
      id
      ...UserFragment
      ...UserProfileFragment
      ...UserLoansFragment
      ...CardAccountsFragment
      ...UraFragment
      ...CardsPaymentMethodsFragment
      ...BankAccountsFragment
    }
  }
`

export const MeAddressQuery = gql`
  ${LocationFragment}
  query MeAddress {
    me {
      id

      profile {
        home {
          ...LocationFragment
        }

        mailing {
          ...LocationFragment
        }
      }
    }
  }
`

export const MePendingUserRequestedActionsQuery = gql`
  ${UraFragment}
  query PendingUserRequestedActions {
    me {
      id
      ...UraFragment
    }
  }
`

export const MeProfileQuery = gql`
  ${UserProfileFragment}
  query Profile {
    me {
      id
      ...UserProfileFragment
    }
  }
`

export const UserModifyProfile = gql`
  ${UserFragment}
  ${UserProfileFragment}
  mutation UserModifyProfile(
    $name: ApplicantNameInput
    $dob: ApplicantDOBInput
    $ssn: ApplicantSSNInput
    $id: ApplicantIDInput
  ) {
    userModifyProfile(name: $name, dob: $dob, ssn: $ssn, id: $id) {
      ...UserFragment
      ...UserProfileFragment
    }
  }
`

export const UserModifyEmail = gql`
  mutation UserModifyEmail($input: ModifyEmailInput!) {
    userModifyEmail(input: $input)
  }
`

export const UserModifyAddressComponents = gql`
  ${LocationFragment}
  mutation UserModifyAddressComponents(
    $addressId: ID
    $addressType: AddressType!
    $physicalAddress: ApplicantPhysicalAddressComponentsInput!
  ) {
    userModifyAddressComponents(
      addressId: $addressId
      addressType: $addressType
      physicalAddress: $physicalAddress
    ) {
      ...LocationFragment
    }
  }
`

export const GetUserEnv = gql`
  query GetUserEnv {
    getUserEnv {
      bankingAggregatorId
      ipGeoLocation {
        regionCode
      }
      userId
    }
  }
`

export const AwardsProcessReferralLink = gql`
  mutation AwardsProcessReferralLink($referringUserId: ID!, $referralCode: String!) {
    awardsProcessReferralLink(referringUserId: $referringUserId, referralCode: $referralCode)
  }
`

export const BankAccountUnlink = gql`
  mutation BankAccountUnlink($aggregatorType: AggregatorType!, $aggregatorRef: String!) {
    bankAccountUnlink(aggregatorType: $aggregatorType, aggregatorRef: $aggregatorRef)
  }
`

export const PhoneVerificationCodeSend = gql`
  mutation PhoneVerificationCodeSend($authorizationCodeInput: AuthorizationCodeInput!) {
    phoneVerificationCodeSend(authorizationCodeInput: $authorizationCodeInput)
  }
`

export const PhoneVerificationCodeValidation = gql`
  mutation PhoneVerificationCodeValidation(
    $phoneNumberVerificationInput: PhoneNumberVerificationInput!
  ) {
    phoneVerificationCodeValidation(phoneNumberVerificationInput: $phoneNumberVerificationInput) {
      id
      profile {
        phone {
          number
          verified
        }
      }
    }
  }
`

export const GetUserDocumentsCollection = gql`
  query GetUserDocumentsCollection($types: [UserDocumentType]) {
    me {
      id
      documents {
        all(types: $types) {
          document {
            type
            subType
            createdAt
          }
          presignedUrl {
            url
          }
        }
      }
    }
  }
`

export const GetUserDocumentGenerateUploadUrl = gql`
  mutation UserDocumentGenerateUploadUrl($filename: String!) {
    userDocumentGenerateUploadUrl(fileName: $filename) {
      documentId
      presignedUrl
    }
  }
`
export const GetUserDocumentWithPresignedUrl = gql`
  mutation UserDocumentCreate($input: CreateUserDocumentInput!) {
    userDocumentCreate(input: $input) {
      document {
        id
      }
    }
  }
`
