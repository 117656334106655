import {StackNavigationOptions} from '@react-navigation/stack'

import {genericPageBackground} from 'src/designSystem/semanticColors'
import {HeaderBackImage} from 'src/nav/Header/HeaderBackImage'

const subStackScreenOptions: StackNavigationOptions = {
  cardStyle: {backgroundColor: genericPageBackground},
  headerBackImage: HeaderBackImage,
  headerBackTitleVisible: false,
  headerMode: 'float',
  headerShadowVisible: false,
  title: '',
}

export {subStackScreenOptions}
