import React from 'react'
import {Trans} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SpotlightTile} from 'src/designSystem/components/molecules/SpotlightTile/SpotlightTile'
import type {SpotlightTileProps} from 'src/designSystem/components/molecules/SpotlightTile/SpotlightTile'
import {BrazeContentCard} from 'src/lib/braze/braze.utils'
import Log from 'src/lib/loggingUtil'

// Map of `BrazeContentCard` text fields and their supported markup definitions.
const brazeContentCardMarkup = {
  title: {
    strong: <PFText color={NamedColors.PRODUCT_BLUE} variant="p_lg_semibold" />,
  },
  content: {
    strong: <PFText variant="p_semibold" />,
  },
} as const

type MaybeMarkupFields = keyof typeof brazeContentCardMarkup

/**
 * Used to parse text strings from the Braze Content Card SDK that might contain SUPPORTED basic
 * stylistic markup for typography. Supported markup is defined in `brazeContentCardMarkup`.
 *
 * ⚠️ Important:
 *   1. Does not support nested markup.
 *   2. Strings are not sanitized for unsupported markup.
 */
function parseBrazeContentCardMarkup(
  fieldType: MaybeMarkupFields,
  maybeMarkup: string,
): React.ReactNode {
  return <Trans components={brazeContentCardMarkup[fieldType]} defaults={maybeMarkup} />
}

function mapBrazeContentCardToComponent<TProps extends Partial<SpotlightTileProps>>(
  datum: BrazeContentCard,
  props?: TProps,
): React.ReactElement | null {
  switch (datum.type) {
    case 'Classic':
      return (
        <SpotlightTile
          content={parseBrazeContentCardMarkup('content', datum.cardDescription)}
          title={parseBrazeContentCardMarkup('title', datum.title)}
          endnote={datum.extras.endnote}
          ctaText={datum.extras?.ctaText}
          {...props}
        />
      )
    default:
      Log.error(`Unsupported BrazeContentCard type: ${datum.type}`)
      return null
  }
}

export {mapBrazeContentCardToComponent, parseBrazeContentCardMarkup}
