import {
  AllMutationOperationNames as AllCassandraMutationOperationNames,
  AllQueryOperationNames as AllCassandraQueryOperationNames,
} from '@possible/cassandra/src/types/allDocuments'
import {AddAchPaymentMethodForLinkedAccountResult} from '@possible/cassandra/src/types/types.mobile.generated'
import {GraphqlChaosResponseMock} from '@possible/chaos'
import {
  AllColocatedMutationOperationNames,
  AllColocatedQueryOperationNames,
} from 'src/generated-all-gql-colated-operations'
import {getBankAddPaymentMethodMock} from 'src/products/MCU/AccountManagementV2/PaymentMethods/PaymentMethodsMock.testdata'

// list of all query names
export const AllQueryOperationNames: string[] = AllCassandraQueryOperationNames.concat(
  AllColocatedQueryOperationNames.filter(
    (colocatedQueryName) => AllCassandraQueryOperationNames.indexOf(colocatedQueryName) === -1,
  ),
)

// list of all mutation names
export const AllMutationOperationNames: string[] = AllCassandraMutationOperationNames.concat(
  AllColocatedMutationOperationNames.filter(
    (colcatedMutationName) =>
      AllCassandraMutationOperationNames.indexOf(colcatedMutationName) === -1,
  ),
)

// list of all mock responses for mutations
export const AllMutationResponseMocks: {
  [mutationName: string]: GraphqlChaosResponseMock[]
} = {
  BankAddAchPaymentMethod: getBankAddPaymentMethodMock('ACH'),
  BankAddDebitPaymentMethod: getBankAddPaymentMethodMock('CARD'),
  AccountSelectionAddAchPaymentMethodForLinkedAccount: [
    {
      mockName: 'Fail to verify routing + account',
      mockResponse: {
        bankAddAchPaymentMethodForLinkedAccount: {
          achPaymentMethod: null,
          result: AddAchPaymentMethodForLinkedAccountResult.AccountNumbersNotFound,
          __typename: 'AddAchPaymentMethodForLinkedAccountResponse',
        },
      },
    },
  ],
}
