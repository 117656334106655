import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import OverlayStackedButtons from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtons'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {logOfferActivationError} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {PaymentInfoBox} from 'src/products/loans/LoanApprovedActivation/components/PaymentInfoBox/PaymentInfoBox'
import ValuePropList from 'src/products/loans/components/molecules/ValuePropList/ValuePropList'

export type UpgradeToInstallmentPlanTemplateProps = {
  showFailedToLoadConversionOptions: boolean
  newPaymentDates: {
    isLoading: boolean
    hasError: boolean
    payments: {
      id: string
      date: string
      amount: string
    }[]
  }
  isSubmitting: boolean
  onAccept: () => void | Promise<void>
  onDecline: () => void | Promise<void>
  onOpenDeclineModal: () => void
  onDismissDeclineModal: () => void
  primaryAccount: {
    isLoading: boolean
    hasError: boolean
    mask: string
    type: string
    institutionName: string
  }
}

export const UpgradeToInstallmentPlanTemplate: React.FC<UpgradeToInstallmentPlanTemplateProps> = (
  props,
) => {
  const {
    primaryAccount,
    showFailedToLoadConversionOptions,
    isSubmitting,
    newPaymentDates,
    onAccept,
    onDecline,
    onOpenDeclineModal,
    onDismissDeclineModal,
  } = props
  const {t} = useTranslation('UpgradeToInstallment')

  const [showDeclineUpgradeModal, setShowDeclineUpgradeModal] = useState<boolean>(false)

  const valueProps = [t('ValueProp1'), t('ValueProp2'), t('ValueProp3')]

  const isDataLoading = primaryAccount.isLoading || newPaymentDates.isLoading
  const hasError = primaryAccount.hasError || newPaymentDates.hasError

  const primaryAcceptBtn: ButtonLockupPropsPrimary = {
    text: t('AcceptInstallment'),
    testID: 'Accept-Installment-Plan-Button',
    onPress: () => void onAccept(),
    disabled: isDataLoading || hasError || isSubmitting,
    loading: isSubmitting,
  }
  const secondaryDeclineBtn: ButtonLockupPropsSecondary = {
    buttonText: t('DeclineInstallment'),
    testID: 'Decline-Installment-Plan-Link',
    onPress: () => {
      onOpenDeclineModal()
      setShowDeclineUpgradeModal(true)
    },
    // we don't need to disable the decline button if data failed. they're free to decline the upgrade and continue
    // even if the new payment dates for the conversion failed to load since they already have an active loan
    disabled: isDataLoading || isSubmitting,
  }

  return (
    <BaseTemplate
      testID="UpgradeToInstallmentPlanTemplate"
      onErrorBoundary={(e): void => {
        logOfferActivationError(e, 'UpgradeToInstallmentPlanTemplate render failed')
      }}
    >
      <Page
        variant={'generic'}
        buttonProps={buttonLockupProperties(primaryAcceptBtn, secondaryDeclineBtn)}
        title={t('Title')}
        titleTextAlignment="center"
        smallTopGap
      >
        {showFailedToLoadConversionOptions ? (
          <View style={styles.errorView}>
            <PFText variant={'p'}>{t('ErrorLoadingConversion')}</PFText>
          </View>
        ) : (
          <Box gap="medium" paddingBottom="small">
            <PaymentInfoBox
              payments={newPaymentDates.payments ?? []}
              isAccountDataLoading={newPaymentDates.isLoading || primaryAccount.isLoading}
              preferredAccountInstitutionName={primaryAccount.institutionName ?? ''}
              preferredAccountMask={primaryAccount.mask ?? ''}
              preferredAccountType={primaryAccount.type ?? ''}
            />
            <ValuePropList
              items={valueProps}
              style={styles.listView}
              boldItems={true}
              icon={<SvgIcon name={'checkWithCircle'} colorVariant={'success'} size={'medium'} />}
            />
          </Box>
        )}
      </Page>
      {/* decline upgrade modal */}
      <OverlayStackedButtons
        testID="Decline-Upgrade-To-Installment-Plan-Modal"
        visible={showDeclineUpgradeModal}
        title={t('TitleDecline')}
        primary={{
          text: t('GoBackInstallment'),
          onPress: (): void => {
            setShowDeclineUpgradeModal(false)
            onDismissDeclineModal()
          },
        }}
        secondary={[
          {
            text: t('PreferSinglePayment'),
            onPress: (): void => {
              setShowDeclineUpgradeModal(false)
              void onDecline()
            },
          },
        ]}
      >
        <PFText variant="p">{t('DeclinationText')}</PFText>
      </OverlayStackedButtons>
    </BaseTemplate>
  )
}

const styles = StyleSheet.create({
  errorView: {
    alignSelf: 'center',
    paddingTop: 120,
  },
  listView: {
    alignSelf: 'center',
  },
})
