import React from 'react'
import {StyleSheet, View} from 'react-native'

import {ChaosEnabled} from '@possible/chaos'
import {
  UsePageViewedAnalyticsProps,
  usePageViewedAnalytics,
} from 'src/lib/Analytics/usePageViewedAnalytics'
import {PaymentAction} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {AdhocPaymentAmountEntryTemplate} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocPaymentAmountEntryTemplate'
import {OverpayAlert} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/OverpayAlert'
import {ButtonActionEventProps} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocPaymentAmountEntry.utils'
import {usePfDispatch} from 'src/store/utils'
import {
  BaseTemplate,
  BaseTemplateProps,
} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

export type AdhocPaymentAmountEntryContainerProps = {
  pageViewedAnalytics: UsePageViewedAnalyticsProps
  testID: string
} & Pick<BaseTemplateProps, 'onErrorBoundary'> & // separate prop types for loading/not loading let us make required fields required when necessary
  (
    | {
        isLoading: true
      }
    | {
        buttonActionEventProps?: ButtonActionEventProps
        buttonActions: PaymentAction[] | undefined
        handleOnChangeSelectedButton: (action: PaymentAction) => void
        isAmountInputEditable?: boolean
        isLoading: false
        onPressContinue: () => void
        paymentAmount: string
        paymentTrackingId: string
        infoNoticeText?: string
        totalOwed: string
      }
  )

const AdhocPaymentAmountEntryContainer: React.FC<AdhocPaymentAmountEntryContainerProps> = (
  props,
) => {
  const {isLoading, pageViewedAnalytics, testID, onErrorBoundary: handleOnErrorBoundary} = props

  usePageViewedAnalytics(pageViewedAnalytics)
  const disaptch = usePfDispatch()

  if (isLoading) {
    return (
      <View testID="AdhocPaymentAmountEntry-Loading" style={styles.loadingContainer}>
        <Loading type={'loader0'} size={'large'} />
      </View>
    )
  }
  const {
    buttonActionEventProps,
    buttonActions,
    handleOnChangeSelectedButton,
    isAmountInputEditable,
    onPressContinue,
    paymentAmount,
    paymentTrackingId,
    infoNoticeText,
    totalOwed,
  } = props
  const handleOnPressContinue = onPressContinue

  const paymentValue = paymentAmount
  const isOverpaying = Number(paymentAmount) > Number(totalOwed)
  const isValidPayment = Number(paymentValue) > 0 && !isOverpaying
  return (
    <BaseTemplate onErrorBoundary={handleOnErrorBoundary}>
      <ChaosEnabled componentName={testID} dispatch={disaptch}>
        {isOverpaying ? <OverpayAlert /> : null}
        <AdhocPaymentAmountEntryTemplate
          buttonActionEventProps={buttonActionEventProps}
          buttonActions={buttonActions}
          isAmountInputEditable={isAmountInputEditable}
          isValidPayment={isValidPayment}
          onPressButtonAction={handleOnChangeSelectedButton}
          onPressContinue={handleOnPressContinue}
          paymentValue={paymentValue}
          paymentTrackingId={paymentTrackingId}
          infoNoticeText={infoNoticeText}
        />
      </ChaosEnabled>
    </BaseTemplate>
  )
}

export {AdhocPaymentAmountEntryContainer}

const styles = StyleSheet.create({
  loadingContainer: {
    display: 'flex',
    height: '100%',
  },
})
