/**
 * This function is limited to amounts which are >= 0 and have no cents
 */
export const formatCurrency = (value: string): string => {
  // Add dollar signs and commas
  const numericValue = value.replace(/[^0-9]/g, '')
  const parsedValue = parseFloat(numericValue)

  return !isNaN(parsedValue) && parsedValue !== null
    ? parsedValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : ''
}

/** Remove dollar signs and commas  */
export const sanitizeCurrencyString = (currencyString: string): string => {
  return currencyString.replace(/[$,]/g, '')
}
