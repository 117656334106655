import {
  CardPaymentMethodType,
  Maybe,
  AchPaymentMethod,
  DebitCardPaymentMethod,
  Institution,
  LinkedAccount,
  User,
  LinkedAccountType,
  CardAccount,
  PaymentMethodTypeCode,
} from '@possible/cassandra/src/types/consumer'

import {PaymentAccount} from 'src/products/card/PaymentMethods/types'

export const cvvFilter = (input: string): string => {
  try {
    return input.replace(/\D/g, '')
  } catch (e) {
    return input
  }
}

export const amountFilter = (input: string): string => {
  try {
    let newValue = input.replace(/\D/g, '')
    newValue = newValue.length > 0 ? `$${newValue}` : ''

    return newValue
  } catch (e) {
    return input
  }
}

export const numberFilter = (input: string): string => {
  try {
    let newValue = input.replace(/\D/g, '')
    newValue = newValue.replace(/(\d{4})/g, '$1 ')
    newValue = newValue.replace(/\.$/, '')
    newValue = newValue.trim()
    newValue = newValue.substring(0, 19)

    return newValue
  } catch (e) {
    return input
  }
}

const linkedAccountTypeToName = {
  [LinkedAccountType.Savings]: 'Savings',
  [LinkedAccountType.Checking]: 'Check',
  [LinkedAccountType.Other]: '',
}

export const getPaymentMethodTypeName = (paymentMethod?: Maybe<PaymentAccount>): string => {
  if (!paymentMethod) {
    return ''
  }

  if (paymentMethod?.__typename === 'DebitCardPaymentMethod') {
    return 'Debit Card'
  }

  const paymentMethodAccountType: LinkedAccountType =
    getPaymentMethodAccount(paymentMethod)?.type ?? LinkedAccountType.Other
  return linkedAccountTypeToName[paymentMethodAccountType]
}

export const getPaymentMethodInstitution = (
  paymentMethod?: Maybe<CardPaymentMethodType> | Maybe<LinkedAccount>,
): Maybe<Institution> => {
  const paymentMethodLinkedAccount = paymentMethod as LinkedAccount
  const paymentMethodAch = paymentMethod as AchPaymentMethod
  const paymentMethodDebit = paymentMethod as DebitCardPaymentMethod

  switch (paymentMethod?.__typename) {
    case 'LinkedAccount':
      return paymentMethodLinkedAccount?.institution ?? null
    case 'AchPaymentMethod':
      return paymentMethodAch?.account?.institution ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethodDebit?.card?.account?.institution ?? null
    default:
      return null
  }
}

export const getPaymentMethodAccount = (paymentMethod?: PaymentAccount): Maybe<LinkedAccount> => {
  const paymentMethodLinkedAccount = paymentMethod as LinkedAccount
  const paymentMethodAch = paymentMethod as AchPaymentMethod
  const paymentMethodDebit = paymentMethod as DebitCardPaymentMethod

  switch (paymentMethod?.__typename) {
    case 'LinkedAccount':
      return paymentMethodLinkedAccount
    case 'AchPaymentMethod':
      return paymentMethodAch?.account ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethodDebit?.card?.account ?? null
    default:
      return null
  }
}

export const getPaymentMethodMask = (
  paymentMethod?: Maybe<CardPaymentMethodType> | Maybe<LinkedAccount>,
): Maybe<string> => {
  const paymentMethodLinkedAccount = paymentMethod as LinkedAccount
  const paymentMethodAch = paymentMethod as AchPaymentMethod
  const paymentMethodDebit = paymentMethod as DebitCardPaymentMethod

  switch (paymentMethod?.__typename) {
    case 'LinkedAccount':
      return paymentMethodLinkedAccount?.mask
    case 'AchPaymentMethod':
      return paymentMethodAch?.account?.mask ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethodDebit?.card?.mask ?? null
    default:
      return null
  }
}

export const getPaymentMethodAccountName = (
  paymentMethod?: Maybe<CardPaymentMethodType> | Maybe<LinkedAccount>,
): Maybe<string> => {
  return [
    getPaymentMethodInstitution(paymentMethod)?.name,
    getPaymentMethodAccount(paymentMethod)?.name,
  ]
    .filter((i) => !!i)
    .join(' - ')
}

/**
 * Given a paymentMethod and a paymentInstrument, determine if the
 * paymentMethod was created from the paymentInstrument.
 * This is useful for the payment methods form
 */
export const isPaymentMethodFromPaymentInstrument = (
  paymentMethod: PaymentAccount,
  paymentInstrument: PaymentAccount,
): boolean => {
  if (paymentMethod?.id && paymentMethod?.id === paymentInstrument?.id) {
    return true
  }

  // If paymentMethod is AchPaymentMethod, and paymentInstrument is LinkedAccount, match them by account id
  // If paymentMethod is LinkedAccount, and paymentInstrument is LinkedAccount, match them by account id
  if (
    (paymentMethod?.__typename === 'AchPaymentMethod' ||
      paymentMethod?.__typename === 'LinkedAccount') &&
    paymentInstrument?.__typename === 'LinkedAccount' &&
    getPaymentMethodAccount(paymentMethod)?.id &&
    getPaymentMethodAccount(paymentMethod)?.id === getPaymentMethodAccount(paymentInstrument)?.id
  ) {
    return true
  }

  // Debit cards don't always link to an account.
  // If paymentMethod is DebitCardPaymentMethod without a linked account, and paymentInstrument is DebitCardPaymentMethod,
  // match them by card mask
  if (
    paymentMethod?.__typename === 'DebitCardPaymentMethod' &&
    paymentInstrument?.__typename === 'DebitCardPaymentMethod' &&
    paymentMethod?.card?.account === null &&
    paymentInstrument?.card?.account === null &&
    paymentMethod?.card?.mask &&
    paymentMethod?.card?.mask === paymentInstrument?.card?.mask
  ) {
    return true
  }

  return false
}

/**
 * Given paymentAccountId, get the associated payment object type
 * For ach accounts, this will be the id of a LinkedAccount.
 * For debit cards, paymentAccountId will be the id of a DebitCardPaymentMethod
 * The form to select a payment method will include the payment types LinkedAccount, AchPaymentMethod, and DebitCardPaymentMethod
 * paymentAccountId can belong to any of these
 */
export enum PaymentTypes {
  LinkedAccount,
  PaymentInstrument,
  PaymentMethod,
  PrimaryPaymentMethod,
}
export const findPaymentAccountType = (
  paymentAccountId: string,
  user: User,
): [PaymentAccount, PaymentTypes | undefined] => {
  let paymentAccount: [PaymentAccount, PaymentTypes | undefined] = [undefined, undefined]

  // First check if this is a linked account
  const linkedAccount = (user?.bankAccounts?.all ?? []).find(
    (linkedAcct) => paymentAccountId === linkedAcct.id,
  )
  if (linkedAccount) {
    paymentAccount = [linkedAccount, PaymentTypes.LinkedAccount]
  }

  // Second check if this is a payment instrument
  const paymentInstrument = (user?.paymentMethods?.cardEligible ?? []).find(
    (paymentInst) =>
      paymentAccountId === paymentInst.id ||
      paymentAccountId === getPaymentMethodAccount(paymentInst)?.id,
  )
  if (paymentInstrument) {
    paymentAccount = [paymentInstrument, PaymentTypes.PaymentInstrument]
  }

  // Third check for Payment Method
  const paymentMethod = (user?.cardAccounts?.active?.paymentMethods?.all ?? []).find(
    (paymentMeth) =>
      paymentAccountId === paymentMeth.id ||
      paymentAccountId === getPaymentMethodAccount(paymentMeth)?.id,
  )
  if (paymentMethod) {
    paymentAccount = [paymentMethod, PaymentTypes.PaymentMethod]
  }

  // Fourth check if this is the Primary Payment Method
  const primaryPaymentMethod = user?.cardAccounts?.active?.paymentMethods?.default ?? undefined
  if (
    paymentAccountId === primaryPaymentMethod?.id ||
    paymentAccountId === getPaymentMethodAccount(primaryPaymentMethod)?.id
  ) {
    paymentAccount = [primaryPaymentMethod, PaymentTypes.PrimaryPaymentMethod]
  }

  return paymentAccount
}

// This filters banks by bank name or id
export const getAllPlaidLinkedBankAccountsFilteredByBank = (
  allBanks: LinkedAccount[],
  bankName?: string,
  bankId?: string,
): LinkedAccount[] => {
  return allBanks.filter(
    (linkedAccount) =>
      (bankName && bankName === linkedAccount?.institution?.name) ||
      (bankId && bankId === linkedAccount?.id) ||
      (!bankName && !bankId),
  )
}

/**
 * Given a list of paymentMethods,
 * If there is a preferredPaymentMethod in the list return it
 * Otherwise return the first paymentMethod
 */
export const findPreferredFundingSource = (
  paymentMethods: PaymentAccount[] = [],
): PaymentAccount => {
  const isPreferredFundingSource = (paymentMethod: PaymentAccount): boolean => {
    const linkedAccount = getPaymentMethodAccount(paymentMethod)
    const isPreferred = linkedAccount?.preferredFundingSource
    return !!isPreferred
  }

  const usablePaymentMethods = filterUnusablePaymentMethods(paymentMethods)
  const primaryPaymentMethod = usablePaymentMethods.find(isPreferredFundingSource)

  if (primaryPaymentMethod) {
    return primaryPaymentMethod
  } else if (usablePaymentMethods.length > 0) {
    return usablePaymentMethods[0]
  } else {
    return null
  }
}

/**
 * Some Payment Methods are unsuitable to be the default payment method. Filter these out. They are:
 * 1) Debit cards
 * 2) The CheckPaymentMethod that is created by default for the first ACH payment method in cards
 */
export const filterUnusablePaymentMethods = (
  paymentMethods: PaymentAccount[] = [],
): PaymentAccount[] => {
  return paymentMethods.filter((paymentMethod) => isUsablePaymentMethod(paymentMethod))
}

export const isUsablePaymentMethod = (paymentMethod: PaymentAccount): boolean => {
  if (!paymentMethod?.id) return false

  if ('code' in paymentMethod) {
    return paymentMethod.code !== PaymentMethodTypeCode.DebitCard
  }
  return true
}

/**
 * The BE returns information about the users payment methods, payment instruments, and linked accounts, along with which accounts are preferred.
 * But it doesn't say which account to use. This method takes all a user's payment details, and determines which payment account is best to use
 * when enrolling in autopay.
 * We don't use Debit Cards or the Check Payment Method for the primary payment method, so filter these out
 */
export const getDefaultPaymentMethodUtil = (
  activeAccount: Maybe<CardAccount>,
  allCardEligiblePaymentMethods: CardPaymentMethodType[],
  allLinkedBankAccounts: LinkedAccount[],
): PaymentAccount => {
  const allAccountPaymentMethods = activeAccount?.paymentMethods?.all ?? []

  // First, check /me/cardAccounts/active/paymentMethods/default. If there is a payment method set here, use it.
  const cardDefaultFundingSource = findPreferredFundingSource([
    activeAccount?.paymentMethods?.default,
  ])
  if (cardDefaultFundingSource) return cardDefaultFundingSource

  return (
    [
      // Second, return the first payment method at /me/cardAccounts/active/paymentMethods/all with preferredFundingSource set to true.
      // Third, return the first payment method in /me/cardAccounts/active/paymentMethods/all
      allAccountPaymentMethods,

      // Fourth, use the first payment method at /me/paymentMethods/cardEligible where the account has preferredFundingSource=true
      // Fifth, use the first payment method at /me/paymentMethods/cardEligible
      allCardEligiblePaymentMethods,

      // Sixth, the first linked bank account at /me/bankAccounts with preferredFundingSource=true
      // Seventh, just return the first linked bank account in /me/bankAccounts/all ¯\_(ツ)_/¯
      allLinkedBankAccounts,
    ]
      .map((paymentMethods) => findPreferredFundingSource(paymentMethods))
      .find((paymentMethod) => !!paymentMethod) ?? null
  )
}
