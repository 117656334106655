import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type GenerateAchAgreementMutationMutationVariables = Types.Exact<{
  loanId: Types.Scalars['ID']['input']
  linkedAccountId?: Types.InputMaybe<Types.Scalars['ID']['input']>
  paymentMethodId?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type GenerateAchAgreementMutationMutation = {
  __typename?: 'Mutation'
  loanGenerateAchAgreement: {
    __typename?: 'LoanDocumentWithDownloadUrl'
    type: Types.LoanDocumentType
    download: {__typename?: 'PresignedUrl'; url: string; expiresAt: string}
  }
}

export type SetDefaultPaymentMethodMutationMutationVariables = Types.Exact<{
  loanId: Types.Scalars['ID']['input']
  paymentInstrumentId: Types.Scalars['ID']['input']
}>

export type SetDefaultPaymentMethodMutationMutation = {
  __typename?: 'Mutation'
  loanSetDefaultPaymentMethod: {
    __typename?: 'SetDefaultPaymentMethodResponse'
    defaultPaymentMethodId: string
  }
}

export type AcceptLoanAutoPayAgreementQueryVariables = Types.Exact<{[key: string]: never}>

export type AcceptLoanAutoPayAgreementQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            bankAccount: {__typename?: 'BankAccount'; mask: string}
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            card: {__typename?: 'LinkedDebitCard'; mask?: string | null}
          }
      > | null
    }
  }
}

export const GenerateAchAgreementMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'GenerateACHAgreementMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethodId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGenerateAchAgreement'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkedAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentMethodId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethodId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'download'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'url'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateAchAgreementMutationMutation,
  GenerateAchAgreementMutationMutationVariables
>
export const SetDefaultPaymentMethodMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'SetDefaultPaymentMethodMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentInstrumentId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDefaultPaymentMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentInstrumentId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentInstrumentId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'defaultPaymentMethodId'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetDefaultPaymentMethodMutationMutation,
  SetDefaultPaymentMethodMutationMutationVariables
>
export const AcceptLoanAutoPayAgreementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AcceptLoanAutoPayAgreement'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'typeCodeFilter'},
                            value: {
                              kind: 'ListValue',
                              values: [
                                {kind: 'EnumValue', value: 'DEBIT_CARD'},
                                {kind: 'EnumValue', value: 'ACH'},
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptLoanAutoPayAgreementQuery,
  AcceptLoanAutoPayAgreementQueryVariables
>
