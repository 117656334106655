import {DocumentNode} from 'graphql'

import {GetClient} from '../client'
import {QueryStatus} from '../types'

export * from './factories'

/**
 * NextQueryStatus will return QueryStatus.Refreshing under certain circumstances.
 * This will allow developers to determine if we're refreshing state (polling, forceRefresh)
 * or loading state for the first time.
 * @param currentStatus The current QueryStatus of the state slice.
 * @param incomingStatus The proposed next QueryStatus of the state slice.
 * @returns incomingStatus or QueryStatus.Refreshing
 */
export const NextQueryStatus = (
  currentStatus: QueryStatus,
  incomingStatus: QueryStatus,
): QueryStatus => {
  if (currentStatus === QueryStatus.Fulfilled && incomingStatus === QueryStatus.Pending) {
    return QueryStatus.Refreshing
  }

  return incomingStatus
}

/**
 * @deprecated Use ./operations/ApplyQuery instead.
 */
export async function _applyQuery<T>(query: DocumentNode, variables = {}): Promise<T> {
  let client = GetClient()

  const response = await client.query<T>({
    query,
    variables,
  })

  return response.data
}

/**
 * @deprecated Use ./operations/ApplyMutation instead.
 */
export const _applyMutation = async (
  mutation: DocumentNode,
  variables: object,
  returnFullResponse?: boolean,
) => {
  let client = GetClient()

  const response = await client.mutate({
    mutation,
    variables,
  })

  if (returnFullResponse) {
    return response
  }

  if (response.errors?.length) {
    throw new Error(`Oops! ${response.errors?.[0].message}. Please try again or contact support.`)
  }

  return response.data
}
