import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivationAutopayScheduleReduxContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleReduxContainer'

export const CardActivationAutopayScheduleNavigationContainer: FC<
  StackScreenProps<MainStackParamList, 'CardActivationAutopaySchedule'>
> = ({navigation}) => {
  usePageViewedAnalytics({
    // Send the 'card_autopay_settings_page_viewed' event when this paged is accessed via 'Autopay Settings' on the dash
    eventName: CardEvents.card_autopay_settings_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  return (
    <CardActivationAutopayScheduleReduxContainer
      /* This is likely be removed in ENG-16749
       *
       * https://possible.atlassian.net/browse/ENG-16749
       */
      navigation={navigation}
      isRoutedFromDashboard
    />
  )
}
