import React from 'react'
import {useTranslation} from 'react-i18next'
import {AccessibilityProps, StyleSheet, StyleProp, ViewStyle, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'

enum ProgressBarSegmentStatus {
  'Error',
  'InProgress',
  'None',
  'Success',
  'Warning',
}

type ProgressBarSegmentProps = AccessibilityProps & {
  accessibilityLabelNamespace: string
  amount: number // A number between 0.0 and 1. Defaults to 1
  status: ProgressBarSegmentStatus
}

const getSegmentColorStyle = (segmentStatus: ProgressBarSegmentStatus): StyleProp<ViewStyle> => {
  switch (segmentStatus) {
    case ProgressBarSegmentStatus.Success:
      return styles.completed
    case ProgressBarSegmentStatus.Error:
      return styles.error
    case ProgressBarSegmentStatus.Warning:
      return styles.warning
    case ProgressBarSegmentStatus.InProgress:
      /* To show the striped "in progress" background for segments we render segments with a
       * transparent background to reveal the striped background image layered behind the entire
       * progress bar. This ensures the background image repeats smoothly. */
      return styles.inProgress
    default:
      return []
  }
}

const ProgressBarSegment = ({
  accessibilityLabelNamespace,
  amount,
  status,
  ...accessibilityProps
}: ProgressBarSegmentProps): JSX.Element => {
  const {t} = useTranslation(accessibilityLabelNamespace)

  return (
    <View
      accessible={true}
      accessibilityLabel={t(`segmentAccessibilityLabel.${ProgressBarSegmentStatus[status]}`, {
        keySeparator: '.',
      })}
      accessibilityValue={{min: 0, max: 100, now: amount * 100}}
      role={'progressbar'}
      style={[
        // Order matters
        styles.progressSegment,
        getSegmentColorStyle(status),
        {width: `${amount * 100}%`},
      ]}
      testID={`ProgressBarSegment-${ProgressBarSegmentStatus[status]}`}
      {...accessibilityProps}
    />
  )
}

export {ProgressBarSegment, ProgressBarSegmentStatus}

const styles = StyleSheet.create({
  completed: {
    backgroundColor: NamedColors.LIME,
  },
  error: {
    backgroundColor: NamedColors.PRODUCT_VERMILLION,
  },
  inProgress: {
    backgroundColor: NamedColors.TRANSPARENT,
  },
  progressSegment: {
    backgroundColor: NamedColors.WHITE, // status.None
  },
  warning: {
    backgroundColor: NamedColors.GOLD,
  },
})
