import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'
import {BankAddDebitPaymentMethodMutation} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/mutations/BankAddDebitPaymentMethod/BankAddDebitPaymentMethod.gqls'

export const getBankAddDebitPaymentMethodErrorResponse = (args: {
  resultType: Exclude<AddPaymentMethodResultType, AddPaymentMethodResultType.Successful>
}): BankAddDebitPaymentMethodMutation => {
  return {
    __typename: 'Mutation',
    bankAddDebitPaymentMethod: {
      __typename: 'AddPaymentMethodResponse',
      paymentMethod: null,
      result: {
        __typename: 'AddPaymentMethodResult',
        type: args.resultType,
      },
    },
  }
}
