import React, {FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PaymentMethodItem} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodItem'
import {getPaymentMethodsOrganizedByInstitution} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/util'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import {PaymentMethodListProps} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodList.types'

export const PaymentMethodList: FC<PaymentMethodListProps> = (props) => {
  const {paymentMethods, showRadio} = props
  const paymentMethodsOrganizedByInstitution =
    getPaymentMethodsOrganizedByInstitution(paymentMethods)

  const getPaymentItems = (paymentMethodsSubset: PaymentAccount[]): React.ReactNode => {
    return (
      <>
        {paymentMethodsSubset.map((paymentMethod, index) => (
          <PaymentMethodItem
            {...props}
            key={index}
            paymentMethod={paymentMethod}
            showRadio={showRadio}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {Object.keys(paymentMethodsOrganizedByInstitution).map((instName, index) => (
        <Box key={index}>
          <Box marginVertical={'little'}>
            <PFText variant={'p_lg_semibold'}>{instName}</PFText>
          </Box>
          {getPaymentItems(paymentMethodsOrganizedByInstitution[instName])}
        </Box>
      ))}
    </>
  )
}
