import {useFocusEffect} from '@react-navigation/native'
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LayoutChangeEvent, LayoutRectangle, Linking} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {
  ButtonLockupPropSingleButton,
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ShowException} from 'src/lib/errors'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {possibleSupportCameraPermission} from 'src/navigation/WebLinks'
import UniversalCamera from 'src/products/general/UniversalCamera/UniversalCamera'
import {UniversalCameraRef} from 'src/products/general/UniversalCamera/UniversalCamera.types'
import {
  CameraAnalyticEvents,
  ImageOrientation,
  ImageOrientations,
  PhotoTakenCallback,
} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import {CalculateCameraImageStyle} from 'src/products/general/components/organisms/CameraPage/CameraPage.utils'
import Log from 'src/lib/loggingUtil'
import {getCameraError} from 'src/products/general/UniversalCamera/UniversalCamera.utils'

type Props = {
  onPhotoTaken: PhotoTakenCallback
  orientations: ImageOrientations
  onOrientationChange: (orientation: ImageOrientation) => void
  position: 'front' | 'back'
  aspectRatio?: number

  title: string
  message: string | React.ReactNode
  takePhotoButtonText?: string
  turnCameraButtonText?: Partial<{[key in ImageOrientation]: string}>

  androidCameraPermissionOptions?: {
    title: string
    message: string
    buttonPositive?: string
    buttonNegative?: string
    buttonNeutral?: string
  }

  viewedEvent: CameraAnalyticEvents['takePhotoViewedEvent']
}

const CameraPageCapture: FC<Props> = (props) => {
  const {t} = useTranslation(['CameraPage', 'Common'])

  const {
    onPhotoTaken,
    orientations,
    onOrientationChange,
    position,
    title,
    message,
    takePhotoButtonText,
    turnCameraButtonText = {
      portrait: t('TurnCameraLandscape'),
      landscape: t('TurnCameraPortrait'),
      square: '',
    },
    viewedEvent,
  } = props

  const [isBusy, setBusy] = useState<boolean>(false)

  const [orientation, setOrientation] = useState<ImageOrientation>(
    isDeviceWeb() ? 'square' : orientations[0],
  )

  const [isCameraReady, setCameraReady] = useState<boolean>(false)
  const [cameraFrame, setCameraFrame] = useState<LayoutRectangle>()
  const [hasCameraPermissions, setHasCameraPermissions] = useState<boolean>(true)

  const cameraRef = useRef<UniversalCameraRef | null>(null)

  const viewedEventName = viewedEvent?.name
  const viewedEventCategory = viewedEvent?.category
  useFocusEffect(
    useCallback(() => {
      if (viewedEventName && viewedEventCategory) {
        TrackAppEvent(viewedEventName, viewedEventCategory)
      }
    }, [viewedEventCategory, viewedEventName]),
  )

  const onPressTakePicture = useCallback(async (): Promise<void> => {
    setBusy(true)
    try {
      const imageResponse = await cameraRef?.current?.takeImageCapture?.()

      if (imageResponse && cameraFrame) {
        onPhotoTaken({
          image: imageResponse,
          orientation,
          frame: cameraFrame,
        })
      }
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }, [cameraFrame, onPhotoTaken, orientation])

  const switchOrientation = useCallback((): void => {
    const newOrientation =
      orientations[(orientations.indexOf(orientation) + 1) % orientations.length]
    setOrientation(newOrientation)
    onOrientationChange(newOrientation)
  }, [orientation, onOrientationChange, orientations])

  const handleOnCameraReady = (): void => setCameraReady(true)

  const handleOnCameraLayout = useCallback((event: LayoutChangeEvent): void => {
    setCameraFrame(event.nativeEvent.layout)
  }, [])

  const handleOnError = (error: Error): void => {
    Log.error(getCameraError(error))
  }

  useEffect(() => {
    if (isDeviceWeb()) {
      navigator.mediaDevices.getUserMedia({video: true}).catch((error: Error) => {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDismissedError') {
          setHasCameraPermissions(false)
          return
        }

        handleOnError(error)
      })
    }
  }, [])

  const renderedCamera = useMemo(() => {
    if (cameraFrame) {
      const style = CalculateCameraImageStyle(cameraFrame, orientation)

      return (
        <UniversalCamera
          ref={cameraRef}
          orientation={
            orientation === 'portrait' || orientation === 'square' ? 'portrait' : 'landscapeLeft'
          }
          style={style}
          position={position === 'front' ? 'front' : 'back'}
          onCameraReady={handleOnCameraReady}
          aspectRatio={style?.aspectRatio}
          onError={handleOnError}
          {...cameraFrame}
        />
      )
    }

    return null
  }, [cameraFrame, orientation, position])

  const buttons: ButtonLockupProps = useMemo(() => {
    const singleButton: ButtonLockupPropSingleButton = {
      type: 'singleButton',
      primary: {
        text: takePhotoButtonText ?? t('TakePhoto'),
        onPress: onPressTakePicture,
        disabled: isBusy || !isCameraReady,
        loading: isBusy,
        testID: 'CameraPageCapture',
      },
    }

    if (!hasCameraPermissions) {
      return {
        ...singleButton,
        type: 'inlineLink',
        inline: {
          buttonText: t('PermissionsButtonText'),
          text: t('PermissionsText'),
          onPress: (): void => {
            if (isDeviceWeb()) {
              Linking.openURL(possibleSupportCameraPermission).catch(ShowException)
            } else {
              Linking.openSettings().catch(ShowException)
            }
          },
        },
      }
    } else if (orientations.length > 1 && !isDeviceWeb()) {
      return {
        ...singleButton,
        type: 'doubleButton',
        secondary: {
          buttonText: turnCameraButtonText[orientation] ?? '',
          onPress: switchOrientation,
          disabled: isBusy,
          icon: 'rotatePhone',
        },
      }
    }

    return singleButton
  }, [
    takePhotoButtonText,
    t,
    onPressTakePicture,
    isBusy,
    isCameraReady,
    hasCameraPermissions,
    orientations.length,
    turnCameraButtonText,
    orientation,
    switchOrientation,
  ])

  return (
    <Page
      buttonProps={buttons}
      variant={'generic'}
      smallTopGap={true}
      title={title}
      description={typeof message === 'string' ? message : undefined}
    >
      <Box fill="vertical" flex>
        {typeof message !== 'string' ? message : null}

        <Box flex paddingHorizontal="small" justify="center" align="center">
          <Box
            onLayout={handleOnCameraLayout}
            align="center"
            justify="center"
            boxStyle={{minWidth: '100%'}}
            testID="CameraBox"
          >
            <Box
              align="center"
              justify="center"
              boxStyle={
                cameraFrame ? CalculateCameraImageStyle(cameraFrame, orientation) : undefined
              }
            >
              {renderedCamera}
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default CameraPageCapture
