import React, {useReducer} from 'react'

import {
  CardAccountSchedulePaymentInput,
  PaymentIntention,
  PaymentSpecificationInput,
  ProcessorNetwork,
} from '@possible/cassandra/src/types/types.mobile.generated'

type PaymentState = {
  loading: boolean
  paymentArgs: CardAccountSchedulePaymentInput
  userSelectedButtonId?: string
}

type PaymentReducerAction =
  | {
      type: 'setAmount'
      payload: {amount: PaymentSpecificationInput['amount']; currentBalance: string}
    }
  | {type: 'setLoading'; payload: {loading: PaymentState['loading']}}
  | {type: 'setUserSelectedButtonId'; payload: {userSelectedButtonId: string}}
  | {
      type: 'success'
      payload: {paymentArgs: PaymentState['paymentArgs']}
    }

type UsePaymentState = [paymentState: PaymentState, dispatch: React.Dispatch<PaymentReducerAction>]

const initialState: PaymentState = {
  loading: false,
  paymentArgs: {
    cardAccountId: '',
    paymentInstrumentId: '',
    processorNetwork: ProcessorNetwork.Ach,
    paymentSpecification: {
      amount: '',
      intention: PaymentIntention.PartialPayment,
      executeDate: '',
    },
  },
  userSelectedButtonId: '',
}

const paymentReducer: React.Reducer<PaymentState, PaymentReducerAction> = (state, action) => {
  switch (action.type) {
    case 'setAmount':
      return {
        ...state,
        paymentArgs: {
          ...state.paymentArgs,
          paymentSpecification: {
            ...state.paymentArgs.paymentSpecification,
            amount: action.payload.amount,
            intention:
              Number(action.payload.amount) === Number(action.payload.currentBalance)
                ? PaymentIntention.FullBalance
                : PaymentIntention.PartialPayment,
          },
        },
      }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case 'setUserSelectedButtonId': {
      return {
        ...state,
        userSelectedButtonId: action.payload.userSelectedButtonId,
      }
    }
    case 'success': {
      return {
        ...state,
        loading: false,
        paymentArgs: action.payload.paymentArgs,
      }
    }
  }
}

const usePaymentState = (): UsePaymentState => {
  return useReducer(paymentReducer, initialState)
}

export {usePaymentState}
export type {PaymentReducerAction, PaymentState, UsePaymentState}
