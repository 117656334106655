import React, {createContext, useContext, useMemo} from 'react'

import {
  PlaidLayersProviderProps,
  UsePlaidLayersReturnType,
} from 'src/products/MCU/PlaidLayers/usePlaidLayers.types'
import {usePlaidLayers} from 'src/products/MCU/PlaidLayers/usePlaidLayers'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import {StackNavigationProp} from '@react-navigation/stack'

type PlaidLayersContextType = UsePlaidLayersReturnType &
  WorkflowPreReqFulfillScreenProps & {
    navigation: StackNavigationProp<WorkflowsStackParams> | undefined
  }

// Create the context with an initial value
const PlaidLayersContext = createContext<PlaidLayersContextType | undefined>(undefined)

// Create the provider component
const PlaidLayersProvider: React.FC<PlaidLayersProviderProps> = ({
  children,
  onPreReqFulfilled,
  navigation,
}) => {
  const plaidLayers = usePlaidLayers({onPreReqFulfilled, navigation})

  const providerValue = useMemo(
    () => ({
      ...plaidLayers,
      onPreReqFulfilled,
      navigation,
    }),
    [plaidLayers, onPreReqFulfilled, navigation],
  )

  return <PlaidLayersContext.Provider value={providerValue}>{children}</PlaidLayersContext.Provider>
}

// Create a custom hook to use the PlaidLayersContext
const usePlaidLayersContext = (): PlaidLayersContextType => {
  const context = useContext(PlaidLayersContext)

  if (context === undefined) {
    throw new Error('usePlaidLayersContext must be used within a PlaidLayersProvider')
  }

  return context
}

export {PlaidLayersProvider, usePlaidLayersContext}
