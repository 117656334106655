import React, {FC, ReactElement} from 'react'
import {useNavigation} from '@react-navigation/native'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {StateDisclosureGQLContainer} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosureGQLContainer'
import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'
import {StateDisclosureScreenProps} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosure.types'

type Props = WorkflowPreReqFulfillScreenProps & {
  stateCode: StateCodes
  render: (args: StateDisclosureScreenProps) => ReactElement
}

/**
 * Base container to integrate any State Disclosure screen within Workflows.
 */
export const StateDisclosureWorkflowContainerBase: FC<Props> = (props) => {
  const {onPreReqFulfilled, stateCode, render} = props
  const navigation = useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.state_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'Workflows',
      state: stateCode,
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.state_disclosure_accepted, AppEvents.Category.Activation, {
      navContainerType: 'Workflows',
      state: stateCode,
    })

    await onPreReqFulfilled()
  }

  const handleOnExit = (): void => {
    TrackAppEvent(AppEvents.Name.state_disclosure_exit, AppEvents.Category.Activation, {
      navContainerType: 'Workflows',
      state: stateCode,
    })

    navigation.goBack()
  }

  return (
    <StateDisclosureGQLContainer
      onExit={handleOnExit}
      onContinue={handleOnContinue}
      render={render}
    ></StateDisclosureGQLContainer>
  )
}
