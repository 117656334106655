import moment from 'moment'

import {Consumer} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'

const maxExtensionDays = 29

export function allowedEndDay(
  originationDate: moment.Moment,
  paymentStatus: string,
): moment.Moment {
  const standardAllowedTime = originationDate.clone().add(maxExtensionDays, 'days')

  //If payment failed, allow 2 weeks from now
  if (paymentStatus && paymentStatus === 'failed') {
    const failedPaymentExtension = moment().add(2, 'weeks')
    return standardAllowedTime.isAfter(failedPaymentExtension)
      ? standardAllowedTime
      : //add two weeks from today
        failedPaymentExtension
  }

  return standardAllowedTime
}

export function userTimeZoneId(profile: Consumer.types.UserProfile): string | null {
  if (!profile?.home) {
    return null
  }
  try {
    if (!profile.home?.timeZone?.id) {
      Log.warnOnce(`User time zone id is unknown for user id = ${profile.email?.address}`)
    }

    return profile.home?.timeZone?.id ?? 'America/Los_Angeles'
  } catch (e) {
    Log.warnOnce(e?.toString?.())
    Log.warnOnce(`User time zone id is unknown for user id = ${profile.email?.address}`)
  }
  return 'America/Los_Angeles'
}
