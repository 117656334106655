import {createSelector} from 'reselect'

import {
  CardAccount,
  CardPaymentMethodType,
  DebitCardPaymentMethod,
  LinkedAccount,
  LinkedAccountStatusCode,
  Maybe,
  UserProfile,
} from '@possible/cassandra/src/types/consumer'

import {PfReduxState} from 'src/reducers/types'

export const getUserProfile = (state: PfReduxState): Maybe<UserProfile> =>
  state.cassandra.user.me?.profile ?? null

export const getActiveAccount = (state: PfReduxState): Maybe<CardAccount> =>
  state.cassandra.user.me?.cardAccounts?.active ?? null

const getPaymentMethodTypeFactory = (paymentMethodType: string) => {
  return createSelector<PfReduxState, Maybe<CardAccount>, CardPaymentMethodType[]>(
    getActiveAccount,
    (activeAccount) => {
      const allAccounts = activeAccount?.paymentMethods?.all ?? []
      return allAccounts?.filter((account) => account?.__typename === paymentMethodType)
    },
  )
}

export const getDebitPaymentMethods = getPaymentMethodTypeFactory('DebitCardPaymentMethod') as (
  state: PfReduxState,
) => DebitCardPaymentMethod[]

const linkedAccountStatuses: (LinkedAccountStatusCode | undefined | null)[] = [
  LinkedAccountStatusCode.LinkedInUse,
]
export const getAllPlaidLinkedBankAccounts = (state: PfReduxState): LinkedAccount[] => {
  const allAccounts = state.cassandra.user.me?.bankAccounts?.all ?? []
  return allAccounts.filter((account) => linkedAccountStatuses.includes(account?.status))
}

export const getShouldEnrollInCards = (state: PfReduxState): boolean =>
  state.lib.card?.shouldEnrollInCards

export const getPreviousCardAccountId = (state: PfReduxState): string | undefined =>
  state.lib.card?.previousCardAccountId
