import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserPropertiesQueryVariables = Types.Exact<{[key: string]: never}>

export type UserPropertiesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    properties?: {
      __typename?: 'UserPropertyCollection'
      all?: Array<{__typename?: 'UserProperty'; property: string; value: string}> | null
    } | null
  }
}

export type SetUserPropertyMutationVariables = Types.Exact<{
  property: Types.Scalars['String']['input']
  value: Types.Scalars['String']['input']
}>

export type SetUserPropertyMutation = {__typename?: 'Mutation'; setUserProperty: boolean}

export type UserSetPropertyMutationVariables = Types.Exact<{
  property: Types.UserPropertyKey
  value: Types.Scalars['String']['input']
}>

export type UserSetPropertyMutation = {
  __typename?: 'Mutation'
  userSetProperty: {__typename?: 'UserSetPropertyResponse'; success: boolean}
}

export const UserPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UserProperties'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'properties'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'property'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPropertiesQuery, UserPropertiesQueryVariables>
export const SetUserPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'SetUserProperty'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'property'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'value'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'setUserProperty'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'property'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'property'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'value'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'value'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetUserPropertyMutation, SetUserPropertyMutationVariables>
export const UserSetPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserSetProperty'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'property'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserPropertyKey'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'value'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userSetProperty'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'property'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'property'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'value'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'value'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSetPropertyMutation, UserSetPropertyMutationVariables>
