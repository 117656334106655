import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {NamedColors} from 'src/designSystem/colors'
import Image from 'src/products/general/Image/Image'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'

export type CardApplyTileProps = BaseCardProps & {
  creditLimit: string
}

export const CardApplyTile = ({
  navigation,
  loading,
  creditLimit,
  tileMarginHorizontal,
  tileRadius,
}: CardApplyTileProps): ReactNode => {
  const {t} = useTranslation('CardDashboard')
  usePageViewedAnalytics({
    eventName: CardEvents.apply_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnPress = (): void => {
    TrackAppEvent(CardEvents.apply_dashboard_card_cta, AppEvents.Category.Card)
    navigation.navigate('ProductHub', {isInNonInitialOnboardingFlow: true})
  }

  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText="TheNewPossibleCard"
      buttonText="ApplyNow"
      content={t('ApplyNowBodyContent', {creditLimit: convertToDollarAmt(creditLimit, false)})}
      legalText="ApplyNowLegalContent"
      pillText="Beta"
      img={
        <Image
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          source={require('src/assets/images/PossibleCardAngled2.png')}
        />
      }
      onPress={handleOnPress}
      loading={loading}
      backgroundColor="primary"
      titleColor={NamedColors.WHITE}
      bodyColor={NamedColors.WHITE}
    />
  )
}
