import {combineReducers} from 'redux'

import UserReducer, {UserState} from './user/slice'
import EnvReducer from './env/slice'
import {EnvState} from './env/types'

export type CassandraRootStateType = {
  user: UserState
  env: EnvState
}

export const CassandraReducers = {
  user: UserReducer,
  env: EnvReducer,
}

const rootReducer = combineReducers<CassandraRootStateType>(CassandraReducers)

export type CassandraRootReducerType = typeof rootReducer

export default rootReducer
