import React, {ReactNode} from 'react'
import {StyleSheet, ScrollView} from 'react-native'

import {genericPageBackground} from 'src/designSystem/semanticColors'

type Props = {
  children: ReactNode
}

const LightboxScrollView = ({children}: Props): JSX.Element => (
  <ScrollView style={styles.scroll_view} contentContainerStyle={[styles.scroll_container_view]}>
    {children}
  </ScrollView>
)

export default LightboxScrollView

const styles = StyleSheet.create({
  scroll_container_view: {
    backgroundColor: genericPageBackground,
    flexGrow: 1,
  },
  scroll_view: {
    backgroundColor: genericPageBackground,
  },
})
