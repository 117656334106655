import {ImagePickerResponse} from 'react-native-image-picker'

import {WebImageCapture as WebImageInfoCapture} from 'src/products/general/UniversalCamera/UniversalCamera.types'

export const jpegContentType = 'image/jpeg'

type MobileImageCapture = {
  uri?: string
  fileName: string
  type: string
}

type WebImageCapture = {
  dataUri: string
  file: File
}

export type ImageCapture = Partial<MobileImageCapture & WebImageCapture>

export type ImageInfo =
  | (NonNullable<ImagePickerResponse['assets']>[number] & WebImageInfoCapture)
  | WebImageInfoCapture

export const getImageFileName = (imageInfo: ImageCapture): string => {
  const uri = imageInfo?.uri || imageInfo?.dataUri
  const fileName = uri?.split('/').pop()
  return fileName ?? ''
}

export const getImagePickerDefaults = (uri?: string): {fileName: string; type: string} => {
  const defaultFileName = uri?.includes(jpegContentType) ? 'photo.jpg' : 'photo.png'
  const defaulFileType = uri?.includes(jpegContentType) ? jpegContentType : 'image/png'
  return {
    fileName: defaultFileName,
    type: defaulFileType,
  }
}
