import React, {FunctionComponent} from 'react'

import MaintenanceInProgress from 'src/products/general/MaintenanceInProgress/MaintenanceInProgress'
import {useMaintenanceMode} from 'src/lib/RealtimeDatabase'

export const MaintenanceInProgressNotifier: FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const isMaintenanceModeEnabled = useMaintenanceMode()
  return <>{isMaintenanceModeEnabled ? <MaintenanceInProgress /> : children}</>
}

export default MaintenanceInProgressNotifier
