import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import SSNCollectionGQLContainer from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleSsnURI} from 'src/navigation/WebLinks'
import {openContactUsForm} from 'src/lib/contactUs'

type SSNCollectionWorkflowContainerProps = StackScreenProps<WorkflowsStackParams, 'SSN'> &
  WorkflowPreReqFulfillScreenProps

export const SSNCollectionWorkflowContainer: React.FC<SSNCollectionWorkflowContainerProps> = (
  props,
) => {
  const {onPreReqFulfilled, navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_ssn_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const handleMoreInfo = (): void => {
    TrackAppEvent(
      AppEvents.Name.personal_details_ssn_helper_selected,
      AppEvents.Category.Application,
    )
    PushWebPageOnStack(navigation, {
      uri: possibleSsnURI,
    })
  }

  const handleContactUs = (): void => {
    openContactUsForm(navigation.getParent())
  }

  const handleComplete = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.personal_details_ssn_completed, AppEvents.Category.Application)
    await onPreReqFulfilled()
  }

  return (
    <SSNCollectionGQLContainer
      onComplete={handleComplete}
      onMoreInfoPressed={handleMoreInfo}
      onContactUs={handleContactUs}
    />
  )
}
