import {configureStore} from '@reduxjs/toolkit'
import {PreloadedState} from 'redux'
import {createLogger} from 'redux-logger'
import ReduxThunk from 'redux-thunk'

import {REDUX_STORE_LOGGING} from 'src/config'
import rootReducer from 'src/reducers'
import {PfReduxState} from 'src/reducers/types'
import {PfReduxStore} from 'src/store/types'

const pfStore: PfReduxStore = pfCreateStore()

/**
 * Get the redux store.
 * @returns The redux store. :)
 */
export function getPfStore(): PfReduxStore {
  return pfStore
}

/**
 * This is exported for the purposes of testing.
 * @param preloadedState Some state to preload the store with. Unused.
 * @returns The created store
 * @private **DO NOT USE THIS FUNCTION OUTSIDE OF THIS FILE.**
 */
export function pfCreateStore(preloadedState?: PreloadedState<PfReduxState>): PfReduxStore {
  if (pfStore) {
    return pfStore
  }

  if (__DEV__) {
    if (REDUX_STORE_LOGGING) {
      const logger = createLogger({
        timestamp: true,
        duration: true,
      })

      return configureStore({
        reducer: rootReducer,
        middleware: [ReduxThunk, logger],
        preloadedState,
      })
    } else {
      return configureStore({
        reducer: rootReducer,
        middleware: [ReduxThunk],
        preloadedState,
      })
    }
  } else {
    return configureStore({
      reducer: rootReducer,
      middleware: [ReduxThunk],
    })
  }
}
