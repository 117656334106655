import {capitalize} from 'lodash'
import moment from 'moment'

import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'

export function validateDate(date: moment.Moment | Date, start: moment.Moment, end: moment.Moment) {
  date = moment(date)

  try {
    if (!date) return ''

    if (!date.isValid() || date.isBefore(start) || date.isAfter(end)) {
      return ''
    }

    return formatDate(date, graphqlDateFormat)
  } catch (e) {
    return ''
  }
}

export const suffixOptions = [
  {label: 'Junior', value: 'junior'},
  {label: 'Senior', value: 'senior'},
  {label: 'Jr', value: 'jr'},
  {label: 'Sr', value: 'sr'},
  {label: 'I', value: 'i'},
  {label: 'II', value: 'ii'},
  {label: 'III', value: 'iii'},
  {label: 'IV', value: 'iv'},
  {label: 'V', value: 'v'},
  {label: 'VI', value: 'vi'},
  {label: 'VII', value: 'vii'},
  {label: 'VIII', value: 'viii'},
  {label: 'IX', value: 'ix'},
]

type FormatUserNameType = {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  nameSuffix?: string | null
}

export const formatNameForOnboarding = (userInfo?: FormatUserNameType): string => {
  if (!userInfo) return ''
  return [
    userInfo?.firstName ?? '',
    userInfo?.middleName ?? '',
    userInfo?.lastName ?? '',
    capitalize(userInfo?.nameSuffix ?? ''),
  ].join(' ')
}
