import React, {useCallback, useContext, useMemo, useState} from 'react'

export enum WarningModalKind {
  LedgerSpentMinimum = 'ledgerSpentMinimum',
  POTDelinquency = 'POTDelinquency',
}

/* While it would be more consistent to mirror the return type of `useState` we are using an object
 * for better ergonomics so you can destructure only the properties you need.
 */
type WarningModalContextProps = {
  isModalVisible: (warningModalKind: WarningModalKind) => boolean
  setModalVisible: React.Dispatch<React.SetStateAction<WarningModalKind | false>>
}

type WarningModalProviderProps = {
  children: React.ReactNode
}

const WarningModalContext = React.createContext<WarningModalContextProps | undefined>(undefined)

const WarningModalProvider = ({children}: WarningModalProviderProps): JSX.Element => {
  const [warningModalKind, setWarningModalKind] = useState<WarningModalKind | false>(false)

  const isModalVisible = useCallback(
    (kind: WarningModalKind) => kind === warningModalKind,
    [warningModalKind],
  )

  const memoizedContext = useMemo<WarningModalContextProps>(
    () => ({isModalVisible, setModalVisible: setWarningModalKind}),
    [isModalVisible, setWarningModalKind],
  )

  return (
    <WarningModalContext.Provider value={memoizedContext}>{children}</WarningModalContext.Provider>
  )
}
const useWarningModals = (): WarningModalContextProps => {
  const modalState = useContext(WarningModalContext)

  if (!modalState) {
    throw new Error('useWarningModals has to be used within <WarningModalContext.Provider>')
  }

  return modalState
}

export {useWarningModals, WarningModalProvider}
