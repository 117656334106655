import {LoginResult} from '@possible/cassandra/src/types/types.public.generated'

export type MobileGatewayApiResponse = {
  throwIfError: () => void
  hasError: () => boolean
  getErrorStr: () => string | undefined
  getErrorCode: () => string | undefined
}

export enum ErrorResponseCode {
  BadRequest = 'bad_request',
  BlacklistedPassword = 'blacklisted_password',
  ConflictingAccount = 'conflicting_account',
  DeliveryMethodNotVerified = 'delivery_method_not_verified',
  DependencyFailure = 'dependency_failure',
  DocumentUploadError = 'document_upload_error',
  DuplicateResource = 'duplicate_resource',
  InsufficientData = 'insufficient_data',
  InternalServerError = 'internal_server_error',
  InvalidAccountState = 'invalid_account_state',
  InvalidAuthorizationToken = 'invalid_authorization_token',
  InvalidPasswordCriteria = 'invalid_password_criteria',
  InvalidUsername = 'invalid_username',
  InvalidVerificationCode = 'invalid_verification_code',
  MessageSendFailure = 'message_send_failure',
  MissingAccount = 'missing_account',
  NotImplemented = 'not_implemented',
  PasswordAttemptsExceeded = 'password_attempts_exceeded',
  PasswordResetRequired = 'password_reset_required',
  ProductNotReady = 'product_not_ready',
  RateLimitExceeded = 'rate_limit_exceeded',
  RegistrationNotPermitted = 'registration_not_permitted',
  RetryableError = 'retryable_error',
  ResourceNotFound = 'resource_not_found',
  Timeout = 'timeout',
  TemporarilyUnavailable = 'temporarily_unavailable',
  Unauthorized = 'unauthorized',
  UnknownState = 'unknown_state',
  UnsupportedState = 'unsupported_state',
  WrongPassword = 'wrong_password',
}

export enum UnderwritingGetPrequalificationStatus {
  InProgress = 'in_progress',
  MoreInfoNeeded = 'more_info_needed',
  Approved = 'approved',
  Rejected = 'rejected',
}
export interface UnderwritingGetPrequalificationResponse {
  /**
   *
   * @type {boolean}
   * @memberof UnderwritingGetPrequalificationResponse
   */
  isPrequalified?: boolean
  /**
   *
   * @type {number}
   * @memberof UnderwritingGetPrequalificationResponse
   */
  prequalificationAmount?: number
  /**
   *
   * @type {UnderwritingGetPrequalificationStatus}
   * @memberof UnderwritingGetPrequalificationResponse
   */
  prequalStatus?: UnderwritingGetPrequalificationStatus
  /**
   *
   * @type {ErrorResponseCode}
   * @memberof UnderwritingGetPrequalificationResponse
   */
  errorCode?: ErrorResponseCode
  /**
   *
   * @type {string}
   * @memberof UnderwritingGetPrequalificationResponse
   */
  errorMessage?: string
}

export type LoginOrRegisterResult = Omit<LoginResult, '__typename'>

export type LoginResponse = MobileGatewayApiResponse & LoginOrRegisterResult
