import {gql} from '@apollo/client'

export const CompleteUra = gql`
  mutation CompleteUra($uraId: ID!) {
    uraCompleted(uraId: $uraId)
  }
`
export const ExpireUra = gql`
  mutation ExpireUra($uraId: ID!) {
    uraExpired(uraId: $uraId)
  }
`
