import {gql} from '@apollo/client'

export const PaymentMethodTypeFragment = gql`
  fragment AchPaymentMethodFragment on AchPaymentMethod {
    id
    code
    bankingPaymentInstrumentId
    status {
      code
      reason
    }
    account {
      id
      mask
      name
      preferredFundingSource
      type
      institution {
        name
      }
    }
  }

  fragment DebitCardPaymentMethodFragment on DebitCardPaymentMethod {
    id
    code
    status {
      code
      reason
    }
    bankingPaymentInstrumentId
    card {
      mask
      account {
        id
        mask
        name
        preferredFundingSource
        type
        institution {
          name
        }
      }
    }
  }
`

export const CardsPaymentMethodsFragment = gql`
  ${PaymentMethodTypeFragment}
  fragment CardsPaymentMethodsFragment on User {
    paymentMethods {
      cardEligible {
        ... on AchPaymentMethod {
          ...AchPaymentMethodFragment
        }
        ... on DebitCardPaymentMethod {
          ...DebitCardPaymentMethodFragment
        }
      }
    }
  }
`
