import React, {ReactNode, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {StackHeaderProps, StackNavigationProp} from '@react-navigation/stack'
import {StyleSheet, View} from 'react-native'
import {useNavigationState} from '@react-navigation/native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {ParamListBase} from '@react-navigation/routers'

import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import HeaderCloseTextButton from 'src/nav/Header/HeaderCloseTextButton/HeaderCloseTextButton'
import HeaderLogoutButton from 'src/nav/Header/HeaderLogoutButton/HeaderLogoutButton'
import {HeaderMenuButton} from 'src/nav/Header/HeaderMenuButton/HeaderMenuButton'
import {UserLoginStates} from 'src/api/reducers/types'
import {
  headerIconHeight,
  headerIconLeftPadding,
  headerIconRightPadding,
  iconSize,
  LeftButtonStateTypes,
  pfHeaderHeight,
  pfHeaderHeightWeb,
  RightButtonStateTypes,
} from 'src/nav/Header/HeaderConstants'
import {pageMarginHorizontal} from 'src/designSystem/layout'
import {BasicSizeVariants, Color} from 'src/designSystem/types'
import {PfReduxState} from 'src/reducers/types'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {noLeftButtonProp} from 'src/nav/ModalStackHeader'

type NavigationType = StackNavigationProp<ParamListBase, string>

const leftButtonComp = (
  leftButton: LeftButtonStateTypes,
  navigation: NavigationType,
  headerButtonsColor?: Color,
): ReactNode => {
  switch (leftButton) {
    case LeftButtonStateTypes.BACK:
      return (
        <View style={styles.imageView} testID="Header-Back-Button">
          <HeaderBackButton navigation={navigation} size={iconSize} color={headerButtonsColor} />
        </View>
      )
    case LeftButtonStateTypes.LOGOUT:
      return (
        <View style={styles.logoutViewLeft} testID="Header-Logout-Button">
          <HeaderLogoutButton color={headerButtonsColor} />
        </View>
      )
    case LeftButtonStateTypes.MENU:
      return (
        <View style={styles.imageView} testID="Header-Menu-Button">
          <HeaderMenuButton navigation={navigation} size={iconSize} />
        </View>
      )
    default:
      return undefined
  }
}

const rightButtonComp = (
  rightButton: RightButtonStateTypes,
  navigation: NavigationType,
  headerButtonsColor?: Color,
): ReactNode => {
  switch (rightButton) {
    case RightButtonStateTypes.CLOSE:
      return (
        <View style={styles.closeView} testID="Header-Close-Button">
          <HeaderCloseTextButton navigation={navigation} color={headerButtonsColor} />
        </View>
      )
    case RightButtonStateTypes.LOGOUT:
      return (
        <View style={styles.logoutViewRight} testID="Header-Logout-Button">
          <HeaderLogoutButton />
        </View>
      )
    case RightButtonStateTypes.NONE:
      return undefined
  }
}

export const getLeftButtonType = (index: number, userIsLoggedIn: boolean): LeftButtonStateTypes => {
  if (index === 0) {
    if (userIsLoggedIn) {
      return LeftButtonStateTypes.MENU
    } else {
      return LeftButtonStateTypes.NONE
    }
  } else {
    return LeftButtonStateTypes.BACK
  }
}

export const getRightButtonType = ({
  index,
  currentPageName,
}: {
  index: number
  currentPageName: string
}): RightButtonStateTypes => {
  if (index > 0 && currentPageName === 'Profile') {
    return RightButtonStateTypes.LOGOUT
  }
  return RightButtonStateTypes.NONE
}

type Props = StackHeaderProps &
  PropsFromRedux & {
    headerButtonsColor?: Color
  }
const MainStackHeader: React.FC<Props> = (props) => {
  const [leftButton, setLeftButton] = useState(LeftButtonStateTypes.NONE)
  const [rightButton, setRightButton] = useState(RightButtonStateTypes.NONE)
  const [showLogo, setShowLogo] = useState(false)
  const [logoSize, setLogoSize] = useState<BasicSizeVariants>('medium')
  const {route, navigation, userIsLoggedIn: isUserLoggedIn, headerButtonsColor} = props
  const index = useNavigationState((state) => state.index)

  const processState = (currentPageName: string, pageParams?: object): void => {
    if (currentPageName === 'Dashboard') {
      setLeftButton(LeftButtonStateTypes.MENU)
      setRightButton(RightButtonStateTypes.NONE)
      setShowLogo(true)
      return
    }

    if (currentPageName === 'Landing') {
      setLeftButton(LeftButtonStateTypes.NONE)
      setRightButton(RightButtonStateTypes.NONE)
      setShowLogo(true)
      setLogoSize('small')
      return
    }

    if (pageParams?.[noLeftButtonProp]) {
      setLeftButton(LeftButtonStateTypes.NONE)
      setRightButton(RightButtonStateTypes.NONE)
      return
    }

    setLeftButton(getLeftButtonType(index, isUserLoggedIn))
    setRightButton(
      getRightButtonType({
        index,
        currentPageName,
      }),
    )
  }

  useEffect(() => processState(route.name, route.params))

  return (
    <SafeAreaView edges={['top']}>
      <View style={[styles.container, isDeviceWeb() && styles.containerWeb]}>
        <View style={styles.leftButton} testID="Header-Left">
          {leftButtonComp(leftButton, navigation, headerButtonsColor)}
        </View>
        <View style={styles.content} testID="Header-Logo">
          {showLogo ? <NameLogo size={logoSize} /> : null}
        </View>
        <View style={styles.rightButton} testID="Header-Right">
          {rightButtonComp(rightButton, navigation, headerButtonsColor)}
        </View>
      </View>
    </SafeAreaView>
  )
}
const mapStateToProps = (
  state: PfReduxState,
): {
  userIsLoggedIn: boolean
} => {
  return {
    userIsLoggedIn: state.api.user_logged_state === UserLoginStates.logged_in,
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MainStackHeader)

const styles = StyleSheet.create({
  closeView: {
    paddingRight: headerIconRightPadding,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: pfHeaderHeight,
    width: '100%',
  },
  containerWeb: {
    height: pfHeaderHeightWeb,
  },
  content: {
    flex: 1,
  },
  imageView: {
    height: headerIconHeight,
    marginLeft: headerIconLeftPadding,
    width: headerIconHeight,
  },
  leftButton: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  logoutViewLeft: {
    alignItems: 'flex-start',
    paddingLeft: pageMarginHorizontal,
  },
  logoutViewRight: {
    paddingRight: pageMarginHorizontal,
  },
  rightButton: {
    alignItems: 'flex-end',
    flex: 1,
  },
})
