/**
 * These types represent the UI states the
 * dashboard can have on an active account
 */
export enum CardAccountDashboardStatus {
  Active,
  SuspendedPayOverTime, // account suspended due to pay over time agreement
  SuspendedFraud, // account suspended due to fraud,
  Overdue,
  AtRiskDelinquency,
  Delinquent,
  DelinquencyReported,
  OverdueNSF,
  AtRiskDelinquencyNSF,
  DelinquentNSF,
  DelinquencyReportedNSF,
  Deactivated,
  DeactivatedDelinquent,
  DeactivatedDelinquentThirtyDays,
  ChargedOff,
}

export enum CardAccountPOTStatus {
  NotPOT,
  UpToDate,
  OverdueOneMissedPayment,
  OverdueMultipleMissedPayments,
}
