/**
 * Renders a web image element with the specified source, alt text, and styles.
 * @param {uri: string, default: string} props.source: The source of the image. Can be a string, or an object with a uri or default property.
 * @param {string} props.alt: The alt text for the image.
 * @param {CSSProperties} props.style: The styles to apply to the image.
 * @returns The rendered image element.
 *
 * @example <Image source={require('path/to/image.png')} alt="Image alt text" style={styles.imageStyle} />
 */

import {CSSProperties, createElement} from 'react'

type ImageProps = {
  source: {
    uri?: string
    default?: string
  }
  alt?: string
  style?: CSSProperties
}

const Image: React.FC<ImageProps> = ({source, alt, style}: ImageProps) => {
  const imgProps = {
    src: source.uri || source.default || source,
    alt: alt,
    style: style,
  }
  return createElement('img', imgProps)
}

export default Image
