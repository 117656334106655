import React, {useCallback, useEffect} from 'react'
import {AppState, AppStateStatus} from 'react-native'

import 'src/lib/localization/i18n'
import * as RealtimeDatabase from 'src/lib/RealtimeDatabase'
import {forceRefresh as RemoteConfigForceRefresh} from 'src/lib/RemoteConfig'
import {appIsBackground, appIsForeground} from 'src/native/webToNativeCompatibility'
import {getPfStore} from 'src/store'
import {_initSingular} from 'src/lib/Analytics'
import {UpdateLastLoan} from 'src/api/actions/loans/loanActions'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'

enum PossibleAppState {
  Active = 'active',
  Background = 'background',
}

const AppStateManager: React.FC = () => {
  const isMaintenanceMode = RealtimeDatabase.useMaintenanceMode()
  const handleAppActivated = async (): Promise<void> => {
    const store = getPfStore()
    const reduxState = store.getState()
    const loggedInState = reduxState?.api?.user_logged_state

    void RealtimeDatabase.init()
    // Needs to be called everytime on app open for proper functioning
    await _initSingular()

    RemoteConfigForceRefresh()

    if (loggedInState === 'logged_in') {
      // refresh loan data when back in foreground
      if (isMaintenanceMode) {
        void store.dispatch(Logout(false))
      } else {
        await store.dispatch(UpdateLastLoan())
      }
    }
  }

  const handleAppStateChange = useCallback(async (nextAppState: AppStateStatus) => {
    try {
      if (nextAppState === PossibleAppState.Active) {
        await handleAppActivated()
        appIsForeground()
      } else if (nextAppState === PossibleAppState.Background) {
        RealtimeDatabase.unsubscribeWhenAppIsInBackground()
        appIsBackground()
      }
    } catch (e) {
      /* noop */
    }
  }, [])

  useEffect(() => {
    const handleAppStateChangeEvent = (state: AppStateStatus): void => {
      void handleAppStateChange(state)
    }

    const subscription = AppState.addEventListener('change', handleAppStateChangeEvent)

    return () => subscription.remove()
  }, [handleAppStateChange])

  return null
}

export {AppStateManager}
