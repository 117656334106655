import React, {FC} from 'react'
import {formatMask} from 'src/lib/user/utils'
import {
  getPaymentMethodAccount,
  getPaymentMethodMask,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {AchPaymentMethod} from 'src/cassandra'
import {
  PaymentMethodFieldBase,
  PaymentMethodFieldProps,
} from 'src/products/card/PaymentMethods/PaymentMethodField/PaymentMethodFieldBase'

type Props = PaymentMethodFieldProps & {
  paymentMethod: AchPaymentMethod
}

export const AchPaymentMethodField: FC<Props> = (props) => {
  const {paymentMethod, ...otherProps} = props
  const accountName = getPaymentMethodAccount(paymentMethod)?.name ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''

  return (
    <PaymentMethodFieldBase
      paymentAccount={paymentMethod}
      title={accountName}
      subTitle={formatMask(accountMask, false)}
      {...otherProps}
    />
  )
}
