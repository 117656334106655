import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

type DidNotAcceptAgreementModalProps = {
  visible: boolean
  onCTA: (result: 'try_again' | 'ok_thanks') => void
}

export const DidNotAcceptAgreementModal: FC<DidNotAcceptAgreementModalProps> = ({
  visible,
  onCTA,
}) => {
  const {t} = useTranslation('AccountManagement')

  const body: ReactElement = (
    <PFText variant="p">
      {t('ToMakeSure')}
      <PFText variant="p_semibold">{t('PaymentsAreAutomatically')}</PFText>
      {t('WeDoNeedYourPermission')}
    </PFText>
  )

  return (
    <OverlaySimple
      visible={visible}
      title={t('YouDidNotAcceptAgreement')}
      titleSize="h2"
      titleAlign="left"
      okayButtonText={t('TryAgain')}
      okayButtonSize="large"
      hideOnOkay={true}
      onOkay={(): void => onCTA('try_again')}
      padding="medium"
      testID="Did_Not_Accept_Agreement_Modal"
      onDismiss={(): void => onCTA('ok_thanks')}
      dismissButtonText={t('OkThanks')}
      dismissButtonSize="large"
    >
      {body}
    </OverlaySimple>
  )
}
