import {StackScreenProps} from '@react-navigation/stack'
import React, {Component, ReactElement} from 'react'
import {Platform, StyleSheet, TouchableOpacity, View} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {
  backgroundWithOpacity,
  genericPageBackground,
  shadowWithOpacity,
  transparent,
} from 'src/designSystem/semanticColors'
import AppNav from 'src/nav/AppNavActions'
import {RootStackParamList} from 'src/nav/MainStackParamsList'
import LightboxScrollView from 'src/designSystem/components/organisms/Lightbox/LightboxScrollview'

type LightboxProps = StackScreenProps<RootStackParamList, 'LightBox'>

class LightBox extends Component<LightboxProps> {
  close = (): void => {
    //Dismiss modal with key currently only works with modals opened on the main stack and not the modal stack. Current usage is limited to LoanSuspendedModal
    const key = this.props.route?.params?.onKey ?? this.props.navigation.getState().key
    const isInModalStack = AppNav.isInModalStack(this.props.navigation)
    if (key && !isInModalStack) {
      AppNav.dismissModal(key)
    } else {
      this.props.navigation.goBack()
    }
  }

  render(): JSX.Element {
    const element = this.props.route?.params?.body
    //If the body is not a LightboxNotice, then wrap it in a LightboxScrollView
    const body =
      element &&
      React.cloneElement(element, {
        navigation: this.props.navigation,
        route: this.props.route,
        close: this.close,
      })

    const noScroll = this.props.route?.params?.noScroll
    const scrollBody = noScroll ? body : <LightboxScrollView>{body}</LightboxScrollView>

    return (
      <View style={styles.container}>
        <View style={styles.background} />
        <TouchableOpacity
          activeOpacity={1}
          onPress={(): void => {
            if (!this.props.route?.params?.undismissable) {
              this.props.route?.params?.onBackdropClose?.()
              this.close()
            }
          }}
          style={styles.backdrop}
        >
          <View style={styles.frame_view}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={(): void => {
                //avoid content click
              }}
              style={styles.touchable}
            >
              <View style={styles.container_view}>{scrollBody}</View>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

export default withForwardedNavigationParams<LightboxProps>()(LightBox)

type ShowLightBoxParamsType = {
  onBackdropClose?: () => void
}

export function ShowLightbox(
  body: ReactElement | (() => ReactElement),
  undismissable = false,
  noScroll = false,
  onKey = '',
  params: ShowLightBoxParamsType = {},
): void {
  if (typeof body === 'function') {
    body = body()
  }
  AppNav.newModal('LightBox', {body, undismissable, noScroll, overlay: true, onKey, ...params})
}

/*
Note : Don't use vertical margins, they mess up with the calculated sizes, causing an expansion after initial rendering
 */
const styles = StyleSheet.create({
  backdrop: {
    height: '100%',
    width: '100%',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: backgroundWithOpacity,
    opacity: 0.0,
  },
  container: {
    alignItems: 'center',
    backgroundColor: transparent,
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  },
  container_view: {
    alignSelf: 'center',
    backgroundColor: genericPageBackground,
    borderRadius: 10,
    maxHeight: 700,
    maxWidth: 380,
    minHeight: 160,
    minWidth: 200,
    padding: 16,
    shadowColor: shadowWithOpacity,
    shadowOffset: {width: 5, height: 5},
    shadowOpacity: 0.8,
    shadowRadius: 10,
    width: '100%',
    ...Platform.select({
      web: {
        cursor: 'auto',
      },
    }),
  },
  frame_view: {
    alignSelf: 'center',
    backgroundColor: transparent,
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 24,
    width: '100%',
  },
  touchable: {width: '100%'},
})
