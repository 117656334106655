import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type PaymentReviewTilaAndLoanAgreementCaQueryVariables = Types.Exact<{[key: string]: never}>

export type PaymentReviewTilaAndLoanAgreementCaQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        state: Types.StateCodes
        agreement: {
          __typename?: 'LoanAgreement'
          apr: string
          totalCost: string
          totalOwed: string
          cabFee?: string | null
          interestFee?: string | null
        }
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {
              __typename: 'ApprovedLoanAggregateStatus'
              amountApproved: string
              code: Types.LoanAggregateStatusCode
              payments: {
                __typename?: 'LoanPaymentCollection'
                payments: Array<{
                  __typename?: 'LoanPayment'
                  id: string
                  amount: string
                  originalDate: string
                  rescheduledDate?: string | null
                }>
              }
            }
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        mask: string
        type: Types.LinkedAccountType
        preferredFundingSource?: boolean | null
        institution?: {__typename?: 'Institution'; name: string} | null
      }> | null
    }
  }
}

export type PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationMutationVariables =
  Types.Exact<{
    loanId: Types.Scalars['UUID']['input']
    acceptLoanAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  }>

export type PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export const PaymentReviewTilaAndLoanAgreementCaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'PaymentReviewTilaAndLoanAgreementCa'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'agreement'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'apr'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'totalOwed'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'cabFee'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'interestFee'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountApproved'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'payments'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'payments'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'amount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'originalDate'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'rescheduledDate',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PaymentReviewTilaAndLoanAgreementCaQuery,
  PaymentReviewTilaAndLoanAgreementCaQueryVariables
>
export const PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptPayments'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptTilaDisclosure'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptLoanAgreement'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationMutation,
  PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationMutationVariables
>
