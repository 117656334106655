import React, {useState} from 'react'
import {ApolloError} from '@apollo/client'

import {LoanOriginator, LoanRole} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  UseAcceptLoanArguments,
  useAcceptLoan,
} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {
  LoanFinalAcceptTemplate,
  LoanFinalAcceptTemplateProps,
  useLoanFinalAcceptTitle,
} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptTemplate'
import {accountAndRoutingProvidedSelector} from 'src/lib/loans/selector'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {LoanFinalAcceptDocument} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.gqls'
import {logOfferActivationError} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {usePfSelector} from 'src/store/utils'

export type LoanFinalAcceptGQLContainerProps = {
  onContinue: () => Promise<void>
}

const LoanFinalAcceptGQLContainer: React.FC<LoanFinalAcceptGQLContainerProps> = (props) => {
  const {onContinue} = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    selectedData,
    error: queryError,
    loading: isQueryLoading,
  } = useCassandraQuery(
    LoanFinalAcceptDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'LoanFinalAccept query error',
        )
      },
    },
    (data) => {
      let isExtendedInstallmentPlanConvertedLoan = false

      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ApprovedLoanAggregateStatus'
      ) {
        const conversion = data.me.loans.latestActionableLoan.aggregateStatus.conversion
        isExtendedInstallmentPlanConvertedLoan =
          conversion.__typename === 'LoanConversionConverted' &&
          conversion.role === LoanRole.ConversionReplacement
      }
      return {
        loanId: data.me.loans.latestActionableLoan?.id,
        originator: data.me.loans.latestActionableLoan?.originator,
        firstName: data.me.profile?.name?.firstName,
        lastName: data.me.profile?.name?.lastName,
        preferredAccount: data.me.bankAccounts.all?.find(
          (account) => account.preferredFundingSource,
        ),
        isExtendedInstallmentPlanConvertedLoan,
      }
    },
  )

  const loanId = selectedData?.loanId

  const [acceptLoan, ,] = useAcceptLoan()

  const accountAndRouting = usePfSelector(accountAndRoutingProvidedSelector)

  const onPressAcceptLoan = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      if (!loanId) {
        return
      }

      const acceptLoanProps: UseAcceptLoanArguments = {
        loanId: loanId,
        accountNumber: accountAndRouting?.account_number,
        routingNumber: accountAndRouting?.routing_number,
      }

      const isSuccessful = await acceptLoan({
        ...acceptLoanProps,
      })

      if (isSuccessful) {
        await onContinue()
      }
    } catch (e) {
      logOfferActivationError(e, 'LoanFinalAccept acceptLoan() failed')
    } finally {
      setIsSubmitting(false)
    }
  }

  const loanFinalAcceptTemplateProps: LoanFinalAcceptTemplateProps = {
    firstName: selectedData?.firstName ?? '',
    isExtendedInstallmentPlanConvertedLoan: selectedData?.isExtendedInstallmentPlanConvertedLoan,
    isLoanInCoastalState: selectedData?.originator === LoanOriginator.Coastal,
    isSubmitting,
    lastName: selectedData?.lastName ?? '',
    onPressAcceptLoan: onPressAcceptLoan,
  }

  return (
    <BaseTemplate
      isError={!!queryError}
      isLoading={isQueryLoading}
      pageTitle={useLoanFinalAcceptTitle(selectedData?.isExtendedInstallmentPlanConvertedLoan)}
      showTileBorder={false}
      onErrorBoundary={(err: Error): void => {
        logOfferActivationError(err, 'LoanFinalAccept render failures')
      }}
      testID="LoanFinalAccept"
    >
      <LoanFinalAcceptTemplate {...loanFinalAcceptTemplateProps} />
    </BaseTemplate>
  )
}

export {LoanFinalAcceptGQLContainer}
