import {TFunction} from 'i18next'
import {RegisterOptions} from 'react-hook-form'

import {validSsnField} from 'src/lib/utils/formValidationUtil'
import {VerifyYourIdentityFormData} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.types'

const labelIsRequired = 'Common:LabelIsRequired'

export const getValidationRules = (
  t: TFunction,
): Record<
  keyof VerifyYourIdentityFormData,
  Omit<RegisterOptions, 'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'>
> => ({
  firstName: {
    required: t(labelIsRequired, {
      replace: {
        label: t('Common:FirstName'),
      },
    }),
  },
  lastName: {
    required: t(labelIsRequired, {
      replace: {
        label: t('Common:LastName'),
      },
    }),
  },
  birthday: {
    required: t(labelIsRequired, {
      replace: {
        label: t('Common:Birthday'),
      },
    }),
  },
  ssn: {
    required: t(labelIsRequired, {
      replace: {
        label: t('Common:SocialSecurityNumber'),
      },
    }),
    validate: (value: string): string | undefined =>
      !validSsnField(value) ? t('VerifyYourIdentity:SSNMustBe9Numbers') : undefined,
  },
  didCertifySSN: {
    required: true,
  },
})

export const cleanUpSSN = (ssn: string): string => {
  // Use a regular expression to match only digits and join them into a single string
  return ssn.replace(/\D/g, '')
}
