import {PfReduxState} from 'src/reducers/types'
import {CardPaymentMethodSliceType} from 'src/lib/onboarding/types'

export const onboardingEmailCodeSentSelector = (state: PfReduxState): boolean =>
  state.lib.onboarding.emailCodeSent

export const onboardingCardPaymentMethodSelector = (
  state: PfReduxState,
): CardPaymentMethodSliceType | undefined => state.lib.onboarding.cardPaymentMethod

export const sentToWebSelector = (state: PfReduxState): boolean => state.lib.onboarding.sentToWeb
