import {
  LoanCustomPaymentIntention,
  LoanCustomPaymentOption,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import i18n from 'src/lib/localization/i18n'
import Log from 'src/lib/loggingUtil'

/**
 * Get the display label for a LoanCustomPaymentOption.
 */
export const getLoanPaymentOptionLabel = (paymentOption: LoanCustomPaymentOption): string => {
  switch (paymentOption.intention) {
    case LoanCustomPaymentIntention.FullBalance:
      return i18n.t('AdhocPaymentAmountEntry:FULL_BALANCE')
    case LoanCustomPaymentIntention.MissedInstallment:
      return i18n.t('AdhocPaymentAmountEntry:MISSED_INSTALLMENT')
    case LoanCustomPaymentIntention.NextInstallment:
      return i18n.t('AdhocPaymentAmountEntry:NEXT_INSTALLMENT')
    case LoanCustomPaymentIntention.OverdueBalance:
      return i18n.t('AdhocPaymentAmountEntry:OVERDUE_BALANCE')
  }
}

/**
 * Get the trackingId for a LoanCustomPaymentOption that is used as an ID to reference individual payment options.
 */
export const getLoanPaymentOptionTrackingId = (paymentOption: LoanCustomPaymentOption): string => {
  return paymentOption.intention
}

const addLoanAdhocPaymentIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - Loan Adhoc Payment`)
  modifiedError.stack = e.stack
  return modifiedError
}

/**
 * Log errors related to Loans Adhoc Payment. Includes standardized log prefix.
 */
export const loanAdhocPaymentLogError = (e: Error, msg?: string): void => {
  Log.error(addLoanAdhocPaymentIdentifierToError(e), msg)
}

export const convertPaymentIntentionStringToEnum = (
  intentionString: string,
): LoanCustomPaymentIntention | undefined => {
  const isLoanCustomPaymentIntention = (str: string): str is LoanCustomPaymentIntention => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    return Object.values(LoanCustomPaymentIntention).includes(str as LoanCustomPaymentIntention)
  }

  if (isLoanCustomPaymentIntention(intentionString)) {
    let intention: LoanCustomPaymentIntention
    // action.trackingId is a string, we need to convert it back to the correct enum value using
    // a type guard
    switch (intentionString) {
      case LoanCustomPaymentIntention.FullBalance:
        intention = LoanCustomPaymentIntention.FullBalance
        break
      case LoanCustomPaymentIntention.MissedInstallment:
        intention = LoanCustomPaymentIntention.MissedInstallment
        break
      case LoanCustomPaymentIntention.NextInstallment:
        intention = LoanCustomPaymentIntention.NextInstallment
        break
      case LoanCustomPaymentIntention.OverdueBalance:
        intention = LoanCustomPaymentIntention.OverdueBalance
        break
    }
    return intention
  }
}

/**
 * Tracks when any loan adhoc payment screen fails to render and we show an error UX instead.
 */
export const trackLoansAdhocScreenRenderFailure = (e: Error, routeName: string): void => {
  TrackAppEvent(
    ManageActiveLoanEvents.loans_adhoc_payment_error_screen_viewed,
    AppEvents.Category.ManageActiveLoan,
    {
      route: routeName,
    },
  )
  loanAdhocPaymentLogError(e, `${routeName} render failed and threw`)
}
