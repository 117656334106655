import {createAction, createSlice} from '@reduxjs/toolkit'

import {Consumer} from '@possible/cassandra'

import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import {
  clearPreviousCardAccountId,
  clearShouldEnrollInCards,
  setShouldEnrollInCards,
  updatePreviousCardAccountId,
} from 'src/lib/card/actions'

export type CardsState = {
  selectedPayNowPaymentMethod?: Consumer.types.CardPaymentMethodType
  shouldEnrollInCards: boolean
  previousCardAccountId?: string
}

const initialState: CardsState = {
  shouldEnrollInCards: false,
}

export const setSelectedPayNowPaymentMethod = createAction<Consumer.types.CardPaymentMethodType>(
  'setSelectedPayNowPaymentMethod',
)
export const clearSelectedPayNowPaymentMethod = createAction('clearSelectedPayNowPaymentMethod')

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CLEAR_USER_ACTION, () => initialState)
      .addCase(setSelectedPayNowPaymentMethod, (state, action) => {
        state.selectedPayNowPaymentMethod = action.payload
      })
      .addCase(clearSelectedPayNowPaymentMethod, (state) => {
        state.selectedPayNowPaymentMethod = undefined
      })
      .addCase(setShouldEnrollInCards, (state) => {
        state.shouldEnrollInCards = true
      })
      .addCase(clearShouldEnrollInCards, (state) => {
        state.shouldEnrollInCards = false
      })
      .addCase(updatePreviousCardAccountId, (state, action) => {
        state.previousCardAccountId = action.payload
      })
      .addCase(clearPreviousCardAccountId, (state) => {
        state.previousCardAccountId = undefined
      })
  },
})

export default cardsSlice.reducer
