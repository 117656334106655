import {createSlice} from '@reduxjs/toolkit'

import {
  enableChaosFailureForQuery,
  disableChaosFailureForQuery,
  enableChaosFailureForMutation,
  disableChaosFailureForMutation,
  enableChaosResponseMockForMutation,
  disableChaosResponseMockForMutation,
} from './actions'

export type GraphqlChaosResponseMock = {
  mockName: string
  mockResponse: any
}
export type GraphqlChaosState = {
  // true/false for any queries that should simulate request failure
  failureQueries: {[queryName: string]: boolean}
  // true/false for any mutations that should simulate request failure
  failureMutations: {[mutationName: string]: boolean}
  // contains mock response objects for mutations that will replace the real response
  mockResponseMutations: {[mutationName: string]: GraphqlChaosResponseMock}
}

const initialState: GraphqlChaosState = {
  failureQueries: {},
  failureMutations: {},
  mockResponseMutations: {},
}

export const GraphqlChaosSlice = createSlice({
  name: 'graphqlChaos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // queries failures
    builder.addCase(enableChaosFailureForQuery, (state, action) => {
      state.failureQueries[action.payload.queryName] = true
    })
    builder.addCase(disableChaosFailureForQuery, (state, action) => {
      delete state.failureQueries[action.payload.queryName]
    })
    // mutations failures
    builder.addCase(enableChaosFailureForMutation, (state, action) => {
      state.failureMutations[action.payload.mutationName] = true
    })
    builder.addCase(disableChaosFailureForMutation, (state, action) => {
      delete state.failureMutations[action.payload.mutationName]
    })
    // mutations response mocks
    builder.addCase(enableChaosResponseMockForMutation, (state, action) => {
      state.mockResponseMutations[action.payload.mutationName] = {
        mockName: action.payload.mockName,
        mockResponse: action.payload.mockResponse,
      }
    })
    builder.addCase(disableChaosResponseMockForMutation, (state, action) => {
      delete state.mockResponseMutations[action.payload.mutationName]
    })
  },
})

export const {actions} = GraphqlChaosSlice

export default GraphqlChaosSlice.reducer
