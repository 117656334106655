import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase, CardTileBaseProps} from 'src/products/card/LoanDash/CardTileBase'

type Props = Pick<CardTileBaseProps, 'loading'> & {
  emailAddress: string
  variant: 'quota' | 'default'
}

const tileTextKeys = {
  default: {
    title: 'CardRejectedTitle',
    body: 'CardRejectedBody',
  },
  quota: {
    title: 'CardRejectedForQuotaTitle',
    body: 'CardRejectedForQuotaBody',
  },
}

export const CardApplicationRejected = ({
  loading,
  emailAddress,
  variant = 'default',
}: Props): ReactNode => {
  const {t} = useTranslation('CardApplicationRejected')
  const {title, body} = tileTextKeys[variant]

  return (
    <CardTileBase
      titleText={t(title)}
      content={t(body, {emailAddress})}
      loading={loading}
      tileMarginHorizontal={0}
      testID="CardApplicationRejectedBase"
    />
  )
}
