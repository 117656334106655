import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SurveyTemplate} from 'src/products/general/components/templates/SurveyTemplate/SurveyTemplate'
import LoanCancelAmountModal from 'src/products/general/components/templates/SurveyTemplate/LoanCancelAmountModal'
import i18next from 'i18next'

const options = [
  i18next.t('CancelLoan:CancelSurveyOptionDontNeedTheMoney'),
  i18next.t('CancelLoan:CancelSurveyOptionGotMoneyElsewhere'),
  i18next.t('CancelLoan:CancelSurveyOptionAmountTooLow'),
  i18next.t('CancelLoan:CancelSurveyOptionDecisionTooLong'),
]

type Props = {
  onContinue: (value: string, other?: string) => Promise<void> | void
  onRedirectAcceptLoan: () => void
  isOnContinueLoading: boolean
}

export const CancelSurveyTemplate: React.FC<Props> = (props) => {
  const {onContinue: handleOnContinue, onRedirectAcceptLoan, isOnContinueLoading} = props

  const {t} = useTranslation('CancelLoan')

  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
  const [isAmountTooLowVisibleModalVisible, setIsAmountTooLowVisibleModalVisible] =
    useState<boolean>(false)

  const handleOnSelectedOption = (value: string): void => {
    if (selectedOption !== value) {
      setSelectedOption(value)

      // when the user selects the "The amount was too low" option we show a modal
      if (
        value.startsWith(t('CancelSurveyOptionAmountTooLow')) &&
        !isAmountTooLowVisibleModalVisible
      ) {
        setIsAmountTooLowVisibleModalVisible(true)
      }
    }
  }

  const secondary = {
    buttonText: t('WantThisLoan'),
    onPress: onRedirectAcceptLoan,
    disabled: isOnContinueLoading,
  }

  return (
    <>
      <SurveyTemplate
        isContinueLoading={isOnContinueLoading}
        onContinue={handleOnContinue}
        onSelectedOption={handleOnSelectedOption}
        secondaryAction={secondary}
        options={options}
        title={t('CancelSurveyTitle')}
        text={t('CancelSurveyText')}
        subText={t('CancelSurveySubText')}
        radioButtonExtraInputLabel={t('WhyCanceling')}
      />
      {/*
          If there is an option for "The amount was too low" and the user selected it
          we show a modal asking if they're sure they want to cancel or want to accept the loan.
       */}
      <LoanCancelAmountModal
        visible={isAmountTooLowVisibleModalVisible}
        onDismiss={(): void => {
          setIsAmountTooLowVisibleModalVisible(false)
          onRedirectAcceptLoan()
        }}
        onConfirm={(): void => {
          setIsAmountTooLowVisibleModalVisible(false)
        }}
      />
    </>
  )
}
