import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import {PFStatusPillProps} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {Color} from 'src/designSystem/types'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import TransactionItem from 'src/designSystem/components/organisms/TransactionsTile/TransactionItem'
/**
 * Each supported type of transaction.
 * Used to determine which status pill and font color to use.
 */
export enum TransactionItemType {
  DepositCompleted = 'DEPOSIT_COMPLETED',
  DepositFailed = 'DEPOSIT_FAILED',
  PaymentUpcoming = 'PAYMENT_UPCOMING',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentCompleted = 'PAYMENT_COMPLETED', // same as PaymentPaid but no statusPill
  PaymentPaid = 'PAYMENT_PAID',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentReversed = 'PAYMENT_REVERSED',
  PaymentDeclined = 'PAYMENT_DECLINED',
  PaymentCancelled = 'PAYMENT_CANCELLED',
  PaymentDisputed = 'PAYMENT_DISPUTED',
  PaymentReviewed = 'PAYMENT_REVIEWED',
}

/**
 * The type of account associated with this transaction.
 * Used to determine which icon to display for a transaction.
 */
export enum TransactionItemAccountType {
  Bank = 'BANK',
  Card = 'CARD',
}

/**
 * A single transaction item.
 */
export type TransactionTileItem = {
  type: TransactionItemType
  title: string // ex: 'First Loan Installment'
  accountDescriptor: string // ex: 'Chase Acct 0000'
  accountType: TransactionItemAccountType
  dateTime: string // ex: '2023-03-01T20:43:22.040947Z'
  amount: string // ex: '120.00'
}

/**
 * Get the appropriate icon config for a transaction item.
 */
export const getTransactionItemIcon = (params: {
  transactionItem: TransactionTileItem
}): ReactElement => {
  const {transactionItem} = params
  let iconConfig: {
    name: SvgIconProps['name']
    colorVariant: SvgIconProps['colorVariant']
    size?: SvgIconProps['size']
  }

  if (transactionItem.type === TransactionItemType.DepositCompleted) {
    // deposit gets checkmark regardless of account type
    iconConfig = {
      name: 'check',
      colorVariant: 'success',
    }
  } else if (transactionItem.type === TransactionItemType.DepositFailed) {
    // failed deposit gets X regardless of account type
    iconConfig = {
      name: 'close',
      colorVariant: 'error',
    }
  } else {
    // all other transactions get the bank or card icon
    if (transactionItem.accountType === TransactionItemAccountType.Bank) {
      // bank account
      iconConfig = {
        name: 'bank',
        colorVariant: 'default',
      }
    } else {
      // card account
      iconConfig = {
        name: 'card',
        colorVariant: 'default',
      }
    }
    // pending transactions get gray icon
    if (
      transactionItem.type === TransactionItemType.PaymentPending ||
      transactionItem.type === TransactionItemType.PaymentUpcoming
    ) {
      iconConfig.colorVariant = 'inactive'
    }
  }
  iconConfig.size = 'medium'
  return <SvgIcon {...iconConfig} />
}

/**
 * Get the appropriate status pill config (if any) for a transaction item.
 */
export const getTransactionItemStatusPillConfig = (params: {
  transactionItem: TransactionTileItem
  t: ReturnType<typeof useTranslation>['t']
}): PFStatusPillProps | null => {
  const {t, transactionItem} = params
  let statusPillConfig: PFStatusPillProps | null
  switch (transactionItem.type) {
    case TransactionItemType.DepositCompleted:
      statusPillConfig = null
      break
    case TransactionItemType.DepositFailed:
      statusPillConfig = null
      break
    case TransactionItemType.PaymentUpcoming:
      statusPillConfig = {
        text: t('Upcoming'),
        color: NamedColors.ASH,
        fontColor: NamedColors.BLACK,
        testID: 'Payment-Upcoming-Status-Pill',
      }
      break
    case TransactionItemType.PaymentPending:
      statusPillConfig = {
        text: t('Pending'),
        color: NamedColors.ASH,
        fontColor: NamedColors.BLACK,
        testID: 'Payment-Pending-Status-Pill',
      }
      break
    case TransactionItemType.PaymentFailed:
      statusPillConfig = {
        text: t('Failed'),
        color: NamedColors.PRODUCT_VERMILLION,
        fontColor: NamedColors.WHITE,
        testID: 'Payment-Failed-Status-Pill',
      }
      break
    case TransactionItemType.PaymentDeclined:
      statusPillConfig = {
        text: t('Declined'),
        color: NamedColors.PRODUCT_VERMILLION,
        fontColor: NamedColors.WHITE,
        testID: 'Payment-Declined-Status-Pill',
      }
      break
    case TransactionItemType.PaymentCancelled:
      statusPillConfig = {
        text: t('Cancelled'),
        color: NamedColors.PRODUCT_VERMILLION,
        fontColor: NamedColors.WHITE,
        testID: 'Payment-Cancelled-Status-Pill',
      }
      break
    case TransactionItemType.PaymentCompleted:
      // no pill. for black/green pill use PaymentPaid
      statusPillConfig = null
      break
    case TransactionItemType.PaymentPaid:
      statusPillConfig = {
        text: t('Paid'),
        color: NamedColors.BLACK,
        fontColor: NamedColors.LIME,
        testID: 'Payment-Paid-Status-Pill',
      }
      break
    case TransactionItemType.PaymentReversed:
      statusPillConfig = {
        text: t('Reversed'),
        color: NamedColors.LIME,
        fontColor: NamedColors.BLACK,
        testID: 'Payment-Reversed-Status-Pill',
      }
      break
    case TransactionItemType.PaymentDisputed:
      statusPillConfig = {
        text: t('Disputed'),
        color: NamedColors.SAND,
        fontColor: NamedColors.BLACK,
        testID: 'Payment-Disputed-Status-Pill',
      }
      break
    case TransactionItemType.PaymentReviewed:
      statusPillConfig = {
        text: t('Reviewed'),
        color: NamedColors.SAND,
        fontColor: NamedColors.BLACK,
        testID: 'Payment-Reviewed-Status-Pill',
      }
      break
  }
  return statusPillConfig
}

/**
 * Construct a single TransactionItem element given a TransactionTileItem object.
 */
export const getTransactionItemElement = (params: {
  t: ReturnType<typeof useTranslation>['t']
  item: TransactionTileItem
}): ReactElement => {
  const {t, item} = params

  const icon = getTransactionItemIcon({
    transactionItem: item,
  })

  const statusPillConfig: PFStatusPillProps | null = getTransactionItemStatusPillConfig({
    transactionItem: item,
    t,
  })
  let descriptionFontColor: Color
  let dataFontColor: Color
  if (
    item.type === TransactionItemType.PaymentPending ||
    item.type === TransactionItemType.PaymentUpcoming
  ) {
    descriptionFontColor = NamedColors.SILVER
    dataFontColor = NamedColors.SILVER
  } else {
    descriptionFontColor = NamedColors.BLACK
    dataFontColor = NamedColors.BLACK
  }

  let subtitle = item.accountDescriptor

  if (item.type === TransactionItemType.DepositCompleted) {
    subtitle = `To ${item.accountDescriptor}`
  }

  const date = new Date(item.dateTime)
  const month = date.toLocaleString('default', {month: 'long'})
  const dateToDisplay = `${month} ${date.getDate()}, ${date.getFullYear()}`

  return (
    <TransactionItem
      icon={icon}
      description={{
        title: item.title,
        subtitle: subtitle,
        subtitleEmphasized: dateToDisplay,
        fontColor: descriptionFontColor,
      }}
      data={{
        value: `$${item.amount}`,
        statusPill: statusPillConfig ?? undefined,
        fontColor: dataFontColor,
      }}
    />
  )
}
