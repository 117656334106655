import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseLinkAchPaymentMethodMutationMutationVariables = Types.Exact<{
  routingNumber: Types.Scalars['String']['input']
  accountNumber: Types.Scalars['String']['input']
  linkedAccountId?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type UseLinkAchPaymentMethodMutationMutation = {
  __typename?: 'Mutation'
  bankAddAchPaymentMethod: {
    __typename?: 'AddPaymentMethodResponse'
    paymentMethod?:
      | {__typename?: 'AchPaymentMethod'; id: string}
      | {__typename?: 'CheckPaymentMethod'; id: string}
      | {__typename?: 'DebitCardPaymentMethod'; id: string}
      | null
  }
}

export const UseLinkAchPaymentMethodMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UseLinkAchPaymentMethodMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'routingNumber'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'accountNumber'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAddAchPaymentMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'routingNumber'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'routingNumber'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'accountNumber'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'accountNumber'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'linkedAccountId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethod'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseLinkAchPaymentMethodMutationMutation,
  UseLinkAchPaymentMethodMutationMutationVariables
>
