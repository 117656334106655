import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {BottomSheetOrOverlay} from 'src/designSystem/components/organisms/BottomSheetOrOverlay/BottomSheetOrOverlay'

type PendingPaymentHelpOverlayProps = {
  children?: ReactNode
  isVisible: boolean
  onDismiss: () => void
}

const PendingPaymentHelpOverlay = ({
  children,
  isVisible,
  onDismiss: handleOnDismiss,
}: PendingPaymentHelpOverlayProps): JSX.Element => {
  const {t} = useTranslation(['CardDashboardHeader', 'Common'])

  return (
    <BottomSheetOrOverlay
      onDismiss={handleOnDismiss}
      dismissButtonText={t('Common:Close')}
      showCloseButton
      title={t('CardDashboardHeader:PendingPayment')}
      titleVariant={'h3'}
      visible={isVisible}
      testID={'PendingPaymentHelpOverlay'}
    >
      <>
        <PFText variant={'p_lg'}>{t('CardDashboardHeader:PendingPaymentsHelpText')}</PFText>
        {children}
      </>
    </BottomSheetOrOverlay>
  )
}

export {PendingPaymentHelpOverlay}
