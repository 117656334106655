import React, {Children, FC, ReactNode} from 'react'
import {View, ViewStyle, FlexStyle, ViewProps, DimensionValue} from 'react-native'

import {gapSizeMap} from 'src/designSystem/layout'
import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import {
  boxAlignToFlexMap,
  BoxAlignType,
  BoxBorderType,
  BoxFillStyleType,
  boxJustifyToFlexMap,
  BoxJustifyType,
  BoxOverflowType,
  boxRadiusMap,
  BoxRadiusType,
  BoxSpecificRadiusType,
  DirectionType,
  PositionType,
} from 'src/designSystem/components/atoms/Box/styles'
import {LevelVariants} from 'src/designSystem/components/atoms/PFElevation/styles'
import PFElevation, {
  PFElevationProps,
} from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import {Color, SizeVariants} from 'src/designSystem/types'

export type BoxProps = React.PropsWithChildren & {
  onLayout?: ViewProps['onLayout']
  position?: PositionType

  grow?: boolean
  fill?: boolean | BoxFillStyleType

  flex?: ViewStyle['flex'] | boolean
  direction?: DirectionType
  gap?: 'none' | SizeVariants
  align?: BoxAlignType
  alignSelf?: BoxAlignType
  justify?: BoxJustifyType
  wrap?: FlexStyle['flexWrap']
  boxStyle?: ViewStyle
  shrink?: FlexStyle['flexShrink']
  overflow?: BoxOverflowType

  background?: Color
  border?: BoxBorderType

  height?: DimensionValue
  width?: DimensionValue

  marginTop?: SizeVariants | number
  marginLeft?: SizeVariants | number
  marginRight?: SizeVariants | number
  marginBottom?: SizeVariants | number
  marginVertical?: SizeVariants | number
  marginHorizontal?: SizeVariants | number
  margin?: SizeVariants | number

  paddingTop?: SizeVariants | number
  paddingLeft?: SizeVariants | number
  paddingRight?: SizeVariants | number
  paddingBottom?: SizeVariants | number
  paddingVertical?: SizeVariants | number
  paddingHorizontal?: SizeVariants | number
  padding?: SizeVariants | number

  radius?: BoxRadiusType | BoxSpecificRadiusType | number
  elevation?: LevelVariants
  testID?: string
}

type BoxGapProps = {
  direction?: DirectionType
  gap: SizeVariants
}

const getGapInPoints = (
  sideSize?: SizeVariants | number,
  groupSize?: SizeVariants | number,
  elementSize?: SizeVariants | number,
): number => {
  if (sideSize) {
    if (typeof sideSize === 'number') {
      return sideSize
    }

    return gapSizeMap[sideSize]
  } else if (groupSize) {
    if (typeof groupSize === 'number') {
      return groupSize
    }

    return gapSizeMap[groupSize]
  } else if (elementSize) {
    if (typeof elementSize === 'number') {
      return elementSize
    }

    return gapSizeMap[elementSize]
  }

  return 0
}

const fillStyle = (fill?: boolean | BoxFillStyleType): ViewStyle | undefined => {
  if (fill !== undefined) {
    return {
      width: fill === true || fill === 'horizontal' ? '100%' : undefined,
      height: fill === true || fill === 'vertical' ? '100%' : undefined,
    }
  }

  return undefined
}

const boxRadiusFromSpecificRadius = (radius: BoxSpecificRadiusType): ViewStyle => {
  return {
    borderTopLeftRadius:
      typeof radius.topLeft === 'string' ? boxRadiusMap[radius.topLeft] : radius.topLeft,
    borderTopRightRadius:
      typeof radius.topRight === 'string' ? boxRadiusMap[radius.topRight] : radius.topRight,
    borderBottomLeftRadius:
      typeof radius.bottomLeft === 'string' ? boxRadiusMap[radius.bottomLeft] : radius.bottomLeft,
    borderBottomRightRadius:
      typeof radius.bottomRight === 'string'
        ? boxRadiusMap[radius.bottomRight]
        : radius.bottomRight,
  }
}

const getRadiusStyle = (
  radius?: BoxRadiusType | BoxSpecificRadiusType | number,
): undefined | ViewStyle => {
  if (radius) {
    if (typeof radius === 'object') {
      return boxRadiusFromSpecificRadius(radius)
    } else if (typeof radius === 'string') {
      return {
        borderRadius: boxRadiusMap[radius],
      }
    }

    return {
      borderRadius: radius,
    }
  }

  return undefined
}

const getBorderStyle = (border?: BoxBorderType): ViewStyle | undefined => {
  if (border) {
    let borderColor: string | undefined
    if (DefaultVariantsColor[border.color]) {
      borderColor = DefaultVariantsColor[border.color] as string
    } else if (border.color) {
      borderColor = border.color
    }
    return {
      borderWidth: border.width,
      borderColor: borderColor,
    }
  }

  return undefined
}

const addGapBetweenChildren = (
  children: ReactNode,
  direction?: DirectionType,
  gap: 'none' | SizeVariants = 'none',
): ReactNode => {
  // If we've got a gap, iterate through children and add the gap
  if (gap !== 'none') {
    const contents: ReactNode[] = []

    let hitFirstValid = false
    Children.forEach(children, (child, index) => {
      if (child) {
        if (!hitFirstValid) {
          hitFirstValid = true
        } else {
          // SMELL: All gaps are equal anyway, so it doesn't matter if the keys are the same
          contents.push(<BoxGap key={`boxgap-${index}`} gap={gap} direction={direction} />)
        }
      }

      contents.push(child)
    })
    return contents
  } else {
    // Otherwise, just render children
    return children
  }
}

const wrapInElevation = (
  displayContent: ReactNode,
  computedStyle: ViewStyle,
  options: {
    boxStyle?: ViewStyle
    elevation?: LevelVariants
    background?: Color
    onLayout?: ViewProps['onLayout']
    testID?: string
    overflow?: BoxOverflowType
  },
): React.ReactElement => {
  const {boxStyle, elevation, background, onLayout, testID, overflow = 'hidden'} = options

  if (elevation !== undefined && elevation !== 0) {
    const elevationProps: Omit<PFElevationProps, 'children'> = {
      level: elevation ?? 0,
      backgroundColor: DefaultVariantsColor[background ?? 'white'] as NamedColors,
      borderRadius: computedStyle.borderRadius,
      borderTopLeftRadius: computedStyle.borderTopLeftRadius,
      borderTopRightRadius: computedStyle.borderTopRightRadius,
      borderBottomLeftRadius: computedStyle.borderBottomLeftRadius,
      borderBottomRightRadius: computedStyle.borderBottomRightRadius,
    }
    //NOTE: we need to combine the styles using array vs spread to work on web
    return (
      <PFElevation {...elevationProps} testID={testID} showOverflow={overflow === 'visible'}>
        <View style={[computedStyle, boxStyle]} onLayout={onLayout}>
          {displayContent}
        </View>
      </PFElevation>
    )
  } else {
    return (
      <View style={[computedStyle, boxStyle]} onLayout={onLayout} testID={testID}>
        {displayContent}
      </View>
    )
  }
}

const BoxGap: FC<BoxGapProps> = ({gap, direction = 'column'}) => {
  if (direction === 'column') {
    return <View style={{height: gapSizeMap[gap]}} />
  }

  return <View style={{width: gapSizeMap[gap]}} />
}

const buildBoxStyle = (props: BoxProps): ViewStyle => {
  const {
    flex,
    position = 'relative',
    grow = false,
    fill,
    direction = 'column',
    align,
    alignSelf,
    justify,
    wrap = 'nowrap',
    shrink,
    overflow = 'visible',
    background,
    border,
    height,
    width,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    marginVertical,
    marginHorizontal,
    margin = 0,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingVertical,
    paddingHorizontal,
    padding = 0,
    radius = 'none',
  } = props

  const computedAlign = align ? boxAlignToFlexMap[align] : undefined
  const computedAlignSelf = alignSelf ? boxAlignToFlexMap[alignSelf] : undefined
  const computedJustify = justify ? boxJustifyToFlexMap[justify] : undefined

  const computedRadius = getRadiusStyle(radius)
  const computedFill = fillStyle(fill)

  const computedMargin = {
    marginTop: getGapInPoints(marginTop, marginVertical, margin),
    marginLeft: getGapInPoints(marginLeft, marginHorizontal, margin),
    marginRight: getGapInPoints(marginRight, marginHorizontal, margin),
    marginBottom: getGapInPoints(marginBottom, marginVertical, margin),
  }

  const computedPadding = {
    paddingTop: getGapInPoints(paddingTop, paddingVertical, padding),
    paddingLeft: getGapInPoints(paddingLeft, paddingHorizontal, padding),
    paddingRight: getGapInPoints(paddingRight, paddingHorizontal, padding),
    paddingBottom: getGapInPoints(paddingBottom, paddingVertical, padding),
  }

  let backgroundColor: string | undefined
  if (!background) {
    backgroundColor = undefined
  } else if (DefaultVariantsColor[background]) {
    backgroundColor = DefaultVariantsColor[background] as string
  } else {
    backgroundColor = background
  }

  const computedBorder = getBorderStyle(border)

  let computedFlex: number | undefined
  if (typeof flex === 'number') {
    computedFlex = flex
  } else if (flex === undefined) {
    computedFlex = undefined
  } else {
    computedFlex = flex ? 1 : 0
  }

  return {
    position,
    flex: computedFlex,
    flexDirection: direction,
    flexGrow: grow ? 1 : undefined,
    alignItems: computedAlign,
    alignSelf: computedAlignSelf,
    justifyContent: computedJustify,
    flexWrap: wrap,
    flexShrink: shrink,
    overflow,
    backgroundColor,
    height,
    width,
    ...computedRadius,
    ...computedBorder,
    ...computedFill,
    ...computedMargin,
    ...computedPadding,
  }
}

/**
 * Generic container component akin to View but with branding styles applied.
 *
 * @note for margin and padding size mappings see layout.ts. 'tiny'=8, 'small'=16, ''medium'=24, 'large'=32
 *
 * @example <Box direction={'row'} justify={'center'} align={'start'}>
 * @example <Box marginRight='little' marginTop='tiny' marginLeft='medium' marginBottom='large'>
 */
const Box: FC<BoxProps> = (props) => {
  const {
    gap = 'none',
    overflow = 'visible',
    elevation,
    direction,
    background,
    boxStyle,
    children,
    onLayout,
    testID,
  } = props

  const computedStyle = buildBoxStyle(props)

  const content = addGapBetweenChildren(children, direction, gap)

  return wrapInElevation(content, computedStyle, {
    boxStyle,
    elevation,
    background,
    onLayout,
    testID,
    overflow,
  })
}

export default Box
