import {useCallback} from 'react'
import {ApolloError, NetworkStatus} from '@apollo/client'
import {GraphQLFormattedError} from 'graphql'

import {nowStr, oneYearAgoStr} from '@possible/cassandra/src/utils/formatting'

import {
  ActiveCardAccountStatus,
  Card,
  CardAccount,
  CardAccountInstallmentPlan,
  CardAccountStatusCode,
  CardAccountStatuses,
  CardStatusCode,
  CardType,
  CardsQueryPolledQuery,
  CardsQueryQuery,
  Consumer,
  DeactivatedCardAccountStatus,
  Maybe,
  OverDuePaymentInfo,
  StatementFragmentFragment,
} from 'src/cassandra'
import {
  getAccountPotentialInstallmentPlan,
  getAvailableBalance,
  getAvailableSpent,
  getCardAccountDashboardStatus,
  getCardAccountPOTStatus,
  getCurrentInstallment,
  getIsDue,
  getIssuedCard,
  getLedgerSpent,
  getLedgerSpentAfterPayments,
  getNextPaymentAmount,
  getNextPaymentDate,
  getOverdueInstallmentInfo,
  getPaymentDates,
  getTotalPaymentAmount,
  getTotalTransactionAmount,
} from 'src/products/card/Dashboard/CardDashboardUtils'
import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'

export type useCardsType = {
  loading: boolean
  userEmail: string | null | undefined
  cardAccount: CardsQueryQuery['me']['cardAccounts']['active']
  cardAccountPolled: CardsQueryPolledQuery['me']['cardAccounts']['active']
  creditLimit: CardAccount['creditLimit']
  activeAccountCurrentInstallmentPaymentsCards: CardAccountInstallmentPlan['installments']
  currentInstallmentPlan: CardAccount['installmentPlans']['current']
  totalPendingPayments: number // should be a string?
  availableBalance: string
  spentAmount: string
  nextPaymentDueDate: string | undefined
  nextPaymentDates: string[]
  accountStatus: CardAccountDashboardStatus | undefined
  pendingPhysicalCard: Card | null | undefined
  hasPendingPhysicalCard: boolean
  currentInstallment: number
  eligibleForInstallments: boolean | undefined
  installmentsRemaining: number
  totalPendingTransactions: number
  ledgerSpent: string
  ledgerSpentAfterPayments: number // should this be a string
  installmentsNumberOfPayments: number
  usableVirtualOrPhysicalCard: Card | null
  activePhysicalCard: Card | null
  autopayEnabled: boolean | undefined
  userActivationDate: string | undefined
  refetchCardsQuery: () => void
  paymentIsDue: boolean
  amountOverdue: Maybe<string> | 0 | undefined
  overdueInstallmentInfo: OverDuePaymentInfo[]
  daysUntilDelinquent: Maybe<number> | undefined
  delinquentNumberOfDays: Maybe<number> | undefined
  daysUntilReportedDelinquent: number | false | null | undefined
  startPollingCardsQuery: (pollInterval: number) => void
  stopPollingCardsQuery: () => void
  paymentDueDate: Maybe<string> | undefined
  nextPaymentAmount: string | undefined
  totalInstallments: number | undefined
  paidInstallments: number | undefined
  activeCardStatus: CardAccountStatuses
  POTamountRemaining: number // should this be a string
  POTtotalPaid: number // should this be a string
  POTpendingAmount: number // should this be a string
  POTtotalOverdue: number // this should probably be a string?
  POTtotalDue: number // should this be a string
  POTInstallments: CardAccountInstallmentPlan['installments']
  accountPOTStatus: CardAccountPOTStatus
  nextPaymentAmountFiltered: string | undefined
  nextPaymentDueDateFiltered: string | undefined
  activeStatement: StatementFragmentFragment | undefined
  overdueStatementInfo: Maybe<OverDuePaymentInfo> | undefined
  refetchPollingCardsQuery: (
    variables?:
      | Partial<
          Consumer.types.Exact<{
            startDate: Consumer.types.Scalars['LocalDate']['input']
            endDate: Consumer.types.Scalars['LocalDate']['input']
          }>
        >
      | undefined,
  ) => Promise<{
    data: CardsQueryPolledQuery
    errors?: readonly GraphQLFormattedError[] | undefined
    error?: ApolloError | undefined
    loading: boolean
    networkStatus: NetworkStatus
    partial?: boolean | undefined
  }>
}

// eslint-disable-next-line complexity
export const useCards = (): useCardsType => {
  const {data: activeCard, refetch} = Consumer.hooks.useCardsQuery({
    variables: {startDate: oneYearAgoStr},
    fetchPolicy: 'cache-first',
  })

  const refetchCardsQuery = useCallback(() => {
    void refetch({startDate: oneYearAgoStr})
  }, [refetch])

  const {
    data: activeCardPolled,
    startPolling: startPollingCardsQuery,
    stopPolling: stopPollingCardsQuery,
    refetch: refetchPollingCardsQuery,
  } = Consumer.hooks.useCardsQueryPolled({
    variables: {startDate: oneYearAgoStr, endDate: nowStr},
    fetchPolicy: 'cache-and-network',
  })

  const userEmail = activeCard?.me.profile?.email?.address
  const cardAccount = activeCard?.me.cardAccounts.active
  const cardAccountPolled = activeCardPolled?.me.cardAccounts.active
  const creditLimit = activeCard?.me.cardAccounts.active?.creditLimit || '0'
  const activeAccountCurrentInstallmentPaymentsCards =
    activeCard?.me.cardAccounts.active?.installmentPlans.current?.installments
  const installmentPlans = activeCard?.me.cardAccounts.active?.installmentPlans
  const currentInstallmentPlan = activeCard?.me.cardAccounts.active?.installmentPlans?.current
  const activeCardStatus = activeCardPolled?.me.cardAccounts.active?.status as CardAccountStatuses
  const pendingPayments = activeCardPolled?.me.cardAccounts.active?.payments.pending
  const pendingTransactions = activeCardPolled?.me.cardAccounts.active?.transactions.pending.items
  const totalPendingPayments = getTotalPaymentAmount(pendingPayments || [])
  const totalPendingTransactions = getTotalTransactionAmount(pendingTransactions || [])
  const availableBalance = getAvailableBalance(activeCardStatus)
  const spentAmount = getAvailableSpent(activeCardStatus)
  const ledgerSpent = getLedgerSpent(activeCardStatus)
  const ledgerSpentAfterPayments = getLedgerSpentAfterPayments(activeCardStatus)
  const nextPaymentDates = getPaymentDates(cardAccount, cardAccountPolled)
  const nextPaymentDueDate = getNextPaymentDate(cardAccount, cardAccountPolled, false) //Refactor this to incorporate smaller functions
  const nextPaymentDueDateFiltered = getNextPaymentDate(cardAccount, cardAccountPolled, true) //Refactor this to incorporate smaller functions

  const accountStatus = getCardAccountDashboardStatus(
    cardAccountPolled?.status as CardAccountStatuses,
  )

  const nextPaymentAmount = getNextPaymentAmount(cardAccount, false)
  const nextPaymentAmountFiltered = getNextPaymentAmount(cardAccount, true)
  const amountOverdue =
    activeCardStatus && 'overduePaymentInfo' in activeCardStatus
      ? activeCardStatus.overduePaymentInfo?.amount
      : 0

  const overdueInstallmentInfo: OverDuePaymentInfo[] = getOverdueInstallmentInfo(
    cardAccountPolled?.status as ActiveCardAccountStatus,
  )

  const accountPOTStatus = getCardAccountPOTStatus(
    cardAccountPolled, //as ActiveCardAccountStatus,
  )

  const installmentsNumberOfPayments =
    getAccountPotentialInstallmentPlan(installmentPlans)?.installments?.length ?? 0

  const pendingPhysicalCard = getIssuedCard(
    cardAccount?.cards?.issued,
    CardType.PhysicalCard,
    CardStatusCode.Pending,
  )
  const userActivationDate = cardAccount?.createdAt
  const hasPendingPhysicalCard = !!pendingPhysicalCard

  const currentInstallment = getCurrentInstallment(
    activeAccountCurrentInstallmentPaymentsCards || [],
  )

  const totalInstallments = activeAccountCurrentInstallmentPaymentsCards?.length
  const installmentsRemaining = totalInstallments ? totalInstallments - currentInstallment : 0
  const paidInstallments =
    totalInstallments && installmentsRemaining >= 0
      ? totalInstallments - installmentsRemaining
      : totalInstallments
  const eligibleForInstallments = cardAccount?.installmentPlans?.eligibleForInstallments

  const activePhysicalCard = getIssuedCard(
    cardAccount?.cards?.issued,
    CardType.PhysicalCard,
    CardStatusCode.Active,
  )
  const virtualCard = getIssuedCard(cardAccount?.cards?.issued, CardType.PhysicalVirtualCard)
  const usableVirtualOrPhysicalCard = activePhysicalCard || virtualCard
  const autopayEnabled = cardAccount?.autopayEnabled
  const loading = activeCardPolled === undefined || activeCard === undefined

  const paymentIsDue = getIsDue(cardAccountPolled?.payments?.schedule?.[0]?.paymentDate)
  const daysUntilDelinquent =
    activeCardStatus && activeCardStatus.__typename === 'ActiveCardAccountStatus'
      ? activeCardStatus.daysUntilDelinquent
      : 0
  const delinquentNumberOfDays =
    activeCardStatus &&
    (activeCardStatus.__typename === 'ActiveCardAccountStatus' ||
      activeCardStatus.__typename === 'DeactivatedCardAccountStatus')
      ? activeCardStatus.delinquentNumberOfDays
      : 0
  const daysUntilReportedDelinquent =
    delinquentNumberOfDays && 30 - delinquentNumberOfDays >= 0 && 30 - delinquentNumberOfDays

  const paymentDueDate =
    activeCardStatus && 'overduePaymentInfo' in activeCardStatus
      ? activeCardStatus.overduePaymentInfo?.paymentDueAt
      : undefined
  const POTamountRemaining = Number(
    activeCard?.me.cardAccounts.active?.installmentPlans.current?.remainingAmount,
  )
  const POTtotalPaid = Number(
    activeCard?.me.cardAccounts.active?.installmentPlans.current?.totalPaid,
  )
  const POTpendingAmount = Number(
    activeCard?.me.cardAccounts.active?.installmentPlans.current?.pendingAmount,
  )

  const POTtotalOverdue = Number(
    activeCard?.me.cardAccounts.active?.installmentPlans.current?.totalOverdueAmount,
  )

  const POTtotalDue = Number(activeCard?.me.cardAccounts.active?.installmentPlans.current?.totalDue)

  const POTInstallments =
    activeCard?.me.cardAccounts.active?.installmentPlans?.current?.installments

  const activeStatement = activeCard?.me.cardAccounts.active?.statements?.active ?? undefined

  let overdueStatementInfo: Maybe<OverDuePaymentInfo> | undefined
  if (activeCardStatus?.code === CardAccountStatusCode.Deactivated) {
    const status = activeCardStatus as DeactivatedCardAccountStatus
    overdueStatementInfo = status?.overdueStatementInfo as OverDuePaymentInfo
  } else if (activeCardStatus?.code === CardAccountStatusCode.Active) {
    const status = activeCardStatus as ActiveCardAccountStatus
    overdueStatementInfo = status?.overduePaymentInfo as OverDuePaymentInfo
  }

  return {
    loading,
    userEmail,
    cardAccount,
    cardAccountPolled,
    creditLimit,
    activeAccountCurrentInstallmentPaymentsCards,
    currentInstallmentPlan,
    totalPendingPayments,
    availableBalance,
    spentAmount,
    nextPaymentDueDate,
    nextPaymentDates,
    accountStatus,
    pendingPhysicalCard,
    hasPendingPhysicalCard,
    currentInstallment,
    eligibleForInstallments,
    installmentsRemaining,
    totalPendingTransactions,
    ledgerSpent,
    ledgerSpentAfterPayments,
    installmentsNumberOfPayments,
    usableVirtualOrPhysicalCard,
    activePhysicalCard,
    autopayEnabled,
    userActivationDate,
    refetchCardsQuery,
    paymentIsDue,
    amountOverdue,
    overdueInstallmentInfo,
    daysUntilDelinquent,
    delinquentNumberOfDays,
    daysUntilReportedDelinquent,
    startPollingCardsQuery,
    stopPollingCardsQuery,
    paymentDueDate,
    nextPaymentAmount,
    totalInstallments,
    paidInstallments,
    activeCardStatus,
    POTamountRemaining,
    POTtotalPaid,
    POTpendingAmount,
    POTtotalOverdue,
    POTtotalDue,
    POTInstallments,
    accountPOTStatus,
    nextPaymentAmountFiltered,
    nextPaymentDueDateFiltered,
    activeStatement,
    overdueStatementInfo,
    refetchPollingCardsQuery,
  }
}
