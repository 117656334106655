import React from 'react'

import {
  BottomSheet,
  BottomSheetProps,
} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import StackedButtons from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import OverlayStackedButtons from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtons'
import {OverlayStackedButtonsProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtons.types'
import {isDeviceWeb} from 'src/lib/utils/platform'

/**
 * BottomSheetOrOverlayStackedButtons is a simple wrapper around BottomSheet and OverlayStackedButtons.
 * BottomSheet's are great on mobile, but they can look awful on web.
 * This component takes a BottomSheet's props and displays a BottomSheet in mobile, and an OverlayStackedButtons in web
 */

type BottomSheetOrOverlayStackedButtonsProps = Omit<BottomSheetProps, 'onDismiss'> & {
  /**
   * Only for storybook!
   */
  _storybookOnly_isWeb?: boolean
} & {
  testID: string
} & Pick<OverlayStackedButtonsProps, 'primary' | 'secondary'>

export const BottomSheetOrOverlayStackedButtons = (
  props: BottomSheetOrOverlayStackedButtonsProps,
): JSX.Element => {
  const {
    _storybookOnly_isWeb: isWebProp,
    title,
    titleVariant,
    graphic,
    testID,
    children,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    visible,
    primary,
    secondary,
    titleAlign,
    showCloseButton,
  } = props

  const isWeb = isWebProp === undefined ? isDeviceWeb() : isWebProp

  if (isWeb) {
    return (
      <OverlayStackedButtons
        visible={visible}
        title={title}
        titleSize={titleVariant}
        titleAlign={titleAlign}
        testID={testID}
        primary={primary}
        secondary={secondary}
      >
        {children}
      </OverlayStackedButtons>
    )
  }
  return (
    <BottomSheet
      title={title}
      titleVariant={titleVariant}
      titleAlign={titleAlign}
      visible={visible}
      // since onDismiss is not a cross-platform API we don't use it here. instead you'll have to
      // dismiss using the primary or secondary buttons
      onDismiss={(): void => {}}
      showCloseButton={showCloseButton}
      showDots={false}
      graphic={graphic}
    >
      {children}
      <StackedButtons primary={primary} secondary={secondary} />
    </BottomSheet>
  )
}
