import EventStreamSingleton, {EventStream} from 'src/lib/EventStream/EventStream'
import {EventStreamMetadata, EventStreamMetadataEvent} from 'src/lib/EventStream/EventStream.types'
import {ServiceInterface} from 'src/lib/Services/Service.types'

/**
 * A base class for services. This class implements the `ServiceInterface`
 * and serves as a good starting point for new services.
 *
 * A good candidate for a service is something that needs to listen to
 * events and update its internal state. For example, the `AmplitudeService`
 * listens to metadata events to update its internal state and listens to
 * analytic events to send them to Amplitude.
 */
export class EventStreamServiceBase implements ServiceInterface {
  /**
   * Initialize the service. Note that once the service starts
   * listening to events it will immediately receive a metadata
   * event. You should use `metadataUpdated` to add/update user
   * properties that the service might need.
   */
  // eslint-disable-next-line @typescript-eslint/require-await
  init = async (): Promise<void> => {
    void Promise.resolve()
  }

  /**
   * App metadata has been updated. Most services will want to
   * override this method to update their internal state. Note
   * that this method is called immediately after `startListening` and
   * will be called again if the metadata changes.
   * @param metadata PARTIAL metadata. This will contain all currently known
   * metadata but you should only use the properties that you care about.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metadataUpdated = (metadata: Partial<EventStreamMetadata>): void => {
    // noop -- override this method
  }

  /**
   * Our metadata event callback -- you should not need to override
   * this as it only calls `metadataUpdated`.
   * @param event The metadata event we received
   */
  metadataListener = (event: EventStreamMetadataEvent): void => {
    this.metadataUpdated(event.data)
  }

  /**
   * Start listening to events. This will immediately trigger a metadata
   * event. Override this method to start listening to other events.
   */
  startListening(eventStream: EventStream = EventStreamSingleton): void {
    eventStream.addListener('metadata', this.metadataListener)
  }

  /**
   * Stop listening to events. Override this method to stop listening to
   * other events.
   */
  stopListening(eventStream: EventStream = EventStreamSingleton): void {
    eventStream.removeListener('metadata', this.metadataListener)
  }
}
