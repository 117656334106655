import {merge, cloneDeep} from 'lodash'

function updateRecord(m, recordUpdate, compareUpdateAt, mode) {
  let exisitingRecord

  let setRecord = false
  if (compareUpdateAt || mode === 'MergeRecord') {
    exisitingRecord = m.get(recordUpdate.id)
  }

  if (!exisitingRecord || !compareUpdateAt || exisitingRecord.updateAt !== recordUpdate.updateAt) {
    setRecord = true
  }

  if (setRecord) {
    recordUpdate = cloneDeep(recordUpdate)

    if (mode === 'MergeRecord' && exisitingRecord) {
      recordUpdate = merge(exisitingRecord, recordUpdate)
    }
    m.set(recordUpdate.id, recordUpdate)
  }
  return m
}

export function update_map_state(
  map,
  record_updates,
  compareUpdateAt = false,
  mode = 'ReplaceRecord',
) {
  //$FlowFixMe
  const m = new Map(map)

  if (Array.isArray(record_updates)) {
    for (const record_update of record_updates) {
      updateRecord(m, record_update, compareUpdateAt, mode)
    }
  } else {
    updateRecord(m, record_updates, compareUpdateAt, mode)
  }

  return m
}
