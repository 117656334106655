import React from 'react'

import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {
  SvgIconSizeMap,
  SvgIconSize,
  SvgIconColorVariantNames,
  iconColorOptions,
  SvgIconNames,
  SvgIconsFull,
} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {getColorValue} from 'src/designSystem/lib/colorUtil'
import {Color} from 'src/designSystem/types'

export type SvgIconProps = {
  name: SvgIconNames
  colorVariant: SvgIconColorVariantNames
  size?: SvgIconSize
  isFilled?: boolean
  customColor?: Color
  testID?: string
}

const SvgIcon = React.memo(
  ({
    name,
    colorVariant,
    size = 'small',
    isFilled,
    customColor,
    testID,
  }: SvgIconProps): JSX.Element => (
    <PFSvg
      testID={testID}
      id={name}
      SvgUri={isFilled ? SvgIconsFull[name].filled : SvgIconsFull[name].default}
      svgProps={{
        height: SvgIconSizeMap[size],
        width: SvgIconSizeMap[size],
        fill: getColorValue(customColor) ?? iconColorOptions[colorVariant].color,
      }}
    />
  ),
)

SvgIcon.displayName = 'SvgIcon'

export {SvgIcon}
