import {UseTranslationResponse} from 'react-i18next'

import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'

import {OverlaySimpleProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'

export const getAchPaymentErrorProps = ({
  t,
  reason,
}: {
  t: UseTranslationResponse<string, string>['t']
  reason?: PaymentMethodsAlertModalErrorReason
}): Partial<OverlaySimpleProps> | undefined => {
  const genericErrorTitle = t('Bank:ErrorPaymentGenericTitle')
  const tryAgainText = t('Common:TryAgain')
  const tryNewAccountText = t('Bank:TryNewAccount')
  const checkYourDetailsText = t('Bank:ErrorCheckYourDetailsText')

  switch (reason) {
    case AddPaymentMethodResultType.AlreadyExists:
      return {
        title: t('Bank:ErrorAchAlreadyLinkedTitle'),
        text: t('Bank:ErrorAchAlreadyLinkedText'),
        okayButtonText: tryNewAccountText,
      }
    case AddPaymentMethodResultType.InvalidCardType:
      return {
        title: t('Bank:ErrorInvalidCardAchTypeTitle'),
        okayButtonText: tryNewAccountText,
      }
    case AddPaymentMethodResultType.InvalidRoutingNumber:
      return {
        title: t('Bank:ErrorAchInvalidRoutingTitle'),
        text: checkYourDetailsText,
        okayButtonText: tryAgainText,
      }
    case AddPaymentMethodResultType.InvalidAccountNumber:
      return {
        title: t('Bank:ErrorAchInvalidAccountTitle'),
        text: checkYourDetailsText,
        okayButtonText: tryAgainText,
      }
    case AddPaymentMethodResultType.BinMatch:
      return {
        title: genericErrorTitle,
        text: t('Bank:ErrorBinMatchText'),
        okayButtonText: tryAgainText,
      }
    case 'GENERIC_ERROR':
    default:
      return {
        title: genericErrorTitle,
        text: checkYourDetailsText,
        okayButtonText: tryAgainText,
      }
  }
}
