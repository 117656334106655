import {useNavigation} from '@react-navigation/native'

import {Consumer} from 'src/cassandra'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {useIdentityVerification} from 'src/products/loans/IdentityVerification/useIdentityVerification'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Log from 'src/lib/loggingUtil'
import {StackNavigationProp} from '@react-navigation/stack'

type UseStatementDocPdfType = () => {
  displayStatementPdf: (cardAccountId: string, statementId: string) => Promise<void>
}

/**
 * Hook that takes a statement id and
 * 1) Retrieves the statement presignedUrl
 * 2) Handles MFA verification
 * 3) Displays the pdf
 * Throws an exception if anything goes wrong.
 */
export const useStatementDocPdf: UseStatementDocPdfType = () => {
  const [getStatementDownloadLink] =
    Consumer.hooks.useCardAccountStatementGenerateDownloadLinkMutation()
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const {makeAuthenticatedNetworkCall} =
    useIdentityVerification<Consumer.types.CardAccountStatementGenerateDownloadLinkMutation>(
      Consumer.types.UserVerificationType.MfaStatements,
    )

  const displayStatementPdf = async (cardAccountId: string, statementId: string): Promise<void> => {
    const r = await makeAuthenticatedNetworkCall(() =>
      getStatementDownloadLink({
        variables: {cardAccountId, statementId},
      }),
    )

    const presignedUrl = r?.data?.cardAccountStatementGenerateDownloadLink?.url
    if (presignedUrl) {
      displayPdf(presignedUrl, navigation)
    } else {
      Log.warn('Unable to find presignedUrl', r?.data)
      throw new Error('Unable to display pdf')
    }
  }

  return {displayStatementPdf}
}
