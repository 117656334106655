import {MomentInput} from 'moment'

import {stringDateOrEmpty} from 'src/lib/time_util'

export const DateToStringFormat = (date: MomentInput, format?: string): string => {
  try {
    return stringDateOrEmpty(date, format)
  } catch (e) {
    return ''
  }
}
