import React, {useState} from 'react'
import {ApolloError} from '@apollo/client'
import {useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import {accountAndRoutingProvidedSelector} from 'src/lib/loans/selector'
import {
  LoanAggregateStatusCode,
  LoanPayment,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {
  PaymentReviewTilaAndLoanAgreementCaDocument,
  PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationDocument,
} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCA.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'
import {PaymentInfoBoxProps} from 'src/products/loans/LoanApprovedActivation/components/PaymentInfoBox/PaymentInfoBox'
import {PaymentReviewTilaAndLoanAgreementCATemplate} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCATemplate'
import {useAcceptLoan} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useGetAgreementDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument'
import {usePfSelector} from 'src/store/utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'

export type PaymentReviewTilaAndLoanAgreementCAGQLContainerProps = {
  onContinue: () => Promise<void>
}

const PaymentReviewTilaAndLoanAgreementCAGQLContainer: React.FC<
  PaymentReviewTilaAndLoanAgreementCAGQLContainerProps
> = (props) => {
  const {onContinue} = props

  const {t} = useTranslation('LoanApproved')
  const [isSubmittingLoan, setIsSubmittingLoan] = useState(false)
  const [hasAcceptedLoanAgreement, setHasAcceptedLoanAgreement] = useState(false)

  const {
    selectedData,
    error: queryError,
    loading: isQueryLoading,
  } = useCassandraQuery(
    PaymentReviewTilaAndLoanAgreementCaDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'PaymentReviewTilaAndLoanAgreementCa query error',
        )
      },
    },
    (data) => {
      let amountApproved: string | undefined = undefined
      let payments:
        | Pick<LoanPayment, 'id' | 'amount' | 'originalDate' | 'rescheduledDate'>[]
        | undefined = undefined
      let loanStatusCode: LoanAggregateStatusCode | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ApprovedLoanAggregateStatus'
      ) {
        amountApproved = data.me.loans.latestActionableLoan.aggregateStatus.amountApproved
        loanStatusCode = data.me.loans.latestActionableLoan.aggregateStatus.code
        payments = data.me.loans.latestActionableLoan?.aggregateStatus.payments.payments
      }
      return {
        amountApproved: amountApproved,
        loanStatusCode: loanStatusCode,
        payments: payments,
        apr: data.me.loans.latestActionableLoan?.agreement?.apr,
        cabFee: data.me.loans.latestActionableLoan?.agreement?.cabFee,
        interestFee: data.me.loans.latestActionableLoan?.agreement?.interestFee,
        loanId: data.me.loans.latestActionableLoan?.id,
        stateCode: data.me.loans.latestActionableLoan?.state,
        totalCost: data.me.loans.latestActionableLoan?.agreement?.totalCost,
        totalOwed: data.me.loans.latestActionableLoan?.agreement?.totalOwed,
        preferredAccount: data.me.bankAccounts.all?.find(
          (account) => account.preferredFundingSource,
        ),
      }
    },
  )

  const [acceptLoan] = useAcceptLoan()

  const {getLoanAgreementDocument} = useGetAgreementDocument()

  const [loanActivationUpdateRequirements, {loading: isUpdatingPreReqs}] = useCassandraMutation(
    PaymentReviewTilaAndLoanAgreementCaUpdateLoanActivationMutationDocument,
  )

  const navigation = useNavigation()

  const loanId = selectedData?.loanId ?? ''

  const payments = selectedData?.payments?.map((payment) => {
    return {
      id: payment.id,
      amount: payment.amount,
      date: payment.rescheduledDate ?? payment.originalDate,
    }
  })

  const loanTilaContentData: LoanTilaContentData = {
    amountApproved: selectedData?.amountApproved,
    apr: selectedData?.apr,
    cabFee: selectedData?.cabFee,
    interestFee: selectedData?.interestFee,
    stateCode: selectedData?.stateCode,
    totalCost: selectedData?.totalCost,
    totalOwed: selectedData?.totalOwed,
  }

  const paymentInfoBoxProps: PaymentInfoBoxProps = {
    payments: payments ?? [],
    preferredAccountInstitutionName: selectedData?.preferredAccount?.institution?.name ?? '',
    preferredAccountMask: selectedData?.preferredAccount?.mask ?? '',
    preferredAccountType: selectedData?.preferredAccount?.type?.toLowerCase() ?? '',
  }

  const accountAndRouting = usePfSelector(accountAndRoutingProvidedSelector)

  const handleLoanAgreementCheckbox = (): void => {
    setHasAcceptedLoanAgreement((current) => {
      const isNewValue = !current
      if (isNewValue === true) {
        TrackAppEvent(
          AppEvents.Name.accept_agreements_accept_loan_agreement_selected,
          AppEvents.Category.Activation,
        )
      }
      return isNewValue
    })
  }

  const onUpdatePreReqs = async (): Promise<void> => {
    try {
      const {errors} = await loanActivationUpdateRequirements({
        variables: {
          loanId,
          acceptLoanAgreement: hasAcceptedLoanAgreement,
        },
      })

      if (errors && errors.length > 0) {
        throw new Error(`${errors[0]?.message} ${errors[0]?.path?.join(' ')}`)
      }
    } catch (error) {
      logOfferActivationErrorAndShowException(
        error,
        'PaymentReviewTilaAndLoanAgreementCAGQLContainer onUpdatePreReqs() failed to submit',
      )
      throw error
    }
  }

  const onSubmitLoan = async (): Promise<void> => {
    setIsSubmittingLoan(true)
    try {
      const isSuccessful = await acceptLoan({
        loanId: loanId,
        accountNumber: accountAndRouting?.account_number,
        routingNumber: accountAndRouting?.routing_number,
      })

      if (isSuccessful) {
        TrackAppEvent(
          AppEvents.Name.review_payment_schedule_screen_completed,
          AppEvents.Category.Activation,
        )
        TrackAppEvent(
          AppEvents.Name.tila_disclosure_screen_completed,
          AppEvents.Category.Activation,
        )
        TrackAppEvent(
          AppEvents.Name.accept_agreements_accept_loan_agreement_selected,
          AppEvents.Category.Activation,
        )
        await onContinue()
      }
    } catch (e) {
      logOfferActivationErrorAndShowException(
        e,
        'PaymentReviewTilaAndLoanAgreementCAGQLContainer onSubmitLoan() failed to submit',
      )
      return
    } finally {
      setIsSubmittingLoan(false)
    }
  }

  const handleOnAcceptLoan = async (): Promise<void> => {
    if (!loanId) {
      logOfferActivationError(
        'PaymentReviewTilaAndLoanAgreementCAGQLContainer handleOnAcceptLoan no loan id',
      )
      return
    }
    await onUpdatePreReqs()
    await onSubmitLoan()
  }

  const handleOnOpenLoanAgreement = async (): Promise<void> => {
    if (!loanId) {
      logOfferActivationErrorAndShowException(
        new Error('No loanId found when retrieving loan agreement'),
      )
      return
    }
    try {
      const {documentUrl} = await getLoanAgreementDocument({loanId, isSinglePaymentAgreement: true})
      TrackAppEvent(
        AppEvents.Name.accept_agreements_view_loan_agreement_selected,
        AppEvents.Category.Activation,
      )
      displayPdf(documentUrl, navigation)
    } catch (e) {
      logOfferActivationErrorAndShowException(
        e,
        'Failed to get loan agreement document, using default fallback',
      )
    }
  }

  const isSubmitting = isSubmittingLoan || isUpdatingPreReqs

  const isDisabled = isQueryLoading || isSubmitting || !hasAcceptedLoanAgreement

  return (
    <BaseTemplate
      isLoading={isQueryLoading}
      isError={!!queryError}
      pageTitle={t('ReviewPayments')}
      showTileBorder={false}
      testID={'PaymentReviewTilaAndLoanAgreementCA'}
    >
      <PaymentReviewTilaAndLoanAgreementCATemplate
        isDisabled={isDisabled}
        isLoanAgreementChecked={hasAcceptedLoanAgreement}
        isSubmitting={isSubmitting}
        loanTilaContentData={loanTilaContentData}
        onPressAcceptLoan={handleOnAcceptLoan}
        onPressLoanAgreement={handleOnOpenLoanAgreement}
        onPressLoanAgreementCheckbox={handleLoanAgreementCheckbox}
        paymentInfoBoxProps={paymentInfoBoxProps}
      />
    </BaseTemplate>
  )
}

export {PaymentReviewTilaAndLoanAgreementCAGQLContainer}
