/* eslint-disable no-type-assertion/no-type-assertion */
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  CardAccount,
  CardPaymentMethodType,
  LinkedAccount,
  LinkedAccountStatusCode,
  Maybe,
} from 'src/cassandra'
import {getDefaultPaymentMethodUtil} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {UseDefaultPaymentAccountDocument} from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/UseDefaultPaymentAccount.gqls'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'

export type UseDefaultPaymentAccountType = () => {
  isLoading: boolean
  defaultPaymentAccount: PaymentAccount
  hasPaymentInstrument: boolean
}

const linkedAccountStatuses: (LinkedAccountStatusCode | undefined | null)[] = [
  LinkedAccountStatusCode.LinkedInUse,
]
export const useDefaultPaymentAccount: UseDefaultPaymentAccountType = () => {
  const {data, loading: isLoading} = useCassandraQuery(UseDefaultPaymentAccountDocument, {
    fetchPolicy: 'cache-first',
  })
  let hasPaymentInstrument = false
  const activeAccount = (data?.me?.cardAccounts?.active ?? null) as Maybe<CardAccount>
  const allCardEligiblePaymentMethods = (data?.me?.paymentMethods?.cardEligible ??
    []) as CardPaymentMethodType[]
  const allAccounts = (data?.me?.bankAccounts?.all ?? []) as LinkedAccount[]
  const allLinkedBankAccounts = allAccounts.filter((account) =>
    linkedAccountStatuses.includes(account?.status),
  )

  const defaultPaymentAccount = getDefaultPaymentMethodUtil(
    activeAccount,
    allCardEligiblePaymentMethods,
    allLinkedBankAccounts,
  )

  if (defaultPaymentAccount?.id) {
    hasPaymentInstrument = defaultPaymentAccount.__typename !== 'LinkedAccount'
  }

  return {isLoading, defaultPaymentAccount, hasPaymentInstrument}
}
