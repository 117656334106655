import React, {FC, useMemo} from 'react'

import {TextInputProps, TouchableWithoutFeedback, View} from 'react-native'

import {RightContentWrapperStyle} from 'src/designSystem/components/atoms/PFFieldBase/styles'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {textButtonProps} from 'src/designSystem/components/atoms/PFFieldBase/types'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type RightContentProps = TextInputProps & {
  icon?: SvgIconProps
  textButton?: textButtonProps
  onPressIcon?: () => void
  testID?: string
}

const RightContent: FC<RightContentProps> = (props: RightContentProps) => {
  const {icon, textButton, onPressIcon, testID} = props

  const rightContentWrapperStyle = useMemo(() => RightContentWrapperStyle(!!icon), [icon])

  if (!icon && !textButton) {
    return null
  }

  const getInnerContent = (): JSX.Element => {
    if (icon) {
      const {name, colorVariant, size} = icon
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return <SvgIcon name={name} colorVariant={colorVariant ?? 'default'} size={size ?? 'small'} />
    } else {
      return (
        <PFText color={'link'} textAlign={'right'} variant={'p_sm'}>
          {textButton?.text}
        </PFText>
      )
    }
  }

  return (
    <TouchableWithoutFeedback onPress={icon ? onPressIcon : textButton?.onPress} testID={testID}>
      <View style={rightContentWrapperStyle}>{getInnerContent()}</View>
    </TouchableWithoutFeedback>
  )
}

export default RightContent
