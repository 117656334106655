import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {CohortUserTpe} from 'src/cassandra'
import {allowedStates} from 'src/lib/us_states'
import {CohortUserTypeQueryDocument} from 'src/products/card/Application/useGetCohortUserType/CohortUserTypeQuery.gqls'

type UseGetCohortUserTypeType = {
  isLoading: boolean
  cohortUserType: CohortUserTpe
}

export const useGetCohortUserType = (): UseGetCohortUserTypeType => {
  const {data, loading: isLoading} = useCassandraQuery(CohortUserTypeQueryDocument)

  const doesUserHaveCompletedLoan =
    data?.me?.loans?.latestActionableLoan?.aggregateStatus.__typename ===
    'ClosedLoanAggregateStatus'
  const userState: string | undefined | null =
    data?.me?.profile?.home?.address?.state ?? data?.me?.onboarding?.loan?.stateSelected
  const isUserInLoanState = !!allowedStates().includes(userState ?? '')
  const hasAddressState = !!userState

  let userType = CohortUserTpe.None

  if (doesUserHaveCompletedLoan) {
    userType = CohortUserTpe.LoanGrad
  } else if (hasAddressState && isUserInLoanState) {
    userType = CohortUserTpe.LoanState
  } else if (hasAddressState) {
    userType = CohortUserTpe.NonLoanState
  }

  return {
    isLoading,
    cohortUserType: userType,
  }
}
