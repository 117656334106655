import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanPayConfirmQueryVariables = Types.Exact<{[key: string]: never}>

export type LoanPayConfirmQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            bankingPaymentInstrumentId: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            account?: {
              __typename?: 'LinkedAccount'
              id: string
              preferredFundingSource?: boolean | null
            } | null
            bankAccount: {__typename?: 'BankAccount'; friendlyName: string; mask: string}
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            bankingPaymentInstrumentId: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
            card: {__typename?: 'LinkedDebitCard'; mask?: string | null; friendlyName: string}
          }
      > | null
    }
    loans: {
      __typename: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'; amountOutstanding: string}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
  }
}

export type LoanPayConfirmMakeCustomPaymentMutationVariables = Types.Exact<{
  customPaymentInput: Types.LoanCustomPaymentInput
}>

export type LoanPayConfirmMakeCustomPaymentMutation = {
  __typename?: 'Mutation'
  loanMakeCustomPayment?: {
    __typename?: 'LoanCustomPaymentResponse'
    amount: string
    initiationDate?: string | null
    loan: {__typename?: 'Loan'; id: string}
  } | null
}

export const LoanPayConfirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanPayConfirm'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'typeCodeFilter'},
                            value: {
                              kind: 'ListValue',
                              values: [
                                {kind: 'EnumValue', value: 'ACH'},
                                {kind: 'EnumValue', value: 'DEBIT_CARD'},
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'account'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'preferredFundingSource'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                      ],
                                    },
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountOutstanding'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanPayConfirmQuery, LoanPayConfirmQueryVariables>
export const LoanPayConfirmMakeCustomPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'LoanPayConfirmMakeCustomPayment'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customPaymentInput'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanCustomPaymentInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanMakeCustomPayment'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customPaymentInput'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customPaymentInput'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loan'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'initiationDate'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanPayConfirmMakeCustomPaymentMutation,
  LoanPayConfirmMakeCustomPaymentMutationVariables
>
