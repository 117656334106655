import {useTranslation} from 'react-i18next'

import {
  Consumer,
  GetMeAction,
  CardPaymentMethodType,
  CardAccountLinkPaymentMethodMutation,
} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'
import {setSelectedPayNowPaymentMethod} from 'src/lib/card/slice'
import {monthDate, yearMonth, formatDate} from 'src/lib/utils/date'
import {usePfDispatch} from 'src/store/utils'
import {useBankAddDebitPaymentMethod} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/mutations/BankAddDebitPaymentMethod/useBankAddDebitPaymentMethod'
import {throwSubmitAddDebitCardError} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'
import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {UseLinkDebitPaymentMethodDocument} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForCardProduct/useLinkDebitPaymentMethod.gqls'

type UseLinkDebitPaymentMethodProps = {
  cardNumber: string
  cardExp: string
  cardCvv: string
  shouldSetSelectedPayNowPaymentMethod?: boolean
  shouldSetPrimaryPaymentMethod?: boolean
}

type UseLinkDebitPaymentMethod = (
  props: UseLinkDebitPaymentMethodProps,
) => Promise<CardAccountLinkPaymentMethodMutation['cardAccountLinkPaymentMethod'] | undefined>

/**
 * Given a LinkedAccount, make this a Debit Linked Payment Method.
 * This requires:
 * 1) Creating an Debit Payment Instrument from the LinkedAccount
 * 2) Creating a Payment Method from the Debit Payment Instrument
 * 3) If shouldSetPrimaryPaymentMethod is true, then set the new payment method to be the user primary payment method
 * If shouldSetSelectedPayNowPaymentMethod is true, then save the new payment method to selectedPayNowPaymentMethod
 */
export const useLinkDebitPaymentMethod = (): {
  linkDebitPaymentMethod: UseLinkDebitPaymentMethod
  isLoading: boolean
} => {
  const [applyLinkPaymentMethod] = Consumer.hooks.useCardAccountLinkPaymentMethodMutation()
  const [submitAddDebitCard] = useBankAddDebitPaymentMethod()
  const dispatch = usePfDispatch()
  const {t} = useTranslation('CardVerifyBankDetails')

  const {selectedData: cardAccountId, loading: isLoading} = useCassandraQuery(
    UseLinkDebitPaymentMethodDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active?.id,
  )

  const linkDebitPaymentMethod = async ({
    cardNumber,
    cardExp,
    cardCvv,
    shouldSetSelectedPayNowPaymentMethod = false,
    shouldSetPrimaryPaymentMethod = false,
  }): Promise<CardAccountLinkPaymentMethodMutation['cardAccountLinkPaymentMethod'] | undefined> => {
    if (!cardAccountId) {
      Log.error('useLinkDebitPaymentMethod(): No card account ID found')
      throw Error('No card account ID found')
    }

    // 1) Creating an Debit Payment Instrument from the LinkedAccount
    const {data, errors} = await submitAddDebitCard({
      variables: {
        input: {
          cardNumber: cardNumber?.replace(/\s/g, ''),
          cardExp: formatDate(cardExp, yearMonth, monthDate),
          cardCvv,
        },
      },
    })

    const responseType = data?.bankAddDebitPaymentMethod?.result.type
    const hasError =
      (errors || responseType) && responseType !== AddPaymentMethodResultType.Successful

    if (hasError) {
      throwSubmitAddDebitCardError(responseType)
    }

    const paymentInstrumentId =
      data?.bankAddDebitPaymentMethod.paymentMethod?.bankingPaymentInstrumentId

    if (!paymentInstrumentId) {
      throw t('UnableToRetrieveInstrument')
    }

    // 2) Creating a Payment Method from the Payment Instrument, and setting makePrimary if necessary
    const r2 = await applyLinkPaymentMethod({
      variables: {
        cardAccountId: cardAccountId,
        userPaymentMethodID: paymentInstrumentId,
        makePrimary: shouldSetPrimaryPaymentMethod,
      },
    })

    const paymentMethod = r2.data?.cardAccountLinkPaymentMethod

    if (r2.errors?.length) {
      // Rely on the caller for error handling
      Log.log(r2.errors?.[0].message)
      throw t('UnableToLinkAccount')
    }

    // save the new CardPaymentMethodType
    if (shouldSetSelectedPayNowPaymentMethod && paymentMethod) {
      // This type assertion is here for legacy reasons. This file will be removed in ENG-17533
      dispatch(setSelectedPayNowPaymentMethod(paymentMethod as CardPaymentMethodType)) // eslint-disable-line no-type-assertion/no-type-assertion
    }

    await dispatch(GetMeAction())

    return paymentMethod
  }

  return {isLoading, linkDebitPaymentMethod}
}
