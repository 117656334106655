import {
  ButtonLockupProps,
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'

export const buttonLockupProperties = (
  primary: ButtonLockupPropsPrimary,
  secondary?: ButtonLockupPropsSecondary,
): ButtonLockupProps | undefined => {
  /* in BackCapture we have only a secondary button */
  if (secondary) {
    return {
      type: 'doubleButton',
      primary,
      secondary: {
        ...secondary,
      },
    }
  } else if (primary) {
    return {
      type: 'singleButton',
      primary,
    }
  }
  return undefined
}
