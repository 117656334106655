import {ApprovedLoanDashboardAggregateStatusQueryHookResult} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {DashboardLoanApprovedProps} from 'src/products/loans/Dashboard/DashboardLoanApproved/DashboardLoanApproved'
import {
  DashboardNavigation,
  loanDashboardLogErrorShowException,
} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import {LoanApproved, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {EmitRedirectionEvent} from 'src/lib/utils/events'

/**
 * Constructs complete props for DashboardLoanApproved given GraphQL response data for DashboardLoanAggregateStatusQuery.
 */
export const getDashboardLoanApprovedProps = (config: {
  approvedLoanDashboardData: Pick<
    ApprovedLoanDashboardAggregateStatusQueryHookResult,
    'approvedLoanAggregateStatus' | 'userProfile' | 'loanTypeVariant'
  >
  navigation: DashboardNavigation
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
}): DashboardLoanApprovedProps => {
  const {approvedLoanDashboardData, navigation, shouldRedirectLoanApplyAndAcceptToWeb} = config
  const {approvedLoanAggregateStatus} = approvedLoanDashboardData

  const props: DashboardLoanApprovedProps = {
    firstName: approvedLoanDashboardData.userProfile?.name?.firstName ?? '',
    counterOfferCodes: approvedLoanAggregateStatus.counterOfferCodes.codes,
    amountApproved: approvedLoanAggregateStatus.amountApproved,
    amountRequested: approvedLoanAggregateStatus.amountRequested,
    expirationDateTime: approvedLoanAggregateStatus.expirationDateTime,
    loanTypeVariant: approvedLoanDashboardData.loanTypeVariant,
    onAcceptOffer: () => {
      void onAcceptOffer({
        navigation,
        shouldRedirectLoanApplyAndAcceptToWeb,
      })
    },
    onDeclineCounterOffer: () => {
      navigation.navigate('CancelSurvey')
    },
    onViewCounterOffer: () => {
      void onViewCounterOffer({
        navigation,
        shouldRedirectLoanApplyAndAcceptToWeb,
      })
    },
    onContinueWithCounterOffer: () => {
      void onViewCounterOffer({
        navigation,
        shouldRedirectLoanApplyAndAcceptToWeb,
      })
    },
  }
  return props
}

/**
 * Send users to a screen in LoanApprovedFlow to view their loan offer and accept if desired.
 */
const viewLoanOffer = async (params: {
  navigation: DashboardNavigation
  hasCounterOffer: boolean
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
}): Promise<void> => {
  const {navigation, hasCounterOffer, shouldRedirectLoanApplyAndAcceptToWeb} = params
  try {
    LoanApproved()
    if (hasCounterOffer) {
      TrackAppEvent(AppEvents.Name.loan_counter_offer_completed, AppEvents.Category.Activation)
    } else {
      TrackAppEvent(AppEvents.Name.loan_approved_completed, AppEvents.Category.Activation)
    }
    if (shouldRedirectLoanApplyAndAcceptToWeb) {
      EmitRedirectionEvent('workflows')
    } else {
      navigation.navigate('ApplicationActivationWorkflow', {screen: 'Loading'})
    }
  } catch (e) {
    void loanDashboardLogErrorShowException(
      e,
      `Failed to view loan ${hasCounterOffer ? 'counter offer' : 'offer'} from dashboard`,
    )
  }
}

/**
 * Allow user to view a loan offer from their application and accept it.
 */
export const onAcceptOffer = async (params: {
  navigation: DashboardNavigation
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
}): Promise<void> => {
  const {navigation, shouldRedirectLoanApplyAndAcceptToWeb} = params
  await viewLoanOffer({
    navigation,
    hasCounterOffer: false,
    shouldRedirectLoanApplyAndAcceptToWeb,
  })
}

/**
 * Allow a user to view a counter offer to their loan application.
 */
export const onViewCounterOffer = async (params: {
  navigation: DashboardNavigation
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
}): Promise<void> => {
  const {navigation, shouldRedirectLoanApplyAndAcceptToWeb} = params
  await viewLoanOffer({
    navigation,
    hasCounterOffer: true,
    shouldRedirectLoanApplyAndAcceptToWeb,
  })
}
