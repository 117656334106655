import {TFunction} from 'i18next'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  ProgressTracker,
  ProgressTrackerProps,
} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTracker'
import MoneyIconDot from 'src/products/general/ApplicationSteps/MoneyIconDot.svg'

const Title = (props: {t: TFunction}): JSX.Element => {
  const {t} = props
  return (
    <Box paddingBottom={'small'} testID={'Application-Steps-Title'}>
      <PFText color="white" variant={'d3'}>
        {t('WelcomeToPossible')}
      </PFText>
      <Box paddingTop={'small'} paddingBottom={'medium'}>
        <PFText color={NamedColors.SKY} variant={'p'}>
          {t('ApplyingIsSimple')}
        </PFText>
      </Box>
      <PFText color="white" variant={'p'}>
        {t('WhatNext')}
      </PFText>
    </Box>
  )
}

export type ApplicationStepsTemplateProps = {
  onContinue: () => Promise<void>
}

const ApplicationStepsTemplate: React.FC<ApplicationStepsTemplateProps> = (props) => {
  const {onContinue} = props
  const {t} = useTranslation(['ApplicationSteps', 'Common'])

  const steps: ProgressTrackerProps['steps'] = [
    {
      key: 'SelectAmount',
      isComplete: true,
      label: '1',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'SelectAmount'}>
            Select the amount
            <PFText variant={'p_sm'}>{` you'd like to borrow`}</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'VerifyPhone',
      isComplete: true,
      label: '2',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'VerifyPhone'}>
            Verify the phone number
            <PFText variant={'p_sm'}> where we can reach you</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'LinkBank',
      isComplete: true,
      label: '3',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm_semibold'}>
          <Trans t={t} i18nKey={'LinkBank'}>
            Link the bank account
            <PFText variant={'p_sm'}> where you get paid</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'SubmitApplication',
      isComplete: true,
      label: '4',
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant={'p_sm'}>
          <Trans t={t} i18nKey={'SubmitApplication'}>
            Submit your application with
            <PFText variant={'p_sm_semibold'}>no FICO check</PFText>
          </Trans>
        </PFText>
      ),
    },
    {
      key: 'GetYourMoney',
      isComplete: true,
      shouldAlignStepItemsCenter: true,
      title: (
        <PFText variant="p_sm_semibold" color={NamedColors.PRODUCT_BLUE}>
          {t('GetYourMoney')}
        </PFText>
      ),
      label: (
        <PFSvg
          SvgUri={MoneyIconDot}
          svgProps={{width: 32, height: 32, resizeMode: 'center'}}
          manualWebResizeMethod={'width'}
        />
      ),
    },
  ]

  return (
    <Page
      title={<Title t={t} />}
      variant={'generic'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: onContinue,
          testID: 'Apply-In-A-Few-Steps-Continue-Button',
        },
      }}
      backgroundColor={'black'}
      safeAreaBackground={'black'}
      noHorizontalPadding
      smallTopGap
    >
      <Box paddingTop={'large'} paddingHorizontal={'medium'} gap={'large'}>
        <ProgressTracker steps={steps} />
      </Box>
    </Page>
  )
}

export {ApplicationStepsTemplate}
