import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  getAllPaymentItems,
  getAllTransactionItems,
  ItemType,
  sortTransactionItems,
} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/utils'
import {useCards} from 'src/products/card/hooks/useCards'

/**
 * Returns a sorted list of all transaction and payment items
 */
export const useAllTransactionItems = (): ItemType[] => {
  const {cardAccountPolled} = useCards()

  const {t} = useTranslation(['TransactionsAndPaymentsList', 'Common'])

  return useMemo(() => {
    const allTransactions = cardAccountPolled?.transactions.all.items ?? []
    const allPayments = cardAccountPolled?.payments?.all || []

    const items = [
      ...getAllPaymentItems(allPayments, t),
      ...getAllTransactionItems(allTransactions, t),
    ]

    return sortTransactionItems(items)
  }, [cardAccountPolled, t])
}
