import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'

export const getErrorState = (
  errorOnEmpty: boolean | undefined,
  isValueEmpty: boolean,
  isFirstAttempt: boolean,
  error?: string,
): boolean => {
  return (errorOnEmpty && isValueEmpty && !isFirstAttempt) || !!error
}

export const getBaseFieldState = (
  errorState: boolean,
  isValueEmpty: boolean,
  hasFocus: boolean,
): PFFieldBaseStateTypes => {
  if (errorState) {
    return PFFieldBaseStates.ERROR
  } else if (hasFocus) {
    return PFFieldBaseStates.ACTIVE
  } else if (isValueEmpty) {
    return PFFieldBaseStates.EMPTY
  } else {
    return PFFieldBaseStates.DEFAULT
  }
}
