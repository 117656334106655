import {createAction} from '@reduxjs/toolkit'

// queries failure
export const enableChaosFailureForQuery = createAction<{queryName: string}>(
  'enableChaosFailureForQuery',
)
export const disableChaosFailureForQuery = createAction<{queryName: string}>(
  'disableChaosFailureForQuery',
)

// mutations failure
export const enableChaosFailureForMutation = createAction<{mutationName: string}>(
  'enableChaosFailureForMutation',
)
export const disableChaosFailureForMutation = createAction<{mutationName: string}>(
  'disableChaosFailureForMutation',
)

// mutations with mocked responses
export const enableChaosResponseMockForMutation = createAction<{
  mutationName: string
  mockName: string
  mockResponse: any
}>('enableChaosResponseMockForMutation')

export const disableChaosResponseMockForMutation = createAction<{mutationName: string}>(
  'disableChaosResponseMockForMutation',
)
