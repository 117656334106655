import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanApplicationProcessingStatusQueryVariables = Types.Exact<{[key: string]: never}>

export type LoanApplicationProcessingStatusQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        status:
          | {__typename: 'ActiveLoanStatus'}
          | {
              __typename: 'ApprovedLoanStatus'
              code: Types.LoanStatusCode
              counterOfferReasonCodes: {
                __typename?: 'AdverseReasonCodeCollection'
                codes: Array<Types.AdverseReasonCode | null>
              }
            }
          | {__typename: 'CancelledLoanStatus'}
          | {__typename: 'ChargedOffLoanStatus'}
          | {__typename: 'ExpiredLoanStatus'}
          | {__typename: 'PaidOffLoanStatus'}
          | {__typename: 'PendingLoanStatus'}
          | {__typename: 'RejectedLoanStatus'}
          | {__typename: 'ReplacedLoanStatus'}
      } | null
    }
  }
}

export const LoanApplicationProcessingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanApplicationProcessingStatus'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedLoanStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'counterOfferReasonCodes'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'codes'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanApplicationProcessingStatusQuery,
  LoanApplicationProcessingStatusQueryVariables
>
