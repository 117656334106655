import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {formatYearMonthString} from 'src/lib/time_util'
import {formatMask} from 'src/lib/user/utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import DefaultTertiaryButton from 'src/products/loans/components/atoms/TertiaryButton/DefaultTertiaryButton'

export type ConfirmDebitCardTemplateProps = {
  confirmBtn: {
    onPress: () => void | Promise<void>
    isDisabled: boolean
    isLoading: boolean
  }
  addNewDebitCardBtn: {
    onPress: () => void | Promise<void>
    isDisabled: boolean
  }
  debitCard: {
    mask?: string
    expirationDate?: string // ex: 202504
    institutionName?: string
  }
  isLoading: boolean
  isError: boolean
}

/**
 * Screen template that confirms that a user wants to use their debit card on file or allows
 * them to continue to add a new debit card.
 */
export const ConfirmDebitCardTemplate: React.FC<ConfirmDebitCardTemplateProps> = (props) => {
  const {confirmBtn, addNewDebitCardBtn, debitCard, isLoading, isError} = props
  const {t} = useTranslation(['ConfirmDebitCard', 'Common'])
  const primaryAction: ButtonLockupPropsPrimary = {
    text: t('Common:Confirm'),
    onPress: () => confirmBtn.onPress(),
    disabled: confirmBtn.isDisabled,
    loading: confirmBtn.isLoading,
    testID: 'ConfirmDebitCard-ConfirmBtm',
  }
  const secondaryAction: ButtonLockupPropsSecondary = {
    buttonText: t('AddNewDebitCard'),
    onPress: () => addNewDebitCardBtn.onPress(),
    disabled: addNewDebitCardBtn.isDisabled,
    testID: 'ConfirmDebitCard-AddNewDebitCardBtn',
  }
  let subText = formatMask(debitCard.mask, true)
  if (debitCard.expirationDate) {
    subText = `${subText} | ${t('Expires')} ${formatYearMonthString(debitCard.expirationDate)}`
  }

  return (
    <BaseTemplate
      isError={isError}
      isLoading={isLoading}
      pageTitle={t('ConfirmDebitCard')}
      showTileBorder={false}
      testID="ConfirmDebitCard"
    >
      <Page
        title={t('ConfirmDebitCard')}
        buttonProps={buttonLockupProperties(primaryAction, secondaryAction)}
        description={<PFText variant="p">{t('VisaDebitCardOnFile')}</PFText>}
        variant="generic"
        smallTopGap
      >
        <View>
          <View style={styles.button}>
            <DefaultTertiaryButton subText={subText} disabled={true}>
              {debitCard.institutionName}
            </DefaultTertiaryButton>
          </View>
        </View>
      </Page>
    </BaseTemplate>
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 5,
  },
})
