import React, {FunctionComponent} from 'react'

import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Box from 'src/designSystem/components/atoms/Box/Box'

type DocLinkProps = {
  onClick: () => void
  linkText: string
}

export const DocumentLink: FunctionComponent<DocLinkProps> = (props) => {
  const {onClick: handleOnClick, linkText} = props
  return (
    <Box marginBottom={'little'}>
      <SvgLink
        linkType="inline"
        onPress={handleOnClick}
        linkText={linkText}
        textVariant="p_sm"
        linkIcon={'external'}
        linkIconSize={'little'}
      />
    </Box>
  )
}
