import {AutopayModel} from '@possible/cassandra/src/types/types.mobile.generated'
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {ButtonAction} from 'src/designSystem/types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardAutopayOutOutModal} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleTemplate/CardAutopayOptOutModal'
import {
  CardPaymentScheduleTemplate,
  CardPaymentScheduleTemplateProps,
} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate'
import {ApplicationActivationWorkflowStackParams} from 'src/workflows/types'

type CardActivationAutopayScheduleTemplateProps = Pick<
  CardPaymentScheduleTemplateProps,
  'paymentFrequency' | 'paymentSchedule' | 'minPayData'
> & {
  isLoading?: boolean
  onPressPrimary: ButtonAction
  onSelectManualPay: () => void
  primaryButtonText?: string
  setMinPayAutopayModel: (selectedOption: AutopayModel) => Promise<void>
  isRoutedFromDashboard?: boolean
}

const CardActivationAutopayScheduleTemplate = (
  props: CardActivationAutopayScheduleTemplateProps,
): JSX.Element => {
  const {
    isLoading,
    onPressPrimary: handleOnPressPrimary,
    onSelectManualPay,
    paymentFrequency,
    paymentSchedule,
    primaryButtonText,
    minPayData,
    setMinPayAutopayModel,
    isRoutedFromDashboard,
  } = props
  const {t} = useTranslation(['CardSetupAutopayments', 'Common'])
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const isUserMinPayProgram = minPayData?.supportsMinPay

  const autopayModel: AutopayModel =
    minPayData?.status?.__typename === 'ActiveCardAccountStatus' ||
    minPayData?.status?.__typename === 'DeactivatedCardAccountStatus'
      ? minPayData?.status.autopayModel
      : AutopayModel.StatementBalance

  const [selectedOption, setSelectedOption] = useState<string>(autopayModel)
  const route =
    useRoute<RouteProp<ApplicationActivationWorkflowStackParams, 'CardActivationAutopaySchedule'>>()
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const handleOnPressInline = (): void => {
    TrackAppEvent(CardEvents.card_autopay_schedule_page_opt_out_cta, AppEvents.Category.Card)
    setIsOverlayVisible(true)
  }

  const handleOnSelectManualPay = (): void => {
    setIsOverlayVisible(false)
    onSelectManualPay()
  }
  const handleMinPayOnPressPrimary = async (): Promise<void> => {
    TrackAppEvent(CardEvents.card_autopay_amount_selected, AppEvents.Category.Card, {
      autopay_amount: selectedOption,
    })
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    await setMinPayAutopayModel(selectedOption as AutopayModel)
    if (handleOnPressPrimary && !isRoutedFromDashboard) {
      await handleOnPressPrimary()
    } else {
      navigation.reset({routes: [{name: 'CardDashboard'}]})
    }
  }
  const handleOptionChange = (option: string): void => {
    setSelectedOption(option)
  }
  const buttonProps: ButtonLockupProps = {
    type: 'inlineLink',
    primary: {
      onPress: isUserMinPayProgram ? handleMinPayOnPressPrimary : handleOnPressPrimary,
      disabled: isLoading,
      loading: isLoading,
      text: primaryButtonText ?? t('Common:Continue'),
    },
    inline: {
      buttonText: t('OptOut'),
      text: '',
      icon: 'arrowRight',
      onPress: handleOnPressInline,
    },
  }
  const autoPaySettingsButtonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      onPress: isUserMinPayProgram ? handleMinPayOnPressPrimary : handleOnPressPrimary,
      disabled: isLoading,
      loading: isLoading,
      text: !isRoutedFromDashboard ? t('Common:Continue') : t('SaveSettings'),
    },
  }

  return (
    <>
      <CardPaymentScheduleTemplate
        buttonProps={
          isRoutedFromDashboard || route.params?.showOptOutOfAutoPay === false
            ? autoPaySettingsButtonProps
            : buttonProps
        }
        paymentFrequency={paymentFrequency}
        paymentSchedule={paymentSchedule}
        testID={'CardActivationAutopayScheduleTemplate'}
        subtitle={isUserMinPayProgram ? t('MinPaySubtitle') : t('SubTitle')}
        title={isUserMinPayProgram && isRoutedFromDashboard ? t('MinPayTitle') : t('Title')}
        minPayData={minPayData}
        onOptionChange={handleOptionChange}
        isRoutedFromDashboard={isRoutedFromDashboard}
      />

      <CardAutopayOutOutModal
        visible={isOverlayVisible}
        onOkay={(): void => setIsOverlayVisible(false)}
        onDismiss={handleOnSelectManualPay}
      />
    </>
  )
}

export {CardActivationAutopayScheduleTemplate}
export type {CardActivationAutopayScheduleTemplateProps}
