import React, {FC} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'

import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor, NamedColors, isColorVariant} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type PFStatsPillVariants = 'solid' | 'outline'

type PFStatusPillPropsBase = {
  text: string
  icon?: SvgIconProps
  iconPlacement?: 'before' | 'after'
  color?: Color
  shape?: 'rounded' | 'rectangle'
  variant?: PFStatsPillVariants
  testID?: string
}
export type PFStatusPillPropsSolid = PFStatusPillPropsBase & {
  /**
   * Only the "solid" variant supports fontColor
   */
  fontColor?: Color
  variant?: 'solid'
}
export type PFStatusPillPropsOutline = PFStatusPillPropsBase & {
  variant: 'outline'
}

export type PFStatusPillProps = PFStatusPillPropsSolid | PFStatusPillPropsOutline

const PFStatusPill: FC<PFStatusPillProps> = (props) => {
  const {text, icon, iconPlacement = 'after', color, shape, variant} = props
  let fontColor: Color | undefined
  if (variant !== 'outline') {
    // eslint-disable-next-line react/destructuring-assignment
    fontColor = props.fontColor
  }

  let textColor: Color = DefaultVariantsColor.white
  if (fontColor) {
    if (isColorVariant(fontColor)) {
      textColor = DefaultVariantsColor[fontColor]
    } else {
      textColor = fontColor
    }
  }

  let variantColor: Color = DefaultVariantsColor.primary
  if (color) {
    if (isColorVariant(color)) {
      variantColor = DefaultVariantsColor[color]
    } else {
      variantColor = color
    }
  }

  let borderRadius: number = 3
  if (shape === 'rounded') {
    borderRadius = 100
  }

  if (variant === 'outline') {
    textColor = variantColor
  }

  const outlinedStyle: ViewStyle =
    variant === 'outline' ? {...styles.outlined, borderColor: variantColor} : {}

  return (
    <View style={styles.statusPillLayout}>
      <View
        style={[
          styles.statusPillWrapper,
          iconPlacement === 'before'
            ? styles.statusPillWrapperIconBeforeText
            : styles.statusPillWrapperIconAfterText,
          {backgroundColor: variantColor, borderRadius},
          outlinedStyle,
        ]}
      >
        <PFText color={textColor} variant={'label_sm'}>
          {text}
        </PFText>
        {icon ? (
          <View
            style={
              iconPlacement === 'before'
                ? styles.statusPillIconWrapperBeforeText
                : styles.statusPillIconWrapperAfterText
            }
          >
            <SvgIcon {...icon} />
          </View>
        ) : null}
      </View>
    </View>
  )
}

export {PFStatusPill}

const styles = StyleSheet.create({
  statusPillLayout: {
    flexDirection: 'row',
  },
  statusPillWrapper: {
    borderRadius: 3,
    paddingHorizontal: 8,
    paddingVertical: 2,
    alignItems: 'center',
  },
  statusPillWrapperIconBeforeText: {
    flexDirection: 'row-reverse',
  },
  statusPillWrapperIconAfterText: {
    flexDirection: 'row',
  },
  statusPillIconWrapperAfterText: {
    marginLeft: 4,
  },
  statusPillIconWrapperBeforeText: {
    marginRight: 4,
  },
  outlined: {
    borderWidth: 1,
    borderColor: DefaultVariantsColor.primary,
    backgroundColor: NamedColors.TRANSPARENT,
  },
})
