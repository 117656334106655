import React, {FC, ReactElement} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'
import {SvgProps} from 'react-native-svg'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {defaultIconSize} from 'src/designSystem/guide'
import {ComputedJustifyType} from 'src/designSystem/components/atoms/Box/styles'
import {SvgIconSize, SvgIconSizeMap} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

export type InterstitialListItem = {
  id: string | number
  title?: string
  subtext: string | ReactElement
  illustration?: React.FC<SvgProps>
  illustrationSize?: SvgIconSize | number
  illustrationJustify?: ComputedJustifyType
  illustrationStyle?: ViewStyle
}

export type InterstitialListProps = {
  items: InterstitialListItem[]
  illustrationSize?: SvgIconSize | number
  illustrationJustify?: ComputedJustifyType
  illustrationStyle?: ViewStyle
}

const PageItem: FC<InterstitialListItem> = (props) => {
  const {
    id,
    title,
    subtext,
    illustration,
    illustrationSize,
    illustrationJustify,
    illustrationStyle = {},
  } = props

  let formattedSubText: string | ReactElement

  if (typeof subtext === 'string') {
    formattedSubText = <PFText variant={'p'}>{subtext}</PFText>
  } else {
    formattedSubText = subtext
  }

  const computedIllustrationStyle = {
    ...illustrationStyle,
    justifyContent: illustrationJustify ?? 'center',
  }

  let width: number

  if (typeof illustrationSize === 'number') {
    width = illustrationSize
  } else if (typeof illustrationSize === 'string') {
    width = SvgIconSizeMap[illustrationSize]
  } else {
    width = defaultIconSize
  }

  return (
    <Box gap={'small'} direction={'row'}>
      {illustration ? (
        <View style={[styles.pageItemSvgView, computedIllustrationStyle]}>
          <PFSvgContain width={width} svg={illustration} />
        </View>
      ) : (
        <PFText variant={'h1'}>{id}</PFText>
      )}
      <Box gap={'little'} boxStyle={styles.pageItemTextBlock}>
        {title ? <PFText variant={'p_semibold'}>{title}</PFText> : null}
        {formattedSubText}
      </Box>
    </Box>
  )
}

const InterstitialList: FC<InterstitialListProps> = (props) => {
  const {items, illustrationSize, illustrationJustify, illustrationStyle} = props
  let width: number

  if (typeof illustrationSize === 'number') {
    width = illustrationSize
  } else if (typeof illustrationSize === 'string') {
    width = SvgIconSizeMap[illustrationSize]
  } else {
    width = defaultIconSize
  }

  return (
    <View style={styles.containerView}>
      <Box gap={'medium'} marginBottom={'medium'}>
        {items.map((item) => (
          <PageItem
            key={item.id}
            illustrationSize={width}
            illustrationJustify={illustrationJustify}
            illustrationStyle={illustrationStyle}
            {...item}
          />
        ))}
      </Box>
    </View>
  )
}

export {InterstitialList}

const styles = StyleSheet.create({
  containerView: {
    width: '100%',
  },
  pageItemSvgView: {
    justifyContent: 'center',
  },
  pageItemTextBlock: {
    flex: 1,
  },
})
