import React, {FC} from 'react'
import {LinkedAccount, AchPaymentMethod, DebitCardPaymentMethod} from 'src/cassandra'
import {AchPaymentMethodField} from 'src/products/card/PaymentMethods/PaymentMethodField/AchPaymentMethodField'
import {DebitCardPaymentMethodField} from 'src/products/card/PaymentMethods/PaymentMethodField/DebitCardPaymentMethodField'
import {LinkedAccountPaymentMethodField} from 'src/products/card/PaymentMethods/PaymentMethodField/LinkedAccountPaymentMethodField'
import {PaymentMethodFieldProps} from 'src/products/card/PaymentMethods/PaymentMethodField/PaymentMethodFieldBase'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'

type Props = PaymentMethodFieldProps & {
  paymentMethod: PaymentAccount
}

export const PaymentMethodField: FC<Props> = (props) => {
  const {paymentMethod, ...otherProps} = props
  const paymentMethodLinkedAccount = paymentMethod as LinkedAccount
  const paymentMethodAch = paymentMethod as AchPaymentMethod
  const paymentMethodDebit = paymentMethod as DebitCardPaymentMethod

  switch (paymentMethod?.__typename) {
    case 'LinkedAccount':
      return (
        <LinkedAccountPaymentMethodField
          paymentMethod={paymentMethodLinkedAccount}
          {...otherProps}
        />
      )
    case 'AchPaymentMethod':
      return <AchPaymentMethodField paymentMethod={paymentMethodAch} {...otherProps} />
    case 'DebitCardPaymentMethod':
      return <DebitCardPaymentMethodField paymentMethod={paymentMethodDebit} {...otherProps} />
    default:
      return null
  }
}
