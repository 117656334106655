import {gql} from '@apollo/client'
import {
  CardAccountFeesFragment,
  CardAccountOffersFragment,
  CardAccountTypeFragment,
  CardAgreementsFragment,
  CardBalanceFragment,
  CardInstallmentPlansFragment,
  CardPaymentMethodsFragment,
  CardPaymentsFragment,
  CardsFragment,
  CardStatementsFragment,
  CardStatusFragment,
  CardTransactionsFragment,
  CardAccountOfferInfoFragment,
} from './cards'

export const LoanStatusFragment = gql`
  fragment LoanStatusFragment on LoanStatus {
    __typename

    ... on BaseLoanStatus {
      code
      occurredAt
    }
    ... on ActiveLoanStatus {
      code
      amountBorrowed
      amountOutstanding
      fundingSettledAt
      occurredAt
    }
    ... on ApprovedLoanStatus {
      code
      occurredAt
      amountApproved
      counterOfferReasonCodes {
        codes
      }
    }
    ... on ChargedOffLoanStatus {
      code
      occurredAt
      amountBorrowed
      amountOutstanding
    }
    ... on PendingLoanStatus {
      code
      occurredAt
    }
    ... on RejectedLoanStatus {
      code
      occurredAt
      reasonCodes {
        codes
      }
    }
    ... on PaidOffLoanStatus {
      code
      occurredAt
      amountBorrowed
    }
    ... on ChargedOffLoanStatus {
      code
      occurredAt
      amountBorrowed
      amountOutstanding
    }
    ... on ExpiredLoanStatus {
      code
      occurredAt
    }
    ... on CancelledLoanStatus {
      code
      occurredAt
    }
    ... on ReplacedLoanStatus {
      code
      occurredAt
    }
  }
`

export const LoanFragment = gql`
  ${LoanStatusFragment}
  fragment LoanFragment on Loan {
    id
    createdAt
    updatedAt
    dueAt
    status {
      ...LoanStatusFragment
    }
    borrower {
      disbursementMethod
      monthlyIncome {
        gross
        startPeriod
        endPeriod
      }
    }
    currency
    amountRequested
  }
`

export const LocationFragment = gql`
  fragment LocationFragment on Location {
    address {
      street1
      street2
      city
      state
      postalCode
      country
      verified
    }
    gps {
      latitude
      longitude
    }
    timeZone {
      id
    }
  }
`

export const UserProfileFragment = gql`
  ${LocationFragment}
  fragment UserProfileFragment on User {
    profile {
      name {
        firstName
        lastName
        middleName
        suffix
      }
      email {
        verified
        address
      }
      phone {
        number
        verified
      }
      birthDate
      home {
        ...LocationFragment
      }
      mailing {
        ...LocationFragment
      }
    }
  }
`

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    identification {
      ssn {
        mask
        verified
      }
      identificationDocument {
        number
        expirationDate
      }
    }
    createdAt
    updatedAt
    status {
      ... on EnabledUserStatus {
        code
      }
      ... on DisabledUserStatus {
        code
        disabledCategory: category
        reason
      }
      ... on FraudulentUserStatus {
        code
        fraudulentCategory: category
        reason
      }
    }
  }
`
export const UserLoansFragment = gql`
  ${LoanFragment}
  fragment UserLoansFragment on User {
    loans {
      latestActionableLoan {
        ...LoanFragment
      }
    }
  }
`

export const UraFragment = gql`
  fragment UraFragment on User {
    userRequestedActions {
      pending {
        id
        userId
        status
        actionType
        actionTitle
        actionBody
        optional
        actionData {
          accountId
          paymentInstrumentId
        }
        ableToCompleteAt
        requestContext {
          type
          id
        }
      }
    }
  }
`

export const UraAllFragment = gql`
  fragment UraAllFragment on User {
    userRequestedActions {
      pending {
        id
        userId
        status
        actionType
        actionTitle
        actionBody
        actionData {
          paymentInstrumentId
          accountId
        }
        optional
        createdAt
        updatedAt
        requestContext {
          type
          id
        }
      }
      all {
        id
        userId
        status
        actionType
        actionTitle
        actionBody
        actionData {
          paymentInstrumentId
          accountId
        }
        optional
        createdAt
        updatedAt
        requestContext {
          type
          id
        }
      }
    }
  }
`

export const LoanPaymentFragment = gql`
  fragment LoanPaymentFragment on LoanPayment {
    id
    userId
    loanId
    status
    currency
    amount
    rescheduledDate
    fundingId
    method
    ordinal
    originalDate
    failureCount
    fees
    interest
    principal
    executeEffectiveDateTime
    paidOffDateTime
    payingOffNow
  }
`

export const CardAccountsFragment = gql`
  ${CardBalanceFragment}
  ${CardsFragment}
  ${CardTransactionsFragment}
  ${CardStatusFragment}
  ${CardStatementsFragment}
  ${CardAgreementsFragment}
  ${CardPaymentsFragment}
  ${CardPaymentMethodsFragment}
  ${CardInstallmentPlansFragment}
  ${CardAccountTypeFragment}
  ${CardAccountFeesFragment}
  ${CardAccountOffersFragment}
  ${CardAccountOfferInfoFragment}
  fragment CardAccountsFragment on User {
    cardAccounts {
      active {
        id
        createdAt
        updatedAt
        creditLimit
        cardNetwork
        balance {
          ...CardBalanceFragment
        }
        status {
          ...CardStatusFragment
        }
        cards {
          ...CardsFragment
        }
        transactions {
          ...CardTransactionsFragment
        }
        statements {
          ...CardStatementsFragment
        }
        agreements {
          ...CardAgreementsFragment
        }
        payments {
          ...CardPaymentsFragment
        }
        paymentMethods {
          ...CardPaymentMethodsFragment
        }
        installmentPlans {
          ...CardInstallmentPlansFragment
        }
        cardAccountType {
          ...CardAccountTypeFragment
        }
        fees {
          ...CardAccountFeesFragment
        }
        autopayEnabled
      }
      offers {
        ...CardAccountOffersFragment
      }
      offerInfo {
        ...CardAccountOfferInfoFragment
      }
    }
  }
`
