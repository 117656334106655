import {transferMethodsType} from 'src/lib/loans/consts'
import {AccountAndRoutingProvidedValue} from 'src/lib/loans/reducers/types'
import {PfReduxState} from 'src/reducers/types'

export const getUserStateAbv = (state: PfReduxState): string | undefined =>
  state.cassandra?.user?.me?.profile?.home?.address?.state ?? undefined

export const paymentMethodSelectedSelector = (state) =>
  state.lib.loans?.paymentMethodSelected as transferMethodsType

export const disbursementMethodSelectedSelector = (
  state: PfReduxState,
): Extract<transferMethodsType, 'ach' | 'interchange'> | undefined =>
  state.lib.loans?.disbursementMethodSelected

export const accountAndRoutingProvidedSelector = (
  state: PfReduxState,
): AccountAndRoutingProvidedValue | undefined => state.lib.loans?.accountAndRoutingProvided
