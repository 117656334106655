import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import {OverlaySimpleProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'

type CardAutopayOutOutModalProps = Pick<OverlaySimpleProps, 'visible' | 'onOkay' | 'onDismiss'>

export const CardAutopayOutOutModal: FC<CardAutopayOutOutModalProps> = (props) => {
  const {visible: isVisible} = props
  const {t} = useTranslation('CardSetupAutopayments')
  useEffect(() => {
    if (isVisible) {
      TrackAppEvent(CardEvents.card_manual_pay_confirmation_popup_viewed, AppEvents.Category.Card)
    }
  }, [isVisible])
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay

  return (
    <Overlay
      title={t('ConfirmTitle')}
      text={isUserMinPayProgram ? t('ConfirmTextMinPay') : t('ConfirmText')}
      okayButtonSize={'medium'}
      okayButtonText={t('ConfirmPrimaryButton')}
      dismissButtonSize={'medium'}
      dismissButtonText={t('ConfirmSecondaryButton')}
      {...props}
      testID="CardAutopayOutOutModal"
    />
  )
}
