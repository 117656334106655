import React, {FC, ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import DualLineText from 'src/designSystem/components/molecules/DualLineText/DualLineText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {smallGap} from 'src/designSystem/layout'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {DebitCardTilePaymentMethod} from 'src/products/MCU/AccountManagementV2/DebitCardTile/DebitCardTile.types'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'
import {HowAutoPayWorksOverlay} from 'src/products/MCU/AccountManagementV2/PaymentMethods/HowAutoPayWorksOverlay/HowAutoPayWorksOverlay'

type Props = {
  debitPaymentMethod: DebitCardTilePaymentMethod
  onPress?: () => void
}

export const DebitCardTile: FC<Props> = ({debitPaymentMethod}) => {
  const [isHowAutoPayWorksVisible, setIsHowAutoPayWorksVisible] = useState<boolean>(false)
  const {card} = debitPaymentMethod

  const {t} = useTranslation(['Bank'])

  const accountName = card?.friendlyName
  const cardNumber = '\u2022\u2022\u2022\u2022' + card.mask

  const handleOnPressHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(true)
  }
  const handleOnDismissHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(false)
  }

  let statusPill: ReactNode = null
  if (debitPaymentMethod.isCardJustAdded) {
    statusPill = <PFStatusPill variant="outline" text={t('AccountTagNew')} />
  } else if (debitPaymentMethod.isDefaultLoanPaymentMethod) {
    statusPill = (
      <Touchable onPress={handleOnPressHowAutoPayWorks}>
        <PFStatusPill
          icon={{
            colorVariant: 'info',
            name: 'moneyRotating',
          }}
          text={t('AccountTagAutoPay')}
          variant="outline"
        />
      </Touchable>
    )
  }
  return (
    <Box
      align={'center'}
      direction={'row'}
      padding={'small'}
      border={{color: NamedColors.ASH, width: 2}}
      radius={4}
      testID="Debit-Account-Tile-View"
    >
      <Box marginRight={smallGap}>
        <SvgIcon name={'card'} colorVariant={'default'} />
      </Box>
      <Box flex={1}>
        {statusPill}
        <DualLineText text={accountName} subText={cardNumber} />
      </Box>
      <HowAutoPayWorksOverlay
        isVisible={isHowAutoPayWorksVisible}
        onDismiss={handleOnDismissHowAutoPayWorks}
      />
    </Box>
  )
}
