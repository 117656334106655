import React, {FC} from 'react'

import PFTextInput, {PFTextInputProps} from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'

type PFEmailInputProps = PFTextInputProps & {
  isEditable?: boolean
}

const PFEmailInput: FC<PFEmailInputProps> = ({label, isEditable = true, ...otherProps}) => {
  //autoCompleteType is for android and textContentType is for iOS
  return (
    <Box gap="little">
      <PFTextInput
        {...otherProps}
        label={label}
        autoCompleteType="email"
        autoCapitalize="none"
        textContentType="username"
        keyboardType="email-address"
        autoCorrect={false}
        editable={isEditable}
        editableStyle={isEditable}
        testID="input_email"
        iconTestID="Icon-Email"
      />
    </Box>
  )
}

export default PFEmailInput
