import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {usePfDispatch} from 'src/store/utils'
import {Color} from 'src/designSystem/types'
import {ContextualizedLogException} from 'src/lib/errors'

type Props = {
  color?: Color
}

const HeaderLogoutButton: FC<Props> = (props: Props) => {
  const {color} = props
  const {t} = useTranslation('Common')
  const dispatch = usePfDispatch()

  const handleLogout = (): void => {
    dispatch(Logout(false)).catch(
      ContextualizedLogException('HeaderLogoutButton: Failed to log out'),
    )
  }
  return (
    <ButtonLink onPress={handleLogout} testID="Logout-Button" size="medium" color={color}>
      {t('Logout')}
    </ButtonLink>
  )
}

export default HeaderLogoutButton
