import {singularSdk, SingularConfig} from 'singular-sdk'

class SingularSDK {
  // If a race condition occurs where we try to set user id prior to SDK initialization, this will ensure that we set the user id after initialization
  userId: string | null = null

  init(secret: string, apiKey: string) {
    const config = new SingularConfig(apiKey, secret, 'com.possiblefinance.app')
    if (this.userId) {
      config.withCustomUserId(this.userId)
    }
    singularSdk.init(config)
  }

  setIdentity(userID: string) {
    singularSdk.login(userID)
    singularSdk.event('LOGIN', {
      user_id: userID,
    })
    this.userId = userID
  }

  logEvent(name: string, params?: any) {
    singularSdk.event(name, params)
  }

  unsetUserId() {
    singularSdk.logout()
    this.userId = null
  }
}
export default new SingularSDK()
