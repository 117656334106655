import {createAction} from '@reduxjs/toolkit'
export enum CardWaitListStatus {
  NOT_SET,
  NOT_REGISTERED,
  REGISTERED,
}

export const setShouldEnrollInCards = createAction('setShouldEnrollInCards')
export const clearShouldEnrollInCards = createAction('clearShouldEnrollInCards')
export const updatePreviousCardAccountId = createAction<string>('updatePreviousCardAccountId')
export const clearPreviousCardAccountId = createAction('clearPreviousCardAccountId')
