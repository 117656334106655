import React, {FC, useState} from 'react'
import {StyleSheet, View} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage, PushPage} from 'src/navigation/NavHelper'
import {ShowException} from 'src/lib/errors'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import Image from 'src/products/general/Image/Image'
import Log from 'src/lib/loggingUtil'
import {GetFullStateFromAbv} from 'src/lib/us_states'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ImageInfo} from 'src/lib/utils/formData'
import {userIdSelector} from 'src/api/selectors/selectors'
import {usePfSelector} from 'src/store/utils'
import {uploadAndCreateDocument} from 'src/lib/utils/files'
import {
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {Consumer} from '@possible/cassandra'

type Props = StackScreenProps<MainStackParamList, 'ProofOfResidenceConfirm'> & {
  onRetake: () => void
  imageInfo: ImageInfo
  selectedState: string
  noAddressFill?: boolean
  onComplete: (address: object | undefined) => void
}

const ProofOfResidenceConfirm: FC<Props> = (props) => {
  const [busy, setBusy] = useState<boolean>(false)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const userId = usePfSelector(userIdSelector)
  const {onRetake, navigation, imageInfo, noAddressFill, onComplete, selectedState} = props
  const {t} = useTranslation(['PersonalInformation', 'Common'])
  const retakeAction = (): void => {
    onRetake?.()
    PopPage(navigation)
  }
  const onAddressComplete = (address?: object): void => {
    navigation.goBack()
    onComplete?.(address)
  }

  const onConfirm = async (): Promise<void> => {
    try {
      setBusy(true)

      if (!uploaded) {
        const response = await uploadAndCreateDocument(
          imageInfo,
          UserDocumentType.Legal,
          UserDocumentSubType.ProofOfResidency,
          userId,
        )
        if (response) {
          setUploaded(true)
        } else {
          setUploaded(false)
        }
      }

      if (noAddressFill) {
        onAddressComplete()
      } else {
        const fullSelectedState = GetFullStateFromAbv(selectedState) || selectedState
        PushPage(navigation, 'AddressUpdate', {
          title: t('ConfirmInStateAddress', {selectedState: fullSelectedState}),
          onComplete: (address: Consumer.types.Address) => onAddressComplete(address),
          address: {
            state: selectedState,
          },
        })
      }
    } catch (e) {
      Log.log(e)
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  const action = {
    onPress: onConfirm,
    text: t('SubmitResidencyPhoto'),
    disabled: busy,
    loading: busy,
  }

  const secondaryAction = {
    onPress: retakeAction,
    buttonText: t('Common:UpdateImage'),
    disabled: busy,
  }

  return (
    <GenericNonModalTemplate
      title={t('MakeSureItIsClear')}
      description={t('PictureDescription')}
      actionBlock={buttonLockupProperties(action, secondaryAction)}
    >
      <View style={styles.confirm_content_view}>
        <Image style={styles.image} source={{uri: imageInfo?.uri}} />
      </View>
    </GenericNonModalTemplate>
  )
}

export default withForwardedNavigationParams<Props>()(ProofOfResidenceConfirm)

const styles = StyleSheet.create({
  confirm_content_view: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
  },
  image: {
    aspectRatio: 1,
    resizeMode: 'cover',
    width: '100%',
  },
})
