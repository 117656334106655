import {ApolloProvider} from '@apollo/client'
import React, {FC} from 'react'

import {useCassandraClient} from '@possible/cassandra'
import {userIsLoggedIn} from 'src/api/selectors/selectors'
import {usePfSelector} from 'src/store/utils'

/**
 * Provides the Apollo client for the private authenticated GraphQL API
 * to the children of this component. Wraps children in an ApolloProvider.
 */
export const UseCassandra: FC<React.PropsWithChildren> = (props) => {
  const {children} = props

  const isLoggedIn = usePfSelector(userIsLoggedIn)
  const [apolloClient] = useCassandraClient()

  if (
    // if we don't have an apollo client yet we can't wrap children in the ApolloProvider that they need.
    !apolloClient ||
    // due to sequencing of app init + login, isLoggedIn may be false briefly while the apolloClient exists.
    // we wait until both are true before wrapping children in the ApolloProvider to avoid unexpected behavior
    !isLoggedIn
  ) {
    return <>{children}</>
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
