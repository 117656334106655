import React, {ReactElement} from 'react'

import SocialButton from 'src/designSystem/components/atoms/SocialButton/SocialButton'
import {isDeviceAndroid} from 'src/lib/utils/platform'
import {Divider} from 'src/products/MCU/RegistrationOrLogin/Divider'
import {SocialProvidersType} from 'src/api/MobileGatewayAPI/actions'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'

type GoogleLoginButtonProps = {
  onHandleSocialLogin: (provider: SocialProvidersType) => Promise<void>
  isBusy: boolean
  isDisabled: boolean
  marginTop?: BoxProps['marginTop']
}
export const GoogleLoginButton = ({
  onHandleSocialLogin: handleSocialLogin,
  isBusy,
  isDisabled,
  marginTop,
}: GoogleLoginButtonProps): ReactElement | null => {
  return isDeviceAndroid() ? (
    <Box marginTop={marginTop ?? 'large'}>
      <SocialButton
        variant={'google'}
        mode="secondary"
        onLogin={handleSocialLogin}
        busy={isBusy}
        disabled={isDisabled}
        testID="Btn-Google-Login"
      />
      <Divider />
    </Box>
  ) : null
}
