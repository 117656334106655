export function getNumberOrZero(value: number): number {
  return value ?? 0
}

export const areNumbersInequal = (a: number | undefined, b: number | undefined): boolean => a !== b

/**
 * Convert a decimal type to a dollar type
 * Ex.
 * 100 to $100.00
 * 100.0 to $100.00
 * 100.034 to $100.03
 * the returned string always includes a negative sign (for negative numbers) and the $ sign
 * cents will be included by default unless explicitly stated otherwise
 */
export const convertToDollarAmt = (amt?: string | number | null, includeCents = true): string => {
  if (amt === undefined || amt === null || amt === '') return ''
  const decimalPlaces = includeCents ? 2 : 0
  try {
    const amtF = typeof amt === 'string' ? parseFloat(amt) : amt
    let dollarRes = `$${amtF.toFixed(decimalPlaces)}`

    if (Math.sign(amtF) >= 0) {
      dollarRes = `$${amtF.toFixed(decimalPlaces)}`
    }
    if (Math.sign(amtF) < 0) {
      dollarRes = `-$${Math.abs(amtF).toFixed(decimalPlaces)}`
    }

    return dollarRes
  } catch {
    return ''
  }
}
