import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {Trans, useTranslation} from 'react-i18next'
import {FlatList, StyleSheet, Text, View} from 'react-native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import AppNavActions from 'src/nav/AppNavActions'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {callNowHandler as callNow, emailNow} from 'src/products/card/Dashboard/util'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

type Props = StackScreenProps<MainStackParamList, 'PayOverTimeFAQ'>

export const FaqQuestion: FC<React.PropsWithChildren<{title: string}>> = ({title, children}) => {
  return (
    <BasicTile padding="medium">
      <PFText variant="p_semibold" color={NamedColors.SILVER}>
        {title}
      </PFText>
      <Box marginTop="small">{children}</Box>
    </BasicTile>
  )
}

const FaqTrans: FC<{i18nKey: string}> = ({i18nKey}) => {
  const {t} = useTranslation('PayOverTimeFAQ')
  return (
    <PFText variant="p">
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={{
          bold: <PFText variant="p_semibold" />,
          mailto: (
            <SvgLink
              // eslint-disable-next-line react/jsx-handler-names
              onPress={emailNow}
              linkType={'inline'}
              linkText="support@possiblefinance.com"
            />
          ),
          // eslint-disable-next-line react/jsx-handler-names
          tellto: <SvgLink onPress={callNow} linkType={'inline'} linkText="1-855-961-5354" />,
        }}
      />
    </PFText>
  )
}

type FaqUlProps = {
  data: {key: string}[]
}
const FaqUl: FC<FaqUlProps> = ({data}) => (
  <FlatList
    data={data}
    renderItem={({item}): JSX.Element => (
      <FaqLi>
        <FaqTrans i18nKey={item.key} />
      </FaqLi>
    )}
  />
)

const FaqLi: FC<React.PropsWithChildren> = ({children}) => (
  <Box marginTop="small" direction="row">
    <Box width="5%">
      <Text style={styles.bullet}>{`\u2022`}</Text>
    </Box>
    <Box paddingRight="small" width="95%">
      {children}
    </Box>
  </Box>
)

export const PayOverTimeFAQQuestionTwo: FC = () => {
  const cardCreditReportingFeatureFlag = useIsFeatureFlagEnabled('card-credit-reporting-ui-updates')
  const {t} = useTranslation(['PayOverTimeFAQ', 'Common'])
  return (
    <FaqQuestion title={t('Question2Title')}>
      {cardCreditReportingFeatureFlag ? null : (
        <>
          <Box marginBottom="small">
            <FaqTrans i18nKey="Question2Desc1" />
          </Box>
          <Box>
            <FaqTrans i18nKey="Question2Desc2" />
          </Box>
        </>
      )}
      <FaqUl data={[{key: 'Question2Desc3'}, {key: 'Question2Desc4'}]} />

      <Box marginTop="small">
        <FaqTrans i18nKey="Question2Desc5" />
      </Box>

      <FaqUl data={[{key: 'Question2Desc6'}, {key: 'Question2Desc7'}]} />
    </FaqQuestion>
  )
}

export const PayOverTimeFAQ: FC<Props> = ({navigation}) => {
  const {t} = useTranslation(['PayOverTimeFAQ', 'Common'])

  return (
    <Page
      variant="generic"
      smallTopGap
      title={
        <>
          <View>
            <PFText variant="h1">{t('title1')}</PFText>
          </View>
          <View>
            <PFText variant="h1">{t('title2')}</PFText>
          </View>
        </>
      }
      titleTextAlignment="center"
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:OkGotIt'),
          onPress: () => AppNavActions.pop(navigation),
        },
      }}
    >
      <Box gap="medium">
        <FaqQuestion title={t('Question1Title')}>
          <FaqTrans i18nKey="Question1Desc" />
        </FaqQuestion>

        <PayOverTimeFAQQuestionTwo />

        <FaqQuestion title={t('Question3Title')}>
          <Box marginBottom="small">
            <FaqTrans i18nKey="Question3Desc1" />
          </Box>
          <Box marginBottom="small">
            <FaqTrans i18nKey="Question3Desc2" />
          </Box>
          <Box>
            <FaqTrans i18nKey="Question3Desc3" />
          </Box>
          <FaqUl data={[{key: 'Question3Desc4'}]} />
          <Box marginTop="small">
            <FaqTrans i18nKey="Question3Desc5" />
          </Box>
        </FaqQuestion>

        <FaqQuestion title={t('Question4Title')}>
          <Box marginBottom="small">
            <FaqTrans i18nKey="Question4Desc1" />
          </Box>
          <Box marginBottom="small">
            <Text>
              <FaqTrans i18nKey="Question4Desc2" />
            </Text>
          </Box>
        </FaqQuestion>

        <Box marginBottom="large" />
      </Box>
    </Page>
  )
}

const styles = StyleSheet.create({
  bullet: {
    fontSize: 20,
  },
})
