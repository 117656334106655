import React from 'react'
import {useTranslation} from 'react-i18next'
import {NamedColors} from 'src/designSystem/colors'
import Button from 'src/designSystem/components/atoms/Button/Button'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'
import {PFStatusPillProps} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {ButtonAction} from 'src/designSystem/types'

export type ApplicationTileProps = {
  header: string
  creditAmount: number | string
  monthlyFee?: number
  onApplyClick: ButtonAction
  buttonDisabled?: boolean
  pillText?: string
}

const ApplicationTile: React.FC<ApplicationTileProps> = (props) => {
  const {header, creditAmount, monthlyFee, onApplyClick, buttonDisabled, pillText} = props
  const {t} = useTranslation(['ProductSelection', 'Common'])

  const pill: PFStatusPillProps | undefined = pillText
    ? {text: pillText, color: NamedColors.SAND, fontColor: 'textPrimary'}
    : undefined

  return (
    <BasicTile padding="small" gap="tiny" align="center" pill={pill}>
      <PFText variant="p">{header}</PFText>
      <PFText variant="h3">${creditAmount}</PFText>
      <HeaderSpacer height={2} />
      {monthlyFee && (
        <PFText variant="p_semibold">
          ${monthlyFee} {t('MonthlyFee')}
        </PFText>
      )}
      <Button mode="primary" size="medium" onPress={onApplyClick} disabled={buttonDisabled}>
        {t('Apply').toUpperCase()}
      </Button>
    </BasicTile>
  )
}

export default ApplicationTile
