import React from 'react'
import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import FastLoans from 'src/assets/illustrations/FastLoans.svg'
import GreenCalendar from 'src/assets/illustrations/GreenCalendar.svg'
import GreenClock from 'src/assets/illustrations/GreenClock.svg'
import GreenPassword from 'src/assets/illustrations/GreenPassword.svg'
import GreenSuccess from 'src/assets/illustrations/GreenSuccess.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {
  PFInfoCapsule,
  PFInfoCapsuleVariant,
} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import AppNavActions from 'src/nav/AppNavActions'

type CardHowPayOverTimeWorksProps = StackScreenProps<MainStackParamList, 'CardHowPayOverTimeWorks'>

const POTRestrictionsLink = (): JSX.Element => {
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList, 'CardHowPayOverTimeWorks'>>()
  const {t} = useTranslation('CardHowPayOverTimeWorks')
  const handleOnPress = (): void => AppNavActions.push(navigation, 'PayOverTimeFAQ')
  const link = (
    <Box flex>
      <SvgLink
        linkType={'inline'}
        linkText={t('POTRestrictions')}
        onPress={handleOnPress}
        textVariant={'p_sm'}
      />
    </Box>
  )
  return (
    <Box marginTop="enormous">
      <PFInfoCapsule
        element={link}
        variant={PFInfoCapsuleVariant.Element}
        onPress={handleOnPress}
      />
    </Box>
  )
}

const HowPayOverTimeWorksList = (): JSX.Element => {
  const {t} = useTranslation(['CardHowPayOverTimeWorks', 'Common'])

  const graphicalItems = [
    {
      subtext: t('EnrollInAutopay'),
      illustration: GreenCalendar,
    },
    {
      subtext: t('WillBeLocked'),
      illustration: GreenPassword,
    },
    {
      subtext: t('YourBalanceAndMembership'),
      illustration: GreenClock,
    },
    {
      subtext: t('YourCardIsReady'),
      illustration: GreenSuccess,
    },
  ]

  return (
    <>
      <Box gap="medium" marginBottom="small">
        {graphicalItems.map(({subtext, illustration}) => (
          <Box gap="little" key={subtext}>
            <Box fill="horizontal" align="center">
              <PFSvgContain svg={illustration} width="10%" />
            </Box>
            <Box gap="medium" align="center" paddingHorizontal={20}>
              <PFText variant="p_lg" textAlign="center">
                {subtext}
              </PFText>
            </Box>
          </Box>
        ))}
      </Box>
      <POTRestrictionsLink />
    </>
  )
}

const CardHowPayOverTimeWorks = ({navigation}: CardHowPayOverTimeWorksProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_how_pay_over_time_works_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardHowPayOverTimeWorks', 'Common'])

  return (
    <Page
      barStyle={'light-content'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: 'Continue',
          onPress: (): void => {
            TrackAppEvent(CardEvents.card_how_pay_over_time_works_page_cta, AppEvents.Category.Card)
            PushPage(navigation, 'CardPayOverTimeDetails')
          },
        },
      }}
      variant={'generic'}
      title={t('HowPaymentsWorkPossibleCard')}
      // eslint-disable-next-line testing-library/consistent-data-testid
      testID="how_pot_works_page"
      titleTextAlignment={'center'}
      smallTopGap={true}
    >
      <Box align="center">
        <PFSvgContain svg={FastLoans} width="80%" />
      </Box>
      <HowPayOverTimeWorksList />
    </Page>
  )
}
export {CardHowPayOverTimeWorks}
