import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  StateSpecificAgreement,
  AgreementType,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {
  AcceptLoanAndStateAgreementsBaseTemplate,
  AcceptLoanAndStateAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsBaseTemplate'

export type AcceptLoanAndStateAgreementsFLTemplateProps = Omit<
  AcceptLoanAndStateAgreementsBaseTemplateProps,
  // these are specified by this FL template so they don't propagate to the parent
  'stateSpecificAgreements' | 'loanAgreementTestID'
>
export const AcceptLoanAndStateAgreementsFLTestIds = {
  firstArbitrationAgreement: 'FLORIDA_ARBITRATION_AGREEMENT',
  secondStatutoryNoticeAgreement: 'FLORIDA_STATUTORY_NOTICE',
  thirdLoanAgreement: 'FLORIDA_LOAN_AGREEMENT',
} as const

const AcceptLoanAndStateAgreementsFLTemplate: React.FC<
  AcceptLoanAndStateAgreementsFLTemplateProps
> = (props) => {
  const {
    isLoading,
    isError,
    isSubmitting,
    loanAmountBorrowed,
    onOpenAgreementDocument: handleOnOpenAgreementDocument,
    onAcceptAndContinue: handleOnAcceptAndContinue,
  } = props
  const {t} = useTranslation('LoanApproved')
  const stateSpecificAgreements: StateSpecificAgreement[] = [
    {
      title: t('ArbitrationAgreement'),
      documentTypeToDownload: LoanDocumentType.LoanAgreement,
      agreementType: AgreementType.ArbitrationAgreement,
      id: AcceptLoanAndStateAgreementsFLTestIds.firstArbitrationAgreement,
    },
    {
      title: t('FloridaStatutoryNotice'),
      documentTypeToDownload: LoanDocumentType.LoanAgreement,
      agreementType: AgreementType.StatutoryNotice,
      id: AcceptLoanAndStateAgreementsFLTestIds.secondStatutoryNoticeAgreement,
    },
  ]
  const loanAgreementTestID = AcceptLoanAndStateAgreementsFLTestIds.thirdLoanAgreement

  return (
    <AcceptLoanAndStateAgreementsBaseTemplate
      testID="AcceptLoanAndStateAgreementsFL"
      stateSpecificAgreements={stateSpecificAgreements}
      loanAgreementTestID={loanAgreementTestID}
      loanAmountBorrowed={loanAmountBorrowed}
      isLoading={isLoading}
      isError={isError}
      isSubmitting={isSubmitting}
      onOpenAgreementDocument={handleOnOpenAgreementDocument}
      onAcceptAndContinue={handleOnAcceptAndContinue}
    ></AcceptLoanAndStateAgreementsBaseTemplate>
  )
}

export {AcceptLoanAndStateAgreementsFLTemplate}
