import {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {clearPreviousCardAccountId, updatePreviousCardAccountId} from 'src/lib/card/actions'
import {getPreviousCardAccountId} from 'src/lib/card/selectors'

/**
 * In CardProcessing, the app checks when the active account status is set to
 * Rejected or Approved. But users who are reapplying will have an active account with
 * status=rejected (their old account). This will switch to a new active account with status=pending
 * once the server processes the application.
 * But in the meantime, make sure the app is only checking the status on the new active account
 * and ignoring the old rejected account.
 */
type UseEnsureIsNewCardAccountType = () => {
  setPreviousAccount: (accountId?: string) => void
  clearPreviousAccount: () => void
  isNewActiveAccount: (newAccountId: string) => boolean
}
export const useEnsureIsNewCardAccount: UseEnsureIsNewCardAccountType = () => {
  const dispatch = useDispatch()
  const previousAccountId = useSelector(getPreviousCardAccountId)

  const setPreviousAccount = useCallback(
    (accountId?: string) => {
      if (accountId) {
        dispatch(updatePreviousCardAccountId(accountId))
      }
    },
    [dispatch],
  )

  const clearPreviousAccount = useCallback(() => {
    dispatch(clearPreviousCardAccountId())
  }, [dispatch])

  const isNewActiveAccount = useCallback(
    (newAccountId: string) => {
      if (previousAccountId) {
        return !!newAccountId && previousAccountId !== newAccountId
      } else {
        return !!newAccountId
      }
    },
    [previousAccountId],
  )

  return {setPreviousAccount, clearPreviousAccount, isNewActiveAccount}
}
