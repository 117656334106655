import React, {useCallback, useEffect, useState} from 'react'

import {AddAchPaymentMethodForLinkedAccountResult} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'
import {
  BankAggregatorAccountSelectionGQLContainerProps,
  BankAggregatorAccountSelectionTemplateProps,
} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'
import {BankAggregatorAccountSelectionTemplate} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelectionTemplate'
import {useAccountSelectionAddAchPaymentMethodForLinkedAccount} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/mutations/AccountSelectionAddAchPaymentMethodForLinkedAccount/useAccountSelectionAddAchPaymentMethodForLinkedAccount'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

/**
 * GQL container for the BankAggregatorAccountSelection screen that integrates with a GQL
 * mutation to create an ACH payment method for the selected account.
 */
const BankAggregatorAccountSelectionGQLContainer: React.FC<
  BankAggregatorAccountSelectionGQLContainerProps
> = (props: BankAggregatorAccountSelectionGQLContainerProps) => {
  const {
    title,
    description,
    accountsFromBankAggregator,
    continueBtn,
    failedVerifyBtn,
    testID,
    syncingOverlay,
    showNewAccountIndicator,
    onVerifyRoutingAndAccountFailed,
    onVerifyRoutingAndAccountSucceeded,
    onSelectAccount,
    analytics,
  } = props

  const [
    submitCreateAchPaymentMethodForLinkedAccount,
    {loading: isSubmitting, error: submissionError, data: submissionResponseData},
  ] = useAccountSelectionAddAchPaymentMethodForLinkedAccount()

  const [hasTappedVerifyBtnAfterFailure, setHasTappedVerifyBtnAfterFailure] =
    useState<boolean>(false)

  const handleOnSelectAccount = (selectedAccountId: string): void => {
    TrackAppEvent(
      analytics.selectAccount.name,
      analytics.selectAccount.category,
      analytics.selectAccount.eventArgs,
    )
    onSelectAccount?.(selectedAccountId)
  }
  // we will try to create a new AchPaymentMethod for this LinkedAccount using the bankCreateAchPaymentMethodForLinkedAccount mutation
  const handleSubmitCreateAchPaymentMethod: NonNullable<
    BankAggregatorAccountSelectionTemplateProps['continueBtn']['onContinue']
  > = useCallback(
    async ({selectedAccountId}): Promise<void> => {
      // reset failure state before submitting
      setHasTappedVerifyBtnAfterFailure(false)
      const res = await submitCreateAchPaymentMethodForLinkedAccount({
        variables: {
          input: {
            linkedAccountId: selectedAccountId,
          },
        },
      })

      if (
        res.data?.bankAddAchPaymentMethodForLinkedAccount.result ===
        AddAchPaymentMethodForLinkedAccountResult.Successful
      ) {
        if (!res?.data?.bankAddAchPaymentMethodForLinkedAccount.achPaymentMethod) {
          // new payment method should exist but we do a sanity check just in case
          logAddPaymentMethodError(
            new Error(
              'BankAggregatorAccountSelectionGQLContainer successfully submitted mutation but did not get back a new payment method',
            ),
          )
          return
        }
        TrackAppEvent(
          analytics.verifyRoutingAccountSucceeded.name,
          analytics.verifyRoutingAccountSucceeded.category,
          analytics.verifyRoutingAccountSucceeded.eventArgs,
        )
        // successfully created a new payment method
        void onVerifyRoutingAndAccountSucceeded({
          linkedAccountId: selectedAccountId,
          achPaymentMethod: {
            // typescript thinks this may be undefined but we know it won't be. force it to please ts
            __typename: 'AchPaymentMethod',
            ...res.data.bankAddAchPaymentMethodForLinkedAccount.achPaymentMethod,
          },
        })
      }
    },
    [onVerifyRoutingAndAccountSucceeded, submitCreateAchPaymentMethodForLinkedAccount, analytics],
  )

  // if there is only one account we automatically select it and immediately try to create the new
  // ach payment method which will try to retrieve its routing + account from plaid
  useEffect(() => {
    if (accountsFromBankAggregator.length === 1) {
      Log.info(
        'BankAggregatorAccountSelectionGQLContainer only has one account, immediately submitting mutation to create AchPaymentMethod',
      )
      void handleSubmitCreateAchPaymentMethod({
        selectedAccountId: accountsFromBankAggregator[0].id,
      })
    }
  }, [accountsFromBankAggregator, handleSubmitCreateAchPaymentMethod])

  // when continue btn is pressed we will try to create an AchPaymentMethod which will try
  // to get the routing + account numbers from plaid.
  const continueBtnConfig: BankAggregatorAccountSelectionTemplateProps['continueBtn'] = {
    testID: continueBtn.testID,
    onContinue: ({selectedAccountId}) => {
      void continueBtn.onContinue?.({selectedAccountId})
      void handleSubmitCreateAchPaymentMethod({
        selectedAccountId,
      })
    },
  }

  const didCreatingAchPaymentMethodFail =
    submissionError ||
    (submissionResponseData &&
      submissionResponseData?.bankAddAchPaymentMethodForLinkedAccount.result !==
        AddAchPaymentMethodForLinkedAccountResult.Successful)

  const syncingOverlayToUse: BankAggregatorAccountSelectionTemplateProps['syncingOverlay'] = {
    ...syncingOverlay,
    isVisible: false,
    didSyncingFail: false,
  }
  if (isSubmitting) {
    syncingOverlayToUse.isVisible = true
  } else if (didCreatingAchPaymentMethodFail) {
    syncingOverlayToUse.isVisible = true
    syncingOverlayToUse.didSyncingFail = true
  }
  if (hasTappedVerifyBtnAfterFailure) {
    // after creating an ach payment method fails and the user taps
    // the "Verify account" btn we will hide the syncing overlay modal
    syncingOverlayToUse.isVisible = false
  }

  return (
    <BankAggregatorAccountSelectionTemplate
      testID={testID}
      title={title}
      description={description}
      continueBtn={continueBtnConfig}
      accountsFromBankAggregator={accountsFromBankAggregator}
      showNewAccountIndicator={showNewAccountIndicator}
      syncingOverlay={syncingOverlayToUse}
      onSelectAccount={handleOnSelectAccount}
      failedVerifyBtn={{
        testID: failedVerifyBtn.testID,
        onFailedVerifyAccount: ({selectedAccountId, selectedAccountNumberMask}): void => {
          setHasTappedVerifyBtnAfterFailure(true)
          void onVerifyRoutingAndAccountFailed({
            linkedAccountId: selectedAccountId,
            accountNumberMask: selectedAccountNumberMask,
            achPaymentMethod: submissionResponseData?.bankAddAchPaymentMethodForLinkedAccount
              .achPaymentMethod
              ? {
                  // typescript thinks this may be undefined but we know it won't be. force it to please ts
                  __typename: 'AchPaymentMethod',
                  ...submissionResponseData?.bankAddAchPaymentMethodForLinkedAccount
                    .achPaymentMethod,
                }
              : undefined,
          })
        },
      }}
    />
  )
}

export {BankAggregatorAccountSelectionGQLContainer}
