const RNFS = {
  DocumentDirectoryPath: '',
  stat: async (path: string) => {
    return window.localStorage.getItem(path) ? {isFile: () => true} : {isFile: () => false}
  },
  writeFile: async (path: string, body: string) => {
    window.localStorage.setItem(path, body)
  },
  unlink: async (path: string) => {
    window.localStorage.removeItem(path)
  },
  readFile: async (path: string) => {
    return window.localStorage.getItem(path)
  },
  exists: async (path: string): Promise<boolean> => {
    return window.localStorage.getItem(path) !== null
  },
}

export default RNFS
