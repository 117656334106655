import React, {FC} from 'react'

import TertiaryButton, {
  TertiaryButtonProps,
} from 'src/products/loans/components/atoms/TertiaryButton/TertiaryButton'

const DefaultTertiaryButton: FC<TertiaryButtonProps> = (props) => {
  return <TertiaryButton {...props} />
}

export default DefaultTertiaryButton

DefaultTertiaryButton.defaultProps = {
  rightIcon: {
    name: 'chevronRight',
    colorVariant: 'default',
  },
}
