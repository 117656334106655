import {StyleSheet, ViewStyle} from 'react-native'
import {black, white} from 'src/designSystem/colors'
import {smallGap} from 'src/designSystem/layout'

export const outlineSize = 4
export const baseBorderRadius = 8
export const MIN_INPUT_HEIGHT = 50
export const MAX_DROPDOWN_HEIGHT = 200

export type AbsoluteViewDimensionTypes = {
  width: number
  height: number
  top: number
  left: number
}

export default StyleSheet.create({
  innerWrapper: {
    overflow: 'hidden',
  },
})

export const OuterWrapperAbsoluteViewStyle = (
  dimensions: AbsoluteViewDimensionTypes | undefined,
): ViewStyle => {
  return {
    borderRadius: baseBorderRadius,
    marginHorizontal: outlineSize,
    overflow: 'visible',
    position: 'absolute',
    top: dimensions ? dimensions.top + dimensions.height : 0,
    width: dimensions ? dimensions.width : 0,
    left: dimensions ? dimensions.left : 0,
  }
}

export const ActiveOptionStyle = (isActiveValue: boolean, isPlaceholder: boolean): ViewStyle => ({
  backgroundColor: isActiveValue && !isPlaceholder ? black : white,
  minHeight: MIN_INPUT_HEIGHT,
  paddingHorizontal: smallGap / 2,
  justifyContent: 'center',
})
