import {GraphqlChaosResponseMock} from '@possible/chaos'
import {getBankAddDebitPaymentMethodErrorResponse} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/mutations/BankAddDebitPaymentMethod/BankAddDebitPaymentMethod.testdata'

import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'
import {PaymentType} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {getBankAddAchPaymentMethodErrorResponse} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPage.testdata'

export const getBankAddPaymentMethodMock = (
  paymentType: PaymentType,
): GraphqlChaosResponseMock[] => {
  const bankAddPaymentMethodErrorResponse =
    paymentType === 'ACH'
      ? getBankAddAchPaymentMethodErrorResponse
      : getBankAddDebitPaymentMethodErrorResponse

  return [
    {
      mockName: 'AlreadyExists',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.AlreadyExists,
      }),
    },
    {
      mockName: 'BinMatch',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.BinMatch,
      }),
    },
    {
      mockName: 'ExpiredCard',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.ExpiredCard,
      }),
    },
    {
      mockName: 'InvalidAccountNumber',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.InvalidAccountNumber,
      }),
    },
    {
      mockName: 'InvalidRoutingNumber',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.InvalidRoutingNumber,
      }),
    },
    {
      mockName: 'InvalidCardType',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.InvalidCardType,
      }),
    },
    {
      mockName: 'InvalidExpirationDate',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.InvalidExpirationDate,
      }),
    },
    {
      mockName: 'InvalidMask',
      mockResponse: bankAddPaymentMethodErrorResponse({
        resultType: AddPaymentMethodResultType.InvalidMask,
      }),
    },
  ]
}
