import React, {ReactElement, useEffect, useState} from 'react'
import moment from 'moment'
import {Trans, useTranslation} from 'react-i18next'

import {
  LoanActiveStatusPaymentAlertItem,
  LoanActiveStatusPaymentAlerts,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'

import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanCalendarAndPaymentTileProps,
  LoanCalendarAndPaymentTile,
} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile'
import {
  LoanProgressTileProps,
  LoanProgressTile,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import {LoanHistoryTileProps} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'

import {
  LoanAlertTile,
  LoanAlertTileProps,
} from 'src/products/loans/Dashboard/LoanAlertTile/LoanAlertTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {DashboardLoanActiveProps} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.types'
import {
  determineLoanAlertStatuses,
  useRedirectActiveLoanWebUserToAppIfAppropriate,
  useDashboardLoanActiveAlertsAnalytics,
} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.utils'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'
import {AdvanceAlertTile} from 'src/products/loans/Dashboard/AdvanceAlertTile/AdvanceAlertTile'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {getLoanDisbursementAvailableDate} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'
import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'

const DashboardLoanActive: React.FC<DashboardLoanActiveProps> = (
  props: DashboardLoanActiveProps,
) => {
  const {
    loanId,
    shouldShowMoneyIsOnTheWayMessage,
    navigation,
    payments,
    amountProcessing,
    alerts,
    actions,
    disbursement,
    hasPreviousLoan,
    onUpdatePaymentDates,
    onMakeAPayment,
    onShowChargedOffExplanation,
    onShowDefaultExplanation,
    onContactUs,
    onRelinkBankAccount,
    onViewLoanHistory,
    onUpdatePaymentMethod,
    loanTypeVariant,
    subscriptions,
  } = props
  const {t} = useTranslation(['DashboardLoanActive', 'LoanApproved'])

  const isAdvance = isAdvanceVariant(loanTypeVariant)
  const [disbursementSettlementDate, setDisbursementSettlementDate] =
    useState<DisbursementSettlementDateType>()
  const [showMoneyOnTheWay, setShowMoneyOnTheWay] = useState(false)

  const isInterchangeDisbursement = disbursement?.method === LoanPaymentMethod.DebitCard

  const disbursementSettlementDateStr = moment
    .utc(disbursementSettlementDate?.adjustedSettlementDatetime)
    .local()
    .format('dddd, MMMM Do')

  const tiles: ReactElement[] = []
  // track when this dashboard is viewed
  useEffect(() => {
    TrackAppEvent(
      ManageActiveLoanEvents.active_dashboard_viewed,
      AppEvents.Category.ManageActiveLoan,
    )

    if (loanId && shouldShowMoneyIsOnTheWayMessage) {
      void getLoanDisbursementAvailableDate({
        loanId: loanId,
        activeDate: moment().utc(),
      }).then((response) => {
        setDisbursementSettlementDate(response)
        setShowMoneyOnTheWay(true)
      })
    }
  }, [loanId, shouldShowMoneyIsOnTheWayMessage])

  useDashboardLoanActiveAlertsAnalytics({alerts})

  // some users on web should be sent back to the mobile app when their loan is active.
  // this is typically for Android users who were sent to web during loan application
  useRedirectActiveLoanWebUserToAppIfAppropriate()

  const {isChargedOff, isInDefault, isSuspended, hasPaymentMethodIssue} =
    determineLoanAlertStatuses({alerts})

  // note that alerts are ordered by the BE so we can just display as received
  alerts.items.forEach((thisAlert: LoanActiveStatusPaymentAlertItem) => {
    if (thisAlert.type !== LoanActiveStatusPaymentAlerts.None) {
      const thisAlertTileProps: LoanAlertTileProps = {
        alert: thisAlert,
        actions,
        loanPayments: payments,
        onRelinkBankAccount,
        onContactUs,
        onUpdateLoanPaymentDates: onUpdatePaymentDates,
        onMakeAPayment,
        onUpdatePaymentMethod,
        testID: `DashboardLoanActive-LoanAlertTile-${thisAlert.type}`,
      }
      const alertTile = isAdvance ? (
        <AdvanceAlertTile {...thisAlertTileProps} />
      ) : (
        <LoanAlertTile {...thisAlertTileProps} />
      )
      tiles.push(alertTile)
    }
  })

  const loanCalendarTileProps: LoanCalendarAndPaymentTileProps = {
    isChargedOff,
    isInDefault,
    hasPaymentMethodIssue,
    loanPaymentDates: payments,
    actions,
    amountProcessing,
    onMakeAPayment,
    onUpdatePaymentDates,
    testID: 'DashboardLoanActive-LoanCalendarAndPaymentTile',
  }
  tiles.push(<LoanCalendarAndPaymentTile {...loanCalendarTileProps} />)

  const loanProgressTileProps: LoanProgressTileProps = {
    onShowDefaultExplanation,
    onShowChargedOffExplanation,
    onReschedule: onUpdatePaymentDates,
    onContactUs,
    onRelinkBankAccount,
    payments,
    amountProcessing,
    isInDefault,
    isChargedOff,
    isSuspended,
    fundingFailed: hasPaymentMethodIssue,
    testID: 'DashboardLoanActive-LoanProgressTile',
  }
  tiles.push(<LoanProgressTile {...loanProgressTileProps} />)

  const loanHistoryTileProps: LoanHistoryTileProps = {
    loanPayments: payments,
    hasPreviousLoan,
    onViewLoanHistory,
    loanDisbursement: disbursement,
    loanTypeVariant,
    subscriptions,
    testID: 'DashboardLoanActive-LoanHistoryTile',
  }
  tiles.push(<LoanHistoryTile {...loanHistoryTileProps} />)

  return (
    <>
      <TileListView testID="DashboardLoanActive" list={tiles} />
      <BottomSheet
        visible={showMoneyOnTheWay}
        onDismiss={() => {
          navigation?.setParams({showMoneyIsOnTheWayMessage: false})
          setShowMoneyOnTheWay(false)
        }}
        showDots={false}
        dismissButton={{text: t('LoanApproved:ContinueToDashboard')}}
        fullWidthTitle={false}
        testID="Money-On-The-Way-Bottom-Sheet"
      >
        <Box gap={'small'}>
          <SvgIcon name={'checkWithCircle'} colorVariant={'success'} size={'enormous'} />

          <PFText variant="h3">{t('MoneyOnTheWay')}</PFText>
          {isInterchangeDisbursement ? (
            <PFText variant={'p'}>{t('MoneyOnTheWayInterchangeDate')}</PFText>
          ) : (
            <PFText variant={'p'}>
              <Trans
                i18nKey={'MoneyOnTheWayDate'}
                values={{settleDateStr: disbursementSettlementDate}}
              >
                It should show up in your account by the end of the day on {''}
                <PFText variant={'p_semibold'}>{{disbursementSettlementDateStr}}</PFText>
              </Trans>
            </PFText>
          )}
        </Box>
      </BottomSheet>
    </>
  )
}

export {DashboardLoanActive}
