import {Platform} from 'react-native'
import MobileDetect from 'mobile-detect'

const android = 'android'
const ios = 'ios'
const web = 'web'
const md = new MobileDetect(window.navigator.userAgent)
const androidOS = 'AndroidOS'
const iOS = 'iOS'

export const rootTagNameWeb = 'root'

export function isDeviceAndroid() {
  return Platform.OS === android
}

export function isDeviceIOS() {
  return Platform.OS === ios
}

export function isDeviceWeb() {
  return Platform.OS === web
}

export function isDeviceNotWeb() {
  return Platform.OS !== web
}

export function isDeviceWebOnDesktop() {
  return isDeviceWeb() && !md.mobile()
}

export function isDeviceAndroidWeb() {
  return md.mobile() && md.os() === androidOS
}

export function isDeviceiOSWeb(): boolean {
  return !!md.mobile() && md.os() === iOS
}
