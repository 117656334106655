import {difference} from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {Consumer} from '@possible/cassandra'

import {ContextualizedLogException} from 'src/lib/errors'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {OfferTypenameToFrontEndApplicationPreReqMap} from 'src/workflows/constants'
import {wfDebug, wfError, wfLog, wfWarn} from 'src/workflows/logging'
import {
  FetchWorkflowStateAction,
  workflowsMetOneTimeFrontEndPreReqsSelector,
  workflowsSelectedOfferIdSelector,
  workflowsSelectedOfferSelector,
  workflowsStateFetchedSelector,
} from 'src/workflows/slice'
import {
  ProductsQueryEligibleOffersType,
  ProductsQueryOfferType,
  SelectedOfferInformation,
  SelectedOfferType,
  WorkflowsType,
} from 'src/workflows/types'
import {GetOfferForId} from 'src/workflows/workflow.utils'
import {userIdSelector} from 'src/lib/user/selector'

/**
 * Retrieve an offer's met and unmet pre-reqs.
 * @param selectedOffer The offer for which to retrieve pre-reqs
 * @returns metPreReqs and unmetPreReqs
 */
const useSelectedOfferPreReqs = (
  selectedOffer: ProductsQueryOfferType | null,
): Pick<SelectedOfferInformation, 'metPreReqs' | 'unmetPreReqs' | 'unmetFrontEndPreReqs'> => {
  const selectedOfferMiscProp = usePfSelector(workflowsSelectedOfferSelector)
  const metOneTimeFrontEndPreReqs = usePfSelector(workflowsMetOneTimeFrontEndPreReqsSelector)

  // get our selected offer's met pre reqs
  const selectedOfferMetPreReqs = useMemo(() => {
    return [
      ...(selectedOffer?.preReqs.requirementMet ?? []),
      ...metOneTimeFrontEndPreReqs,
      ...(selectedOfferMiscProp?.metFrontEndPreReqs ?? []),
    ]
  }, [
    metOneTimeFrontEndPreReqs,
    selectedOffer?.preReqs.requirementMet,
    selectedOfferMiscProp?.metFrontEndPreReqs,
  ])

  // get our selected offer's unmet front-end pre reqs
  const selectedOfferUnmetFrontEndPreReqs = useMemo(() => {
    if (!selectedOffer || !selectedOfferMiscProp) {
      return []
    }

    return difference(
      OfferTypenameToFrontEndApplicationPreReqMap[selectedOffer.__typename],
      selectedOfferMiscProp?.metFrontEndPreReqs,
      metOneTimeFrontEndPreReqs,
    )
  }, [metOneTimeFrontEndPreReqs, selectedOffer, selectedOfferMiscProp])

  // get our selected offer's unmet pre reqs
  const selectedOfferUnmetPreReqs = useMemo(() => {
    if (selectedOffer && selectedOfferMiscProp) {
      return [...selectedOffer.preReqs.requirementNotMet, ...selectedOfferUnmetFrontEndPreReqs]
    }

    return []
  }, [selectedOffer, selectedOfferMiscProp, selectedOfferUnmetFrontEndPreReqs])

  if (selectedOffer !== null) {
    wfDebug(
      `Selected Offer PreReqs\nMet: ${JSON.stringify(
        selectedOfferMetPreReqs,
      )}\nUnmet: ${JSON.stringify(selectedOfferUnmetPreReqs)}\nUnmet FrontEnd: ${JSON.stringify(
        selectedOfferUnmetFrontEndPreReqs,
      )}\n\n`,
    )
  }

  return {
    metPreReqs: selectedOfferMetPreReqs,
    unmetPreReqs: selectedOfferUnmetPreReqs,
    unmetFrontEndPreReqs: selectedOfferUnmetFrontEndPreReqs,
  }
}

/**
 * Retrieve and interact with the user's selected offer.
 * @param eligibleOffers The eligible offers from the products query
 * @returns {SelectedOfferType} Data related to the selected offer
 */
const useSelectedOffer = (eligibleOffers?: ProductsQueryEligibleOffersType): SelectedOfferType => {
  const [selectedOffer, setSelectedOffer] = useState<ProductsQueryOfferType | null>(null)

  const userId = usePfSelector(userIdSelector)
  const isFetched = usePfSelector(workflowsStateFetchedSelector)
  const selectedOfferId = usePfSelector(workflowsSelectedOfferIdSelector)
  const {metPreReqs, unmetPreReqs, unmetFrontEndPreReqs} = useSelectedOfferPreReqs(selectedOffer)

  const dispatch = usePfDispatch()

  // fetch our user misc props
  useEffect(() => {
    if (userId && !isFetched) {
      wfLog('Fetching Workflows UserMiscProp')
      dispatch(FetchWorkflowStateAction())
        .catch(ContextualizedLogException('Workflows UserMiscProp Fetch Error'))
        .finally(() => wfLog('Finished fetching Workflows UserMiscProp'))
    }
  }, [dispatch, userId, isFetched])

  return useMemo(() => {
    // find our selected offer
    if (selectedOfferId && eligibleOffers && selectedOffer === null) {
      wfLog('Selected Offer Id', {selectedOfferId})
      setSelectedOffer(GetOfferForId(selectedOfferId, eligibleOffers))
    }

    if (!isFetched) {
      return {
        status: 'LOADING',
      }
    }

    if (!selectedOfferId || !selectedOffer) {
      return {
        status: 'NONE',
        offerId: null,
        offer: null,
        metPreReqs: null,
        unmetPreReqs: null,
      }
    }

    return {
      status: 'SELECTED',
      offerId: selectedOfferId,
      offer: selectedOffer,
      metPreReqs,
      unmetPreReqs,
      unmetFrontEndPreReqs,
    }
  }, [
    selectedOfferId,
    eligibleOffers,
    selectedOffer,
    isFetched,
    metPreReqs,
    unmetPreReqs,
    unmetFrontEndPreReqs,
  ])
}

/**
 * A hook to get a calculated status of workflows along with data relevant
 * to that status.
 * @returns One of three statuses: `ERROR`, `LOADING`, or `READY`.
 * `ERROR` means that there was an error fetching the data. `refetch` can be called to try again.
 * `LOADING` means that the data is being fetched. `loading` will be true.
 * `READY` means that the data is ready to be used. `applications`, `unmetMinPreReqs`, and `offers` will be populated.
 */
export const useWorkflows = (): WorkflowsType => {
  const {
    selectedData,
    error,
    loading: isProductsQueryLoading,
    refetch: productsRefresh,
  } = Consumer.hooks.useProductsQuery({fetchPolicy: 'network-only'})
  const [isLoading, setIsLoading] = useState(isProductsQueryLoading)

  // the loading prop provided by `useQuery` (and thus `useProductsQuery`)
  // does not update when `refetch` is called.
  // so let's override it.
  const refetch = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await productsRefresh()
      if (result.error || result.errors) {
        throw result.error ?? result.errors?.[0]
      }
      return result
    } catch (e) {
      if (e instanceof Error) {
        wfError(e, 'Error refetching products query')
      }
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [productsRefresh])

  const selectedOffer = useSelectedOffer(selectedData?.eligible)

  if (isProductsQueryLoading || selectedOffer.status === 'LOADING') {
    return {
      status: 'LOADING',
      loading: true,
    }
  }

  if (error) {
    return {
      status: 'ERROR',
      error,
      refetch,
    }
  }

  if (!selectedData) {
    wfWarn('Somehow we finished loading but have no data. This should never happen.')

    return {
      status: 'LOADING',
      loading: true,
    }
  }

  return {
    status: 'READY',
    loading: isLoading,
    applications: selectedData.applications.all,
    unmetMinPreReqs: selectedData.offersMinPreReqs.requirementNotMet,
    metMinPreReqs: selectedData.offersMinPreReqs.requirementMet,
    offers: selectedData.eligible.all,
    ineligibleOffers: selectedData.ineligible.all,
    selectedOffer: selectedOffer.status === 'SELECTED' ? selectedOffer : null,
    refetch,
  }
}
