import {StackHeaderProps} from '@react-navigation/stack'
import React, {useMemo} from 'react'

import {headerBackButtonColor} from 'src/designSystem/semanticColors'
import {Color} from 'src/designSystem/types'
import {
  SvgIconButton,
  SvgIconButtonProps,
} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

type HeaderBackButtonPropTypes = Pick<StackHeaderProps, 'navigation'> &
  Pick<SvgIconButtonProps, 'size'> & {
    color?: Color
    onPress?: () => void
  }

const HeaderBackButton = (props: HeaderBackButtonPropTypes): JSX.Element => {
  const {navigation, color, onPress: handleOnPress} = props
  const handleDefaultOnPress = (): void => navigation.goBack()

  const selectedColor = useMemo(() => color ?? headerBackButtonColor, [color])

  return (
    <SvgIconButton
      name={'arrowLeft'}
      colorVariant={'custom'}
      customColor={selectedColor}
      size={'large'}
      onPress={handleOnPress ? handleOnPress : handleDefaultOnPress}
    />
  )
}

export {HeaderBackButton}
