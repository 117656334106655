import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect} from 'react'

import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardAccountApplicationActivationWorkflow} from 'src/products/general/ApplicationActivationWorkflow/CardAccountApplicationActivationWorkflow/CardAccountApplicationActivationWorkflow'
import {LoanApplicationActivationWorkflow} from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanApplicationActivationWorkflow'
import {useApprovedApplication} from 'src/products/general/ApplicationActivationWorkflow/useApprovedApplication'
import {WorkflowLoading} from 'src/products/general/Workflows/WorkflowLoading'
import {useWFLogging} from 'src/workflows/logging'
import {WorkflowsReadyType} from 'src/workflows/types'

export const ApplicationActivationWorkflowReady: React.FC<
  StackScreenProps<MainStackParamList, 'ApplicationActivationWorkflow'> & WorkflowsReadyType
> = (props) => {
  useEffect(() => {
    TrackAppEvent(
      AppEvents.Name.application_activation_workflow_entered,
      AppEvents.Category.Workflows,
    )
    return () => {
      TrackAppEvent(
        AppEvents.Name.application_activation_workflow_exited,
        AppEvents.Category.Workflows,
      )
    }
  }, [])
  const log = useWFLogging('ApplicationActivationWorkflowReady')

  const approvedApplication = useApprovedApplication()
  useEffect(() => {
    log.debug('Approved application updated from useApprovedApplication()', approvedApplication)
  })

  switch (approvedApplication.status) {
    case 'READY':
      if (approvedApplication.productTypename === 'Loan') {
        return <LoanApplicationActivationWorkflow {...props} {...approvedApplication} />
      } else {
        return <CardAccountApplicationActivationWorkflow {...props} />
      }
    // if the product was just activated in the corresponding product's nav stack we assume that
    // nav stack is going to handle navigating the user appropriately after activation so we
    // momentarily show a loading UX while that's happening
    case 'ACTIVATED':
    case 'LOADING':
      return <WorkflowLoading />
    case 'ERROR':
      return <ErrorTemplate error={approvedApplication.error} />
  }
}
