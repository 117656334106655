import {useTranslation} from 'react-i18next'

import {useCards} from 'src/products/card/hooks/useCards'
import {isUserRejectedDueToQuota} from 'src/products/card/LoanDash/utils'

export const useGetRejectionText = (): {
  isLoading: boolean
  title: string
  body: string
} => {
  const {t} = useTranslation('CardApplicationRejected')
  const {activeCardStatus, userEmail, loading: isLoading} = useCards()

  let titleKey = 'CardRejectedTitle'
  let bodyKey = 'CardRejectedBody'

  if (isUserRejectedDueToQuota(activeCardStatus)) {
    titleKey = 'CardRejectedForQuotaTitle'
    bodyKey = 'CardRejectedForQuotaBody'
  }

  return {
    isLoading,
    title: t(titleKey),
    body: t(bodyKey, {emailAddress: userEmail}),
  }
}
