import {useCallback, useState} from 'react'
import {LayoutChangeEvent, LayoutRectangle} from 'react-native'

export type UseMeasurement = {
  handleLayout: (event: LayoutChangeEvent) => void
} & LayoutRectangle

export const useMeasurement = (): UseMeasurement => {
  const [measurement, setMeasurement] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})

  const handleLayout = useCallback(
    (event: LayoutChangeEvent): void => {
      setMeasurement(event.nativeEvent.layout)
    },
    [setMeasurement],
  )

  return {...measurement, handleLayout}
}
