import {isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'

/**
 * For the onSuccess and onExit handlers, Plaid returns institution data in the format:
 * android/ios: { institution: { id, name}}
 * web: { institution: { institution_id, name}}  // SMH
 */
export const getNormalizedInstitutionIdFromMetadata = (metadata): string => {
  return isDeviceWeb() ? metadata?.institution?.institution_id : metadata?.institution?.id
}

export const getNormalizedInstitutionNameFromMetadata = (metadata): string => {
  return metadata?.institution?.name
}

/**
 * For events, Plaid returns the institution data in the format:
 * android/web: { institution_id, institution_name}
 * ios: { institutionId, institutionName }
 */
export const getNormalizedInstitutionIdFromEvent = (event): string => {
  return isDeviceIOS() ? event.institutionId : event.institution_id
}

export const getNormalizedInstitutionNameFromEvent = (event): string => {
  return isDeviceIOS() ? event.institutionName : event.institution_name
}

/** Get Plaid requestId and linkSessionId */
export const getNormalizedValuesForLogging = (event) => {
  const plaidRequestId = event?.requestId ?? event?.request_id
  const plaidLinkSessionId = event?.linkSessionId ?? event?.link_session_id
  return {plaidRequestId, plaidLinkSessionId}
}

export const getNormalizedValuesForLoggingStr = (event): string => {
  const values = getNormalizedValuesForLogging(event)
  return `link_session_id=${values.plaidLinkSessionId}, request_id=${values.plaidRequestId}`
}
