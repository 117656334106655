import {LoanPayment} from '@possible/cassandra/src/types/types.mobile.generated'

export type LoanProgressTileLoanPaymentFields = Pick<
  LoanPayment,
  'statusCode' | 'amount' | 'originalDate' | 'rescheduledDate' | 'id'
>

export enum LoanProgressTileTestIds {
  DefaultStatusLink = 'defaultStatusLink',
  ChargedOffStatusLink = 'chargedOffStatusLink',
  RescheduleBtn = 'rescheduleBtn',
  ContactUsBtn = 'contactUsBtn',
  RelinkBankAccountBtn = 'relinkBankAccountBtn',
}
