import React, {useCallback} from 'react'

import {useAgreementDocPdf} from 'src/products/card/CardsHistory/useAgreementDocPdf'
import {CardAgreementType} from 'src/cassandra'
import {
  CardAcceptDocument,
  Props as DocumentAgreementProps,
} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptDocument'

type Props = Omit<DocumentAgreementProps, 'onClick'> & {
  cardAgreementType: CardAgreementType
  agreementName: string
}

export const CardAcceptAgreement: React.FC<Props> = (props) => {
  const {cardAgreementType, agreementName} = props
  const {displayAgreementPdf} = useAgreementDocPdf()

  const handleOnClick = useCallback(
    () => displayAgreementPdf(cardAgreementType),
    [displayAgreementPdf, cardAgreementType],
  )

  return <CardAcceptDocument {...props} onClick={handleOnClick} agreementName={agreementName} />
}
