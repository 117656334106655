import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PrimaryAccountDescription from 'src/products/general/components/molecules/PrimaryAccountDescription/PrimaryAccountDescription'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export const PrimaryAccountContent: React.FC = () => {
  const {t} = useTranslation(['AccountManagement', 'Common'])
  return (
    <Box>
      <PrimaryAccountDescription />
      <PFText variant="p" textAlign="left">
        {t('UpdatingPrimaryAccount')}
      </PFText>
    </Box>
  )
}
