import React, {FunctionComponent, useState} from 'react'
import {GestureResponderEvent, TouchableHighlight} from 'react-native'

import {
  borderedButtonBackground,
  pressedButtonColor,
  disabledBackground,
  primaryBrandColor,
  borderedButtonBorderColor,
  tertiaryButtonDisabled,
  disabledButtonBackground,
  transparent,
} from 'src/designSystem/semanticColors'
import {
  buttonBorderRadius,
  buttonBorderWidth,
  maxButtonWidth,
  primaryButtonHeight,
  secondaryButtonHeight,
  tertiaryButtonHeight,
  tertiaryButtonBorderWidth,
} from 'src/designSystem/guide'
import withDoubleClickPrevention from 'src/designSystem/components/atoms/WithDoubleClickPrevention/WithDoubleClickPrevention'

export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  BORDERED = 'bordered',
}

const baseStyle = {
  borderRadius: buttonBorderRadius,
  justifyContent: 'center',
  width: '100%',
}
const primaryContainerStyle = {
  ...baseStyle,
  minHeight: primaryButtonHeight,
  maxWidth: maxButtonWidth,
}
const secondaryContainerStyle = {
  ...baseStyle,
  borderWidth: buttonBorderWidth,
  borderStyle: 'solid',
  minHeight: secondaryButtonHeight,
  maxWidth: maxButtonWidth,
}
const tertiaryContainerStyle = {
  ...baseStyle,
  borderWidth: tertiaryButtonBorderWidth,
  borderStyle: 'solid',
  minHeight: tertiaryButtonHeight,
}
const borderedContainerStyle = {
  ...baseStyle,
  borderWidth: buttonBorderWidth,
  borderStyle: 'solid',
  minHeight: tertiaryButtonHeight,
}

export type ButtonProps = React.PropsWithChildren & {
  onPress?: (event?: GestureResponderEvent) => void | Promise<void>
  disabled?: boolean
  onDown?: () => void
  onUp?: () => void
  secondaryAction?: boolean
  testID?: string
}

type ButtonBaseProps = ButtonProps & {variant: BUTTON_VARIANTS}

const ButtonBase: FunctionComponent<ButtonBaseProps> = ({
  onPress,
  onDown,
  onUp,
  variant,
  children,
  disabled,
  secondaryAction = false,
  testID,
}) => {
  const [pressStatus, setPressStatus] = useState(false)

  let containerStyle
  let underlayColor
  let secondaryActiveColor
  let tertiaryActiveColor

  switch (true) {
    case variant === BUTTON_VARIANTS.BORDERED && !disabled:
      containerStyle = {
        ...borderedContainerStyle,
      }
      containerStyle.borderColor = borderedButtonBorderColor
      underlayColor = borderedButtonBackground
      containerStyle.backgroundColor = borderedButtonBackground
      break
    case variant === BUTTON_VARIANTS.BORDERED && disabled:
      containerStyle = {
        ...borderedContainerStyle,
      }
      containerStyle.borderColor = disabled
      underlayColor = disabledBackground
      containerStyle.backgroundColor = disabledBackground
      break
    case variant === BUTTON_VARIANTS.TERTIARY && !disabled:
      containerStyle = {
        ...tertiaryContainerStyle,
      }
      tertiaryActiveColor = pressStatus ? pressedButtonColor : disabledButtonBackground
      containerStyle.borderColor = tertiaryActiveColor
      underlayColor = borderedButtonBackground
      containerStyle.backgroundColor = borderedButtonBackground
      break
    case variant === BUTTON_VARIANTS.TERTIARY && disabled:
      containerStyle = {
        ...tertiaryContainerStyle,
      }
      containerStyle.borderColor = tertiaryButtonDisabled
      underlayColor = tertiaryButtonDisabled
      containerStyle.backgroundColor = tertiaryButtonDisabled
      break
    case variant === BUTTON_VARIANTS.SECONDARY:
      containerStyle = {
        ...secondaryContainerStyle,
      }
      containerStyle.backgroundColor = borderedButtonBackground
      secondaryActiveColor = pressStatus ? pressedButtonColor : primaryBrandColor
      containerStyle.borderColor = disabled ? disabledBackground : secondaryActiveColor
      underlayColor = transparent
      break
    case variant === BUTTON_VARIANTS.PRIMARY:
    default:
      containerStyle = {
        ...primaryContainerStyle,
      }
      containerStyle.backgroundColor = disabled ? disabledBackground : primaryBrandColor
      underlayColor = pressedButtonColor
  }

  const onShowUnderlay = (): void => {
    setPressStatus(true)
    onDown?.()
  }

  const onHideUnderlay = (): void => {
    setPressStatus(false)
    onUp?.()
  }

  return (
    <TouchableHighlight
      testID={testID}
      disabled={disabled && !secondaryAction}
      onHideUnderlay={onHideUnderlay}
      onShowUnderlay={onShowUnderlay}
      underlayColor={underlayColor}
      onPress={onPress}
      style={containerStyle}
    >
      {children}
    </TouchableHighlight>
  )
}

export default withDoubleClickPrevention(ButtonBase)
