import React, {Children} from 'react'
import {StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {basicTileElevation} from 'src/designSystem/guide'
import {littleGap, mediumGap, smallGap} from 'src/designSystem/layout'
import {ShadowViewStyle} from 'src/designSystem/components/atoms/PFElevation/styles'

type SpotlightTileProps = {
  content: string | React.ReactNode
  title: string | React.ReactNode
  ctaText?: string
  endnote?: string
  index?: number
  onPress?: () => void
  totalTiles?: number
}

const SpotlightTileFooter = ({children}: {children: React.ReactNode}): JSX.Element => {
  const numberOfChildren = Children.toArray(children).length

  return numberOfChildren ? (
    <Box
      align={'center'}
      direction={'row'}
      /* We use `paddingTop` here instead of `gap` on the parent because `gap` renders a `spacer`
       * even when `SpotlightTileFooter` renders `<></>` */
      paddingTop={'small'}
      wrap={'wrap'}
    >
      {children}
    </Box>
  ) : (
    <></>
  )
}

const SpotlightTile = ({
  content,
  title,
  endnote,
  // SpotlightTileCtaProps
  ctaText,
  onPress,
  // CarouselTileProps
  index,
  totalTiles,
}: SpotlightTileProps): JSX.Element => {
  /* `PFElevation` has a bug where it doesn't allow children to grow with flex box. `PFElevation`
   * is used by `BasicTile` and `Box` so we had to resort to a `View`. */
  return (
    <View style={styles.tileWrapper} testID="SpotlightTile">
      <Box gap={'little'} grow>
        <PFText variant={'p_lg_semibold'}>{title}</PFText>
        <PFText variant={'p'}>{content}</PFText>

        {endnote ? (
          <PFText variant={'p_sm'} color={NamedColors.SILVER}>
            {endnote}
          </PFText>
        ) : null}
      </Box>

      <SpotlightTileFooter>
        {index && totalTiles ? (
          <PFText variant={'p'}>
            {index}/{totalTiles}
          </PFText>
        ) : null}

        {onPress && ctaText ? (
          <Box direction={'row'} justify={'end'} paddingLeft={'little'} grow>
            <Button mode={'secondary'} onPress={onPress} size={'medium'} width={'auto'}>
              {ctaText}
            </Button>
          </Box>
        ) : null}
      </SpotlightTileFooter>
    </View>
  )
}

const styles = StyleSheet.create({
  tileWrapper: {
    flex: 1,
    paddingHorizontal: smallGap,
    paddingVertical: mediumGap,
    ...ShadowViewStyle(basicTileElevation, NamedColors.WHITESMOKE, littleGap),
  },
})

export {SpotlightTile}
export type {SpotlightTileProps}
