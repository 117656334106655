import {getUserProperty, setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {
  LoginMethodType,
  LoginMethodPropPayloadLegacy,
  LoginMethodPropPayloadNew,
} from 'src/api/lib/UserProperties/LoginMethodUserProperty.types'

const loginMethodIsNewFormat = (value: unknown): value is LoginMethodPropPayloadNew => {
  return value !== null && typeof value === 'object' && 'method' in value
}

// Mutate the data to allow all users to move to the new shape of property
export async function getLoginMethodUserProperty(): Promise<LoginMethodType | undefined> {
  const {value} = await getUserProperty('login_method')

  if (value === undefined) {
    return undefined
  }
  if (loginMethodIsNewFormat(value)) {
    return value.method
  }

  const loginMethodValue = getLoginFromLegacyData(value)
  void setUserProperty('login_method', {method: loginMethodValue})
  return loginMethodValue
}

export function getLoginFromLegacyData(value: LoginMethodPropPayloadLegacy): LoginMethodType {
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return Object.keys(value)[0] as LoginMethodType
}
