import {CardAccountStatusCode} from 'src/cassandra'
import {useCards} from 'src/products/card/hooks/useCards'

export const useShouldDisplayPhysicalCardActivationTile = (): boolean => {
  const {hasPendingPhysicalCard, activeCardStatus} = useCards()

  // ENG-14442 Hide entry tile for deactivated users
  if (activeCardStatus?.code === CardAccountStatusCode.Deactivated) {
    return false
  }

  return hasPendingPhysicalCard
}
