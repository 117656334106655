import React, {ReactElement, FC} from 'react'
import {StyleSheet} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'

type AddNewAccountModalProps = {
  onOk: () => void
  visible: boolean
  onDismiss: () => void
}

export const AddNewAccountModal: FC<AddNewAccountModalProps> = ({
  onOk,
  visible,
  onDismiss: handleOnDismiss,
}) => {
  const {t} = useTranslation(['AccountManagement', 'Common'])

  const handlePrimaryAction = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.account_management_plaid_start,
      AppEvents.Category.BankAccountManagement,
      {
        HasPrimaryAccount: 'true',
      },
    )
    onOk()
    handleOnDismiss()
  }

  const title = (
    <PFText>
      <Trans
        t={t}
        i18nKey="AddAccountToPossible"
        components={{
          productBlue: <PFText color={NamedColors.PRODUCT_BLUE} />,
        }}
      />
    </PFText>
  )

  const body: ReactElement = (
    <Box>
      <Box boxStyle={styles.row}>
        <PFText variant="p">{t('YouAlreadyHave')} </PFText>
        <PFStatusPill
          text="Primary"
          color={NamedColors.PRODUCT_BLUE}
          fontColor={NamedColors.WHITE}
        />
        <PFText variant="p">{t('Common:Account')}</PFText>
        <PFText variant="p">{t('AccountOnFileForAutoPay')}</PFText>
      </Box>
      <Box boxStyle={styles.infoWrapper}>
        <PFText variant="p" textAlign="left">
          {t('IfYouAddNewAccountPart1')}
          <PFText variant="p_semibold">{t('IfYouAddNewAccountFYI')}</PFText>
          {t('IfYouAddNewAccountPart2')}
        </PFText>
      </Box>
    </Box>
  )

  return (
    <OverlaySimple
      visible={visible}
      title={title}
      titleSize="h2"
      titleAlign="left"
      okayButtonText={t('AddNewAccount')}
      okayButtonSize="medium"
      hideOnOkay={true}
      onOkay={handlePrimaryAction}
      dismissButtonText={t('Common:NeverMind')}
      onDismiss={handleOnDismiss}
      dismissButtonSize="medium"
      padding="medium"
      testID="Add_New_Account_Modal"
    >
      {body}
    </OverlaySimple>
  )
}

const styles = StyleSheet.create({
  infoWrapper: {
    marginBottom: 20,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
})
