import {
  LinkedAccount,
  AchPaymentMethod,
  DebitCardPaymentMethod,
  CheckPaymentMethod,
} from '@possible/cassandra/src/types/consumer'

export type PaymentAccount =
  | LinkedAccount
  | AchPaymentMethod
  | DebitCardPaymentMethod
  | CheckPaymentMethod
  | null
  | undefined

export enum PaymentFlow {
  Onboarding,
  PayNow,
  PayOverTime,
  SwitchToAutopay,
}
