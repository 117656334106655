import moment from 'moment'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  CardDocumentsQueryDocument,
  CardDocumentsQueryQuery,
} from 'src/products/card/CardsHistory/UseCardDocuments/CardDocumentsQuery.gqls'
import {oneYearAgoStr} from '@possible/cassandra/src/utils/formatting'

type CardActiveAccountType = NonNullable<CardDocumentsQueryQuery['me']['cardAccounts']['active']>
export type CardAccountAgreements = CardActiveAccountType['agreements']['all']
type CardAccountStatement = CardActiveAccountType['statements']['historical']['statements'][0]

type UseCardDocumentsType = () => {
  isLoading: boolean
  cardAccountId: CardActiveAccountType['id'] | undefined
  isAutopayEnabled: CardActiveAccountType['autopayEnabled'] | undefined
  createdAt: CardActiveAccountType['createdAt'] | undefined
  agreements: CardActiveAccountType['agreements']['all']
  statements: CardActiveAccountType['statements']['historical']['statements']
}
export const useCardDocuments: UseCardDocumentsType = () => {
  const {data, loading: isLoading} = useCassandraQuery(CardDocumentsQueryDocument, {
    variables: {startDate: oneYearAgoStr},
  })
  const agreements = data?.me?.cardAccounts?.active?.agreements?.all ?? []
  const statements = data?.me?.cardAccounts?.active?.statements?.historical?.statements ?? []

  // Returns all statements from within the past year, ordered most recent first
  const statementsClone = [...statements].sort(
    (a: CardAccountStatement, b: CardAccountStatement) => {
      const aTime = moment(a?.statementDate).unix()
      const bTime = moment(b?.statementDate).unix()
      return bTime - aTime
    },
  )

  return {
    isLoading,
    cardAccountId: data?.me?.cardAccounts?.active?.id,
    isAutopayEnabled: data?.me?.cardAccounts?.active?.autopayEnabled,
    createdAt: data?.me?.cardAccounts?.active?.createdAt,
    agreements,
    statements: statementsClone,
  }
}
