import {NETWORK_CONNECTION_FAILURE, NETWORK_CONNECTION_SUCCESS} from 'src/api/actions/actionsNames'
import {PfReduxState} from 'src/reducers/types'
import {PfDispatch} from 'src/store/types'

export function ConnectionError() {
  return (dispatch: PfDispatch, getState: () => PfReduxState): void => {
    const state = getState()
    if (!state.api.badConnection) {
      dispatch({type: NETWORK_CONNECTION_FAILURE})
    }
  }
}

export function ConnectionSuccess() {
  return (dispatch: PfDispatch, getState: () => PfReduxState): void => {
    const state = getState()
    if (state.api.badConnection) {
      dispatch({type: NETWORK_CONNECTION_SUCCESS})
    }
  }
}
