import React from 'react'

import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowScreenProps} from 'src/workflows/types'
import {CardPaymentAgreementGQLContainer} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementGQLContainer/CardPaymentAgreementGQLContainer'

type CardPaymentAgreementWorkflowContainerProps = WorkflowScreenProps & {
  onPressSecondary: () => void
}

const CardPaymentAgreementWorkflowContainer = ({
  onPressSecondary: handleOnPressSecondary,
  onScreenComplete: handleOnComplete,
}: CardPaymentAgreementWorkflowContainerProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_finish_virtual_activation_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })

  return (
    <CardPaymentAgreementGQLContainer
      onComplete={handleOnComplete}
      onPressSecondary={handleOnPressSecondary}
    />
  )
}

export {CardPaymentAgreementWorkflowContainer}
