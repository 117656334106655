import React, {ReactNode} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import moment from 'moment'

import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

export type LoanProcessingRejectedProps = {
  email: string
  reapplyDatetime: string
  onComplete: () => void
  onReadMore: () => void
}

export const LoanProcessingRejectedTemplate = (props: LoanProcessingRejectedProps): ReactNode => {
  const {email, reapplyDatetime, onReadMore: handleOnReadMore, onComplete} = props

  const {t} = useTranslation(['Onboarding', 'Common'])

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:Continue'),
      onPress: onComplete,
      testID: 'LoanProcessingRejected-Continue-Button',
    },
  }

  return (
    <Page
      variant="generic"
      title={t('OnboardingLoanProcessingRejectedTitle')}
      smallTopGap
      buttonProps={buttonProps}
    >
      <Box>
        <PFText variant="p">
          <Trans
            t={t}
            i18nKey="OnboardingLoanProcessingRejectedBody"
            values={{
              emailAddress: email,
              reapplyDate: moment(reapplyDatetime).format('dddd, MMMM Do'),
            }}
            components={{
              svgLink: (
                <SvgLink
                  onPress={handleOnReadMore}
                  linkText={'Learn more about this result.'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </Box>
    </Page>
  )
}
