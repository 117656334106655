import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {signingBlockBorder, darkGrayText} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type SigningBlockProps = {
  date: moment.Moment
  desc: string
  name: string
  signature?: string
  title: string
}

const SigningBlock: FC<SigningBlockProps> = (props) => {
  const {title, name, signature, date, desc} = props
  const {t} = useTranslation('Common')

  return (
    <View>
      <PFText variant="h3">{title}</PFText>
      <View style={styles.namePrinted}>
        <PFText variant="label_md" testID={`${title}_Name`}>
          {name}
        </PFText>
      </View>
      <View style={styles.signView}>
        <View style={styles.leftContent}>
          <PFText variant="p" color={darkGrayText} testID={`${title}_Signature`}>
            {signature ?? name}
          </PFText>
        </View>
        <View style={styles.rightContent}>
          <PFText variant="p" color={darkGrayText} testID={`${title}_Date`}>
            {date.format('MMM Do, YYYY')}
          </PFText>
        </View>
      </View>
      <View style={styles.underSignView}>
        <View style={styles.leftContent}>
          <PFText variant="p_sm">{desc}</PFText>
        </View>
        <View style={styles.rightContent}>
          <PFText variant="p_sm">{t('Date')}</PFText>
        </View>
      </View>
    </View>
  )
}

export {SigningBlock}

const styles = StyleSheet.create({
  leftContent: {
    width: '70%',
  },
  namePrinted: {
    marginTop: 2,
  },
  rightContent: {
    width: '40%',
  },
  signView: {
    borderBottomWidth: 1,
    borderColor: signingBlockBorder,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
    marginTop: smallGap,
  },
  underSignView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
