import React, {useState} from 'react'
import {Platform, StyleSheet, View} from 'react-native'

import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'
import {ButtonAction} from 'src/designSystem/types'

type ButtonSegment = {
  isActive?: boolean
  onPress: NonNullable<ButtonAction>
  title: string
}

type SegmentedButtonProps = {
  buttons: ButtonSegment[]
}

const SegmentedButton = ({buttons}: SegmentedButtonProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(() => {
    const activeButtonIndex = buttons.findIndex((button) => button.isActive)
    return activeButtonIndex === -1 ? 0 : activeButtonIndex
  })

  const getIsSelected = (buttonIndex: number): boolean => buttonIndex === activeIndex

  const handleOnPress = (buttonIndex: number) => (): void => {
    setActiveIndex(buttonIndex)
    void buttons[buttonIndex].onPress()
  }

  return (
    <>
      <View accessibilityRole={'menu'} style={styles.layout}>
        <View
          aria-hidden={true}
          importantForAccessibility={'no-hide-descendants'}
          style={styles.border}
        />
        {buttons.map((button, index) => (
          <View key={button.title} style={styles.androidOverflowFix}>
            <Touchable
              accessibilityRole={'button'}
              aria-selected={getIsSelected(index)}
              key={button.title}
              onPress={handleOnPress(index)}
              style={getIsSelected(index) ? [styles.active, styles.button] : styles.button}
            >
              <PFText color={getIsSelected(index) ? 'white' : 'textDisabled'} variant={'label_sm'}>
                {button.title}
              </PFText>
            </Touchable>
          </View>
        ))}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  active: {
    backgroundColor: NamedColors.PRODUCT_BLUE,
  },
  androidOverflowFix: {
    flex: 1,
    flexBasis: 2,
    borderRadius: 999,
    overflow: 'hidden',
    zIndex: 1,
  },
  button: {
    alignItems: 'center',
    paddingHorizontal: 16,
    // Android optical centering fix
    paddingTop: Platform.OS === 'android' ? 5 : 6,
    paddingBottom: Platform.OS === 'android' ? 7 : 6,
  },
  layout: {
    flexDirection: 'row',
    position: 'relative',
  },
  // Positions the border behind the active button
  border: {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 1,
    borderColor: DefaultVariantsColor.textDisabled,
    borderRadius: 999,
  },
})

export {SegmentedButton}
