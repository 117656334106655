import React, {FC} from 'react'
import {StyleSheet} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Line95 from 'src/assets/illustrations/Line95.svg'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {settlementOnboardingBackground, separatorColor} from 'src/designSystem/semanticColors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import SmCard from 'src/assets/illustrations/SmCard.svg'
import {formatDate, humanReadableDate} from 'src/lib/utils/date'

type Props = StackScreenProps<MainStackParamList, 'SettlementOnboarding'>

const SettlementOnboarding: FC<Props> = () => {
  const {t} = useTranslation(['Settlements', 'Common'])

  // TODO FRONT-68 Get data from selectors
  const date = formatDate(new Date(), humanReadableDate)
  const amountOwed = 150
  const amountPaid = 50
  const forgiven = 100

  const singleButtonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('SeeDetails'),
      // TODO FRONT-11 Nav to How Settlements work page
      onPress: () => {
        /*
          no-op
        */
      },
    },
  }
  const svgPropsLine = {
    height: 5,
    width: 36,
  }

  return (
    <Page
      buttonProps={singleButtonProps}
      variant={'generic'}
      title={t('SettlementOnboardingTitle')}
      noHorizontalPadding
      smallTopGap
    >
      <Box paddingHorizontal="medium">
        <PFSvg SvgUri={Line95} svgProps={svgPropsLine} />
        <Box paddingVertical="small">
          <PFText textAlign={'left'} variant={'p'}>
            {t('SettlementOnboardingDescription', {date})}
          </PFText>
        </Box>
      </Box>
      <Box background={settlementOnboardingBackground} width={'100%'}>
        <Box paddingTop={'enormous'} align="center">
          <PFSvgContain width={144} svg={SmCard} />
        </Box>
        <Box direction="row" paddingVertical={'medium'}>
          <Box gap={'tiny'} boxStyle={styles.amountOwed} direction={'column'}>
            <PFText variant="p_lg_semibold">{`${t('Common:MoneySymbol')}${amountOwed.toFixed(
              2,
            )}`}</PFText>
            <PFText variant="p">{t('YouOwe')}</PFText>
          </Box>
          <Box gap={'tiny'} boxStyle={styles.amountPaid} direction={'column'}>
            <PFText variant="p_lg_semibold">{`${t('Common:MoneySymbol')}${amountPaid.toFixed(
              2,
            )}`}</PFText>
            <PFText variant="p">{t('YouPay')}</PFText>
          </Box>
        </Box>
        <Box boxStyle={styles.amountPaid} paddingBottom={'enormous'} direction={'column'}>
          <PFText variant="d2">{`${t('Common:MoneySymbol')}${forgiven.toFixed(2)}`}</PFText>
          <PFText variant="p_lg_semibold">{t('Forgiven')}</PFText>
        </Box>
      </Box>
    </Page>
  )
}

export default SettlementOnboarding

const styles = StyleSheet.create({
  amountOwed: {
    alignItems: 'center',
    borderRightColor: separatorColor,
    borderRightWidth: 1,
    flex: 1,
  },
  amountPaid: {
    alignItems: 'center',
    flex: 1,
  },
})
