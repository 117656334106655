import {UserProperty, UserPropertyKey} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyMutation, ApplyQuery} from '@possible/cassandra/src/utils/operations'

import {
  SetUserPropertyDocument,
  UserPropertiesDocument,
  UserSetPropertyDocument,
} from 'src/api/lib/UserProperties/UserProperties.gqls'
import {
  GetUserPropertyResult,
  InferredUserMiscProperty,
  UserProperties,
  UserPropertyTypes,
  UserPropertyValueTypeGuards,
} from 'src/api/lib/UserProperties/UserProperties.types'

/**
 * Checks if a user property is of a specific type
 * @param propType The type to check against
 * @param prop The property to check
 * @returns Whether the property is of the expected type
 */
export const IsUserPropertyType = <P extends UserProperties>(
  propType: P,
  prop?: UserPropertyTypes | UserProperty,
): prop is InferredUserMiscProperty<P> => {
  return prop?.property === propType
}

/**
 * Extracts a specific user property from a list of user properties
 * @param userPropertyName The name of the user property to extract
 * @param allUserProperties All the props to search through
 * @returns The JSON parsed value of that property or undefined if it doesn't exist
 */
export const getPropertyFromAllUserProperties = <
  P extends UserProperties,
  V = InferredUserMiscProperty<P>['value'],
>(
  userPropertyName: P,
  allUserProperties: UserProperty[],
): V | undefined => {
  const userProperty = allUserProperties.find((prop) => prop.property === userPropertyName)
  if (IsUserPropertyType(userPropertyName, userProperty)) {
    try {
      const parsedValue: unknown = JSON.parse(userProperty.value)
      if (UserPropertyValueTypeGuards[userPropertyName](parsedValue)) {
        // we actually have reason to believe this is the right type as our
        // type guards check the shape of `parsedValue`. but at the time of
        // this writing, TS doesn't actually reflect type guards for generics.
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        return parsedValue as V
      }
    } catch (e) {
      return undefined
    }
  }

  return undefined
}

/**
 * Queries the backend for a specific user property and returns it
 * @param userPropertyName The property to query for
 * @returns A promise that resolves to the value of the property or undefined if it doesn't exist
 */
export const getUserProperty = async <P extends UserProperties>(
  userPropertyName: P,
): Promise<GetUserPropertyResult<P>> => {
  const r = await ApplyQuery(UserPropertiesDocument)
  const allProps = r.data.me.properties?.all ?? []
  const value = getPropertyFromAllUserProperties(userPropertyName, allProps)
  const error = r.error ?? r.errors?.[0]
  return {property: userPropertyName, value, error}
}

/**
 * @deprecated Use userSetProperty instead
 *
 * Updates a user property in the backend
 * @param property The property to update
 * @param value The value to set the property to
 *
 */
export const setUserProperty = async <
  P extends UserProperties,
  V extends InferredUserMiscProperty<P>['value'],
>(
  property: P,
  value: V,
): Promise<void> => {
  const result = await ApplyMutation(SetUserPropertyDocument, {
    property,
    value: JSON.stringify(value),
  })

  // ApplyMutation will throw if the BE returns an error
  // or there is a network error -- but we also want to
  // throw if the BE returns false... I think
  if (!result?.setUserProperty) {
    throw new Error('Failed to set user property')
  }
}

/**
 * Updates a user property in the backend
 * @param property The property to update
 * @param value The value to set the property to
 */
export const userSetProperty = async <
  P extends UserPropertyKey,
  V extends InferredUserMiscProperty<P>['value'],
>(
  property: UserPropertyKey,
  value: V,
): Promise<void> => {
  const result = await ApplyMutation(UserSetPropertyDocument, {
    property,
    value: JSON.stringify(value),
  })

  // ApplyMutation will throw if the BE returns an error
  // or there is a network error -- but we also want to
  // throw if the BE returns false... I think
  if (!result?.userSetProperty.success) {
    throw new Error('Failed to set user property')
  }
}
