import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {cardActionRowBorderColor} from 'src/designSystem/semanticColors'
import {pageMarginHorizontal, smallGap} from 'src/designSystem/layout'
import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

// ENG-15552 update ActionListItem (general) and CardActionListItem (card) to use ActionRow (design system)
// ActionListItem will replace CardActionListItem when Card uses the generalized AdhocPayment
export type CardActionListItemProps = {
  isDisabled?: boolean
  isFirstChild?: boolean
  isPadded?: boolean
  onPress?: () => void
  subTitle?: string
  title: string
  titleColor?: Color
}

const ActionRowContent = ({
  onPress,
  title,
  titleColor = DefaultVariantsColor.black,
  subTitle,
}: Omit<CardActionListItemProps, 'isDisabled' | 'isFirstChild' | 'isPadded'>): JSX.Element => (
  <View style={styles.contentWrapper}>
    <Box gap={'tiny'} grow shrink={1}>
      <PFText variant={'p_lg_semibold'} color={titleColor} testID="Action-Row-Content-Id">
        {title}
      </PFText>
      {subTitle ? <PFText variant={'p'}>{subTitle}</PFText> : null}
    </Box>

    {onPress ? <SvgIcon name={'chevronRight'} colorVariant={'default'} /> : null}
  </View>
)

const CardActionListItem = ({
  isDisabled,
  isFirstChild,
  isPadded,
  onPress,
  ...props
}: CardActionListItemProps): JSX.Element => {
  const wrapperStyle = [
    styles.listSeparator,
    isFirstChild && styles.firstChild,
    isPadded && styles.padded,
  ]

  return (
    //  Borders render outside of `TouchableOpacity` so they don't change opacity unevenly `onPress`.
    <View style={wrapperStyle}>
      {onPress ? (
        <TouchableOpacity disabled={isDisabled} onPress={onPress}>
          <ActionRowContent {...props} onPress={onPress} />
        </TouchableOpacity>
      ) : (
        <ActionRowContent {...props} onPress={onPress} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  contentWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: smallGap,
  },
  firstChild: {
    borderTopWidth: 1,
  },
  listSeparator: {
    borderBottomWidth: 1,
    borderColor: cardActionRowBorderColor,
  },
  padded: {
    paddingHorizontal: pageMarginHorizontal,
  },
})

export {CardActionListItem}
