import React, {useRef} from 'react'
import {
  NativeSyntheticEvent,
  NativeTouchEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputProps,
} from 'react-native'

import {VariantsStyle} from 'src/designSystem/typography'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import Button, {ButtonMode} from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {selectionColor} from 'src/designSystem/components/atoms/PFFieldBase/styles'
import {getIsButtonSelected} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput.utils'
import {PaymentAction} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput.types'

type PaymentInputProps = {
  buttonActions?: PaymentAction[]
  onChange?: (text: string) => void
  onPressButtonAction?: (
    paymentAction: PaymentAction,
    event?: NativeSyntheticEvent<NativeTouchEvent>,
  ) => void
  /** Calls the function you pass before calling `onChange` */
  onSubmitEditing?: TextInputProps['onSubmitEditing']
  paymentValue: string
  paymentTrackingId: string
  isAmountInputEditable?: boolean
}

const PaymentInput = ({
  buttonActions,
  onChange,
  onPressButtonAction,
  onSubmitEditing,
  paymentValue,
  paymentTrackingId,
  isAmountInputEditable,
}: PaymentInputProps): JSX.Element => {
  const inputRef = useRef<TextInput | null>(null)

  const createOnPressHandler =
    (action: PaymentAction) => (event?: NativeSyntheticEvent<NativeTouchEvent>) => {
      onPressButtonAction?.(action, event)
      if (action.isFallback) {
        inputRef?.current?.focus()
      }

      onChange?.(action.amount)
    }

  const handleOnChangeText = (text: string): void => {
    /* Cast `text` value to a `Number` to strip non-numerical values. There is probably a better
     * way to do this. */
    const validNumber = Number(text.replace('$', '')) || 0

    onChange?.(validNumber.toString())
  }

  const handleSelectedState = (action: PaymentAction): ButtonMode => {
    let mode: ButtonMode = 'secondary'

    if (
      buttonActions &&
      getIsButtonSelected({
        buttonActions,
        currentAction: action,
        trackingId: paymentTrackingId,
      })
    ) {
      mode = 'selectedTab'
    }

    return mode
  }

  const handleOnSubmitEditing: TextInputProps['onSubmitEditing'] = (event) => {
    handleOnChangeText(paymentValue)
    onSubmitEditing?.(event)
  }

  return (
    <Box gap={'small'}>
      <TextInput
        cursorColor={DefaultVariantsColor['textPrimary']}
        keyboardType={'decimal-pad'}
        maxLength={7}
        onChangeText={handleOnChangeText}
        onSubmitEditing={handleOnSubmitEditing}
        ref={inputRef}
        enterKeyHint={'done'}
        selectionColor={selectionColor}
        style={styles.input}
        value={`$${paymentValue}`}
        testID="Adhoc-Payment-Input"
        editable={isAmountInputEditable}
      />
      {buttonActions ? (
        <Box gap={'small'} paddingHorizontal={'enormous'} paddingTop={'small'}>
          {buttonActions.map((action) => {
            const handleOnPress = createOnPressHandler(action)
            return (
              <Button
                key={`${action.trackingId}${action.amount}`}
                mode={handleSelectedState(action)}
                onPress={handleOnPress}
                size={'medium'}
                testID={action.trackingId}
                width={'100%'}
              >
                {action.label}
                {action.amount !== '0' ? ` - $${action.amount}` : ''}
              </Button>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

const styles = StyleSheet.create({
  input: {
    ...VariantsStyle.d2(DefaultVariantsColor['textPrimary']),
    /** `alignSelf` fixes an Android bug where the default input value shifts downward and gets
     *  cropped when an invalid character is entered multiple times 🙃*/
    alignSelf: 'stretch',
    paddingVertical: 0, // Fixes Android specific display bug.
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
    }),
  },
})

export {PaymentInput}
export type {PaymentAction, PaymentInputProps}
