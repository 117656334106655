import React from 'react'
import {useTranslation} from 'react-i18next'
import {StackHeaderProps} from '@react-navigation/stack'

import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import {headerCloseTextButtonColor} from 'src/designSystem/semanticColors'
import {Color} from 'src/designSystem/types'

type Props = Pick<StackHeaderProps, 'navigation'> & {
  color?: Color
}

const HeaderCloseTextButton = (props: Props) => {
  const {navigation, color} = props
  const {t} = useTranslation('Common')

  return (
    <ButtonLink
      onPress={() => navigation.popToTop()}
      size={'medium'}
      color={color ?? headerCloseTextButtonColor}
    >
      {t('Close')}
    </ButtonLink>
  )
}

export default HeaderCloseTextButton
