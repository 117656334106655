import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'

export const trackBankAccountSelectedBeforeSave = (args: {isFromOnboarding: boolean}): void => {
  const {isFromOnboarding} = args
  TrackAppEvent(
    BankAccountManagementEvents.select_primary_account_page_select_cta,
    AppEvents.Category.BankAccountManagement,
    {
      isFromOnboarding,
    },
  )
}

export const trackContinueCTAButtonTapped = (args: {isFromOnboarding: boolean}): void => {
  const {isFromOnboarding} = args
  TrackAppEvent(
    BankAccountManagementEvents.select_primary_account_page_cta,
    AppEvents.Category.BankAccountManagement,
    {
      isFromOnboarding,
    },
  )
}

export const trackPrimaryAccountSavedInOnboarding = (): void => {
  TrackAppEvent(
    BankAccountManagementEvents.account_management_primary_account_set,
    AppEvents.Category.BankAccountManagement,
    {
      isFromOnboarding: true,
    },
  )
}

export const trackPrimaryAccountSaveFailedInOnboarding = (): void => {
  TrackAppEvent(
    BankAccountManagementEvents.account_management_primary_account_set_error,
    AppEvents.Category.BankAccountManagement,
    {
      isFromOnboarding: true,
    },
  )
}
