import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type GetPlaidLinkTokenForLayersQueryVariables = Types.Exact<{
  input: Types.LinkTokenForOneClickOnboardingInput
}>

export type GetPlaidLinkTokenForLayersQuery = {
  __typename?: 'Query'
  getBankPlaidLinkTokenForOneClickOnboarding: {
    __typename: 'LinkTokenForOneClickOnboardingResponse'
    linkToken?: string | null
  }
}

export const GetPlaidLinkTokenForLayersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetPlaidLinkTokenForLayers'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'LinkTokenForOneClickOnboardingInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getBankPlaidLinkTokenForOneClickOnboarding'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'linkToken'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlaidLinkTokenForLayersQuery,
  GetPlaidLinkTokenForLayersQueryVariables
>
