import {ApolloClientType, Applications} from './types'

type ApplicationClients = {
  // client for public schema
  publicClient: undefined | ApolloClientType
  // client for public schema that hist localhost for birdsong
  publicLocalClient: undefined | ApolloClientType
  // client for private (authenticated) schema
  privateClient: undefined | ApolloClientType
  // client for private (authenticated) schema that hist localhost for birdsong
  privateLocalClient: undefined | ApolloClientType
}

/**
 * Cache of clients for each application (IAM or Mobile).
 */
export class CassandraClientCache {
  private clients: Record<Applications, ApplicationClients> = {
    [Applications.IAM]: {
      publicClient: undefined,
      publicLocalClient: undefined,
      privateClient: undefined,
      privateLocalClient: undefined,
    },
    [Applications.MOBILE]: {
      publicClient: undefined,
      publicLocalClient: undefined,
      privateClient: undefined,
      privateLocalClient: undefined,
    },
  }

  setClients(args: {
    applicationName?: Applications
    publicClient?: ApolloClientType
    publicLocalClient?: ApolloClientType
    privateClient?: ApolloClientType
    privateLocalClient?: ApolloClientType
  }): void {
    // default to mobile app. this isn't ideal but useCassandraQuery() is used everywhere and doesn't
    // pass an app type so we can't change it without a big backwards breaking change
    const {
      applicationName = Applications.MOBILE,
      publicClient,
      publicLocalClient,
      privateClient,
      privateLocalClient,
    } = args
    // if client is provided, even if undefined, we will cache it. if it's not
    // at all we won't update cache, allowing to update N clients at once
    if ('publicClient' in args) {
      this.clients[applicationName].publicClient = publicClient
    }
    if ('publicLocalClient' in args) {
      this.clients[applicationName].publicLocalClient = publicLocalClient
    }
    if ('privateClient' in args) {
      this.clients[applicationName].privateClient = privateClient
    }
    if ('privateLocalClient' in args) {
      this.clients[applicationName].privateLocalClient = privateLocalClient
    }
  }

  getClients({app = Applications.MOBILE}: {app?: Applications} = {}): ApplicationClients {
    return this.clients[app]
  }
}
