import React, {useCallback, useEffect, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Image from 'src/products/general/Image/Image'
import {useIdentityVerification} from 'src/products/loans/IdentityVerification/useIdentityVerification'
import {Consumer, CardAccountPanDecodedData} from 'src/cassandra'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import {formatExpiry} from 'src/products/card/Dashboard/CardDashboardUtils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {CardDetailsContent} from 'src/products/card/CardDetails/CardDetailsContent'
import {useCards} from 'src/products/card/hooks/useCards'
import {formatNameForOnboarding} from 'src/products/loans/PersonalInformation/PIUtils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {
  CardAccountPanDecodeDocument,
  CardAccountPanDecodeMutation,
} from 'src/products/card/CardDetails/CardAccountPanDecode.gqls'
import PossibleCardAngled from 'src/assets/images/PossibleCardAngled.png'

type Props = StackScreenProps<MainStackParamList, 'CardDetails'>
export const CardDetails: React.FC<Props> = ({navigation}) => {
  const [cardAccountPanDecode] = useCassandraMutation(CardAccountPanDecodeDocument)
  const goBack = useCallback(() => {
    navigation.goBack()
    navigation.goBack()
  }, [navigation])
  const {makeAuthenticatedNetworkCall} = useIdentityVerification<CardAccountPanDecodeMutation>(
    Consumer.types.UserVerificationType.MfaCards,
    goBack,
  )
  const {t} = useTranslation(['CardDetails', 'CardDashboard'])
  const {pendingPhysicalCard, cardAccount, usableVirtualOrPhysicalCard: virtualCard} = useCards()
  const isTemporaryCard = !!pendingPhysicalCard
  const activeAccountId = cardAccount?.id
  const userProfileData = Consumer.hooks.useMeProfileQuery().data?.me.profile
  const userFullName = formatNameForOnboarding(userProfileData?.name ?? undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [virtualCardPanDecode, setVirtualCardPanDecode] = useState<
    CardAccountPanDecodedData | undefined
  >()
  usePageViewedAnalytics({
    eventName: CardEvents.show_virtual_card,
    eventCategory: AppEvents.Category.Card,
  })

  const getCardAccountPanDecode = useCallback(
    async (cardAccountId: string, cardId: string) => {
      try {
        const result = await makeAuthenticatedNetworkCall(async () =>
          cardAccountPanDecode({
            variables: {cardAccountId, cardId},
          }),
        )

        if (result?.data?.cardAccountPanDecode) {
          setVirtualCardPanDecode(result?.data?.cardAccountPanDecode)
          setIsLoading(false)
        } else {
          snackbarErrorMessage(t('CardDashboard:UnableToAccessCardDetails'))
        }
      } catch (err) {
        snackbarErrorMessage(t('CardDashboard:UnableToAccessCardDetails'))
      }
    },
    [makeAuthenticatedNetworkCall, t, cardAccountPanDecode],
  )

  useEffect(() => {
    if (virtualCard?.id && !virtualCardPanDecode?.number && activeAccountId) {
      void getCardAccountPanDecode(activeAccountId, virtualCard?.id)
    }
  }, [activeAccountId, getCardAccountPanDecode, virtualCardPanDecode?.number, virtualCard?.id])

  const cardValues = [
    [t('Name'), userFullName, ''],
    [t('CardNumber'), virtualCardPanDecode?.number, t('NumberCopied')],
    [t('Expiration'), formatExpiry(virtualCardPanDecode?.expiry), t('ExpirationCopied')],
    [t('CVC'), virtualCardPanDecode?.cvv, t('CVCCopied')],
    [t('Network'), cardAccount?.cardNetwork, ''],
  ]

  return (
    <BaseTemplate isLoading={isLoading}>
      <Page
        variant={'generic'}
        title={t(isTemporaryCard ? 'YourTemporaryVirtualCard' : 'CardInformation')}
        smallTopGap={true}
        contentVerticalAlignment="space-between"
        testID="Card-Info-Page-Id"
      >
        <Box>
          <Image
            source={PossibleCardAngled}
            testID="Card-Image-Id"
            resizeMode={'contain'}
            style={styles.image}
          />

          <CardDetailsContent cardValues={cardValues} />
        </Box>

        <Box marginBottom="large">
          {isTemporaryCard ? (
            <PFInfoCapsule
              svgIcon={{name: 'copy', colorVariant: 'active', isFilled: true}}
              text={t('InfoMessage')}
            />
          ) : null}
        </Box>
      </Page>
    </BaseTemplate>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
  },
})
