import React, {FC, ReactElement} from 'react'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {LAStateDisclosureTemplate} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/LAStateDisclosure/LAStateDisclosureTemplate'
import {StateDisclosureWorkflowContainerBase} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosureWorkflowContainerBase'
import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'

type Props = WorkflowPreReqFulfillScreenProps

/**
 * Integrates the Lousiana State Disclosure screen within Workflows.
 */
export const LAStateDisclosureWorkflowContainer: FC<Props> = (props) => {
  const {onPreReqFulfilled: handleOnPreReqFulfilled} = props
  return (
    <StateDisclosureWorkflowContainerBase
      onPreReqFulfilled={handleOnPreReqFulfilled}
      stateCode={StateCodes.La}
      render={(props): ReactElement => <LAStateDisclosureTemplate {...props} />}
    />
  )
}
