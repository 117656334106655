/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Log from 'src/lib/loggingUtil'

const isWorkflowsLoggingEnabled = false
const isWorkflowsDebugLoggingEnabled = false

export const wfDebug = (...args: any[]): void => {
  if (isWorkflowsDebugLoggingEnabled) {
    Log.log('[Workflows Debug]', ...args)
  }
}

export const wfLog = (...args: any[]): void => {
  if (isWorkflowsLoggingEnabled) {
    Log.log('[Workflows]', ...args)
  }
}

export const wfWarn = (...args: any[]): void => {
  Log.warn('[Workflows]', ...args)
}

export const wfError = (error: Error, msg?: string): void => {
  Log.error(error, `[Workflows Error] ${msg}`)
}

export const useWFLogging = (
  component: string,
): {
  debug: (...args: any[]) => void
  log: (...args: any[]) => void
  warn: (...args: any[]) => void
  error: (error: Error, msg?: string) => void
} => {
  return {
    debug: (...args: any[]) => wfDebug(`(${component})`, ...args),
    log: (...args: any[]) => wfLog(`(${component})`, ...args),
    warn: (...args: any[]) => wfWarn(`(${component})`, ...args),
    error: (error: Error, msg?: string) => wfError(error, `(${component}) ${msg}`),
  }
}
