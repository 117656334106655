import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import ProcessingApplication from 'src/assets/illustrations/processingApplication.svg'

export const ApplicationProcessingTemplate: FC = () => {
  const {t} = useTranslation('ApplicationProcessing')

  return (
    <Page variant="generic" smallTopGap={true} testID="ApplicationProcessingTemplate">
      <Box align={'center'} marginTop="little">
        <PFSvgContain svg={ProcessingApplication} width={'85%'} />
      </Box>
      <Box>
        <Box paddingTop="medium">
          <PFText variant="h3">{t('ApplicationSubmitted1')}</PFText>
          <PFText variant="h3">{t('ApplicationSubmitted2')}</PFText>
        </Box>
        <Box paddingTop="little">
          <PFText variant="p">{t('Description')}</PFText>
        </Box>
      </Box>
    </Page>
  )
}
