import React, {ReactNode} from 'react'

import WarningIcon from 'src/assets/icons/Warning.svg'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {LargeArrowUpRight} from 'src/designSystem/components/atoms/SvgIcon/assets'

export enum PaymentNotificationLevel {
  Warn = 'Warn',
  Error = 'Error',
  Info = 'Info',
}

const PaymentNotifictionBackgroundColor = {
  [PaymentNotificationLevel.Warn]: {
    color: NamedColors.SAND,
    icon: WarningIcon,
  },
  [PaymentNotificationLevel.Error]: {
    color: NamedColors.BLUSH,
    icon: WarningIcon,
  },
  [PaymentNotificationLevel.Info]: {
    color: NamedColors.WHITESMOKE,
    icon: LargeArrowUpRight,
  },
}

type PaymentNotificationProps = {
  variant: PaymentNotificationLevel
  title: string
  content: string | ReactNode
}

export const PaymentNotification = (props: PaymentNotificationProps): JSX.Element => {
  const {variant, title} = props
  let {content} = props
  const backgroundColor =
    PaymentNotifictionBackgroundColor[variant]?.color ?? NamedColors.WHITESMOKE
  const icon = PaymentNotifictionBackgroundColor[variant]?.icon ?? WarningIcon

  if (typeof content === 'string') {
    content = <PFText variant="p">{content}</PFText>
  }

  return (
    <Box direction="row" background={backgroundColor} radius={8} width={'100%'}>
      <Box align="center" direction="row" justify="around" width={'25%'} height={'100%'}>
        <Box justify="center" align={'center'}>
          <PFSvgContain svg={icon} width={27} height={24} />
        </Box>
      </Box>
      <Box height={'100%'} width={3} background={'white'} />
      <Box width={'75%'} justify="center" align={'start'} padding="small">
        <PFText variant="p_semibold">{title}</PFText>
        {content}
      </Box>
    </Box>
  )
}
