import React, {FC} from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {borderColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import {SvgIconButton} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

export type MenuItemProps = {
  text: string
  onPress: () => void
  testID: string
}

const MenuItem: FC<MenuItemProps> = (props) => {
  const {text, onPress: handleOnPress, testID} = props

  return (
    <View style={styles.containerView}>
      <TouchableWithoutFeedback onPress={handleOnPress}>
        <View style={styles.contentView}>
          <View style={styles.textView}>
            <PFText testID={testID} variant={'label_md'}>
              {text}
            </PFText>
          </View>
          <SvgIconButton
            onPress={handleOnPress}
            name={'chevronRight'}
            colorVariant={'info'}
            size={'small'}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  )
}

export {MenuItem}

const styles = StyleSheet.create({
  containerView: {
    borderBottomWidth: 1,
    borderColor: borderColor,
    justifyContent: 'center',
    paddingLeft: smallGap,
    paddingVertical: smallGap,
    width: '100%',
  },
  contentView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: smallGap,
    width: '100%',
  },
  textView: {
    flex: 1,
  },
})
