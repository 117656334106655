import React, {FC} from 'react'

import {
  AcceptLoanAndAutoPayAgreementsBaseTemplate,
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'

export type AcceptAutoPayAgreementTemplateProps = Omit<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'showLoanAgreementCheckbox' | 'onOpenLoanAgreement'
>

/**
 * Page template for a screen that asks users to accept the autopay agreement. Does not
 * include the loan agreement checkbox.
 */
export const AcceptAutoPayAgreementTemplate: FC<AcceptAutoPayAgreementTemplateProps> = (props) => {
  return (
    <AcceptLoanAndAutoPayAgreementsBaseTemplate
      {...props}
      // this is the AutoPay only screen so we don't show the Loan Agreement
      onOpenLoanAgreement={(): void => {}}
      testID="AutoPayAgreement"
      showLoanAgreementCheckbox={false}
    />
  )
}
