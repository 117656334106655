import React, {FC, useEffect} from 'react'
import {StyleSheet} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {SafeAreaView} from 'react-native-safe-area-context'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import CardActivatingPhysicalCardSvg from 'src/assets/illustrations/activatingPhysicalCard.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {useUser} from 'src/cassandra'
import AppNav from 'src/nav/AppNavActions'
import Snackbar from 'src/lib/Snackbar'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

const closeDelay = 10000

type Props = StackScreenProps<MainStackParamList, 'CardActivatingPhysicalCard'>

const CardActivatingPhysicalCard: FC<Props> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_activating_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardActivatingPhysicalCard'])
  const user = useUser(false)

  // if we don't get any responses from BE or lost connection, we close this modal to avoid user to get stuck
  useEffect(() => {
    const timerClose = setTimeout(() => {
      Snackbar.error({
        title: user?.error?.message ?? t('CardActivationError'),
        duration: Snackbar.LENGTH_LONG,
      })
      AppNav.pop(navigation)
    }, closeDelay)

    return () => {
      clearTimeout(timerClose)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SafeAreaView style={styles.pageView}>
      <Box padding={'large'} gap={'large'} marginTop={'large'} boxStyle={styles.wrapper}>
        <Box marginBottom={'large'} marginTop={'large'}>
          <PFText variant={'h1'} textAlign={'center'}>
            {t('ActivingYourPossibleCard')}
          </PFText>
        </Box>
        <Box padding={'large'}>
          <PFSvgContain width={'100%'} svg={CardActivatingPhysicalCardSvg} />
        </Box>
      </Box>
    </SafeAreaView>
  )
}

export default CardActivatingPhysicalCard

const styles = StyleSheet.create({
  pageView: {
    backgroundColor: genericPageBackground,
    flex: 1,
  },
  wrapper: {
    flex: 1,
  },
})
