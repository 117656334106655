import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  ActionListItem,
  ActionListItemProps,
} from 'src/products/general/components/molecules/ActionListItem/ActionListItem'

import {DefaultVariantsColor} from 'src/designSystem/colors'

export const UnlinkedBankPaymentMethodLink: FC<Partial<ActionListItemProps>> = ({
  onPress: handleOnPress,
  rightStatusPill,
  ...props
}) => {
  const {t} = useTranslation('PaymentMethods')

  return (
    <ActionListItem
      isFirstChild
      onPress={handleOnPress}
      subTitle={t('LinkAccount')}
      title={t('AddPaymentMethod')}
      titleColor={DefaultVariantsColor.error}
      rightStatusPill={rightStatusPill}
      {...props}
    />
  )
}
