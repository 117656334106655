import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import LightboxNotice, {
  LightboxNoticeAction,
} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type WhyChargedOffModalProps = {
  onMakeAPayment: () => void
}
/**
 * A modal explaining why a loan was charged off.
 * @returns
 */
const WhyChargedOffModalContent: FC<WhyChargedOffModalProps> = (props) => {
  const {onMakeAPayment} = props
  const {t} = useTranslation('DashboardWhyChargedOffModal')

  const makePaymentAction: LightboxNoticeAction = {
    text: t('MakePaymentBtn'),
    onPress: onMakeAPayment,
    closeOnPress: true,
    size: 'large',
  }
  const closeModalAction: LightboxNoticeAction = {
    text: t('OkThanksBtn'),
    closeOnPress: true,
    size: 'large',
  }

  return (
    <LightboxNotice
      title={t('Title')}
      titleTextAlign={'left'}
      primary_action={makePaymentAction}
      secondary_action={closeModalAction}
    >
      <Box gap="medium">
        <PFText variant="p">
          <PFText variant="p_semibold">{t('OverdueReported')}</PFText>
          <PFText variant="p">{t('ChargedOffExplanation')}</PFText>
        </PFText>
        <PFText variant="p">{t('YouCanStillSettle')}</PFText>
      </Box>
    </LightboxNotice>
  )
}

/**
 * Show a modal explaining why a loan was charged off.
 */
export function showWhyChargedOffModal(props: WhyChargedOffModalProps): void {
  ShowLightbox(<WhyChargedOffModalContent {...props} />, true)
}
