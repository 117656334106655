import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {displayUpcomingPaymentsTile} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCards} from 'src/products/card/hooks/useCards'

export const useShouldDisplayUpcomingPaymentsCard = (): boolean => {
  const {accountStatus, autopayEnabled} = useCards()
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const doesSupportMinPay = cardMinPayResponse?.supportsMinPay
  return displayUpcomingPaymentsTile(accountStatus, autopayEnabled, doesSupportMinPay)
}
