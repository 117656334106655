import {UseTranslationResponse} from 'react-i18next'

import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'

import {OverlaySimpleProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'

export const getCardPaymentErrorProps = ({
  t,
  reason,
  isInLoanApprovalFlow,
}: {
  t: UseTranslationResponse<string, string>['t']
  reason?: PaymentMethodsAlertModalErrorReason
  isInLoanApprovalFlow?: boolean
}): Partial<OverlaySimpleProps> | undefined => {
  const unsupportedCardTitle = t('Bank:UnableToLinkCardMessage')
  const linkDebitCardLabel = t('Bank:linkDebitCard')
  const labelTryAgain = t('Common:TryAgain')
  const labelContinue = t('Common:Continue')
  const genericTitle = t('Bank:ErrorPaymentGenericTitle')
  const supportedCardsText = {
    replace: {supportedCard: t('Bank:VISAorMastercard')},
  }

  switch (reason) {
    case AddPaymentMethodResultType.InvalidCardType:
      return {
        title: t('Bank:ErrorInvalidCardTypeTitle'),
        text: t('Bank:ErrorInvalidCardTypeText'),
        okayButtonText: linkDebitCardLabel,
      }
    case AddPaymentMethodResultType.AlreadyExists:
      return {
        title: t('Bank:ErrorCardAlreadyLinkedTitle'),
        text: t('Bank:ErrorCardAlreadyLinkedText'),
        okayButtonText: linkDebitCardLabel,
      }
    case 'PAYMENT_UNSUPPORTED_CARD':
      return {
        title: unsupportedCardTitle,
        text: t('Bank:UnableToPayWithDebitCard', supportedCardsText),
        okayButtonText: labelContinue,
      }
    case 'DISBURSEMENT_UNSUPPORTED_CARD':
      return {
        title: unsupportedCardTitle,
        text: isInLoanApprovalFlow
          ? t('Bank:UnableToSendMoneyToUserDebitCardLoanAcceptance', supportedCardsText)
          : t('Bank:UnableToSendMoneyToUserDebitCardPMM', supportedCardsText),
        okayButtonText: labelContinue,
      }
    case AddPaymentMethodResultType.InvalidAccountNumber:
    case AddPaymentMethodResultType.InvalidRoutingNumber:
      return {
        title: genericTitle,
        text: t('Bank:ErrorCardInvalidRoutingOrAccountText'),
        okayButtonText: labelTryAgain,
      }
    default:
      return {
        title: genericTitle,
        text: t('Bank:ErrorCheckYourDetailsText'),
        okayButtonText: labelTryAgain,
      }
  }
}
