import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {UserCanUpdateAddressDocument} from 'src/products/general/AddressUpdates/AddressUpdates.gqls'
import Log from 'src/lib/loggingUtil'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

type UseCanUserUpdateAddressReturnType = {
  canUserUpdateAddress: boolean
  isAddressUpdateEnabled: boolean
  isLoading: boolean
}
export const useCanUserUpdateAddress = (): UseCanUserUpdateAddressReturnType => {
  const {data, loading: isLoading} = useCassandraQuery(UserCanUpdateAddressDocument, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      Log.error(`${error.message} - Profile Screen - UserCanUpdateAddressDocument query`)
    },
  })
  const isAddressUpdateEnabled = useIsFeatureFlagEnabled('self-serve-address-profile')
  return {
    canUserUpdateAddress: data?.me.canUpdateAddress ?? false,
    isAddressUpdateEnabled,
    isLoading,
  }
}
