import React, {FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PaymentMethodAccount from 'src/products/card/Common/PaymentMethodAccount/PaymentMethodAccount'
import {
  getPaymentMethodInstitution,
  getPaymentMethodMask,
  getPaymentMethodTypeName,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {NamedColors} from 'src/designSystem/colors'
import {useDefaultPaymentAccount} from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/useDefaultPaymentAccount'

type Props = {
  title: string
}

export const CardAutopaySchedulePaymentsMethod: FC<Props> = ({title}) => {
  const {defaultPaymentAccount} = useDefaultPaymentAccount()

  if (!defaultPaymentAccount) {
    return null
  }
  return (
    <>
      <PFText variant={'p_semibold'}>{title}</PFText>
      <Box marginTop={'tiny'}>
        <PaymentMethodAccount
          bankName={getPaymentMethodInstitution(defaultPaymentAccount)?.name ?? ''}
          accountType={getPaymentMethodTypeName(defaultPaymentAccount)}
          accountMask={getPaymentMethodMask(defaultPaymentAccount) ?? ''}
          hideBorder={true}
          variant="small"
          color={NamedColors.SILVER}
        />
      </Box>
    </>
  )
}
