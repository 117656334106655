import {PaymentAction} from 'src/products/general/components/molecules/PaymentInput/PaymentInput.types'

type GetIsButtonSelectedProps = {
  buttonActions: PaymentAction[]
  currentAction: PaymentAction
  inputValue: string
  trackingId?: string
}

const getIsButtonSelected = ({
  buttonActions,
  currentAction,
  inputValue,
}: GetIsButtonSelectedProps): boolean => {
  const buttonValues = buttonActions.map((action) => parseFloat(action.amount).toFixed(2))

  return (
    parseFloat(currentAction.amount).toFixed(2) === parseFloat(inputValue).toFixed(2) ||
    (currentAction.isFallback && !buttonValues.includes(parseFloat(inputValue).toFixed(2)))
  )
}

export {getIsButtonSelected}
export type {GetIsButtonSelectedProps}
