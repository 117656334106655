import moment from 'moment'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

import {
  LoanProgressTileLoanPaymentFields,
  LoanProgressTileTestIds,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile.types'

type LoanProgressTileProblemNoticeProps = {
  firstMissedPayment: LoanProgressTileLoanPaymentFields
  isChargedOff: boolean
  isInDefault: boolean
  fundingFailed: boolean
  noAccountLinked: boolean
  onShowDefaultExplanation: () => void
  onShowChargedOffExplanation: () => void
}

/**
 * Define a pre-configure PFText for use in <Trans>
 * @param props
 * @returns
 */
const PFTextForTrans: React.FC<React.PropsWithChildren> = (props) => {
  const {children} = props
  return (
    <PFText
      variant="p_sm"
      textAlign="center"
      color={NamedColors.SILVER}
      testID="Loan-Progress-Tile-Problem-Notice"
    >
      {children}
    </PFText>
  )
}

/**
 * Notices related to any problems that are shown on the LoanProgressTile.
 */
const LoanProgressTileProblemNotice: React.FC<LoanProgressTileProblemNoticeProps> = (
  props: LoanProgressTileProblemNoticeProps,
) => {
  const {
    firstMissedPayment,
    isChargedOff,
    isInDefault,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    fundingFailed,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    noAccountLinked,
    onShowDefaultExplanation,
    onShowChargedOffExplanation,
  } = props

  const {t} = useTranslation('DashboardLoanProgressTile')

  return (
    <>
      {/* missed payment notice when loan is still active */}
      {firstMissedPayment && !isChargedOff && !isInDefault ? (
        <Box marginTop="little">
          <PFText variant="p_sm" textAlign="center" color={NamedColors.SILVER}>
            {t('MissedPaymentWasDueOn', {
              missedAmount: parseFloat(firstMissedPayment.amount).toFixed(2),
              date: moment(
                firstMissedPayment.rescheduledDate || firstMissedPayment.originalDate,
              ).format('MM/DD/YY'),
            })}
          </PFText>
        </Box>
      ) : null}

      {/* default notice */}
      {isInDefault ? (
        <Box marginTop="little">
          <Trans i18nKey="LoanIsInDefault" t={t} parent={PFTextForTrans}>
            Your loan is in
            <PFText
              variant="p_sm"
              color={NamedColors.SILVER}
              textProps={{style: {textDecorationLine: 'underline'}}}
              onPress={(): void => {
                onShowDefaultExplanation()
              }}
              testID={LoanProgressTileTestIds.DefaultStatusLink}
            >
              default
            </PFText>
          </Trans>
        </Box>
      ) : null}

      {/* charged off notice */}
      {isChargedOff ? (
        <Box marginTop="little">
          <Trans i18nKey="LoanIsChargedOff" t={t} parent={PFTextForTrans}>
            Your loan is
            <PFText
              variant="p_sm"
              color={NamedColors.SILVER}
              textProps={{style: {textDecorationLine: 'underline'}}}
              onPress={(): void => {
                onShowChargedOffExplanation()
              }}
              testID={LoanProgressTileTestIds.ChargedOffStatusLink}
            >
              charged off
            </PFText>
          </Trans>
        </Box>
      ) : null}

      {/* no account linked notice */}
      {noAccountLinked ? (
        <Box marginTop="little">
          <PFText variant="p_sm" color={NamedColors.SILVER} textAlign="center">
            {t('NoAccountLinked')}
          </PFText>
        </Box>
      ) : null}

      {/* funding failed notice */}
      {fundingFailed ? (
        <Box marginTop="little">
          <PFText variant="p_sm" color={NamedColors.SILVER} textAlign="center">
            {t('FundingFailed')}
          </PFText>
        </Box>
      ) : null}
    </>
  )
}

export {LoanProgressTileProblemNotice}
