/* eslint-disable react-hooks/rules-of-hooks */

import {AsyncThunkAction} from '@reduxjs/toolkit'
import {useEffect, useState} from 'react'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {CombinedState} from 'redux'

import {CassandraRootStateType, QueryStatus, StateBase} from '@possible/cassandra'
import {PfReduxState} from 'src/reducers/types'
import {PfReduxStore} from 'src/store/types'

export type CassandraDispatch = PfReduxStore['dispatch']
export type CassandraRootState = ReturnType<PfReduxStore['getState']>['cassandra']
export const useCassandraDispatch = (): CassandraDispatch => useDispatch<CassandraDispatch>()
export const useCassandraSelector: TypedUseSelectorHook<CassandraRootState> = <TSelected>(
  selector: (cassandra: CassandraRootState) => TSelected,
) => useSelector((state: PfReduxState) => selector(state.cassandra))

type thunkPromiseType = {
  abort(): void
}

/**
 * Add a random amount between 0 and 2.5s to the polling interval to make it slightly random.
 * This is to prevent all the polling requests from being made at the exact same time.
 */
export const getRandomPollingInterval = (pollingInterval: number): number => {
  return pollingInterval + Math.floor(Math.random() * 2500)
}

type SmartSelector<S extends StateBase> = (
  /**
   * SmartSelectors are selectors that are aware of the status of their queries.
   * If they haven't been fetched yet, the SmartSelector will do the fetching. You can set them
   * to force refresh (should be briefly true, then false) for a pull to refresh user experience,
   * or set them to poll at a regular interval.
   *
   * @param forceRefresh
   * Use this to implement 'pull to refresh' interaction, or a reload button.
   * Do not pass const true as this will infinite loop.
   *
   * @param fetchEnabled
   * To control if the requests should be fired.
   */
  forceRefresh?: boolean,
  fetchEnabled?: boolean,
) => S

export const SmartSelectorFactory = <
  StateType extends StateBase,
  ThunkType extends AsyncThunkAction<unknown, unknown, Record<string, unknown>>,
>(
  selector: (state: CombinedState<CassandraRootStateType>) => StateType,
  queryThunk: (state: CassandraRootStateType) => ThunkType,
  requiresAuthentication: boolean,
): SmartSelector<StateType> => {
  const useSmartSelector = (forceRefresh = false, fetchEnabled = true): StateType => {
    const state = useCassandraSelector((s) => s)
    const selectedState = useCassandraSelector(selector)
    const {queryStatus} = selectedState
    const token = useCassandraSelector((s) => s.env.token)
    const dispatch = useCassandraDispatch()
    const [shouldForceRefresh, setShouldForceRefresh] = useState<boolean>(forceRefresh)
    let thunkPromise: thunkPromiseType | undefined

    const hasAuthenticationToken = !requiresAuthentication || token
    const isQueryFulfilledOrRejected =
      queryStatus === QueryStatus.Fulfilled || queryStatus === QueryStatus.Rejected
    const shouldForceRefreshOrQueryStatusInitial =
      shouldForceRefresh || queryStatus === QueryStatus.Initial
    const defaultPollingValue = 30000

    useEffect(() => {
      if (fetchEnabled && isQueryFulfilledOrRejected && hasAuthenticationToken) {
        const id = setInterval(() => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          thunkPromise = dispatch(queryThunk(state))
        }, getRandomPollingInterval(defaultPollingValue))
        return () => clearInterval(id)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedState.queryStatus, token])

    useEffect(() => {
      if (fetchEnabled && hasAuthenticationToken && shouldForceRefreshOrQueryStatusInitial) {
        setShouldForceRefresh(false)
        void dispatch(queryThunk(state))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedState, shouldForceRefreshOrQueryStatusInitial, dispatch])

    // On unmount, cancel any in-flight requests
    useEffect(() => {
      return () => {
        thunkPromise?.abort()
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return selectedState
  }
  return useSmartSelector
}
