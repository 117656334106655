import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type PersonalInfoConfirmationQueryVariables = Types.Exact<{[key: string]: never}>

export type PersonalInfoConfirmationQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    identification?: {
      __typename?: 'IssuedIdentificationInfo'
      ssn?: {__typename?: 'SSN'; mask: string} | null
    } | null
    profile?: {
      __typename?: 'UserProfile'
      birthDate?: string | null
      email?: {__typename?: 'Email'; address?: string | null} | null
      home?: {
        __typename?: 'Location'
        address?: {
          __typename?: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          verified: boolean
        } | null
      } | null
      name?: {
        __typename?: 'Name'
        firstName: string
        middleName?: string | null
        lastName?: string | null
        suffix?: string | null
      } | null
    } | null
  }
}

export const PersonalInfoConfirmationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'PersonalInfoConfirmation'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'identification'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'ssn'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'mask'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'birthDate'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'email'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'address'}}],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'name'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'middleName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'suffix'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PersonalInfoConfirmationQuery, PersonalInfoConfirmationQueryVariables>
