import {Consumer, EnvironmentType} from '@possible/cassandra'
import APIClient, {APIClientOptions, ClientInfo} from '@possible/generated/APIClient'
import DeviceInfo from 'react-native-device-info'
import {Platform} from 'react-native'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import {apiUriDev, apiUriProd} from '@possible/generated/src/api/lib/ApiUris'

import {STORE_USER_ID_TOKEN_ACTION, USER_LOGGED_IN_ACTION} from 'src/api/actions/actionsNames'
import {APIStateChange} from 'src/api/actions/index'
import APIClientLoan from 'src/api/lib/APIClientLoan'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {ApiReduxState, UserLoginStates} from 'src/api/reducers/types'
import {initializeCassandra} from 'src/cassandra/initializeCassandra'
import codePush from 'src/products/general/CodePush'
import {API_CLIENT_LOGGING} from 'src/config'
import {SetAmplitudeUserProperties, SetUserId} from 'src/lib/Analytics/analytics_compat'
import {readDevMode, readEnv} from 'src/lib/devMode'
import Firebase from 'src/lib/firebase'
import * as Keychain from 'src/lib/keychain'
import Log from 'src/lib/loggingUtil'
import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {isJailBroken} from 'src/lib/jailBreak'
import {PfDispatch, PfGetState} from 'src/store/types'
import {setShouldEnrollInCards} from 'src/lib/card/actions'
import singular from 'src/lib/singular'
import {ConnectionError, ConnectionSuccess} from 'src/api/actions/client/connectionActions'
import {PfReduxState} from 'src/reducers/types'

const APP_UI_VERSION_PROPERTY_NAME = 'app_ui_version'

export const DevModeAction = () => APIStateChange({dev_mode: true})

//// THUNKS

const trackAppUiVersion = async () => {
  const codePushMetadata = await codePush.getUpdateMetadata()

  const uiVersion = codePushMetadata?.label ?? 'undefined'
  SetAmplitudeUserProperties(`[Possible Finance] ${APP_UI_VERSION_PROPERTY_NAME}`, uiVersion)
}

const trackDeviceJailbroken = async (userId?: string) => {
  if (isJailBroken()) {
    Log.warn(`Device is jailbroken! userId: ${userId}`)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function UserLoggedIn(
  refreshLoginMethod?: boolean,
  isLoginFromRegistration: boolean = false,
) {
  return async (dispatch: PfDispatch, getState: PfGetState): Promise<boolean> => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const userId = getState().api.user_id! //user id must be set by now
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const token = getState().api.user_token!

    SetUserId(userId)
    void trackAppUiVersion()

    await initializeCassandra(dispatch, token)

    dispatch(APIStateChange({initialUserRefresh: true}))
    if (!isLoginFromRegistration) {
      dispatch(UserLoggedInAction())
    }
    return true
  }
}
export function GetURIsFromEnv(env: EnvironmentType) {
  switch (env) {
    case EnvironmentType.LocalJava:
    case EnvironmentType.Local:
    case EnvironmentType.Dev:
      return apiUriDev
    default:
      //default to production
      return apiUriProd
  }
}

export async function GetURIs() {
  const env = await getEnvironment()
  return GetURIsFromEnv(env)
}

export async function GetClientInfo(): Promise<ClientInfo> {
  const info: ClientInfo = {}
  try {
    if (isDeviceWeb()) {
      // we have only a few available properties for web browsers
      info.clientType = 'web_app'
      info.deviceId = (await (await FingerprintJS.load()).get()).visitorId
      info.deviceOsName = await DeviceInfo.getBaseOs()
    } else {
      info.clientType = 'mobile_app'
      info.deviceId = DeviceInfo.getUniqueIdSync() // BE uses appUniqueId for device id
      info.deviceCarrier = (await DeviceInfo.getCarrier()).substring(0, 40)
      info.deviceMfg = (await DeviceInfo.getManufacturer()).substring(0, 40)
      info.deviceOsName = Platform.OS
    }

    info.appUniqueId = info.deviceId
  } catch (error: unknown) {
    let message = 'Unknown Error'
    if (error instanceof Error) {
      message = error.message
    }
    Log.log(`getClientInfo failed, e : ${message}`)
  }
  return info
}

async function getAPIClientOptions(
  dispatch: PfDispatch,
  getState: () => PfReduxState,
): Promise<APIClientOptions> {
  try {
    const uris = await GetURIs()

    const handleLoggedIn = async (): Promise<boolean> => {
      const result = await dispatch(UserLoggedIn(true))

      if (result) {
        dispatch(
          APIStateChange({
            user_logged_state: UserLoginStates.logged_in,
          }),
        )
      }
      return result
    }

    const handleNotLoggedIn = () => {
      dispatch(
        APIStateChange({
          user_logged_state: UserLoginStates.not_logged_in,
          user_id: undefined,
          user_token: undefined,
        }),
      )
    }

    const handleLogout = async () => {
      dispatch(Logout(true))
    }

    const handleConnectionError = async () => {
      dispatch(ConnectionError())
    }

    const handleConnectionSuccess = async () => {
      dispatch(ConnectionSuccess())
    }

    const user_creds = await read_user_id()
    const userId = getState().api.user_id
    const clientInfo = await GetClientInfo()

    const instanceId = Firebase.instanceId
    await dispatch(APIStateChange({instanceId}))

    const handleStateChange = async (newState: Partial<ApiReduxState>) => {
      await dispatch(APIStateChange(newState))
    }

    const devMode = await readDevMode()

    return {
      shouldLog: API_CLIENT_LOGGING,

      log: Log.log,
      warn: Log.warn,
      error: Log.error,

      username: user_creds && user_creds?.username !== '_pfo' ? user_creds.username : undefined,
      password: user_creds && user_creds?.password !== '1' ? user_creds.password : undefined,
      // this is silly but TS needs a string here. we're deleting this soon anyways so whatever
      userId: userId ?? '',
      userToken: userId ?? '',

      devMode,

      instanceId,

      ...uris,

      badConnection: getState()?.api.badConnection,
      maintainenceOn: false,

      onLoggedIn: handleLoggedIn,
      onNotLoggedIn: handleNotLoggedIn,
      onLogout: handleLogout,

      onConnectionError: handleConnectionError,
      onConnectionSuccess: handleConnectionSuccess,
      checkIsMaintenance: () => Promise.resolve(false),
      onStateChange: handleStateChange,

      platform: Platform.OS,

      clientInfo,
    }
  } catch (e) {
    Log.error(e, 'getAPIClientOptions: ')
    throw e
  }
}

export function InitClient() {
  return async (dispatch, getState) => {
    try {
      const userCreds = await read_user_id()
      const userId = userCreds ? userCreds.username : undefined
      Firebase.init()

      const apiClientOptions = await getAPIClientOptions(dispatch, getState)
      const envOverride = await readEnv()
      initializeCassandra(dispatch, userCreds ? userCreds.password : undefined)

      APIClientLoan.init(dispatch, getState)
      // APIClient.init depends on PfOpenApi being initialized
      const response = await APIClient.init(apiClientOptions)
      if (response === false) {
        throw Error('DoNotLog')
      }

      if (userId && userCreds && userCreds.password !== '1') {
        await Firebase.registerNotifications()
        if (Firebase.fcmToken) {
          singular.setPushToken(Firebase.fcmToken)
        }
      }
      dispatch(APIStateChange({init: true, options: apiClientOptions, envOverride}))

      trackDeviceJailbroken(userId)
    } catch (e) {
      if (e instanceof Error && e.message !== 'DoNotLog') {
        Log.error(e, 'Unable to complete InitClient')
      }
      dispatch(Logout())
      dispatch(APIStateChange({init: true}))
    }
  }
}

function StoreUserIdTokenAction(userId: string, token: string) {
  return async (dispatch) => {
    dispatch({type: STORE_USER_ID_TOKEN_ACTION, user_id: userId, token})
  }
}

function UserLoggedInAction() {
  return {type: USER_LOGGED_IN_ACTION}
}

const handlePostLoginDeeplinks = async (userId: string, dispatch: PfDispatch) => {
  const deeplink = getDeeplink('offer')
  if (deeplink) {
    const {params} = deeplink

    try {
      await Consumer.methods.partnerLinkUserWithOffer(params.offer_id)
      await clearDeeplink(deeplink)
    } catch (e) {
      Log.error(e, `Error linking partner offer. offer_id: "${params.offer_id}"`)
    }
  }

  const cardDeeplink = getDeeplink('card_landing')
  if (cardDeeplink) {
    try {
      dispatch(setShouldEnrollInCards())
      await clearDeeplink(cardDeeplink)
    } catch (e) {
      Log.error(e, 'Error adding user to card group.')
    }
  }
}

export async function initUserAuth({
  userId,
  accessToken,
  dispatch,
  isLoginFromRegistration = false,
}: {
  userId: string
  accessToken: string
  dispatch: PfDispatch
  isLoginFromRegistration: boolean
}): Promise<void> {
  await save_user_id(userId, accessToken)

  APIClient.updateOptions({
    userId,
    userToken: accessToken,
  })
  Firebase.init()

  await handlePostLoginDeeplinks(userId, dispatch)

  await dispatch(StoreUserIdTokenAction(userId, accessToken))
  await Firebase.registerNotifications()
  if (Firebase.fcmToken) {
    singular.setPushToken(Firebase.fcmToken)
  }

  await dispatch(UserLoggedIn(false, isLoginFromRegistration))
}

export async function save_user_id(username, password) {
  return Keychain.setServicePassword(username, password)
}

export async function read_user_id() {
  //This method supports transitioning from storing our service password in the generic key store, to the specific service key store
  //The Firebase SDK was overwriting the generic key store. https://github.com/oblador/react-native-keychain/issues/363
  try {
    const servicePassword = await Keychain.getServicePassword()
    if (!servicePassword) {
      //check generic key store and attempt a transition
      const user_creds = await Keychain.getGenericPassword()
      if (user_creds && user_creds?.username === '_pfo') {
        //Firebase is using the apps keychain on iOS, this is conflicting with our usage of it.
        Log.info(`firebase has overriden generic keychain`)
        return false
      }

      //update access token
      if (user_creds) {
        await Keychain.setServicePassword(user_creds.username, user_creds.password)
        return {
          username: user_creds.username,
          password: user_creds.password,
        }
      }
    }
    return servicePassword
  } catch (e) {
    return false
  }
}
