import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import React, {useEffect, useState} from 'react'

import Log from 'src/lib/loggingUtil'
import {usePreferredAccount} from 'src/lib/bank/usePreferredAccount'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {
  ConvertLoanUpgradeToInstallmentPlanDocument,
  UpgradeToInstallmentPlanDocument,
  UpgradeToInstallmentPlanGetConversionOptionsDocument,
} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlan.gqls'
import {PaymentInfoBoxPayment} from 'src/products/loans/LoanApprovedActivation/components/PaymentInfoBox/PaymentInfoBox'
import {
  UpgradeToInstallmentPlanTemplate,
  UpgradeToInstallmentPlanTemplateProps,
} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlanTemplate'

export type UpgradeToInstallmentPlanGQLContainerProps = {
  onConvertLoanSuccess: () => void | Promise<void>
} & Pick<
  UpgradeToInstallmentPlanTemplateProps,
  'onDismissDeclineModal' | 'onOpenDeclineModal' | 'onDecline'
>

export const UpgradeToInstallmentPlanGQLContainer: React.FC<
  UpgradeToInstallmentPlanGQLContainerProps
> = (props) => {
  const {
    onConvertLoanSuccess,
    onOpenDeclineModal: handleOnOpenDeclineModal,
    onDismissDeclineModal: handleOnDismissDeclineModal,
    onDecline: handleOnDecline,
  } = props
  const {
    value: preferredAccount,
    isLoading: isPreferredAccountLoading,
    preferredAccountError,
  } = usePreferredAccount()

  const {selectedData} = useCassandraQuery(
    UpgradeToInstallmentPlanDocument,
    {
      onError: (e) => {
        logOfferActivationError(e, 'UpgradeToInstallmentPlanGQLContainer failed to load loan id')
      },
    },
    (from) => {
      return {
        loanId: from.me.loans.latestActionableLoan?.id,
      }
    },
  )
  const loanId = selectedData?.loanId
  const [submitConvertLoan, {loading: isConvertLoanSubmitting}] = useCassandraMutation(
    ConvertLoanUpgradeToInstallmentPlanDocument,
  )
  const [
    getConversionOptions,
    {loading: isLoadingConversionOptions, error: getConversionOptionsError},
  ] = useCassandraMutation(UpgradeToInstallmentPlanGetConversionOptionsDocument)
  const [conversionOptionsValidationError, setConversionOptionsValidationError] = useState<
    Error | undefined
  >(undefined)

  const [conversionOffer, setConversionOffer] = useState<
    | {
        projectedPayments: PaymentInfoBoxPayment[]
        conversionOfferId: string
      }
    | undefined
  >(undefined)

  const handleOnSubmitConvertLoan = async (): Promise<void> => {
    if (!loanId || !conversionOffer?.conversionOfferId) {
      logOfferActivationErrorAndShowException(
        new Error('loanId or conversionOfferId is not available'),
        `UpgradeToInstallmentPlanGQLContainer unable to convert loan, loanId=${loanId} conversionOfferId=${conversionOffer?.conversionOfferId}`,
      )
      return
    }
    try {
      const res = await submitConvertLoan({
        variables: {
          input: {
            loanId,
            offerId: conversionOffer.conversionOfferId,
          },
        },
      })
      if (res.errors) {
        throw new Error(res.errors[0]?.message)
      }
      if (!res.data?.loanConvert.converted) {
        throw new Error('loanConvert response indicates conversion failed')
      }

      await onConvertLoanSuccess()
    } catch (e) {
      logOfferActivationErrorAndShowException(
        e,
        'UpgradeToInstallmentPlanGQLContainer failed to convert loan',
      )
    }
  }

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (!loanId) {
        return
      }
      // load the conversion options once the loanId is available
      try {
        setConversionOptionsValidationError(undefined)
        const res = await getConversionOptions({
          variables: {
            input: {
              loanId,
              includeOffers: true,
            },
          },
        })
        Log.log('getConversionOptions: ', res)
        if (res.errors?.[0]) {
          throw res.errors[0]
        }
        const projectedPayments = res.data?.loanConversionOptions?.options?.[0]?.projectedPayments
        const conversionOfferId = res.data?.loanConversionOptions?.options?.[0]?.offerId

        if (!projectedPayments || !conversionOfferId) {
          throw new Error('projected payments or offerId not returned')
        }
        setConversionOffer({
          projectedPayments: projectedPayments.map((payment) => ({
            // I don't like these fallback strings but every field is nullable
            id: payment?.id ?? '',
            date: payment?.originalDate ?? '',
            amount: payment?.amount ?? '',
          })),
          conversionOfferId,
        })
      } catch (e) {
        setConversionOptionsValidationError(e instanceof Error ? e : new Error(String(e)))
        void logOfferActivationErrorAndShowException(
          e,
          'UpgradeToInstallmentPlanGQLContainer failed to load conversion options',
        )
      }
    }

    void load()
  }, [loanId, getConversionOptions])

  return (
    <UpgradeToInstallmentPlanTemplate
      showFailedToLoadConversionOptions={
        !!getConversionOptionsError || !!conversionOptionsValidationError
      }
      isSubmitting={isConvertLoanSubmitting}
      onAccept={handleOnSubmitConvertLoan}
      onDecline={handleOnDecline}
      onOpenDeclineModal={handleOnOpenDeclineModal}
      onDismissDeclineModal={handleOnDismissDeclineModal}
      primaryAccount={{
        isLoading: isPreferredAccountLoading,
        hasError: !!preferredAccountError,
        mask: preferredAccount?.mask ?? '',
        type: preferredAccount?.type.toLowerCase() ?? '',
        institutionName: preferredAccount?.institution?.name ?? '',
      }}
      newPaymentDates={{
        isLoading: isLoadingConversionOptions,
        hasError: !!getConversionOptionsError || !!conversionOptionsValidationError,
        payments: conversionOffer?.projectedPayments ?? [],
      }}
    />
  )
}
