import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardActiveWithAutopayPaymentsQueryVariables = Types.Exact<{[key: string]: never}>

export type CardActiveWithAutopayPaymentsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        autopayEnabled: boolean
        installmentsEnabled: boolean
        payments: {
          __typename?: 'CardPaymentCollection'
          schedule?: Array<{
            __typename?: 'AutomaticPaymentSchedule'
            id?: string | null
            paymentDate: string
            originalScheduleDate: string
            rescheduleDate?: string | null
            paymentExecution: {__typename?: 'PaymentExecutionInfo'; executeAt: string}
            paymentAllowedRescheduleDates: {
              __typename?: 'CardPaymentAllowedRescheduleDatesCollection'
              statementDueDate?: string | null
              allowed: Array<{
                __typename?: 'CardPaymentRescheduleDate'
                date: string
                restriction: Types.CardPaymentRescheduleDateRestriction
              }>
            }
          }> | null
        }
      } | null
    }
  }
}

export type CardAccountReschedulePaymentMutationVariables = Types.Exact<{
  input: Types.CardAccountReschedulePaymentInput
}>

export type CardAccountReschedulePaymentMutation = {
  __typename?: 'Mutation'
  cardAccountReschedulePayment: boolean
}

export const CardActiveWithAutopayPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CardActiveWithAutopayPayments'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsEnabled'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'payments'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'schedule'},
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {kind: 'Name', value: 'numberOfPayments'},
                                        value: {kind: 'IntValue', value: '100'},
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'originalScheduleDate'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'rescheduleDate'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'paymentExecution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'executeAt'},
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'paymentAllowedRescheduleDates',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'statementDueDate'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'allowed'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'date'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'restriction'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardActiveWithAutopayPaymentsQuery,
  CardActiveWithAutopayPaymentsQueryVariables
>
export const CardAccountReschedulePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CardAccountReschedulePayment'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CardAccountReschedulePaymentInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountReschedulePayment'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardAccountReschedulePaymentMutation,
  CardAccountReschedulePaymentMutationVariables
>
