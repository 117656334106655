import React, {ReactNode} from 'react'

import {userIsLoggedIn} from 'src/api/selectors/selectors'
import {MainStackBaseOptions, MainStackNav} from 'src/nav/Stacks/consts'
import {AuthenticatedRoutes, UnauthenticatedRoutes} from 'src/nav/Stacks/Routes'
import {usePfSelector} from 'src/store/utils'
import {ProductSelectionWrapper} from 'src/nav/Stacks/ProductSelectionWrapper'

export const MainStack = (): ReactNode => {
  const isLoggedIn = usePfSelector(userIsLoggedIn)

  if (isLoggedIn) {
    return (
      <ProductSelectionWrapper>
        <AuthenticatedRoutes StackNav={MainStackNav} baseOptions={MainStackBaseOptions} />
      </ProductSelectionWrapper>
    )
  }

  return <UnauthenticatedRoutes StackNav={MainStackNav} baseOptions={MainStackBaseOptions} />
}
