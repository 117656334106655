import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardLoanDashboardTileQueryQueryVariables = Types.Exact<{[key: string]: never}>

export type CardLoanDashboardTileQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    areLoansInGoodStanding?: boolean | null
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        address?: {__typename?: 'Address'; state?: string | null} | null
      } | null
    } | null
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        status:
          | {
              __typename?: 'ActiveCardAccountStatus'
              autopayFrequency: Types.PaymentFrequency
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
              cardAccountOverdueStatus?: Types.CardAccountOverdueStatus | null
              daysUntilDelinquent?: number | null
              delinquentNumberOfDays?: number | null
              amountOverdue?: string | null
              chargedOffStatus?: Types.ChargedOffCardStatus | null
            }
          | {
              __typename?: 'ApprovedCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
            }
          | {
              __typename?: 'CancelledCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
            }
          | {
              __typename?: 'DeactivatedCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
              cardAccountOverdueStatus?: Types.CardAccountOverdueStatus | null
              reasonCode?: Types.DeactivatedCardAccountReasonCode | null
              delinquentNumberOfDays?: number | null
            }
          | {
              __typename?: 'ExpiredCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
              eligibleToReapplyAfter: string
            }
          | {
              __typename?: 'PendingCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
            }
          | {
              __typename?: 'RejectedCardAccountStatus'
              code: Types.CardAccountStatusCode
              occurredAt?: string | null
              subStatus: Types.CardAccountSubStatus
              eligibleToReapplyAfter: string
              rejectionReasonCodes?: Array<string | null> | null
            }
      } | null
    }
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        mask: string
        name?: string | null
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        lastSyncedAt?: string | null
        isDeletable: boolean
        institution?: {__typename?: 'Institution'; name: string} | null
        ownershipSummary?: {
          __typename?: 'LinkedAccountOwnershipSummary'
          ownerType: Types.LinkedAccountOwnerType
          ownershipStatus: Types.LinkedAccountOwnershipStatus
          validationErrors?: Array<{
            __typename?: 'LinkedAccountOwnershipValidationError'
            validationType: Types.LinkedAccountOwnershipValidationType
            validationStatus: Types.LinkedAccountOwnershipValidationStatus
            matchScore: number
          }> | null
        } | null
      }> | null
    }
  }
}

export const CardLoanDashboardTileQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CardLoanDashboardTileQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'state'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'PendingCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'RejectedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'eligibleToReapplyAfter'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'rejectionReasonCodes'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CancelledCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ExpiredCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'eligibleToReapplyAfter'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'autopayFrequency'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'cardAccountOverdueStatus'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'daysUntilDelinquent'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'delinquentNumberOfDays'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountOverdue'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'chargedOffStatus'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'cardAccountOverdueStatus'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'reasonCode'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'delinquentNumberOfDays'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'accountFilter'},
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {kind: 'Name', value: 'onlyAchEligible'},
                                  value: {kind: 'BooleanValue', value: true},
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastSyncedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isDeletable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'ownershipSummary'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'ownerType'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'ownershipStatus'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'validationErrors'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'validationType'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'validationStatus'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'matchScore'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'areLoansInGoodStanding'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardLoanDashboardTileQueryQuery,
  CardLoanDashboardTileQueryQueryVariables
>
