import React, {FC, useEffect} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {ApplicationProcessingTemplate} from 'src/products/general/ApplicationProcessing/ApplicationProcessingTemplate'
import {ApplicationProcessingProps} from 'src/products/general/ApplicationProcessing/types'
import {useCardApplicationProcessingStatus} from 'src/products/general/ApplicationProcessing/useCardApplicationProcessingStatus'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export const CardApplicationProcessing: FC<
  ApplicationProcessingProps & Pick<StackScreenProps<MainStackParamList>, 'navigation'>
> = ({onUnderwritingComplete: onProcessingComplete, navigation}) => {
  const {isApplicationUWComplete, isAccountStatusLoading} = useCardApplicationProcessingStatus()

  usePageViewedAnalytics({
    eventName: CardEvents.card_application_processing_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  useEffect(() => {
    if (!isAccountStatusLoading && isApplicationUWComplete) {
      void onProcessingComplete()
    }
  }, [isApplicationUWComplete, isAccountStatusLoading, onProcessingComplete])

  navigation.setOptions({
    headerShown: false,
  })

  return <ApplicationProcessingTemplate />
}
