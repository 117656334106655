import {LinkedAccountType} from '@possible/cassandra/src/types/types.mobile.generated'
import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useAggregatorAccountSelectionForPrimaryAccountQuery} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForPrimaryAccount/queries/AggregatorAccountSelectionForPrimaryAllAccounts/useAggregatorAccountSelectionForPrimaryAllAccountsQuery'
import {BankAggregatorAccountSelectionLinkedAccountSubset} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'
import {BankAggregatorAccountSelectionGQLContainer} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelectionGQLContainer'
import PrimaryAccountDescription from 'src/products/general/components/molecules/PrimaryAccountDescription/PrimaryAccountDescription'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type PropsFromRouter = Pick<
  StackScreenProps<MainStackParamList, 'BankAggregatorAccountSelectionForPrimaryAccount'>,
  'route'
>

/**
 * Screen that is used to select a primary bank account.
 */
const BankAggregatorAccountSelectionForPrimaryAccount: React.FC<PropsFromRouter> = (
  props: PropsFromRouter,
) => {
  const {route} = props
  const {
    accountsFromBankAggregator = [],
    onVerifyRoutingAndAccountFailed: handleOnVerifyRoutingAndAccountFailed,
    onVerifyRoutingAndAccountSucceeded: handleOnVerifyRoutingAndAccountSucceeded,
  } = route.params

  const {
    selectedData,
    loading: isLoading,
    error,
  } = useAggregatorAccountSelectionForPrimaryAccountQuery()
  const {t} = useTranslation(['BankAggregatorAccountSelectionForPrimary'])

  // we only allow new checking accounts from the aggregator to be chosen for primary
  const allLinkedAccountsValidForPrimary: BankAggregatorAccountSelectionLinkedAccountSubset[] =
    accountsFromBankAggregator.filter((account) => account.type === LinkedAccountType.Checking)

  // get all existing accounts that are valid for primary and combine them with any new accounts
  // that were just linked with the aggregator (plaid / yodlee)
  selectedData?.linkedCheckingAccounts.forEach((accountFromQuery) => {
    if (
      !allLinkedAccountsValidForPrimary.find(
        (newAccountFromAggregator) => newAccountFromAggregator.id === accountFromQuery.id,
      )
    ) {
      allLinkedAccountsValidForPrimary.push(accountFromQuery)
    }
  })

  usePageViewedAnalytics({
    eventName: BankAccountManagementEvents.select_primary_account_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
  })
  const handleOnErrorBoundary = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.select_primary_account_page_error_viewed,
      AppEvents.Category.BankAccountManagement,
    )
  }

  const handleOnSelectAccountContinue = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.select_primary_account_page_cta,
      AppEvents.Category.BankAccountManagement,
      {
        isFromOnboarding: false,
      },
    )
  }

  return (
    <BaseTemplate isLoading={isLoading} isError={!!error} onErrorBoundary={handleOnErrorBoundary}>
      <BankAggregatorAccountSelectionGQLContainer
        analytics={{
          selectAccount: {
            name: BankAccountManagementEvents.select_primary_account_page_select_cta,
            category: AppEvents.Category.BankAccountManagement,
            eventArgs: {
              isFromOnboarding: false,
            },
          },
          verifyRoutingAccountSucceeded: {
            name: BankAccountManagementEvents.primary_account_aggregator_account_selection_verify_succeeded,
            category: AppEvents.Category.ManageActiveLoan,
          },
          verifyRoutingAccountFailed: {
            name: BankAccountManagementEvents.primary_account_aggregator_account_selection_verify_failed,
            category: AppEvents.Category.ManageActiveLoan,
          },
        }}
        accountsFromBankAggregator={allLinkedAccountsValidForPrimary}
        // want to show "New Account" next to accounts that were just added
        showNewAccountIndicator={true}
        title={t('ChooseYourPrimaryAccount')}
        description={<PrimaryAccountDescription isChooseYourAccount={true} />}
        continueBtn={{
          testID: 'BankAggregatorAccountSelectionForPrimaryAccount-ContinueBtn',
          onContinue: handleOnSelectAccountContinue,
        }}
        failedVerifyBtn={{
          testID: 'BankAggregatorAccountSelectionForPrimaryAccount-FailedVerifyBtn',
        }}
        syncingOverlay={{
          title: t('SyncingInfo'),
          description: t('SyncingDontClose'),
        }}
        onVerifyRoutingAndAccountFailed={handleOnVerifyRoutingAndAccountFailed}
        onVerifyRoutingAndAccountSucceeded={handleOnVerifyRoutingAndAccountSucceeded}
      />
    </BaseTemplate>
  )
}

export {BankAggregatorAccountSelectionForPrimaryAccount}
