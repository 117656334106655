import {Maybe} from 'graphql/jsutils/Maybe'
import {Alert, Linking, Platform} from 'react-native'

const phoneNumber = `tel:${18559615354}`
const errorPrompt = 'Please call 1-855-961-5354 to make a payment'

export const callNow = async (): Promise<void> => {
  const callingSupported = await Linking.canOpenURL(phoneNumber)
  if (callingSupported) {
    await Linking.openURL(phoneNumber)
  } else {
    Platform.OS !== 'web' ? Alert.alert(errorPrompt) : alert(errorPrompt)
  }
}

export const callNowHandler = (): void => {
  void callNow()
}

export const emailNow = (): void => {
  void Linking.openURL('mailto:help@possiblefinance.com')
}

export const isOver30DaysDelinquent = (delinquentNumberOfDays: Maybe<number>): boolean =>
  delinquentNumberOfDays !== null &&
  delinquentNumberOfDays !== undefined &&
  delinquentNumberOfDays >= 30 &&
  delinquentNumberOfDays < 60
export const isOver60DaysDelinquent = (delinquentNumberOfDays: Maybe<number>): boolean =>
  delinquentNumberOfDays !== null &&
  delinquentNumberOfDays !== undefined &&
  delinquentNumberOfDays >= 60 &&
  delinquentNumberOfDays < 90
export const isOver90DaysDelinquent = (delinquentNumberOfDays: Maybe<number>): boolean =>
  delinquentNumberOfDays !== null &&
  delinquentNumberOfDays !== undefined &&
  delinquentNumberOfDays >= 90 &&
  delinquentNumberOfDays < 150
export const isOver150DaysDelinquent = (delinquentNumberOfDays: Maybe<number>): boolean =>
  delinquentNumberOfDays !== null &&
  delinquentNumberOfDays !== undefined &&
  delinquentNumberOfDays >= 150 &&
  delinquentNumberOfDays < 180
