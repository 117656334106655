import RNFS from 'src/lib/RNFS/RNFS'
import Log from 'src/lib/loggingUtil'
import WebAppConfig from 'src/WebAppConfig'
import {EnvironmentType} from '@possible/cassandra'
import {isDeviceWeb} from 'src/lib/utils/platform'
const storybookPref = `${RNFS.DocumentDirectoryPath}/storybook_pref`

export enum StorybookState {
  INIT,
  STORYBOOK,
  NO_STORYBOOK,
}

export async function enableStorybookPref() {
  await RNFS.writeFile(storybookPref, 'true', 'utf8')
}

export async function disableStorybookPref() {
  await RNFS.unlink(storybookPref)
}

export async function getStorybookPref(): Promise<boolean> {
  try {
    const statResult = await RNFS.stat(storybookPref)
    if (statResult && statResult.isFile()) {
      Log.log('Storybook Mode Enabled')
      return true
    }
  } catch (e: any) {
    Log.log('failed to read storybook pref: ', e.toString())
  }
  if (isDeviceWeb() && WebAppConfig.REACT_APP_PFENV === EnvironmentType.Dev) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    return !!params['storybook']
  }
  return false
}
