import React, {FC, useEffect} from 'react'
import {StyleSheet} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {SafeAreaView} from 'react-native-safe-area-context'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import CardSubmitApplicationSvg from 'src/assets/illustrations/CardSubmitApplication.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import AppNav from 'src/nav/AppNavActions'

const closeDelay = 10000

type Props = StackScreenProps<MainStackParamList, 'CardSubmitApplication'>

const CardSubmitApplication: FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation(['CardSubmitApplication'])

  // if we don't get any responses from BE or lost connection, we close this modal to avoid user to get stuck
  useEffect(() => {
    const timerClose = setTimeout(() => {
      AppNav.pop(navigation)
    }, closeDelay)

    return () => {
      clearTimeout(timerClose)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SafeAreaView style={styles.pageView}>
      <Box padding={'large'} gap={'large'} justify={'center'} boxStyle={{flex: 1}}>
        <Box marginBottom={'large'}>
          <PFText variant={'h1'} textAlign={'center'}>
            {t('SubmittingYourApplication')}
          </PFText>
        </Box>
        <Box padding={'large'}>
          <PFSvgContain width={'100%'} svg={CardSubmitApplicationSvg} />
        </Box>
      </Box>
    </SafeAreaView>
  )
}

export default CardSubmitApplication

const styles = StyleSheet.create({
  pageView: {
    backgroundColor: genericPageBackground,
    flex: 1,
  },
})
