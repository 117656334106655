import React, {FC, ReactNode} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import {littleGap, smallGap} from 'src/designSystem/layout'
import {
  PFStatusPillProps,
  PFStatusPill,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {Color, SizeVariants} from 'src/designSystem/types'
import {TilesToDisplayOnce} from 'src/products/card/LoanDash/utils'
import {useDisplayOneTimeMessage} from 'src/products/card/LoanDash/useDisplayOneTimeMessage'
import {ButtonProps} from 'src/designSystem/components/atoms/ButtonBase/ButtonBase'
import {
  PageViewedAnalyticsProvider,
  UsePageViewedAnalyticsProps,
} from 'src/lib/Analytics/usePageViewedAnalytics'

const cardHeight: number = 90 as const

export type BaseCardProps = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  loading: boolean
  tileMarginHorizontal?: SizeVariants | number
  tileRadius?: number
  testID?: string
}

export type CardTileBaseProps = Pick<
  BaseCardProps,
  'loading' | 'tileMarginHorizontal' | 'tileRadius' | 'testID'
> &
  Partial<Pick<UsePageViewedAnalyticsProps, 'eventName' | 'eventCategory'>> & {
    titleText: string
    buttonText?: string
    content: string | JSX.Element
    legalText?: string
    pillText?: string
    pillColor?: Color
    img?: ReactNode
    onPress?: ButtonProps['onPress']
    secondaryAction?: StackedButtonsAction[]
    backgroundColor?: BoxProps['background']
    titleColor?: Color
    bodyColor?: Color
    displayOnceKey?: TilesToDisplayOnce
  }

const CardTileBaseLoader = (): JSX.Element => (
  <View style={styles.loader}>
    <Loading type={'loader0'} size={'large'} />
  </View>
)

export const CardTileBase: FC<CardTileBaseProps> = (props) => {
  const {t} = useTranslation('CardDashboard')
  const {
    titleText,
    buttonText,
    content,
    legalText,
    pillText,
    pillColor = NamedColors.SAND,
    img,
    loading: isLoading,
    onPress,
    secondaryAction,
    backgroundColor,
    titleColor = DefaultVariantsColor.primary,
    bodyColor = DefaultVariantsColor.black,
    displayOnceKey,
    tileMarginHorizontal,
    tileRadius,
    testID,
    eventName,
    eventCategory,
  } = props
  const show = useDisplayOneTimeMessage(displayOnceKey)
  let pillContent: ReactNode = null
  let buttonContent: ReactNode = null
  let legalContent: ReactNode = null
  let analyticsContent: ReactNode = null

  let contentNode = content
  if (typeof content === 'string') {
    contentNode = (
      <PFText variant={'p'} textAlign={'left'} color={bodyColor}>
        {t(content)}
      </PFText>
    )
  }

  const action = {
    text: buttonText ? t(buttonText) : '',
    onPress,
    disabled: isLoading,
    testID: 'CardTileBasePrimaryButton',
  }

  const cardContent = (): JSX.Element => (
    <View style={styles.contentContainer}>
      <View style={styles.text}>
        <View style={{marginBottom: littleGap}}>
          <PFText textAlign={'left'} variant={'h3'} color={titleColor}>
            {t(titleText)}
          </PFText>
        </View>
        {contentNode}
      </View>
      {img ? <View style={styles.img}>{img}</View> : null}
    </View>
  )

  if (pillText) {
    const statusPill: PFStatusPillProps = {
      text: t(pillText),
      color: pillColor,
      fontColor: 'textPrimary',
    }
    pillContent = (
      <View style={styles.pill}>
        <PFStatusPill {...statusPill} />
      </View>
    )
  }

  if (onPress || secondaryAction) {
    buttonContent = (
      <View style={{marginTop: smallGap}}>
        <StackedButtons primary={action} secondary={secondaryAction} />
      </View>
    )
  }

  if (legalText) {
    legalContent = (
      <View style={styles.legalContent}>
        <PFText variant={'p_tiny'} textAlign={'center'} color={NamedColors.ASH}>
          {t(legalText)}
        </PFText>
      </View>
    )
  }

  if (eventName && eventCategory) {
    analyticsContent = (
      <PageViewedAnalyticsProvider eventName={eventName} eventCategory={eventCategory} />
    )
  }

  if (!show) return null
  // default horizontal margin to smallgap if not set
  const tileMarginHorizontalToUse = tileMarginHorizontal ?? smallGap
  // default tile radius to 0 if not set
  const tileRadiusToUse = tileRadius ?? 0

  return (
    <Box marginHorizontal={tileMarginHorizontalToUse} testID={testID}>
      {analyticsContent}
      <BasicTile
        padding={smallGap}
        radius={tileRadiusToUse}
        styles={backgroundColor ? {background: backgroundColor} : undefined}
      >
        {isLoading ? (
          <CardTileBaseLoader />
        ) : (
          <>
            {pillContent}
            {cardContent()}
            {buttonContent}
            {legalContent}
          </>
        )}
      </BasicTile>
    </Box>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  img: {
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 0,
  },
  legalContent: {
    marginTop: smallGap,
  },
  loader: {
    height: cardHeight,
  },
  pill: {
    borderRadius: 4,
    marginBottom: smallGap,
    marginTop: littleGap,
  },
  text: {
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 5,
  },
})
