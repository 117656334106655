import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'
import {EVENT_CARD_ACTIVATE, EVENT_PHONE_VERIFY} from 'src/lib/Analytics/marketingEvents'
import Log from 'src/lib/loggingUtil'
import PosAnaylitcs from 'src/lib/Analytics/index'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'
import {AnalyticDataObject} from 'src/lib/EventStream/EventStream.types'

export function SetAmplitudeUserProperties(property: string, value: string): void {
  PosAnaylitcs.SetAmplitudeUserProperties(property, value)
}

export function SetBrazeUserProperties(
  key: Parameters<typeof PosAnaylitcs.SetBrazeUserProperties>[0],
  value: Parameters<typeof PosAnaylitcs.SetBrazeUserProperties>[1],
): void {
  PosAnaylitcs.SetBrazeUserProperties(key, value)
}

export function SetUserId(userID: string): void {
  PosAnaylitcs.SetUserId(userID)
}

export function ClearId(): void {
  PosAnaylitcs.ClearId()
}

export function BankInfoAdded(): void {
  PosAnaylitcs.BankInfoAdded()
}

export function ApplicationSubmitted(loanCount: number): void {
  PosAnaylitcs.ApplicationSubmitted(loanCount)
}

export function LoanSelectionCompleted(): void {
  PosAnaylitcs.LoanSelectionCompleted()
}

export function LoanApproved(): void {
  PosAnaylitcs.LoanApproved()
}

export function LoanAccepted({userId}: {userId: string}): void {
  void PosAnaylitcs.LoanAccepted({userId})
}

export function LoanPaidOff(): void {
  PosAnaylitcs.LoanPaidOff()
}

export function CardApproved(): void {
  PosAnaylitcs.CardApproved()
}

export function CardActivated(): void {
  PosAnaylitcs.CardActivated()
}

export function VerifyPhoneEvent(): void {
  PosAnaylitcs.TrackSingularAndFirebaseEvent(EVENT_PHONE_VERIFY)
}

export function ActivateCardEvent(creditLimit: string): void {
  PosAnaylitcs.TrackSingularAndFirebaseEvent(EVENT_CARD_ACTIVATE, {creditLimit})
}

// eslint-disable-next-line max-params
export function TrackAppEvent(
  eventName: AppEventName,
  eventCategory: AppEventCategory,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  args?: any,
  logEventToSingular?: boolean,
): void {
  if (__DEV__) {
    Log.log('App Event : ', eventName, eventCategory, args)
  }

  PosAnaylitcs.TrackAppEvent(
    eventName,
    eventCategory,
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    args as AnalyticDataObject,
    logEventToSingular,
  )
  EventStreamSingleton.emit({
    type: 'analytic',
    name: eventName,
    category: eventCategory,
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    data: args as AnalyticDataObject,
    destinations: logEventToSingular ? ['amplitude', 'braze', 'singular'] : ['amplitude', 'braze'],
  })
}
