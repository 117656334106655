import React from 'react'
import {useTranslation} from 'react-i18next'

import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {getRemoteValueJson} from 'src/lib/RemoteConfig'
import {UNSUPPORTED_INSTITUTIONS_PLAID} from 'src/lib/RemoteConfig/parameterkeys'
import {AggregatorPlaidProps} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaid.types'

export type UnsupportedInstitutionsProps = {
  id: string
  name: string
  switchTo?: string
}

export const aggregatorSwitchFromPlaid: (institutionId: string) => string | undefined = (
  institutionId,
) => {
  const plaidUnsupportedInstitutions = getRemoteValueJson<UnsupportedInstitutionsProps[]>(
    UNSUPPORTED_INSTITUTIONS_PLAID,
  )

  const result = plaidUnsupportedInstitutions?.find((pair) => pair.id === institutionId)
  return result?.switchTo
}

export function showSwitchAggregatorPopup(
  bank: string,
  alternateId: string,
  accountId?: string,
  onSwitch?: AggregatorPlaidProps['onSwitch'],
  onSwitchDecline?: () => void,
) {
  const Notice = () => {
    const {t} = useTranslation('Bank')

    const primaryAction = {
      text: t('ContinueWithBank', {bank}),
      closeOnPress: true,
      onPress: () => {
        onSwitch?.(alternateId, accountId)
      },
    }

    //LightboxNotice puts the secondary_action in an array
    const secondaryAction = {
      text: t('UseDifferentBank'),
      closeOnPress: true,
      onPress: () => onSwitchDecline?.(),
    }

    const title = t('UnsupportedBank')
    const body_text = `${t('BankNotSupported', {bank})}\n\n${t('UseAlternateBankingPartner')}`

    return (
      <LightboxNotice
        title={title}
        body_text={body_text}
        primary_action={primaryAction}
        secondary_action={secondaryAction}
      />
    )
  }

  ShowLightbox(<Notice />, true)

  TrackAppEvent(
    AppEvents.Name.link_bank_account_unsupported_institution_viewed,
    AppEvents.Category.Application,
    {value: bank},
  )
}
