import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanPayMethodsQueryVariables = Types.Exact<{[key: string]: never}>

export type LoanPayMethodsQuery = {
  __typename?: 'Query'
  getUserEnv: {__typename?: 'UserEnv'; bankingAggregatorId?: string | null}
  me: {
    __typename: 'User'
    id: string
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            bankingPaymentInstrumentId: string
            account?: {
              __typename?: 'LinkedAccount'
              id: string
              preferredFundingSource?: boolean | null
            } | null
            bankAccount: {
              __typename?: 'BankAccount'
              friendlyName: string
              mask: string
              institution?: {__typename?: 'Institution'; name: string} | null
            }
          }
        | {__typename?: 'CheckPaymentMethod'}
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            bankingPaymentInstrumentId: string
            card: {
              __typename?: 'LinkedDebitCard'
              mask?: string | null
              friendlyName: string
              institution?: {__typename?: 'Institution'; name: string} | null
            }
          }
      > | null
    }
  }
}

export const LoanPayMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanPayMethods'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getUserEnv'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'bankingAggregatorId'}}],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'typeCodeFilter'},
                            value: {
                              kind: 'ListValue',
                              values: [
                                {kind: 'EnumValue', value: 'ACH'},
                                {kind: 'EnumValue', value: 'DEBIT_CARD'},
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'account'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'preferredFundingSource'},
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankAccount'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'card'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'friendlyName'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'institution'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanPayMethodsQuery, LoanPayMethodsQueryVariables>
