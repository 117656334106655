import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useCallback} from 'react'

import {UserPropertiesDocument} from 'src/api/lib/UserProperties/UserProperties.gqls'
import {
  UseUserPropertyResult,
  UserProperties,
} from 'src/api/lib/UserProperties/UserProperties.types'
import {
  getPropertyFromAllUserProperties,
  setUserProperty,
} from 'src/api/lib/UserProperties/UserProperties.utils'
import {userIsLoggedIn} from 'src/api/selectors/selectors'
import {ContextualizedLogException} from 'src/lib/errors'
import {usePfSelector} from 'src/store/utils'

/**
 * A hook through which to interact with a user property. This hook will
 * automatically fetch the user property and keep it up to date.
 * @param userPropertyName The property to interact with
 * @returns @type UseUserPropertyResult
 */
export const useUserProperty = <P extends UserProperties>(
  userPropertyName: P,
): UseUserPropertyResult<P> => {
  const isLoggedIn = usePfSelector(userIsLoggedIn)
  const {
    selectedData,
    loading: isLoading,
    refetch,
    error,
  } = useCassandraQuery(
    UserPropertiesDocument,
    {
      skip: !isLoggedIn,
    },
    (fromData) => fromData.me.properties?.all ?? [],
  )

  const value = getPropertyFromAllUserProperties(userPropertyName, selectedData ?? [])
  const handleSetValue: UseUserPropertyResult<P>['setValue'] = useCallback(
    async (value) => {
      await setUserProperty(userPropertyName, value).catch(
        ContextualizedLogException('Failed to set user misc property'),
      )
      await refetch().catch(
        ContextualizedLogException(
          'Failed to refetch user properties after setting user misc property',
        ),
      )
    },
    [userPropertyName, refetch],
  )
  return {value, isLoading, setValue: handleSetValue, refetch, error}
}
