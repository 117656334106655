import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserModifyProfileMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.ApplicantNameInput>
  dob?: Types.InputMaybe<Types.ApplicantDobInput>
  ssn?: Types.InputMaybe<Types.ApplicantSsnInput>
  id?: Types.InputMaybe<Types.ApplicantIdInput>
}>

export type UserModifyProfileMutation = {
  __typename?: 'Mutation'
  userModifyProfile?: {__typename: 'User'; id: string; updatedAt: string} | null
}

export const UserModifyProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserModifyProfile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ApplicantNameInput'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dob'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ApplicantDOBInput'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ssn'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ApplicantSSNInput'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ApplicantIDInput'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userModifyProfile'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dob'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dob'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ssn'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'ssn'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserModifyProfileMutation, UserModifyProfileMutationVariables>
