import {useEffect, useState} from 'react'

import {getUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {CardWaitListStatus} from 'src/lib/card/actions'
import Log from 'src/lib/loggingUtil'

export const useGetCardWaitlistStatus = (): CardWaitListStatus => {
  const [cardWaitListStatus, setCardWaitListStatus] = useState<CardWaitListStatus>(
    CardWaitListStatus.NOT_SET,
  )

  useEffect(() => {
    const run = async (): Promise<void> => {
      const {value, error} = await getUserProperty('card_wait_list')
      if (error) {
        Log.warn('getWaitListStatus failed with error : ', error)
        return
      }
      const isRegistered = value?.registered_for_wait_list
      setCardWaitListStatus(
        isRegistered ? CardWaitListStatus.REGISTERED : CardWaitListStatus.NOT_REGISTERED,
      )
    }
    void run()
  }, [])

  return cardWaitListStatus
}
