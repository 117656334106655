import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'
import {RegisterOptions} from 'react-hook-form'

import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'

import PFPasswordInput from 'src/designSystem/components/molecules/PFPasswordInput/PFPasswordInput'
import PFEmailInput from 'src/designSystem/components/molecules/PFEmailInput/PFEmailInput'
import HookForm, {
  FieldVariants,
  FormFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {EmailPasswordFormProps} from 'src/products/MCU/components/organisms/EmailPasswordForm/EmailPasswordForm.types'
import {
  getHasErrorsOrMissingValues,
  startsOrEndsWithWhitespace,
  validEmail,
} from 'src/lib/utils/formValidationUtil'
import {ForgotUsernamePassword} from 'src/products/MCU/RegistrationOrLogin/ForgotUsernamePassword'
import StateDropdown from 'src/designSystem/components/molecules/StateDropdown/StateDropdown'

const EmailPasswordForm: FC<EmailPasswordFormProps> = (props) => {
  const {
    action,
    navigation,
    control,
    errors,
    showForgotLinks,
    watch,
    isSubmittingForm,
    showRequirements,
    showAppRevampRegistrationExperiment = false,
  } = props

  const [didRequirementsMet, setDidRequirementsMet] = useState(false)
  const [didMinCharacterMet, setDidMinCharacterMet] = useState(false)

  const {t} = useTranslation(['EmailPasswordForm', 'Common'])

  const formFieldLabels = {
    stateSelected: t('Common:State'),
    email: t('Common:Email'),
    password: t('Common:Password'),
  }

  const fieldRequiredRule = (label: string): RegisterOptions['required'] =>
    t('Common:LabelIsRequired', {replace: {label}})

  const formProps: Partial<Record<keyof UserRegisterInput, FormFieldProps>> = {
    ...(showAppRevampRegistrationExperiment
      ? {
          stateSelected: {
            name: 'stateSelected',
            field: FieldVariants.Dropdown,
            rules: {
              required: fieldRequiredRule(formFieldLabels.stateSelected),
            },
          },
        }
      : {}),
    email: {
      name: 'email',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:EmailRequiredError'),
        validate: (value: string) =>
          validEmail(value) ? undefined : t('Common:InvalidEmailError'),
      },
    },
    password: {
      name: 'password',
      field: FieldVariants.TextField,
      rules: {
        required: fieldRequiredRule(formFieldLabels.password),
        validate: (value: string) =>
          startsOrEndsWithWhitespace(value) ? t('PasswordWhitespaceError') : undefined,
      },
    },
  }

  const getDisableSubmit = (): boolean => {
    if (getHasErrorsOrMissingValues(errors, watch) || isSubmittingForm) {
      return true
    } else {
      return showRequirements ? !didRequirementsMet : !didMinCharacterMet
    }
  }

  const getButtonTestId = (): string => {
    if (action.testID) {
      return action.testID
    }

    if (action.text === 'Login') {
      return 'LoginButton'
    } else {
      return 'CreateAccountButton'
    }
  }

  const getSubmitButton = (): React.JSX.Element => {
    const {onSubmit: handleSubmit} = action

    return (
      <View style={styles.button}>
        <Button
          mode="primary"
          disabled={getDisableSubmit()}
          loading={isSubmittingForm}
          size="large"
          width="100%"
          onPress={handleSubmit}
          testID={getButtonTestId()}
        >
          {action.text}
        </Button>
      </View>
    )
  }

  return (
    <HookForm<UserRegisterInput> control={control} errors={errors}>
      {showAppRevampRegistrationExperiment ? (
        <View key={formProps.stateSelected?.name} accessible={true}>
          <StateDropdown
            label={formFieldLabels.stateSelected}
            formProps={formProps.stateSelected}
            testID="State-Dropdown"
          />
        </View>
      ) : null}
      <View accessible={true}>
        <PFEmailInput label={formFieldLabels.email} formProps={formProps.email} defaultValue="" />
      </View>
      <View accessible={true}>
        <PFPasswordInput
          label={formFieldLabels.password}
          showRequirements={showRequirements}
          onAllRequirementsMet={(isMet: boolean): void => setDidRequirementsMet(isMet)}
          onMinCharactersMet={(isMet: boolean): void => setDidMinCharacterMet(isMet)}
          formProps={formProps.password}
          defaultValue=""
        />
      </View>
      {showForgotLinks ? (
        <ForgotUsernamePassword navigation={navigation} disabled={isSubmittingForm} />
      ) : undefined}
      {getSubmitButton()}
    </HookForm>
  )
}

export {EmailPasswordForm}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginTop: 28,
  },
})
