import {gql} from '@apollo/client'

export const BankAccountsFragment = gql`
  fragment BankAccountsFragment on User {
    bankAccounts {
      all(accountFilter: {onlyAchEligible: true}) {
        id
        name
        mask
        officialName
        status
        preferredFundingSource
        institution {
          name
        }
        achNumbersAvailable
        debitCardNumbersAvailable
        isLoginRequired
        type
      }
    }
  }
`
