import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'

type LoanCancelAmountModalPropsTypes = {
  visible: boolean
  onConfirm: () => void
  onDismiss: () => void
}

const LoanCancelAmountModal: FC<LoanCancelAmountModalPropsTypes> = (props) => {
  const {t} = useTranslation('LoanCancelAmount')

  const {visible, onConfirm, onDismiss} = props

  return (
    <Overlay
      visible={visible}
      title={t('DidYouKnow')}
      text={t('AcceptingAndPayingthisLoan')}
      okayButtonText={t('ContinueWithMyLoan')}
      dismissButtonText={t('IStillWantToCancel')}
      okayButtonSize={'medium'}
      dismissButtonSize={'medium'}
      onOkay={(): void => {
        onDismiss()
      }}
      onDismiss={(): void => {
        onConfirm()
      }}
      testID="LoanCancelAmountModal"
    />
  )
}

export default LoanCancelAmountModal
