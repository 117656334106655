import {gql} from '@apollo/client'

import {PaymentMethodTypeFragment} from '../fragments/paymentMethods'
import {nowStr, oneYearAgoStr} from '../../utils/formatting'

export const CardBalanceFragment = gql`
  fragment CardBalanceFragment on CardAccountBalance {
    availableBalance
    availableSpent
    ledgerBalance
    ledgerSpent
    ledgerSpentAfterPayments
    minimumPaymentDue
    updatedAt
  }
`
export const CardStatusFragment = gql`
  ${CardBalanceFragment}
  fragment CardStatusFragment on CardAccountStatuses {
    ... on PendingCardAccountStatus {
      code
      occurredAt
      subStatus
    }
    ... on RejectedCardAccountStatus {
      code
      occurredAt
      subStatus
      eligibleToReapplyAfter
      rejectionReasonCodes
    }
    ... on ApprovedCardAccountStatus {
      code
      occurredAt
      subStatus
    }
    ... on CancelledCardAccountStatus {
      code
      occurredAt
      subStatus
    }
    ... on ExpiredCardAccountStatus {
      code
      occurredAt
      subStatus
      eligibleToReapplyAfter
    }
    ... on ActiveCardAccountStatus {
      autopayFrequency
      code
      occurredAt
      subStatus
      cardAccountOverdueStatus
      daysUntilDelinquent
      delinquentNumberOfDays
      amountOverdue
      overduePaymentInfo {
        amount
        paymentDueAt
      }
      overdueInstallmentInfo {
        amount
        paymentDueAt
      }
      chargedOffStatus
      restriction {
        ... on CardAccountLockedRestriction {
          code
          occurredAt
          expiresAt
        }
        ... on CardAccountSuspendedRestriction {
          code
          reason
          occurredAt
        }
      }
      balance {
        ...CardBalanceFragment
      }
    }
    ... on DeactivatedCardAccountStatus {
      code
      occurredAt
      subStatus
      cardAccountOverdueStatus
      reasonCode
      delinquentNumberOfDays
      balance {
        ...CardBalanceFragment
      }
      chargedOffStatus
      overduePaymentInfo {
        amount
        paymentDueAt
      }
      overdueStatementInfo {
        amount
        paymentDueAt
      }
    }
  }
`

export const CardsFragment = gql`
  fragment CardsFragment on CardCollection {
    issued(states: [PENDING, ACTIVE, SUSPENDED]) {
      id
      externalId
      mask
      createdAt
      updatedAt
      type
      status {
        ... on PendingCardStatus {
          code
          occurredAt
        }
        ... on ActiveCardStatus {
          code
          occurredAt
          restriction {
            ... on CardSuspendedRestriction {
              code
              occurredAt
            }
          }
        }
        ... on SuspendedCardStatus {
          code
          occurredAt
          restriction {
            ... on CardSuspendedRestriction {
              code
              occurredAt
            }
          }
        }
        ... on DeactivatedCardStatus {
          code
          occurredAt
        }
      }
    }
  }
`

export const CardTransactionsFragment = gql`
fragment CardTransactionsFragment on CardAccountTransactionCollection {
	all(
		dateRange: { start: "${oneYearAgoStr}" }
		pageSelector: { cursor: null, count: 100 }
	) {
		items {
			transactionId
			authorizationCode
			transactionDatetime
			transactionPostedDatetime
			transactionExpiryDatetime
			amount
			description
			status
			merchant {
				id
				categoryCode
				name
				city
				state
			}
		}
	}
  
}`
export const CardTransactionsFragmentV2 = gql`
  fragment CardTransactionsFragmentV2 on CardAccountTransactionCollection {
    all(dateRange: {start: $startDate}, pageSelector: {cursor: null, count: 100}) {
      items {
        transactionId
        authorizationCode
        transactionDatetime
        transactionPostedDatetime
        transactionExpiryDatetime
        amount
        description
        status
        merchant {
          id
          categoryCode
          name
          city
          state
        }
      }
    }
    pending(dateRange: {start: $startDate}, pageSelector: {cursor: null, count: 100}) {
      items {
        transactionId
        authorizationCode
        transactionDatetime
        transactionPostedDatetime
        transactionExpiryDatetime
        amount
        description
        status
        merchant {
          id
          categoryCode
          name
          city
          state
        }
      }
    }
  }
`

const StatementFragment = gql`
  fragment StatementFragment on CardAccountStatement {
    id
    balance
    statementDate
    paymentDueDate
  }
`

export const CardStatementsFragment = gql`
${StatementFragment}
fragment CardStatementsFragment on CardAccountStatementCollection {
	active {
    ...StatementFragment
	}
	mostRecent {
    ...StatementFragment
	}
	historical(
		dateRange: {start: "${oneYearAgoStr}"}
		pageSelector: { cursor: null, count: 12 }
	) {
		statements {
      ...StatementFragment
		}
	}
}`
export const CardStatementsFragmentV2 = gql`
  ${StatementFragment}
  fragment CardStatementsFragmentV2 on CardAccountStatementCollection {
    active {
      ...StatementFragment
    }
    mostRecent {
      ...StatementFragment
    }
    historical(dateRange: {start: $startDate}, pageSelector: {cursor: null, count: 12}) {
      statements {
        ...StatementFragment
      }
    }
  }
`
export const CardAgreementsFragment = gql`
  fragment CardAgreementsFragment on CardAccountAgreementCollection {
    all {
      id
      createdAt
      updatedAt
      type
      status
    }
  }
`
const PaymentsFragment = gql`
  fragment PaymentsFragment on Payment {
    id
    cardAccountId
    amount
    status
    statusCode
    statusReason
    executeAt
    updatedAt
    createdAt
  }
`
export const CardPaymentsFragment = gql`
${PaymentsFragment}
fragment CardPaymentsFragment on CardPaymentCollection {
	all(
		dateRange: { start: "${oneYearAgoStr}", end: "${nowStr}" }
		pageSelector: { cursor: null, count: 100 }
	) {
		...PaymentsFragment
	}
	pending(pageSelector: { cursor: null, count: 100 }) {
		...PaymentsFragment
	}
	completed(
		dateRange: { start: "${oneYearAgoStr}", end: "${nowStr}" }
		pageSelector: { cursor: null, count: 100 }
	) {
		...PaymentsFragment
	}
	schedule(numberOfPayments: 100) {
		id
		paymentDate
	}
	potential {
		frequency
		payments {
			id
			paymentDate
		}
	}
}`
export const CardPaymentsFragmentV2 = gql`
  fragment CardPaymentsFragmentV2 on CardPaymentCollection {
    all(dateRange: {start: $startDate, end: $endDate}, pageSelector: {cursor: null, count: 100}) {
      id
      cardAccountId
      amount
      status
      statusCode
      statusReason
      executeAt
      updatedAt
      createdAt
    }
    pending(pageSelector: {cursor: null, count: 100}) {
      id
      cardAccountId
      amount
      status
      statusCode
      statusReason
      executeAt
      updatedAt
      createdAt
    }
    completed(
      dateRange: {start: $startDate, end: $endDate}
      pageSelector: {cursor: null, count: 100}
    ) {
      id
      cardAccountId
      amount
      status
      statusCode
      statusReason
      executeAt
      updatedAt
      createdAt
    }
    schedule(numberOfPayments: 100) {
      id
      paymentDate
    }
    potential {
      frequency
      payments {
        id
        paymentDate
      }
    }
  }
`

export const CardPaymentMethodsFragment = gql`
  ${PaymentMethodTypeFragment}

  fragment CardPaymentMethodsFragment on CardPaymentMethodCollection {
    default {
      ... on AchPaymentMethod {
        ...AchPaymentMethodFragment
      }
      ... on DebitCardPaymentMethod {
        ...DebitCardPaymentMethodFragment
      }
    }
    all {
      ... on AchPaymentMethod {
        ...AchPaymentMethodFragment
      }
      ... on DebitCardPaymentMethod {
        ...DebitCardPaymentMethodFragment
      }
    }
  }
`

export const CardInstallmentPlansFragment = gql`
  fragment CardInstallmentPlansFragment on CardInstallmentPlanCollection {
    current {
      id
      frequency
      status {
        ... on ActiveInstallmentPlanStatus {
          code
          installmentsRemaining
        }
        ... on InactiveInstallmentPlanStatus {
          code
        }
      }
      installments {
        id
        executeAt
        amount
        ordinal
        statusCode
        fees
        totalDue
        pendingAmount
        paidAmount
        paidOff
        overdue
        amountWithoutFees
        dueAt
        remainingDue
      }
      totalPaid
      pendingAmount
      remainingAmount
      totalDue
      totalOverdueAmount
      numberOfOverdueInstallments
    }
    potential {
      id
      frequency
      status {
        ... on ActiveInstallmentPlanStatus {
          code
          installmentsRemaining
        }
        ... on InactiveInstallmentPlanStatus {
          code
        }
      }
      installments {
        id
        executeAt
        amount
        ordinal
        statusCode
        fees
        totalDue
        pendingAmount
        paidAmount
        paidOff
        overdue
        amountWithoutFees
        dueAt
        remainingDue
      }
      totalPaid
      pendingAmount
      remainingAmount
      totalDue
      totalOverdueAmount
      numberOfOverdueInstallments
    }
    eligibleForInstallments
  }
`

export const CardAccountTypeFragment = gql`
  fragment CardAccountTypeFragment on CardAccountType {
    id
    cardNetwork
    monthlyMembershipFees
    maxBalanceLimit
  }
`

export const CardAccountFeesFragment = gql`
  fragment CardAccountFeesFragment on CardAccountFees {
    potential {
      date
      amount
    }
  }
`

export const CardAccountOffersFragment = gql`
  fragment CardAccountOffersFragment on CardAccountOffer {
    id
    balanceLimit
    network
    monthlyMembershipFees
  }
`

export const CardAccountOfferInfoFragment = gql`
  fragment CardAccountOfferInfoFragment on CardAccountOfferInfo {
    offerAvailabilityStatus
    offers {
      id
    }
  }
`
export const CardAccountMiscDataFragment = gql`
  fragment CardAccountMiscDataFragment on CardAccountMiscData {
    cohortPhase
    cohortUserType
  }
`
