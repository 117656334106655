import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export type ReschedulePaymentErrorOverlayProps = {
  showReschedulePaymentErrorOverlay: boolean
  hideReschedulePaymentErrorOverlay: () => void
}

const ReschedulePaymentErrorOverlay: FC<ReschedulePaymentErrorOverlayProps> = (props) => {
  const {showReschedulePaymentErrorOverlay, hideReschedulePaymentErrorOverlay} = props
  const {t} = useTranslation(['Reschedule', 'Common'])
  const navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList> =
    useNavigation()
  return (
    <OverlaySimple
      testID={'Reschedule-Payment-Error-Overlay'}
      visible={showReschedulePaymentErrorOverlay}
      okayButtonText={t('Common:Continue')}
      title={t('OopsWeAreUnableToSave')}
      content={
        <Box gap={'small'} marginBottom={'little'}>
          <PFText variant={'p'}>{t('HmmWeAreHavingTroubleSaving')}</PFText>
          <PFText variant={'p'}>
            <Trans i18nKey="ForAssistance" t={t}>
              Please{' '}
              <SvgLink
                testID={'Contact-Us-Link'}
                onPress={() => {
                  hideReschedulePaymentErrorOverlay()
                  openContactUsForm(navigation)
                }}
                linkType={'inline'}
                linkText={t('Common:ContactUsLowercase')}
              />{' '}
              for further assistance!
            </Trans>
          </PFText>
        </Box>
      }
    />
  )
}

export {ReschedulePaymentErrorOverlay}
