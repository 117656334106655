import {createAction} from '@reduxjs/toolkit'

// register components that support chaos mode
export const registerComponentForChaos = createAction<{componentName: string}>(
  'registerComponentForChaos',
)
export const degregisterComponentForChaos = createAction<{componentName: string}>(
  'deregisterComponentForChaos',
)
// toggle chaos render failures for registered components
export const enableChaosFailureForComponentRender = createAction<{componentName: string}>(
  'enableChaosFailureForComponentRender',
)
export const disableChaosFailureForComponentRender = createAction<{componentName: string}>(
  'disableChaosFailureForComponentRender',
)
