import {useCards} from 'src/products/card/hooks/useCards'

/**
 * The Ad-Hoc payment button should be disabled if
 * balance is 0 or pending payments > balance
 */
export const useIsBalancePaidOff = (): boolean => {
  const {ledgerSpentAfterPayments} = useCards()

  return ledgerSpentAfterPayments <= 0
}
