import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react'
import {
  Animated,
  Dimensions,
  Easing,
  Modal,
  PanResponder,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'

import {bottomSheetDragIndicatorColor, modalBackgroundColor} from 'src/designSystem/semanticColors'
import {bottomSheetOpenCloseDuration, bottomSheetOpenCloseEasing} from 'src/designSystem/animations'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button, {ButtonProps} from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {TextVariants} from 'src/designSystem/typography'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {contentMaxWidth} from 'src/designSystem/layout'

const dismissThreshold = 50
const dragIndicatorSize = 4

enum TransitionState {
  Visible,
  Closed,
}

export type BottomSheetProps = {
  visible: boolean
  title?: string
  titleAlign?: 'left' | 'center'
  titleVariant?: TextVariants
  graphic?: ReactNode
  showDots?: boolean
  noHorizontalPadding?: boolean
  onDismiss: () => void | Promise<void>
  showCloseButton?: boolean
  fullWidthTitle?: boolean
  testID?: string
  dismissButton?: {text: string} & Partial<ButtonProps>
  children?: ReactNode
}

const renderDot = (): JSX.Element => {
  return (
    <Box
      background={bottomSheetDragIndicatorColor}
      radius="round"
      width={dragIndicatorSize}
      height={dragIndicatorSize}
    />
  )
}

const BottomSheet = (props: BottomSheetProps): JSX.Element => {
  const {
    visible: isVisible,
    title,
    titleAlign = 'center',
    titleVariant = 'label_md',
    graphic,
    showDots = true,
    onDismiss: handleOnDismiss,
    children,
    noHorizontalPadding: shouldHaveNoHorizontalPadding,
    showCloseButton,
    fullWidthTitle: shouldHaveFullWidthTitle,
    testID,
    dismissButton,
  } = props
  const pan = useRef(
    new Animated.Value(isDeviceWeb() ? 0 : Dimensions.get('screen').height),
  ).current
  const [transitionState, setTransitionState] = useState<TransitionState>(TransitionState.Closed)

  const top = pan.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, 0, 1],
  })

  const openAnimation = bottomSheetOpenCloseEasing
    ? Animated.timing(pan, {
        toValue: 0,
        duration: bottomSheetOpenCloseDuration,
        easing: bottomSheetOpenCloseEasing,
        useNativeDriver: false,
      })
    : undefined

  const closeAnimation = Easing?.cubic
    ? Animated.timing(pan, {
        toValue: Dimensions.get('screen').height,
        duration: bottomSheetOpenCloseDuration,
        easing: Easing.cubic,
        useNativeDriver: false,
      })
    : undefined

  const animateOpen = useCallback(() => {
    setTransitionState(TransitionState.Visible)
    openAnimation?.start()
  }, [openAnimation])

  const animateClosed = useCallback(() => {
    closeAnimation?.start(() => {
      setTransitionState(TransitionState.Closed)
    })
  }, [closeAnimation])

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderMove: (e, gs) => {
        pan.setValue(gs.dy)
      },
      onPanResponderRelease: (e, gs) => {
        if (gs.dy > dismissThreshold) {
          handleOnDismiss()
        } else {
          animateOpen()
        }
      },
    }),
  ).current

  useEffect(() => {
    if (isVisible) {
      animateOpen()
    } else if (!isVisible && transitionState === TransitionState.Visible) {
      animateClosed()
    }
  }, [isVisible, animateOpen, animateClosed, transitionState])

  return (
    <Modal
      animationType="fade"
      visible={transitionState === TransitionState.Visible}
      transparent
      onRequestClose={handleOnDismiss}
      testID={testID}
    >
      <Box
        background={modalBackgroundColor}
        fill
        justify="end"
        radius={{topLeft: 'medium', topRight: 'medium'}}
      >
        <Animated.View style={{top, ...getStyles()}} {...panResponder.panHandlers}>
          <Box background="white" radius={{topLeft: 'medium', topRight: 'medium'}}>
            <Box
              boxStyle={isDeviceWeb() ? styles.contentContainerWeb : {}}
              paddingTop="medium"
              paddingBottom="enormous"
              paddingHorizontal={shouldHaveNoHorizontalPadding ? 0 : 'medium'}
              radius={{topLeft: 'medium', topRight: 'medium'}}
              gap="small"
              align="center"
              elevation={6}
              testID="BottomSheet-Content"
            >
              {graphic ?? null}
              {showDots ? (
                <Box direction="row" gap="tiny">
                  {renderDot()}
                  {renderDot()}
                  {renderDot()}
                </Box>
              ) : null}
              <Box direction="row" justify="between" align="start" width={'100%'}>
                {title ? (
                  <PFText
                    variant={titleVariant}
                    textAlign={titleAlign}
                    textProps={shouldHaveFullWidthTitle ? {style: {width: '100%'}} : {}}
                  >
                    {title}
                  </PFText>
                ) : null}
                {showCloseButton ? (
                  <TouchableOpacity onPress={handleOnDismiss} testID={`${testID}-close-button`}>
                    <SvgIcon name="close" colorVariant="inactive" />
                  </TouchableOpacity>
                ) : null}
              </Box>
              {children}

              {dismissButton ? (
                <Button
                  mode="primary"
                  size="large"
                  width="100%"
                  onPress={handleOnDismiss}
                  {...dismissButton}
                >
                  {dismissButton.text}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Animated.View>
      </Box>
    </Modal>
  )
}

const getStyles = (): ViewStyle => {
  return isDeviceWeb()
    ? {
        width: '100%',
        alignSelf: 'flex-end',
      }
    : {}
}

export {BottomSheet}

const styles = StyleSheet.create({
  contentContainerWeb: {
    maxWidth: contentMaxWidth,
    alignSelf: 'center',
  },
})
