import {TFunction} from 'i18next'
import moment, {Moment} from 'moment'
import {MarkedDates} from 'react-native-calendars/src/types'
import {DateData as RnCalendarsDateData} from 'react-native-calendars'

import {isHoliday, isWeekEnd} from 'src/lib/time_util'
import {LoanPaymentStatusCode} from 'src/cassandra'
import {RescheduleLoanPaymentItemType} from 'src/products/loans/Reschedule/Reschedule.types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'

export enum FeasibleDateCode {
  Allowed, // code number: 0
  AchCutoff, // code number: 1
  Holiday, // code number: 2
  Weekend, // code number: 3
  // 15 to 29 days past original date
  FifteenToTwentyNineDaysPastDue, // code number: 4
  // 30+ days past original date
  ThirtyPlusDaysPastDue, // code number: 5
  // 30+ days past original date, but allow continue
  ThirtyPlusDaysPastDueAllowContinue, // code number: 6
  // After credit impact date, but allow to continue
  AfterCreditImpactDate, // code number: 7
  IsToday, // code number: 8
}

export const calendarDateFormat = 'YYYY-MM-DD'

const daysBeforeReportedToCreditBureaus = 29
const fifteenDayGracePeriod = 15
const daysAllowedForOnTimePayment = 14
const maximumDaysToScheduleLateOrFailedPayment = 28

export const isPaymentLate = (selectedDate: Moment, originalDate: Moment): boolean => {
  const durationFromOrigDate = moment.duration(selectedDate.diff(originalDate))
  // WARN: Math.floor can cause issues when calculating days
  const daysFromOriginalDate = Math.floor(durationFromOrigDate.asDays())
  return daysFromOriginalDate > daysBeforeReportedToCreditBureaus
}

const isPaymentFailed = (payment: RescheduleLoanPaymentItemType): boolean => {
  return payment && payment.statusCode === LoanPaymentStatusCode.Failed
}

export const warnAndAllowContinue = (code: FeasibleDateCode): boolean => {
  return (
    code === FeasibleDateCode.FifteenToTwentyNineDaysPastDue ||
    code === FeasibleDateCode.ThirtyPlusDaysPastDueAllowContinue ||
    code === FeasibleDateCode.AfterCreditImpactDate ||
    code === FeasibleDateCode.IsToday
  )
}

export type GetFeasibleDateProps = {
  creditImpactDate?: Moment
  earliestAvailableDate: Moment
  originalDate: Moment
  payment: RescheduleLoanPaymentItemType
  selectedDate: Moment
  todayDate: Moment
}

export const getFeasibleDateCode = (props: GetFeasibleDateProps): FeasibleDateCode => {
  const {creditImpactDate, earliestAvailableDate, originalDate, payment, selectedDate, todayDate} =
    props

  const selectedDateTime = selectedDate.set({hour: 17, minute: 0, second: 0, millisecond: 0})

  const durationFromOrigDate = moment.duration(selectedDateTime.diff(originalDate))
  const durationFromNow = moment.duration(selectedDateTime.diff(todayDate))

  // WARN: Math.floor can cause issues when calculating days
  const daysFromOriginalDate = Math.floor(durationFromOrigDate.asDays())
  // WARN: Math.floor can cause issues when calculating days
  const daysFromNow = Math.floor(durationFromNow.asDays())

  if (isWeekEnd(selectedDateTime)) {
    return FeasibleDateCode.Weekend
  }

  if (isHoliday(selectedDateTime)) {
    return FeasibleDateCode.Holiday
  }

  if (selectedDate.isSameOrAfter(creditImpactDate, 'day')) {
    return FeasibleDateCode.AfterCreditImpactDate
  }

  if (selectedDate.isBefore(earliestAvailableDate, 'day')) {
    return FeasibleDateCode.AchCutoff
  }

  if (
    daysFromOriginalDate >= fifteenDayGracePeriod &&
    daysFromOriginalDate <= daysBeforeReportedToCreditBureaus
  ) {
    return FeasibleDateCode.FifteenToTwentyNineDaysPastDue
  }

  if (daysFromOriginalDate > daysBeforeReportedToCreditBureaus) {
    const maximumAllowedDays = isPaymentLate(todayDate, originalDate)
      ? maximumDaysToScheduleLateOrFailedPayment
      : daysAllowedForOnTimePayment

    if (
      (isPaymentFailed(payment) || isPaymentLate(todayDate, originalDate)) &&
      daysFromNow <= maximumAllowedDays
    ) {
      return FeasibleDateCode.ThirtyPlusDaysPastDueAllowContinue
    } else {
      return FeasibleDateCode.ThirtyPlusDaysPastDue
    }
  }

  if (selectedDate.isSame(todayDate, 'day')) {
    return FeasibleDateCode.IsToday
  }

  return FeasibleDateCode.Allowed
}

export type GetRescheduleOverlayContentProps = {
  reasonCode: FeasibleDateCode
  todayDate: Moment
  earliestAvailableDate: Moment
  t: TFunction
}

export type ReschedulePaymentAlertOverlayContentProps = {
  title: string
  text: string
  primaryButtonText?: string
  secondaryButtonText?: string
}

export const getRescheduleOverlayContent = (
  props: GetRescheduleOverlayContentProps,
): ReschedulePaymentAlertOverlayContentProps => {
  const {reasonCode, todayDate, earliestAvailableDate, t} = props
  const todayIs = todayDate.format('M/D')
  const earliestAvailDate = earliestAvailableDate.format('dddd, M/D')

  let title: string
  let text: string
  let primaryButtonText: string
  let secondaryButtonText: string | undefined = undefined

  switch (reasonCode) {
    case FeasibleDateCode.AchCutoff:
      title = t('DateUnavailable')
      text = t('OopsThisDateIsUnavailable', {
        todayIs,
        earliestAvailDate,
      })
      primaryButtonText = t('PickANewDate')
      break

    case FeasibleDateCode.Weekend:
      title = t('DateUnavailable')
      text = t('YouWillNeedToPickADifferentDayForYourPaymentBanksAreClosedOnTheWeekend')
      primaryButtonText = t('PickANewDate')
      break

    case FeasibleDateCode.Holiday:
      title = t('ThatIsABankHoliday')
      text = t('YouWillNeedToPickADifferentDayForYourPayment')
      primaryButtonText = t('PickANewDate')
      break

    case FeasibleDateCode.FifteenToTwentyNineDaysPastDue:
      title = t('HmmThatDateIsFarAway')
      text = t('ThisWillNotAffectYourCredit')
      primaryButtonText = t('Common:Continue')
      secondaryButtonText = t('Common:NeverMind')
      break

    case FeasibleDateCode.ThirtyPlusDaysPastDue:
      title = t('DateTooFarAway')
      text = t('UhOhThisDateIsOutsideOfThe29DayGraceWindow')
      primaryButtonText = t('Common:GoBack')
      break

    case FeasibleDateCode.ThirtyPlusDaysPastDueAllowContinue:
    case FeasibleDateCode.AfterCreditImpactDate:
      title = t('CreditRiskWarning')
      text = t('ThanksForReschedulingYourPaymentIsMoreThan29Days')
      primaryButtonText = t('Common:Continue')
      secondaryButtonText = t('PickANewDate')
      break

    case FeasibleDateCode.IsToday:
      title = t('PayNow?')
      text = t('YouHaveSelectedToMakeASameDayPayment')
      primaryButtonText = t('Common:Continue')
      secondaryButtonText = t('Common:NeverMind')
      break

    default:
      title = t('Whoops')
      text = t('ThisDateIsNotFeasible')
      primaryButtonText = t('PickANewDate')
      break
  }

  return {
    title,
    text,
    primaryButtonText,
    secondaryButtonText,
  }
}

export const isSelectedDayFeasible = (props: GetFeasibleDateProps): boolean => {
  const feasibility = getFeasibleDateCode(props)
  return (
    feasibility === FeasibleDateCode.Allowed ||
    feasibility === FeasibleDateCode.FifteenToTwentyNineDaysPastDue ||
    feasibility === FeasibleDateCode.ThirtyPlusDaysPastDueAllowContinue ||
    feasibility === FeasibleDateCode.IsToday
  )
}

export type GetMarkedDatesProps = {
  creditImpactDate: Moment
  endDay: Moment
  originalDate: Moment
  payment: RescheduleLoanPaymentItemType
  startDay: Moment
  todayDate: Moment
}

// Determine the dates that need to be marked as disabled/selected on the calendar
export const getNotFeasibleMarkedDates = (props: GetMarkedDatesProps): MarkedDates => {
  const {creditImpactDate, endDay, originalDate, payment, startDay, todayDate: today} = props
  const updatedMarkedDates: MarkedDates = {}
  let iterDay = startDay
  let isIterDayFeasibleProps: GetFeasibleDateProps

  // get dates that are not feasible and mark them as disabled
  while (iterDay.isSameOrBefore(endDay, 'day')) {
    isIterDayFeasibleProps = {
      earliestAvailableDate: startDay,
      originalDate: originalDate,
      payment: payment,
      selectedDate: iterDay,
      todayDate: today,
      creditImpactDate,
    }

    if (!isSelectedDayFeasible(isIterDayFeasibleProps)) {
      updatedMarkedDates[iterDay.format(calendarDateFormat)] = {
        disabled: true,
        disableTouchEvent: true,
      }
    }
    iterDay = iterDay.add(1, 'days')
  }
  return updatedMarkedDates
}

export const getAdjustedDateTimeFromDay = (
  day: RnCalendarsDateData,
  timeZoneId: string,
  today: Moment,
): Moment => {
  const selectedDateMoment = moment(day.dateString, calendarDateFormat).tz(timeZoneId)

  return today.isSame(selectedDateMoment, 'day')
    ? selectedDateMoment.set({
        hour: today.get('hour'),
        minute: today.get('minute'),
        second: today.get('second'),
      })
    : selectedDateMoment.set({
        hour: 10,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
}

type TrackRescheduleOverlayShownProps = {
  dayFeasibilityDateCode: FeasibleDateCode
  nextAvailSettlementDate: Moment
  todayDate: Moment
  t: TFunction
}

export const trackRescheduleOverlayShown = (props: TrackRescheduleOverlayShownProps): void => {
  const {dayFeasibilityDateCode, nextAvailSettlementDate, todayDate, t} = props

  const titleForEvent = getRescheduleOverlayContent({
    reasonCode: dayFeasibilityDateCode,
    earliestAvailableDate: nextAvailSettlementDate,
    todayDate: todayDate,
    t: t,
  }).title
  TrackAppEvent(AppEvents.Name.reschedule_alert_viewed, AppEvents.Category.ManageActiveLoan, {
    title: titleForEvent,
  })
}
