import React from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonAction} from 'src/designSystem/types'
import {
  CardPaymentScheduleTemplate,
  CardPaymentScheduleTemplateProps,
} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate'

type CardManualPayScheduleTemplateProps = Pick<
  CardPaymentScheduleTemplateProps,
  'paymentFrequency' | 'paymentSchedule'
> & {
  isLoading?: boolean // This is not currently used but will be utilized or removed in ENG-16748 or ENG-16749
  onPress: ButtonAction
}

const CardManualPayScheduleTemplate = ({
  onPress,
  paymentFrequency,
  paymentSchedule,
}: CardManualPayScheduleTemplateProps): JSX.Element => {
  const {t} = useTranslation(['CardManualPaymentSchedule', 'Common'])

  return (
    <CardPaymentScheduleTemplate
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: onPress,
        },
      }}
      paymentFrequency={paymentFrequency}
      paymentSchedule={paymentSchedule}
      testID={'CardManualPayScheduleTemplate'}
      title={t('CardManualPaymentSchedule:Title')}
    />
  )
}

export {CardManualPayScheduleTemplate}
