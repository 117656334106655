import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'

import ButtonBase, {
  BUTTON_VARIANTS,
  ButtonProps,
} from 'src/designSystem/components/atoms/ButtonBase/ButtonBase'
import DualLineText from 'src/designSystem/components/molecules/DualLineText/DualLineText'
import {smallGap} from 'src/designSystem/layout'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type TertiaryButtonProps = ButtonProps & {
  center?: boolean
  leftIcon?: SvgIconProps
  rightIcon?: SvgIconProps
  subText?: string
  info?: boolean
  error?: boolean
  onSelectInfo?: () => void
}

const handlePress = ({disabled, onPress, info, onSelectInfo}): void => {
  if (!disabled && onPress) {
    onPress()
  }
  if (info && onSelectInfo) {
    onSelectInfo()
  }
}

const TertiaryButton: FC<TertiaryButtonProps> = ({
  center,
  children,
  disabled,
  leftIcon,
  rightIcon,
  onPress,
  subText,
  info,
  onSelectInfo,
  error,
}) => {
  if (disabled || !onPress) {
    rightIcon = undefined
  }

  const textAlign = center ? 'center' : undefined

  if (info) {
    rightIcon = {name: 'info', colorVariant: 'info'}
  }

  if (error) {
    rightIcon = {name: 'warning', colorVariant: 'error'}
  }

  return (
    <ButtonBase
      disabled={disabled}
      secondaryAction={true}
      onPress={(): void => handlePress({disabled, onPress, info, onSelectInfo})}
      variant={BUTTON_VARIANTS.TERTIARY}
    >
      <View style={styles.containingView}>
        {leftIcon ? (
          <View style={styles.leftIconView}>
            <SvgIcon name={leftIcon.name} colorVariant={'info'} />
          </View>
        ) : null}
        <View style={styles.textView}>
          <DualLineText
            text={children}
            subText={subText}
            textAlign={textAlign}
            disabled={disabled}
          />
        </View>
        {rightIcon ? (
          <View style={styles.rightIconView}>
            <SvgIcon name={rightIcon.name} colorVariant={rightIcon.colorVariant ?? 'info'} />
          </View>
        ) : undefined}
      </View>
    </ButtonBase>
  )
}

export default TertiaryButton

const styles = StyleSheet.create({
  containingView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: smallGap,
    marginVertical: smallGap,
  },
  leftIconView: {
    marginRight: smallGap,
  },
  rightIconView: {
    marginLeft: smallGap,
  },
  textView: {
    flex: 1,
  },
})
