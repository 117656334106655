import {Easing} from 'react-native'

export const animationDuration = 250
//export const animationEasing = Easing.inOut(Easing.ease)
export const animationEasing = () => {
  return 0
}

export const bottomSheetOpenCloseEasing = Easing.cubic
export const bottomSheetOpenCloseDuration = 400
