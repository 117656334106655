import React, {ReactElement, FC} from 'react'
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {TextVariants} from 'src/designSystem/typography'

type CommonProps = {
  boldItems?: boolean
  itemStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  icon?: ReactElement
}
type ItemsProps = CommonProps & {
  text: string
}

type Props = CommonProps & {
  style?: StyleProp<ViewStyle>
  items: string[]
}

const ValuePropItem: FC<ItemsProps> = (props) => {
  const {text, icon, boldItems: bold} = props
  const itemStyle = props.itemStyle ? props.itemStyle : styles.listView
  const textStyle = props.textStyle ? props.textStyle : styles.listViewText
  let textVariant: TextVariants = 'p'

  if (bold) {
    textVariant = 'p_lg_semibold'
  }

  return (
    <View style={itemStyle}>
      {icon}
      <View style={textStyle}>
        <PFText variant={textVariant}>{text}</PFText>
      </View>
    </View>
  )
}

const ValuePropList: React.FC<Props> = ({
  items,
  style,
  itemStyle,
  textStyle,
  icon,
  boldItems = false,
}) => {
  const valuePropItems = items.map((item, index) => (
    <ValuePropItem
      text={item}
      itemStyle={itemStyle}
      key={index}
      icon={icon}
      boldItems={boldItems}
      textStyle={textStyle}
    />
  ))
  return <View style={style}>{valuePropItems}</View>
}

const styles = StyleSheet.create({
  listView: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    paddingBottom: 10,
  },
  listViewText: {
    alignItems: 'center',
    height: 26,
    margin: 0,
    paddingLeft: 4,
    paddingTop: 2,
  },
})

export default ValuePropList
