import React, {useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppNav from 'src/nav/AppNavActions'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import AutoPayIcon from 'src/assets/illustrations/Autopay.svg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {useGetPaymentFrequency} from 'src/products/card/Autopay/UseGetPaymentFrequency/useGetPaymentFrequency'
import {useCards} from 'src/products/card/hooks/useCards'
import {NamedColors} from 'src/designSystem/colors'
import {CardActivationAutopayScheduleDocument} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleQuery.gqls'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

type Props = StackScreenProps<MainStackParamList, 'CardSignedUpAutopay'>

export const CardSignedUpAutopay: React.FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation(['CardSignedUpAutopay', 'Common'])
  const frequency = useGetPaymentFrequency().frequencyText
  const [isBusy, setIsBusy] = useState(false)
  const {refetchCardsQuery} = useCards()

  const {selectedData: schedule} = useCassandraQuery(
    CardActivationAutopayScheduleDocument,
    {},
    (data) => {
      return data?.me.cardAccounts.active?.payments?.potential?.payments
    },
  )
  const startingOn = formatDate(schedule?.[0]?.paymentDate, friendlyDateWithDOW, utcDate)

  const onPress = (): void => {
    setIsBusy(true)
    refetchCardsQuery()
    AppNav.PopToName(navigation, 'CardDashboard')
  }

  return (
    <Page
      variant="generic"
      title={t('YouHaveSignedUp')}
      testID="You-Have-SignedUp-Id"
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Ok'),
          onPress,
          disabled: isBusy,
          loading: isBusy,
        },
      }}
    >
      <Box align="center">
        <Box marginTop="little" marginBottom="large">
          <PFSvg SvgUri={AutoPayIcon} svgProps={{width: 191, height: 157}} />
        </Box>
        <Box direction="row" alignSelf="center" marginBottom="little">
          <PFText variant="p">{t('YourAutopayWillBePaid')}</PFText>
          <PFText variant="p">{frequency}</PFText>
          <PFText variant="p">{t('StartingOn')}</PFText>
        </Box>
        <PFText variant="p_lg_semibold">{startingOn}</PFText>
        <Box padding={'small'}>
          <PFText variant="p" textAlign="center" color={NamedColors.SILVER}>
            {t('NotTakeEffect')}
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}
