import {StyleSheet} from 'react-native'

import {isDeviceIOS} from 'src/lib/utils/platform'

export const ImageCaptureWidth = 1024 //1536
export const StandardIDRatio = 3.37 / 2.125
export const InverseStandardIDRatio = 2.125 / 3.37

// Android specific change
// The computation on android seems off and the correct camera frame view is not visible
// The short term fix was to set this to a ratio that was more reasonable such that both android and ios show
// desirable camera view wrappers
export const AndroidAdjustedInverseStandardIDRatio = 0.75

export const AspectRatioStyle = isDeviceIOS()
  ? InverseStandardIDRatio
  : AndroidAdjustedInverseStandardIDRatio

export const CameraAndFrameStyle = StyleSheet.create({
  landscape: {
    alignItems: 'center',
    aspectRatio: StandardIDRatio,
    justifyContent: 'center',
    overflow: 'hidden',
    resizeMode: 'contain',
  },
  portrait: {
    alignItems: 'center',
    aspectRatio: AspectRatioStyle,
    justifyContent: 'center',
    minHeight: 300,
    overflow: 'hidden',
    resizeMode: 'contain',
  },
  square: {
    alignItems: 'center',
    aspectRatio: 1,
    justifyContent: 'center',
    minHeight: 300,
    overflow: 'hidden',
  },
})
