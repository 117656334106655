import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {CardAgreementType, CardStaticAgreementType} from 'src/cassandra'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {CardAcceptAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptAgreement'
import {CardAcceptStaticAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptStaticAgreement'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'
import Card from 'src/assets/icons/CardBlue.svg'
import Coin from 'src/assets/icons/CoinBlue.svg'
import Download from 'src/assets/icons/Download.svg'
import Graph from 'src/assets/icons/GraphBlue.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {
  ProgressTracker,
  ProgressTrackerProps,
} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTracker'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'

type CardPaymentAgreementTemplateProps = {
  feeAmount: string
  isAutopay: boolean
  isBusy?: boolean
  onPressPrimary: ButtonAction
  onPressStep: Parameters<typeof SvgLink>[0]['onPress']
  isUserMinPayProgram: boolean
}

const CardPaymentAgreementTemplate = (props: CardPaymentAgreementTemplateProps): JSX.Element => {
  const {
    feeAmount,
    isAutopay,
    isBusy,
    onPressPrimary,
    onPressStep: handleOnPressStep,
    isUserMinPayProgram,
  } = props

  usePageViewedAnalytics({
    eventName: CardEvents.card_finish_virtual_activation_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })
  const {t} = useTranslation(['CardPaymentAgreement', 'Common'])
  const [hasAcceptedDebitAuthorization, setHasAcceptedDebitAuthorization] = useState(false)
  const [hasAcceptedCardholderAgreement, setHasAcceptedCardholderAgreement] = useState(false)

  const getIsDisabled = (): boolean => {
    let areAllChecked = hasAcceptedCardholderAgreement
    if (isAutopay) {
      areAllChecked = hasAcceptedCardholderAgreement && hasAcceptedDebitAuthorization
    }

    return isBusy || !areAllChecked
  }

  const progressTrackerSteps: ProgressTrackerProps['steps'] = [
    {
      title: t('ManualPayBenefit1'),
      subtitle: (
        <Trans i18nKey="ManualPayBenefit1Subtitle" t={t}>
          Get access to credit sooner when you
          <SvgLink
            onPress={handleOnPressStep}
            linkType={'inline'}
            textVariant={'p_sm'}
            linkText="enroll in AutoPay"
          />
          .
        </Trans>
      ),
      label: <PFSvgContain svg={Card} width={20} />,
      lineStyle: 'dashed',
      isComplete: true,
    },
    {
      title: t('ManualPayBenefit2'),
      subtitle: t('ManualPayBenefit2Subtitle'),
      label: <PFSvgContain svg={Download} width={20} />,
      lineStyle: 'dashed',
      isComplete: true,
    },
    {
      title: t('AutopayBenefit3', {
        monthlyFee: convertToDollarAmt(feeAmount, false),
      }),
      subtitle: t('AutopayBenefit3Subtitle'),
      label: <PFSvgContain svg={Coin} width={20} />,
      lineStyle: 'dashed',
      isComplete: true,
    },
    {
      title: t('AutopayBenefit4'),
      label: <PFSvgContain svg={Graph} width={20} />,
      isComplete: true,
    },
  ]

  const minPayAutoPayProgressTrackerSteps: ProgressTrackerProps['steps'] = [
    {
      title: t('MinPayAltProgress1Title', {
        monthlyFee: convertToDollarAmt(feeAmount, false),
      }),
      subtitle: t('MinPayAltProgress1Subtitle'),
      label: <PFSvgContain svg={Coin} width={20} />,
      isComplete: false,
    },
    {
      title: t('MinPayAltProgress2Title'),
      subtitle: t('MinPayAltProgress2Subtitle'),
      label: <PFSvgContain svg={Card} width={20} />,
      isComplete: false,
    },
  ]
  const minPayManualPayProgressTrackerSteps: ProgressTrackerProps['steps'] = [
    {
      title: t('MinPayManualProgress1Title', {
        monthlyFee: convertToDollarAmt(feeAmount, false),
      }),
      subtitle: t('MinPayManualProgress1Subtitle'),
      label: <PFSvgContain svg={Coin} width={20} />,
      isComplete: false,
    },
    {
      title: t('MinPayManualProgress2Title'),
      subtitle: t('MinPayManualProgress2Subtitle'),
      label: <PFSvgContain svg={Card} width={20} />,
      isComplete: false,
    },
  ]

  if (isAutopay) {
    progressTrackerSteps.splice(
      0,
      2,
      {
        title: t('AutopayBenefit1'),
        label: <PFSvgContain svg={Card} width={20} />,
        lineStyle: 'dashed',
        isComplete: true,
      },
      {
        title: t('AutopayBenefit2'),
        subtitle: t('AutopayBenefit2Subtitle'),
        label: <PFSvgContain svg={Download} width={17} />,
        lineStyle: 'dashed',
        isComplete: true,
      },
    )
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('PrimaryCta'),
          onPress: onPressPrimary,
          disabled: getIsDisabled(),
          loading: isBusy,
        },
      }}
      variant={'generic'}
      title={t('Title')}
      testID={'CardPaymentAgreementTemplate'}
      smallTopGap
    >
      <Box paddingTop={'medium'} paddingBottom={'large'}>
        <ProgressTracker
          steps={
            isUserMinPayProgram
              ? isAutopay
                ? minPayAutoPayProgressTrackerSteps
                : minPayManualPayProgressTrackerSteps
              : progressTrackerSteps
          }
        />
      </Box>

      <Box flex={1} gap={'small'} justify={'end'} paddingVertical={'large'}>
        {isUserMinPayProgram ? (
          <PFInfoCapsule
            svgIcon={{name: 'info', colorVariant: 'success'}}
            text={t('InfoBox', {monthlyFee: convertToDollarAmt(feeAmount, false)})}
          />
        ) : null}
        {isAutopay ? (
          <CardAcceptAgreement
            checked={hasAcceptedDebitAuthorization}
            setChecked={setHasAcceptedDebitAuthorization}
            cardAgreementType={CardAgreementType.DebitAuthorizationAgreement}
            agreementName={t('DebitAuthorizationAgreement')}
          />
        ) : null}

        <CardAcceptStaticAgreement
          checked={hasAcceptedCardholderAgreement}
          setChecked={setHasAcceptedCardholderAgreement}
          cardStaticAgreementType={CardStaticAgreementType.CardAgreement}
          agreementName={t('CardholderAgreement')}
        />
      </Box>
    </Page>
  )
}

export {CardPaymentAgreementTemplate}
export type {CardPaymentAgreementTemplateProps}
