import {ReactNode} from 'react'
import {Animated} from 'react-native'

import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type textButtonProps = {
  text: string
  onPress: () => void
}

export type PFFieldBaseStateTypes = 'default' | 'empty' | 'active' | 'error'
export enum PFFieldBaseStates {
  DEFAULT = 'default',
  EMPTY = 'empty',
  ACTIVE = 'active',
  ERROR = 'error',
}

export type PFFieldBaseProps = {
  label: string
  fieldState: PFFieldBaseStateTypes
  children?: ReactNode
  animationControl: Animated.Value
  error?: string
  infoMessage?: string
  editable?: boolean
  onPressIcon?: () => void
  textButton?: textButtonProps
  icon?: SvgIconProps
  placeholder?: string
  testID?: string
}
