import React from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {VerifyYourIdentityGQLContainer} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList, MainStackScreenProps} from 'src/nav/MainStackParamsList'
import {LoanSubmissionDocument} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'

type Props = MainStackScreenProps<'VerifyYourIdentity'>

const EditYourIdentityRouteContainer: React.FC<Props> = () => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'VerifyYourIdentity'>>()

  const handleOnComplete = (): void => {
    navigation.pop()
  }

  return (
    <VerifyYourIdentityGQLContainer
      isForReviewAndEdit={true}
      onComplete={handleOnComplete}
      refetchQueriesOnSubmit={[LoanSubmissionDocument]}
    />
  )
}

export {EditYourIdentityRouteContainer}
