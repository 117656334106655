import {isDeviceWeb} from 'src/lib/utils/platform'
import {PushPage, OpenWebPage} from 'src/navigation/NavHelper'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'

export const displayPdf = (uri: string, navigation: MainStackNavigationProp): void => {
  if (isDeviceWeb()) {
    PushPage(navigation, 'PDFViewer', {uri: uri})
  } else {
    OpenWebPage({uri})
  }
}
