import React from 'react'

import {useGetLinkedAccounts} from 'src/lib/bank/useGetLinkedAccounts'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  logAddPaymentMethodError,
  logAddPaymentMethodErrorAndShowException,
} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {ReapplicationBankTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankTemplate'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'

type Props = {
  onContinue: (accountId: string) => void | Promise<void>
  bankLinkAnalyticEvent: () => void
}

export const ReapplicationBankGQLContainer: React.FC<Props> = (props) => {
  const {onContinue, bankLinkAnalyticEvent} = props

  const {linkedAccountsData, isLoadingLinkedAccounts, linkedAccountsError, linkedAccountsRefetch} =
    useGetLinkedAccounts()

  const {addOrRelinkAccountForPrimary} = useAccountManagementBankLinking()

  const preferredAccount = linkedAccountsData?.preferredAccount
  const latestAccount = linkedAccountsData?.latestAccount

  const handleContinueCurrentAccount = async (): Promise<void> => {
    bankLinkAnalyticEvent()
    try {
      if (preferredAccount) {
        await onContinue(preferredAccount.id)
        return
      } else if (latestAccount) {
        await onContinue(latestAccount.id)
        return
      } else {
        throw new Error('No preferred or latest account found')
      }
    } catch (e) {
      logAddPaymentMethodError(e, 'Error while continuing with current account')
    }
  }

  const handleContinueRelink = async (): Promise<void> => {
    bankLinkAnalyticEvent()
    try {
      const result = await linkedAccountsRefetch()
      if (result.errors) {
        logAddPaymentMethodError(new Error('refetch error'))
        return
      }

      if (result.selectedData?.preferredAccount) {
        await onContinue(result.selectedData?.preferredAccount.id)
      } else if (result.selectedData?.latestAccount) {
        await onContinue(result.selectedData?.latestAccount.id)
      } else {
        throw new Error('refetched but no preferred account found')
      }
    } catch (e) {
      logAddPaymentMethodError(
        e,
        'ReapplicationBankGQLContainer handleRelinkContinue() refetch error',
      )
    }
  }

  const handleOnRelinkAccount = (): void => {
    if (!preferredAccount?.id) {
      logAddPaymentMethodErrorAndShowException(
        new Error(
          'ReapplicationBankGQLContainer handleOnRelinkAccount() no preferred account found',
        ),
      )
      return
    }

    addOrRelinkAccountForPrimary({
      accountToRelink: {
        id: preferredAccount?.id,
      },
      onChoosePrimaryAccountComplete: handleContinueRelink,
    })
    bankLinkAnalyticEvent()
  }

  const handleShouldLinkNewAccount = (): void => {
    addOrRelinkAccountForPrimary({
      onChoosePrimaryAccountComplete: handleContinueRelink,
    })
    bankLinkAnalyticEvent()
  }

  return (
    <BaseTemplate
      isLoading={isLoadingLinkedAccounts && !linkedAccountsData}
      isError={!!linkedAccountsError}
    >
      <ReapplicationBankTemplate
        {...props}
        isPreferredAccount={preferredAccount}
        isLatestAccount={latestAccount}
        onContinue={handleContinueCurrentAccount}
        onLinkNewAccount={handleShouldLinkNewAccount}
        onRelinkAccount={handleOnRelinkAccount}
      />
    </BaseTemplate>
  )
}
