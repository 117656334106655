import React, {ReactElement} from 'react'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import DateBubbleList from 'src/designSystem/components/molecules/DateBubbleList/DateBubbleList'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {DateBubblePaymentDate} from 'src/designSystem/components/atoms/DateBubble/DateBubble'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type UpcomingPaymentsTileProps = {
  title: string
  subtitle: ReactElement
  hasSubtitleIcon?: boolean
  paymentNote?: ReactElement
  paymentDates: DateBubblePaymentDate[]
  primaryAction?: StackedButtonsAction
  secondaryAction?: StackedButtonsAction
  testID?: string
}

/**
 * A tile with displaying a list of upcoming payments. Includes a DateBubbleList for payments and optional action buttons.
 * @example <UpcomingPaymentsTile title="Payments" subtitle="Upcoming payments" paymentDates=[] />
 */
const UpcomingPaymentsTile: React.FC<UpcomingPaymentsTileProps> = ({
  title,
  subtitle,
  hasSubtitleIcon = true,
  paymentNote,
  paymentDates,
  primaryAction,
  secondaryAction,
  testID,
}: UpcomingPaymentsTileProps) => {
  const desiredDateBubbleListMargin = 28
  const hasButtons = primaryAction ?? secondaryAction
  return (
    <BasicTile padding="medium" testID={testID}>
      <PFText variant="h3" textAlign="center">
        {title}
      </PFText>

      {/* next payment row */}
      <Box marginTop="tiny" direction="row" justify="center" align="center">
        {hasSubtitleIcon ? (
          <Box marginRight="little">
            <SvgIcon name={'calendar'} colorVariant={'inactive'} />
          </Box>
        ) : null}
        {subtitle}
      </Box>

      {/* date bubble list */}
      <Box marginTop={desiredDateBubbleListMargin}>
        <DateBubbleList dates={paymentDates} />
      </Box>

      {paymentNote ? (
        <Box marginTop="small" marginBottom={hasButtons ? 'large' : 0}>
          {paymentNote}
        </Box>
      ) : (
        <Box marginBottom={desiredDateBubbleListMargin}></Box>
      )}

      {/* buttons */}
      {hasButtons ? (
        <Box>
          <StackedButtons
            primary={primaryAction}
            secondary={secondaryAction ? [secondaryAction] : undefined}
          />
        </Box>
      ) : null}
    </BasicTile>
  )
}

export default UpcomingPaymentsTile
