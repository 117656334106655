import React from 'react'
import {View, StyleSheet} from 'react-native'

import {gapSizeMap, littleGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SizeVariants} from 'src/designSystem/types'

type Props = {
  items: {value: string; key: string}[]
  gap?: SizeVariants
}

export const PFUnorderedList: React.FC<Props> = (props) => {
  const {items, gap} = props

  const listItemMargin = gap ? (gapSizeMap[gap] ?? 0) : 0

  return (
    <View style={[listStyles.list]}>
      {items.map((item) => (
        <View key={item.key} style={[listStyles.listItem, {marginTop: listItemMargin}]}>
          <PFText variant="p" textProps={{style: listStyles.bullet}}>
            {'\u2022'}
          </PFText>
          <PFText variant="p">{item.value}</PFText>
        </View>
      ))}
    </View>
  )
}

const listStyles = StyleSheet.create({
  list: {
    marginLeft: littleGap,
  },
  listItem: {
    flexDirection: 'row',
  },
  bullet: {
    marginRight: littleGap,
  },
})
