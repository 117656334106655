import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {waitTime} from 'src/products/loans/PhoneConfirmation/ResendWaitingTime'
import {PhoneNumberVerificationMethod} from '@possible/generated/APIClient'
import {
  VerificationMethod,
  VerificationStackNavigationPropType,
} from 'src/products/loans/PhoneConfirmation/PhoneConfirmation.types'

type Props = {
  method: PhoneNumberVerificationMethod | VerificationMethod
  navigation: VerificationStackNavigationPropType
  dismissCallback?: () => void
}

const CodeSentConfirmation: FC<Props> = ({navigation, method, dismissCallback}) => {
  const {t} = useTranslation(['PhoneConfirmation', 'Common'])

  const methodToText = {
    SMS: t('Common:text'),
    VOICE: t('PhoneCall'),
    EMAIL: t('Common:email'),
  }
  const onOk = () => {
    navigation?.pop()
  }
  const primary = {
    text: t('Common:Dismiss'),
    onPress: () => {
      dismissCallback?.()
      onOk()
    },
  }
  const textParam = methodToText?.[method] ?? ''
  const content = t('PhoneConfirmationContentMessage', {textParam, waitTime})
  const lightboxBody = <PFText variant={'p'}>{content}</PFText>
  return <LightboxNotice title={t('CodeSent')} primary_action={primary} body={lightboxBody} />
}

export default CodeSentConfirmation
