import {Consumer} from '@possible/cassandra'
import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {useCallback, useState} from 'react'

import {LoanApplicationSubmitted} from 'src/api/actions/loans/loanActions'
import {isAdvanceState} from 'src/lib/advance/CashAdvanceUtils'
import {ApplicationSubmitted} from 'src/lib/Analytics/analytics_compat'
import {ShowException} from 'src/lib/errors'
import {
  UseLoanSubmissionLoanTypeDocument,
  UseLoanSubmissionAmountStateLoanCountDocument,
} from 'src/lib/loans/useLoanSubmission/UseLoanSubmission.gqls'
import Log from 'src/lib/loggingUtil'
import {usePfDispatch} from 'src/store/utils'

export type TrySubmitReturnType = Consumer.types.LoanApplyMutation['loanApply'] | undefined
export type TrySubmitType = (amount?: string) => Promise<TrySubmitReturnType>

export type LoanSubmissionReturnType = [TrySubmitType, {loading: boolean}]

export const useLoanSubmission = (): LoanSubmissionReturnType => {
  const [loanApply] = Consumer.hooks.useLoanApplyMutation()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = usePfDispatch()

  const [checkAmountStateAndLoanCount] = useCassandraLazyQuery(
    UseLoanSubmissionAmountStateLoanCountDocument,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const trySubmit: TrySubmitType = useCallback(
    // eslint-disable-next-line complexity
    async (amount?: string) => {
      setIsSubmitting(true)
      try {
        Log.log('Submitting Application with GraphQL')

        // get user's state and loan count
        const amountStateAndLoanCountQueryRes = await checkAmountStateAndLoanCount()

        const stateAbv = amountStateAndLoanCountQueryRes.data?.me.profile?.home?.address?.state
        if (!stateAbv) {
          throw new Error('Could not determine the state. Try closing and re-opening the app.')
        }

        const amountFromQuery =
          amountStateAndLoanCountQueryRes.data?.me.onboarding?.loan?.amountSelected

        //Advance states do not need a loan amount, it defaults to max allowed
        if (!isAdvanceState(stateAbv, false)) {
          if (!amount || amount !== amountFromQuery) {
            Log.error(
              'LoanSubmission trySubmit() amount is empty or does not match expected amount from query',
              JSON.stringify({
                amountFromQuery,
                amount: amount ?? 'undefined',
              }),
            )
          }
          if (!amountFromQuery) {
            throw new Error('Could not determine the amount. Try closing and re-opening the app.')
          }

          if (amountStateAndLoanCountQueryRes.errors) {
            Log.error(
              amountStateAndLoanCountQueryRes.errors[0],
              'LoanSubmission onSubmit: failed to load state and loan counts',
            )
          }
        }

        // if they don't have a latest ClosedLoanAggregateStatus this is their first loan so count = 0
        const loanCount =
          amountStateAndLoanCountQueryRes.data?.me.loans.latestActionableLoan?.aggregateStatus
            .__typename === 'ClosedLoanAggregateStatus'
            ? amountStateAndLoanCountQueryRes.data.me.loans.latestActionableLoan.aggregateStatus
                .loanCount
            : 0

        // get this state's loan type
        const loanTypeRes = await ApplyQuery(UseLoanSubmissionLoanTypeDocument, {
          stateAbv: stateAbv,
        })
        if (loanTypeRes.errors) {
          Log.error(
            loanTypeRes.errors[0],
            'LoanSubmission, onSubmit: failed to load loan type query',
          )
        }
        const typeId = loanTypeRes.data?.getPrimaryLoanTypeByState?.id

        if (!typeId) {
          throw new Error('Could not determine the loan type. Try closing and re-opening the app.')
        }

        const applyInput: Consumer.types.LoanApplyInput = {
          typeId,
          amount: amountFromQuery ?? '0', //Passing in zero for advance will default to the max allowed. This field should be changes to optional.
          currency: 'USD',
        }

        const res = await loanApply({
          variables: {
            applyInput,
          },
        })

        if (res.errors) {
          throw res.errors[0]
        }
        await dispatch(LoanApplicationSubmitted())

        ApplicationSubmitted(loanCount)

        return res.data?.loanApply
      } catch (e) {
        Log.error(e, 'LoanSubmission, onSubmit:')
        ShowException(e)

        return undefined
      } finally {
        setIsSubmitting(false)
      }
    },
    [dispatch, loanApply, checkAmountStateAndLoanCount],
  )

  return [trySubmit, {loading: isSubmitting}]
}
