import AsyncStorage from '@react-native-async-storage/async-storage'
import Log from 'src/lib/loggingUtil'

export async function isExistingKey(key) {
  try {
    const value = await AsyncStorage.getItem(key)
    return !!value
  } catch (e) {
    Log.log(`Error getting ${key} from async storage: ${e}`)
  }
  return false
}

export async function setKey(key) {
  try {
    if (!(await isExistingKey(key))) {
      await AsyncStorage.setItem(key, 'true')
    }
  } catch (e) {
    Log.log(`Error setting ${key} to async storage: ${e}`)
  }
}

export async function removeKey(key) {
  try {
    await AsyncStorage.removeItem(key)
  } catch (e) {
    Log.log(`Error removing ${key} from async storage: ${e}`)
  }
}

export async function getKeyValue(key) {
  try {
    return await AsyncStorage.getItem(key)
  } catch (e) {
    Log.log(`Error getting ${key} from async storage: ${e}`)
  }
  return false
}

export async function setKeyValue(key, value) {
  try {
    await AsyncStorage.setItem(key, value)
  } catch (e) {
    Log.log(`Error setting value ${key} to async storage: ${e}`)
  }
}
