import React, {ReactNode, useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import AppNavActions from 'src/nav/AppNavActions'
import {
  CardDashboardPaymentFooter,
  CardDashboardPaymentFooterPropsFromUseCards,
} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentFooter'
import {
  PaymentNotification,
  PaymentNotificationLevel,
} from 'src/products/card/components/molecules/PaymentNotification/PaymentNotification'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {formatDate, humanReadableDateShortOrdinal} from 'src/lib/utils/date'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {CardAccountPOTStatus} from 'src/products/card/types'

export type CardPOTOverduePropsFromUseCards = Pick<
  useCardsType,
  | 'accountPOTStatus'
  | 'amountOverdue'
  | 'overdueInstallmentInfo'
  | 'paymentDueDate'
  | 'accountStatus'
>
export type CardPOTOverdueProps = CardDashboardPaymentFooterPropsFromUseCards &
  CardPOTOverduePropsFromUseCards & {
    navigation: StackNavigationProp<MainStackParamList>
  }

export const CardPOTOverdue = (props: CardPOTOverdueProps): JSX.Element => {
  const {navigation, accountPOTStatus, amountOverdue, overdueInstallmentInfo, paymentDueDate} =
    props
  const {t} = useTranslation('CardPOTOverdue')

  const [title, variant, content] = useMemo((): [string, PaymentNotificationLevel, ReactNode] => {
    if (accountPOTStatus === CardAccountPOTStatus.OverdueOneMissedPayment) {
      return [
        t('SingleMissedPayment'),
        PaymentNotificationLevel.Warn,
        t('Overdue', {
          amountOverdue: convertToDollarAmt(amountOverdue),
          overdueDate: formatDate(paymentDueDate, humanReadableDateShortOrdinal),
        }),
      ]
    } else if (accountPOTStatus === CardAccountPOTStatus.OverdueMultipleMissedPayments) {
      return [
        t('MultipleMissedPayment', {numberOfMissedPayments: overdueInstallmentInfo.length}),
        PaymentNotificationLevel.Error,
        overdueInstallmentInfo.map((installment) => (
          <Box key={`${installment?.paymentDueAt}-{${installment?.amount}}`}>
            <PFText variant="p">
              {t('Overdue', {
                amountOverdue: convertToDollarAmt(installment?.amount),
                overdueDate: formatDate(installment?.paymentDueAt, humanReadableDateShortOrdinal),
              })}
            </PFText>
          </Box>
        )),
      ]
    } else {
      return ['SingleMissedPayment', PaymentNotificationLevel.Warn, '']
    }
  }, [amountOverdue, overdueInstallmentInfo, paymentDueDate, accountPOTStatus, t])

  return (
    <Box marginHorizontal="medium">
      <BasicTile>
        <Box paddingHorizontal="small" paddingVertical="medium">
          <PaymentNotification variant={variant} title={title} content={content} />
          <Box marginTop="small" direction="row" justify="center" align="center">
            <PFText variant="p">
              <Trans
                t={t}
                i18nKey="TotalOverdue"
                components={{bold: <PFText variant="p_semibold" />}}
                values={{
                  totalOverdue: convertToDollarAmt(amountOverdue),
                }}
              />
            </PFText>
          </Box>
          <Box marginTop="small">
            <PFText variant="p_semibold" textAlign="center">
              {t('MakeAPayment')}
            </PFText>
          </Box>
          <Box align="center" marginTop="tiny">
            <SvgLink
              linkType={'single'}
              linkIcon={'internal'}
              onPress={(): void => AppNavActions.push(navigation, 'PayOverTimeFAQ')}
              linkText={t('TapForDetails')}
              linkIconSize={'little'}
            />
          </Box>

          <CardDashboardPaymentFooter {...props} />
        </Box>
      </BasicTile>
    </Box>
  )
}
