import {
  EventStreamAnalyticEvent,
  EventStreamEvent,
  EventStreamLogEvent,
  EventStreamMetadataEvent,
} from 'src/lib/EventStream/EventStream.types'

export const isMetadataEvent = (event: EventStreamEvent): event is EventStreamMetadataEvent => {
  return event.type === 'metadata'
}

export const isAnalyticEvent = (event: EventStreamEvent): event is EventStreamAnalyticEvent => {
  return event.type === 'analytic'
}

export const isLogEvent = (event: EventStreamEvent): event is EventStreamLogEvent => {
  return event.type === 'log'
}
