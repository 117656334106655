import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserCanUpdateAddressQueryVariables = Types.Exact<{[key: string]: never}>

export type UserCanUpdateAddressQuery = {
  __typename?: 'Query'
  me: {__typename?: 'User'; id: string; canUpdateAddress?: boolean | null}
}

export const UserCanUpdateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UserCanUpdateAddress'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'canUpdateAddress'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCanUpdateAddressQuery, UserCanUpdateAddressQueryVariables>
