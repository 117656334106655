import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AccountListQueryVariables = Types.Exact<{[key: string]: never}>

export type AccountListQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename: 'LinkedAccount'
        id: string
        mask: string
        name?: string | null
        officialName?: string | null
        status: Types.LinkedAccountStatusCode
        preferredFundingSource?: boolean | null
        achNumbersAvailable?: boolean | null
        isDeletable: boolean
        debitCardNumbersAvailable?: boolean | null
        isLoginRequired: boolean
        type: Types.LinkedAccountType
        lastSyncedAt?: string | null
        institution?: {__typename?: 'Institution'; name: string} | null
      }> | null
    }
  }
}

export const AccountListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AccountList'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'institution'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isDeletable'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'debitCardNumbersAvailable'},
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastSyncedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountListQuery, AccountListQueryVariables>
