import {useContext, useEffect} from 'react'

import {initUserAuth} from 'src/api/actions/client'
import {initializeCassandra} from 'src/cassandra/initializeCassandra'
import {getPfStore} from 'src/store'
import {NavContext} from 'src/nav/NavContext'
import {Destinations, EmitLoadingEvent} from 'src/lib/utils/events'
import {userExchangeTemporaryToken} from '@possible/cassandra/src/user/authPublicMethods'
import {
  LoginResult,
  UserExchangeTempTokenInput,
} from '@possible/cassandra/src/types/types.public.generated'
import Log from 'src/lib/loggingUtil'
import {getDeviceDetails} from 'src/lib/getDeviceDetails'

const authWithTempToken = async (
  destination: Destinations | undefined,
  userId: string,
  tempToken: string,
): Promise<boolean> => {
  try {
    const deviceDetails = await getDeviceDetails()
    const input: UserExchangeTempTokenInput = {
      userId,
      tempToken,
      deviceDetails,
    }
    const response: LoginResult | undefined = await userExchangeTemporaryToken(input)

    if (!response) {
      throw Error('Failed to exchange temporary token')
    }

    if (destination) {
      EmitLoadingEvent(destination)
    }

    if (response.token) {
      const {dispatch} = getPfStore()
      await initializeCassandra(dispatch, response.token)
      await initUserAuth({
        userId,
        accessToken: response.token,
        dispatch,
        isLoginFromRegistration: false,
      })
      return true
    }
  } catch (e) {
    Log.error(e, 'Error authWithTempToken')
  }
  return false
}

export const useTempTokenAuthentication = () => {
  const [state, updateState] = useContext(NavContext)
  const {tempAuthToken, tempAuthUserId, handledTempAuthToken, destination} = state

  useEffect(() => {
    if (handledTempAuthToken || !tempAuthToken || !tempAuthUserId) {
      return
    }

    if (destination && destination !== 'none') {
      authWithTempToken(destination, tempAuthUserId, tempAuthToken).finally(() => {
        updateState(() => ({handledTempAuthToken: true}))
      })
    }
  }, [destination, handledTempAuthToken, tempAuthToken, tempAuthUserId, updateState])
}
