import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {CardAccountOfferAvailabilityStatus} from 'src/cassandra'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {CardOfferAvailabilityStatusQueryDocument} from 'src/products/loans/StateSelector/UseGetWaitlistVariant/CardOfferAvailabilityStatusQuery.gqls'

export enum WaitListVariant {
  Loan,
  CardEnabledSupportedState,
  CardEnabledUnsupportedState,
  CardEnabledQuotaReached,
}

export const getWaitListVariant = (
  isCardsUser: boolean,
  offerAvailabilityStatus?: CardAccountOfferAvailabilityStatus,
): WaitListVariant => {
  if (isCardsUser) {
    if (offerAvailabilityStatus === CardAccountOfferAvailabilityStatus.QuotaReached) {
      return WaitListVariant.CardEnabledQuotaReached
    } else if (offerAvailabilityStatus === CardAccountOfferAvailabilityStatus.IneligibleLocation) {
      return WaitListVariant.CardEnabledUnsupportedState
    }
    return WaitListVariant.CardEnabledSupportedState
  }

  return WaitListVariant.Loan
}

export const useGetWaitlistVariant = (): WaitListVariant => {
  const {canAccessCards: isCardsUser} = useCanAccessCards()
  const {selectedData: offerAvailabilityStatus} = useCassandraQuery(
    CardOfferAvailabilityStatusQueryDocument,
    {},
    (data) => data?.me?.cardAccounts?.offerInfo?.offerAvailabilityStatus,
  )

  return getWaitListVariant(isCardsUser, offerAvailabilityStatus)
}
