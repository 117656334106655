import React, {FC} from 'react'

import {enableStorybookPref} from 'src/lib/storybookPref'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {reloadUI} from 'src/lib/utils/reload'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

const StorybookToggle: FC = () => {
  const storybookFeatureFlagEnabled = useIsFeatureFlagEnabled('storybook')

  async function enableStorybook(): Promise<void> {
    await enableStorybookPref()
    reloadUI()
  }

  const displayStorybook = __DEV__ || storybookFeatureFlagEnabled

  return displayStorybook ? (
    <PFText variant="p">
      <Button mode="primary" size="medium" onPress={enableStorybook}>
        Storybook
      </Button>
    </PFText>
  ) : null
}

export default StorybookToggle
