/*************************************************
 * The first chunk of this file is a merging of types
 * from the react-plaid-link package and the
 * react native package. It defers to the react
 * native types by default as they seem the most
 * exhaustive.
 *************************************************/

export enum LinkAccountType {
  CREDIT = 'credit',
  DEPOSITORY = 'depository',
  INVESTMENT = 'investment',
  LOAN = 'loan',
  OTHER = 'other',
}

export interface LinkAccountSubtype {}

export enum LinkAccountVerificationStatus {
  PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
  PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
  MANUALLY_VERIFIED = 'manually_verified',
}

export interface LinkAccount {
  id: string
  name?: string
  mask?: string
  type: LinkAccountType
  subtype: LinkAccountSubtype
  verificationStatus?: LinkAccountVerificationStatus
}

export interface LinkInstitution {
  id: string
  name: string
}

export enum LinkExitMetadataStatus {
  CONNECTED = 'connected',
  CHOOSE_DEVICE = 'choose_device',
  REQUIRES_ACCOUNT_SELECTION = 'requires_account_selection',
  REQUIRES_CODE = 'requires_code',
  REQUIRES_CREDENTIALS = 'requires_credentials',
  REQUIRES_EXTERNAL_ACTION = 'requires_external_action',
  REQUIRES_OAUTH = 'requires_oauth',
  REQUIRES_QUESTIONS = 'requires_questions',
  REQUIRES_RECAPTCHA = 'requires_recaptcha',
  REQUIRES_SELECTIONS = 'requires_selections',
  REQUIRES_DEPOSIT_SWITCH_ALLOCATION_CONFIGURATION = 'requires_deposit_switch_allocation_configuration',
  REQUIRES_DEPOSIT_SWITCH_ALLOCATION_SELECTION = 'requires_deposit_switch_allocation_selection',
}

export interface LinkExitMetadata {
  status?: LinkExitMetadataStatus
  institution?: LinkInstitution
  linkSessionId: string
  requestId: string
  metadataJson?: string
}

export enum LinkErrorCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_MFA = 'INVALID_MFA',
  ITEM_LOGIN_REQUIRED = 'ITEM_LOGIN_REQUIRED',
  INSUFFICIENT_CREDENTIALS = 'INSUFFICIENT_CREDENTIALS',
  ITEM_LOCKED = 'ITEM_LOCKED',
  USER_SETUP_REQUIRED = 'USER_SETUP_REQUIRED',
  MFA_NOT_SUPPORTED = 'MFA_NOT_SUPPORTED',
  INVALID_SEND_METHOD = 'INVALID_SEND_METHOD',
  NO_ACCOUNTS = 'NO_ACCOUNTS',
  ITEM_NOT_SUPPORTED = 'ITEM_NOT_SUPPORTED',
  TOO_MANY_VERIFICATION_ATTEMPTS = 'TOO_MANY_VERIFICATION_ATTEMPTS',
  INVALD_UPDATED_USERNAME = 'INVALD_UPDATED_USERNAME',
  INVALID_UPDATED_USERNAME = 'INVALID_UPDATED_USERNAME',
  ITEM_NO_ERROR = 'ITEM_NO_ERROR',
  item_no_error = 'item-no-error',
  NO_AUTH_ACCOUNTS = 'NO_AUTH_ACCOUNTS',
  NO_INVESTMENT_ACCOUNTS = 'NO_INVESTMENT_ACCOUNTS',
  NO_INVESTMENT_AUTH_ACCOUNTS = 'NO_INVESTMENT_AUTH_ACCOUNTS',
  NO_LIABILITY_ACCOUNTS = 'NO_LIABILITY_ACCOUNTS',
  PRODUCTS_NOT_SUPPORTED = 'PRODUCTS_NOT_SUPPORTED',
  ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
  ITEM_PRODUCT_NOT_READY = 'ITEM_PRODUCT_NOT_READY',
  INSTITUTION_DOWN = 'INSTITUTION_DOWN',
  INSTITUTION_NOT_RESPONDING = 'INSTITUTION_NOT_RESPONDING',
  INSTITUTION_NOT_AVAILABLE = 'INSTITUTION_NOT_AVAILABLE',
  INSTITUTION_NO_LONGER_SUPPORTED = 'INSTITUTION_NO_LONGER_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  PLANNED_MAINTENANCE = 'PLANNED_MAINTENANCE',
  PRODUCT_NOT_ENABLED = 'PRODUCT_NOT_ENABLED',
  DATA_UNAVAILABLE = 'DATA_UNAVAILABLE',
  ASSET_PRODUCT_NOT_READY = 'ASSET_PRODUCT_NOT_READY',
  ASSET_REPORT_GENERATION_FAILED = 'ASSET_REPORT_GENERATION_FAILED',
  INVALID_PARENT = 'INVALID_PARENT',
  INSIGHTS_NOT_ENABLED = 'INSIGHTS_NOT_ENABLED',
  INSIGHTS_PREVIOUSLY_NOT_ENABLED = 'INSIGHTS_PREVIOUSLY_NOT_ENABLED',
  BANK_TRANSFER_LIMIT_EXCEEDED = 'BANK_TRANSFER_LIMIT_EXCEEDED',
  BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT = 'BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT',
  BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT = 'BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT',
  BANK_TRANSFER_ACCOUNT_BLOCKED = 'BANK_TRANSFER_ACCOUNT_BLOCKED',
  BANK_TRANSFER_INSUFFICIENT_FUNDS = 'BANK_TRANSFER_INSUFFICIENT_FUNDS',
  BANK_TRANSFER_NOT_CANCELLABLE = 'BANK_TRANSFER_NOT_CANCELLABLE',
  BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE = 'BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE',
  BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT = 'BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT',
  SANDBOX_PRODUCT_NOT_ENABLED = 'SANDBOX_PRODUCT_NOT_ENABLED',
  SANDBOX_WEBHOOK_INVALID = 'SANDBOX_WEBHOOK_INVALID',
  SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID = 'SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID',
  MISSING_FIELDS = 'MISSING_FIELDS',
  UNKNOWN_FIELDS = 'UNKNOWN_FIELDS',
  INVALID_FIELD = 'INVALID_FIELD',
  INCOMPATIBLE_API_VERSION = 'INCOMPATIBLE_API_VERSION',
  INVALID_BODY = 'INVALID_BODY',
  INVALID_HEADERS = 'INVALID_HEADERS',
  NOT_FOUND = 'NOT_FOUND',
  NO_LONGER_AVAILABLE = 'NO_LONGER_AVAILABLE',
  SANDBOX_ONLY = 'SANDBOX_ONLY',
  INVALID_ACCOUNT_NUMBER = 'INVALID_ACCOUNT_NUMBER',
  INCORRECT_DEPOSIT_AMOUNTS = 'INCORRECT_DEPOSIT_AMOUNTS',
  UNAUTHORIZED_ENVIRONMENT = 'UNAUTHORIZED_ENVIRONMENT',
  INVALID_PRODUCT = 'INVALID_PRODUCT',
  UNAUTHORIZED_ROUTE_ACCESS = 'UNAUTHORIZED_ROUTE_ACCESS',
  DIRECT_INTEGRATION_NOT_ENABLED = 'DIRECT_INTEGRATION_NOT_ENABLED',
  INVALID_API_KEYS = 'INVALID_API_KEYS',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_PUBLIC_TOKEN = 'INVALID_PUBLIC_TOKEN',
  INVALID_LINK_TOKEN = 'INVALID_LINK_TOKEN',
  INVALID_PROCESSOR_TOKEN = 'INVALID_PROCESSOR_TOKEN',
  INVALID_AUDIT_COPY_TOKEN = 'INVALID_AUDIT_COPY_TOKEN',
  INVALID_ACCOUNT_ID = 'INVALID_ACCOUNT_ID',
  MICRODEPOSITS_ALREADY_VERIFIED = 'MICRODEPOSITS_ALREADY_VERIFIED',
  PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA = 'PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA',
  ACCOUNTS_LIMIT = 'ACCOUNTS_LIMIT',
  ADDITION_LIMIT = 'ADDITION_LIMIT',
  AUTH_LIMIT = 'AUTH_LIMIT',
  BALANCE_LIMIT = 'BALANCE_LIMIT',
  IDENTITY_LIMIT = 'IDENTITY_LIMIT',
  ITEM_GET_LIMIT = 'ITEM_GET_LIMIT',
  RATE_LIMIT = 'RATE_LIMIT',
  TRANSACTIONS_LIMIT = 'TRANSACTIONS_LIMIT',
  RECAPTCHA_REQUIRED = 'RECAPTCHA_REQUIRED',
  RECAPTCHA_BAD = 'RECAPTCHA_BAD',
  INCORRECT_OAUTH_NONCE = 'INCORRECT_OAUTH_NONCE',
  OAUTH_STATE_ID_ALREADY_PROCESSED = 'OAUTH_STATE_ID_ALREADY_PROCESSED',
}

export enum LinkErrorType {
  BANK_TRANSFER = 'BANK_TRANSFER_ERROR',
  INVALID_REQUEST = 'INVALID_REQUEST',
  INVALID_RESULT = 'INVALID_RESULT',
  INVALID_INPUT = 'INVALID_INPUT',
  INSTITUTION_ERROR = 'INSTITUTION_ERROR',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  API_ERROR = 'API_ERROR',
  ITEM_ERROR = 'ITEM_ERROR',
  AUTH_ERROR = 'AUTH_ERROR',
  ASSET_REPORT_ERROR = 'ASSET_REPORT_ERROR',
  SANDBOX_ERROR = 'SANDBOX_ERROR',
  RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
  OAUTH_ERROR = 'OAUTH_ERROR',
}

export interface LinkError {
  errorCode: LinkErrorCode
  errorType: LinkErrorType
  errorMessage: string
  /** @deprecated DO NOT USE, data not guaranteed. Use `displayMessage` instead */
  errorDisplayMessage?: string
  displayMessage?: string
  errorJson?: string
}

export interface LinkSuccess {
  publicToken: string
  metadata: LinkSuccessMetadata
}

export interface LinkSuccessMetadata {
  institution?: LinkInstitution
  accounts: LinkAccount[]
  linkSessionId: string
  metadataJson?: string
}

export type LinkOnSuccess = (publicToken: string, metadata: LinkSuccessMetadata) => void

// the doc comment for this
// says that there are other event types
// but that they should not be relied upon
// for experience customization
export enum LinkStableEvent {
  OPEN = 'OPEN',
  EXIT = 'EXIT',
  HANDOFF = 'HANDOFF',
  SELECT_INSTITUTION = 'SELECT_INSTITUTION',
  ERROR = 'ERROR',
}

export declare enum LinkEventViewName {
  ACCEPT_TOS = 'ACCEPT_TOS',
  CONNECTED = 'CONNECTED',
  CONSENT = 'CONSENT',
  CREDENTIAL = 'CREDENTIAL',
  DATA_TRANSPARENCY = 'DATA_TRANSPARENCY',
  DATA_TRANSPARENCY_CONSENT = 'DATA_TRANSPARENCY_CONSENT',
  DOCUMENTARY_VERIFICATION = 'DOCUMENTARY_VERIFICATION',
  ERROR = 'ERROR',
  EXIT = 'EXIT',
  KYC_CHECK = 'KYC_CHECK',
  SELFIE_CHECK = 'SELFIE_CHECK',
  LOADING = 'LOADING',
  MATCHED_CONSENT = 'MATCHED_CONSENT',
  MATCHED_CREDENTIAL = 'MATCHED_CREDENTIAL',
  MATCHED_MFA = 'MATCHED_MFA',
  MFA = 'MFA',
  NUMBERS = 'NUMBERS',
  NUMBERS_SELECT_INSTITUTION = 'NUMBERS_SELECT_INSTITUTION',
  OAUTH = 'OAUTH',
  RECAPTCHA = 'RECAPTCHA',
  RISK_CHECK = 'RISK_CHECK',
  SCREENING = 'SCREENING',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SELECT_AUTH_TYPE = 'SELECT_AUTH_TYPE',
  SUBMIT_PHONE = 'SUBMIT_PHONE',
  VERIFY_PHONE = 'VERIFY_PHONE',
  SELECT_SAVED_INSTITUTION = 'SELECT_SAVED_INSTITUTION',
  SELECT_SAVED_ACCOUNT = 'SELECT_SAVED_ACCOUNT',
  SELECT_BRAND = 'SELECT_BRAND',
  SELECT_INSTITUTION = 'SELECT_INSTITUTION',
  SUBMIT_DOCUMENTS = 'SUBMIT_DOCUMENTS',
  SUBMIT_DOCUMENTS_SUCCESS = 'SUBMIT_DOCUMENTS_SUCCESS',
  SUBMIT_DOCUMENTS_ERROR = 'SUBMIT_DOCUMENTS_ERROR',
  UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
  VERIFY_SMS = 'VERIFY_SMS',
}

export interface LinkEventMetadata {
  linkSessionId: string
  mfaType?: string
  requestId?: string
  viewName: LinkEventViewName
  errorCode?: string
  errorMessage?: string
  errorType?: string
  exitStatus?: string
  institutionId?: string
  institutionName?: string
  institutionSearchQuery?: string
  selection?: null | string
  timestamp: string
}

/*************************************************
 * The second chunk of this file are types that
 * relate to our unified implementation of the
 * Plaid Link
 *************************************************/
export type onExitEventType = LinkExitMetadata & {error?: LinkError}
export type onExitType = (data: onExitEventType) => void

export type onSuccessType = (data: onSuccessEventType) => Promise<void>
export type onSuccessEventType = LinkSuccessMetadata & {
  publicToken: LinkSuccess['publicToken']
  status?: string
}

export type onEventType = (eventName: LinkStableEvent, metadata: LinkEventMetadata) => void

export type onInstitutionSelectedType = (id: string, name: string) => void

export type onLinkedEventType =
  | onSuccessEventType
  | (onExitEventType & {
      public_token: string | undefined
      accounts?: LinkAccount[]
    })

export type AggregatorPlaidViewProps = {
  plaidLinkToken: string
  onSuccess: onSuccessType
  onExit: onExitType
  onInstitutionSelected: onInstitutionSelectedType
}

export type AggregatorPlaidGQLContainerProps = {
  accountId?: string
  onSuccess: onSuccessType
  onExit: onExitType
  onInstitutionSelected: onInstitutionSelectedType
  onSwitch: AggregatorPlaidProps['onSwitch']

  /**
   * This is called when the user selects an
   * unsupported institution (either altogether,
   * or just unsupported by Plaid) or when the
   * user selects an institution that just needed
   * to be relinked.
   */
  onEarlyExit: () => void
}

export type AggregatorPlaidValidationContainerProps = AggregatorPlaidGQLContainerProps & {
  token: string
}

export type AggregatorPlaidLinkContainerProps = {
  accountId?: string
  token: string
  onInstitutionSelected: onInstitutionSelectedType
  onExit: onExitType
  onSuccess: onSuccessType
}

export type AggregatorPlaidProps = {
  accountId?: string
  onComplete: (CALLBACK_STATUS, string?) => void

  /**
   * This is called when the user selects an
   * institution that is not supported by plaid
   * but is supported by another aggregator.
   */
  onSwitch: (alternateId: string, accountId?: string) => void
}

export type UseUniversalPlaidLinkOptions = {
  publicToken: string | null
  onSuccess: onSuccessType
  onExit: onExitType
  onEvent: onEventType
}
export type UseUniversalPlaidLinkReturn = {
  ready: boolean
  open: () => void
}
export type UseUniversalPlaidLink = (
  options: UseUniversalPlaidLinkOptions,
) => UseUniversalPlaidLinkReturn
export type UseUniversalPlaidRelink = (onSuccess: onSuccessType) => UseUniversalPlaidLinkReturn

export class AggregatorPlaidError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AggregatorPlaidError'
    // prefixing the message with a marker will allow us
    // to more easily create datadog allerts
    this.message = `[AggregatorPlaidError]: ${message}`
  }
}
