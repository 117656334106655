import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'

import Snackbar from 'src/lib/Snackbar'
import {CompleteUraDocument} from '@possible/cassandra/src/types/types.mobile.generated'
import {AcceptAgreementsV2Template} from 'src/products/MCU/AccountManagementV2/AcceptAgreementsV2Template'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {
  AcceptLoanAutoPayAgreementQuery,
  AcceptLoanAutoPayAgreementDocument,
  GenerateAchAgreementMutationDocument,
  SetDefaultPaymentMethodMutationDocument,
} from 'src/products/MCU/AccountManagementV2/AcceptLoanAutoPayAgreement/AcceptLoanAutoPayAgreement.gqls'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type Props = StackScreenProps<MainStackParamList, 'AcceptLoanAutoPayAgreement'>

export const AcceptLoanAutoPayAgreement: FC<Props> = (props) => {
  const {navigation, route} = props
  const {newDefaultPaymentMethodId, uraId, loanId, onSuccessRoute = 'Dashboard'} = route.params

  const {t} = useTranslation(['LoanApproved', 'AccountManagement', 'Common'])
  const tryAgain = t('Common:TryAgain')

  const getMaskFromQuery = (from: AcceptLoanAutoPayAgreementQuery): string | undefined | null => {
    let mask: string | undefined | null

    from.me.paymentMethods?.loanEligible?.forEach((method) => {
      if (method.__typename === 'AchPaymentMethod' && method.id === newDefaultPaymentMethodId) {
        mask = method.bankAccount.mask
      } else if (
        method.__typename === 'DebitCardPaymentMethod' &&
        method.id === newDefaultPaymentMethodId
      ) {
        mask = method.card.mask
      }
    })

    return mask
  }

  const accountMaskQuery = useCassandraQuery(
    AcceptLoanAutoPayAgreementDocument,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        if (!getMaskFromQuery(data)) {
          logAddPaymentMethodError(
            new Error(
              `AcceptLoanAutoPayAgreement: Unable to find payment method mask for instrumentID=${newDefaultPaymentMethodId}`,
            ),
          )
        }
      },
    },
    getMaskFromQuery,
  )

  const [generateAndShowAchAgreement, {loading: isLoadingAchAgreement}] = useCassandraMutation(
    GenerateAchAgreementMutationDocument,
    {
      variables: {
        loanId,
        paymentMethodId: newDefaultPaymentMethodId,
      },
      onError: (error) => {
        Snackbar.error({
          title: tryAgain,
          duration: Snackbar.LENGTH_LONG,
        })
        logAddPaymentMethodError(
          error,
          'AcceptLoanAutoPayAgreement: Unable to load url for ACH documents',
        )
      },
      onCompleted: (data) => {
        const url = data?.loanGenerateAchAgreement.download?.url
        if (!url) {
          Snackbar.error({
            title: tryAgain,
            duration: Snackbar.LENGTH_LONG,
          })
          logAddPaymentMethodError(
            new Error('AcceptLoanAutoPayAgreement: Empty ACH agreement URL returned from server.'),
          )
          return
        }

        displayPdf(url, navigation)
      },
    },
  )

  const [setDefaultPaymentMethod, {loading: isLoadingSetDefaultPaymentMethod}] =
    useCassandraMutation(SetDefaultPaymentMethodMutationDocument, {
      variables: {
        loanId,
        paymentInstrumentId: newDefaultPaymentMethodId,
      },
      onError: (e) => {
        logAddPaymentMethodError(
          e,
          'AcceptLoanAutoPayAgreement: Error setting default payment method.',
        )
        Snackbar.error({
          title: tryAgain,
          duration: Snackbar.LENGTH_LONG,
        })
      },
      onCompleted: () => {
        if (uraId) {
          // if this was for a URA we will complete it
          void completeUraAndGoBack()
        } else {
          // if not for a URA we nav go the specified success route
          navigation.reset({index: 0, routes: [{name: onSuccessRoute}]})
        }
      },
    })

  const [completeUraAndGoBack, {loading: isLoadingCompleteUra}] = useCassandraMutation(
    CompleteUraDocument,
    {
      variables: {
        // if uraId isn't set this won't be used but since it's a required arg we set empty string
        uraId: uraId ?? '',
      },
      onError: (e) => {
        logAddPaymentMethodError(e, 'AcceptLoanAutoPayAgreement: Error completing URA.')
        Snackbar.error({
          title: tryAgain,
          duration: Snackbar.LENGTH_LONG,
        })
      },
      onCompleted: () => {
        TrackAppEvent(
          AppEvents.Name.change_default_payment_method_URA_completed,
          AppEvents.Category.BankAccountManagement,
          {
            did_update_payment_method: true,
          },
        )

        navigation.goBack()

        // This second call closes the URA lightbox,
        // reset or pop(2) doesn't work because it's on a different stack
        navigation.goBack()
      },
    },
  )

  const handleACHAgreementPressed = async (): Promise<void> => {
    if (isLoadingAchAgreement) return
    await generateAndShowAchAgreement()
  }

  const {refetch: handleRefetchAccountMask} = accountMaskQuery
  return (
    <BaseTemplate
      isLoading={accountMaskQuery.loading}
      isError={!!accountMaskQuery.error}
      onRetry={handleRefetchAccountMask}
    >
      <AcceptAgreementsV2Template
        isLoadingOnContinue={
          isLoadingSetDefaultPaymentMethod ||
          isLoadingCompleteUra ||
          accountMaskQuery.loading ||
          isLoadingAchAgreement
        }
        accountMask={accountMaskQuery.selectedData ?? ''}
        onACHAgreementPressed={handleACHAgreementPressed}
        onAccept={(): void => void setDefaultPaymentMethod()}
        onRefuse={(): void => navigation.goBack()}
      />
    </BaseTemplate>
  )
}
