import React, {FunctionComponent, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {PushPage} from 'src/navigation/NavHelper'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type Props = StackScreenProps<MainStackParamList, 'DuplicatePhoneNumber'> & {
  phoneNumber: string
  Logout: (sessionExpired) => void
}

const DuplicatePhoneNumber: FunctionComponent<Props & PropsFromRedux> = (props: Props) => {
  const {navigation, phoneNumber} = props
  const [busy, setBusy] = useState(false)
  const {t} = useTranslation('PhoneConfirmation')

  const primary = {
    text: t('RecoverMyLoginInfo'),
    onPress: (): void => {
      setBusy(true)
      PushPage(navigation, 'AccountRecoveryAuthenticated', {
        isFromDuplicate: true,
      })
      setBusy(false)
    },
    disabled: busy,
  }

  const secondary = {
    buttonText: t('UseDifferentLoginMethod'),
    onPress: (): void => {
      setBusy(true)
      props.Logout(false)
      setBusy(false)
    },
    disabled: busy,
  }

  return (
    <GenericNonModalTemplate
      title={t('PhoneNumberNotAvailable')}
      description={t('UnableToUsePhoneNumber', {phoneNumber})}
      actionBlock={buttonLockupProperties(primary, secondary)}
      subPage={true}
    />
  )
}

const mapDispatchToProps = {
  Logout,
}

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default withForwardedNavigationParams<Props>()(connector(DuplicatePhoneNumber))
