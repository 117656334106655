import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseEnablePayOverTimeQueryVariables = Types.Exact<{[key: string]: never}>

export type UseEnablePayOverTimeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        installmentPlans: {
          __typename?: 'CardInstallmentPlanCollection'
          potential?: {
            __typename?: 'CardAccountInstallmentPlan'
            id?: string | null
            installments?: Array<{
              __typename?: 'InstallmentPayment'
              id?: string | null
              amount?: string | null
              executeAt?: string | null
            }> | null
          } | null
        }
      } | null
    }
  }
}

export const UseEnablePayOverTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseEnablePayOverTime'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'installmentPlans'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'potential'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'installments'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'amount'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'executeAt'},
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseEnablePayOverTimeQuery, UseEnablePayOverTimeQueryVariables>
