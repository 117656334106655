import React, {FC} from 'react'
import {Modal, View, StyleSheet} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'

export const BusyModal: FC = () => {
  return (
    <Modal transparent>
      <View style={styles.view}>
        <ActivityIndicator size="large" />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    backgroundColor: NamedColors.TRANSLUCENT,
    flex: 1,
    justifyContent: 'center',
  },
})
