import {
  LinkedAccount,
  AchPaymentMethod,
  DebitCardPaymentMethod,
  CheckPaymentMethod,
} from '@possible/cassandra/src/types/consumer'

export type PaymentAccount =
  | LinkedAccount
  | AchPaymentMethod
  | DebitCardPaymentMethod
  | CheckPaymentMethod
  | null
  | undefined

export enum PaymentFlow {
  Onboarding,
  PayNow,
  LoanPay,
  PayOverTime,
  SwitchToAutopay,
}

export enum PaymentMethodTypesEnum {
  Plaid = 'Plaid',
  Yodlee = 'Yodlee',
  AccountRouting = 'AccountRouting',
  DebitCard = 'DebitCard',
}

export type PaymentMethodTypeForAnalytics =
  | 'ach_routing_account'
  | 'ach_linked_with_aggregator'
  | 'debit_card'
