import {StackNavigationProp} from '@react-navigation/stack'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {View, StyleSheet} from 'react-native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  disabled?: boolean
}

const ForgotUsernamePassword: FC<Props> = (props) => {
  const {t} = useTranslation('EmailPasswordForm')
  const {navigation, disabled: isDisabled} = props

  const handleOnForgotEmail = (): void => {
    PushPage(navigation, 'AccountRecovery')
  }

  const handleOnForgotPassword = (): void => {
    PushPage(navigation, 'PasswordRecoveryViaPhoneNumber', {
      forgotPassword: true,
    })
  }

  return (
    <View accessible={false} style={styles.ForgotUsernamePasswordWrapper}>
      <PFText variant="p">{t('Forgot')} </PFText>
      <SvgLink
        linkType={'inline'}
        onPress={handleOnForgotEmail}
        disabled={isDisabled}
        linkText={t('email')}
        testID={'link_forgot_email'}
      />
      <PFText variant="p"> {t('or')} </PFText>
      <SvgLink
        linkType={'inline'}
        onPress={handleOnForgotPassword}
        disabled={isDisabled}
        linkText={t('password')}
        testID={'link_forgot_password'}
      />
      <PFText variant="p">?</PFText>
    </View>
  )
}

export {ForgotUsernamePassword}

const styles = StyleSheet.create({
  ForgotUsernamePasswordWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
