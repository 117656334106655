import React, {FC} from 'react'
import {Platform, StyleSheet, View} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardReapplyTile} from 'src/products/card/LoanDash/CardReapplyTile'
import {CardApplyTileProps} from 'src/products/card/LoanDash/CardApplyTile'
import {useGetCardOffers} from 'src/products/loans/ProductChoice/useGetCardOffers'

export const CardReapply: FC<Pick<CardApplyTileProps, 'tileMarginHorizontal'>> = ({
  tileMarginHorizontal,
}) => {
  const navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList> =
    useNavigation()
  const {defaultCardOffer} = useGetCardOffers()

  return (
    <View style={styles.container}>
      <CardReapplyTile
        navigation={navigation}
        loading={false}
        creditLimit={defaultCardOffer?.balanceLimit ?? ''}
        tileMarginHorizontal={tileMarginHorizontal}
      />
    </View>
  )
}

const contentMaxWidth: number = 450 as const
const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        maxWidth: contentMaxWidth,
        alignSelf: 'center',
      },
    }),
  },
})
