import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {PaymentMethodListProps} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodList.types'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import ActionRow from 'src/products/card/PaymentMethods/CardViewPaymentMethods/ActionRow'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {PushPage} from 'src/navigation/NavHelper'
import {PaymentMethodField} from 'src/products/card/PaymentMethods/PaymentMethodField/PaymentMethodField'

type Props = Pick<
  PaymentMethodListProps,
  'navigation' | 'nextScreen' | 'selectedPaymentAccount' | 'onPaymentAccountSelect'
> & {
  paymentMethod: PaymentAccount
  showRadio: boolean
}

export const PaymentMethodItem: FC<Props> = (props) => {
  const {
    paymentMethod,
    navigation,
    nextScreen,
    selectedPaymentAccount,
    onPaymentAccountSelect: handleOnPaymentAccountSelect,
    showRadio,
  } = props
  const {t} = useTranslation(['CardViewPaymentMethods', 'Common'])

  return (
    <ActionRow isFirstItem={false}>
      <Box direction={'row'} align={'center'} boxStyle={style.paymentMethodItem}>
        <PaymentMethodField
          paymentMethod={paymentMethod}
          selectedPaymentAccount={selectedPaymentAccount}
          onPaymentAccountSelect={handleOnPaymentAccountSelect}
          showRadio={showRadio}
        />
        {nextScreen ? (
          <Box align={'end'} gap={'small'}>
            <SvgLink
              linkType={'single'}
              linkText={t('Common:Edit')}
              onPress={(): void => {
                if (navigation) {
                  PushPage(navigation, nextScreen)
                }
              }}
              linkIcon={'internal'}
              linkIconSize={'little'}
            />
          </Box>
        ) : null}
      </Box>
    </ActionRow>
  )
}

const style = StyleSheet.create({
  paymentMethodItem: {
    flex: 1,
  },
})
