import {useFocusEffect} from '@react-navigation/native'
import {useCallback, useEffect, useState} from 'react'

import {Braze, logContentCardDismissal, subscribeToBrazeContentCards} from 'src/lib/braze/braze'
import type {BrazeContentCard} from 'src/lib/braze/braze.utils'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import Log from 'src/lib/loggingUtil'
import type {FilterBrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards.utils'

type UseBrazeContentCardsResponse<TProps> = {
  brazeContentCards: ReturnType<Parameters<typeof useBrazeContentCards<TProps>>[0]>
  handleOnDismiss: () => void
}

/**
 * Get and filter all available Braze Content Cards from context. There are pre-configured filters
 * and their corresponding types in `useBrazeContentCards.utils`. See tests for usage examples.
 *
 * @template UseBrazeContentCards takes a param to extend the hook's parameters and define
 * the parameters of the `filterFunction`.
 *
 * @param filterFunction pass a filter function that receives all params passed to the hook.
 *
 * @returns `Array<BrazeContentCard>`.
 */

const useBrazeContentCards = <TProps>(
  filterFunction: ReturnType<FilterBrazeContentCards<TProps>>,
): UseBrazeContentCardsResponse<TProps> => {
  const isBrazeContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')
  const [data, setData] = useState<BrazeContentCard[]>([])

  useEffect(() => {
    try {
      if (!isBrazeContentCardsEnabled) return

      return subscribeToBrazeContentCards((data) => {
        setData(filterFunction(data))
      })
    } catch (error) {
      Log.error('Error subscribing to Braze Content Cards')
    }
  }, [filterFunction, isBrazeContentCardsEnabled])

  /* 💡 Order matters! We have to call this after the `useEffect` above because we init our Content
   * Card subscription after we init the user's Braze "session" */
  useFocusEffect(
    useCallback(() => {
      try {
        if (!isBrazeContentCardsEnabled) return

        Braze.requestContentCardsRefresh()
      } catch (error) {
        Log.error('Error refreshing Braze Content Cards')
      }
    }, [isBrazeContentCardsEnabled]),
  )

  const handleOnDismiss = useCallback((): void => {
    try {
      if (!isBrazeContentCardsEnabled) return

      data.forEach((datum) => {
        logContentCardDismissal(datum)
      })

      // Force refresh to trigger a re-render with updated Braze `data`
      Braze.requestContentCardsRefresh()
    } catch (error) {
      Log.error('Error dismissing Braze Content Cards')
    }
  }, [data, isBrazeContentCardsEnabled])

  return {
    brazeContentCards: data,
    handleOnDismiss,
  }
}

export {useBrazeContentCards}
