import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatMask} from 'src/lib/user/utils'
import {
  getPaymentMethodAccountName,
  getPaymentMethodMask,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {
  CardActionListItem,
  CardActionListItemProps,
} from 'src/products/card/components/molecules/CardActionListItem'
import {DebitCardPaymentMethod} from 'src/cassandra'

type DebitCardPaymentMethodLinkProps = Partial<CardActionListItemProps> & {
  paymentMethod: DebitCardPaymentMethod
}

export const DebitCardPaymentMethodLink = ({
  paymentMethod,
  ...props
}: DebitCardPaymentMethodLinkProps): JSX.Element => {
  const {t} = useTranslation('PayNow')
  const accountName = getPaymentMethodAccountName(paymentMethod) ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''
  const mask = formatMask(accountMask, false)

  return (
    <CardActionListItem
      isFirstChild
      /* Debit cards may not be associated with an account. If there is an account, add
       * 'Debit Card' to the title so it's clear this isn't an ach account */
      title={accountName ? accountName : t('DebitCard')}
      subTitle={accountName ? `${t('DebitCard')} ${mask}` : mask}
      {...props}
    />
  )
}
