import {PaymentFrequency} from 'src/cassandra'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'

import {PaymentFrequencyQueryDocument} from 'src/products/card/Autopay/UseGetPaymentFrequency/PaymentFrequencyQuery.gqls'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

/** Returns the localized, human readable payment frequency (ex. every week, bi-weekly, monthly) */
export function getFrequencyText(
  frequency: PaymentFrequency | null | undefined,
  t: TFunction,
): string {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return t('Common:Weekly')
    case PaymentFrequency.Biweekly:
      return t('Common:Biweekly')
    case PaymentFrequency.Monthly:
      return t('Common:Monthly')
    default:
      return t('Common:Biweekly')
  }
}

/** Returns the localized, human readable payment frequency (ex. One Week, Two Weeks, Month) */
export function getWeeksFrequencyText(
  frequency: PaymentFrequency | null | undefined,
  t: TFunction,
): string {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return t('OneWeek')
    case PaymentFrequency.Biweekly:
      return t('TwoWeeks')
    case PaymentFrequency.Monthly:
      return t('Month')
    default:
      return t('TwoWeeks')
  }
}

/**
 * Returns the localized, human readable payment frequency (ex. every week, bi-weekly, monthly)
 */
export type UseGetPaymentFrequencyType = {
  isLoading: boolean
  frequencyText: string
  weeksFrequencyText: string
}
export const useGetPaymentFrequency = (): UseGetPaymentFrequencyType => {
  const {selectedData: frequency, loading: isLoading} = useCassandraQuery(
    PaymentFrequencyQueryDocument,
    {},
    (data) => data.me.cardAccounts.active?.payments?.potential?.frequency,
  )
  const {t} = useTranslation(['CardSetupAutopayments', 'Common'])
  return {
    isLoading,
    frequencyText: getFrequencyText(frequency, t),
    weeksFrequencyText: getWeeksFrequencyText(frequency, t),
  }
}
