import {useFocusEffect} from '@react-navigation/native'
import React, {useCallback, useEffect, useState} from 'react'
import {isEqual} from 'lodash'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'

type AnalyticsEventArgs = {[key: string]: unknown}

export type UsePageViewedAnalyticsProps = {
  eventName: AppEventName
  eventCategory: AppEventCategory
  eventArgs?: AnalyticsEventArgs
  isLoading?: boolean
  logEventToSingular?: boolean
}

/**
 * A hook to send page viewed analytics when the screen is focused.
 * Use this for function components.
 */
export const usePageViewedAnalytics = ({
  eventName,
  eventCategory,
  eventArgs,
  isLoading = false,
  logEventToSingular = false,
}: UsePageViewedAnalyticsProps): void => {
  // many screens pass a plain object as event args which causes the effect to run every time
  // the screen is re-rendered since eventArgs is a new object literal every time. to avoid this
  // we memoize the args and only update the memoized args when the args object actually changes
  const [memoizedEventArgs, setMemoizedEventArgs] = useState<AnalyticsEventArgs | undefined>(
    eventArgs,
  )
  useEffect(() => {
    if (!isEqual(memoizedEventArgs, eventArgs)) {
      setMemoizedEventArgs(eventArgs)
    }
  }, [eventArgs, memoizedEventArgs])
  const focusEffect = useCallback(() => {
    if (isLoading) return

    TrackAppEvent(eventName, eventCategory, memoizedEventArgs, logEventToSingular)
  }, [memoizedEventArgs, eventCategory, eventName, isLoading, logEventToSingular])
  useFocusEffect(focusEffect)
}

/**
 * Render this component in a class-based component to send a
 * page viewed analytics event when the parent is focused
 */
export const PageViewedAnalyticsProvider: React.FC<UsePageViewedAnalyticsProps> = (props) => {
  usePageViewedAnalytics(props)

  return null
}
