export enum aggregatorsId {
  plaid = 'plaid',
  yodlee = 'yodlee',
  mocked = 'mocked',
}

export enum bankLinkStatus {
  linked = 'linked_in_use',
  unlinked = 'unlinked',
}
