import React from 'react'

import {WorkflowScreenProps} from 'src/workflows/types'
import {usePfDispatch} from 'src/store/utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {onboardingSetCardPaymentMethod} from 'src/lib/onboarding/slice'
import {CardManualPayOverviewTemplate} from 'src/products/card/Activation/CardManualPayOverview/CardManualPayOverviewTemplate/CardManualPayOverviewTemplate'

type CardManualPayOverviewWorkflowContainerProps = WorkflowScreenProps & {
  onPressSecondary: () => void
}

const CardManualPayOverviewWorkflowContainer = ({
  onPressSecondary,
  onScreenComplete,
}: CardManualPayOverviewWorkflowContainerProps): JSX.Element => {
  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_how_manual_pay_works_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnPressPrimary = (): void => {
    TrackAppEvent(AppEvents.Name.card_how_manual_pay_works_page_cta, AppEvents.Category.Card)
    onScreenComplete()
  }

  const handleOnPressSecondary = (): void => {
    dispatch(onboardingSetCardPaymentMethod('reset'))
    onPressSecondary()
  }

  return (
    <CardManualPayOverviewTemplate
      onPressPrimary={handleOnPressPrimary}
      onPressSecondary={handleOnPressSecondary}
    />
  )
}

export {CardManualPayOverviewWorkflowContainer}
