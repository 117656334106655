import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'
import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ConfirmDebitCardGQLContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {
  ApplicationActivationWorkflowStackParams,
  WorkflowPreReqFulfillScreenProps,
} from 'src/workflows/types'

export type ConfirmDebitCardWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to integrate the ConfirmDebitCard screen into the NavPageState navigation system.
 */
export const ConfirmDebitCardWorkflowContainer: React.FC<ConfirmDebitCardWorkflowContainerProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props
  const navigation = useNavigation<StackNavigationProp<ApplicationActivationWorkflowStackParams>>()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_debit_card_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnAddNewDebitCard = (): void => {
    TrackAppEvent(AppEvents.Name.suggested_debit_card_confirmed, AppEvents.Category.Activation, {
      confirmed: false,
      navContainerType: 'Workflows',
    })

    navigation.navigate('CollectDebitCardNumbers')
  }

  const handleOnConfirm = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.suggested_debit_card_confirmed, AppEvents.Category.Activation, {
      confirmed: true,
      navContainerType: 'Workflows',
    })
    await onPreReqFulfilled()
  }

  return (
    <ConfirmDebitCardGQLContainer
      onAddNewDebitCard={handleOnAddNewDebitCard}
      onConfirm={handleOnConfirm}
    />
  )
}
