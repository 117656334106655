import * as Types from '@possible/cassandra/src/types/types.mobile.generated'
import {LoanSubmissionQuery} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'

export const AdvanceMonthlyFee = 15

/*
  This is a temporary hack for the frontend to determine if the user is in an advance state.
  This is a temporary solution until we can remove the min pre req system and/or add an API
  to inform the frontend what products are offered in a given state
*/
export const AdvanceEligableStates = ['CO', 'GA']

type ProductsCollectionMin = LoanSubmissionQuery['me']['products']
type LoanOfferInfoMin = ProductsCollectionMin['eligible']['all'][0]
type LoanOfferMin = Extract<LoanOfferInfoMin, {category: Types.ProductCategory}>['offers'][0]

export const loanOfferIsCashAdvance = (loanOffer: LoanOfferMin): boolean => {
  return loanOffer?.loanTypeVariant === Types.LoanTypeVariant.NonRecourseInstallment
}

export const loanOfferInfoIsCashAdvance = (LoanOfferInfo: LoanOfferInfoMin): boolean => {
  return 'offers' in LoanOfferInfo && LoanOfferInfo.offers?.some(loanOfferIsCashAdvance)
}

export const eligibleForPossibleAdvance = (products?: ProductsCollectionMin): boolean => {
  const loanOffers = (products?.eligible?.all || []).filter(
    (product) => 'category' in product && product?.category === Types.ProductCategory.Loan,
  )

  if (!loanOffers || loanOffers.length === 0) return false

  return loanOffers.some(loanOfferInfoIsCashAdvance)
}

/*
  This is a temporary hack for the frontend to determine if the user is in an advance state.
  This is a temporary solution until we can remove the min pre req system and/or add an API
  to inform the frontend what products are offered in a given state
*/

export const isAdvanceState = (state: string | undefined | null, userInCards: boolean): boolean => {
  if (!state) return false

  // Cards users should always be sent to cards, not the advance landing screen
  if (userInCards) return false

  return AdvanceEligableStates.includes(state)
}

export const isAdvanceVariant = (loanTypeVariant?: Types.LoanTypeVariant | null): boolean => {
  return loanTypeVariant === Types.LoanTypeVariant.NonRecourseInstallment
}
