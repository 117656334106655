import React, {FC} from 'react'
import {StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, AppEventName} from 'src/lib/Analytics/app_events'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {
  possibleElectronicCommunicationURI,
  possiblePrivacyURI,
  possibleTOSURI,
  possibleStateLicensesURI,
  coastalPrivacyPolicyURI,
  possibleCreditApprovalDisclosureURI,
} from 'src/navigation/WebLinks'
import {smallGap} from 'src/designSystem/layout'

type LoginRegistrationLegalProps = {
  disabled: boolean
}

const LoginRegistrationLegal: FC<LoginRegistrationLegalProps> = ({disabled}) => {
  const {t} = useTranslation(['RegistrationOrLogin'])

  const handleOnPress = (uri: string, eventName?: AppEventName) => (): void => {
    if (eventName) {
      TrackAppEvent(eventName, AppEvents.Category.Login)
    }

    OpenWebPage({uri})
  }

  return (
    <>
      <PFText variant={'p_sm'} textAlign={'center'} testID={'label_login_or_register_consent'}>
        {t('LoginOrRegisterConsent')}
        <SvgLink
          accessibilityLabel={t('TermsOfUse')}
          disabled={disabled}
          linkText={t('TermsOfUse')}
          linkType={'inline'}
          onPress={handleOnPress(
            possibleTOSURI(),
            AppEvents.Name.accept_agreements_view_tos_selected,
          )}
          textVariant="p_sm"
        />
        {', '}
        <SvgLink
          accessibilityLabel={t('PrivacyPolicy')}
          disabled={disabled}
          linkText={t('PrivacyPolicy')}
          linkType={'inline'}
          onPress={handleOnPress(
            possiblePrivacyURI(),
            AppEvents.Name.accept_agreements_view_privacy_policy_selected,
          )}
          textVariant="p_sm"
        />
        {', '}
        <SvgLink
          accessibilityLabel={t('CreditApprovalDisclosure')}
          disabled={disabled}
          linkText={t('CreditApprovalDisclosure')}
          linkType={'inline'}
          onPress={handleOnPress(
            possibleCreditApprovalDisclosureURI,
            AppEvents.Name.conditions_for_approval_disclosure_selected,
          )}
          textVariant="p_sm"
        />
        {', '}
        <SvgLink
          accessibilityLabel={t('ElectronicCommunicationsConsent')}
          disabled={disabled}
          linkText={t('ElectronicCommunicationsConsent')}
          linkType={'inline'}
          onPress={handleOnPress(
            possibleElectronicCommunicationURI(),
            AppEvents.Name.accept_agreements_view_ecc_selected,
          )}
          textVariant="p_sm"
        />
        {` ${t('Common:and')} `}
        <SvgLink
          accessibilityLabel={t('CoastalPrivacyPolicy')}
          disabled={disabled}
          linkText={t('CoastalPrivacyPolicy')}
          linkType={'inline'}
          onPress={handleOnPress(coastalPrivacyPolicyURI)}
          textVariant="p_sm"
        />
      </PFText>

      <PFText variant="p_sm" textAlign={'center'} textProps={{style: styles.viewOurStateLicenses}}>
        {t('ViewOur')}

        <SvgLink
          accessibilityLabel={t('StateLicenses')}
          disabled={disabled}
          linkText={t('StateLicenses')}
          linkType={'inline'}
          onPress={handleOnPress(
            possibleStateLicensesURI(),
            AppEvents.Name.accept_agreements_view_state_licenses_selected,
          )}
          textVariant="p_sm"
        />
      </PFText>
    </>
  )
}
export {LoginRegistrationLegal}

const styles = StyleSheet.create({
  viewOurStateLicenses: {
    paddingVertical: smallGap,
  },
})
