import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {PaymentMethodList} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/PaymentMethodList/PaymentMethodList'
import {
  ViewPaymentMethodsSubsets,
  ViewPaymentMethodsDebitCardSubset,
  ViewPaymentMethodsAchSubset,
} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethods.types'
import {AddPaymentMethodButtons} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddPaymentMethodButtons'
import {littleGap, mediumGap} from 'src/designSystem/layout'
import {PaymentMethodTypesEnum} from 'src/products/general/GeneralPaymentMethods/types'

export type ViewPaymentMethodTemplateProps = {
  allBankAccounts: ViewPaymentMethodsAchSubset[]
  allDebitPaymentMethods: ViewPaymentMethodsDebitCardSubset[]
  onBankSelect: (paymentAccount: ViewPaymentMethodsSubsets) => void
  onContinue: () => Promise<void>
  selectedPaymentMethod: ViewPaymentMethodsSubsets | null
  addAccountType: PaymentMethodTypesEnum | null
  onSelectAddAccountType: (accountType: PaymentMethodTypesEnum) => void
  hideAggregatorButton?: boolean
}

export const ViewPaymentMethodsTemplate: FC<ViewPaymentMethodTemplateProps> = (props) => {
  const {
    allBankAccounts,
    allDebitPaymentMethods,
    onBankSelect: handleOnPaymentAccountSelect,
    onContinue,
    selectedPaymentMethod,
    addAccountType,
    onSelectAddAccountType: handleOnSelectAccountType,
    hideAggregatorButton,
  } = props

  const {t} = useTranslation(['PaymentMethods', 'Common'])

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Continue'),
          onPress: onContinue,
          disabled: !selectedPaymentMethod && !addAccountType,
          testID: 'ViewPaymentMethods-ContinueBtn',
        },
      }}
      variant={'generic'}
      title={t('PaymentMethods')}
      banner={(): null => null}
      smallTopGap
      noHeaderSpacer
    >
      <Box>
        <PFText variant={'h3'}>{t('BankAccounts')}</PFText>
        <Box marginTop={'small'}>
          <PaymentMethodList
            paymentMethods={allBankAccounts}
            selectedPaymentAccount={selectedPaymentMethod}
            onPaymentAccountSelect={handleOnPaymentAccountSelect}
          />
        </Box>
      </Box>

      <Box marginTop={'small'}>
        <PFText variant={'h3'}>{t('DebitCards')}</PFText>
        <Box marginTop={'small'}>
          <PaymentMethodList
            paymentMethods={allDebitPaymentMethods ?? []}
            selectedPaymentAccount={selectedPaymentMethod}
            onPaymentAccountSelect={handleOnPaymentAccountSelect}
          />
        </Box>
      </Box>
      <Box marginTop={'medium'} marginBottom={'large'}>
        <PFText variant={'h3'}>{t('AddNewPaymentMethod')}</PFText>
        <PFText variant={'p'} textProps={{style: styles.oneTimeText}}>
          {t('UseForOneTime')}
        </PFText>
        <Box marginTop={'large'}>
          <AddPaymentMethodButtons
            selectedAccountType={addAccountType}
            onSelectAccountType={handleOnSelectAccountType}
            hideAggregatorButton={hideAggregatorButton}
          />
        </Box>
        <PFText variant={'p_sm'} color={'textDisabled'} textProps={{style: styles.onlineBank}}>
          {t('UsingOnlineBank')}
        </PFText>
      </Box>
    </Page>
  )
}

const styles = StyleSheet.create({
  oneTimeText: {marginTop: littleGap},
  onlineBank: {marginTop: mediumGap},
})
