import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type Props = {
  isLoadingEmailVerification: boolean
  hasErrorOnEmailVerification: boolean
  onClickButton: () => void | Promise<void>
}

const VerifyEmailLinkTemplate: React.FC<Props> = (props) => {
  const {
    hasErrorOnEmailVerification,
    isLoadingEmailVerification,
    onClickButton: handleOnClickButton,
  } = props
  const {t} = useTranslation(['VerifyEmailLink', 'Common'])

  const action = useMemo(
    () => ({
      onPress: handleOnClickButton,
      text: hasErrorOnEmailVerification ? t('Common:TryAgain') : t('ConfirmButton'),
    }),
    [handleOnClickButton, hasErrorOnEmailVerification, t],
  )

  if (isLoadingEmailVerification) {
    return <Loading size="medium" color="primary" type="loader0" />
  }

  return (
    <Page
      variant="generic"
      buttonProps={buttonLockupProperties(action)}
      title={hasErrorOnEmailVerification ? t('ErrorTitle') : t('EmailConfirmedTitle')}
      testID="VerifyEmailLinkScreen"
    >
      <PFText variant="p">
        {hasErrorOnEmailVerification ? t('ErrorDescription') : t('EmailConfirmedDescription')}
      </PFText>
    </Page>
  )
}
export {VerifyEmailLinkTemplate}
