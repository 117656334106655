import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {formatDate, humanReadableDateShort} from 'src/lib/utils/date'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {CardAgreementType} from 'src/cassandra'
import {useAgreementDocPdf} from 'src/products/card/CardsHistory/useAgreementDocPdf'
import {DocumentRow} from 'src/products/card/CardsHistory/DocumentRow'
import {useStatementDocPdf} from 'src/products/card/CardsHistory/useStatementDocPdf'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {hasAgreement} from 'src/products/card/CardsHistory/CardsHistory.utils'
import {useCardDocuments} from 'src/products/card/CardsHistory/UseCardDocuments/useCardDocuments'

export const CardsHistory: React.FC = () => {
  const {displayAgreementPdf} = useAgreementDocPdf()
  const {displayStatementPdf} = useStatementDocPdf()
  const {t} = useTranslation(['CardsHistory', 'Common'])
  const {cardAccountId, isAutopayEnabled, createdAt, agreements, statements} = useCardDocuments()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.lefthand_drawer_card_statements_and_documents_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const renderAgreements = (): JSX.Element => {
    return (
      <Box gap={'medium'}>
        {isAutopayEnabled ? (
          <DocumentRow
            name={t('ElectronicRepaymentAuthorization')}
            onPress={(): void => {
              TrackAppEvent(
                AppEvents.Name.cardholder_repayment_authorization_selected,
                AppEvents.Category.Admin,
              )
              void displayAgreementPdf(CardAgreementType.ElectronicRepayments)
            }}
          />
        ) : null}
        {hasAgreement(CardAgreementType.TermsAndConditions, agreements) ? (
          <DocumentRow
            name={t('CardholderAgreement')}
            onPress={(): void => {
              TrackAppEvent(AppEvents.Name.cardholder_agreement_selected, AppEvents.Category.Admin)
              void displayAgreementPdf(CardAgreementType.TermsAndConditions)
            }}
          />
        ) : null}

        {hasAgreement(CardAgreementType.BetaTestingAgreement, agreements) ? (
          <DocumentRow
            name={t('BetaTestParticipationAgreement')}
            onPress={(): void => {
              TrackAppEvent(
                AppEvents.Name.cardholder_beta_agreement_selected,
                AppEvents.Category.Admin,
              )
              void displayAgreementPdf(CardAgreementType.BetaTestingAgreement)
            }}
          />
        ) : null}
      </Box>
    )
  }

  const items = useMemo(
    () =>
      statements.map((stmt) => ({
        id: stmt.id,
        title: t('DateStatement', {date: formatDate(stmt.statementDate, humanReadableDateShort)}),
        handleOnPress: (): void => {
          TrackAppEvent(AppEvents.Name.card_statement_selected, AppEvents.Category.Admin)
          displayStatementPdf(cardAccountId ?? '', stmt?.id).catch(() => {
            snackbarErrorMessage(t('UnableToLoadStatement'))
          })
        },
      })),
    [statements, t, displayStatementPdf, cardAccountId],
  )

  return (
    <Page
      variant={'generic'}
      title={t('CreditCardStatementsAndDocuments')}
      testID="Credit-Card-Statements-Title-Id"
      description={
        createdAt
          ? t('CardApplicationSubmitted', {
              date: formatDate(createdAt, humanReadableDateShort),
            })
          : ''
      }
      smallTopGap={true}
    >
      <Box gap={'enormous'} marginTop={'medium'}>
        <Box gap={'large'}>
          <PFText testID="Legal-Agreements-Title-Id" variant={'h3'}>
            {t('LegalAgreements')}
          </PFText>
          <Box gap={'large'}>{renderAgreements()}</Box>
        </Box>

        <Box gap={'large'}>
          <PFText testID="Statements-Title-Id" variant={'h3'}>
            {t('CreditCardStatements')}
          </PFText>
          <Box gap={'large'}>
            {items.map((item) => (
              <DocumentRow key={item.id} name={item.title} onPress={item.handleOnPress} />
            ))}
          </Box>
        </Box>
      </Box>
    </Page>
  )
}
