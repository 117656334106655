import WebAppConfig from 'src/WebAppConfig'

/**
 * The key under which to store our public token in local storage.
 * This is only needed by web as we need to recover the key after
 * the user is redirected back to our app during OAuth with their
 * bank.
 */
export const PlaidPublicTokenLocalStorageKey = 'plaid_public_token'

/**
 * The key under which to store the URI we returned to after
 * OAuth bank linking in local storage.
 */
export const PlaidReturnURIKey = 'plaid_return_uri'

/**
 * This will be appended to the environment URI that we are running
 * in. For example, on production, this will result in
 * `https://app.possiblefinance.com/plaid-return.html`
 */
export const PlaidOAuthRedirectUriAppend = 'plaid-return.html'

let plaidLinkURI = `${WebAppConfig.REACT_APP_URI}`
// on test we need to remove the index.html
if (plaidLinkURI.includes('test.possiblesasquatch.com')) {
  plaidLinkURI = plaidLinkURI.replace('index.html', '')
} else if (!plaidLinkURI.endsWith('/')) {
  // on staging/prod we may need to add a trailing slash
  plaidLinkURI += '/'
}

/**
 * The full URI to return to after completing OAuth bank linking
 */
export const PlaidOAuthRedirectUri = `${plaidLinkURI}${PlaidOAuthRedirectUriAppend}`
