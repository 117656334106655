import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {RadioButton} from 'src/designSystem/components/atoms/RadioButton/RadioButton'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ViewPaymentMethodsSubsets} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethods.types'
import {
  getPaymentMethodLabel,
  isPrimaryAccount,
} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type PaymentMethodListProps = {
  paymentMethods: ViewPaymentMethodsSubsets[]
  onPaymentAccountSelect: (paymentAccount: ViewPaymentMethodsSubsets) => void
  selectedPaymentAccount: ViewPaymentMethodsSubsets | null
}

export const PaymentMethodList: FC<PaymentMethodListProps> = (props) => {
  const {onPaymentAccountSelect, paymentMethods, selectedPaymentAccount} = props

  // Move primary account to the top of the list
  let sortedPaymentMethods = paymentMethods
  const primaryAccount = paymentMethods.find((account) => isPrimaryAccount(account))
  if (primaryAccount) {
    const index = paymentMethods.indexOf(primaryAccount)
    sortedPaymentMethods = [
      primaryAccount,
      ...paymentMethods.slice(0, index),
      ...paymentMethods.slice(index + 1),
    ]
  }

  return (
    <>
      {sortedPaymentMethods.map((paymentMethod) => (
        <Box key={paymentMethod.id} direction={'row'} align={'center'} paddingVertical={'little'}>
          <Box flex>
            <RadioButton
              testID={`PaymentMethodList-RadioButton-${paymentMethod.id}`}
              id={'paymentMethod'}
              text={
                <PaymentMethodLabel
                  text={getPaymentMethodLabel(paymentMethod)}
                  isPrimary={isPrimaryAccount(paymentMethod)}
                />
              }
              subTextColorVariant={'disabled'}
              isChecked={
                selectedPaymentAccount != null &&
                selectedPaymentAccount.bankingPaymentInstrumentId ===
                  paymentMethod.bankingPaymentInstrumentId
              }
              toggleChecked={(): void => onPaymentAccountSelect(paymentMethod)}
            />
          </Box>
        </Box>
      ))}
    </>
  )
}

const PaymentMethodLabel = (props: {isPrimary: boolean; text: string}): React.ReactElement => {
  const {isPrimary, text, ...otherProps} = props
  const {t} = useTranslation(['PaymentMethods'])

  return (
    <>
      {isPrimary ? (
        <Box marginBottom={'tiny'}>
          <PFStatusPill
            text={t('Primary')}
            color={NamedColors.PRODUCT_BLUE}
            fontColor={NamedColors.WHITE}
          />
        </Box>
      ) : null}
      <PFText {...otherProps}>{text}</PFText>
    </>
  )
}
