/**
 * @fileoverview This file manages our TikTok analytics & event implementation.
 */

const ttsdk = {
  AppEventsLogger: {
    logEvent: (eventName: string, params?: Record<string, unknown>): void => {
      ttq.track(eventName, params)
    },
  },
}

export default ttsdk
