import {useCards} from 'src/products/card/hooks/useCards'

/**
 * Does this user have a pending payment >= the statement balance
 * The overdueStatementInfo.amount should be the statement balance - any payments made after the statement date
 */
export const useHasPendingPaymentForStatementBalance = (): boolean => {
  const {totalPendingPayments, overdueStatementInfo} = useCards()

  if (!overdueStatementInfo?.amount) {
    return false
  }

  const statementBalance = parseFloat(overdueStatementInfo.amount)
  return (
    totalPendingPayments > 0 && statementBalance > 0 && totalPendingPayments >= statementBalance
  )
}
