import React, {FC, ReactNode, useMemo} from 'react'
import {View, ViewStyle} from 'react-native'

import {LevelVariants, LevelsStyle} from 'src/designSystem/components/atoms/PFElevation/styles'
import {ColorVariants, DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'

export type PFElevationProps = React.PropsWithChildren & {
  level: LevelVariants
  children: ReactNode
  backgroundColor: ColorVariants | NamedColors
  borderRadius?: ViewStyle['borderRadius']
  borderBottomLeftRadius?: ViewStyle['borderRadius']
  borderBottomRightRadius?: ViewStyle['borderRadius']
  borderTopLeftRadius?: ViewStyle['borderRadius']
  borderTopRightRadius?: ViewStyle['borderRadius']
  showOverflow?: boolean
  style?: ViewStyle
  otherProps?: object
  testID?: string
}

const PFElevation: FC<PFElevationProps> = (props) => {
  const {
    level,
    borderRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    backgroundColor,
    showOverflow,
    style,
    children,
    otherProps,
    testID,
  } = props

  const shadowViewStyle = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      backgroundColor:
        level === -1 ? NamedColors.ASH : (DefaultVariantsColor[backgroundColor] ?? backgroundColor),
      borderRadius: borderRadius ?? 0,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      ...LevelsStyle[level](),
    }),
    [
      level,
      backgroundColor,
      borderRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
    ],
  )
  const innerViewStyle: ViewStyle = useMemo(
    () => ({
      overflow: showOverflow ? undefined : 'hidden',
      borderRadius: borderRadius ?? 0,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
    }),
    [
      borderRadius,
      showOverflow,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
    ],
  )

  return (
    <View style={[shadowViewStyle, style]} {...otherProps} testID={testID}>
      <View style={innerViewStyle} {...otherProps}>
        {children}
      </View>
    </View>
  )
}

export default PFElevation
