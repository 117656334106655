import React, {PropsWithChildren, RefObject} from 'react'
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form'
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputChangeEventData,
  View,
  ViewStyle,
} from 'react-native'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {extractFieldError} from 'src/designSystem/components/atoms/HookForm/HookForm.utils'

//___________________________steps to use form component_______________________________________
//1. create the form data type in your component
//    eg. type FormData = {
//           firstName: string
//           lastName: string
//        }
//2. set up the form hook in your component with the FormData type
//    eg. const {control, handleSubmit, errors} = useForm<FormData>({mode: 'all'})
//    NOTE: the default validation behavior is only onSubmit if you don't add the option argument as above
//3. wrap your input fields in the form component passing the control and errors props
//4. pass the formFieldProps to each input field as a formProps object
//4. wrap the submit button function in handleSubmit

//_____________________steps to add new input field type_______________________________________
//1. add to FIELD_VARIANT enum
//2. update switch in getInputField below to add necessary props to the rendered input
// ^^ at a minimum this needs to call the render prop onChange function with the form value when it changes
//3. add formFieldProps to prop types of the input field

// Ideally this would have a lot stricter typing, however, doing so
// creates an INSURMOUNTABLE number of tsc issues. So we're keeping
// things a bit looser on 'HookForm', sadly.
export type FormFieldProps = {
  shouldDisplayErrors?: boolean
  field: FieldVariants
  name: string
  rules?: Omit<RegisterOptions, 'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'>
  viewStyle?: ViewStyle
  ref?: RefObject<TextInput>
  defaultValue?: string
}

export enum FieldVariants {
  DatePicker = 'datepicker',
  Dropdown = 'dropdown',
  TextField = 'textField',
}

export const DefaultFormGapSizeVariant = 'little'

type HookFormPropTypes<FV extends FieldValues> = React.PropsWithChildren & {
  control: Control<FV>
  errors: FieldErrors<FV>
  box?: BoxProps
}

const getErrorMessage = (name: string, errors: FieldErrors): string | undefined => {
  const error = extractFieldError(name, errors)
  return error ? error.message : undefined
}

const getDisplayError = (
  display: boolean,
  errors: FieldErrors,
  name: string,
): JSX.Element | undefined => {
  if (!display) return undefined
  const error = extractFieldError(name, errors)
  if (error) {
    return (
      <PFText color={'error'} variant={'p_sm'}>
        {error.message}
      </PFText>
    )
  }
}

const HookForm = <FV extends FieldValues>(
  props: HookFormPropTypes<FV>,
): React.ReactElement<HookFormPropTypes<FV>> => {
  const {control, errors, box, children} = props

  const getInputField = <N extends Path<FV>>({
    renderProps,
    formProps,
    childProps,
    type,
  }: {
    renderProps: ControllerRenderProps<FV, N>
    formProps: FormFieldProps
    childProps: {formProps?: FormFieldProps; changeFilter?: (value: string) => string}
    type:
      | string
      | React.JSXElementConstructor<{
          onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
          onSelection?: (val: string) => void
          changeFilter?: (text: string) => string
          value: (typeof renderProps)['value']
          ref: (typeof formProps)['ref']
          error: string | undefined
        }>
  }): React.ReactElement => {
    const {value, onChange} = renderProps
    const {field, name, ref} = formProps

    const error = getErrorMessage(name, errors)

    const elementProps: {
      onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
      onSelection?: (val: string) => void
      changeFilter?: (text: string) => string
      value: (typeof renderProps)['value']
      ref: (typeof formProps)['ref']
      error: string | undefined
    } = {
      ...childProps,
      value,
      ref,
      error,
    }

    const onChangeWithFilter = (text: string): string => {
      const {changeFilter} = elementProps
      return changeFilter ? changeFilter(text) : text
    }

    switch (field) {
      case FieldVariants.TextField:
        elementProps.onChange = (e): void => onChange(onChangeWithFilter(e.nativeEvent.text))
        elementProps.error = error
        break
      case FieldVariants.Dropdown:
        elementProps.onSelection = (val): void => onChange(val)
        elementProps.error = error
        break
      case FieldVariants.DatePicker:
        elementProps.onChange = (val): void => onChange(val)
        elementProps.error = error
        break
      default:
        elementProps.onChange = (e): void => onChange(e.nativeEvent.text)
    }

    return React.createElement(type, {...elementProps})
  }

  const wrapChildInController = (
    child: React.ReactElement<{
      formProps?: FormFieldProps
      changeFilter?: (text: string) => string
    }>,
  ): React.ReactNode => {
    const {formProps} = child.props

    if (!formProps) {
      return child
    }

    const {name, rules, shouldDisplayErrors = false, viewStyle} = formProps
    return (
      <View key={name} style={[{width: '100%'}, viewStyle]}>
        <Controller<FV>
          control={control}
          render={(renderProps) =>
            getInputField({
              renderProps: renderProps.field,
              formProps,
              childProps: child.props,
              type: child.type,
            })
          }
          // eslint-disable-next-line no-type-assertion/no-type-assertion
          name={name as Path<FV>}
          rules={
            // eslint-disable-next-line no-type-assertion/no-type-assertion
            rules as Omit<
              RegisterOptions<FV, Path<FV>>,
              'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'
            >
          }
          defaultValue={control._defaultValues[name] ?? undefined}
        />
        {getDisplayError(shouldDisplayErrors, errors, name)}
      </View>
    )
  }

  const wrapRecursive = (nodeChildren: React.ReactNode): React.ReactNode => {
    return React.Children.toArray(nodeChildren).map((child) => {
      if (React.isValidElement<PropsWithChildren<{formProps?: FormFieldProps}>>(child)) {
        if ('formProps' in child.props && child.props.formProps?.name) {
          return wrapChildInController(child)
        } else if (child?.props?.children) {
          return React.cloneElement(child, child.props, wrapRecursive(child.props.children))
        }
      }
      return child
    })
  }

  const formInputs = wrapRecursive(children)

  //for the box, we want to default to using a box with little gap (8px)
  //but if you explicitly pass box props we should override with those values
  //and if you specify noBox then we will not use the gap at all
  const boxProps: BoxProps = {
    gap: DefaultFormGapSizeVariant,
    ...box,
  }

  return <Box {...boxProps}>{formInputs}</Box>
}

export default HookForm
