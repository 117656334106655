import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {isEmulatorSync} from 'react-native-device-info'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {NotificationsPermissionsTemplate} from 'src/products/general/NotificationsPermissions/NotificationsPermissionsTemplate'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

const isEmulator = isEmulatorSync()

type NotificationsPermissionsContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'NotificationsPermissions'>

const NotificationsPermissionsContainer: React.FC<NotificationsPermissionsContainerProps> = (
  NotificationsPermissionsContainerProps,
): JSX.Element => {
  const {onPreReqFulfilled} = NotificationsPermissionsContainerProps

  const [hasPushPermissions, requestPushPermissions] = usePushPermissions()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.notification_permission_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const handleOnPermissionsGranted = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.notification_permission_granted_cta, AppEvents.Category.Admin)
    // the __DEV_ condition is to allow detox to bypass the push permission
    if (__DEV__ || isEmulator || hasPushPermissions) {
      await onPreReqFulfilled()
      return
    }

    await requestPushPermissions()
    await onPreReqFulfilled()
  }

  const handleOnDismiss = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.notification_permission_not_now_cta, AppEvents.Category.Admin)
    await onPreReqFulfilled()
  }

  return (
    <NotificationsPermissionsTemplate
      onPermissionsGranted={handleOnPermissionsGranted}
      onDismiss={handleOnDismiss}
    />
  )
}

export {NotificationsPermissionsContainer}
