import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {BankRoutingAccountFormData} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountForm.types'
import {
  UseBankAddAchPaymentMethodResultPaymentMethod,
  useBankAddAchPaymentMethod,
} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/useBankAddAchPaymentMethod'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

type SubmitRoutingAndAccountFormHandlerReturn = {
  errorReason: PaymentMethodsAlertModalErrorReason | undefined
  achPaymentMethod?: UseBankAddAchPaymentMethodResultPaymentMethod
}

type SubmitRoutingAndAccountFormHandler = (args: {
  formData: BankRoutingAccountFormData
  linkedAccountIdToAssociateWith?: string
}) => Promise<SubmitRoutingAndAccountFormHandlerReturn>

/**
 * Hook that provides access to a mutation to create a new AchPaymentMethod with routing and account numbers..
 */
export const useBankRoutingAndAccountFormSubmission = (): {
  isSubmitting: boolean
  handleSubmitRoutingAndAccountForm: SubmitRoutingAndAccountFormHandler
} => {
  const [submitAddAchPaymentMethod, {loading: isSubmitting}] = useBankAddAchPaymentMethod()

  const handleSubmitRoutingAndAccountForm: SubmitRoutingAndAccountFormHandler = async (
    args,
  ): ReturnType<SubmitRoutingAndAccountFormHandler> => {
    const {formData, linkedAccountIdToAssociateWith} = args
    try {
      const res = await submitAddAchPaymentMethod({
        variables: {
          input: {
            routingNumber: formData.routingNumber,
            accountNumber: formData.accountNumber,
            linkedAccountId: linkedAccountIdToAssociateWith,
          },
        },
      })

      if (!res || res.errors?.length) {
        return {
          errorReason: 'GENERIC_ERROR',
        }
      }
      if (res.data?.bankAddAchPaymentMethod.result.type === AddPaymentMethodResultType.Successful) {
        if (res.data.bankAddAchPaymentMethod.paymentMethod?.__typename !== 'AchPaymentMethod') {
          logAddPaymentMethodError(
            new Error(
              `BankRoutingAndAccountFormPageContainer got successful response but its missing an ach payment method, payment method typename="${res.data.bankAddAchPaymentMethod.paymentMethod?.__typename}"`,
            ),
          )
          return {
            errorReason: 'GENERIC_ERROR',
          }
        }
        return {
          errorReason: undefined,
          achPaymentMethod: {
            __typename: 'AchPaymentMethod',
            ...res?.data?.bankAddAchPaymentMethod?.paymentMethod,
          },
        }
      } else {
        const ret: SubmitRoutingAndAccountFormHandlerReturn = {
          errorReason: res.data?.bankAddAchPaymentMethod.result.type ?? 'GENERIC_ERROR',
        }

        // If the error is "AlreadyExists" then we should return the existing method
        if (res.data?.bankAddAchPaymentMethod.paymentMethod?.__typename === 'AchPaymentMethod') {
          ret.achPaymentMethod = {
            __typename: 'AchPaymentMethod',
            ...res?.data?.bankAddAchPaymentMethod?.paymentMethod,
          }
        }

        return ret
      }
    } catch (e) {
      logAddPaymentMethodError(
        e,
        `BankRoutingAndAccountFormPageContainer failed to submit mutation to verify ach payment method routing and account numbers`,
      )
      return {
        errorReason: 'GENERIC_ERROR',
      }
    }
  }
  return {
    handleSubmitRoutingAndAccountForm,
    isSubmitting,
  }
}
