let isSessionReplayEnabled: boolean = true

const getIsSessionReplayEnabled = (): boolean => {
  return isSessionReplayEnabled
}

const setIsSessionReplayEnabled = (value: string | undefined): void => {
  isSessionReplayEnabled = value?.toLowerCase() === 'true'
}

export {getIsSessionReplayEnabled, setIsSessionReplayEnabled}
