import {useEffect, useState} from 'react'

import {Consumer} from 'src/cassandra'

export enum CardAccountType {
  Card400 = 'Card400',
  Card800 = 'Card800',
}

export const useGetCardType = (): CardAccountType => {
  const [cardType, setCardType] = useState<CardAccountType>(CardAccountType.Card400)
  const {data, loading} = Consumer.hooks.useCardsAccountTypeQuery()

  useEffect(() => {
    if (!loading) {
      const maxBalance: string | undefined | null =
        data?.me?.cardAccounts?.active?.cardAccountType?.maxBalanceLimit

      if (maxBalance && parseFloat(maxBalance) > 400) {
        setCardType(CardAccountType.Card800)
      } else {
        setCardType(CardAccountType.Card400)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return cardType
}
