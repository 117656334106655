import React, {FC, useState, useCallback} from 'react'
import {View, StyleSheet, ViewStyle, LayoutChangeEvent} from 'react-native'

type Props = React.PropsWithChildren & {
  style?: ViewStyle
  aspectRatio: ViewStyle['aspectRatio']
}

const AspectView: FC<Props> = (props) => {
  const {style, children, aspectRatio} = props
  const [width, setWidth] = useState<number>()
  const [height, setHeight] = useState<number>()

  const onLayout = (ev: LayoutChangeEvent): void => {
    setWidth(ev.nativeEvent.layout.width)
    setHeight(ev.nativeEvent.layout.height)
  }

  const computedStyle = useCallback(() => {
    const propsStyle = StyleSheet.flatten(style)

    let numberRatio = Number(aspectRatio)
    if (Number.isNaN(numberRatio)) {
      numberRatio = 1.0
    }

    if (propsStyle.width && width) {
      return {height: width / numberRatio}
    }
    if (propsStyle.height && height) {
      return {width: height * numberRatio}
    }
  }, [style, width, height, aspectRatio])

  return (
    <View style={[style, computedStyle()]} onLayout={onLayout}>
      {children}
    </View>
  )
}

export default AspectView
