/**
 * If this is true, redux store actions will be logged
 */
const isReduxStoreLoggingEnabled = false //do not check in with true

/**
 * If this is true, the APIClient will log requests and responses
 */
const isApiClientLoggingEnabled = false //do not check in with true

/**
 * If this is true, various services will log events
 */
const isEventServiceLoggingEnabled: boolean = false //do not check in with true

enum LogLevels {
  Dir,
  Log,
  Info,
  Warn,
  Error,
}

/**
 * All log messages of severity equal to or greater than logLevel will be logged to the console
 * All log messages of lower severity will be ignored.
 * You can silence the application log messages in the console by setting logLevel = LogLevels.ERROR
 */
const logLevel = LogLevels.Dir

export {
  isReduxStoreLoggingEnabled as REDUX_STORE_LOGGING,
  isApiClientLoggingEnabled as API_CLIENT_LOGGING,
  isEventServiceLoggingEnabled as EVENT_SERVICE_LOGGING,
  LogLevels,
  logLevel,
}
