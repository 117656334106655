import React, {useCallback, useContext, useEffect} from 'react'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {NavContext} from 'src/nav/NavContext'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserVerifyEmailConfirmDocument} from '@possible/cassandra/src/types/types.public.generated'
import {VerifyEmailLinkTemplate} from 'src/products/MCU/VerifyEmailLink/VerifyEmailLinkTemplate'

const clearQueryParams = (): void => {
  window.open('/', '_self')
}

const VerifyEmailLinkContainer: React.FC = (): JSX.Element => {
  const [{initialParams}] = useContext(NavContext)

  const verificationCode = initialParams?.get('code') ?? ''
  const email = initialParams?.get('email') ?? ''

  const handleOnEmailVerified = useCallback(() => {
    TrackAppEvent(AppEvents.Name.cs_email_verify_confirmed, AppEvents.Category.Admin)
  }, [])

  const handleOnError = useCallback(() => {
    TrackAppEvent(AppEvents.Name.cs_email_verify_failed, AppEvents.Category.Admin)
  }, [])

  const [verifyUserEmail, {loading: isLoadingEmailVerification, error: errorOnEmailVerification}] =
    useCassandraMutation(
      UserVerifyEmailConfirmDocument,
      {
        onError: handleOnError,
        onCompleted: handleOnEmailVerified,
      },
      {
        isPublic: true,
      },
    )

  useEffect(() => {
    if (verificationCode && email) {
      void verifyUserEmail({
        variables: {
          input: {
            email,
            verificationCode,
          },
        },
      })
    }
  }, [email, verificationCode, verifyUserEmail])

  const handleOnClickButton = useCallback(() => {
    if (!errorOnEmailVerification) {
      TrackAppEvent(AppEvents.Name.cs_email_verify_click_log_in, AppEvents.Category.Admin)
      clearQueryParams()
      return
    }

    void verifyUserEmail()
    TrackAppEvent(AppEvents.Name.cs_email_verify_try_again, AppEvents.Category.Admin)
  }, [errorOnEmailVerification, verifyUserEmail])

  return (
    <VerifyEmailLinkTemplate
      isLoadingEmailVerification={isLoadingEmailVerification}
      hasErrorOnEmailVerification={!!errorOnEmailVerification}
      onClickButton={handleOnClickButton}
    />
  )
}

export {VerifyEmailLinkContainer}
