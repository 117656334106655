import {createSlice} from '@reduxjs/toolkit'

import {
  enableChaosFailureForComponentRender,
  disableChaosFailureForComponentRender,
  registerComponentForChaos,
  degregisterComponentForChaos,
} from './actions'

export type ComponentChaosState = {
  registeredComponents: {[componentName: string]: boolean}
  failureComponents: {[componentName: string]: boolean}
}

const initialState: ComponentChaosState = {
  failureComponents: {},
  registeredComponents: {},
}

export const ChaosModeSlice = createSlice({
  name: 'componentChaos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // components that have registered themselves to allow chaos
    builder.addCase(registerComponentForChaos, (state, action) => {
      state.registeredComponents[action.payload.componentName] = true
    })
    builder.addCase(degregisterComponentForChaos, (state, action) => {
      delete state.registeredComponents[action.payload.componentName]
    })
    // component render failures
    builder.addCase(enableChaosFailureForComponentRender, (state, action) => {
      state.failureComponents[action.payload.componentName] = true
    })
    builder.addCase(disableChaosFailureForComponentRender, (state, action) => {
      delete state.failureComponents[action.payload.componentName]
    })
  },
})

export const {actions} = ChaosModeSlice

export default ChaosModeSlice.reducer
