import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {AddBankLinkedAccountSubset} from 'src/products/general/AddBankLink/AddBankLink.types'
import * as AddBankLinkUtils from 'src/products/general/AddBankLink/AddBankLink.utils'
import {
  AddBankLinkGQLContainer,
  AddBankLinkGQLContainerProps,
} from 'src/products/general/AddBankLink/AddBankLinkGQLContainer'
import {AddBankLinkWorkflowContainerDocument} from 'src/products/general/AddBankLink/AddBankLinkWorkflowContainer.gqls'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type AddBankLinkWorkflowContainerProps = WorkflowPreReqFulfillScreenProps & {
  navigation: AddBankLinkGQLContainerProps['navigation']
}

/**
 * Container to integrate the AddBankLink screen into Workflows.
 */
const AddBankLinkWorkflowContainer: React.FC<AddBankLinkWorkflowContainerProps> = (
  props: AddBankLinkWorkflowContainerProps,
) => {
  const {navigation, onPreReqFulfilled} = props
  const {t} = useTranslation('AddBankLink')

  const [setPrimaryAccount] = useCassandraMutation(AddBankLinkWorkflowContainerDocument)

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.BankAccountManagement,
    eventName: AppEvents.Name.prequal_bank_link_shown,
  })

  const handleCheckBankSupport: AddBankLinkGQLContainerProps['onCheckBankSupport'] = () => {
    AddBankLinkUtils.onCheckBankSupport({
      navigation,
    })
  }

  /**
   * When user continues after adding a bank account, send them to the next
   * Workflow preReq accordingly.
   */
  const handleContinueAfterBankLinkComplete: AddBankLinkGQLContainerProps['onContinueAfterBankLinkComplete'] =
    async (args: {
      bankName: string
      accounts: AddBankLinkedAccountSubset[] | undefined
    }): Promise<void> => {
      const {accounts} = args
      try {
        if (!accounts || accounts.length === 0) {
          throw new Error('No bank accounts found after linking')
        }

        // if only one account was linked, set it as primary
        // if more than one account was linked there will be a
        // PRIMARY_ACCOUNT pre-req to fulfill
        if (accounts.length === 1) {
          const accountId = accounts[0].id
          if (!accountId) {
            throw new Error('No account id found after linking bank')
          }
          const setPrimaryResponse = await setPrimaryAccount({
            variables: {
              linkedAccountId: accountId,
              paymentMethod: LoanPaymentMethod.Ach,
            },
          })
          if (setPrimaryResponse.errors) {
            throw setPrimaryResponse.errors[0]
          }
        }

        await onPreReqFulfilled()
      } catch (e) {
        // the user will just be stuck on the screen when this happens - UX could probably be improved
        logOfferApplicationError(
          e,
          'Error while trying to continue after bank linking in AddBankLinkWorkflowContainer',
        )
        ShowException(
          t(
            'ErrorSettingSinglePrimaryAccount',
            'We were unable to set your primary account. Please try again later.',
          ),
        )
        // their prereq failed to fulfill but we will call onPreReqFulfilled() so that the
        // user is sent back through workflows and then given another chance to try this screen.
        // workflows will see the prereq is missing and try again
        await onPreReqFulfilled()
      }
    }

  return (
    <AddBankLinkGQLContainer
      navigation={navigation}
      onCheckBankSupport={handleCheckBankSupport}
      pageTitle={t('LinkYourBankAccountPrequal')}
      onContinueAfterBankLinkComplete={handleContinueAfterBankLinkComplete}
    />
  )
}

export {AddBankLinkWorkflowContainer}
