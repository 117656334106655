import React from 'react'
import {ActivityIndicator, StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {iconButtonSizeSmall, iconButtonPaddingSmall} from 'src/designSystem/guide'
import {primaryBrandColor, tileDividerBackground} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type ImageUploadListProps = {
  items: Array<ImageListItemProps>
  onPress: () => void | Promise<void>
}

export type ImageListItemProps = {
  name: string
  isLoading: boolean
  hasError: boolean
  key: number
}

const ImageListItem = (props: ImageListItemProps): JSX.Element => {
  const {name, isLoading, hasError} = props

  let asset = <SvgIcon name="checkWithCircle" size="medium" colorVariant="info" isFilled />
  if (isLoading) {
    asset = <ActivityIndicator animating={isLoading} size="small" color={primaryBrandColor} />
  }
  if (hasError) {
    asset = <SvgIcon name="error" size="medium" colorVariant="error" isFilled />
  }

  return (
    <Box>
      <Box direction={'row'} marginVertical={smallGap} shrink={1}>
        <Box paddingLeft={iconButtonPaddingSmall} width={iconButtonSizeSmall}>
          <Box alignSelf={'center'} height={16} width={40}>
            {asset}
          </Box>
        </Box>
        <Box alignSelf={'center'} paddingLeft={10} shrink={1}>
          <PFText variant={'p'} color={hasError ? 'error' : 'textPrimary'}>
            {name}
          </PFText>
        </Box>
      </Box>
      <View style={styles.tileDivider} />
    </Box>
  )
}

const ImageUploadList = (props: ImageUploadListProps): JSX.Element => {
  const {items, onPress: handleOnPress} = props
  const isLoading = !!items.find((obj) => obj.isLoading)
  const isEmpty = items.length === 0

  const {t} = useTranslation(['Common'])

  return (
    <Box direction={'column'} paddingTop={smallGap}>
      <Button
        mode="landingScreenPrimary"
        size="medium"
        onPress={handleOnPress}
        disabled={isLoading}
        width="100%"
        testID={'Image-Upload-Button'}
      >
        {isEmpty ? t('UploadFiles') : t('UploadMoreFiles')}
      </Button>
      <Box marginTop={smallGap}>
        {items?.map((item) => <ImageListItem {...item} key={item.key} />)}
      </Box>
    </Box>
  )
}

export {ImageUploadList}

const styles = StyleSheet.create({
  tileDivider: {
    backgroundColor: tileDividerBackground,
    height: 0.5,
    width: '100%',
  },
})
