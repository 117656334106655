import {usePfDispatch} from 'src/store/utils'
import {GetMeAction, Consumer} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'

export const useAcceptCardAccount = (): ((cardAccountId: string) => Promise<void>) => {
  const dispatch = usePfDispatch()
  const [acceptCardAccount] = Consumer.hooks.useCardAccountAcceptMutation()

  return async (cardAccountId: string) => {
    const r = await acceptCardAccount({
      variables: {
        cardAccountId,
      },
    })

    if (r.errors?.length) {
      Log.log(r.errors?.[0].message)
      throw new Error('Unable to accept card account.')
    }
    await dispatch(GetMeAction())
  }
}
