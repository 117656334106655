import React from 'react'
import {StyleSheet} from 'react-native'

const ProdegeCardActivatedPixel: React.FC = () => {
  return (
    <img
      src="https://sboffers.swagbucks.com/GLCDt"
      width={1}
      height={1}
      alt="prodege-pixel"
      style={styles.img}
    />
  )
}

export default ProdegeCardActivatedPixel

const styles = StyleSheet.create({
  img: {display: 'none'},
})
