import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'

/**
 * To sign up for cards, a user must have the `cardApplicationGroup` UserMiscProp.
 * Once a user has created a cards account, these props are no longer checked
 */
export const useCanAccessCards = (): {
  canAccessCards: boolean
  hadOpportunityToApplyForCard: boolean
  isLoading: boolean
} => {
  const {value, isLoading} = useUserProperty('cardApplicationGroup')

  const didHaveOpportunityToApplyForCard = typeof value?.possibleCard !== 'undefined'
  const canAccessCards = value?.possibleCard ?? false

  return {
    canAccessCards,
    isLoading,
    hadOpportunityToApplyForCard: didHaveOpportunityToApplyForCard,
  }
}
