import type {EmitterSubscription} from 'react-native'
import * as Braze from '@braze/web-sdk'
import type {
  ContentCard as RnContentCard,
  ContentCardBase as RnContentCardBase,
} from '@braze/react-native-sdk'

import {getValidBrazeContentCards} from 'src/lib/braze/braze.utils'
import type {BrazeContentCard} from 'src/lib/braze/braze.utils'
import Log from 'src/lib/loggingUtil'

/**
 * For internal use and exported only for tests. This is probably not what you're looking for.
 */
type WebContentCard = Braze.ImageOnly | Braze.CaptionedImage | Braze.ClassicCard

let hasAttemptedInit = false
function newBraze(key: string): typeof Braze {
  if (!hasAttemptedInit) {
    hasAttemptedInit = true
    const wasInitSuccessful = Braze.initialize(key, {
      baseUrl: 'sdk.iad-03.braze.com',
    })

    if (!wasInitSuccessful) {
      Log.error('Braze SDK failed to initialize')
    } else {
      Braze.openSession()
    }
  }

  return Braze
}

/**
 * Exposes a normalized API for subscribing to Braze Content Card across all platforms.
 */
function subscribeToBrazeContentCards(
  callback: (cards: BrazeContentCard[]) => void,
): EmitterSubscription['remove'] {
  const contentCardSubscription = Braze.subscribeToContentCardsUpdates((data) => {
    /* Filters out any Braze Content Cards that do no have our required schema
     * (`screen`,`location`) and performs a type conversion on the SDK's string responses to return
     * a type safe array of `BrazeContentCard` objects. */
    try {
      const validContentCards = getValidBrazeContentCards(
        //The web SDK returns a different schema than `@braze/react-native-sdk`.
        mapWebContentCardsToNativeSchema(data.cards),
      )

      callback(validContentCards)
    } catch (error) {
      Log.error(error, `Error getting valid Braze Content Cards: ${JSON.stringify(data)}`)
    }
  })

  return () => {
    if (contentCardSubscription) {
      Braze.removeSubscription(contentCardSubscription)
    }
  }
}

/**
 * Exposes a normalized API for tracking Braze Content Card clicks via the Braze SDK across
 * all platforms.
 */
function logContentCardClick(datum: BrazeContentCard): void {
  /* It's unclear why but `Braze.logContentCardClick` won't log a click unless a `url` property
   * is present. */
  datum.url = datum.url ?? 'boom' // 💥

  Braze.logContentCardClick(mapBrazeContentCardToWebSchema(datum))
}

/**
 * Exposes a normalized API for tracking Braze Content Card dismiss events via the Braze SDK across
 * all platforms.
 */
function logContentCardDismissal(datum: BrazeContentCard): void {
  /* It's unclear why but `Braze.logContentCardClick` won't log a click unless a `url` property
   * is present. */
  datum.url = datum.url ?? 'boom' // 💥

  Braze.logCardDismissal(mapBrazeContentCardToWebSchema(datum))
}

/**
 * Maps our type safe `BrazeContentCard` back to the `@braze/web-sdk` compatible class.
 */
function mapBrazeContentCardToWebSchema(datum: BrazeContentCard): WebContentCard {
  /* Unfortunately our normalized `BrazeContentCard` has to be converted back to a `WebContentCard`.
   * for use with this method of the Braze Web SDK. */

  switch (datum.type) {
    case 'ImageOnly':
      return Object.assign(new Braze.ImageOnly(), {
        ...datum,
        aspectRatio: datum.imageAspectRatio,
        created: new Date(datum.created),
        expiresAt: new Date(datum.expiresAt),
        imageUrl: datum.image,
      })

    case 'Captioned':
      return Object.assign(new Braze.CaptionedImage(), {
        ...datum,
        aspectRatio: datum.imageAspectRatio,
        created: new Date(datum.created),
        description: datum.cardDescription,
        expiresAt: new Date(datum.expiresAt),
        imageUrl: datum.image,
      })
    case 'Classic':
      return Object.assign(new Braze.ClassicCard(), {
        ...datum,
        created: new Date(datum.created),
        description: datum.cardDescription,
        expiresAt: new Date(datum.expiresAt),
        imageUrl: datum?.image,
      })
  }
}

/*
 * The following utilities are web specific and can not be co-located with our shared
 * cross-platform utilities in `/braze/braze.utils` because they require imports from
 * `@braze/web-sdk` that conflict with `@braze/react-native-sdk`. A platform specific utility file
 * like `/braze/braze.utils.web` would unfortunately also make the shared utilities in
 * `/braze/braze.utils` inaccessible to web. However, these can be imported conflict free with
 * `@braze/react-native-sdk` dependencies in Jest tests since Jest renders in a "web DOM-like" and
 * were native dependencies are mocked.
 */

// Maps the shared properties amongst all Braze Content Card types from the web SDK in one place.
function mapWebCardToBaseSchema(webContentCard: WebContentCard): RnContentCardBase {
  const baseSchema: RnContentCardBase = {
    id: webContentCard.id ?? '',
    created: webContentCard.created?.getTime() ?? 0,
    expiresAt: webContentCard.expiresAt?.getTime() ?? 0,
    viewed: webContentCard.viewed,
    clicked: webContentCard.clicked,
    pinned: webContentCard.pinned,
    dismissed: webContentCard.dismissed,
    dismissible: webContentCard.dismissible,
    url: webContentCard?.url,
    openURLInWebView: false,
    isControl: webContentCard.isControl,
    extras: webContentCard.extras ?? {},
  }

  return baseSchema
}

/**
 * Maps the `@braze/web-sdk` response to match `@braze/react-native-sdk` so we can work with the
 * same data structure across all platforms.
 */
function mapWebContentCardsToNativeSchema(cards: Braze.ContentCards['cards']): RnContentCard[] {
  return cards.reduce<RnContentCard[]>((previousValue, currentValue) => {
    if (currentValue instanceof Braze.ClassicCard) {
      previousValue.push({
        ...mapWebCardToBaseSchema(currentValue),
        type: 'Classic',
        image: currentValue.imageUrl,
        title: currentValue.title,
        cardDescription: currentValue.description,
      })
    }
    if (currentValue instanceof Braze.CaptionedImage) {
      previousValue.push({
        ...mapWebCardToBaseSchema(currentValue),
        type: 'Captioned',
        image: currentValue.imageUrl ?? '',
        imageAspectRatio: currentValue.aspectRatio ?? 0,
        title: currentValue.title,
        cardDescription: currentValue.description,
      })
    }
    if (currentValue instanceof Braze.ImageOnly) {
      previousValue.push({
        ...mapWebCardToBaseSchema(currentValue),
        type: 'ImageOnly',
        image: currentValue?.imageUrl ?? '',
        imageAspectRatio: currentValue.aspectRatio ?? 0,
      })
    }

    return previousValue
  }, [])
}

export {
  /* `Braze` is re-exported to provide easier access to the few parts of the RN and Web SDKs that
   * overlap. Use carefully and verify cross-platform method compatibility. */
  Braze,
  logContentCardClick,
  logContentCardDismissal,
  mapBrazeContentCardToWebSchema,
  mapWebContentCardsToNativeSchema,
  newBraze,
  subscribeToBrazeContentCards,
}
export type {WebContentCard}
