import React from 'react'
import {useTranslation} from 'react-i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {getRemoteValueJson} from 'src/lib/RemoteConfig'
import {
  UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS,
  UNSUPPORTED_INSTITUTIONS_POSSIBLE,
} from 'src/lib/RemoteConfig/parameterkeys'
import {userHasOnlineBankEnabled} from 'src/lib/user/utils'

export const institutionIsSupported = async (bankId: string): Promise<boolean> => {
  const unsupportedInstitutionsPossible = getRemoteValueJson<Record<string, string>[]>(
    UNSUPPORTED_INSTITUTIONS_POSSIBLE,
  )
  const unsupportedInstitutionsOnlineBanks = getRemoteValueJson<Record<string, string>[]>(
    UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS,
  )
  const isOnlineBankSupportEnabled = await userHasOnlineBankEnabled()
  const allUnsupportedBanks = [
    ...(unsupportedInstitutionsPossible ? unsupportedInstitutionsPossible : []),
    ...(!isOnlineBankSupportEnabled ? (unsupportedInstitutionsOnlineBanks ?? []) : []),
  ]
  return !!bankId && !allUnsupportedBanks.map((item) => item.id).includes(bankId)
}

export function showUnsupportedInstitutionPopup(bank) {
  const Notice = () => {
    const {t} = useTranslation('Bank')

    const primary_action = {
      text: t('TryDifferentBank'),
      onPress: () => {
        TrackAppEvent(
          AppEvents.Name.link_bank_account_unsupported_institution_try_again,
          AppEvents.Category.Application,
          {value: bank},
        )
      },
      closeOnPress: true,
    }

    TrackAppEvent(
      AppEvents.Name.link_bank_account_unsupported_institution_viewed,
      AppEvents.Category.Application,
      {value: bank},
    )

    return (
      <LightboxNotice
        title={t('UnsupportedBank')}
        body_text={`${t('UnsupportedBankMessage', {bank})}\n\n${t(
          'UnsupportedBankMessageLinkAnotherBank',
        )}`}
        primary_action={primary_action}
      />
    )
  }

  ShowLightbox(<Notice />, true)
}
