import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type DashboardLoanUtilsLatestActionableLoanQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type DashboardLoanUtilsLatestActionableLoanQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    loans: {
      __typename: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename: 'Loan'
        id: string
        status:
          | {__typename: 'ActiveLoanStatus'}
          | {__typename: 'ApprovedLoanStatus'}
          | {__typename: 'CancelledLoanStatus'}
          | {__typename: 'ChargedOffLoanStatus'}
          | {__typename: 'ExpiredLoanStatus'}
          | {__typename: 'PaidOffLoanStatus'}
          | {__typename: 'PendingLoanStatus'}
          | {__typename: 'RejectedLoanStatus'}
          | {__typename: 'ReplacedLoanStatus'}
        originalLoan?: {__typename?: 'Loan'; id: string} | null
      } | null
    }
  }
}

export const DashboardLoanUtilsLatestActionableLoanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'DashboardLoanUtilsLatestActionableLoan'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'originalLoan'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardLoanUtilsLatestActionableLoanQuery,
  DashboardLoanUtilsLatestActionableLoanQueryVariables
>
