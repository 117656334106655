import {DocumentNode} from 'graphql'

import * as genTypes from './types.mobile.generated'

export const parseGeneratedTypes = (generatedTypes) => {
  const allDocuments = Object.keys(generatedTypes)
    .map((key) => {
      if (key.endsWith('Document')) {
        const doc = generatedTypes[key]
        if (doc.kind === 'Document') {
          const tDoc: DocumentNode = doc
          return tDoc
        }
      }

      return null
    })
    .filter((doc) => doc !== null) as DocumentNode[]

  const allQueries = allDocuments.filter((doc) =>
    doc.definitions.some((def) => def.kind === 'OperationDefinition' && def.operation === 'query'),
  )
  const allQueryOperationNames: string[] = []
  allQueries.forEach((doc) => {
    doc.definitions.forEach((def) => {
      if (def.kind === 'OperationDefinition' && def.name) {
        allQueryOperationNames.push(def.name.value)
      }
    })
  })

  const allMutations = allDocuments.filter((doc) =>
    doc.definitions.some(
      (def) => def.kind === 'OperationDefinition' && def.operation === 'mutation',
    ),
  )
  const allMutationOperationNames: string[] = []
  allMutations.forEach((doc) => {
    doc.definitions.forEach((def) => {
      if (def.kind === 'OperationDefinition' && def.name) {
        allMutationOperationNames.push(def.name.value)
      }
    })
  })
  return {
    allQueries,
    allQueryOperationNames,
    allMutations,
    allMutationOperationNames,
  }
}

const {allQueries, allQueryOperationNames, allMutations, allMutationOperationNames} =
  parseGeneratedTypes(genTypes)

export const AllQueryOperations = allQueries
export const AllQueryOperationNames = allQueryOperationNames
export const AllMutationOperations = allMutations
export const AllMutationOperationNames = allMutationOperationNames
