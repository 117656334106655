import {gql} from '@apollo/client'

import {
  UserFragment,
  UserProfileFragment,
  UserLoansFragment,
  CardAccountsFragment,
  UraAllFragment,
  LocationFragment,
} from '../fragments'

export const UserQuery = gql`
  ${UserFragment}
  ${UserProfileFragment}
  ${UserLoansFragment}
  ${CardAccountsFragment}
  ${UraAllFragment}
  query UserQuery($userId: ID!) {
    user(userId: $userId) {
      ...UserFragment
      ...UserProfileFragment
      ...UserLoansFragment
      ...CardAccountsFragment
      ...UraAllFragment
    }
  }
`

export const CardNextManualReviewActionQuery = gql`
  query CardNextManualReviewActionQuery($userId: ID!) {
    cardNextManualReviewAction(userId: $userId) {
      manualReviewTask {
        cardAccountId
        assigneeId
        taskId
        reviewReasonCode
        reviewReasonMessage
      }
      cardAccount {
        id
        createdAt
        cardNetwork
        status {
          __typename
        }
      }
      userId
      userFullName
    }
  }
`

// Resume a card application from the hopper
export const CardAccountApplicationResumeQuery = gql`
  mutation cardAccountApplicationResume($taskId: ID!) {
    cardAccountApplicationResume(taskId: $taskId)
  }
`

// Snooze a card application from the hopper
export const CardAccountApplicationSnoozeQuery = gql`
  mutation cardAccountApplicationSnooze($taskId: ID!, $snoozeForHours: Int!) {
    cardAccountApplicationSnooze(taskId: $taskId, snoozeForHours: $snoozeForHours)
  }
`

// Reject a card application from the hopper
export const CardAccountApplicationRejectQuery = gql`
  mutation cardAccountApplicationReject($taskId: ID!, $reviewReasonCode: Int) {
    cardAccountApplicationReject(taskId: $taskId, reviewReasonCode: $reviewReasonCode)
  }
`

// Request to activate card account
export const CardActivateQuery = gql`
  mutation cardActivate($cardAccountId: ID!, $cardId: ID!) {
    cardActivate(cardAccountId: $cardAccountId, cardId: $cardId)
  }
`

// Request to suspend card account
export const CardAccountSuspendQuery = gql`
  mutation cardAccountSuspend($cardAccountId: ID!, $suspensionStatusReason: String!) {
    cardAccountSuspend(
      cardAccountId: $cardAccountId
      suspensionStatusReason: $suspensionStatusReason
    )
  }
`

// Request to deactivate card account
export const CardAccountDeactivateQuery = gql`
  mutation cardAccountDeactivate($cardAccountId: ID!, $deactivationStatusReason: String!) {
    cardAccountDeactivate(
      cardAccountId: $cardAccountId
      deactivationStatusReason: $deactivationStatusReason
    )
  }
`

export const UnderwritingSimulatorGetTemplatesQuery = gql`
  query UnderwritingSimulatorGetTemplatesQuery {
    underwritingSimulatorGetTemplates {
      templates {
        key
        template
      }
    }
  }
`

export const LoanDocumentsMostRecent = gql`
  query LoanDocumentsMostRecent($loanId: ID!) {
    loan(loanId: $loanId) {
      documents {
        mostRecent {
          documentKey
          type
          loanStatusAtCreation
          createdAt
          updatedAt
          updatedBy {
            id
            type
          }
        }
      }
    }
  }
`

export const LoanDocumentsHistory = gql`
  query LoanDocumentsHistory($loanId: ID!) {
    loan(loanId: $loanId) {
      documents {
        history {
          documentKey
          type
          loanStatusAtCreation
          createdAt
          updatedAt
          updatedBy {
            id
            type
          }
        }
      }
    }
  }
`

export const TransformLoanDocumentKeyForDownload = gql`
  mutation TransformLoanDocumentKeyForDownload(
    $documentKey: String!
    $documentType: LoanDocumentType!
  ) {
    transformLoanDocumentKeyForDownload(documentKey: $documentKey, type: $documentType) {
      url
      expiresAt
    }
  }
`
export const UserModifyAddressComponentMutation = gql`
  ${LocationFragment}
  mutation UserModifyAddressComponents(
    $userId: ID!
    $addressId: ID
    $addressType: AddressType!
    $physicalAddress: ApplicantPhysicalAddressComponentsInput!
  ) {
    userModifyAddressComponents(
      userId: $userId
      addressId: $addressId
      addressType: $addressType
      physicalAddress: $physicalAddress
    ) {
      ...LocationFragment
    }
  }
`
