import React from 'react'
import {TouchableOpacity, Clipboard} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Snackbar from 'src/lib/Snackbar'
import Box from 'src/designSystem/components/atoms/Box/Box'

type CardValuesType = (string | null | undefined)[][]

type Props = {
  cardValues: CardValuesType
}
export const CardDetailsContent: React.FC<Props> = (props) => {
  const {cardValues} = props

  const content = cardValues.map((val, i) => (
    <Box key={i}>
      <Box direction="row" marginBottom="medium">
        <Box grow={true}>
          <PFText variant="p_semibold">{val[0]}</PFText>
        </Box>
        <Box>
          {val[2] ? (
            <TouchableOpacity
              onPress={(): void => {
                if (val[1] && val[2]) {
                  Clipboard.setString(val[1])
                  Snackbar.show({
                    title: val[2],
                    duration: Snackbar.LENGTH_LONG,
                  })
                }
              }}
            >
              <Box direction="row" align="center">
                <PFText variant="p" textAlign="right">
                  {val[1]}
                </PFText>
                <Box marginLeft="little">
                  <SvgIcon name={'copy'} size={'small'} colorVariant={'default'} />
                </Box>
              </Box>
            </TouchableOpacity>
          ) : (
            <PFText variant="p" textAlign="right">
              {val[1]}
            </PFText>
          )}
        </Box>
      </Box>
    </Box>
  ))

  return <>{content}</>
}
