import {StyleSheet} from 'react-native'
import {inputLineHeightActive} from 'src/designSystem/typography'
import {smallGap} from 'src/designSystem/layout'
import {isDeviceAndroid} from 'src/lib/utils/platform'

export const inputGap = 8
const inputInternalGap = 4
const MIN_INPUT_HEIGHT = 50

export default StyleSheet.create({
  textBox: {
    minHeight: MIN_INPUT_HEIGHT,
    paddingBottom: isDeviceAndroid() ? inputInternalGap : inputGap,
    paddingLeft: smallGap,
    paddingRight: 0,
    paddingTop: isDeviceAndroid()
      ? inputLineHeightActive
      : inputLineHeightActive + inputInternalGap,
  },
})
