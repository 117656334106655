import React, {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Dimensions, StyleSheet, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import FilledStar from 'src/assets/icons/FilledStar.svg'
import {mediumGap, smallGap} from 'src/designSystem/layout'
import ReviewCarouselLeft from 'src/assets/illustrations/review-carousel-left.png'
import ReviewCarouselRight from 'src/assets/illustrations/review-carousel-right.png'
import Image from 'src/products/general/Image/Image'
import {NamedColors} from 'src/designSystem/colors'

const scoreSlots = Array.from(Array(5).keys())

export const ReviewCarousel = (): JSX.Element => {
  const {t} = useTranslation('RegistrationOrLogin')

  const contentPadding = useMemo(
    () => (Dimensions.get('window').width > 440 ? mediumGap : smallGap),
    [],
  )

  const renderContent = useMemo(() => {
    return (
      <>
        <Box direction={'row'} alignSelf={'center'} align={'center'} justify="between" width={150}>
          {scoreSlots.map((position) => (
            <Box key={`star-${position}`} direction={'row'}>
              <PFSvg SvgUri={FilledStar} svgProps={{width: 20, height: 20}} />
            </Box>
          ))}
        </Box>
        <PFText color={NamedColors.WHITE} variant={'p_sm'} textAlign={'center'}>
          <Trans
            t={t}
            i18nKey={'ReviewCarouselMain'}
            components={{
              bold: <PFText color={NamedColors.WHITE} variant={'label_sm'} />,
            }}
          />
        </PFText>
      </>
    )
  }, [t])

  return (
    <View testID={'review-carousel'}>
      <Box direction={'row'} boxStyle={styles.container}>
        <Image resizeMode={'contain'} style={styles.detail} source={ReviewCarouselLeft} />
        <Box padding={contentPadding} flex={1}>
          {renderContent}
        </Box>
        <Image resizeMode={'contain'} style={styles.detail} source={ReviewCarouselRight} />
      </Box>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: NamedColors.BLACK,
    height: 87,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  detail: {
    margin: 24,
    height: 63,
    width: 23,
  },
})
