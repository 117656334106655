import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

/**
 * The interval in milliseconds to retry a failed operation.
 * Each retry will double this interval (3, 6, 12, ...)
 */
const initialRetryInterval = 2999

/**
 * The frequency in milliseconds to update the retry countdown.
 */
const retryTickRate = 500

type ErrorNoticeRetryCountdownProps = {
  retryCount: number
  onAttemptRetry: () => Promise<void>
}

const useTimeout = (callback: () => void, delay: number): void => {
  useEffect(() => {
    const id = setTimeout(callback, delay)
    return () => clearTimeout(id)
  }, [callback, delay])
}

/**
 * A component for managing the retry countdown. This probably should not be used
 * outside of `ErrorNotice`.
 */
const ErrorNoticeRetryCountdown: React.FC<ErrorNoticeRetryCountdownProps> = (props) => {
  const {retryCount, onAttemptRetry} = props

  const {t} = useTranslation('ErrorNotice')

  const [remainingTime, setRemainingTime] = useState(initialRetryInterval)

  useEffect(() => {
    setRemainingTime((retryCount + 1 + Math.max(retryCount - 1, 0)) * initialRetryInterval)
  }, [retryCount])

  useTimeout(
    useCallback(() => {
      if (remainingTime === 0) {
        return
      }

      const result = Math.max(remainingTime - retryTickRate, 0)
      setRemainingTime(result)
      if (result === 0) {
        onAttemptRetry().catch(() => {
          // no op
        })
      }
    }, [onAttemptRetry, remainingTime]),
    retryTickRate,
  )

  const tString = useMemo(() => {
    if (remainingTime === 0) {
      return 'BodyRetryingNow'
    } else if (remainingTime <= 1000) {
      return 'BodyRetryingInSingular'
    } else {
      return 'BodyRetryingInPlural'
    }
  }, [remainingTime])

  return (
    <Box marginTop="medium">
      <PFText variant="p">
        {t(tString, {replace: {time: Math.floor(remainingTime / 1000 + 1)}})}
      </PFText>
    </Box>
  )
}

export {ErrorNoticeRetryCountdown, initialRetryInterval, retryTickRate}
