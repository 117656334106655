import {EnvState} from './types'
import {EnvironmentType, Applications} from '../types'

export const GraphqlURIMap: Record<
  Applications,
  Record<
    Exclude<EnvironmentType, EnvironmentType.NotSet>,
    Pick<EnvState, 'graphqlUri' | 'graphqlWSUri' | 'graphqlPublicUri'>
  >
> = {
  [Applications.MOBILE]: {
    [EnvironmentType.Local]: {
      graphqlUri: 'http://localhost:4000/graphql',
      graphqlWSUri: 'ws://localhost:4000/graphql',
      graphqlPublicUri: 'http://localhost:4000/graphql-public',
    },
    [EnvironmentType.LocalJava]: {
      graphqlUri: 'https://localhost:8443/graphql',
      graphqlWSUri: 'wss://localhost:8443/graphql',
      graphqlPublicUri: 'https://localhost:8443/graphql-public',
    },
    [EnvironmentType.Dev]: {
      graphqlUri: 'https://api.possiblesasquatch.com/graphql',
      graphqlWSUri: 'wss://api.possiblesasquatch.com/graphql',
      graphqlPublicUri: 'https://api.possiblesasquatch.com/graphql-public',
    },
    [EnvironmentType.Prod]: {
      graphqlUri: 'https://api.possiblefinance.com/graphql',
      graphqlWSUri: 'wss://api.possiblefinance.com/graphql',
      graphqlPublicUri: 'https://api.possiblefinance.com/graphql-public',
    },
  },
  [Applications.IAM]: {
    [EnvironmentType.Local]: {
      graphqlUri: 'http://localhost:4000/graphql',
      graphqlWSUri: 'ws://localhost:4000/graphql',
    },
    [EnvironmentType.LocalJava]: {
      graphqlUri: 'https://localhost:8443/iam/graphql',
      graphqlWSUri: 'wss://localhost:8443/iam/graphql',
    },
    [EnvironmentType.Dev]: {
      graphqlUri: 'https://api.possiblesasquatch.com/iam/graphql',
      graphqlWSUri: 'wss://api.possiblesasquatch.com/iam/graphql',
    },
    [EnvironmentType.Prod]: {
      graphqlUri: 'https://api.possiblefinance.com/iam/graphql',
      graphqlWSUri: 'wss://api.possiblefinance.com/iam/graphql',
    },
  },
}
