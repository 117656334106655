import {gql} from '@apollo/client'
import {OfferFragment} from '../fragments/partner'

export const PartnerGetCurrentOffer = gql`
  ${OfferFragment}
  query GetCurrentOffer {
    getCurrentOffer {
      ...OfferFragment
    }
  }
`

export const PartnerLinkUserWithOffer = gql`
  mutation PartnerLinkUserWithOffer($offerId: UUID!) {
    partnerLinkUserWithOffer(offerId: $offerId)
  }
`

export const PartnerStoreProductAttribution = gql`
  mutation PartnerStoreProductAttribution($offerId: UUID!, $entityId: UUID!) {
    partnerStoreProductAttribution(offerId: $offerId, entityId: $entityId)
  }
`
