import React from 'react'
import {StyleSheet, View, StyleProp, ViewStyle, Image} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {
  LoanPayment,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type LoanProgressBarLoanPaymentFields = Pick<
  LoanPayment,
  'statusCode' | 'originalDate' | 'rescheduledDate' | 'id'
>
type LoanProgressBarIndicatorIconVariants = 'success' | 'warning' | 'failure'

export type LoanProgressBarProps = {
  payments: LoanProgressBarLoanPaymentFields[]
  isChargedOff: boolean
  isInDefault: boolean
  hasFundingIssue: boolean
}

type LoanProgressBarIconProps = {
  latestPaymentDate: LoanProgressBarLoanPaymentFields
  isChargedOff: boolean
  isInDefault: boolean
  indicatorIconOverride?: LoanProgressBarIndicatorIconVariants
}

/**
 * Icon displayed at the front tip of the progress bar.
 */
const LoanProgressBarIcon: React.FC<LoanProgressBarIconProps> = ({
  latestPaymentDate,
  isChargedOff,
  isInDefault,
  indicatorIconOverride,
}: LoanProgressBarIconProps) => {
  let showCompletedIcon = false
  let showMissedIcon = false
  let showDefaultIcon = false
  if (indicatorIconOverride) {
    // if the icon has been overridden we will use that
    switch (indicatorIconOverride) {
      case 'success':
        showCompletedIcon = true
        break
      case 'warning':
        showMissedIcon = true
        break
      case 'failure':
        showDefaultIcon = true
        break
    }
  } else {
    // if the icon was not overidden we choose it depending on latest payment status
    showCompletedIcon = latestPaymentDate.statusCode === LoanPaymentStatusCode.Completed

    showMissedIcon =
      (latestPaymentDate.statusCode === LoanPaymentStatusCode.Failed ||
        latestPaymentDate.statusCode === LoanPaymentStatusCode.Suspended) &&
      !isInDefault &&
      !isChargedOff

    showDefaultIcon =
      (latestPaymentDate.statusCode === LoanPaymentStatusCode.Failed ||
        latestPaymentDate.statusCode === LoanPaymentStatusCode.Suspended) &&
      (isInDefault || isChargedOff)
  }
  return (
    <>
      {
        /* completed status icon */
        showCompletedIcon ? (
          <SvgIcon name={'checkWithCircle'} colorVariant={'success'} isFilled />
        ) : null
      }
      {
        /* missed status icon */
        showMissedIcon ? (
          <View style={styles.progressBarIndicatorMissedIcon}>
            <SvgIcon name={'warning'} colorVariant={'warning'} isFilled />
          </View>
        ) : null
      }
      {
        /* default status icon */
        showDefaultIcon ? <SvgIcon name={'error'} colorVariant={'error'} isFilled /> : null
      }
    </>
  )
}

const getPaymentDatesCopySorted = (
  paymentDates: LoanProgressBarLoanPaymentFields[],
): LoanProgressBarLoanPaymentFields[] => {
  return [...paymentDates.map((thisPaymentDate) => ({...thisPaymentDate}))].sort(
    (a: LoanProgressBarLoanPaymentFields, b: LoanProgressBarLoanPaymentFields) => {
      return (
        new Date(a.rescheduledDate || a.originalDate).getTime() -
        new Date(b.rescheduledDate || b.originalDate).getTime()
      )
    },
  )
}

const getLoanProgressBarPieceStyle = ({
  pieceIndex,
  totalPaymentsCount,
  thisPaymentData,
  isInDefault,
  isChargedOff,
}: {
  pieceIndex: number
  totalPaymentsCount: number
  thisPaymentData: LoanProgressBarLoanPaymentFields
  isInDefault: boolean
  isChargedOff: boolean
}): StyleProp<ViewStyle>[] => {
  const thisPieceStyles: StyleProp<ViewStyle>[] = [styles.progressBarPiece]
  if (pieceIndex === 0) {
    // first piece
    thisPieceStyles.push(styles.progressBarPieceFirst)
  } else if (pieceIndex === totalPaymentsCount - 1) {
    // last piece
    thisPieceStyles.push(styles.progressBarPieceLast)
  }
  if (thisPaymentData.statusCode === LoanPaymentStatusCode.Completed) {
    // completed/successful payment piece
    thisPieceStyles.push(styles.progressBarPieceCompleted)
  } else if (
    (thisPaymentData.statusCode === LoanPaymentStatusCode.Failed ||
      thisPaymentData.statusCode === LoanPaymentStatusCode.Suspended) &&
    !isInDefault &&
    !isChargedOff
  ) {
    // missed payment, but loan is still active
    thisPieceStyles.push(styles.progressBarPieceMissed)
  } else if (
    (thisPaymentData.statusCode === LoanPaymentStatusCode.Failed ||
      thisPaymentData.statusCode === LoanPaymentStatusCode.Suspended) &&
    (isInDefault || isChargedOff)
  ) {
    // missed payment, loan is default/charged off
    thisPieceStyles.push(styles.progressBarPieceDefault)
  }
  return thisPieceStyles
}

/**
 * A progress bar to show progress of loan payments
 * @example <LoanProgressBar payments={[]} isInDefault={false} isChargedOff={false} />
 */
const LoanProgressBar: React.FC<LoanProgressBarProps> = ({
  payments,
  isChargedOff,
  isInDefault,
  hasFundingIssue,
}: LoanProgressBarProps) => {
  const paymentDatesCompleted: LoanProgressBarLoanPaymentFields[] = getPaymentDatesCopySorted(
    payments,
  ).filter(
    (thisPaymentDate: LoanProgressBarLoanPaymentFields) =>
      thisPaymentDate.statusCode === LoanPaymentStatusCode.Completed,
  )

  const paymentDatesUpcoming: LoanProgressBarLoanPaymentFields[] = getPaymentDatesCopySorted(
    payments,
  ).filter((thisPaymentDate: LoanProgressBarLoanPaymentFields) => {
    return (
      thisPaymentDate.statusCode === LoanPaymentStatusCode.Pending ||
      thisPaymentDate.statusCode === LoanPaymentStatusCode.InProgress
    )
  })

  const paymentDatesFailed: LoanProgressBarLoanPaymentFields[] = getPaymentDatesCopySorted(
    payments,
  ).filter((thisPaymentDate: LoanProgressBarLoanPaymentFields) => {
    return (
      thisPaymentDate.statusCode === LoanPaymentStatusCode.Failed ||
      thisPaymentDate.statusCode === LoanPaymentStatusCode.Suspended
    )
  })

  // make a new copy of payment dates but sorted with missed payments first since they always appear at the front of the progress bar
  const paymentDatesSortedWithMissedFirst: LoanProgressBarLoanPaymentFields[] = [
    ...paymentDatesCompleted,
    ...paymentDatesFailed,
    ...paymentDatesUpcoming,
  ]

  // if there is a funding issue (for example, funding failed or no account is linked) we override the
  // icon indicator to show a warning indicator, even if they only have successful payments so far
  let indicatorIconOverride: LoanProgressBarIndicatorIconVariants | undefined = undefined
  if (hasFundingIssue) {
    indicatorIconOverride = 'warning'
  }
  let hasShownIndicatorIcon = false
  const hasIndicatorOverrideWithNoProcessedPayments =
    indicatorIconOverride && paymentDatesCompleted.length === 0 && paymentDatesFailed.length === 0
  return (
    <View style={styles.progressBar}>
      {paymentDatesSortedWithMissedFirst.map(
        (thisPaymentDate: LoanProgressBarLoanPaymentFields, index: number) => {
          // apply appropriate styles and return this progress bar piece
          const thisPieceStyles: StyleProp<ViewStyle>[] = getLoanProgressBarPieceStyle({
            pieceIndex: index,
            totalPaymentsCount: payments.length,
            thisPaymentData: thisPaymentDate,
            isChargedOff,
            isInDefault,
          })
          // determine if this payment has already happened or not to apply proper style
          if (thisPaymentDate.statusCode !== LoanPaymentStatusCode.Pending) {
            thisPieceStyles.push(styles.progressBarPieceAlreadyHappened)
          } else {
            thisPieceStyles.push(styles.progressBarPieceUpcoming)
          }
          // determine if this is the latest payment, which means the progress bar
          // should display the icon at the tip (right side) of the progress bar piece
          let isLatestPayment = false
          const nextPaymentDate = paymentDatesSortedWithMissedFirst[index + 1]
          if (
            thisPaymentDate.statusCode !== LoanPaymentStatusCode.Pending &&
            thisPaymentDate.statusCode !== LoanPaymentStatusCode.InProgress &&
            (!nextPaymentDate ||
              nextPaymentDate.statusCode === LoanPaymentStatusCode.Pending ||
              nextPaymentDate.statusCode === LoanPaymentStatusCode.InProgress)
          ) {
            isLatestPayment = true
          }
          const showIndicatorIcon =
            !hasShownIndicatorIcon &&
            (isLatestPayment || hasIndicatorOverrideWithNoProcessedPayments)
          if (showIndicatorIcon) {
            hasShownIndicatorIcon = true
          }
          // by default the icon is displayed at the tip of a progress bar piece (the right side).
          // if the icon indicator was overridden AND there are no payments processed yet, we will show
          // the icon at the left side of the first piece
          const progressBarIndicatorStyles = hasIndicatorOverrideWithNoProcessedPayments
            ? [styles.progressBarIndicator, styles.progressBarIndicatorOnLeftSide]
            : [styles.progressBarIndicator]
          return (
            <View
              style={[
                thisPieceStyles,
                showIndicatorIcon ? styles.progressBarPieceBeforeIndicator : {},
              ]}
              key={`${thisPaymentDate.id}-${thisPaymentDate.statusCode}-${
                thisPaymentDate.originalDate
              }-${thisPaymentDate.rescheduledDate || ''}`}
            >
              {showIndicatorIcon ? (
                <View style={progressBarIndicatorStyles}>
                  <LoanProgressBarIcon
                    latestPaymentDate={thisPaymentDate}
                    isInDefault={isInDefault}
                    isChargedOff={isChargedOff}
                    indicatorIconOverride={indicatorIconOverride}
                  />
                </View>
              ) : null}
            </View>
          )
        },
      )}
      {/* to show the striped background on pieces of the progress bar that are for payments that are in progress/processing
      we add the image background across the entire progress bar and then pieces that are in progress have a transparent
      background to make the striped background image visible */}
      <View style={styles.progressBarInProgressBackgroundContainer}>
        <Image
          style={styles.progressBarInProgressBackgroundImage}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          source={require('./assets/ProgressBarPieceProcessingBg.png')}
          resizeMode="cover"
          resizeMethod="auto"
        />
      </View>
    </View>
  )
}

export default LoanProgressBar

const styles = StyleSheet.create({
  progressBar: {
    borderColor: NamedColors.BLACK,
    borderRadius: 16,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    height: 16,
  },
  progressBarInProgressBackgroundContainer: {
    borderRadius: 16,
    height: 14,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  progressBarInProgressBackgroundImage: {
    backgroundColor: NamedColors.HONEYDEW,
    borderRadius: 16,
    height: 16,
    top: 0,
    width: '100%',
  },
  progressBarIndicator: {
    alignItems: 'center',
    alignSelf: 'flex-end',
    backgroundColor: NamedColors.WHITE,
    borderColor: NamedColors.BLACK,
    borderRadius: 16,
    borderWidth: 1,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    right: -12, // move half of width so it's on the edge of this progress bar piece
    width: 24,
  },
  progressBarIndicatorMissedIcon: {
    top: -1.5,
    transform: [
      {
        scale: 0.87,
      },
    ],
  },
  progressBarIndicatorOnLeftSide: {
    alignSelf: 'flex-start',
    left: -1,
    right: 0,
  },
  progressBarPiece: {
    borderRightWidth: 1,
    flex: 1,
    justifyContent: 'center',
    zIndex: 2,
  },
  progressBarPieceAlreadyHappened: {
    borderColor: NamedColors.BLACK,
  },
  progressBarPieceBeforeIndicator: {
    zIndex: 3,
  },
  progressBarPieceCompleted: {
    backgroundColor: NamedColors.LIME,
  },
  progressBarPieceDefault: {
    backgroundColor: NamedColors.PRODUCT_VERMILLION,
  },
  progressBarPieceFirst: {
    borderBottomLeftRadius: 16,
    borderTopLeftRadius: 16,
  },
  progressBarPieceLast: {
    borderBottomRightRadius: 16,
    borderRightWidth: 0,
    borderTopRightRadius: 16,
  },
  progressBarPieceMissed: {
    backgroundColor: NamedColors.GOLD,
  },
  progressBarPieceUpcoming: {
    backgroundColor: NamedColors.WHITE,
    borderColor: NamedColors.ASH,
  },
})
