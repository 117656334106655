import {
  BankExchangePlaidPublicTokenDocument,
  GetBankPlaidLinkTokenDocument,
} from '../../types/types.mobile.generated'

import {useLazyQueryFactory, useMutationFactory} from '../../utils'

export const useBankLinkPlaidQuery = useLazyQueryFactory(
  GetBankPlaidLinkTokenDocument,
  (from) => from.getBankPlaidLinkToken,
)

export const useExchangeBankLinkTokenPlaidMutation = useMutationFactory(
  BankExchangePlaidPublicTokenDocument,
)
