import {createSlice, createAction} from '@reduxjs/toolkit'

import {CardPaymentMethodSliceType} from 'src/lib/onboarding/types'

export type OnboardingState = {
  emailCodeSent: boolean
  cardPaymentMethod: CardPaymentMethodSliceType | undefined
  sentToWeb: boolean
}

const initialState: OnboardingState = {
  emailCodeSent: false,
  cardPaymentMethod: undefined,
  sentToWeb: false,
}

export const onboardingEmailCodeSent = createAction<boolean | undefined>('updateEmailEdited')
export const onboardingSetCardPaymentMethod = createAction<CardPaymentMethodSliceType | undefined>(
  'setCardPaymentMethod',
)
export const onboardingSentToWeb = createAction<boolean>('sentToWeb')

export const OnboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onboardingEmailCodeSent, (state, action) => {
      state.emailCodeSent = action.payload ?? true
    })
    builder.addCase(onboardingSetCardPaymentMethod, (state, action) => {
      state.cardPaymentMethod = action.payload
    })
    builder.addCase(onboardingSentToWeb, (state, action) => {
      state.sentToWeb = action.payload
    })
  },
})

export const {actions} = OnboardingSlice

export default OnboardingSlice.reducer
