import React from 'react'
import {StyleSheet, TouchableOpacity} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {smallGap} from 'src/designSystem/layout'
import {NamedColors} from 'src/designSystem/colors'
import {PossibleLogo} from 'src/designSystem/components/atoms/PossibleLogo/PossibleLogo'
import Lightning from 'src/assets/illustrations/Lightning.svg'
import ChevronRight from 'src/assets/illustrations/ChevronRight.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'

type InstantSignUpAlertProps = {
  onPress?: () => void
}
const InstantSignupAlert = (props: InstantSignUpAlertProps): JSX.Element => {
  const {t} = useTranslation(['PhoneNumberEntry'])
  const {onPress: handleOnPress} = props
  return (
    <TouchableOpacity
      onPress={handleOnPress}
      style={styles.container}
      testID={'Opt-Back-Into-Plaid-Layers'}
    >
      <Box padding={'small'} marginRight={'small'} background="white" radius={'medium'}>
        <PossibleLogo size={'large'} align={'flex-end'} />
      </Box>
      <PFText variant={'p'} color={'textDisabled'} textProps={{style: styles.content}}>
        <Trans
          i18nKey={'PhoneNumberEntry:InstantSignUpAlert'}
          t={t}
          components={{
            bold: <PFText variant={'p_semibold'} color={'black'} />,
            icon: <PFSvg SvgUri={Lightning} svgProps={{width: 18, height: 18}} />,
          }}
        />
      </PFText>
      <PFSvg SvgUri={ChevronRight} svgProps={{width: 24, height: 24}} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: smallGap,
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: 12,
  },
  content: {
    flex: 1,
  },
})

export {InstantSignupAlert}
