import {
  GetUserGenerateTemporaryTokenDocument,
  ModifyPasswordInput,
  ModifyEmailInput,
  UserLogoutMutationDocument,
  UserModifyPasswordMutationDocument,
  UserModifyEmailMutationDocument,
  UserVerifyEmailSendCodeInput,
  UserVerifyEmailSendCodeMutationDocument,
} from '../types/types.mobile.generated'
import {ApplyMutation} from '../utils/operations'

export const getUserGenerateTemporaryToken = async () => {
  return (await ApplyMutation(GetUserGenerateTemporaryTokenDocument))?.userGenerateTemporaryToken!
}

export const UserLogoutMutation = async (): Promise<boolean | undefined> => {
  return (await ApplyMutation(UserLogoutMutationDocument))?.userLogout ?? undefined
}
export const UserVerifyEmailSendCodeMutation = async (
  input: UserVerifyEmailSendCodeInput,
): Promise<boolean | undefined> => {
  return (
    (await ApplyMutation(UserVerifyEmailSendCodeMutationDocument, {input}))
      ?.userVerifyEmailSendCode ?? undefined
  )
}

export const UserModifyPasswordMutation = async (
  input: ModifyPasswordInput,
): Promise<boolean | undefined> => {
  return (
    (await ApplyMutation(UserModifyPasswordMutationDocument, {input}))?.userModifyPassword ??
    undefined
  )
}

export const UserModifyEmailMutation = async (
  input: ModifyEmailInput,
): Promise<boolean | undefined> => {
  return (
    (await ApplyMutation(UserModifyEmailMutationDocument, {input}))?.userModifyEmail ?? undefined
  )
}
