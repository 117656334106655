export function appIsBackground() {
  //no op
}

export function appIsForeground() {
  //no op
}

export function triggerUnloadEvent() {
  //no op
}

export default function initWebToNativeCompatibility() {
  //no op
}
