import React, {FC} from 'react'

import {CardApplicationFinancesGQLContainer} from 'src/products/card/Application/CardApplicationFinances/CardApplicationFinancesGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export const CardApplicationFinancesWorkflowContainer: FC<WorkflowPreReqFulfillScreenProps> = ({
  onPreReqFulfilled,
}) => {
  const handleOnComplete = async (): Promise<void> => {
    return onPreReqFulfilled()
  }

  return <CardApplicationFinancesGQLContainer onComplete={handleOnComplete} />
}
