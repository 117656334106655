import * as Types from '../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CollectDebitCardNumbersForLoanQueryVariables = Types.Exact<{[key: string]: never}>

export type CollectDebitCardNumbersForLoanQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        preferredFundingSource?: boolean | null
      }> | null
    }
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename: 'ActiveLoanAggregateStatus'}
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
  }
}

export type CollectDebitCardNumbersForLoanSubmitMutationVariables = Types.Exact<{
  input: Types.DebitPaymentMethodInput
}>

export type CollectDebitCardNumbersForLoanSubmitMutation = {
  __typename?: 'Mutation'
  bankAddDebitPaymentMethod: {
    __typename?: 'AddPaymentMethodResponse'
    result: {__typename?: 'AddPaymentMethodResult'; type: Types.AddPaymentMethodResultType}
    paymentMethod?:
      | {__typename?: 'AchPaymentMethod'; id: string}
      | {__typename?: 'CheckPaymentMethod'; id: string}
      | {__typename?: 'DebitCardPaymentMethod'; id: string}
      | null
  }
}

export type CollectDebitCardNumbersForLoanSaveDisbursementMethodMutationVariables = Types.Exact<{
  input: Types.SetLoanDisbursementMethodInput
}>

export type CollectDebitCardNumbersForLoanSaveDisbursementMethodMutation = {
  __typename?: 'Mutation'
  loanSetDisbursementMethod: {__typename?: 'SetLoanDisbursementMethodResponse'; success: boolean}
}

export type CollectDebitCardNumbersForLoanConfirmCardMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
}>

export type CollectDebitCardNumbersForLoanConfirmCardMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export const CollectDebitCardNumbersForLoanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CollectDebitCardNumbersForLoan'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollectDebitCardNumbersForLoanQuery,
  CollectDebitCardNumbersForLoanQueryVariables
>
export const CollectDebitCardNumbersForLoanSubmitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CollectDebitCardNumbersForLoanSubmit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitPaymentMethodInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAddDebitPaymentMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'result'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'type'}}],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethod'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollectDebitCardNumbersForLoanSubmitMutation,
  CollectDebitCardNumbersForLoanSubmitMutationVariables
>
export const CollectDebitCardNumbersForLoanSaveDisbursementMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CollectDebitCardNumbersForLoanSaveDisbursementMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'SetLoanDisbursementMethodInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDisbursementMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollectDebitCardNumbersForLoanSaveDisbursementMethodMutation,
  CollectDebitCardNumbersForLoanSaveDisbursementMethodMutationVariables
>
export const CollectDebitCardNumbersForLoanConfirmCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CollectDebitCardNumbersForLoanConfirmCard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'confirmDebitCard'},
                      value: {kind: 'BooleanValue', value: true},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollectDebitCardNumbersForLoanConfirmCardMutation,
  CollectDebitCardNumbersForLoanConfirmCardMutationVariables
>
