import snakeCase from 'lodash/snakeCase'

import {useMemo} from 'react'

import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'
import {useCards} from 'src/products/card/hooks/useCards'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

// These values are specific to our analytics string patterns
export enum PaymentMode {
  Autopay = 'autopay',
  AutopayWithPot = 'autopay_with_pot',
  ManualPay = 'manual_pay',
  ManualPayWithPot = 'manual_pay_with_pot',
}

type UseCardAdhocPaymentAnalytics = {
  isLoading: boolean
  eventArgs: {
    overdueStatus: string | undefined
    paymentMode: PaymentMode
  }
}

const useCardAdhocPaymentAnalytics = (): UseCardAdhocPaymentAnalytics => {
  const {accountPOTStatus, accountStatus, autopayEnabled, loading: isLoading} = useCards()
  const {selectedData: minPayStatus} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active?.status,
  )
  const autopayModel =
    minPayStatus?.__typename === 'ActiveCardAccountStatus' ||
    minPayStatus?.__typename === 'DeactivatedCardAccountStatus'
      ? minPayStatus?.autopayModel
      : undefined

  const AutopayWithPot =
    accountPOTStatus !== CardAccountPOTStatus.NotPOT && autopayEnabled
      ? PaymentMode.AutopayWithPot
      : undefined
  const Autopay =
    accountPOTStatus === CardAccountPOTStatus.NotPOT && autopayEnabled
      ? PaymentMode.Autopay
      : undefined
  const ManualPayWithPot =
    accountPOTStatus !== CardAccountPOTStatus.NotPOT && !autopayEnabled
      ? PaymentMode.ManualPay
      : undefined

  const overdueStatus =
    accountStatus !== undefined ? CardAccountDashboardStatus[accountStatus] : undefined

  /* Memoize `analyticsArgs` so they can safely be used in dependency arrays like the `focusEffect`
   * in `usePageViewedAnalytics`
   */
  return useMemo<UseCardAdhocPaymentAnalytics>(
    () => ({
      isLoading,
      eventArgs: {
        overdueStatus: overdueStatus ? snakeCase(overdueStatus) : undefined,
        paymentMode: AutopayWithPot ?? Autopay ?? ManualPayWithPot ?? PaymentMode.ManualPay,
        adhoc_payment_type: autopayModel,
      },
    }),
    [Autopay, AutopayWithPot, ManualPayWithPot, isLoading, overdueStatus],
  )
}

export {useCardAdhocPaymentAnalytics}
