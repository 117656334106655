import {useMutationFactory} from '@possible/cassandra/src/utils'
import {AccountSelectionAddAchPaymentMethodForLinkedAccountDocument} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/mutations/AccountSelectionAddAchPaymentMethodForLinkedAccount/AccountSelectionAddAchPaymentMethodForLinkedAccount.gqls'

export const useAccountSelectionAddAchPaymentMethodForLinkedAccount =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutationFactory(AccountSelectionAddAchPaymentMethodForLinkedAccountDocument)

/**
 * The AchPaymentMethod from the result of useAccountSelectionAddAchPaymentMethodForLinkedAccount()
 */
export type UseAccountSelectionAddAchResultPaymentMethod = NonNullable<
  NonNullable<
    Awaited<
      ReturnType<ReturnType<typeof useAccountSelectionAddAchPaymentMethodForLinkedAccount>[0]>
    >['data']
  >['bankAddAchPaymentMethodForLinkedAccount']['achPaymentMethod']
> & {
  __typename: 'AchPaymentMethod'
}
