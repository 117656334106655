import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  StateSpecificAgreement,
  AgreementType,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {
  AcceptLoanAndStateAgreementsBaseTemplate,
  AcceptLoanAndStateAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsBaseTemplate'

export type AcceptLoanAndStateAgreementsTXTemplateProps = Omit<
  AcceptLoanAndStateAgreementsBaseTemplateProps,
  // these are specified by this FL template so they don't propagate to the parent
  'stateSpecificAgreements' | 'loanAgreementTestID'
>

export const AcceptLoanAndStateAgreementsTxTestIds = {
  firstCreditServiceDisclosure: 'TEXAS_CREDIT_SERVICES_DISCLOSURE_STATEMENT',
  secondCreditServiceAgreement: 'TEXAS_CREDIT_SERVICES_AGREEMENT',
  thirdArbitrationAgreement: 'TEXAS_ARBITRATION_AGREEMENT',
  fourthLoanAgreement: 'TEXAS_LOAN_AGREEMENT',
}
const AcceptLoanAndStateAgreementsTXTemplate: React.FC<
  AcceptLoanAndStateAgreementsTXTemplateProps
> = (props) => {
  const {
    isLoading,
    isError,
    isSubmitting,
    loanAmountBorrowed,
    onOpenAgreementDocument: handleOnOpenAgreementDocument,
    onAcceptAndContinue: handleOnAcceptAndContinue,
  } = props
  const {t} = useTranslation('LoanApproved')
  const stateSpecificAgreements: StateSpecificAgreement[] = [
    {
      title: t('CreditServicesDisclosureStatement'),
      documentTypeToDownload: LoanDocumentType.CreditServiceDisclosure,
      agreementType: AgreementType.CreditServicesDisclosureStatement,
      id: AcceptLoanAndStateAgreementsTxTestIds.firstCreditServiceDisclosure,
    },
    {
      title: t('CreditServicesAgreement'),
      documentTypeToDownload: LoanDocumentType.CreditService,
      agreementType: AgreementType.CreditServicesAgreement,
      id: AcceptLoanAndStateAgreementsTxTestIds.secondCreditServiceAgreement,
    },
    {
      title: t('ArbitrationAgreement'),
      documentTypeToDownload: LoanDocumentType.LoanAgreement,
      agreementType: AgreementType.ArbitrationAgreement,
      id: AcceptLoanAndStateAgreementsTxTestIds.thirdArbitrationAgreement,
    },
  ]
  const loanAgreementTestID = AcceptLoanAndStateAgreementsTxTestIds.fourthLoanAgreement
  return (
    <AcceptLoanAndStateAgreementsBaseTemplate
      testID="AcceptLoanAndStateAgreementsTX"
      stateSpecificAgreements={stateSpecificAgreements}
      loanAgreementTestID={loanAgreementTestID}
      loanAmountBorrowed={loanAmountBorrowed}
      isLoading={isLoading}
      isError={isError}
      isSubmitting={isSubmitting}
      onOpenAgreementDocument={handleOnOpenAgreementDocument}
      onAcceptAndContinue={handleOnAcceptAndContinue}
    ></AcceptLoanAndStateAgreementsBaseTemplate>
  )
}

export {AcceptLoanAndStateAgreementsTXTemplate}
