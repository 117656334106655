import {CardAccountDashboardStatus} from 'src/products/card/types'

const isAccountDelinquent = (status: CardAccountDashboardStatus | undefined): boolean => {
  switch (status) {
    case CardAccountDashboardStatus.Delinquent:
    case CardAccountDashboardStatus.DelinquencyReported:
    case CardAccountDashboardStatus.DelinquentNSF:
    case CardAccountDashboardStatus.DelinquencyReportedNSF:
      return true
    default:
      return false
  }
}

export {isAccountDelinquent}
