export const DEEP_LINK_EVENT = 'DEEP_LINK_EVENT'
export const DEEP_LINK_PARAM = '$deeplink_path' //this is a branch reserved key for a deep link path
export const CLICK_TIMESTAMP = '+click_timestamp' //this is auto provided by the branch sdk indicating the last the link was pressed
export const ID = '~id'
export const EVENT_ADD_TO_CART = 'ADD_TO_CART'
export const EVENT_ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const EVENT_VIEW_CART = 'VIEW_CART'
export const EVENT_INITIATE_PURCHASE = 'INITIATE_PURCHASE'
export const EVENT_ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO'
export const EVENT_PURCHASE = 'PURCHASE'
export const EVENT_CARD_APPROVED = 'CARD_APPROVED'
export const EVENT_CARD_ACTIVATED = 'CARD_ACTIVATED'
export const EVENT_SPEND_CREDITS = 'SPEND_CREDITS'
export const EVENT_SEARCH = 'SEARCH'
export const EVENT_VIEW_ITEM = 'VIEW_ITEM'
export const EVENT_VIEW_ITEMS = 'VIEW_ITEMS'
export const EVENT_RATE = 'RATE'
export const EVENT_SHARE = 'SHARE'
export const EVENT_COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION'
export const EVENT_COMPLETE_TUTORIAL = 'COMPLETE_TUTORIAL'
export const EVENT_ACHIEVE_LEVEL = 'ACHIEVE_LEVEL'
export const EVENT_UNLOCK_ACHIEVEMENT = 'UNLOCK_ACHIEVEMENT'
export const EVENT_PHONE_VERIFY = 'PHONE_VERIFY'
export const EVENT_CARD_ACTIVATE = 'CARD_ACTIVATE'
