/* eslint-disable import/no-default-export */
/* eslint-disable no-console */

import {triggerUnloadEvent} from 'src/native/webToNativeCompatibility'
import {logLevel as appLogLevel, LogLevels} from 'src/config'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'

const shouldLogMessage = (logLevel: LogLevels): boolean => {
  return logLevel >= appLogLevel
}

export const argsToMessage = (args: unknown[]): string => {
  let message = ''

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (arg instanceof Error) {
      message += `${arg.name}: ${arg.message}`
    } else {
      switch (typeof arg) {
        case 'undefined':
          message += 'undefined'
          break
        case 'object':
          message += JSON.stringify(arg)
          break
        case 'number':
        case 'string':
        case 'boolean':
        case 'bigint':
        case 'symbol':
          message += arg.toString()
          break
        case 'function':
          message += '[Function]'
          break
      }
    }

    if (i < args.length - 1) {
      message += ', '
    }
  }

  return message
}

/*
 Unified Log Entry point. Logs are forwarded to the console when in dev mode.
 Logs are also sent to Datadog.
 For Datadog the filtered log level is set in Analytics/index.ts. See DatadogLogs.createLogger.
 */
export default class Log {
  static warnOnceMap = new Set()

  static dir = (args: unknown[]): void => {
    if (shouldLogMessage(LogLevels.Dir) && __DEV__) {
      console.dir(args)
    }
  }

  static log = (...args: unknown[]): void => {
    if (shouldLogMessage(LogLevels.Log) && __DEV__) {
      console.log(...args)
    }
  }

  static warnOnce = (...args: unknown[]): void => {
    if (__DEV__) {
      console.warn(...args)
    }

    const message = argsToMessage(args)
    if (!__DEV__ && Log.warnOnceMap.has(message)) {
      return
    }

    Log.warnOnceMap.add(message)
    EventStreamSingleton.emit({
      type: 'log',
      severity: 'warn',
      data: message,
      context: {
        args,
      },
    })
  }

  /** Similar to log(), except logs to Datadog */
  static info = (...args: unknown[]): void => {
    if (shouldLogMessage(LogLevels.Info) && __DEV__) {
      // Only in __DEV__ (local) for now until cleaned up
      console.info(...args)

      const message = argsToMessage(args)

      EventStreamSingleton.emit({
        type: 'log',
        severity: 'info',
        data: message,
      })
    }
  }

  static warn = (...args: unknown[]): void => {
    if (shouldLogMessage(LogLevels.Warn)) {
      if (__DEV__) {
        console.warn(...args)
      }

      const message = argsToMessage(args)
      EventStreamSingleton.emit({
        type: 'log',
        severity: 'warn',
        data: message,
        context: {
          args,
        },
      })
    }
  }

  static error = (error: unknown, msg = ''): void => {
    if (__DEV__) {
      console.error(msg, error)
    }

    let data: string = `${msg} `
    let context: Record<string, unknown> | undefined = undefined
    if (error instanceof Error) {
      data += `${error.message}`
      context = {
        error,
        stack: error.stack,
      }
    } else {
      if (typeof error === 'string') {
        data += `${error}`
      }

      context = {
        error,
        stack: new Error().stack,
      }
    }

    EventStreamSingleton.emit({
      type: 'log',
      severity: 'error',
      data,
      context,
    })
    triggerUnloadEvent() //causes Datadog to flush logs.
  }
}
