import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'

export type VerificationStackNavigationPropType =
  | StackNavigationProp<MainStackParamList, 'PasswordRecoveryViaPhoneNumber'>
  | StackNavigationProp<MainStackParamList, 'EmailVerification'>
  | StackNavigationProp<MainStackParamList, 'OTPVerification'>
  | StackNavigationProp<MainStackParamList, 'OTPIdentityVerification'>

export const PHONE = 'PHONE'
export const EMAIL = 'EMAIL'
export type VerificationMethod = typeof PHONE | typeof EMAIL
