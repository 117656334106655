import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CancelSurveyQueryVariables = Types.Exact<{[key: string]: never}>

export type CancelSurveyQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
  }
}

export const CancelSurveyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CancelSurvey'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelSurveyQuery, CancelSurveyQueryVariables>
