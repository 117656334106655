import React from 'react'
import {useTranslation} from 'react-i18next'

import {Consumer} from '@possible/cassandra'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {userIdSelector} from 'src/lib/user/selector'
import {uploadAndCreateDocument} from 'src/lib/utils/files'
import CameraPage, {
  CameraPageProps,
  OnCameraCompleteCallback,
} from 'src/products/general/components/organisms/CameraPage/CameraPage'
import {CameraAnalyticEvents} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import {usePfSelector} from 'src/store/utils'

type SelfieCameraPageProps = Partial<CameraPageProps> & {
  onComplete: CameraPageProps['onComplete']
}

const SelfieCameraPage: React.FC<SelfieCameraPageProps> = (props) => {
  const {onComplete, ...rest} = props

  const {t} = useTranslation(['CameraPage', 'Common'])

  const userId = usePfSelector(userIdSelector)

  const handleSubmission: OnCameraCompleteCallback = async (image, orientation): Promise<void> => {
    try {
      const response = await uploadAndCreateDocument(
        image,
        Consumer.types.UserDocumentType.Identity,
        Consumer.types.UserDocumentSubType.Face,
        userId,
      )

      if (response) {
        await onComplete(image, orientation)
      }
    } catch (e) {
      Log.error({e}, 'Unable to upload or create document')
      ShowException(e)
    }
  }

  const events: CameraAnalyticEvents = {
    takePhotoViewedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.take_a_selfie_screen_viewed,
    },
    photoSubmittedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.take_a_selfie_completed,
    },
    ...rest.events,
  }

  return (
    <CameraPage
      orientations={['portrait']}
      position="front"
      captureTitle={t('SelfieCapturePhaseTitle')}
      captureMessage={t('SelfieCapturePhaseMessage')}
      confirmTitle={t('SelfieConfirmPhaseTitle')}
      confirmMessage={t('SelfieConfirmPhaseMessage')}
      {...rest}
      onComplete={handleSubmission}
      events={events}
    />
  )
}

export default SelfieCameraPage
