import {PreReqType} from 'src/workflows/types'
import {PreReqSortFunctionFactory} from 'src/workflows/order/utils'

export const BaselineMinPreReqsOrder: PreReqType[] = [
  'PHONE',
  'PHONE_VERIFICATION',
  'FULL_NAME',
  'DOB',
  'ADDRESS_HOME',
]

/**
 * This array and the baselinePreReqSortFunction are used to
 * determine the baseline (meaning no experiments or other mutations)
 * order that pre-reqs should be fulfilled in.
 */
export const BaselinePreReqOrder: PreReqType[] = [
  // user minimum pre-reqs (minimum to get any eligible or ineligible offers)
  ...BaselineMinPreReqsOrder,

  'SSN',
  'PII_CONFIRM',

  'CARD_APPLICATION_FINANCES', // Cards
  'DEPOSITORY_ACCOUNTS', // Both
  'PRIMARY_ACCOUNT',

  // Loans
  'AMOUNT_SELECTION',
  'CONFIRM_BANK_REAPPLICATION_NO_ACCOUNTS',
  'CONFIRM_BANK_REAPPLICATION_RELINK_ACCOUNT',
  'CONFIRM_BANK_REAPPLICATION_VALID_ACCOUNT',
  'MARKETING_SURVEY',

  // Both
  'EMAIL_VERIFICATION',

  // Loans
  'LOAN_APPLICATION_SUBMISSION',

  // loans activation; this probably isn't the correct order
  'ACCEPT_PAYMENTS',
  'SELECT_DISBURSEMENT_METHOD',
  'CONFIRM_DEBIT_CARD',
  'COLLECT_DEBIT_CARD_NUMBERS',
  'ACCEPT_TILA_DISCLOSURE',
  'ACCEPT_HOW_AUTOPAY_WORKS',
  'ACCEPT_LOAN_AGREEMENT',
  'ACCEPT_ARBITRATION_AGREEMENT_FL',
  'ACCEPT_ARBITRATION_AGREEMENT_TX',
  'ACCEPT_AUTOPAY_AGREEMENT_EXTENDED',
  'ACCEPT_AUTOPAY_AGREEMENT_STANDARD',
  'ACCEPT_CREDIT_SERVICES_AGREEMENT_TX',
  'ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX',
  'ACCEPT_STATE_DISCLOSURE_LA',
  'ACCEPT_STATE_DISCLOSURE_OH',
  'ACCEPT_STATUTORY_NOTICE_FL',
  'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',
  'RELINK_PREFERRED_BANK_ACCOUNT',
  'CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS',
  'COMPLETE_LOAN_REASON_SURVEY',
  'FINAL_ACCEPT_STANDARD',

  // Cards
  'CARD_APPLICATION_SUBMISSION',
]

/**
 * This function and the BaselinePreReqOrder are used to
 * determine the baseline (meaning no experiments or other mutations)
 * order that pre-reqs should be fulfilled in.
 */
export const BaselinePreReqSortFunction = PreReqSortFunctionFactory(BaselinePreReqOrder)
