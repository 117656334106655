let DisablePlaidLayer: string | undefined = undefined
const GetDisablePlaidLayer = (): string | undefined => {
  return DisablePlaidLayer
}

const DisablePlaidPlayerProcessor = (value: string | undefined): void => {
  DisablePlaidLayer = value
}

export {GetDisablePlaidLayer, DisablePlaidPlayerProcessor}
