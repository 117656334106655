import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type ApprovedApplicationsQueryVariables = Types.Exact<{[key: string]: never}>

export type ApprovedApplicationsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    products: {
      __typename?: 'ProductsCollection'
      applications: {
        __typename?: 'ProductApplicationCollection'
        all: Array<{
          __typename: 'ProductApplication'
          offerId: string
          product:
            | {
                __typename: 'CardAccount'
                id: string
                status:
                  | {__typename: 'ActiveCardAccountStatus'}
                  | {__typename: 'ApprovedCardAccountStatus'}
                  | {__typename: 'CancelledCardAccountStatus'}
                  | {__typename: 'DeactivatedCardAccountStatus'}
                  | {__typename: 'ExpiredCardAccountStatus'}
                  | {__typename: 'PendingCardAccountStatus'}
                  | {__typename: 'RejectedCardAccountStatus'}
              }
            | {
                __typename: 'Loan'
                id: string
                state: Types.StateCodes
                status:
                  | {__typename: 'ActiveLoanStatus'}
                  | {__typename: 'ApprovedLoanStatus'}
                  | {__typename: 'CancelledLoanStatus'}
                  | {__typename: 'ChargedOffLoanStatus'}
                  | {__typename: 'ExpiredLoanStatus'}
                  | {__typename: 'PaidOffLoanStatus'}
                  | {__typename: 'PendingLoanStatus'}
                  | {__typename: 'RejectedLoanStatus'}
                  | {__typename: 'ReplacedLoanStatus'}
                aggregateStatus:
                  | {__typename: 'ActiveLoanAggregateStatus'}
                  | {
                      __typename: 'ApprovedLoanAggregateStatus'
                      activationPreReqs: {
                        __typename: 'LoanActivationPreRequisiteCollection'
                        met: Array<Types.LoanActivationPreRequisiteType>
                        unmet: Array<Types.LoanActivationPreRequisiteType>
                      }
                    }
                  | {__typename: 'ClosedLoanAggregateStatus'}
                  | {__typename: 'DecisioningLoanAggregateStatus'}
                  | {__typename: 'UndefinedLoanAggregateStatus'}
                loanTypeInfo?: {__typename?: 'LoanTypeInfo'; variant: Types.LoanTypeVariant} | null
              }
        }>
      }
    }
  }
}

export const ApprovedApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ApprovedApplications'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'applications'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'all'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'offerId'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'product'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'Loan'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: '__typename'},
                                                    },
                                                  ],
                                                },
                                              },
                                              {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'aggregateStatus'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: '__typename'},
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'ApprovedLoanAggregateStatus',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'activationPreReqs',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: '__typename',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'met',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'unmet',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'loanTypeInfo'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'variant'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'CardAccount'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: '__typename'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>
