import React, {FC, useEffect, useMemo, useState} from 'react'
import {StyleSheet, TouchableHighlight, Pressable, ViewStyle} from 'react-native'

import {disabledIconButtonColor} from 'src/designSystem/semanticColors'
import {iconButtonPadding} from 'src/designSystem/guide'
import withDoubleClickPrevention from 'src/designSystem/components/atoms/WithDoubleClickPrevention/WithDoubleClickPrevention'
import {Color} from 'src/designSystem/types'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconSizeMap} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {NamedColors} from 'src/designSystem/colors'
import {getTestID} from 'src/lib/utils/tests.utils'

export type SvgIconButtonProps = SvgIconProps & {
  backgroundColor?: Color
  disabled?: boolean
  onPress: () => void | Promise<void>
  styleProp?: ViewStyle
  underlayColor?: Color
  accessibilityLabel?: string
}

const SvgIconButton: FC<SvgIconButtonProps> = ({
  name,
  colorVariant,
  size,
  isFilled,
  disabled,
  onPress,
  backgroundColor,
  styleProp,
  underlayColor,
  accessibilityLabel,
  customColor,
}) => {
  const [iconColorVariant, setIconColorVariant] =
    useState<SvgIconProps['colorVariant']>(colorVariant)

  useEffect(() => {
    if (disabled) {
      setIconColorVariant('inactive')
    }
  }, [disabled])

  // if color changes as a prop, it's not reflected if passed directly into useState
  useEffect(() => {
    setIconColorVariant(colorVariant)
  }, [colorVariant])

  const handlePress = (): void => {
    if (disabled) return
    void onPress?.()
  }

  const handleOnIconPress = (): void => {
    setIconColorVariant('inactive')
  }

  const handleOnIconRelease = (): void => {
    setIconColorVariant(colorVariant)
  }

  const iconAccessibilityLabel = accessibilityLabel ?? `${name} button`

  const backgroundSize = size ? SvgIconSizeMap[size] * 2 : SvgIconSizeMap['small'] * 2
  const containerStyle = backgroundColor
    ? {
        backgroundColor: disabled ? disabledIconButtonColor : backgroundColor,
        height: backgroundSize,
        width: backgroundSize,
      }
    : undefined

  const icon = useMemo(() => {
    return (
      <SvgIcon
        name={name}
        colorVariant={iconColorVariant}
        customColor={customColor}
        size={size}
        isFilled={isFilled}
      />
    )
  }, [customColor, iconColorVariant, isFilled, name, size])

  if (backgroundColor) {
    return (
      <TouchableHighlight
        disabled={disabled}
        onPress={handlePress}
        style={[styles.baseContainerStyle, containerStyle, styleProp]}
        underlayColor={underlayColor ?? NamedColors.SILVER}
        testID={getTestID(iconAccessibilityLabel, '-Id')}
      >
        {icon}
      </TouchableHighlight>
    )
  }

  return (
    <Pressable
      hitSlop={iconButtonPadding}
      onPressIn={handleOnIconPress}
      onPressOut={handleOnIconRelease}
      onLongPress={handleOnIconRelease}
      onPress={handlePress}
      style={styleProp}
      accessibilityLabel={iconAccessibilityLabel}
      testID={getTestID(iconAccessibilityLabel, '-Id')}
    >
      {icon}
    </Pressable>
  )
}

const svgIconButtonComponent = withDoubleClickPrevention(SvgIconButton)

export {svgIconButtonComponent as SvgIconButton}

const styles = StyleSheet.create({
  baseContainerStyle: {
    alignItems: 'center',
    borderRadius: 50,
    justifyContent: 'center',
  },
})
