import {useCallback} from 'react'

import {Consumer} from '@possible/cassandra'
import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {SetAmplitudeUserProperties} from 'src/lib/Analytics/analytics_compat'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType/useGetCohortUserType'

/**
 * The card cohort data may change during the onboarding process.
 * We won't know if they are in a loan or non-loan state until after they enter an address
 * This hook updates the user's card cohort data in Amplitude and in UserMiscProps
 */
type UseUpdateCardUserType = () => {isLoading: boolean; updateCardUserType: () => Promise<void>}
export const useUpdateCardUserType: UseUpdateCardUserType = () => {
  const {value: cardUserProperties, isLoading: isLoadingUserProperties} =
    useUserProperty('cardApplicationGroup')
  const {cohortUserType} = useGetCohortUserType()
  const [cardToggleApplicationProperties] =
    Consumer.hooks.useCardAccountToggleApplicationPropertiesMutation()
  const hasPossibleCard = !!cardUserProperties?.possibleCard

  const updateCardUserType = useCallback(async () => {
    await cardToggleApplicationProperties({
      variables: {
        applicationDataInput: {
          enabled: hasPossibleCard,
          miscData: {
            cohortUserType,
          },
          extraFlags: [],
        },
      },
    })

    SetAmplitudeUserProperties('cohortUserType', cohortUserType)
  }, [cardToggleApplicationProperties, hasPossibleCard, cohortUserType])

  return {isLoading: isLoadingUserProperties, updateCardUserType}
}
