import React, {useState} from 'react'

import {
  LinkedAccount,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {usePfSelector} from 'src/store/utils'
import {
  PrimaryAccountSelectionTemplateProps,
  PrimaryAccountSelectionTemplate,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionTemplate'
import {isAccountValidForPrimary} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import {
  OnPrimaryAccountSelectionSavingFailed,
  OnContinueAfterPrimaryAccountSelected,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.types'
import {getPayNowPaymentMethod} from 'src/products/card/PaymentMethods/selectors'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  LinkedAccountsDocument,
  PrimaryAccountSelectionGqlContainerMutationDocument,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionGQLContainer.gqls'

export type PrimaryAccountSelectionGQLContainerProps = Pick<
  PrimaryAccountSelectionTemplateProps,
  'navigation' | 'onBankAccountSelected' | 'onContinueAfterPrimaryAccountSelected'
> & {
  isFromOnboarding: boolean
  onSavingPrimaryAccountFailed: OnPrimaryAccountSelectionSavingFailed
  // this is called when the selection was saved
  onContinueAfterPrimaryAccountSelectionSaved: OnContinueAfterPrimaryAccountSelected
}

/**
 * Gets any data necessary for the PrimaryAccountSelection screen using GraphQL and saves their
 * primary account after selection.
 */
const PrimaryAccountSelectionGQLContainer: React.FC<PrimaryAccountSelectionGQLContainerProps> = (
  props: PrimaryAccountSelectionGQLContainerProps,
) => {
  const {
    navigation,
    onContinueAfterPrimaryAccountSelected,
    onContinueAfterPrimaryAccountSelectionSaved,
    onBankAccountSelected: handleOnBankAccountSelected,
    onSavingPrimaryAccountFailed,
    isFromOnboarding,
  } = props

  const [setPrimaryAccount] = useCassandraMutation(
    PrimaryAccountSelectionGqlContainerMutationDocument,
  )

  const [isSavingPrimaryAccount, setIsSavingPrimaryAccount] = useState<boolean>(false)
  const defaultPayNowPaymentMethod: PaymentAccount = usePfSelector(getPayNowPaymentMethod)

  const {selectedData: allBankAccountsFromGraphql, loading: isLoadingAllBankAccountsFromGraphql} =
    useCassandraQuery(
      LinkedAccountsDocument,
      {
        fetchPolicy: 'cache-and-network',
      },
      (data) => data.me.bankAccounts.all,
    )

  /**
   * Get the list of all bank accounts that are valid choices for selecting a
   * primary account.
   */
  const getValidAccountsForPrimary = (): LinkedAccount[] => {
    if (!allBankAccountsFromGraphql) {
      return []
    }
    if (isFromOnboarding) {
      return allBankAccountsFromGraphql
    }
    return allBankAccountsFromGraphql.filter((thisAccountFromGraphql) =>
      isAccountValidForPrimary(
        thisAccountFromGraphql.type,
        !!thisAccountFromGraphql.achNumbersAvailable,
      ),
    )
  }

  /**
   * Save the user's selected account as their primary when continuing after selecting an account.
   */
  const handleOnContinueSavePrimaryAccount: OnContinueAfterPrimaryAccountSelected = async (args: {
    accountId: string
    accountMask?: string
  }): Promise<void> => {
    const {accountId, accountMask} = args
    try {
      setIsSavingPrimaryAccount(true)
      // this is called when the button is tapped, regardless of if saving succeeds or not
      await onContinueAfterPrimaryAccountSelected({
        accountId,
        accountMask,
      })
      // save the primary account
      const response = await setPrimaryAccount({
        variables: {
          linkedAccountId: accountId,
          paymentMethod: LoanPaymentMethod.Ach,
        },
      })
      if (response.errors && response.errors.length > 0) {
        throw new Error(response.errors[0].message)
      }

      // this is only called when saving succeeds
      await onContinueAfterPrimaryAccountSelectionSaved({
        accountId,
        accountMask,
      })
    } catch (e) {
      onSavingPrimaryAccountFailed(e as Error)
    } finally {
      setIsSavingPrimaryAccount(false)
    }
  }

  return (
    <PrimaryAccountSelectionTemplate
      navigation={navigation}
      onBankAccountSelected={handleOnBankAccountSelected}
      onContinueAfterPrimaryAccountSelected={handleOnContinueSavePrimaryAccount}
      linkedAccountsValidForPrimary={getValidAccountsForPrimary()}
      defaultPaymentMethod={defaultPayNowPaymentMethod}
      isLoading={isSavingPrimaryAccount || isLoadingAllBankAccountsFromGraphql}
    />
  )
}

export {PrimaryAccountSelectionGQLContainer}
