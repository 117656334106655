import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type ExplainHighAprOverlayProps = {
  showExplainHighAprOverlay: boolean
}

const ExplainHighAprOverlay: FC<ExplainHighAprOverlayProps> = (props) => {
  const {showExplainHighAprOverlay} = props
  const {t} = useTranslation('LoanPaymentReview')

  return (
    <OverlaySimple
      visible={showExplainHighAprOverlay}
      okayButtonText={t('Common:OK')}
      testID="Explain-High-Apr-Overlay"
      content={
        <Box gap={'small'}>
          <PFText variant={'h3'}>{t('WhyIsTheAPRSoHigh')}</PFText>
          <Box>
            <PFText variant={'p'}>{t('AprStandsFor')}</PFText>
          </Box>
          <Box>
            <PFText variant={'p'}>{t('WhenYouEvaluate')}</PFText>
          </Box>
          <Box>
            <PFText variant={'p'}>{t('PlusAtPossible')}</PFText>
          </Box>
        </Box>
      }
    />
  )
}

export {ExplainHighAprOverlay}
