import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'
import DualLineText from 'src/designSystem/components/molecules/DualLineText/DualLineText'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'

export type RelinkPreferredBankAccountTemplateProps = {
  institutionName: string
  accountMask: string
  isSubmitting: boolean
  onContactUs: () => void
  onVerifyMyAccountInfo: () => void
}

const RelinkPreferredBankAccountTemplate: FC<RelinkPreferredBankAccountTemplateProps> = (props) => {
  const {
    institutionName,
    accountMask,
    onVerifyMyAccountInfo,
    isSubmitting,
    onContactUs: handleOnContactUs,
  } = props

  const {t} = useTranslation('RelinkPreferredBankAccount')

  const accountNumber = '\u2022\u2022\u2022\u2022' + accountMask

  return (
    <Page
      variant={'generic'}
      title={t('YourBankInformationNeedsToBeVerified')}
      smallTopGap
      testID="RelinkPreferredBankAccountTemplate"
      buttonProps={{
        type: 'inlineLink',
        primary: {
          text: t('VerifyMyAccountInfo'),
          onPress: onVerifyMyAccountInfo,
          disabled: isSubmitting,
          loading: isSubmitting,
          testID: 'VerifyMyAccountInfo-CTA-Button',
        },
        inline: {
          buttonText: t('StillHavingTrouble'),
          text: '',
          disabled: isSubmitting,
          loading: isSubmitting,
          hideIcon: true,
          onPress: handleOnContactUs,
          testID: 'Need-Help-Relinking-CTA-Link',
        },
      }}
    >
      <Box gap={'little'}>
        <PFText variant="p">{t('PleaseVerifyThisAccountInformation')}</PFText>
        <Box
          align={'center'}
          direction={'row'}
          padding={'small'}
          border={{
            color: NamedColors.ASH,
            width: 2,
          }}
          radius={4}
        >
          <Box marginRight={'small'}>
            <SvgIcon name={'warning'} colorVariant={'warning'} isFilled />
          </Box>
          <DualLineText text={institutionName} subText={accountNumber} />
        </Box>
      </Box>
    </Page>
  )
}

export {RelinkPreferredBankAccountTemplate}
