import {EventStreamServiceBase} from 'src/lib/Services/EventStreamServiceBase'
import {
  EventStreamMetadata,
  EventStreamNavigationEvent,
} from 'src/lib/EventStream/EventStream.types'
import {EVENT_SERVICE_LOGGING} from 'src/config'
import Log from 'src/lib/loggingUtil'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'
import EventStreamSingleton, {EventStream} from 'src/lib/EventStream/EventStream'
import {sessionReplayLogger} from 'src/lib/sessionReplay/LogRocket.utils'

const eventStreamLogger = (message: string): void => {
  if (EVENT_SERVICE_LOGGING) {
    Log.log(`[SessionReplayService] ${message}`)
  }
}

/**
 * SessionReplayService is a wrapper around the SessionReplay lib.
 * It receives `metadata` events from the EventStream and
 * uses them to know when to trigger session recording.
 */
class SessionReplayService extends EventStreamServiceBase {
  _currentUserId: string | undefined
  _hasLoggedOut: boolean | undefined
  _previousScreenName: string | undefined

  init = async (): Promise<void> => {
    eventStreamLogger('init complete and waiting for login event')

    await Promise.resolve()
  }

  metadataUpdated = ({userId}: EventStreamMetadata): void => {
    eventStreamLogger(`metadata updated: ${userId}`)

    if (this._currentUserId !== undefined && userId === undefined) {
      eventStreamLogger('User has logged out')
      this._hasLoggedOut = true
    }

    if (userId && userId !== this._currentUserId) {
      this._currentUserId = userId
      eventStreamLogger(`User has logged in: ${this._currentUserId}`)

      this.sessionReplayStart(this._currentUserId)
    }
  }

  sessionReplayStart = (userId: string): void => {
    if (this._hasLoggedOut) {
      eventStreamLogger('starting a new session')
      void SessionReplay.startNewSession()
    } else {
      try {
        eventStreamLogger('initializing session replay')

        void SessionReplay.init()
      } catch (error) {
        sessionReplayLogger(error, 'Error initializing SDK')
      }
    }

    try {
      eventStreamLogger(`identifying user: ${userId}`)

      SessionReplay.identifyUser(userId)
    } catch (error) {
      sessionReplayLogger(error, 'Error identifying user')
    }
  }

  navigationListener = ({screenName: currentScreenName}: EventStreamNavigationEvent): void => {
    try {
      /* Only track the screen session replay if:
       * 1. The user is logged in. This means session replay is initialized.
       * 2. The currentScreenName is not `undefined`.
       * 3. The previousScreenName is different from the currentScreenName. This means the user has
       *    navigated to a new screen.
       */
      if (this._currentUserId === undefined || currentScreenName === undefined) return

      if (this._previousScreenName !== currentScreenName) {
        eventStreamLogger(`navigation event: current screen is ${currentScreenName}`)
        SessionReplay.identifyNavigationEvent(currentScreenName)
      }

      this._previousScreenName = currentScreenName
    } catch (error) {
      sessionReplayLogger(error, `Error identifying navigation event on ${currentScreenName}`)
    }
  }

  startListening(eventStream: EventStream = EventStreamSingleton): void {
    super.startListening(eventStream)
    eventStream.addListener('navigation', this.navigationListener)
  }

  stopListening(eventStream: EventStream = EventStreamSingleton): void {
    super.stopListening(eventStream)
    eventStream.removeListener('navigation', this.navigationListener)
  }
}

export {SessionReplayService}
