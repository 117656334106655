import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppNav from 'src/nav/AppNavActions'

export function openContactUsForm(
  navigator: StackNavigationProp<MainStackParamList, keyof MainStackParamList>,
): void {
  AppNav.push(navigator, 'ZendeskHelpCenter')
}
