import {FetchResult, MutationResult} from '@apollo/client'
import {useState} from 'react'

import {useCassandraLazyQuery, useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

import {
  UseSetPrimaryAccountDocument,
  UseSetPrimaryAccountLatestLoanAutoPayMethodDocument,
  UseSetPrimaryAccountMutation,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/useSetPrimaryAccount/useSetPrimaryAccount.gqls'
import {useAskToUpdateLoanAutoPayOverlay} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/useSetPrimaryAccount/useAskToUpdateLoanAutoPayOverlay'

/**
 * Hook to handle updating the user's primary account. If the user has an active loan with an auto pay method that is not the primary
 * then a modal will be shown to ask whether they want to update the auto pay method to the new primary account or not.
 */
export function useSetPrimaryAccount(
  newPrimaryLinkedAccountId: string,
): [
  () => Promise<FetchResult<UseSetPrimaryAccountMutation>>,
  MutationResult<UseSetPrimaryAccountMutation>,
] {
  const {showAskToUpdateLoanAutoPayOverlay} = useAskToUpdateLoanAutoPayOverlay()
  const [isSetPrimaryLoading, setIsSetPrimaryLoading] = useState(false)

  /**
   * Submit the mutation to update the user's primary account.
   */
  const [submitMutation, mutationResult] = useCassandraMutation(UseSetPrimaryAccountDocument, {
    onCompleted: () => {
      TrackAppEvent(
        BankAccountManagementEvents.account_management_primary_account_set,
        AppEvents.Category.BankAccountManagement,
      )
    },
    onError: (error) => {
      TrackAppEvent(
        BankAccountManagementEvents.account_management_primary_account_set_error,
        AppEvents.Category.BankAccountManagement,
      )
      logAddPaymentMethodError(
        error,
        `useSetPrimaryAccount() failed to set primary account with linkedAccountId=${newPrimaryLinkedAccountId}`,
      )
    },
  })

  /**
   * Runs a query to check if the user's primary account is the same as their loan's default payment method.
   */
  const [checkLoanDefaultPaymentMethod] = useCassandraLazyQuery(
    UseSetPrimaryAccountLatestLoanAutoPayMethodDocument,
    {
      onError: (error) => {
        logAddPaymentMethodError(
          error,
          'useSetPrimaryAccount failed to check loan default payment method',
        )
      },
    },
    (from) => {
      const primaryBankAccount = from.me.bankAccounts.all?.find(
        (acct) => acct.preferredFundingSource === true,
      )
      const newPrimaryBankAccount = from.me.bankAccounts.all?.find(
        (acct) => acct.id === newPrimaryLinkedAccountId,
      )
      const loanAutoPayMethod = from.me.loans.latestActionableLoan?.paymentMethods.paymentDefault
      let loanAutoPayMethodLinkedAccountId: string | undefined = undefined
      let loanAutoPayMethodLinkedAccountMask: string | undefined = undefined
      if (loanAutoPayMethod?.__typename === 'AchPaymentMethod') {
        loanAutoPayMethodLinkedAccountId = loanAutoPayMethod.account?.id
        loanAutoPayMethodLinkedAccountMask = loanAutoPayMethod.account?.mask
      } else if (loanAutoPayMethod?.__typename === 'DebitCardPaymentMethod') {
        loanAutoPayMethodLinkedAccountId = loanAutoPayMethod.card.account?.id
        loanAutoPayMethodLinkedAccountMask = loanAutoPayMethod.card.account?.mask
      }
      // determine if their primary account is the same as their loan autopay method account
      const isLoanAutoPayMethodPrimaryAccount =
        primaryBankAccount?.id === loanAutoPayMethodLinkedAccountId

      return {
        isLatestLoanActive:
          from.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
          'ActiveLoanAggregateStatus',
        isLoanAutoPayMethodPrimaryAccount,
        loanAutoPayMethodLinkedAccountId,
        primaryAccountMask: primaryBankAccount?.mask,
        newPrimaryBankAccountMask: newPrimaryBankAccount?.mask,
        loanAutoPayMethodMask: loanAutoPayMethodLinkedAccountMask,
        allLinkedAccounts: from.me.bankAccounts.all,
      }
    },
  )

  /**
   * Function provided to components to allow them to set a user's LinkedAccount as their primary account.
   * Wraps the mutation function and handles prompting the user to update their active loan's default payment
   * method if it's not currently their primary.
   */
  const handleOnSetAccountAsPrimary = (): ReturnType<typeof submitMutation> => {
    return new Promise<Awaited<ReturnType<typeof submitMutation>>>((resolve, reject) => {
      setIsSetPrimaryLoading(true)

      checkLoanDefaultPaymentMethod()
        .then((checkLoanAutoPayMethodRes) => {
          const {selectedData} = checkLoanAutoPayMethodRes
          const shouldConfirmIfUserWantsToUpdateLoanAutoPay =
            // user should only be asked to change their loan autopay method if they have an active loan
            selectedData?.isLatestLoanActive &&
            // user should only be asked to change their loan autopay method if their primary account is not the same as their loan autopay method
            !selectedData?.isLoanAutoPayMethodPrimaryAccount &&
            // user should only be asked to change their loan autopay method if the new primary is NOT already their autopay method
            newPrimaryLinkedAccountId !== selectedData?.loanAutoPayMethodLinkedAccountId

          if (!shouldConfirmIfUserWantsToUpdateLoanAutoPay) {
            // if we don't have to ask the user if they want to update their active loans' default payment method
            // then we will just submit the mutation to set the primary account
            submitMutation({
              variables: {
                linkedAccountId: newPrimaryLinkedAccountId,
              },
            })
              .then(resolve)
              .catch(reject)
          } else if (shouldConfirmIfUserWantsToUpdateLoanAutoPay) {
            // show the overlay to ask the user if they want to update their active loan's default payment method
            // to their new primary account
            showAskToUpdateLoanAutoPayOverlay<FetchResult<UseSetPrimaryAccountMutation>>({
              newLinkedAccountId: newPrimaryLinkedAccountId,
              newLinkedAccountMask: selectedData?.newPrimaryBankAccountMask ?? '',
              currentLoanAutoPayAccountMask: selectedData?.loanAutoPayMethodMask ?? '',
              onSubmitUpdate: async ({
                linkedAccountId,
                updateActiveLoanPaymentMethod,
              }: {
                linkedAccountId: string
                updateActiveLoanPaymentMethod: boolean
              }) => {
                return await submitMutation({
                  variables: {
                    linkedAccountId,
                    updateActiveLoanPaymentMethod,
                  },
                })
              },
            })
              .then(resolve)
              .catch(reject)
          }
        })
        .catch(reject)
    }).finally(() => {
      setIsSetPrimaryLoading(false)
    })
  }

  return [handleOnSetAccountAsPrimary, {...mutationResult, loading: isSetPrimaryLoading}]
}
