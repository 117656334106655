import React, {useId} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'

type ProgressBarDelimitersProps = {
  amount: number
  numberOfDelimiters: number
}
/**
 * `ProgressBarDelimiters` marks the `ProgressBar` with evenly spaced delimiters (lines).
 */
const ProgressBarDelimiters = ({
  amount,
  numberOfDelimiters,
}: ProgressBarDelimitersProps): JSX.Element => {
  const delimiterId = useId()

  if (numberOfDelimiters === 0) return <></>

  /* Delimiters are a row of evenly sized `View`s with a border on the right side that "divide"
   * 100% of their parent. If you want 1 delimiter you need 2 `View`s so the first `View` is the
   * correct size. We don't render a border on the last `View`. You can think of the last `View` as
   * a "spacer" but we can't render it separately below because we need it to correctly calculate
   * delimiter position for styling. */
  numberOfDelimiters += 1
  const arrayOfDelimiters = Array(numberOfDelimiters).fill(numberOfDelimiters)

  const getBorderStyle = (currentDelimiter: number): StyleProp<ViewStyle> => {
    // The last `View` should not have a border.
    if (currentDelimiter === numberOfDelimiters) return {}

    let style = styles.border

    /* The position of the delimiter within the `ProgressBar` is calculated by dividing 100(%) by
     * the `numberOfDelimiters` then multiplying by the `index`. Lastly, we divide by 100 to get a
     * position as a percentage.
     *
     * Example:
     * ```
     * <ProgressBarDelimiters numberOfDelimiters={3} />
     * currentDelimiter = 3 (3rd element in the array of delimiters)
     * numberOfDelimiters + 1 = 4 See comment on line 21 for explanation
     *
     * 100 / 4 = 25 (the size of each delimiter)
     * 25 * currentDelimiter = 75 (the position of the delimiter)
     * 75 / 100 = 0.75 (the position as a percentage)
     * ```
     */
    const delimiterPosition = ((100 / numberOfDelimiters) * currentDelimiter) / 100

    // Set the style of the delimiter based on the progress bar's `amount`
    if (amount >= delimiterPosition) {
      style = styles.completedBorder
    }

    return style
  }

  return (
    <View role={'presentation'} style={styles.wrapper}>
      {arrayOfDelimiters.map((_, index) => (
        <View
          key={`${delimiterId}-${index}`}
          // Add 1 to account for 0-based index when calculating delimiter position
          role={'presentation'}
          style={[styles.delimiter, getBorderStyle(index + 1)]}
          testID={'ProgressBarDelimiter'}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  delimiter: {
    flex: 1,
  },
  border: {
    borderRightWidth: 1,
    borderRightColor: NamedColors.ASH,
  },
  completedBorder: {
    borderRightWidth: 1,
    borderRightColor: NamedColors.BLACK,
  },
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row',
  },
})

export {ProgressBarDelimiters}
