import {EnvironmentType} from '@possible/cassandra'
import {isDeviceWeb} from 'src/lib/utils/platform'
import WebAppConfig from 'src/WebAppConfig'
import {readDevMode, readEnv} from 'src/lib/devMode'
import DeviceInfo from 'react-native-device-info'

export const isDev = async (): Promise<boolean> => {
  const devMode = await readDevMode()
  const isEmulator = DeviceInfo.isEmulatorSync()
  return __DEV__ || devMode || isEmulator
}

let _environment: EnvironmentType = EnvironmentType.NotSet

export const getEnvironment = async (): Promise<EnvironmentType> => {
  if (_environment !== EnvironmentType.NotSet) return _environment
  let envResolved: EnvironmentType

  const envOverride = await readEnv()

  if (envOverride) {
    envResolved = envOverride
  } else if (isDeviceWeb()) {
    envResolved = WebAppConfig.REACT_APP_PFENV
  } else if (await isDev()) {
    envResolved = EnvironmentType.Dev
  } else {
    envResolved = EnvironmentType.Prod
  }

  _environment = envResolved
  return _environment
}
