import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'
import {SafeAreaView} from 'react-native-safe-area-context'

import {smallGap, pageMarginHorizontal, largeGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {NetworkFailureErrorDocument} from 'src/products/loans/NetworkFailureError/NetworkFailureError.gqls'
import {genericPageBackground} from 'src/designSystem/semanticColors'

type Props = {
  logo: boolean
  backgroundColor: string
}

/**
 * Screen to inform the user that they have a network connection problem.
 */
const NetworkFailureError: FC<Props> = (props) => {
  const {t} = useTranslation(['NetworkFailureError', 'Common'])
  const {logo: showLogo, backgroundColor = genericPageBackground} = props

  // the network status is updated depending on if cassandra requests
  // succeed so we poll a simple query here to make it re-check
  useCassandraQuery(NetworkFailureErrorDocument, {
    pollInterval: 1000 * 10,
    fetchPolicy: 'no-cache',
  })

  return (
    <SafeAreaView style={styles.safeAreaViewContainer}>
      <View style={[styles.container, {backgroundColor: backgroundColor}]}>
        <View style={styles.content}>
          <View style={styles.top}>
            {showLogo ? (
              <View style={styles.logoView}>
                <NameLogo />
              </View>
            ) : null}
            <View style={styles.text}>
              <View style={{marginVertical: smallGap, marginBottom: smallGap}}>
                <PFText variant={'h1'} textAlign={'center'}>
                  {t('NetworkFailureTitle')}
                </PFText>
              </View>
              <View style={styles.unableToConnectTextContainer}>
                <PFText variant={'p'} textAlign={'center'}>
                  {t('UnableToConnectMessage')}
                </PFText>
              </View>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

export {NetworkFailureError}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    marginHorizontal: pageMarginHorizontal,
  },
  logoView: {
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingVertical: largeGap,
  },
  safeAreaViewContainer: {
    flex: 1,
  },
  text: {
    width: '100%',
  },
  top: {
    width: '100%',
  },
  unableToConnectTextContainer: {
    marginBottom: smallGap,
    width: '100%',
  },
})
