import React from 'react'
import {SvgProps} from 'react-native-svg'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {SizeVariants} from 'src/designSystem/types'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {getTestID} from 'src/lib/utils/tests.utils'

export type EntryTileProps = {
  width?: number
  paddingHorizontal?: SizeVariants
  svg?: React.FC<SvgProps>
  svgWidth?: number
  svgHeight?: number
  header: string
  body: string
  bodyStyle?: BoxProps
  linkText: string
  onLinkPress: () => void
}

const EntryTile: React.FC<EntryTileProps> = (props) => {
  const {
    paddingHorizontal,
    width,
    svg,
    svgHeight = 56,
    svgWidth = 56,
    header,
    body,
    bodyStyle = {},
    linkText,
    onLinkPress: handleOnLinkPress,
  } = props

  return (
    <Box
      width={width}
      paddingHorizontal={paddingHorizontal || 'medium'}
      testID={header.replace(/\s/g, '-') + '-Id'}
    >
      <BasicTile padding="small">
        <Box direction="row" justify="start" gap="little">
          {svg ? <PFSvgContain svg={svg} width={svgWidth} height={svgHeight} /> : null}
          <Box shrink={1} {...bodyStyle}>
            <PFText variant="label_md">{header}</PFText>
            <PFText variant="p">{body}</PFText>
            <Box marginTop="tiny">
              <SvgLink
                testID={getTestID(linkText, '-Id')}
                linkIcon={'internal'}
                linkText={linkText}
                linkType={'single'}
                linkIconSize={'little'}
                onPress={handleOnLinkPress}
                textVariant="label_sm"
              />
            </Box>
          </Box>
        </Box>
      </BasicTile>
    </Box>
  )
}

export {EntryTile}
