import React from 'react'
import {NavigationContainer, useTheme} from '@react-navigation/native'

import {rootNavigationRef} from 'src/nav/RootStackNavigation'
import {NavContextProvider} from 'src/nav/NavContext'
import Datadog from 'src/lib/Analytics/Datadog'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'
import Log from 'src/lib/loggingUtil'
import {getPfStore} from 'src/store'

type AppNavContainerProps = {
  children: React.ReactNode
}

const handleOnReady: Parameters<typeof NavigationContainer>[0]['onReady'] = () => {
  /* TODO: Create and move this to `Services/ReduxService` or delete this.
   * To prevent a feedback loop from going backwards, we keep track of ignorePopCount */
  Log.log('READY!: ', rootNavigationRef.current?.getRootState())
  getPfStore().dispatch({type: 'NAVIGATOR_READY', ready: true})

  // TODO: update DatadogService to do this internally
  Datadog.trackViews(rootNavigationRef.current)

  EventStreamSingleton.emit({
    type: 'navigation',
    screenName: rootNavigationRef.current?.getCurrentRoute()?.name,
  })
}

const handleOnStateChange: Parameters<typeof NavigationContainer>[0]['onStateChange'] = (
  navigationState,
) => {
  EventStreamSingleton.emit({
    type: 'navigation',
    data: navigationState,
    screenName: rootNavigationRef.current?.getCurrentRoute()?.name,
  })
}

const AppNavContainer = ({children}: AppNavContainerProps): JSX.Element => {
  const theme = useTheme()
  theme.colors.background = 'transparent'

  return (
    <NavigationContainer
      onReady={handleOnReady}
      onStateChange={handleOnStateChange}
      ref={rootNavigationRef}
      theme={theme}
    >
      <NavContextProvider>{children}</NavContextProvider>
    </NavigationContainer>
  )
}

export {AppNavContainer}
