import React from 'react'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {UsePageViewedAnalyticsProps} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {AdhocPaymentSuccessContainer} from 'src/products/general/AdhocPayment/AdhocPaymentSuccess/AdhocPaymentSuccessContainer'
import {trackLoansAdhocScreenRenderFailure} from 'src/products/loans/AdhocPayment/LoanPay.utils'
import {utcDate} from 'src/lib/utils/date'

type LoanPaySuccessProps = Pick<
  StackScreenProps<LoanPayNavigatorStack, 'LoanPaySuccess'>,
  'route'
> &
  Pick<StackScreenProps<MainStackParamList>, 'navigation'>

/**
 * Displays a success message after an adhoc loan payment has been submitted.
 * The payment amount and date displayed are passed as route params.
 */
const LoanPaySuccess = ({navigation, route}: LoanPaySuccessProps): JSX.Element => {
  const {t} = useTranslation('AdhocPayment')

  const pageViewedAnalytics: UsePageViewedAnalyticsProps = {
    eventName: ManageActiveLoanEvents.loans_adhoc_success_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
    eventArgs: {
      payment_method_type: route.params.paymentMethodTypeForAnalytics,
    },
  }

  const paymentAmount = route.params.paymentAmount
  const executeDate = route.params.initiationDate ?? ''

  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansAdhocScreenRenderFailure(e, 'LoanPaySuccess')
  }

  const handleOnOkay = (): void => {
    navigation.reset({
      routes: [
        {
          name: 'Dashboard',
        },
      ],
    })
  }

  return (
    <AdhocPaymentSuccessContainer
      pageViewedAnalytics={pageViewedAnalytics}
      onOkay={handleOnOkay}
      paymentAmount={paymentAmount}
      executeDate={executeDate ?? ''}
      executeDateFormat={utcDate}
      overrideTitle={t('YourPaymentIsProcessing')}
      testID="LoanPaySuccess"
      onErrorBoundary={handleOnErrorBoundary}
    />
  )
}

export {LoanPaySuccess}
