import * as nav_actions from 'src/navigation/actions'

export type NavReduxType = {
  component_id_top: string
  component_name_top: string
  side_menu_open: boolean
}

export const NavStateDefault: NavReduxType = {
  component_id_top: '',
  component_name_top: '',
  side_menu_open: false,
}

export default function reducer(state = NavStateDefault, action): NavReduxType {
  switch (action.type) {
    case nav_actions.TOP_OF_UI_STACK:
      return {
        ...state,
        component_id_top: action.component_id,
        component_name_top: action.component_name,
      }
    case nav_actions.SIDE_MENU:
      return {...state, side_menu_open: action.open}
    default:
      return state
  }
}
