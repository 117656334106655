import React from 'react'
import {ScrollView, StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import StackedButtons from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {OverlayStackedButtonsProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtons.types'

export type OverlayStackedButtonsTemplateProps = Omit<
  OverlayStackedButtonsProps,
  'testID' | 'visible'
>

export const OverlayStackedButtonsTemplate: React.FC<OverlayStackedButtonsTemplateProps> = (
  props,
) => {
  const {
    title,
    titleAlign = 'left',
    titleSize = 'h2',
    padding = 'medium',
    children,
    shouldContentScroll,
    primary,
    secondary,
  } = props
  return (
    <Box gap={'medium'} padding={padding}>
      {title && title !== '' ? (
        <PFText variant={titleSize} textAlign={titleAlign}>
          {title}
        </PFText>
      ) : null}

      {shouldContentScroll ? (
        <ScrollView style={style.contentContainerStyle}>{children}</ScrollView>
      ) : (
        children
      )}

      <StackedButtons primary={primary} secondary={secondary} />
    </Box>
  )
}

const style = StyleSheet.create({
  contentContainerStyle: {
    maxHeight: 300,
  },
})
