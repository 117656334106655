import React, {useState, FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native'

import ImagePicker from 'src/products/general/ImagePicker'
import {PushPage} from 'src/navigation/NavHelper'
import {ShowException} from 'src/lib/errors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Log from 'src/lib/loggingUtil'
import ValuePropList from 'src/products/loans/components/molecules/ValuePropList/ValuePropList'
import {smallGap} from 'src/designSystem/layout'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {stateFull, stateFromLoanObject} from 'src/products/MCU/Address/address.utils'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {ImageCapture, getImagePickerDefaults} from 'src/lib/utils/formData'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {UserAndLatestActionableLoanDocument} from 'src/products/loans/PersonalInformation/ProofOfResidence/ProofOfResidence.gqls'
import Page from 'src/designSystem/components/organisms/Page/Page'

type Props = StackScreenProps<MainStackParamList, 'ProofOfResidence'>

const ProofOfResidence: FC<Props> = () => {
  const [isBusy, setBusy] = useState(false)
  const navigation = useNavigation()
  const route = useRoute<RouteProp<MainStackParamList, 'ProofOfResidence'>>()

  const {t} = useTranslation(['PersonalInformation', 'Common'])

  const {data: userAndLatestLoanData, loading: isLoadingLatestLoan} = useCassandraQuery(
    UserAndLatestActionableLoanDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: {},
      onError: (e) => {
        Log.error(e, 'ProofOfResidence - LatestLoanForProofOfResidence query failed')
      },
    },
  )

  const loan = userAndLatestLoanData?.me?.loans?.latestActionableLoan
  const userState = userAndLatestLoanData?.me?.profile?.home?.address?.state

  const onComplete = (address): void => {
    navigation.goBack()
    route?.params?.onProofComplete?.(address)
  }

  function confirmImage(imageInfo: ImageCapture): void {
    // TODO: We should not be passing functions and large objects through navigation params
    PushPage(navigation, 'ProofOfResidenceConfirm', {
      imageInfo,
      noAddressFill: route?.params?.noAddressFill,
      onComplete: (address) => onComplete(address),
      onRetake: onCaptureWithImagePicker,
      selectedState: route?.params?.selectedState,
    })
  }

  const onCaptureWithImagePicker = async (): Promise<void> => {
    setBusy(true)

    const options: ImagePicker.ImageLibraryOptions = {
      maxHeight: 2048,
      quality: 0.8,
      mediaType: 'photo',
      selectionLimit: 1,
    }

    try {
      await ImagePicker.launchImageLibrary(options, (response) => {
        if (response.didCancel) {
          Log.log('User cancelled image picker')
        } else if (response.errorCode || response.errorMessage) {
          Log.log('ImagePicker Error: ', response.errorCode, response.errorMessage)
        } else if (response?.assets?.length && response.assets[0].uri) {
          const {fileName, type} = getImagePickerDefaults(response.assets[0].uri)
          confirmImage({
            uri: response.assets[0].uri,
            fileName: response.assets[0].fileName ?? fileName,
            type: response.assets[0].type ?? type,
          })
        }
      })
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  const action = {
    onPress: onCaptureWithImagePicker,
    text: t('Common:Continue'),
    disabled: isBusy,
  }

  const loanState = loan?.state ? stateFromLoanObject(loan?.state) : t('Unknown')
  const state = userState ? stateFull(userState) : t('Unknown')

  let bodyText = ''
  if (route?.params?.uraActionType === 'POR_DATE') {
    bodyText = t('OldProofOfResidency')
  } else {
    bodyText = t('SelectedLoanStateDifferentThanAddress', {loanState, state})
  }

  const mainBody = (
    <PFText variant={'p'}>
      {bodyText}
      {'\n\n'}
      {t('SomeExamplesOfThisAre')}
      {'\n\n'}• {t('ARecentCellPhoneBill')}
      {'\n'}• {t('ARecentUtilityBill')}
      {'\n'}• {t('ARecentPayStub')}
      {'\n\n'}
    </PFText>
  )

  const requirements = [
    t('YourFirstAndLastName'),
    t('YourCurrentInStateAddress'),
    t('ADateWithinTheLast30Days'),
  ]

  const icon = <SvgIcon name={'checkWithCircle'} colorVariant={'success'} />

  if (isLoadingLatestLoan) {
    return <Spinner />
  }

  return (
    <Page
      title={t('ProvideProofOfResidency')}
      buttonProps={buttonLockupProperties(action)}
      variant={'generic'}
    >
      <View style={styles.must_show}>
        {mainBody}
        <PFText variant={'p_semibold'}>
          {t('YourProofOfResidencyMustShow')}:{'\n\n'}
        </PFText>
        <ValuePropList
          icon={icon}
          items={requirements}
          boldItems={false}
          style={styles.boxView}
          textStyle={styles.listTextStyle}
        />
      </View>
    </Page>
  )
}

export {ProofOfResidence}

const styles = StyleSheet.create({
  boxView: {
    justifyContent: 'space-around',
  },
  listTextStyle: {
    paddingLeft: smallGap,
  },
  must_show: {
    justifyContent: 'center',
  },
  pageBodyStyle: {
    justifyContent: 'flex-start',
  },
})
