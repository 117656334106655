import React, {useState, FC} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import PFPasswordInput from 'src/designSystem/components/molecules/PFPasswordInput/PFPasswordInput'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {ShowException} from 'src/lib/errors'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type FormData = {
  password: string
}
type Props = {
  submitAction: (string) => Promise<void>
  email?: string
}

const CreatePasswordBase: FC<Props> = (props) => {
  const {t} = useTranslation(['LoginTransition', 'Common'])
  const [requirementsMet, setRequirementsMet] = useState(false)
  const [busy, setBusy] = useState(false)
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>({mode: 'all'})

  const onSubmit = async (data) => {
    setBusy(true)
    try {
      await props.submitAction(data.password)
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  const getDisabledAction = () => {
    return !requirementsMet || busy || getHasErrorsOrMissingValues(errors, watch, 'password')
  }

  const action = {
    text: t('Common:Submit'),
    onPress: handleSubmit(onSubmit),
    disabled: getDisabledAction(),
  }

  const checkPasswordValidity = (valid) => {
    setRequirementsMet(valid)
  }

  const formProps = {
    name: 'password',
    field: FieldVariants.TextField,
  }

  const getContent = () => {
    return (
      <HookForm control={control} errors={errors}>
        <PFPasswordInput
          label={t('Common:Password')}
          showRequirements={true}
          onAllRequirementsMet={checkPasswordValidity}
          formProps={formProps}
        />
      </HookForm>
    )
  }

  const title = t('CreatePasswordPageTitle')
  const description = t('CreatePasswordPageDescription', {email: props.email})

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(action)}
      title={title}
      description={description}
    >
      {getContent()}
    </GenericNonModalTemplate>
  )
}

export default CreatePasswordBase
