import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {LogException, ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {AddBankLinkedAccountSubset} from 'src/products/general/AddBankLink/AddBankLink.types'
import * as AddBankLinkUtils from 'src/products/general/AddBankLink/AddBankLink.utils'
import {
  AddBankLinkGQLContainer,
  AddBankLinkGQLContainerProps,
} from 'src/products/general/AddBankLink/AddBankLinkGQLContainer'

export type AddBankLinkLegacyContainerProps = {
  navigation: AddBankLinkGQLContainerProps['navigation']
  route: StackScreenProps<MainStackParamList, 'AddBankLink'>['route']
} & {
  flow?: PaymentFlow
}

/**
 * Container to integrate the AddBankLink screen into legacy navigation when using
 * navigation.navigate(). This container is used for the Cards flow.
 */
const AddBankLinkLegacyContainer: React.FC<AddBankLinkLegacyContainerProps> = (
  props: AddBankLinkLegacyContainerProps,
) => {
  const {navigation, route, flow: flowProp} = props
  const {t} = useTranslation('AddBankLink')

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.BankAccountManagement,
    eventName: AppEvents.Name.prequal_bank_link_shown,
  })

  // I think flowProp is unnecessary and route.params.flow is correct but leaving in
  // for backwards compatibility until ENG-15583 is addressed
  // see https://possible.atlassian.net/browse/ENG-15583
  const flow = flowProp ?? route?.params?.flow

  const onCheckBankSupport: AddBankLinkGQLContainerProps['onCheckBankSupport'] = () => {
    AddBankLinkUtils.onCheckBankSupport({
      navigation,
    })
  }

  /**
   * When user continues after adding a bank account, send them to `CardVerifyBankDetails`
   */
  const onContinueAfterBankLinkComplete: AddBankLinkGQLContainerProps['onContinueAfterBankLinkComplete'] =
    (args: {bankName: string; accounts: AddBankLinkedAccountSubset[] | undefined}): void => {
      const {bankName} = args
      try {
        PushPage(navigation, 'CardVerifyBankDetails', {
          bankNameToFilter: bankName,
          flow: flow,
        })
      } catch (e) {
        LogException(
          e,
          'Error while trying to continue after bank linking in AddBankLinkLegacyContainer',
        )
        ShowException(
          t(
            'ErrorSettingSinglePrimaryAccount',
            'We were unable to set your primary account. Please try again later.',
          ),
        )
      }
    }

  return (
    <AddBankLinkGQLContainer
      navigation={navigation}
      onCheckBankSupport={onCheckBankSupport}
      pageTitle={t('LinkYourBankAccount')}
      onContinueAfterBankLinkComplete={onContinueAfterBankLinkComplete}
    />
  )
}

export default AddBankLinkLegacyContainer
