import React, {useId} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonAction} from 'src/designSystem/types'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {
  InterstitialList,
  InterstitialListItem,
} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import Group1715_with_calendar from 'src/assets/illustrations/CalendarCashBanner.svg'
import Calendar_Icon from 'src/assets/illustrations/GreenClock.svg'
import CashWithCoin_Icon_Light from 'src/assets/illustrations/GreenCard.svg'
import List_Icon from 'src/assets/illustrations/GreenSuccess.svg'

export type HowPaymentsWorkTemplateProps = {
  isSubmitting?: boolean
  onContinue: NonNullable<ButtonAction>
}

export const HowPaymentsWorkTemplate = (props: HowPaymentsWorkTemplateProps): JSX.Element => {
  const {isSubmitting, onContinue} = props
  const {t} = useTranslation('HowPaymentsWork')
  const renderKey = useId()

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('OkGotIt'),
      onPress: onContinue,
      disabled: isSubmitting,
      loading: isSubmitting,
    },
  }

  const ExplanationList: InterstitialListItem[] = [
    {
      id: `${renderKey}1`,
      subtext: t('ExplanationOneDescription'),
      illustration: Calendar_Icon,
    },
    {
      id: `${renderKey}2`,
      subtext: t('ExplanationTwoDescription'),
      illustration: CashWithCoin_Icon_Light,
    },
    {
      id: `${renderKey}3`,
      subtext: t('ExplanationThreeDescription'),
      illustration: List_Icon,
    },
  ]

  return (
    <Page
      variant={'interstitial'}
      buttonProps={buttonProps}
      banner={Group1715_with_calendar}
      title={t('MainTitle')}
      barStyle={'light-content'}
    >
      <InterstitialList items={ExplanationList} />
    </Page>
  )
}
