import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  AmountFinancedTilaLineItem,
  AprTilaLineItem,
  FinanceChargeTilaLineItem,
  PaymentScheduleTilaLineItem,
  TotalOfPaymentsTilaLineItem,
} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/TilaLineItem'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {FinanceChargeBreakdown} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/FinanceChargeBreakdown'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'

const LoanTilaContent: FC<LoanTilaContentData> = (props) => {
  const {apr, totalCost, totalOwed, amountApproved, cabFee, interestFee, stateCode} = props
  const {t} = useTranslation('LoanApproved')

  return (
    <Box gap={'small'} marginBottom={'small'}>
      <PFText variant={'p'}>{t('WeAreRequiredToShow')}:</PFText>
      <PFText variant={'p_lg_semibold'}>{t('FederalTruthInLendingAct')}</PFText>
      <AprTilaLineItem apr={apr ?? ''} />
      <FinanceChargeTilaLineItem totalCost={totalCost ?? ''} />
      <FinanceChargeBreakdown
        cabFee={cabFee ?? ''}
        interestFee={interestFee ?? ''}
        stateCode={stateCode ?? undefined}
      />
      <AmountFinancedTilaLineItem amountApproved={amountApproved ?? ''} />
      <TotalOfPaymentsTilaLineItem totalOwed={totalOwed ?? ''} />
      <PaymentScheduleTilaLineItem />
    </Box>
  )
}

export {LoanTilaContent}
