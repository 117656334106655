import React, {ReactElement} from 'react'
import {TouchableWithoutFeedback} from 'react-native-gesture-handler'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {tinyGap} from 'src/designSystem/layout'
import PlaidLogo from 'src/assets/icons/Plaid-logo.svg'
import YodleeLogo from 'src/assets/icons/YodleeLogo.svg'
import PlaidLogoWhite from 'src/assets/icons/Plaid-logo-white.svg'
import {PaymentMethodTypesEnum} from 'src/products/general/GeneralPaymentMethods/types'

type AccountIconButtonProps = {
  title: string
  accountType: PaymentMethodTypesEnum
  isActive: boolean
  onPress: () => void
}

const AccountIconButton = (props: AccountIconButtonProps): ReactElement => {
  const {title, accountType, isActive, onPress: handleOnPress} = props

  const iconComponents = {
    [PaymentMethodTypesEnum.Plaid]: (
      <PFSvgContain svg={isActive ? PlaidLogoWhite : PlaidLogo} width={33} height={33} />
    ),
    [PaymentMethodTypesEnum.Yodlee]: <PFSvgContain svg={YodleeLogo} width={33} height={33} />,
    [PaymentMethodTypesEnum.AccountRouting]: (
      <SvgIcon name={'bank'} colorVariant={isActive ? 'white' : 'info'} size="large" />
    ),
    [PaymentMethodTypesEnum.DebitCard]: (
      <SvgIcon name={'card'} colorVariant={isActive ? 'white' : 'info'} size="large" />
    ),
  }

  return (
    <TouchableWithoutFeedback onPress={handleOnPress}>
      <Box
        background={isActive ? NamedColors.PRODUCT_BLUE : NamedColors.WHITESMOKE}
        border={
          isActive
            ? {
                width: 2,
                color: NamedColors.SKY,
              }
            : {
                width: 0,
                color: NamedColors.WHITESMOKE,
              }
        }
        height={106}
        width={89}
        direction="column"
        align="center"
        justify="center"
        radius={8}
        elevation={3}
        position="relative"
        testID={`AddPaymentMethodButton-${accountType}`}
      >
        <Box marginBottom={tinyGap}>{iconComponents[accountType]}</Box>
        <Box paddingHorizontal={tinyGap}>
          <PFText
            variant={isActive ? 'p_sm_semibold' : 'p_sm'}
            color={isActive ? NamedColors.WHITE : NamedColors.BLACK}
            textAlign="center"
            testID={`AccountIconButton-${accountType}`}
          >
            {title}
          </PFText>
        </Box>
      </Box>
    </TouchableWithoutFeedback>
  )
}

export {AccountIconButton}
