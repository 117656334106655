import AccountManagement from 'src/lib/localization/es/AccountManagement.json'
import AccountRecovery from 'src/lib/localization/es/AccountRecovery.json'
import AddBankLink from 'src/lib/localization/es/AddBankLink.json'
import AddDebitCard from 'src/lib/localization/es/AddDebitCard.json'
import AdhocPayment from 'src/lib/localization/es/AdhocPayment.json'
import AdhocPaymentAmountEntry from 'src/lib/localization/es/AdhocPaymentAmountEntry.json'
import AttemptsList from 'src/lib/localization/es/AttemptsList.json'
import Bank from 'src/lib/localization/es/Bank.json'
import BankFees from 'src/lib/localization/es/BankFees.json'
import CancelLoan from 'src/lib/localization/es/CancelLoan.json'
import CardActivated from 'src/lib/localization/es/CardActivated.json'
import CardActivation from 'src/lib/localization/es/CardActivation.json'
import CardDashboard from 'src/lib/localization/es/CardDashboard.json'
import CardHowPaymentsWork from 'src/lib/localization/es/CardHowPaymentsWork.json'
import CardPaymentAgreement from 'src/lib/localization/es/CardPaymentAgreement.json'
import CardSubmitApplication from 'src/lib/localization/es/CardSubmitApplication.json'
import CardTitle from 'src/lib/localization/es/CardTitle.json'
import CardTransaction from 'src/lib/localization/es/CardTransaction.json'
import CardVerifyBankDetails from 'src/lib/localization/es/CardVerifyBankDetails.json'
import CardViewPaymentMethods from 'src/lib/localization/es/CardViewPaymentMethods.json'
import Common from 'src/lib/localization/es/Common.json'
import ConfirmBankAccount from 'src/lib/localization/es/ConfirmBankAccount.json'
import ConfirmDebitCard from 'src/lib/localization/es/ConfirmDebitCard.json'
import ContactUs from 'src/lib/localization/es/ContactUs.json'
import DisbursementMethodSelection from 'src/lib/localization/es/DisbursementMethodSelection.json'
import EmailConfirmation from 'src/lib/localization/es/EmailConfirmation.json'
import EmailPasswordForm from 'src/lib/localization/es/EmailPasswordForm.json'
import EmailPasswordManagement from 'src/lib/localization/es/EmailPasswordManagement.json'
import ErrorAlertLightbox from 'src/lib/localization/es/ErrorAlertLightbox.json'
import GoogleLogin from 'src/lib/localization/es/GoogleLogin.json'
import HowPaymentsWork from 'src/lib/localization/es/HowPaymentsWork.json'
import IDScan from 'src/lib/localization/es/IDScan.json'
import LeftDrawer from 'src/lib/localization/es/LeftDrawer.json'
import Liveness from 'src/lib/localization/es/Liveness.json'
import LoanApproved from 'src/lib/localization/es/LoanApproved.json'
import LoanCancelAmount from 'src/lib/localization/es/LoanCancelAmount.json'
import LoanFinalAccept from 'src/lib/localization/es/LoanFinalAccept.json'
import LoanHistory from 'src/lib/localization/es/LoanHistory.json'
import LoanSelection from 'src/lib/localization/es/LoanSelection.json'
import LoansMarketingSurvey from 'src/lib/localization/es/LoansMarketingSurvey.json'
import LoanSubmission from 'src/lib/localization/es/LoanSubmission.json'
import LoginTransition from 'src/lib/localization/es/LoginTransition.json'
import MaintenanceInProgress from 'src/lib/localization/es/MaintenanceInProgress.json'
import NetworkFailureError from 'src/lib/localization/es/NetworkFailureError.json'
import NotificationsPermissions from 'src/lib/localization/es/NotificationsPermissions.json'
import OpeningLandingPage from 'src/lib/localization/es/OpeningLandingPage.json'
import PaymentMethods from 'src/lib/localization/es/PaymentMethods.json'
import PersonalInformation from 'src/lib/localization/es/PersonalInformation.json'
import PFPasswordInput from 'src/lib/localization/es/PFPasswordInput.json'
import PhoneConfirmation from 'src/lib/localization/es/PhoneConfirmation.json'
import PhoneNumberEntry from 'src/lib/localization/es/PhoneNumberEntry.json'
import PhoneNumberInput from 'src/lib/localization/es/PhoneNumberInput.json'
import PhotoInstructionsCard from 'src/lib/localization/es/PhotoInstructionsCard.json'
import PossibleCard from 'src/lib/localization/es/PossibleCard.json'
import Profile from 'src/lib/localization/es/Profile.json'
import ReasonSurvey from 'src/lib/localization/es/ReasonSurvey.json'
import RegistrationOrLogin from 'src/lib/localization/es/RegistrationOrLogin.json'
import Reschedule from 'src/lib/localization/es/Reschedule.json'
import Security from 'src/lib/localization/es/Security.json'
import StateDisclosure from 'src/lib/localization/es/StateDisclosure.json'
import SurveyTemplate from 'src/lib/localization/es/SurveyTemplate.json'
import SuspendedLoan from 'src/lib/localization/es/SuspendedLoan.json'
import SynapseInfo from 'src/lib/localization/es/SynapseInfo.json'
import TemporaryPassword from 'src/lib/localization/es/TemporaryPassword.json'
import UnsupportedBanks from 'src/lib/localization/es/UnsupportedBanks.json'
import UnsupportedStateWaitList from 'src/lib/localization/es/UnsupportedStateWaitList.json'
import UpdateBinary from 'src/lib/localization/es/UpdateBinary.json'
import UpdaterScreen from 'src/lib/localization/es/UpdaterScreen.json'
import UpgradeToInstallment from 'src/lib/localization/es/UpgradeToInstallment.json'
import UserRequestedAction from 'src/lib/localization/es/UserRequestedAction.json'
import VerifyBankDetails from 'src/lib/localization/es/VerifyBankDetails.json'
import LoanApprovedExtendedPlan from 'src/lib/localization/es/LoanApprovedExtendedPlan.json'

export default {
  AccountManagement,
  AccountRecovery,
  AddDebitCard,
  AdhocPayment,
  AdhocPaymentAmountEntry,
  AttemptsList,
  Bank,
  BankFees,
  AddBankLink,
  CancelLoan,
  CardActivated,
  CardActivation,
  CardDashboard,
  CardHowPaymentsWork,
  CardPaymentAgreement,
  CardSubmitApplication,
  CardTitle,
  CardTransaction,
  CardVerifyBankDetails,
  CardViewPaymentMethods,
  Common,
  ConfirmBankAccount,
  ConfirmDebitCard,
  ContactUs,
  DisbursementMethodSelection,
  EmailConfirmation,
  EmailPasswordForm,
  EmailPasswordManagement,
  ErrorAlertLightbox,
  LoanApprovedExtendedPlan,
  GoogleLogin,
  HowPaymentsWork,
  IDScan,
  LeftDrawer,
  Liveness,
  LoanApproved,
  LoanCancelAmount,
  LoanFinalAccept,
  LoanHistory,
  LoanSelection,
  LoansMarketingSurvey,
  LoanSubmission,
  LoginTransition,
  MaintenanceInProgress,
  NetworkFailureError,
  NotificationsPermissions,
  OpeningLandingPage,
  PaymentMethods,
  PersonalInformation,
  PFPasswordInput,
  PhoneConfirmation,
  PhoneNumberEntry,
  PhoneNumberInput,
  PhotoInstructionsCard,
  PossibleCard,
  Profile,
  ReasonSurvey,
  RegistrationOrLogin,
  Reschedule,
  Security,
  StateDisclosure,
  SurveyTemplate,
  SuspendedLoan,
  SynapseInfo,
  TemporaryPassword,
  UnsupportedBanks,
  UnsupportedStateWaitList,
  UpdateBinary,
  UpdaterScreen,
  UpgradeToInstallment,
  UserRequestedAction,
  VerifyBankDetails,
}
