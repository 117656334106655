import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {VerifyYourIdentityGQLContainer} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityGQLContainer'

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'PersonalInformation'>

const VerifyYourIdentityWorkflowContainer: React.FC<Props> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const handleOnComplete = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return <VerifyYourIdentityGQLContainer onComplete={handleOnComplete} />
}

export {VerifyYourIdentityWorkflowContainer}
