import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

export type EmailVerificationIsVerifiedTemplateProps = {
  isContinueBusy: boolean
  onContinuePressed: () => Promise<void>
}

const EmailVerificationIsVerifiedTemplate: FC<EmailVerificationIsVerifiedTemplateProps> = ({
  isContinueBusy,
  onContinuePressed,
}) => {
  const {t} = useTranslation(['Common', 'EmailConfirmation'])
  return (
    <Page
      variant="generic"
      buttonProps={buttonLockupProperties({
        text: t('Common:Continue'),
        onPress: onContinuePressed,
        loading: isContinueBusy,
        disabled: isContinueBusy,
      })}
      title={t('EmailConfirmation:EmailVerified')}
    />
  )
}

export {EmailVerificationIsVerifiedTemplate}
