import React from 'react'
import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

const DescriptionText = (props) => {
  const {children} = props

  return (
    <PFText variant="p" color={NamedColors.SILVER}>
      {children}
    </PFText>
  )
}

export default DescriptionText
