import React from 'react'
import {StyleSheet, View, TextStyle, ViewStyle} from 'react-native'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  failedDateBubbleBorderColor,
  inProgressDateBubbleBorderColor,
  missedDateBubbleBorderColor,
  nextDateBubbleBorderColor,
  upcomingDateBubbleBorderColor,
} from 'src/designSystem/semanticColors'

export enum DateBubblePaymentDateStatus {
  FAILED = 'failed', // red bubble
  WARNING = 'warning', // yellow bubble
  NEXT = 'next', // dark blue bubble
  UPCOMING = 'upcoming', // light blue bubble
  IN_PROGRESS = 'in_progress', // green checkmark
}

/**
 * A single payment date for use with a DateBubble.
 */
export type DateBubblePaymentDate =
  | {
      status: DateBubblePaymentDateStatus.IN_PROGRESS
      key: string
    }
  | {
      date: Date
      status?: DateBubblePaymentDateStatus // defaults to UPCOMING
      amount: number
      /**
       * A unique key for this payment date.
       */
      key: string
    }

export type DateBubbleProps = {
  paymentDate: DateBubblePaymentDate
}

/**
 * Renders a circle containing a month and day of that month in different states
 * defined by DateBubbleStates.
 */
const DateBubble: React.FC<DateBubbleProps> = ({paymentDate}) => {
  const {t} = useTranslation('DateBubble')

  // default to UPCOMING if not provided
  let bubbleStyle: ViewStyle
  const statusToUse: DateBubblePaymentDateStatus =
    paymentDate.status ?? DateBubblePaymentDateStatus.UPCOMING

  switch (statusToUse) {
    case DateBubblePaymentDateStatus.NEXT:
      bubbleStyle = {...styles.bubble, ...styles.bubbleNext}
      break
    case DateBubblePaymentDateStatus.UPCOMING:
      bubbleStyle = {...styles.bubble, ...styles.bubbleUpcoming}
      break
    case DateBubblePaymentDateStatus.FAILED:
      bubbleStyle = {...styles.bubble, ...styles.bubbleFailed}
      break
    case DateBubblePaymentDateStatus.WARNING:
      bubbleStyle = {...styles.bubble, ...styles.bubbleWarning}
      break
    case DateBubblePaymentDateStatus.IN_PROGRESS:
      bubbleStyle = {...styles.bubble, ...styles.bubbleInProgress}
      break
    default:
      bubbleStyle = {...styles.bubble, ...styles.bubbleUpcoming}
      break
  }

  let bubbleContents: React.ReactElement | null
  if (paymentDate.status === DateBubblePaymentDateStatus.IN_PROGRESS) {
    bubbleContents = <SvgIcon name="check" colorVariant="success" size="large" />
  } else {
    // example date: new Date('2023-05-18T17:00Z')
    // note: month is zero-indexed, day is NOT zero-indexed, January = 0, 1st = 1
    const month = paymentDate.date.getMonth() + 1
    const day = paymentDate.date.getDate()
    const monthNamesMap = {
      1: t('Jan'),
      2: t('Feb'),
      3: t('Mar'),
      4: t('Apr'),
      5: t('May'),
      6: t('Jun'),
      7: t('Jul'),
      8: t('Aug'),
      9: t('Sep'),
      10: t('Oct'),
      11: t('Nov'),
      12: t('Dec'),
    }
    let dayStyle: TextStyle
    let monthStyle: TextStyle
    if (paymentDate.status === DateBubblePaymentDateStatus.FAILED) {
      dayStyle = {...styles.day, ...styles.dayFailedOrWarning}
      monthStyle = {...styles.month, ...styles.monthFailedOrWarning}
    } else {
      dayStyle = styles.day
      monthStyle = styles.month
    }
    bubbleContents = (
      <>
        <PFText variant="p_lg" textProps={{style: dayStyle}}>
          {day}
        </PFText>
        <PFText variant="p_sm" textProps={{style: monthStyle}}>
          {monthNamesMap[month]}
        </PFText>
      </>
    )
  }
  return <View style={bubbleStyle}>{bubbleContents}</View>
}

export default DateBubble

const styles = StyleSheet.create({
  bubble: {
    alignItems: 'center',
    borderRadius: 66 / 2,
    borderWidth: 1.5,
    display: 'flex',
    height: 66,
    justifyContent: 'center',
    width: 66,
  },
  bubbleFailed: {
    borderColor: failedDateBubbleBorderColor,
  },
  bubbleInProgress: {
    borderColor: inProgressDateBubbleBorderColor,
  },
  bubbleNext: {
    borderColor: nextDateBubbleBorderColor,
  },
  bubbleUpcoming: {
    borderColor: upcomingDateBubbleBorderColor,
  },
  bubbleWarning: {
    borderColor: missedDateBubbleBorderColor,
  },
  day: {
    lineHeight: 24,
  },
  dayFailedOrWarning: {
    color: NamedColors.SILVER,
  },
  month: {
    lineHeight: 20,
  },
  monthFailedOrWarning: {
    color: NamedColors.SILVER,
  },
})
