import React from 'react'
import {useTranslation} from 'react-i18next'

import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'

type PaymentConfirmInfoCapsuleProps = {
  paymentAmount: string
  paymentsCount: string
}

const PaymentConfirmInfoCapsule: React.FC<PaymentConfirmInfoCapsuleProps> = (
  props,
): JSX.Element => {
  const {paymentAmount, paymentsCount} = props
  const {t} = useTranslation('AdhocPayment')

  return (
    <PFInfoCapsule
      svgIcon={{name: 'largeArrowUpRight', colorVariant: 'success'}}
      text={t('PaymentsWithdrawlDetails', {
        paymentAmount: paymentAmount,
        count: parseInt(paymentsCount),
      })}
    />
  )
}

export {PaymentConfirmInfoCapsule}
