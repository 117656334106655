import React from 'react'

import {
  useCassandraLazyQuery,
  useCassandraMutation,
  useCassandraQuery,
} from '@possible/cassandra/src/utils/hooks'
import {
  LoanAmountSelectionEstimatedCostDocument,
  LoanAmountSelectionSetLoanAmountDocument,
  LoanAmountSelectionDocument,
} from 'src/products/loans/LoanAmountSelection/LoanAmountSelection.gqls'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {LoanAmountSelectionTemplate} from 'src/products/loans/LoanAmountSelection/LoanAmountSelectionTemplate'
import {LoanSelectionCompleted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export const LoanAmountSelectionGQLContainer: React.FC<WorkflowPreReqFulfillScreenProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  // Gets today's date just once on component mount
  const todayDate = React.useRef(new Date().toISOString()).current

  const {
    selectedData: queryData,
    loading: isQueryLoading,
    error: queryError,
  } = useCassandraQuery(
    LoanAmountSelectionDocument,
    {
      variables: {assessmentDate: todayDate},
      fetchPolicy: 'network-only',
      onError: (e) => {
        logOfferApplicationError(
          e,
          'LoanAmountSelectionGQLContainer: LoanAmountSelection query failed',
        )
      },
      onCompleted: (data) => {
        const defaultAmount = data.getLoanPreApplicationProfile.defaultAmount.split('.')[0]

        handleOnLoanAmountChanged(defaultAmount)
      },
    },
    (data) => {
      const prequalifiedAmount = data.loanGetPrequalification.amount.split('.')[0]
      return {
        prequalifiedAmount: prequalifiedAmount === '0' ? undefined : prequalifiedAmount,
        maximumLoanAmount: data.getLoanPreApplicationProfile.maximumAllowedAmount.split('.')[0],
        minimumLoanAmount: data.getLoanPreApplicationProfile.minimumAllowedAmount.split('.')[0],
        defaultLoanAmount: data.getLoanPreApplicationProfile.defaultAmount.split('.')[0],
      }
    },
  )

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_amount_selection_screen_viewed,
    eventCategory: AppEvents.Category.Application,
    isLoading: isQueryLoading,
  })

  const minLoanAmount = queryData?.minimumLoanAmount ?? '50'
  const maxLoanAmount = queryData?.maximumLoanAmount ?? '800'
  const defaultAmount = queryData?.defaultLoanAmount ?? minLoanAmount

  const [loanAmount, setLoanAmount] = React.useState<string>(defaultAmount)

  const [getEstimatedCosts, {selectedData: estimatedCostData, loading: isLoadingEstimatedCost}] =
    useCassandraLazyQuery(
      LoanAmountSelectionEstimatedCostDocument,
      {
        fetchPolicy: 'cache-first',
        onError: (e) => {
          logOfferApplicationError(
            e,
            'LoanAmountSelectionGQLContainer: LoanAmountSelectionEstimatedCost query failed',
          )
        },
      },
      (data) => {
        return {
          apr: data.loanEstimatedCosts.apr,
          installmentAmount: data.loanEstimatedCosts.installmentAmount,
          installmentCount: data.loanEstimatedCosts.installmentCount,
          totalCost: data.loanEstimatedCosts.totalCost,
          totalOwed: data.loanEstimatedCosts.totalOwed,
        }
      },
    )

  const estimatedApr = estimatedCostData?.apr ?? '0'
  const estimatedTotalOwed = estimatedCostData?.totalOwed ?? '0'
  const estimatedInstallmentAmount = estimatedCostData?.installmentAmount ?? '0'
  const estimatedInstallmentCount = estimatedCostData?.installmentCount ?? 0
  const estimatedTotalCost = estimatedCostData?.totalCost ?? '0'

  const getIsLoanAmountValid = (amount: string): boolean => {
    const intAmount = parseInt(amount)
    return (
      !isNaN(intAmount) &&
      intAmount >= parseInt(minLoanAmount) &&
      intAmount <= parseInt(maxLoanAmount)
    )
  }

  const handleOnLoanAmountChanged = (amount: string): void => {
    setLoanAmount(amount)

    if (getIsLoanAmountValid(amount)) {
      void getEstimatedCosts({variables: {amount}})
    }
  }

  const [handleOnPreReqFulfilled, {isLoading: isLoadingOnPreReqFulfilled}] = usePromise(
    async () => {
      await onPreReqFulfilled()

      TrackAppEvent(AppEvents.Name.loan_amount_selected, AppEvents.Category.Application, {
        value: loanAmount,
        loanTermsMinAmount: minLoanAmount,
        loanTermsMaxAmount: maxLoanAmount,
      })
      LoanSelectionCompleted()
    },
    {
      catch: (error) => {
        logOfferApplicationErrorAndShowException(
          error,
          'LoanAmountSelectionGQLContainer: Error fulfilling workflow prerequisite.',
        )
      },
    },
  )

  const [submitLoanAmountMutation, {loading: isSubmittingAmount}] = useCassandraMutation(
    LoanAmountSelectionSetLoanAmountDocument,
    {
      onError: (error) => {
        logOfferApplicationErrorAndShowException(
          error,
          'LoanAmountSelectionGQLContainer: Error submitting loan amount.',
        )
      },
    },
  )

  async function handleOnAmountSelected(amount: string): Promise<void> {
    const response = await submitLoanAmountMutation({variables: {amount}})
    if (response.errors) {
      return
    }

    await handleOnPreReqFulfilled()
  }

  return (
    <BaseTemplate testID={'LoanAmountSelection'} isLoading={isQueryLoading} isError={!!queryError}>
      <LoanAmountSelectionTemplate
        APRPercent={estimatedApr}
        buttonAmounts={[minLoanAmount, '100', '200', maxLoanAmount]}
        estimatedTotal={estimatedTotalOwed}
        installmentAmount={estimatedInstallmentAmount}
        installmentCount={estimatedInstallmentCount}
        isLoadingEstimatedCosts={isLoadingEstimatedCost}
        isLoadingOnContinue={isSubmittingAmount || isLoadingOnPreReqFulfilled}
        isValidAmount={getIsLoanAmountValid(loanAmount)}
        loanAmount={loanAmount}
        maxLoanAmount={maxLoanAmount}
        minLoanAmount={minLoanAmount}
        onContinue={handleOnAmountSelected}
        onLoanAmountChanged={handleOnLoanAmountChanged}
        prequalifiedAmount={queryData?.prequalifiedAmount}
        totalInterestAndFees={estimatedTotalCost}
      />
    </BaseTemplate>
  )
}
