import React from 'react'
import {useTranslation, Trans} from 'react-i18next'

import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

export type Props = {
  checked: boolean
  isDisabled?: boolean
  setChecked: (checked: boolean) => void
  onClick: (() => void) | (() => Promise<void>)
  agreementName: string
}

export const CardAcceptDocument: React.FC<Props> = (props) => {
  const {
    checked: isChecked,
    isDisabled,
    setChecked: handleOnPressCheckbox,
    onClick: handleOnPressLink,
    agreementName,
  } = props

  const {t} = useTranslation('CardPaymentAgreement')
  return (
    <PFCheckbox
      checked={isChecked}
      disabled={isDisabled}
      onPress={handleOnPressCheckbox}
      testID={'CardAcceptDocument-checkbox-agreement'}
    >
      <Trans
        t={t}
        i18nKey={'AcceptAgreement'}
        values={{agreementName}}
        components={{
          svgLink: (
            <SvgLink
              linkText={agreementName}
              onPress={handleOnPressLink}
              disabled={isDisabled}
              linkType={'inline'}
            />
          ),
        }}
      />
    </PFCheckbox>
  )
}
