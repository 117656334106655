import {AdverseReasonCode} from '@possible/cassandra/src/types/types.mobile.generated'
import i18n from 'src/lib/localization/i18n'

type AdverseReasonCodeDescriptionsType = {
  [key in AdverseReasonCode]: string
}

const adverseReasonCodeDescriptions: AdverseReasonCodeDescriptionsType = {
  [AdverseReasonCode.BalanceTooLow]: i18n.t('LoanCounterOffer:BalanceTooLow'),
  [AdverseReasonCode.IncomeTooLow]: i18n.t('LoanCounterOffer:IncomeTooLow'),
  [AdverseReasonCode.IncomeUnpredictable]: i18n.t('LoanCounterOffer:IncomeUnpredictable'),
  [AdverseReasonCode.CannotVerifyIncome]: i18n.t('LoanCounterOffer:CannotVerifyIncome'),
  [AdverseReasonCode.RiskyIncomeSource]: i18n.t('LoanCounterOffer:RiskyIncomeSource'),
  [AdverseReasonCode.NotEnoughHistory]: i18n.t('LoanCounterOffer:NotEnoughHistory'),
  [AdverseReasonCode.HighReturnedChecks]: i18n.t('LoanCounterOffer:HighReturnedChecks'),
  [AdverseReasonCode.TooManyInquiries]: i18n.t('LoanCounterOffer:TooManyInquiries'),
  [AdverseReasonCode.BadCashFlow]: i18n.t('LoanCounterOffer:BadCashFlow'),
  [AdverseReasonCode.PoorPfPerformance]: i18n.t('LoanCounterOffer:PoorPfPerformance'),
  [AdverseReasonCode.StackedLoans]: i18n.t('LoanCounterOffer:StackedLoans'),
  [AdverseReasonCode.SdbFailure]: i18n.t('LoanCounterOffer:SdbFailure'),
  [AdverseReasonCode.LimitedPfHistory]: i18n.t('LoanCounterOffer:LimitedPfHistory'),
  [AdverseReasonCode.Other]: i18n.t('LoanCounterOffer:Other'),
}

export const getAdverseReasonDescription = (
  reason: AdverseReasonCode = AdverseReasonCode.Other,
): string => {
  const reasonKey =
    adverseReasonCodeDescriptions[reason] || adverseReasonCodeDescriptions[AdverseReasonCode.Other]
  return reasonKey
}

export const isAmountOverOrEqualFiftyPercent = (
  amountBorrowed: number,
  amountRequested: number,
): boolean => {
  if (!amountBorrowed || !amountRequested) return false
  return amountBorrowed / amountRequested >= 0.5
}
