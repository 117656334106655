import React, {useCallback, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {Platform, View} from 'react-native'
import RNPickerSelect from 'react-native-picker-select'

import {DevBranchInfo, readUiDeploymentKey, setUiDeploymentKey} from 'src/lib/devMode'
import {reloadUI} from 'src/lib/utils/reload'
import Log from 'src/lib/loggingUtil'
import {PfReduxState} from 'src/reducers/types'
import {isDeviceAndroid} from 'src/lib/utils/platform'
import {nextId} from 'src/lib/componentId'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {GetGitBranchOverride} from 'src/lib/LaunchArgs/GitBranch'
import {getDonkeyUiBranches} from '@possible/cassandra/src/user/methods'
import {AppPlatform, DonkeyUiBranch} from '@possible/cassandra/src/types/types.public.generated'

type BranchInfo = {
  branches: Array<DonkeyUiBranch | null>
  currentBranch?: DonkeyUiBranch
  stagingBranch?: DonkeyUiBranch
}

export const setAndReloadDevBranch = async (selectedBranch: DonkeyUiBranch): Promise<void> => {
  await setUiDeploymentKey(selectedBranch.name, selectedBranch.deploymentKey)
  reloadUI()
}

const MrBranchSelector: React.FC<PropsFromRedux> = (props) => {
  const [branchInfo, setBranchInfo] = useState<BranchInfo>()
  const [selectedBranch, setSelectedBranch] = useState<DonkeyUiBranch>()
  const [generatedKey] = useState(nextId())
  const selectBranch = 'Select Branch'

  const onChangeBranch = useCallback((branch: DonkeyUiBranch) => {
    if (!branch) return
    //Android behaves differently then iOS. There isn't an equivalent onDone press like in iOS. So we
    //load the UI on press
    if (isDeviceAndroid()) {
      setAndReloadDevBranch(branch)
    } else {
      setSelectedBranch(branch)
    }
  }, [])

  const onChangeBranchDonePress = useCallback(async () => {
    if (!selectedBranch || !branchInfo?.currentBranch) return
    if (selectedBranch.name != branchInfo.currentBranch.name) {
      setAndReloadDevBranch(selectedBranch)
    }
  }, [selectedBranch, branchInfo])

  useEffect(() => {
    const call = async () => {
      if (!props.devMode) return

      try {
        const platform = Platform.OS.toLowerCase() === 'ios' ? AppPlatform.Ios : AppPlatform.Android
        const deployments = await getDonkeyUiBranches({platform, count: 20})
        if (deployments === null || deployments?.length === 0) {
          throw new Error('No branches found')
        }

        const devBranch: DevBranchInfo | undefined = await readUiDeploymentKey()

        const currentBranchName: string = devBranch?.name || 'Staging'
        const stagingBranch = deployments?.find((branch) => branch?.name === 'Staging') ?? undefined
        const currentBranch = deployments?.find((branch) => branch?.name === currentBranchName) || {
          name: currentBranchName + ' (NA)',
          deploymentKey: '',
        }

        setBranchInfo({
          branches: deployments,
          currentBranch,
          stagingBranch,
        })
        setSelectedBranch(currentBranch)

        // if a `gitBranchName` launch arg is present, override the current branch
        const branchOverride = GetGitBranchOverride()
        if (branchOverride) {
          const override = deployments?.find((branch) => branch?.name === branchOverride)
          if (override && override !== currentBranch) {
            void setAndReloadDevBranch(override)
          }
        }
      } catch (e) {
        Log.error(e, 'Failed to get branches')
        return
      }
    }
    call()
  }, [props.devMode])

  if (!branchInfo) return null

  return (
    <View testID="BranchSelectorID">
      <RNPickerSelect
        touchableWrapperProps={{testID: 'BranchSelector'}}
        textInputProps={{testID: 'BranchSelector'}}
        pickerProps={{testID: 'BranchSelector'}}
        key={generatedKey}
        onValueChange={onChangeBranch}
        onDonePress={onChangeBranchDonePress}
        items={branchInfo.branches
          .filter((branch) => branch?.name !== branchInfo.currentBranch?.name)
          .map((branch) => ({
            label: branch?.name || 'N/A',
            value: branch,
          }))}
        value={selectedBranch}
        placeholder={{label: branchInfo.currentBranch?.name || selectBranch}}
        style={{inputIOS: {textAlign: 'center'}}}
      >
        <PFText testID="Branch_Selector_label" variant="p">
          {branchInfo.currentBranch?.name || selectBranch}
        </PFText>
      </RNPickerSelect>
    </View>
  )
}

const mapStateToProps = (state: PfReduxState) => {
  return {
    devMode: state.api.dev_mode,
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MrBranchSelector)
