import React, {FC, useMemo} from 'react'

import {Animated, TextInputProps, TextStyle, ViewStyle} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import {LabelStyle, LabelWrapperStyle} from 'src/designSystem/components/atoms/PFFieldBase/styles'
import {NamedColors} from 'src/designSystem/colors'
import {
  error as errorColor,
  textInputLabelActiveColor,
  textInputDefaultColor,
  textInputEmptyColor,
} from 'src/designSystem/semanticColors'

export type LabelProps = TextInputProps & {
  label: string
  fieldState: PFFieldBaseStateTypes
  animationControl: Animated.Value
  placeholder?: string
}

const Label: FC<LabelProps> = ({label, fieldState, animationControl, placeholder}: LabelProps) => {
  const labelWrapperStyles: Animated.WithAnimatedValue<ViewStyle> = useMemo(
    () => LabelWrapperStyle(animationControl, placeholder),
    [animationControl, placeholder],
  )

  const labelTextStyles: Animated.WithAnimatedValue<TextStyle> = useMemo(
    () => LabelStyle(animationControl, fieldState, placeholder),
    [animationControl, fieldState, placeholder],
  )

  const getLabelColor = (): NamedColors => {
    switch (fieldState) {
      case PFFieldBaseStates.ERROR: {
        return errorColor
      }
      case PFFieldBaseStates.ACTIVE: {
        return textInputLabelActiveColor
      }
      case PFFieldBaseStates.EMPTY: {
        return textInputEmptyColor
      }
      default: {
        return textInputDefaultColor
      }
    }
  }

  return (
    <Animated.View style={labelWrapperStyles}>
      <PFText
        variant={fieldState === PFFieldBaseStates.EMPTY ? 'p' : 'label_sm'}
        color={getLabelColor()}
        animated={true}
        animationStyles={labelTextStyles}
      >
        {label}
      </PFText>
    </Animated.View>
  )
}

export default Label
