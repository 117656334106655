import React, {FC} from 'react'

import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

type Props = {
  component: React.FC<any>
}

const ScreenSelectorModuleContainer: FC<Props> = (props) => {
  const {component: MyComponent} = props

  return <MyComponent {...props} />
}

export default withForwardedNavigationParams<Props>()(ScreenSelectorModuleContainer)
