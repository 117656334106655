import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PageImage from 'src/designSystem/components/molecules/PageImage/PageImage'
import Page from 'src/designSystem/components/organisms/Page/Page'

export const PlaidMaintenance: FC = () => {
  const {t} = useTranslation(['MaintenanceInProgress'])

  return (
    <Page title={t('GiveUsAMoment')} description={t('PlaidMaintenance')} variant="generic">
      <PageImage image={require('../../../../../assets/illustrations/Maintenance.png')} />
    </Page>
  )
}

export default PlaidMaintenance
