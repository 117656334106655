import * as Types from '../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type BankAddAchPaymentMethodMutationVariables = Types.Exact<{
  input: Types.AchPaymentMethodInput
}>

export type BankAddAchPaymentMethodMutation = {
  __typename?: 'Mutation'
  bankAddAchPaymentMethod: {
    __typename?: 'AddPaymentMethodResponse'
    paymentMethod?:
      | {
          __typename?: 'AchPaymentMethod'
          id: string
          bankingPaymentInstrumentId: string
          status:
            | {__typename?: 'ExpiredPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'InvalidPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'PendingPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'RemovedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'SuspendedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'SystemPurgedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
            | {__typename?: 'VerifiedPaymentMethodStatus'; code: Types.PaymentMethodStatusCode}
          account?: {__typename?: 'LinkedAccount'; id: string} | null
          bankAccount: {
            __typename?: 'BankAccount'
            friendlyName: string
            mask: string
            institution?: {__typename?: 'Institution'; name: string} | null
          }
        }
      | {__typename?: 'CheckPaymentMethod'}
      | {__typename?: 'DebitCardPaymentMethod'}
      | null
    result: {__typename?: 'AddPaymentMethodResult'; type: Types.AddPaymentMethodResultType}
  }
}

export const BankAddAchPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'BankAddAchPaymentMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethodInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAddAchPaymentMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethod'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'AchPaymentMethod'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'bankingPaymentInstrumentId'},
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'account'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'bankAccount'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'friendlyName'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'institution'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                      ],
                                    },
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'result'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'type'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BankAddAchPaymentMethodMutation,
  BankAddAchPaymentMethodMutationVariables
>
