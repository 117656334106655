import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {NamedColors} from 'src/designSystem/colors'

//each array of items in the itemsLists array will be displayed as a column of requirements
type RequirementsListProps = {
  itemLists: Array<Array<RequirementsItemProps>>
}

export type RequirementsItemProps = {
  text: string
  complete: boolean
  testID?: string
}

const RequirementItem: FC<RequirementsItemProps> = React.memo(function RequirementItem({
  text,
  complete,
  testID,
}) {
  const completeIcon = <SvgIcon name={'checkWithCircle'} colorVariant={'success'} isFilled />
  const incompleteIcon = (
    <SvgIcon name={'circle'} colorVariant={'custom'} customColor={NamedColors.ASH} />
  )
  const textColor = complete ? 'textPrimary' : 'textDisabled'

  return (
    <View style={styles.item} testID={testID}>
      {complete ? completeIcon : incompleteIcon}
      <View style={styles.text}>
        <PFText variant={'p_sm'} color={textColor}>
          {text}
        </PFText>
      </View>
    </View>
  )
})

const RequirementsList: FC<RequirementsListProps> = (props: RequirementsListProps) => {
  const {itemLists} = props
  const columns = itemLists.map((columnItems, index) => {
    return (
      <View style={styles.column} key={'column' + index}>
        {columnItems.map(({...itemProps}) => {
          const {text, complete, testID} = itemProps
          return (
            <RequirementItem
              key={text}
              text={text}
              complete={complete}
              testID={complete ? `${testID}_complete` : `${testID}_incomplete`}
            />
          )
        })}
      </View>
    )
  })
  return <View style={styles.container}>{columns}</View>
}

const styles = StyleSheet.create({
  column: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 2,
  },
  item: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 2,
    width: '100%',
  },
  text: {
    flex: 1,
    paddingLeft: 4,
  },
})

export default RequirementsList
