import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Notifications from 'src/assets/illustrations/Notifications.svg'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type NotificationsPermissionsTemplateProps = {
  onPermissionsGranted: () => Promise<void>
  onDismiss: () => Promise<void>
}

const NotificationsPermissionsTemplate: React.FC<NotificationsPermissionsTemplateProps> = (
  props,
) => {
  const {onPermissionsGranted, onDismiss} = props
  const {t} = useTranslation(['NotificationsPermissions', 'Common'])

  const primaryAction: ButtonLockupPropsPrimary = {
    text: t('YesTurnOnNotifications'),
    onPress: onPermissionsGranted,
    testID: 'Notification-Permissions-Granted-Button',
    mode: 'primary',
  }

  const secondaryAction: ButtonLockupPropsSecondary = {
    buttonText: t('Common:NotNow'),
    onPress: onDismiss,
    testID: 'Notification-Permissions-Not-Now-Button',
    hideIcon: true,
  }

  const title = (
    <Box paddingRight={'enormous'} marginRight={'large'}>
      <PFText variant={'h1'} textAlign="left" color={NamedColors.BLACK}>
        {t('TurnOnNotifications')}
      </PFText>
    </Box>
  )

  const content = (
    <Box paddingHorizontal={'medium'} gap={'medium'}>
      <Box>
        <PFText variant="p">
          <Trans
            t={t}
            i18nKey={'GetRealTimeUpdatesOnApprovalStatus'}
            components={{
              bold: <PFText variant={'p_semibold'} color={NamedColors.BLACK} />,
            }}
          />
        </PFText>
      </Box>
      <Box>
        <PFSvgContain svg={Notifications} width={'100%'} />
      </Box>
    </Box>
  )

  return (
    <Page
      title={title}
      variant={'generic'}
      buttonProps={buttonLockupProperties(primaryAction, secondaryAction)}
      backgroundColor={NamedColors.SLATE}
      safeAreaBackground={NamedColors.SLATE}
      contentBackgroundColor={NamedColors.SLATE}
      noHorizontalPadding
      smallTopGap
    >
      {content}
    </Page>
  )
}

export {NotificationsPermissionsTemplate}
