import React from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  PaymentAction,
  PaymentInput,
  PaymentInputProps,
} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ButtonActionEventProps} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocPaymentAmountEntry.utils'
import {AdhocAmountEntryInfoCapsule} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocAmountEntryInfoCapsule'

export type AdhocPaymentAmountEntryTemplateProps = {
  buttonActionEventProps?: ButtonActionEventProps
  isAmountInputEditable?: boolean
  isValidPayment: boolean
  onPressContinue: () => void
  infoNoticeText?: string
} & PaymentInputProps

const AdhocPaymentAmountEntryTemplate: React.FC<AdhocPaymentAmountEntryTemplateProps> = (
  props,
): JSX.Element => {
  const {t} = useTranslation('AdhocPaymentAmountEntry')

  const {
    buttonActionEventProps,
    buttonActions,
    isAmountInputEditable,
    isValidPayment,
    onChange,
    onPressButtonAction,
    onPressContinue,
    onSubmitEditing,
    paymentValue,
    paymentTrackingId,
    infoNoticeText,
  } = props

  const handleOnChange = onChange
  const handleOnSubmitEditing = onSubmitEditing

  const handleOnPressButtonAction = (action: PaymentAction): void => {
    if (buttonActionEventProps) {
      TrackAppEvent(buttonActionEventProps.eventName, buttonActionEventProps.eventCategory, {
        adhoc_payment_type: action.trackingId,
        ...buttonActionEventProps.eventArgs,
      })
    }
    onPressButtonAction?.(action)
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('ContinueToPaymentReview'),
          onPress: onPressContinue,
          disabled: !isValidPayment,
          testID: 'amount-entry-cta-button',
        },
      }}
      variant={'interstitial'}
      enabledKeyboardAvoidingView
      banner={(): null => null}
    >
      <Box gap={'tiny'} paddingTop={'small'}>
        <PFText variant={'label_md'} textAlign={'center'}>
          {t('Title')}
        </PFText>
        <PaymentInput
          buttonActions={buttonActions}
          isAmountInputEditable={isAmountInputEditable}
          onChange={handleOnChange}
          onPressButtonAction={handleOnPressButtonAction}
          onSubmitEditing={handleOnSubmitEditing}
          paymentValue={paymentValue}
          paymentTrackingId={paymentTrackingId}
        />
        {infoNoticeText ? (
          <Box paddingTop={'medium'}>
            <AdhocAmountEntryInfoCapsule text={infoNoticeText} />
          </Box>
        ) : null}
      </Box>
    </Page>
  )
}

export {AdhocPaymentAmountEntryTemplate}
