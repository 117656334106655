import React, {FC, ReactNode} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'
import {flatMap} from 'lodash'

import {tileBackground, tileDividerBackground} from 'src/designSystem/semanticColors'

type TileListViewProps = {
  style?: ViewStyle
  children: ReactNode | ReactNode[]
}

const TileListView: FC<TileListViewProps> = (props) => {
  const {children, style} = props
  if (!Array.isArray(children)) {
    return <View style={[styles.view, style]}>{children}</View>
  }

  if (!children) {
    return null
  }

  const flattenedChildren = flatMap(children, (child, index, array) => {
    if (!child) return null
    const val = (
      <View style={styles.item_view} key={index}>
        {child}
      </View>
    )
    return array.length - 1 !== index // check for the last item
      ? [val, <View key={`${index}:space`} style={styles.spacer_view} />]
      : val
  })

  return <View style={[styles.view, style]}>{flattenedChildren}</View>
}

export default TileListView

const styles = StyleSheet.create({
  item_view: {
    backgroundColor: tileBackground,
  },
  spacer_view: {
    backgroundColor: tileDividerBackground,
    height: 0.5,
    width: '100%',
  },
  view: {},
})
