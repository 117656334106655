import {createSelector} from 'reselect'

import {CardAccount, CardPaymentMethodType, LinkedAccount, Maybe} from 'src/cassandra'
import {getDefaultPaymentMethodUtil} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {getActiveAccount, getAllPlaidLinkedBankAccounts} from 'src/lib/card/selectors'
import {PfReduxState} from 'src/reducers/types'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'

/**
 * Given a PaymentAccount, does this PaymentAccount have a Payment Instrument?
 */

export const getAllCardEligiblePaymentMethods = (state: PfReduxState): CardPaymentMethodType[] =>
  state.cassandra.user.me?.paymentMethods?.cardEligible ?? []

export const getDefaultPaymentMethod = createSelector<
  PfReduxState,
  Maybe<CardAccount>,
  CardPaymentMethodType[],
  LinkedAccount[],
  Maybe<PaymentAccount>
>(
  getActiveAccount,
  getAllCardEligiblePaymentMethods,
  getAllPlaidLinkedBankAccounts,
  (activeAccount, allCardEligiblePaymentMethods, allLinkedBankAccounts) => {
    return getDefaultPaymentMethodUtil(
      activeAccount,
      allCardEligiblePaymentMethods,
      allLinkedBankAccounts,
    )
  },
)

export const getSelectedPayNowPaymentMethod = (
  state: PfReduxState,
): CardPaymentMethodType | undefined => state.lib.card?.selectedPayNowPaymentMethod

/**
 * If a payment method has been selected for Pay Now, return it
 * Otherwise return the default payment method
 */
export const getPayNowPaymentMethod = createSelector(
  getDefaultPaymentMethod,
  getSelectedPayNowPaymentMethod,
  (
    defaultPaymentMethod: Maybe<PaymentAccount>,
    selectedPayNowPaymentMethod?: CardPaymentMethodType,
  ): PaymentAccount => {
    if (selectedPayNowPaymentMethod) {
      return selectedPayNowPaymentMethod
    } else if (defaultPaymentMethod) {
      return defaultPaymentMethod
    } else {
      return null
    }
  },
)
