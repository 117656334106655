import {GraphqlChaosResponseMock} from './slice'
import {ChaosState} from '../../reducer'

export const getChaosFailedQueries = (state: ChaosState): {[queryName: string]: boolean} =>
  state.graphqlChaos.failureQueries

export const getChaosFailedMutations = (state: ChaosState): {[queryName: string]: boolean} =>
  state.graphqlChaos.failureMutations

export const getChaosResponseMockMutations = (
  state: ChaosState,
): {[mutationName: string]: GraphqlChaosResponseMock} => state.graphqlChaos.mockResponseMutations
