import Firebase from 'src/lib/firebase/index.web'
import {fetchAndActivate, getValue, FetchStatus} from 'firebase/remote-config'
import {refreshInterval, defaultConfig} from 'src/lib/RemoteConfig/properties'

export const remoteConfigLib = Firebase.remoteConfig()

export const initializeRemoteConfig = async (refreshInterval: number): Promise<boolean> => {
  remoteConfigLib.settings.fetchTimeoutMillis = refreshInterval
  return fetchAndActivate(remoteConfigLib)
}

export const setDefaults = async (): Promise<null> => {
  return new Promise<null>((resolve) => {
    remoteConfigLib.defaultConfig = defaultConfig
    remoteConfigLib.settings.minimumFetchIntervalMillis = refreshInterval
    return resolve(null)
  })
}

export const verifyLastFetchStatusSucceeded = (lastFetchStatus: FetchStatus): boolean => {
  return lastFetchStatus === 'success'
}

export const getRemoteValueString = (key: string): string =>
  getValue(remoteConfigLib, key).asString()
