import React from 'react'
import {StyleSheet} from 'react-native'

import {LoanAcceptedPixelProps} from 'src/products/loans/LoanApprovedActivation/components/LoanAcceptedPixel/LoanAcceptedPixel.types'

const LoanAcceptedPixel: React.FC<LoanAcceptedPixelProps> = (props) => {
  const {loanId, loanAmount} = props
  const cb = Date.now()

  return (
    <img
      src={`https://px.mountain.com/sti?conv=1&shaid=36806&shoid=${loanId}&shoamt=${loanAmount}&cb=${cb}`}
      width={1}
      height={1}
      alt="mntn-pixel"
      style={styles.img}
    />
  )
}

export {LoanAcceptedPixel}

const styles = StyleSheet.create({
  img: {display: 'none'},
})
