import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MarketingSurveyGQLContainer} from 'src/products/general/MarketingSurvey/MarketingSurveyGQLContainer'
import {OnContinueAfterMarketingSurveyOptionSelected} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'

export type MarketingSurveyLegacyContainerProps = StackScreenProps<
  MainStackParamList,
  'LoansMarketingSurvey'
>

/**
 * Container to integrate the marketing survey as a direct route outside of MPO or Workflows.
 */
const MarketingSurveyLegacyContainer: React.FC<MarketingSurveyLegacyContainerProps> = (
  props: MarketingSurveyLegacyContainerProps,
) => {
  const {route} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.marketing_source_survey_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const handleOnContinue: OnContinueAfterMarketingSurveyOptionSelected = ({
    selectedSourceOption,
    otherValue,
  }) => {
    TrackAppEvent(AppEvents.Name.marketing_source_survey_selected, AppEvents.Category.Application, {
      value: selectedSourceOption,
      other: otherValue,
    })
    route.params.onComplete()
  }

  return <MarketingSurveyGQLContainer onContinue={handleOnContinue} />
}

export default MarketingSurveyLegacyContainer
