import {
  LoanGetPaymentsDocument,
  NextAvailablePaymentDateDocument,
  NextAvailablePaymentDateQuery,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'

import {LoanActionsLatestAndOriginalLoanInfoDocument} from 'src/api/actions/loans/LoanActions.gqls'
import {userIdSelector} from 'src/api/selectors/selectors'
import {DISBURSEMENT_METHOD_SELECTED, LOAN_TRANSFERS_UPDATE} from 'src/lib/loans/actions'
import {transferMethodsType} from 'src/lib/loans/consts'
import Log from 'src/lib/loggingUtil'
import {getPfStore} from 'src/store'
import {PfDispatch} from 'src/store/types'

/**
 * Retrieves a loan's transfers and dispatches an action to update redux
 * @throws error if graphql call isn't successful
 * @returns the loan transfers
 */
export const getLoanTransfers = async (loanId: string, dispatch: PfDispatch): Promise<void> => {
  const loanTransferResp = await ApplyQuery(LoanGetPaymentsDocument, {loanId})
  if (!loanTransferResp) {
    throw new Error(`Unable to make call for loan transers, loanId=${loanId}`)
  }

  const payments = loanTransferResp.data.loanGetPayments.payments.payments?.map((payment) => {
    return {
      ...payment,
      amount: Number(payment.amount),
      ...(payment.interest ? {interest: Number(payment.interest)} : {}),
      ...(payment.fees ? {fees: Number(payment.fees)} : {}),
      ...(payment.principal ? {principal: Number(payment.principal)} : {}),
    }
  })
  const loanTransfers = {payments, loanId}

  dispatch({type: LOAN_TRANSFERS_UPDATE, data: loanTransfers})
}

export async function getNextAvailableSettlementDate(
  loanId: string,
  timeNow: string,
  forDisbursement: boolean,
): Promise<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate'] | undefined> {
  const state = getPfStore().getState()
  const userId = userIdSelector(state)

  try {
    const responseData: NextAvailablePaymentDateQuery = (
      await ApplyQuery(NextAvailablePaymentDateDocument, {
        loanId,
        timeNow,
        forDisbursement,
        userId,
      })
    ).data

    if (!responseData) {
      throw new Error('Failed to retrieve next available settlement date')
    }

    return responseData['getNextAvailablePaymentDate']
  } catch (e) {
    Log.error(e, 'getNextAvailableSettlementDate error:')
  }
}

export async function getNextAvailableDisbursementDate(
  loanId: string,
  givenTimeNow: string,
): Promise<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate'] | undefined> {
  return getNextAvailableSettlementDate(loanId, givenTimeNow, true)
}

export function LoanApplicationSubmitted() {
  return async (dispatch: PfDispatch): Promise<void> => {
    await dispatch(UpdateLastLoan())
  }
}

export function UpdateLastLoan() {
  return async (dispatch: PfDispatch): Promise<void> => {
    const result = await ApplyQuery(LoanActionsLatestAndOriginalLoanInfoDocument)
    const loan = result.data.me.loans.latestActionableLoan
    const originalLoan = result.data.me.loans.latestActionableLoan?.originalLoan

    if (loan) {
      try {
        await getLoanTransfers(loan.id, dispatch)
      } catch (e) {
        Log.error(e, 'saveLoanTransfers error:')
      }

      if (originalLoan && loan.status.__typename === 'PendingLoanStatus') {
        try {
          await getLoanTransfers(originalLoan.id, dispatch)
        } catch (e) {
          Log.error(e, 'saveReplacementLoanTransfers error:')
        }
      }
    }
  }
}

export function UpdateDisbursementMethod(method: transferMethodsType) {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: PfDispatch): Promise<void> => {
    dispatch({type: DISBURSEMENT_METHOD_SELECTED, value: method})
  }
}
