import React from 'react'

import {ColorVariants} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type CardDashboardHeaderHeadingProps = {
  text: string
  textColor: ColorVariants
  iconColorVariant: SvgIconProps['colorVariant']
}

const CardDashboardHeaderHeading: React.FC<CardDashboardHeaderHeadingProps> = ({
  text,
  textColor,
  iconColorVariant,
}) => (
  <Box align={'center'} direction={'row'} justify={'start'} width={'100%'}>
    <Box marginRight={'tiny'}>
      <SvgIcon name={'lock'} colorVariant={iconColorVariant} />
    </Box>
    <PFText variant={'label_md'} color={textColor}>
      {text}
    </PFText>
  </Box>
)

export {CardDashboardHeaderHeading}
