import React, {PropsWithChildren} from 'react'

import {badConnectionSelector} from 'src/api/selectors/selectors'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {NetworkFailureError} from 'src/products/loans/NetworkFailureError/NetworkFailureError'
import {usePfSelector} from 'src/store/utils'

/**
 * Displays children if network connection is good. Displays error message instead if
 * network connection is bad.
 */
const NetworkFailureErrorNotifier: React.FC<PropsWithChildren> = (props) => {
  const {children} = props
  const hasNetworkError = usePfSelector(badConnectionSelector)

  if (hasNetworkError) {
    return <NetworkFailureError logo={true} backgroundColor={genericPageBackground} />
  }
  return children
}

export {NetworkFailureErrorNotifier}
