import React, {FC} from 'react'
import {View, FlexStyle, ViewStyle} from 'react-native'

import {transparent} from 'src/designSystem/semanticColors'
import {headerIconHeight} from 'src/nav/Header/HeaderConstants'
import {BasicSizeVariants} from 'src/designSystem/types'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Logo from 'src/assets/illustrations/PossibleLogo.svg'
import LightLogo from 'src/assets/illustrations/PossibleLogoWhite.svg'
import InvertedLogo from 'src/assets/illustrations/PossibleLogoInverted.svg'
import {SvgProps} from 'react-native-svg'

const imageSizeMap: {[key in BasicSizeVariants]: number} = {
  small: 22,
  medium: 24,
  large: 26,
}

type LogoProps = {
  size?: BasicSizeVariants
  align?: FlexStyle['alignItems']
  width?: number
  isLight?: boolean
  isInverted?: boolean
}

const NameLogo: FC<LogoProps> = (props) => {
  const {size, align, isLight, isInverted} = props

  const viewStyle: ViewStyle = {
    alignItems: align ?? 'center',
    backgroundColor: transparent,
    height: headerIconHeight,
    width: '100%',
  }

  const getLogo = (): FC<SvgProps> => {
    if (isLight) {
      return LightLogo
    }

    if (isInverted) {
      return InvertedLogo
    }

    return Logo
  }

  return (
    <View style={viewStyle}>
      <PFSvgContain svg={getLogo()} height={imageSizeMap[size ?? 'medium']} />
    </View>
  )
}

export {NameLogo}
