import React from 'react'

import {LessThan50DollarsModal} from 'src/products/card/Dashboard/CardDashboardPayment/LessThan50DollarsModal'
import {POTDelinquencyModal} from 'src/products/card/Dashboard/CardDashboardPayment/POTDelinquencyModal'
import {useWarningModals, WarningModalKind} from 'src/products/card/hooks/useWarningModals'

export const WarningModals = (): JSX.Element | null => {
  const {isModalVisible, setModalVisible} = useWarningModals()

  const handleOnDismiss = (callback?: () => void): void =>
    setModalVisible(() => {
      callback?.()

      return false
    })

  if (isModalVisible(WarningModalKind.LedgerSpentMinimum)) {
    return <LessThan50DollarsModal dismiss={handleOnDismiss} />
  }

  if (isModalVisible(WarningModalKind.POTDelinquency)) {
    return <POTDelinquencyModal onPress={handleOnDismiss} />
  }

  return null
}
