import {DefaultVariantsColor, NamedColors, isColorVariant} from 'src/designSystem/colors'
import {Color} from 'src/designSystem/types'
import Log from 'src/lib/loggingUtil'

const convertHexToRgbA = (hex: string, opacity = 1): string | undefined => {
  try {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      let c: string | string[] = hex.substring(1).split('')
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = `0x${c.join('')}`
      return `rgba(${[(Number(c) >> 16) & 255, (Number(c) >> 8) & 255, Number(c) & 255].join(
        ',',
      )},${opacity})`
    }

    throw new Error('Bad Hex')
  } catch (e) {
    Log.log('convertHexToRgbA threw error')

    return
  }
}

const isNamedColor = (color: Color | undefined): color is NamedColors => {
  if (!color || isColorVariant(color)) {
    return false
  }

  return true
}

const getColorValue = (color: Color | undefined): Color | undefined => {
  if (!color) return

  if (isColorVariant(color)) {
    return DefaultVariantsColor[color]
  }

  return color
}

export {convertHexToRgbA, getColorValue, isNamedColor}
