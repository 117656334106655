import React, {useEffect, useRef, useState} from 'react'
import {
  NativeSyntheticEvent,
  NativeTouchEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputProps,
} from 'react-native'

import {DefaultVariantsColor} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button, {ButtonMode} from 'src/designSystem/components/atoms/Button/Button'
import {selectionColor} from 'src/designSystem/components/atoms/PFFieldBase/styles'
import {VariantsStyle} from 'src/designSystem/typography'
import {PaymentAction} from 'src/products/general/components/molecules/PaymentInput/PaymentInput.types'

type PaymentInputProps = {
  buttonActions?: PaymentAction[]
  onChange: (text: string) => void
  onPressButtonAction?: (
    paymentAction: PaymentAction,
    event?: NativeSyntheticEvent<NativeTouchEvent>,
  ) => void
  /** Calls the function you pass before calling `onChange` */
  onSubmitEditing?: TextInputProps['onSubmitEditing']
  value: string
}

// This component is only used for the PaymentInput component in CardAdhocPaymentAmountEntry.tsx
// Loans AdhocPaymentAmountEntry use the PaymentInput component in general/AdhocPayment/PaymentInput/PaymentInput.tsx
// This component will be replaced with the general/AdhocPayment/PaymentInput/PaymentInput.tsx component
// when Card is updated to use the common components built along with the Loans Payments work
const PaymentInput = ({
  buttonActions,
  onChange,
  onPressButtonAction,
  onSubmitEditing,
  value: paymentValue,
}: PaymentInputProps): JSX.Element => {
  const inputRef = useRef<TextInput | null>(null)
  const [selectedButtonTrackingId, setSelectedButtonTrackingId] = useState<string | null>(null)
  const createOnPressHandler =
    (action: PaymentAction) => (event?: NativeSyntheticEvent<NativeTouchEvent>) => {
      onPressButtonAction?.(action, event)

      if (action.isFallback) {
        inputRef?.current?.focus()
      }
      setSelectedButtonTrackingId(action.trackingId)
      onChange(action.amount)
    }

  const handleOnChangeText = (text: string): void => {
    /* Cast `text` value to a `Number` to strip non-numerical values. There is probably a better
     * way to do this. */
    const validNumber = Number(text.replace('$', '')) || 0

    onChange(validNumber.toString())
  }

  const handleSelectedState = (action: PaymentAction): ButtonMode => {
    let mode: ButtonMode = 'secondary'
    if (selectedButtonTrackingId === action.trackingId) {
      mode = 'selectedTab'
    }

    return mode
  }
  useEffect(() => {
    const buttonValues = buttonActions?.map((action) => parseFloat(action.amount).toFixed(2))

    const selectedButton = buttonActions?.find((action) => {
      return (
        parseFloat(action.amount).toFixed(2) === parseFloat(paymentValue).toFixed(2) ||
        (action.isFallback && !buttonValues?.includes(parseFloat(paymentValue).toFixed(2)))
      )
    })
    setSelectedButtonTrackingId(selectedButton?.trackingId || null)
  }, [buttonActions, paymentValue])

  const handleOnSubmitEditing: TextInputProps['onSubmitEditing'] = (event) => {
    handleOnChangeText(paymentValue)
    onSubmitEditing?.(event)
  }

  return (
    <Box gap={'small'}>
      <TextInput
        cursorColor={DefaultVariantsColor['textPrimary']}
        keyboardType={'decimal-pad'}
        maxLength={7}
        onChangeText={handleOnChangeText}
        onSubmitEditing={handleOnSubmitEditing}
        ref={inputRef}
        enterKeyHint={'done'}
        selectionColor={selectionColor}
        testID="Input-Payment-Amount-Id"
        style={styles.input}
        value={`$${paymentValue}`}
      />
      {buttonActions ? (
        <Box gap={'small'} paddingHorizontal={'enormous'} paddingTop={'small'}>
          {buttonActions.map((action) => (
            <Button
              key={`${action.trackingId}${action.amount}`}
              mode={handleSelectedState(action)}
              onPress={createOnPressHandler(action)}
              size={'medium'}
              testID={action.trackingId}
              width={'100%'}
            >
              {action.label}
              {action.amount !== '0' ? ` - $${action.amount}` : ''}
            </Button>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

const styles = StyleSheet.create({
  input: {
    ...VariantsStyle.d2(DefaultVariantsColor['textPrimary']),
    /** `alignSelf` fixes an Android bug where the default input value shifts downward and gets
     *  cropped when an invalid character is entered multiple times 🙃*/
    alignSelf: 'stretch',
    paddingVertical: 0, // Fixes Android specific display bug.
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
    }),
  },
})

export {PaymentInput}
export type {PaymentAction, PaymentInputProps}
