import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {
  ProductChoiceTemplate,
  ProductChoiceTemplateProps,
} from 'src/products/loans/ProductChoice/ProductChoiceTemplate'
import {CardLevel} from 'src/products/loans/ProductChoice/types'
import {useGetCardOffers} from 'src/products/loans/ProductChoice/useGetCardOffers'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {LoanCountDocument} from 'src/products/loans/ProductChoice/LoanCount.gqls'
import {useIsInCardBureauFlow} from 'src/products/card/Application/useIsInCardBureauFlow'

type Props = Omit<
  ProductChoiceTemplateProps,
  'showCards' | 'cardLevels' | 'isInCardBureauFlow' | 'isUserLoanGrad'
>

export const ProductChoiceGQLContainer: React.FC<Props> = (props) => {
  const {handleOnCardPressed} = props
  const {cardOffers: offers} = useGetCardOffers()
  const {isInCardBureauFlow} = useIsInCardBureauFlow()

  const {selectedData: loanCount} = useCassandraQuery(
    LoanCountDocument,
    {},
    (data) => data?.me?.loans?.countApplied,
  )
  const isUserLoanGrad = !!(loanCount && loanCount > 0)

  const cardLevels: CardLevel[] = offers.map((offer) => ({
    creditAmount: Number(offer.balanceLimit),
    monthlyFee: Number(offer.monthlyMembershipFees),
  }))

  const onCardSelect = async (): Promise<void> => {
    TrackAppEvent(
      CardEvents.apply_for_card_selected,
      AppEvents.Category.Card,
      {
        card_limit: cardLevels?.[0]?.creditAmount,
      },
      true,
    )
    await handleOnCardPressed()
  }

  const showCards = !!(cardLevels && cardLevels.length > 0)

  return (
    <ProductChoiceTemplate
      {...props}
      showCards={showCards}
      cardLevels={cardLevels}
      handleOnCardPressed={onCardSelect}
      isInCardBureauFlow={isInCardBureauFlow}
      isUserLoanGrad={isUserLoanGrad}
    />
  )
}
