import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {LoanAcceptedPixel} from 'src/products/loans/LoanApprovedActivation/components/LoanAcceptedPixel/LoanAcceptedPixel'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type MoneyOnTheWayTemplateProps = {
  amountBorrowed: string
  disbursementSettlementDate: string
  isAch: boolean
  loanId: string
  onContinue: () => void | Promise<void>
}

const TitleWithIcon = (props: {
  isAch: boolean
  isAchTitle: string
  isNotAchTitle: string
}): JSX.Element => {
  const {isAch, isAchTitle, isNotAchTitle} = props
  return (
    <Box gap={'little'}>
      <SvgIcon name={'money'} colorVariant={'success'} size={'enormous'} />
      <PFText variant="h1">{!isAch ? isNotAchTitle : isAchTitle}</PFText>
    </Box>
  )
}

const Description = (props: {
  isAch: boolean
  isNotAchDescription: string
  disbursementSettlementDate: string
}): JSX.Element => {
  const {isAch, isNotAchDescription, disbursementSettlementDate} = props
  return (
    <PFText textAlign={'left'} variant={'p'}>
      {!isAch ? (
        <PFText variant={'p'}>{isNotAchDescription}</PFText>
      ) : (
        <PFText variant={'p'}>
          <Trans i18nKey={'MoneyOnTheWayDate'} values={{settleDateStr: disbursementSettlementDate}}>
            It should show up in your account by the end of the day on {''}
            <PFText variant={'p_semibold'}>{{disbursementSettlementDate}}</PFText>
          </Trans>
        </PFText>
      )}
    </PFText>
  )
}

export const MoneyOnTheWayTemplate: React.FC<MoneyOnTheWayTemplateProps> = (props) => {
  const {amountBorrowed, disbursementSettlementDate, isAch, loanId, onContinue} = props

  const {t} = useTranslation('LoanApproved')

  const handleOnContinue = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.your_money_is_on_the_way_completed, AppEvents.Category.Activation)
    await onContinue()
  }

  return (
    <Page
      variant={'generic'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('ContinueToDashboard'),
          testID: 'Money-On-The-Way-Continue-Button',
          onPress: handleOnContinue,
        },
      }}
      title={
        <TitleWithIcon
          isAch={isAch}
          isAchTitle={t('MoneyOnTheWay')}
          isNotAchTitle={t('CheckYourBankAccount')}
        />
      }
      description={
        <Description
          isAch={isAch}
          isNotAchDescription={t('MoneyOnTheWayInterchangeDate')}
          disbursementSettlementDate={disbursementSettlementDate}
        />
      }
      smallTopGap
      testID={'Money-On-The-Way-Template'}
    >
      <LoanAcceptedPixel loanId={loanId} loanAmount={amountBorrowed} />
    </Page>
  )
}
