import React, {FC, useMemo} from 'react'

import {BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {CardApplyTile} from 'src/products/card/LoanDash/CardApplyTile'
import {CardRejectedTile} from 'src/products/card/LoanDash/CardRejectedTile'
import {useGetCardOffers} from 'src/products/loans/ProductChoice/useGetCardOffers'
import {CardWaitListTile} from 'src/products/card/LoanDash/CardWaitListTile'
import {CardDashTileStatus} from 'src/products/card/LoanDash/utils'
import {CardReapplyTile} from 'src/products/card/LoanDash/CardReapplyTile'
import {CardExpiredTile} from 'src/products/card/LoanDash/CardExpiredTile'
import {useCardLoanDashboardTile} from 'src/products/card/LoanDash/UseCardLoanDashboardTile/useCardLoanDashboardTile'
import {CardDeactivatedTile} from 'src/products/card/LoanDash/CardDeactivatedTile'

export const CardLoanDashboardTile: FC<
  Pick<BaseCardProps, 'navigation' | 'tileMarginHorizontal' | 'tileRadius'>
> = ({navigation, tileMarginHorizontal, tileRadius}) => {
  const {defaultCardOffer} = useGetCardOffers()

  const {
    cardOfferStatus,
    shouldShowCardLoanDashboardTile,
    isLoading: isLoadingCardTile,
  } = useCardLoanDashboardTile()

  return useMemo(() => {
    const baseProps: BaseCardProps = {
      navigation,
      loading: false,
      tileMarginHorizontal,
      tileRadius,
    }
    if (!shouldShowCardLoanDashboardTile || isLoadingCardTile) {
      return null
    } else {
      if (cardOfferStatus === CardDashTileStatus.Apply) {
        return <CardApplyTile {...baseProps} creditLimit={defaultCardOffer?.balanceLimit ?? ''} />
      } else if (cardOfferStatus === CardDashTileStatus.WaitList) {
        return <CardWaitListTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Rejected) {
        return <CardRejectedTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Reapply) {
        return <CardReapplyTile {...baseProps} creditLimit={defaultCardOffer?.balanceLimit ?? ''} />
      } else if (cardOfferStatus === CardDashTileStatus.Expired) {
        return <CardExpiredTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Deactivated) {
        return <CardDeactivatedTile {...baseProps} />
      } else {
        // note: this shouldn't happen due to shouldShowCardLoanDashboardTile but
        // typescript compiler wants it to adhere to React.FC type
        return null
      }
    }
  }, [
    isLoadingCardTile,
    navigation,
    cardOfferStatus,
    defaultCardOffer,
    tileMarginHorizontal,
    tileRadius,
    shouldShowCardLoanDashboardTile,
  ])
}
