import {combineReducers} from 'redux'

import loans from 'src/lib/loans/reducers'
import {LoanState} from 'src/lib/loans/reducers/types'
import ura, {URAState} from 'src/lib/ura/reducers/uraReducers'
import card, {CardsState} from 'src/lib/card/slice'
import onboarding, {OnboardingState} from 'src/lib/onboarding/slice'

export interface LibReduxType {
  loans?: LoanState
  ura?: URAState
  card: CardsState
  onboarding: OnboardingState
}

export default combineReducers<LibReduxType>({
  loans,
  ura,
  card,
  onboarding,
})
