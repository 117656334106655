import {FieldErrors, LiteralUnion, RegisterOptions} from 'react-hook-form'

type FormFieldErrorType = {
  message: string
  type: LiteralUnion<keyof RegisterOptions, string>
}
const extractFieldError = (
  path: string,
  errors: FieldErrors,
  recursionDepth: number = 0,
): FormFieldErrorType | undefined => {
  const current = path.split('.')[0]
  const error = errors[current]
  if (recursionDepth < 10 && error) {
    if (
      'type' in error &&
      typeof error.type === 'string' &&
      'message' in error &&
      error.message &&
      typeof error.message === 'string'
    ) {
      return {type: error.type, message: error.message}
    } else {
      // @ts-expect-error We are moving up the tree, so although this new path
      // is not a full path, it is accurate relative to our position
      return extractFieldError(path.replace(`${current}.`, ''), error, recursionDepth + 1)
    }
  }

  return undefined
}

export {extractFieldError}
