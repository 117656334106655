import React, {FC, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import RequirementsList, {
  RequirementsItemProps,
} from 'src/designSystem/components/molecules/PFPasswordInput/RequirementsList'
import PFTextInput, {PFTextInputProps} from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type Props = {
  label: string
  showRequirements?: boolean
  onAllRequirementsMet?: (boolean) => void
  onMinCharactersMet?: (boolean) => void
} & PFTextInputProps

const maxCharacters = 99

const PFPasswordInput: FC<Props> = (props: Props) => {
  const {label, showRequirements, onAllRequirementsMet, onMinCharactersMet, ...otherProps} = props
  const [showIcon, setShowIcon] = useState(false)
  const [maskedPassword, setMaskedPassword] = useState(true)
  const [uppercaseComplete, setUppercaseComplete] = useState(false)
  const [lowercaseComplete, setLowercaseComplete] = useState(false)
  const [numberComplete, setNumberComplete] = useState(false)
  const [charactersComplete, setCharactersComplete] = useState(false)

  const {t} = useTranslation('PFPasswordInput')

  useEffect(() => {
    if (onAllRequirementsMet) {
      const requirementsMet =
        uppercaseComplete && lowercaseComplete && numberComplete && charactersComplete
      onAllRequirementsMet(requirementsMet)
    }
    if (onMinCharactersMet) {
      onMinCharactersMet(charactersComplete)
    }
  })

  const requirements: Array<Array<RequirementsItemProps>> = [
    [
      {text: t('UPPERCASE'), complete: uppercaseComplete, testID: 'password_uppercase'},
      {text: t('LOWERCASE'), complete: lowercaseComplete, testID: 'password_lowercase'},
    ],
    [
      {text: t('NUMBER'), complete: numberComplete, testID: 'password_number'},
      {text: t('CHARACTERS'), complete: charactersComplete, testID: 'password_characters'},
    ],
  ]

  const regexChecks = [
    {
      expression: /\d/,
      hook: setNumberComplete,
    },
    {
      expression: /[a-z]/,
      hook: setLowercaseComplete,
    },
    {
      expression: /[A-Z]/,
      hook: setUppercaseComplete,
    },
    {
      expression: /^.{8,}$/,
      hook: setCharactersComplete,
    },
  ]

  const onChangeText = (value: string): void => {
    checkIcon(value)
    checkRequirements(value)
    if (otherProps.onChangeText) {
      otherProps.onChangeText(value)
    }
  }

  const checkIcon = (value): void => {
    //only display the icon when there is a value in the field and don't override icon if it is already set
    const inputHasValue = value.trim().length !== 0
    if (inputHasValue && !showIcon) {
      setShowIcon(true)
    } else if (!inputHasValue) {
      setShowIcon(false)
    }
  }

  const checkRequirements = (value): void => {
    regexChecks.forEach((type) => {
      type.hook(type.expression.test(value.trim()))
    })
  }

  const getRequirements = (): JSX.Element | undefined => {
    if (!showRequirements) return undefined
    return <RequirementsList itemLists={requirements} />
  }

  const inputIcon: SvgIconProps = {
    name: maskedPassword ? 'reveal' : 'hide',
    colorVariant: 'active',
    size: 'medium',
  }

  //autoCompleteType is for android and textContentType is for iOS
  return (
    <Box gap="little">
      <PFTextInput
        {...otherProps}
        label={label}
        icon={showIcon ? inputIcon : undefined}
        onPressIcon={(): void | undefined => setMaskedPassword(!maskedPassword)}
        onChangeText={onChangeText}
        secureTextEntry={maskedPassword}
        maxLength={otherProps?.maxLength || maxCharacters}
        autoCompleteType="password"
        testID={props.testID ?? 'input_password'}
      />
      {getRequirements()}
    </Box>
  )
}

export default PFPasswordInput
