import {UserRequestedAction} from '@possible/cassandra/src/types/types.mobile.generated'
import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import * as user_actions from 'src/lib/ura/actions/uraActions'
import {URAUpdateActionProps} from 'src/lib/ura/actions/uraActions'
import {update_map_state} from 'src/reducers/utils'
import {PendingURAType} from '../types'

export type URAState = {
  ura: Map<string, PendingURAType>
  ura_ui: Map<string, URAUpdateActionProps[number]>
  compareUpdateTime?: boolean
  mode?: string
}

const URAStateDefault: URAState = {
  ura: new Map<string, PendingURAType>(),
  ura_ui: new Map<string, URAUpdateActionProps[number]>(),
}

type URAAction =
  | {type: typeof CLEAR_USER_ACTION}
  | {type: typeof user_actions.URA_UPDATE; ura: UserRequestedAction}
  | {
      type: typeof user_actions.URA_UI_UPDATE
      ura_ui: URAUpdateActionProps
      compareUpdateTime: boolean
      mode: string
    }

export default function reducer(state: URAState | undefined, action: URAAction): URAState {
  state = state ?? URAStateDefault

  switch (action.type) {
    case CLEAR_USER_ACTION:
      return URAStateDefault
    case user_actions.URA_UPDATE:
      return {
        ...state,
        ura: update_map_state(state.ura, action.ura, true, 'ReplaceRecord') as URAState['ura'],
      }
    case user_actions.URA_UI_UPDATE:
      return {
        ...state,
        ura_ui: update_map_state(
          state.ura_ui,
          action.ura_ui,
          action.compareUpdateTime,
          action.mode,
        ) as URAState['ura_ui'],
      }
    default:
      return state
  }
}
