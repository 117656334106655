import React, {FC, useState} from 'react'
import {cloneDeep} from 'lodash'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import moment from 'moment-timezone'
import {ApolloError} from '@apollo/client'

import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {getNextAvailableSettlementDate} from 'src/api/actions/loans/loanActions'
import {LoanPayment, NextAvailablePaymentDateQuery, StateCodes} from 'src/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {RescheduleLoanPaymentItemType} from 'src/products/loans/Reschedule/Reschedule.types'
import {SelectPaymentToRescheduleDocument} from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToReschedule.gqls'
import {SelectPaymentToRescheduleTemplate} from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToRescheduleTemplate/SelectPaymentToRescheduleTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {AppEvents} from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'

export type SelectPaymentToRescheduleGQLContainerProps = {
  onContinue: () => void
  navigation: StackScreenProps<MainStackParamList>['navigation']
}

const SelectPaymentToRescheduleGQLContainer: FC<SelectPaymentToRescheduleGQLContainerProps> = (
  props,
) => {
  const {navigation, onContinue: handleOnContinue} = props
  const {t} = useTranslation('Reschedule')

  const [nextAvailableSettlementDate, setNextAvailableSettlementDate] =
    useState<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate']>()

  const {
    selectedData,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(
    SelectPaymentToRescheduleDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        Log.log(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'ReschedulePayment query error',
        )
      },
      onCompleted: (data) => {
        const loanId = data.me.loans.latestActionableLoan?.id
        const timeZoneId = selectedData?.timeZoneId
        const timeNow = moment().tz(timeZoneId ?? 'America/Los_Angeles')

        if (!loanId) {
          return
        }
        void getNextAvailableSettlementDate(loanId, timeNow.format(), false)
          .then((response) => {
            setNextAvailableSettlementDate(response)
          })
          .catch((e) => {
            void logErrorAndShowException(e)
          })
      },
    },
    (data) => {
      let payments:
        | Pick<
            LoanPayment,
            'id' | 'amount' | 'originalDate' | 'rescheduledDate' | 'ordinal' | 'statusCode'
          >[]
        | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ActiveLoanAggregateStatus'
      ) {
        payments = data.me.loans.latestActionableLoan?.aggregateStatus.payments.payments
      }
      return {
        loanId: data.me.loans.latestActionableLoan?.id,
        timeZoneId: data.me.profile?.home?.timeZone?.id,
        payments: payments,
        stateCode: data.me.loans.latestActionableLoan?.state,
      }
    },
  )

  const timeZoneId = selectedData?.timeZoneId ?? 'America/Los_Angeles'
  const stateCode = selectedData?.stateCode
  const loanId = selectedData?.loanId
  const payments = selectedData?.payments

  const nextAvailSettlementDate = moment(
    nextAvailableSettlementDate?.adjustedSettlementDatetime,
  ).tz(timeZoneId)
  const nextAvailSettlementDateText = nextAvailSettlementDate.format('dddd, MMMM Do')

  const willRescheduleChangePaymentAmount = stateCode === (StateCodes.Tx || StateCodes.Oh)

  const handleOnEdit = (payment: RescheduleLoanPaymentItemType): void => {
    TrackAppEvent(
      AppEvents.Name.reschedule_payments_payment_selected,
      AppEvents.Category.ManageActiveLoan,
      {value: payment.ordinal},
    )

    if (!loanId) return

    navigation.navigate('ReschedulePayment', {
      loanId,
      payment: cloneDeep(payment),
    })
  }

  return (
    <BaseTemplate
      testID={'SelectPaymentToReschedule'}
      isError={!!queryError}
      isLoading={isLoadingQuery}
      pageTitle={t('NeedMoreTime')}
    >
      <SelectPaymentToRescheduleTemplate
        nextAvailSettlementDateText={nextAvailSettlementDateText}
        payments={payments ?? []}
        onContinue={handleOnContinue}
        willRescheduleChangePaymentAmount={willRescheduleChangePaymentAmount}
        onEdit={handleOnEdit}
      />
    </BaseTemplate>
  )
}

export {SelectPaymentToRescheduleGQLContainer}
