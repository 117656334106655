import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {usePreferredAccount} from 'src/lib/bank/usePreferredAccount'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivatedWorkflowContainer} from 'src/products/card/Activation/CardActivated/CardActivatedWorkflowContainer'
import {CardActivationAutopayScheduleWorkflowContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleWorkflowContainer'
import {CardAutopayOverviewWorkflowContainer} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewWorkflowContainer'
import {CardManualPayOverviewWorkflowContainer} from 'src/products/card/Activation/CardManualPayOverview/CardManualPayOverviewWorkflowContainer'
import {CardManualPayScheduleWorkflowContainer} from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleWorkflowContainer'
import {CardPaymentAgreementWorkflowContainer} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementWorkflowContainer'
import {useIsInCardBureauFlow} from 'src/products/card/Application/useIsInCardBureauFlow'
import {AddBankLinkWorkflowContainer} from 'src/products/general/AddBankLink/AddBankLinkWorkflowContainer'
import {ApplicationActivationWorkflowStackNav} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.consts'
import {WorkflowDebugPanel} from 'src/products/general/Workflows/WorkflowDebugPanel'
import {WorkflowLoading} from 'src/products/general/Workflows/WorkflowLoading'
import {PrimaryAccountSelectionWorkflowContainer} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionWorkflowContainer'
import {GetApplicationActivationWorkflowNavigationOptions} from 'src/workflows/navigation'
import {ApplicationActivationWorkflowStackParams} from 'src/workflows/types'

export const CardAccountApplicationActivationWorkflow: React.FC<
  StackScreenProps<MainStackParamList, 'ApplicationActivationWorkflow'>
> = (props) => {
  const {navigation} = props
  const {isInCardBureauFlow, isLoading} = useIsInCardBureauFlow()
  const {value: preferredAccount, isLoading: isPreferredAccountLoading} = usePreferredAccount()

  const handleOnScreenComplete = useCallback(
    (screenName: keyof ApplicationActivationWorkflowStackParams) => (): void => {
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      navigation.navigate('ApplicationActivationWorkflow', {
        screen: screenName,
      } as MainStackParamList['ApplicationActivationWorkflow'])
    },
    [navigation],
  )

  const handleOnWorkflowComplete = (): void => {
    navigation.reset({index: 0, routes: [{name: 'ProductHub'}]})
  }

  if (isLoading || isPreferredAccountLoading) {
    return <WorkflowLoading />
  }

  // Users in the bureau flow link a bank account in the first step of the activation flow
  const initialRouteName =
    isInCardBureauFlow && !preferredAccount
      ? 'CardCreditBureauFlowBankLinking'
      : 'CardAutopayOverview'

  return (
    <>
      <ApplicationActivationWorkflowStackNav.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{
          gestureEnabled: true,
          headerTransparent: true,
          headerShadowVisible: false,
          headerBackgroundContainerStyle: {
            backgroundColor: 'white',
          },
          headerMode: 'float',
        }}
      >
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardAutopayOverview"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardAutopayOverviewWorkflowContainer
              {...screenProps}
              onScreenComplete={handleOnScreenComplete('CardActivationAutopaySchedule')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>

        {/* BankLinking and PrimaryAccountSelection are only in the activation flow for the bureau flow */}
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardCreditBureauFlowBankLinking"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <AddBankLinkWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnScreenComplete(
                'CardCreditBureauFlowPrimaryAccountSelection',
              )}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardCreditBureauFlowPrimaryAccountSelection"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <PrimaryAccountSelectionWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnScreenComplete('CardAutopayOverview')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        {/* End mock bureau flow */}

        <ApplicationActivationWorkflowStackNav.Screen
          name="CardActivationAutopaySchedule"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardActivationAutopayScheduleWorkflowContainer
              {...screenProps}
              onScreenComplete={handleOnScreenComplete('CardAgreements')}
              onPressSecondary={handleOnScreenComplete('CardManualPayOverview')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardAgreements"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardPaymentAgreementWorkflowContainer
              {...screenProps}
              onPressSecondary={handleOnScreenComplete('CardActivationAutopaySchedule')}
              onScreenComplete={handleOnScreenComplete('CardActivated')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>

        {/* Manual pay flow */}
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardManualPayOverview"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardManualPayOverviewWorkflowContainer
              {...screenProps}
              onPressSecondary={handleOnScreenComplete('CardActivationAutopaySchedule')}
              onScreenComplete={handleOnScreenComplete('CardManualPaySchedule')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        <ApplicationActivationWorkflowStackNav.Screen
          name="CardManualPaySchedule"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardManualPayScheduleWorkflowContainer
              {...screenProps}
              onScreenComplete={handleOnScreenComplete('CardAgreements')}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>

        {/* End Manual pay flow */}

        <ApplicationActivationWorkflowStackNav.Screen
          name="CardActivated"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardActivatedWorkflowContainer
              {...screenProps}
              onScreenComplete={handleOnWorkflowComplete}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
      </ApplicationActivationWorkflowStackNav.Navigator>
      <WorkflowDebugPanel title="Card Activation Workflow Debug" />
    </>
  )
}
