import {PreReqType} from 'src/workflows/types'
import {PreReqSortFunctionType} from 'src/workflows/order/types'

/**
 * Generate a sort function given a specific order array.
 * @param order An array of pre-reqs in the order they should be fulfilled.
 * @returns A sort function that sorts `a` and `b` by `order`
 */
export const PreReqSortFunctionFactory =
  (order: PreReqType[]): PreReqSortFunctionType =>
  (a: PreReqType, b: PreReqType) => {
    let aIndex = -1
    let bIndex = -1

    for (let i = 0; i < order.length; ++i) {
      const req = order[i]
      if (req === a) {
        aIndex = i
      }
      if (req === b) {
        bIndex = i
      }

      if (aIndex !== -1 && bIndex !== -1) {
        break
      }
    }

    // send unrecognized pre-reqs to the end
    if (aIndex === -1) {
      return 1
    } else if (bIndex === -1) {
      return -1
    }

    if (aIndex < bIndex) {
      return -1
    } else if (aIndex > bIndex) {
      return 1
    } else {
      return 0
    }
  }
