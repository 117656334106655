// DO NOT USE THIS COMPONENT -- use the Loading component instead
import React, {FC} from 'react'
import {ActivityIndicator, View, StyleSheet} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {spinnerColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import Box from 'src/designSystem/components/atoms/Box/Box'

type Props = {
  description?: string
}

const Spinner: FC<Props> = ({description}) => {
  let descJsx: JSX.Element | null = null

  if (description) {
    descJsx = (
      <Box marginTop={smallGap}>
        <PFText variant="p" textAlign="center">
          {description}
        </PFText>
      </Box>
    )
  }

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={spinnerColor} testID="Activity-Indicator-Spinner" />
      {descJsx}
    </View>
  )
}

export default Spinner

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
})
