// define all available properties
export const RealtimeDbProperties = {
  MaintenanceMode: '/maintenance_support_active',
  MinimumBinaryVersion: '/minimum_binary_version',
} as const

// get a type that defines all available properties
export type RealtimeDbProperty = (typeof RealtimeDbProperties)[keyof typeof RealtimeDbProperties]

// type of callback function that will be called when the value changes
export type OnValueChangeCallback = (value: unknown) => void
