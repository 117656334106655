import React, {FC, useCallback} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'

import {Consumer} from 'src/cassandra'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {cvvFilter} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {PushPage} from 'src/navigation/NavHelper'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import Snackbar from 'src/lib/Snackbar'
import AppNav from 'src/nav/AppNavActions'
import Log from 'src/lib/loggingUtil'
import {ActivateCardEvent, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import PFPasswordInput from 'src/designSystem/components/molecules/PFPasswordInput/PFPasswordInput'
import {useCards} from 'src/products/card/hooks/useCards'

export type CardActivatePhysicalCardFormData = {
  securityCode: string
  ssn: string
}

type Props = StackScreenProps<MainStackParamList, 'CardActivatePhysicalCard'>

const CardActivatePhysicalCard: FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation(['CardActivatePhysicalCard', 'Common'])
  const [applyActivatePhysicalCard, {loading}] = Consumer.hooks.useCardActivatePhysicalMutation()
  const {cardAccount, pendingPhysicalCard} = useCards()
  const cardId = pendingPhysicalCard?.id
  const {id: cardAccountId, creditLimit} = cardAccount || {creditLimit: ''}
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<CardActivatePhysicalCardFormData>({
    mode: 'all',
  })
  usePageViewedAnalytics({
    eventName: CardEvents.card_physical_activation_cvc_ssn_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const formProps = {
    securityCode: {
      name: 'securityCode',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('SecurityCode')}),
        validate: (value) =>
          value?.length === 3 ? undefined : t('Common:LabelIsInvalid', {label: t('SecurityCode')}),
      },
    },
    ssn: {
      name: 'ssn',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('SecurityCode')}),
        minLength: {
          value: 4,
          message: t('Common:LabelMustBeXDigits', {
            label: t('Common:SSN/TIN'),
            count: 4,
          }),
        },
      },
    },
  }

  const checkIsValid = useCallback((): boolean => {
    return !loading && !getHasErrorsOrMissingValues(errors, watch, ['ssn', 'securityCode'])
  }, [loading, errors, watch])

  const onSubmit = useCallback(
    async (formValues): Promise<void> => {
      TrackAppEvent(CardEvents.card_physical_activation_cvc_ssn_page_cta, AppEvents.Category.Card)
      const {ssn, securityCode} = formValues

      Log.log('[debug] onsubmit', ssn, securityCode, cardId, cardAccountId)

      if (!cardAccountId || !cardId) {
        Snackbar.error({
          title: 'CardAccountId or CardId not found!',
          duration: Snackbar.LENGTH_LONG,
        })
        return
      }

      AppNav.pushToMainStack('CardActivatingPhysicalCard')

      const {data, errors: mutationErrors} = await applyActivatePhysicalCard({
        variables: {
          cardAccountId,
          cardId,
          cvv: securityCode,
          ssnMask: ssn,
        },
      })

      AppNav.pop(navigation)

      if (data?.cardActivatePhysical !== true) {
        Log.error(mutationErrors, '[debug] onsubmit err')
        PushPage(navigation, 'CardPhysicalCardActivationFailed')
      } else {
        ActivateCardEvent(creditLimit)
        PushPage(navigation, 'CardPhysicalCardActivated')
      }
    },
    [cardAccountId, cardId, creditLimit, navigation, applyActivatePhysicalCard],
  )

  return (
    <Page
      variant={'generic'}
      title={t('ActivateYourPossibleCard')}
      smallTopGap={true}
      contentVerticalAlignment="space-between"
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Activate'),
          disabled: !checkIsValid(),
          onPress: handleSubmit(onSubmit),
          loading,
        },
      }}
    >
      <Box>
        <HookForm control={control} errors={errors}>
          <Box marginBottom={'small'}>
            <PFTextInput
              label={t('CVC')}
              returnKeyType="done"
              keyboardType={'numeric'}
              formProps={formProps.securityCode}
              changeFilter={cvvFilter}
              maxLength={3}
              infoMessage={t('CVCInfo')}
              autoComplete={'off'}
            />
          </Box>
          <PFPasswordInput
            label={t('LastFourOfSsn')}
            returnKeyType="done"
            keyboardType="numeric"
            maxLength={4}
            formProps={formProps.ssn}
            infoMessage={t('LastFourOfSsnInfo')}
            autoComplete={'password-new'}
          />
        </HookForm>
      </Box>
      <Box>
        <PFInfoCapsule
          svgIcon={{name: 'copy', colorVariant: 'active', isFilled: true}}
          text={t('DontForget')}
        />
      </Box>
    </Page>
  )
}

export default CardActivatePhysicalCard
