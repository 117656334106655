import {
  getChaosFailedMutations,
  getChaosFailedQueries,
  getChaosResponseMockMutations,
} from './state/selectors'
import {GraphqlChaosResponseMock} from './state/slice'
import {chaosReduxStoreCache} from '../root-redux-store-cache'
import {ApolloLink} from '@apollo/client'
import {Observable} from 'zen-observable-ts'

const getMockResponse = (mockResponse: any): Response => {
  const blob = new Blob([JSON.stringify(mockResponse, null, 2)], {type: 'application/json'})
  return new Response(blob)
}

/**
 * (WORK IN PROGRESS - not ready for use yet)
 */
export const ChaosModeApolloLink = new ApolloLink((operation, forward) => {
  let operationName = ''
  // parse the operation name from the request body and simulate chaos / failure if it's
  // enabled for this mutation
  operationName = operation.operationName
  const chaosState = chaosReduxStoreCache.getChaosStateFromStore()
  if (!chaosState) {
    // if chaos state isn't set or configured don't modify this request
    return forward(operation)
  }
  const chaosFailedMutations: {
    [mutationName: string]: boolean
  } = getChaosFailedMutations(chaosState)
  const chaosFailedQueries: {
    [queryName: string]: boolean
  } = getChaosFailedQueries(chaosState)
  const chaosResponseMockMutations: {
    [mutationName: string]: GraphqlChaosResponseMock
  } = getChaosResponseMockMutations(chaosState)

  const shouldSimulateChaosFailureForThisMutationCall =
    operationName && chaosFailedMutations[operationName] === true
  const shouldSimulateChaosResponseMockForThisMutationCall =
    operationName && chaosResponseMockMutations[operationName] !== undefined
  const shouldSimulateChaosFailureForThisQueryCall =
    operationName && chaosFailedQueries[operationName] === true

  if (
    !shouldSimulateChaosFailureForThisMutationCall &&
    !shouldSimulateChaosResponseMockForThisMutationCall &&
    !shouldSimulateChaosFailureForThisQueryCall
  ) {
    // if chaos isnt enabled don't modify this request
    return forward(operation)
  }
  let response: Response | undefined = undefined
  // simluate query failure
  if (shouldSimulateChaosFailureForThisQueryCall) {
    console.log('@possible/chaos simulating query failure', operationName)
    response = getMockResponse({
      data: null,
      errors: [
        {
          message: `Chaos Mode simulated query failure for: ${operationName}`,
        },
      ],
    })
  }
  // simulate mutation failure
  if (shouldSimulateChaosFailureForThisMutationCall) {
    console.log('@possible/chaos simulating mutation failure', operationName)
    response = getMockResponse({
      data: null,
      errors: [
        {
          message: `Chaos Mode simulated mutation failure for: ${operationName}`,
        },
      ],
    })
  }
  // simulate mutation mock response
  if (shouldSimulateChaosResponseMockForThisMutationCall) {
    console.log(
      `@possible/chaos simulating mutation using mock response "${chaosResponseMockMutations[operationName]?.mockName}"`,
      operationName,
    )
    // short wait to simulate API call
    response = getMockResponse({
      data: chaosResponseMockMutations[operationName]?.mockResponse,
      errors: [
        {
          message: `Chaos Mode simulated query failure for: ${operationName}`,
        },
      ],
    })
  }
  if (response) {
    return new Observable((observer) => {
      operation.setContext({
        response,
        error: 'foo',
      })
      return () => {}
    })
  } else {
    return forward(operation)
  }
})
