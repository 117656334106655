import type {BrazeContentCard} from 'src/lib/braze/braze.utils'

type FilterBrazeContentCards<TProps> = (
  props: TProps,
) => (brazeContentCards: BrazeContentCard[]) => BrazeContentCard[]

type FilterBrazeContentCardsByScreenAndLocationProps = {
  location: BrazeContentCard['extras']['location']
  screenName: BrazeContentCard['extras']['screen']
}

/**
 * Based off the `FilterBrazeContentCards` type, this is the mostly commonly needed filter and sort
 * need for Braze Content Cards. Allows you to target and return data for a specific screen and
 * location in the App. Then applies data derived sorting from the `extras.order` field.
 */
const filterBrazeContentCardsByScreenAndLocation: FilterBrazeContentCards<
  FilterBrazeContentCardsByScreenAndLocationProps
> =
  ({location, screenName}) =>
  (brazeContentCards) => {
    return brazeContentCards
      .filter(
        (brazeContentCard) =>
          brazeContentCard.extras.screen === screenName &&
          brazeContentCard.extras.location === location &&
          brazeContentCard.type === 'Classic' &&
          !brazeContentCard.dismissed,
      )
      .sort((a, b) => {
        // If the data is missing a sort value place it at the end
        if (!a.extras.order || !b.extras.order) return a.extras.order ? -1 : 1

        // Otherwise use the sort order provided
        return a.extras.order - b.extras.order
      })
  }

export type {FilterBrazeContentCards, FilterBrazeContentCardsByScreenAndLocationProps}
export {filterBrazeContentCardsByScreenAndLocation}
