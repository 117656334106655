import React, {FC, useMemo} from 'react'

import DiagArrow from 'src/assets/illustrations/DiagArrow.svg'
import Calendar from 'src/assets/illustrations/descriptors/Calendar.svg'
import InFocus from 'src/assets/illustrations/descriptors/InFocus.svg'
import NoHands from 'src/assets/illustrations/descriptors/NoHands.svg'
import WellLit from 'src/assets/illustrations/descriptors/WellLit.svg'
import Bank from 'src/assets/illustrations/descriptors/bank.svg'
import BankFees from 'src/assets/illustrations/descriptors/bankFees.svg'
import BankFeesFailed from 'src/assets/illustrations/descriptors/bankFeesFailed.svg'
import Charge from 'src/assets/illustrations/descriptors/charge.svg'
import ChargedFees from 'src/assets/illustrations/descriptors/chargedFees.svg'
import ChargedFeesFailed from 'src/assets/illustrations/descriptors/chargedFeesFailed.svg'
import InfoWarning from 'src/assets/illustrations/descriptors/infoWarning.svg'
import Note from 'src/assets/illustrations/descriptors/note.svg'
import Refund from 'src/assets/illustrations/descriptors/refund.svg'
import Security from 'src/assets/illustrations/descriptors/security.svg'
import Shield from 'src/assets/illustrations/descriptors/shield.svg'
import Silhouette from 'src/assets/illustrations/descriptors/silhouette.svg'
import Time from 'src/assets/illustrations/descriptors/time.svg'
import TransactionSuccess from 'src/assets/illustrations/descriptors/transactionSuccess.svg'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {SizeVariants} from 'src/designSystem/types'
import {Extends} from 'src/lib/utils/typesUtil'

export type PFDescriptorProps = {
  name: PFDescriptorNames
  size?: PFDescriptorSizes | number
}

export type PFDescriptorNames =
  | 'bank'
  | 'bank-fees'
  | 'bank-fees-failed'
  | 'charge'
  | 'charged-fees'
  | 'charged-fees-failed'
  | 'refund'
  | 'security'
  | 'note'
  | 'time'
  | 'info-warning'
  | 'calendar'
  | 'in-focus'
  | 'no-hands'
  | 'well-lit'
  | 'transaction-success'
  | 'shield'
  | 'silhouette'
  | 'diag-arrow'

const descriptorIllustrationMap: {[key in PFDescriptorNames]: React.FC<any>} = {
  'bank': Bank,
  'bank-fees': BankFees,
  'bank-fees-failed': BankFeesFailed,
  'charge': Charge,
  'charged-fees': ChargedFees,
  'charged-fees-failed': ChargedFeesFailed,
  'refund': Refund,
  'note': Note,
  'security': Security,
  'time': Time,
  'info-warning': InfoWarning,
  'calendar': Calendar,
  'in-focus': InFocus,
  'no-hands': NoHands,
  'well-lit': WellLit,
  'shield': Shield,
  'silhouette': Silhouette,
  'transaction-success': TransactionSuccess,
  'diag-arrow': DiagArrow,
}

export type PFDescriptorSizes = Extends<SizeVariants, 'small' | 'medium' | 'large' | 'enormous'>

export const descriptorSizesMap: {[key in PFDescriptorSizes]: number} = {
  small: 16,
  medium: 24,
  large: 32,
  enormous: 40,
}

const getDescriptor = (name: PFDescriptorNames, size: number) => {
  const id = `${name}-descriptor`
  const descriptorProps = {
    height: size,
    width: size,
  }

  return <PFSvg id={id} SvgUri={descriptorIllustrationMap[name]} svgProps={descriptorProps} />
}

const PFDescriptor: FC<PFDescriptorProps> = (props) => {
  const {name, size = 'large'} = props
  const sizeValue = descriptorSizesMap[size] ?? size

  // tested this, and it reduces re-renders vs non-memoization
  return useMemo(() => getDescriptor(name, sizeValue), [name, sizeValue])
}

export default PFDescriptor
