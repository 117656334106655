import React from 'react'

import {
  OverlayContext,
  useOverlayContextValue,
} from 'src/designSystem/components/organisms/Overlay/OverlayContext'
import OverlayModal from 'src/designSystem/components/organisms/Overlay/OverlayModal'

/**
 * Provider that gives any children components access to the OverlayContext.
 * This should be used only once near the root of the app.
 */
export const OverlayProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const overlayContextValue = useOverlayContextValue()

  // render all current overlays
  const modalsToRender = Object.keys(overlayContextValue.overlays)
    .sort((overlayIdA, overlayIdB) => {
      // sort them by the newest modal on top
      if (
        overlayContextValue.overlays[overlayIdB].index >
        overlayContextValue.overlays[overlayIdA].index
      ) {
        return -1
      }
      return 1
    })
    .map((thisOverlayId) => {
      // render the base overlay component. by giving it the overlayId it will be able
      // to access its own overlay state from the OverlayContext
      return <OverlayModal key={thisOverlayId} overlayId={thisOverlayId} />
    })

  return (
    <OverlayContext.Provider value={overlayContextValue}>
      {children}
      {modalsToRender}
    </OverlayContext.Provider>
  )
}
