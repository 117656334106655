import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type AggregatorPlaidErrorPageProps = {
  onRetry: () => Promise<unknown> | void
}

const AggregatorPlaidErrorPage: React.FC<AggregatorPlaidErrorPageProps> = (props) => {
  const {onRetry} = props

  const {t} = useTranslation('AddBankLink')

  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList, keyof MainStackParamList>>()
  const handleContactUs = useCallback(() => {
    openContactUsForm(navigation)
  }, [navigation])

  return (
    <ErrorTemplate
      onContactUs={handleContactUs}
      onRetry={onRetry}
      errorTitle={t('ErrorEncounteredWhileLinking')}
    />
  )
}

export default AggregatorPlaidErrorPage
