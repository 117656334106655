import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import BouncingBack from 'src/assets/illustrations/BouncingBack.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import {CardAccountDashboardStatus} from 'src/products/card/types'
import {usePrevious} from 'src/lib/utils/hooks'

const CardUnlockedModal = ({
  accountStatus,
  ledgerSpent,
}: {
  accountStatus: CardAccountDashboardStatus | undefined
  ledgerSpent: string
}) => {
  const {t} = useTranslation('CardUnlockedModal')
  const [visible, setVisible] = useState(false)

  const previousAccountStatus: CardAccountDashboardStatus | undefined = usePrevious(accountStatus)
  const [overlayProps, setOverlayProps] = useState({
    title: t('YourCardIsNowUnlocked'),
    text: t('YourPaymentHasCleared'),
  })
  useEffect(() => {
    if (
      previousAccountStatus &&
      [
        CardAccountDashboardStatus.Delinquent,
        CardAccountDashboardStatus.DelinquentNSF,
        CardAccountDashboardStatus.DelinquencyReported,
        CardAccountDashboardStatus.DelinquencyReportedNSF,
      ].includes(previousAccountStatus) &&
      accountStatus === CardAccountDashboardStatus.Active
    ) {
      setOverlayProps({
        title: t('YourCardIsNowUnlocked'),
        text: t('YourPaymentHasCleared'),
      })
      setVisible(true)
    }
  }, [accountStatus, previousAccountStatus, t])
  useEffect(() => {
    if (
      previousAccountStatus &&
      [CardAccountDashboardStatus.SuspendedPayOverTime].includes(previousAccountStatus) &&
      Number(ledgerSpent) === 0 &&
      accountStatus === CardAccountDashboardStatus.Active
    ) {
      setOverlayProps({
        title: t('YourInstallmentsPaidOff'),
        text: t('SuccessfullyPaidOff'),
      })
      setVisible(true)
    }
  }, [accountStatus, previousAccountStatus, t, ledgerSpent])

  return (
    <Overlay
      visible={visible}
      image={<PFSvgContain svg={BouncingBack} height={100} width={100} />}
      okayButtonText={t('OkGotIt')}
      onDismiss={() => setVisible(false)}
      onOkay={() => setVisible(false)}
      textAlign={'left'}
      titleAlign={'left'}
      {...overlayProps}
      testID="CardUnlockedModal"
    />
  )
}

export default CardUnlockedModal
