import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonAction} from 'src/designSystem/types'
import ApplicationTile from 'src/products/loans/ProductChoice/ApplicationTile'
import DescriptionText from 'src/products/loans/ProductChoice/DescriptionText'

type PossibleLoanSelectionProps = {
  onTileClick: ButtonAction
  minimumAmount: number
  maximumAmount: number
}

const PossibleLoanSelection: React.FC<PossibleLoanSelectionProps> = (props) => {
  const {onTileClick: handleOnTileClick, minimumAmount, maximumAmount} = props
  const {t} = useTranslation(['LoanSelection', 'Common'])

  return (
    <Box align="center" gap="small">
      <Box align="center" gap="tiny">
        <PFText variant="h3">Possible Loan</PFText>
        <PFText variant="p_semibold">Get up to ${maximumAmount} cash.</PFText>
        <DescriptionText>{t('BuildCreditHistory')}</DescriptionText>
        <DescriptionText>{t('FlexibilityToPayBack')}</DescriptionText>
      </Box>

      <ApplicationTile
        header="Loan"
        creditAmount={`${minimumAmount}-${maximumAmount}`}
        onApplyClick={handleOnTileClick}
      />
    </Box>
  )
}

export default PossibleLoanSelection
