import React from 'react'
import {StyleSheet, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {defaultExtraHeightOffset, loginPageTitleWidth} from 'src/designSystem/guide'
import {contentMaxWidth, mediumGap, pageMarginHorizontal} from 'src/designSystem/layout'

type WelcomeHeaderProps = {
  label: string
}

export const WelcomeHeader: React.FC<WelcomeHeaderProps> = (props): JSX.Element => {
  const {label} = props
  return (
    <View style={styles.container}>
      <NameLogo size={'small'} align={'flex-start'} isInverted />
      <Box width={loginPageTitleWidth}>
        <PFText variant={'d3'} testID={'label_welcome'} color={'white'}>
          {label}
        </PFText>
      </Box>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: contentMaxWidth,
    paddingTop: defaultExtraHeightOffset,
    paddingHorizontal: pageMarginHorizontal,
    marginBottom: mediumGap,
  },
})
