import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanAmountSelectionEstimatedCostQueryVariables = Types.Exact<{
  amount: Types.Scalars['Decimal']['input']
}>

export type LoanAmountSelectionEstimatedCostQuery = {
  __typename?: 'Query'
  loanEstimatedCosts: {
    __typename?: 'LoanEstimatedCostsResponse'
    apr: string
    installmentAmount: string
    installmentCount: number
    totalCost: string
    totalOwed: string
  }
}

export type LoanAmountSelectionQueryVariables = Types.Exact<{
  assessmentDate: Types.Scalars['DateTime']['input']
}>

export type LoanAmountSelectionQuery = {
  __typename?: 'Query'
  loanGetPrequalification: {__typename?: 'LoanPrequalification'; amount: string}
  getLoanPreApplicationProfile: {
    __typename?: 'LoanPreApplicationProfile'
    defaultAmount: string
    maximumAllowedAmount: string
    minimumAllowedAmount: string
  }
}

export type LoanAmountSelectionSetLoanAmountMutationVariables = Types.Exact<{
  amount: Types.Scalars['Decimal']['input']
}>

export type LoanAmountSelectionSetLoanAmountMutation = {
  __typename?: 'Mutation'
  productApplicationUpdateRequirements: {
    __typename: 'ProductApplicationRequirementsResponse'
    success: boolean
  }
}

export const LoanAmountSelectionEstimatedCostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelectionEstimatedCost'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Decimal'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanEstimatedCosts'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'apr'}},
                {kind: 'Field', name: {kind: 'Name', value: 'installmentAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'installmentCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOwed'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanAmountSelectionEstimatedCostQuery,
  LoanAmountSelectionEstimatedCostQueryVariables
>
export const LoanAmountSelectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelection'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGetPrequalification'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'assessmentDate'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'amount'}}],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getLoanPreApplicationProfile'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'defaultAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'maximumAllowedAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'minimumAllowedAmount'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanAmountSelectionQuery, LoanAmountSelectionQueryVariables>
export const LoanAmountSelectionSetLoanAmountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'LoanAmountSelectionSetLoanAmount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Decimal'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'productApplicationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanAmount'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanAmountSelectionSetLoanAmountMutation,
  LoanAmountSelectionSetLoanAmountMutationVariables
>
