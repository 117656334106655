import React, {FC, ReactNode} from 'react'
import {StyleSheet, View} from 'react-native'
import {SvgProps} from 'react-native-svg'

import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {defaultIconSize} from 'src/designSystem/guide'
import {smallGap} from 'src/designSystem/layout'

export type ItemProps = {
  text: ReactNode
  icon: React.FC<SvgProps>
  isLast?: boolean
  iconWidth?: number
}

const IconFeatureListItem: FC<ItemProps> = (props) => {
  const {text, icon, iconWidth, isLast} = props

  const selectedSize = iconWidth ?? defaultIconSize
  const listItemStyle = [styles.listItem, isLast ? styles.lastListItem : {}]

  return (
    <View style={listItemStyle}>
      <View style={styles.iconView}>
        <PFSvg
          SvgUri={icon}
          svgProps={{width: selectedSize, height: '100%'}}
          manualWebResizeMethod={'width'}
        />
      </View>
      <View style={styles.textView}>
        <PFText variant={'p'}>{text}</PFText>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  iconView: {
    alignSelf: 'center',
    aspectRatio: 1,
  },
  lastListItem: {
    marginBottom: 0,
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: smallGap * 2,
  },
  textView: {
    flex: 1,
    marginLeft: smallGap,
  },
})

export default IconFeatureListItem
