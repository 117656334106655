import React from 'react'

import {formatMaskNonBreaking} from 'src/lib/user/utils'
import {
  getPaymentMethodFriendlyName,
  getPaymentMethodMask,
} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {
  ActionListItem,
  ActionListItemProps,
} from 'src/products/general/components/molecules/ActionListItem/ActionListItem'

import {PaymentMethodLinkAchSubset} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink.types'

type AchPaymentMethodLinkProps = Partial<ActionListItemProps> & {
  paymentMethod: PaymentMethodLinkAchSubset
}

const AchPaymentMethodLink = ({
  paymentMethod,
  titleStatusPill,
  rightStatusPill,
  ...props
}: AchPaymentMethodLinkProps): JSX.Element => {
  const friendlyName = getPaymentMethodFriendlyName(paymentMethod) ?? ''
  const accountMask = formatMaskNonBreaking(getPaymentMethodMask(paymentMethod), true)

  return (
    <ActionListItem
      isFirstChild
      subTitle={accountMask}
      title={friendlyName}
      titleStatusPill={titleStatusPill}
      rightStatusPill={rightStatusPill}
      {...props}
    />
  )
}

export {AchPaymentMethodLink}
