/* eslint-disable complexity */
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button, {ButtonProps} from 'src/designSystem/components/atoms/Button/Button'
import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {WarningModals} from 'src/products/card/Dashboard/CardDashboardPayment/WarningModals'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {useIsBalancePaidOff} from 'src/products/card/hooks/useIsBalancePaidOff'
import {usePayOverTimeNavigation} from 'src/products/card/hooks/usePayOverTimeNavigation'
import {CardAccountDashboardStatus} from 'src/products/card/types'

export type CardDashboardPaymentFooterPropsFromUseCards = Pick<
  useCardsType,
  'spentAmount' | 'eligibleForInstallments' | 'ledgerSpentAfterPayments' | 'cardAccount'
>
export type CardDashboardPaymentFooterProps = CardDashboardPaymentFooterPropsFromUseCards & {
  navigation: StackNavigationProp<MainStackNavigationProp>
  accountStatus: CardAccountDashboardStatus | undefined
}

type ButtonAction = {
  disabled: ButtonProps['disabled']
  mode: ButtonProps['mode']
  onPress: ButtonProps['onPress']
  text: string
}

type FooterKind =
  | 'latePayment'
  | 'payOverTime'
  | 'delinquency'
  | 'default'
  | 'deactivated'
  | 'chargedOff'
  | 'minPay'

type ButtonActionProps = {
  [key in FooterKind]: ButtonAction[]
}

type CtaButtonsProp = {
  actions: ButtonAction[]
}

const CtaButtons = ({actions}: CtaButtonsProp): JSX.Element => (
  <Box gap={'little'} marginTop={'medium'} width={'100%'} align="center">
    {actions.map(({text, ...props}) => (
      <Button key={JSON.stringify({text, ...props})} size={'medium'} width={'100%'} {...props}>
        {text}
      </Button>
    ))}
  </Box>
)

const CardDashboardPaymentFooter: React.FC<CardDashboardPaymentFooterProps> = (props) => {
  const isBalancePaidOff = useIsBalancePaidOff()
  const {spentAmount, eligibleForInstallments, navigation, accountStatus} = props

  const {selectedData: cardMinPayResponse, loading: isLoading} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay
  const handleOnPressPayOverTime = usePayOverTimeNavigation()

  const {t} = useTranslation('CardDashboardPaymentFooter')

  const isPOTDisabled = Number(spentAmount) <= 0 || eligibleForInstallments === false

  const handleOnPressAdhocPayment = (): void => navigation.push('CardAdhocPayment')

  const buttonActionProps: ButtonActionProps = {
    minPay: [
      {
        disabled: !cardMinPayResponse?.autopayEnabled,
        mode: 'secondary',
        onPress: () =>
          PushPage(navigation, 'CardActivationAutopaySchedule', {isRoutedFromDashboard: true}),
        text: t('Autopay Settings'),
      },
      {
        disabled: false,
        mode: 'primary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
    ],
    latePayment: [
      {
        disabled: isPOTDisabled,
        mode: 'primary',
        onPress: handleOnPressPayOverTime,
        text: t('SwitchToPayOverTime'),
      },
      {
        disabled: isBalancePaidOff,
        mode: 'secondary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
    ],
    payOverTime: [
      {
        disabled: isBalancePaidOff,
        mode: 'primary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
      {
        disabled: false,
        mode: 'secondary',
        onPress: () => PushPage(navigation, 'CardPayOverTimeSchedule'),
        text: t('ViewFullSchedule'),
      },
    ],
    delinquency: [
      {
        disabled: isPOTDisabled,
        mode: 'primary',
        onPress: handleOnPressPayOverTime,
        text: t('SwitchToPayOverTime'),
      },
      {
        disabled: isBalancePaidOff,
        mode: 'secondary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
    ],
    deactivated: [
      {
        disabled: isBalancePaidOff,
        mode: 'primary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
    ],
    chargedOff: [
      {
        disabled: isBalancePaidOff,
        mode: 'primary',
        onPress: handleOnPressAdhocPayment,
        text: t('MakeAPayment'),
      },
    ],
    default: [
      {
        disabled: isBalancePaidOff,
        mode: 'secondary',
        onPress: handleOnPressAdhocPayment,
        text: t('PayNow'),
      },
      {
        disabled: isPOTDisabled,
        mode: 'secondary',
        onPress: handleOnPressPayOverTime,
        text: t('PayOverTime'),
      },
    ],
  }

  const getFooter = (): JSX.Element => {
    if (isUserMinPayProgram) {
      return <CtaButtons actions={buttonActionProps.minPay} />
    }
    switch (accountStatus) {
      case CardAccountDashboardStatus.SuspendedPayOverTime:
        return <CtaButtons actions={buttonActionProps.payOverTime} />
      case CardAccountDashboardStatus.Active:
      case CardAccountDashboardStatus.SuspendedFraud:
      case CardAccountDashboardStatus.Overdue:
      case CardAccountDashboardStatus.AtRiskDelinquency:
      case CardAccountDashboardStatus.OverdueNSF:
      case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
        return <CtaButtons actions={buttonActionProps.latePayment} />
      case CardAccountDashboardStatus.Delinquent:
      case CardAccountDashboardStatus.DelinquencyReported:
      case CardAccountDashboardStatus.DelinquentNSF:
      case CardAccountDashboardStatus.DelinquencyReportedNSF:
        return <CtaButtons actions={buttonActionProps.delinquency} />
      case CardAccountDashboardStatus.Deactivated:
      case CardAccountDashboardStatus.DeactivatedDelinquent:
      case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
        return <CtaButtons actions={buttonActionProps.deactivated} />
      case CardAccountDashboardStatus.ChargedOff:
        return <CtaButtons actions={buttonActionProps.chargedOff} />
      default:
        return <CtaButtons actions={buttonActionProps.default} />
    }
  }

  return (
    <>
      <WarningModals />
      {isLoading ? null : getFooter()}
    </>
  )
}

export {CardDashboardPaymentFooter}
