import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CreateCassandraSelector} from '@possible/cassandra/src/utils/selectors'

import {LoanApplicationProcessingStatusDocument} from 'src/products/general/ApplicationProcessing/LoanApplicationProcessingStatus.gqls'

export type UseLoanApplicationProcessingStatusResult = {
  isLoanApproved: boolean
  isLoanExpired: boolean
  isLoanRejected: boolean
  isCounterOfferFlow: boolean
  isApplicationUWComplete: boolean
}

const LoanApplicationProcessingStatusSelector = CreateCassandraSelector(
  LoanApplicationProcessingStatusDocument,
  (data) => data.me.loans.latestActionableLoan?.status,
)

/**
 * This logic is copied from LoanProcessing.tsx
 */
export function useLoanApplicationProcessingStatus(
  shouldPoll: boolean,
): UseLoanApplicationProcessingStatusResult {
  const {selectedData: loanStatus, loading: isLoading} = useCassandraQuery(
    LoanApplicationProcessingStatusDocument,
    {pollInterval: shouldPoll ? 5000 : undefined},
    LoanApplicationProcessingStatusSelector,
  )

  const isCounterOfferFlow =
    loanStatus?.__typename === 'ApprovedLoanStatus' &&
    loanStatus.counterOfferReasonCodes.codes.length > 0

  return {
    isLoanApproved: !isCounterOfferFlow && loanStatus?.__typename === 'ApprovedLoanStatus',
    isLoanRejected: loanStatus?.__typename === 'RejectedLoanStatus',
    isLoanExpired: loanStatus?.__typename === 'ExpiredLoanStatus',
    isCounterOfferFlow,
    isApplicationUWComplete: !isLoading && loanStatus?.__typename !== 'PendingLoanStatus',
  }
}
