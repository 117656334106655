import React from 'react'

import OverlaySimple, {
  OverlaySimpleProps,
} from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

import {getCardPaymentErrorProps} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/getCardPaymentErrorProps'
import {getAchPaymentErrorProps} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/getAchPaymentErrorProps'
import {useTranslation} from 'react-i18next'

import {
  PaymentMethodsAlertModalErrorReason,
  PaymentType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'

type Props = {
  paymentType: PaymentType
  isInLoanApprovalFlow?: boolean
  reason?: PaymentMethodsAlertModalErrorReason
  onDismiss?: () => void
  onOkay?: () => void
}

export const PaymentMethodsAlertModals = ({paymentType, ...props}: Props): JSX.Element => {
  const {isInLoanApprovalFlow, reason, onDismiss: handleOnDismiss, onOkay: handleOnOkay} = props
  const {t} = useTranslation()

  let localizedErrorProps: Partial<OverlaySimpleProps> | undefined

  if (paymentType === 'CARD') {
    localizedErrorProps = getCardPaymentErrorProps({t, reason, isInLoanApprovalFlow})
  } else {
    localizedErrorProps = getAchPaymentErrorProps({t, reason})
  }

  return (
    <OverlaySimple
      testID="Payment-Methods-Alert-Modal"
      visible={!!reason}
      onOkay={handleOnOkay}
      {...(handleOnDismiss && {
        onDismiss: handleOnDismiss,
      })}
      {...localizedErrorProps}
    />
  )
}
