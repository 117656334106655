import React, {useContext} from 'react'

import {
  OverlayContextValue,
  OverlayVariantBaseProps,
} from 'src/designSystem/components/organisms/Overlay/Overlay.types'
import {OverlayStackedButtonsTemplateProps} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtonsTemplate'
import {AskToUpdateLoanAutoPayOverlayTemplate} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/AskToUpdateLoanAutoPayOverlay/AskToUpdateLoanAutoPayOverlayTemplate'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {OverlayContext} from 'src/designSystem/components/organisms/Overlay/OverlayContext'

/**
 * Type of the onSubmitUpdate() callback for showAskToUpdateLoanAutoPayOverlay() that allows the implementing
 * component to handle submitting the update.
 */
type AskToUpdateLoanAutoPayOverlyOnSubmitUpdate<OnSubmitUpdateResultType> = (args: {
  linkedAccountId: string
  updateActiveLoanPaymentMethod: boolean
}) => Promise<OnSubmitUpdateResultType>

/**
 * Type of the showAskToUpdateLoanAutoPayOverlay() function that allows a component to show the overlay.
 */
type ShowAskToUpdateLoanAutoPayOverly = <OnSubmitUpdateResultType>(args: {
  newLinkedAccountId: string
  newLinkedAccountMask: string
  currentLoanAutoPayAccountMask: string
  onSubmitUpdate: AskToUpdateLoanAutoPayOverlyOnSubmitUpdate<OnSubmitUpdateResultType>
}) => Promise<OnSubmitUpdateResultType>

/**
 * Allows a component to show an overlay that asks the user if they want to update their
 * loan auto pay method or not.
 */
const useAskToUpdateLoanAutoPayOverlay = (): {
  showAskToUpdateLoanAutoPayOverlay: ShowAskToUpdateLoanAutoPayOverly
} => {
  const {showOverlay, hideOverlay, updateOverlay} = useContext(OverlayContext)

  return {
    showAskToUpdateLoanAutoPayOverlay: <OnSubmitUpdateResultType,>(args: {
      newLinkedAccountId: string
      newLinkedAccountMask: string
      currentLoanAutoPayAccountMask: string
      onSubmitUpdate: AskToUpdateLoanAutoPayOverlyOnSubmitUpdate<OnSubmitUpdateResultType>
    }): Promise<OnSubmitUpdateResultType> => {
      const {
        onSubmitUpdate,
        newLinkedAccountId: newPrimaryLinkedAccountId,
        newLinkedAccountMask: newPrimaryAccountMask,
        currentLoanAutoPayAccountMask,
      } = args
      return new Promise((resolve, reject) => {
        showOverlay({
          overlayProps: getAskToUpdateLoanAutoPayOverlayProps<OnSubmitUpdateResultType>({
            newLinkedAccountId: newPrimaryLinkedAccountId,
            newLinkedAccountMask: newPrimaryAccountMask,
            currentLoanAutoPayAccountMask,
            onSubmitUpdate,
            hideOverlay,
            updateOverlay,
            resolveWhenDone: resolve,
            rejectWhenFailed: reject,
          }),
        })
      })
    },
  }
}

/**
 * Gets the props to construct the overlay that asks the user if they want to update their
 * loan auto pay method to the new primary account or not.
 */
const getAskToUpdateLoanAutoPayOverlayProps = <OnSubmitUpdateResultType,>(args: {
  isSubmittingConfirm?: boolean
  isSubmittingDeny?: boolean
  newLinkedAccountId: string
  newLinkedAccountMask: string
  currentLoanAutoPayAccountMask: string
  onSubmitUpdate: AskToUpdateLoanAutoPayOverlyOnSubmitUpdate<OnSubmitUpdateResultType>
  hideOverlay: OverlayContextValue['hideOverlay']
  updateOverlay: OverlayContextValue['updateOverlay']
  resolveWhenDone: (res: OnSubmitUpdateResultType) => void
  rejectWhenFailed: (error: Error) => void
}): OverlayVariantBaseProps & OverlayStackedButtonsTemplateProps => {
  const {
    newLinkedAccountMask: newPrimaryAccountMask,
    currentLoanAutoPayAccountMask,
    isSubmittingConfirm = false,
    isSubmittingDeny = false,
    newLinkedAccountId: newPrimaryLinkedAccountId,
    onSubmitUpdate,
    hideOverlay,
    updateOverlay,
    resolveWhenDone,
    rejectWhenFailed,
  } = args
  /**
   * Updates the overlay to show a submitting/loading state and submits the mutation to
   * set the primary account.
   */
  const handleSubmitMutationToSetPrimary = async (config: {
    shouldUpdateActiveLoanPaymentMethod: boolean
  }): Promise<void> => {
    const {shouldUpdateActiveLoanPaymentMethod} = config
    // while submitting we update the overlay to show the loading state
    updateOverlay({
      overlayProps: getAskToUpdateLoanAutoPayOverlayProps({
        ...args,
        isSubmittingConfirm: shouldUpdateActiveLoanPaymentMethod === true,
        isSubmittingDeny: shouldUpdateActiveLoanPaymentMethod === false,
      }),
    })
    // submit the mutation to set the primary account and update the current active loan's autopay
    // method to the new primary account (if the user chooses to)
    try {
      const setPrimaryAccountMutationRes = await onSubmitUpdate({
        linkedAccountId: newPrimaryLinkedAccountId,
        updateActiveLoanPaymentMethod: shouldUpdateActiveLoanPaymentMethod,
      })

      hideOverlay({
        overlayProps: askToChangeLoanAutoPayOverlayProps,
      })
      if (shouldUpdateActiveLoanPaymentMethod) {
        TrackAppEvent(
          BankAccountManagementEvents.account_management_primary_account_set_update_loan_autopay_confirm,
          AppEvents.Category.BankAccountManagement,
        )
      } else {
        TrackAppEvent(
          BankAccountManagementEvents.account_management_primary_account_set_update_loan_autopay_deny,
          AppEvents.Category.BankAccountManagement,
        )
      }
      resolveWhenDone(setPrimaryAccountMutationRes)
    } catch (e) {
      logAddPaymentMethodErrorAndShowException(
        e,
        `useSetPrimaryAccount failed to set primary account when checking if user wants to update loan autopay method, linkedAccountId=${newPrimaryLinkedAccountId} shouldUpdateActiveLoanPaymentMethod=${shouldUpdateActiveLoanPaymentMethod}`,
      )
      rejectWhenFailed(e instanceof Error ? e : new Error(String(e)))
    }
  }
  const askToChangeLoanAutoPayOverlayProps: OverlayVariantBaseProps &
    OverlayStackedButtonsTemplateProps = {
    testID: 'UseSetAccountAsPrimary-ChangeAutoPayOverlay',
    visible: true,
    children: (
      <AskToUpdateLoanAutoPayOverlayTemplate
        newAccountMask={newPrimaryAccountMask}
        currentLoanAutoPayAccountMask={currentLoanAutoPayAccountMask}
        isSubmittingConfirm={isSubmittingConfirm}
        isSubmittingDeny={isSubmittingDeny}
        onConfirmUpdate={(): void => {
          void handleSubmitMutationToSetPrimary({
            shouldUpdateActiveLoanPaymentMethod: true,
          })
        }}
        onDenyUpdate={(): void => {
          void handleSubmitMutationToSetPrimary({
            shouldUpdateActiveLoanPaymentMethod: false,
          })
        }}
      />
    ),
  }
  return askToChangeLoanAutoPayOverlayProps
}

export {useAskToUpdateLoanAutoPayOverlay}
