import {
  ExperimentNames,
  ExperimentNamesType,
  ExperimentValue,
  ExperimentValues,
} from 'src/lib/experimentation/amplitude/experimentTypes'
import Log from 'src/lib/loggingUtil'

type ExperimentsType = {
  [key in ExperimentNamesType]?: ExperimentValue
}

const ExperimentOverrides: ExperimentsType = {}
const GetExperimentOverride = (name: ExperimentNamesType): ExperimentValue | undefined => {
  const override = ExperimentOverrides[name]
  if (override) {
    Log.warnOnce(`[Launch Args] Experiment override for "${name}": "${override}"`)
  }
  return override
}

const parseExperimentString = (value: string): ExperimentsType => {
  const experiments: ExperimentsType = {}

  const experimentStrings = value.split(';')
  experimentStrings.forEach((experimentString) => {
    const [name, value] = experimentString.split('=')
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const trimmedName = name.trim() as ExperimentNamesType
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const trimmedValue = value.trim() as ExperimentValue
    if (ExperimentNames.includes(trimmedName) && ExperimentValues.includes(trimmedValue)) {
      experiments[trimmedName] = trimmedValue
    }
  })

  return experiments
}

const ExperimentsLaunchArgProcessor = (value: string): void => {
  const experiments = parseExperimentString(value)
  Object.assign(ExperimentOverrides, experiments)
}

export {ExperimentsLaunchArgProcessor, GetExperimentOverride}
