import {Maybe, Institution} from '@possible/cassandra/src/types/consumer'

import {ViewPaymentMethodsSubsets} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethods.types'
import {formatMaskNonBreaking} from 'src/lib/user/utils'
import Log from 'src/lib/loggingUtil'
import {PaymentMethodTypeForAnalytics} from 'src/products/general/GeneralPaymentMethods/types'
import i18n from 'src/lib/localization/i18n'
import {logErrorAndShowException} from 'src/lib/errors'

export const logAddPaymentMethodError = (error: unknown, message?: string): void => {
  const addPaymentSuffix = 'Add Payment Method'

  // Add suffix to error message
  if (error instanceof Error) {
    error.message = `${error.message} - ${addPaymentSuffix}`
  } else {
    // If for some reason, error is not an instance of Error, log the message with the suffix
    message = message ? `${message} - ${addPaymentSuffix}` : addPaymentSuffix
  }

  Log.error(error, message)
}

export const logAddPaymentMethodErrorAndShowException = (
  error: unknown,
  message?: string,
): void => {
  const addPaymentSuffix = 'Add Payment Method'

  // Add suffix to error message
  if (error instanceof Error) {
    error.message = `${error.message} - ${addPaymentSuffix}`
  } else {
    // If for some reason, error is not an instance of Error, log the message with the suffix
    message = message ? `${message} - ${addPaymentSuffix}` : addPaymentSuffix
  }
  void logErrorAndShowException(error, message)
}

export const getPaymentMethodInstitution = (
  paymentMethod?: ViewPaymentMethodsSubsets,
): Maybe<Pick<Institution, 'name'>> => {
  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return paymentMethod?.bankAccount.institution ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethod?.card?.institution ?? null
    default:
      return null
  }
}

export const getPaymentMethodFriendlyName = (
  paymentMethod?: ViewPaymentMethodsSubsets,
): Maybe<string> => {
  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return paymentMethod?.bankAccount?.friendlyName ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethod?.card?.friendlyName ?? null
    default:
      return null
  }
}

export const getPaymentMethodMask = (paymentMethod?: ViewPaymentMethodsSubsets): Maybe<string> => {
  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return paymentMethod?.bankAccount?.mask ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethod?.card?.mask ?? null
    default:
      return null
  }
}

export const getPaymentMethodLabel = (paymentMethod: ViewPaymentMethodsSubsets): string => {
  const friendlyName = getPaymentMethodFriendlyName(paymentMethod) ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''
  const formattedMask = formatMaskNonBreaking(accountMask, false)
  const cardEnding = i18n.t('PaymentMethods:CardEndingIn', {card: accountMask})

  switch (paymentMethod.__typename) {
    case 'AchPaymentMethod':
      return `${friendlyName} (${formattedMask})`
    case 'DebitCardPaymentMethod':
      return `${friendlyName}\n${cardEnding}`
    default:
      return ''
  }
}

export const isPrimaryAccount = (paymentMethod: ViewPaymentMethodsSubsets): boolean =>
  !!(
    paymentMethod.__typename === 'AchPaymentMethod' && paymentMethod.account?.preferredFundingSource
  )

export const getPaymentMethodTypeForAnalytics = (
  paymentMethod: ViewPaymentMethodsSubsets,
): PaymentMethodTypeForAnalytics | undefined => {
  if (paymentMethod?.__typename === 'DebitCardPaymentMethod') {
    return 'debit_card'
  } else if (paymentMethod?.__typename === 'AchPaymentMethod') {
    if (paymentMethod.account) {
      return 'ach_linked_with_aggregator'
    } else {
      return 'ach_routing_account'
    }
  }
}
