import {NotificationManager} from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const Snackbar = {
  show: ({title, duration}) => {
    NotificationManager.info(title, undefined, duration)
  },
  info: ({title, duration}) => {
    NotificationManager.info(title, undefined, duration)
  },
  success: ({title, duration}) => {
    NotificationManager.success(title, undefined, duration)
  },
  error: ({title, duration}) => {
    NotificationManager.info(title, undefined, duration)
  },
  warning: ({title, duration}) => {
    NotificationManager.success(title, undefined, duration)
  },
  LENGTH_LONG: 3.0 * 1000, // duration in ms
}

type SnackbarIfErrorResponse = {
  getErrorStr: () => string | undefined
}

export const snackbarIfError = (response: SnackbarIfErrorResponse | undefined) => {
  const errStr = response?.getErrorStr()

  if (errStr) {
    Snackbar.error({
      title: errStr,
      duration: Snackbar.LENGTH_LONG,
    })
    return true
  }
  return false
}

export default Snackbar
