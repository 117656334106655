import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {useDeeplinks} from 'src/lib/singular/utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardLandingWeb} from 'src/products/card/CardLandingWeb/CardLandingWeb'
import PartnerLanding from 'src/products/loans/Landing/PartnerLanding'
import {LoanLandingScreenContainer} from 'src/products/MCU/Landing/LoanLandingScreenContainer'
import TemporaryPassword from 'src/products/MCU/LoginTransition/TemporaryPassword'
import {Registration} from 'src/products/MCU/RegistrationOrLogin/Registration/Registration'

type Props = StackScreenProps<
  MainStackParamList,
  'Landing' | 'CardLandingWeb' | 'TemporaryPassword'
>

const Landing: React.FC<Props> = (props) => {
  const deeplinkCache = useDeeplinks()

  if (deeplinkCache.card_landing) {
    // @ts-expect-error We don't have a good way to type this yet
    return <CardLandingWeb {...props} />
  } else if (deeplinkCache.offer) {
    // @ts-expect-error We don't have a good way to type this yet
    return <PartnerLanding {...props} />
  } else if (deeplinkCache.account_recovery) {
    // @ts-expect-error We don't have a good way to type this yet
    return <TemporaryPassword {...props} />
  } else if (deeplinkCache.account_management) {
    // @ts-expect-error We don't have a good way to type this yet
    return <Registration {...props} />
  }

  // @ts-expect-error We don't have a good way to type this yet
  return <LoanLandingScreenContainer {...props} />
}

export {Landing}
