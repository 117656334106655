import React from 'react'
import {startCase, toLower} from 'lodash'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {SigningBlock} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/components/SigningBlock'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type LoanFinalAcceptTemplateProps = {
  firstName: string
  isLoanInCoastalState: boolean
  isSubmitting: boolean
  isExtendedInstallmentPlanConvertedLoan?: boolean
  lastName: string
  onPressAcceptLoan: () => Promise<void>
}

const useLoanFinalAcceptTitle = (
  isExtendedInstallmentPlanConvertedLoan: LoanFinalAcceptTemplateProps['isExtendedInstallmentPlanConvertedLoan'],
): string => {
  const {t} = useTranslation(['LoanFinalAccept', 'LoanApprovedExtendedPlan'])

  return isExtendedInstallmentPlanConvertedLoan
    ? t('LoanApprovedExtendedPlan:AcceptYourExtendedInstallmentPlan')
    : t('AcceptYourLoan')
}

const LoanFinalAcceptTemplate: React.FC<LoanFinalAcceptTemplateProps> = (props) => {
  const {t} = useTranslation(['LoanFinalAccept', 'LoanApprovedExtendedPlan'])
  const {
    firstName,
    isLoanInCoastalState,
    isSubmitting,
    isExtendedInstallmentPlanConvertedLoan,
    lastName,
    onPressAcceptLoan,
  } = props

  const description = isExtendedInstallmentPlanConvertedLoan
    ? t('LoanApprovedExtendedPlan:DigitalSignatureAcknowledges')
    : t('DigitalSignatureDescription')

  const isOnContinueDisabled = isSubmitting

  const primaryAction: ButtonLockupPropsPrimary = {
    disabled: isOnContinueDisabled,
    loading: isOnContinueDisabled,
    onPress: onPressAcceptLoan,
    testID: 'LoanAcceptanceButton',
    text: t('IAcceptThisLoan'),
  }

  return (
    <Page
      title={useLoanFinalAcceptTitle(isExtendedInstallmentPlanConvertedLoan)}
      variant={'generic'}
      smallTopGap={true}
      buttonProps={buttonLockupProperties(primaryAction)}
      testID="LoanFinalAcceptTemplate"
    >
      <Box gap="small">
        <PFText variant="p">{description}</PFText>
        <SigningBlock
          date={moment()}
          desc={t('AuthorizedRepresentative')}
          name={isLoanInCoastalState ? t('CoastalCommunityBank') : t('PossibleFinance')}
          signature={t('PossibleFinance')}
          title={t('Lender')}
        />
        <SigningBlock
          date={moment()}
          desc={t('BorrowerSignature')}
          name={startCase(toLower(`${firstName} ${lastName}`))}
          title={t('Borrower')}
        />
      </Box>
    </Page>
  )
}

export {LoanFinalAcceptTemplate, useLoanFinalAcceptTitle}
