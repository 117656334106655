import {PfReduxState} from 'src/reducers/types'
import {URAUpdateActionProps} from 'src/lib/ura/actions/uraActions'
import {PendingURAType} from 'src/lib/ura/types'

export function get_ura_by_id_from_redux(
  id: string,
  state: PfReduxState,
): PendingURAType | undefined {
  try {
    return state.lib.ura?.ura.get(id)
  } catch (e) {
    return undefined
  }
}

export function get_ura_ui_by_id_from_redux(
  id: string,
  state: PfReduxState,
): URAUpdateActionProps[number] | undefined {
  try {
    return state.lib.ura?.ura_ui.get(id)
  } catch (e) {
    return undefined
  }
}
