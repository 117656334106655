import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {ReactElement, ReactNode} from 'react'
import {ErrorBoundary} from 'react-error-boundary'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {
  ErrorTemplate,
  ErrorTemplateProps,
} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'

export type BaseTemplateProps = {
  children: ReactNode
  // in the future we can probably deprecate isError in favor of useSuspenseQuery and a boundary
  isError?: boolean
  isLoading?: boolean
  trackError?: {eventName: AppEventName; eventCategory: AppEventCategory}
  // if this is undefined there will not be an error boundary
  errorBoundaryFallback?: ReactElement | undefined
  onErrorBoundary?: (err: Error) => void
} & ErrorTemplateProps

const ErrorPageForBaseTemplate = (props: BaseTemplateProps): ReactElement => {
  const {testID, onContactUs} = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const handleOnContactUs = (): void => {
    openContactUsForm(navigation.getParent())
    onContactUs?.()
  }
  return (
    <ErrorTemplate {...props} onContactUs={handleOnContactUs} testID={`${testID}-ErrorTemplate`} />
  )
}

/**
 * Generic base template for a screen. Contains common re-usable UI for error states and loading states.
 */
const BaseTemplate = (baseTemplateProps: BaseTemplateProps): JSX.Element => {
  const {
    children,
    isError,
    isLoading,
    onContactUs: handleOnContactUs,
    pageTitle,
    testID = 'BaseTemplate',
    trackError,
    errorBoundaryFallback = (
      <ErrorPageForBaseTemplate
        testID={testID}
        onContactUs={handleOnContactUs}
        {...baseTemplateProps}
      />
    ),
    onErrorBoundary: handleOnErrorBoundary,
  } = baseTemplateProps

  if (isLoading) {
    return (
      <Page smallTopGap testID={`${testID}-LoadingIndicator`} title={pageTitle} variant={'generic'}>
        <Loading type={'loader0'} size={'large'} />
      </Page>
    )
  }

  if (isError) {
    if (trackError) {
      TrackAppEvent(trackError.eventName, trackError.eventCategory)
    }
    return (
      <ErrorPageForBaseTemplate
        testID={testID}
        onContactUs={handleOnContactUs}
        {...baseTemplateProps}
      />
    )
  }

  return errorBoundaryFallback ? (
    <ErrorBoundary fallback={errorBoundaryFallback} onError={handleOnErrorBoundary}>
      {children}
    </ErrorBoundary>
  ) : (
    <>{children}</>
  )
}

export {BaseTemplate}
