import React from 'react'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {CardManualPayScheduleDocument} from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleGQLContainer/CardManualPaySchedule.gqls'
import {
  getValidPaymentFrequency,
  getValidPaymentSchedule,
} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate.utils'
import {CardManualPayScheduleTemplate} from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleTemplate/CardManualPayScheduleTemplate'

type CardManualPayScheduleGQLContainerProps = {
  onComplete: () => void
}

const CardManualPayScheduleGQLContainer = ({
  onComplete: handleOnComplete,
}: CardManualPayScheduleGQLContainerProps): JSX.Element => {
  const {
    data,
    error: isError,
    loading: isLoading,
  } = useCassandraQuery(CardManualPayScheduleDocument)

  const paymentFrequency = getValidPaymentFrequency(
    data?.me.cardAccounts?.active?.payments?.potential?.frequency,
  )
  const paymentSchedule = getValidPaymentSchedule(
    data?.me.cardAccounts?.active?.payments?.potential?.payments,
  )

  return (
    <BaseTemplate isError={!!isError} isLoading={isLoading || !paymentSchedule}>
      <CardManualPayScheduleTemplate
        onPress={handleOnComplete}
        paymentFrequency={paymentFrequency}
        paymentSchedule={paymentSchedule ?? []}
      />
    </BaseTemplate>
  )
}

export {CardManualPayScheduleGQLContainer}
