import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardDocumentsQueryQueryVariables = Types.Exact<{
  startDate: Types.Scalars['LocalDate']['input']
}>

export type CardDocumentsQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        autopayEnabled: boolean
        createdAt: string
        agreements: {
          __typename?: 'CardAccountAgreementCollection'
          all: Array<{
            __typename?: 'CardAccountAgreement'
            id: string
            type: Types.CardAgreementType
            status: Types.CardAgreementStatus
          }>
        }
        statements: {
          __typename?: 'CardAccountStatementCollection'
          historical: {
            __typename?: 'CardAccountStatementPage'
            statements: Array<{
              __typename?: 'CardAccountStatement'
              id: string
              balance?: string | null
              statementDate: string
              paymentDueDate?: string | null
            }>
          }
        }
      } | null
    }
  }
}

export const CardDocumentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CardDocumentsQuery'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LocalDate'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'agreements'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'all'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'statements'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'historical'},
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {kind: 'Name', value: 'dateRange'},
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {kind: 'Name', value: 'start'},
                                              value: {
                                                kind: 'Variable',
                                                name: {kind: 'Name', value: 'startDate'},
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: {kind: 'Name', value: 'pageSelector'},
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {kind: 'Name', value: 'cursor'},
                                              value: {kind: 'NullValue'},
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: {kind: 'Name', value: 'count'},
                                              value: {kind: 'IntValue', value: '12'},
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'statements'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'balance'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'statementDate'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'paymentDueDate'},
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardDocumentsQueryQuery, CardDocumentsQueryQueryVariables>
