import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

import {OverlayStackedButtonsTemplate} from 'src/designSystem/components/organisms/Overlay/variants/OverlayStackedButtons/OverlayStackedButtonsTemplate'

export type AskToUpdateLoanAutoPayOverlayTemplateProps = {
  isSubmittingConfirm: boolean
  isSubmittingDeny: boolean
  currentLoanAutoPayAccountMask: string
  newAccountMask: string
  onConfirmUpdate: () => void
  onDenyUpdate: () => void
}

/**
 * Template for an overlay that asks the user if they would like to update their loan AutoPay account.
 */
const AskToUpdateLoanAutoPayOverlayTemplate: React.FC<
  AskToUpdateLoanAutoPayOverlayTemplateProps
> = (props: AskToUpdateLoanAutoPayOverlayTemplateProps) => {
  const {
    currentLoanAutoPayAccountMask,
    newAccountMask,
    isSubmittingConfirm,
    isSubmittingDeny,
    onConfirmUpdate,
    onDenyUpdate,
  } = props
  const {t} = useTranslation('AccountManagement')
  return (
    <OverlayStackedButtonsTemplate
      title={
        <>
          <Trans
            t={t}
            i18nKey={'AskToUpdateLoanAutoPay'}
            components={{
              title: <PFText variant="h2" />,
              lineBreak: <PFText variant="p">{'\n'}</PFText>,
              titleBlue: <PFText variant="h2" color={NamedColors.PRODUCT_BLUE} />,
            }}
            values={{
              currentLoanAutoPayAccountMask,
              newAccountMask,
            }}
          />
        </>
      }
      primary={{
        text: t('AskToUpdateLoanAutoPayConfirm'),
        onPress: (): void => {
          onConfirmUpdate()
        },
        disabled: isSubmittingConfirm || isSubmittingDeny,
        loading: isSubmittingConfirm,
      }}
      secondary={[
        {
          text: t('AskToUpdateLoanAutoPayDeny'),
          onPress: (): void => {
            onDenyUpdate()
          },
          disabled: isSubmittingConfirm || isSubmittingDeny,
          loading: isSubmittingDeny,
        },
      ]}
    />
  )
}

export {AskToUpdateLoanAutoPayOverlayTemplate}
