import {MutationResult} from '@apollo/client'

import {CardAgreementStatus, CardAgreementType, CardAccountAgreement, Consumer} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'

type MutationOptions = Parameters<
  ReturnType<typeof Consumer.hooks.useCardAccountAgreementsAcceptMutation>[0]
>[0]

type AcceptCardAgreementsMutation = (props: {
  cardAccountId: string
  isAutopay: boolean
  mutationOptions?: MutationOptions
  pendingAgreements: Pick<CardAccountAgreement, 'status' | 'type' | 'id'>[]
}) => Promise<void>

type UseAcceptCardAgreements = [
  mutate: AcceptCardAgreementsMutation,
  mutationResult: MutationResult<Consumer.types.CardAccountAgreementsAcceptMutation>,
]

const useAcceptCardAgreements = (): UseAcceptCardAgreements => {
  const [acceptAgreements, mutationResult] = Consumer.hooks.useCardAccountAgreementsAcceptMutation()

  const mutate: AcceptCardAgreementsMutation = async ({
    cardAccountId,
    isAutopay = true,
    mutationOptions,
    pendingAgreements,
  }) => {
    const pendingDocIds = pendingAgreements
      .filter(
        (agreement) =>
          agreement.status === CardAgreementStatus.Pending &&
          (isAutopay || agreement.type !== CardAgreementType.DebitAuthorizationAgreement),
      )
      .map((agreement) => agreement.id)

    if (pendingDocIds.length > 0) {
      await acceptAgreements({
        variables: {
          cardAccountId: cardAccountId,
          cardAccountAgreementId: pendingDocIds,
        },
        onError: (error) => {
          Log.log(error.message)
          throw new Error('Unable to accept agreements.')
        },
        ...mutationOptions,
      })
    }
  }

  return [mutate, mutationResult]
}

export {useAcceptCardAgreements}
