import {Platform, ViewStyle} from 'react-native'
import {shadowColorStackedButton} from 'src/designSystem/semanticColors'
import {convertHexToRgbA} from 'src/designSystem/lib/colorUtil'

export const ShadowStyle = (shadowOn: boolean): ViewStyle => {
  if (shadowOn) {
    return {
      ...Platform.select({
        ios: {
          shadowColor: shadowColorStackedButton,
          shadowOffset: {width: 0, height: 2},
          shadowOpacity: 0.25,
          shadowRadius: 10,
        },
        android: {
          elevation: 16,
        },
        web: {
          boxShadow: `0px -8px 30px ${convertHexToRgbA(shadowColorStackedButton, 0.1)}`,
        },
      }),
    }
  } else {
    return {}
  }
}
