import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'

import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'

type Props = {
  animating: boolean
  backgroundColor: string
}

const FullPageLoading: FC<Props> = ({animating, backgroundColor}) => {
  return (
    <SafeAreaView style={[styles.container, {backgroundColor}]}>
      <View style={styles.activityIndicatorView}>
        <ActivityIndicator animating={animating} />
      </View>
    </SafeAreaView>
  )
}

export default FullPageLoading

const styles = StyleSheet.create({
  activityIndicatorView: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  container: {
    flex: 1,
  },
})
