import React, {createContext} from 'react'

import {UsePaymentState, usePaymentState} from 'src/products/card/AdhocPayment/usePaymentState'

type AdhocPaymentProviderProps = {
  children: React.ReactNode
}

const CardAdhocPaymentContext = createContext<UsePaymentState | undefined>(undefined)

const CardAdhocPaymentProvider = ({children}: AdhocPaymentProviderProps): JSX.Element => {
  const paymentState = usePaymentState()
  return (
    <CardAdhocPaymentContext.Provider value={paymentState}>
      {children}
    </CardAdhocPaymentContext.Provider>
  )
}

export {CardAdhocPaymentContext, CardAdhocPaymentProvider}
