import React, {FC} from 'react'

import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'

type Props = {
  title: string
  description: string
  action: ButtonLockupPropsPrimary
  secondary: ButtonLockupPropsSecondary
  testID: string
}

export const StateDisclosureTemplate: FC<Props> = (props) => {
  const {title, description, action, secondary, testID} = props

  return (
    <Page
      noHeaderSpacer
      variant="generic"
      title={title}
      description={description}
      buttonProps={buttonLockupProperties(action, secondary)}
      testID={testID}
    />
  )
}
