/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  GraphqlChaosResponseMock,
  getChaosFailedMutations,
  getChaosResponseMockMutations,
  graphqlChaosActions,
} from '@possible/chaos'
import React, {useState} from 'react'
import {StyleSheet} from 'react-native'
import Collapsible from 'react-native-collapsible'
import {TouchableOpacity} from 'react-native-gesture-handler'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {AllMutationResponseMocks} from 'src/products/general/VersionFooter/ChaosModeSettings/ChaosModeOperationsConfig'

import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch, usePfSelector} from 'src/store/utils'

/**
 * Toggle for enabling/disabling chaos mode for a specific mutation. Lets the user
 * toggle request failure, or they can select any of the response mocks that are configured
 * for this mutation.
 */
export const MutationChaosToggle: React.FC<{
  mutationName: string
}> = (props) => {
  const {mutationName} = props
  const dispatch = usePfDispatch()
  const chaosFailureMutations = usePfSelector(
    (
      state: PfReduxState,
    ): {
      [mutationName: string]: boolean
    } => {
      return getChaosFailedMutations(state.chaos)
    },
  )
  const chaosResponseMockEnabledMutations = usePfSelector(
    (
      state: PfReduxState,
    ): {
      [mutationName: string]: GraphqlChaosResponseMock
    } => {
      return getChaosResponseMockMutations(state.chaos)
    },
  )

  const isChaosEnabledForThisMutation =
    chaosFailureMutations[mutationName] || chaosResponseMockEnabledMutations[mutationName]

  const thisMutationMockResponses: GraphqlChaosResponseMock[] =
    AllMutationResponseMocks[mutationName] || []

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const handleOnToggleExpanded = (): void => {
    setIsExpanded(!isExpanded)
  }
  const handleOnToggleMutationFailure = (mutationName: string) => (checked: boolean) => {
    if (checked) {
      dispatch(graphqlChaosActions.enableChaosFailureForMutation({mutationName}))
    } else {
      dispatch(graphqlChaosActions.disableChaosFailureForMutation({mutationName}))
    }
    // disable any mocks
    dispatch(graphqlChaosActions.disableChaosResponseMockForMutation({mutationName}))
  }
  const handleOnToggleMutationResponseMock =
    (mutationName: string, mock: GraphqlChaosResponseMock) => (checked: boolean) => {
      dispatch(graphqlChaosActions.disableChaosFailureForMutation({mutationName}))
      if (checked) {
        // disable failure when enabling a mock
        dispatch(
          graphqlChaosActions.enableChaosResponseMockForMutation({
            mutationName,
            mockName: mock.mockName,
            mockResponse: mock.mockResponse,
          }),
        )
      } else {
        dispatch(graphqlChaosActions.disableChaosResponseMockForMutation({mutationName}))
      }
    }

  return (
    <>
      <TouchableOpacity onPress={handleOnToggleExpanded}>
        <Box direction="row" align="center" gap="tiny">
          <SvgIcon
            name={isExpanded ? 'chevronUp' : 'chevronDown'}
            colorVariant={isChaosEnabledForThisMutation ? 'error' : 'active'}
            size="small"
          />
          <PFText variant="p_sm">{mutationName}</PFText>
        </Box>
      </TouchableOpacity>
      <Collapsible collapsed={!isExpanded}>
        <Box paddingLeft={'small'} boxStyle={MutationChaosToggleStyles.collapseBox}>
          <PFCheckbox
            checked={chaosFailureMutations[mutationName] === true}
            onPress={handleOnToggleMutationFailure(mutationName)}
            size={'Small'}
          >
            Fail request
          </PFCheckbox>
          {thisMutationMockResponses.map((thisMock: GraphqlChaosResponseMock) => {
            return (
              <PFCheckbox
                key={`${mutationName}-${thisMock.mockName}`}
                checked={
                  chaosResponseMockEnabledMutations[mutationName]?.mockName === thisMock.mockName
                }
                onPress={handleOnToggleMutationResponseMock(mutationName, thisMock)}
                size={'Small'}
              >
                Mock: {thisMock.mockName}
              </PFCheckbox>
            )
          })}
        </Box>
      </Collapsible>
    </>
  )
}
const MutationChaosToggleStyles = StyleSheet.create({
  collapseBox: {
    borderLeftColor: NamedColors.PRODUCT_BLUE,
    borderLeftWidth: 2,
  },
})
