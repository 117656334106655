import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  BankRoutingAndAccountFormPageContainer,
  BankRoutingAndAccountFormPageContainerProps,
} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageContainer'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'

export type BankAddRoutingAndAccountPropsFromRouter = Pick<
  StackScreenProps<MainStackParamList & LoanPayNavigatorStack, 'BankAddRoutingAndAccount'>,
  'route'
>
/**
 * Screen to add a new bank account by entering routing + account number manually without Plaid.
 */
export const BankAddRoutingAndAccount: React.FC<BankAddRoutingAndAccountPropsFromRouter> = (
  props: BankAddRoutingAndAccountPropsFromRouter,
) => {
  const {route} = props
  const {onSuccessRouteDestination} = route.params
  const {t} = useTranslation(['Bank', 'Common'])

  usePageViewedAnalytics({
    eventName: BankAccountManagementEvents.bank_add_routing_and_account_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
  })

  const handleOnSuccess: BankRoutingAndAccountFormPageContainerProps['onSuccess'] = () => {
    TrackAppEvent(
      BankAccountManagementEvents.add_new_payment_account_completed,
      AppEvents.Category.BankAccountManagement,
      {
        type: 'routing_account',
      },
    )
  }

  const handleOnFailure: BankRoutingAndAccountFormPageContainerProps['onFailure'] = ({
    errorReason,
  }) => {
    TrackAppEvent(
      BankAccountManagementEvents.add_new_payment_account_failed,
      AppEvents.Category.BankAccountManagement,
      {
        reason: errorReason,
        type: 'routing_account',
      },
    )
  }

  const handleOnErrorBoundary: BankRoutingAndAccountFormPageContainerProps['onErrorBoundary'] = (
    error,
  ) => {
    TrackAppEvent(
      BankAccountManagementEvents.bank_add_routing_and_account_error_viewed,
      AppEvents.Category.BankAccountManagement,
      {
        error: error.message,
      },
    )
    logAddPaymentMethodError(error, 'BankAddRoutingAndAccount render failed and threw exception')
  }
  return (
    <BankRoutingAndAccountFormPageContainer
      title={t('AddBankDetails')}
      description={t('YouCanFindThisInfoWithBank')}
      successRouteDestination={onSuccessRouteDestination}
      onSuccess={handleOnSuccess}
      onFailure={handleOnFailure}
      onErrorBoundary={handleOnErrorBoundary}
    />
  )
}
