import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {ProgressBarSegmentStatus} from 'src/designSystem/components/molecules/ProgressBar/ProgressBarSegment'

type ProgressBarIndicatorProps = {
  accessibilityLabelNamespace: string
  position: number
  type: ProgressBarSegmentStatus
}

type WarningIconFixProps = {
  children: React.ReactNode
  type: ProgressBarIndicatorProps['type']
}

const WarningIconFix = ({children, type}: WarningIconFixProps): JSX.Element =>
  type === ProgressBarSegmentStatus.Warning ? (
    <View style={styles.warningIconFix}>{children}</View>
  ) : (
    <>{children}</>
  )

/**
 * Icon displayed at the end of the `ProgressBarSegment`s (read: tip of the progress bar).
 */
const ProgressBarIndicator = ({
  accessibilityLabelNamespace,
  position,
  type,
}: ProgressBarIndicatorProps): JSX.Element => {
  const {t} = useTranslation(accessibilityLabelNamespace)

  const mapTypeToSvgIconProps = (
    type: ProgressBarIndicatorProps['type'],
  ): Pick<Parameters<typeof SvgIcon>[0], 'name' | 'colorVariant'> | null => {
    switch (type) {
      case ProgressBarSegmentStatus.Success:
        return {name: 'checkWithCircle', colorVariant: 'success'}
      case ProgressBarSegmentStatus.Warning:
        return {name: 'warning', colorVariant: 'warning'}
      case ProgressBarSegmentStatus.Error:
        return {name: 'error', colorVariant: 'error'}
      default:
        return null
    }
  }

  const svgIconProps = mapTypeToSvgIconProps(type)

  return svgIconProps !== null ? (
    <View style={styles.wrapper}>
      <View
        aria-hidden
        style={{
          width: `${position * 100}%`,
        }}
      />
      <View
        accessible={true}
        accessibilityLabel={t(`indicatorAccessibilityLabel.${ProgressBarSegmentStatus[type]}`, {
          keySeparator: '.',
        })}
        role={'status'}
        style={[
          styles.indicator,
          position > 0 ? styles.indicatorOffset : {},
          position === 1 ? styles.indicatorOffsetEnd : {},
        ]}
      >
        <WarningIconFix type={type}>
          <SvgIcon {...svgIconProps} isFilled />
        </WarningIconFix>
      </View>
    </View>
  ) : (
    <></>
  )
}

export {ProgressBarIndicator}

const styles = StyleSheet.create({
  indicator: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: NamedColors.WHITE,
    borderColor: NamedColors.BLACK,
    borderRadius: 16,
    borderWidth: 1,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  indicatorOffset: {
    left: -12, // Offset indicator half it's width so it covers the edge of the progress bar segment
  },
  indicatorOffsetEnd: {
    left: -24, // Offset indicator its full width so it stays within the bounds of the progress bar when ProgressBar is full/100%/`position` is 1
  },
  warningIconFix: {
    top: -1.5,
    transform: [
      {
        scale: 0.87,
      },
    ],
  },
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row',
  },
})
