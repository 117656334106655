import {combineReducers} from 'redux'

import graphqlChaos, {GraphqlChaosState} from './graphql-chaos/state/slice'
import componentChaos, {ComponentChaosState} from './component-chaos/state/slice'

export type ChaosState = {
  graphqlChaos: GraphqlChaosState
  componentChaos: ComponentChaosState
}

export const ChaosReducers = {
  graphqlChaos,
  componentChaos,
}

const rootChaosReducer = combineReducers<ChaosState>(ChaosReducers)

export type ChaosRootReducerType = typeof rootChaosReducer

export const ChaosReducer = rootChaosReducer
