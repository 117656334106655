import * as IAMTypes from '../types/types.iam.generated'
import * as CTypes from '../types/types.mobile.generated'

// Addresses
type ApplicantPhysicalAddressComponentsInput = CTypes.ApplicantPhysicalAddressComponentsInput
type Address = IAMTypes.Address | CTypes.Address

/**
 * Converts an ApplicantPhysicalAddressComponentsInput to an Address.
 * This is needed as the types have different type strictness.
 * @param components ApplicantPhysicalAddressComponentsInput to convert to Address
 * @returns Address
 */
export const AddressComponentsToAddress = (
  components: ApplicantPhysicalAddressComponentsInput,
  verified = false,
): Address => {
  return {
    ...components,
    __typename: 'Address',
    verified,
  } as Address
}

/**
 * Converts an Address to an ApplicantPhysicalAddressComponentsInput.
 * This is needed as the types have different type strictness.
 * @param address Address to convert to ApplicantPhysicalAddressComponentsInput
 * @returns ApplicantPhysicalAddressComponentsInput
 */
export const AddressToAddressComponents = (
  address: Address | undefined,
): ApplicantPhysicalAddressComponentsInput => {
  if (address) {
    return {
      street1: address.street1 ?? '',
      street2: address.street2 ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      postalCode: address.postalCode ?? '',
      country: address.country ?? '',
      county: address.county ?? '',
    }
  }

  return {
    street1: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    county: '',
  }
}
