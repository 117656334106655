import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  AcceptLoanAndAutoPayAgreementsBaseTemplate,
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'

export type AcceptLoanAndAutoPayAgreementsWATemplateProps = Omit<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'title' | 'description' | 'showLoanAgreementCheckbox'
>

/**
 * Page template for a screen for WA (Washington) users to accept the loan agreement and
 * the autopay agreement.
 */
export const AcceptLoanAndAutoPayAgreementsWATemplate: FC<
  AcceptLoanAndAutoPayAgreementsWATemplateProps
> = (props) => {
  const {t} = useTranslation('LoanApproved')

  return (
    <AcceptLoanAndAutoPayAgreementsBaseTemplate
      {...props}
      testID="LoanAndAutoPayAgreements-WA"
      showLoanAgreementCheckbox={true}
      // washington has state-specific description copy
      description={
        <PFText variant="p">
          {t('LoanCommonText1')} <PFText variant="p_semibold">{t('LoanCommonText2')}</PFText>{' '}
          {t('WASpecificText')}
        </PFText>
      }
    />
  )
}
