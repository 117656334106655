import {useEffect, useState} from 'react'

import {
  CardAccountStatusCode,
  CardAccountStatuses,
  LoanStatus,
  ProductsDocument,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {logErrorAndShowException} from 'src/lib/errors'
import {useEnsureIsNewCardAccount} from 'src/products/card/Application/useEnsureIsNewCardAccount'
import {useCassandraClient} from '@possible/cassandra'
import {
  AccountStatusQueryDocument,
  AccountStatusQueryQuery,
} from 'src/products/general/ApplicationProcessing/AccountStatusQuery.gqls'

export type UseApplicationProcessingStatusType = () => {
  isAccountStatusLoading: boolean
  isApplicationUWComplete: boolean
  cardAccountStatus?: Partial<CardAccountStatuses>
  loanAccountStatus?: Partial<LoanStatus>
}

const hasNewAccountStatus = (
  newData: AccountStatusQueryQuery | undefined,
  previousData: AccountStatusQueryQuery | undefined,
): boolean => {
  if (!newData || !previousData) {
    return false
  }

  const hasNewCardAccountStatus =
    newData?.me?.cardAccounts?.active?.status?.code !==
    previousData?.me?.cardAccounts?.active?.status?.code
  const hasNewLoanAccountStatus =
    newData?.me?.loans?.latestActionableLoan?.status?.code !==
    previousData?.me?.loans?.latestActionableLoan?.status?.code
  return hasNewCardAccountStatus || hasNewLoanAccountStatus
}

export const useCardApplicationProcessingStatus: UseApplicationProcessingStatusType = () => {
  const [cassandraClient] = useCassandraClient()
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  // This query continuously polls the BE for the account status, so the hook knows when the application has been approved or rejected.
  const {data, previousData} = useCassandraQuery(AccountStatusQueryDocument, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    // Update offers if card or loan status changes
    // This onCompleted param can be removed once MPO is deleted
    onCompleted: () => {
      if (hasNewAccountStatus(data, previousData)) {
        void cassandraClient?.refetchQueries({include: [ProductsDocument]})
      }
      // Set this manually b/c `notifyOnNetworkStatusChange: true` sets loading=true on every poll request
      setIsInitialLoad(false)
    },
  })

  const loanAccountStatus: Partial<LoanStatus> | undefined =
    data?.me?.loans?.latestActionableLoan?.status
  const cardAccount = data?.me?.cardAccounts?.active
  const cardAccountId: string | undefined = cardAccount?.id
  const cardAccountStatus = cardAccount?.status
  const cardAccountStatusCode = cardAccountStatus?.code
  const [isNewAccount, setIsNewAccount] = useState<boolean>(false)
  const {clearPreviousAccount, isNewActiveAccount} = useEnsureIsNewCardAccount()

  useEffect(() => {
    const checkForApplicationCompletion = async (): Promise<void> => {
      try {
        if (cardAccountId && isNewActiveAccount(cardAccountId) && cardAccountStatusCode) {
          clearPreviousAccount()
          setIsNewAccount(true)
        }
      } catch (e) {
        await logErrorAndShowException(e, 'Unable to determine application status')
      }
    }

    void checkForApplicationCompletion()
  }, [cardAccountStatusCode, cardAccountId, clearPreviousAccount, isNewActiveAccount])

  return {
    isApplicationUWComplete:
      isNewAccount && cardAccountStatus?.code !== CardAccountStatusCode.Pending,
    cardAccountStatus,
    loanAccountStatus,
    isAccountStatusLoading: isInitialLoad,
  }
}
