import React from 'react'

import {Consumer} from '@possible/cassandra'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useUpdateUserAddress} from 'src/products/MCU/Address/address.utils'
import {StateSelectedDocument} from 'src/products/MCU/Address/AddressCollection.gqls'
import {AddressCollectionTemplate} from 'src/products/MCU/Address/AddressCollectionTemplate'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {LoanSubmissionDocument} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'
import {MainStackScreenProps} from 'src/nav/MainStackParamsList'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'

type Props = MainStackScreenProps<'UpdateAddress'>

const UpdateAddressContainer: React.FC<Props> = ({navigation}) => {
  const [updateUserAddress] = useUpdateUserAddress(Consumer.types.AddressType.Home, {
    refetchQueries: [LoanSubmissionDocument],
  })

  const {selectedData: selectedState, loading: isLoadingSelectedState} = useCassandraQuery(
    StateSelectedDocument,
    {},
    (data) => data?.me?.onboarding?.loan?.stateSelected,
  )

  const isAppRevampFunctionalUpdatesEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  const handleOnComplete = (): void => {
    navigation.pop()
  }

  return (
    <BaseTemplate isLoading={isLoadingSelectedState}>
      <AddressCollectionTemplate
        updateAddress={updateUserAddress}
        onComplete={handleOnComplete}
        selectedState={selectedState}
        isForReviewAndEdit={true}
        isAppRevampFunctionalUpdatesEnabled={isAppRevampFunctionalUpdatesEnabled}
      />
    </BaseTemplate>
  )
}

export {UpdateAddressContainer}
