import React, {FunctionComponent, ReactNode} from 'react'
import {StyleSheet, View} from 'react-native'

import {ButtonProps} from 'src/designSystem/components/atoms/ButtonBase/ButtonBase'
import {smallGap} from 'src/designSystem/layout'
import Button, {ButtonMode, ButtonSize} from 'src/designSystem/components/atoms/Button/Button'
import {buttonFocusBorderWidth} from 'src/designSystem/guide'

export type StackedButtonsAction = ButtonProps & {
  text: string
  loading?: boolean
} & {
  mode?: ButtonMode
  size?: ButtonSize
}

export type StackedButtonsProps = {
  testID?: string
  primary?: StackedButtonsAction | Array<StackedButtonsAction>
  secondary?: Array<StackedButtonsAction>
}

const StackedButtons: FunctionComponent<StackedButtonsProps> = ({testID, primary, secondary}) => {
  let primaryButton: ReactNode[] | null = null
  if (primary) {
    const primaryArray = Array.isArray(primary) ? primary : [primary]
    primaryButton = primaryArray.map((item) => (
      <View
        key={item.text}
        style={[styles.primaryButton, secondary && styles.betweenButtonPadding]}
      >
        <Button
          mode={item.mode ?? 'primary'}
          disabled={item.disabled}
          loading={item.loading}
          size={item.size ?? 'medium'}
          onPress={item.onPress}
          testID={item.testID}
          width="100%"
        >
          {item.text}
        </Button>
      </View>
    ))
  }

  let secondaryButton: ReactNode[] | null = null
  if (secondary) {
    secondaryButton = secondary.map((item, index) => (
      <View
        key={`${item.text}`}
        style={[styles.secondary, index !== secondary.length - 1 && styles.betweenButtonPadding]}
      >
        <Button
          mode={item.mode ?? 'secondary'}
          disabled={item.disabled}
          loading={item.loading}
          size={item.size ?? 'medium'}
          onPress={item?.onPress}
          testID={item?.testID}
          width="100%"
        >
          {item?.text}
        </Button>
      </View>
    ))
  }

  return (
    <View style={styles.container} testID={testID}>
      {primaryButton}
      {secondaryButton}
    </View>
  )
}
export default StackedButtons

const styles = StyleSheet.create({
  betweenButtonPadding: {
    marginBottom: smallGap / 2,
  },
  container: {
    alignItems: 'center',
    width: '100%',
  },
  primaryButton: {
    alignItems: 'center',
    padding: buttonFocusBorderWidth,
    width: '100%',
  },
  secondary: {
    alignItems: 'center',
    padding: buttonFocusBorderWidth,
    width: '100%',
  },
})
