import {gql} from '@apollo/client'

export const OfferFragment = gql`
  fragment OfferFragment on Offer {
    id
    annualFee
    approvedAmount
    apr
    interestRate
    monthlyPayment
    months
    originationFee
    partnerId
    status
    title
    url
  }
`
