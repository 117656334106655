import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {UpcomingPaymentsCard} from 'src/products/card/components/molecules/UpcomingPaymentsCard/UpcomingPaymentsCard'
import {modernDashboardHeaderBackgroundLight} from 'src/designSystem/semanticColors'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import AppNav from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import CardAlert from 'src/products/card/Dashboard/CardAlert/CardAlert'
import CardDashboardHeaderTile from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardHeader'
import {CardDashboardPayOverTime} from 'src/products/card/Dashboard/CardDashboardPayOverTime'
import {CardDashboardPayment} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPayment'
import CardUnlockedModal from 'src/products/card/Dashboard/CardModals/CardUnlockedModal'
import Dashboard from 'src/products/card/Dashboard/Dashboard'
import {PhysicalCardActivationTile} from 'src/products/card/Dashboard/EntryPointTiles/PhysicalCardActivationTile'
import {SetupAutopayTile} from 'src/products/card/Dashboard/EntryPointTiles/SetupAutopayTile'
import {useShouldDisplayCardDashboardPayOverTimeTile} from 'src/products/card/Dashboard/TileHooks/useShouldDisplayCardDashboardPayOverTimeTile'
import {useShouldDisplayPhysicalCardActivationTile} from 'src/products/card/Dashboard/TileHooks/useShouldDisplayPhysicalCardActivationTile'
import {useShouldDisplaySetupAutopayTile} from 'src/products/card/Dashboard/TileHooks/useShouldDisplaySetupAutopayTile'
import {useShouldDisplayUpcomingPaymentsCard} from 'src/products/card/Dashboard/TileHooks/useShouldDisplayUpcomingPaymentsCard'
import {TransactionsAndPaymentsList} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/TransactionsAndPaymentsList'
import {useCards} from 'src/products/card/hooks/useCards'
import {WarningModalProvider} from 'src/products/card/hooks/useWarningModals'
import {CardAccountDashboardStatus} from 'src/products/card/types'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {BrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/BrazeContentCards'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {getRandomPollingInterval} from 'src/cassandra/src/utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {
  FilterBrazeContentCardsByScreenAndLocationProps,
  filterBrazeContentCardsByScreenAndLocation,
} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards.utils'
import {useBrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards'
import {BrazeContentCardScreen} from 'src/lib/braze/braze.utils'

const brazeCardDashboardLocation1 = filterBrazeContentCardsByScreenAndLocation({
  location: 1,
  screenName: BrazeContentCardScreen.CardDashboard,
})

type Props = StackScreenProps<MainStackParamList, 'CardDashboard'>
const CardDashboard: React.FC<Props> = (props) => {
  const {navigation, route} = props
  const {t} = useTranslation(['CardDashboard', 'Common'])
  const shouldDisplaySetupAutopayTile = useShouldDisplaySetupAutopayTile()
  const shouldDisplayUpcomingPaymentsCard = useShouldDisplayUpcomingPaymentsCard()
  const shouldDisplayPhysicalCardActivationTile = useShouldDisplayPhysicalCardActivationTile()
  const shouldDisplayCardDashboardPayOverTimeTile = useShouldDisplayCardDashboardPayOverTimeTile()
  const isBrazeContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')

  const allCardsProps = useCards()
  const {
    loading,
    ledgerSpent,
    hasPendingPhysicalCard,
    accountStatus,
    startPollingCardsQuery,
    stopPollingCardsQuery,
    accountPOTStatus,
    delinquentNumberOfDays,
    refetchPollingCardsQuery,
  } = allCardsProps

  // Added because a page may need to trigger a refetch of the card data when navigating back to the dashboard
  // PayOverTimeConfirmation does this to disable the Pay Over Time button after it is set up
  const [isManuallyRefetching, setIsManuallyRefetching] = React.useState(false)
  React.useEffect(() => {
    async function refetch(): Promise<void> {
      if (route.params?.shouldRefetch) {
        setIsManuallyRefetching(true)
        await refetchPollingCardsQuery()
        setIsManuallyRefetching(false)
      }
    }
    void refetch()
  }, [route.params?.shouldRefetch, refetchPollingCardsQuery])

  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  usePageViewedAnalytics({
    eventName: CardEvents.card_dashboard_viewed,
    eventCategory: AppEvents.Category.Card,
    eventArgs: {
      UIAccountStatus: accountStatus ? CardAccountDashboardStatus[accountStatus] : undefined,
      DaysDelinquent: delinquentNumberOfDays ?? 0,
    },
    logEventToSingular: true,
  })

  useFocusEffect(
    useCallback(() => {
      try {
        startPollingCardsQuery(getRandomPollingInterval(10000))
      } catch (e) {
        Log.error(e)
      }

      return () => {
        stopPollingCardsQuery()
      }
    }, [startPollingCardsQuery, stopPollingCardsQuery]),
  )

  const {brazeContentCards, handleOnDismiss} =
    useBrazeContentCards<FilterBrazeContentCardsByScreenAndLocationProps>(
      brazeCardDashboardLocation1,
    )

  const CardDashboardHeader = (
    <>
      <CardAlert
        accountStatus={accountStatus}
        accountPOTStatus={accountPOTStatus}
        delinquentNumberOfDays={delinquentNumberOfDays}
      />
      <CardDashboardHeaderTile
        onShowCardDetails={(): void => {
          TrackAppEvent(CardEvents.show_virtual_card, AppEvents.Category.Card)
          AppNav.push(navigation, 'CardDetails')
        }}
        {...allCardsProps}
      />
    </>
  )

  const getCardDashboardItems = (): React.ReactNode[] => {
    return [
      shouldDisplaySetupAutopayTile(true) ? (
        <SetupAutopayTile
          key="setup_autopay_no_physical_card"
          hasPendingPhysicalCard={hasPendingPhysicalCard}
        />
      ) : null,
      !cardMinPayResponse?.supportsMinPay ? (
        <CardDashboardPayment key="payments" navigation={navigation} {...allCardsProps} />
      ) : null,
      shouldDisplayUpcomingPaymentsCard ? (
        <UpcomingPaymentsCard
          key="upcomingPayments"
          navigation={navigation}
          footer={false}
          {...allCardsProps}
        />
      ) : null,
      shouldDisplaySetupAutopayTile(false) ? (
        <SetupAutopayTile
          key="setup_autopay_physical_card"
          hasPendingPhysicalCard={hasPendingPhysicalCard}
        />
      ) : null,
      shouldDisplayPhysicalCardActivationTile ? (
        <PhysicalCardActivationTile key="card_activate" navigation={navigation} />
      ) : null,
      isBrazeContentCardsEnabled ? (
        <BrazeContentCards
          brazeContentCardData={brazeContentCards}
          key={`brazeContentCards:screen-${BrazeContentCardScreen.CardDashboard}:location-1`}
          onDismiss={handleOnDismiss}
        />
      ) : null,
      //ENG-5635 make this block a new UI component DashboardCard
      <Box key={'transactionsList'} paddingHorizontal={'medium'}>
        <Box
          gap={'medium'}
          elevation={2}
          paddingVertical={'small'}
          radius={'small'}
          testID="Transactions-Tile-Id"
        >
          <PFText variant={'label_lg'} textAlign={'center'}>
            {t('Transactions')}
          </PFText>
          <TransactionsAndPaymentsList maxItems={5} />
          <Box align={'center'} width={'100%'} testID="Show-Transactions-Button-Id">
            <Button
              size={'medium'}
              mode={'secondary'}
              width={225}
              onPress={(): void => {
                TrackAppEvent(CardEvents.view_transaction_cta, AppEvents.Category.Card)
                PushPage(navigation, 'CardTransactions')
              }}
            >
              {t('SeeAllTransactions')}
            </Button>
          </Box>
        </Box>
      </Box>,
      shouldDisplayCardDashboardPayOverTimeTile ? (
        <CardDashboardPayOverTime key="cardDashboardPayOvertTime" />
      ) : null,
    ].filter((item) => !!item)
  }

  if (loading || isManuallyRefetching) {
    return <Spinner />
  }

  return (
    <WarningModalProvider>
      <Dashboard
        key={'dashboard-component'}
        theme={'light'}
        backgroundColor={modernDashboardHeaderBackgroundLight}
        headerItem={CardDashboardHeader}
        items={getCardDashboardItems()}
        noHorizontalPadding={true}
      />
      <CardUnlockedModal accountStatus={accountStatus} ledgerSpent={ledgerSpent} />
    </WarningModalProvider>
  )
}

export default CardDashboard
