import React from 'react'
import {StatusBar} from 'react-native'
import {isDeviceIOS} from 'src/lib/utils/platform'

const AppStatusBar = () => {
  if (isDeviceIOS()) {
    return <StatusBar barStyle="dark-content" />
  }

  return <StatusBar barStyle="light-content" />
}

export default AppStatusBar
