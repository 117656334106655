import {
  LoanActivationPreRequisiteType,
  LoanTypeVariant,
  StateCodes,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {AcceptAutoPayAgreementWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptAutoPayAgreement/AcceptAutoPayAgreementWorkflowContainer'
import {AcceptLoanAndAutoPayAgreementsWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndAutoPayAgreements/AcceptLoanAndAutoPayAgreementsWorkflowContainer'
import {AcceptLoanAndAutoPayAgreementsWAWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndAutoPayAgreementsWA/AcceptLoanAndAutoPayAgreementsWAWorkflowContainer'
import {AcceptLoanAndStateAgreementsFLWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsFL/AcceptLoanAndStateAgreementsFLWorkflowContainer'
import {AcceptLoanAndStateAgreementsTXWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsTX/AcceptLoanAndStateAgreementsTXWorkflowContainer'
import {ConfirmBankAccountWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountWorkflowContainer'
import {ConfirmDebitCardWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardWorkflowContainer'
import {HowPaymentsWorkWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkWorkflowContainer'
import {LoanDisbursementMethodSelectionWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionWorkflowContainer'
import {LoanFinalAcceptWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptWorkflowContainer'
import {LoanPaymentReviewWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewWorkflowContainer'
import {LoanTilaDisclosureWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureWorkflowContainer'
import {PaymentReviewTilaAndLoanAgreementCAWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCAWorkflowContainer'
import {ReasonSurveyWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyWorkflowContainer'
import {CollectDebitCardNumbersForLoanWorkflowContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanWorkflowContainer'
import {LAStateDisclosureWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/LAStateDisclosure/LAStateDisclosureWorkflowContainer'
import {OHStateDisclosureWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/OHStateDisclosure/OHStateDisclosureWorkflowContainer'
import {AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndAutoPayAgreementsExtendedPlan/AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer'
import {PreReqSortFunctionType} from 'src/workflows/order/types'
import {PreReqSortFunctionFactory} from 'src/workflows/order/utils'
import {
  ApplicationActivationWorkflowStackParams,
  WorkflowPreReqFulfillScreenProps,
} from 'src/workflows/types'
import {RelinkPreferredBankAccountWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/RelinkPreferredBankAccountWorkflowContainer'
import {AcceptLoanAndStateAgreementsHIWorkflowContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsHI/AcceptLoanAndStateAgreementsHIWorkflowContainer'

type DynamicRouteToScreenMapperFn = (args: {
  unmetPreReqs: LoanActivationPreRequisiteType[]
  metPreReqs: LoanActivationPreRequisiteType[]
}) => React.FC<WorkflowPreReqFulfillScreenProps>

/**
 * UX config for the loan activation workflow. Defines the order that prereqs are sorted (which in turn drives
 * the order of screens to fulfill those prereqs) and the mapping of route names to components that are displayed
 * for that route since different scenarios may handle routes differently (for example AcceptLoanAgreement has many
 * variations of UX depending on the user's scenario).
 */
export type LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: PreReqSortFunctionType
  /**
   * Each prereq (i.e. ACCEPT_LOAN_AGREEMENT) maps to a route name in the ApplicationActivationWorkflowDescriptor.
   * Then here we map each route name to a screen component for the loan activation workflow.
   */
  routeNameToComponentMap: {
    [key in keyof ApplicationActivationWorkflowStackParams]?:  // a route name can map directly to a component
      | React.FC<WorkflowPreReqFulfillScreenProps>
      // or if it needs more dynamic behavior for any reason it can map to a function that returns a component
      // given the current met and unmet prereqs
      | {
          type: 'dynamic'
          routeToScreenMapperFn: DynamicRouteToScreenMapperFn
        }
  }
}

/**
 * Get the loan activation workflow UX config for a given state.
 */
export const getLoanActivationWorkflowUXConfig = ({
  stateCode,
  loanTypeVariant,
}: {
  stateCode: StateCodes
  loanTypeVariant?: LoanTypeVariant
}): LoanActivationWorkflowUXConfig => {
  let uxConfig: LoanActivationWorkflowUXConfig = standardLoanActivationUXConfig

  if (loanTypeVariant === LoanTypeVariant.NonRecourseInstallment) {
    /*
      We don't use the standardLoanActivationUXConfig because the Loan Agreement unmet requirement is handled broadly.
      The prompt for Loan Agreement is showing even when Loan Agreement is not being sent down as an unmet requirement
      See https://possible.atlassian.net/browse/ENG-21629
    */
    return advanceLoanActivationUXConfig
  }

  switch (stateCode) {
    case StateCodes.Ca:
      uxConfig = caSinglePaymentLoanActivationUXConfig
      break
    case StateCodes.Tx:
      uxConfig = txLoanActivationUXConfig
      break
    case StateCodes.Fl:
      uxConfig = flLoanActivationUXConfig
      break
    case StateCodes.Hi:
      uxConfig = hiLoanActivationUXConfig
      break
    case StateCodes.La:
      uxConfig = laLoanActivationUXConfig
      break
    case StateCodes.Oh:
      uxConfig = ohLoanActivationUXConfig
      break
    case StateCodes.Wa:
      uxConfig = waLoanActivationUXConfig
      break
    default:
      uxConfig = standardLoanActivationUXConfig
      break
  }
  return uxConfig
}
/**
 * Utility to create an array with all required enum values in any order.
 */
export function createArrayWithAllEnumValues<
  ProvidedEnumValuesOrder extends string,
  AllowedValuesEnum extends {[K in keyof AllowedValuesEnum]: ProvidedEnumValuesOrder},
>(
  _allowedValuesEnum: AllowedValuesEnum,
  providedEnumValuesOrder: [...ProvidedEnumValuesOrder[]],
): ProvidedEnumValuesOrder[] {
  return providedEnumValuesOrder
}

/**
 * Standard sort order for loan activation workflow.
 */
const standardSortOrderFn = PreReqSortFunctionFactory(
  createArrayWithAllEnumValues(LoanActivationPreRequisiteType, [
    // 1 - accept payments
    'ACCEPT_PAYMENTS',

    // 2 - select disbursement method
    'SELECT_DISBURSEMENT_METHOD',

    // 3 - confirm debit card. if using ACH we'll confirm bank account later if necessary
    'COLLECT_DEBIT_CARD_NUMBERS',
    'CONFIRM_DEBIT_CARD',

    // 4 - accept TILA
    'ACCEPT_TILA_DISCLOSURE',

    // 5 - accept how autopay works
    'ACCEPT_HOW_AUTOPAY_WORKS',

    // 6a - accept agreements
    'ACCEPT_ARBITRATION_AGREEMENT_FL',
    'ACCEPT_ARBITRATION_AGREEMENT_TX',
    'ACCEPT_CREDIT_SERVICES_AGREEMENT_TX',
    'ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX',
    'ACCEPT_STATE_DISCLOSURE_LA',
    'ACCEPT_STATE_DISCLOSURE_OH',
    'ACCEPT_STATUTORY_NOTICE_FL',
    'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',

    // 6b - accept loan agreement
    'ACCEPT_LOAN_AGREEMENT',

    // 7 - accept autopay agreement
    'ACCEPT_AUTOPAY_AGREEMENT_EXTENDED',
    'ACCEPT_AUTOPAY_AGREEMENT_STANDARD',

    // 8 - if user's primary account is unlinked we need the to relink it
    'RELINK_PREFERRED_BANK_ACCOUNT',

    // 9 - confirm bank account (if necessary after accepting autopay agreement)
    'CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS',

    // 10 - final survey and accept loan
    'COMPLETE_LOAN_REASON_SURVEY',
    'FINAL_ACCEPT_STANDARD',

    // Currently unsupported state HI
    'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',
  ]),
)

/**
 * Get the route component for the ACCEPT_LOAN_AGREEMENT prereq route for states that support an
 * extended installment plan (which is indicated by the presence of the ACCEPT_AUTOPAY_AGREEMENT_EXTENDED prereq).
 */
const getLoanAgreementRouteForStatesThatSupportExtendedPlan: ({
  nonExtendedPlanAutoPayAgreementComponent,
}: {
  nonExtendedPlanAutoPayAgreementComponent: React.FC<WorkflowPreReqFulfillScreenProps>
}) => DynamicRouteToScreenMapperFn = ({nonExtendedPlanAutoPayAgreementComponent}) => {
  return (args: {
    unmetPreReqs: LoanActivationPreRequisiteType[]
    metPreReqs: LoanActivationPreRequisiteType[]
  }): React.FC<WorkflowPreReqFulfillScreenProps> => {
    const {unmetPreReqs, metPreReqs} = args
    if (
      metPreReqs
        .concat(unmetPreReqs)
        .includes(LoanActivationPreRequisiteType.AcceptAutopayAgreementExtended)
    ) {
      // typically since the loan + autopay agreement are on the same screen we just map both prereqs' routes to the AcceptLoanAndAutoPayAgreementsWorkflowContainer.
      // but if the user has an extended installment plan (which is true if the ACCEPT_AUTOPAY_AGREEMENT_EXTENDED prereq is unmet) then we
      // need to show them a version of the loan + autopay agreement screen with copy specific to the extended plan

      return AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer
    }
    return nonExtendedPlanAutoPayAgreementComponent
  }
}

/**
 * The standard loan activation screens and screen order that is used for most states and users.
 */
export const standardLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    PaymentReview: LoanPaymentReviewWorkflowContainer,
    DisbursementMethodSelection: LoanDisbursementMethodSelectionWorkflowContainer,
    // standard UX has Loan and AutoPay agreements on the same screen
    AcceptAutoPayAgreement: AcceptLoanAndAutoPayAgreementsWorkflowContainer,
    AcceptLoanAgreement: AcceptLoanAndAutoPayAgreementsWorkflowContainer,
    HowAutoPayWorks: HowPaymentsWorkWorkflowContainer,
    TilaDisclosure: LoanTilaDisclosureWorkflowContainer,
    CollectDebitCardNumbers: CollectDebitCardNumbersForLoanWorkflowContainer,
    ConfirmDebitCard: ConfirmDebitCardWorkflowContainer,
    RelinkPreferredBankAccount: RelinkPreferredBankAccountWorkflowContainer,
    ConfirmPrimaryBankAccountDetails: ConfirmBankAccountWorkflowContainer,
    ReasonSurvey: ReasonSurveyWorkflowContainer,
    FinalAcceptance: LoanFinalAcceptWorkflowContainer,
  },
}

/**
 * Ohio loan activation UX is the same as standard UX but it has an OH-specific state disclosure.
 */
export const ohLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    StateDisclosureOH: OHStateDisclosureWorkflowContainer,
  },
}

/**
 * Louisiana loan activation UX is the same as standard UX but it has an LA-specific state disclosure.
 */
export const laLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    StateDisclosureLA: LAStateDisclosureWorkflowContainer,
    AcceptLoanAgreement: {
      type: 'dynamic',
      routeToScreenMapperFn: getLoanAgreementRouteForStatesThatSupportExtendedPlan({
        nonExtendedPlanAutoPayAgreementComponent: AcceptLoanAndAutoPayAgreementsWorkflowContainer,
      }),
    },
    AcceptAutoPayAgreementExtendedInstallmentPlan:
      AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer,
  },
}

/**
 * Washington loan activation UX is mostly the same as standard UX but:
 * 1. It has some WA-specific copy.
 * 2. It supports a unique screen for accepting the loan agreement and the autopay agreement for a WA extended installment plan loan.
 */
export const waLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // WA has the standard UX of Loan and AutoPay agreements on the same screen
    // but it has state-specific copy
    AcceptAutoPayAgreement: AcceptLoanAndAutoPayAgreementsWAWorkflowContainer,
    AcceptLoanAgreement: {
      type: 'dynamic',
      routeToScreenMapperFn: getLoanAgreementRouteForStatesThatSupportExtendedPlan({
        nonExtendedPlanAutoPayAgreementComponent: AcceptLoanAndAutoPayAgreementsWAWorkflowContainer,
      }),
    },
    // when WA users convert their loan to an extended installment plan they will need to re-activate the loan with a unique screen for loan + autopay agreement
    AcceptAutoPayAgreementExtendedInstallmentPlan:
      AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer,
  },
}

/**
 * Texas loan activation UX. TX has state-specific agreements and disclosures on
 * a unique screen.
 */
export const txLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // for TX the AutoPay agreement is on a separate screen from Loan Agreement. see https://www.notion.so/possiblefinance/Accepting-Loan-Agreement-AutoPay-Agreements-2fa1af84184a4ecfa304498a49b837b7
    AcceptAutoPayAgreement: AcceptAutoPayAgreementWorkflowContainer,
    // for TX the Loan Agreement & state disclosure & arbitration agreement are all on one screen
    AcceptLoanAgreement: AcceptLoanAndStateAgreementsTXWorkflowContainer,
    AcceptStateAgreementsTX: AcceptLoanAndStateAgreementsTXWorkflowContainer,
  },
}

/**
 * Texas loan activation UX. TX has state-specific agreements and disclosures on
 * a unique screen.
 */
export const advanceLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // for TX the AutoPay agreement is on a separate screen from Loan Agreement. see https://www.notion.so/possiblefinance/Accepting-Loan-Agreement-AutoPay-Agreements-2fa1af84184a4ecfa304498a49b837b7
    AcceptAutoPayAgreement: AcceptAutoPayAgreementWorkflowContainer,
  },
}

/**
 * Florida loan activation UX. FL has state-specific agreements and disclosures on
 * a unique screen.
 */
export const flLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // for FL the AutoPay agreement is on a separate screen from Loan Agreement. see https://www.notion.so/possiblefinance/Accepting-Loan-Agreement-AutoPay-Agreements-2fa1af84184a4ecfa304498a49b837b7
    AcceptAutoPayAgreement: AcceptAutoPayAgreementWorkflowContainer,
    // for FL the Loan Agreement & state disclosures & arbitration agreement are all on one screen
    AcceptLoanAgreement: AcceptLoanAndStateAgreementsFLWorkflowContainer,
    AcceptStateAgreementsFL: AcceptLoanAndStateAgreementsFLWorkflowContainer,
  },
}

/**
 * Hawaii loan activation UX. HI has a state-specific installment plan disclosure agreement on
 * a unique screen.
 */
export const hiLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  preReqSortOrderFn: standardSortOrderFn,
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // for HI the AutoPay agreement is on a separate screen from Loan Agreement. see https://www.notion.so/possiblefinance/Accepting-Loan-Agreement-AutoPay-Agreements-2fa1af84184a4ecfa304498a49b837b7
    AcceptAutoPayAgreement: AcceptAutoPayAgreementWorkflowContainer,
    // for HI the Loan Agreement & installment plan disclosure agreement are on one screen
    AcceptLoanAgreement: AcceptLoanAndStateAgreementsHIWorkflowContainer,
    AcceptStateAgreementsHI: AcceptLoanAndStateAgreementsHIWorkflowContainer,
  },
}

/**
 * California loan activation UX. Users begin with a single payment loan and then are
 * able to upgrade to an installment plan after accepting the single payment loan.
 */
export const caSinglePaymentLoanActivationUXConfig: LoanActivationWorkflowUXConfig = {
  /**
   * CA sorts the screens differently
   */
  preReqSortOrderFn: PreReqSortFunctionFactory(
    createArrayWithAllEnumValues(LoanActivationPreRequisiteType, [
      // 1 - select disbursement method
      'SELECT_DISBURSEMENT_METHOD',

      // 2 - if unlinked, relink preferred bank account
      'RELINK_PREFERRED_BANK_ACCOUNT',

      // 3 - confirm debit card or bank account
      'COLLECT_DEBIT_CARD_NUMBERS',
      'CONFIRM_DEBIT_CARD',
      'CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS',

      // 4 - accept autopay
      'ACCEPT_HOW_AUTOPAY_WORKS',
      'ACCEPT_AUTOPAY_AGREEMENT_EXTENDED',
      'ACCEPT_AUTOPAY_AGREEMENT_STANDARD',

      // 5 - accept legal agreements (state agreements won't apply to CA but we
      // define them to make sure we handle all possible prereqs w/type safety using createArrayWithAllEnumValues)
      'ACCEPT_ARBITRATION_AGREEMENT_FL',
      'ACCEPT_ARBITRATION_AGREEMENT_TX',
      'ACCEPT_CREDIT_SERVICES_AGREEMENT_TX',
      'ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX',
      'ACCEPT_STATE_DISCLOSURE_LA',
      'ACCEPT_STATE_DISCLOSURE_OH',
      'ACCEPT_STATUTORY_NOTICE_FL',
      'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',

      // 6 - reason survey
      'COMPLETE_LOAN_REASON_SURVEY',

      // 7 - accept payments, TILA and the loan agreement. for CA this happens all in one
      // screen for when multi payment upgrade is available (PaymentReviewTilaAndLoanAgreement)
      'ACCEPT_PAYMENTS',
      'ACCEPT_TILA_DISCLOSURE',
      'ACCEPT_LOAN_AGREEMENT',

      // 8 - accept loan
      'FINAL_ACCEPT_STANDARD',

      // Currently unsupported state HI
      'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',
    ]),
  ),
  routeNameToComponentMap: {
    ...standardLoanActivationUXConfig.routeNameToComponentMap,
    // for CA payment review, TILA, Loan Agreement & final accept are all in one screen so
    // each route maps to the same screen component
    PaymentReview: PaymentReviewTilaAndLoanAgreementCAWorkflowContainer,
    TilaDisclosure: PaymentReviewTilaAndLoanAgreementCAWorkflowContainer,
    AcceptLoanAgreement: PaymentReviewTilaAndLoanAgreementCAWorkflowContainer,
    FinalAcceptance: PaymentReviewTilaAndLoanAgreementCAWorkflowContainer,
    // for CA the AutoPay agreement is on a separate screen from Loan Agreement. see https://www.notion.so/possiblefinance/Accepting-Loan-Agreement-AutoPay-Agreements-2fa1af84184a4ecfa304498a49b837b7
    AcceptAutoPayAgreement: AcceptAutoPayAgreementWorkflowContainer,
  },
}
