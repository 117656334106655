import {PendingURAType} from 'src/lib/ura/types'

export const URA_UPDATE = 'URA_UPDATE'
export const URA_UI_UPDATE = 'URA_UI_UPDATE'

export type URAUpdateActionProps = {
  id?: string
  uri_id?: string | null
  componentId?: string | null
  close?: (() => void) | null
  prompted?: boolean
}[]

export function URAUpdateAction(ura: (PendingURAType | null)[]): {
  type: typeof URA_UPDATE
  ura: (PendingURAType | null)[]
} {
  return {type: URA_UPDATE, ura}
}

export function URAUIUpdateAction(
  ura_ui: URAUpdateActionProps,
  mode: string,
): {type: typeof URA_UI_UPDATE; ura_ui: URAUpdateActionProps; mode: string} {
  return {type: URA_UI_UPDATE, ura_ui, mode}
}
