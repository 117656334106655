import React, {useCallback, useEffect, useState} from 'react'
import {BackHandler, StyleSheet, View, Alert} from 'react-native'
import RNPickerSelect from 'react-native-picker-select'

import {reloadUI} from 'src/lib/utils/reload'
import {setEnv} from 'src/lib/devMode'
import PfModule from 'src/lib/PfModule'
import {isDeviceAndroid, isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {EnvironmentType} from '@possible/cassandra'
import {usePfSelector} from 'src/store/utils'

const EnvSelector: React.FC = () => {
  const envOverride = usePfSelector((state) => state.api.envOverride)
  const [selectedEnv, setSelectedEnv] = useState(envOverride)
  //This to handle a race condition
  useEffect(() => {
    setSelectedEnv(envOverride)
  }, [envOverride])
  const TriggerRestart = useCallback(
    async (newEnv) => {
      if (newEnv === envOverride) {
        return
      }
      await setEnv(newEnv)
      if (isDeviceAndroid() || isDeviceIOS()) {
        Alert.alert(
          'Switching API Environments',
          'Switching environments requires an app restart due to API keys from the native side',
          [
            {
              text: 'Exit App',
              onPress: () => (isDeviceAndroid() ? BackHandler.exitApp() : PfModule.exitApp()),
            },
          ],
          {cancelable: true},
        )
      } else {
        reloadUI()
      }
    },
    [envOverride],
  )

  const onDonePress = useCallback(async () => {
    TriggerRestart(selectedEnv)
  }, [selectedEnv, TriggerRestart])

  const onValueChange = useCallback(
    async (newEnv) => {
      if (isDeviceAndroid() || isDeviceWeb()) {
        TriggerRestart(newEnv)
      } else {
        setSelectedEnv(newEnv)
      }
    },
    [TriggerRestart],
  )

  const envValue = selectedEnv ?? 'not_set'
  return (
    <View key={'apiEnvControl'} style={styles.selector}>
      <PFText variant={'p'}>API Env Override :</PFText>

      <RNPickerSelect
        key={'apiEnvSelect'}
        value={envValue}
        onValueChange={onValueChange}
        onDonePress={onDonePress}
        items={Object.values(EnvironmentType).map((type) => ({
          label: type,
          value: type,
          key: type,
        }))}
        style={{
          inputIOS: styles.inputIOS,
        }}
      />
    </View>
  )
}
export default EnvSelector

const styles = StyleSheet.create({
  inputIOS: {
    textAlign: 'center',
  },
  selector: {
    alignItems: 'center',
    alignSelf: 'center',
    borderWidth: 1,
    justifyContent: 'center',
    width: '100%',
  },
})
