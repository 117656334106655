import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'

import {
  CollectDebitCardNumbersForLoanGQLContainer,
  CollectDebitCardNumbersForLoanGQLContainerProps,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type CollectDebitCardNumbersForLoanWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container for the CollectDebitCardNumbersForLoan screen to integrate it into
 * the Workflow navigation system.
 */
const CollectDebitCardNumbersForLoanWorkflowContainer: React.FC<
  CollectDebitCardNumbersForLoanWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props

  /**
   * After adding a new debit card they've completed this prereq.
   */
  const handleOnDebitCardSaved = async (): Promise<void> => {
    TrackAppEvent(
      AppEvents.Name.disbursement_method_debit_link_success,
      AppEvents.Category.Activation,
    )
    await onPreReqFulfilled?.()
  }

  const handleOnDebitCardSaveFailed: CollectDebitCardNumbersForLoanGQLContainerProps['onDebitCardSaveFailed'] =
    (errorReason) => {
      TrackAppEvent(
        AppEvents.Name.disbursement_method_debit_link_fail,
        AppEvents.Category.Activation,
        {error: errorReason},
      )
    }
  return (
    <CollectDebitCardNumbersForLoanGQLContainer
      onDebitCardSaved={handleOnDebitCardSaved}
      onDebitCardSaveFailed={handleOnDebitCardSaveFailed}
    />
  )
}

export {CollectDebitCardNumbersForLoanWorkflowContainer}
