import moment, {Moment} from 'moment'

export const graphqlDateFormat = 'YYYY-MM-DD'
export const humanReadableDate = 'MMMM DD, YYYY'
export const humanReadableDateShort = 'MMM DD, YYYY'
export const humanReadableDateShortOrdinal = 'MMM Do'
export const humanReadableDateDayMonth = 'MMMM D'
export const humanReadableDateDayMonth2 = 'MMMM Do'
export const humanReadableDateNumbers = 'MM/DD/YYYY'
export const humanReadableDateAtTime = 'MMM. D [at] hh:mmA' // Nov. 9 at 11:27AM
export const friendlyDateWithDOW = 'dddd, MMMM Do'
export const utcDate = 'YYYY-MM-DD'
export const monthDate = 'MM/YY'
export const monthDay = 'MM/DD'
export const monthDayShort = 'M/DD'
export const yearMonth = 'YYYYMM'

const milliseconds = 1000
const seconds = 60
const minutes = 60

export const convertHoursToMs = (hours: number): number => {
  return hours * minutes * seconds * milliseconds
}

export function formatDate(
  date: string | Date | Moment | undefined | null,
  templateString: string,
  parseFormat?: string,
): string {
  if (date === null) {
    date = undefined
  }
  const dateMoment = moment(date, parseFormat)
  if (!dateMoment) {
    return ''
  }
  return dateMoment.format(templateString)
}
