import CassandraReducer from '@possible/cassandra/src/reducer'
import {combineReducers} from 'redux'

import api from 'src/api/reducers'
import entities from 'src/api/reducers/entities'
import lib from 'src/lib/reducers'
import nav from 'src/navigation/reducers'
import {PfReduxState} from 'src/reducers/types'
import {reducer as workflows} from 'src/workflows/slice'
import {ChaosReducer} from '@possible/chaos'

const Reducer = combineReducers<PfReduxState>({
  cassandra: CassandraReducer,
  api,
  nav,
  lib,
  entities,
  workflows,
  chaos: ChaosReducer,
})

export default Reducer
