import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UpgradeToInstallmentPlanQueryVariables = Types.Exact<{[key: string]: never}>

export type UpgradeToInstallmentPlanQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
  }
}

export type ConvertLoanUpgradeToInstallmentPlanMutationVariables = Types.Exact<{
  input: Types.LoanConvertInput
}>

export type ConvertLoanUpgradeToInstallmentPlanMutation = {
  __typename?: 'Mutation'
  loanConvert: {__typename?: 'LoanConvertResponse'; converted: boolean}
}

export type UpgradeToInstallmentPlanGetConversionOptionsMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.LoanConversionOptionsInput>
}>

export type UpgradeToInstallmentPlanGetConversionOptionsMutation = {
  __typename?: 'Mutation'
  loanConversionOptions?: {
    __typename?: 'LoanConversionOptionsResponse'
    options?: Array<{
      __typename?: 'LoanConversionOption'
      offerId?: string | null
      projectedPayments?: Array<{
        __typename?: 'ConversionOptionLoanPayment'
        id?: string | null
        amount: string
        originalDate?: string | null
      } | null> | null
    } | null> | null
  } | null
}

export const UpgradeToInstallmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UpgradeToInstallmentPlan'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpgradeToInstallmentPlanQuery, UpgradeToInstallmentPlanQueryVariables>
export const ConvertLoanUpgradeToInstallmentPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'ConvertLoanUpgradeToInstallmentPlan'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanConvertInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanConvert'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'converted'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConvertLoanUpgradeToInstallmentPlanMutation,
  ConvertLoanUpgradeToInstallmentPlanMutationVariables
>
export const UpgradeToInstallmentPlanGetConversionOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpgradeToInstallmentPlanGetConversionOptions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanConversionOptionsInput'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanConversionOptions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'options'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'offerId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'projectedPayments'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'originalDate'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpgradeToInstallmentPlanGetConversionOptionsMutation,
  UpgradeToInstallmentPlanGetConversionOptionsMutationVariables
>
