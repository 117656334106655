import {Consumer} from '@possible/cassandra'
import React, {useState} from 'react'

import {Address} from '@possible/cassandra/src/types/types.mobile.generated'
import {APIDateFormat, apiParseDateFormat} from 'src/lib/time_util'
import {formatDate} from 'src/lib/utils/date'
import {
  PersonalInfoConfirmationTemplate,
  PersonalInfoConfirmationTemplateProps,
} from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmationTemplate'
import {formatNameForOnboarding} from 'src/products/loans/PersonalInformation/PIUtils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {PersonalInfoConfirmationDocument} from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmation.gqls'

export type UserInfo = {
  address: Address
  birth_date: string
  first_name: string
  last_name: string
  middle_name: string
  name_suffix: string
  ssn: string
}

export type PersonalInfoConfirmationGQLContainerProps = Pick<
  PersonalInfoConfirmationTemplateProps,
  'onConfirm'
>

/**
 * Container to load data for the PersonalInfoConfirmation screen with REST APIs.
 */
const PersonalInfoConfirmationGQLContainer: React.FC<PersonalInfoConfirmationGQLContainerProps> = (
  props: PersonalInfoConfirmationGQLContainerProps,
) => {
  const {onConfirm} = props
  const [isConfirming, setIsConfirming] = useState(false)

  const handleOnConfirm = async (): Promise<void> => {
    setIsConfirming(true)
    try {
      await onConfirm()
    } finally {
      setIsConfirming(false)
    }
  }
  const {data, loading} = useCassandraQuery(PersonalInfoConfirmationDocument)

  const userProfileData = data?.me.profile
  const userEmail = userProfileData?.email?.address

  const address: Address | null | undefined = data?.me.profile?.home?.address
  const addressFormatted = address
    ? Consumer.formatting.FormatAddressOrAddressComponents(address)
    : ''

  const name = formatNameForOnboarding({
    firstName: userProfileData?.name?.firstName,
    middleName: userProfileData?.name?.middleName,
    lastName: userProfileData?.name?.lastName,
    nameSuffix: userProfileData?.name?.suffix,
  })
  const birthDate = formatDate(userProfileData?.birthDate, APIDateFormat, apiParseDateFormat)

  const ssnLastFour = data?.me.identification?.ssn?.mask || ''

  const isLoading = loading && !data
  return (
    <PersonalInfoConfirmationTemplate
      isLoading={isLoading}
      name={name}
      birthDate={birthDate}
      address={addressFormatted}
      ssnLastFour={ssnLastFour}
      onConfirm={handleOnConfirm}
      email={userEmail ?? ''}
      isConfirmBtnDisabled={isLoading || isConfirming}
      isConfirmBtnLoading={isConfirming}
    />
  )
}

export default PersonalInfoConfirmationGQLContainer
