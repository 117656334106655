import React from 'react'

import {BrazeContentCardScreen} from 'src/lib/braze/braze.utils'
import {BrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/BrazeContentCards'
import {useBrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards'
import {
  filterBrazeContentCardsByScreenAndLocation,
  FilterBrazeContentCardsByScreenAndLocationProps,
} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards.utils'

const brazeLoanDashboardLocation1 = filterBrazeContentCardsByScreenAndLocation({
  location: 1,
  screenName: BrazeContentCardScreen.LoansDashboard,
})

const LoanBrazeTiles = (): JSX.Element => {
  const {brazeContentCards, handleOnDismiss} =
    useBrazeContentCards<FilterBrazeContentCardsByScreenAndLocationProps>(
      brazeLoanDashboardLocation1,
    )

  return <BrazeContentCards brazeContentCardData={brazeContentCards} onDismiss={handleOnDismiss} />
}

export {LoanBrazeTiles}
