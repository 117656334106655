import {LinkedAccountStatusCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {useQueryFactory} from '@possible/cassandra/src/utils'
import {
  AccountManagementAccountsDocument,
  AccountManagementAccountsQuery,
} from 'src/products/MCU/AccountManagementV2/queries/AccountManagementAccounts/AccountManagementAccounts.gqls'

/**
 * Debit Card Payment Method used within Account Management. This is a subset of the
 * DebitCardPaymentMethod type.
 */
export type AccountManagementPaymentMethodDebitCard = NonNullable<
  AccountManagementAccountsQuery['me']['paymentMethods']['all']
>[0] & {
  // the items in this collection can be a few types. we do this to specify that this
  // is only those with the specific debit card type
  __typename: 'DebitCardPaymentMethod'
}

/**
 * ACH Payment Method used within Account Management. This is a subset of the
 * AchPaymentMethod type.
 */
export type AccountManagementPaymentMethodAch = NonNullable<
  AccountManagementAccountsQuery['me']['paymentMethods']['all']
>[0] & {
  // the items in this collection can be a few types. we do this to specify that this
  // is only those with the specific debit card type
  __typename: 'AchPaymentMethod'
}

/**
 * ACH Payment Method used within Account Management. This is a subset of the
 * AchPaymentMethod type.
 */
export type AccountManagementLinkedAccountBank = NonNullable<
  AccountManagementAccountsQuery['me']['bankAccounts']['all']
>[0] & {
  __typename: 'LinkedAccount'
}

/**
 * Query hook to fetch all payment methods for Account Management.
 */
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useAccountManagementAccountsQuery = useQueryFactory(
  AccountManagementAccountsDocument,
  (
    from: AccountManagementAccountsQuery,
  ): {
    defaultLoanPaymentMethodId: string | undefined
    debitCardPaymentMethods: AccountManagementPaymentMethodDebitCard[]
    achPaymentMethods: AccountManagementPaymentMethodAch[]
    bankLinkedAccountsNeedAttention: AccountManagementLinkedAccountBank[]
    activeLoanId: string | undefined
  } => {
    const debitCardPaymentMethods: AccountManagementPaymentMethodDebitCard[] = []
    const achPaymentMethods: AccountManagementPaymentMethodAch[] = []

    const defaultLoanPaymentMethod =
      from.me.loans.latestActionableLoan?.paymentMethods?.paymentDefault

    from.me.paymentMethods.all?.forEach((paymentMethod) => {
      if (paymentMethod?.__typename === 'DebitCardPaymentMethod') {
        debitCardPaymentMethods.push({
          // the type has typename as optional, doing this forces ts to know it's a DebitCardPaymentMethod within the PaymentMethodCollection
          __typename: 'DebitCardPaymentMethod',
          ...paymentMethod,
        })
      } else if (
        paymentMethod?.__typename === 'AchPaymentMethod' &&
        paymentMethod.account?.status !== LinkedAccountStatusCode.Unlinked
      ) {
        achPaymentMethods.push({
          __typename: 'AchPaymentMethod',
          ...paymentMethod,
        })
      }
    })
    // this is only bank accounts that are not verified and valid. if they're valid they will appear in achPaymentMethods
    const bankLinkedAccountsNeedAttention: AccountManagementLinkedAccountBank[] = []
    from.me.bankAccounts.all?.forEach((bankAccount) => {
      if (bankAccount?.__typename === 'LinkedAccount') {
        const thisAccountsAchPaymentMethod = achPaymentMethods.find(
          (achPaymentMethod) => achPaymentMethod.account?.id === bankAccount.id,
        )
        if (
          !thisAccountsAchPaymentMethod &&
          bankAccount.status === LinkedAccountStatusCode.LinkedInUse
        ) {
          bankLinkedAccountsNeedAttention.push({
            __typename: 'LinkedAccount',
            ...bankAccount,
          })
        }
      }
    })

    return {
      activeLoanId:
        from.me.loans.latestActionableLoan?.aggregateStatus?.__typename ===
        'ActiveLoanAggregateStatus'
          ? from.me.loans.latestActionableLoan.id
          : undefined,
      defaultLoanPaymentMethodId: defaultLoanPaymentMethod?.id,
      debitCardPaymentMethods,
      achPaymentMethods,
      bankLinkedAccountsNeedAttention,
    }
  },
)
