import React, {FC} from 'react'
import {Linking} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type UnverifiedUserContactUsProps = {
  isVisible: boolean
}

const UnverifiedUserContactUs: FC<UnverifiedUserContactUsProps> = (props) => {
  const {t} = useTranslation(['UnverifiedUserContactUs', 'Common'])
  const {isVisible} = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const text = (
    <Trans
      t={t}
      i18nKey={'modalText'}
      components={{
        bold: <PFText variant={'p_semibold'} />,
      }}
    />
  )
  return (
    <Overlay
      visible={isVisible}
      title={t('modalTitle')}
      text={text}
      okayButtonText={t('Common:Continue')}
      onOkay={() => {
        navigation.pop()
        void Linking.openURL('mailto:help@possiblefinance.com')
      }}
      dismissButtonText={t('Common:NeverMind')}
      onDismiss={() => {
        navigation.pop()
      }}
      testID="UnverifiedUserContactUsModal"
    />
  )
}

export {UnverifiedUserContactUs}
