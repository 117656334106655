import React from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {smallGap} from 'src/designSystem/layout'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'

type EmptyStateTileProps = {
  onPress: () => void
}

export const NoPrimaryAccountTile = (props: EmptyStateTileProps): JSX.Element => {
  const {onPress: handleOnPress} = props
  const {t} = useTranslation('AccountManagement')

  return (
    <>
      <TouchableWithoutFeedback onPress={handleOnPress}>
        <View style={styles.outerView}>
          <Box marginRight={'small'}>
            <SvgIcon name={'plus'} colorVariant={'info'} />
          </Box>
          <Box flex>
            <PFText variant="p_semibold">{t('NoPrimaryAccount')}</PFText>
            <PFText variant="p_semibold" color="link">
              {t('PleaseTapHereToAdd')}
            </PFText>
          </Box>
          <Box marginLeft={'small'}>
            <SvgIcon name={'info'} colorVariant={'info'} />
          </Box>
        </View>
      </TouchableWithoutFeedback>
    </>
  )
}

const styles = StyleSheet.create({
  outerView: {
    alignItems: 'center',
    borderColor: NamedColors.ASH,
    borderRadius: 4,
    borderWidth: 2,
    flexDirection: 'row',
    marginBottom: smallGap,
    padding: smallGap,
  },
})
