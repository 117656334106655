import React, {createContext, useMemo, useState} from 'react'

import {LoanCustomPaymentOption} from '@possible/cassandra/src/types/types.mobile.generated'

type LoanPayProviderProps = {
  children: React.ReactNode
  initialSelectedPaymentOptionForTesting?: LoanCustomPaymentOption
  initialSelectedPaymentMethodIdForTesting?: string
  initialNewSelectedPaymentMethodId?: string
}

export type LoanPayContextState = {
  selectedPaymentOption?: LoanCustomPaymentOption
  selectedPaymentMethodId?: string
  newSelectedPaymentMethodId?: string
}

type LoanPaySetterContextState = {
  setSelectedPaymentOption: (paymentOption: LoanCustomPaymentOption | undefined) => void
  setSelectedPaymentMethodId: (paymentMethodId: string | undefined) => void
  setNewSelectedPaymentMethodId: (newSelectedPaymentMethodId: string | undefined) => void
}

export const LoanPayContext = createContext<LoanPayContextState>({})

/**
 * Define a separate context for setting the state to avoid unnecessary re-renders
 * in components that only need to set the state, but not read it.
 */
export const LoanPaySetterContext = createContext<LoanPaySetterContextState>({
  setSelectedPaymentOption: (): void => undefined,
  setSelectedPaymentMethodId: (): void => undefined,
  setNewSelectedPaymentMethodId: (): void => undefined,
})

/**
 * Context provider to allow components to integrate with adhoc loan payments local state throughout the flow.
 */
export const LoanPayProvider = ({
  children,
  initialSelectedPaymentOptionForTesting, // used to inject initial state for unit testing only
  initialSelectedPaymentMethodIdForTesting, // testing only
  initialNewSelectedPaymentMethodId, // testing only
}: LoanPayProviderProps): JSX.Element => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<
    LoanCustomPaymentOption | undefined
  >(initialSelectedPaymentOptionForTesting)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | undefined>(
    initialSelectedPaymentMethodIdForTesting,
  )
  const [newSelectedPaymentMethodId, setNewSelectedPaymentMethodId] = useState<string | undefined>(
    initialNewSelectedPaymentMethodId,
  )

  const contextValue = useMemo(
    () => ({selectedPaymentOption, selectedPaymentMethodId, newSelectedPaymentMethodId}),
    [selectedPaymentOption, selectedPaymentMethodId, newSelectedPaymentMethodId],
  )
  const setterContextValue = useMemo(
    () => ({
      setSelectedPaymentOption,
      setSelectedPaymentMethodId,
      setNewSelectedPaymentMethodId,
    }),
    [],
  )

  return (
    <LoanPayContext.Provider value={contextValue}>
      <LoanPaySetterContext.Provider value={setterContextValue}>
        {children}
      </LoanPaySetterContext.Provider>
    </LoanPayContext.Provider>
  )
}
