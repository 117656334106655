import React, {ReactNode, useEffect} from 'react'

import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanApprovedTile,
  LoanApprovedTileProps,
} from 'src/products/loans/Dashboard/LoanApprovedTile/LoanApprovedTile'
import {
  LoanCounterOfferTile,
  LoanCounterOfferTileProps,
} from 'src/products/loans/Dashboard/LoanCounterOfferTile/LoanCounterOfferTile'
import {
  AdverseReasonCode,
  ApprovedLoanAggregateStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'

export type DashboardLoanApprovedProps = Pick<
  ApprovedLoanAggregateStatus,
  'amountApproved' | 'amountRequested' | 'expirationDateTime' | 'loanTypeVariant'
> &
  Pick<
    LoanCounterOfferTileProps,
    'onViewCounterOffer' | 'onDeclineCounterOffer' | 'onContinueWithCounterOffer'
  > &
  Pick<LoanApprovedTileProps, 'onAcceptOffer'> & {
    counterOfferCodes: (AdverseReasonCode | null)[]
    firstName: string
  }

/**
 * Dashboard displayed when a loan is approved but not yet accepted.
 * @example <DashboardLoanApproved amountApproved="100.00" amountRequested="100.00" expirationDateTime="2023-05-09T12:00Z" .../>
 */
const DashboardLoanApproved: React.FC<DashboardLoanApprovedProps> = (props) => {
  const {
    amountApproved,
    amountRequested,
    expirationDateTime,
    onViewCounterOffer,
    onDeclineCounterOffer,
    onContinueWithCounterOffer,
    onAcceptOffer,
    firstName,
    counterOfferCodes,
    loanTypeVariant,
  } = props
  const amountApprovedNumber = parseFloat(amountApproved)
  const amountRequestedNumber = parseFloat(amountRequested)
  const hasCounterOffer = amountApprovedNumber < amountRequestedNumber
  useEffect(() => {
    TrackAppEvent(
      ManageActiveLoanEvents.approved_dashboard_viewed,
      AppEvents.Category.ManageActiveLoan,
      {
        isCounterOffer: hasCounterOffer,
      },
    )
  }, [hasCounterOffer])
  const tiles: ReactNode[] = []
  if (hasCounterOffer) {
    const props: LoanCounterOfferTileProps = {
      amountApproved: amountApprovedNumber,
      amountRequested: amountRequestedNumber,
      onViewCounterOffer,
      onDeclineCounterOffer,
      onContinueWithCounterOffer,
      firstName,
      counterOfferCodes,
    }
    tiles.push(
      <LoanCounterOfferTile {...props} testID="DashboardLoanApproved-LoanCounterOfferTile" />,
    )
  } else {
    const props: LoanApprovedTileProps = {
      amount: parseFloat(amountApproved),
      onAcceptOffer,
      expirationDate: new Date(expirationDateTime),
      loanTypeVariant,
    }
    tiles.push(<LoanApprovedTile {...props} testID="DashboardLoanApproved-LoanApprovedTile" />)
  }
  return <TileListView list={tiles} testID="DashboardLoanApproved" />
}

export {DashboardLoanApproved}
