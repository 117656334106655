import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'

import {SurveyTemplate} from 'src/products/general/components/templates/SurveyTemplate/SurveyTemplate'

const options = [
  i18next.t('ReasonSurvey:MedicalExpenses'),
  i18next.t('ReasonSurvey:EmergencyExpenses'),
  i18next.t('ReasonSurvey:VehicleRepair'),
  i18next.t('ReasonSurvey:PayBills'),
  i18next.t('ReasonSurvey:BuildOrRepairCreditHistory'),
  i18next.t('ReasonSurvey:FinanceServiceOrPurchase'),
  i18next.t('ReasonSurvey:PersonalPurposes'),
  i18next.t('ReasonSurvey:PurchaseVehicle'),
  i18next.t('ReasonSurvey:ConsolidateDebt'),
]

export type ReasonSurveyTemplateProps = {
  isContinueLoading: boolean
  onSubmitSurvey: (value: string, other?: string) => Promise<void>
}

export const ReasonSurveyTemplate: FC<ReasonSurveyTemplateProps> = (props) => {
  const {isContinueLoading, onSubmitSurvey: handleOnSubmitSurvey} = props
  const {t} = useTranslation('ReasonSurvey')

  return (
    <SurveyTemplate
      isContinueLoading={isContinueLoading}
      onContinue={handleOnSubmitSurvey}
      options={options}
      radioButtonExtraInputLabel={t('HowIsThisLoanHelpingYou')}
      testID={'ReasonSurveyTemplate'}
      radioButtonExtraInputTestID={'ReasonSurveyTemplate_Other'}
      title={t('HowIsThisLoanHelpingYou')}
    />
  )
}
