import React, {FC} from 'react'

import {LoanTilaDisclosureGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {AppEvents} from 'src/lib/Analytics/app_events'

type LoanTilaDisclosureWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

const LoanTilaDisclosureWorkflowContainer: FC<LoanTilaDisclosureWorkflowContainerProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.tila_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }

  return <LoanTilaDisclosureGQLContainer onContinue={handleOnContinue} />
}

export {LoanTilaDisclosureWorkflowContainer}
