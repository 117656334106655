import {TextStyle} from 'react-native'
import {isDeviceNotWeb} from 'src/lib/utils/platform'

// Android and iOS have different font loading schemes
// see https://medium.com/@kswanie21/custom-fonts-in-react-native-tutorial-for-ios-android-76ceeaa0eb78
// see https://medium.com/react-native-training/react-native-custom-fonts-ccc9aacf9e5e
// Android will read from the filename whilst iOS will match against the PostScript Name and the Full name.

export function HafferRegular() {
  return isDeviceNotWeb() ? 'Haffer-Regular' : 'Haffer Regular'
}

export function HafferSemiBold() {
  return isDeviceNotWeb() ? 'Haffer-SemiBold' : 'Haffer Semi Bold'
}

export const regularStyle: TextStyle = {
  fontFamily: HafferRegular(),
}

export const semiBoldStyle: TextStyle = {
  fontFamily: HafferSemiBold(),
}
