import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import DiagArrow from 'src/assets/illustrations/DiagArrow.svg'
import {PushPage} from 'src/navigation/NavHelper'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {EntryTile} from 'src/designSystem/components/molecules/EntryTile/EntryTile'

export type Props = {
  navigation?: MainStackNavigationProp
}

export const PhysicalCardActivationTile: FC<Props> = ({navigation}) => {
  const {t} = useTranslation('CardDashboardEntryPointTiles')

  return (
    <EntryTile
      svg={DiagArrow}
      header={t('GotYourPossibleCard')}
      body={t('LetsGetYouYourCard')}
      linkText={t('ActivateYourCard')}
      onLinkPress={(): void => {
        if (navigation) {
          PushPage(navigation, 'CardActivatePhysicalCard')
        }
      }}
    />
  )
}
