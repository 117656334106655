import {FetchResult} from '@apollo/client'

import {
  UserForgotPasswordSendCodeInput,
  UserForgotPasswordSendCodeDocument,
  UserForgotPasswordResetInput,
  UserForgotPasswordResetDocument,
  LoginResult,
  UserValidateVerificationCodeInput,
  UserValidateVerificationCodeDocument,
  UserValidateVerificationCodeResponse,
  UserExchangeTempTokenInput,
  UserExchangeTemporaryTokenDocument,
  UserRegisterInput,
  UserRegisterDocument,
  UserLoginInput,
  UserLoginDocument,
  UserLoginWithMfaInput,
  UserLoginWithMfaDocument,
  UserVerifyEmailInput,
  UserVerifyEmailConfirmDocument,
  UserTemporaryPasswordResetInput,
  UserResetTemporaryPasswordDocument,
  UserResendLoginWithMfaInput,
  UserResendLoginWithMfaDocument,
  UserIdentityProviderCallbackInput,
  UserIdentityProviderCallbackDocument,
  PartnerSelectOfferDocument,
  AccountRecoveryDocument,
  AccountRecoveryMutation,
} from '../types/types.public.generated'
import {ApplyMutation} from '../utils/operations'
import {GetPublicClient} from '../client'

export async function partnerSelectOffer(offerId: string): Promise<boolean | undefined> {
  const resp = await ApplyMutation(PartnerSelectOfferDocument, {offerId}, true)
  return resp?.partnerSelectOffer
}

export async function userForgotPasswordSendCode(
  input: UserForgotPasswordSendCodeInput,
): Promise<boolean | undefined> {
  const resp = await ApplyMutation(UserForgotPasswordSendCodeDocument, {input}, true)
  return resp?.userForgotPasswordSendCode
}

export async function userForgotPasswordReset(
  input: UserForgotPasswordResetInput,
): Promise<LoginResult | undefined> {
  const resp = await ApplyMutation(UserForgotPasswordResetDocument, {input}, true)
  return resp?.userForgotPasswordReset
}

export async function userValidateVerificationCode(
  input: UserValidateVerificationCodeInput,
): Promise<UserValidateVerificationCodeResponse | undefined> {
  const resp = await ApplyMutation(UserValidateVerificationCodeDocument, {input}, true)
  return resp?.userValidateVerificationCode
}

export async function userExchangeTemporaryToken(
  input: UserExchangeTempTokenInput,
): Promise<LoginResult | undefined> {
  return (
    (await ApplyMutation(UserExchangeTemporaryTokenDocument, {input}, true))
      ?.userExchangeTemporaryToken ?? undefined
  )
}

export async function userRegister(input: UserRegisterInput) {
  const client = GetPublicClient()
  return client.mutate({
    mutation: UserRegisterDocument,
    variables: {input},
  })
}

export async function userLogin(input: UserLoginInput) {
  const client = GetPublicClient()
  return client.mutate({
    mutation: UserLoginDocument,
    variables: {input},
  })
}

export async function userLoginWithMfa(input: UserLoginWithMfaInput) {
  const client = GetPublicClient()
  return client.mutate({
    mutation: UserLoginWithMfaDocument,
    variables: {input},
  })
}

export async function userVerifyEmailConfirm(
  input: UserVerifyEmailInput,
): Promise<boolean | undefined> {
  const resp = await ApplyMutation(UserVerifyEmailConfirmDocument, {input}, true)
  return resp?.userVerifyEmailConfirm
}

export async function userResetTemporaryPassword(input: UserTemporaryPasswordResetInput) {
  const client = GetPublicClient()
  return client.mutate({
    mutation: UserResetTemporaryPasswordDocument,
    variables: {input},
  })
}

export async function userResendLoginWithMfa(
  input: UserResendLoginWithMfaInput,
): Promise<boolean | undefined> {
  const resp = await ApplyMutation(UserResendLoginWithMfaDocument, {input}, true)
  return resp?.userResendLoginWithMfa
}

export async function userIdentityProviderCallback(input: UserIdentityProviderCallbackInput) {
  const client = GetPublicClient()
  return client.mutate({
    mutation: UserIdentityProviderCallbackDocument,
    variables: {input},
  })
}

export async function userAccountRecovery(
  phoneNumber: string,
): Promise<FetchResult<AccountRecoveryMutation>> {
  const client = GetPublicClient()
  return client.mutate({
    mutation: AccountRecoveryDocument,
    variables: {phoneNumber},
  })
}
