import * as Types from '../../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type ReapplicationConfirmBankMutationVariables = Types.Exact<{
  accountId: Types.Scalars['UUID']['input']
}>

export type ReapplicationConfirmBankMutation = {
  __typename?: 'Mutation'
  productApplicationUpdateRequirements: {
    __typename: 'ProductApplicationRequirementsResponse'
    success: boolean
  }
}

export const ReapplicationConfirmBankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'ReapplicationConfirmBank'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'accountId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'productApplicationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'confirmedBankReapplicationLinkedAccountId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'accountId'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReapplicationConfirmBankMutation,
  ReapplicationConfirmBankMutationVariables
>
