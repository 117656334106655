import i18n from 'i18next'
import {NativeModules} from 'react-native'
import {initReactI18next} from 'react-i18next'

import {isDeviceAndroid, isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'
import {isLanguageSupported} from 'src/lib/localization/i18nHelper'
import {getKeyValue} from 'src/lib/utils/storage'
import en from 'src/lib/localization/en'
import es from 'src/lib/localization/es'
import fr from 'src/lib/localization/fr'

export const resources = {
  en,
  es,
  fr,
}

export function getDeviceLanguage(): string | undefined {
  let language: string | undefined
  if (isDeviceIOS()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (NativeModules.SettingsManager?.settings?.AppleLocale) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      language = NativeModules.SettingsManager.settings.AppleLocale
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } else if (NativeModules.SettingsManager?.settings?.AppleLanguages?.[0]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      language = NativeModules.SettingsManager.settings.AppleLanguages[0]
    } else {
      language = undefined
    }
  } else if (isDeviceAndroid()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    language = NativeModules.I18nManager?.localeIdentifier
  } else if (isDeviceWeb()) {
    language = navigator?.languages?.[0]
    if (!language) {
      language = navigator?.language
    }
  } else {
    return undefined
  }
  return language?.substring(0, 2)
}

export async function updateLanguage(): Promise<void> {
  const language = (await getKeyValue('UserLanguage')) as string
  changeLanguage(language)
}

export function changeLanguage(language: string): boolean {
  let languageToUse: string = language
  if (!isLanguageSupported(languageToUse)) {
    const deviceLanguage = getDeviceLanguage()
    if (deviceLanguage && isLanguageSupported(deviceLanguage)) {
      languageToUse = deviceLanguage
    } else {
      languageToUse = 'en'
    }
  }
  if (languageToUse === i18n.language) {
    return false
  }
  void i18n.changeLanguage(languageToUse)
  return true
}

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
