import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

export type SetLoanAutoPayAccountTemplateProps = {
  onPressConfirm: () => void
  onPressNevermind: () => void
  isLoading: boolean
  isError: boolean
}

/**
 * Template for a page that lets a user set their loan autopay account/payment method.
 */
const SetLoanAutoPayAccountTemplate: React.FC<SetLoanAutoPayAccountTemplateProps> = (props) => {
  const {onPressConfirm, onPressNevermind, isLoading, isError} = props
  const {t} = useTranslation(['SetLoanAutoPayAccount', 'Common'])
  return (
    <Page
      title={t('UsingAutoPay')}
      variant="generic"
      smallTopGap
      buttonProps={
        isLoading || isError
          ? undefined
          : {
              type: 'doubleButton',
              primary: {
                text: t('ConfirmAutoPay'),
                onPress: onPressConfirm,
                testID: 'SetLoanAutoPayAccount-ConfirmButton',
              },
              secondary: {
                buttonText: t('Common:Nevermind'),
                onPress: onPressNevermind,
                testID: 'SetLoanAutoPayAccount-NevermindButton',
              },
            }
      }
    >
      <BaseTemplate testID="SetLoanAutoPayAccount" isLoading={isLoading} isError={isError}>
        <PFText variant="p">{t('SettingThisAccount')}</PFText>
      </BaseTemplate>
    </Page>
  )
}

export {SetLoanAutoPayAccountTemplate}
