import React from 'react'
import {ActivityIndicator} from 'react-native'

import {spinnerColor} from 'src/designSystem/semanticColors'

type Size = 'small' | 'large'

type Props = {
  animating?: boolean
  style?: object
  size?: Size
}

const DefaultActivityIndicator = (props: Props): JSX.Element => {
  return (
    <ActivityIndicator
      animating={props.animating}
      style={props.style}
      size={props.size}
      color={spinnerColor}
    />
  )
}

export default DefaultActivityIndicator
