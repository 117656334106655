import React, {ReactNode} from 'react'
import {useDispatch} from 'react-redux'
import {useChaosModeRenderFailure} from '../hooks/useChaosModeRenderFailure'

type ChaosEnabledProps = {
  componentName: string
  dispatch: ReturnType<typeof useDispatch>
  children: ReactNode
}
const ChaosEnabled: React.FC<ChaosEnabledProps> = (props) => {
  const {componentName, dispatch, children} = props
  useChaosModeRenderFailure({
    componentName,
    dispatch,
  })
  return <>{children}</>
}

export {ChaosEnabled}
