import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'

import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {
  OnBankSelectedForPrimaryAccountSelection,
  OnContinueAfterPrimaryAccountSelected,
  OnPrimaryAccountSelectionSavingFailed,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.types'
import {
  trackBankAccountSelectedBeforeSave,
  trackContinueCTAButtonTapped,
  trackPrimaryAccountSavedInOnboarding,
  trackPrimaryAccountSaveFailedInOnboarding,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.utils'
import {
  PrimaryAccountSelectionGQLContainerProps,
  PrimaryAccountSelectionGQLContainer,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionGQLContainer'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type PrimaryAccountSelectionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps &
  Pick<PrimaryAccountSelectionGQLContainerProps, 'navigation'>

const PrimaryAccountSelectionWorkflowContainer: React.FC<
  PrimaryAccountSelectionWorkflowContainerProps
> = (props: PrimaryAccountSelectionWorkflowContainerProps) => {
  const {navigation, onPreReqFulfilled} = props
  const {t} = useTranslation(['AccountManagement', 'Common'])

  usePageViewedAnalytics({
    eventName: AppEvents.Name.select_primary_account_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
    eventArgs: {
      isFromOnboarding: true,
    },
  })

  const isFromOnboarding: boolean = true

  const handleOnBankAccountSelected: OnBankSelectedForPrimaryAccountSelection = () => {
    trackBankAccountSelectedBeforeSave({isFromOnboarding})
  }
  const handleOnContinueButtonTapped: OnContinueAfterPrimaryAccountSelected = (): void => {
    trackContinueCTAButtonTapped({isFromOnboarding})
  }
  const handleOnContinueWorkflows: OnContinueAfterPrimaryAccountSelected =
    async (): Promise<void> => {
      try {
        trackPrimaryAccountSavedInOnboarding()
        Log.info('Successfully set bank account as primary in Workflows')
        await onPreReqFulfilled()
      } catch (e) {
        logOfferApplicationError(
          e,
          'Error setting bank account as primary in Workflows and fulfilling prereq',
        )
        ShowException(t('ErrorSettingPrimaryAccount'))
      }
    }

  const handleOnSavingPrimaryAccountFailed: OnPrimaryAccountSelectionSavingFailed = (e: Error) => {
    trackPrimaryAccountSaveFailedInOnboarding()
    logOfferApplicationError(e, 'Error setting bank account as primary in Workflows')
    ShowException(t('ErrorSettingPrimaryAccount'))
  }

  return (
    <PrimaryAccountSelectionGQLContainer
      navigation={navigation}
      onBankAccountSelected={handleOnBankAccountSelected}
      onContinueAfterPrimaryAccountSelected={handleOnContinueButtonTapped}
      onContinueAfterPrimaryAccountSelectionSaved={handleOnContinueWorkflows}
      onSavingPrimaryAccountFailed={handleOnSavingPrimaryAccountFailed}
      isFromOnboarding={isFromOnboarding}
    />
  )
}

export {PrimaryAccountSelectionWorkflowContainer}
