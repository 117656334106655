import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {StateDisclosureScreenProps} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosure.types'
import {StateDisclosureTemplate} from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosureTemplate'

export const LAStateDisclosureTemplate: FC<StateDisclosureScreenProps> = (props) => {
  const {onContinue, onExit, isSubmitting, isLoading} = props

  const {t} = useTranslation(['StateDisclosure', 'Common'])

  const action = {
    text: t('Common:AcknowledgeAndContinue'),
    testID: 'Acknowledge-And-Continue-Button-LA',
    onPress: (): void | Promise<void> => onContinue(),
    loading: isSubmitting,
    disabled: isSubmitting || isLoading,
  }

  const secondary = {
    buttonText: t('Common:Exit'),
    testID: 'ButtonLink-LA',
    onPress: (): void | Promise<void> => onExit(),
    disabled: isSubmitting,
  }

  return (
    <StateDisclosureTemplate
      title={t('LATitle')}
      description={t('LADescription')}
      action={action}
      secondary={secondary}
      testID={`State-Disclosure-Description-USA-LA`}
    />
  )
}
