import {getDatabase, ref, onValue, off} from 'firebase/database'

import Firebase from 'src/lib/firebase'
import {RealtimeDbProperty} from 'src/lib/RealtimeDatabase/RealtimeDb.types'
import {RealtimeDatabaseAdapterInterface} from 'src/lib/RealtimeDatabase/RealtimeDbAdapter/RealtimeDbAdapterInterface'

const subscribe = (
  dbProperty: RealtimeDbProperty,
  onPropertyValueChanged: (dbProperty: RealtimeDbProperty, maintenanceModeValue: unknown) => void,
): void => {
  const dbRef = ref(getDatabase(Firebase.getApp()), dbProperty)
  onValue(dbRef, (snapshot) => {
    onPropertyValueChanged(dbProperty, snapshot.val())
  })
}

const unsubscribe = (dbProperty: RealtimeDbProperty): void => {
  const dbRef = ref(getDatabase(Firebase.getApp()), dbProperty)
  off(dbRef, 'value')
}

const webRealtimeDbAdapter: RealtimeDatabaseAdapterInterface = {
  subscribe,
  unsubscribe,
}
export {webRealtimeDbAdapter as realtimeDatabaseAdapter}
