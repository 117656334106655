//Monkey patch because react native web has removed these before react native
import * as ReactNative from 'react-native'

import {EnvironmentType} from '@possible/cassandra'

import Log from 'src/lib/loggingUtil'
import PropTypes from 'prop-types'
import RNFS from 'src/lib/RNFS/RNFS'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {DonkeyUiBranch} from '@possible/cassandra/src/types/types.public.generated'

if (isDeviceWeb()) {
  Log.log('Monkey Patching WebApp React Native Prop Types')

  // @ts-expect-error Gotta do what we gotta do
  ReactNative.Text.propTypes = {style: PropTypes.any}
  // @ts-expect-error Gotta do what we gotta do
  ReactNative.View.propTypes = {style: PropTypes.any}
  // @ts-expect-error Gotta do what we gotta do
  ReactNative.Image.propTypes = {style: PropTypes.any}
  // // @ts-ignore
  // ReactNative.ViewPropTypes = {style: PropTypes.any}
  // @ts-expect-error Gotta do what we gotta do
  ReactNative.TextInput.propTypes = {style: PropTypes.any}
}

const basePath = RNFS.DocumentDirectoryPath
const envPath = `${basePath}/pf_env`
const devModePath = `${basePath}/dev_mod`
const uiDeploymentPath = `${basePath}/ui_deployment`

if (__DEV__) {
  void RNFS.writeFile(devModePath, 'dev_mode', 'utf8')
}

export type DevModeInfoType = {
  devMode?: boolean
  branch?: DevBranchInfo
  env?: EnvironmentType
}

export const devModeInfo: DevModeInfoType = {
  devMode: undefined,
  branch: undefined,
  env: undefined,
}

async function privateReadDevMode(): Promise<boolean> {
  if (__DEV__) {
    Log.log('Dev mode : ', true)
    return true
  }

  try {
    const statResult = await RNFS.stat(devModePath)
    if (statResult && statResult.isFile()) {
      Log.log('Dev Mode Enabled')
      return true
    }
    Log.log('Dev Mode Disabled')
    return false
  } catch (e) {
    if (e instanceof Error) {
      Log.log(`Dev Mode Disabled with error: ${e.message}`)
    }
    return false
  }
}

export async function readDevMode(): Promise<boolean> {
  if (devModeInfo.devMode !== undefined) {
    return devModeInfo.devMode
  }
  devModeInfo.devMode = await privateReadDevMode()
  return devModeInfo.devMode
}

export async function readEnv(): Promise<EnvironmentType | undefined> {
  let ret: EnvironmentType | undefined
  try {
    const statResult = await RNFS.stat(envPath)
    if (statResult && statResult.isFile()) {
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      ret = (await RNFS.readFile(envPath, 'utf8')) as EnvironmentType
    }
  } catch (e) {
    return undefined
  }
  return ret
}

export async function setEnv(env: EnvironmentType | undefined): Promise<void> {
  try {
    if (env && env !== EnvironmentType.NotSet) {
      await RNFS.writeFile(envPath, env, 'utf8')
    } else {
      await RNFS.unlink(envPath)
    }
  } catch (e) {
    Log.log('setEnv error:', e)
  }
}

export async function setDevMode(dev_mode: boolean | undefined): Promise<void> {
  try {
    if (dev_mode) {
      await RNFS.writeFile(devModePath, 'dev_mode', 'utf8')
    } else {
      await RNFS.unlink(devModePath)
    }
  } catch (e) {
    Log.log('setDevMode error:', e)
  }
}

export type DevBranchInfo = Pick<DonkeyUiBranch, 'name' | 'deploymentKey'>

export async function readUiDeploymentKey(): Promise<DevBranchInfo | undefined> {
  if (devModeInfo.branch !== undefined) {
    return devModeInfo.branch
  }
  let ret: string | undefined
  try {
    ret = await RNFS.readFile(uiDeploymentPath, 'utf8')
  } catch (e) {
    return undefined
  }
  if (!ret) return undefined

  try {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const parsed = JSON.parse(ret) as {name: string; deploymentKey: string} | undefined
    devModeInfo.branch = !parsed?.name || !parsed?.deploymentKey ? undefined : parsed
    return devModeInfo.branch
  } catch (e) {
    Log.log('readUiDeploymentKey error:', e)
    return undefined
  }
}

export async function setUiDeploymentKey(
  name: string | undefined,
  deploymentKey: string | undefined,
): Promise<void> {
  await RNFS.writeFile(uiDeploymentPath, JSON.stringify({name, deploymentKey}), 'utf8')
}

export async function clearDeploymentBranchKey(): Promise<void> {
  await RNFS.unlink(uiDeploymentPath)
}
