import {ApolloError} from '@apollo/client'

import {UserEnv} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {UserEnvDocument} from 'src/lib/user/queries/UserEnv.gqls'

type UseGetUserEnvResult = {
  userEnvData: UserEnv | undefined
  userEnvError: ApolloError | undefined
  isLoadingUserEnv: boolean
}

export const useGetUserEnv = (): UseGetUserEnvResult => {
  const {
    selectedData: userEnvData,
    loading: isLoadingUserEnv,
    error: userEnvError,
  } = useCassandraQuery(
    UserEnvDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data?.getUserEnv,
  )
  return {userEnvData, userEnvError, isLoadingUserEnv}
}
