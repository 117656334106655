import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type SelectPaymentToRescheduleQueryVariables = Types.Exact<{[key: string]: never}>

export type SelectPaymentToRescheduleQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
      } | null
    } | null
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        state: Types.StateCodes
        aggregateStatus:
          | {
              __typename: 'ActiveLoanAggregateStatus'
              payments: {
                __typename?: 'LoanPaymentCollection'
                payments: Array<{
                  __typename?: 'LoanPayment'
                  id: string
                  ordinal: number
                  statusCode: Types.LoanPaymentStatusCode
                  amount: string
                  rescheduledDate?: string | null
                  originalDate: string
                }>
              }
            }
          | {__typename: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'}
          | {__typename: 'DecisioningLoanAggregateStatus'}
          | {__typename: 'UndefinedLoanAggregateStatus'}
      } | null
    }
  }
}

export const SelectPaymentToRescheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'SelectPaymentToReschedule'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'timeZone'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'payments'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'payments'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'ordinal'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'statusCode'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'amount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'rescheduledDate',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'originalDate'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectPaymentToRescheduleQuery,
  SelectPaymentToRescheduleQueryVariables
>
