export const DEV_HOST = 'possiblesasquatch.com'
export const PROD_HOST = 'possiblefinance.com'

export type POSSIBLE_HOST = typeof DEV_HOST | typeof PROD_HOST

export const ApiV1 = (host: POSSIBLE_HOST) => `https://api.${host}`
export const ApiV2 = (host: POSSIBLE_HOST) => `https://gateway.${host}`
export const App = (host: POSSIBLE_HOST) => `https://app.${host}`

export const apiUriDev = {
  apiV1Uri: ApiV1(DEV_HOST),
  apiV2Uri: ApiV2(DEV_HOST),
  appUri: App(DEV_HOST),
}

export const apiUriProd = {
  apiV1Uri: ApiV1(PROD_HOST),
  apiV2Uri: ApiV2(PROD_HOST),
  appUri: App(PROD_HOST),
}
