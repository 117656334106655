import {useEffect} from 'react'

import {PreReqSortFunctionType} from 'src/workflows/order/types'
import {PreReqType, SelectedOfferInformation} from 'src/workflows/types'
import {SetPreReqSortFunction} from 'src/workflows/workflow.utils'
import {wfLog} from 'src/workflows/logging'

export type PreReqSortFunctionSelectorType<PT> = (
  selectedOffer: SelectedOfferInformation | undefined | null,
  unmetPreReqs: PreReqType[],
  passthrough: PT,
) => PreReqSortFunctionType

/**
 * Allow dynamic sorting based on selectedOffer, unmetPreReqs, and arbitrary passthrough data.
 * @param selectedOffer
 * The currently selected offer we are sorting for (or none for minimum pre-reqs).
 * @param unmetPreReqs
 * The selected offer, application, or minimum unmet pre-reqs.
 * @param preReqSortSelector
 * This is a function that should return a PreReqSortFunctionType.
 * @param passthrough
 * Arbitrary data the selector might need -- could be experiments, user data, etc.
 * This is passed through to the selector. This needs to be MEMOIZED.
 */
const useWorkflowsSort = <PT>(args: {
  selectedOffer: SelectedOfferInformation | undefined | null
  unmetPreReqs: PreReqType[]
  preReqSortSelector: PreReqSortFunctionSelectorType<PT>
  passthrough: PT
}): void => {
  const {selectedOffer, unmetPreReqs, preReqSortSelector, passthrough} = args
  useEffect(() => {
    wfLog(`useWorkflowsSort Effect Running`)
    const preReqSortFunction = preReqSortSelector(selectedOffer, unmetPreReqs, passthrough)
    SetPreReqSortFunction(preReqSortFunction)
  }, [passthrough, preReqSortSelector, selectedOffer, unmetPreReqs])
}

export {useWorkflowsSort}
