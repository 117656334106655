import {CompositeNavigationProp, useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useCallback, useContext} from 'react'

import {Consumer, GetMeAction} from '@possible/cassandra'
import {
  CardAccountSchedulePaymentInput,
  CardAdhocPaymentQuery,
  ProcessorNetwork,
} from '@possible/cassandra/src/types/consumer'

import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {clearSelectedPayNowPaymentMethod} from 'src/lib/card/slice'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {CardAdhocPaymentContext} from 'src/products/card/AdhocPayment/useCardAdhocPayment/CardAdhocPaymentProvider'
import {CardAccountSchedulePaymentDocument} from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment.gqls'
import {PaymentReducerAction, PaymentState} from 'src/products/card/AdhocPayment/usePaymentState'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {usePfDispatch} from 'src/store/utils'
import {useCardAdhocPaymentAccount} from 'src/products/card/AdhocPayment/useCardAdhocPaymentAccount/useCardAdhocPaymentAccount'

type UseCardAdhocPayment = {
  cardAccountId: string
  currentBalance: string
  data: CardAdhocPaymentQuery | undefined
  dispatchPaymentState: React.Dispatch<PaymentReducerAction>
  executePayment: () => Promise<void>
  isLoading: PaymentState['loading']
  userSelectedButtonId: PaymentState['userSelectedButtonId']
  paymentState: PaymentState['paymentArgs']
}

// The plan is to move the logic within this function to the backend.
const getCurrentBalance = (
  activeAccount: CardAdhocPaymentQuery['me']['cardAccounts']['active'] | null,
): string => {
  return activeAccount?.status && 'balance' in activeAccount.status
    ? (activeAccount?.status.balance.ledgerSpentAfterPayments ?? '0')
    : '0'
}

const useCardAdhocPayment = (): UseCardAdhocPayment => {
  const paymentStateContext = useContext(CardAdhocPaymentContext)
  const {data, loading: isLoadingQuery} = Consumer.hooks.useCardAdhocPaymentQuery({
    fetchPolicy: 'cache-first',
  })
  const [schedulePayment] = useCassandraMutation(CardAccountSchedulePaymentDocument)
  const {isLoading: isLoadingAdhocPaymentAccount, adhocPaymentAccount: selectedPaymentMethod} =
    useCardAdhocPaymentAccount()
  const pfDispatch = usePfDispatch()
  const linkPaymentMethod = useLinkPaymentMethod()
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<CardAdhocPaymentStack>,
        StackNavigationProp<MainStackParamList>
      >
    >()

  if (!paymentStateContext) {
    throw new Error('useAdhocPayment has to be used within <AdhocPaymentProvider.Provider>')
  }

  const [paymentState, dispatchPaymentState] = paymentStateContext

  const cardAccountId =
    !isLoadingQuery && data?.me?.cardAccounts.active?.id ? data?.me?.cardAccounts.active?.id : ''

  const getProcessorNetwork = (paymentMethodType: string | undefined): ProcessorNetwork => {
    switch (paymentMethodType) {
      case 'AchPaymentMethod':
        return ProcessorNetwork.Ach
      case 'DebitCardPaymentMethod':
        return ProcessorNetwork.Interchange
      case 'CheckPaymentMethod':
        return ProcessorNetwork.Check
      default:
        return ProcessorNetwork.None
    }
  }

  const executePayment = useCallback(async (): Promise<void> => {
    const isBankingPaymentMethod =
      selectedPaymentMethod?.__typename === 'AchPaymentMethod' ||
      selectedPaymentMethod?.__typename === 'DebitCardPaymentMethod' ||
      selectedPaymentMethod?.__typename === 'CheckPaymentMethod'
    const bankingPaymentInstrumentId = isBankingPaymentMethod
      ? selectedPaymentMethod?.bankingPaymentInstrumentId
      : ''

    const processorNetwork = getProcessorNetwork(selectedPaymentMethod?.__typename)

    const paymentSpecification = {
      intention: paymentState.paymentArgs?.paymentSpecification.intention,
      amount: paymentState.paymentArgs?.paymentSpecification.amount,
    }

    const variables: {input: CardAccountSchedulePaymentInput} = {
      input: {
        cardAccountId,
        paymentInstrumentId: bankingPaymentInstrumentId,
        processorNetwork,
        paymentSpecification,
      },
    }

    try {
      dispatchPaymentState({type: 'setLoading', payload: {loading: true}})

      /* Ensure the default payment method is linked to the card account and is the primary
       * payment method */
      await linkPaymentMethod(getPaymentMethodAccount(selectedPaymentMethod)?.id)

      const {data} = await schedulePayment({variables})

      if (!data?.cardAccountSchedulePayment.id) {
        throw new Error()
      }

      await pfDispatch(GetMeAction())
      pfDispatch(clearSelectedPayNowPaymentMethod())

      dispatchPaymentState({
        type: 'success',
        payload: {paymentArgs: variables.input},
      })

      navigation.navigate('CardAdhocPaymentSuccess')
    } catch (error) {
      dispatchPaymentState({type: 'setLoading', payload: {loading: false}})

      navigation.navigate('CardAdhocPaymentFailure')
    }
  }, [
    paymentState.paymentArgs,
    cardAccountId,
    selectedPaymentMethod,
    dispatchPaymentState,
    linkPaymentMethod,
    schedulePayment,
    pfDispatch,
    navigation,
  ])

  const currentBalance = getCurrentBalance(data?.me?.cardAccounts.active)

  return {
    cardAccountId,
    currentBalance,
    dispatchPaymentState,
    data,
    executePayment,
    paymentState: paymentState.paymentArgs,
    userSelectedButtonId: paymentState.userSelectedButtonId,
    isLoading: isLoadingQuery || paymentState.loading || isLoadingAdhocPaymentAccount,
  }
}

export {useCardAdhocPayment}
