export const DELETE_MAP_ACTION_TYPE = Symbol('DELETE_MAP_ACTION_TYPE')
export type DeleteMapActionType = {
  type: symbol
  entityType: string
  keys: string[]
}

export const UPSERT_MAP_ACTION_TYPE = Symbol('UPSERT_MAP_ACTION_TYPE')
export type UpsertMapActionType<T> = {
  type: symbol
  entityType: string
  values: Record<string, T>
}

export type entityActionTypes = DeleteMapActionType | UpsertMapActionType<any>

export function isDeleteMapAction(action: entityActionTypes): action is DeleteMapActionType {
  return action.type === DELETE_MAP_ACTION_TYPE
}

export function isUpsertMapAction(action: entityActionTypes): action is UpsertMapActionType<any> {
  return action.type === UPSERT_MAP_ACTION_TYPE
}
