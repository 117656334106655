import {useCallback, useEffect} from 'react'

import {
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, isPrioritizedPlaidSDKEvent, PlaidSDKEvents} from 'src/lib/Analytics/app_events'
import {ContextualizedLogException} from 'src/lib/errors'

import {
  PlaidPublicTokenLocalStorageKey,
  PlaidReturnURIKey,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.consts'
import {
  LinkAccountType,
  LinkAccountVerificationStatus,
  LinkErrorCode,
  LinkErrorType,
  LinkEventMetadata,
  LinkEventViewName,
  LinkStableEvent,
  onExitEventType,
  onSuccessEventType,
  UseUniversalPlaidLink,
  UseUniversalPlaidRelink,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'

/**
 * transmogrify the onSuccess metadata into our internal type
 * @param publicToken our public token from plaid
 * @param metadata the bits and bobs from plaid
 * @returns
 */
const adaptSuccessEventType = (
  publicToken: string,
  metadata: PlaidLinkOnSuccessMetadata,
): onSuccessEventType => {
  const accounts: onSuccessEventType['accounts'] = metadata.accounts.map((account) => ({
    id: account.id,
    name: account.name,
    mask: account.mask,
    type: account.type as LinkAccountType,
    subtype: account.subtype,
    verificationStatus: account.verification_status as LinkAccountVerificationStatus,
  }))

  let institution: onSuccessEventType['institution'] = undefined
  if (metadata.institution) {
    institution = {
      name: metadata.institution.name,
      id: metadata.institution.institution_id,
    }
  }

  return {
    ...metadata,
    publicToken,
    linkSessionId: metadata.link_session_id,
    accounts,
    institution,
  }
}

const adaptEventMetadata = (metadata: PlaidLinkOnEventMetadata): LinkEventMetadata => {
  return {
    errorType: metadata.error_type ?? '',
    errorCode: metadata.error_code ?? '',
    errorMessage: metadata.error_message ?? '',
    exitStatus: metadata.exit_status ?? '',
    institutionId: metadata.institution_id ?? '',
    institutionName: metadata.institution_name ?? '',
    institutionSearchQuery: metadata.institution_search_query ?? '',
    mfaType: metadata.mfa_type ?? '',
    viewName: metadata.view_name as LinkEventViewName,
    selection: metadata.selection ?? '',
    timestamp: metadata.timestamp,
    linkSessionId: metadata.link_session_id,
  }
}

/**
 * transmogrify the onExit metadata into our internal type
 * @param error the error that caused the exit, if any
 * @param metadata the bits and bobs from plaid
 * @returns
 */
const adaptExitEventType = (
  error: PlaidLinkError | null,
  metadata: PlaidLinkOnExitMetadata,
): onExitEventType => {
  let institution: onExitEventType['institution'] = undefined
  if (metadata.institution) {
    institution = {
      name: metadata.institution.name,
      id: metadata.institution.institution_id,
    }
  }

  return {
    ...metadata,
    linkSessionId: metadata.link_session_id,
    institution,
    requestId: metadata.request_id,
    status: metadata.status as onExitEventType['status'],
    error: error
      ? {
          errorCode: error?.error_code as LinkErrorCode,
          errorMessage: error?.error_message,
          errorType: error?.error_type as LinkErrorType,
          displayMessage: error?.display_message,
        }
      : undefined,
  }
}

export const useUniversalPlaidOAuthRelink: UseUniversalPlaidRelink = (onSuccess) => {
  const returnUri = localStorage.getItem(PlaidReturnURIKey)
  const publicToken = localStorage.getItem(PlaidPublicTokenLocalStorageKey)

  const handleEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    const lowerCasePlaidEventNameWithPrefix = `plaid_${eventName.toLowerCase()}`

    if (isPrioritizedPlaidSDKEvent(lowerCasePlaidEventNameWithPrefix)) {
      TrackAppEvent(
        PlaidSDKEvents[lowerCasePlaidEventNameWithPrefix],
        AppEvents.Category.PlaidSDK,
        {
          viewName: metadata.view_name,
          errorCode: metadata.error_code,
          errorMessage: metadata.error_message,
          errorType: metadata.error_type,
        },
      )
    }
  }, [])

  const {open, ready} = usePlaidLink({
    token: publicToken,
    onSuccess: (publicToken, metadata) => {
      onSuccess(adaptSuccessEventType(publicToken, metadata))
        .then(() => {
          localStorage.removeItem(PlaidReturnURIKey)
          localStorage.removeItem(PlaidPublicTokenLocalStorageKey)
        })
        .catch(ContextualizedLogException('Failed to handle Plaid onSuccess during relinking'))
    },
    onEvent: handleEvent,
    receivedRedirectUri: returnUri ?? '',
  })

  const handleOpen = useCallback((): void => {
    open()
  }, [open])

  return {
    ready: ready && !!returnUri && !!publicToken,
    open: handleOpen,
  }
}

export const useUniversalPlaidLink: UseUniversalPlaidLink = (options) => {
  const {publicToken, onSuccess, onExit, onEvent} = options

  useEffect(() => {
    if (publicToken) {
      localStorage.setItem(PlaidPublicTokenLocalStorageKey, publicToken)
    }
  }, [publicToken])

  const handleSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      try {
        await onSuccess(adaptSuccessEventType(publicToken, metadata))
        localStorage.removeItem(PlaidPublicTokenLocalStorageKey)
        localStorage.removeItem(PlaidReturnURIKey)
      } catch (e) {
        ContextualizedLogException('Failed to handle Plaid onSuccess')(e)
      }
    },
    [onSuccess],
  )

  const handleEvent = useCallback<PlaidLinkOnEvent>(
    (eventName, metadata) => {
      const lowerCasePlaidEventNameWithPrefix = `plaid_${eventName.toLowerCase()}`

      if (isPrioritizedPlaidSDKEvent(lowerCasePlaidEventNameWithPrefix)) {
        TrackAppEvent(
          PlaidSDKEvents[lowerCasePlaidEventNameWithPrefix],
          AppEvents.Category.PlaidSDK,
          {
            viewName: metadata.view_name,
            errorCode: metadata.error_code,
            errorMessage: metadata.error_message,
            errorType: metadata.error_type,
            institution_name: metadata.institution_name,
          },
        )
      }

      onEvent(eventName as unknown as LinkStableEvent, adaptEventMetadata(metadata))
    },
    [onEvent],
  )

  const handleExit = useCallback<PlaidLinkOnExit>(
    (error, metadata) => {
      onExit(adaptExitEventType(error, metadata))
    },
    [onExit],
  )

  const {open, ready} = usePlaidLink({
    token: publicToken,
    onSuccess: handleSuccess,
    onExit: handleExit,
    onEvent: handleEvent,
  })

  const handleOpen = useCallback(() => {
    if (ready) {
      open()
    }
  }, [open, ready])

  return {
    ready,
    open: handleOpen,
  }
}
