import React from 'react'

import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import i18n from 'src/lib/localization/i18n'
import {getRemoteValueJson} from 'src/lib/RemoteConfig'
import {HOW_DID_YOU_HEAR_ABOUT_US} from 'src/lib/RemoteConfig/parameterkeys'
import {UpdateMarketingSurveyDocument} from 'src/products/general/MarketingSurvey/MarketingSurvey.gqls'
import {
  MarketingSurveyBaseProps,
  OnContinueAfterMarketingSurveyOptionSelected,
} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'
import {MarketingSurveyTemplate} from 'src/products/general/MarketingSurvey/MarketingSurveyTemplate'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

/**
 * Container to store necessary marketing survey answers with redux.
 */
const MarketingSurveyGQLContainer: React.FC<MarketingSurveyBaseProps> = (
  props: MarketingSurveyBaseProps,
) => {
  const {onContinue} = props

  const [updateMarketingSurvey] = useCassandraMutation(UpdateMarketingSurveyDocument)

  /**
   * Gets the survey options list version.
   */
  const getListVersion = (): string => {
    const remoteConfig: {version?: string} | undefined =
      getRemoteValueJson(HOW_DID_YOU_HEAR_ABOUT_US)
    if (!remoteConfig) {
      return 'v8'
    }
    const version = remoteConfig['version']
    if (!version) {
      return 'unknown'
    }
    return typeof version === 'string' ? version : String(version)
  }

  /**
   * Gets the survey options from remote config.
   */
  const options = React.useMemo((): string[] | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const remoteConfig = getRemoteValueJson(HOW_DID_YOU_HEAR_ABOUT_US)
    if (remoteConfig) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const optionList = remoteConfig[i18n.language]
      const optionsListStrings: string[] = []
      if (optionList && Array.isArray(optionList)) {
        optionList.forEach((thisOption) => {
          if (typeof thisOption === 'string') {
            optionsListStrings.push(thisOption)
          }
        })
        return optionsListStrings
      }
    }
    return undefined
  }, [])

  const handleOnContinue: OnContinueAfterMarketingSurveyOptionSelected = async ({
    selectedSourceOption,
    otherValue,
  }) => {
    const listVersion: string = getListVersion()

    try {
      const response = await updateMarketingSurvey({
        variables: {
          input: {
            source: selectedSourceOption,
            otherManualInput: otherValue,
            version: listVersion,
          },
        },
      })

      if (response?.errors?.length) {
        throw new Error(`GraphQL Error: ${response.errors[0].message}`)
      }

      if (!response.data?.productApplicationUpdateRequirements.success) {
        throw new Error('Update attempt considered unsuccessful by backend')
      }

      await onContinue({
        selectedSourceOption,
        otherValue,
      })
    } catch (error) {
      logOfferApplicationError(error, 'Failed to update marketing survey data')
    }
  }

  return <MarketingSurveyTemplate onContinue={handleOnContinue} marketingSourceOptions={options} />
}

export {MarketingSurveyGQLContainer}
