import {
  AddPaymentMethodResultType,
  PaymentMethodStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {GraphQLError} from 'graphql'

import {
  BankAddAchPaymentMethodDocument,
  BankAddAchPaymentMethodMutation,
} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/BankAddAchPaymentMethod.gqls'
import {UseBankAddAchPaymentMethodResultPaymentMethod} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/useBankAddAchPaymentMethod'
import {CreateMockedClient} from 'test/global/common'

/**
 * Get an error response for the UseBankAddAchPaymentMethod mutation.
 */
export const getBankAddAchPaymentMethodErrorResponse = (args: {
  resultType: Exclude<AddPaymentMethodResultType, AddPaymentMethodResultType.Successful>
}): BankAddAchPaymentMethodMutation => {
  return {
    __typename: 'Mutation',
    bankAddAchPaymentMethod: {
      __typename: 'AddPaymentMethodResponse',
      paymentMethod: null,
      result: {
        __typename: 'AddPaymentMethodResult',
        type: args.resultType,
      },
    },
  }
}

export const mockBankRoutingAndAccountResultPaymentMethod: UseBankAddAchPaymentMethodResultPaymentMethod =
  {
    id: '1',
    __typename: 'AchPaymentMethod',
    status: {
      __typename: 'VerifiedPaymentMethodStatus',
      code: PaymentMethodStatusCode.Verified,
    },
    account: {
      id: 'linkedaccount-1',
    },
    bankingPaymentInstrumentId: 'bankingPaymentInstrumentId-1',
    bankAccount: {
      friendlyName: 'Xelayan Savings',
      institution: {
        name: 'Xelayan National Bank',
      },
      mask: '1234',
    },
  }

/**
 * Get a mocked Apollo Client config for the BankAddAchPaymentMethodMutation.
 */
export const getBankRoutingAndAccountMockedApolloClientConfig = (args: {
  requestInput: {
    routingNumber: string
    accountNumber: string
    linkedAccountId?: string
  }
  response: {
    resultType?: AddPaymentMethodResultType
    paymentMethod?: UseBankAddAchPaymentMethodResultPaymentMethod | null
    errors?: GraphQLError[]
  }
}): Parameters<typeof CreateMockedClient>[0] => {
  return [
    {
      request: {
        query: BankAddAchPaymentMethodDocument,
        variables: {
          iam: false,
          input: {
            routingNumber: args.requestInput.routingNumber,
            accountNumber: args.requestInput.accountNumber,
            linkedAccountId: args.requestInput.linkedAccountId,
          },
        },
      },
      result: {
        // if errors provided data is empty
        data: args.response.errors
          ? undefined
          : {
              bankAddAchPaymentMethod: {
                result: {
                  type: args.response.resultType,
                },
                paymentMethod: args.response.paymentMethod,
              },
            },
        errors: args.response.errors,
      },
    },
  ]
}
