import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AcceptAgreementsV2QueryVariables = Types.Exact<{[key: string]: never}>

export type AcceptAgreementsV2Query = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
  }
}

export type GenerateAchAgreementMutationMutationVariables = Types.Exact<{
  loanId: Types.Scalars['ID']['input']
  linkedAccountId?: Types.InputMaybe<Types.Scalars['ID']['input']>
  paymentMethodId?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type GenerateAchAgreementMutationMutation = {
  __typename?: 'Mutation'
  loanGenerateAchAgreement: {
    __typename?: 'LoanDocumentWithDownloadUrl'
    type: Types.LoanDocumentType
    download: {__typename?: 'PresignedUrl'; url: string; expiresAt: string}
  }
}

export const AcceptAgreementsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AcceptAgreementsV2'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptAgreementsV2Query, AcceptAgreementsV2QueryVariables>
export const GenerateAchAgreementMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'GenerateACHAgreementMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethodId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGenerateAchAgreement'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkedAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentMethodId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethodId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'download'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'url'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateAchAgreementMutationMutation,
  GenerateAchAgreementMutationMutationVariables
>
