import React, {FC} from 'react'
import {StyleSheet} from 'react-native'
import moment from 'moment'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {
  PFStatusPillProps,
  PFStatusPill,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {ColorVariants} from 'src/designSystem/colors'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type CommonListItemBaseProps = {
  title: string
  titleColor?: ColorVariants
  serviceChargeDescription?: string
  descriptionColor?: ColorVariants
  date: Date
  dateColor?: ColorVariants
  amount: number
  amountColor?: ColorVariants
  icon: SvgIconProps
}

export type AllListItemBaseProps = CommonListItemBaseProps & {
  pillProps?: PFStatusPillProps
  amountPrefix?: '-' | '+'
}

//the lineheight of the right aligned text (p) is greater than the left aligned text (p_sm)
const textOffset = 2
const webFlexValue = 3
const mobileFlexValue = 2
const toFixedNumber = 2

const flexValue = isDeviceWeb() ? webFlexValue : mobileFlexValue

const ListItemBase: FC<AllListItemBaseProps> = (props) => {
  const {
    title,
    titleColor,
    date,
    dateColor,
    amount,
    pillProps,
    serviceChargeDescription,
    amountPrefix = '',
    icon,
    descriptionColor,
    amountColor,
  } = props

  return (
    <Box direction={'row'} justify={'between'} paddingHorizontal={'medium'} testID="ListItemBase">
      <Box
        direction={'row'}
        gap={'small'}
        boxStyle={styles.imageTitleBoxStyles}
        paddingTop={textOffset}
        marginRight={'little'}
      >
        <Box paddingTop={'little'}>
          <SvgIcon name={icon.name} colorVariant={icon.colorVariant} size={'medium'} />
        </Box>
        <Box fill={'horizontal'} boxStyle={styles.textContentBoxStyles}>
          <PFText variant={'p'} color={titleColor ?? 'textPrimary'}>
            {title}
          </PFText>
          {serviceChargeDescription ? (
            <PFText variant={'p_sm'} color={descriptionColor ?? 'textPrimary'}>
              {serviceChargeDescription}
            </PFText>
          ) : undefined}
          <Box paddingTop={'tiny'}>
            <PFText variant={'p_sm'} color={dateColor ?? 'textDisabled'}>
              {moment(date).local().format('MMMM D, YYYY')}
            </PFText>
          </Box>
        </Box>
      </Box>
      <Box align={'end'} boxStyle={styles.amountBoxStyles}>
        <PFText
          variant={'p_semibold'}
          color={amountColor ?? 'textPrimary'}
        >{`${amountPrefix}$${amount.toFixed(toFixedNumber)}`}</PFText>
        {pillProps ? <PFStatusPill {...pillProps} /> : null}
      </Box>
    </Box>
  )
}

export {ListItemBase}

const styles = StyleSheet.create({
  amountBoxStyles: {
    flex: 0,
  },
  imageTitleBoxStyles: {
    flex: flexValue,
  },
  textContentBoxStyles: {
    flex: 1,
  },
})
