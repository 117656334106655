import React, {FC} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {LoanPaymentStatusCode} from 'src/cassandra'
import {NamedColors} from 'src/designSystem/colors'
import {PaymentItemStatusIndicator} from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToRescheduleTemplate/PaymentItem/PaymentItemStatusIndicator'
import {RescheduleLoanPaymentItemType} from 'src/products/loans/Reschedule/Reschedule.types'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type PaymentItemProps = {
  testID?: string
  hasBeenRescheduled: boolean
  onEdit: (payment: RescheduleLoanPaymentItemType) => void
  payment: RescheduleLoanPaymentItemType
}

const PaymentItem: FC<PaymentItemProps> = (props) => {
  const {hasBeenRescheduled, payment, onEdit, testID} = props

  const {t} = useTranslation('Reschedule')

  const currentPaymentDateMoment = moment(payment.rescheduledDate)
  const originalPaymentDateMoment = moment(payment.originalDate)

  const canEdit =
    payment.statusCode === LoanPaymentStatusCode.Pending ||
    payment.statusCode === LoanPaymentStatusCode.Failed ||
    payment.statusCode === LoanPaymentStatusCode.Suspended

  const handleOnEdit = (): void | (() => null) => {
    return canEdit ? onEdit(payment) : (): null => null
  }

  return (
    <Touchable testID={testID} onPress={() => handleOnEdit()} disabled={!canEdit}>
      <Box
        background={NamedColors.WHITESMOKE}
        radius={'tiny'}
        marginVertical={'little'}
        padding={'small'}
      >
        <Box direction={'row'} align={'center'} justify={'between'}>
          <PFText variant={'p_semibold'}>{currentPaymentDateMoment.format('MMM Do')}</PFText>
          <PFText variant={'p_semibold'}>${payment.amount}</PFText>
          <Box>
            <PaymentItemStatusIndicator
              paymentStatusCode={payment.statusCode}
              hasBeenRescheduled={hasBeenRescheduled}
            />
          </Box>
        </Box>
        {hasBeenRescheduled ? (
          <Box paddingTop={'little'}>
            <PFText variant={'p'}>
              {t('InitiallyScheduledForDate', {date: originalPaymentDateMoment.format('MMM Do')})}
            </PFText>
          </Box>
        ) : null}
      </Box>
    </Touchable>
  )
}

export {PaymentItem}
