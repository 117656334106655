import React, {FC} from 'react'

import {useWorkflows} from 'src/workflows/hooks/useWorkflows'
import {wfLog} from 'src/workflows/logging'
import {WorkflowLoading} from 'src/products/general/Workflows/WorkflowLoading'
import {WorkflowError, WorkflowErrorAnalytics} from 'src/products/general/Workflows/WorkflowError'
import {WorkflowsReadyType} from 'src/workflows/types'

export type WorkflowLoaderProps = WorkflowErrorAnalytics & {
  children?: JSX.Element
  onReady?: (workflowsData: WorkflowsReadyType) => JSX.Element
}

/**
 * This component takes a child to return once workflows has fully loaded
 * Alternatively, if the child needs workflowsData, this component can take
 * an onReady prop
 */
export const WorkflowLoader: FC<WorkflowLoaderProps> = (props) => {
  const {children = null, errorEvent, errorContactUsEvent, onReady} = props
  const workflows = useWorkflows()

  wfLog('Workflows Data = ', workflows)
  switch (workflows.status) {
    case 'READY':
      return onReady ? onReady(workflows) : children
    case 'LOADING':
      return <WorkflowLoading />
    case 'ERROR':
      return (
        <WorkflowError
          errorEvent={errorEvent}
          errorContactUsEvent={errorContactUsEvent}
          {...workflows}
        />
      )
    default:
      return null
  }
}
