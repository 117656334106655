import React, {Component} from 'react'
import {View, ImageSourcePropType, ViewStyle, StyleSheet} from 'react-native'
import * as Animatable from 'react-native-animatable'

import Image from 'src/products/general/Image/Image'
import {resolveAssetSource} from 'src/lib/Image'

const ANIMATION_TIME = 500
type Props = {
  collapse?: boolean
  image: ImageSourcePropType
}

type State = {
  aspectRatio?: number
  viewWidth?: number
}

class PageImage extends Component<Props, State> {
  ref: any
  constructor(props) {
    super(props)
    this.state = {}

    const res = resolveAssetSource(props.image)
    if (res) {
      this.state = {
        aspectRatio: res.width / res.height,
      }
    }
  }

  onRef = (ref) => (this.ref = ref)

  realHeight = (): number => {
    if (
      this.state.viewWidth === undefined ||
      this.state.aspectRatio === undefined ||
      this.state.aspectRatio === 0
    ) {
      return 0
    }
    return this.state.viewWidth / this.state.aspectRatio
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.collapse !== this.props.collapse) {
      if (this.ref && this.props.collapse) {
        this.ref.animate({0: {height: this.realHeight()}, 1: {height: 0}}, ANIMATION_TIME)
      }
    }
  }

  onLayout = (evt): void => {
    this.setState({viewWidth: evt.nativeEvent.layout.width})
  }

  render(): JSX.Element {
    const style: ViewStyle = {
      width: '100%',
      overflow: 'hidden',
    }

    if (this.props.collapse) {
      style.height = this.realHeight()
    } else {
      style.aspectRatio = this.state.aspectRatio
    }

    return (
      <Animatable.View ref={this.onRef} onLayout={this.onLayout} style={style}>
        <View style={style}>
          <Image resizeMode={'contain'} source={this.props.image} style={styles.image} />
        </View>
      </Animatable.View>
    )
  }
}

export default PageImage

const styles = StyleSheet.create({
  image: {
    height: '100%',
    width: '100%',
  },
})
