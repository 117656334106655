import React, {FC, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {PhoneNumberInputVerificationTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationTemplate'
import {userAccountRecovery} from '@possible/cassandra/src/user/authPublicMethods'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

type Props = StackScreenProps<
  MainStackParamList,
  'AccountRecovery' | 'AccountRecoveryAuthenticated'
> & {
  isFromDuplicate?: boolean
}

const AccountRecovery: FC<Props> = (props: Props) => {
  const [isBusy, setBusy] = useState<boolean>(false)
  const {navigation, isFromDuplicate} = props
  const {t} = useTranslation(['AccountRecovery', 'Common'])

  usePageViewedAnalytics({
    eventName: AppEvents.Name.account_recovery_enter_phone_number_viewed,
    eventCategory: AppEvents.Category.Login,
  })

  const handleOnCellPhoneNumberReady = async (
    phone: string,
    phoneFormatted: string,
  ): Promise<void> => {
    setBusy(true)
    await userAccountRecovery(phone)
    /*
    even if there is an error we will go to the next page, where the user can click resend
    */

    const instructionProps = {
      phoneNumber: phone,
      phoneNumberFormatted: phoneFormatted,
      isFromDuplicate: isFromDuplicate,
    }
    const redirectionRoute: keyof MainStackParamList = isFromDuplicate
      ? 'AccountRecoveryInstructionsAuthenticated'
      : 'AccountRecoveryInstructions'

    navigation.navigate(redirectionRoute, {
      ...instructionProps,
    })

    setBusy(false)
  }

  return (
    <PhoneNumberInputVerificationTemplate
      onButtonPress={handleOnCellPhoneNumberReady}
      mainTitle={t('ForgotLogin')}
      mainBody={t('TextInstructions')}
      isActionDisabled={isBusy}
      navigation={navigation}
    />
  )
}

export default withForwardedNavigationParams<Props>()(AccountRecovery)
