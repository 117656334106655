import React from 'react'

import IconFeatureListItem, {
  ItemProps,
} from 'src/designSystem/components/molecules/IconFeatureList/IconFeatureListItem'

type IconFeatureListProps = {
  items: Array<Omit<ItemProps, 'isLast'>>
}

const IconFeatureList = ({items}: IconFeatureListProps): JSX.Element => (
  <>
    {items.map((item, index) => (
      <IconFeatureListItem key={`item-${index}`} {...item} isLast={index === items.length - 1} />
    ))}
  </>
)

export default IconFeatureList
