import React, {useCallback, useEffect, useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {Consumer, CardStaticAgreementType} from 'src/cassandra'
import {
  CardAcceptDocument,
  Props as DocumentAgreementProps,
} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptDocument'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {logErrorAndShowException} from 'src/lib/errors'

export type CardAcceptStaticAgreementProps = Omit<DocumentAgreementProps, 'onClick'> & {
  cardStaticAgreementType: CardStaticAgreementType
  agreementName: string
}

export const CardAcceptStaticAgreement: React.FC<CardAcceptStaticAgreementProps> = (props) => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const [url, setUrl] = useState('test.possiblesasquatch.com')
  const {cardStaticAgreementType, agreementName} = props

  // Refactor to use Apollo Client query and onCompleted or move logic to backend to guarantee url response
  useEffect(() => {
    async function getAgreements(): Promise<void> {
      try {
        const res = await Consumer.methods.getStaticCardAgreements([cardStaticAgreementType])
        const newUrl = res?.getStaticCardAgreements?.[0]?.presignedUrl ?? ''
        if (newUrl === '') {
          void logErrorAndShowException(
            new Error(JSON.stringify(res)),
            'getStaticCardAgreements error:',
          )
        } else {
          setUrl(newUrl)
        }
      } catch (e) {
        void logErrorAndShowException(e as Error, 'getStaticCardAgreements error:')
      }
    }
    void getAgreements()
  }, [cardStaticAgreementType])

  const handleOnClick = useCallback(() => {
    displayPdf(url, navigation)
  }, [navigation, url])

  return (
    <CardAcceptDocument
      {...props}
      isDisabled={!url}
      onClick={handleOnClick}
      agreementName={agreementName}
    />
  )
}
