import React, {ReactNode, PropsWithChildren} from 'react'
import {StyleSheet, TextStyle, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'

import AppNav from 'src/nav/AppNavActions'
import {smallGap, tinyGap} from 'src/designSystem/layout'
import withNav, {InjectedNavProps} from 'src/nav/withNav'
import LightboxScrollView from 'src/designSystem/components/organisms/Lightbox/LightboxScrollview'

export type LightboxNoticeAction = StackedButtonsAction & {
  closeOnPress?: boolean
}

type Props = InjectedNavProps &
  PropsWithChildren & {
    primary_action: LightboxNoticeAction
    secondary_action?: LightboxNoticeAction
    busy?: boolean
    body_text?: ReactNode
    title?: string
    body?: ReactNode
    titleTextAlign?: TextStyle['textAlign']
    legalText?: string
    testID?: string
  }

const LightboxNotice: React.FC<Props> = (props) => {
  const {
    navigation,
    primary_action,
    secondary_action,
    body,
    busy,
    body_text,
    children,
    title,
    titleTextAlign,
    legalText,
    testID = 'Light-Box-Notice',
  } = props

  const close = (): void => {
    AppNav.pop(navigation)
  }

  const onPrimaryAction = (): void => {
    if (primary_action.closeOnPress) {
      close()
    }

    if (primary_action.onPress) {
      void primary_action.onPress()
    }
  }

  const onSecondaryAction = (): void => {
    if (secondary_action?.closeOnPress) {
      close()
    }

    void secondary_action?.onPress?.()
  }
  const generateTestID = (baseID: string, text: string): string => {
    return `${baseID}-${text.replace(/\s+/g, '')}`
  }

  const secondary: LightboxNoticeAction[] | undefined = secondary_action
    ? [
        {
          text: secondary_action.text,
          disabled: busy || secondary_action.disabled,
          onPress: onSecondaryAction,
          size: secondary_action.size,
          testID: generateTestID(testID, secondary_action.text),
        },
      ]
    : undefined

  const primary: LightboxNoticeAction | undefined = primary_action
    ? {
        text: primary_action.text,
        disabled: busy || primary_action.disabled,
        onPress: onPrimaryAction,
        size: primary_action.size,
        testID: generateTestID(testID, primary_action.text),
      }
    : undefined

  let lightboxBody: ReactNode
  if (body_text) {
    lightboxBody = <PFText variant={'p'}>{body_text}</PFText>
  } else {
    lightboxBody = body || children
  }

  return (
    <View style={styles.container} testID={`${testID}-Container`}>
      <LightboxScrollView>
        {title ? (
          <View
            style={[styles.contentView, body ? styles.contentSpacing : undefined]}
            testID={`${testID}-Title`}
          >
            <PFText variant={'h2'} textAlign={titleTextAlign ?? 'center'}>
              {title}
            </PFText>
          </View>
        ) : null}
        {lightboxBody ? (
          <View
            style={[styles.contentView, (primary || secondary) && styles.contentSpacing]}
            testID={`${testID}-Body`}
          >
            {lightboxBody}
          </View>
        ) : null}
      </LightboxScrollView>
      <StackedButtons primary={primary} secondary={secondary} testID={`${testID}-Buttons`} />
      {legalText ? (
        <View style={styles.disclaimer} testID={`${testID}-LegalText`}>
          <PFText variant={'p_sm'} color="textDisabled" textAlign="center">
            {legalText}
          </PFText>
        </View>
      ) : null}
    </View>
  )
}
export default withNav(LightboxNotice)

/*
Note : Don't use vertical margins, they mess up with the calculated sizes, causing an expansion after initial rendering
 */

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
    padding: tinyGap / 2,
  },
  contentSpacing: {
    marginBottom: smallGap,
  },
  contentView: {
    marginHorizontal: tinyGap,
  },
  disclaimer: {
    marginTop: smallGap,
  },
})
