import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {TransactionsAndPaymentsList} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/TransactionsAndPaymentsList'

type Props = StackScreenProps<MainStackParamList, 'CardTransactions'>
const CardTransactions: React.FC<Props> = () => {
  const {t} = useTranslation(['Transactions', 'Common'])

  return (
    <Page
      variant={'generic'}
      title={t('Transactions')}
      testID="Transactions-Page-Id"
      smallTopGap={true}
    >
      <TransactionsAndPaymentsList />
    </Page>
  )
}

export default CardTransactions
