import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {StackedButtonsAction} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {BottomSheetOrOverlayStackedButtons} from 'src/designSystem/components/organisms/BottomSheetOrOverlayStackedButtons/BottomSheetOrOverlayStackedButtons'

export type WhatToDoModalResult =
  | 'set_as_primary_account'
  | 'connect_debit_card'
  | 'nevermind'
  | 'edit_debit_card'
  | 'update_bank_details'
  | 'relink_account'
  | 'set_as_autopay_account'

type Props = {
  showSetAsPrimaryAccount?: boolean
  showConnectDebitCard?: boolean
  showUpdateBankDetails?: boolean
  showRelinkAccount?: boolean
  showSetAsAutoPayAccount?: boolean
  onCTA: (result: WhatToDoModalResult) => void
  visible: boolean
}

export const WhatToDoModal: FC<Props> = ({
  showSetAsPrimaryAccount,
  showConnectDebitCard,
  showUpdateBankDetails,
  showRelinkAccount,
  showSetAsAutoPayAccount,
  onCTA,
  visible,
}) => {
  const {t} = useTranslation('AccountManagement')

  const buttonSize = 'large'
  const primaryActions: StackedButtonsAction[] = []
  if (showSetAsPrimaryAccount) {
    primaryActions.push({
      text: t('SetAsPrimaryAccount'),
      size: buttonSize,
      onPress: (): void => {
        onCTA('nevermind')
        onCTA('set_as_primary_account')
      },
    })
  }
  if (showConnectDebitCard) {
    primaryActions.push({
      text: t('ConnectDebitCard'),
      size: buttonSize,
      onPress: (): void => {
        onCTA('nevermind')
        onCTA('connect_debit_card')
      },
    })
  }
  if (showUpdateBankDetails) {
    primaryActions.push({
      text: t('UpdateBankDetails'),
      size: buttonSize,
      onPress: (): void => {
        onCTA('nevermind')
        onCTA('update_bank_details')
      },
    })
  }
  if (showRelinkAccount) {
    primaryActions.push({
      text: t('RelinkAccount'),
      size: buttonSize,
      onPress: (): void => {
        onCTA('nevermind')
        onCTA('relink_account')
      },
    })
  }

  const secondaryActions: StackedButtonsAction[] = []
  if (showSetAsAutoPayAccount) {
    // if there is no primary action then this is set as the primary action,
    // otherwise this is a secondary action
    const primaryOrSecondaryToUse = primaryActions.length === 0 ? primaryActions : secondaryActions
    primaryOrSecondaryToUse.push({
      text: t('SetAsAutoPayAccount'),
      size: buttonSize,
      testID: 'Set-As-AutoPay-Account-Btn',
      onPress: (): void => {
        onCTA('set_as_autopay_account')
      },
    })
  }
  secondaryActions.push({
    text: t('Nevermind'),
    size: buttonSize,
    onPress: (): void => {
      onCTA('nevermind')
    },
  })

  return (
    <BottomSheetOrOverlayStackedButtons
      visible={visible}
      title={t('WhatWouldYouLikeToDo')}
      titleVariant="h2"
      titleAlign="left"
      testID="What-To-Do-Modal"
      primary={primaryActions}
      secondary={secondaryActions}
    />
  )
}
