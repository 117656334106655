import React, {FC} from 'react'

import {CardAgreementViewerGQLContainer} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

const CardAgreementViewerWorkflowContainer: FC<WorkflowPreReqFulfillScreenProps> = ({
  onPreReqFulfilled,
}) => {
  return <CardAgreementViewerGQLContainer onComplete={onPreReqFulfilled} />
}

export default CardAgreementViewerWorkflowContainer
