import {CardAccountStatusCode} from 'src/cassandra'
import {useCards} from 'src/products/card/hooks/useCards'

type returnType = (showIfHasPendingPhysicalCard: boolean) => boolean
export const useShouldDisplaySetupAutopayTile = (): returnType => {
  const {hasPendingPhysicalCard, cardAccount, activeCardStatus} = useCards()

  return (showIfHasPendingPhysicalCard: boolean): boolean => {
    const isAutopayDisabled = !!cardAccount && !cardAccount?.autopayEnabled

    // ENG-14442 Hide entry tile for deactivated users
    if (activeCardStatus?.code === CardAccountStatusCode.Deactivated) {
      return false
    }

    return (
      isAutopayDisabled &&
      ((showIfHasPendingPhysicalCard && hasPendingPhysicalCard) ||
        (!showIfHasPendingPhysicalCard && !hasPendingPhysicalCard))
    )
  }
}
