import valid from 'card-validator'

import {AddPaymentMethodResultType} from '@possible/cassandra/src/types/types.mobile.generated'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export type DebitCardSubmitValues = {
  name?: string
  number: string
  cvv: string
  expiration: string
}

export const expirationFilter = (input: string): string => {
  const minValue = 2
  const maxValue = 4
  try {
    let newValue = input.replace(/\D/g, '')
    if (newValue.length > minValue) {
      newValue = `${newValue.substring(0, minValue)}/${newValue.substring(minValue, maxValue)}`
    }
    return newValue
  } catch (e) {
    return input
  }
}

export const isValidCardExpiration = (input: string): boolean => {
  const cardExpirationValidation = valid.expirationDate(input)
  return cardExpirationValidation.isValid
}

export const isValidCVV = (input: string): boolean => {
  const cardCVVValidation = valid.cvv(input, [3, 4])
  return cardCVVValidation.isValid
}

export const isValidNumber = (input: string): boolean => {
  const cardNumberValidation = valid.number(input)
  return cardNumberValidation.isValid
}

export const isValidName = (input: string): boolean => {
  const nameValidation = valid.cardholderName(input)
  return nameValidation.isValid
}

export const failedMutationErrorText =
  'CollectDebitCardNumberForAdhocPayment failed to submit mutation to add Debit payment method'

export const throwSubmitAddDebitCardError = (
  errorType: AddPaymentMethodResultType | undefined,
): undefined => {
  switch (errorType) {
    case AddPaymentMethodResultType.AlreadyExists:
      TrackAppEvent(
        BankAccountManagementEvents.bank_account_management_add_debit_card_duplicate,
        AppEvents.Category.BankAccountManagement,
      )
      throw Error('Debit card already exists')
    case AddPaymentMethodResultType.BinMatch:
      TrackAppEvent(
        BankAccountManagementEvents.bank_account_management_add_debit_card_fail,
        AppEvents.Category.BankAccountManagement,
      )
      throw Error('Bin match error')
    case AddPaymentMethodResultType.InvalidCardType:
      TrackAppEvent(
        BankAccountManagementEvents.bank_account_management_add_debit_card_fail_credit,
        AppEvents.Category.BankAccountManagement,
      )
      throw Error('Invalid card type')
    default:
      TrackAppEvent(
        BankAccountManagementEvents.bank_account_management_add_debit_card_fail,
        AppEvents.Category.BankAccountManagement,
      )
      throw Error('Unknown error')
  }
}
