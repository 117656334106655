import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getStatesFullAsDropdownOptions} from 'src/lib/us_states'
import PFDropdown, {
  PFDropdownProps,
  PFDropdownOptionProps,
} from 'src/designSystem/components/atoms/PFDropdown/PFDropdown'
import {GetStatesDocument} from '@possible/cassandra/src/types/types.public.generated'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

type Props = Omit<PFDropdownProps, 'label' | 'options' | 'placeholder'> & {
  label?: string
  placeholder?: string
  useValueForLabel?: boolean
}

const StateDropdown: FC<Props> = (props) => {
  const {label, placeholder, useValueForLabel: shouldUseValueForLabel, ...rest} = props
  const {t} = useTranslation('Common')

  const {selectedData: StatesFromGraphql, loading: isLoadingStates} = useCassandraQuery(
    GetStatesDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => {
      if (data.getStates) {
        const states: PFDropdownOptionProps[] = data.getStates.map(({code, name}) => ({
          label: name,
          value: code,
        }))
        return states
      }
    },
    {
      isPublic: true,
    },
  )

  const defaultStateOptions: PFDropdownOptionProps[] = useMemo(
    () => getStatesFullAsDropdownOptions(),
    [],
  )

  const stateOptions = StatesFromGraphql ? StatesFromGraphql : defaultStateOptions

  //useValueForLabel allows us to show the full state name in the list when the dropdown is open
  //but use the state abreviation in the field when it is closed
  return isLoadingStates ? (
    <Spinner />
  ) : (
    <PFDropdown
      label={label ?? t('State')}
      options={stateOptions}
      placeholder={placeholder ?? t('Select')}
      useValueForLabel={shouldUseValueForLabel ?? true}
      {...rest}
    />
  )
}

export default StateDropdown
