let GitBranchOverride: string | undefined = undefined
const GetGitBranchOverride = (): string | undefined => {
  return GitBranchOverride
}

const GitBranchLaunchArgProcessor = (value: string | undefined): void => {
  GitBranchOverride = value
}

export {GetGitBranchOverride, GitBranchLaunchArgProcessor}
